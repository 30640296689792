import RNSmsRetriever from 'react-native-sms-retriever-api';
import {
    BigButton, Field, Keyboard, Link, Logo, Page, Platform, React, Section, TextInput,
} from '../../common';
import { getDefaultSmsProvider, getIsUserFetching, getUserInteraction } from '../../selectors';
import { MODE } from '../../config';
import { user } from '../../services/user';
import { Timer } from './Timer';

export const CodeConfirmation = Page.register(
    ({
        id,
        interaction: { error, isLoading },
        isAuthenticating,
        phoneNumber = '',
        code = MODE === 'dev' ? '111111' : '',
        disabled = true,
        confirmation,
        setState,
        actions,
        isSignInWithApi = false,
        waitingResponse,
        navigation,
        defaultSmsProvider,
        startListenSms = async () => {
            if (Platform.OS === 'android') {
                RNSmsRetriever.getOtp();
                await RNSmsRetriever.addListener(({ message = '' }) => {
                    const index = message.indexOf(':');
                    let finalSmsCode = message.slice(index + 1).split('.')[0];
                    finalSmsCode = Number(finalSmsCode) ? finalSmsCode : '';
                    setState({ code: finalSmsCode });
                    RNSmsRetriever.removeListener();
                });
            }
        },
        onSubmit = async () => {
            Keyboard.dismiss();
            if (isSignInWithApi) {
                actions.startUserFetch();
                Object.SendConfirmationCode = true;
                const token = await user.smsConfirmation(Number(code), id, (message, errorCode) => {
                    Object.SendConfirmationCode = false;
                    actions.stopUserFetch();
                    actions.setUserError(message, errorCode);
                });
                if (token) {
                    actions.signInWithToken(token, (message, errorCode) => {
                        Object.SendConfirmationCode = false;
                        actions.stopUserFetch();
                        actions.setUserError(message, errorCode);
                    });
                }
            } else {
                Object.SendConfirmationCode = true;
                actions.startUserFetch();
                actions.sendSmsCode(code, confirmation, (e) => {
                    Object.SendConfirmationCode = false;
                    let message = '';
                    const errorCode = e?.code;
                    if (e?.code === 'auth/invalid-verification-code' || e?.code === 'auth/code-expired') {
                        message = Object.R('error.ERROR_INVALID_VERIFICATION_CODE');
                    } else {
                        message = Object.R('titles.somethingWentWrong');
                    }
                    actions.stopUserFetch();
                    actions.setUserInteractionError({
                        message,
                        errorCode,
                    });
                });
            }
            // actions.setUserInteractionError({ });
            setState({ waitingResponse: false });
        },
        sendAgain = async () => {
            // // isSignInWithApi ?
            let appHash = [];
            if (Platform.OS === 'android') {
                appHash = await RNSmsRetriever.getHash();
                startListenSms();
            }
            if (defaultSmsProvider !== 'fb') {
                const fullPhoneNumber = `+${phoneNumber.replace(/\D+/g, '')}`;
                try {
                    actions.signInWithPhoneNumber(fullPhoneNumber, (newId) => {
                        setState({
                            code: '',
                            id: newId,
                            confirmation: newId,
                            isSignInWithApi: false,
                        });
                    });
                } catch (e) {
                    console.log('Error login', e);
                }
            } else {
                const { id: newId } = await user.signIn(
                    `+${phoneNumber.replace(/\D+/g, '')}`,
                    appHash[0],
                    actions.setUserError,
                );
                setState({
                    code: '',
                    id: newId,
                    isSignInWithApi: true,
                });
            }
        },
    }) => (
        <Page
            name="sign-in"
            persistKeyboard
            notification={
                error && {
                    ...error,
                    onSubmit: isSignInWithApi ? undefined : onSubmit,
                }
            }
            isLoading={isLoading || isAuthenticating}
            onDidMount={() => {
                startListenSms();
                actions.setFormData({
                    CodeConfirmation: {
                        back: () => {
                            actions.setUserInteractionError();
                            navigation.goBack();
                            return true;
                        },
                    },
                });
            }}
            onWillUnmount={() => {
                actions.setUserError();
                if (Platform.OS === 'android') {
                    RNSmsRetriever.removeListener();
                }
            }}>
            <Section flex>
                <Logo.Wrapper
                    title="titles.enter_code"
                    subtitle="titles.codeHasBeenSent"
                    subtitleParams={{ phone: phoneNumber }}>
                    <Field>
                        <TextInput.Number
                            ns="code"
                            placeholder="placeholders.code"
                            value={code}
                            onChange={c => setState({ code: c })}
                        />
                    </Field>
                    <BigButton
                        action={
                            waitingResponse
                                ? () => {}
                                : () => {
                                    setState({ waitingResponse: true });
                                    onSubmit();
                                }
                        }
                        title="buttons.enter_to_aibolit"
                        disabled={!code}
                    />
                </Logo.Wrapper>
            </Section>
            <Section centered>{disabled ? <Timer onComplete={() => setState({ disabled: false })} /> : null}</Section>
            <Section centered style={{ marginBottom: 40 }}>
                {!disabled && (
                    <Link
                        underline
                        disabled={disabled}
                        bigger
                        id="titles.sendAgain"
                        href={() => {
                            setState({ disabled: true });
                            sendAgain();
                        }}
                        trackingAlias="CodeConfirm"
                    />
                )}
            </Section>
        </Page>
    ),
    {
        interaction: { from: getUserInteraction },
        isAuthenticating: { from: getIsUserFetching },
        defaultSmsProvider: { from: getDefaultSmsProvider },
    },
);
