// noinspection JSUnusedGlobalSymbols

import { capitalize, histogram } from '../utils';
import { createSelector, gettr } from './utils';
import { getIncludeAnalogs } from './getters';

/**
 * Drugs
 */

export const getSearchDrugsKeyword = gettr('drugs.keyword', '');
export const getSearchSuggestionsKeyword = gettr('drugs.suggestionsKeyword', '');
export const getSearchDrugsRemoteKeyword = gettr('drugs.remoteKeyword', '');
export const getSearchDrugsData = gettr('drugs.data');
export const getSearchDrugs = gettr('drugs');
export const getSearchDrugsError = gettr('drugs.error');
export const getSearchDrugsShouldReload = gettr('drugs.shouldReload');

export const getDrugsSearchState = store => store.drugs;

export const getSearchKeywordDrugs = createSelector(
    [getSearchDrugs, getSearchDrugsKeyword, getIncludeAnalogs, getSearchDrugsError],
    (drugs, keyword, includeAnalogs, error) => {
        let result = Object.values(drugs.data || []);
        if (!includeAnalogs) {
            result = result.filter(e => e.key.includes(keyword));
        }
        return {
            ...drugs,
            error,
            data: result.map(e => ({
                ...e,
                id: e.ls_num,
                name: e.ls_name.toUpperCase(),
            })),
        };
    },
);

export const getSearchDrugsHistogram = createSelector([getSearchDrugs, getSearchDrugsKeyword], (drugs, keyword) => ({
    ...drugs,
    data: Object.values(
        histogram(
            (drugs.data || []).filter(e => e.key.includes(keyword)),
            e => e.name.split(/[\s-]/)[0],
        ),
    ).map(e => ({
        ...e,
        id: e.key,
        name: `${capitalize(e.key)} (${e.count})`,
    })),
}));

export const getDrugInfo = createSelector([getDrugsSearchState], drugs => drugs?.drugInfo);
export const getDrugsSearchList = createSelector([getDrugsSearchState], drugs => drugs?.searchList);
export const isDrugsInfoLoading = createSelector([getDrugsSearchState], drugs => drugs?.isLoading);
