/* eslint-disable no-underscore-dangle */
import {
    Button,
    ButtonsGroup,
    Component,
    dataFrom,
    List,
    Modal2,
    Page,
    React,
    Section,
    SingleNavigation,
    View,
} from '../../common';
import { selector as styles } from '../../styles/common';
import { putIntoStore, SCHEME } from '../../store';
import {
    getUmsPrefilterMedcentersGroup,
    testsPrefilterClinics,
    testsPrefilterStatuses,
    testsPrefilterVisitors,
} from '../../selectors';
import { back } from '../../actions';
import { MedcentersFilterModal } from '../../combo';
import { VisitorItem } from './VisitsFilter';
import { visitsFilter as style } from './style';

const {
    TESTS_FILTER,
    TESTS_PREFILTER_VISITORS,
    TESTS_PREFILTER_CLINICS,
    TESTS_PREFILTER_STATUSES,
    UMS_PREFILTER_MEDCENTERS_GROUP,
} = SCHEME;

const listData = (data) => {
    const selectIf = fn => putIntoStore(SCHEME.TESTS_PREFILTER_VISITORS, data.map(e => ({
        ...e,
        selected: fn(e),
    })));
    return data.map(e => ({
        ...e,
        toggle: () => selectIf(ee => (ee.id === e.id ? !ee.selected : ee.selected)),
    }));
};

const listDataStatuses = (data) => {
    const selectIf = fn => putIntoStore(SCHEME.TESTS_PREFILTER_STATUSES, data.map(e => ({
        ...e,
        selected: fn(e),
    })));
    return data.map(e => ({
        ...e,
        toggle: () => selectIf(ee => (ee.id === e.id ? !ee.selected : ee.selected)),
    }));
};

const VisitorsFilterList = ({ visitors }) => (
    <View style={styles.listWrapper} accessibilityLabel="list">
        <List
            style={style.list}
            data={listData(visitors)}
            renderItem={VisitorItem}
        />
    </View>
);

const StatusesFilterList = ({ statuses }) => (
    <View style={styles.listWrapper} accessibilityLabel="list">
        <List
            style={style.list}
            data={listDataStatuses(statuses)}
            renderItem={VisitorItem}
        />
    </View>
);

const VisitorsFilter = dataFrom(testsPrefilterVisitors, ({ data }) => <VisitorsFilterList visitors={data}/>);
const StatusesFilter = dataFrom(testsPrefilterStatuses, ({ data }) => <StatusesFilterList statuses={data}/>);


class TestsFilterImpl extends Component {
    get selectedVisitorName() {
        return this.props.visitors.filter(e => e.selected)
            .map(({ name }) => name)
            .join(', ') || Object.R('titles.anyVisitor');
    }

    get selectedClinicName() {
        return this.props.clinics.filter(e => e.selected)
            .map(({ name }) => name)
            .join(', ') || Object.R('titles.anyClinics');
    }

    get selectedStatusName() {
        return this.props.statuses.filter(e => e.selected)
            .map(({ name }) => name)
            .join(', ') || Object.R('titles.anyStatuses');
    }

    _resetFilter = () => {
        putIntoStore(TESTS_FILTER, {
            visitors: [],
            statuses: [],
            clinics: [],
        });
        const {
            visitors,
            clinics,
            statuses,
        } = this.props;
        putIntoStore(TESTS_PREFILTER_VISITORS, visitors.map(e => ({
            ...e,
            selected: false,
        })));
        putIntoStore(TESTS_PREFILTER_CLINICS, clinics.map(e => ({
            ...e,
            selected: false,
        })));
        putIntoStore(TESTS_PREFILTER_STATUSES, statuses.map(e => ({
            ...e,
            selected: false,
        })));
    };

    _applyFilter = () => {
        const {
            visitors,
            clinics,
            statuses,
        } = this.props;
        putIntoStore(TESTS_FILTER, {
            visitors: visitors.filter(e => e.selected)
                .map(e => e.id),
            clinics: clinics.filter(e => e.selected)
                .map(e => e.id),
            statuses: statuses.filter(e => e.selected)
                .map(e => e.id),
        });
        back();
    };

    render() {
        return (
            <Page
                name="tests-filter"
                footer={(
                    <ButtonsGroup style={{
                        backgroundColor: 'white',
                        paddingBottom: 12,
                        borderTopWidth: 1,
                        borderTopColor: '#F0F0F0',
                    }}>
                        <Button transparent action={this._resetFilter} title="buttons.reset_filter" titleStyles={{
                            color: '#555555',
                            fontSize: 16,
                            fontFamily: 'SourceSansPro-Semibold',
                        }}/>
                        <Button primary action={this._applyFilter} title="buttons.find"/>
                    </ButtonsGroup>
                )}
            >
                <Section title="titles.forWhomTests" capitalize>
                    <SingleNavigation
                        title={this.selectedVisitorName}
                        onPress={() => Page.showModal((
                            <Modal2 noScroll title="titles.forWhomTests">
                                <VisitorsFilter/>
                            </Modal2>
                        ))}
                    />
                </Section>
                <Section title="titles.forClinicsTests" capitalize>
                    <SingleNavigation
                        title={this.selectedClinicName}
                        onPress={() => Page.showModal(<MedcentersFilterModal
                            groupFrom={getUmsPrefilterMedcentersGroup}
                            prefilterDataFrom={testsPrefilterClinics}
                            prefilterPath={TESTS_PREFILTER_CLINICS}
                            groupPath={UMS_PREFILTER_MEDCENTERS_GROUP}
                        />)}
                    />
                </Section>

                <Section title="titles.forStatusesTests" capitalize>
                    <SingleNavigation
                        title={this.selectedStatusName}
                        onPress={() => Page.showModal((
                            // eslint-disable-next-line no-undef
                            <Modal2 noScroll title="titles.forStatusesTests">
                                <StatusesFilter/>
                            </Modal2>
                        ))}
                    />

                </Section>
            </Page>
        );
    }
}

export const TestsFilter = Page.register(TestsFilterImpl, {
    visitors: { from: testsPrefilterVisitors },
    clinics: { from: testsPrefilterClinics },
    statuses: { from: testsPrefilterStatuses },
});
