import { useSelector } from 'react-redux';
import { gettr } from '../selectors/utils';
import { useFocusEffect } from '@react-navigation/native';
import { useCallback } from 'react';

const millisecondsInHour = 60 * 60 * 1000;

export const TWELVE_HOURS = 12 * millisecondsInHour;
const exampleTime = 3 * 60 * 1000;

export const CACHE_VALID_TIME = TWELVE_HOURS;

export const getSpecialitiesPath = (locationCode, branchId) => `doctors.specialitiesInfo.${locationCode}.${branchId || 'all'}.modificationDate`;
export const getDoctorsBySpecPath = (locationCode, specId) => `doctors.data.${locationCode}.${specId || 'all'}.modificationDate`;

export const getSpecialitiesFullListPath = () => `doctors.specialitiesFullList.modificationDate`;

export const isTimePassed = (mdate, time) => {

    const currentTime = Date.now();
    return currentTime - mdate >= time;

};

export const useDoctorsDataExpirationHandler = (path, updateFn) => {

    const mdate = useSelector(gettr(path));
    useFocusEffect(
        useCallback(() => {

            if (mdate && isTimePassed(mdate, CACHE_VALID_TIME)) {

                console.log('!!! Specialties/Doctors were updated !!!');
                updateFn();

            }

        }, [mdate]),
    );

};
