/* eslint-disable no-underscore-dangle */
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { OnMapButton, Page, React, SearchBar, Section, Stub, View } from '../../common';
import actions from '../../actions';
import {
    actualDoctors,
    actualSplitDoctors,
    getClinicsHasBumba,
    getCountOfActualDoctors,
    getCountOfDoctorsInFavorites,
    getCountOfDoctorsListInFavorites,
    getDoctorsDataFromFavorites,
    getDoctorsFromFavorites,
    getDoctorsIdsFromFavorites,
    getFetchingSyncAllPhysicians,
    getHasSpecialitiesFullList,
    getIsFavoriteDoctorsFilterActive,
    getLocationAndSpec,
    getPhysiciansFavoritesSearch,
    getPhysiciansSearch,
    physiciansIsLoading,
    getIsDoctorsLoading,
    getHasDoctorsDataFirFavorites,
} from '../../selectors';
import { curry, isEmpty } from '../../utils';
import { AnimatedHeaderList } from '../../common/AnimatedHeaderList';
// noinspection ES6PreferShortImport
// import firebase, { collections as collectiontToSubscribe, pureCollections } from '../../services/firebase';
import { getFormData } from '../../selectors/forms';
import { BumbaBanner, SortIcon } from '..';
import { storage } from '../../services/localstorage';
import { ProgramSelector } from '../../combo';
import { SCHEME } from '../../scheme';
import { putIntoStore } from '../../store';
import {
    fetchDoctorsSums,
    fetchLocalDoctors,
    fetchPhysicians,
    fetchPhysiciansData,
    useHasActualRelatedPrograms,
} from './utils';
import { FiltersTagsGroup } from './snippets';
import { DoctorsFavoritesItem } from './DoctorsFavoritesItem';
import {
    getSpecialitiesFullListPath,
    useDoctorsDataExpirationHandler,
} from '../../hooks/useDoctorsDataExpirationHandler';

const NS = 'doctors';

const DoctorsListEmpty = () => {
    const isFilterActive = useSelector(getIsFavoriteDoctorsFilterActive);
    return !isEmpty(isFilterActive) ? <Stub.FavoriteDoctors /> : null;
};

const onSearch = curry(putIntoStore, SCHEME.PHYSICIANS_FAVORITES_SEARCH);

const TheSearchBar = ({ searchData, initialValue = '', onSearch: _onSearch, placeholder }) => (
    <SearchBar
        autoCorrect={false}
        initialValue={searchData || initialValue}
        searchAfter={2}
        onSearch={_onSearch}
        placeholder={placeholder}
        borderRadius={3}
    />
);

const DoctorsListHeader = () => {
    const searchData = useSelector(getPhysiciansFavoritesSearch);
    return (
        <TheSearchBar
            initialValue={''}
            searchData={searchData}
            onSearch={onSearch}
            placeholder={Object.R('placeholders.search_doctors')}
        />
    );
};

const renderItem = ({ item }) => <DoctorsFavoritesItem doctorInfo={item} key={item.id} />;

const ListSectionHeader = ({ title }) => {
    const isDoctorsLoading = useSelector(getIsDoctorsLoading);
    const count = useSelector(getCountOfDoctorsListInFavorites);
    // eslint-disable-next-line no-nested-ternary
    const defaultTitle = count === undefined || isDoctorsLoading ? 'titles.loadingData' : 'doctors';

    return (
        <Section
            title={title || defaultTitle}
            count={count === undefined || isDoctorsLoading ? undefined : count}
            isLoading={count === undefined || isDoctorsLoading}
        />
    );
};

export const DoctorsScreenHeader = () => {
    const { bumbaBanner } = useSelector(getFormData);
    const hasBumba = useSelector(getClinicsHasBumba);
    return (
        <View>
            <ProgramSelector withInfo />
            <DoctorsListHeader />
            {/* <FiltersTagsGroup /> */}
            {!!bumbaBanner && hasBumba && (
                <BumbaBanner
                    onClose={() => {
                        actions.setFormData({ bumbaBanner: false });
                        storage.set('bumbaBanner', false).then();
                    }}
                />
            )}
            <ListSectionHeader />
        </View>
    );
};

const DataList = ({ headerHeight }) => {
    const data = useSelector(getDoctorsDataFromFavorites);
    const { bumbaBanner } = useSelector(getFormData);
    const hasBumba = useSelector(getClinicsHasBumba);
    return (
        <AnimatedHeaderList
            data={data}
            ListHeader={DoctorsScreenHeader}
            headerHeight={headerHeight + (bumbaBanner && hasBumba ? 72 : 0)}
            renderItem={renderItem}
            emptyComponentRenderer={DoctorsListEmpty}
            separateHeader={false}
            onRefresh={fetchDoctorsSums}
        />
    );
};

export const DoctorsFavorites = Page.register(() => {
    const hasPrograms = useHasActualRelatedPrograms();
    const docFavIds = useSelector(getDoctorsIdsFromFavorites);
    const hasSpec = useSelector(getHasSpecialitiesFullList);
    const hasDocData = useSelector(getHasDoctorsDataFirFavorites);
    // fetchLocalDoctors();
    useEffect(() => {
        if (!hasDocData) {
            actions.loadDoctorListInfo(docFavIds);
        }
    }, [docFavIds, hasDocData]);

    useEffect(() => {
        if (!hasSpec) {
            actions.syncSpecialitiesFullList();
        }
    }, [hasSpec]);

    useDoctorsDataExpirationHandler(getSpecialitiesFullListPath(), () =>
        actions.syncSpecialitiesFullList({ forceReload: true }),
    );

    useEffect(() => {
        return () => {
            putIntoStore(SCHEME.PHYSICIANS_FILTER_FAVORITES, false);
            actions.updateDocFilters({ filterFavoriteDoctors: false });
        };
    }, []);
    return (
        <Page name={NS} noscroll>
            <DataList headerHeight={hasPrograms ? 180 : 110} />
        </Page>
    );
});
