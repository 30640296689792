/* eslint-disable class-methods-use-this */
import {
    Component, React, Text, View,
} from '../../common';

let downloadTimer;
const total = 61;

export class Timer extends Component {
    state = { startTime: Date.now() };

    componentDidMount() {
        downloadTimer = setInterval(() => {
            const { startTime } = this.state;
            const deltaSec = Math.round((Date.now() - startTime) / 1000);
            if (deltaSec >= total) {
                clearInterval(downloadTimer);
                this.props.onComplete();
            } else {
                this.setState({ time: total - deltaSec });
            }
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(downloadTimer);
    }

    render() {
        return (!this.state.time ? null
            : (
                <View
                    style={{
                        borderWidth: 3,
                        borderColor: '#B6BEDA',
                        width: 40,
                        height: 40,
                        borderRadius: 40 / 2,
                    }}
                >
                    <View style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <Text style={{
                            color: '#B6BEDA',
                            fontFamily: 'SourceSansPro-Semibold',
                        }}>
                            {this.state.time}
                        </Text>
                    </View>
                </View>
            )
        );
    }
}

