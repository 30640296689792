import { React, RText, withIsAuthenticated } from '../common';
import { textWithAuth as styles } from '../styles/common';

export const TextWithAuth = withIsAuthenticated(({
    isAuthenticated,
    id,
    paddingLeft = 20,
    style,
}) => (
    <RText id={`${id}${isAuthenticated ? '_auth' : ''}`} style={[styles.emptyListText, { paddingLeft }, style]}
        numberOfLines={null}/>
));
