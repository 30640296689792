import MapView, { Callout, Marker } from 'react-native-maps';
import { useCallback, useEffect, useRef } from 'react';
import {
    Platform, React, Text, View,
} from '../../common/react';
import { elementsStyles } from '../../common/style';
import { IMAGES } from '../../assets';
import { SCALE_COLORS } from './utils';
import { mapMarkerStyles, priceTooltipStyles } from './styles';

const calloutsDisabled = !Callout || Platform.OS === 'ios';

const PriceTooltip = ({
    price, backgroundColor = '#A2AEBD', unit, nullTitle, toolTipTitle,
}) => {
    const title = price === null ? nullTitle : `${toolTipTitle || price}${unit ? ` ${unit}` : ''}`;
    const iosTextStyle = Platform.OS === 'ios' && {
        width: 20 + (title.length ?? 1) * 8,
        alignItems: 'center',
    };

    return (
        <View style={[priceTooltipStyles.priceTooltip]}>
            <View style={[priceTooltipStyles.body, { backgroundColor }, iosTextStyle]}>
                <Text numberOfLines={1} style={[elementsStyles.headLine, priceTooltipStyles.text]}>
                    {title}
                </Text>
            </View>
            <View style={[priceTooltipStyles.callout, { borderTopColor: backgroundColor }]} />
        </View>
    );
};

const ItemMarker = ({ children, forwardRef, ...restProps }) => (Platform.OS === 'web' ? (
    <MapView.Marker ref={forwardRef} {...restProps}>
        {children}
    </MapView.Marker>
) : (
    <Marker ref={forwardRef} {...restProps}>
        {children}
    </Marker>
));

export const MapMarker = React.memo(
    ({
        data,
        pressHandler,
        colorId,
        scaleKey = 'totalPrice',
        toolTipKey,
        data: {
            id, latitude, longitude, isSelected,
        },
        toolTipTitle,
        price = data[scaleKey],
        isWithoutData = price === null,
        backgroundColor = isWithoutData ? '#A2AEBD' : SCALE_COLORS[colorId - 1],
        nullTitle,
        unit,
    }) => {
        const ref = useRef();
        const onPress = useCallback(() => pressHandler(data), [pressHandler, data]);

        useEffect(() => {
            if (calloutsDisabled) {
                return;
            }
            const map = ref.current;
            setTimeout(() => {
                if (isSelected) {
                    ref.current?.showCallout?.();
                } else {
                    map.hideCallout();
                }
            }, 500);
        }, [isSelected, ref.current]);
        return (
            <ItemMarker
                onPress={onPress}
                identifier={id}
                coordinate={{
                    latitude,
                    longitude,
                }}
                forwardRef={ref}
                centerOffset={{
                    x: 0,
                    y: 0,
                }}
                calloutOffset={{
                    x: 0,
                    y: 20,
                }}
                anchor={{
                    x: 0.5,
                    y: 0.5,
                }}
                calloutAnchor={{
                    x: 0.5,
                    y: 0,
                }}
                image={colorId === -1 ? IMAGES.Dot8 : IMAGES[`Dot${isWithoutData ? 8 : colorId}`]}>
                {
                    // eslint-disable-next-line no-nested-ternary
                    calloutsDisabled ? (
                        !isSelected ? null : (
                            <View style={mapMarkerStyles.selectedPriceTooltipWrapper}>
                                <PriceTooltip
                                    price={data[toolTipKey] || price}
                                    backgroundColor={backgroundColor}
                                    unit={unit}
                                    nullTitle={nullTitle}
                                    toolTipTitle={toolTipTitle}
                                />
                            </View>
                        )
                    ) : (
                        <Callout tooltip>
                            <PriceTooltip
                                price={data[toolTipKey] || price}
                                backgroundColor={backgroundColor}
                                unit={unit}
                                nullTitle={nullTitle}
                                toolTipTitle={toolTipTitle}
                            />
                        </Callout>
                    )
                }
            </ItemMarker>
        );
    },
);
