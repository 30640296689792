/* eslint-disable camelcase */
import { StackActions, useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { BackHandler, Platform } from 'react-native';
import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';
import { Icon, ShareIcon } from '../common';
import {
    AppHeader, CitySelector, FavIcon, FavoritesIcon, MainHeader,
} from '../combo';
import { FirstAidKitIcon } from '../screens';
import firebase from '../services/firebase';
import { shareItem } from '../services/device';
import tracking from '../services/tracking';
import { selectGettr } from '../selectors/utils';
import Store from '../store';
import { shareVisit } from '../screens/Visits/utils';
import { urlStringify } from './url';
import { capitalize } from '.';

const cardStyle = {
    flex: 1,
    overflow: 'hidden',
};
const transitionPreset = Platform.OS === 'web' ? {} : TransitionPresets.SlideFromRightIOS;

export const screenOptionsTab = {
    header: () => (
        <MainHeader>
            <CitySelector />
        </MainHeader>
    ),
    cardStyle,
};

export const screenOptions = {
    header: () => <AppHeader right />,
    cardStyle,
    mode: 'card',
    // animationEnabled: true,
    ...transitionPreset,
};

export const screenOptionsLoadVisits = {
    header: ({ navigation }) => (
        <AppHeader
            backPress={() => {
                navigation.dispatch(StackActions.pop());
                navigation.navigate('MyCard');
            }}
            right
        />
    ),
    cardStyle,
    mode: 'card',
    animationEnabled: true,
    ...transitionPreset,
};

export const screenOptionsBare = {
    ...screenOptions,
    header: () => <AppHeader />,
};

export const screenOptionsNoHeader = {
    ...screenOptions,
    header: () => null,
};

export const screenOptionsRoot = () => ({
    header: () => <AppHeader root />,
    cardStyle,
});

export const optionsMedCenterDetails = ({ route: { params: { mdInfo } } }) => ({
    ...screenOptions,
    header: () => (
        <AppHeader right>
            <FavIcon item={mdInfo} type="clinics" />
        </AppHeader>
    ),
});

export const optionsMyCards = () => ({
    ...screenOptionsTab,
    header: () => (
        <MainHeader>
            <CitySelector />
        </MainHeader>
    ),
});

export const optionsDoctorDetails = ({
    route: {
        params: {
            doctorInfo: { id, _id },
            doctorInfo,
        },
    },
}) => {
    return {
        ...screenOptions,
        header: () => (
            <AppHeader right>
                {Platform.OS === 'web' ? null : (
                    <ShareIcon shareTitle="Share doctor" shareParams={{ docId: id ?? _id }} type="doctor" />
                )}
                <FavIcon item={doctorInfo} type="physicians" />
            </AppHeader>
        ),
    };
};

export const optionsDoctorsSpecialtiesSearch = () => ({
    ...screenOptions,
    header: ({ navigation }) => (
        <AppHeader right>
            <FavoritesIcon
                color={'#fff'}
                cb={() => {
                    navigation.navigate('DoctorsFavorites');
                }}
                isActive={false}
            />
        </AppHeader>
    ),
});

export const optionsDoctorsList = ({ route: { params: { title } } }) => ({
    ...screenOptions,
    header: () => (
        <AppHeader title={title && capitalize(title)} noFlex right>
            {/* <DoctorsFavoritesIcon /> */}
        </AppHeader>
    ),
});

export const optionsDoctorsFavorites = ({ navigation }) => ({
    ...screenOptions,
    header: () => (
        <AppHeader right>
            <FavoritesIcon
                color={'#fff'}
                cb={() => {
                    navigation.goBack();
                }}
                isActive={true}
            />
        </AppHeader>
    ),
});

export const shareDrugsData = (data, type = 'firstAidKit', title = 'titles.shareFirstAidKit', addParams = {}) => {
    const location = Store?.getState()?.selections?.data?.location;
    const share = {
        drugs: data
            ?.filter?.((item) => {
                const { selected } = item;
                if (typeof selected === 'undefined') {
                    return true;
                }
                return !!selected;
            })
            ?.map(({ ls_name, ls_num, qty }) => ({
                ls_name,
                ls_num,
                qty,
            })),
        type,
    };
    // eslint-disable-next-line no-param-reassign
    addParams.location = location;
    share.addParams = addParams;
    const url = urlStringify({
        target: 'https://link.aibolit.md',
        path: 'drugs',
        params: share,
    });
    shareItem(Object.R(title), url, () => {
        tracking.logEvent('share', { content_type: type });
    });
};

export const optionsSearchDrugstores = ({ route }) => {
    const params = route?.params;
    const data = params?.drugs;
    const location = selectGettr('location', 17);
    const shareFirstAidKit = () => {
        shareDrugsData(data, 'firstAidKit', 'titles.shareFirstAidKit', { location });
    };

    return {
        ...screenOptions,
        header: () => (
            <AppHeader right>
                {Platform.OS === 'web' ? null : <Icon.Share onPress={shareFirstAidKit} />}
                <FirstAidKitIcon />
            </AppHeader>
        ),
    };
};

export const optionsSearchMultipleDrugs = ({ route: { params: { drugs: data = [] } } }) => {
    const location = selectGettr('location', 17);
    const shareFirstAidKit = () => {
        const state = Store?.getState();
        const drugList = (state?.drugs?.searchList ?? []).filter(item => item?.selected);
        !!drugList?.length && shareDrugsData(drugList, 'firstAidKit', 'titles.shareFirstAidKit', { location });
    };

    return {
        ...screenOptions,
        header: () => (
            <AppHeader right>
                {Platform.OS === 'web' ? null : <Icon.Share onPress={shareFirstAidKit} />}
                <FirstAidKitIcon />
            </AppHeader>
        ),
    };
};
export const optionsShareDrugsWithApt = ({ route: { params: { drugs: data, item = [] } } }) => {
    const location = selectGettr('location', 17);
    const shareFirstAidKit = () => {
        shareDrugsData(data, 'apt', 'titles.shareDrugstore', {
            aptId: item.id,
            location,
        });
    };
    return {
        ...screenOptions,
        header: () => (
            <AppHeader right>
                {Platform.OS === 'web' ? null : <Icon.Share onPress={shareFirstAidKit} />}
                <FirstAidKitIcon />
            </AppHeader>
        ),
    };
};

export const optionsDoctorFeedbacks = ({
    route: {
        params: {
            doctorInfo: { id, _id },
            doctorInfo,
        },
    },
}) => ({
    ...screenOptions,
    header: () => (
        <AppHeader right>
            {Platform.OS === 'web' ? null : (
                <ShareIcon shareTitle="Share doctor" shareParams={{ docId: id ?? _id }} type="doctor" />
            )}
            <FavIcon item={doctorInfo} type="physicians" />
        </AppHeader>
    ),
});

export const optionsEditDoctorFeedbacks = ({ route: { params } }) => ({
    ...screenOptions,
    header: () => {
        const { doctorInfo } = params;
        const id = doctorInfo?.id;
        const [doctor, setDoctor] = useState(doctorInfo);
        const { feedback } = params;
        const forItemId = feedback?.forItemId;
        useEffect(() => {
            if (!id && forItemId) {
                (async () => {
                    const info = await firebase.getById('physicians', forItemId);
                    setDoctor(info && !info?.deleted ? info : null);
                })();
            }
        }, [id, forItemId]);
        return (
            <AppHeader right>
                {Platform.OS === 'web' ? null : (
                    <ShareIcon shareTitle="Share doctor" shareParams={{ docId: id ?? forItemId }} type="doctor" />
                )}
                <FavIcon item={doctor} type="physicians" />
            </AppHeader>
        );
    },
});

export const optionsDrugs = ({
    route: {
        params: { drug: data = {}, drugs: dataDrugs = {} },
        params,
    },
}) => {
    const location = selectGettr('location', 17);
    return {
        ...screenOptions,
        header: () => {
            let d = null;
            let a = {};
            let type = 'drug';
            if (Object.keys(data).length > 0) {
                d = [data];
            } else if (Object.keys(dataDrugs).length > 0) {
                d = dataDrugs;
                type = 'apt';
                if (params?.item?.id) {
                    a = { aptId: params?.item?.id };
                }
            }
            const shareFirstAidKit = () => {
                shareDrugsData(d, type, 'titles.shareDrug', a, { location });
            };
            return (
                <AppHeader right>
                    {Platform.OS === 'web' || !d ? null : <Icon.Share onPress={shareFirstAidKit} />}
                    <FirstAidKitIcon />
                </AppHeader>
            );
        },
    };
};

export const optionsVisit = ({
    route: {
        // params: { drug: data = {}, drugs: dataDrugs = {} } = {},
        params,
    },
}) => {
    return {
        ...screenOptions,
        header: () => {
            return (
                <AppHeader right>
                    {Platform.OS !== 'web' && (
                        <Icon.Share
                            onPress={() => {
                                shareVisit(params?.visitInfo);
                            }}
                        />
                    )}
                </AppHeader>
            );
        },
    };
};

export const optionsWebPage = ({ route: { params: { title } = {} }, navigation }) => ({
    ...screenOptions,
    header: () => <AppHeader title={title} backPress={() => navigation.goBack()} />,
});

export const optionsDoctorsFilter = ({ route: { params: { handlers: { goBackHandler = () => {} } = {} } = {} } }) => ({
    ...screenOptions,
    header: () => <AppHeader backPress={goBackHandler} />,
});

// noinspection JSUnusedGlobalSymbols
export const createNavigator = (routes, props = { screenOptions: screenOptionsBare }) => {
    const Stack = createStackNavigator();
    return () => (
        <Stack.Navigator {...props}>
            {Object.entries(routes).map(([name, component]) => (
                <Stack.Screen name={name} component={component} options={component.screenOptions} />
            ))}
        </Stack.Navigator>
    );
};

// TODO use https://github.com/react-native-community/hooks/blob/master/src/useBackHandler.ts
export function useBackHandler(handler = () => false) {
    const navigation = useNavigation();
    useEffect(() => {
        const hardwareBackPressListener = BackHandler?.addEventListener?.('hardwareBackPress', handler);
        const gestureEndListener = navigation?.addListener?.('gestureEnd', handler);
        return () => {
            // BackHandler.removeEventListener('hardwareBackPress', handler);
            hardwareBackPressListener?.remove?.();
            // navigation.removeListener('gestureEnd', handler);
            gestureEndListener?.remove && gestureEndListener.remove?.();
        };
    }, [handler]);
}
