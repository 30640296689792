import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    BigButton,
    Button,
    ButtonsGroup,
    Keyboard,
    Modal,
    Page,
    Platform,
    React,
    Text,
    TextInput,
    Title,
    TouchableWithoutFeedback,
    View,
} from '../../../common';
import { shareChildrens, shareMyFamily } from '../../../services/user.utils';
import { getUserInfo } from '../../../selectors';
import { COLORS } from '../../../styles/colors';
import { showPopupMessage } from '../../../common/Modal';
import { consoleLog } from '../../../utils/log';

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

const isKeyValid = (key = '') => passwordRegex.test(key);

export const ShareMyFamilyModal = ({ onShare = () => {}, onCancel = () => {}, isChild = false }) => {
    const [text, setText] = useState('');
    const [keyValid, setKeyValid] = useState(isKeyValid(text));
    const [inputError, setInputError] = useState('');

    useEffect(() => {
        setKeyValid(isKeyValid(text));
    }, [text]);

    return (
        <Modal hideClose>
            <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
                <View>
                    <Title.Bigger
                        id={isChild ? 'titles.shareChildTitle' : 'titles.shareMyFamilyTitle'}
                        style={{
                            marginTop: 8,
                            marginHorizontal: 12,
                            textAlign: 'center',
                            fontSize: 18,
                            fontFamily: 'SourceSansPro-Semibold',
                        }}
                    />
                    <View style={{ marginHorizontal: 10 }}>
                        <Title
                            id="titles.shareMyFamilyKey"
                            numberOfLines={null}
                            style={{
                                marginBottom: 20,
                                marginHorizontal: 2,
                            }}
                        />
                        <TextInput
                            placeholder="placeholders.shareFamilyKey"
                            style={{
                                marginTop: 12,
                                fontSize: 16,
                                color: '#555555',
                            }}
                            onChange={(value) => {
                                setText(value);
                            }}
                            invalid={!keyValid}
                            onBlur={() => setInputError(isKeyValid(text) ? '' : Object.R('titles.passwordError'))}
                            value={text}
                        />
                        {inputError && (
                            <View
                                style={{
                                    backgroundColor: '#F8E6E5',
                                    borderRadius: 4,
                                    marginTop: 14,
                                    marginBottom: 6,
                                    padding: 8,
                                }}>
                                <Text
                                    style={{
                                        fontFamily: 'SourceSansPro-Semibold',
                                        color: '#DF655C',
                                    }}>
                                    {inputError}
                                </Text>
                            </View>
                        )}
                        <ButtonsGroup style={{ paddingHorizontal: 0 }}>
                            <BigButton
                                title="buttons.cancel"
                                action={() => {
                                    onCancel();
                                    Page.closeModal();
                                }}
                                styles={{ backgroundColor: 'transparent' }}
                                titleStyles={{ color: '#565656' }}
                            />
                            <BigButton
                                title="buttons.shareMyFamilyShare"
                                disabledStyle={{ backgroundColor: '#B8BED8' }}
                                disabled={inputError || !isKeyValid(text)}
                                action={() => {
                                    onShare(text);
                                }}
                            />
                        </ButtonsGroup>
                    </View>
                </View>
            </TouchableWithoutFeedback>
        </Modal>
    );
};

export const ShareFamilyButton = () => {
    const user = useSelector(getUserInfo);
    const shareFamilyButtonHandler = () => {
        Page.showModal(
            <>
                <ShareMyFamilyModal
                    onShare={(key) => {
                        shareMyFamily(user?.hashKey, key, (d) => {
                            Platform.OS === 'web' &&
                                setTimeout(() => {
                                    showPopupMessage({
                                        title: Object.R('titles.myFamily'),
                                        secondTitle: false,
                                        message: d,
                                    });
                                }, 100);
                        });
                        Page.closeModal();
                    }}
                />
            </>,
        );
    };

    return (
        <>
            <Button
                action={shareFamilyButtonHandler}
                title={Object.R('titles.shareMyFamily')}
                styles={[
                    {
                        borderWidth: 1,
                        borderColor: COLORS.BORDER_GRAY,
                        backgroundColor: COLORS.POSITIVE,
                        color: COLORS.LIGHT_GRAY,
                        padding: 4,
                    },
                ]}
            />
        </>
    );
};

export const ShareChildrenButton = () => {
    const user = useSelector(getUserInfo);
    const shareChildrenButtonHandler = () => {
        Page.showModal(
            <>
                <ShareMyFamilyModal
                    onShare={(key) => {
                        shareChildrens(
                            user?.hashKey,
                            key,
                            (d) => {
                                consoleLog('Share', d);
                                Platform.OS === 'web' &&
                                    setTimeout(() => {
                                        showPopupMessage({
                                            title: Object.R('titles.myChildren'),
                                            secondTitle: false,
                                            message: d,
                                        });
                                    }, 100);
                            },
                            user,
                        );
                        Page.closeModal();
                    }}
                    isChild
                />
            </>,
        );
    };

    return (
        <>
            <Button
                action={shareChildrenButtonHandler}
                title={Object.R('titles.shareChildren')}
                styles={[
                    {
                        borderWidth: 1,
                        borderColor: COLORS.BORDER_GRAY,
                        backgroundColor: COLORS.POSITIVE,
                        color: COLORS.LIGHT_GRAY,
                        padding: 4,
                    },
                ]}
            />
        </>
    );
};
