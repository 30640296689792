import {
    Button, ButtonsGroup, Modal, React, Subtitle, VBox,
} from '../../common';

// noinspection JSUnusedGlobalSymbols
export const InvoiceReminderModal = () => (
    <Modal
        title="titles.unconfirmed_services"
    >
        <VBox>
            <Subtitle.Bigger id="titles.check_services" numberOfLines={null} centered/>
            <ButtonsGroup>
                <Button normal title="buttons.later" action={() => {
                }}/>
                <Button primary title="button.check_services" action={() => {
                }}/>
            </ButtonsGroup>
        </VBox>
    </Modal>
);
