import { createReducerFor } from './utils';

const type = 'SET_COLLECTION';

export const initialState = { data: {} };

// noinspection JSUnusedGlobalSymbols
export const syncCollection = (path, data) => async (dispatch) => {
    dispatch(({
        type,
        data,
        path,
    }));
};

export default createReducerFor(type, {});
