import React from 'react';
import NetInfo from '@react-native-community/netinfo';
import { urlParse, urlStringify } from '../utils';
import { MODE } from '../config';
import { ErrorManager } from '../utils/ErrorManager';
import firebase from './firebase';
import { storage } from './localstorage';
import { myApiUrlBase, publicApiUrlBase } from './api';

export const HEADERS_DEFAULT = { 'Cache-Control': 'no-store' };

// noinspection JSUnusedGlobalSymbols
export const HEADERS_FORM = {
    ...HEADERS_DEFAULT,
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
};
export const HEADERS_JSON = {
    ...HEADERS_DEFAULT,
    'Content-Type': 'application/json; charset=utf8',
};

const client = () => Object.fetchBlob.config({
    followRedirect: false,
    trusty: true,
    timeout: MODE === 'dev' || MODE === 'qa' ? 90000 : 150000, // 90 seconds timeout for dev and 15 seconds for prod
});

class HttpError {
    constructor(status = -1, message = 'titles.somethingWentWrong', code, body) {
        this.status = status;
        this.message = Object.R(message);
        this.code = code;
        this.body = body;
        ErrorManager.setError({
            message: this.message,
            statusCode: code,
            detail: body?.detail,
        });
    }

    toString() {
        return `HttpError: ${this.message} (${this.status})`;
    }
}

const ensure2xxStatus = (response) => {
    const { status, body: responseBody, code: responseCode } = response.info();
    const body = responseBody || response.data;
    if (`${status}`[0] !== '2') {
        let code;
        let errorBody;
        try {
            const jsonBody = body ? JSON.parse(body) : {};
            // eslint-disable-next-line prefer-destructuring
            code = jsonBody.code || responseCode;
            errorBody = jsonBody;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(`Error parsing response body JSON: ${error}`);
        }
        throw new HttpError(status, 'titles.somethingWentWrong', code, errorBody);
    }
    return response;
};

const callJsonMethod = (response) => {
    try {
        const { status } = response.info();
        return status === 204 ? {} : response.json();
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(`Wrong JSON: ${response.data}`);
        throw new HttpError();
    }
};

const successfulJson = promise => promise.then(ensure2xxStatus).then(callJsonMethod);

const fetch = async (method, url, headers = {}, body = null, responseType) => {
    let response;
    const parsedUrl = urlParse(url);
    const prettyTarget = (t) => {
        if (!t) {
            return '';
        }
        return t?.split?.(publicApiUrlBase)?.join?.('')?.split?.(myApiUrlBase)
            ?.join('');
    };
    const reqId = `${method}!${parsedUrl?.path?.length ? parsedUrl.path.join('/') : prettyTarget(parsedUrl?.target)}`;
    // const reqId = `${method}!${parsedUrl.path.join('/')}`;
    // eslint-disable-next-line no-console
    console.log(`%c>>>> ${reqId}`, 'color: #779977', {
        get url() {
            return parsedUrl;
        },
        get headers() {
            return headers;
        },
        get body() {
            return body;
        },
    });

    try {
        response = await client().fetch(method, urlStringify(url), headers, body, responseType);
        // eslint-disable-next-line no-console
        console.log(`%c<<<< ${reqId}`, 'color: #99FF99', {
            get url() {
                return parsedUrl;
            },
            get response() {
                return response;
            },
        });
        return response;
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log('e', e);
        // the only reason to exception is networking issues
        const isOnline = (await NetInfo.fetch()).isInternetReachable;
        return Promise.reject(new HttpError(-1, isOnline ? 'error.request_time_out' : 'error.network_connection'));
    }
};

const UNAUTHORIZED_STUB = {
    info: () => ({ status: 403 }),
    json: () => null,
};

const authRequestFactory =
    method => async (url, body, headers = body ? HEADERS_JSON : HEADERS_DEFAULT, responseType) => {
        const authenticated = await firebase.isAuthenticated();
        let token;
        try {
            token = await firebase.getToken();
        } catch (error) {
            const isOnline = (await NetInfo.fetch()).isInternetReachable;
            if (isOnline) {
                try {
                    Object.trackAction('get token error occurred');
                } catch (err) {
                    //
                }
                throw error;
            } else {
                Promise.reject(new HttpError(-1, 'error.network_connection'));
            }
        }
        const deviceId = await storage.get('deviceId');
        let requestBody = null;
        const requestHeaders = headers;
        if (body) {
            if (typeof body === 'string') {
                requestBody = body;
            } else {
                requestHeaders.Accept = 'application/json';
                requestBody = JSON.stringify(body);
            }
        }
        return !authenticated
            ? Promise.resolve(UNAUTHORIZED_STUB)
            : fetch(
                method,
                url,
                {
                    ...requestHeaders,
                    Authorization: `Bearer firebase@${deviceId}:${token}`,
                },
                requestBody,
                responseType,
            );
    };

export const get = authRequestFactory('GET');
export const del = authRequestFactory('DELETE');
export const put = authRequestFactory('PUT');
export const post = authRequestFactory('POST');
export const patch = authRequestFactory('PATCH');

[get, del, put, post, patch].map(fn => Object.assign(fn, { Json: (...args) => successfulJson(fn(...args)) }));

get.Json.Anonymously = url => successfulJson(fetch('GET', url));
get.Anonymously = url => fetch('GET', url);
get.AnonymouslyBin = url => fetch('GET', url, null, null, 'blob');
post.Json.Anonymously = (url, headers, body) => successfulJson(fetch('POST', url, headers, JSON.stringify(body)));
post.Anonymously = (url, headers, body) => fetch('POST', url, headers, JSON.stringify(body));
put.Anonymously = (url, headers, body) => fetch('PUT', url, headers, JSON.stringify(body));
put.Json.Anonymously = (url, headers, body) => successfulJson(fetch('PUT', url, headers, JSON.stringify(body)));

export const useIsOffine = () => {
    const [val, setOffline] = React.useState(false);
    React.useEffect(() => {
        const check = async () => {
            const { isConnected, isInternetReachable } = await NetInfo.fetch();
            setOffline(!(isConnected || isInternetReachable));
        };
        const unsubcr = NetInfo.addEventListener(() => {
            check();
        });
        setTimeout(check, 1000);
        return unsubcr;
    }, []);

    return val;
};

const getMessegeFromObj = (obj) => {
    if (obj) {
        const msgKey = `error.${obj.code}`;
        const localizedMessage = Object.R(`error.${obj?.code}`);
        return localizedMessage === msgKey ? obj?.detail || Object.R('titles.somethingWentWrong') : localizedMessage;
    }
    return Object.R('titles.somethingWentWrong');
};

export const getFinalMessage = (respData) => {
    try {
        if (typeof respData === 'string') {
            const jsonData = JSON.parse(respData);
            return getMessegeFromObj(jsonData);
        }
        return getMessegeFromObj(respData);
    } catch (e) {
        ErrorManager.setError(e);
        // eslint-disable-next-line no-console
        console.log('getFinalMessage: ', e);
    }
};

export const getErrorCodeIfNeeded = resp => (resp?.severity >= 3 ? resp.code : undefined);
