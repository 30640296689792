import { isAdult, parseToDdMmYyyy } from '../../../utils';

export const filterEmptyProfiles = (profiles = []) => {
    return profiles?.filter(({ name, fullNameRu, birthday }) => (name ?? fullNameRu) && birthday);
};

export const filterAdultChild = profiles => profiles?.filter(({ birthday, relKind }) => !(isAdult(birthday) && relKind === 'CHILD'));

export const filterWithoutManual = profiles => profiles?.filter(({ manual }) => !manual);

export const mapProfilesToRequestBookCapabilities = (profiles = []) => profiles?.map?.(({
    profileId, fullNameRu, sex, birthday, name, emails = [], phones = [],
}) => {
    return {
        _id: profileId,
        fullNameRu: name ?? fullNameRu,
        birthday,
        sex,
        emails: Array.isArray(emails) ? emails : [emails],
        phones: Array.isArray(phones) ? phones : [phones],
    };
});

export const mapAllowBookingProfiles = (profiles = []) => profiles?.map?.(p => ({ ...p, allowBooking: true }));

export const mapProfilesAfterRequest = profile => profile?.map?.(p => ({
    ...p,
    subName: p?.isMe ? Object.R('titles.for_me') : p?.subName,
    profileHint: p?.isMe ? Object.R('titles.for_me') : p?.profileHint,
    id: p?.profileId,
}));
export const getProfileHint = (profile) => {
    return profile?.isMe
        ? Object.R('titles.for_me')
        : `${
              profile?.manual
                  ? `${profile?.firstName} ${profile?.lastName}`
                  : [profile?.firstName ?? '', profile?.lastName ?? profile?.surname ?? ''].join(' ')
          }${profile?.birthday ? ` (${parseToDdMmYyyy(profile?.birthday)})` : ''}`;
};
