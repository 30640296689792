import actions from '../actions';
import {
    Modal, Page, React, Text, TouchableOpacity, View,
} from '../common';
import { modal } from '../styles/common';
import tracking from '../services/tracking';

export const callPhones = (phones, paramsForTrecking) => {
    if (phones.length > 1) {
        Page.showModal((
            <Modal title="titles.phone_numbers" containerStyles={modal.itemList}>
                {phones.map((phone, index) => (
                    <TouchableOpacity
                        accessibilityLabel={`phone_${index}`}
                        onPress={() => {
                            tracking.logEvent('ai_lnk_tel_registry_map', paramsForTrecking);
                            Page.closeModal(() => actions.dial(phone));
                        }}
                    >
                        <View style={[modal.modalButton, {
                            alignItems: 'center',
                            marginHorizontal: 0,
                            paddingLeft: 10,
                            paddingRight: 10,
                        }]}>
                            <Text>{phone}</Text>
                        </View>
                    </TouchableOpacity>
                ))
                }
            </Modal>
        ));
    } else {
        tracking.logEvent('ai_lnk_tel_registry_map', paramsForTrecking);
        actions.dial(phones[0]);
    }
    tracking.logEvent('ai_btn_call_phones_map', paramsForTrecking);
};
