import { tail, head } from '../../app/utils';

export const accessToken1 = 'Auto_EPM-AIMD_Test1';
export const personId1 = '5b91825acff47e000c661343';
export const profileWithEpam = accessToken1;

export const accessToken2 = 'Auto_EPM-AIMD_Test2';
export const personId2 = '5b91825acff47e000c661344';
export const profileAny = accessToken2;

export const accessToken3 = 'Auto_EPM-AIMD_Test3';
export const personId3 = '5b91825acff47e000c661345';
export const profileSimple = accessToken3;

export const accessToken4 = 'Auto_EPM-AIMD_Test4';
export const personId4 = '5b91825acff47e000c661346';
export const profileWithBns = accessToken4;

export const accessToken5 = 'Auto_EPM-AIMD_Test5';
export const personId5 = '5b91825acff47e000c661347';
export const profileEmpty = accessToken5;

export const accessToken6 = 'Auto_EPM-AIMD_Test6';
export const personId6 = '5b91825acff47e000c661348';
export const profileUnderage = accessToken6;

export const accessTokenPassword = 'Epampass@1';

export const firebaseAnonymousToken = '5b91825acff47e000c661342';

export const USER_PHONE_BY_PROFILE = {
    [profileWithEpam]: '+375:335553636:111111',
    [profileAny]: '+375:335553737:111111',
    [profileSimple]: '+375:335553738:111111',
    [profileWithBns]: '+375:335553739:111111',
    [profileEmpty]: '+375:335553740:111111',
    [profileUnderage]: '+375:335553741:111111',
};

export const PERSON_BY_PROFILE = {
    [profileWithEpam]: personId1,
    [profileAny]: personId2,
    [profileSimple]: personId3,
    [profileWithBns]: personId4,
    [profileEmpty]: personId5,
    [profileUnderage]: personId6,
};

export const PROVIDER_CREDS_BY_PROFILE = {
    [profileWithEpam]: `${accessToken1}@epam.com:${accessTokenPassword}`,
    [profileAny]: `${accessToken2}@epam.com:${accessTokenPassword}`,
    [profileSimple]: `${accessToken3}@epam.com:${accessTokenPassword}`,
    [profileWithBns]: `${accessToken4}@epam.com:${accessTokenPassword}`,
    [profileEmpty]: `${accessToken5}@epam.com:${accessTokenPassword}`,
    [profileUnderage]: `${accessToken6}@epam.com:${accessTokenPassword}`,
};

export const familyCode = 111111;
export const lastModified = Date.now();
export const now = Date.now();
export const tomorrowDate = new Date(now + (24 * 60 * 60 * 1000));
export const todayISOText = new Date(now).toISOString();
export const todayDateText = Number(tail(head(todayISOText, 'T'), '-')).toString();
export const today = head(todayISOText, 'T');
export const tomorrowISOText = new Date(now + (24 * 60 * 60 * 1000)).toISOString();
export const afterTomorrowISOText = new Date(now + (48 * 60 * 60 * 1000)).toISOString();
export const tomorrowDateText = Number(tail(head(tomorrowISOText, 'T'), '-')).toString();
export const afterTomorrowDateText = Number(tail(head(afterTomorrowISOText, 'T'), '-')).toString();
export const tomorrow = head(tomorrowISOText, 'T');
export const afterTomorrow = head(afterTomorrowISOText, 'T');
export const yesterday = new Date(now - (24 * 60 * 60 * 1000));
export const yesterdayISOText = yesterday.toISOString();
export const yesterdayDateText = Number(tail(head(yesterdayISOText, 'T'), '-')).toString();
export const photoUrl = 'https://facebook.github.io/react-native/docs/assets/favicon.png';

export const AIBOLIT4_ID = '5b51794c7abf0bb3b';
export const KEDIQ_ID = '5b3f5fa83884cda40';

export const DOCTOR_WHO_HAS_VISITS = 'Айболит4';

export const DEFAULT_DOCTOR = 'Айболит4';
export const DEFAULT_TODAY_VISIT_DATE = 'Сегодня - 23:59';
export const DEFAULT_TOMMOROW_VISIT_DATE = 'Завтра - 19:20';
export const HISTORY_VISIT_TIME_TITLE = '16 Октября в 19:20';
