import EStyleSheet from 'react-native-extended-stylesheet';
import { decor, fontSize, padding } from './atomic';

const headLine = {
    color: '$darkGrayTextColor',
    fontFamily: '$sourceSansPropFontFamily',
    fontSize: 16,
};

const badge = {
    position: 'absolute',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '$red',
    borderRadius: 100,
    borderColor: '$white',
    borderWidth: 1,
    flex: 1,
};

export const panelStyle = {
    marginBottom: 4,
    paddingHorizontal: 12,
    paddingVertical: 12,
    backgroundColor: '$white',
    borderWidth: 1,
    borderRadius: 3,
    borderColor: '#e0e0e0',
    borderTopColor: '#e0e0e0',
    borderLeftColor: '#e0e0e0',
    borderBottomColor: '#e0e0e0',
    borderRightColor: '#e0e0e0',
};

export const elementsStyles = EStyleSheet.create({
    padding,
    spinnerWeb: {
        top: 0,
        display: 'flex',
        flex: 1000,
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
    },
    spinner: {
        position: 'absolute',
        top: -70,
        left: 0,
        width: '$deviceWidth',
        height: '$deviceHeight',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
    },
    input: {
        minHeight: 60,
        backgroundColor: '$white',
        paddingLeft: 0,
        fontSize: 14,
    },
    section: {
        marginTop: 8,
        marginBottom: 8,
        paddingHorizontal: 12,
    },
    children: {
        paddingHorizontal: 12,
        backgroundColor: 'white',
    },
    sectionHeader: {
        height: 32,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    sectionHeaderText: {
        paddingLeft: 12,
        fontSize,
        fontFamily: '$sourceSansPropFontFamily_semibold',
        color: '#787878',
    },
    text: { color: '#ACACAC' },
    text_bold: { fontFamily: '$sourceSansPropFontFamily_semibold' },
    text_color: { color: '$emerald' },
    text_strikethrough: decor.strikethrough,
    headLine,
    headLine_bigger: {
        ...headLine,
        fontSize: 18,
    },
    headLine_bold: {
        ...headLine,
        fontFamily: '$sourceSansPropFontFamily_semibold',
    },
    headLine_wightNormal: {
        fontFamily: '$sourceSansPropFontFamily',
        fontWeight: 'normal',
    },
    headLine_smaller: {
        fontFamily: '$sourceSansPropFontFamily',
        fontSize: 14,
        fontWeight: 'normal',
    },
    subtitle: {
        color: '$headerScreenTitleColor',
        fontSize,
        fontWeight: 'normal',
    },
    subtitle_bigger: {
        color: '$headerScreenTitleColor',
        fontFamily: '$sourceSansPropFontFamily',
        fontSize: 14,
    },
    headLine_alignLeft: { textAlign: 'left' },
    headLine_alignCenter: { textAlign: 'center' },
    headLine_marginReset: { marginBottom: 0 },
    panel: panelStyle,
    badge: {
        ...badge,
        width: 15,
        height: 15,
    },
    badge_notification: {
        ...badge,
        width: 8,
        height: 8,
        right: '-10%',
    },
    badgeText: {
        fontSize: 10,
        height: 15,
        lineHeight: 13,
        textAlign: 'center',
        textAlignVertical: 'center',
        color: 'white',
    },
    badgePendings: {
        ...badge,
        width: 15,
        height: 15,
        backgroundColor: '#BC2828',
        borderColor: '#BC2828',
    },
    badgePendingsText: {
        fontSize: 12,
        fontFamily: 'SourceSansPro-Semibold',
        height: 16,
        lineHeight: 16.5,
        textAlign: 'center',
        textAlignVertical: 'center',
        color: 'white',
    },
});
