import { useKeyboardHeight } from './Modal';
import { Platform, React, View } from './react';

export const IosKeyboardAvoidingView = ({
    children, addHeight = 80, allPlatforms = false, enabled = true,
}) => {

    const keyboardHeight = useKeyboardHeight(['ios', 'android']);
    const height = (keyboardHeight ?? 0) + addHeight;
    return (Platform.OS === 'ios' || allPlatforms) && enabled ? (
        <>
            {children}
            <View style={{ height }} />
        </>
    ) : children;

};
