import { useNavigation } from '@react-navigation/native';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Page, Platform, React, View,
} from '../../../common';
import { SwipeBack } from '../../../common/SwipeBack';
import { useSpecialitiesFullList } from '../../../hooks/useSpecialitiesFullList';
import { useIsOffine } from '../../../services/http';
import { fetchMyFeedbacksPhysicians } from '../../../actions/feedbacks';
import {
    getIsAuthenticated, getIsDoctorsLoading, getMyFeedbacksLoading, getMyFeedbacksPhysicians, isNeedToFetchMyPhysicians,
} from '../../../selectors';
import { StubMyFeedbacksNotAuthorized } from './StubMyFeedbacksNotAuthorized';
import { AllFeedbacksHeader, useAllFeedbacksPage } from './AllFeedbacksHeader';
import { MyFeedbacksList } from './MyFeedbacksList';

export const SearchFeedback = () => {

    return (
        <View style={{ flex: 1 }}>
            <AllFeedbacksHeader />
        </View>
    );

};

export const AllFeedbacks = Page.register(() => {

    const isAuthorized = useSelector(getIsAuthenticated);
    const isOffline = useIsOffine();
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const isDocLoading = useSelector(getIsDoctorsLoading);
    const feedbacksLoading = useSelector(getMyFeedbacksLoading);
    const dispatch = useDispatch();
    const physicians = useSelector(getMyFeedbacksPhysicians);
    const { pages, activePage } = useAllFeedbacksPage();
    const navigation = useNavigation();
    const needFetch = useSelector(isNeedToFetchMyPhysicians);

    useSpecialitiesFullList();

    useEffect(() => {

        if (isAuthorized && needFetch) {

            setIsLoading(true);
            dispatch(fetchMyFeedbacksPhysicians()).finally(() => setIsLoading(false));

        }

    }, [isAuthorized, needFetch]);
    if (!isAuthorized) {

        return <Page><StubMyFeedbacksNotAuthorized /></Page>;

    }

    const onSwipeBackHandler = () => {

        navigation.navigate('Home');

    };
    const isLoad = isLoading || feedbacksLoading || isDocLoading;
    return (
        <Page
            name="all-feedbacks"
            backgroundColor="#f2f2f2"
            notification={isOffline ? Object.R('error.network_connection') : error}
            hideOnBackground
            noscroll={Platform.OS !== 'web'}
            isLoading={isLoad}
        >
            {!isLoad && <SwipeBack onSwipeBack={onSwipeBackHandler} forOS={['ios']}>
                <View style={{ flex: 1 }}>
                    {activePage === 'my' ? <MyFeedbacksList data={physicians?.items ?? []} /> : <SearchFeedback />}
                </View>
            </SwipeBack>}
        </Page>
    );

});
