import { MODE } from '../config';

export const consoleLog = (...params) => {
    if (MODE !== 'qa') {
        return;
    }
    // eslint-disable-next-line no-console
    console.log(
        `%c${params?.[0]}`,
        'color: aqua; background: black; font-size: 14px; font-weight: bold; border: 1px solid aqua; text-align: center; padding: 3px 15px;',
        ...(params?.slice?.(1) ?? []),
    );
};
