import {
    arrayToHash, dig, someEmpty, sortBy,
} from '../utils';
import { SCHEME } from '../scheme';
import { createSelector, dbGettr, gettr } from './utils';
// import { joinedClinics } from './medicians';
import { getCurrentOrFirstProgram, getRelatedPrograms } from './programs';
import { EMPTY_OBJECT } from './const';
import { getGlobalLocation } from './getters';
import { getClinicsIdsForSelectedProgram, joinedClinics } from './medicians';
import { getIsOnline } from '.';

const optionsAdapter = (types = {}) => {

    const hash = {};
    Object.values(types).forEach(({ groups, hidden: isOptionTypeHidden }) => Object.values(groups || {}).forEach(({ items, name: groupName, hidden: isOptionGroupHidden }) => Object.entries(items || {}).forEach(([id, itemData]) => {

        const hidden = isOptionTypeHidden || isOptionGroupHidden || itemData.hidden;
        hash[id] = {
            ...itemData,
            id,
            groupName,
            hidden,
        };

    })));
    return hash;

};

/* eslint-disable max-params */

export const umsServicesCatalog = createSelector(
    [gettr(`ums.info.catalog`), dbGettr('clinics.data')],
    (catalog, clinics) => {

        if (someEmpty(catalog)) {

            return null;

        }
        const optionsHash = optionsAdapter(catalog.options);
        const services = new Map();
        const errors = [];
        Object.entries(catalog.items || []).forEach(([typeId, { groups, name: typeName, hidden: isHiddenType }]) => {

            Object.entries(groups).forEach(([groupId, { items = {}, name: groupName, hidden: isHiddenGroup }]) => {

                Object.entries(items).forEach(([id, service]) => {

                    const name = service.name.replace(/[*]{3}$/, '');
                    const hidden = service.hidden || isHiddenType || isHiddenGroup;
                    const options = service.options
                        ? service.options.map(optionId => optionsHash[optionId]).filter(Boolean)
                        : [];
                    services.set(id, {
                        id,
                        name,
                        type: {
                            id: typeId,
                            name: typeName,
                        },
                        group: {
                            id: groupId,
                            name: groupName,
                        },
                        options,
                        clinics: {},
                        programs: {},
                        hidden,
                        onlineBooking: service.onlineBooking,
                    });

                });

            });

        });
        if (clinics) {

            catalog.mappings.forEach(({ providerId, itemIds }) => {

                const clinic = clinics[providerId];
                if (!clinic) {

                    errors.push(`non-existing ums clinic ${providerId}`);
                    return;

                }
                itemIds.forEach((id) => {

                    const srvc = services.get(id);
                    if (!srvc) {

                        errors.push(`non-existing ums service ${id}`);
                        return;

                    }
                    srvc.clinics[providerId] = clinic;

                });

            });

        }
        if (errors.length) {

            // eslint-disable-next-line no-console
            console.log('%cumsServicesCatalog errors', 'color: #FF9999', errors);

        }
        return services;

    },
);

export const getCurrentUserProgram = createSelector(
    [gettr('ums.info.programs'), gettr('prefs.data.program')],
    (programs, current) => {

        try {

            return programs[current.id];

        } catch {

            return false;

        }

    },
);

export const umsServicesWithPrograms = createSelector(
    [umsServicesCatalog, gettr(`ums.info.coverage`), getRelatedPrograms, dbGettr('ums.filter')],
    (services, coverages, relatedPrograms, filter) => {

        // ensure idempotency:
        if (someEmpty(services, coverages, relatedPrograms)) {

            return services;

        }
        const errors = [];
        services.forEach(srvc => Object.assign(srvc, {
            coverer: false,
            programs: {},
        }));
        Object.entries(coverages).forEach(([programId, providersOfProgram]) => {

            const program = relatedPrograms[programId];
            if (program) {

                Object.entries(providersOfProgram).forEach(([providerId, servicesOfProvider]) => {

                    if (filter?.medcenters?.length && !filter.medcenters.includes(providerId)) {
                        //
                    } else {

                        Object.entries(servicesOfProvider).forEach(([id, { coverage, attributes }]) => {

                            const srvc = services.get(id);
                            if (!srvc) {

                                errors.push(`coverage for non-existing service ${id}`);
                                return;

                            }
                            const serviceProgram =
                                srvc.programs[programId] ||
                                (srvc.programs[programId] = {
                                    id: programId,
                                    name: program.name,
                                    coverage: {},
                                    coverer: program.coverer,
                                    attributes: {},
                                });
                            serviceProgram.coverage[providerId] = coverage;
                            serviceProgram.attributes[providerId] = attributes;
                            srvc.coverer = program.coverer;

                        });

                    }

                });

            }

        });
        if (errors.length) {

            // eslint-disable-next-line no-console
            console.log('umsServicesWithPrograms errors', errors);

        }
        return services;

    },
);

export const umsLocalServicesTree = createSelector(
    [
        umsServicesWithPrograms,
        getGlobalLocation,
        dbGettr('ums.filter'),
        getCurrentOrFirstProgram,
        gettr('ums.info.coverage'),
        getIsOnline,
    ],
    (services, locationCode, filter = {}, program = {}, coverage = {}, isOnlineFilter = false) => {

        if (someEmpty(services, locationCode)) {

            return EMPTY_OBJECT;

        }

        const tree = {};
        const getOptionCoverage = (id, optionId) => {

            const cover = new Set();

            Object.entries(coverage).forEach(([programId, prog]) => Object.values(prog).forEach((clinic) => {

                if (clinic[id] && clinic[id].optionCoverage) {

                    clinic[id].optionCoverage.forEach((optionCover) => {

                        if (optionCover[optionId]) {

                            cover.add(programId);

                        }

                    });

                }

            }));
            return Array.from(cover);

        };
        services.forEach((service) => {

            if (service.hidden || (isOnlineFilter && !service.onlineBooking)) {

                return;

            }
            const localClinics = Object.values(service.clinics).filter(c => c.areaCode === locationCode);
            if (
                !localClinics.length ||
                (filter.withPrograms &&
                    (!service.programs[program.id] ||
                        (filter.medcenters?.length &&
                            !filter.medcenters?.some(i => service.programs[program.id].coverage?.[i])))) ||
                (filter.medcenters?.length &&
                    !localClinics.filter(({ id }) => filter.medcenters.some(e => e === id)).length)
            ) {

                return;

            }

            const type =
                tree[service.type.id] ||
                (tree[service.type.id] = {
                    ...service.type,
                    sub: {},
                    programs: {},
                });
            const group =
                type.sub[service.group.id] ||
                (type.sub[service.group.id] = {
                    ...service.group,
                    sub: {},
                    programs: {},
                });
            const options = service.options
                .filter(({ hidden }) => !hidden)
                .map(item => ({
                    ...item,
                    coverage: getOptionCoverage(service.id, item.id),
                }));
            group.sub[service.id] = {
                ...service,
                clinics: arrayToHash(localClinics),
                options,
            };
            // sync programs for types and groups
            if (service.programs) {

                Object.entries(service.programs).forEach(([programId, programDetails]) => {

                    const programType = Object.values(programDetails.attributes).some((e = {}) => e.LIMIT) ? 1 : 2;
                    if (!type.programs[programId] || type.programs[programId] === 2) {

                        type.programs[programId] = programType;

                    }
                    if (!group.programs[programId] || group.programs[programId] === 2) {

                        group.programs[programId] = programType;

                    }

                });

            }
            // sync coverer too
            if (service.coverer) {

                type.coverer = service.coverer;
                group.coverer = service.coverer;

            }

        });
        return tree;

    },
);

// noinspection JSUnusedGlobalSymbols
export const hasServices = createSelector(
    [umsServicesCatalog, getGlobalLocation],
    (services, locationCode) => services &&
        Array.from(services.values()).some(({ clinics = {} }) => Object.values(clinics).some(el => el.areaCode === locationCode)),
);

export const medcentersSort = createSelector([gettr(`db.medservices.medcentersSortBy`)], sortType => sortType);
export const coveredMedcentersSort = createSelector(
    [gettr(`db.medservices.medcentersCoveredSortBy`)],
    sortType => sortType,
);

export const selectServicesForClinic = createSelector(
    umsServicesWithPrograms,
    getRelatedPrograms,
    getCurrentOrFirstProgram,
    (_, clinicId) => clinicId,
    (services, relatedPrograms, currentProgram = { id: '*' }, clinicId) => {

        const servicesForClinic = services ? [...services.values()].filter(s => s.clinics[clinicId]) : [];
        const sortedServices = sortBy(servicesForClinic);
        const covererForService = (service) => {

            const program = Object.values(service.programs).find(
                p => p.coverage[clinicId] && p.id === currentProgram.id,
            );
            return program ? dig(relatedPrograms, `${program.id}.coverer`) : null;

        };

        return sortedServices.map(e => ({
            ...e,
            coverer: currentProgram.id === '*' ? null : covererForService(e, clinicId),
            limited: Object.values((e.programs[currentProgram.id] || {}).attributes || {}).some(el => el),
        }));

    },
);

export const selectMedCenterDetails = createSelector(
    joinedClinics,
    umsServicesWithPrograms,
    (_, clinicId) => clinicId,
    (clinics, _services, clinicId) => {

        const clinic = clinics[clinicId] || {};
        const services = _services ? [..._services.values()].filter(s => s.clinics[clinicId]) : [];
        return {
            clinic,
            hasServices: services.length > 0,
            isLoading: !Object.values(clinics).length,
        };

    },
);

export const selectMedCenterMapDetails = createSelector(
    joinedClinics,
    (_, clinicId) => clinicId,
    (clinics, clinicId) => {

        if (clinicId) {

            const clinic = clinics[clinicId];
            return Object.assign(clinic, {
                latitude: +clinic.latitude,
                longitude: +clinic.longitude,
                onlineConnectivity: clinic.onlineConnectivity ? 1 : null,
                message: clinic.onlineConnectivity ? Object.R('titles.onlineConnectivityMessage') : '',
            });

        }
        return null;

    },
);

export const getUmsPrefilterMedcenters = createSelector(
    dbGettr('ums.prefilter.medcenters', []),
    getClinicsIdsForSelectedProgram,
    getCurrentOrFirstProgram,
    gettr(`ums.info.coverage`),
    (clinics, programsBranches, currentProgram, servicesCoverage) => clinics
        .filter(e => !e.hidden)
        .map(e => ({
            ...e,
            coverer: programsBranches?.[e.id] ? currentProgram.coverer : null,
            get limited() {

                return Object.values(servicesCoverage?.[currentProgram?.id]?.[e.id] || {}).some(
                    el => el.attributes,
                );

            },
        })),
);

export const getUmsActiveFilters = createSelector(
    [dbGettr('ums.filter', {}), getIsOnline],
    ({ medcenters = [], withPrograms = false }, withOnline) => [medcenters.length ? 1 : 0, withPrograms, withOnline].reduce((acc, next) => (next ? 1 + acc : acc), 0),
);

export const getHasServices = createSelector(
    umsServicesWithPrograms,
    (_, clinicId) => clinicId,
    (_services, clinicId) => [...(_services?.values?.() ?? [])].some(s => s.clinics[clinicId]),
);

export const getMedcentersListWithPrograms = createSelector(
    [dbGettr('clinics.data'), gettr('ums.info.programs'), gettr('prefs.data.fullFavorites'), getGlobalLocation],
    (clinics, programs, favoritesList, location) => {

        if (!clinics) {

            return [];

        }
        const favorites = favoritesList ?? [];
        const result = [];
        Object.entries(clinics).forEach(([id, clinic]) => {

            const coverage = [];
            if (clinic?.areaCode !== location || clinic?.hidden) {

                return;

            }
            if (programs) {

                Object.values(programs).forEach((program) => {

                    if ((program?.branches ?? []).includes(id)) {

                        coverage.push(program);

                    }

                });

            }
            result.push({
                ...clinic,
                coverage,
                isFavorite: favorites.some(favoriteItem => favoriteItem?.id === id),
            });

        });
        return result;

    },
);

export const getUmsPrefilterWithOnline = createSelector(
    dbGettr(SCHEME.UMS_PREFILTER_WITH_ONLINE),
    getIsOnline,
    (prefilterOnline, filterOnline) => (prefilterOnline !== undefined ? prefilterOnline : filterOnline),
);

export const getClinicCityIfDifferentFromCurrent = createSelector(
    dbGettr('clinics.data'),
    getGlobalLocation,
    (_, clinicId) => clinicId,
    (clinics, location, clinicId) => {

        if (clinicId) {

            const clinic = clinics[clinicId];
            if (clinic && `${location}` !== `${clinic?.areaCode}`) {

                return clinic?.areaCode;

            }

        }

    },
);
