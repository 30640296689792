import { createSelector, gettr } from './utils';


export const getServicesData = createSelector([gettr('services')], (f => f));

export const getServicesList = createSelector([getServicesData], (data) => {
    return data?.servicesList ?? {};
});

export const getServicesIsLoading = createSelector([getServicesData], data => data?.isLoading);

export const getSelectedService = createSelector([getServicesData], data => data?.selectedService);

export const getServiceByAssignmentId = createSelector(
    getServicesData,
    (_, assignmentId, serviceId) => ({
        assignmentId,
        serviceId,
    }),
    (data, servData) => {
        return data?.servicesList[servData.assignmentId]?.find(e => e.id === servData.serviceId);
    },
);

export const getDoctorsDescriptions = createSelector([getServicesData], (data) => {
    return data?.doctorsDescriptions ?? {};
});
