/* eslint-disable no-underscore-dangle */
import { useFocusEffect, useNavigation, useNavigationState } from '@react-navigation/native';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
    Avatar, Box, Icon, Panel, Platform, React, Subtitle, Text, Title, VBox, View,
} from '../../../common';
import { COLORS } from '../../../common/style';
import { dbGettr } from '../../../selectors/utils';
import { SCHEME } from '../../../scheme';
import { SubtitleHighlight, TitleHighlight } from '../../../common/elements';
import actions from '../../../actions';
import { getFormValues } from '../../../selectors/forms';
import { getServicesIds } from '../../UMS/utils';
import { ViewHighlight } from '../../../common/ViewHighlight';
import tracking from '../../../services/tracking';
import { getRouteHistory } from '../../../utils/getRouteHistory';

const getMin = prices => Math.min(...prices.filter(e => e !== undefined));
const getMax = prices => Math.max(...prices.filter(e => e !== undefined));

const debug = false;

export const pricesIncludeNaN = (prices) => {
    if (!Array.isArray(prices)) {
        return true;
    }
    if (prices.filter(e => e !== undefined).length < 1) {
        return true;
    }
    const max = getMax(prices);
    const min = getMin(prices);
    return Number.isNaN(max) || Number.isNaN(min);
};

const getPriceString = (prices) => {
    const max = getMax(prices);
    const min = getMin(prices);
    return max === min
        ? Object.R('titles.fixedPrice', { value: max })
        : Object.R('titles.priceFromTo', {
            firstValue: min,
            secondValue: max,
        });
};

export const MedCentersListItem = React.memo(
    ({
        mdInfo, hideCommentsCount, info, noHighlighter = false, byOnlineBooking = false, from,
    }) => {
        const {
            photoUrl,
            notification,
            connectivityStatus,
            feedbackCount = 0,
            name,
            address,
            workTime,
            coverer,
            showPrices,
            prices = [],
            limited,
            bumbaReportsSupport,
            bumbaTestsSupport,
            onlineBooking,
        } = mdInfo;
        const navigation = useNavigation();
        const navState = useNavigationState(s => s);
        const { umsClinic, umsService } = useSelector(getFormValues);
        const onPress = useCallback(() => {
            actions.setFormValue('umsClinic', mdInfo);
            if (info) {
                const mappingSpecItem = info?.mapping?.filter?.(i => i?.specItemId === umsService?.id);
                const mappingProvider = mappingSpecItem?.filter?.(
                    i => i?.providerId === mdInfo?.id || i?.branchIds?.includes?.(mdInfo?.id),
                );
                const mapping = [...mappingProvider];
                const items = info?.items
                    ?.filter?.(item => mapping.find(i => i?.priceItemId === item?._id && i?.priceListId === item?.priceListId))
                    .filter(({ ppiServiceId }) => ppiServiceId);
                actions.setFormValue('umsServices', items);
                if (
                    info?.items?.some(
                        ({ onlineBooking, branchIds }) => onlineBooking && branchIds?.some(e => e === mdInfo?.id),
                    )
                ) {
                    const serviceIds = getServicesIds(info?.items, mdInfo?.id);
                    // actions.setCurrentServiceMcInfo({
                    //     serviceName: info?.serviceName,
                    //     clinicId: mdInfo?.id,
                    //     clinicName: mdInfo?.name,
                    //     clinicAddres: mdInfo?.address,
                    // });
                    navigation.navigate('UMSDoctorsList', {
                        serviceIds,
                        clinicId: mdInfo?.id,
                        serviceName: info?.serviceName,
                        clinicName: mdInfo?.name,
                        clinicAddres: mdInfo?.address,
                        prices: mdInfo?.showPrices ? prices : undefined,
                    });
                    tracking.logEvent(`ums_clinic_serv_online`);
                    return;
                }
            }
            navigation.navigate('MedCenterDetails', {
                mdInfo,
                byOnlineBooking,
                from,
            });
            tracking.logEvent(`ums_clinic_${mdInfo?.onlineConnectivity ? 'online' : 'offline'}`);
        }, [mdInfo]);
        const onPressFeedbacks = useCallback(() => {
            Object.trackAction(
                `clinicList_feedback`,
                {
                    journey: getRouteHistory(navState),
                    clinic_id: mdInfo?.id,
                },
                'ai_icon',
            );
            navigation.navigate('MedCenterFeedbacks', { mdInfo });
        }, [mdInfo]);
        const search = noHighlighter ? '' : useSelector(dbGettr(SCHEME.MEDCENTERS_SEARCH));
        useFocusEffect(() => {
            if (umsClinic) {
                actions.setFormValue('umsClinic', null);
            }
        });
        const borderColor = COLORS[`${connectivityStatus}`.toUpperCase()] || '#0000';
        const onlineColor = COLORS[`${onlineBooking ? 'ONLINE' : 'UNAVAILABLE'}`] || '#0000';

        const getBorder = useCallback(() => {
            if (info) {
                const branchItems = info.items.filter(
                    ({ branchIds, onlineBooking: a }) => branchIds?.includes?.(mdInfo?.id) && a,
                );
                if (branchItems?.length) {
                    return borderColor;
                }
                return COLORS.UNAVAILABLE;
            }
            if (byOnlineBooking) {
                return onlineColor;
            }
            return borderColor;
        }, [onlineBooking, byOnlineBooking, borderColor]);

        return (
            <Panel
                onPress={onPress}
                style={[
                    {
                        borderRightWidth: 5,
                        borderColor: null,
                        marginHorizontal: 12,
                        borderRightColor: getBorder(),
                    },
                ]}>
                <Avatar coverer={coverer} of="clinics" imageUrl={photoUrl} isLimited={limited} />
                <VBox flex gap={0.25} style={{ marginLeft: 18 }}>
                    {debug ? (
                        <Text>
                            D: [{mdInfo?.branchDoctorsCounter ?? '-'}/{mdInfo?.doctorsCounter ?? '-'}]
                        </Text>
                    ) : null}
                    <TitleHighlight bold keyword={search}>
                        {name}
                    </TitleHighlight>
                    {bumbaReportsSupport || bumbaTestsSupport ? (
                        <ViewHighlight
                            style={{
                                flexDirection: 'row',
                                borderRadius: 3,
                                borderColor: 'transparent',
                            }}
                            highlightStyle={{
                                flexDirection: 'row',
                                backgroundColor: COLORS.HIGHLIGHT_BACKGROUND,
                                borderRadius: 3,
                            }}
                            highlightId="panel">
                            <Icon.Attach
                                size={11}
                                style={{
                                    marginTop: Platform.OS === 'web' ? 5 : 2,
                                    marginRight: 4,
                                }}
                            />
                            <SubtitleHighlight
                                style={{
                                    fontSize: 14,
                                    color: COLORS.BUMBA_LINK,
                                }}
                                containerStyle={{ flex: 1 }}
                                keyword={search}>
                                {address}
                            </SubtitleHighlight>
                        </ViewHighlight>
                    ) : (
                        <SubtitleHighlight style={{ fontSize: 14 }} keyword={search}>
                            {address}
                        </SubtitleHighlight>
                    )}
                    {showPrices && prices.length && !pricesIncludeNaN(prices) ? (
                        <Title
                            bold
                            numberOfLines={0}
                            style={{
                                marginTop: 4,
                                color: COLORS.BUMBA_LINK,
                                fontSize: 14,
                            }}>{`${Object.R('titles.price')} ${getPriceString(prices)}`}</Title>
                    ) : null}
                    {!notification ? null : <Subtitle.Warning>{notification.message}</Subtitle.Warning>}
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingTop: 8,
                        }}>
                        <View
                            style={{
                                flex: 1,
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}>
                            <Icon.Clock />
                            <Subtitle
                                style={{
                                    flex: 1,
                                    marginLeft: 5,
                                }}>
                                {workTime}
                            </Subtitle>
                        </View>
                        {hideCommentsCount ? null : (
                            <Box flex centered gap={5} onPress={onPressFeedbacks}>
                                <Icon.CommentDoctor color={COLORS.MAIN} />
                                <Subtitle style={{ flex: 1 }} id="feedbacks" count={feedbackCount} />
                            </Box>
                        )}
                    </View>
                </VBox>
            </Panel>
        );
    },
);
