import { useEffect, useState } from 'react';
import {
    BigButton, dataFrom, Icon, List, Page, React, Section, Stub, View,
} from '../../common';
import { SimpleCheckbox } from '../../common/Selector';
import { getBasket } from '../../selectors';
import { storage } from '../../services/localstorage';
import tracking from '../../services/tracking';
import actions from '../../actions';
import { FirstAidKitSimpleBanner, renderSelectableDrugItem } from './snippets';
import { firstAidKitstyles } from './style';

/**
 * FirstAidKit page.
 */

const PRICES_IN_FIRST_AID_KIT = false;
export const FirstAidKit = Page.register(
    dataFrom(getBasket, ({ data = [], navigation }) => {
        const [dataWithSelected, setDataWithSelected] = useState(data);
        const isEmpty = !data?.length;
        useEffect(() => {
            (async () => {
                const basketWithSelected = await storage.get('basketWithSelected');
                const newData = data.map((e) => {
                    const found = basketWithSelected?.find?.(d => d?.id === e?.id);
                    return {
                        ...e,
                        selected: found ? found.selected : e?.selected ?? false,
                    };
                });
                setDataWithSelected(newData);
            })();
        }, [data]);
        const searchDrugstoresHandler = buttonName => () => {
            tracking.logEvent(`buttonFindAllPositions_${buttonName}`);
            navigation.navigate('SearchDrugstores', {
                from: 'searchFirstAidKit',
                drugs: dataWithSelected
                    .filter(f => f.selected)
                    .map(item => ({
                        ...item,
                        apt_cnt: '5000',
                    })),
                totalCount: dataWithSelected
                    .filter(f => f.selected)
                    .map(item => ({
                        ...item,
                        apt_cnt: '5000',
                    }))?.length,
            });
        };
        const onSelect = (item) => {
            const newData = dataWithSelected.map(e => (item.id === e.id
                ? {
                    ...e,
                    qty: e?.qty ?? 1,
                    selected: !e.selected,
                }
                : e));
            setDataWithSelected(newData);
            const newDataForStore = newData.filter(f => f.selected);
            const newItem = newData?.find(i => i?.id === item?.id) ?? item;
            actions.changeSelectInBasket(newItem, newItem.selected);
            storage.set('basketWithSelected', newDataForStore);
        };
        const onChangeQty = (item, qty) => {
            const newData = dataWithSelected.map(e => (item.id === e.id
                ? {
                    ...e,
                    qty,
                }
                : e));
            actions.changeBasketQty(newData?.find(i => i?.id === item?.id) ?? item, qty);
            setDataWithSelected(newData);
            storage.set('basketWithSelected', newData);
        };

        const onSelectAll = () => {
            const isAllSelected = !dataWithSelected.some(e => !e.selected);
            const newData = dataWithSelected.map(e => ({
                ...e,
                qty: e?.qty ?? 1,
                selected: !isAllSelected,
            }));
            setDataWithSelected(newData);
            actions.changeSelectionInBasket(newData);
            storage.set('basketWithSelected', newData);
        };
        const onDelete = (item) => {
            storage.set(
                'basketWithSelected',
                dataWithSelected?.filter(e => e.selected && e.id !== item.id),
            );
        };
        return (
            <Page noscroll name="basket">
                {!!data?.length && PRICES_IN_FIRST_AID_KIT && (
                    <FirstAidKitSimpleBanner
                        data={dataWithSelected.filter(f => f.selected)}
                        onClickFind={searchDrugstoresHandler('green')}
                        withPrices={PRICES_IN_FIRST_AID_KIT}
                    />
                )}
                <Section
                    flex
                    style={firstAidKitstyles.section}
                    title={'titles.addDrugs'}
                    textStyles={firstAidKitstyles.sectionTextStyles}
                    titleIcon={
                        <Icon.Add
                            style={firstAidKitstyles.addIcon}
                            size={14}
                            onPress={() => navigation.navigate('SearchDrugsRoute', { drugs: data })}
                        />
                    }
                    right={
                        isEmpty ? null : (
                            <View style={firstAidKitstyles.checkboxWrapper}>
                                <SimpleCheckbox
                                    onPress={onSelectAll}
                                    selected={!dataWithSelected.some(e => !e.selected)}
                                    light
                                />
                            </View>
                        )
                    }>
                    <List
                        type="basket"
                        data={dataWithSelected}
                        renderItem={renderSelectableDrugItem({
                            onSelect,
                            onDelete,
                            onChangeQty,
                            onPress: () => {},
                            from: 'basket',
                            withPrices: PRICES_IN_FIRST_AID_KIT,
                        })}
                        ListEmptyComponent={<Stub.Basket />}
                        style={firstAidKitstyles.list}
                    />
                </Section>
                <View style={firstAidKitstyles.footer}>
                    <BigButton
                        title={`${Object.R('titles.findInDrugStores')}${
                            PRICES_IN_FIRST_AID_KIT
                                ? ''
                                : ` (${dataWithSelected?.filter?.(f => f.selected).length ?? 0})`
                        }`}
                        action={searchDrugstoresHandler('green')}
                        disabled={!dataWithSelected.filter(f => f.selected).length}
                    />
                </View>
            </Page>
        );
    }),
);
