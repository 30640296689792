import { createSelector } from 'reselect';
import { SCHEME } from '../scheme';
import { arrayToHash, someEmpty } from '../utils';
import { fillDoctorsWithClinics, fillDoctorsWithPartnershipList, umsSpecialties } from './doctors';
import { getDoctorsForGlobalLocation } from './doctorsInfo';
import {
    getClinicsWithPricesInLocationVisit, getFeedbackSumsInLocationVisit, getnNarestAvailableDatesInLocationVisit, getSpecInfoForGlobalLocationAll, getSpecInfoForGlobalLocationForVisit, getTimetableSumByAssignmentInLocationVisit, isHasTimetableVisit, prepareActualSplitDoctors, prepareDoctorsWithFeedbackSumsInLocation, prepareDoctorsWithSplitPartnershipAndSumsForLocal,
} from './doctorsSums';
import { getGlobalLocation, getPhysiciansSearchFromVisits } from './getters';
import { getClinicsWithBumba, joinedClinics } from './medicians';
import { getCurrentOrFirstProgram } from './programs';
import { dbGettr, getAdaptedDoctorsWithWorksAt, prefGettr } from './utils';

const {
    PHYSICIANS_FILTER_SPECIALTY_VISITS,
    PHYSICIANS_FILTER_SPECIALTY_ID_VISITS,
    PHYSICIANS_FILTER_WORKS_AT_VISIT,
    PHYSICIANS_FILTER_PERIOD_VISIT,
    PHYSICIANS_FILTER_FAVORITES,
    PHYSICIANS_FILTER_PROFILES_VISIT,
    PHYSICIANS_FILTER_AGES_VISIT,
    PHYSICIANS_FILTER_FEEDBACKS_VISIT,
    PHYSICIANS_SORT_BY_VISIT,
    PHYSICIANS_VISITS_FROM_SEARCH,
} = SCHEME;


export const getDoctorsBySpeciatityForVisit = createSelector(
    [getDoctorsForGlobalLocation, dbGettr('physicians.filterForVisit.specialty'), dbGettr('physicians.filterForVisit.specialtyId'), getSpecInfoForGlobalLocationAll],
    (data = {}, spec, specId, specList = []) => {
        const newArr = getAdaptedDoctorsWithWorksAt(data[specId]?.data, specList, specId);
        // const newArr = data[specId]?.data?.map(e => ({
        //     ...e,
        //     _id: e.id,
        //     worksAt: e.assignments?.map(a => ({
        //         ...a,
        //         id: a.branchId,
        //     })),
        //     specialization: specList?.find(e => e.id === specId)?.name,
        // })) || []; // data[specId]?.map((e) => ({ ...e, worksAt: e.assignments?.map((a) => ({ ...a, id: a.branchId })) })) || [];
        return arrayToHash(
            newArr
        );

    },
);

export const getDoctorsBySpeciatityForVisitWithClinics = createSelector(
    getDoctorsBySpeciatityForVisit,
    joinedClinics,
    getClinicsWithBumba,
    (physicians, clinics, bumbaClinics) => {

        return fillDoctorsWithClinics(physicians, clinics, bumbaClinics);

    },
);

export const getDoctorsBySpeciatityWithPartnershipListForVisit = createSelector(
    getDoctorsBySpeciatityForVisitWithClinics,
    getCurrentOrFirstProgram,
    umsSpecialties,
    (physicians, currentProgram, specialties) => fillDoctorsWithPartnershipList(physicians, currentProgram, specialties),
);

const doctorsBySpecialityWithFeedbackSumsInLocationVisits = createSelector(
    [getDoctorsBySpeciatityWithPartnershipListForVisit, getFeedbackSumsInLocationVisit],
    (physicians, feedbackSum) => prepareDoctorsWithFeedbackSumsInLocation(physicians, feedbackSum),
);

export const doctorsBySpecialityWithSplitPartnershipAndSumsForLocalVisit = createSelector(
    [
        doctorsBySpecialityWithFeedbackSumsInLocationVisits,
        getnNarestAvailableDatesInLocationVisit,
        getTimetableSumByAssignmentInLocationVisit,
        getClinicsWithPricesInLocationVisit,
        isHasTimetableVisit,
        getGlobalLocation,
    ],
    // eslint-disable-next-line max-params
    (physicians, timetableSum, timetableSumByAssignment, pricesSum, isUpdated, locationCode) => prepareDoctorsWithSplitPartnershipAndSumsForLocal(
        physicians,
        timetableSum,
        timetableSumByAssignment,
        pricesSum,
        isUpdated,
        locationCode,
    ),
);
export const actualSplitDoctorsBySpecialityForVisit = createSelector(
    [
        doctorsBySpecialityWithSplitPartnershipAndSumsForLocalVisit,
        getPhysiciansSearchFromVisits,
        dbGettr(PHYSICIANS_FILTER_SPECIALTY_VISITS),
        dbGettr(PHYSICIANS_FILTER_WORKS_AT_VISIT),
        dbGettr(PHYSICIANS_FILTER_PERIOD_VISIT),
        dbGettr(PHYSICIANS_FILTER_FAVORITES),
        dbGettr(PHYSICIANS_FILTER_PROFILES_VISIT),
        dbGettr(PHYSICIANS_FILTER_FEEDBACKS_VISIT),
        dbGettr(PHYSICIANS_SORT_BY_VISIT),
        getGlobalLocation,
        prefGettr('fullFavorites'),
        dbGettr(PHYSICIANS_VISITS_FROM_SEARCH),
        dbGettr('sortByBumbaFeature'),
        dbGettr(PHYSICIANS_FILTER_AGES_VISIT),
        dbGettr(PHYSICIANS_FILTER_SPECIALTY_ID_VISITS),
    ],
    (
        physicians,
        search,
        specialty,
        worksAt,
        period,
        filterByFavorites,
        profiles,
        feedbacks,
        sort,
        locationCode,
        favorites = [],
        physiciansFromSearch,
        sortByBumba = true,
        ages = '0',
        specialityId,
        // eslint-disable-next-line max-params
    ) => prepareActualSplitDoctors(
        physicians,
        search,
        specialty,
        worksAt,
        period,
        filterByFavorites,
        profiles,
        feedbacks,
        sort,
        locationCode,
        favorites,
        physiciansFromSearch,
        sortByBumba,
        ages,
        specialityId,
    ),
);
export const getHasPhysiciansDataForVisit = createSelector([getDoctorsBySpeciatityForVisit], (data) => {

    return !someEmpty(data);

});
