import { COLORS } from '../styles/colors';
import {
    Platform, React, Text, TouchableOpacity, View,
} from './react';
import { Icon } from './Icon';

export const LikeButton = ({
    onPress = () => {},
    title = '',
    textStyle,
    buttonStyle,
    selected,
    disabled = false,
    textColor,
}) => {
    const color = textColor || (disabled ? COLORS.DARK_GRAY : COLORS.MAIN);
    const defaultTextStyle = {
        fontSize: 12,
        lineHeight: 15,
        fontWeight: '400',
        // marginTop: Platform.OS === 'web' ? 0 : 2,
        marginLeft: 6,
        // backgroundColor: 'red',
    };

    const defaultButtonStyle = {
        // height: 24,
        backgroundColor: 'transparent',
        width: 120,
        marginTop: 14,
        // alignItems: 'center',
        // justifyContent: 'center',
        flexDirection: 'row',
    };

    const size = 20;
    const iconStyle = {
        marginTop: Platform.OS === 'web' ? -3 : -4,
        // borderWidth: 0, padding: 0, margin: 0, height: size, width: size, top: 0, left: 0,
    };

    const Btn = () => {
        return (
            <>
                <View style={[defaultButtonStyle, buttonStyle]}>
                    {selected ? (
                        <Icon.GoodFill color={color} size={size} style={iconStyle} />
                    ) : (
                        <Icon.Good color={color} size={size} style={iconStyle} />
                    )}
                    <Text style={[{ color }, defaultTextStyle, textStyle]}>{title}</Text>
                </View>
            </>
        );
    };
    return (
        <>
            {disabled ? (
                <Btn />
            ) : (
                <TouchableOpacity onPress={onPress}>
                    <Btn />
                </TouchableOpacity>
            )}
        </>
    );
};
