import { input } from '../styles/input';
import { formatDateLong } from '../utils';
import { COLORS } from '../styles/colors';
import { isMobile, isSafari } from '../utils/strings';
import { getDate } from '../combo/DateSelector';
import { toDateTZ } from '../utils/dates';
import { React } from './react';
import {
    Icon, Keyboard, Platform, S, Text, TouchableOpacity,
} from '.';

const toInlineStyle = (inputStyles) => {
    try {
        if (inputStyles === null || typeof inputStyles === 'undefined') {
            return {};
        }

        if (Array.isArray(inputStyles)) {
            return inputStyles.reduce((mergedStyles, style) => {
                if (Array.isArray(style)) {
                    return { ...mergedStyles, ...toInlineStyle(style) };
                }
                if (style === null || typeof style === 'undefined') {
                    return mergedStyles;
                }
                if (typeof style === 'object') {
                    return { ...mergedStyles, ...style };
                }
                return mergedStyles;
            }, {});
        }
        if (typeof inputStyles === 'object') {
            return { ...inputStyles };
        }
        return {};
    } catch (e) {
        return inputStyles;
    }
};

export const DateInput = ({
    id,
    value,
    child,
    placeholder,
    onChange,
    checkValidation,
    error,
    type,
    background,
    addMarginTop = 13,
    style,
    padding,
    marginTop = 0,
    ...props
}) => {
    const iconStyle = {
        borderWidth: 0,
        width: 35,
        // height: '100%',
        position: 'absolute',
        top: isMobile() ? 24 + ((type === 'time' && 2) ?? 0) : 0,
        right: 0,
        marginRight: 12,
        marginTop: addMarginTop,
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9,
        // margin: 'auto',
        // bottom: 0,
    };
    if (type === 'childDate') {
        type = 'date';
    }

    try {
        value?.toISOString?.();
    } catch (e) {
        value = undefined;
    }
    return Platform.OS === 'web' ? (
        <div
            style={toInlineStyle([
                {
                    background: background ?? '#fff',
                    flex: 1,
                    width: '100%',
                },
                style,
            ])}>
            <input
                type={type || 'date'}
                style={toInlineStyle([
                    {
                        // backgroundColor: '#fff',
                        padding: padding ?? 12,
                        borderRadius: 3,
                        borderWidth: 1,
                        borderColor: error ? '#DF655C' : '#E0E0E0',
                        borderStyle: 'solid',
                        fontFamily: 'FontAwesome',
                        fontSize: 14,
                        outline: 'none',
                        cursor: 'text',
                        // minWidth: '93%',
                        width: '100%',
                        boxSizing: 'border-box',
                        '-webkit-appearance': 'none',
                        // minHeight: '1.4em',
                        minHeight: 45,
                        textAlign: 'start',
                        display: 'block',
                        position: 'relative',
                        background: 'transparent',
                        flex: 1,
                        zIndex: 10,
                        paddingTop: (padding ?? 12) + (isMobile() ? marginTop : 0),
                    },
                    style,
                ])}
                onChange={(e) => {
                    onChange(e.target.value);
                    checkValidation?.(e.target.value);
                }}
                value={value}
                placeholder={placeholder}
                {...props}
            />
            {(isMobile() || isSafari()) && type !== 'time' && (
                <Icon.Calendar size={25} color={COLORS.DARK_GRAY} style={iconStyle} />
            )}
            {(isMobile() || isSafari()) && type === 'time' && (
                <Icon.Clock size={20} color={COLORS.DARK_GRAY} style={iconStyle} />
            )}
        </div>
    ) : (
        <TouchableOpacity
            accesibilityLabel={id}
            style={[
                input.input,
                S.flex1,
                S.flexRow__center,
                S.spaceBetween,
                input.input__panel,
                { paddingVertical: 10 },
            ]}
            onPress={() => {
                Keyboard.dismiss();
                // const adultDate = new Date(new Date().setFullYear(new Date().getFullYear() - 18));
                // const minDate = undefined;
                // const minDate = child ? adultDate : undefined;
                // const maxDate = new Date();
                // const maxDate = child ? new Date() : adultDate;
                // const val = value ?? child ? maxDate : new Date(adultDate?.getTime?.() - 1000);
                const dateFormatted = toDateTZ(value ?? new Date(), true);
                dateFormatted?.setHours?.(12, 0, 0, 0);
                getDate({
                    mode: 'date',
                    date: dateFormatted,
                    onSelectDate: (date, displayDate) => {
                        onChange(date, displayDate);
                        checkValidation(date, displayDate);
                    },
                    // maxDate,
                    // minDate,
                    timezoneOffsetInMinutes: 0,
                });
                // Page.showModal(
                //     <>
                //     </>
                // );
                // Page.showModal(
                //     <BirthdayPicker
                //         child={child}
                //         value={value}
                //         onChange={(date, displayDate) => {
                //             onChange(date, displayDate);
                //             Page.closeModal();
                //             checkValidation(date, displayDate);
                //         }}
                //     />,
                // );
            }}>
            <Text style={[input.infoText, !value && input.hint]}>
                {`${value ? formatDateLong(value) : placeholder}`}
            </Text>
            <Icon name="calendar" size={26} color="#666666" />
        </TouchableOpacity>
    );
};
