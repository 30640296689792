/* eslint-disable complexity */
import {
    Box, Icon, React, Title, View,
} from '../../common';
import { Blinker } from '../AnimatedComponents/Blinker';
import { adaptiveListHeader as s } from './styles';
import { TextHighliter } from './snippets';

const WithBlinker = ({ animateTrigger, children }) => (!animateTrigger ? children : <Blinker animateTrigger={animateTrigger}>{children}</Blinker>);

const Shevron = () => (
    <View style={s.titleArrow}>
        <Icon.Right />
    </View>
);

export const AdaptiveListHeader = ({
    titleFirst = null,
    titleFirstStyles,
    titleSecond = null,
    titleSecondStyles,
    onTitleSecondPress = null,
    keyword = null,
    types,
    animated = false,
    boxStyle,
}) => {
    const type = types ? Object.values(types).find(({ name }) => name === titleFirst) : {};
    const group = types ? Object.values(type.sub).find(({ name }) => name === titleSecond) : [];

    const secondPress = !onTitleSecondPress
        ? undefined
        : () => onTitleSecondPress({
            keyword: '',
            onSearchValue: '',
            type,
            typeName: titleSecond,
            typeId: type.id,
            group,
            groupId: group.id,
            groupName: titleFirst,
            level: 'item',
        });

    return (
        <View onStartShouldSetResponder={() => true}>
            {titleFirst ? (
                <WithBlinker animateTrigger={animated ? titleFirst : null}>
                    <View style={[titleFirstStyles || s.titleFirst, { ...boxStyle }]}>
                        <Title id={titleFirst} style={[s.titleFirstText]} />
                    </View>
                </WithBlinker>
            ) : null}
            {titleSecond ? (
                <WithBlinker animateTrigger={animated ? titleSecond : null}>
                    <Box
                        style={[titleSecondStyles || s.titleSecond, boxStyle]}
                        onPress={secondPress}
                        justify="space-between">
                        <TextHighliter value={titleSecond} keyword={keyword} style={s.titleSecondText} />
                        {!onTitleSecondPress ? null : <Shevron />}
                    </Box>
                </WithBlinker>
            ) : null}
        </View>
    );
};
