import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import actions from '../actions';
import { React } from '../common';
import { getDoctorBiIdFirstCallDate, getFlatDoctorsDataWithSpecialities } from '../selectors';

export const useDoctorApi = ({ id = '', withAssignments = false, nullWithoutAssignment = true }) => {

    const [doctor, setDoctor] = React.useState(id);
    const [wasLoaded, setWasLoaded] = React.useState(false);
    const doctors = useSelector(getFlatDoctorsDataWithSpecialities);
    const callTime = useSelector(getDoctorBiIdFirstCallDate);

    useEffect(() => {

        if (!callTime && wasLoaded) {

            setWasLoaded(false);

        }

    }, [callTime]);
    const resetLoaded = () => setWasLoaded(false);

    useEffect(() => {

        if (id) {

            if (nullWithoutAssignment) {

                if (doctors?.[id] && (doctors?.[id]?.assignments?.length || !withAssignments)) {

                    setDoctor(doctors[id]);

                } else {

                    if (!wasLoaded) {

                        actions.loadDoctorInfo(id, withAssignments);
                        setWasLoaded(true);

                    } else {

                        setDoctor(null);

                    }

                }

            } else {

                if (doctors?.[id]) {

                    setDoctor(doctors[id]);
                    if (!doctors?.[id]?.assignments?.length && withAssignments && !wasLoaded) {

                        actions.loadDoctorInfo(id, withAssignments);
                        setWasLoaded(true);

                    }

                }

            }

        }

    }, [id, doctors, nullWithoutAssignment, withAssignments, wasLoaded]);

    return {
        doctors,
        doctor,
        resetLoaded,
    };

};
