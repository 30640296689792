/* eslint-disable no-underscore-dangle */
import {
    BigButton, Keyboard, Link, Logo, Page, React, Section, TextInput,
} from '../../common';
import api from '../../services/api';
import { getFormValues } from '../../selectors/forms';
import { saveRelativeToStorage } from '../../services/user.utils';
import { getUserInfo } from '../../selectors';
import { adaptErrorMessage } from './utils';

export const RelativeCodeConfirmation = Page.register(
    ({
        code = '',
        setState,
        error,
        objectId,
        isTryCount,
        navigation,
        isWaitingResponse,
        formValues,
        user,
        requestFamilyCode = async () => {
            try {
                const result = await api.confirmRelativeSmsCode(objectId, code);
                const relativeDataToAdd = formValues?.relativeDataToAdd;
                if (relativeDataToAdd && result?._id) {
                    relativeDataToAdd.profileId = result?._id;
                    relativeDataToAdd.hashKey = user?.hashKey;
                    saveRelativeToStorage(relativeDataToAdd);
                }
                navigation.popToTop();
                navigation.goBack();
            } catch (err) {
                setState({
                    error: adaptErrorMessage(err),
                    isTryCount: err.code === 'TRY_COUNT',
                    isWaitingResponse: false,
                });
            }
        },
        sendAgain = async () => {
            try {
                setState({
                    error: undefined,
                    isTryCount: false,
                });
                await api.sendRelativeSms(objectId);
            } catch (err) {
                setState({
                    error: adaptErrorMessage(err),
                    isTryCount: err.code === 'WAIT_TILL',
                });
            }
        },
        onSubmit = () => {
            if (!isWaitingResponse) {
                setState({ isWaitingResponse: true });
                Keyboard.dismiss();
                requestFamilyCode();
            }
        },
    }) => (
        <Page
            name="relative-code-confirmation"
            notification={
                error && {
                    message: error.message,
                    code: error.code || -1,
                    onSubmit,
                }
            }>
            <Section centered>
                <Logo.Wrapper
                    title="titles.relative_code_confirm"
                    subtitle="titles.relative_code_confirm_description"
                    titleStyle={{ paddingBottom: 12 }}>
                    <TextInput
                        value={code}
                        textStyle={{ textAlign: 'center' }}
                        onChange={v => setState({ code: v })}
                        keyboardType="numeric"
                    />
                </Logo.Wrapper>
            </Section>
            <Section>
                <BigButton
                    title="buttons.continue"
                    action={onSubmit}
                    disabled={!code || isWaitingResponse}
                    busy={isWaitingResponse}
                />
                {isTryCount ? (
                    <Link
                        href={sendAgain}
                        underline
                        id="titles.sendAgain"
                        style={{
                            marginTop: 20,
                            alignSelf: 'center',
                        }}
                    />
                ) : null}
            </Section>
        </Page>
    ),
    {
        formValues: { from: getFormValues },
        user: { from: getUserInfo },
    },
);
