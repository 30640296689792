// Firebase App (the core Firebase SDK) is always required and must be listed first
// import * as firebaseNative from 'firebase/app';
import firebaseNative from 'firebase/compat/app';

import config from './config';

/* global window */
const firebase = Object.firebase || firebaseNative;
let _app = null;

const _appFn = firebase.app.bind(firebase);
firebase.app = () => {
    if (_app) return _app;
    firebase.initializeApp(config);

    //perf: lazy loading
    require('firebase/compat/analytics');
    require('firebase/compat/auth');
    require('firebase/compat/performance');
    require('firebase/compat/firestore');
    require('firebase/compat/remote-config');

    _app = _appFn();
    const perf = firebase.performance();
    _app.perf = () => ({
        newTrace: () => {
            return perf.trace();
        },
    });
    const remoteConfig = firebase.remoteConfig();
    _app.remoteConfig = () => ({
        setDefaults: (defaults) => {
            return (remoteConfig.defaultConfig = defaults);
        },
        fetch: (millis) => {
            remoteConfig.settings.minimumFetchIntervalMillis = millis * 1000;
            return Promise.resolve(true);
        },
        fetchAndActivate: () => {
            return remoteConfig.fetchAndActivate();
        },
        getAll: () => {
            return remoteConfig.getAll();
        },
    });
    _app.RecaptchaVerifier = () => new firebase.auth.RecaptchaVerifier('recaptcha-container', { size: 'invisible' });
    _app.getCredential = (verificationId, code) => firebase.auth.PhoneAuthProvider.credential(verificationId, code);
    const firestore = _app.firestore();
    const _enablePersistence = firestore.enablePersistence.bind(firestore);
    firestore.enablePersistence = (t) => _enablePersistence(t ? { synchronizeTabs: true } : false);
    firestore.Timestamp = () => firebase.firestore.Timestamp.now().seconds.toString();
    return _app;
};

const userProps = {};
// prevent for web:
// firebase.analytics = () => ({
//     setUserProperty: () => { },
//     setUserProperties: (delta) => Object.assign(userProps, delta),
//     logEvent: () => { }
// });
firebase.crashlytics = () => ({
    recordError: () => {},
});
firebase.links = () => ({
    onLink: () => {},
    getInitialLink: () => {},
});
// firebase.config = () => ({
//     enableDeveloperMode: () => { },
//     setDefaults: () => { },
//     fetch: () => Promise.resolve({}),
//     getValues: () => Promise.resolve({
//         online_booking_enabled: {
//             val: () => userProps.user_type != 'REGULAR'
//         },
//     }),
//     fetchAndActivate: () => Promise.resolve({}),
//     activateFetched: () => Promise.resolve({}),
//     getKeysByPrefix: () => Promise.resolve([])
// });

firebase.messaging = () => ({
    hasPermission: () => {
        return Promise.resolve(true);
    },
    requestPermission: () => {
        return Promise.resolve(true);
    },
});

export default firebase;
