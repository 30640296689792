import api from './api';
import { user } from './user';

/**
 * Service around logic common for all programs.
 */
export const removePendingPrograms = async () => {
    const { emails: userMails } = await user.getUserInfo();
    if (userMails && userMails.length) {
        const pendingProgramMails = userMails.filter(({
            confirmed,
            usage,
        }) => !confirmed && usage !== 'PRIMARY');
        pendingProgramMails.forEach((mail) => {
            userMails.splice(userMails.indexOf(mail));
            if (mail.address.split('@')[1].indexOf('epam') > -1) {
                api.epamCancelRegister();
            }
        });
        await api.updateUserInfo({
            emails: userMails.map(({
                address,
                usage,
            }) => ({
                address,
                usage,
            })),
        });
    }
};
