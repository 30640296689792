/* eslint-disable no-underscore-dangle */
// noinspection JSUnusedGlobalSymbols

import service from '../services/feedbacks';
import { asyncActionCreator, createReducerFor } from './utils';

const TYPE = 'FEEDBACKS';

// export const deleteFeedback = asyncActionCreator(TYPE, 'data', [], feedback => service.deleteFeedback(feedback));
export const deleteFeedback =
    (feedback, forItemKind, fetch = true) => async (dispatch, getState) => {

        let payload = { [forItemKind]: [] };
        try {

            const state = getState()?.feedbacks;
            if (fetch) {

                payload = {
                    [forItemKind]: state[forItemKind],
                    isLoading: true,
                };
                dispatch({
                    type: TYPE,
                    payload,
                });

            }
            const feedbacks = await service.deleteFeedback(
                {
                    ...feedback,
                    forItemKind,
                },
                fetch,
            );

            if (fetch || forItemKind === 'clinics') {

                payload = {
                    [forItemKind]: feedbacks,
                    isLoading: false,
                };
                dispatch({
                    type: TYPE,
                    payload,
                });

            }
            let votes = state[`votes_${forItemKind}`];
            delete votes[feedback?.forItemId];
            if (fetch) {

                votes = await service.getVotes(forItemKind);

            }
            dispatch({
                type: TYPE,
                payload: {
                    [`votes_${forItemKind}`]: { ...votes },
                    [`completed${forItemKind}`]: [
                        ...(state?.[`completed${forItemKind}`]?.filter?.(item => item !== feedback?.forItemId) ?? []),
                    ],
                    [`completed`]: [...(state?.completed?.filter?.(item => item !== feedback?.forItemId) ?? [])],
                    myphysicians: { needFetch: true },
                },
            });

        } catch (e) {

            dispatch({
                type: TYPE,
                payload: {
                    [forItemKind]: [],
                    error: e,
                    isLoading: false,
                },
            });

        }

    };

export const resetFeedbacksError = asyncActionCreator(TYPE, 'error', {}, () => {});

// export const clearFeedbacks = () => asyncActionCreator(TYPE, 'data', [], () => { });

export const setLoadedIdFeedbacks = asyncActionCreator(TYPE, 'loadedId', undefined, () => {});

export const clearFeedbacks =
    (forItemKind = 'physicians', cb) => (dispatch) => {

        const payload = { [forItemKind]: [] };
        dispatch({
            type: TYPE,
            payload,
        });

        cb && cb(forItemKind);

    };

export const fetchMyFeedbacksPhysicians = asyncActionCreator(TYPE, 'myphysicians', [], () => {

    return service.getMyFeedbacks('physicians');

});

export const clearMyFeedbacksPhysicians = asyncActionCreator(TYPE, 'myphysicians', { needFetch: true }, () => {

    return { needFetch: true };

});

// TODO use asyncActionCreator
export const fetchFeedbacks =
    (forItemId, forItemKind = 'physicians', withErrorClear = true, cb = () => {}) => async (dispatch, state) => {

        const dataName = `${forItemKind}`;
        let data = [];
        let error = null;
        try {

            const feedbacks = state();
            if (feedbacks?.feedbacks?.isFeedbacksLoading) {

                return false;

            }
            const payload = {
                [dataName]: data,
                isLoading: true,
                isFeedbacksLoading: true,
                forItemId,
            };
            if (withErrorClear) {

                payload.error = error;

            }
            dispatch({
                type: TYPE,
                payload,
            });
            data = await service.fetchFeedbacks(forItemId);
            dispatch({
                type: TYPE,
                payload: {
                    error,
                    [dataName]: data,
                    isLoading: false,
                    isFeedbacksLoading: false,
                },
            });
            cb(!!error);

        } catch (ex) {

            // eslint-disable-next-line no-console
            console.log('Error', ex);
            error = ex;
            dispatch({
                type: TYPE,
                payload: {
                    error,
                    [dataName]: data,
                    isLoading: false,
                    isFeedbacksLoading: false,
                },
            });
            cb(!!error);
            return false;

        } finally {
            //
        }
        return true;

    };

export const setFeedbackData = data => (dispatch) => {

    try {

        const payload = { feedbackFormData: data };
        dispatch({
            type: TYPE,
            payload,
        });

    } catch (e) {

        // eslint-disable-next-line no-console
        console.log('Error set feedback form data');

    }

};

export const fetchCompleted =
    (itemKind = 'physicians', isReset) => async (dispatch) => {

        if (isReset) {

            dispatch({
                type: TYPE,
                payload: { [`completed${itemKind}`]: [] },
            });
            return;

        }
        let completed = [];
        try {

            completed = await service.fetchCompleted(itemKind);

        } catch (e) {

            // eslint-disable-next-line no-console
            console.log('Error fetch completed feedbacks', e);

        }
        dispatch({
            type: TYPE,
            payload: { [`completed${itemKind}`]: completed },
        });

    };

export const fetchVotes =
    (itemKind = 'physicians', isReset) => async (dispatch) => {

        if (isReset) {

            dispatch({
                type: TYPE,
                payload: { [`votes_${itemKind}`]: [] },
            });
            return;

        }
        let completed = [];
        try {

            completed = await service.getVotes(itemKind);

        } catch (e) {

            // eslint-disable-next-line no-console
            console.log('Error fetch votes', e);

        }
        dispatch({
            type: TYPE,
            payload: { [`votes_${itemKind}`]: completed },
        });

    };

// TODO use asyncActionCreator
export const modifyFeedback =
    (feedback, cb, fetch = false) => async (dispatch, getStatus) => {

        let error;
        let completed = [];
        try {

            const state = getStatus();
            if (state?.feedbacks?.isLoading) {

                return false;

            }
            dispatch({
                type: TYPE,
                payload: { isLoading: true },
            });
            const data = await service.modifyFeedback(feedback, feedback?.forItemKind, fetch);
            completed = (await service.getVotes(feedback?.forItemKind)) ?? [];
            if (fetch) {

                dispatch({
                    type: TYPE,
                    payload: {
                        [feedback.forItemKind]: data,
                        [`votes_${feedback.forItemKind}`]: completed,
                        isLoading: false,
                        myphysicians: { needFetch: true },
                    },
                });

            } else {

                dispatch({
                    type: TYPE,
                    payload: {
                        isLoading: false,
                        [`votes_${feedback.forItemKind}`]: completed,
                    },
                    myphysicians: { needFetch: true },
                });

            }

        } catch (err) {

            error = err;
            dispatch({
                type: TYPE,
                payload: {
                    isLoading: false,
                    [`votes_${feedback.forItemKind}`]: completed,
                },
            });

        } finally {

            cb(error);

        }

    };

export const modifyUseful =
    (
        { id, value },
        cb = () => {

        },
    ) => async (dispatch, getState) => {

        try {

            const response = await service.modifyUseful(id, value);
            if (!response) {

                return;

            }
            const { feedbacks } = getState();
            if (!feedbacks) {

                return;

            }
            const {
                forItemId, forItemKind, usefulCount, usefulForMe,
            } = response;
            if (feedbacks?.forItemId !== forItemId) {

                return;

            }
            const data = feedbacks[forItemKind ?? 'physicians'];
            if (!Array.isArray(data)) {

                return;

            }

            for (const i in data) {

                const v = data[i];
                if (v?._id === id) {

                    v.usefulCount = usefulCount;
                    v.usefulForMe = usefulForMe;
                    data[i] = v;

                }

            }
            dispatch({
                type: TYPE,
                payload: {
                    ...feedbacks,
                    [forItemKind]: [...data],
                },
            });
            cb({
                ...feedbacks,
                [forItemKind]: [...data],
            });

        } catch (e) {

            // eslint-disable-next-line no-console
            console.log('Error modify useful', e);

        }

    };

export const setAllFeedbacksActivePage = activePage => (dispatch) => {

    dispatch({
        type: TYPE,
        payload: { activePage },
    });

};

export const setAllFeedbacksMyFilter = filter => (dispatch) => {

    dispatch({
        type: TYPE,
        payload: { myFilter: filter },
    });

};

export const setAllFeedbacksMyTextFilter = filter => (dispatch) => {

    dispatch({
        type: TYPE,
        payload: { myTextFilter: filter },
    });

};

export const setAllFeedbacksMySpecialityFilter = list => (dispatch) => {

    dispatch({
        type: TYPE,
        payload: { mySpecialitiesFilter: list },
    });

};

export const setAllFeedbacksMySpecialityPreFilter = list => (dispatch) => {

    dispatch({
        type: TYPE,
        payload: { mySpecialitiesPreFilter: list },
    });

};

export const setAllFeedbacksMySpecialityFilterToggle = id => (dispatch, state) => {

    const list = [...(state()?.feedbacks?.mySpecialitiesPreFilter ?? [])];
    const index = list.indexOf(id);
    if (index === -1) {

        list.push(id);

    } else {

        list.splice(index, 1);

    }
    dispatch({
        type: TYPE,
        payload: { mySpecialitiesPreFilter: list },
    });

};

export const setAllFeedbacksMySortOrder = sortOrder => (dispatch) => {

    dispatch({
        type: TYPE,
        payload: { mySortOrder: sortOrder },
    });

};

export const setAllFeedbacksHasText = hasText => (dispatch) => {

    dispatch({
        type: TYPE,
        payload: { myFilterHasText: hasText },
    });

};

export const setAllFeedbacksHideNotRecommend = hide => (dispatch) => {

    dispatch({
        type: TYPE,
        payload: { myFilterHideNotRecommend: hide },
    });

};

export const setAllFeedbacksHideNeutral = hide => (dispatch) => {

    dispatch({
        type: TYPE,
        payload: { myFilterHideNeutral: hide },
    });

};

export const setAllFeedbacksHideRecommend = hide => (dispatch) => {

    dispatch({
        type: TYPE,
        payload: { myFilterHideRecommend: hide },
    });

};

export const setNeedFetchMyPhysicians = needFetch => (dispatch) => {

    dispatch({
        type: TYPE,
        payload: { myphysicians: { needFetch } },
    });

};

export default createReducerFor(TYPE);
