import { dataFrom, React, Section } from '../../../common';
import { getIsFetchingVisits } from '../../../selectors';

export * from './PopularQueries';
export * from './Banner';
export * from './Tests&Visits';

export const SectionWithVisitsLoading = dataFrom(getIsFetchingVisits, ({
    data: isLoading,
    children,
    ...rest
}) => (
    <Section isLoading={isLoading} {...rest}>{children}</Section>
));
