/* eslint-disable no-underscore-dangle */
import { getUser } from '../selectors';
import Store from '../store';
import { storage } from '../services/localstorage';
import { VERSION } from '../config';
import { Platform } from '../common';
import { ErrorManager } from './ErrorManager';

const getUserSupportInfo = (profile) => {
    if (!profile?.info) {
        return '';
    }
    const { info, deviceInfo } = profile;
    const phone = info?.phones?.[0]?.number ?? '';
    const {
        userId,
        deviceIdOfExistedUser,
        // eslint-disable-next-line no-unsafe-optional-chaining
    } = profile?.info;
    const user = userId?.substring(Math.max(0, userId?.length - 16)) ?? deviceIdOfExistedUser ?? '';
    const os = deviceInfo ?? '';
    const buildNumber = `${Platform.OS === 'web' ? 'mwa' : 'mma'},${VERSION}`;
    // eslint-disable-next-line no-undef
    const screen = window?._currentRouteName ?? '-';
    // eslint-disable-next-line no-undef
    const params = window?._currentRouteParams ?? '-';
    return Object.R('titles.supportInfo', {
        phone,
        user,
        os,
        screen,
        params,
        lastError: ErrorManager.getError(),
        buildNumber,
    });
};

const getUserSupportInfoAsync = async (profile) => {
    if (!profile?.info) {
        return '';
    }
    const { info, deviceInfo } = profile;
    const phone = info?.phones?.[0]?.number ?? (await storage.get('phoneNumber'));
    const {
        userId,
        deviceIdOfExistedUser,
        // eslint-disable-next-line no-unsafe-optional-chaining
    } = profile?.info;
    const userCode = userId?.substring(Math.max(0, userId?.length - 16));
    const user = userCode ?? (deviceIdOfExistedUser ? `deviceId ${deviceIdOfExistedUser}` : '');
    const os = deviceInfo ?? '';
    // eslint-disable-next-line no-undef
    const screen = window?._currentRouteName ?? '-';
    // eslint-disable-next-line no-undef
    const params = window?._currentRouteParams ?? '-';
    const buildNumber = `${Platform.OS === 'web' ? 'mwa' : 'mma'},${VERSION}`;
    return Object.R('titles.supportInfo', {
        phone,
        user,
        os,
        screen,
        params,
        lastError: ErrorManager.getError(),
        buildNumber,
    })
        .split(' , ')
        .join(' ');
};

export const getSupportInfo = () => {
    let body;
    try {
        body = getUserSupportInfo(getUser(Store.getState()));
    } catch (e) {
        body = '';
    }
    return body;
};

export const getSupportInfoAsync = async () => {
    let body;
    try {
        body = await getUserSupportInfoAsync(getUser(Store.getState()));
    } catch (e) {
        body = '';
    }
    return body;
};

export const getSupportBody = () => {
    const info = encodeURIComponent(getSupportInfo());
    return info ? `?body=${info}` : '';
};

export const getSupportBodyAsync = async () => {
    const info = await getSupportInfoAsync();
    return info ? `?body=${encodeURIComponent(info)}` : '';
};
