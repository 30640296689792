/* eslint-disable no-param-reassign,no-underscore-dangle */
// noinspection JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {
    arrayToHash,
    createMemoizedProps,
    dig,
    histogram,
    isEmpty,
    normalizeString,
    someEmpty,
    sortBy,
    sortDoctorByBumbaComparator,
} from '../utils';
import { SCHEME } from '../scheme';
import { synonymize } from '../i18n';
import {
    AUTO_SWITCH_TO_FUZZY_LENGTH_DOCTOR,
    DEFAULT_SEARCH_FUNCTION,
    MIN_WORD_LENGTH,
    wordsList,
} from '../utils/strings';
import {
    getGlobalLocation,
    getPhysiciansClinicsAdvancedSearch,
    getPhysiciansFilterClinic,
    getPhysiciansFilterVisit,
    getPhysiciansSearch,
    getPhysiciansSearchFromClinics,
    getPhysiciansSearchFromVisits,
    getPhysiciansVisitsAdvancedSearch,
    getUser,
} from './getters';
import {
    createSelector,
    dbGettr,
    doctorSortOptions,
    getDateForJob,
    getDoctorProfile,
    getHandledAgesFromProfile,
    getPrices,
    gettr,
    mergeFeedbacksToDoctor,
    normSpecialty,
    normSpecialtyString,
    prefGettr,
} from './utils';
import { umsServicesWithPrograms } from './ums';
import { EMPTY_OBJECT } from './const';
import { getCurrentOrFirstProgram } from './programs';
import { getClinicsWithBumba, joinedClinics } from './medicians';
import { getCurrentLocation } from './location';
import {
    getFavoritesFull,
    getFetching,
    getPhysiciansAdvancedSearch,
    getPhysiciansFavoritesSearch,
    getPhysiciansFilter,
} from '.';

const {
    PHYSICIANS_FILTER_SPECIALTY,
    PHYSICIANS_FILTER_SPECIALTY_CLINICS,
    PHYSICIANS_FILTER_SPECIALTY_VISITS,
    PHYSICIANS_FILTER_WORKS_AT,
    PHYSICIANS_FILTER_WORKS_AT_CLINIC,
    PHYSICIANS_FILTER_WORKS_AT_VISIT,
    PHYSICIANS_FILTER_PERIOD,
    PHYSICIANS_FILTER_PERIOD_CLINIC,
    PHYSICIANS_FILTER_PERIOD_VISIT,
    PHYSICIANS_FILTER_FAVORITES,
    PHYSICIANS_FILTER_PROFILES,
    PHYSICIANS_FILTER_PROFILES_CLINIC,
    PHYSICIANS_FILTER_PROFILES_VISIT,
    PHYSICIANS_FILTER_FEEDBACKS,
    PHYSICIANS_FILTER_FEEDBACKS_CLINIC,
    PHYSICIANS_FILTER_FEEDBACKS_VISIT,
    PHYSICIANS_SORT_BY,
    PHYSICIANS_FROM_SERARCH,
    PHYSICIANS_FROM_CLINICS_SEARCH,
    PHYSICIANS_FROM_VISITS_SEARCH,
} = SCHEME;

const doctorProto = createMemoizedProps({
    specializations: normSpecialty,
    notification() {
        return this.worksAt[0]?.getNotif?.(this.id);
    },
    profile() {
        return getDoctorProfile(this.worksAt);
    },
    handledAges() {
        // eslint-disable-next-line no-return-assign
        return getHandledAgesFromProfile(this.profile);
    },
    _fullNameSearch() {
        const fullNameParts = this.fullName.replace(/ё/gi, 'е').split(' ');
        return `${fullNameParts[0]} ${fullNameParts[0]} ${fullNameParts[0]} ${fullNameParts[1]} ${fullNameParts[1]} ${fullNameParts[2]}`;
    },
    _search() {
        const profileSearch = (this.profile || [])
            .map(({ isForChildren }) => (isForChildren ? 'дети детский' : 'взрослый'))
            .join(' ');
        const worksAtSearch = this.worksAt
            .map(
                ({ name }) => `${name} ${name
                    .replace(/[^А-Яа-яA-Za-z0-9Ёё ]/g, '')
                    .split(' ')
                    .map(synonymize)
                    .join(' ')}`,
            )
            .join(' ');
        return normalizeString(
            `${this.specialization.replace(/-/g, ' ') || ''} ${(this.specializations || [])
                .map(item => synonymize(item))
                .join(' ')} ${worksAtSearch} ${profileSearch}`,
        );
    },
});

export const getSortByBumba = createSelector(dbGettr('sortByBumbaFeature'), s => s);

/* eslint-disable max-statements */
/* eslint-disable max-params */
const SPEC_KEYS = [
    'Консультация специалиста',
    'УЗИ',
    'Рентген',
    'КТ',
    'МРТ',
    'томография',
    'Функциональн',
    'коронавирус',
];
export const umsSpecialties = createSelector(umsServicesWithPrograms, (services) => {
    if (someEmpty(services)) {
        return EMPTY_OBJECT;
    }
    const tree = {};
    services.forEach(({ programs, options, group }) => {
        if (!Object.values(programs).length || !SPEC_KEYS.some(key => group.name.includes(key))) {
            if (Object.values(programs).length) {
                // console.log('Skip key', { name: group.name, group });
            }
            return;
        }
        const specOptions = options.filter(({ groupName }) => groupName === 'Специализация') ?? [];
        if (!specOptions.length) {
            return;
        }

        specOptions.forEach((specOption) => {
            if (tree[specOption.name]) {
                const mergedPrograms = {};
                Object.entries(programs).forEach(([programId, coverageInfo]) => {
                    Object.entries(coverageInfo).forEach(([key, value]) => {
                        if (!mergedPrograms[programId]) {
                            mergedPrograms[programId] =
                                typeof value === 'object'
                                    ? { [key]: { ...tree[specOption.name]?.[programId][key], ...value } }
                                    : (mergedPrograms[programId] = { [key]: value });
                        } else {
                            mergedPrograms[programId][key] =
                                typeof value === 'object' && tree[specOption.name][programId]
                                    ? { ...tree[specOption.name][programId]?.[key], ...value }
                                    : value;
                        }
                    });
                });
                tree[specOption.name] = mergedPrograms;
            } else {
                tree[specOption.name] = programs;
            }
        });
    });
    return tree;
});

export const fillDoctorsWithClinics = (physicians, clinics, bumbaClinics) => {
    return someEmpty(clinics, physicians)
        ? undefined
        : Object.values(physicians)
            .map((e) => {
                if (!e.worksAt?.length) {
                    return null;
                }

                const workPlaces = e.worksAt.filter(job => job && clinics[job.id] && !clinics[job.id]?.hidden);
                if (!workPlaces.length) {
                    return null;
                }

                let experienceYears = e?.experienceYears;
                if (experienceYears && typeof experienceYears === 'number') {
                    experienceYears = String(experienceYears);
                }

                let category = e?.category;
                try {
                    category = JSON.parse(category);
                    if (!Array.isArray(category) && category) {
                        category = [category];
                    } else if (!category?.length) {
                        category = null;
                    }
                } catch (_) {
                    //
                }

                let degree = e?.degree;
                try {
                    degree = JSON.parse(degree);
                    if (!Array.isArray(degree) && degree) {
                        degree = [degree];
                    } else if (!degree.length) {
                        degree = null;
                    }
                } catch (_) {
                    //
                }

                let academicTitle = e?.academicTitle;
                try {
                    academicTitle = JSON.parse(academicTitle);
                    if (!Array.isArray(academicTitle) && academicTitle) {
                        academicTitle = [academicTitle];
                    } else if (!academicTitle?.length) {
                        academicTitle = null;
                    }
                } catch (_) {
                    //
                }

                return Object.assign(Object.create(doctorProto), {
                    ...e,
                    hasBumba: workPlaces?.some(clinic => bumbaClinics.includes(clinic?.clinicId)),
                    degree,
                    academicTitle,
                    category,
                    experienceYears,
                    specialization: e.specialization || '',
                    worksAt: workPlaces.map(job => Object.assign(Object.create(clinics[job.id] || {}), {
                        ...job,
                        hasBumba: bumbaClinics?.includes?.(job?.clinicId),
                        // Unique case when job has neither lower nor upper age means that doctor handles only adults
                        patientLowerAge:
                                  job.patientLowerAge === null && job.patientUpperAge === null
                                      ? 18
                                      : job.patientLowerAge,
                    })),
                });
            })
            .filter(Boolean);
};

export const doctorsWithClinics = createSelector(
    dbGettr('physicians.data'),
    joinedClinics,
    getClinicsWithBumba,
    (physicians, clinics, bumbaClinics) => fillDoctorsWithClinics(physicians, clinics, bumbaClinics),
);

export const fillDoctorsWithPartnershipList = (physicians, currentProgram, specialties) => {
    if (someEmpty(physicians, specialties, currentProgram)) {
        return physicians;
    }
    return physicians.map((e) => {
        const worksAt = e?.worksAt?.map(job => Object.assign(Object.create(job), {
            ...job,
            get coverer() {
                return e.specialization
                    ?.split?.(',')
                    ?.some?.(specId => dig(specialties, `${specId}.${currentProgram.id}.coverage.${job.id}`))
                    ? currentProgram?.coverer
                    : null;
            },
            get covererId() {
                return job?.id ?? job?.clinicId ?? job?._id;
            },
        }));
        return Object.assign(Object.create(e), {
            ...e,
            worksAt,
            get coverer() {
                return Object.keys(
                    worksAt?.reduce((r, { coverer }) => {
                        if (coverer) {
                            r[coverer] = 1;
                        }
                        return r;
                    }, {}),
                ).join?.(',');
            },
            get limited() {
                if (!this.$$limited) {
                    this.$$limited = Object.keys(
                        worksAt.reduce((r, { coverer, id }) => {
                            if (
                                e?.specialization
                                    ?.split(',')
                                    ?.some(specId => dig(specialties, `${specId}.${currentProgram.id}.attributes.${id}`))
                            ) {
                                r[coverer] = 1;
                            }
                            return r;
                        }, {}),
                    )?.join?.(',');
                }
                return this.$$limited;
            },
            get getPhysicianId() {
                return e._id ?? e?.id;
            },
        });
    });
};

export const doctorsWithPartnershipList = createSelector(
    doctorsWithClinics,
    getCurrentOrFirstProgram,
    umsSpecialties,
    (physicians, currentProgram, specialties) => fillDoctorsWithPartnershipList(physicians, currentProgram, specialties),
);
export const doctorsWithPartnership = createSelector(doctorsWithPartnershipList, (physicians) => {
    if (someEmpty(physicians)) {
        return physicians ? arrayToHash(physicians) : physicians;
    }
    return arrayToHash(physicians);
});

const getTimetableSum = createSelector([dbGettr('physicians_timetable_sum.data', EMPTY_OBJECT)], (timetableSum) => {
    if (someEmpty(timetableSum)) {
        return timetableSum;
    }
    const nearestAvailableDates = {};
    Object.values(timetableSum).forEach((e) => {
        nearestAvailableDates[e._id] = arrayToHash(e.nearest_available_dates, 'clinic_id', 'nearest_available_date');
    });
    return nearestAvailableDates;
});
export const getIsTimetableSumLoading = createSelector(
    [dbGettr('physicians_timetable_sum.data')],
    timetableSum => !!timetableSum,
);

const getPricesSum = createSelector([dbGettr('physicians_prices_sum.data', EMPTY_OBJECT)], (pricesSum) => {
    if (someEmpty(pricesSum)) {
        return pricesSum;
    }
    const clinicsWithPrices = {};

    Object.values(pricesSum).forEach((e) => {
        clinicsWithPrices[e._id] = getPrices(e);
    });
    return clinicsWithPrices;
});

export const isAllPhysitionsCollectionsUpdated = createSelector(
    [getFetching],
    fetchedCollections => fetchedCollections.physicians_timetable_sum,
); // && fetchedCollections.physicians_feedback_sum && fetchedCollections.physicians_prices_sum);

export const isPhysitionsCollectionsUpdated = createSelector(
    [getFetching],
    fetchedCollections => fetchedCollections.physicians,
); // && fetchedCollections.physicians_feedback_sum && fetchedCollections.physicians_prices_sum);

export const doctorsWithFeedbackSums = createSelector(
    [doctorsWithPartnershipList, dbGettr('physicians_feedback_sum.data', EMPTY_OBJECT)],
    (physicians, feedbackSum) => {
        if (someEmpty(physicians, feedbackSum)) {
            return physicians;
        }
        // return physicians.map(e => Object.assign(Object.create(e), {
        //     feedbacksCount: (feedbackSum[e._id] || EMPTY_OBJECT).feedbackCount || 0,
        //     recommendCount: (feedbackSum[e._id] || EMPTY_OBJECT)?.recommendCount || 0,
        //     notRecommendCount: (feedbackSum[e._id] || EMPTY_OBJECT)?.notRecommendCount || 0,
        //     neutralCount: (feedbackSum[e._id] || EMPTY_OBJECT)?.neutralCount || 0,
        //     recommendationIndex: (feedbackSum[e._id] || EMPTY_OBJECT)?.recommendationIndex || 0,
        //     textFeedbackCount: (feedbackSum[e._id] || EMPTY_OBJECT)?.textFeedbackCount || 0,
        // }));
        return physicians.map(e => mergeFeedbacksToDoctor(e, feedbackSum[e._id], true));
    },
);

export const doctorsFeedbacks = createSelector(
    [dbGettr('physicians_feedback_sum.data', EMPTY_OBJECT)],
    doctors => doctors,
);

export const doctorsFeedbacks_ = createSelector([gettr('feedbacks.physicians', [])], doctors => doctors);

export const doctorsFeedbacksWithLoading = createSelector(
    [
        gettr('feedbacks', {
            isLoading: false,
            physicians: [],
        }),
    ],
    doctors => doctors,
);

export const getMyFeedbacks = createSelector([gettr('feedbacks'), getUser], (feedbacks, user) => {
    let data = [];
    if (!user?.isAuthenticated || !feedbacks) {
        return {
            ...feedbacks,
            physicians: data,
        };
    }
    data =
        feedbacks?.physicians
            ?.filter(item => item?.userProfileId === user?.info?.userId)
            .sort((a, b) => (new Date(a?.createdAt) > new Date(b?.createdAt) ? -1 : 1)) ?? [];
    return {
        ...feedbacks,
        physicians: data,
    };
});

export const getCompletedPhysiciansFeedbacks = createSelector(
    [gettr('feedbacks.completedphysicians')],
    completed => completed ?? [],
);

export const doctorsWithPartnershipAndSums = createSelector(
    [
        doctorsWithFeedbackSums,
        getTimetableSum,
        getPricesSum,
        isAllPhysitionsCollectionsUpdated,
        // getGlobalLocation,
    ],
    (physicians, timetableSum, pricesSum, isUpdated) => {
        if (someEmpty(physicians, timetableSum) || !isUpdated) {
            return physicians;
        }
        return physicians.map((e) => {
            const r = Object.create(e);
            r.nearestDate = null;
            r.priceRange = pricesSum[e._id] || {};
            // const allWorksAt = [...e.worksAt];
            // r.worksAt = allWorksAt.filter(({ areaCode }) => areaCode === locationCode);
            r.worksAt = [...e.worksAt];
            r.worksAt.forEach((job) => {
                const nearestJobDate = getDateForJob(e, job, timetableSum);
                Object.assign(job, {
                    nearestAvailableDate: nearestJobDate,
                    priceRange: r.priceRange[job._id],
                });
                if (nearestJobDate && (!r.nearestDate || nearestJobDate < r.nearestDate)) {
                    r.nearestDate = new Date(nearestJobDate.getTime());
                }
            });
            return r;
        });
    },
);

export const doctorsOnlyWithPartnershipAndSums = createSelector(
    [
        doctorsWithFeedbackSums,
        getTimetableSum,
        getPricesSum,
        isAllPhysitionsCollectionsUpdated,
        // getGlobalLocation,
    ],
    (physicians, timetableSum, pricesSum, isUpdated) => {
        if (someEmpty(physicians, timetableSum) || !isUpdated) {
            return [];
        }
        return physicians.map((e) => {
            const r = Object.create(e);
            r.nearestDate = null;
            r.priceRange = pricesSum[e._id] || {};
            r.worksAt = [...e.worksAt];
            r.worksAt.forEach((job) => {
                const nearestJobDate = getDateForJob(e, job, timetableSum);
                Object.assign(job, {
                    nearestAvailableDate: nearestJobDate,
                    priceRange: r.priceRange[job._id],
                });
                if (nearestJobDate && (!r.nearestDate || nearestJobDate < r.nearestDate)) {
                    r.nearestDate = new Date(nearestJobDate.getTime());
                }
            });
            return r;
        });
    },
);

export const doctorsWithPartnershipAndSumsForLocal = createSelector(
    [doctorsWithFeedbackSums, getTimetableSum, getPricesSum, isAllPhysitionsCollectionsUpdated, getGlobalLocation],
    (physicians, timetableSum, pricesSum, isUpdated, locationCode) => {
        if (someEmpty(physicians, timetableSum) || !isUpdated) {
            return physicians;
        }
        return physicians.map((e) => {
            const r = Object.create(e);
            r.nearestDate = null;
            r.priceRange = pricesSum[e._id] || {};
            const allWorksAt = [...e.worksAt];
            r.worksAt = allWorksAt.filter(({ areaCode }) => areaCode === locationCode);
            r.worksAt.forEach((job) => {
                const nearestJobDate = getDateForJob(e, job, timetableSum);
                Object.assign(job, {
                    nearestAvailableDate: nearestJobDate,
                    priceRange: r.priceRange[job._id],
                });
                if (nearestJobDate && (!r.nearestDate || nearestJobDate < r.nearestDate)) {
                    r.nearestDate = new Date(nearestJobDate.getTime());
                }
            });
            return r;
        });
    },
);

export const filterBySearch = (data, search, fieldKey = 'fullName') => {
    const searchKeywords = search
        ?.toLowerCase()
        .trim()
        .split(' ')
        .filter(word => word.length >= MIN_WORD_LENGTH);

    const doctors = data.filter((r) => {
        const value = r?.[fieldKey];
        return (
            wordsList(value, searchKeywords, DEFAULT_SEARCH_FUNCTION, AUTO_SWITCH_TO_FUZZY_LENGTH_DOCTOR).length >=
            searchKeywords.length
        );
        // || wordsList(specialization, searchKeywords, DEFAULT_SEARCH_FUNCTION, AUTO_SWITCH_TO_FUZZY_LENGTH_DOCTOR).length >= searchKeywords.length;
    });
    return doctors.map((doctor) => {
        let foundIn;
        const name = doctor?.[fieldKey].split(' ');
        name.some((part, index) => {
            const found = wordsList(part, searchKeywords, DEFAULT_SEARCH_FUNCTION, AUTO_SWITCH_TO_FUZZY_LENGTH_DOCTOR);
            if (found.length > 0) {
                foundIn = index * 20 + part.indexOf(found[0]);
                return true;
            }
            return false;
        });

        return typeof foundIn === 'number' ? Object.assign(Object.create(doctor), { foundIn }) : doctor;
    });
};

const getActualDoctors = (
    physicians,
    search,
    specialty,
    worksAt,
    period,
    filterByFavorites,
    profiles,
    feedbacks,
    sort,
    locationCode,
    favorites = [],
    physiciansFromSearch,
    sortByBumba = true,
) => {
    if (isEmpty(physicians)) {
        return null;
    }
    let rr = physicians.filter(({ hidden } = {}) => !hidden);
    if (physiciansFromSearch && Array.isArray(physiciansFromSearch)) {
        rr = rr
            ?.filter(({ worksAt: pWorksAt = [] } = {}) => pWorksAt?.some(({ assignmentId: pAssignmentId } = {}) => physiciansFromSearch?.some(({ assignmentId }) => assignmentId === pAssignmentId)))
            ?.map((e = {}) => {
                const times = physiciansFromSearch
                    ?.filter(({ assignmentId } = {}) => (e.worksAt || [])?.some(
                        ({ assignmentId: pAssignmentId } = {}) => pAssignmentId === assignmentId,
                    ))
                    ?.map(({ firstTimeSlotAvail, assignmentId } = {}) => ({
                        nta: new Date(firstTimeSlotAvail).valueOf(),
                        assignmentId,
                    }));
                const minNta = times?.reduce((min, cur) => {
                    if (cur.nta < min.nta) {
                        return cur;
                    }
                    return min;
                });
                return Object.assign(Object.create(e), {
                    nearestDate: minNta.nta,
                    assignmentIdForNTA: minNta.assignmentId,
                });
            });
    }
    if (locationCode) {
        rr = rr.filter(e => (e.worksAt || []).some(({ areaCode }) => areaCode === locationCode));
    }
    let sortBySearch;
    if (search) {
        rr = filterBySearch(rr, search);
        sortBySearch = 'foundIn';
    }
    if (worksAt && worksAt.length) {
        rr = rr.filter(e => (e.worksAt || []).some(({ _id: mine }) => worksAt.some(their => mine === their)));
    }
    if (!Array.isArray(physiciansFromSearch) && specialty) {
        rr = rr.filter(({ specializations }) => specializations.some(e => e === specialty));
    }
    // if (period) {
    //     const limit = Date.now() + (period * 24 * 3600 * 1000);
    //     rr = rr.filter(({ nearestDate }) => !nearestDate || (nearestDate.valueOf() < limit));
    // }
    if (!isEmpty(filterByFavorites)) {
        rr = rr.filter(({ id }) => favorites.some(e => e.id === id));
    }
    if (profiles && Number(profiles)) {
        if (profiles === '1') {
            rr = rr.filter(({ profile }) => profile.some(({ isForChildren }) => !isForChildren));
        } else if (profiles === '2') {
            rr = rr.filter(({ profile }) => profile.some(({ isForChildren }) => isForChildren));
        }
    }
    if (feedbacks) {
        // TODO: textFeedbackCount ??
        rr = rr.filter(({ textFeedbackCount }) => Boolean(textFeedbackCount));
    }

    const sortId = sort && !sortBySearch ? sort.id : sortBySearch || doctorSortOptions()[0].id;

    const sortExpr = sort && !sortBySearch ? sort.expr : sortBySearch || doctorSortOptions()[0].expr;
    if (sortId === 'byDate' && sortByBumba) {
        return rr.sort(sortDoctorByBumbaComparator);
    }
    return sortBy(rr, sortExpr);
};

export const actualDoctorsVisit = createSelector(
    [
        doctorsWithPartnershipAndSumsForLocal,
        getPhysiciansSearchFromVisits,
        dbGettr(PHYSICIANS_FILTER_SPECIALTY_VISITS),
        dbGettr(PHYSICIANS_FILTER_WORKS_AT_VISIT),
        dbGettr(PHYSICIANS_FILTER_PERIOD_VISIT),
        dbGettr(PHYSICIANS_FILTER_FAVORITES),
        dbGettr(PHYSICIANS_FILTER_PROFILES_VISIT),
        dbGettr(PHYSICIANS_FILTER_FEEDBACKS_VISIT),
        dbGettr(PHYSICIANS_SORT_BY),
        getGlobalLocation,
        prefGettr('fullFavorites'),
        dbGettr(PHYSICIANS_FROM_SERARCH),
        dbGettr('sortByBumbaFeature'),
    ],
    (
        physicians,
        search,
        specialty,
        worksAt,
        period,
        filterByFavorites,
        profiles,
        feedbacks,
        sort,
        locationCode,
        favorites = [],
        physiciansFromSearch,
        sortByBumba = true,
    ) => getActualDoctors(
        physicians,
        search,
        specialty,
        worksAt,
        period,
        filterByFavorites,
        profiles,
        feedbacks,
        sort,
        locationCode,
        (favorites = []),
        physiciansFromSearch,
        (sortByBumba = true),
    ),
);

export const actualDoctorsClinic = createSelector(
    [
        doctorsWithPartnershipAndSumsForLocal,
        getPhysiciansSearchFromClinics,
        dbGettr(PHYSICIANS_FILTER_SPECIALTY_CLINICS),
        dbGettr(PHYSICIANS_FILTER_WORKS_AT_CLINIC),
        dbGettr(PHYSICIANS_FILTER_PERIOD_CLINIC),
        dbGettr(PHYSICIANS_FILTER_FAVORITES),
        dbGettr(PHYSICIANS_FILTER_PROFILES_CLINIC),
        dbGettr(PHYSICIANS_FILTER_FEEDBACKS_CLINIC),
        dbGettr(PHYSICIANS_SORT_BY),
        getGlobalLocation,
        prefGettr('fullFavorites'),
        dbGettr(PHYSICIANS_FROM_SERARCH),
        dbGettr('sortByBumbaFeature'),
    ],
    (
        physicians,
        search,
        specialty,
        worksAt,
        period,
        filterByFavorites,
        profiles,
        feedbacks,
        sort,
        locationCode,
        favorites = [],
        physiciansFromSearch,
        sortByBumba = true,
    ) => getActualDoctors(
        physicians,
        search,
        specialty,
        worksAt,
        period,
        filterByFavorites,
        profiles,
        feedbacks,
        sort,
        locationCode,
        (favorites = []),
        physiciansFromSearch,
        (sortByBumba = true),
    ),
);

export const actualDoctors = createSelector(
    [
        doctorsWithPartnershipAndSumsForLocal,
        getPhysiciansSearch,
        dbGettr(PHYSICIANS_FILTER_SPECIALTY),
        dbGettr(PHYSICIANS_FILTER_WORKS_AT),
        dbGettr(PHYSICIANS_FILTER_PERIOD),
        dbGettr(PHYSICIANS_FILTER_FAVORITES),
        dbGettr(PHYSICIANS_FILTER_PROFILES),
        dbGettr(PHYSICIANS_FILTER_FEEDBACKS),
        dbGettr(PHYSICIANS_SORT_BY),
        getGlobalLocation,
        prefGettr('fullFavorites'),
        dbGettr(PHYSICIANS_FROM_SERARCH),
        dbGettr('sortByBumbaFeature'),
    ],
    // eslint-disable-next-line complexity
    (
        physicians,
        search,
        specialty,
        worksAt,
        period,
        filterByFavorites,
        profiles,
        feedbacks,
        sort,
        locationCode,
        favorites = [],
        physiciansFromSearch,
        sortByBumba = true,
    ) => getActualDoctors(
        physicians,
        search,
        specialty,
        worksAt,
        period,
        filterByFavorites,
        profiles,
        feedbacks,
        sort,
        locationCode,
        (favorites = []),
        physiciansFromSearch,
        (sortByBumba = true),
    ),
);

export const getAllSpecialities = createSelector([dbGettr('physicians.data')], (doctors) => {
    if (!doctors) {
        return [];
    }
    return Object.values(doctors).reduce((p, c) => {
        if (p?.find(e => e.name === c.specialization)) {
            return p;
        }
        return [
            ...p,
            {
                name: c.specialization,
                id: c.specialization,
            },
        ];
    }, []);
});

export const getAllSpecialitiesExcludeHidden = createSelector([dbGettr('physicians.data')], (doctors) => {
    if (!doctors) {
        return [];
    }
    return Object.values(doctors)
        ?.filter?.(({ hidden }) => hidden !== true)
        .reduce((p, c) => {
            if (p?.find(e => e.name === c.specialization)) {
                return p;
            }
            return [
                ...p,
                {
                    name: c.specialization,
                    id: c.specialization,
                },
            ];
        }, []);
});

export const getDoctorsSearchSuggestions = createSelector(
    [getPhysiciansSearch, dbGettr('physicians.data'), dbGettr('clinics.data'), getCurrentLocation],
    (keyword, physicians = {}, clinics = {}, location) => {
        const specialties = Object.values(histogram(Object.values(physicians), normSpecialty));
        const worksAt = Object.values(clinics).filter(({ areaCode }) => areaCode === Number(location?.code));
        const lowerCaseKeyword = keyword?.toLowerCase().trim();
        return {
            specialties: specialties.filter(e => e.key?.toLowerCase()?.includes(lowerCaseKeyword)),
            medcenters: worksAt.filter(e => e.name?.toLowerCase()?.includes(lowerCaseKeyword) && e?.hidden !== true),
        };
    },
);

export const allDoctors = createSelector([dbGettr('physicians.data')], data => data);

export const getEmptyCollections = createSelector(
    [
        dbGettr('physicians.data'),
        dbGettr('physicians_timetable_sum.data'),
        dbGettr('physicians_feedback_sum.data'),
        dbGettr('physicians_prices_sum.data'),
    ],
    (physicians, physiciansTimetableSum, physiciansFeedbackSum, physiciansPricesSum) => {
        const emptyCollectionsNames = [];
        if (!physicians) {
            emptyCollectionsNames.push('physicians');
        }
        if (!physiciansTimetableSum) {
            emptyCollectionsNames.push('physicians_timetable_sum');
        }
        if (!physiciansFeedbackSum) {
            emptyCollectionsNames.push('physicians_feedback_sum');
        }
        if (!physiciansPricesSum) {
            emptyCollectionsNames.push('physicians_prices_sum');
        }
        return emptyCollectionsNames;
    },
);

export const getDoctorsAttributes = createSelector([actualDoctors], (d) => {
    if (!d) {
        return {};
    }
    return Object.values(d).reduce(
        (p, c) => ({
            ...p,
            [c?.fullName ?? 'NA']: c,
        }),
        {},
    );
});

export const getFiltersAdditionalInfo = createSelector(
    [dbGettr('specializationForApi'), getCurrentLocation],
    (spec, location) => ({
        specialtyForApi: spec,
        areaCode: location?.code,
    }),
);

export const getFiltersAdditionalInfoClinic = createSelector(
    [dbGettr('specializationForApiClinic'), getCurrentLocation],
    (spec, location) => ({
        specialtyForApi: spec,
        areaCode: location?.code,
    }),
);

export const getFiltersAdditionalInfoVisit = createSelector(
    [dbGettr('specializationForApiVisit'), getCurrentLocation],
    (spec, location) => ({
        specialtyForApi: spec,
        areaCode: location?.code,
    }),
);

const mapPhysiciansForCurrentLocation = (physicians = {}, clinics, location, clinicFilters) => Object.values(physicians).filter(
    e => (e.worksAt || []).some(
        ({ id }) => (clinicFilters?.worksAt?.some(cId => cId === id) || !clinicFilters?.worksAt?.length) &&
                    clinics[id]?.areaCode === location,
    ) &&
            !e.hidden &&
            !e.deleted,
);

export const getPhysiciansInCurrentLocation = createSelector(
    [dbGettr('physicians.data'), dbGettr('clinics.data'), getGlobalLocation, getPhysiciansFilter],
    (physicians = {}, clinics, location, clinicFilters) => mapPhysiciansForCurrentLocation(physicians, clinics, location, clinicFilters),
);

export const getPhysiciansInCurrentLocationAllClinics = createSelector(
    [dbGettr('physicians.data'), dbGettr('clinics.data'), getGlobalLocation],
    (physicians = {}, clinics, location) => mapPhysiciansForCurrentLocation(physicians, clinics, location, []),
);

export const getPhysiciansInCurrentLocationForClinic = createSelector(
    [dbGettr('physicians.data'), dbGettr('clinics.data'), getGlobalLocation, getPhysiciansFilterClinic],
    (physicians = {}, clinics, location, clinicFilters) => mapPhysiciansForCurrentLocation(physicians, clinics, location, clinicFilters),
);

export const getPhysiciansInCurrentLocationForVisit = createSelector(
    [dbGettr('physicians.data'), dbGettr('clinics.data'), getGlobalLocation, getPhysiciansFilterVisit],
    (physicians = {}, clinics, location, clinicFilters) => mapPhysiciansForCurrentLocation(physicians, clinics, location, clinicFilters),
);

export const getPhysiciansInCurrentLocationForClinicAllClinic = createSelector(
    [dbGettr('physicians.data'), dbGettr('clinics.data'), getGlobalLocation],
    (physicians = {}, clinics, location) => mapPhysiciansForCurrentLocation(physicians, clinics, location, []),
);

export const getPhysiciansInCurrentLocationForVisitAllClinic = createSelector(
    [dbGettr('physicians.data'), dbGettr('clinics.data'), getGlobalLocation],
    (physicians = {}, clinics, location) => mapPhysiciansForCurrentLocation(physicians, clinics, location, []),
);

const getDictionary = (words, getTitle) => {
    return words.reduce((dic, word) => {
        const title = getTitle(word)?.[0];
        const firstLetter = title[0]?.toUpperCase();
        if (!dic[firstLetter]) {
            dic[firstLetter] = [
                {
                    title,
                    value: word,
                },
            ];
        } else {
            dic[firstLetter].push({
                title,
                value: word,
            });
        }
        return dic;
    }, {});
};

export const getSpecDictionary = (physicians = []) => {
    const spec = arrayToHash(physicians, 'specialization');
    const specialtiesKeys = Object.keys(spec);
    const dic = getDictionary(specialtiesKeys, normSpecialtyString);
    const values = Object.values(dic)?.sort((a, b) => a[0]?.title?.charCodeAt(0) - b[0]?.title?.charCodeAt(0));
    const sorted = values.map(d => ({
        data: d?.sort((a, b) => {
            const titleA = a.title?.toLowerCase();
            const titleB = b.title?.toLowerCase();

            if (titleA < titleB) {
                return -1;
            } else if (titleA > titleB) {
                return 1;
            }
            return 0;
        }),
    }));
    const indexOfRussianSpec = sorted?.findIndex(e => /^[А-Яа-я]/.test(e.data[0]?.title[0]));
    const dictionary =
        indexOfRussianSpec > 0 ? [...sorted.slice(indexOfRussianSpec), ...sorted.slice(0, indexOfRussianSpec)] : sorted;
    const dictionaryArr = [];
    dictionary.forEach(e => dictionaryArr.push(
        ...e.data.map((item, i) => (i > 0
            ? item
            : {
                ...item,
                isFirst: true,
            })),
    ));
    return dictionaryArr;
};

export const getSpecialitysDictionary = createSelector([getPhysiciansInCurrentLocation], p => getSpecDictionary(p));

export const getSpecialitysDictionaryAllClinics = createSelector([getPhysiciansInCurrentLocationAllClinics], p => getSpecDictionary(p));

export const getSpecialitysDictionaryForClinic = createSelector([getPhysiciansInCurrentLocationForClinic], p => getSpecDictionary(p));

export const getSpecialitysDictionaryForVisit = createSelector([getPhysiciansInCurrentLocationForVisit], p => getSpecDictionary(p));

export const getSpecialitysDictionaryForClinicAllClinics = createSelector(
    [getPhysiciansInCurrentLocationForClinicAllClinic],
    p => getSpecDictionary(p),
);

export const getSpecialitysDictionaryForVisitAllClinics = createSelector(
    [getPhysiciansInCurrentLocationForVisitAllClinic],
    p => getSpecDictionary(p),
);

export const getPhysiciansIsLoading = createSelector(
    [gettr('fetching.isLocalPhysiciansFetching')],
    (local, phCollection) => local,
); //  || phCollection

export const getHasFilterByClinic = createSelector([getPhysiciansFilter], filters => !!filters?.worksAt?.length);
export const getHasFilterByClinicClinic = createSelector(
    [getPhysiciansFilterClinic],
    filters => !!filters?.worksAt?.length,
);

export const getHasFilterByClinicVisit = createSelector(
    [getPhysiciansFilterVisit],
    filters => !!filters?.worksAt?.length,
);

export const getHasSpecialityFilter = createSelector([getPhysiciansFilter], filters => !!filters?.specialty);
export const getHasSpecialityFilterClinic = createSelector(
    [getPhysiciansFilterClinic],
    filters => !!filters?.specialty,
);
export const getHasSpecialityFilterVisit = createSelector(
    [getPhysiciansFilterVisit],
    filters => !!filters?.specialty,
);

export const getActualPhysiciansAdvancedSearch = createSelector([getPhysiciansAdvancedSearch], keyword => keyword?.trim());
export const getActualPhysiciansClinicsAdvancedSearch = createSelector(
    [getPhysiciansClinicsAdvancedSearch],
    keyword => keyword?.trim(),
);
export const getActualPhysiciansVisitsAdvancedSearch = createSelector([getPhysiciansVisitsAdvancedSearch], keyword => keyword?.trim());

const getPhysiciansAdvSearchRes = (physicians = {}, keyword) => {
    const physiciansArr = Object.values(physicians)?.filter(e => !(e.hidden || e.deleted)) || [];
    const spec = arrayToHash(physiciansArr, 'specialization') || {};

    const specializations = filterBySearch(
        Object.keys(spec).map(spc => ({ title: normSpecialtyString(spc)?.[0] })),
        keyword,
        'title',
    );

    const doctorsF = filterBySearch(physiciansArr, keyword);
    const doctors = sortBy(doctorsF, 'foundIn').map(({
        id, fullName, photoUrl, specialization: specialty,
    }) => ({
        id,
        fullName,
        photoUrl,
        specialty,
    }));
    return {
        doctors,
        specializations: sortBy(specializations, 'foundIn'),
    };
};

export const getPhysiciansAdvancedSearchResults = createSelector(
    [dbGettr('physicians.data'), getActualPhysiciansAdvancedSearch],
    getPhysiciansAdvSearchRes,
);

export const getPhysiciansForClinicAdvancedSearchResults = createSelector(
    [dbGettr('physicians.data'), getActualPhysiciansClinicsAdvancedSearch],
    getPhysiciansAdvSearchRes,
);

export const getPhysiciansForVisitAdvancedSearchResults = createSelector(
    [dbGettr('physicians.data'), getActualPhysiciansVisitsAdvancedSearch],
    getPhysiciansAdvSearchRes,
);

export const getDoctorsFromFavorites = createSelector(
    [doctorsWithPartnershipList, getFavoritesFull, getPhysiciansFavoritesSearch],
    (doctors, favorites, search = '') => {
        const favDoctors = doctors.filter(({ id }) => favorites?.some(e => e.id === id));
        return search ? filterBySearch(favDoctors, search) : favDoctors;
    },
);

export const getCountOfDoctorsInFavorites = createSelector([getDoctorsFromFavorites], doctors => doctors?.length);

export const getIsPhysiciansNotSet = createSelector([dbGettr('physicians.data')],ph => isEmpty(ph));
