import React from 'react';
import { PanGestureHandler, State } from 'react-native-gesture-handler';
import { Platform } from './react';

export const SwipeBack = ({
    children, forOS = [], onSwipeBack, enabled = true,
}) => {

    return (Array.isArray(forOS) ? forOS : [forOS])?.find(os => os === Platform.OS) && enabled ? (
        <PanGestureHandler onHandlerStateChange={({ nativeEvent }) => {

            if (nativeEvent?.state === State.END) {

                if (nativeEvent?.translationX > 100) {

                    typeof onSwipeBack === 'function' && onSwipeBack();

                }

            }

        }}>
            {children}
        </PanGestureHandler>
    ) : children;

};
