import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import actions, { navigate } from '../../actions';
import {
    Box,
    CoverableBy,
    FlatList,
    Icon,
    Modal,
    Page,
    Platform,
    React,
    Text,
    Title,
    TouchableOpacity,
    View,
} from '../../common';
import { COLORS } from '../../common/style';
import { getEmptyCollections, getRelatedPrograms, isAllPhysitionsCollectionsUpdated } from '../../selectors';
import { getPriceRangeString } from '../../selectors/utils';
import firebase from '../../services/firebase';
import firebaseService, {
    collections as collectiontToSubscribe,
    dinymicSplitCollectionsToSubscribe,
    pureCollections,
    splitCollectionsSums,
    splitCollectionsToSubscribe,
    splitCollectionsToUpdate,
} from '../../services/firebase';
import { storage } from '../../services/localstorage';
import { preparePrefilters } from '../../services/user.utils';
import { putIntoStore } from '../../store';
import {
    arrayToHash, capitalize, getDefaultNearestDate, isEmpty, toDate,
} from '../../utils';
import { CURRENT_SERVER_TIMEZONE_OFFSET } from '../../utils/dates';
import { doctorFilterParams } from './DoctorsSpecialtiesSearch';
import { AgeBadge } from './snippets';
import { details as styles } from './style';

export const sortClinics = (clinicList = []) => {

    return clinicList.sort((a, b) => {

        if (a?.hasBumba !== b?.hasBumba) {

            return a?.hasBumba ? -1 : 1;

        }
        if (a?.connectivityStatus !== b?.connectivityStatus) {

            return a?.connectivityStatus === 'online' ? -1 : 1;

        }
        return a?.name > b?.name ? 1 : -1;

    });

};

export const useHasActualRelatedPrograms = () => {

    const programs = useSelector(getRelatedPrograms);
    const programsList = Object.values(programs);
    return programsList.length > 1 || programsList.some(({ id }) => id !== '*');

};

export const getHandledAgesFromWorkPlace = (worksAt) => {

    if (!worksAt) {

        return [];

    }
    const { patientLowerAge, patientUpperAge } = worksAt;

    let handledAges = [];
    if (patientLowerAge >= 18 || !patientUpperAge || patientUpperAge > 18) {

        handledAges = [
            {
                title: '18+',
                isForChild: false,
            },
        ];

    }
    if (patientLowerAge < 18) {

        handledAges.push({
            title:
                patientUpperAge === null || patientUpperAge === 18 || patientUpperAge === undefined
                    ? `${patientLowerAge || 0}+`
                    : Object.R('titles.doctorAgeHandlingInterval', {
                        from: `${patientLowerAge || 0}+`,
                        to: patientUpperAge,
                    }),
            isForChild: true,
        });

    }
    return handledAges;

};

export const toVisit = async (doctorInfo, mcId) => {

    const { worksAt } = doctorInfo;
    const navigateToDatePick = async (mdInfo) => {

        let phones = Array.isArray(mdInfo) ? mdInfo[0]?.phones : mdInfo?.phones;
        const { clinicId } = mdInfo;
        if (!phones) {

            try {

                const data = await firebase.getById('clinics', clinicId);
                phones = data?.phone?.split(';').map(e => e.trim()) ?? [];

            } catch (e) {

                phones = [];

            }
            mdInfo.phones = phones;

        }
        if (mdInfo.notification) {

            navigate('MedCenterRecord', { mdInfo });

        } else {

            if (Platform.OS === 'web') {

                actions.setSelectedService();

            }
            navigate('DoctorRecordDatePick', {
                mdInfo,
                doctorInfo,
            });

        }

    };
    if (worksAt.length === 1) {

        navigateToDatePick(worksAt[0]);

    } else if (mcId) {

        const mc = worksAt?.find(e => e.id === mcId);
        if (mc) {

            navigateToDatePick(mc);

        }

    } else if (worksAt.length > 1) {

        Page.showModal(
            <Modal title="titles.several_medical_centers" subtitle="titles.choose_a_medical_center">
                <FlatList
                    data={sortClinics(worksAt)}
                    keyExtractor={e => e.id}
                    renderItem={({
                        item,
                        item: {
                            id,
                            name,
                            nearestAvailableDate,
                            nearestTimeslotAvailable,
                            coverer,
                            connectivityStatus = '',
                            priceRange: { priceRangeString } = {},
                            hasBumba,
                        },
                        onItem = () => Page.closeModal(() => navigateToDatePick(item)),
                        handledAgesInterval = getHandledAgesFromWorkPlace(item),
                    }) => {

                        let nearest = nearestAvailableDate;
                        if (!nearest && nearestTimeslotAvailable) {

                            try {

                                nearest = new Date(nearestTimeslotAvailable);

                            } catch {
                                //
                            }

                        }
                        return (
                            <TouchableOpacity
                                key={id}
                                onPress={onItem}
                                style={{
                                    ...styles.modalButton,
                                    borderRadius: 3,
                                    borderRightWidth: 5,
                                    borderTopColor: '#E9E9E9',
                                    borderRightColor: COLORS[connectivityStatus.toUpperCase()] || '#0000',
                                    borderColor: null,
                                }}>
                                <Box
                                    style={{
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginBottom: 8,
                                        marginRight: 12,
                                    }}>
                                    <Text style={styles.selectModalTitle}>
                                        {name}
                                        {hasBumba && (
                                            <Icon.Attach
                                                size={11}
                                                style={{
                                                    paddingTop: 3,
                                                    paddingLeft: 4,
                                                }}
                                                color={COLORS.MAIN}
                                            />
                                        )}
                                    </Text>
                                    <CoverableBy coverer={coverer} />
                                </Box>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginBottom: 3,
                                        marginRight: 12,
                                    }}>
                                    <Box gap={0.5} style={{ alignItems: 'center' }}>
                                        <Icon name="calendar" size={24} color="#96B0E9" />
                                        <Text style={styles.selectModalIcon}>
                                            {capitalize(getDefaultNearestDate(nearest))}
                                        </Text>
                                    </Box>
                                    <Box
                                        gap={0.5}
                                        style={{
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}>
                                        {handledAgesInterval.map(({ title, isForChild }) => (
                                            <AgeBadge title={title} isForChild={isForChild} />
                                        ))}
                                    </Box>
                                </View>
                                {priceRangeString ? (
                                    <Title
                                        bold
                                        style={{
                                            color: COLORS.STANDARD_GRAY,
                                            paddingTop: 6,
                                        }}>{`${Object.R('titles.consultation')} ${priceRangeString}`}</Title>
                                ) : null}
                            </TouchableOpacity>
                        );

                    }}
                />
            </Modal>,
        );

    }

};

export const gotoFilters = (from = 'default', mdInfo) => {

    preparePrefilters(from);
    navigate('DoctorFilters', { from, mdInfo });

};

export const resetDoctorsFilters = (newFilters = {}, filtersToSave = {}, from = 'default') => {

    const filterParams = doctorFilterParams[from] ?? doctorFilterParams.default;
    const defaultFilters = {
        specialty: '',
        worksAt: [],
        profiles: '0',
        feedbacks: false,
        time: '0',
        dates: {},
    };
    putIntoStore(filterParams.scheme, {
        ...defaultFilters,
        ...newFilters,
    });
    putIntoStore(filterParams.physiciansSearchScheme, undefined);
    putIntoStore(filterParams.specializationForApi, undefined);
    actions.updateDocFilters(
        {
            worksAt: filtersToSave.worksAt || [],
            worksAtGroup: filtersToSave.worksAtGroup || 'any',
        },
        from === 'default' ? '' : from,
    );

};

const subscribeOnCollection = (collections) => {

    collectiontToSubscribe.push(...collections);
    collections.forEach(coll => firebase.setAndUpdateCollection(
        coll,
        (collName, data) => actions.syncCollection(`${collName}.data`, { ...data }),
        actions.stopCollectionFetching,
        actions.isAllCollectionFetching,
    ),); // , actions.collectionFetchingTime));

};

const getCollection = (collections) => {

    // collectiontToSubscribe.push(...collections)
    collections.forEach(coll => firebase.updateCollection(
        coll,
        (collName, data) => actions.syncCollection(`${collName}.data`, { ...data }),
        actions.stopCollectionFetching,
        actions.isAllCollectionFetching,
        collections?.some(e => e === 'physicians') ? actions.physiciansCollectionsFetching : undefined,
    ),); // , actions.collectionFetchingTime));

};

const getDinymicCollection = (collections) => {

    // pureCollections.push(...collections);
    collections.forEach(coll => firebase.pureSubscribeOnCollection(
        coll,
        (collName, data) => actions.syncCollection(`${collName}.data`, { ...data }),
        actions.stopCollectionFetching,
    ),); // , actions.collectionFetchingTime));

};

const subscribeOnDinymicCollectuon = (collections) => {

    pureCollections.push(...collections);
    collections.forEach(coll => firebase.pureSubscribeOnCollection(
        coll,
        (collName, data) => actions.syncCollection(`${collName}.data`, { ...data }),
        actions.stopCollectionFetching,
    ),); // , actions.collectionFetchingTime));

};

export const setPhysiciansCollection = async () => {

    actions.localPhysiciansFetching(true);
    const { lastMax = -1, data = {} } = await storage.getObject('physicians');
    firebase.collectionsData.physicians = {
        data,
        lastMax,
    };
    if (!isEmpty(data)) {

        actions.syncCollection(`physicians.data`, { ...data });

    }
    actions.localPhysiciansFetching(false);

};

export const fetchDoctorsAndAdditionalInfo = () => {

    const collections = useSelector(getEmptyCollections);
    useEffect(() => {

        if (collections.length) {

            if (collections.find(e => e === 'physicians')) {

                setPhysiciansCollection();

            } else if (
                ![...collectiontToSubscribe, ...pureCollections].find(
                    collName => collName === 'physicians_timetable_sum',
                )
            ) {

                subscribeOnDinymicCollectuon(['physicians_timetable_sum']);
                subscribeOnCollection(['physicians_prices_sum', 'physicians_feedback_sum']);

            }

        }

    }, [collections]);

};

// export const fetchPhysicians = () => {
//     const collections = useSelector(getEmptyCollections);
//     const isDoctorsFetched = useSelector(isAllPhysitionsCollectionsUpdated);
//     useEffect(() => {
//         if (collections.length) {
//             if (collections.find(e => e === 'physicians')) {
//                 setPhysiciansCollection();
//                 subscribeOnCollection(['physicians']);
//             }
//         }
//     }, [collections, isDoctorsFetched]);
// };

export const fetchOnlyPhysicians = (isAuth) => {

    const collections = useSelector(getEmptyCollections);
    const isDoctorsFetched = useSelector(isAllPhysitionsCollectionsUpdated);
    useEffect(() => {

        if (collections.length && isAuth) {

            if (collections.find(e => e === 'physicians')) {

                setPhysiciansCollection();
                getCollection(['physicians']);

            }

        }

    }, [collections, isDoctorsFetched, isAuth]);

};

const needToGetSplitCollection = name => !splitCollectionsToUpdate.some(collName => collName === name);
const needToSubscribeSplitCollection = name => !splitCollectionsToSubscribe.some(collName => collName === name);
const needToSubscribeDinymicSplitCollection = name => !dinymicSplitCollectionsToSubscribe.some(collName => collName === name);
const needToGetSplitCollectionSum = name => !splitCollectionsSums.some(collName => collName === name); // dinymicSplitCollectionsToSubscribe

export const fetchPhysicians = () => {

    const collections = useSelector(getEmptyCollections);
    const isDoctorsFetched = useSelector(isAllPhysitionsCollectionsUpdated);
    useEffect(() => {

        if (collections.length) {

            if (collections.find(e => e === 'physicians')) {

                setPhysiciansCollection();
                getCollection(['physicians']);

            } else if (
                ![...collectiontToSubscribe, ...pureCollections].find(
                    collName => collName === 'physicians_timetable_sum',
                )
            ) {

                subscribeOnDinymicCollectuon(['physicians_timetable_sum']);
                getCollection(['physicians_prices_sum', 'physicians_feedback_sum']);

            }

        }

    }, [collections, isDoctorsFetched]);

};

const specialtyRelatedSubscribe = (location, spec) => {

    if (spec) {

        if (needToGetSplitCollection(`${'physicians_prices'}.${location}.${spec}`)) {

            firebaseService.getSplitCollection('physicians_prices', location, spec, (collName, data) => actions.syncCollection(`${collName}.data`, { ...data }),);

        }
        if (needToSubscribeSplitCollection(`${'physicians_feedback_counters'}.${location}.${spec}`)) {

            firebaseService.subscribeOnSplitCollection(
                'physicians_feedback_counters',
                location,
                spec,
                (collName, data) => actions.syncCollection(`${collName}.data`, { ...data }),
            );

        }
        if (needToGetSplitCollectionSum(`${'physicians_nta_sum'}.${location}.${spec}`)) {

            firebaseService.getSplitCollectionSum(
                'physicians_nta_sum',
                location,
                spec,
                'ntaByAssignment',
                (collName, data) => actions.syncCollection(`${collName}.data`, { ...data }),
                actions.isAllCollectionFetching,
            );

        }
        if (needToSubscribeDinymicSplitCollection(`${'physicians_nta'}.${location}.${spec}`)) {

            firebaseService.subscribeOnDinymicSplitCollection(
                'physicians_nta',
                location,
                spec,
                (collName, data) => {

                    actions.syncCollection(`${collName}.data`, { ...data });

                },
                firebaseService.splitCollectionsData[`${'physicians_nta_sum'}.${location}.${spec}`]?.lastMax,
            );

        }

    }

};

export const fetchPhysiciansData = (location, spec) => {

    const collections = useSelector(getEmptyCollections);
    useEffect(() => {

        if (collections.length) {

            if (collections.find(e => e === 'physicians')) {

                setPhysiciansCollection();
                getCollection(['physicians']);

            } else if (spec) {

                if (Array.isArray(spec)) {

                    spec?.forEach(s => specialtyRelatedSubscribe(location, s));

                } else {

                    specialtyRelatedSubscribe(location, spec);

                }

            }

        }

    }, [collections, splitCollectionsToUpdate?.length, spec]);

};

export const fetchPhysiciansSplitData = (location, spec, hasData) => {

    useEffect(() => {

        if (hasData && spec && location) {

            if (Array.isArray(spec)) {

                spec?.forEach(s => specialtyRelatedSubscribe(location, s));

            } else {

                specialtyRelatedSubscribe(location, spec);

            }

        }

    }, [location, spec, hasData, splitCollectionsToUpdate?.length]);

};

export const fetchDoctorsSums = () => {

    // InteractionManager.runAfterInteractions(() => getDinymicCollection(['physicians_timetable_sum']));
    // InteractionManager.runAfterInteractions(() => getCollection(['physicians_prices_sum', 'physicians_feedback_sum']));
    // InteractionManager.runAfterInteractions(() => storage.set('physicians', {
    //     data: firebaseService.collectionsData?.['physicians']?.data,
    //     lastMax: firebaseService.collectionsData?.['physicians']?.lastMax,
    // }));
    // subscribeOnDinymicCollectuon(['physicians_timetable_sum']);
    getCollection(['physicians_prices_sum', 'physicians_feedback_sum']);

};

export const fetchDoctors = async () => {

    const collections = useSelector(getEmptyCollections);
    const isDoctorsFetched = useSelector(isAllPhysitionsCollectionsUpdated);
    useEffect(() => {

        if (collections.length) {

            if (collections.find(e => e === 'physicians')) {

                setPhysiciansCollection();

            } else if (
                ![...collectiontToSubscribe, ...pureCollections].find(
                    collName => collName === 'physicians_timetable_sum',
                )
            ) {

                subscribeOnDinymicCollectuon(['physicians_timetable_sum']);
                subscribeOnCollection(['physicians_prices_sum', 'physicians_feedback_sum']);

            }

        }
        if (
            isDoctorsFetched &&
            ![...collectiontToSubscribe, ...pureCollections].find(collName => collName === 'physicians')
        ) {

            subscribeOnCollection(['physicians']);

        }
        // if (collections.length) {
        //     if (collections.find(collName => collName === 'physicians' && !collectiontToSubscribe.find(coll => coll === 'physicians'))) {
        //         subscribeOnCollection(['physicians']);
        //         // subscribeOnDinymicCollectuon(['physicians_timetable_sum']);
        //         // subscribeOnCollection(['physicians_prices_sum', 'physicians_feedback_sum']);
        //     } else if (!collectiontToSubscribe.find(collName => collName === 'physicians_timetable_sum')) {
        //         subscribeOnDinymicCollectuon(['physicians_timetable_sum']);
        //         subscribeOnCollection(['physicians_prices_sum', 'physicians_feedback_sum']);
        //     }
        // }

    }, [collections, isDoctorsFetched]);

};

export const fetchLocalDoctors = async (needFetch = true) => {

    const collections = useSelector(getEmptyCollections);
    useEffect(() => {

        if (collections.length && collections.find(e => e === 'physicians') && needFetch) {

            setPhysiciansCollection();

        }

    }, [collections]);

};

export const getDoctorWithNTA = (timetb = {}, doc) => {

    const nearestAvailableDates = arrayToHash(timetb.nearest_available_dates, 'clinic_id', 'nearest_available_date');
    const r = Object.create(doc);
    r.nearestDate = null;
    r.worksAt = doc.worksAt ? [...doc.worksAt] : [];
    r.worksAt.forEach((job) => {

        const nearestJobDate = toDate(nearestAvailableDates[job.id]);
        Object.assign(job, {
            nearestAvailableDate: nearestJobDate,
            connectivityStatus: job.onlineConnectivity ? 'online' : 'unavailable',
            priceRange: r.priceRange?.[job.id],
        });
        if (nearestJobDate && (!r.nearestDate || nearestJobDate < r.nearestDate)) {

            r.nearestDate = new Date(nearestJobDate.getTime());

        }

    });
    return r;

};

export const useUpdateDoctorFeedback = (doctorId, needUnsubscribe = true) => {

    useEffect(() => {

        let unsub;
        if (doctorId) {

            unsub = firebase?.subscribeOnDocChanges?.(`physicians_feedback_sum`, doctorId, (snapshot) => {

                try {

                    snapshot && putIntoStore('currentDoctorFeedbacks', snapshot.data());

                } catch (e) {
                    // console.log('Error unsubscribe', e);
                }

            });

        }
        return unsub && needUnsubscribe
            ? () => {

                unsub();
                putIntoStore('currentDoctorFeedbacks', null);

            }
            : () => {};

    }, [doctorId]);

};

export const getPriceRangeStringFromArr = (prices = []) => {

    const pr = prices.filter?.(e => e);
    const min = Math.min(...pr);
    const max = Math.max(...pr);
    return getPriceRangeString(min, max);

};

// const dateAndTimeToUTCString = (date, time) => {
//
//     try {
//
//         const [year, month, day] = date.split('-').map(Number);
//         const [hour, min] = time.split(':').map(Number);
//         const localDate = new Date(year, month - 1, day, hour, min);
//         return localDate.toISOString()?.replace?.('.000Z', 'Z');
//
//     } catch (e) {
//
//         console.log('Error convert strings to UTC date string', e);
//         const localDate = new Date();
//         return localDate.toISOString()?.replace?.('.000Z', 'Z');
//
//     }
//
// };
//
const dateToUTCString = (date, isStart = true) => {

    try {

        const [year, month, day] = date.split('-').map(Number);
        const localDate = new Date();
        localDate.setUTCDate(day);
        localDate.setUTCMonth(month - 1);
        localDate.setUTCFullYear(year);
        if (isStart) {

            localDate.setUTCHours(CURRENT_SERVER_TIMEZONE_OFFSET / 60, 0, 0, 0);

        } else {

            localDate.setUTCHours(23 + CURRENT_SERVER_TIMEZONE_OFFSET / 60, 59, 0, 0);

        }
        return localDate.toISOString()?.replace?.('.000Z', 'Z');

    } catch (e) {

        console.log('Error convert strings to UTC date string', e);
        const localDate = new Date();
        return localDate.toISOString()?.replace?.('.000Z', 'Z');

    }

};

export const timePeriods = [
    {
        timeStart: '00:00',
        timeEnd: '23:59',
    },
    {
        timeStart: '06:00',
        timeEnd: '10:00',
    },
    {
        timeStart: '10:00',
        timeEnd: '14:00',
    },
    {
        timeStart: '14:00',
        timeEnd: '18:00',
    },
    {
        timeStart: '18:00',
        timeEnd: '22:00',
    },
];

const convertVTimeToUTC = (vTime) => {

    try {

        const [hour, min] = vTime.split(':').map(Number);
        const localDate = new Date();
        localDate.setHours(hour, min, 0, 0);
        const utcHours = localDate.getUTCHours();
        const utcMinutes = localDate.getUTCMinutes();

        return `${utcHours.toString().padStart(2, '0')}:${utcMinutes.toString().padStart(2, '0')}`;

    } catch (e) {

        return vTime;

    }

};

export const getRequestDates = ({ dateStart, dateEnd, timeRange = '0' }) => {

    // let startDate = new Date().toISOString().replace?.('.000Z', 'Z');
    // let endDate = new Date().toISOString().replace?.('.000Z', 'Z');
    let startDate;
    let endDate;
    let startTime;
    let endTime;
    try {

        const time = timePeriods[Number(timeRange)];
        startTime = time.timeStart;
        startDate = dateToUTCString(dateStart, true);
        endTime = time.timeEnd;
        endDate = dateToUTCString(dateEnd ?? dateStart, false);

    } catch (e) {

        console.log('Error get request dates', e);

    }
    return Object.entries({
        dateStart: startDate,
        dateEnd: endDate,
        timeStart: timeRange > 0 && convertVTimeToUTC(startTime),
        timeEnd: timeRange > 0 && convertVTimeToUTC(endTime),
    })?.reduce((prev, [k, v]) => {

        if (v) {

            return {
                ...prev,
                [k]: v,
            };

        }
        return prev;

    }, {});

};
