import { useNavigation, useNavigationState } from '@react-navigation/native';
import {
    List, Page, React, Section,
} from '../../common';
import { PhoneItem } from '../../combo';
import { getRouteHistory } from '../../utils/getRouteHistory';
import actions from '../../actions';
import { AllDoctorsSection } from './snippets/AllDoctorsSection';

export const MedCenterRecordFull = Page.register(
    ({
        mdInfo: { phones, name, _id }, onPress, byOnlineBooking, from = 'default',
    }) => {
        const routes = getRouteHistory(useNavigationState(s => s));
        const nav = useNavigation();
        actions.setFormData({
            MedCenterRecord: {
                back: () => {
                    try {
                        if (nav.getState().routeNames[0] === 'DoctorRecordDatePick') {
                            nav.navigate('DoctorRecordDatePick');
                            return false;
                        }
                        nav.goBack();
                        // return true;
                    } catch (e) {
                        //
                    }
                },
            },
        });
        const additionalProps = byOnlineBooking ? { trackingAlias: 'tel_registry_ums' } : {};
        return (
            <Page name="medcenter-record" hint="hints.offline-medcenter-details-record">
                <Section title="titles.registry" params={{ name }} containerStyles={{ height: 40 }} numberOfLines={2}>
                    <List
                        type="contacts"
                        data={phones}
                        renderItem={PhoneItem}
                        details={{
                            clinicId: _id,
                            journey: routes,
                        }}
                        style={{ marginTop: 5 }}
                        {...additionalProps}
                    />
                </Section>
                <AllDoctorsSection
                    onPress={() => {
                        onPress?.(from);
                    }}
                />
            </Page>
        );
    },
);
