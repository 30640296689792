import { ListItem } from 'react-native-elements';
import { useNavigationState } from '@react-navigation/native';
import { input as inputStyles } from '../styles/input';
import { getEnums } from '../i18n';
import { getRouteHistory } from '../utils/getRouteHistory';
import {
    FlatList, Keyboard, PureComponent, React, S, Text, View,
} from './react';
import {
    Box, boxStyleResolver, LoadingIndicator, Panel, RText, Section, Subtitle, Title, VBox,
} from './elements';
import { ErrorStub, Stub } from './Stub';
import { navigationList as styles } from './style';
import { Icon } from './Icon';
import { CoverableBy } from './Img';

const renderItemDefault =
    onItem => ({ item, item: { name, right, subtitle } }) => (
        <Box
            ns="listitem"
            flex
            justify="space-between"
            style={[styles.container]}
            pressHandler={() => onItem(item)}>
            <VBox style={right ? { width: '90%' } : {}}>
                <Title>{name}</Title>
                {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
            </VBox>
            <VBox centered>
                {right || null}
                <View />
            </VBox>
        </Box>
    );

const keyExtractorFn = (...params) => params.filter(Boolean).join('_');
const keyExtractorDefault = ({
    _id, id, lastModifiedAt, code, subType,
}, index) => keyExtractorFn(_id, id, lastModifiedAt, code, subType, index);

export class List extends PureComponent {
    state = {};

    async applyDataFunc(fn) {
        try {
            const data = await fn();
            this.dataFromState = true;
            this.setState({
                data,
                error: null,
            });
        } catch (error) {
            this.dataFromState = true;
            this.setState({ error });
        }
    }

    /* eslint-disable complexity */
    render() {
        const {
            type,
            noscroll,
            extraData,
            isLoading,
            ListEmptyComponent,
            details,
            renderItem,
            onItem,
            style,
            keyExtractor = keyExtractorDefault,
            emptyStyle,
            trackingAlias,
            noSpinner,
            ...restProps
        } = this.props;

        const renderItemWithDetails = item => renderItem({
            ...item,
            trackingAlias,
            details,
        }) || renderItemDefault(onItem);
        const data = this.dataFromState ? this.state.data : this.props.data;
        const error = this.dataFromState ? this.state.error : this.props.error;
        this.dataFromState = false;
        if (error) {
            return <ErrorStub error={error} support />;
        }
        if (!data || isLoading) {
            if (!noSpinner) {
                return <LoadingIndicator />;
            }
        }
        if (typeof data === 'function') {
            this.applyDataFunc(data);
            if (!noSpinner) {
                return <LoadingIndicator />;
            }
        }
        return data.length || noSpinner ? (
            <View
                accessibilityLabel={type || 'list'}
                style={[noscroll ? null : { flex: 1 }, boxStyleResolver(this.props), style]}
                onStartShouldSetResponder={() => true}>
                <FlatList
                    {...restProps}
                    data={data}
                    extraData={extraData}
                    keyExtractor={keyExtractor}
                    renderItem={renderItemWithDetails}
                />
            </View>
        ) : (
            ListEmptyComponent || <Stub type={`empty-${type || 'default'}`} style={emptyStyle} />
        );
    }
}

export const NavigationItem = ({
    firstItem = true,
    noPadding = false,
    title,
    onItem,
    rightIcon,
    titleStyle,
    titleWrapperStyle,
    wrapperStyle,
    containerStyle,
    subtitle,
    trackingId,
    ...props
}) => {
    return (
        <ListItem
            wrapperStyle={[
                {
                    paddingHorizontal: 0,
                    paddingVertical: 0,
                },
                wrapperStyle,
            ]}
            containerStyle={[
                {
                    paddingLeft: 12,
                    borderWidth: 0,
                    backgroundColor: '#ffffff',
                    borderColor: '#E0E0E0',
                    borderTopWidth: firstItem ? 1 : 0,
                    borderBottomWidth: 1.2,
                    marginVertical: 0,
                },
                containerStyle,
            ]}
            onPress={() => {
                onItem?.();
            }}
            {...props}>
            <ListItem.Content style={noPadding ? { alignItems: undefined } : { paddingHorizontal: 11 }}>
                <ListItem.Title style={{ ...titleWrapperStyle }}>
                    {typeof title === 'string' ? (
                        <Text style={[styles.navigationItemTitle, titleStyle]}>{Object.R(title)}</Text>
                    ) : (
                        title
                    )}
                </ListItem.Title>
                {subtitle ? (
                    <ListItem.Subtitle>
                        {typeof subtitle === 'string' ? Object.R(subtitle) : subtitle}
                    </ListItem.Subtitle>
                ) : null}
            </ListItem.Content>
            {rightIcon || <Icon.Right color="#bec7d0" style={{ marginRight: 7 }} />}
        </ListItem>
    );
};

const combineEnums = (param) => {
    if (Array.isArray(param)) {
        return param.reduce((prev, curr) => {
            return [...prev, ...getEnums(curr)];
        }, []);
    }
    return getEnums(param);
};

export const NavigationList = ({
    filtredFields = [],
    dataEnum,
    data = dataEnum
        ? (combineEnums(dataEnum) || []).filter(el => !filtredFields.some(e => e === el.id))
        : [{ name: '' }],
    onItem,
    rightIcon,
    onTitle,
    trackingId,
    trackingAlias,
    trackingParams = {},
    ...props
}) => {
    const navState = useNavigationState(s => s);
    return (
        <View
            accessibilityLabel="button:navigator"
            style={{
                marginTop: 0,
                marginBottom: 0,
            }}>
            {data.map((e, i) => (
                <React.Fragment key={e?.id}>
                    <NavigationItem
                        firstItem={i === 0}
                        title={onTitle ? onTitle(e) ?? `${e.name}` : `${e.name}`}
                        key={e.id}
                        onItem={() => {
                            Object.trackAction(
                                trackingId || `${Array.isArray(dataEnum) ? dataEnum.join('_') : dataEnum}_${e?.id}`,
                                {
                                    trackingAlias,
                                    journey: getRouteHistory(navState),
                                    item: e?.id,
                                    ...trackingParams,
                                },
                                'ai',
                            );
                            onItem(e);
                        }}
                        rightIcon={rightIcon && rightIcon(e)}
                        {...props}
                    />
                </React.Fragment>
            ))}
        </View>
    );
};

export const SingleNavigation = ({
    title,
    onPress,
    placeholder,
    hideIconRight = false,
    rightItem,
    style,
    textStyle,
    placeholderStyle,
    showIndicator = false,
    coverer,
    isLimited = false,
    smallCoverer = false,
    editable = true,
    trackingAlias,
    trackingParams = {},
    trackingId,
}) => {
    const navState = useNavigationState(s => s);
    return (
        <Panel
            style={[S.flexRow__center, inputStyles.input__panel, style, { marginBottom: 0 }]}
            onPress={() => {
                if (editable && onPress) {
                    Object.trackAction(
                        trackingId || title,
                        {
                            trackingAlias,
                            journey: getRouteHistory(navState),
                            ...trackingParams,
                        },
                        'ai_navigation',
                    );
                    onPress();
                }
            }}
            ns="navigator">
            <View
                style={[
                    S.flex1,
                    {
                        flexDirection: 'row',
                        alignItems: 'center',
                    },
                ]}>
                {showIndicator &&
                    (smallCoverer && coverer ? (
                        <CoverableBy.Dot coverer={coverer} containerStyles={{ marginRight: 8 }} isLimited={isLimited} />
                    ) : (
                        <CoverableBy coverer={coverer} containerStyles={{ marginRight: 8 }} isLimited={isLimited} />
                    ))}
                <Text
                    style={[
                        {
                            fontSize: 16,
                            flex: 1,
                        },
                        textStyle,
                        !title && [{ color: '#c9c9c9' }, placeholderStyle],
                    ]}
                    numberOfLines={1}>
                    {title || placeholder}
                </Text>
            </View>
            {hideIconRight
                ? null
                : rightItem || (
                    <View style={{ width: 16 }}>
                        <Icon.Right />
                    </View>
                )}
        </Panel>
    );
};

export const SectionList = (props) => {
    const {
        data,
        title,
        type = 'default',
        onItem,
        ListEmptyComponent,
        noscroll,
        renderItem,
        count,
        right,
        white,
        error,
        isLoading,
        loadingIndicatorStyle,
        sectionStyle,
        listContainerStyle,
        footer,
    } = props;
    if (error) {
        return <ErrorStub error={error} />;
    }
    if (!data || isLoading) {
        return <LoadingIndicator style={loadingIndicatorStyle} />;
    }
    return (
        <Section
            flex
            style={[
                S.borderTop,
                {
                    backgroundColor: white ? 'white' : 'transparent',
                    marginBottom: 0,
                    paddingHorizontal: 20,
                },
                sectionStyle,
            ]}
            ns={type}>
            <View
                style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                {title && (
                    <RText
                        ns={`heading:${title}`}
                        bold
                        id={title}
                        count={count}
                        style={[
                            S.pv,
                            {
                                paddingHorizontal: 0,
                                color: '#555',
                                fontSize: 12,
                            },
                        ]}
                    />
                )}
                {right || null}
            </View>
            <FlatList
                keyboardShouldPersistTaps="handled"
                data={data}
                style={[noscroll ? null : S.flex1, title ? S.borderTop : null, listContainerStyle]}
                keyExtractor={({
                    _id, id, lastModifiedAt, code, subType,
                }, index) => keyExtractorFn(_id, id, lastModifiedAt, code, subType, index)
                }
                accessibilityLabel="list"
                renderItem={renderItem || renderItemDefault(onItem)}
                ListEmptyComponent={ListEmptyComponent || <Stub.Simple type={`empty-${type}`} />}
                onScrollBeginDrag={Keyboard.dismiss}
            />
            {footer || null}
        </Section>
    );
};
