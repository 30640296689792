import { personId6 } from '../const.stub';

export const profile = {
    _id: personId6,
    fullNameRu: 'Несовершеннолетний Тест Тестович',
    primaryPhone: { number: '375291234567'},
    phones: [{ number: '375291234567' }],
    primaryEmail: { address: 'Auto_EPM-AIMD_Test6@epam.com', usage: 'PRIMARY', confirmed: true },
    emails: [{ address: 'Auto_EPM-AIMD_Test6@epam.com', usage: 'PRIMARY', confirmed: true }],
    birthday: `${new Date().getFullYear() - 5}-01-01`,
    sex: 'MALE',
    keys: [{ tag: 'FULL_NAME_RU', value: 'Несовершеннолетний Тест Тестович' }],
    external: {
        'horizont-minsk': {
            id: '81901',
        },
        'lode-minsk': {
            id: '122801',
            validFrom: '2018-09-06T19:39:53.217Z',
        },
    },
    hashKey: personId6,
    storeKey: personId6,
    _updated: { time: '2000-01-01T00:00:00.000Z' }
};

export const coverage = {
    people: [{
        profile: {
            _id: personId6,
            primaryPhone: { number: '375291234567' },
            phones: [{ number: '375291234567' }],
            birthday: `${new Date().getFullYear() - 5}-01-01`,
            sex: 'MALE',
            emails: [{ address: 'Auto_EPM-AIMD_Test6@epam.com', usage: 'PRIMARY', confirmed: true }],
            hashKey: personId6,
            storeKey: personId6,
            _updated: { time: '2000-01-01T00:00:00.000Z' }
        },
        profileId: personId6,
        relKind: 'OWNER'
    }],
    programs: {},
};