import { SCHEME } from '../scheme';
import { getDoctorsList } from './manulaVisit';
import {
    allFeedbackSortOptions, createSelector, dbGettr, gettr,
} from './utils';
import { getIsAuthenticated, getUserInfo } from './getters';
import { doctorsWithFeedbackSums } from './doctors';
import { getCurrentDoctorFeedbacks } from '.';

/**
 * Feedbacks
 */

export const getFeedbacks = store => store.feedbacks;

export const getActualFeedbacks = createSelector(
    [getIsAuthenticated, getFeedbacks, getUserInfo, doctorsWithFeedbackSums, getCurrentDoctorFeedbacks],
    (isAuthenticated, feedbacks, userInfo, sums, feedbackInfo) => {

        const feedbacksSums = {
            feedbacksCount: 0,
            recommendCount: 0,
            notRecommendCount: 0,
            neutralCount: 0,
            recommendationIndex: 0,
            textFeedbackCount: 0,
        };
        if (Array.isArray(sums)) {

            const found = feedbackInfo || sums.find(({ _id }) => _id === feedbacks?.forItemId);
            if (found) {

                feedbacksSums.feedbacksCount = found.feedbacksCount;
                feedbacksSums.textFeedbackCount = found.textFeedbackCount;
                feedbacksSums.neutralCount = found.neutralCount;
                feedbacksSums.recommendCount = found.recommendCount;
                feedbacksSums.notRecommendCount = found.notRecommendCount;
                feedbacksSums.recommendationIndex = found.recommendationIndex;

            }

        } else if (!sums && feedbackInfo) {

            feedbacksSums.feedbacksCount = feedbackInfo.feedbacksCount;
            feedbacksSums.textFeedbackCount = feedbackInfo.textFeedbackCount;
            feedbacksSums.neutralCount = feedbackInfo.neutralCount;
            feedbacksSums.recommendCount = feedbackInfo.recommendCount;
            feedbacksSums.notRecommendCount = feedbackInfo.notRecommendCount;
            feedbacksSums.recommendationIndex = feedbackInfo.recommendationIndex;

        }
        return {
            isAuthenticated,
            feedbacks,
            userInfo,
            feedbacksSums,
        };

    },
);

export const getFeedbackSort = createSelector([dbGettr(SCHEME.PHYSICIANS_FEEDBACK_SORT_BY)], state => state);

export const getFeedbackFormData = createSelector([getFeedbacks], feedbacks => feedbacks?.feedbackFormData);

// noinspection JSUnresolvedVariable
export const getMyVotes = createSelector([gettr('feedbacks')], feedbacks => ({
    physicians: feedbacks?.votes_physicians,
    clinics: feedbacks?.votes_clinics,
}));

export const getMyFeedbacksPhysicians = createSelector(
    [gettr('feedbacks')],
    f => f?.myphysicians ?? {
        count: 0,
        items: [],
    },
);

export const getMyFeedbacksPhysiciansStatuses = createSelector([getMyFeedbacksPhysicians], f => Array.from(new Set((f?.items ?? [])?.map(({ status }) => status))));
export const getMyFeedbacksPhysiciansModerationStatuses = createSelector([getMyFeedbacksPhysicians], f => Array.from(new Set((f?.items ?? [])?.map(({ moderationState }) => moderationState ?? 'ACCEPTED'))));

export const getMyFeedbacksExistingPhysicians = createSelector([gettr('feedbacks'), getDoctorsList], (f, doctors) => {

    const feedbacks = f?.myphysicians?.items ?? [];
    return feedbacks?.filter?.(({ forItemKind, forItemId }) => forItemKind === 'physicians' && doctors?.find(({ id }) => id === forItemId)) ?? [];

});


export const getMyFeedbacksLoading = createSelector([gettr('feedbacks')], f => f?.isLoading ?? false);

export const getAllFeedbacksActivePage = createSelector([gettr('feedbacks')], f => f?.activePage ?? 'my');
export const getAllFeedbacksMyFilter = createSelector([gettr('feedbacks')], f => f?.myFilter ?? 'published');
export const getAllFeedbacksMySpecialityPreFilter = createSelector([gettr('feedbacks')], f => f?.mySpecialitiesPreFilter ?? []);
export const getAllFeedbacksMySpecialityFilter = createSelector([gettr('feedbacks')], f => f?.mySpecialitiesFilter ?? []);
export const getAllFeedbacksMyHasTextFilter = createSelector([gettr('feedbacks')], f => !!f?.myFilterHasText);
export const getAllFeedbacksMyHideNotRecommendFilter = createSelector([gettr('feedbacks')], f => !!f?.myFilterHideNotRecommend);
export const getAllFeedbacksMyHideRecommendFilter = createSelector([gettr('feedbacks')], f => !!f?.myFilterHideRecommend);
export const getAllFeedbacksMyHideNeutralFilter = createSelector([gettr('feedbacks')], f => !!f?.myFilterHideNeutral);
export const getAllFeedbacksMyTextFilter = createSelector([gettr('feedbacks')], (f) => {

    const text = (f?.myTextFilter ?? '').trim();
    if (text.length < 3) {

        return '';

    }
    return text.toLowerCase();

});

export const getAllFeedbacksMySortOrder = createSelector(
    [gettr('feedbacks')],
    (f) => {

        const all = allFeedbackSortOptions();
        const my = f?.mySortOrder;
        if (!my) {

            return all[0];

        }
        const found = all.find(({ id }) => my.id && id === my.id);
        if (found) {

            return found;

        }
        return all[0];

    }
);

export const isNeedToFetchMyPhysicians = createSelector([getMyFeedbacksPhysicians], physicians => physicians?.needFetch !== false);
