import { Button, React } from '../../common';
import { invoiceItem as s } from './style';

export const InvoiceAdaptiveButton = (props) => {
    const {
        stateButton,
        action,
        style,
        params,
    } = props;
    const optionsList = {
        '': {
            style: s.disabledStateStyle,
            title: 'buttons.mark_every_service',
        },
        approved: {
            style: s.approvedStateStyle,
            title: 'buttons.confirm_all_payment',
        },
        rejected: {
            style: s.rejectedStateStyle,
            title: 'buttons.reject_all_payment',
        },
        partly: {
            style: s.partlyStateStyle,
            title: 'buttons.confirm_payment_partially',
        },
    };
    if (params) {
        Object.keys(params)
            .forEach(key => Object.assign(optionsList[key], params[key]));
    }
    const titleStyles = [{
        fontSize: 14,
        color: 'white',
        fontWeight: '600',
    }];
    const options = optionsList[stateButton];
    return (
        <Button styles={[s.commonStateStyle, options.style, style]} action={stateButton === '' ? null : action}
            title={options.title} titleStyles={titleStyles}/>
    );
};
