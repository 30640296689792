/* eslint-disable complexity,no-underscore-dangle */
/* eslint-disable max-statements */
import React, { useCallback, useEffect, useState } from 'react';
import { Keyboard } from 'react-native';
import DeviceInfo from 'react-native-device-info';
import { useDispatch, useSelector } from 'react-redux';
import { useKeyboardHeight } from '../../../../common/Modal';
import { CONVERT_TIMEZONE } from '../../../../config';
import { dbGettr } from '../../../../selectors/utils';
import { modal as stylesModal } from '../../../../styles/common';
import { isMobile } from '../../../../utils/strings';
import { useWindowHeight } from '../VisitorSelector';
import { IS_FOR_CHILD, IS_FOR_ME, IS_OTHER } from './consts';
import {
    AgreemantCheckbox, EmailInput, PatientSelector, SwitchWithLabel, SwitchWithNotification, VisitInfo,
} from './snippets';
import actions from '../../../../actions';
import { addImportedVisit } from '../../../../actions/visits';
import { resetMedCentersFilter } from '../../../../combo';
import {
    Button, ButtonsGroup, Modal2, Page, Platform, ScrollView, TopNotification, TouchableWithoutFeedback, View,
} from '../../../../common';
import {
    getClinicCityIfDifferentFromCurrent, getUserConsentReceived, getUserInfo, getUserMainInfo,
} from '../../../../selectors';
import api from '../../../../services/api';
import { changeDisplayOfWebInstallPopup } from '../../../../services/deeplinks';
import firebase from '../../../../services/firebase';
import user from '../../../../services/user';
import {
    capitalize, formatDate, formatTime,isAdult, isEmpty,
} from '../../../../utils';
import { formatDateTz, parseDateFromDotsToISO } from '../../../../utils/dates';
import { adaptDoctorToFav } from '../../../App/snippets/utils';
import { resetDoctorsFilters } from '../../../Doctors/utils';
import { useNavigation } from '@react-navigation/native';

const adaptVisitData = (
    {
        startDate,
        physicianName,
        branchName,
    },
    { fullNameRu, birthday },
) => {

    const date = CONVERT_TIMEZONE ? formatDateTz(startDate) : formatDate(startDate);
    const time = formatTime(startDate, false, !CONVERT_TIMEZONE);
    const bd = formatDate(birthday);
    return {
        date: `${date} в ${time}`,
        doctor: physicianName,
        name: fullNameRu,
        birthday: bd,
        mc: branchName,
    };

};

const getEmail = (emails = []) => {

    const e = emails.find(el => el.usage === 'PRIMARY');
    return e?.address;

};

const getChildProfileId = (family, patient) => {

    return family?.find(
        el => el.profile?.birthday === patient?.birthday && el.profile?.fullNameRu === patient?.fullNameRu,
    )?.profileId;

};

const adaptToPatch = (profileData, emails, isSave) => {

    return isSave
        ? {
            fullNameRu: profileData.fullNameRu,
            sex: profileData.sex,
            birthday: profileData.birthday,
            emails, // profileData.emails?.find(el=>el?.address ===email) ? profileData.emails: [...profileData.emails, {address:email }]
            consentReceived: true,
        }
        : {
            emails,
            consentReceived: true,
        };

};

const getDataToUpdateUser = (userEmail, email, emails, patientData, userConsent, saveData, isForMe) => {

    const dataToUpdate = {};
    if (userEmail !== email) {

        dataToUpdate.emails = emails;

    }
    if (!userConsent) {

        dataToUpdate.consentReceived = true;

    }
    if (saveData && isForMe) {

        dataToUpdate.fullNameRu = patientData.fullNameRu;
        dataToUpdate.birthday = patientData.birthday;
        dataToUpdate.sex = patientData.sex;

    }
    return dataToUpdate;

};

const getPatientId = (forMe, forOther, forChild, targetProfileId, updatedUserProfileId, childId, isSaveProfileData) => {

    if (forMe && isSaveProfileData) {

        return updatedUserProfileId;

    }
    if (targetProfileId) {

        return targetProfileId;

    }
    if (forChild && isSaveProfileData) {

        return childId;

    }
    return null;

};
// OBW visit


export const NewVisitModal = ({ data }) => {

    Object.alreadyImported = true;
    const dispatch = useDispatch();
    const nav = useNavigation();

    const bookmarkRc = useSelector(dbGettr('bookmarkFeatureOnVisitImport'));
    const {
        order, patientData, omId, targetProfile,
    } = data;
    const adaptedVisitData = adaptVisitData(order, patientData);
    const {
        date, doctor, name, birthday, mc,
    } = adaptedVisitData;
    const originalEmail = getEmail(patientData.emails);
    const {
        userEmail, userBirthday, userFullNameRu, userProfileId, userHasProgram,
    } = useSelector(getUserMainInfo);
    const userInfo = useSelector(getUserInfo);

    const isUserConsentReceived = useSelector(getUserConsentReceived);
    const locationCode = useSelector(state => getClinicCityIfDifferentFromCurrent(state, order?.branchId));

    const [email, setEmail] = useState(userEmail || originalEmail || '');
    const [emailError, setEmailError] = useState(null);
    const [selectedPatient, setSelectedPatient] = useState({});
    const [isSavePatientData, setIsSavePatientData] = useState(true);
    const [isUserAgreementChecked, setIsUserAgreementChecked] = useState(false);
    const [isAdditionalAgreementChecked, setIsAdditionalAgreementChecked] = useState(false);
    const [isChildAgreementChecked, setIsChildAgreementChecked] = useState(false);
    const [doctorData, setDoctorData] = useState({});

    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isChangeCity, setIsChangeCity] = useState(true);

    const isAdultPatient = isAdult(parseDateFromDotsToISO(birthday));
    const [isToBookmark, setIsToBookmark] = useState(true);
    const [objectBookmark] = useState(!!Object.bookMark);

    const isForChild = selectedPatient.id === IS_FOR_CHILD;
    const isForMe = selectedPatient.id === IS_FOR_ME;
    const isForOther = selectedPatient.id === IS_OTHER;
    const hasProfile = !!targetProfile;
    const isTargetProfileConsentReceived = targetProfile && targetProfile?.consentReceived;
    // const isForMeAndHasConsent = !targetProfile && isUserConsentReceived && isForMe;
    // const hidePoliceCheckbox =
    //     isForOther || isTargetProfileConsentReceived || isForMeAndHasConsent || (!targetProfile && !selectedPatient.id);
    // const hideAdditionalAgreementCheckbox =
    //      isUserConsentReceived || (!targetProfile && !selectedPatient.id);
    const hideChildAgreementCheckbox =
        isForOther ||
        isForMe ||
        isAdultPatient ||
        (isTargetProfileConsentReceived && !isAdultPatient) ||
        (!targetProfile && !selectedPatient.id);

    const isPatientSelected = isForChild || selectedPatient.id === IS_FOR_ME;
    const isDisable =
        (!selectedPatient.id && !hasProfile) ||
        emailError ||
        !email?.trim()?.length ||
        (!isUserAgreementChecked && !isUserConsentReceived) ||
        (!isAdditionalAgreementChecked && !isUserConsentReceived) ||
        (!isChildAgreementChecked && !hideChildAgreementCheckbox) ||
        isLoading;

    useEffect(() => {

        if ((isForMe && userBirthday && userFullNameRu && isSavePatientData) || (isForMe && userHasProgram)) {

            setIsSavePatientData(false);

        }

    }, [isForMe]);

    useEffect(() => {

        if (!email && userEmail) {

            setEmail(userEmail);

        }

    }, [userEmail]);

    useEffect(() => {

        Object.orderId = null;
        Object.orderProviderCode = null;
        Object.onNewVisitFound = null;
        Object.onAlreadyImported = null;

    }, []);

    const onClose = (resetNav) => {

        // console.log('onClose', { nav });
        if (resetNav) {

            nav.navigate('Home', { screen: 'Home' });

        }
        Page.closeModal();

    };
    const onSubmit = () => {

        setIsLoading(true);
        (async () => {

            let childProfileId;
            if (isForChild && isSavePatientData) {

                try {

                    const childInfo = await api.addChild({
                        fullNameRu: patientData.fullNameRu,
                        sex: patientData.sex,
                        birthday: patientData.birthday,
                        consentReceived: true,
                    });
                    childProfileId = getChildProfileId(childInfo?.family, patientData);

                } catch (e) {

                    setError(e?.body?.detail || e.code);
                    setIsLoading(false);
                    return;

                }

            }
            // let userProfileId; // = userProfile.birthday && userProfile.fullNameRu ? userProfile._id : null;
            const userMails = await user.getUserEmailsUpdated(email, 'PRIMARY');
            // const dataToSave = adaptToPatch(
            //     patientData,
            //     userMails.map(({ address, usage }) => ({
            //         address,
            //         usage,
            //     })),
            //     isSavePatientData && isForMe,
            // );
            const emails = userMails.map(({ address, usage }) => ({
                address,
                usage,
            }));
            const dataToSave = getDataToUpdateUser(
                userEmail,
                email,
                emails,
                patientData,
                isUserConsentReceived,
                isSavePatientData,
                isForMe,
            );
            const actualUserId = (userBirthday && userFullNameRu) || isSavePatientData ? userProfileId : null;

            const profileId = getPatientId(
                isForMe,
                isForOther,
                isForChild,
                targetProfile?._id,
                actualUserId,
                childProfileId,
                isSavePatientData,
            );
            const visit = await user.importPubVisit(profileId, omId, isSavePatientData && isForMe);
            if (profileId && visit && (!visit?.visitor || Object.keys(visit?.visitor ?? {})?.length === 0)) {

                const currentUser = userInfo?.relatives?.find?.(el => el.profileId === profileId);

                if (currentUser) {

                    visit.visitor = currentUser;

                } else {

                    visit.visitor = {
                        ...patientData ?? {},
                        subName: patientData?.fullNameRu,
                    };

                }

            }
            dispatch(addImportedVisit(visit));
            if (!isEmpty(dataToSave)) {

                const userProfile = await api.updateUserInfo(dataToSave);
                // userProfileId = userProfile.birthday && userProfile.fullNameRu ? userProfile._id : null;

            }

            if (!isTargetProfileConsentReceived && !isAdultPatient && targetProfile?._id) {

                await api.updateChild({
                    consentReceived: true,
                    _id: targetProfile?._id,
                });

            }

            // const profileId = getPatientId(isForMe, isForOther, isForChild, targetProfile?._id, userProfileId, childProfileId, isSavePatientData);
            // if (hasProfile && !isAdultPatient && targetProfile?._id) {
            //     profileId = targetProfile?._id;
            // } else if (!hasProfile && isForChild && childProfileId) {
            //     profileId = childProfileId;
            // } else if (isForMe) {
            //     profileId = isSavePatientData ? userProfileId : null;
            // } else {
            //     profileId = userProfileId;
            // }
            // const profileId = isForChild ? childProfileId : userProfileId;
            // await user.importPubVisit(profileId, omId);
            if (isChangeCity && locationCode) {

                resetDoctorsFilters();
                actions.setLocation(locationCode);
                resetMedCentersFilter();

            }
            try {

                if ((Object.bookMark || bookmarkRc ) && isToBookmark && doctorData && Object.keys(doctorData)?.length) {

                    const full = adaptDoctorToFav(doctorData);
                    actions.addFullFavorites(full);

                }

            } catch (e) {
                //
            }
            onClose(true);
            setIsLoading(false);
            changeDisplayOfWebInstallPopup('block', 5000);

        })();

    };

    useEffect(() => {

        if (!data?.order?.physicianId) {

            return;

        }
        firebase
            .getById('physicians', data?.order?.physicianId)
            .then(d => setDoctorData(d))
            .catch(() => setDoctorData(null));

    }, [data?.order?.physicianId]);

    const [contentHeight, setContentHeight] = useState(0);
    const [scrollHeight, setScrollHeight] = useState(0);

    const windowHeight = useWindowHeight();

    const platforms = ['ios', 'android'];
    if (Platform.OS === 'web' && isMobile()) {

        platforms.push('web');

    }
    const keyboardHeight = useKeyboardHeight(platforms);

    const diff = 35;

    const keyDiffSize = Platform.OS === 'web' && isMobile() ? diff : diff;
    // const MAX_FORM_HEIGHT = 2000;

    const notchSize = DeviceInfo?.hasNotch?.() ? 48 : 20;

    const getHeight = useCallback(() => {

        return (
            // eslint-disable-next-line no-nested-ternary
            (Platform.OS === 'web'
                ? isMobile()
                    ? windowHeight - keyDiffSize
                    : windowHeight
                : windowHeight - keyboardHeight - notchSize) - keyDiffSize
        );

    }, [keyboardHeight, windowHeight, notchSize]);

    const FORM_ELEMENTS_SIZE = 60;

    const [visibleHeight, setVisibleHeight] = useState(0);

    useEffect(() => {

        setVisibleHeight(scrollHeight);

        const keyboardDidShowListener = Keyboard.addListener(
            'keyboardDidShow',
            e => setVisibleHeight(scrollHeight - e.endCoordinates.height)
        );
        const keyboardDidHideListener = Keyboard.addListener(
            'keyboardDidHide',
            () => setVisibleHeight(scrollHeight)
        );

        return () => {

            keyboardDidShowListener.remove();
            keyboardDidHideListener.remove();

        };

    }, [scrollHeight]);

    const getContainerStyles = useCallback(() => {

        const containerStyles = [];

        const isScrollNeeded = contentHeight - visibleHeight;

        if (isScrollNeeded > 10) {

            containerStyles.push({ top: notchSize + 11 });

        }

        if (contentHeight && contentHeight + FORM_ELEMENTS_SIZE < getHeight()) {

            containerStyles.push({
                height: contentHeight + FORM_ELEMENTS_SIZE,
                maxHeight: contentHeight + FORM_ELEMENTS_SIZE,
            });

        } else {

            containerStyles.push({
                maxHeight: getHeight(),
                height: getHeight(),
            });

        }
        if (Platform.OS === 'ios' && keyboardHeight) {

            containerStyles.push({ bottom: keyboardHeight });

        }

        return containerStyles.reduce((prev, curr) => ({
            ...prev,
            ...curr,
        }), {});

    }, [stylesModal, keyboardHeight, getHeight, contentHeight, scrollHeight, visibleHeight]);

    const onLayoutHandler = (event) => {

        const layoutHeight = event?.nativeEvent?.layout?.height;
        setContentHeight(layoutHeight);

    };

    const onScrollLayoutHandler = (event) => {

        const layoutHeight = event?.nativeEvent?.layout?.height;
        setScrollHeight(layoutHeight);

    };

    return (
        <Modal2 title={'titles.newVisitFound'} hideClose noScroll={false}
            containerStyle={getContainerStyles()}
        >
            <View onLayout={onLayoutHandler}>
                <TouchableWithoutFeedback>
                    <ScrollView
                        enableAutomaticScroll
                        keyboardDismissMode="on-drag"
                        keyboardShouldPersistTaps={'handled'}
                        onLayout={onScrollLayoutHandler}
                    >
                        <View

                        >
                            <VisitInfo
                                title={Object.R(`titles.visitInfo`, {
                                    date,
                                    doctor,
                                    name,
                                    birthday,
                                    mc,
                                })}
                            />
                            <View
                                style={{ paddingHorizontal: 12 }}>
                                <EmailInput onChange={setEmail} value={email} onError={setEmailError} error={emailError} />
                                {!hasProfile ? (
                                    <>
                                        <PatientSelector
                                            onSelect={setSelectedPatient}
                                            selectedItem={selectedPatient}
                                            filtredFields={isAdultPatient ? [IS_FOR_CHILD] : [IS_FOR_ME]}
                                        />
                                        {isPatientSelected && !(isForMe && userHasProgram) ? (
                                            <SwitchWithNotification
                                                hint={'hint.savePatientDataOfVisit'}
                                                caption={Object.R('titles.savePatientDataOfVisit')}
                                                value={isSavePatientData}
                                                onValueChange={setIsSavePatientData}
                                            />
                                        ) : null}
                                    </>
                                ) : null}
                                {locationCode ? (
                                    <SwitchWithLabel
                                        caption={Object.R('titles.myCityLabel', { city: capitalize(Object.R(`locations.${locationCode}`)) })}
                                        value={isChangeCity}
                                        onValueChange={setIsChangeCity}
                                        containerStyle={{ marginBottom: 12 }}
                                        style={isPatientSelected ? {} : { marginTop: 0 }}
                                    />
                                ) : null}
                                {(objectBookmark || bookmarkRc ) && !!doctorData && (
                                    <SwitchWithLabel
                                        caption={Object.R('titles.addDoctorToBookmarks')}
                                        value={isToBookmark}
                                        onValueChange={setIsToBookmark}
                                        containerStyle={{ marginBottom: 12 }}
                                        style={{ marginTop: 8 }}
                                    />
                                )}
                                {!isUserConsentReceived && (
                                    <AgreemantCheckbox
                                        title={Object.R('titles.userAgreement')}
                                        selected={isUserAgreementChecked}
                                        checkboxStyle={{
                                            marginTop: 2,
                                            marginRight: 6,
                                        }}
                                        onPress={setIsUserAgreementChecked}
                                        style={{ marginBottom: Platform.OS === 'web' ? 12 : 0 }}
                                    />
                                )}
                                {!isUserConsentReceived && (
                                    <AgreemantCheckbox
                                        title={Object.R('titles.personalDataAgreement')}
                                        selected={isAdditionalAgreementChecked}
                                        checkboxStyle={{
                                            marginTop: 2,
                                            marginRight: 6,
                                        }}
                                        onPress={setIsAdditionalAgreementChecked}
                                        style={{ marginBottom: Platform.OS === 'web' ? 12 : 0 }}
                                    />
                                )}
                                {hideChildAgreementCheckbox ? null : (
                                    <AgreemantCheckbox
                                        title={Object.R('titles.childDataAgreement')}
                                        selected={isChildAgreementChecked}
                                        onPress={setIsChildAgreementChecked}
                                        checkboxStyle={{
                                            marginTop: 2,
                                            marginRight: 6,
                                        }}
                                        style={{ marginBottom: Platform.OS === 'web' ? 12 : 0 }}
                                    />
                                )}
                            </View>
                            {!!error && (
                                <TopNotification
                                    hint={{
                                        level: 'error',
                                        message: error,
                                    }}
                                    containerStyle={{
                                        borderTopWidth: 1,
                                        marginTop: 6,
                                    }}
                                />
                            )}
                            <ButtonsGroup style={{ paddingBottom: 12 }}>
                                <Button normal title="buttons.close" action={onClose} capitalize />
                                <Button
                                    primary
                                    title="buttons.continue"
                                    disabled={isDisable}
                                    action={onSubmit}
                                    capitalize
                                    busy={isLoading}
                                />
                            </ButtonsGroup>
                        </View>
                    </ScrollView>
                </TouchableWithoutFeedback>
            </View>
        </Modal2>
    );

};
