import React from 'react';
import { AppRegistry, render } from 'react-native';
// initialize infrastructure. Before imports below.
import { useInit } from './init';
import { ID } from './config';

/**
 * The Starter component.
 */
const Starter = () => {
    const isReady = useInit();
    const [App, setApp] = React.useState(null);
    React.useEffect(() => {
        setTimeout(() => {
            // eslint-disable-next-line global-require
            const { Application } = require('./combo/Application');
            setApp(() => Application);
        }, 5);
    }, []);
    return isReady && App ? <App/> : null;
};

/**
 * register Application for Mobile platforms.
 * @see /index.js
 */
export const registerAppComponent = () => AppRegistry.registerComponent(ID, () => Starter);

/**
 * Renders into DOM for Web platform.
 * @param {*} rootElt the root DOM element to render into
 * @see /web/index.js
 */
// eslint-disable-next-line no-undef
export const renderWebApp = rootElt => window.requestAnimationFrame(() => render(<Starter/>, rootElt));
