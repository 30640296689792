import {
    Button, FlatList, Image, React, View, withState,
} from '../../common';
import actions, { navigate } from '../../actions';
import { resolveImagePath, tryShowImageFromCache } from '../../services/device';
import { sendProfileMessage } from '../../services/imagePicker';
import { attachments as styles } from './style';

const ListItemTitle = withState(
    ({
        item: {
            uri, path, cachePath, id,
        }, onPress, retryRender, errorOccurred, setState, item,
    }) => {
        const resolvedUri = resolveImagePath(uri, cachePath || path);
        sendProfileMessage({
            text: 'Attachments',
            title: 'VIEW',
            data: {
                uri,
                path,
                cachePath,
                id,
                resolvedUri,
            },
        });

        return (
            <Button
                action={onPress}
                styles={[styles.addAttachmentButton, styles.attachmentsListItem]}
                trackingId={'view_attachment_visit'}>
                <Image
                    key={`${id}-${retryRender ? 1 : 0}`}
                    accessibilityLabel="visits_attachments-list_image"
                    resizeMode="cover"
                    style={[styles.attachmentPreview, !uri || errorOccurred ? styles.emptyImage : {}]}
                    source={{ uri: resolveImagePath(uri, cachePath || path) }}
                    onError={(e) => {
                        sendProfileMessage({
                            text: 'Attachments on error',
                            title: 'VIEW',
                            data: {
                                uri,
                                path,
                                cachePath,
                                id,
                                resolvedUri,
                                e,
                            },
                        });
                        actions.addLog('attachmentsError', {
                            resolvedPath: resolvedUri,
                            uri,
                            path,
                            cachePath,
                            id,
                            item,
                        });
                        tryShowImageFromCache(
                            path,
                            item,
                            uri,
                            () => setState({ retryRender: !retryRender }),
                            (err) => {
                                setState({ errorOccurred: true });
                                sendProfileMessage({
                                    text: 'Attachments from cache',
                                    title: 'VIEW',
                                    data: {
                                        uri,
                                        path,
                                        cachePath,
                                        id,
                                        resolvedUri,
                                        err,
                                    },
                                });

                                actions.addLog('attachmentsErrorCache', {
                                    uri,
                                    path,
                                    cachePath,
                                    id,
                                    item,
                                });
                            },
                        );
                    }}
                />
            </Button>
        );
    },
);

export const Attachments = ({
    data, manualVisit, readOnly, clickable = true,
}) => (
    <View
        accessibilityLabel="visits_attachments-section"
        style={[
            styles.containerBottom,
            {
                marginHorizontal: 10,
                marginBottom: 0,
                paddingBottom: 0,
            },
        ]}>
        {!!data && (
            <FlatList
                accessibilityLabel="visits_attachments-list"
                horizontal
                data={data}
                renderItem={({ item: { id }, item }) => (
                    <ListItemTitle
                        item={item}
                        onPress={() => !!clickable &&
                            navigate('VisitImagesSlider', {
                                initialId: id,
                                enableDelete: true,
                                manualVisit,
                                readOnly,
                            })
                        }
                    />
                )}
            />
        )}
    </View>
);
