import {
    Component,
    Icon,
    Img,
    List,
    Page,
    Platform,
    React,
    Section,
    Switch,
    TabSelector,
    Title,
    TopNotification,
    TouchableOpacity,
    View,
} from '../../common';
import { getDrugsStores } from '../../services/tabletka';
import actions, { navigate } from '../../actions';
import { COLORS } from '../../styles/colors';
import { getEnums } from '../../i18n';
import { getDrugstoresFilters, getTabletkaLocation } from '../../selectors';
import { getFormValues } from '../../selectors/forms';
import { calculateDistance, sortBy as sortByUtil } from '../../utils';
import { getCurrentPosition } from '../../services/device';
import { deepEquals } from '../../utils/url';
import {
    DrugInfo, drugstoresFilter, FirstAidKitBanner, renderDrugsStoreItem,
} from './snippets';

const getFilteredData = (data, filter) => {

    let filteredData = [...data];
    if (filter && Object.keys(filter).length) {

        Object.entries(filter).forEach(([key, value]) => {

            if (value) {

                filteredData = filteredData.filter(e => e[key] && Number(e[key]) > 0);

            }

        });

    }
    return filteredData;

};

export class DrugsStoresListImpl extends Component {

    state = {
        showWithAllDrugs: true,
        isLoading: true,
        loaded: false,
        drugsData: [],
        redirected: false,
    };

    prepareDrugsData(drugs, data) {

        data?.forEach?.((e) => {

            e?.drugs?.forEach((d) => {

                const drug = drugs.find(dr => dr.id === d.id);
                if (drug) {

                    if (!drug.priceMax) {

                        drug.priceMax = d.price;

                    }
                    if (!drug.priceMin) {

                        drug.priceMin = d.price;

                    }
                    if (d.price > drug.priceMax) {

                        drug.priceMax = d.price;

                    } else if (d.price < drug.priceMin) {

                        drug.priceMin = d.price;

                    }
                    drug.ls_name = d.ls_name;
                    drug.name = d.name;
                    drug.pricingText = `от ${drug?.priceMin} до ${drug?.priceMax} руб`;

                }

            });

        });

    }

    // eslint-disable-next-line max-statements
    async load(drugs, sortBy, location, loader = true) {

        const drugsWithSelected = drugs?.map?.(i => ({
            ...i,
            selected: typeof i?.selected === 'undefined' ? true : i?.selected,
        }));
        let data = [];
        let isFailed = false;
        try {

            const key =
                drugsWithSelected
                    ?.filter?.(i => i?.selected)
                    ?.map(e => `${e.id}`)
                    .join('_') + location;
            const { formValues } = this.props;
            const values = formValues?.[key];
            if (values && values?.length) {

                if (loader) {

                    this.setState({
                        isLoading: true,
                        fromCache: true,
                    });

                } else {

                    this.setState({ fromCache: true });

                }

                const prices = {};
                data = values?.map?.((v) => {

                    const drg = v?.drugs.map((d) => {

                        const drug = drugs.find(dr => dr.ls_num === d.ls_num);
                        if (drug) {

                            d.qty = drug.qty;

                        }
                        const drugId = d.ls_num;
                        const price = Number(d.price);
                        if (prices[drugId]) {

                            prices[drugId] = {
                                min: Math.min(prices[drugId].min ?? 0, price),
                                max: Math.max(prices[drugId].max ?? 0, price),
                            };

                        } else {

                            prices[drugId] = {
                                min: price,
                                max: price,
                            };

                        }

                        return d;

                    });
                    const { drugsHash } = v;
                    Object.keys(drugsHash).forEach((k) => {

                        const drug = drugs.find(dr => dr.ls_num === k);
                        if (drug) {

                            drugsHash[k].qty = drug.qty;

                        }

                    });
                    return {
                        ...v,
                        drugs: drg,
                        drugsHash,
                    };

                });

                const selectedDrugs = drugsWithSelected.filter(e => e.selected);
                const {
                    coords: { latitude: lat, longitude: lng },
                    isFailed,
                } = await getCurrentPosition();

                data = data
                    .map(e => ({
                        ...e,
                        capacity: e.drugs.length === selectedDrugs.length ? 1 : e.drugs.length / selectedDrugs.length,
                        distance: calculateDistance(lat, lng, e.latitude, e.longitude),
                        distanceError: isFailed,
                        totalPrice:
                            Math.round(
                                e.drugs.reduce((acc, { price, qty = 1 }) => Number(price) * Number(qty) + acc, 0) * 100,
                            ) / 100,
                        lackOf: selectedDrugs
                            .filter(ee => !e.drugsHash[ee.id])
                            .map(i => ({
                                ...i,
                                priceMax: prices[i.ls_num] ? prices[i.ls_num]?.max : i.priceMax ?? 0,
                                priceMin: prices[i.ls_num] ? prices[i.ls_num]?.min : i.priceMin ?? 0,
                            })),
                    }))
                    .map(e => ({
                        ...e,
                        rank:
                            1 + // non-zero
                            (1 - e.capacity) * 100 + // lack of capacity
                            e.distance * 5 + // far away
                            e.totalPrice + // total price of presenting stuff
                            e.distance * e.lackOf.reduce((r, ee) => r + ee.priceMax, 0), // price of absence
                        // e.distance * e.lackOf.reduce((r, ee) => r + ee.priceMax * (ee.qty ?? 1), 0), // price of absence
                    }))
                    .map(e => ({
                        ...e,
                        rankOrder: e.rank, //
                        distanceOrder: e.distance + (1 - e.capacity) * 100000, //
                        totalPriceOrder: e.totalPrice + (1 - e.capacity) * 100000, //
                    }));
                data = sortByUtil(
                    data.filter(e => e.rank < 100000 && e.latitude && e.longitude),
                    `${sortBy}Order`,
                );

            } else {

                if (loader) {

                    this.setState({
                        isLoading: true,
                        fromCache: false,
                    });

                } else {

                    this.setState({ fromCache: false });

                }
                const res = await getDrugsStores(drugsWithSelected, sortBy, location);
                data = res?.data;
                isFailed = res?.isFailed;
                actions.setFormValue(key, data);

            }
            data = sortByUtil(data, `${sortBy}Order`);
            this.dataLoaded = true;
            this.setState({
                data,
                error: null,
                isLoading: false,
                isFailed,
            });
            this.prepareDrugsData(drugs, data);
            this.setState({
                loaded: true,
                drugsData: drugs,
            });
            if (this.props.aptId) {

                const item = data.find(e => e.id === this.props.aptId);
                setTimeout(() => {

                    this.setState({ redirected: true });

                }, 500);
                !this?.state?.redirected && navigate(this.props.detailsName ?? 'DrugstoreDetails', {
                    data,
                    item,
                    drugs,
                    filter: 1,
                    from: this?.props?.from,
                });

            }

        } catch (error) {

            this.dataLoaded = true;
            this.setState({
                error,
                isLoading: false,
            });

        }

    }

    bannerPressHandler() {

        if (this.props?.drugs?.length < 2) {

            return;

        }
        const { navigation } = this.props;
        navigation?.replace?.('SearchListDrugs', {
            drugs: this.state.drugsData,
            prevScreen: 'DrugstoresList',
        });
        // }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const { drugs, sortBy = 'totalPrice', location } = this.props;
        const { showWithAllDrugs } = this.state;
        if (
            !deepEquals(drugs, prevProps.drugs) ||
            location !== prevProps.location ||
            showWithAllDrugs !== prevState.showWithAllDrugs
        ) {

            this.load(drugs, sortBy, location);

        }

    }

    // eslint-disable-next-line complexity
    render() {

        const {
            navigation,
            drugs,
            sortBy = 'totalPrice',
            detailsName = 'DrugstoreDetails',
            location,
            filter,
            from,
            firstAidKit = false,
            totalCount,
            removeFromFirstAidKit = true,
            addToFirstAidKit = true,
            manualVisit,
            fromVisit,
        } = this.props;
        const {
            data: loadedDrugstores = [],
            error,
            isLoading,
            fromCache,
            isFailed,
            showWithAllDrugs,
            loaded,
            drugsData,
        } = this.state;
        const hint = {
            level: 'warn',
            message: 'hints.impossible_determine_location',
        };
        if (this.sortBy !== sortBy) {

            this.sortBy = sortBy;
            setTimeout(() => {

                this.load(drugs, sortBy, location, false);

            }, 5);

        }
        const getAllSelectedDrugs = d => (d
            ? d.filter((item) => {

                return item?.drugs?.some(({ selected }) => selected);
                // const { selected } = item;
                // if (typeof selected === 'undefined') {
                //     return true;
                // }
                // return !!selected;

            })
            : []);
        const getSelectedDrugs = d => getAllSelectedDrugs(d).slice(0, 100);

        const getSelected = (d, single = false) => {

            if (d?.length === 1 && single) {


                d[0].selected = true;
                return d;

            }
            return d?.filter?.(({ selected }) => selected);

        };
        const filteredData = getFilteredData(loadedDrugstores, filter);
        const data =
            showWithAllDrugs && drugs && drugs.length > 1
                ? filteredData.filter(el => !el.lackOf.length)
                : filteredData;
        return (
            <>
                <>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {isLoading && !fromCache ? (
                        <></>
                    ) // eslint-disable-next-line no-nested-ternary
                        : (from === 'firstAidKit' ||
                          from === 'searchFirstAidKit' ||
                          from === 'basket' ||
                          from === 'apt' ||
                          from === 'searchListDrugs') &&
                      !!firstAidKit ? (
                                drugsData?.length > 1 ? (
                                    <FirstAidKitBanner
                                        data={loaded ? drugsData : drugs}
                                        loading={!loaded}
                                        title={
                                            drugs?.length > 1 ? String.i18n('drugs', drugs?.length ?? 0).toLowerCase() : null
                                        }
                                        onPress={() => this.bannerPressHandler.bind(this)()}
                                        withDetails={!!drugsData?.length}
                                        totalCount={totalCount}
                                        canRemove={removeFromFirstAidKit}
                                    />
                                ) : (
                                    !!drugsData?.[0] && (
                                        <DrugInfo
                                            drug={drugsData[0]}
                                            canRemove={removeFromFirstAidKit}
                                            canAdd={addToFirstAidKit}
                                            manualVisit={manualVisit}
                                            withQty
                                        />
                                    )
                                )
                            ) : from === 'drugDetails' ? (
                                !!drugsData?.[0] && (
                                    <DrugInfo
                                        drug={drugsData[0]}
                                        canRemove={removeFromFirstAidKit}
                                        canAdd={addToFirstAidKit}
                                        manualVisit={manualVisit}
                                        fromVisit={fromVisit}
                                    />
                                )
                            ) : null}
                </>
                <Section
                    style={{
                        width: '100%',
                        paddingHorizontal: 0,
                        paddingVertical: 0,
                        marginTop: 0,
                        marginBottom: 0,
                    }}
                    title={Object.R('titles.drugstores')}
                    right={drugstoresFilter(Object.entries(filter)?.filter(([, v]) => v)?.length)}
                    capitalize
                    containerStyles={{ marginVertical: 6 }}>
                    {/* <FilterSectionHeader /> */}
                    {data && data.length ? (
                        <TouchableOpacity
                            onPress={() => navigate(detailsName, {
                                data,
                                drugs,
                                allData: !showWithAllDrugs,
                            })
                            }>
                            <View
                                style={{
                                    height: 97,
                                    width: '100%',
                                    margin: 0,
                                }}>
                                <Img.MapCover />
                                <View
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        bottom: 0,
                                        left: 0,
                                        right: 0,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                    <View
                                        style={{
                                            flexDirection: 'row',
                                            backgroundColor: '#5d85dd',
                                            borderRadius: 50,
                                            padding: 10,
                                            paddingHorizontal: 20,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}>
                                        <Icon.Geo size={12} color="white" />
                                        <Title
                                            style={{
                                                marginLeft: 8,
                                                color: 'white',
                                                fontSize: 12,
                                                fontWeight: 'bold',
                                            }}>
                                            {getAllSelectedDrugs(data, true)?.length ?? 0}
                                        </Title>
                                        <Title
                                            style={{
                                                marginLeft: 6,
                                                color: 'white',
                                                fontSize: 12,
                                            }}>
                                            {Object.R('titles.versionOnMap')}
                                        </Title>
                                    </View>
                                </View>
                            </View>
                        </TouchableOpacity>
                    ) : null}
                    {drugs && getSelected(drugs, true)?.length > 1 ? (
                        <View
                            style={{
                                height: 60,
                                margin: 0,
                                backgroundColor: 'white',
                                flexDirection: 'row',
                                paddingVertical: 8,
                                paddingRight: Platform.OS === 'ios' ? 0 : 12,
                                borderBottomWidth: 1,
                                borderColor: COLORS.LIGHT_GRAY,
                                alignItems: 'center',
                            }}>
                            <Title
                                style={{
                                    paddingHorizontal: 21,
                                    flexWrap: 'wrap',
                                    flex: 6,
                                }}>
                                {Object.R('titles.onlyDrugstoresWithAllDrugs')}
                            </Title>
                            <Switch
                                accessibilityLabel="switch:drugs-search"
                                style={
                                    Platform.OS === 'ios'
                                        ? {
                                            transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }],
                                            marginRight: 20,
                                        }
                                        : { flex: 1 }
                                }
                                onTintColor="#6987D6"
                                thumbTintColor="#5880D0"
                                value={showWithAllDrugs}
                                onValueChange={(value) => {

                                    this.setState({ showWithAllDrugs: value });

                                }}
                            />
                        </View>
                    ) : null}
                    {isFailed ? <TopNotification hint={hint} /> : null}
                    <TabSelector
                        items={isFailed ? [getEnums('drugstoresSortItems')[0]] : getEnums('drugstoresSortItems')}
                        selected={sortBy}
                        onItem={e => navigation.setParams({ sortBy: e.id })}
                    />
                    <List
                        type="drugstores"
                        data={getSelectedDrugs(data) ?? []}
                        error={error}
                        // noSpinner
                        isLoading={isLoading}
                        renderItem={renderDrugsStoreItem(getSelected(drugs).length < 2, item => navigate(detailsName, {
                            data,
                            item,
                            drugs: getSelected(drugsData, true),
                            from,
                        }))}
                    />
                </Section>
            </>
        );

    }

}

export const DrugsStoresList = Page.register(DrugsStoresListImpl, {
    location: { from: getTabletkaLocation },
    filter: { from: getDrugstoresFilters },
    formValues: { from: getFormValues },
});
