import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFocusEffect } from '@react-navigation/native';
import {
    Button, Card, Icon, Text, View,
} from '../../../common';
import { getAllClinicsWithBumba, plannedVisits } from '../../../selectors';
import { groupBy } from '../../../utils/groupBy';
import { sortBy } from '../../../utils';
import { inAppUpdaterStyles } from '../../../common/InAppUpdater';
import { storage } from '../../../services/localstorage';

export const DISABLED_A_CODE_REMINDER_PROVIDERS = 'disabledACodeReminders';
export const ALREADY_OPENED_STORAGE_KEY = 'providerCheck';

export const ACodeReminder = () => {

    const visits = useSelector(plannedVisits);
    const [todayVisits, setTodayVisits] = useState([]);
    const bumbaClinics = useSelector(getAllClinicsWithBumba);

    const getDisabledReminders = async () => {

        try {

            const today = new Date().toISOString().slice(0, 10);
            const disabled = await storage.get(DISABLED_A_CODE_REMINDER_PROVIDERS);

            const forToday = disabled?.[today];
            if (forToday && Array.isArray(forToday)) {

                return [...forToday];

            }
            return [];

        } catch (e) {

            return [];

        }

    };

    const fillData = useCallback(async () => {

        const today = new Date().toISOString().slice(0, 10);

        let alreadyOpened = [];
        try {

            alreadyOpened = await storage.getList(ALREADY_OPENED_STORAGE_KEY, []);

        } catch (e) {
            //
        }
        const groupedResult = groupBy(
            visits?.filter?.(
                visit => new Date(visit?.startDate)?.toISOString?.()?.slice?.(0, 10) === today &&
                    bumbaClinics.includes(visit?.branchId),
            ) ?? [],
            'providerCode',
        );

        const disabledReminders = await getDisabledReminders();

        const filteredResult = groupedResult?.filter?.(
            item => !alreadyOpened?.includes?.(item?.providerCode) && !disabledReminders?.includes?.(item?.providerCode),
        );

        const result = sortBy(filteredResult, '-startDate').map(item => ({
            medcenterName: item?.medcenterName,
            providerCode: item?.providerCode,
        }));
        setTodayVisits(result);

    }, [visits]);

    useEffect(() => {

        fillData();

    }, [visits]);

    useFocusEffect(
        useCallback(() => {

            fillData();

        }, [visits]),
    );

    const onCloseReminder = providerCode => async () => {

        const disabledReminders = await getDisabledReminders();
        const res = [...disabledReminders, providerCode];
        const today = new Date().toISOString().slice(0, 10);
        await storage.set(DISABLED_A_CODE_REMINDER_PROVIDERS, { [today]: res });
        await fillData();

    };

    return (
        !!todayVisits?.length && (
            <View
                style={[
                    {
                        paddingHorizontal: 0,
                        paddingVertical: 0,
                        marginHorizontal: 0,
                        marginVertical: 0,
                        borderColor: 'red',
                        borderWidth: 0,
                        padding: 0,
                    },
                ]}>
                {todayVisits.map((visit, index) => (
                    <ACodeReminderItem
                        key={index}
                        name={Object.R('titles.aCodeReminder', { clinic: visit?.medcenterName })}
                        onClose={onCloseReminder(visit?.providerCode)}
                    />
                ))}
                {/* <ACodeReminderItem name={Object.R('titles.aCodeReminder', { clinic: todayVisits?.join(', ') })} /> */}
            </View>
        )
    );

};

export const ACodeReminderItem = ({ name, onClose = () => {} }) => {

    return (
        <Card containerStyle={[inAppUpdaterStyles.wrapper]}>
            <View style={inAppUpdaterStyles.lineWrapper}>
                <View style={{ flex: 1 }}>
                    <Text numberOfLines={0} style={[inAppUpdaterStyles.stdText]}>
                        {name}
                    </Text>
                </View>
                <Button
                    action={onClose}
                    styles={[
                        inAppUpdaterStyles.closeButton,
                        {
                            top: -12,
                            right: -12,
                        },
                    ]}>
                    <Icon name="cross" color="#787878" style={inAppUpdaterStyles.closeIcon} />
                </Button>
            </View>
        </Card>
    );

};
