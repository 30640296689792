import actions, { navigate } from '../../../actions';
import { SCHEME } from '../../../scheme';
import { putIntoStore } from '../../../store';

export const gotoDoctors = (name = '', useSearch, isToDoctors, from) => {
    if (name) {
        const newFilters = {
            specialty: useSearch ? '' : name.toLowerCase(),
            worksAt: [],
            worksAtGroup: 'any',
        };
        putIntoStore(SCHEME.PHYSICIANS_FILTER, newFilters);
        putIntoStore(SCHEME.PHYSICIANS_FROM_SERARCH, undefined);
        putIntoStore('specializationForApi', undefined);
        putIntoStore(SCHEME.PHYSICIANS_SEARCH, useSearch ? name : '');
        actions.updateDocFilters(newFilters);
    }
    const navParams = isToDoctors
        ? [
            'DoctorList',
            {
                title: name,
                from,
            },
        ]
        : ['DoctorsSpecialtiesSearch'];
    navigate(...navParams);
};

export const adaptDoctorToFav = (doctor) => {
    return {
        displayName: doctor?.fullName || doctor?.name || doctor?.displayName, // ?.replace(' ', '\n'),
        photoUrl: doctor?.photoUrl,
        type: 'physicians',
        // worksAt: doctor?.worksAt,
        id: doctor?.id || doctor?._id,
    };
};

export const adaptClinicToFav = (clinic) => {
    return {
        displayName: clinic.fullName || clinic.name || clinic.displayName, // ?.replace(' ', '\n'),
        photoUrl: clinic.photoUrl,
        type: 'clinics',
        id: clinic.id || clinic._id,
        areaCode: clinic.areaCode,
    };
};
