import {
    FlatList, Keyboard, React, View,
} from './react';
import { Stub } from './Stub';

const onMomentumScrollBegin = () => {
    Keyboard.dismiss();
};

const emptyComponentRendererDefault = () => <Stub type="empty-default" style={{ marginHorizontal: 12 }} />;

export const AnimatedHeaderList = React.memo(
    ({
        data,
        ListHeader,
        getItemLayout,
        renderItem,
        emptyComponentRenderer = emptyComponentRendererDefault,
        separateHeader = true,
        listHeaderProps = {},
    }) => {
        // re-create a flat list every focus, because it loses virtual feature in some cases.
        // see https://jira.epam.com/jira/browse/EPMAIMD-28683.
        // const isFocused = useIsFocused();
        return (
            <View style={{ flex: 1 }}>
                {separateHeader && <ListHeader {...listHeaderProps} />}
                <View style={{ flex: 1 }}>
                    {
                        // isFocused &&
                        <FlatList
                            style={{
                                height: 0,
                                flex: 1,
                            }}
                            accessibilityLabel="list"
                            scrollEventThrottle={8}
                            data={data}
                            contentContainerStyle={{ paddingTop: 0 }}
                            onMomentumScrollBegin={onMomentumScrollBegin}
                            renderItem={renderItem}
                            getItemLayout={getItemLayout}
                            ListHeaderComponent={!separateHeader ? <ListHeader {...listHeaderProps} /> : <></>}
                            keyExtractor={item => item.id}
                            ListEmptyComponent={emptyComponentRenderer()}
                        />
                    }
                </View>
            </View>
        );
    },
);
