import { useNavigation, useNavigationState } from '@react-navigation/native';
import {
    List, Page, React, Section,
} from '../../common';
import { PhoneItem } from '../../combo';
import { getRouteHistory } from '../../utils/getRouteHistory';
import actions from '../../actions';

/**
 * MedCenter Record page(☆☆☆☆☆).
 */
export const MedCenterRecord = Page.register(({
    mdInfo: {
        phones, phone, name, _id,
    }, byOnlineBooking,
}) => {
    const routes = getRouteHistory(useNavigationState(s => s));
    const nav = useNavigation();
    actions.setFormData({
        MedCenterRecord: {
            back: () => {
                try {
                    if (nav.getState().routeNames[0] === 'DoctorRecordDatePick') {
                        nav.replace('DoctorRecordDatePick');
                        return false;
                    }
                    nav.goBack();
                    // return true;
                } catch (e) {
                    //
                }
            },
        },
    });
    const additionalProps = byOnlineBooking ? { trackingAlias: 'tel_registry_ums' } : {};

    const phonesFromString = phone?.split?.(';')?.map?.(i => i?.trim?.()) ?? [];
    return (
        <Page name="medcenter-record" hint="hints.medcenter-details-record" noscroll>
            <Section flex title="titles.registry" params={{ name }} containerStyles={{ height: 40 }} numberOfLines={2}>
                <List
                    type="contacts"
                    data={phones?.length ? phones : phonesFromString}
                    renderItem={PhoneItem}
                    details={{
                        clinicId: _id,
                        journey: routes,
                    }}
                    style={{ marginTop: 5 }}
                    {...additionalProps}
                />
            </Section>
        </Page>
    );
});
