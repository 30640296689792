/* eslint-disable no-underscore-dangle */
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import {
    BigButton, Checkbox, Field, Page, React, Section, showNotification, Stub, TextArea, Title, View,
} from '../../../common';
import { listItem as styles } from '../style';
import { getIsAuthenticated, getUserInfo } from '../../../selectors';
import { back } from '../../../actions';
import { isAdult } from '../../../utils';
import { modifyFeedback } from '../../../actions/feedbacks';
import { setFormData } from '../../../actions/forms';
import { useStdBackNavigation } from '../../../hooks/useStdBackNavigation';

/**
 * This page is to submit a user feedback for a medician or a medcenter.
 */

export const AddFeedbacks = Page.register((props) => {
    const isAuthenticated = useSelector(getIsAuthenticated);
    const userInfo = useSelector(getUserInfo);
    const [isFormValid, setIsFormValid] = useState(true);
    const { route = {} } = props;
    const { params = {} } = route;
    const [isEditFeedback, setIsEditFeedback] = useState(false);
    const [isSendingFeedback, setIsSendingFeedback] = useState(false);
    const [publicName, setPublicName] = useState(!params?.feedback?.anonymous);
    const [text, setText] = useState('');
    const dispatch = useDispatch();
    const [feedback, setFeedback] = useState({});

    useEffect(() => {
        setIsEditFeedback(params?.isEditFeedback ?? false);
    }, [params?.isEditFeedback]);

    useEffect(() => {
        setPublicName(params?.feedback?.anonymous === undefined ? true : !params?.feedback?.anonymous);
    }, [params?.feedback?.anonymous]);

    useEffect(() => {
        setText(params?.feedback?.feedbackText ?? '');
    }, [params?.feedback?.feedbackText]);

    useEffect(() => {
        setFeedback(params?.feedback);
    }, [params?.feedback]);

    const post = () => {
        return new Promise((resolve) => {
            try {
                delete feedback.confirmed;
            } catch {
                //
            }
            dispatch(
                modifyFeedback(
                    {
                        ...feedback,
                        feedbackText: text?.trim(),
                        anonymous: !publicName,
                    },
                    () => {
                        back();
                        resolve();
                    },
                ),
            );
        });
    };

    const submit = useCallback(() => {
        if (!text?.trim()) {
            setIsFormValid(false);
            return;
        }
        if (isSendingFeedback) {
            return;
        }
        setIsSendingFeedback(true);
        post()
            .then(() => {
                showNotification(isEditFeedback ? 'titles.comment_has_been_edited' : 'titles.comment_has_been_added');
            })
            .finally(() => {
                setIsSendingFeedback(false);
            });
    }, [text, isSendingFeedback, isEditFeedback, publicName]);

    useStdBackNavigation();

    const RenderForm = () => {
        const { forItemKind } = feedback;
        const placeholderKey = `feedback.${forItemKind}InputPlaceholder`;
        const titleKey = isEditFeedback ? 'feedback.formEditTitle' : 'feedback.formTitle';
        const buttonTitleKey = isEditFeedback ? 'buttons.save_changes' : 'buttons.send_feedback';

        return (
            <Section>
                <Title bold id={titleKey} style={styles.titleForm}/>
                <Field ns="title-text">
                    <TextArea
                        invalid={!isFormValid}
                        onChange={(t) => {
                            let finalText = t;
                            if (t.length > 2048) {
                                finalText = finalText.slice(0, 2048);
                            }
                            setText(finalText);
                        }}
                        value={text}
                        placeholder={placeholderKey}
                        style={[
                            styles.feedbackInput,
                            {
                                outlineWidth: 0,
                                outline: 'none',
                            },
                        ]}
                    />
                </Field>
                <View style={styles.checkboxContainer}>
                    <Checkbox
                        onPress={() => {
                            setPublicName(p => !p);
                        }}
                        selected={publicName}
                        titleId="feedback.publicName"
                    />
                </View>
                <BigButton disabled={!text} busy={isSendingFeedback} action={submit} title={buttonTitleKey}/>
            </Section>
        );
    };

    dispatch(
        setFormData({
            EditFeedback: {
                back: () => {
                    back();
                },
                logName: 'EditFeedback',
            },
        }),
    );


    return (
        <Page name="modify-feedback" isLoading={isSendingFeedback}>
            {isAuthenticated && isAdult(userInfo.birthday) ? RenderForm() : <Stub.NotAuthorized/>}
        </Page>
    );
});
// export const AddFeedbacksClass = Page.register(
//     class AddFeedbacks extends PureComponent {
//         state = {
//             isFormValid: true,
//             isEditFeedback: this.props.navigation.getParam('isEditFeedback', false),
//             isSendingFeedback: false,
//             anonymous: this.props.navigation.getParam('isEditFeedback', false)
//                 ? this.props.route.params.feedback.anonymous
//                 : true,
//             text: this.props.navigation.getParam('feedback', {}).feedbackText || '',
//         };
//
//         _submit = () => {
//             const { text, anonymous, isSendingFeedback } = this.state;
//             if (!text.trim()) {
//                 this.setState({ isFormValid: false });
//                 return;
//             }
//             if (isSendingFeedback) {
//                 return;
//             }
//             this.setState({ isSendingFeedback: true }, () => {
//                 this._post({
//                     feedbackText: text.trim(),
//                     anonymous,
//                 })
//                     .then(() => {
//                         showNotification(
//                             this.state.isEditFeedback
//                                 ? 'titles.comment_has_been_edited'
//                                 : 'titles.comment_has_been_added',
//                         );
//                     })
//                     .catch(() => this.setState({ isSendingFeedback: false }));
//             });
//         };
//
//         _post({ feedbackText, anonymous }) {
//             const feedback = this.props.navigation.getParam('feedback', {});
//             try {
//                 delete feedback.confirmed;
//             } catch {
//                 //
//             }
//             return actions.modifyFeedback(
//                 {
//                     ...feedback,
//                     feedbackText,
//                     anonymous,
//                 },
//                 back,
//             );
//         }
//
//         renderForm() {
//             const {
//                 text, anonymous, isFormValid, isLoading, isEditFeedback,
//             } = this.state;
//             const feedback = this.props.navigation.getParam('feedback', {});
//             const { forItemKind } = feedback;
//             const placeholderKey = `feedback.${forItemKind}InputPlaceholder`;
//             const titleKey = isEditFeedback ? 'feedback.formEditTitle' : 'feedback.formTitle';
//             const buttonTitleKey = isEditFeedback ? 'buttons.save_changes' : 'buttons.send_feedback';
//             this.props.navigation.onBackPress = () => {
//                 console.log('Back press');
//             };
//             return (
//                 <Section>
//                     <Title bold id={titleKey} style={styles.titleForm} />
//                     <Field ns="title-text">
//                         <TextArea
//                             invalid={!isFormValid}
//                             onChange={(t) => {
//                                 let finalText = t;
//                                 if (t.length > 2048) {
//                                     finalText = finalText.slice(0, 2048);
//                                 }
//                                 this.setState({ text: finalText });
//                             }}
//                             value={text}
//                             placeholder={placeholderKey}
//                             style={[
//                                 styles.feedbackInput,
//                                 {
//                                     outlineWidth: 0,
//                                     outline: 'none',
//                                 },
//                             ]}
//                         />
//                     </Field>
//                     <View style={styles.checkboxContainer}>
//                         <Checkbox
//                             onPress={() => this.setState({ anonymous: !anonymous })}
//                             selected={!anonymous}
//                             titleId="feedback.publicName"
//                         />
//                     </View>
//                     <BigButton disabled={!text} busy={isLoading} action={this._submit} title={buttonTitleKey} />
//                 </Section>
//             );
//         }
//
//         render() {
//             const {
//                 props: { isAuthenticated, userInfo },
//                 state: { isLoading, error, isSendingFeedback },
//             } = this;
//             const children = isAuthenticated && isAdult(userInfo.birthday) ? this.renderForm() : <Stub.NotAuthorized />;
//             return (
//                 <Page name="modify-feedback" isLoading={isLoading || isSendingFeedback} notification={error}>
//                     {children}
//                 </Page>
//             );
//         }
//     },
//     // properties descriptors
//     {
//         isAuthenticated: { from: getIsAuthenticated },
//         userInfo: { from: getUserInfo },
//     },
// );
