import {
    InfoList, Page, React, Section,
} from '../../common';

/**
 * MedCenter Info page(☆☆☆☆☆).
 */
export const MedCenterInfo = Page.register(({ mdInfo }) => (
    <Page name="medcenter-info">
        <Section title="titles.general_info">
            <InfoList
                listFooterText={mdInfo.overview}
                fields="medcenterInfoFields"
                data={mdInfo}
            />
        </Section>
    </Page>
));

