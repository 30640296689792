import { SCHEME } from '../scheme';
import { arrayToHash, someEmpty, sortBy } from '../utils';
import {
    fillDoctorsWithClinics, fillDoctorsWithClinicsLight, fillDoctorsWithPartnershipList, filterBySearch, umsSpecialties,
} from './doctors';
import { getDocSpecDictionary } from './doctorsInfo.utils';
import {
    getClinicsWithPricesInLocation, getFeedbackSumsInLocation, getnNarestAvailableDatesInLocation, getSpecInfoForGlobalLocation, getSpecInfoForGlobalLocationAll, getSpecInfoForGlobalLocationForClinic, getSpecInfoForGlobalLocationForVisit, getTimetableSumByAssignmentInLocation, isHasTimetable, prepareActualSplitDoctors, prepareDoctorsWithFeedbackSumsInLocation, prepareDoctorsWithSplitPartnershipAndSumsForLocal,
} from './doctorsSums';
import {
    getDoctors, getDoctorsData, getFavoritesFull, getGlobalLocation, getPhysiciansFavoritesSearch, getPhysiciansSearch, getPureSpecialitiesFullList, getSpecialitiesFullList,
} from './getters';
import { getClinicsWithBumba, joinedClinics } from './medicians';
import { getCurrentOrFirstProgram } from './programs';
import {
    createSelector, dbGettr, getAdaptedDoctorsWithWorksAt, gettr, prefGettr,
} from './utils';

const {
    PHYSICIANS_FILTER_SPECIALTY,
    PHYSICIANS_FILTER_SPECIALTY_ID,
    PHYSICIANS_FILTER_WORKS_AT,
    PHYSICIANS_FILTER_PERIOD,
    PHYSICIANS_FILTER_FAVORITES,
    PHYSICIANS_FILTER_PROFILES,
    PHYSICIANS_FILTER_AGES,
    PHYSICIANS_FILTER_FEEDBACKS,
    PHYSICIANS_SORT_BY,
    PHYSICIANS_FROM_SERARCH,
} = SCHEME;

export const getSpecialitiesFullMap = createSelector([getPureSpecialitiesFullList], list => (list ? arrayToHash(list) : null));
export const getHasSpecialitiesFullList = createSelector([getPureSpecialitiesFullList], list => !!list);

export const getSpecialitiesError = createSelector([getDoctors], e => e?.error);

export const getFiltredSpecialitiesInCurrentLocation = createSelector([
    getSpecInfoForGlobalLocation,
    (_, clinicId) => clinicId,
], (info, clinicId) => {

    const list = clinicId ? info?.[clinicId]?.data : info?.all?.data;
    return getDocSpecDictionary(list);

});

export const getHasFiltredSpecDataForLocation = createSelector([
    getSpecInfoForGlobalLocation,
    (_, clinicId) => clinicId,
], (info, clinicId) => {

    const list = clinicId ? info?.[clinicId]?.data : info?.all?.data;
    return !!list;

});

export const getSpecialitiesInCurrentLocationAllClinics = createSelector([getSpecInfoForGlobalLocationAll], list => getDocSpecDictionary(list),);
export const getSpecialitiesForClinicInCurrentLocation = createSelector(
    [getSpecInfoForGlobalLocationForClinic],
    (list) => {

        return getDocSpecDictionary(list);

    },
);
export const getSpecialitiesInCurrentLocationForVisit = createSelector([getSpecInfoForGlobalLocationForVisit], list => getDocSpecDictionary(list),);

export const getHasSpecDataForLocation = createSelector([getSpecInfoForGlobalLocationAll], data => !!data);

export const getHasSpecDataForClinicForLocation = createSelector(
    [getSpecInfoForGlobalLocationForClinic],
    data => !!data,
);
export const getHasSpecDataForLocationForVisit = createSelector(
    [getSpecInfoForGlobalLocationForVisit],
    data => !!data,
);

export const getDoctorsForGlobalLocation = createSelector(
    [getGlobalLocation, getDoctorsData],
    (location, info = {}) => info[location],
);

export const getDoctorsBySpeciatity = createSelector(
    [
        getDoctorsForGlobalLocation,
        dbGettr('physicians.filter.specialty'),
        dbGettr('physicians.filter.specialtyId'),
        getSpecInfoForGlobalLocationAll,
    ],
    (data = {}, spec, specId, specList = []) => {

        const newArr = getAdaptedDoctorsWithWorksAt(data[specId]?.data, specList, specId);
        // const newArr =
        //     data[specId]?.data?.map(e => ({
        //         ...e,
        //         _id: e.id,
        //         worksAt: e.assignments?.map(a => ({
        //             ...a,
        //             id: a.branchId,
        //         })),
        //         specialization: specList?.find(e => e.id === specId)?.name,
        //     })) || []; // data[specId]?.map((e) => ({ ...e, worksAt: e.assignments?.map((a) => ({ ...a, id: a.branchId })) })) || [];
        return arrayToHash(newArr);

    },
);

export const getDoctorsBySpeciatityWithClinics = createSelector(
    getDoctorsBySpeciatity,
    joinedClinics,
    getClinicsWithBumba,
    getSpecInfoForGlobalLocation,
    (physicians, clinics, bumbaClinics, specialities = {}) => {

        return fillDoctorsWithClinics(physicians, clinics, bumbaClinics, arrayToHash(specialities?.all?.data ?? [], 'id'));

    },
);

export const getDoctorsBySpeciatityWithPartnershipList = createSelector(
    getDoctorsBySpeciatityWithClinics,
    getCurrentOrFirstProgram,
    umsSpecialties,
    (physicians, currentProgram, specialties) => fillDoctorsWithPartnershipList(physicians, currentProgram, specialties),
);

const getDoctorsBySpeciatityWithFeedbackSumsInLocation = createSelector(
    [getDoctorsBySpeciatityWithPartnershipList, getFeedbackSumsInLocation],
    (physicians, feedbackSum) => prepareDoctorsWithFeedbackSumsInLocation(physicians, feedbackSum),
);

export const getDoctorsBySpeciatityWithSplitPartnershipAndSumsForLocal = createSelector(
    [
        getDoctorsBySpeciatityWithFeedbackSumsInLocation,
        getnNarestAvailableDatesInLocation,
        getTimetableSumByAssignmentInLocation,
        getClinicsWithPricesInLocation,
        isHasTimetable,
        getGlobalLocation,
    ],
    // eslint-disable-next-line max-params
    (physicians, timetableSum, timetableSumByAssignment, pricesSum, isUpdated, locationCode) => prepareDoctorsWithSplitPartnershipAndSumsForLocal(
        physicians,
        timetableSum,
        timetableSumByAssignment,
        pricesSum,
        isUpdated,
        locationCode,
    ),
);

export const actualSplitDoctorsBySpeciatity = createSelector(
    [
        getDoctorsBySpeciatityWithSplitPartnershipAndSumsForLocal,
        getPhysiciansSearch,
        dbGettr(PHYSICIANS_FILTER_SPECIALTY),
        dbGettr(PHYSICIANS_FILTER_WORKS_AT),
        dbGettr(PHYSICIANS_FILTER_PERIOD),
        dbGettr(PHYSICIANS_FILTER_FAVORITES),
        dbGettr(PHYSICIANS_FILTER_PROFILES),
        dbGettr(PHYSICIANS_FILTER_FEEDBACKS),
        dbGettr(PHYSICIANS_SORT_BY),
        getGlobalLocation,
        prefGettr('fullFavorites'),
        dbGettr(PHYSICIANS_FROM_SERARCH),
        dbGettr('sortByBumbaFeature'),
        dbGettr(PHYSICIANS_FILTER_AGES),
        dbGettr(PHYSICIANS_FILTER_SPECIALTY_ID),
    ],
    (
        physicians,
        search,
        specialty,
        worksAt,
        period,
        filterByFavorites,
        profiles,
        feedbacks,
        sort,
        locationCode,
        favorites = [],
        physiciansFromSearch,
        sortByBumba = true,
        ages,
        specialityId,
    ) => prepareActualSplitDoctors(
        physicians,
        search,
        specialty,
        worksAt,
        period,
        filterByFavorites,
        profiles,
        feedbacks,
        sort,
        locationCode,
        favorites,
        physiciansFromSearch,
        sortByBumba,
        ages,
        specialityId,
    ),
);

export const getHasPhysiciansData = createSelector([getDoctorsBySpeciatity], (data) => {

    return !someEmpty(data);

});

export const flatDoctorDataState = (data) => {

    let result = {};
    try {

        for (const i in data) {

            const loc = data[i];
            for (const j in loc) {

                let specialities = loc[j];
                if (Array.isArray(specialities?.data)) {

                    specialities = arrayToHash(specialities?.data, 'id');

                }
                result = {
                    ...result,
                    ...specialities,
                };

            }

        }
        return result;

    } catch (e) {

        return {};

    }

};

export const getFlatDoctorsData = createSelector([getDoctorsData], (data) => {

    const result = flatDoctorDataState(data);
    return result;

});

export const getFlatDoctorsDataWithSpecialities = createSelector([getFlatDoctorsData, getSpecialitiesFullList], ((data, specialities = []) => {

    const spec = arrayToHash(specialities, 'id');
    for (const i in data) {

        const doc = data[i];
        if (doc?.specialityId && !doc.specialization && spec[doc.specialityId]) {

            doc.specialization = spec[doc.specialityId].name;

        }

    }
    return data;

}));

export const getMyFeedbacksExistingPhysiciansApi = createSelector([gettr('feedbacks'), getFlatDoctorsData], (f, doctors) => {

    const feedbacks = f?.myphysicians?.items ?? [];

    return feedbacks?.filter?.(({ forItemKind, forItemId }) => forItemKind === 'physicians' && doctors?.[forItemId]) ?? [];

});

export const getSpecialitiesFullListSorted = createSelector([getSpecialitiesFullList], list => sortBy(list, 'name'));

export const getAdaptedFlatDoctorsData = createSelector(
    [getFlatDoctorsData, getSpecialitiesFullMap],
    (doctorsObj = {}, specMap = {}) => {

        const doctors = Object.values(doctorsObj);
        const adaptedDoctors = getAdaptedDoctorsWithWorksAt(doctors, specMap);
        // const adaptedDoctors = doctors?.map(e => ({
        //     ...e,
        //     worksAt: e.assignments?.map(a => ({
        //         ...a,
        //         id: a.branchId,
        //     })),
        //     specialization: specMap?.[e?.specialityId]?.name,
        // }));
        return adaptedDoctors;

    },
);

const getFullDoctorsFromFavorites = createSelector(
    [getAdaptedFlatDoctorsData, getFavoritesFull],
    (doctors = [], favorites) => {

        const favDoctors = doctors?.filter(({ id, name }) => favorites?.some(e => (e.id === id) && name));
        return favDoctors;

    },
);

const getDoctorsFromFavoritesWithClinics = createSelector(
    getFullDoctorsFromFavorites,
    joinedClinics,
    getClinicsWithBumba,
    (physicians, clinics, bumbaClinics) => {

        const result = fillDoctorsWithClinicsLight(physicians, clinics, bumbaClinics);
        return (physicians?.length === 0 ? [] : result);

    },
);

const getDoctorsFromFavoritesWithPartnershipList = createSelector(
    getDoctorsFromFavoritesWithClinics,
    getCurrentOrFirstProgram,
    umsSpecialties,
    (physicians, currentProgram, specialties) => fillDoctorsWithPartnershipList(physicians, currentProgram, specialties),
);

export const getDoctorsDataFromFavorites = createSelector(
    [getDoctorsFromFavoritesWithPartnershipList, getPhysiciansFavoritesSearch],
    (favDoctors, search = '') => {

        return search ? filterBySearch(favDoctors, search) : favDoctors;

    },
);

export const getDoctorsIdsFromFavorites = createSelector(
    [getFavoritesFull],
    (favorites) => {

        return favorites?.filter?.(({ type }) => type === 'physicians')
            ?.map?.(({ id }) => id);

    },
);

export const getCountOfDoctorsListInFavorites = createSelector([getDoctorsDataFromFavorites], doctors => doctors?.length);

export const getHasDoctorsDataFirFavorites = createSelector([getAdaptedFlatDoctorsData, getDoctorsIdsFromFavorites], (doctors, favIds) => {

    const favDoctors = doctors?.filter(({ id }) => favIds?.some(favId => (favId === id)));
    const objectIds = new Set(favDoctors.map(obj => obj.id));
    return favIds?.every(id => objectIds?.has(id));

});


// export const getLocationAndSpecInfo = createSelector(
//     [getPhysiciansFilter, getCurrentLocation, getSpecInfoForGlobalLocationAll],
//     (filters, location, specArr) => {
//     console.log('filters: ', filters);
//     const specObj = arrayToHash(specArr);
//         return {
//             specialty: specObj[filters?.specialtyId]?.name,
//             specialtyId: filters?.specialtyId,
//             specialtyTitle: filters?.specialty,
//             location: location?.code,

//         };

//     },
// );

// export const isHasTimetableCollection = createSelector([getFetching, getLocationAndSpecInfo], (fetchedCollections, info = {}) => prepareIsHasTimetable(fetchedCollections, info));

//
const getDoctorsWithClinics = createSelector(
    getAdaptedFlatDoctorsData,
    joinedClinics,
    getClinicsWithBumba,
    (physicians, clinics, bumbaClinics) => {

        return fillDoctorsWithClinics(physicians, clinics, bumbaClinics);

    },
);

export const getDoctorsWithPartnershipList = createSelector(
    getDoctorsWithClinics,
    getCurrentOrFirstProgram,
    umsSpecialties,
    (physicians, currentProgram, specialties) => fillDoctorsWithPartnershipList(physicians, currentProgram, specialties),
);

export const getAssignments = createSelector(
    getDoctors,
    (physicians) => {

        return physicians?.assignments ?? {};

    }
);
