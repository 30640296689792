import { detailedInfoColumns as styles } from './style';
import {
    Box, React, Subtitle, View,
} from '.';

const Item = ({
    icon,
    title,
    text,
    onPress,
    style: stl,
}) => (
    <Box centered onPress={onPress} style={[styles.detailedInfoItem, stl]}>
        <View style={styles.iconMarginR}>{icon}</View>
        <View style={{ paddingLeft: 5 }}>
            <Subtitle style={{ fontSize: 10 }}>{title}</Subtitle>
            <Subtitle.Bigger style={{ fontSize: 13 }}>{text}</Subtitle.Bigger>
        </View>
    </Box>
);
// TODO over-engineering - eliminate this
export const DetailedInfoColumns = ({
    leftIcon,
    leftItemTitle,
    leftItemText,
    rightIcon,
    rightItemTitle,
    rightItemText,
    rightItemHandlePress,
    leftItemHandlePress,
}) => (
    <View accessibilityLabel="detailed-info" style={[styles.detailedInfo]}>
        <Item icon={leftIcon} title={leftItemTitle} text={leftItemText} onPress={leftItemHandlePress}
            style={styles.detailedInfoItemFirst}/>
        <Item icon={rightIcon} title={rightItemTitle} text={rightItemText} onPress={rightItemHandlePress}/>
    </View>
);
