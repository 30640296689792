import {
    Button,
    ButtonsGroup,
    Keyboard,
    Linking,
    Modal,
    Page,
    Platform,
    React,
    showNotification,
    Subtitle,
    TextInput,
    Title,
    TouchableOpacity,
    TouchableWithoutFeedback,
    View,
    withState,
} from '../common';
import { modal as styles } from '../common/style';
import { getDeviceModel, getStoreDetailsLink } from '../services/device';
import { storage } from '../services/localstorage';
import { VERSION } from '../config';
import { getErrorMessage } from '../i18n';
import actions from '../actions';

const scheduleRateRequest = (periodName = 'defaultCancel') => {
    storage.set('lastRateRequest', (new Date()).toString());
    storage.set('rateRequestPeriod', periodName);
};

const RateOptionButton = ({
    title,
    titleColor = '#5A5A5A',
    onClick,
}) => (
    <TouchableOpacity
        accessibilityLabel={title}
        style={{
            borderTopWidth: 1,
            borderTopColor: '#C8C8C8',
            justifyContent: 'center',
            alignItems: 'center',
            paddingVertical: 14,
        }}
        onPress={onClick}
    >
        <Title bold id={title} style={{ color: titleColor }}/>
    </TouchableOpacity>
);

const RateContent = () => (
    <View>
        <Title
            id={`titles.${Platform.OS}SupportByGoodMark`}
            bold
            centered
            style={{
                marginTop: 16,
                marginHorizontal: 36,
            }}
            numberOfLines={null}
        />
        <Subtitle
            id="titles.supportByGoodMarkHint"
            centered
            style={{
                marginTop: 12,
                marginHorizontal: 36,
                marginBottom: 24,
            }}
            numberOfLines={null}
        />
        <RateOptionButton
            title="buttons.rateApp"
            titleColor="#6684D7"
            onClick={() => {
                Object.trackAction('rate_to_store');
                scheduleRateRequest('never');
                Linking.openURL(getStoreDetailsLink());
                Page.closeModal();
            }}
        />
        <RateOptionButton
            title="buttons.remindMeLater"
            onClick={() => {
                Object.trackAction('rate_later');
                scheduleRateRequest('rateRemind');
                Page.closeModal();
            }}
        />
        <RateOptionButton
            title="buttons.noThanks"
            onClick={() => {
                Object.trackAction('rate_no_thanks');
                scheduleRateRequest('rateDecline');
                Page.closeModal();
            }}
        />
    </View>
);

const FeedbackContent = withState(({
    setState,
    isSendEnabled = false,
    feedbackText,
    isSending = false,
    error,
    onTextChange = text => setState({
        feedbackText: text,
        isSendEnabled: text.trim().length,
    }),
}) => (
    <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
        <View style={{ paddingBottom: 20 }}>
            <Title
                id="titles.tellWhatToImprove"
                bold
                centered
                style={{
                    marginTop: 16,
                    marginHorizontal: 28,
                }}
                numberOfLines={null}
            />
            <TextInput
                ns="feedback_input"
                style={{
                    height: 84,
                    paddingTop: 16,
                    marginHorizontal: 12,
                    marginTop: 8,
                    textAlignVertical: 'top',
                }}
                placeholder="placeholders.yourFeedback"
                multiline
                onChange={onTextChange}
            />
            {
                error &&
                <Title
                    id={error.code}
                    color="red"
                    numberOfLines={null}
                    style={{
                        marginTop: 12,
                        paddingHorizontal: 12,
                    }}
                >{error}
                </Title>
            }
            <ButtonsGroup>
                <Button
                    ns="cancel"
                    transparent
                    title="button.cancelButton"
                    action={() => {
                        Object.trackAction('rate_feedback_cancel');
                        scheduleRateRequest('feedbackCancel');
                        Page.closeModal();
                    }}
                />
                <Button
                    ns="confirm"
                    busy={isSending}
                    disabled={!isSendEnabled}
                    primary
                    styles={styles.button}
                    title="buttons.send"
                    action={async () => {
                        setState({ isSending: true });
                        const deviceModel = await getDeviceModel();
                        await actions.modifyFeedback({
                            forItemKind: 'application',
                            forItemId: VERSION,
                            feedbackText: `${Platform.OS}-${Platform.Version}-${deviceModel}: ${feedbackText}`,
                        }, (err) => {
                            if (err) {
                                Object.trackAction('rate_feedback_error');
                                let errorText = getErrorMessage(err.code);
                                if (!errorText || errorText === 'undefined') {
                                    errorText = err.message ? err.message : typeof err === 'string' && err;
                                }
                                setState({
                                    error: errorText,
                                    isSending: false,
                                });
                            } else {
                                scheduleRateRequest('never');
                                Object.trackAction('rate_feedback_sent');
                                Page.closeModal();
                                showNotification('titles.thanksForFeedback');
                            }
                        });
                    }}
                />
            </ButtonsGroup>
        </View>
    </TouchableWithoutFeedback>
), ({ setState }) => ({ setState }));

const RateConfirmContent = ({
    onDecline,
    onConfirm,
}) => (
    <View style={{ paddingBottom: 20 }}>
        <Title
            id="titles.didLikeApp"
            bold
            centered
            style={{
                marginTop: 16,
                marginHorizontal: 36,
            }}
            numberOfLines={null}
        />
        <ButtonsGroup style={{ marginTop: 20 }}>
            <Button
                ns="decline"
                transparent
                title="buttons.notQuite"
                action={onDecline}
            />
            <Button
                ns="confirm"
                primary
                styles={styles.button}
                title="buttons.ofCourse"
                action={onConfirm}
            />
        </ButtonsGroup>
    </View>
);

const RateConfirmModal = withState(({
    setState,
    step = 'rateConfirm',
}) => (
    <Modal
        containerStyles={styles.container_no_padding}
        hideClose={step === 'rate'}
        onRequestClose={() => {
            if (step === 'rateConfirm' || step === 'feedback') {
                scheduleRateRequest(step === 'rateConfirm' ? 'defaultCancel' : 'feedbackCancel');
                Object.trackAction(step === 'rateConfirm' ? 'rate_close' : 'rate_feedback_cancel');
                Page.closeModal();
            }
        }}
        onOutsidePress={() => {
            if (step === 'rateConfirm') {
                scheduleRateRequest('defaultCancel');
                Object.trackAction('rate_close');
                Page.closeModal();
            } else if (step === 'feedback') {
                Keyboard.dismiss();
            }
        }}
    >
        {step === 'rateConfirm' && <RateConfirmContent
            onDecline={() => {
                Object.trackAction('rate_not_liked');
                setState({ step: 'feedback' });
            }}
            onConfirm={() => {
                Object.trackAction('rate_liked');
                setState({ step: 'rate' });
            }}
        />}
        {step === 'feedback' && <FeedbackContent/>}
        {step === 'rate' && <RateContent/>}
    </Modal>
), ({ setState }) => ({ setState }));

export const showRateConfirmModal = () => Page.showModal((<RateConfirmModal/>));
