import {
    Button, ButtonsGroup,
    Page, React, Section, SingleNavigation, Text, View,
} from '../../../common';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAllFeedbacksMyHasTextFilter, getAllFeedbacksMyHideNeutralFilter, getAllFeedbacksMyHideNotRecommendFilter, getAllFeedbacksMyHideRecommendFilter, getAllFeedbacksMySpecialityPreFilter, getAllFeedbacksMyTextFilter, getMyFeedbacksPhysicians,
} from '../../../selectors';
import { useFeedbackFilterData } from './MyFeedbacksList';
import { useEffect, useState } from 'react';
import { SelectSpecialityModal } from './SelectSpecialityModal';
import { SwitchWithLabel } from '../../Visits/snippets/NewVisitModal/snippets';
import {
    setAllFeedbacksHasText, setAllFeedbacksHideNeutral, setAllFeedbacksHideNotRecommend, setAllFeedbacksHideRecommend, setAllFeedbacksMySpecialityFilter, setAllFeedbacksMySpecialityPreFilter,
} from '../../../actions/feedbacks';
import { useNavigation } from '@react-navigation/native';

const useFeedbacksSpecialities = (data) => {

    const [specialities, setSpecialities] = useState([]);
    useEffect(() => {

        setSpecialities(
            Array.from(
                new Set(data?.map(({ doctor }) => doctor?.specialization).filter(item => item))
            ).map(item => ({
                name: item,
                id: item,
                selected: false,
            }))
        );

    }, [data]);
    return specialities;

};

export const AllFeedbackFilterScreen = Page.register(() => {

    const textFilter = useSelector(getAllFeedbacksMyTextFilter);
    const hasText = useSelector(getAllFeedbacksMyHasTextFilter);
    const hideNotRecommend = useSelector(getAllFeedbacksMyHideNotRecommendFilter);
    const hideRecommend = useSelector(getAllFeedbacksMyHideRecommendFilter);
    const hideNeutral = useSelector(getAllFeedbacksMyHideNeutralFilter);
    const [hasTextFilter, setHasTextFilter] = useState(!!hasText);
    const [hideNotRecommendFilter, setHideNotRecommendFilter] = useState(!!hideNotRecommend);
    const [hideRecommendFilter, setHideRecommendFilter] = useState(!!hideRecommend);
    const [hideNeutralFilter, setHideNeutralFilter] = useState(!!hideNeutral);
    const physicians = useSelector(getMyFeedbacksPhysicians);
    const { allData } = useFeedbackFilterData(physicians?.items ?? []);
    const specialities = useFeedbacksSpecialities(allData);
    const selected = useSelector(getAllFeedbacksMySpecialityPreFilter);
    const dispatch = useDispatch();
    const nav = useNavigation();

    const resetFilter = () => {

        dispatch(setAllFeedbacksMySpecialityPreFilter([]));
        dispatch(setAllFeedbacksHasText(false));
        dispatch(setAllFeedbacksHideNotRecommend(false));
        dispatch(setAllFeedbacksHideRecommend(false));
        dispatch(setAllFeedbacksHideNeutral(false));
        nav.goBack();

    };

    const applyFilter = () => {

        dispatch(setAllFeedbacksMySpecialityFilter(selected));
        dispatch(setAllFeedbacksHasText(hasTextFilter));
        dispatch(setAllFeedbacksHideNotRecommend(hideNotRecommendFilter));
        dispatch(setAllFeedbacksHideRecommend(hideRecommendFilter));
        dispatch(setAllFeedbacksHideNeutral(hideNeutralFilter));
        nav.goBack();

    };

    return <Page title={'titles.allFeedbacksFilterPage'}
        footer={<ButtonsGroup
            style={{
                backgroundColor: 'white',
                paddingBottom: 12,
                borderTopWidth: 1,
                borderTopColor: '#F0F0F0',
            }}>
            <Button
                transparent
                action={resetFilter}
                title="buttons.reset_filter"
                titleStyles={{
                    color: '#555555',
                    fontSize: 16,
                    fontFamily: 'SourceSansPro-Semibold',
                }}
            />
            <Button primary action={applyFilter} title="buttons.find" />
        </ButtonsGroup>}
    >
        <View>
            <Section title="titles.forDoctorSpeciality" capitalize>
                <SingleNavigation
                    title={selected?.join?.(', ') || Object.R('titles.anySpeciality')}
                    onPress={() => Page.showModal(<SelectSpecialityModal list={specialities} onSelect={() => {}}/>)}
                />
            </Section>
            <View style={{ paddingHorizontal: 16 }}>
                <SwitchWithLabel value={hasTextFilter} onValueChange={setHasTextFilter} caption={Object.R('titles.allFeedbacksHasText')} />
            </View>
            <View style={{ paddingHorizontal: 16 }}>
                <SwitchWithLabel value={hideNotRecommendFilter} onValueChange={setHideNotRecommendFilter} caption={Object.R('titles.allFeedbacksHideNotRecommend')} />
            </View>
            <View style={{ paddingHorizontal: 16 }}>
                <SwitchWithLabel value={hideNeutralFilter} onValueChange={setHideNeutralFilter} caption={Object.R('titles.allFeedbacksHideNeutral')} />
            </View>
            <View style={{ paddingHorizontal: 16 }}>
                <SwitchWithLabel value={hideRecommendFilter} onValueChange={setHideRecommendFilter} caption={Object.R('titles.allFeedbacksHideRecommend')} />
            </View>
            <Text>{textFilter}</Text>
        </View>
    </Page>;

});
