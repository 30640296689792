// noinspection ES6PreferShortImport

import { connect } from 'react-redux';
import { getIsAuthenticated } from '../selectors';
import { PureComponent, React } from './react';

const stateInitializerDefault = ({ setState }) => ({ setState });

/**
 * Connects an arbitrary selector into `data` property.
 * @param {*} selector
 * @param {*} component
 */
export const dataFrom = (selector, component) => (component
    ? connect(stor => ({ data: selector(stor) }))(React.memo(component))
    : c => dataFrom(selector, c));

export const withIsAuthenticated = component => connect(store => ({ isAuthenticated: getIsAuthenticated(store) }))(component);

export const dataTrigger = (selector, c) => connect(stor => ({ $trigger: selector(stor) }))(React.memo(props => (props.$trigger ? React.createElement(c, props) : null)));

/**
 * Connects a selector which will be destructured into props.
 * @param {*} selector
 * @param {*} component
 */
export const entityData = (selector, component) => connect(stor => (selector(stor)))(React.memo(component));

/**
 * Turns a given target component into pure and stateful.
 *
 * @param {*} Target a target component
 * @param {*} stateInitializer function that recieves props with `setState()` and returns an initial state object.
 */
export const withState = (Target, stateInitializer = stateInitializerDefault) => class extends PureComponent {
    state = ({
        ...stateInitializer({
            ...this.props,
            setState: (s, cb) => this.setState(s, cb),
        }),
    });

    render() {
        // noinspection JSPotentiallyInvalidUsageOfThis
        return React.createElement(Target, {
            ...this.props,
            ...this.state,
        });
    }
};
