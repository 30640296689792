import { StyleSheet } from 'react-native';
import { createIconSetFromIcoMoon } from 'react-native-vector-icons';
import IconFeather from 'react-native-vector-icons/Feather';
import IconFontAwesome from 'react-native-vector-icons/FontAwesome';
import IconIonicons from 'react-native-vector-icons/Ionicons';
import IconMaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { useNavigationState } from '@react-navigation/native';
import icoMoonConfig from '../assets/icomoon/selection.json';
import { getCurrentRoute } from '../utils/getRouteHistory';
import { React, TouchableOpacity, View } from './react';
import { WithBadge } from './elements';
import { COLORS, icons as style } from './style';

export const IcoMoon = createIconSetFromIcoMoon(icoMoonConfig);
export const Icon = React.memo(
    ({
        name,
        feather,
        awesome,
        ionicons,
        material,
        onPress,
        trackingParams = {},
        trackingAlias,
        size = 25,
        ns = `icon:${name}`,
        color = '#555555',
        style: styl,
    }) => {
        const navState = useNavigationState(s => s);
        // const journey = getRouteHistory(navState);
        const route = getCurrentRoute(navState);
        let content;
        if (feather) {
            content = <IconFeather accessibilityLabel={ns} name={name} size={size} color={color} />;
        } else if (awesome) {
            content = <IconFontAwesome accessibilityLabel={ns} name={name} size={size} color={color} />;
        } else if (ionicons) {
            content = <IconIonicons accessibilityLabel={ns} name={name} size={size} color={color} />;
        } else if (material) {
            content = <IconMaterialCommunityIcons accessibilityLabel={ns} name={name} size={size} color={color} />;
        } else {
            content = <IcoMoon accessibilityLabel={ns} name={name} size={size} color={color} />;
        }
        return onPress ? (
            <TouchableOpacity
                style={styl}
                accessibilityLabel={ns}
                onPress={() => {
                    const tracking = trackingAlias ? { trackingAlias } : {};
                    Object.trackAction(`${name}_${route}`, { ...tracking, ...trackingParams }, 'ai_icon');
                    onPress?.();
                }}>
                {content}
            </TouchableOpacity>
        ) : (
            <View accessibilityLabel={ns} style={styl}>
                {content}
            </View>
        );
    },
);

Icon.FirstAidKit = ({
    onPress, style: stl, size = 20, color, trackingParams = {},
}) => (
    <Icon
        name="firstAidKit"
        color={color ?? '#666666'}
        size={size}
        onPress={onPress}
        style={stl}
        trackingParams={trackingParams}
    />
);

Icon.Trash = ({ onPress, trackingParams }) => (
    <Icon feather name="trash" color="#696969" size={30} onPress={onPress} trackingParams={trackingParams} />
);
Icon.Trash.White = ({
    onPress, style: styl, color = '#000000', trackingParams,
}) => (
    <Icon
        awesome
        name="trash-o"
        size={20}
        onPress={onPress}
        color={color}
        style={[style.deletePhotoIcon, styl]}
        trackingParams={trackingParams}
    />
);
Icon.Exc = ({ onPress, trackingParams }) => (
    <Icon feather name="x" color="#696969" size={20} onPress={onPress} trackingParams={trackingParams} />
);
Icon.Right = ({ color = '#555555', style: stl, trackingParams, size = 20 }) => (
    <Icon name="right-arrow" size={size} color={color} style={stl} trackingParams={trackingParams} />
);
Icon.Left = ({ style: stl, color = '#555555', trackingParams }) => (
    <Icon name="left-arrow" size={20} color={color} style={stl} trackingParams={trackingParams} />
);
Icon.Down = ({
    color = '#555555', size = 20, style: stl, onPress, trackingParams,
}) => (
    <Icon name="down-arrow" size={size} color={color} style={stl} onPress={onPress} trackingParams={trackingParams} />
);
Icon.Up = ({
    color = '#555555', size = 20, style: stl, onPress, trackingParams,
}) => (
    <Icon name="up-arrow" size={size} color={color} style={stl} onPress={onPress} trackingParams={trackingParams} />
);

Icon.Clock = ({
    color, style: stl, size, onPress, trackingParams,
}) => (
    <Icon
        feather
        name="clock"
        size={size ?? 18}
        color={color ?? '#8BA8E7'}
        style={stl}
        onPress={onPress}
        trackingParams={trackingParams}
    />
);
Icon.Comment = ({
    size, color, onPress, style: stl, trackingParams,
}) => (
    <Icon
        name="speech-bubble-oval-shape-with-text-lines"
        size={size || 18}
        color={color ?? '#4FC9B0'}
        onPress={onPress}
        style={stl}
        trackingParams={trackingParams}
    />
);
Icon.Good = ({
    size, color, style: stl, trackingParams,
}) => (
    <Icon name="good" size={size || 18} color={color ?? '#5C85DD'} style={stl} trackingParams={trackingParams} />
);
Icon.GoodFill = ({
    size, color, style: stl, trackingParams,
}) => (
    <Icon name="good-fill" size={size || 18} color={color ?? '#5C85DD'} style={stl} trackingParams={trackingParams} />
);
Icon.Check = ({
    size, color, style: stl, trackingParams,
}) => (
    <Icon
        name="check"
        size={size || 18}
        color={color ?? '#4FC9B0'}
        style={{ ...stl }}
        trackingParams={trackingParams}
    />
);
Icon.CommentDoctor = ({
    size, color, style: stl, trackingParams,
}) => (
    <Icon
        style={stl}
        name="comment-square"
        size={size || 18}
        color={color ?? '#4FC9B0'}
        trackingParams={trackingParams}
    />
);
Icon.Question = ({
    size, color, style: stl, ...props
}) => (
    <Icon style={stl} name="question" size={size || 18} color={color ?? '#979797'} {...props} />
);
Icon.RateDoctor = ({ size, color, ...props }) => (
    <Icon name="chart" size={size || 18} color={color ?? '#4FC9B0'} {...props} />
);
Icon.Calendar = ({
    size, color, style: styl, onPress, ...props
}) => (
    <Icon name="calendar" size={size || 20} color={color || '#8BA8E7'} style={styl} onPress={onPress} {...props} />
);
Icon.Danger = ({
    size, color, style: styl, ...props
}) => (
    <Icon name="danger" size={size || 20} color={color || '#D4D2D2'} style={styl} {...props} />
);
Icon.OpenVector = ({
    size, color, style: styl, ...props
}) => (
    <Icon name="open-vector" size={size || 20} color={color || '#8BA8E7'} style={styl} {...props} />
);
Icon.CalendarBig = ({
    size, color, style: styl, ...props
}) => (
    <Icon name="calendar-big" size={size || 18} color={color || '#8BA8E7'} style={styl} {...props} />
);
Icon.Geo = ({
    onPress, color, size = 20, style: stl, ...props
}) => (
    <Icon name="location-pin" onPress={onPress} color={color || 'white'} size={size} style={stl} {...props} />
);
Icon.Cross = ({
    onPress, color, size = 20, style: stl, ...props
}) => (
    <Icon name="cross" onPress={onPress} color={color || 'white'} size={size} style={stl} {...props} />
);
Icon.Search = ({
    onPress, color, size = 20, ...props
}) => (
    <Icon name="search" onPress={onPress} color={color || '#8BA8E7'} size={size} {...props} />
);
Icon.Edit = ({
    onPress, size, color, style: styl, ...props
}) => (
    <Icon name="edit-icon" size={size || 15} onPress={onPress} color={color || '#5C85DD'} style={styl} {...props} />
);
Icon.MapMarker = ({ color = 'red', ...props }) => <Icon awesome name="map-marker" color={color} size={35} {...props} />;
Icon.Sort = ({ onPress, ...props }) => <Icon name="sort" onPress={onPress} color="#666" {...props} />;
Icon.Menu = ({ onPress, style: styl, ...props }) => (
    <Icon
        name="menu-black"
        size={24}
        color="#555555"
        onPress={onPress}
        style={[
            {
                padding: 12,
                marginRight: -12,
                marginTop: -12,
            },
            styl,
        ]}
        {...props}
    />
);
Icon.Delete = ({
    onPress, noStyles, color = '#555555', ...props
}) => (
    <Icon
        name="delete"
        size={20}
        color={color}
        onPress={onPress}
        style={
            noStyles
                ? null
                : {
                    padding: 12,
                    marginRight: -12,
                    marginTop: -12,
                }
        }
        {...props}
    />
);
Icon.Filter = ({
    onPress, badge, style: styl, color = '#666', ...props
}) => (
    <WithBadge
        badge={badge}
        style={[
            {
                width: 23,
                height: 29,
                paddingTop: 3,
            },
            styl,
        ]}
        onPress={onPress}
        {...props}>
        <Icon name="filter-2" size={22} color={color} />
    </WithBadge>
);
Icon.Logo = ({ stl, ...props }) => <Icon name="aibolit-logo" size={20} color="#fff" style={stl} {...props} />;
Icon.Warning = ({ size, style: stl }) => (
    <Icon feather name="alert-triangle" size={size || 20} color="#DF655C" style={stl} />
);
Icon.RadioChecked = ({
    size, color, style: styl, ...props
}) => (
    <Icon name="radio-selected" size={size} color={color || '#8BA8E7'} style={styl} {...props} />
);
Icon.RadioUnChecked = ({
    size, color, style: styl, ...props
}) => (
    <Icon name="radio" size={size} color={color || '#8BA8E7'} style={styl} {...props} />
);
Icon.Radio = ({
    size = 16, color, style: styl, checked = false, ...props
}) => (checked ? (
    <Icon.RadioChecked size={size} color={color} style={styl} {...props} />
) : (
    <Icon.RadioUnChecked size={size} color={color} style={styl} {...props} />
));

Icon.Add = ({
    onPress, style: stl, color = '#666666', size = 20, ...props
}) => (
    <Icon feather name="plus" color={color} size={size} onPress={onPress} style={[style.iconWrapper, stl]} {...props} />
);

Icon.Sub = ({
    onPress, style: stl, color = '#666666', size = 20, ...props
}) => (
    <Icon
        feather
        name="minus"
        color={color}
        size={size}
        onPress={onPress}
        style={[style.iconWrapper, stl]}
        {...props}
    />
);

Icon.AddRelative = ({ onPress, ...props }) => (
    <Icon feather name="plus" color="#fff" size={20} onPress={onPress} style={[style.iconWrapperRelative]} {...props} />
);

Icon.UpdateRelatives = ({ onPress, iconStyle, ...props }) => (
    <Icon
        feather
        name="refresh-ccw"
        color="#fff"
        size={20}
        onPress={onPress}
        style={[style.iconWrapperRelative, iconStyle]}
        {...props}
    />
);

Icon.Update = ({ onPress, style: iconStyle, ...props }) => (
    <Icon feather name="refresh-ccw" color="#666" size={20} onPress={onPress} style={iconStyle} {...props} />
);

Icon.Info = ({
    color = '#fff', size = 27, iconStyle, onPress, ...props
}) => (
    <Icon
        onPress={onPress}
        style={[style.iconWrapperRelative, iconStyle]}
        name="information"
        size={size}
        color={color}
        ionicons
        {...props}
    />
);

Icon.Share = ({
    onPress, color, size, style: stl, ...props
}) => (
    <Icon name="share-app" color={color ?? '#fff'} size={size ?? 20} onPress={onPress} style={stl} {...props} />
);
Icon.ShareRound = ({ onPress, ...props }) => (
    <Icon name="share-app" color="#fff" size={20} onPress={onPress} style={[style.iconWrapperRelative]} {...props} />
);

Icon.Del = ({ onPress, ...props }) => (
    <Icon
        feather
        name="x"
        color="white"
        size={20}
        onPress={onPress}
        style={[style.iconWrapper, style.iconDelete]}
        {...props}
    />
);

Icon.EmptyFeedback = ({ ...props }) => (
    <Icon name="first-comment-icon" size={40} color="#C9D6F0" style={{ marginTop: 50 }} {...props} />
);

Icon.SearchBarFind = (
    <View style={style.iconSearchBar}>
        <Icon name="search" size={25} color="#9C9C9C" />
    </View>
);
Icon.SearchBarClose = ({ onPress, iconStyle = {}, ...props }) => (
    <Icon
        name="cross2"
        style={[style.iconSearchBar, iconStyle]}
        onPress={onPress}
        accessibilityLabel="cancel"
        color="#666666"
        {...props}
    />
);

Icon.EditCircle = ({ grey, ...props }) => (
    // eslint-disable-next-line no-use-before-define
    <View style={grey ? greyBadge : styles.badge}>
        <Icon.Edit size={15} color="white" {...props} />
    </View>
);
Icon.Settings = ({ onPress, ...props }) => (
    <Icon name="settings-outline" onPress={onPress} color="#666" ionicons size={22} {...props} />
);
Icon.Download = ({ onPress, ...props }) => (
    <Icon name="download" onPress={onPress} color="#555555" feather size={22} {...props} />
);
Icon.Pill = ({ onPress, style: st, ...props }) => (
    <Icon name="pill" onPress={onPress} color="#5C85DD" material size={18} style={st} {...props} />
);
Icon.Attachment = ({ onPress, style: st, ...props }) => (
    <Icon name="md-attach-outline" onPress={onPress} color="#5C85DD" ionicons size={20} style={st} {...props} />
);

Icon.Attach = ({
    onPress, color, size, style: stl, ...props
}) => (
    <Icon name="attach-1" size={size || 18} color={color ?? COLORS.MAIN} onPress={onPress} style={stl} {...props} />
);

Icon.Alert = ({
    onPress, color, size, style: stl, ...props
}) => (
    <Icon
        name="alert-outline"
        size={size || 18}
        color={color ?? COLORS.MAIN}
        onPress={onPress}
        style={stl}
        {...props}
    />
);

const styles = StyleSheet.create({
    badge: {
        backgroundColor: '#18B797',
        height: 20,
        width: 20,
        position: 'absolute',
        top: 0,
        right: 10,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 100,
        borderWidth: 1,
        borderColor: 'white',
    },
    grey: { backgroundColor: '#B7B7B7' },
});

const greyBadge = StyleSheet.compose(styles.badge, styles.grey);
