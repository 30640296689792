import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Page, React, ScrollView, TabSelector,
} from '../../common';
import { HistoryVisits, Tests } from '../App/snippets';
import { setFormData } from '../../actions/forms';
import actions, { back } from '../../actions';
import { EditPage } from '../Account/EditPage';
import { plannedVisits } from '../../selectors';
import { saveRelativeToStorage } from '../../services/user.utils';
import api from '../../services/api';
import { getEditUserPdError } from '../../selectors/forms';
import { putIntoStore, SCHEME } from '../../store';
import { FutureVisitListItem } from './visits/FutureVisitListItem';
import { DISABLE_SERVICES } from '../../config';

const { VISITS_FILTER } = SCHEME;

const resetFilter = () => {

    putIntoStore(VISITS_FILTER, {
        visitors: [],
        byAttachments: false,
        byReports: false,
        clinics: [],
        doctors: [],
        speciality: [],
    });

};
export const TabsForEditUserPD = ({ onChange }) => {

    const [selected, setSelected] = useState('form');
    const items = [
        {
            id: 'form',
            name: 'titles.editUserPD',
        },
        {
            id: 'visits',
            name: 'titles.editUserVisits',
        },
        {
            id: 'reports',
            name: 'titles.editUserReports',
        },
    ];
    return (
        <TabSelector
            items={DISABLE_SERVICES === 1 ? items?.filter(({ id }) => id !== 'reports') : items}
            // item={items}
            onItem={(item) => {

                setSelected(item.id);
                onChange?.(item.id);

            }}
            selected={selected}
        />
    );

};

export const EditChildrenForm = Page.register(
    EditPage(
        // eslint-disable-next-line max-params
        (_, navigation, isChildEdit, setError_, needSendConsent, ___, user) => async ({
            name, date: birthday, sex, profileId,
        }) => {

            const bd = user?.relatives?.find(r => r.profileId === profileId)?.birthday;
            if (isChildEdit) {

                actions.setFormValue('pendingEditUserDP', true);
                actions.setFormError();
                try {

                    await api.updateChild(
                        {
                            _id: profileId,
                            fullNameRu: name,
                            birthday,
                            sex,
                            consentReceived: true,
                        },
                        bd !== birthday,
                    );
                    actions.setFormError();

                } catch (e) {

                    actions.setFormError(e);
                    return;

                } finally {

                    actions.setFormValue('pendingEditUserDP', false);

                }

            } else {

                try {

                    await api.updateRelative(
                        {
                            _id: profileId,
                            fullNameRu: name,
                            birthday,
                            sex,
                        },
                        bd !== birthday,
                    );

                } catch (e) {

                    actions.setFormError(e);
                    return;

                }

            }
            saveRelativeToStorage({
                name,
                birthday,
                sex,
                profileId,
                hashKey: user?.hashKey,
            }).then(() => {

                actions.reloadUserInfo(Object.handleNewUserItemsFound, actions.updatePrefs, actions.updateSelects);
                back();

            });

        },
        'addChild',
        'hints.editUserPD',
        Object.R('titles.fillProfilePD', null, false),
        {
            withPage: false,
            buttonTitle: 'button.saveUserPD',
        },
    )
);

export const EditUserPD = Page.register((params) => {

    const [selected, setSelected] = useState('form');
    const [visitsFetched, setVisitsFetched] = useState(false);
    const [reportsFetched, setReportsFetched] = useState(false);
    const futureVisits = useSelector(plannedVisits);
    const error = useSelector(getEditUserPdError);
    const dispatch = useDispatch();

    useEffect(() => {

        resetFilter();
        if (selected === 'visits' && !visitsFetched) {

            setVisitsFetched(true);
            // fetchVisits();

        }

    }, [selected, visitsFetched]);

    useEffect(() => {

        if (selected === 'reports' && !reportsFetched) {

            setReportsFetched(true);
            // fetchReports();

        }

    }, [selected, reportsFetched]);

    useEffect(() => {

        actions.setFormError();

    }, []);
    useEffect(() => {

        dispatch(
            setFormData({
                EditUserPD: {
                    back: () => {

                        back();

                    },
                },
                VisitRelative: {
                    back: () => {

                        back();

                    },
                },
            }),
        );

    }, []);

    return (
        <Page notification={error} noscroll>
            <TabsForEditUserPD
                onChange={(e) => {

                    setSelected(e);
                    actions.setFormError();

                }}
            />
            {selected === 'visits' && (
                <HistoryVisits
                    needHeader={false}
                    profileId={params?.relative?.profileId}
                    clickable={false}
                    detailScreen={'VisitRelative'}
                    addData={futureVisits
                        ?.map?.((v) => {

                            if (v?.endDate) {

                                return v;

                            }
                            return {
                                ...v,
                                endDate: v?.startDate,
                            };

                        })
                        ?.filter(v => (params?.relative?.profileId ? v?.visitor?.profileId === params?.relative?.profileId : true))}
                    FutureVisitItem={FutureVisitListItem}
                    customDrawElement={true}
                />
            )}
            {selected === 'reports' && <Tests needHeader={false} profileId={params?.relative.profileId} />}
            {selected === 'form' && (
                <ScrollView>
                    <EditChildrenForm />
                </ScrollView>
            )}
        </Page>
    );

});
