export const services = {
    version: {
        _id: '5bbd02c57cf5cf2c1cc9b64c',
        createdAt: '2018-10-09T19:34:29.122Z',
        modifiedAt: '2019-04-10T14:29:59.968Z',
    },
    items: {
        '5bbd02c57cf5cf2c1cc9b64d': {
            name: 'Консультация специалиста',
            groups: {
                '5c472d2cce00ee33424b99c3': {
                    name: 'Консультация специалиста',
                    items: {
                        '5bbd02c57cf5cf2c1cc9bbc7': {
                            name: 'Консультация гастроэнтеролога',
                            providers: [
                                '5b3f5a696cadf395a',
                                '5b3f5fa4529813245',
                                '5b4c5eb620ae653c1',
                                '5b3f59191860bcdde',
                                '5b3f5cf17c5d77cc4',
                                '5b46096c302c5a36f',
                            ],
                            options: [
                                '5c500b9bce00ee33424e0666',
                            ],
                        },
                    },
                },
            },
        },
        '5bbd02c57cf5cf2c1cc9b64e': {
            name: 'Аллергология',
            groups: {
                '5c472d2cce00ee33424b99c4': {
                    name: 'Консультация специалиста',
                    items: {
                        '5bbd02c57cf5cf2c1cc9bbc8': {
                            name: 'Консультация аллерголога ***',
                            providers: [
                                '5b3f5a696cadf395a',
                                '5b3f5fa4529813245',
                                '5b4c5eb620ae653c1',
                                '5b3f59191860bcdde',
                                '5b3f5cf17c5d77cc4',
                                '5b3f5ac1f2075180c',
                            ],
                            options: [
                                '5bbd02c57cf5cf2c1cc9bc22',
                                '5bbd02c57cf5cf2c1cc9bc2b',
                                '5bbd02c57cf5cf2c1cc9bc21',
                                '5bbd02c57cf5cf2c1cc9bc1e',
                                '5bbd02c57cf5cf2c1cc9bc26',
                                '5bbd02c57cf5cf2c1cc9bc25',
                                '5bbd02c57cf5cf2c1cc9bc1f',
                                '5c2f19a9ce00ee3342456437',
                                '5bbd02c57cf5cf2c1cc9bc29',
                            ],
                        },
                    },
                },
            },
        },
        '5bbd02c57cf5cf2c1cc9bab8': {
            name: 'Физиотерапия',
            groups: {
                '5bbd02c57cf5cf2c1cc9bae5': {
                    name: 'Гирудотерапия',
                    items: {
                        '5bbd02c57cf5cf2c1cc9bae6': {
                            name: 'Пунктурная гирудотерапия',
                            providers: [
                                '5b4c5eb620ae653c1',
                                '5b3f59191860bcdde',
                                '5b4c5eceaf981f5f0',
                                '5b4c5e876e354dbfb',
                                '5b3f5cf17c5d77cc4',
                            ],
                            options: [
                                '5bbd02c57cf5cf2c1cc9bf30',
                                '5bbd02c57cf5cf2c1cc9bf2a',
                                '5bbd02c57cf5cf2c1cc9bf31',
                                '5c5d75dcbe95841639a15073',
                                '5bbd02c57cf5cf2c1cc9bf2c',
                                '5bbd02c57cf5cf2c1cc9bf2b',
                                '5bbd02c57cf5cf2c1cc9bf2e',
                                '5bbd02c57cf5cf2c1cc9bf2d',
                                '5bbd02c57cf5cf2c1cc9bf27',
                                '5bbd02c57cf5cf2c1cc9bf2f',
                                '5bbd02c57cf5cf2c1cc9bf29',
                                '5bbd02c57cf5cf2c1cc9bf28',
                            ],
                        },
                    },
                },
            },
        },
        '5bbd02c57cf5cf2c1cc9b8e7': {
            name: 'Рентген, КТ, МРТ',
            groups: {
                '5c4ef7dfce00ee33424daac2': {
                    name: 'Материалы',
                    items: {
                        '5bcf3c70213a045efd0cddab': {
                            name: 'Беруши',
                            providers: ['5b3f5fa4529813245'],
                            options: ['5c500b9bce00ee33424e0665'],
                        },
                    },
                },
            },
        },
        '5bbd02c57cf5cf2c1cc9b662': {
            name: 'Гинекология',
            groups: {
                '5c472d6fce00ee33424b99c6': {
                    name: 'Консультация специалиста',
                    items: {
                        '5bbd02c57cf5cf2c1cc9bbcb': {
                            name: 'Консультация гинеколога ***',
                            providers: [
                                '5b3f5a696cadf395a',
                                '5b3f5fa4529813245',
                                '5b4c5eb620ae653c1',
                                '5b3f59191860bcdde',
                                '5b4c5eceaf981f5f0',
                                '5b46096c302c5a36f',
                                '5b4c5e876e354dbfb',
                                '5b3f5cf17c5d77cc4',
                                '5b3f5ac1f2075180c',
                            ],
                            options: [
                                '5bbd02c57cf5cf2c1cc9c123',
                                '5bbd02c57cf5cf2c1cc9bc2b',
                                '5bbd02c57cf5cf2c1cc9c127',
                                '5bc71e44213a041a298359db',
                                '5bbd02c57cf5cf2c1cc9c126',
                                '5bbd02c57cf5cf2c1cc9bc1e',
                                '5bbd02c57cf5cf2c1cc9bc1d',
                                '5bbd02c57cf5cf2c1cc9bc29',
                                '5bbd02c57cf5cf2c1cc9bc96',
                                '5bbd02c57cf5cf2c1cc9bc22',
                                '5bbd02c57cf5cf2c1cc9bc21',
                                '5bbd02c57cf5cf2c1cc9bc26',
                                '5bbd02c57cf5cf2c1cc9bc25',
                                '5c2f1157ce00ee33424563fa',
                                '5bbd02c57cf5cf2c1cc9bc1f',
                                '5bc49037213a0475077c634b',
                            ],
                        },
                        '5c861909b37dd526a110db8c': {
                            name: 'Прием врачом полового партнера',
                            providers: [
                                '5b3f5cf17c5d77cc4',
                                '5b3f5ac1f2075180c',
                            ],
                        },
                    },
                },
            },
        },
    },
    options: {
        '5bbd02c57cf5cf2c1cc9bc2c': {
            name: 'Аллергология',
            groups: {
                '5bbd02c57cf5cf2c1cc9bc1b': {
                    name: 'Квалификационная категория',
                    items: {
                        '5bbd02c57cf5cf2c1cc9bc1e': {
                            name: 'Высшая квалификационная категория',
                        },
                        '5bbd02c57cf5cf2c1cc9bc1f': {
                            name: 'Первая квалификационная категория',
                        },
                    },
                },
                '5bbd02c57cf5cf2c1cc9bc20': {
                    name: 'Тип консультации',
                    items: {
                        '5bbd02c57cf5cf2c1cc9bc21': { name: 'Первичная' },
                        '5bbd02c57cf5cf2c1cc9bc22': { name: 'Повторная' },
                    },
                },
                '5bbd02c57cf5cf2c1cc9bc24': {
                    name: 'Ученая степень',
                    items: {
                        '5bbd02c57cf5cf2c1cc9bc25': {
                            name: 'Доктор медицинских наук',
                        },
                        '5bbd02c57cf5cf2c1cc9bc26': {
                            name: 'Кандидат медицинских наук',
                        },
                        '5bf40a24ce00ee27d0a82761': {
                            name: 'Кандидат педагогических наук',
                        },
                    },
                },
                '5bbd02c57cf5cf2c1cc9bc28': {
                    name: 'Ученое звание',
                    items: {
                        '5bbd02c57cf5cf2c1cc9bc29': { name: 'Доцент' },
                        '5bbd02c57cf5cf2c1cc9bc2b': { name: 'Профессор' },
                    },
                },
            },
        },
        '5bbd02c57cf5cf2c1cc9bf90': {
            name: 'Общие опции',
            groups: {
                '5bbd02c57cf5cf2c1cc9bfee': {
                    name: 'Количество единиц',
                    items: {
                        '5c500b9bce00ee33424e0665': { name: '1 пара' },
                    },
                },
            },
        },
        '5bbd02c57cf5cf2c1cc9bf91': {
            name: 'Консультация специалиста',
            groups: {
                '5bbd02c57cf5cf2c1cc9bfef': {
                    name: 'Специализация',
                    items: {
                        '5c500b9bce00ee33424e0666': { name: 'гастроэнтеролог' },
                    },
                },
            },
        },
        '5bbd02c57cf5cf2c1cc9bc8a': {
            name: 'Гинекология',
            groups: {
                '5bbd02c57cf5cf2c1cc9bc90': {
                    name: 'Дополнительные исследования',
                    items: {
                        '5bbd02c57cf5cf2c1cc9bc91': {
                            name: 'Аспирационная биопсия',
                        },
                        '5be839c3cc5c162726183afd': { name: 'Биопсия' },
                        '5bbd02c57cf5cf2c1cc9bc92': {
                            name: 'Биопсия шейки матки',
                        },
                        '5bbd02c57cf5cf2c1cc9bc93': {
                            name: 'Соскоб из цервикального канала',
                        },
                        '5be563b5cc5c16272617ddb0': {
                            name:
                                'Цитологическое исследование соскоба шейки матки и цервикального канала',
                        },
                        '5bbd02c57cf5cf2c1cc9bc94': { name: 'Цитология' },
                    },
                },
                '5bbd02c57cf5cf2c1cc9bc95': {
                    name: 'Дополнительные опции',
                    items: {
                        '5be82b7acc5c162726183ad6': {
                            name:
                                'C частичным иссечением слизистой цервикального канала',
                        },
                        '5bf2bf50ce00ee27d0a7f557': {
                            name: 'Без наложения швов',
                        },
                        '5be5620ecc5c16272617dda7': {
                            name: 'Без повреждения целостности слизистой',
                        },
                        '5bc99f40213a040f9b7034e0': {
                            name:
                                'Во время выполнения гистероскопии, гистерорезектоскопии и др.',
                        },
                        '5bc9b09f213a040f9b70352a': {
                            name:
                                'Во время выполнения диагностической лапароскопии',
                        },
                        '5bee86dfce00ee27d0a765bc': {
                            name:
                                'Исследование соскобов и отделяемого с поверхности эрозий, язв, ран, свищей',
                        },
                        '5bbd02c57cf5cf2c1cc9bc9c': {
                            name: 'Раздельное диагностическое выскабливание',
                        },
                        '5bc9a00d213a040f9b7034e6': {
                            name: 'С биопсией эндометрия',
                        },
                        '5be8391bcc5c162726183af6': {
                            name: 'С выскабливанием цервикального канала',
                        },
                        '5be83ccbcc5c162726183b0e': {
                            name: 'С использованием СО2 лазера',
                        },
                        '5bf2bf27ce00ee27d0a7f552': {
                            name: 'С наложением швов',
                        },
                        '5be8385acc5c162726183af2': { name: 'С обследованием' },
                    },
                },
                '5c740a921f349274cc82f308': {
                    name: 'Патология',
                    items: {
                        '5bbd02c57cf5cf2c1cc9bc96': {
                            name:
                                'Диагностика и лечение нейроэндокринных гинекологических заболеваний',
                        },
                        '5bc71e44213a041a298359db': {
                            name: 'По лечению бесплодия',
                        },
                        '5be83c70cc5c162726183b09': {
                            name: 'При вовлечении в процесс кишечника (3-4 ст.)',
                        },
                        '5be83c66cc5c162726183b08': {
                            name:
                                'При не вовлечении в процесс кишечника (1-2 ст.)',
                        },
                        '5bbd02c57cf5cf2c1cc9bc9b': {
                            name:
                                'При неудовлетворительной кольпоскопии с фиксацией шейки матки',
                        },
                        '5c740af61f349274cc82f309': {
                            name:
                                'При хирургических операциях на шейке матки и при отторжении струпа',
                        },
                        '5be837eacc5c162726183af0': {
                            name: 'С задержкой менструации до 49 дней',
                        },
                    },
                },
                '5bc48fcd213a0475077c634a': {
                    name: 'Период',
                    items: {
                        '5bf2a5d7ce00ee27d0a7f47a': { name: 'I триместр' },
                        '5bf2a5e3ce00ee27d0a7f47b': { name: 'II триместр' },
                    },
                },
                '5bbd02c57cf5cf2c1cc9bc8b': {
                    name: 'Вид процедуры',
                    items: {
                        '5bbd02c57cf5cf2c1cc9bc8c': { name: 'Ванночка' },
                        '5bbd02c57cf5cf2c1cc9bc8d': {
                            name: 'Введение лечебных тампонов',
                        },
                        '5bbd02c57cf5cf2c1cc9bc8f': {
                            name: 'Орошение влагалища',
                        },
                    },
                },
            },
        },

    },
    providers: {
        '5c87b9ef55a629026': {
            _id: '5c87b9ef55a629026',
            name: 'Лодэ Брест выезд на дом',
            shortName: 'Лодэ-Брест-Дом',
            branches: [],
        },
        '5b3f5a696cadf395a': {
            _id: '5b3f5a696cadf395a',
            name: 'Нордин',
            shortName: 'Нордин',
            loginDomain: 'lode',
            mainAddress: '',
            branches: [],
        },
        '5b3f5fa4529813245': {
            _id: '5b3f5fa4529813245',
            name: 'Лодэ на Независимости',
            shortName: 'Лодэ-Н',
            loginDomain: 'lode',
            mainAddress: 'Минск, пр. Независимости, 58',
            branches: [],
        },
        '5c87b8f2ace5bbed8': {
            _id: '5c87b8f2ace5bbed8',
            name: 'Лодэ Гродно выезд на дом',
            shortName: 'Лодэ-Гродно-Дом',
            branches: [],
        },
        '5b4c5eceaf981f5f0': {
            _id: '5b4c5eceaf981f5f0',
            name: 'УЗ "8 городская поликлиника" (Минск)',
            shortName: '8гп-Минск',
            loginDomain: '8gp.minsk',
            mainAddress: '',
            branches: [],
        },
        '5b3f5faa72b310212': {
            _id: '5b3f5faa72b310212',
            name: 'Лодэ на Притыцкого',
            shortName: 'Лодэ-П',
            branches: [],
        },
        '5bfbf3ffeee022cd3': {
            _id: '5bfbf3ffeee022cd3',
            name: 'Лодэ на Полиграфистов',
            shortName: 'Лодэ-Полиграфистов',
            branches: [],
        },
        '5c0ead627a292a60f': {
            _id: '5c0ead627a292a60f',
            name: 'Лодэ Минск выезд на дом',
            shortName: 'Лодэ-Минск-Дом',
            branches: [],
        },
        '5b3f5fa7eaf998e58': {
            _id: '5b3f5fa7eaf998e58',
            name: 'Лодэ на Гикало',
            shortName: 'Лодэ-Г',
            branches: [],
        },
        '5b3f5ac1f2075180c': {
            _id: '5b3f5ac1f2075180c',
            name: 'Лодэ на Пионерской',
            shortName: 'Лодэ-Пионерская',
            branches: [],
        },
        '5b4c5e876e354dbfb': {
            _id: '5b4c5e876e354dbfb',
            name:
                'Республиканский центр медицинской реабилитации и бальнеолечения (Минск)',
            shortName: 'РЦМРиБ',
            loginDomain: 'rcmrb',
            mainAddress: '',
            branches: [],
        },
        '5b3f5cf17c5d77cc4': {
            _id: '5b3f5cf17c5d77cc4',
            name: 'Лодэ на Замковой',
            shortName: 'Лодэ-Замковая',
            branches: [],
        },
        '5b4c5eb620ae653c1': {
            _id: '5b4c5eb620ae653c1',
            name: 'РЦ "Элеос"',
            shortName: 'Элеос',
            loginDomain: 'eleos',
            mainAddress: 'Где-то там',
            branches: [],
        },
        '5b3f59191860bcdde': {
            _id: '5b3f59191860bcdde',
            name: 'Экомедсервис',
            shortName: 'Экомедсервис',
            loginDomain: 'ecomedservice',
            mainAddress: '',
            branches: [],
        },
        '5bfbf6d1679408696': {
            _id: '5bfbf6d1679408696',
            name: 'Лодэ на Гоголя',
            shortName: 'Лодэ-Гоголя',
            branches: [],
        },
        '5b46096c302c5a36f': {
            _id: '5b46096c302c5a36f',
            name: 'МЦ "Горизонт" (Минск)',
            shortName: 'Горизонт',
            loginDomain: 'horizont.med',
            mainAddress: '',
            branches: [],
        },
    },
};
