/* eslint-disable camelcase */
import { useState } from 'react';
import {
    Icon, React, Subtitle, Title, TouchableOpacity, TouchableWithoutFeedback, View,
} from '../../common';
import { CallAndMapButtons } from '../DoctorsMap/MedcenterDetailsBunner';
import { drugstoreDetails as style } from './style';
import { getTimetable, getWorkTimeString } from './DrugstoreDetails.utils';
import { AvailableDrugs } from './DrugstoreDetails.snippets';

export const DrugstoreDetailsBanner = ({ item }) => {
    const {
        // id,
        name,
        address,
        latitude,
        longitude,
        drugs,
        lackOf,
        mon,
        tue,
        wed,
        thu,
        fri,
        sat,
        san,
        phones,
        workToday = null,
        isOpen,
        workMsg,
    } = item;
    const [isShowFullTimetable, setIsShowFullTimetable] = useState(false);
    // const [drugsData, setDrugsData] = useState(drugs);
    // const drugsInfo = useSelector(getDrugInfo);
    // useEffect(() => {
    //     const d = drugs.map(i => extendDrugData(i, drugsInfo[i?.ls_num ?? i?.id] ?? {}));
    //     setDrugsData(d);
    // }, [drugsInfo]);
    //
    // useEffect(() => {
    //     setDrugsData(drugs);
    // }, []);
    const timetable = [san, mon, tue, wed, thu, fri, sat].map(el => (el === '1000-1000' ? null : el));
    const onArrowPress = () => setIsShowFullTimetable(!isShowFullTimetable);
    return (
        <TouchableWithoutFeedback>
            <View>
                <View style={[style.bottomBox, style.borderBottom, { flex: 5 }]}>
                    <View style={[style.addressBoxInnerWrap, { paddingHorizontal: 30 }]}>
                        <Title>{address}</Title>
                        <Subtitle>{name}</Subtitle>
                    </View>
                    <View
                        style={[
                            style.subtitle,
                            {
                                paddingHorizontal: 30,
                                marginTop: 10,
                            },
                        ]}>
                        <View
                            style={{
                                flex: 7,
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <Icon.Clock />
                            {/* <Subtitle>{workMsg}</Subtitle> */}
                            <Subtitle
                                style={{
                                    flex: 1,
                                    marginLeft: 5,
                                }}>
                                {getWorkTimeString(timetable, workToday)}
                            </Subtitle>
                            {timetable.some(el => el !== null) ? (
                                <TouchableOpacity onPress={onArrowPress} style={{ marginHorizontal: 15 }}>
                                    <Icon.Down />
                                </TouchableOpacity>
                            ) : null}
                        </View>
                        <CallAndMapButtons phones={phones} latitude={latitude} longitude={longitude} name={name} />
                    </View>
                </View>
                {isShowFullTimetable ? (
                    <View
                        style={[
                            style.bottomBox,
                            style.borderBottom,
                            {
                                flex: 4,
                                paddingTop: 10,
                                backgroundColor: '#f9f9f9',
                            },
                        ]}>
                        <View style={[style.addressBoxInnerWrap, { paddingHorizontal: 30 }]}>
                            <Title>{`${Object.R('medcenterInfoFields.schedule')}: `}</Title>
                            <View style={{ marginTop: 6 }}>
                                {isOpen === 2 ? (
                                    <Subtitle style={{ marginTop: 3 }}>{Object.R('titles.allDay')}</Subtitle>
                                ) : (
                                    getTimetable(timetable).map(el => (
                                        <Subtitle style={{ marginTop: 3 }}>{el}</Subtitle>
                                    ))
                                )}
                            </View>
                        </View>
                        {!!workMsg && (
                            <View
                                style={[
                                    style.subtitle,
                                    {
                                        paddingHorizontal: 26,
                                        marginTop: 10,
                                    },
                                ]}>
                                <Subtitle
                                    style={{
                                        flex: 1,
                                        marginLeft: 5,
                                    }}>
                                    {workMsg?.toString?.() ?? ''}
                                </Subtitle>
                            </View>
                        )}
                    </View>
                ) : null}
                <AvailableDrugs
                    drugs={drugs?.filter?.((i) => {
                        const { selected } = i;
                        if (typeof selected === 'undefined') {
                            return true;
                        }
                        return !!selected;
                    })}
                    lackOf={lackOf}
                />
            </View>
        </TouchableWithoutFeedback>
    );
};
