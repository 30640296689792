/* eslint-disable no-underscore-dangle */
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Page, React, View } from '../../common';
import { Map } from '../../combo';
import {
    clinicsForLocation, getClinicsMedcentersMap, getMapType, selectMedCenterMapDetails,
} from '../../selectors';
import { averageLocation } from '../Tabletka/DrugstoreDetails.utils';
import { sortBy } from '../../utils';
import { useBackHandler } from '../../utils/routing';
import { MedCenterDetailsBunner } from './MedCenterDetailsBunner';

export const MedCentersMap = Page.register(
    ({ item: initialItem, id }) => {

        const cl = useSelector(clinicsForLocation);
        const mapType = useSelector(getMapType);
        const data = useSelector(getClinicsMedcentersMap);
        const initialItemFromData = useSelector(state => selectMedCenterMapDetails(state, id));
        const [item, setItem] = useState(initialItem || initialItemFromData);
        const { id: itemId } = item || {};
        const initialRegion = useMemo(
            () => {

                try {

                    return ((initialItem && initialItem.latitude) || initialItemFromData?.latitude
                        ? {
                            latitude: initialItem?.latitude || initialItemFromData?.latitude,
                            longitude: initialItem?.longitude || initialItemFromData?.longitude,
                        }
                        : averageLocation(data ?? [{}, {}]));

                } catch (e) {

                    averageLocation([{}, {}]);

                }

            }
            ,
            [initialItem, data, id, initialItemFromData]
        );
        useBackHandler(
            useCallback(() => {


                if (itemId && window._currentRouteName === 'MedcentersMap') {

                    setItem(null);
                    return true;

                }
                return false;

            }, [!!itemId]),
        );

        const actualData = useMemo(
            () => sortBy(
                data?.map?.(e => (!e?.isSelected === !(e?.id === itemId)
                    ? e
                    : {
                        ...(e ?? {}),
                        isSelected: !e?.isSelected,
                    })) ?? [],
                '-latitude',
            ),
            [itemId, data],
        );
        return (
            <Page noscroll name="medcenters_map_screen">
                <View accessibilityLabel="medcenters_screen_map" style={{ flex: 1 }}>
                    <View style={{ flex: 1 }}>
                        {!initialRegion ||
                        (!actualData?.length && !cl?.length)
                            ? null
                            : (
                                <>
                                    <Map
                                        mapType={mapType}
                                        data={actualData?.length ? actualData : cl}
                                        onItem={setItem}
                                        initialRegion={initialRegion}
                                        scaleKey="onlineConnectivity"
                                        centerOnClick
                                        item={{ id: itemId }}
                                        zoom={itemId ? 17 : undefined}
                                    />
                                </>
                            )}
                    </View>
                    {!itemId ? null : <MedCenterDetailsBunner item={item} />}
                </View>
            </Page>
        );

    }
);
