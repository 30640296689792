/* eslint-disable max-statements,no-underscore-dangle */
import {
    arrayToHash, decrypt, encrypt, getFirstLastName, getLastSubName, getSplittedName, histogram, sortBy,
} from '../utils';
import { getEnums } from '../i18n';
import store, { getFromStore, getFromStoreDb, putIntoStore } from '../store';
import { SCHEME } from '../scheme';
import { normSpecialty } from '../selectors/utils';
import { notifications } from '../common/Notifications';
import { memoryStorage } from '../utils/memoryStorage';
import actions from '../actions';
import { doctorFilterParams } from '../screens/Doctors/DoctorsSpecialtiesSearch';
import { mergeObjects } from '../utils/objects';
import { storage } from './localstorage';
import api from './api';
import firebase from './firebase';
import { removeAllCachedResponses } from './iosCacheManager';
import encryptedStorage from './encryptedStorage';
import { currentAccessLevelConfig } from './remoteconfig';
import { getFinalMessage } from './http';
import { shareItemNew } from './device';

// storage keys:
export const USER_KEY = 'user';
export const LOCAL_STORE_RELATIVES = ['CHILD'];
export const PREFS = 'prefs';
export const PROFILES_KEY = 'profileList';
export const getUserInfoDefaults = (delta = {}) => ({
    userType: 'REGULAR',
    ...delta,
    me: { name: '', ...delta?.me },
});

export const preparePrefilters = (from = 'default') => {
    // TODO remove hardcode
    const location = getFromStore(SCHEME.LOCATION) || 17;
    const physicians = Object.values(getFromStoreDb(SCHEME.PHYSICIANS_DATA) || {});
    const clinics = Object.values(getFromStoreDb(SCHEME.CLINICS)?.data || []) || {};
    const filtedData = doctorFilterParams[from] || doctorFilterParams.default;
    const {
        specialty = '',
        worksAt = [],
        worksAtGroup = 'any',
        profiles = '0',
        feedbacks = false,
        time = '0',
        dates = {},
    } = getFromStoreDb(filtedData.scheme) || {};
    putIntoStore(filtedData.prefilter.prefilter, {
        specialty,
        specialties: sortBy(
            Object.values(histogram(physicians, normSpecialty)).map(e => ({
                ...e,
                id: e.key,
                name: e.key,
            })),
            'name',
        ),
        worksAt: sortBy(
            Object.values(clinics)
                .filter(({ areaCode, doctorsCounter }) => areaCode === location && doctorsCounter)
                .map(e => ({
                    ...e,
                    id: e._id,
                    selected: worksAt.some(id => id === e._id),
                })),
            'name',
        ),
        worksAtGroup,
        profiles: getEnums('preferredProfile').map(e => ({
            ...e,
            selected: profiles === e.id,
        })),
        feedbacks,
        times: sortBy(
            getEnums('preferredTimes').map(e => ({
                ...e,
                selected: time === e.id,
            })),
            'id',
        ),
        dates,
    });
};

const getProgramFromCoverage = ({
    _id,
    name,
    description,
    link,
    startsFrom,
    branchIds,
    declineReasonCode,
    covererCode,
    problem,
}) => ({
    programId: _id,
    programName: name,
    programCaption: description,
    programLink: link,
    programStartsFrom: startsFrom,
    programIsActive: _id && !problem,
    programBranches: branchIds,
    coverer: `${covererCode}`.split('-')[0] || 'epam',
    declineReasonCode,
    covererCode,
});

const transferRelatives = (rels, relPrograms = [], family = [], grants = []) => (!rels
    ? null
    : rels.map(
        (
            {
                profile: {
                    fullNameRu: name,
                    birthday,
                    sex,
                    programs = [],
                    userProgram = programs.find(({ id: programId }) => !!programId) || {},
                    phones,
                    emails = [],
                },
                profileId,
                relKind,
                accessLevel = family.find(e => e.profileId === profileId)?.accessLevel,
            },
            index,
        ) => ({
            birthday,
            profileId,
            name,
            sex,
            ...getSplittedName(name),
            emails,
            subName: getLastSubName(name),
            firstLastName: getFirstLastName(name),
            relation: relKind
                ? (getEnums('rel').find(e => e.id === relKind) || {}).name ||
                        (relKind === 'PARENT' &&
                            ((sex === 'MALE' && Object.R('titles.father')) || Object.R('titles.mother'))) ||
                        (relKind === 'OTHER' && Object.R('titles.relative'))
                : '',
            isOwner: relKind === 'OWNER',
            isChild: relKind === 'CHILD',
            isSpouse: relKind === 'SPOUSE',
            isParent: relKind === 'PARENT',
            isVisitsManageable: currentAccessLevelConfig[accessLevel].some(level => level === 'BOOK_VISITS'),
            isReportsManageable: currentAccessLevelConfig[accessLevel].some(
                level => level === 'VIEW_REPORTS_TESTS',
            ),
            isMe: false,
            accessLevel,
            accessLevels: currentAccessLevelConfig[accessLevel],
            grantLevel: grants.find(e => e.profileId === profileId)?.level,
            relKind,
            phones: phones && phones[0].number,
            ...getProgramFromCoverage(relPrograms[userProgram.id] || {}),
            index, // used for colors of
        }),
    ));

export const mergeUserInfo = (
    {
        _id: userId,
        keys,
        hashKey,
        storeKey,
        fullNameRu,
        fullNameEn,
        sex,
        birthday,
        primaryPhone,
        primaryEmail,
        emails,
        phones,
        external,
        programs: myPrograms = [],
        family,
        grants,
        consentReceived,
        cards = [],
    } = {},
    { people: relSrc, programs: relPrograms, me: meSrc = {} },
    photoUrl,
) => {
    const name = fullNameRu || fullNameEn || '';
    const relatives =
        transferRelatives(
            relSrc.filter(({ profileId }) => profileId !== userId),
            relPrograms,
            family,
            grants,
        ) || [];
    const userProgram = relPrograms[(myPrograms[0] || {}).id];
    const me = {
        ...meSrc,
        profileId: userId,
        subName: name,
        ...getProgramFromCoverage(userProgram || {}),
        programs: userProgram
            ? [userProgram].map(item => ({
                ...item,
                id: item._id,
            }))
            : [],
        isMe: true,
        hasFamily: !!relatives.find(r => r.isSpouse || r.isOwner),
        numChildren: relatives.filter(r => !r.isSpouse && !r.isOwner && !r.isParent && r?.relKind !== 'OTHER').length,
        numParent: relatives.filter(r => r.isParent).length,
        numSpouse: relatives.filter(r => r.isSpouse).length,
        cards,
        consentReceived,
        grants,
    };

    const relatedPrograms = [me, ...relatives]
        .filter(
            ({
                programId, isOwner, isMe, declineReasonCode, programs,
            }) => programId &&
                (isMe || !isOwner) &&
                (!programs ||
                    programs.some(program => !program.declineReasonCode || program.declineReasonCode !== 8)) &&
                declineReasonCode !== 8,
        )
        .map(({
            programId, programName, programBranches = [], coverer = '', covererCode,
        }) => ({
            id: programId,
            name: programName,
            branches: programBranches,
            coverer,
            covererCode,
        }));

    const hasPrograms = !!relatedPrograms.length;
    const hasCompanyLogin = !!myPrograms.length;

    const usereEmails = primaryEmail ? [primaryEmail] : emails;

    return {
        userId,
        name,
        sex,
        primaryPhone,
        birthday,
        photoUrl,
        external,
        phones: primaryPhone ? [primaryPhone] : phones,
        emails: usereEmails || [],
        // flags
        familyCoveredByProgram: hasPrograms,
        canEditProfile: !hasCompanyLogin,
        // only personalized user will have keys beside firebase id
        isPerson: keys && keys.some(({ tag }) => tag === 'FULL_NAME_RU'),
        // context
        me,
        relatives,
        programs: arrayToHash(relatedPrograms),
        // eslint-disable-next-line no-nested-ternary
        userType: hasCompanyLogin
            ? relatedPrograms.some(e => e.coverer.includes('bns'))
                ? 'INSURER_PROGRAM'
                : 'EMPLOYER_PROGRAM'
            : 'REGULAR',
        accessNotAuthorized: false,
        waitingConfirmEmail: null,
        storeKey,
        hashKey,
    };
};

export const clearForUnAuthenticated = async () => {
    await storage.remove(USER_KEY);
    await storage.set(PREFS, {
        program: null,
        basket: [],
        drugsSearch: [],
        // favorites: [],
        docFilters: {},
    });
    const { location, mapType, locale } = await storage.getObject('selections', {});
    const acodeConfirmation = null;
    await storage.set('selections', {
        location,
        mapType,
        locale,
        acodeConfirmation,
        mainScreenSettings: undefined,
        allowScreenshots: true,
    });
    await storage.set('basketWithSelected', null);
    putIntoStore(SCHEME.PHYSICIANS_FILTER, {});
    putIntoStore(SCHEME.PHYSICIANS_RECENT_SPECIALTY_REQUESTS, []);
    putIntoStore(SCHEME.PHYSICIANS_FILTER_FAVORITES, false);
    putIntoStore(SCHEME.MEDCENTERS_FILTER_FAVORITES, false);
    putIntoStore(SCHEME.UMS_FILTER, {});
    putIntoStore(SCHEME.MEDCENTERS_SEARCH, '');
    putIntoStore(SCHEME.PHYSICIANS_SEARCH, '');
    putIntoStore(SCHEME.PHYSICIANS_ADVANCED_SEARCH, '');
    putIntoStore(SCHEME.PHYSICIANS_CLINICS_ADVANCED_SEARCH, '');
    putIntoStore(SCHEME.PHYSICIANS_VISITS_ADVANCED_SEARCH, '');
    putIntoStore(SCHEME.PHYSICIANS_FROM_CLINICS_SEARCH, '');
    putIntoStore(SCHEME.PHYSICIANS_FROM_VISITS_SEARCH, '');
    putIntoStore(SCHEME.DRUGSTORES_FILTER, {});
    Object.SendConfirmationCode = false;
    actions.clearFormValues();
    actions.clearSuggestionsValues();
    actions.setFormsData('ManualVisitor', []);
    preparePrefilters();
    const selectedMedcenterFilter = getFromStoreDb(SCHEME.MEDCENTERS_FILTER);
    if (selectedMedcenterFilter && selectedMedcenterFilter.id === 'byProgram') {
        putIntoStore(SCHEME.MEDCENTERS_FILTER, undefined);
    }
    putIntoStore(SCHEME.VISITS_FILTER, undefined);
    // putIntoStore(SCHEME.PAST_VISITS, null);
    firebase.unsubscribeFromProfileCollectionsChanges();
    notifications.cancelAll();
    // storage.remove('bumbaBanner');
    encryptedStorage.removeAll();
    removeAllCachedResponses();
    memoryStorage.clear();
};

export const getNewProgram = (newPrograms = [], programs = []) => newPrograms.find(e => !programs.some(el => e.id === el.id));

export const getMessageWithTime = (timeFromError, messageTextWithSeconds, messageTextWithMinutes) => {
    if (timeFromError && messageTextWithSeconds && messageTextWithMinutes) {
        const resendAfter = Math.round((new Date(timeFromError).getTime() - new Date().getTime()) / 1000);
        return resendAfter > 60
            ? Object.R(messageTextWithMinutes, { time: Math.round(resendAfter / 60) })
            : Object.R(messageTextWithSeconds, { time: Math.round(resendAfter) });
    }
    return '';
};

export const initUserProfile = async (deviceIdOfExistedUser) => {
    await storage.remove(USER_KEY);
    try {
        // noinspection UnnecessaryLocalVariableJS
        const initResult = await api.initUser(deviceIdOfExistedUser);
        return initResult;
    } catch (error) {
        return error;
    }
};
// eslint-disable-next-line consistent-return
export const errouneousUser = async (localStorageUserInfo, _backEndUserInfo) => {
    let backEndUserInfo = _backEndUserInfo;
    if (backEndUserInfo.error.code === 'USER_NOT_REGISTERED') {
        backEndUserInfo = await initUserProfile();
        if (backEndUserInfo.error) {
            return { ...localStorageUserInfo, ...backEndUserInfo };
        } else if (backEndUserInfo.code === 'PROFILE_EXISTS') {
            return {
                ...localStorageUserInfo,
                deviceIdOfExistedUser: backEndUserInfo.body?.attributes?.deviceId || true,
            };
        }
    } else if (backEndUserInfo.error.code === 'ACCESS_NOT_AUTHORIZED') {
        return {
            ...localStorageUserInfo,
            outdatedAppVersion: backEndUserInfo.outdatedAppVersion,
            profileAttributes: backEndUserInfo.error.body.attributes.profiles,
            // profileAttributes: backEndUserInfo.error.body.attributes.profiles[0],
            accessNotAuthorized: true,
        };
    } else {
        return {
            ...localStorageUserInfo,
            ...backEndUserInfo,
            defaultMessage: getFinalMessage(backEndUserInfo.body),
        };
    }
};

const isFamilyItemPersonalized = (item) => {
    return item?.profile?.fullNameRu?.trim?.() && item?.profile?.birthday?.trim?.();
};

export const getProfileFromStorage = async (hashKey) => {
    try {
        let profileList = (await storage.get(PROFILES_KEY, [])) ?? [];

        if (typeof profileList !== 'object') {
            profileList = [];
        } else if (!Array.isArray(profileList)) {
            profileList = [];
        }

        let foundIndex = -1;
        let currentProfile = {};
        let encrypted = '';
        profileList.some((item, idx) => {
            const decrypted = decrypt(item ?? '', hashKey);
            if (decrypted) {
                encrypted = item;
                foundIndex = idx;
                currentProfile = decrypted;
                return true;
            }
            return false;
        });
        return {
            profileList,
            foundIndex,
            currentProfile,
            encrypted,
        };
    } catch (e) {
        return false;
    }
};

export const saveRelativeToStorage = async ({
    name, birthday, sex, profileId, hashKey: hk, phones = [],
}) => {
    try {
        const hashKey = hk ?? (await store.getState())?.user?.info;
        const profiles = await getProfileFromStorage(hashKey);
        if (!profiles) {
            return false;
        }
        const { currentProfile, foundIndex, profileList } = profiles;
        if (!currentProfile) {
            return false;
        }
        currentProfile[profileId] = {
            fullNameRu: name,
            birthday,
            profileId,
            sex,
            phones,
        };
        profileList[foundIndex] = encrypt(currentProfile, hashKey);
        await storage.set(PROFILES_KEY, profileList);
        return true;
    } catch (e) {
        return false;
    }
};

export const removeRelativeFromStorage = async ({ profileId, hashKey }) => {
    try {
        const profiles = await getProfileFromStorage(hashKey);
        if (!profiles) {
            return false;
        }
        const { currentProfile, foundIndex, profileList } = profiles;
        if (!currentProfile) {
            return false;
        }

        const profileToSave = Object.entries(currentProfile).reduce((acc, [key, value]) => {
            if (key !== profileId) {
                acc[key] = value;
            }
            return acc;
        }, {});
        profileList[foundIndex] = encrypt(profileToSave, hashKey);
        await storage.set(PROFILES_KEY, profileList);
        return true;
    } catch (e) {
        return false;
    }
};

export const saveFamilyToStorage = async (profileId, hashKey, family, relatives) => {
    if (!profileId || !hashKey) {
        return false;
    }
    try {
        const familyToSave =
            family
                ?.filter?.(({ relKind }) => LOCAL_STORE_RELATIVES.includes(relKind))
                ?.filter(item => isFamilyItemPersonalized(item)) ?? [];

        let profileList = (await storage.get(PROFILES_KEY, [])) ?? [];

        if (typeof profileList !== 'object') {
            profileList = [];
        } else if (!Array.isArray(profileList)) {
            profileList = [];
        }

        let foundIndex = -1;
        let currentProfile = {};
        profileList.some((item, idx) => {
            const decrypted = decrypt(item ?? '', hashKey);
            if (decrypted) {
                foundIndex = idx;
                currentProfile = decrypted;
                return true;
            }
            return false;
        });

        familyToSave.forEach((item) => {
            const sex =
                item?.profile?.sex ??
                relatives?.people?.find(profile => profile?.profileId === item?.profileId)?.profile?.sex ??
                '';
            currentProfile[item?.profileId] = {
                profileId: item?.profileId,
                fullNameRu: item?.profile?.fullNameRu,
                birthday: item?.profile?.birthday,
                sex,
                phones: item?.profile?.phones ?? [],
            };
        });

        const encrypted = encrypt(currentProfile, hashKey);

        if (foundIndex >= 0) {
            profileList[foundIndex] = encrypted;
        } else {
            profileList.push(encrypted);
        }

        await storage.set(PROFILES_KEY, profileList);
        return true;
    } catch (e) {
        return false;
    }
};

export const personalizeMergedProfileFromStorage = async (profile, hashKey) => {
    if (!profile?.relatives || !hashKey) {
        return profile;
    }
    let decrypted;
    const profileList = (await storage.get(PROFILES_KEY, [])) ?? [];
    profileList?.some((item) => {
        const data = decrypt(item, hashKey);
        if (data) {
            decrypted = data;
            return true;
        }

        return false;
    });

    if (!decrypted) {
        return profile;
    }

    Object.entries(decrypted).forEach(([key, value]) => {
        const person = {
            ...value,
            ...getSplittedName(value?.fullNameRu),
            firstLastName: getFirstLastName(value?.fullNameRu),
            name: value?.fullNameRu,
        };
        if (value?.phones?.length && value?.relKind !== 'CHILD') {
            person.phones = value?.phones?.[0]?.number;
        } else {
            person.phones = profile?.relatives?.find(({ profileId }) => key === profileId)?.phones ?? [];
        }
        const found = profile?.relatives?.findIndex(({ profileId }) => key === profileId);
        if (found >= 0) {
            if (!LOCAL_STORE_RELATIVES.includes(profile?.relatives?.[found]?.relKind)) {
                return;
            }
            Object.assign(profile.relatives[found], person);
        }
    });
    return profile;
};

// eslint-disable-next-line complexity
export const applyUserProfile = async (userInfo, userData, updatePrefs, updateSelects) => {
    const {
        hashKey,
        // storeKey,
    } = userInfo;

    // const userData = await firebase.getUserProfile(storeKey);

    if (!userData) {
        return;
    }

    const {
        prefs,
        selections: {
            location,
            includeAnalogs,
            notificationsSettings,
            mainScreenSettings,
            mapType,
            allowScreenshots = true,
        } = {},
    } = userData;
    if (prefs) {
        await storage.set(PREFS, prefs);
    }

    let prefsData = {
        mapType,
        location,
        includeAnalogs,
        docFilters: {},
    };
    if (hashKey) {
        prefsData = decrypt(prefs, hashKey) || {};
    }

    const { docFilters = {} } = prefsData;
    const {
        location: slocation,
        mapType: sMapType,
        locale,
        acodeConfirmation: sacodeConfirmation,
    } = await storage.getObject('selections');

    const selections = {
        mapType: mapType || sMapType || 'standard',
        location: location || slocation || 17,
        includeAnalogs: includeAnalogs || false,
        locale: locale || 'ru',
        acodeConfirmation: sacodeConfirmation,
        // Due to bug with notifications settings, default settings for current users are set to empty, although it`s necessary to have two options.
        // And since completely empty settings are impossible in valid case, override them with proper ones.
        notificationsSettings: (notificationsSettings &&
            Object.keys(notificationsSettings).length &&
            notificationsSettings) || {
            doNotNotify: false,
            selectedRemainderTimes: ['2hours', '1dayMorning'],
        },
        mainScreenSettings,
        allowScreenshots,
    };

    await storage.set('selections', selections);
    // const profiles = await storage.get('profiles');
    // let decryptedProfile = [];
    // if (hashKey && profiles) {
    //     try {
    //         decryptedProfile = JSON.parse(decrypt(profiles, hashKey, []));
    //     } catch (e) {
    //         //
    //     }
    // }
    //
    updatePrefs(prefsData);
    updateSelects(selections);
    const filters = getFromStoreDb(SCHEME.PHYSICIANS_FILTER);
    putIntoStore(SCHEME.PHYSICIANS_FILTER, {
        worksAt: docFilters.worksAt || [],
        worksAtGroup: docFilters.worksAtGroup || 'any',
        specialty: filters?.specialty,
    });
    // putIntoStore(SCHEME.PHYSICIANS_FILTER_FAVORITES, docFilters.filterFavoriteDoctors);
    // putIntoStore(SCHEME.PHYSICIANS_FILTER_FAVORITES, false);
    // putIntoStore(SCHEME.MEDCENTERS_FILTER_FAVORITES, false);
    // putIntoStore(SCHEME.MEDCENTERS_FILTER_FAVORITES, docFilters.filterFavoriteMedcenters);
    putIntoStore(SCHEME.UMS_FILTER, {});
};

export const extractNewRelatives = (merged, localStorageUserInfo, backEndUserInfo, userInfoWithUpdates) => {
    let newRelatives = [];
    let deletedRelatives = [];
    if (
        merged.relatives &&
        localStorageUserInfo &&
        localStorageUserInfo.relatives &&
        merged.relatives.length > localStorageUserInfo.relatives.length
    ) {
        newRelatives = merged.relatives.filter(
            ({ profileId }) => !localStorageUserInfo.relatives.some(person => person.profileId === profileId),
        );
    }
    if ((localStorageUserInfo?.relatives?.length ?? 0) > (merged?.relatives?.length ?? 0)) {
        try {
            deletedRelatives = localStorageUserInfo.relatives.filter(
                local => !merged.relatives.some(e => e.profileId === local.profileId),
            );
        } catch {
            //
        }
    }
    let newProgram;
    if (localStorageUserInfo.me.programs && merged.me.programs) {
        newProgram = getNewProgram(merged.me.programs, localStorageUserInfo.me.programs);
    }
    if (!localStorageUserInfo.me.programs && userInfoWithUpdates.programs) {
        if (backEndUserInfo.programs) {
            const program = getNewProgram(userInfoWithUpdates.programs, backEndUserInfo.programs);
            newProgram = program ? merged.me.programs.find(e => e.id === program.id) : null;
        } else if (userInfoWithUpdates.programs && userInfoWithUpdates.programs.length) {
            // eslint-disable-next-line prefer-destructuring
            newProgram = merged.me.programs.find(e => e.id === userInfoWithUpdates.programs[0].id);
        }
    }

    if (!newProgram && localStorageUserInfo.programs && merged.programs) {
        newProgram = getNewProgram(Object.values(merged.programs), Object.values(localStorageUserInfo.programs));
        if (newProgram) {
            Object.assign(newProgram, { isRelatives: true });
        }
    }
    return [newRelatives, newProgram, deletedRelatives];
};

export const shareMyFamily = async (hashKey, shareKey, onShareData = () => {}) => {
    try {
        const { encrypted } = await getProfileFromStorage(hashKey);
        const res = encrypt(encrypted, shareKey);
        shareItemNew(Object.R('titles.myFamily'), res).finally(() => {
            onShareData(res);
        });

        return true;
    } catch (e) {
        return false;
    }
};

export const shareChildrens = async (hashKey, shareKey, onShareData = () => {}, profile) => {
    try {
        const { currentProfile } = await getProfileFromStorage(hashKey);
        const childs = Object.entries(currentProfile).reduce((acc, [key, value]) => {
            let forShare = false;
            const foundInProfile = profile?.relatives?.find(e => e.profileId === key);
            if (foundInProfile?.relKind === 'CHILD') {
                forShare = true;
            }
            if (forShare) {
                acc[key] = value;
            }
            return acc;
        }, {});
        const res = encrypt(childs, shareKey);
        shareItemNew(Object.R('titles.myFamily'), res).finally(() => {
            onShareData(res);
        });
        return true;
    } catch (e) {
        return false;
    }
};

export const importMyFamily = async (hashKey, shareKey, data) => {
    try {
        const { profileList, foundIndex, currentProfile } = await getProfileFromStorage(hashKey);
        if (foundIndex <= -1) {
            return false;
        }
        const sharedInfo = decrypt(data, shareKey);
        if (!sharedInfo) {
            return false;
        }
        const decryptedInfo = decrypt(sharedInfo, hashKey);
        const mergedFamily = mergeObjects(currentProfile, decryptedInfo);
        profileList[foundIndex] = encrypt(mergedFamily, hashKey);
        await storage.set(PROFILES_KEY, profileList);
        actions.reloadUserInfo(Object.handleNewUserItemsFound, actions.updatePrefs, actions.updateSelects);
        return true;
    } catch (e) {
        return false;
    }
};

export const importChilds = async (hashKey, shareKey, data) => {
    try {
        const { profileList, foundIndex, currentProfile } = await getProfileFromStorage(hashKey);
        if (foundIndex <= -1) {
            return false;
        }
        const decryptedInfo = decrypt(data, shareKey);
        if (!decryptedInfo) {
            return false;
        }
        const mergedFamily = mergeObjects(currentProfile, decryptedInfo);
        profileList[foundIndex] = encrypt(mergedFamily, hashKey);
        await storage.set(PROFILES_KEY, profileList);
        actions.reloadUserInfo(Object.handleNewUserItemsFound, actions.updatePrefs, actions.updateSelects);
        return true;
    } catch (e) {
        return false;
    }
};
