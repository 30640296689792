import { useEffect, useState } from 'react';
import { Clipboard } from 'react-native';
import {
    BigButton,
    Button,
    Link,
    LoadingIndicator,
    Logo,
    Page,
    Platform,
    Prompt,
    React,
    selectOptions,
    showNotification,
    SingleNavigation,
    Subtitle,
    Title,
    View,
} from '../../common';
import { getUserInfo, getUserLoadingState } from '../../selectors';
import actions from '../../actions';
import { user } from '../../services/user';
import { getDeviceName, openURL } from '../../services/device';
import { storage } from '../../services/localstorage';
import { getSupportBodyAsync } from '../../utils/supportInfo';
import { navigate } from '../../routes';
import { getFinalMessage } from '../../services/http';
import { accessCheckStyles } from './styles';
import {COUNTRY_CODE} from "../../config";

const LoadingView = () => (
    <View style={accessCheckStyles.loadingView}>
        <LoadingIndicator indicatorSize={Platform.OS === 'ios' ? 'large' : 50} />
    </View>
);

const NoConfirmWaysBlock = ({ onSupportRequest, onResetRequest, deviceIdOfExistedUser }) => {
    const action = () => {
        showNotification('titles.textWasCopied');
        Clipboard.setString(deviceIdOfExistedUser);
    };

    return (
        <View style={accessCheckStyles.noConfirmWaysBlock}>
            <Title
                id={deviceIdOfExistedUser ? 'titles.person_exist' : 'titles.noConfirmationWays'}
                numberOfLines={null}
                style={accessCheckStyles.noConfirmWaysBlockTitle}
            />
            {deviceIdOfExistedUser ? (
                <Button
                    ns="deviceId"
                    title={deviceIdOfExistedUser}
                    action={action}
                    styles={accessCheckStyles.deviceIdButton}
                />
            ) : null}
            <BigButton
                title="buttons.writeToSupport"
                action={onSupportRequest}
                styles={accessCheckStyles.noConfirmWaysBlockBigButton}
            />
            <Link id="titles.createNewProfile" href={onResetRequest} style={accessCheckStyles.link} underline />
        </View>
    );
};

export const promptLogOut = async () => {
    const phoneNumber = await storage.getObject('phoneNumber', '');
    Page.showModal(
        <Prompt
            submitTitle="buttons.exit"
            cancelTitle="button.stay"
            onSubmit={actions.logOut}
            submitOptions={{ danger: true }}
            cancelOptions={{ primary: false }}
            hideClose>
            <Title bold centered style={accessCheckStyles.promptLogOutTitle} numberOfLines={null} id="titles.signOut" />
            <Subtitle style={accessCheckStyles.promptLogOutSubtitle} numberOfLines={null}>
                {Object.R('titles.profileCheckSignOutConfirm', { phoneNumber: `${COUNTRY_CODE} ${phoneNumber}` })}
            </Subtitle>
        </Prompt>,
    );
};

const getFormattedProfile = item => `${item?.name}`;

const SelectProfileForConfirm = ({
    profiles,
    onSelectProfile,
    onSendRequest,
    onResetRequest,
    sendConfirmCodeProcess,
}) => {
    const [data, setData] = useState([]);
    const [selectedName, setSelectedName] = useState('');

    const getSelected = (d) => {
        const temp = (d ?? data).find(({ selected }) => selected);
        setSelectedName(getFormattedProfile(temp));
        return temp;
    };

    const setSelected = (id, email) => setData((prev) => {
        const temp = [...prev];
        // eslint-disable-next-line no-return-assign
        temp.forEach((item, index) => {
            temp[index].selected = item.id === id && item.email === email;
        });
        return temp;
    });

    useEffect(() => {
        const temp = profiles;
        if (temp.length > 0) {
            temp[0].selected = true;
        }
        setData(temp);
    }, [profiles]);

    useEffect(() => {
        getSelected();
    }, [data]);

    return (
        <View style={accessCheckStyles.confirmByEmailBlock}>
            <Title
                id="titles.accessCheckHint"
                numberOfLines={null}
                style={accessCheckStyles.confirmByEmailBlockTitle}
            />
            <Title id="titles.confirmViaEmail" style={accessCheckStyles.confirmByEmailBlockHint} />
            <SingleNavigation
                title={selectedName}
                onPress={() => {
                    selectOptions({
                        title: 'titles.selectProfileForConfirm',
                        data,
                        onSelect: (item) => {
                            setSelected(item.id, item.email);
                            // eslint-disable-next-line no-unused-expressions
                            onSelectProfile && onSelectProfile(item);
                        },
                    });
                }}
            />
            <BigButton
                title="buttons.sendConfirmationCode"
                action={() => {
                    onSendRequest(getSelected());
                }}
                styles={accessCheckStyles.confirmByEmailBlockSendConfirmation}
                disabled={sendConfirmCodeProcess}
            />
            <Link id="titles.isNotMyMail" href={onResetRequest} style={accessCheckStyles.link} underline />
        </View>
    );
};

export const AccessCheck = Page.register(
    ({
        setState,
        isLoading,
        navigation,
        userInfo: { profileAttributes, deviceIdOfExistedUser } = {},
        openAccountReset = () => navigation.replace('AccountReset', {
            navigatedFromScreen: 'AccessCheck',
            deviceIdOfExistedUser,
        }),
        err = undefined,
    }) => {
        const getEmails = (p = []) => {
            const e = [];
            p.forEach(item => item?.emails?.forEach(email => e.push({
                email,
                name: `${email}, ${item?.profile?.fullNameRu}`,
                id: item?.profileId,
                selected: false,
            })));
            return e;
        };

        const [data, setData] = useState([]);

        useEffect(() => {
            const temp = getEmails(profileAttributes);
            if (temp && temp[0] && typeof temp[0].selected === 'boolean') {
                temp[0].selected = true;
            }
            setData(temp);
        }, [profileAttributes]);

        const sendConfirmRequest = async ({ email, id }) => {
            const deviceName = await getDeviceName();
            try {
                await user.requestAccessByEmail(id, deviceName, email.replace('***', '...'));
            } catch (e) {
                setState({ err: getFinalMessage(e) });
                return;
            }
            try {
                await actions.reloadUserInfo(
                    Object.handleNewUserItemsFound,
                    actions.updatePrefs,
                    actions.updateSelects,
                );
            } catch (e) {
                setState({ err: getFinalMessage(e) });
                return;
            }

            setState({ err: null });

            navigate('Home');
            showNotification(Object.R('titles.deviceConfirmWasSent', { email }), false);
        };

        return (
            <Page
                safeArea
                name="account-check"
                notification={
                    err
                        ? {
                            message: err,
                            code: 'error',
                            fullObject: true,
                        }
                        : null
                }>
                {isLoading ? (
                    <LoadingView />
                ) : (
                    <Logo.Wrapper
                        title="titles.accessCheck"
                        titleStyle={accessCheckStyles.title}
                        wrapperStyle={accessCheckStyles.wrapper}>
                        {data?.length ? (
                            <>
                                <SelectProfileForConfirm
                                    profiles={data}
                                    onSendRequest={sendConfirmRequest}
                                    onResetRequest={openAccountReset}
                                />
                            </>
                        ) : (
                            <NoConfirmWaysBlock
                                onSupportRequest={async () => {
                                    const body = await getSupportBodyAsync();
                                    openURL(`mailto:${Object.R('supportEmail')}${body}`);
                                }}
                                onResetRequest={openAccountReset}
                                deviceIdOfExistedUser={deviceIdOfExistedUser}
                            />
                        )}
                    </Logo.Wrapper>
                )}
            </Page>
        );
    },
    {
        userInfo: { from: getUserInfo },
        idLoading: { from: getUserLoadingState },
    },
);
