import { useSafeAreaInsets } from 'react-native-safe-area-context';
import {
    entityData, Icon, React, Text, TouchableWithoutFeedback, View, WithBadge,
} from '../common';
import { tabBar as styles } from '../styles/components';
import { elementsStyles } from '../styles/elements';
import { getTabBarOptions } from '../selectors';
import { head } from '../utils';
import { MODE, VERSION } from '../config';
import { EXPIRE_HIGHLIGHTER_TIME, ViewHighlight } from '../common/ViewHighlight';
import actions from '../actions';

const activeTintColor = '#85A3E5';
const inactiveTintColor = '#8D9ABA';

const screenNameMap = {
    Home: 'home-page',
    MedCentersList: 'medcenter',
    SearchDrugs: 'pill',
    MyCard: 'report',
    MainFeedbacks: 'chart',
    Other: 'other',
};

const Tab = ({
    routeName,
    isActive,
    count,
    color = isActive ? activeTintColor : inactiveTintColor,
    onPress,
    textId = `rootNavigation.${routeName}`,
    text = routeName === 'Other' && MODE !== 'prod' ? head(VERSION, '-') : Object.R(textId),
    ns = `${routeName}${isActive ? '__active' : ''}`,
}) => {
    return (
        <TouchableWithoutFeedback accessibilityLabel={ns} style={styles.tab} onPress={onPress}>
            <ViewHighlight
                style={styles.tab}
                highlightId={routeName}
                highlightStyle={styles.tabHighlight}
                expireTime={EXPIRE_HIGHLIGHTER_TIME}
                autoRemoveExpired={false}
                onExpire={() => {
                    actions.removeHighlighters(['panel', 'MyCard']);
                }}>
                <WithBadge notification={count}>
                    <Icon name={screenNameMap[routeName]} color={color} onPress={onPress} />
                </WithBadge>
                <Text
                    accessibilityLabel={textId}
                    style={[
                        elementsStyles.text,
                        {
                            fontSize: 9,
                            color,
                        },
                    ]}
                    numberOfLines={2}>
                    {text}
                </Text>
            </ViewHighlight>
        </TouchableWithoutFeedback>
    );
};

export const TabBar = entityData(getTabBarOptions, ({ counts, navigation }) => {
    const { routes, index } = navigation.getState();
    const insets = useSafeAreaInsets();
    return (
        <View
            style={[
                styles.safeArea,
                {
                    paddingBottom: insets.bottom,
                    paddingLeft: insets.left,
                    paddingRight: insets.right,
                },
            ]}
            forceInset={{
                bottom: 'always',
                top: 'never',
            }}>
            <View style={styles.tabBar} accessibilityLabel="tabBar">
                {routes.map(({ name, key }, idx) => (
                    <Tab
                        routeName={name.replace('Tab', '')}
                        onPress={() => {
                            if (index === idx) {
                                navigation.popToTop();
                            } else {
                                navigation.navigate(name);
                            }
                        }}
                        key={key}
                        isActive={index === idx}
                        count={counts[key]}
                    />
                ))}
            </View>
        </View>
    );
});
