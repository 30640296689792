import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../actions';
import {
    FlatList, React, RText, TouchableWithoutFeedback, View,
} from '../../../common';
import { COLORS } from '../../../styles/colors';
import { sortByOption } from '../../Feedbacks/Doctors/DoctorFeedbaksPage';
import {
    getAllFeedbacksMyHasTextFilter, getAllFeedbacksMyHideNeutralFilter, getAllFeedbacksMyHideNotRecommendFilter, getAllFeedbacksMyHideRecommendFilter,
    getAllFeedbacksMySortOrder, getAllFeedbacksMySpecialityPreFilter, getAllFeedbacksMyTextFilter, getFlatDoctorsDataWithSpecialities, getMyFeedbacksLoading,
} from '../../../selectors';
import { fetchMyFeedbacksPhysicians } from '../../../actions/feedbacks';
import { useMyFeedbacksFilter } from './AllFeedbacksMyFilter';
import { AllFeedbacksHeader } from './AllFeedbacksHeader';
import { StubMyFeedbacksEmpty } from './StubMyFeedbackEmpty';
import { AllFeedbackListItem } from './AllFeedbackListItem';
import { getKeywordsFromSearchString, isStringIncludeAllKeywords } from '../../../utils/strings';
import { textWithAuth as styles } from '../../../styles/common';

export const MyFeedbackListItem = (item) => {

    const {
        status,
        doctor,
        createdAt,
        feedbackText,
        updatedAt,
        usefulCount,
        userName,
        vote,
        userCoverers,
        moderationState,
    } = item?.item ?? {};
    const photoUrl = doctor?.photoUrl;
    return (
        <TouchableWithoutFeedback>
            <View
                style={{
                    paddingTop: 2,
                    paddingHorizontal: 16,
                    backgroundColor: COLORS.WHITE,
                }}>
                {/* <MyFeedback item={item?.item} interaction={true} doctorInfo={item?.item?.doctor} /> */}
                <AllFeedbackListItem
                    photoUrl={photoUrl}
                    feedbackText={feedbackText}
                    confirmed={status === 'CONFIRMED'}
                    vote={vote}
                    interaction
                    userName={userName}
                    created={createdAt}
                    usefulCount={usefulCount}
                    showBorder
                    doctorInfo={doctor ?? {}}
                    hasMenu
                    date={updatedAt}
                    userCoverers={userCoverers}
                    responses={item?.item?.responses}
                    item={item?.item}
                    usefulWithoutText={false}
                    editable={moderationState !== 'PENDING' && moderationState !== 'REJECTED'}
                />
            </View>
        </TouchableWithoutFeedback>
    );

};

export const useFeedbackFilterData = (list = []) => {

    const hasText = useSelector(getAllFeedbacksMyHasTextFilter);
    const hideNotRecommend = useSelector(getAllFeedbacksMyHideNotRecommendFilter);
    const hideRecommend = useSelector(getAllFeedbacksMyHideRecommendFilter);
    const hideNeutral = useSelector(getAllFeedbacksMyHideNeutralFilter);
    const selected = useSelector(getAllFeedbacksMySpecialityPreFilter);
    const [count, setCount] = useState(0);


    const [data, setData] = useState(list);
    const [allData, setAllData] = useState([]);
    const filterText = useSelector(getAllFeedbacksMyTextFilter);
    const { activeFilter } = useMyFeedbacksFilter();
    const doctors = useSelector(getFlatDoctorsDataWithSpecialities);
    // const doctors = useSelector(getDoctorsList);
    const sortOrder = useSelector(getAllFeedbacksMySortOrder);

    const keywords = getKeywordsFromSearchString(filterText);

    useEffect(() => {

        const docIds = Array.from(new Set(list?.filter?.(({ forItemKind }) => forItemKind === 'physicians')?.map?.(({ forItemId }) => forItemId)));
        actions.loadDoctorListInfo(docIds);
        const all = list
            ?.map((item) => {

                let filterStatus = 'published';
                if (item?.moderationState === 'PENDING') {

                    filterStatus = 'moderation';

                } else if (item?.moderationState === 'REJECTED') {

                    filterStatus = 'declined';

                }
                let doctor = null;
                if (item?.forItemKind === 'physicians' && item?.forItemId) {

                    doctor = doctors?.[item.forItemId];

                }
                return {
                    ...item,
                    filterStatus,
                    doctor,
                    count: 1,
                };

            });
        setCount(all?.reduce((acc, curr) => {

            if (curr?.filterStatus === activeFilter) {

                return acc + 1;

            }
            return acc;

        }, 0));
        setAllData(sortByOption(all, sortOrder));
        let newData =
            all?.filter(({ filterStatus, doctor , feedbackText }) => {

                if (filterStatus !== activeFilter || !doctor) {

                    return false;

                }
                const { specialization = '', fullName = '' } = doctor ?? {};
                const string = `${fullName} ${specialization} ${feedbackText}`;
                return !keywords?.length || isStringIncludeAllKeywords(string, keywords);

            });
        if (hideRecommend) {

            newData = newData.filter(({ vote }) => vote !== 'RECOMMEND');

        }

        if (hideNotRecommend) {

            newData = newData.filter(({ vote }) => vote !== 'NOT_RECOMMEND');

        }

        if (hideNeutral) {

            newData = newData.filter(({ vote }) => vote !== 'NEUTRAL');

        }

        if (hasText) {

            newData = newData.filter(({ feedbackText }) => !!feedbackText);

        }

        if (selected?.length) {

            newData = newData.filter(({ doctor }) => selected.includes(doctor?.specialization));

        }

        setData(
            sortByOption(newData, sortOrder)
        );

    }, [list, activeFilter, doctors, sortOrder, filterText, hasText, hideNeutral, hideRecommend, hideNotRecommend, selected]);

    return {
        filteredData: data,
        allData,
        count,
    };

};

const ListHeaderComponent = filteredData => <AllFeedbacksHeader items={filteredData} />;
const NoData = () => <RText id="titles.FeedbacksNotFoundSearch" style={[styles.emptyListText, {
    paddingLeft: 20,
    paddingTop: 8,
}]}
numberOfLines={null}/>;
export const MyFeedbacksList = ({ data }) => {

    const feedbacksLoading = useSelector(getMyFeedbacksLoading);
    const dispatch = useDispatch();

    const { filteredData, count } = useFeedbackFilterData(data);

    return (
        <View>
            <FlatList
                data={filteredData}
                ListEmptyComponent={count ? NoData : StubMyFeedbacksEmpty}
                ListHeaderComponent={ListHeaderComponent(filteredData)}
                renderItem={MyFeedbackListItem}
                refreshing={feedbacksLoading}
                onRefresh={() => {

                    dispatch(fetchMyFeedbacksPhysicians()).finally(() => {});

                }}
            />
        </View>
    );

};
