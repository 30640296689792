/* eslint-disable no-use-before-define */
import { useEffect, useRef, useState } from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';
import SpInAppUpdates from 'sp-react-native-in-app-updates';
import { storage } from '../services/localstorage';
import { elementsStyles } from '../styles/elements';
import actions from '../actions';
import { VERSION } from '../config';
import {
    Alert, Card, Platform, React, Text, View,
} from './react';
import { BigButton, Button } from './Button';
import { Icon } from './Icon';
import { Page } from './Page';
import { Prompt } from './Modal';

const KEY = 'skipUpdateAppVersion';
const PERIOD_KEY = 'inAppNotifyPeriod';
const PERIOD = 1000; // Sec
const DEBUG = false;

const log = m => DEBUG && actions.addLog('update', m);
const getCurrentVersion = () => {
    try {
        const v = VERSION?.toString()?.substring(0, 8).split('.');
        v[0] = `${v[0]}00`;
        return v.join('').split('-').join('');
    } catch (e) {
        return VERSION;
    }
};

// eslint-disable-next-line max-statements
export const InAppUpdater = ({
    useVersionCheck = false,
    permanentCancelUpdate = false,
    notifyPeriod = 0,
    elseComponent = null,
}) => {
    const [updates, setUpdates] = useState();
    const [isDownloaded, setIsDownloaded] = useState(false);
    const inAppUpdates = new SpInAppUpdates(DEBUG);
    const [windowOpen, setWindowOpen] = useState(false);
    const isNeedToShowPopup = useRef(true);
    let globalUpdatePeriod = 0;
    let globalNotifyPeriod = 0;
    let globalStorageVersion = '';
    let globalStoreVersion = '';

    const getPeriod = () => PERIOD * notifyPeriod;

    const getVersionFromStorage = async () => {
        const storageVersion = await storage.get(KEY);
        if (storageVersion) {
            return String(storageVersion).trim();
        }
        return '0';
    };

    const getPeriodFromStorage = async () => {
        const storagePeriod = await storage.get(PERIOD_KEY);
        if (storagePeriod) {
            return Number(storagePeriod || 0);
        }
        return 0;
    };

    const needUpdateVersion = (version, storageVersion, savedPeriod) => {
        const current = new Date().getTime();
        if (!version) {
            log('No version info!!!');
            return false;
        }
        if (permanentCancelUpdate) {
            const storeVersion = String(version).trim();
            if (storeVersion === storageVersion) {
                if (savedPeriod === 0) {
                    return false;
                }
                return savedPeriod < current;
            }
        }
        return true;
    };

    const checkUpdates = async () => {
        log('checkUpdates');
        if (!inAppUpdates) {
            log('No in app updates object');
            return;
        }

        const sv = await getVersionFromStorage();
        const updatePeriod = await getPeriodFromStorage();
        globalStorageVersion = sv;
        globalUpdatePeriod = updatePeriod;

        log({
            sv,
            updatePeriod,
        });

        inAppUpdates
            .checkNeedsUpdate({
                curVersion: getCurrentVersion(),
                customVersionComparator: (v1, v2) => {
                    log({
                        message: 'customComparator',
                        v1,
                        v2,
                    });
                    if (!v2) {
                        return -1;
                    }
                    if (v1 < v2) {
                        log({ message: 'v1 < v2' });
                        return -1;
                    }
                    if (v1 > v2) {
                        log({ message: 'v1 > v2' });
                        return 1;
                    }
                    log({ message: 'v1 = v2' });
                    return 0;
                },
                toSemverConverter: (v) => {
                    log({
                        message: 'semverConverter',
                        v,
                    });
                    return v.toString();
                },
            })
            .then((result) => {
                setUpdates(result);
                log({
                    message: 'then checkNeedsUpdate',
                    result,
                });
                if (!result?.shouldUpdate) {
                    log({
                        message: 'Other',
                        other: result?.other,
                    });
                    if (
                        result?.other?.updateAvailability >= 2 &&
                        result?.other?.versionCode >= getCurrentVersion() &&
                        result?.other?.isImmediateUpdateAllowed
                    ) {
                        log({ message: 'Already downloaded?' });
                        isNeedToShowPopup.current = false;
                        setWindowOpen(true);
                        setIsDownloaded(true);
                    }
                    return;
                }
                globalStoreVersion = result?.storeVersion;
                log({
                    globalStoreVersion,
                    result,
                    sv,
                    updatePeriod,
                });
                if (needUpdateVersion(result?.storeVersion, sv, updatePeriod)) {
                    log('Need update');
                    setWindowOpen(true);
                }
            })
            .catch((e) => {
                log({
                    message: 'catch checkNeedsUpdate',
                    e,
                });
                setWindowOpen(false);
            });
    };

    const onUpdateProcess = (status) => {
        log({
            message: 'onUpdateProcess',
            status,
        });
        if (Platform.OS !== 'android') {
            return;
        }
        if (status?.status === 11) {
            setIsDownloaded(true);
            // inAppUpdates.installUpdate();
        }
    };

    const startUpdate = (upd) => {
        log(upd);
        setWindowOpen(false);
        try {
            if (!inAppUpdates || !upd) {
                log('No in app updates || !upd');
                return;
            }
            let updateType;
            if (Platform.OS === 'android') {
                if (upd?.other?.isFlexibleUpdateAllowed) {
                    updateType = 0;
                } else if (upd?.other?.isImmediateUpdateAllowed) {
                    updateType = 1;
                }
                log(updateType);
            }
            inAppUpdates.addStatusUpdateListener(onUpdateProcess);
            inAppUpdates
                .startUpdate({ updateType })
                .then(startUpdateThen => log({
                    message: 'start update then',
                    startUpdateThen,
                }))
                .catch(e => log({
                    message: 'start update catch',
                    e,
                }));
        } catch (e) {
            log({
                message: 'start update something went wrong',
                e,
            });
        }
    };

    const installUpdate = (upd) => {
        log({
            message: 'install update fn',
            upd,
        });
        try {
            inAppUpdates.installUpdate();
            setWindowOpen(false);
        } catch (e) {
            log({
                message: 'Install update catch',
                e,
            });
        }
    };

    const showInstallUpdateWindow = () => {
        Page.showModal(
            <Prompt
                title="titles.inAppUpdateTitle"
                subtitle="titles.inAppUpdateSubtitle"
                buttonCloseTitle="titles.inAppUpdateLater"
                cancelTitle="titles.inAppUpdateInstall"
                submitTitle="titles.inAppUpdateLater"
                onCancel={() => {
                    log('onSubmit');
                    installUpdate();
                    Page.closeModal();
                }}
                onSubmit={() => {
                    log({ message: 'onClose' });
                    Page.closeModal();
                }}
                submitOptions={{
                    danger: false,
                    titleStyles: { fontFamily: 'SourceSansPro-Semibold' },
                }}
                cancelOptions={{ transparent: false }}
            />,
        );
    };

    useEffect(() => {
        log({
            isDownloaded,
            message: 'Effect is downloaded',
        });
        if (isDownloaded) {
            setWindowOpen(true);
            log({
                isDownloaded,
                message: 'isDownloaded',
            });
            if (isNeedToShowPopup.current) {
                showInstallUpdateWindow();
            }
            isNeedToShowPopup.current = true;

            log({ message: 'isDownloaded true, show Modal and window' });
        }
    }, [isDownloaded]);

    useEffect(() => {
        checkUpdates();
        return () => {
            if (inAppUpdates) {
                inAppUpdates.removeStatusUpdateListener(onUpdateProcess);
            }
        };
    }, []);

    const cancelUpdate = async () => {
        log('Cancel update');
        setWindowOpen(false);
        if (permanentCancelUpdate) {
            storage.set(KEY, String(updates?.storeVersion || 0));
            const period = notifyPeriod === 0 ? 0 : new Date().getTime() + getPeriod();
            globalNotifyPeriod = period;
            storage.set(PERIOD_KEY, period);
        }
    };

    const showInfoHandler = async () => {
        await checkUpdates();
        Alert.alert(
            ``,
            JSON.stringify({
                notifyPeriod,
                globalStorageVersion,
                globalStoreVersion,
                globalUpdatePeriod,
                globalNotifyPeriod,
            }),
        );
    };

    const DebugInfo = () => (DEBUG ? (
        <View style={{ flexDirection: 'column' }}>
            <Text>
                {JSON.stringify({
                    useVersionCheck,
                    windowOpen,
                    updates,
                })}
            </Text>
            <BigButton styles={{ paddingHorizontal: 10 }} title="Show info" action={showInfoHandler} />
            <BigButton styles={{ paddingHorizontal: 10 }} title="Show modal" action={showInstallUpdateWindow} />
        </View>
    ) : null);

    return (
        <>
            {Platform.OS === 'web' || !useVersionCheck || !windowOpen || !updates ? (
                <>
                    {elseComponent}
                    <DebugInfo />
                </>
            ) : (
                <Card containerStyle={styles.wrapper}>
                    <View style={styles.lineWrapper}>
                        <View>
                            <Text style={styles.text}>{Object.R('titles.haveNewVersion')}</Text>
                        </View>
                        <Button action={cancelUpdate} styles={styles.closeButton}>
                            <Icon name="cross" color="#787878" style={styles.closeIcon} />
                        </Button>
                    </View>
                    {isDownloaded ? (
                        <BigButton
                            styles={styles.button}
                            titleStyles={styles.buttonTitle}
                            title={Object.R('buttons.installUpdate')}
                            action={() => installUpdate(updates)}
                        />
                    ) : (
                        <BigButton
                            styles={styles.button}
                            titleStyles={styles.buttonTitle}
                            title={Object.R('buttons.update')}
                            action={() => startUpdate(updates)}
                        />
                    )}
                    <DebugInfo />
                    <View />
                </Card>
            )}
        </>
    );
};

export const styles = EStyleSheet.create({
    wrapper: {
        flexDirection: 'column',
        marginHorizontal: 12,
        padding: 20,
        borderColor: '#E0E0E0',
        elevation: 0,
        borderRadius: 3,
    },
    lineWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 5,
    },
    text: {
        ...elementsStyles.sectionHeaderText,
        paddingLeft: 0,
        alignContent: 'flex-start',
        textTransform: 'uppercase',
        marginTop: 5,
        letterSpacing: 0.5,
        color: '#555555',
    },
    textNormal: {
        ...elementsStyles.sectionHeaderText,
        fontFamily: '$sourceSansPropFontFamily',
        paddingLeft: 0,
        alignContent: 'flex-start',
        marginTop: 5,
        letterSpacing: 0.5,
        color: '#555555',
    },
    buttonTitle: { textTransform: 'uppercase' },
    button: {},
    closeButton: { marginBottom: 0 },
    closeIcon: { padding: 0 },
});
