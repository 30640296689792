import { useSelector } from 'react-redux';
import {
    dataFrom, dataTrigger, Icon, Page, React, selectOptions, View,
} from '../../../common';
import { getFromStoreDb, putIntoStore, SCHEME } from '../../../store';
import {
    getIsFavoriteMedcentersFilterActive,
    getIsPersonalized,
    getNumberOfActiveMedCentersFilters,
    getUserInfo,
} from '../../../selectors';
import actions from '../../../actions';
import { medcenterFilterOptions, medcenterSortOptions } from '../../../selectors/utils';
import { curry, isEmpty } from '../../../utils';

/**
 * FilterMenu` component for MedCentersList page.
 */

// `onFilterSelected` handler.
const onFilterSelected = curry(putIntoStore, SCHEME.MEDCENTERS_FILTER);

// `onSelectOptions` handler.
const onSelectOptions = programs => async () => {
    const selectedFilter = getFromStoreDb(SCHEME.MEDCENTERS_FILTER);
    const allOptions = medcenterFilterOptions();
    const actualOptions = programs ? allOptions : allOptions.filter(option => option.id !== 'byProgram');
    const selectedFilterId = selectedFilter ? selectedFilter.id : actualOptions[0].id;
    selectOptions({
        title: 'titles.medcenter_type',
        selected: selectedFilterId,
        data: actualOptions,
        onSelect: e => Page.closeModal(() => onFilterSelected(e)),
    });
};

export const FilterIcon = dataFrom(getUserInfo, ({
    data,
    filtersCount,
}) => (
    <Icon.Filter
        style={{ marginRight: 14 }}
        badge={filtersCount}
        onPress={onSelectOptions(data.programs && Object.keys(data.programs).length > 0)}
    />));

/**
 * SortMenu` component for MedCentersList page.
 */

const onSort = curry(putIntoStore, SCHEME.MEDCENTERS_SORT_BY);

const showSortModal = () => {
    const selectedSort = getFromStoreDb(SCHEME.MEDCENTERS_SORT_BY);
    const medcenterSort = medcenterSortOptions();
    const selectedSortId = selectedSort ? selectedSort.id : medcenterSort[0].id;
    selectOptions({
        title: 'titles.sort_list_of_medcenters',
        selected: selectedSortId,
        data: medcenterSort,
        onSelect: e => Page.closeModal(() => onSort(e)),
    });
};

export const FavoritesIcon = dataTrigger(getIsPersonalized, dataFrom(getIsFavoriteMedcentersFilterActive)(({ data: isFavoritesFilterActive }) => (
    <Icon
        style={{ marginRight: 19.2 }}
        ns={`bookmark-medcentersFilter-${isFavoritesFilterActive ? 'active' : 'normal'}`}
        name={`bookmark-${!isEmpty(isFavoritesFilterActive) ? 'active' : 'normal'}`}
        color={!isEmpty(isFavoritesFilterActive) ? '#5C85DD' : '#666666'}
        onPress={() => {
            putIntoStore(SCHEME.MEDCENTERS_FILTER_FAVORITES, isEmpty(isFavoritesFilterActive));
            actions.updateDocFilters({ filterFavoriteMedcenters: isEmpty(isFavoritesFilterActive) });
        }}
    />
)));

export const MedCentersListMenu = () => {
    const filtersCount = useSelector(getNumberOfActiveMedCentersFilters);
    return (
        <View style={{
            flexDirection: 'row',
            alignItems: 'center',
            paddingRight: 4,
        }}>
            <FavoritesIcon/>
            <FilterIcon filtersCount={filtersCount} onPress={onSelectOptions}/>
            <Icon.Sort onPress={showSortModal}/>
        </View>
    );
};
