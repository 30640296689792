import { View } from 'react-native';
import { useEffect, useState } from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';
import { React } from './react';
import { COLORS } from './style';
import { TapBarText } from './DatePicker';
import { Text } from '.';

const style = EStyleSheet.create({
    sectionTitle: {
        fontSize: 12,
        fontFamily: '$sourceSansPropFontFamily_semibold',
        color: '#787878',
        paddingVertical: 8,
    },
    sectionHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingRight: 12,
        paddingLeft: 24,
        alignItems: 'center',
    },
    seeMoreBtn: {
        marginVertical: 8,
        flexDirection: 'row',
        alignItems: 'center',
    },
    seeMoreBtnTitle: {
        fontSize: 14,
        fontFamily: '$sourceSansPropFontFamily_bold',
        color: COLORS.MAIN,
    },
    container: {
        marginHorizontal: 12,
        borderColor: '#E9E9E9',
        borderWidth: 1,
        borderRadius: 3,
        backgroundColor: '#fff',
    },
});

const SearchSectionHeader = ({
    sectionTitle, count, maxNumToRender, onShowMore, isOpen,
}) => {
    const title = Object.R('titles.showMore').toUpperCase?.();
    return (
        <View style={style.sectionHeader}>
            <Text style={[style.sectionTitle, count === 0 ? { paddingBottom: 0 } : {}]}>
                {sectionTitle?.toUpperCase?.()}
            </Text>
            {count > maxNumToRender ? (
                // <TouchableOpacity style={style.seeMoreBtn} onPress={onShowMore}>
                //     <Title style={style.seeMoreBtnTitle}>{Object.R('titles.showMore').toUpperCase()}</Title>
                //     {isOpen ? <Icon.Down color={COLORS.MAIN} size={17} /> : <Icon.Right color={COLORS.MAIN} size={17} />}
                // </TouchableOpacity>
                <TapBarText onPress={onShowMore} close={!isOpen} closeText={title} openText={title} />
            ) : null}
        </View>
    );
};

export const CustomSectionList = ({
    sectionTitle,
    data: _data,
    renderItem: RenderItem,
    maxNumToRender,
    keyword,
    limit = 50,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    // eslint-disable-next-line no-nested-ternary
    const data =
        // eslint-disable-next-line no-nested-ternary
        maxNumToRender && !isOpen
            ? _data?.slice?.(0, maxNumToRender)
            : _data?.length > limit
                ? _data?.slice?.(0, limit)
                : _data;
    const onShowMore = () => setIsOpen(!isOpen);
    useEffect(() => {
        if (isOpen) {
            setIsOpen(false);
        }
    }, [keyword]);
    return (
        <>
            <SearchSectionHeader
                sectionTitle={sectionTitle}
                isOpen={isOpen}
                onShowMore={onShowMore}
                count={_data?.length}
                maxNumToRender={maxNumToRender}
            />
            {data?.length ? (
                <View style={style.container}>
                    {data.map((e, index) => (
                        <RenderItem
                            item={e}
                            index={index}
                            isLast={!data?.length || data?.length - 1 === index}
                            keyword={keyword}
                        />
                    ))}
                </View>
            ) : null}
        </>
    );
};
