import { React } from './react';
import {
    Icon, Text, Title, TouchableOpacity, View,
} from '.';

export const FilterTag = ({ title, onClose = () => {}, getHeigth }) => {
    return (
        <View
            onLayout={getHeigth}
            style={{
                paddingVertical: 5,
                paddingRight: 6,
                paddingLeft: 12,
                flexDirection: 'row',
                alignItems: 'center',
                borderColor: '#5C85DD80',
                borderWidth: 1,
                backgroundColor: '#F5F7FF',
                borderRadius: 20,
                marginTop: 8,
                marginRight: 4,
            }}>
            <Text style={{ fontSize: 14 }}>{title}</Text>
            <Icon.Cross onPress={onClose} color="#555555" style={{ marginLeft: 4 }} size={18} />
        </View>
    );
};
