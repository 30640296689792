import { KeyboardAvoidingView } from 'react-native';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFocusEffect } from '@react-navigation/native';
import {
    BigButton,
    Field,
    Keyboard,
    Logo,
    Page,
    Platform,
    React,
    Section,
    showNotification,
    showSettingsModal,
    Text,
    TextInput,
} from '../../common';
import { back, navigate } from '../../actions';
import { validateByFieldType } from '../../common/fields';
import userServises from '../../services/user';
import { removeACodeForProvider } from '../../services/encryptedStorage';
import { downloadFile, openFileOnNewTab } from '../../services/device';
import { getIsAuthenticated } from '../../selectors';
import { memoryStorage } from '../../utils/memoryStorage';
import { aCodeSecurity } from '../../utils/aCodeSecurity';
import tracking from '../../services/tracking';
import { getErrorCodeIfNeeded } from '../../services/http';
import { setFormData } from '../../actions/forms';
import { consoleLog } from '../../utils/log';

export const ACodeConfirmation = Page.register(({ item, isVisit, navigation }) => {
    const [value, setValue] = useState(); // '811910'
    const [isInvalid, setIsInvalid] = useState(false);
    const [codeError, setCodeError] = useState();
    const [isLoading, setIsLoading] = useState();

    const isAuth = useSelector(getIsAuthenticated);
    useEffect(() => {
        if (!isAuth) {
            navigation.goBack();
        }
    }, [isAuth]);

    const onChange = (v) => {
        setIsInvalid(false);
        setValue(v);
    };
    // eslint-disable-next-line max-statements
    const onSubmit = async () => {
        const provider = item?.providerCode;
        Keyboard.dismiss();
        const validationError = validateByFieldType({ type: 'acode' }, value);
        setIsInvalid(!!validationError);
        if (validationError) {
            return;
        }
        try {
            setIsLoading(true);
            memoryStorage.remove(item);
            const result = isVisit
                ? await userServises.openVisitReportMem(item, value)
                : await userServises.openTestResultMem(item, value);

            if (result.data) {
                setCodeError();
                await aCodeSecurity.setCode(provider, value);
                const check = await aCodeSecurity.checkProvider(provider);
                await aCodeSecurity.resetUnexpiredAccess();
                userServises.resetAcodeTimeout();
                tracking.logEvent(`ai_${isVisit ? 'report' : 'test'}_open`);
                if (!check.selectedConfirmation && Platform.OS !== 'web') {
                    showSettingsModal(() => {
                        back();
                        result.contentType && result.contentType !== 'application/pdf'
                            ? downloadFile(result.data, showNotification, result.contentType)
                            : navigate('PDFViewer', {
                                sourceData: result.data,
                                name: item?.name,
                            });
                    });
                } else {
                    back();
                    // eslint-disable-next-line no-nested-ternary
                    Platform.OS === 'web'
                        ? openFileOnNewTab(result.data, item, isVisit)
                        : result.contentType && result.contentType !== 'application/pdf'
                            ? downloadFile(result.data, showNotification, result.contentType)
                            : navigate('PDFViewer', {
                                sourceData: result.data,
                                name: item?.name,
                            });
                }
            } else if (result.error) {
                setCodeError(
                    (result.error.code && result.error.code === 'INVALID_ACODE'
                        ? Object.R('error.invalid_acode')
                        : {
                            message: result.error.defaultMessage,
                            code: getErrorCodeIfNeeded(result.error),
                        } || Object.R('error.auth/network-error')) || result.error.status,
                );
            }
        } catch (e) {
            setCodeError(e);
            // setCodeError(Object.R('error.auth/internal-error'));
        } finally {
            setIsLoading(false);
        }
    };

    useFocusEffect(() => {
        removeACodeForProvider(item?.providerCode);
        // memoryStorage.filter(key => key?.providerCode === item?.providerCode);
    });

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(
            setFormData({
                ACodeConfirmation: {
                    back: (b) => {
                        consoleLog('Back pressed', b);
                        back();
                    },
                },
            }),
        );
    }, []);
    return (
        <Page name="a-code-confirmation" persistKeyboard notification={codeError}>
            <KeyboardAvoidingView behavior="position" style={Platform.OS === 'ios' ? { paddingBottom: 100 } : {}}>
                <Section flex>
                    <Logo.Wrapper
                        title="titles.enter_acode"
                        subtitle={Object.R('titles.enter_acode_hint', { branchName: item?.branchName })}
                        wrapperStyle={{ marginTop: 16 }}
                        titleStyle={{ marginTop: 10 }}>
                        <Field>
                            <TextInput.Number
                                ns="code"
                                placeholder="placeholders.code"
                                value={value}
                                checkValidation={v => validateByFieldType({ type: 'acode' }, v)}
                                invalid={isInvalid}
                                onChange={onChange}
                                autoCorrect={false}
                            />
                            {isInvalid ? (
                                <Text style={{ color: '#DF655C' }}>{Object.R('error.wrongFormat')}</Text>
                            ) : null}
                        </Field>
                        <BigButton
                            action={onSubmit}
                            title="buttons.send"
                            disabled={!value || isLoading}
                            busy={isLoading}
                        />
                    </Logo.Wrapper>
                </Section>
            </KeyboardAvoidingView>
        </Page>
    );
});
