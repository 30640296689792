import React, {
    useCallback, useEffect, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { SuggestionEdit } from '../../common/SuggestionEdit';
import { getSpecialitiesFullList } from '../../selectors';
import api from '../../services/api';
import { debouncer, sortByNational } from '../../utils';
import { DOCTOR_SEARCH_LIMIT } from '../../config';

const debounce = debouncer(500);

export const DoctorSuggestionEdit = (props) => {

    const searchCache = useRef({});
    const [list, setList] = useState([]);
    const [fullList, setFullList] = useState([]);
    const specialityList = useSelector(getSpecialitiesFullList);

    const [searchTimeout, setSearchTimeout] = useState();

    const prevText = useRef('');

    const fillList = useCallback((text) => {

        const sc = searchCache.current;
        const flatCache = Object.values(sc)?.flat?.() ?? [];
        if (searchCache[text] || flatCache?.find(({ fullName }) => fullName?.trim() === text?.trim())) {

            setList(sc[text]);
            return;

        }
        api.searchDoctorsByName(text, DOCTOR_SEARCH_LIMIT).then((data) => {

            searchCache.current = {
                ...sc,
                [text]: data?.physicians,
            };
            setList(sortByNational(data?.physicians ?? [], 'fullName'));

        }).catch(() => {

            setList([]);

        });

    }, [setList]);

    useEffect(() => {

        setFullList(list?.map?.(e => ({
            ...e,
            name: e?.fullName,
            specialization: specialityList?.find?.(s => s?.id === e?.specialityId)?.name,
        })));

    }, [list, specialityList]);

    const handleText = (text) => {

        if (prevText.current === text) {

            return;

        }
        prevText.current = text;
        props?.onChangeText?.(text);
        if (text?.length >= 3) {

            fillList(text);

        } else {

            setList([]);

        }

    };


    const onChangeTextHandler = (text) => {

        if (searchTimeout) {

            clearTimeout(searchTimeout);

        }
        setSearchTimeout(setTimeout(() => handleText(text), 800));

    };

    const onSelectHandler = (item) => {

        prevText.current = item?.fullName;
        props?.onSelect?.(item);

    };


    return <SuggestionEdit
        {...props}
        placeholder={'doctorsInfoFields.fullName'}
        onChangeText={onChangeTextHandler}
        onSelect={onSelectHandler}
        list={fullList}
        suggestionKey={'userDoctors'}
    />;

};
