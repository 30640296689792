import { Text } from 'react-native';
import { Linking, Platform, React } from './react';

const LinkItem = ({
    text,
    style,
    onClickText,
    onClickLink,
}) => {

    const parts = text.split('::');
    const link = String(Object.R([parts[1]]) ?? '');

    const open = (url) => {

        if (Platform.OS === 'web') {


            window.open(link, '_blank');

        } else {

            Linking.openURL(url);

        }

    };

    const pressHandler = () => {

        if (onClickLink) {

            onClickLink();

        }
        Linking.canOpenURL(link ?? '')
            .then(() => open(link))
            // eslint-disable-next-line no-console
            .catch(() => console.log('Cannot open URL'));

    };
    if (!link) {

        return <Text style={style} onPress={() => onClickText && onClickText()}>{parts[0]}</Text>;

    }
    const linkColor = style ? style.linkColor ?? 'blue' : 'blue';
    return <Text style={{
        ...style,
        color: linkColor,
    }} onPress={pressHandler}>{parts[0]}</Text>;

};

const matchAll = (s, regExp) => {

    const res = [];
    try {

        const match = s.match(regExp);
        let currentIndex = 0;
        match.forEach((element) => {

            const findPosition = s.indexOf(element, currentIndex);
            res.push({
                0: element,
                1: element.substring(1, element.length - 1),
                index: findPosition,
            });
            currentIndex += element.length;

        });

    } catch (e) {
        //
    }
    return res;

};

export const HyperLink = ({
    title,
    style,
    linkStyle,
    onClickText,
    onClickLink,
}) => {

    const fields = matchAll(Object.R(title), /{([^}]*)}/gm);
    const textLength = title.length;
    const result = [];
    let currentPosition = 0;
    let key = 1;
    for (let i = 0; i < fields.length; i++) {

        const field = fields[i];
        if (currentPosition >= textLength || !field.index) {

            break;

        }
        if ((field.index > 0 && !currentPosition) || currentPosition < field.index) {

            const t = title.substring(currentPosition, field.index);
            result.push(<Text style={style} key={key++} onPress={() => onClickText && onClickText()}>{t}</Text>);
            currentPosition += (field.index + 1);

        }
        const linkText = title.substring(field.index + 1, (field.index + field[0].length) - 1);
        result.push(<LinkItem key={key++} style={{
            ...style,
            ...linkStyle,
        }} text={linkText} onClickLink={onClickLink}
        onClickText={onClickText}/>);
        currentPosition = field.index + field[0].length;

    }
    if (currentPosition < textLength) {

        result.push(<Text onPress={() => onClickText && onClickText()} key={key}
            style={style}>{title.substring(currentPosition)}</Text>);

    }
    return result;

};

