import { today, accessToken1, accessToken2, accessToken4, personId1, personId2, personId3, personId4, firebaseAnonymousToken } from '../const.stub';
import { defaultStorage, filledPrefsStorage } from './localstorage.dump';
import clinics from './clinics';
import clinicsFeedbackSum from './clinics_feedback_sum';
import physicians from './physicians';
import physiciansFeedbackSum from './physicians_feedback_sum';
import physicians_timetable_sum_static from './physicians_timetable_sum_static';
import physicians_timetable_sum from './physicians_timetable_sum';
import notifications from './notifications';
import { orders } from './orders';
import { encrypt, decrypt } from '../crypto';
function setVisitList(list, key) { 
    list.map(s=>decrypt(s, key)).forEach(e=>{
        Object.assign(orders[e.id], e);
    })
}

export const firebaseData =  {
    profile: ({
        [personId1]: {
            get visitList(){ return Object.values(orders).map(e=>encrypt(e, personId1)) },
            set visitList(list){ setVisitList(list, personId1) },
            prefs: encrypt(filledPrefsStorage.prefs, personId1),
        },
        [personId2]: {
            get visitList(){ return Object.values(orders).map(e=>encrypt(e, personId2)) },
            set visitList(list){ setVisitList(list, personId2) },
            prefs: encrypt(filledPrefsStorage.prefs, personId2),
        },
        [personId4]: {
            get visitList(){ return Object.values(orders).map(e=>encrypt(e, personId4)) },
            set visitList(list){ setVisitList(list, personId4) },
            prefs: encrypt(filledPrefsStorage.prefs, personId4),
        },
    }),
    profileEvents: ({
        [personId1]: {
        },
        [personId2]: {
        },
        [personId4]: {
        },
    }),
    userData: ({
        [accessToken1]: {
            visits: encrypt(filledPrefsStorage.userVisitsList, personId1),
            prefs: encrypt(filledPrefsStorage.prefs, personId1),
        },
        [accessToken2]: {
            visits: encrypt(defaultStorage.userVisitsList, personId2),
            prefs: encrypt(defaultStorage.prefs, personId2),
        },
        [accessToken4]: {
            visits: encrypt(defaultStorage.userVisitsList, personId4),
            prefs: encrypt(defaultStorage.prefs, personId4),
        },
    }),
    authStats: ({
        [firebaseAnonymousToken]: { },
        [personId1]: { },
        [personId2]: { },
        [personId4]: { },
    }),
    physicians_feedback_sum: physiciansFeedbackSum,
    
    physicians_timetable_sum:   ({ ...physicians_timetable_sum_static, ...physicians_timetable_sum }),
    
    physicians:  physicians,
    clinics: clinics,
    clinics_feedback_sum: (clinicsFeedbackSum),
    
    notifications:   notifications,
    profileEvents:   notifications,
    visits:  ({}),
    
};
