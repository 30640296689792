import EStyleSheet from 'react-native-extended-stylesheet';

export const listItem = EStyleSheet.create({
    feedbackContainer: {
        paddingVertical: 16,
        borderBottomColor: '$lightGrayBorder',
        borderBottomWidth: 1,
        marginHorizontal: 20,
    },
    iconContainer: { top: 16 },
    titleForm: {
        marginBottom: 12,
        marginLeft: 8,
        marginTop: 14,
    },
    feedbackSubtitle: {
        paddingLeft: 12,
        marginBottom: 20,
        marginTop: -10,
    },
    checkboxContainer: {
        marginTop: 8,
        marginBottom: 20,
    },
    feedbackInput: {
        flexGrow: 1,
        minHeight: 70,
        // outline: 'none',
    },
    responseContainer: {
        backgroundColor: '$lightGrayBg',
        paddingVertical: 16,
        paddingHorizontal: 8,
        borderRadius: 2,
        marginTop: 12,
    },
    responseHeaderContainer: { flexDirection: 'row' },
    responseUserNameText: {
        flex: 3,
        fontWeight: '400',
        lineHeight: 15.08,
    },
    responseDateText: {
        flex: 1.5,
        textAlign: 'right',
        fontWeight: '400',
        lineHeight: 15.08,
    },
    responseText: {
        marginTop: 8,
        fontWeight: '400',
        lineHeight: 17.6,
        fontSize: 14,
    },
});
