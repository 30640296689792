import api from '../../../services/api';
import { sortBy } from '../../../utils';
import {
    filterAdultChild,
    filterEmptyProfiles,
    mapAllowBookingProfiles,
    mapProfilesAfterRequest,
    mapProfilesToRequestBookCapabilities,
} from './visitorsListOperations';
import { bookVisitorsCache, isCacheValid } from './caches';

const ALLOWED_PROFILE_STATUSES_FOR_BOOKING = ['BOOK_VISITS'];

export const cloneObject = (o) => {

    if (typeof o !== 'object') {

        return o;

    }
    try {

        return JSON.parse(JSON.stringify(o));

    } catch (e) {

        return { ...o };

    }

};

export const loadVisitors = ({
    info = {},
    requestBookCapabilities = true,
    manualVisitors = [],
    assignmentId = '',
    serviceId = undefined,
    cacheRequest = true,
}) => {

    return new Promise((resolve, reject) => {

        if (!info?.info) {

            resolve([]);

        }
        let profiles = [...(info?.info?.relatives ?? [])];
        const me = {
            ...info?.info?.me,
            name: info?.info?.name,
            birthday: info?.info?.birthday,
            isMe: true,
            index: 1,
            phones: info?.info?.phones,
            emails: info?.info?.emails,
        };
        !!me && profiles.push(me);
        profiles = profiles.filter(
            ({ accessLevels, isMe }) => isMe || accessLevels?.some?.(s => ALLOWED_PROFILE_STATUSES_FOR_BOOKING.includes(s)),
        );
        profiles = filterAdultChild(filterEmptyProfiles(profiles));
        if (
            cacheRequest &&
            isCacheValid(profiles) &&
            assignmentId === bookVisitorsCache.cachedAssignmentId &&
            serviceId === bookVisitorsCache.cachedServiceId &&
            bookVisitorsCache.cachedRequest === requestBookCapabilities
        ) {

            resolve(bookVisitorsCache?.cachedProfiles?.concat?.(manualVisitors));
            return;

        }
        if (requestBookCapabilities) {

            api.getBookingCapabilitiesWithProfiles(
                assignmentId,
                serviceId === '0' ? undefined : serviceId,
                profiles?.length ? mapProfilesToRequestBookCapabilities(profiles) : undefined,
            )
                .then((prof) => {

                    // consoleLog('received profiles ', { profiles, prof });
                    const list = mapProfilesAfterRequest(
                        prof
                            .filter(({ profileId }) => profiles?.find(({ profileId: id }) => id === profileId))
                            ?.map((pl, index) => {

                                const p1 = cloneObject(pl);
                                const found = cloneObject(
                                    profiles.find(({ profileId }) => profileId === p1.profileId) ?? {},
                                );
                                const result = cloneObject(found);
                                result.index = index + 2;
                                result.subName = found?.isMe ? Object.R('titles.for_me') : found?.subName;
                                result.profileHint = found?.isMe ? Object.R('titles.for_me') : found?.profileHint;
                                Object.entries(p1).forEach(([key, value]) => {

                                    result[key] = cloneObject(value);

                                });
                                return result;

                            })
                            ?.concat?.(mapAllowBookingProfiles(manualVisitors)),
                    );
                    bookVisitorsCache.cachedProfiles = [...list]?.filter(({ manual }) => !manual);
                    bookVisitorsCache.cachedAssignmentId = assignmentId;
                    bookVisitorsCache.cachedServiceId = serviceId;
                    bookVisitorsCache.cachedRequest = requestBookCapabilities;
                    resolve(list);

                })
                .catch(e => reject(e));

        } else {

            const list = sortBy(
                mapProfilesAfterRequest(
                    mapAllowBookingProfiles(
                        profiles?.concat?.(manualVisitors)?.map(p => ({
                            ...p,
                            profileHint: p?.isMe ? Object.R('titles.for_me') : p?.profileHint,
                            subName: p?.isMe ? Object.R('titles.for_me') : p?.subName,
                        })),
                    ),
                ),
                'index',
            );
            bookVisitorsCache.cachedProfiles = [...list]?.filter(({ manual }) => !manual);
            bookVisitorsCache.cachedAssignmentId = assignmentId;
            bookVisitorsCache.cachedServiceId = serviceId;
            bookVisitorsCache.cachedRequest = requestBookCapabilities;
            resolve(list);

        }

    });

};
