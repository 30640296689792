export const serviceProviders = [
    {
        providerId: '5b3f5fa4529813245',
        providerName: 'Лодэ на Независимости',
        itemIds: [
            '5bcf3c70213a045efd0cddab',
            '5bbd02c57cf5cf2c1cc9bbc8',
            '5bbd02c57cf5cf2c1cc9bae6',
            '5bbd02c57cf5cf2c1cc9bbcb',
        ],
    },
];
