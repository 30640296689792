import {
    BigButton,
    Button,
    ButtonsGroup,
    Image,
    Keyboard,
    Link,
    Modal,
    Page,
    Panel,
    Platform,
    React,
    Section,
    showNotification,
    Subtitle,
    TextArea,
    Title,
    VBox,
    View,
    withState,
} from '../../common';
import { COVERERS } from '../../assets';
import { getDisabledCoverers, getUserInfo } from '../../selectors';
import { getEnums, getErrorMessage } from '../../i18n';
import { buttonsStyles } from '../../styles/button';
import { getDeviceModel } from '../../services/device';
import allActions from '../../actions';
import { MY_API_HOST, VERSION } from '../../config';
import { chooseProgram as styles } from './styles';

const defaultDisabledCoverers = {
    bns: {
        link: `https://${MY_API_HOST}/files/bns-program/bns_memo.html`,
        shouldHideButton: true,
    },
};

const PromptForUserInfo = ({ done }) => (
    <Modal
        onRequestClose={() => done()}
        title="titles.enter_info"
        subtitle="titles.nothing_about_you"
    >
        <ButtonsGroup>
            <Button transparent title="buttons.later" action={() => done()}/>
            <Button
                primary
                title="buttons.fill"
                action={() => done('EditAccount')}
            />
        </ButtonsGroup>
    </Modal>
);

const AddCompanyModal = withState(({
    value = '',
    isSending = false,
    error,
    setState,
    onChangeText = (v) => {
        setState({ value: v });
    },
}) => (
    <Modal title="titles.enterNameOfYourCompany" onOutsidePress={() => {
        Keyboard.dismiss();
    }}>
        <TextArea
            accessibilityLabel="input:insurance_input"
            style={{
                height: 95,
                borderColor: '#E0E0E0',
            }}
            onChange={onChangeText}
        />
        {
            error &&
            <Title
                id={error.code}
                color="red"
                numberOfLines={null}
                style={{
                    marginTop: 12,
                    paddingHorizontal: 12,
                }}
            >{error}
            </Title>
        }
        <View style={[buttonsStyles.buttons_group, { paddingHorizontal: 0 }]}>
            <Button transparent styles={{ flex: 1 }} title="button.cancelButton" action={() => Page.closeModal()}/>
            <Button
                primary
                styles={{ flex: 1 }}
                disabledStyle={{ backgroundColor: '#B6BEDA' }}
                title="buttons.send"
                disabled={!value || !value.match(/[a-zA-Zа-яА-ЯЁё]+/) || isSending}
                action={async () => {
                    setState({ isSending: true });
                    const deviceModel = await getDeviceModel();
                    await allActions.modifyFeedback({
                        forItemKind: 'insurer_request',
                        forItemId: VERSION,
                        feedbackText: `${Platform.OS}-${Platform.Version}-${deviceModel}: ${value}`,
                    }, (err) => {
                        if (err) {
                            Object.trackAction('insurer_request_error');
                            let errorText = getErrorMessage(err.code);
                            if (!errorText || errorText === 'undefined') {
                                errorText = err.message ? err.message : typeof err === 'string' && err;
                            }
                            setState({
                                error: errorText,
                                isSending: false,
                            });
                        } else {
                            Object.trackAction('insurer_request_sent');
                            Page.closeModal();
                            showNotification('titles.addInsurerCompanyMessage');
                        }
                    });
                }}
            />
        </View>
    </Modal>));

export const ChooseProgram = Page.register(
    ({
        user,
        disabledCoverers = defaultDisabledCoverers,
        programs = getEnums('corporatePrograms'),
        actualPrograms = programs.filter(e => !(disabledCoverers[e.id]?.shouldHideButton)),
        selectedRoute = actualPrograms[0].routeTo,
        setState,
        navigation,
        actions,
        relative,
        Home = () => {
            if (selectedRoute === 'Home' && !user.isPerson) {
                if (Object.callbackOnSignIn) {
                    navigation.replace(Object.preEnrollVisitRoute);
                } else {
                    setState({
                        modal: <PromptForUserInfo
                            done={route => (route ? navigation.replace(route) : navigation.pop())}
                        />,
                    });
                }
            } else {
                actions.navigate('Home');
            }
        },
        EpamSignIn = () => {
            if (disabledCoverers.epam?.link) {
                actions.navigate('WebPage', {
                    title: Object.R(`coverer.epam`),
                    source: { uri: disabledCoverers.epam.link },
                });
            } else {
                actions.navigate(relative ? 'EpamAddRelativeHint' : 'EpamSignIn');
            }
        },
        BnsSignIn = () => {
            if (disabledCoverers.bns?.link) {
                actions.navigate('WebPage', {
                    title: Object.R(`coverer.bns`),
                    source: { uri: disabledCoverers.bns.link },
                });
            } else {
                actions.navigate('BnsSignIn', { relativeId: relative?.profileId });
            }
        },
        openAddCompanyModal = () => {
            Page.showModal(<AddCompanyModal/>);
        },
        programList = {
            EpamSignIn,
            BnsSignIn,
            Home,
        },
        modal,
    }) => (
        <Page
            name="choose-program"
            footer={
                <Section>
                    <Link
                        underline
                        style={{
                            alignSelf: 'center',
                            marginBottom: 20,
                        }}
                        href={openAddCompanyModal}
                    >titles.addYourInsurerLink
                    </Link>
                    <BigButton
                        action={() => {
                            programList[selectedRoute]();
                        }}
                        title={selectedRoute === 'Home' ? 'buttons.without_program' : 'buttons.bind_the_program'}
                        disabled={!selectedRoute}
                    />
                </Section>
            }
        >
            <Section>
                <VBox gap={1}>
                    <Title.Bigger bold id="titles.program_selection"/>
                    <Subtitle numberOfLines={null} id="titles.choose_program_subtitle"/>
                </VBox>
            </Section>
            <Section>
                <View
                    accessibilityLabel="home_navigation-section"
                    style={{
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                    }}
                >
                    {actualPrograms.map(({
                        name,
                        id,
                        routeTo,
                    }, i) => (
                        <Panel
                            ns={id}
                            key={id}
                            vertical
                            centered
                            justify="center"
                            onPress={() => setState({ selectedRoute: routeTo })}
                            style={[styles.chooseProgramPanel, selectedRoute === routeTo && styles.chooseProgramPanelActive, (i + 1) % 2 === 0 ? styles.chooseProgramPanelLeft : null]}
                        >
                            {COVERERS[id] && <Image source={(COVERERS[id] || COVERERS.unknown).big}
                                style={[styles[id], styles.image]}/>}
                            <Title bold centered style={id === 'no' ? {} : {
                                position: 'absolute',
                                bottom: 8,
                                lineHeight: 19,
                            }}>{name}</Title>
                            {id === 'no' && <Subtitle.Bigger numberOfLines={null} centered style={{
                                marginTop: 16,
                                color: '#A0A0A0',
                                fontSize: 14,
                            }} id="titles.without_program"/>}
                        </Panel>
                    ))}
                </View>
            </Section>
            {modal}
        </Page>
    ),
    {
        user: { from: getUserInfo },
        disabledCoverers: { from: getDisabledCoverers },
    },
);

