import React, { useCallback, useState } from 'react';
import { IMAGES } from '../../assets';
import { elementsStyles } from '../../styles/elements';
import { SCALE_COLORS } from './utils';

const styles = {
    priceTooltip: { alignItems: 'center' },
    body: {
        height: 24,
        borderRadius: 12,
    },
    text: {
        color: 'white',
        fontSize: 14,
        padding: 4,
        marginHorizontal: 4,
        paddingTop: 2,
    },
    callout: {
        width: 0,
        height: 0,
        borderLeftWidth: 5,
        borderRightWidth: 5,
        borderTopWidth: 13,
        borderStyle: 'solid',
        backgroundColor: 'transparent',
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottom: 0,
    },
};

const PriceTooltip = ({
    price, backgroundColor = '#A2AEBD', unit, nullTitle, toolTipTitle,
}) => {

    const title = price === null ? nullTitle : `${toolTipTitle || price}${unit ? ` ${unit}` : ''}`;

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>
            <div
                style={{
                    height: 24,
                    borderRadius: 12,
                    backgroundColor,
                    width: 'auto',
                    padding: '0 10px',
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                <span style={{
                    ...elementsStyles.headLine,
                    ...styles.text,
                    whiteSpace: 'nowrap',
                }}>{title}</span>
            </div>
            <div style={{
                ...styles.callout,
                borderTopColor: backgroundColor,
            }} />
        </div>
    );

};

export const MapMarker = React.memo(
    ({
        data,
        identifier,
        pressHandler,
        coordinate,
        forwardRef,
        image,
        scaleKey,
        toolTipKey,
        price = data[scaleKey],
        colorId,
        isWithoutData = price === null,
        backgroundColor = isWithoutData ? '#A2AEBD' : SCALE_COLORS[colorId - 1],
        unit,
        nullTitle,
        toolTipTitle,

        data: {
            id, latitude, longitude, isSelected,
        },
    }) => {

        const onPress = useCallback(
            (event) => {

                pressHandler(data);
                event.stopPropagation();

            },
            [pressHandler, data],
        );
        const [isTooltipVisible, setTooltipVisible] = useState(false);

        const handleMouseEnter = () => {

            setTooltipVisible(true);

        };

        const handleMouseLeave = () => {

            setTooltipVisible(false);

        };
        return (
            <div onClick={onPress} key={identifier} ref={forwardRef}>
                <div
                    style={{
                        zIndex: 100,
                        marginLeft: 5,
                        marginTop: -35,
                        // width: 110,
                        height: 38,
                        display: isTooltipVisible || isSelected ? 'block' : 'none',
                    }}>
                    <PriceTooltip
                        price={data[toolTipKey] || price}
                        backgroundColor={backgroundColor}
                        unit={unit}
                        nullTitle={nullTitle}
                        toolTipTitle={toolTipTitle}
                    />
                </div>
                <img
                    // alt={'marker'}
                    // src={image}
                    src={colorId === -1 ? IMAGES.Dot8 : IMAGES[`Dot${isWithoutData ? 8 : colorId}`]}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                />
            </div>
        );

    },
    (prevProps, nextProps) => prevProps?.colorId === nextProps?.colorId &&
    prevProps?.data?.isSelected === nextProps?.data?.isSelected &&
    prevProps?.data?.id === nextProps?.data?.id,
);
