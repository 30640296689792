/* eslint-disable no-use-before-define */
import {
    NativeModules, PermissionsAndroid, Platform, processColor,
} from 'react-native';
import { toDate } from '../utils';
import { request, PERMISSIONS, RESULTS } from 'react-native-permissions';
import { showNotification } from '../screens';

/* eslint-disable max-params */
export function createEvent(eventName = '', startDate, endDate, location, description, allDay = false) {

    presentEventCreatingDialog({
        title: eventName,
        startDate: toDate(startDate).toISOString(),
        endDate: toDate(endDate)?.toISOString(),
        location,
        notes: description,
        allDay,
        navigationBarIOS: {
            barTintColor: '#5C85DD',
            tintColor: 'white',
        },
    });

}

// noinspection CommaExpressionJS
const { WRITE_CALENDAR, READ_CALENDAR } = Platform.OS === 'web' ? ('', '') : PermissionsAndroid.PERMISSIONS;
const { AddCalendarEvent } = NativeModules;

const presentEventCreatingDialog = (options) => {

    const toCall = () => setTimeout(() => {

        // noinspection JSIgnoredPromiseFromCall
        try {

            AddCalendarEvent.presentEventCreatingDialog(processColorsIOS(options));

        } catch (e) {
            //
        }

    }, 10);
    try {

        return withPermissionsCheck(toCall);

    } catch (e) {
        //
    }

};

const processColorsIOS = (config) => {

    if (Platform.OS === 'android' || !config) {

        return config;

    }
    const { navigationBarIOS } = config;
    if (navigationBarIOS) {

        const { tintColor, backgroundColor, barTintColor } = navigationBarIOS;
        // noinspection JSValidateTypes
        navigationBarIOS.tintColor = tintColor && processColor(tintColor);
        // noinspection JSValidateTypes
        navigationBarIOS.backgroundColor = backgroundColor && processColor(backgroundColor);
        // noinspection JSValidateTypes
        navigationBarIOS.barTintColor = barTintColor && processColor(barTintColor);

    }
    return config;

};

const withPermissionsCheck = (toCallWhenPermissionGranted) => {

    if (Platform.OS === 'android') {

        return withPermissionsCheckAndroid(toCallWhenPermissionGranted);

    }
    return withPermissionsCheckIOS(toCallWhenPermissionGranted);

};

const permissionNotGranted = 'permissionNotGranted';

const getObjectText = (key, defaultText) => {

    // console.log('getObjectText', key);
    if (key) {

        const text = Object.R(key);

        return text === key ? defaultText ?? text : text;

    }
    return null;

};

const withPermissionsCheckAndroid = async (toCallWhenPermissionGranted) => {


    // console.log('check android perm');
    // it seems unnecessary to check first, but if permission is manually disabled
    // the PermissionsAndroid.request will return granted (a RN bug?)
    const [hasWritePermission, hasReadPermission] = await Promise.all([
        PermissionsAndroid.check(WRITE_CALENDAR),
        PermissionsAndroid.check(READ_CALENDAR),
    ]);

    if (hasWritePermission === true && hasReadPermission === true) {

        return toCallWhenPermissionGranted();

    }

    const results = await PermissionsAndroid.requestMultiple([WRITE_CALENDAR, READ_CALENDAR]);

    if (
        results[READ_CALENDAR] === PermissionsAndroid.RESULTS.GRANTED &&
        results[WRITE_CALENDAR] === PermissionsAndroid.RESULTS.GRANTED
    ) {

        return toCallWhenPermissionGranted();

    }
    showNotification(getObjectText('error.calendar_permissionAndroid', 'Для работы с календарем предоставьте приложению к нему доступ в настройках Android'));
    return Promise.reject(permissionNotGranted);

};

const withPermissionsCheckIOS = async (toCallWhenPermissionGranted) => {

    let hasPermission = false;
    try {

        const result = await request(PERMISSIONS.IOS.CALENDARS_WRITE_ONLY);
        hasPermission = result === RESULTS.GRANTED;
        // hasPermission = result === RESULTS.GRANTED || result === RESULTS.UNAVAILABLE;
        // hasPermission = await AddCalendarEvent.requestCalendarPermission();

    } catch (e) {

        //

    }

    if (hasPermission) {

        try {

            return toCallWhenPermissionGranted();

        } catch (e) {
            //
        }

    } else {

        showNotification(getObjectText('error.calendar_permissionIOS'), 'Для работы с календарем предоставьте приложению к нему доступ в настройках iOS');

    }
    return Promise.reject(permissionNotGranted);

};
