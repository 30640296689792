import { createSelector, gettr } from './utils';

const getForms = store => store?.forms ?? {};

export const getFormData = createSelector([gettr('forms')], f => f);

export const getHighlighters = createSelector([getForms], f => f?.highlighter ?? []);

export const getFormValues = createSelector([getForms], f => f?.values ?? {});

export const getAttachmentsForVisit = createSelector([getFormValues], f => f?.attachmentsForVisit ?? []);

export const getVisitForScroll = createSelector([getFormValues], f => f?.visitForScroll);

export const isPhysicianCollectionFetched = createSelector(
    [getFormValues],
    f => f?.isPhysicianCollectionFetched ?? false,
);
export const getSuggestions = createSelector([getForms], f => f?.suggestions ?? {});
