import {
    Icon, LoadingIndicator, React, Section, Text, Title, TouchableOpacity,
} from '../../../common';
import { visitInput as styles } from '../style';

const DownArrowIcon = ({ onPress }) => (
    <Icon name="down-arrow" size={20} color="#555555" style={styles.inputIcon} onPress={onPress} />
);

export const VisitInfoSelector = ({
    title,
    sectionTitle,
    sectionStyle,
    isLoading,
    onSelect,
    enabled,
    selectorStyle,
}) => (
    <Section style={sectionStyle}>
        {sectionTitle ? <Text style={styles.inputHeader}>{Object.R(sectionTitle)}</Text> : null}
        {isLoading ? (
            <LoadingIndicator style={styles.loadingCapabilitiesIndicator} indicatorSize={30} />
        ) : (
            <TouchableOpacity disabled={!enabled} onPress={onSelect} style={[styles.containerInput, selectorStyle]}>
                <Title.Bigger style={styles.inputText}>{title}</Title.Bigger>
                {!enabled ? null : <DownArrowIcon onPress={onSelect} />}
            </TouchableOpacity>
        )}
    </Section>
);
