// noinspection UnnecessaryLocalVariableJS

import { Platform } from 'react-native';
import { DOCTOR_STUB, EMPTY_OBJECT } from './const';
import { doctorsWithPartnership } from './doctors';
import {
    getIsAuthenticated, getPastVisits, getUser, getUserPerson, getUserRelatives,
} from './getters';
import { getClinicsIdsForSelectedProgram, joinedClinics } from './medicians';
import { getCurrentOrFirstProgram } from './programs';
import { getSelectedService } from './services';
import { getIsEmailUnconfirmed, isProfileEmpty } from './user';
import {
    arrayMap, createSelector, dbGettr, getActualTimeslots, getLastSubName, gettr, memoizedGetActualTimeslots, memoizeLastResult,
} from './utils';
import { prepareStringToCompare } from '../utils/strings';
import { fmtTimeTz, getMexicoCityOffset } from '../utils/dates';
import {
    formatDateLong, formatDateShort, formatTime, formatTimeslots, isAvaliableOnWeek, isEmpty, isFutureVisit, parseToDdMmYyyy, someEmpty, sortBy, toDate,
} from '../utils';
import { SCHEME } from '../scheme';
import { CONVERT_TIMEZONE } from '../config';
import { CREATED_MANUALLY } from '../actions/visits';

const {
    VISITS_FILTER,
    VISITS_FILTER_VISITORS,
    VISITS_FILTER_CLINICS,
    VISITS_FILTER_ATTACHMENTS,
    VISITS_FILTER_REPORTS,
    VISITS_FILTER_DOCTORS,
    VISITS_FILTER_SPECIALITY,
} = SCHEME;

const getVisitItemDate = (date, withoutTime) => formatDateLong(date, !withoutTime, CONVERT_TIMEZONE, CONVERT_TIMEZONE);

export const getVisit = gettr('visitInfo');
export const getEditedVisit = gettr('visitEdit');
export const getViewedVisit = gettr('visitView');
export const getVisits = gettr('visits.data', null);
export const getImportedVisits = gettr('visits.importedVisits', null);
export const getVisitsLoadingState = gettr('visits.isLoading');

const authenticatedVisitsList = createSelector(
    [getVisits, getIsAuthenticated, getUserRelatives, getUserPerson],
    (visits, isAuthenticated, relatives, me) => {

        if (someEmpty(isAuthenticated, visits, me)) {

            return visits;

        }
        const relativesSafe = relatives || [];
        return arrayMap(Object.values(visits), (v) => {

            let visitor = { ...(relativesSafe.find(({ profileId: pId }) => pId === v.profileId) || me || {}) };
            if (v?.customerFirstName && visitor) {

                visitor.isMe = false;
                visitor.subName = v.customerFirstName;

            } else if (!v?.profileId) {

                visitor = {};

            }
            return {
                ...v,
                relatives: relativesSafe,
                visitor,
            };

        });

    },
);
const enrichVisits = (visits, clinics = {}, physicians = {}, imported = []) => {

    if (someEmpty(visits) && someEmpty(imported)) {

        // for un updated visits
        return visits;

    }

    const filteredImported = imported?.filter?.(({ id }) => !visits?.find?.(({ id: vId }) => id === vId)) ?? [];
    const visitArray = visits?.concat?.(filteredImported);
    const offset = getMexicoCityOffset(new Date()) + 1;

    // eslint-disable-next-line complexity
    return arrayMap(visitArray, (e) => {

        const {
            startDate,
            endDate,
            branchId,
            physicianId,
            id: orderId,
            visitor = {},
            relatives = [],
            providerCode,
            allowedActions = [],
            withoutTime = false,
            physicianName,
            branchName,
            status,
        } = e;
        const targetDate = toDate(endDate ?? startDate);
        const isFuture =
            (isFutureVisit(targetDate) && status === 'reserved') ||
            (isFutureVisit(targetDate) && status === CREATED_MANUALLY);
        const doctorInfo =
            physicians[`${physicianId}`] || physicianName
                ? {
                    ...DOCTOR_STUB,
                    fullName: physicianName,
                }
                : { ...DOCTOR_STUB };

        const worksAtInfo = doctorInfo.worksAt.find(({ id }) => id === branchId);
        const assignmentId = worksAtInfo && worksAtInfo.assignmentId;
        const mdInfo = Object.assign(Object.create(clinics[branchId] || { branchName }), { assignmentId });
        const { address, name: medcenterName } = mdInfo;

        // const endTimeFormat = formatTime(endDate, getTimeZoneDiffMinutes(endDate))?.trim?.();
        const endTimeFormat = formatTime(endDate, false, !CONVERT_TIMEZONE)?.trim?.();
        const targetDateInfo = withoutTime
            ? Object.R('titles.dateWithEmptyTime', { date: formatDateLong(startDate, false, false, CONVERT_TIMEZONE) })
            : `${formatDateLong(startDate, true, false, CONVERT_TIMEZONE)} ${
                  endDate === startDate ? '' : `${endTimeFormat ? '- ' : ''}${endTimeFormat}`
              }`;
        const specialization =
            e.status === CREATED_MANUALLY
                ? e.speciality ?? e?.specialization ?? ''
                : (doctorInfo.specialization || e?.specialization || e.speciality) ?? '';
        if (!doctorInfo.specialization) {

            const spec = (e.speciality || e.specialization) ?? '';
            try {

                doctorInfo.specialization = spec;

            } catch (_) {
                //
            }

        }
        const time = CONVERT_TIMEZONE ? fmtTimeTz(new Date(startDate), offset) : fmtTimeTz(new Date(startDate), 0);
        const res = {
            ...e,
            isFuture,
            canUpdate: allowedActions.some(el => el === 'UPDATE'),
            canDelete: allowedActions.some(el => el === 'DELETE'),
            canShowOnMap: !!branchId && branchId !== branchName,
            canAddToCalendar: !(Platform.OS === 'web'),
            service: e.notes,
            isBns: providerCode ? providerCode.includes('bns') : false,
            orderId,
            timetableId: e.timeslotId,
            doctorInfo,
            mdInfo,
            assignmentId: worksAtInfo ? assignmentId || e.assignmentId : null,
            name:
                e.name ||
                e.serviceName ||
                `${Object.R('titles.visit_to')} ${
                    e.status === CREATED_MANUALLY
                        ? e.speciality || 'врач'
                        : doctorInfo.specialization || e.speciality || 'врач'
                }`,
            targetDate,
            dayText: formatDateShort(
                startDate,
                new Date(Date.now()).getFullYear() !== new Date(startDate).getFullYear(),
            ),
            visitItemDate: startDate ? getVisitItemDate(startDate, withoutTime) : Object.R('date_in_not_defined'),
            time: withoutTime ? '' : time,
            // time: withoutTime ? '' : formatTime(startDate, getTimeZoneDiffMinutes(startDate)),
            address,
            fullName: doctorInfo.fullName,
            specialization,
            medcenterName: medcenterName ?? branchName ?? '',
            room: '',
            canReassignVisitor: Boolean(relatives.length),
            visitor,
            // for info lists:
            targetDateInfo,
            visitorNameInfo:
            // eslint-disable-next-line no-nested-ternary
                visitor && typeof visitor === 'object' && Object.keys(visitor)?.length
                    ? visitor.isMe
                        ? Object.R('titles.for_me')
                        : e?.customerFirstName
                            ? e?.customerFirstName
                            : `${getLastSubName(visitor.name)} (${visitor.relation}, ${parseToDdMmYyyy(visitor.birthday)})`
                    : e?.customerFirstName ?? '???',
        };
        // console.log('RES=', {
        //     res,
        //     e_name: e.name,
        //     e_serviceName: e.serviceName,
        //     status: e.status,
        //     e_speciality: e.speciality,
        //     doctor_infoSpecialization: doctorInfo.specialization,
        // });
        return res;

    }); // .filter(v => v.profileId === v.visitor?.profileId || v?.customerFirstName); // EPMAIMD-52914: commented this so user can see future visits without filling profile

};

export const importedVisits = createSelector([getImportedVisits], v => v);

export const actualVisits = createSelector(
    [authenticatedVisitsList, joinedClinics, doctorsWithPartnership, importedVisits],
    enrichVisits,
);

// actual

export const historyVisitsList = createSelector(
    [
        // dbGettr(PAST_VISITS),
        getPastVisits,
        getUser,
    ],
    (visits, user) => {

        try {

            const {
                info: {
                    storeKey, hashKey, relatives, me,
                } = {},
            } = user;
            if (!visits && storeKey) {

                return {
                    visits,
                    profileId: storeKey,
                };

            }
            if (!hashKey) {

                return {
                    visits: [],
                    profileId: storeKey,
                };

            }
            const otherVisits = visits;
            // let decrypted = Object.values(arrayToHash(visits?.map(e => decrypt(e.data, hashKey)).filter(Boolean)));
            // const otherVisits = decrypted.filter(({ targetDate, status }) => !(isFutureVisit(targetDate) && status === 'reserved'));

            const relativesSafe = relatives || [];

            const withVisitors = arrayMap(otherVisits, v => ({
                ...v,
                relatives: relativesSafe,
                visitor: relativesSafe.find(({ profileId: pId }) => pId === v.profileId) || me || {},
            }));

            const ev = enrichVisits(withVisitors)?.filter(
                v => v.profileId === v.visitor?.profileId || v?.customerFirstName,
            );
            return sortBy(ev, '-startDate');

        } catch (e) {

            return {
                visits: [],
                profileId: null,
            };

        }

    },
);

export const historyVisits = createSelector(
    [
        getPastVisits, // dbGettr(PAST_VISITS),
        joinedClinics,
        doctorsWithPartnership,
        getUser,
        dbGettr(VISITS_FILTER_VISITORS),
        dbGettr(VISITS_FILTER_ATTACHMENTS),
        dbGettr(VISITS_FILTER_REPORTS),
        dbGettr(VISITS_FILTER_CLINICS),
        dbGettr(VISITS_FILTER_DOCTORS),
        dbGettr(VISITS_FILTER_SPECIALITY),
        isProfileEmpty,
        getIsEmailUnconfirmed,
    ],
    // eslint-disable-next-line max-statements
    (
        visits,
        clinics,
        physicians,
        {
            info: {
                storeKey, hashKey, relatives, me,
            } = {}, isAuthenticated,
        },
        visitors = [],
        byAttachments = false,
        byReports = false,
        clinicsFilter = [],
        doctorsFilter = [],
        specialityFilter = [],
        profileEmpty,
        emailUnconfirmed,
        // eslint-disable-next-line max-params
    ) => {

        if (!visits && storeKey) {

            return {
                visits,
                profileId: storeKey,
                isAuthenticated,
                profileEmpty,
                emailUnconfirmed,
            };

        }
        if (!hashKey) {

            return {
                visits: [],
                profileId: storeKey,
                isAuthenticated: false,
                profileEmpty,
                emailUnconfirmed,
            };

        }

        // ensure unique ids
        // let decrypted = Object.values(arrayToHash(visits?.map(e => decrypt(e.data, hashKey)).filter(Boolean)));
        // const otherVisits = decrypted.filter(({ targetDate, status }) => !(isFutureVisit(targetDate) && status === 'reserved'));
        const otherVisits = visits;
        let result = sortBy(otherVisits, '-startDate');
        result = result.map(e => ({
            ...e,
            isReportsManageable:
                relatives?.find(r => r.profileId === e.profileId)?.isReportsManageable ||
                e.profileId === me?.profileId,
        }));
        if (visitors.length) {

            result = result.filter(({ profileId }) => visitors.some(id => id === profileId));

        }

        if (clinicsFilter.length) {

            result = result.filter(
                item => clinicsFilter.some(
                    clinic => clinic?.trim?.()?.toLowerCase() === item?.branchName?.trim()?.toLowerCase?.(),
                ),
                // clinic => clinic === item.branchId ||
                //         (!item.branchId &&
                //             item?.branchName?.trim()?.toLowerCase?.() === clinic?.trim?.()?.toLowerCase?.()),
            );

        }

        if (doctorsFilter.length) {

            result = result.filter(item => doctorsFilter.some((doctor) => {

                const res =
                        // (item.status !== CREATED_MANUALLY &&
                        //     (doctor === item.physicianId ||
                        //         (!item.physicianId &&
                        //             prepareStringToCompare(item.physicianName) === prepareStringToCompare(doctor)) ||
                        //         (doctor === Object.R('titles.undefinedDoctor') &&
                        //             (item.physicianId === undefined || item.physicianName === undefined)))) ||
                        // (
                        //     item.status === CREATED_MANUALLY &&
                        prepareStringToCompare(doctor) ===
                        `${prepareStringToCompare(item?.physicianName) ?? ''}${
                            prepareStringToCompare(item?.speciality) ||
                            prepareStringToCompare(item?.specialization) ||
                            ''
                        }`;
                    // );
                    // const res =
                    //         (item.status !== CREATED_MANUALLY &&
                    //             (doctor === item.physicianId ||
                    //                 (!item.physicianId &&
                    //                     prepareStringToCompare(item.physicianName) === prepareStringToCompare(doctor)) ||
                    //                 (doctor === Object.R('titles.undefinedDoctor') &&
                    //                     (item.physicianId === undefined || item.physicianName === undefined)))) ||
                    //         (
                    //             item.status === CREATED_MANUALLY &&
                    //             prepareStringToCompare(doctor) ===
                    //                 `${prepareStringToCompare(item?.physicianName) ?? ''}${
                    //                     prepareStringToCompare(item?.speciality) ||
                    //                     prepareStringToCompare(item?.specialization) ||
                    //                     ''
                    //                 }`
                    //         );
                return res;

            }));

        }
        if (specialityFilter.length) {

            result = result.filter(item => specialityFilter.some(
                spec => prepareStringToCompare(spec) === prepareStringToCompare(item.speciality) ||
                        prepareStringToCompare(spec) === prepareStringToCompare(item.specialization),
            ));

        }

        if (byAttachments) {

            result = result.filter(({ attachments = [] }) => attachments.length);

        }
        if (byReports) {

            result = result.filter(({ reports = [], isReportsManageable }) => isReportsManageable && reports.length);

        }
        const relativesSafe = relatives || [];

        result = arrayMap(result, (v) => {

            const visitor = { ...(relativesSafe.find(({ profileId: pId }) => pId === v.profileId) || me || {}) };
            if (v?.customerFirstName && visitor) {

                visitor.isMe = false;
                visitor.subName = v.customerFirstName;

            }
            return {
                ...v,
                relatives: relativesSafe,
                visitor,
            };

        });

        result = enrichVisits(result, clinics, physicians)?.filter(
            v => v.profileId === v.visitor?.profileId || v?.customerFirstName,
        );
        return {
            visits: result,
            profileId: storeKey,
            isAuthenticated,
            profileEmpty,
            emailUnconfirmed,
        };

    },
);

export const getClinicsFromVisitHistory = createSelector(
    [historyVisitsList, getClinicsIdsForSelectedProgram, getCurrentOrFirstProgram, dbGettr('clinics.data')],
    (visits, clinicsForProgram, program, clinicsData) => {

        const clinics = new Map();
        if (!visits || !visits.length) {

            return [];

        }
        const getCover = (id) => {

            if (!program) {

                return '';

            }
            if (program.branches.some(item => item === id)) {

                return program.coverer;

            }
            return '';

        };
        visits.forEach((item) => {

            const name = (item?.branchName ?? clinicsData[item.branchId]?.name ?? '')?.trim?.();
            if (name) {

                const tempId = (item?.status === CREATED_MANUALLY ? item.branchName : item?.branchName)
                    ?.trim?.()
                    ?.toLowerCase?.();
                // const tempId = (item?.status === CREATED_MANUALLY ? item.branchName : item?.branchId)?.trim?.();
                clinics.set(tempId, {
                    id: tempId,
                    name,
                    coverer: getCover(item?.branchId),
                    item,
                });

            }

        });
        const out = Array.from(clinics, ([id, value]) => ({
            _id: id?.trim?.(),
            id,
            name: value.name?.trim?.(),
            coverer: value.coverer,
            item: value.item,
        })).sort((a, b) => {

            if (a.name?.trim?.()?.toLowerCase?.() > b.name?.trim?.()?.toLowerCase?.()) {

                return 1;

            } else if (a.name?.trim?.()?.toLowerCase?.() === b.name?.trim?.()?.toLowerCase?.()) {

                return 0;

            }
            return -1;

        });
        return out;

    },
);

export const getDoctorsFromVisitHistory = createSelector(
    [historyVisitsList, dbGettr('physicians.data'), dbGettr('clinics.data')],
    (visits, doctorsList, clinics) => {

        if (!visits || !visits.length) {

            return [];

        }

        const doctors = {};

        const getDoctorName = (id, fallback) => {

            if (isEmpty(doctorsList)) {

                return fallback;

            }
            const res = doctorsList[id]?.fullName;
            // if (manual && !res) {
            //     return '';
            // }
            return res ?? fallback;

        };

        const getClinicName = (id, fallback) => {

            if (isEmpty(clinics)) {

                return fallback;

            }
            return clinics[id?.trim?.()]?.name?.trim?.() ?? fallback?.trim?.();

        };

        const unknownName = Object.R('titles.undefinedDoctor');
        // console.log('visits', visits);

        // eslint-disable-next-line complexity
        visits.forEach((item) => {

            const id =
                // item?.status === CREATED_MANUALLY // && !item?.physicianId
                //    ?
                `${item?.fullName?.trim?.() ?? ''}${
                    item?.speciality?.trim?.() ?? item?.specialization?.trim?.() ?? ''
                }` ?? '';
            //    : item?.physicianId?.trim?.() ?? '';
            const name = getDoctorName(
                id,
                `${
                    item?.status === CREATED_MANUALLY
                        ? item?.physicianName?.trim?.() ?? ''
                        : item?.physicianName?.trim?.() ?? unknownName?.trim?.()
                }`,
                item?.status === CREATED_MANUALLY,
            );
            if (name === '') {

                return;

            }
            const branchName = getClinicName(item?.branchId, item?.branchName?.trim?.() ?? '');
            if (item?.status === CREATED_MANUALLY) {

                if (!doctors[id]) {

                    doctors[id] = {
                        _id: id,
                        id,
                        name: name?.trim?.(),
                        speciality: item?.speciality?.trim?.() || item?.specialization?.trim?.() || '',
                        clinics: [branchName],
                        manual: true,
                    };

                }

            } else if (doctors[id]) {

                if (!doctors[id]?.clinics?.find(c => c === branchName)) {


                    doctors[id] = {
                        ...doctors[id],
                        clinics: [...(doctors[id]?.clinics ?? []), branchName],
                        speciality: item?.speciality || item?.specialization || '',
                        manual: false,
                    };

                }

            } else {

                doctors[id] = {
                    _id: id,
                    id,
                    name: name?.trim?.(),
                    speciality: item?.speciality?.trim?.() || item?.specialization?.trim?.() || '',
                    clinics: [branchName],
                    manual: false,
                };

            }

        });
        let out = Object.values(doctors); // ?.filter?.(v => v?.name !== unknownName);
        // let out = Object.values(doctors)?.filter?.(v => v?.name !== unknownName);
        out = out.sort((a, b) => {

            if (a.name?.toLowerCase() > b.name?.toLowerCase()) {

                return 1;

            } else if (a.name?.toLowerCase() === b.name?.toLowerCase()) {

                return 0;

            }
            return -1;

        });
        // console.log('Doctors from history', out);
        return out;

    },
);

export const getVisitorsFromVisitHistory = createSelector([historyVisitsList], (visits) => {

    if (!visits || !visits.length) {

        return [];

    }

    const visitors = {};
    visits.forEach((item) => {

        const { visitor } = item;
        const {
            profileId, name, isChild, isMe, isOwner, isParent, isSpouse, accessLevels,
        } = visitor;
        if (!visitors[profileId]) {

            visitors[profileId] = {
                id: profileId,
                profileId,
                name: isMe ? Object.R('titles.for_me') : name,
                isChild,
                isMe,
                isOwner,
                isParent,
                isSpouse,
                accessLevels,
            };
            // visitors[profileId] = { id: profileId, profileId, name: (isMe ? Object.R('titles.my_visit') : name), isChild, isMe, isOwner, isParent, isSpouse, accessLevel };

        }

    });
    return Object.values(visitors).sort((a, b) => {

        if (a.name?.toLowerCase() > b.name?.toLowerCase()) {

            return 1;

        } else if (a.name?.toLowerCase() === b.name?.toLowerCase()) {

            return 0;

        }
        return -1;

    });

});
export const getSpecialityFromVisitHistory = createSelector([historyVisitsList], (visits) => {

    if (!visits || !visits.length) {

        return [];

    }

    const speciality = new Set();
    visits.forEach((item) => {

        // console.log('Spec', item);
        speciality.add(
            item?.speciality?.trim?.()?.toLowerCase?.() ?? item?.specialization?.trim?.()?.toLowerCase?.() ?? '',
        );

    });
    return Array.from(speciality)
        .sort((a, b) => {

            if (a?.toLowerCase() > b?.toLowerCase()) {

                return 1;

            } else if (a?.toLowerCase() === b?.toLowerCase()) {

                return 0;

            }
            return -1;

        })
        .map(item => ({
            id: item,
            name: item,
        }));

});

export const getClinicsFilterFromVisitHistory = createSelector([getClinicsFromVisitHistory], clinics => clinics.map(item => ({
    ...item,
    selected: false,
})));
export const getDoctorsFilterFromVisitHistory = createSelector([getDoctorsFromVisitHistory], (doctors) => {

    const out = doctors
        ?.filter(e => e.id)
        ?.map(item => ({
            ...item,
            id: item?.id ?? item?.name,
            selected: false,
        }));
    // console.log('Doctors', {
    //     out,
    //     doctors,
    // });
    return out;
    // ?.filter(e => e.id)

});
export const getVisitorsFilterFromVisitHistory = createSelector([getVisitorsFromVisitHistory], visitors => visitors.map(item => ({
    ...item,
    selected: false,
})));

export const getSpecialityFilterFromVisitHistory = createSelector([getSpecialityFromVisitHistory], spec => spec
    ?.filter(e => e.id)
    .map(item => ({
        ...item,
        selected: false,
    })));

// noinspection JSUnusedGlobalSymbols
export const actualRecord = createSelector([getIsAuthenticated, getVisits], (isAuthenticated, visits) => ({
    isAuthenticated,
    visits,
}));

// planned
export const plannedVisits = createSelector(
    [actualVisits],
    visits => visits &&
        visits.filter(
            ({ status, endDate, startDate }) => status !== 'cancelled' &&
                (!endDate ||
                    (isFutureVisit(endDate) && status === 'reserved') ||
                    ((isFutureVisit(startDate) || isFutureVisit(endDate)) && status === CREATED_MANUALLY)),
        ),
);
// info

export const getVisitInfo = createSelector([getVisit], visit => visit);
export const getVisitInfoLoading = createSelector([getVisitInfo], (visit) => {

    const isLoading = !!visit?.isLoading;
    return isLoading;

});

export const getActualTimeslotsTimeslots = createSelector([getVisitInfo], visit => visit?.timeslots ?? []);

export const memoizedFormatTimeslots = memoizeLastResult(formatTimeslots);
export const memoizedIsAvaliableOnWeek = memoizeLastResult(isAvaliableOnWeek);
export const actualTimeslots = createSelector(
    [getVisitInfo, getSelectedService, getActualTimeslotsTimeslots, getVisitInfoLoading],
    (
        {
            error, date, timetableId, onlineBookingAllowed, declineReason, declineReasonCode,
        } = {},
        selectedService,
        timeslots,
        isLoading,
    ) => {

        let ts;
        if (selectedService?.duration) {

            ts = memoizedGetActualTimeslots(timeslots, selectedService?.duration);

        }

        const actualTimeslotsArr = selectedService?.duration ? ts : timeslots;
        const result = {
            isLoading,
            error,
            timeslots: actualTimeslotsArr,
            onlineBookingAllowed,
            declineReason,
            declineReasonCode,
            date: timeslots.length > 0 && !date ? Object.keys(memoizedFormatTimeslots(timeslots))[0] : date,
            timetableId,
            initState:
                timeslots.length > 0
                    ? memoizedIsAvaliableOnWeek(Object.keys(memoizedFormatTimeslots(timeslots))[0])
                    : undefined,
        };
        return result;

    },
);

export const actualTimeslotsVisitInfo = createSelector(
    [getVisitInfo, getSelectedService, getActualTimeslotsTimeslots, getVisitInfoLoading],
    (
        {
            error, date, timetableId, onlineBookingAllowed, declineReason, declineReasonCode,
        } = {},
        selectedService,
        timeslots,
    ) => {

        const actualTimeslotsArr = selectedService?.duration
            ? getActualTimeslots(timeslots, selectedService?.duration)
            : timeslots;
        const result = {
            error,
            timeslots: actualTimeslotsArr,
            onlineBookingAllowed,
            declineReason,
            declineReasonCode,
            date: timeslots.length > 0 && !date ? Object.keys(formatTimeslots(timeslots))[0] : date,
            timetableId,
            initState: timeslots.length > 0 ? isAvaliableOnWeek(Object.keys(formatTimeslots(timeslots))[0]) : undefined,
        };
        return result;

    },
);

export const getVisitDrugs = ({ visitInfo: { drugsList } }) => drugsList || [];

// noinspection JSUnusedGlobalSymbols
export const getVisitAddedDrugsIds = createSelector([getVisitDrugs], drugs => drugs.map(({ id: n }) => n));

// noinspection JSUnusedGlobalSymbols
export const getVisitMinPrice = createSelector(
    [getVisitDrugs],
    drugs => Math.round(drugs.reduce((acc, { price_min: p }) => acc + Number(p), 0) * 100) / 100,
);

// noinspection JSUnusedGlobalSymbols
export const getVisitDrugsNames = createSelector([getVisitDrugs], drugs => drugs.map(({ name }) => name));

export const getIsFetchingVisits = createSelector(
    [getVisitsLoadingState, getIsAuthenticated],
    (isLoadingVisits, isAuthenticated) => isAuthenticated && isLoadingVisits,
);

export const getNumberOfActiveVisitsFilters = createSelector(
    [dbGettr(VISITS_FILTER)],
    ({
        visitors = [],
        byAttachments = false,
        byReports = false,
        clinics = [],
        doctors = [],
        speciality = [],
    } = EMPTY_OBJECT) => [
        visitors.length ? 1 : 0,
        byAttachments,
        byReports,
        clinics.length ? 1 : 0,
        doctors.length ? 1 : 0,
        speciality.length ? 1 : 0,
    ].reduce((acc, next) => (next ? 1 + acc : acc), 0),
);

export const getIsVisitsLoadOrders = createSelector([gettr('visits')], visits => visits?.isLoadingOrders);

export const getEnableLoadPastsVisits = createSelector([dbGettr('enableLoadPastsVisits')], state => state);

export const getVisitsError = createSelector([gettr('visits.error')], state => state);
