/* eslint-disable no-param-reassign,no-underscore-dangle */
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import {
    InfoList, Link, Page, React, Section, Subtitle, Title,
} from '../../common';
import { getClinicsWithBumbaByType } from '../../selectors';
import { useDoctorDescriptions } from '../../hooks/doctorDescription';
import { View } from '../../common/react';
import { infoList as styles } from '../../styles/common';
import { sortBy } from '../../utils';
import { ClickableTitle } from '../../common/elements';
import { COLORS } from '../../styles/colors';
import { deepCloneObject } from '../../utils/objects';
import { sortClinics } from './utils';
import { useController } from './DoctorDetails';

/**
 * DoctorInfo Page (☆☆☆☆☆).
 */

export const DoctorInfo = Page.register(({ doctorInfo }) => {
    const bumbaClinics = useSelector(getClinicsWithBumbaByType);
    const navigation = useNavigation();

    const [docInfo, setDocInfo] = useState();
    // const clinics = useSelector(getMedcentersListWithPrograms);

    const hasBumbaTest = clinicId => !!(bumbaClinics ?? {})[clinicId]?.bumbaTestsSupport;
    const hasBumbaReport = clinicId => !!(bumbaClinics ?? {})[clinicId]?.bumbaReportsSupport;
    const hasBumba = clinicId => hasBumbaTest(clinicId) || hasBumbaReport(clinicId);

    doctorInfo.worksAt = sortClinics(
        doctorInfo?.worksAt?.map((clinic) => {
            clinic.hasBumba = hasBumba(clinic.id);
            return clinic;
        }),
    );

    const DoctorInfoClinic = ({ info }) => {
        if (Array.isArray(info)) {
            return (
                <View
                    style={{
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                    }}>
                    {info.map((i, index) => (
                        <DoctorInfoClinic key={index} info={i} />
                    ))}
                </View>
            );
        }
    };

    const doc = useController(doctorInfo?._id);

    const descriptions = useDoctorDescriptions(doctorInfo?._id, doc?.doctorInfo ?? doctorInfo);
    const mergeDescriptions = (d, info) => {
        const newD = deepCloneObject(d);
        if (!info) {
            return d;
        }
        for (let j = 0; j < d.worksAt?.length; j++) {
            const clinic = deepCloneObject(d.worksAt?.[j]);
            const i = info?.find?.(c => c?.branchId === clinic?.id);
            clinic.description = i?.description;
            clinic.head = i?.providerCode;
            newD.worksAt[j] = clinic;
        }

        if (typeof newD?.profile === 'object' && newD?.profile !== null && !Array.isArray(newD?.profile)) {
            newD.profile = Object.values(newD.profile);
        }

        return newD;
    };

    useEffect(() => {
        const merged = mergeDescriptions(doctorInfo, descriptions);
        setDocInfo(merged);
    }, [doctorInfo, descriptions]);
    // }, [doctorInfo, doc.doctorInfo, descriptions]);

    return (
        <Page name="doctor-info">
            <Section title="titles.general_info">
                <InfoList
                    listFooterText={doctorInfo.overview}
                    fields="doctorsInfoFieldsWithDescription"
                    data={docInfo}
                    customRenderer={(item, field, title) => {
                        const id = item?.id;
                        const d = item?.data?.[id];
                        const out = [];
                        if (!d && !Array.isArray(d)) {
                            return null;
                        }
                        const data = [
                            ...d.map?.(i => ({
                                ...i,
                                name: i?.name,
                                address: i?.address,
                                branchId: i?.clinicId,
                                use: false,
                            })),
                        ];
                        const length = data?.length;
                        let order = 0;
                        for (let i = 0; i < length; i += 1) {
                            if (!data[i].use) {
                                data[i].use = true;
                                const _id = data?.[i]?.[field] ?? '';
                                const _descr = data?.[i]?.description;
                                if (_id) {
                                    out.push({
                                        order,
                                        id: _id,
                                        [field]: data[i],
                                        data: [data[i]],
                                    });
                                    order += 1;
                                    for (let j = 0; j < length; j += 1) {
                                        if (
                                            !data[j].use &&
                                            (data[j][field] ?? '') === _id &&
                                            data[j].description === _descr
                                        ) {
                                            data[j].use = true;
                                            out[order - 1].data.push(data[j]);
                                        }
                                    }
                                }
                            }
                        }

                        const paddingSize = 12;
                        const doneDescriptions = [];

                        const Description = ({ description }) => {
                            if (!description) {
                                return null;
                            }
                            if (doneDescriptions.includes(description?.trim?.())) {
                                return null;
                            }
                            doneDescriptions.push(description?.trim?.());
                            return (
                                <View
                                    style={[
                                        {
                                            flexDirection: 'row',
                                            paddingBottom: paddingSize,
                                            paddingTop: paddingSize,
                                            borderBottomWidth: 1,
                                            borderBottomColor: COLORS.BORDER_GRAY,
                                            paddingHorizontal: 6,
                                        },
                                    ]}>
                                    <Title style={[styles.title]}>{`${Object.R('titles.doctorDescription')}:`}</Title>
                                    <View style={[styles.rightTitleBlock, { flexDirection: 'column' }]}>
                                        <ClickableTitle numberOfLines={4}>{description}</ClickableTitle>
                                    </View>
                                </View>
                            );
                        };

                        return (
                            <View
                                style={{
                                    flex: 1,
                                    marginHorizontal: 0,
                                    paddingHorizontal: 0,
                                }}>
                                {sortBy(out, 'order').map((i) => {
                                    return (
                                        <>
                                            <View
                                                style={[
                                                    {
                                                        flexDirection: 'row',
                                                        paddingBottom: paddingSize,
                                                        paddingTop: paddingSize,
                                                        // paddingBottom: dt?.description ? 4 : 16,
                                                        borderBottomWidth: 1,
                                                        borderBottomColor: COLORS.BORDER_GRAY,
                                                        paddingHorizontal: 6,
                                                    },
                                                ]}>
                                                <Title style={[styles.title]}>{`${title}:`}</Title>
                                                <View style={[styles.rightTitleBlock, { flexDirection: 'column' }]}>
                                                    {i?.data?.map?.((dt) => {
                                                        return (
                                                            <>
                                                                <Link
                                                                    href={() => navigation.navigate('MedCenterDetails', {
                                                                        mdInfo: {
                                                                            ...dt,
                                                                            _id: dt?.branchId,
                                                                        },
                                                                    })
                                                                    }>
                                                                    {dt?.name}
                                                                </Link>
                                                                <Subtitle>{dt?.address}</Subtitle>
                                                            </>
                                                        );
                                                    })}
                                                </View>
                                            </View>
                                            {i.data?.map((dt) => {
                                                return (
                                                    <>
                                                        <Description description={dt?.description} />
                                                    </>
                                                );
                                            })}
                                        </>
                                    );
                                })}
                            </View>
                        );
                    }}
                />
            </Section>
        </Page>
    );
});
