/* eslint-disable no-underscore-dangle */
import actions, { navigate } from '../../../actions';
import { SCHEME } from '../../../scheme';
import { putIntoStore } from '../../../store';

export const gotoDoctors = (name = '', useSearch, isToDoctors, from, specId) => {
    if (name) {
        const newFilters = {
            specialty: useSearch === true || (useSearch === 'true' && useSearch !== 'false') ? '' : name.toLowerCase(),
            specialtyId: specId,
            worksAt: [],
            worksAtGroup: 'any',
        };
        putIntoStore(SCHEME.PHYSICIANS_FILTER, newFilters);
        putIntoStore(SCHEME.PHYSICIANS_FROM_SERARCH, undefined);
        putIntoStore('specializationForApi', undefined);
        putIntoStore(
            SCHEME.PHYSICIANS_SEARCH,
            useSearch === true || (useSearch === 'true' && useSearch !== 'false') ? name : '',
        );
        actions.updateDocFilters(newFilters);
    }
    const navParams = isToDoctors
        ? [
            'DoctorList',
            {
                title: name,
                from,
            },
        ]
        : ['DoctorsSpecialtiesSearch'];
    setTimeout(() => {
        navigate(...navParams);
    }, 0);
};

export const adaptDoctorToFav = (doctor) => {
    return {
        displayName: doctor?.fullName || doctor?.name || doctor?.displayName, // ?.replace(' ', '\n'),
        photoUrl: doctor?.photoUrl,
        type: 'physicians',
        // worksAt: doctor?.worksAt,
        id: doctor?.id || doctor?._id,
    };
};

export const adaptClinicToFav = (clinic) => {
    return {
        displayName: clinic.fullName || clinic.name || clinic.displayName, // ?.replace(' ', '\n'),
        photoUrl: clinic.photoUrl,
        type: 'clinics',
        id: clinic.id || clinic._id,
        areaCode: clinic.areaCode,
    };
};
