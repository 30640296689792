import EStyleSheet from 'react-native-extended-stylesheet';
import { COLORS } from './colors';

const button = {
    marginTop: 12,
    borderRadius: 3,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingVertical: 12,
    height: 42,
};

export const buttonsStyles = EStyleSheet.create({
    button_half: { flex: 0.49 },
    button_normal: {
        ...button,
        backgroundColor: '$grayForButton',

    },
    button_transparent: { ...button },
    button_danger: {
        ...button,
        backgroundColor: '$red',
    },
    button_orange: {
        ...button,
        backgroundColor: 'orange',
    },
    button__title: {
        color: '$white',
        fontFamily: '$sourceSansPropFontFamily_semibold',
    },
    buttons_group: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        paddingHorizontal: 12,
    },
    button_primary: {
        ...button,
        backgroundColor: '$emerald',
    },
    bigButton: {
        ...button,
        width: '100%',
        backgroundColor: '$emerald',
    },
    bigButtonTitle: {
        color: '$white',
        fontFamily: '$sourceSansPropFontFamily_semibold',
    },
    disabled: {
        ...button,
        backgroundColor: '#B7B7B7',
    },
    link: { color: '$appTitleColor' },
    link_underline: {
        textDecorationLine: 'underline',
        borderBottomColor: '$appTitleColor',
    },
    btnRounded: {
        marginRight: 6,
        marginVertical: 6,
        borderRadius: 20,
        paddingVertical: 7,
        paddingHorizontal: 20,
        backgroundColor: '#B6BEDA',
    },

    btnTxtRounded: { color: '$white' },
    btnRoundedSelected: {
        backgroundColor: '$appTitleColor',
        borderColor: '$appTitleColor',
    },
});

const transparentButton = {
    marginTop: 12,
    borderRadius: 3,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingVertical: 12,
    height: 42,
};


export const transparentButtonsStyles = EStyleSheet.create({
    button_half: { flex: 0.49 },
    button_normal: {
        ...transparentButton,
        color: '$grayForButton',

    },
    button_transparent: { ...transparentButton },
    button_border: {
        borderWidth: 1,
        borderColor: COLORS.BORDER_GRAY,
    },
    button_danger: {
        ...transparentButton,
        color: 'red',
    },
    button_blue: {
        ...transparentButton,
        color: COLORS.MAIN,
    },
    button_orange: {
        ...transparentButton,
        color: 'orange',
    },
    button__title: {
        // color: '$black',
        fontFamily: '$sourceSansPropFontFamily_semibold',
    },
    buttons_group: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        paddingHorizontal: 12,
    },
    button_primary: {
        ...transparentButton,
        color: '$emerald',
    },
    bigButton: {
        ...transparentButton,
        width: '100%',
        color: '$emerald',
    },
    bigButtonTitle: {
        color: '$black',
        fontFamily: '$sourceSansPropFontFamily_semibold',
    },
    disabled: {
        ...transparentButton,
        color: '#B7B7B7',
    },
});
