import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { syncSpecialitiesFullList } from '../actions/doctors';
import { getIsSpecialitiesFetched, getSpecialitiesFullList } from '../selectors';
import { getSpecialitiesFullListPath, useDoctorsDataExpirationHandler } from './useDoctorsDataExpirationHandler';

export const useSpecialitiesFullList = ({ forceReload } = { forceReload: false }) => {

    const specialities = useSelector(getSpecialitiesFullList);
    const isFetched = useSelector(getIsSpecialitiesFetched);
    const dispatch = useDispatch();

    useEffect(() => {

        if (!isFetched) {

            dispatch(syncSpecialitiesFullList({ forceReload }));

        }

    }, [isFetched]);

    useDoctorsDataExpirationHandler(getSpecialitiesFullListPath(), () => {
        dispatch(syncSpecialitiesFullList({ forceReload: true }));
    });

    return { specialities };

};
