import GoogleMapReact from 'google-map-react';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import actions from '../../actions';
import { Button, Img } from '../../common';
import { getCurrentPosition } from '../../services/device';
import { GOOGLE_API_KEY } from '../../config';
import { propsByScaleType } from './utils';
import { MapMarker } from './MapMarker';
import { mapStyles } from './styles';
import { ColorScale, SwitchMapModal, UserLocationMarkerWeb } from './snippets';

const ZOOM = 11;
const DELTA = Math.exp(Math.log(360) - ZOOM * Math.LN2);

const getAdaptedInitialRegion = initialRegion => (initialRegion?.latitude && initialRegion?.longitude
    ? {
        lat: initialRegion?.latitude,
        lng: initialRegion?.longitude,
    }
    : {});

const mapOptions = {
    fullscreenControl: false,
    zoomControl: false,
    clickableIcons: false,
    // mapTypeControl: true,
    // mapTypeId: 'hybrid',
};

export const Map = React.memo(
    ({
        initialRegion, scaleKey = 'totalPrice', data, allDrugs, drugs, onItem, item, toolTipKey, mapType, zoom: defaultZoom,
    }) => {

        const [zoom, setZoom] = useState(defaultZoom);
        const [isShowingLayersMenu, setIsShowingLayersMenu] = useState(false);
        const [minMaxValues, setMinMaxValues] = useState({});
        const [userLocation, setUserLocation] = useState({});
        // const mapRef = useRef(null);
        const [googleMapRef, setGoogleMapRef] = useState();
        const initialRegionAdapted = getAdaptedInitialRegion(initialRegion);
        const { getColorId, hideScale, toolTipTitleId } = propsByScaleType[scaleKey];

        const defaultCenter = {
            lat: DELTA,
            lng: DELTA / 2,
            ...initialRegionAdapted,
        };
        const [center, setCenter] = useState(defaultCenter);

        const toUserLocation = () => {

            setCenter({
                lat: userLocation?.lat,
                lng: userLocation?.lng,
            });
            (async () => {

                const { coords: { latitude: userLat, longitude: userLng } } = await getCurrentPosition();
                if (userLocation?.lat !== userLat || userLocation?.lng !== userLng) {

                    setUserLocation({
                        lat: userLat,
                        lng: userLng,
                    });
                    setCenter({
                        lat: userLocation?.lat,
                        lng: userLocation?.lng,
                    });

                }

            })();

        };

        const handleMapChange = ({ center: newCenter, zoom: newZoom }) => {

            setZoom(newZoom);
            setTimeout(() => setCenter(newCenter), 0);

        };

        const setMapType = (mapT) => {

            actions.setMapType(mapT);
            if (googleMapRef) {

                googleMapRef.setMapTypeId(mapT === 'standard' ? 'roadmap' : mapT);

            }

        };

        const handleMarkerClick = (marker) => {

            const newPosition = {
                lat: marker.latitude,
                lng: marker.longitude,
            };
            setZoom(17);
            setCenter(newPosition);

        };

        const onPress = (i) => {

            onItem && onItem(i);
            handleMarkerClick(i);

        };

        useEffect(() => {

            if (mapType && mapType !== 'standard' && googleMapRef) {

                googleMapRef.setMapTypeId(mapType);

            }

        }, [googleMapRef]);

        useEffect(() => {

            const drugsLength = drugs?.length ?? 0;
            const values = allDrugs
                ? data.filter(el => el.drugs.length === drugsLength).map(el => +el[scaleKey])
                : data.map(el => +el[scaleKey]); // totalPrice
            const maxValue = Math.max(...values);
            const minValue = Math.min(...values);
            const valueDelta = this.maxValue - this.minValue;
            setMinMaxValues({
                maxValue,
                minValue,
                valueDelta,
            });

        }, [drugs, scaleKey]);

        useEffect(() => {

            (async () => {

                const currentUserLocation = await getCurrentPosition();
                if (!currentUserLocation?.isFailed) {

                    setUserLocation({
                        lat: currentUserLocation?.coords?.latitude,
                        lng: currentUserLocation?.coords?.longitude,
                    });

                }

            })();

        }, []);

        const preparedData = data?.filter?.(({ latitude, longitude }) => !(isNaN(latitude) || isNaN(longitude)))?.map((e) => {

            try {

                const { latitude, longitude } = e;
                if (typeof latitude === 'string' || typeof longitude === 'string') {

                    const lat = parseFloat(latitude);
                    const lon = parseFloat(longitude);
                    return {
                        ...e,
                        latitude: lat,
                        longitude: lon,
                    };

                }
                return e;


            } catch (e) {

                return {
                    ...e,
                    latitude: null,
                    longitude: null,
                };

            }

        })?.filter?.(({ latitude, longitude }) => !!latitude && !!longitude);

        return (
            <div style={{ flex: 1 }}>
                <GoogleMapReact
                    // ref={mapRef}
                    bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
                    yesIWantToUseGoogleMapApiInternals={true}
                    defaultCenter={defaultCenter}
                    options={mapOptions}
                    defaultZoom={ZOOM}
                    zoom={zoom}
                    center={center}
                    onChange={handleMapChange}
                    onGoogleApiLoaded={({ map }) => {

                        setGoogleMapRef(map);

                    }}
                    onClick={() => {

                        onItem && onItem(null);

                    }}>
                    {userLocation?.lat ? (
                        <UserLocationMarkerWeb lat={userLocation?.lat} lng={userLocation?.lng} />
                    ) : null}
                    {minMaxValues?.maxValue || preparedData?.length
                        ? preparedData?.map((e) => {

                            let color = getColorId(e[scaleKey], minMaxValues?.minValue, minMaxValues?.maxValue);
                            if (allDrugs === true && e.drugs.length < drugs.length) {

                                color = -1;

                            }
                            if (minMaxValues?.minValue === undefined || minMaxValues?.maxValue === undefined) {

                                return null;

                            }
                            return (
                                <MapMarker
                                    lat={e?.latitude}
                                    lng={e?.longitude}
                                    // zoom={ZOOM}
                                    key={e.id}
                                    data={{
                                        ...e,
                                        isSelected: item?.id === e?.id,
                                    }}
                                    pressHandler={onPress}
                                    colorId={color}
                                    scaleKey={scaleKey}
                                    unit={propsByScaleType[scaleKey].unit}
                                    nullTitle={Object.R(propsByScaleType[scaleKey].nullTitleId)}
                                    toolTipKey={toolTipKey}
                                    toolTipTitle={toolTipTitleId ? Object.R(toolTipTitleId) : ''}
                                />
                            );

                        })
                        : null}
                </GoogleMapReact>
                {isShowingLayersMenu ? (
                    <SwitchMapModal
                        onChange={setMapType}
                        mapType={mapType}
                        onClose={() => setIsShowingLayersMenu(false)}
                    />
                ) : (
                    <View style={mapStyles.menuGroupButtons}>
                        <Button
                            ns="layers_button"
                            action={() => setIsShowingLayersMenu(true)}
                            styles={mapStyles.menuButton}>
                            <Img.Layers />
                        </Button>
                        <Button action={toUserLocation} styles={mapStyles.menuButton}>
                            <Img.LocationSearching />
                        </Button>
                    </View>
                )}
                {isShowingLayersMenu || hideScale || minMaxValues?.valueDelta < 0.7 ? null : (
                    <ColorScale
                        maxValue={minMaxValues?.maxValue}
                        minValue={minMaxValues?.minValue}
                        {...propsByScaleType[scaleKey]}
                    />
                )}
            </div>
        );

    },
    (prevProps, nextProps) => JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data) &&
        prevProps?.item?.id === nextProps?.item?.id,
);
