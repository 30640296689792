import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    BigButton, Keyboard, Logo, Page, React, Section, TextInput,
} from '../../common';
import { AppHeader, SpouseAddedModal } from '../../combo';
import api from '../../services/api';
import { getRelativesSpouse } from '../../selectors';
import { setFormData } from '../../actions/forms';

export const FamilyJoin = Page.register(
    ({
        spouse,
        isLoading,
        error,
        code = '',
        isValid,
        setState,
        navigation,
        onCodeChange = c => setState({
            code: c,
            isValid: c.length >= 6,
        }),
        submitCode = isValid &&
            (async () => {
                try {
                    setState({
                        error: undefined,
                        isLoading: true,
                    });
                    await api.linkToFamily(code);
                    navigation.goBack();
                } catch (err) {
                    Keyboard.dismiss();
                    setState({
                        error: err,
                        isLoading: false,
                    });
                }
            }),
    }) => {
        const dispatch = useDispatch();
        useEffect(() => {
            if (spouse) {
                Page.showModal(<SpouseAddedModal spouse={spouse} />);
            }
            dispatch(
                setFormData({
                    FamilyJoin: {
                        back: () => {
                            navigation.goBack();
                            return true;
                        },
                    },
                }),
            );
        }, [spouse]);
        return (
            <Page name="family-invite-confirm" isLoading={isLoading} notification={error}>
                <Section>
                    <Logo.Wrapper
                        title="titles.enter_code_relative"
                        subtitle="titles.join_relative"
                        titleStyle={{ paddingBottom: 12 }}>
                        <TextInput.Number onChange={onCodeChange} />
                        <BigButton primary title="button.join_the_family" disabled={!isValid} action={submitCode} />
                    </Logo.Wrapper>
                </Section>
            </Page>
        );
    },
    { spouse: { from: getRelativesSpouse } },
    () => ({ header: () => <AppHeader /> }),
);
