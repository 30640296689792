// noinspection JSUnusedGlobalSymbols

import EStyleSheet from 'react-native-extended-stylesheet';

const avatarSize = 100;

export const account = EStyleSheet.create({
    save: { backgroundColor: '#B6BEDA' },
    cancel: { backgroundColor: '$emerald' },
    button: {
        flex: 1,
        borderRadius: 3,
        alignItems: 'center',
        paddingVertical: 11,
    },
    text: { color: '$white' },
    formHandlersBlock: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    // --details
    container: {
        position: 'relative',
        alignItems: 'center',
    },
    avatar: {
        marginTop: 10,
        width: avatarSize,
        height: avatarSize,
        borderRadius: avatarSize / 2,
        overflow: 'hidden',
    },
    avatarImage: {
        width: avatarSize,
        height: avatarSize,
    },
    rightItem: {
        position: 'absolute',
        top: 0,
        right: 10,
    },
    imageEmpty: { backgroundColor: '$gray' },
    title2: {
        marginTop: 16,
        fontSize: 18,
        fontWeight: '600',
        color: '#555555',
        textAlign: 'center',
    },
    subTitle: {
        marginTop: 8,
        color: '#555555',
    },
});


export const imageViewer = EStyleSheet.create({
    view: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'stretch',
        backgroundColor: 'black',
        zIndex: 100,
    },
    image: {
        flex: 1,
        resizeMode: 'contain',
    },
    deleteButton: {
        position: 'absolute',
        bottom: 20,
        right: 20,
        borderRadius: 30,
        backgroundColor: 'white',
        zIndex: 10010,
    },
    deleteImage: {
        marginHorizontal: 10,
        marginVertical: 10,
        color: 'black',
    },
});

export const accountInfo = EStyleSheet.create({
    epamProgramField: {
        marginTop: 0,
        marginBottom: 8,
        borderColor: '#E0E0E0',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        backgroundColor: '#fff',
        paddingHorizontal: 16,
        paddingVertical: 20,
        flexWrap: 'nowrap',
    },
    userInfoField: { paddingHorizontal: 12 },
    loadingContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    loadingIndicator: { paddingVertical: 8 },
    loadingTitle: {
        textAlign: 'center',
        paddingHorizontal: 12,
    },
    pendingConfirmBadge: {
        position: 'relative',
        marginLeft: 4,
    },
});

export const myCard = EStyleSheet.create({
    listHeading: {
        marginTop: 20,
        paddingLeft: 8,
        paddingBottom: 12,
        fontSize: 12,
        fontWeight: '600',
        color: '#787878',
    },
    container: {
        marginTop: 20,
        paddingLeft: 12,
        paddingRight: 13,
    },
    emptyListText: {
        paddingLeft: 20,
        paddingRight: 10,
        color: '$lightGray',
        lineHeight: 20,
    },
    listItem: {
        position: 'relative',
        width: 117,
        marginRight: 4,
        alignItems: 'center',
        paddingTop: 12,
        paddingBottom: 16,
        paddingHorizontal: 13,
        borderWidth: 1,
        borderColor: '#E9E9E9',
        backgroundColor: '$white',
    },
    titleContainer: { marginTop: 12 },
    listItemTitle: {
        textAlign: 'center',
        fontFamily: '$secondaryTextFontFamily',
        fontSize: 12,
        color: '#787878',
    },
    indicator: {
        position: 'absolute',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        top: -4,
        left: -10,
    },
});

export const home = EStyleSheet.create({
    navigationItem: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '$white',
        borderWidth: 1,
        borderColor: '#E9E9E9',
        borderRadius: 3,
        paddingTop: 18,
    },
    navigationContainer: {
        paddingTop: 17,
        paddingBottom: 7,
        alignItems: 'center',
        marginTop: 0,
        marginRight: 0,
        marginLeft: 0,
    },
    image: {
        width: 55,
        height: 56,
        // marginBottom: 25,
    },
    popularButonText: {
        fontSize: 14,
        color: '$white',
    },
    popularHeading: {
        color: '#555555',
        fontSize: 12,
        paddingBottom: 10,
        fontWeight: '600',
    },
    popularButton: {
        marginTop: 8,
        marginRight: 10,
        borderRadius: 20,
        paddingVertical: 7,
        paddingHorizontal: 20,
        backgroundColor: '#B6BEDA',
    },
});

export const chooseProgram = EStyleSheet.create({
    chooseProgramPanel: {
        width: '48%',
        minHeight: 120,
    },
    chooseProgramPanelActive: {
        borderColor: '#18B797',
        borderLeftColor: '#18B797',
        borderRightColor: '#18B797',
        borderTopColor: '#18B797',
        borderBottomColor: '#18B797',
    },
    chooseProgramPanelLeft: { marginLeft: 8 },
    image: { marginBottom: 12 },
    epam: {
        width: 74,
        height: 28,
        resizeMode: 'stretch',
    },
    bns: {
        width: 92,
        height: 28,
        resizeMode: 'stretch',
    },
});

export const notificationSettings = EStyleSheet.create({
    listItem: {
        paddingVertical: 14,
        borderBottomWidth: 1,
        borderColor: '$ultraLightGray',
    },
    checkbox: {
        width: 16,
        height: 16,
        borderWidth: 1,
        borderRadius: 2,
        borderColor: '#999999',
        marginLeft: 16,
        marginRight: 12,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    checkboxSelected: {
        borderColor: '#5C85DD',
        backgroundColor: '#5C85DD',
    },
    checkboxDisabled: { borderColor: '#D6D6D6' },
    checkboxSelectedDisabled: {
        borderColor: '#D6D6D6',
        backgroundColor: '#D6D6D6',
    },
    text: {
        color: '$darkGrayTextColor',
        fontSize: 16,
        lineHeight: 20,
    },
    selectionText: {
        color: '$darkGrayTextColor',
        fontSize: 16,
        lineHeight: 20,
    },
    textDisabled: { color: '#B6B6B6' },
    confirmationButtons: {
        borderTopWidth: 1,
        borderColor: '#E0E0E0',
        paddingBottom: 12,
        backgroundColor: 'white',
    },
});

export const accessCheckStyles = EStyleSheet.create({
    deviceIdButton: {
        fontSize: 18,
        marginTop: 25,
        borderWidth: 1,
        borderRadius: 4,
        borderColor: '#DDDDDD',
        padding: 12,
    },
    loadingView: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    emailText: {
        fontSize: 18,
        marginTop: 36,
        borderWidth: 1,
        borderRadius: 4,
        backgroundColor: 'white',
        borderColor: '#DDDDDD',
        padding: 12,
        width: '100%',
    },
    noConfirmWaysBlock: {
        width: '100%',
        alignItems: 'center',
    },
    noConfirmWaysBlockTitle: {
        color: '#BBBBBB',
        textAlign: 'center',
    },
    noConfirmWaysBlockBigButton: { marginTop: 36 },
    link: { marginTop: 16 },
    confirmByEmailBlock: {
        width: '100%',
        alignItems: 'center',
    },
    confirmByEmailBlockTitle: {
        color: '#BBBBBB',
        textAlign: 'center',
    },
    confirmByEmailBlockHint: {
        color: '#BBBBBB',
        textAlign: 'center',
        marginTop: 24,
    },
    confirmByEmailBlockSendConfirmation: { marginTop: 12 },
    promptLogOutTitle: {
        marginBottom: 12,
        fontSize: 18,
    },
    promptLogOutSubtitle: {
        alignSelf: 'center',
        textAlign: 'center',
        marginBottom: 10,
    },
    title: {
        marginTop: 24,
        fontSize: 20,
    },
    wrapper: {
        marginTop: 16,
        padding: 8,
        alignItems: 'center',
    },

});
