import { useSelector } from 'react-redux';
import {
    List, Page, React, SearchBar, Section,
} from '../../common';
import { servicesFilterByKeywordFn } from '../../selectors/utils';
import { selectServicesForClinic } from '../../selectors';
import { ProgramSelector } from '../../combo';
import { ServicesListItem } from './snippets';

const renderItem = ({ item }) => <ServicesListItem data={item}/>;

const filteredServices = (services, search) => (search.length >= 3 ? services.filter(servicesFilterByKeywordFn(search)) : services);

const useController = (clinicId) => {
    const [search, setSearch] = React.useState('');
    const services = useSelector(state => selectServicesForClinic(state, clinicId));
    return [filteredServices(services, search), setSearch];
};

/**
 * MedCenter Services list page.
 */
export const MedCenterServices = Page.register(({
    mdInfo: {
        id: clinicId,
        name,
    },
}) => {
    const [services, onSearch] = useController(clinicId);
    return (
        <Page noscroll name="MedCenterServices">
            <ProgramSelector withInfo/>
            <SearchBar initialValue="" onSearch={onSearch} placeholder="titles.search_services"/>
            <Section flex title={`${Object.R('screenTitles.MedCenterServices')} ${name}:`}>
                <List data={services} renderItem={renderItem}/>
            </Section>
        </Page>
    );
});
