import { formatTime, formatTimeslots } from '../utils';
import { getEnums } from '../i18n';
import tracking from '../services/tracking';
import {
    Component, React, Text, View,
} from './react';
import { Button } from './Button';
import { TabSelector } from './Selector';
import { timePicker as styles } from './style';

// needs to be refactored. Duplicated code, e.t.c
export class TimePicker extends Component {
    state = { filterIndex: -1 };

    getItems(timeslots, date, timezone) {
        const {
            onChoose,
            timetableId = '',
        } = this.props;
        const { filterIndex } = this.state;
        const timeslotForToday = formatTimeslots(timeslots, timezone)[date];
        const timeSlotsCurrent = filterIndex < 0 ? [].concat(...timeslotForToday) : timeslotForToday[filterIndex];

        // const emptyTimeslotsCount = timeSlotsCurrent.length <= 5 ? 5 - timeSlotsCurrent.length : 5 - (timeSlotsCurrent.length % 5 === 0 ? 5 : timeSlotsCurrent.length % 5);
        // for (let i = 0; i < 3; i++) {
        //     timeSlotsCurrent.push({ startDate: false, id: false });
        // }

        return timeSlotsCurrent.map(({
            startDate,
            id,
        }) => {
            // noinspection JSUnusedAssignment
            let itemStyles = {};
            let time;
            let action = null;
            if (startDate && id) {
                time = formatTime(startDate, timezone);
                itemStyles = timetableId === id
                    ? {
                        item: [styles.timeItem, styles.selected],
                        text: [styles.timeTitle, styles.activeTitle],
                    }
                    : {
                        item: styles.timeItem,
                        text: styles.timeTitle,
                    };
                action = () => {
                    onChoose(id, time);
                };
            } else {
                itemStyles = {
                    item: [styles.timeItem, styles.empty],
                    text: {},
                };
            }
            return (
                <Button key={id} styles={itemStyles.item} action={action} trackingAlias="timepick">
                    <Text style={itemStyles.text}>{time}</Text>
                </Button>
            );
        });
    }

    render() {
        const {
            timeslots = [],
            date,
            timezone,
        } = this.props;
        const { filterIndex } = this.state;
        if (timeslots.length > 0) {
            const slots = formatTimeslots(timeslots, timezone);
            const timeslotForToday = slots[date];
            if (timeslotForToday) {
                getEnums('timeSortItems')
                    .forEach((item) => {
                        const count = item.filterIndex >= 0 ? timeslotForToday[item.filterIndex].length : timeslotForToday.reduce((sum, cur) => sum + cur.length, 0);
                        Object.assign(item, {
                            count,
                            disabled: count === 0,
                        });
                    });
            }
        }
        return (
            <View style={styles.container}>
                <View accessibilityLabel="time-picker" style={styles.innerContainer}>
                    <TabSelector
                        items={getEnums('timeSortItems')}
                        onItem={(e) => {
                            if (e.count > 0) {
                                this.setState({ filterIndex: e.filterIndex });
                            }
                            tracking.logEvent(`ai_datepick_${e.id}`);
                        }}
                        selected={getEnums('timeSortItems')[Number(filterIndex) + 1].id}
                    />
                    <View style={styles.times}>
                        {timeslots.length > 0 && this.getItems(timeslots, date, timezone)}
                    </View>
                </View>
            </View>
        );
    }
}
