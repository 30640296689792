import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDoctorsOnlyWithPartnershipAndSums } from '../selectors';
import actions from '../actions';
import { getDoctorsDescriptions } from '../selectors/services';
import firebase from '../services/firebase';

export const useDoctorDescriptions = (doctorId, doctorInfo) => {
    const doctorsDescriptions = useSelector(getDoctorsDescriptions);
    const doctorData = useSelector(getDoctorsOnlyWithPartnershipAndSums);
    const [list, setList] = useState([]);
    const [fbDoctor, setFbDoctor] = useState();
    const alreadyFetchFromFB = useRef(false);

    const getAssignments = doctor => doctor?.worksAt?.map(({ assignmentId }) => assignmentId);

    useEffect(() => {
        let doctor = doctorInfo ?? doctorData?.find?.(({ _id }) => _id === doctorId);
        if (!doctor) {
            if (fbDoctor) {
                doctor = fbDoctor;
            } else {
                if (alreadyFetchFromFB.current) {
                    return;
                }
                alreadyFetchFromFB.current = true;
                firebase
                    .getById('physicians', doctorId)
                    .then((res) => {
                        setFbDoctor(res);
                    })
                    .catch(() => {});

                return;
            }
        }
        const assignment = getAssignments(doctor);
        actions.fetchDoctorDescriptionsByAssignments(assignment, doctorId);
    }, [doctorId, doctorData, doctorInfo, fbDoctor]);

    useEffect(() => {
        setList(doctorsDescriptions[doctorId]);
    }, [doctorsDescriptions, doctorId]);

    return list;
};
