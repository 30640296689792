
import { Dimensions, Platform } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import { mh, ph, pv } from './atomic';
import { COLORS } from './colors';

export const circleStyle = size => ({
    width: size,
    height: size,
    borderRadius: size / 2,
});

const { height } = Dimensions.get('window');
const rootElt = Platform.OS === 'web' && window && (window.document.getElementById('root') || window.document.body);
const sideElt =
    Platform.OS === 'web' && window && (window.document.getElementById('left-side') || window.document.body);

export const getWindowWidth = () => rootElt.offsetWidth;
export const getWindowHeight = () => rootElt.offsetHeight;
export const getLeftWindowWidth = () => sideElt.offsetWidth;
export const getLeftWindowHeight = () => sideElt.offsetHeight;
export const getMarginHorizontal = () => {

    return Platform.OS === 'web' ? (getLeftWindowWidth() - getWindowWidth()) / 2 : undefined;

};

export const getMarginHorizontalObject = () => {

    const res = Math.abs(getLeftWindowWidth() - (getWindowWidth() - 24)) / 2;
    return Platform.OS === 'web'
        ? {
            marginLeft: res,
            marginRight: res,
        }
        : {};

};
export const getBottom = () => (Platform.OS === 'web' ? Math.abs(getLeftWindowHeight() - getWindowHeight()) : undefined);
export const getBottomObject = (offset = 0) => (Platform.OS === 'web'
    ? { bottom: Math.abs(Math.round((getLeftWindowHeight() - getWindowHeight() - 24) / 2)) + offset }
    : { marginBottom: 16 });
// export const getBottom = () => (Platform.OS === 'web' && window.isDesktop ? (getLeftWindowHeight() - getWindowHeight() - 40) : undefined);

export const icons = EStyleSheet.create({
    iconWrapper: {
        width: 25,
        height: 25,
        borderRadius: 100,
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: '#666666',
        borderWidth: 1,
        backgroundColor: '$white',
    },
    iconWrapperRelative: {
        width: 45,
        height: 45,
        borderRadius: 100,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#18B797',
        alignSelf: 'flex-end',
    },
    iconDelete: {
        backgroundColor: '$emerald',
        borderColor: '$emerald',
    },
    deletePhotoIcon: {
        backgroundColor: 'white',
        width: 45,
        height: 45,
        borderRadius: 100,
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconSearchBar: {
        width: 25,
        ...mh,
    },
});

export const topNoteStyle = EStyleSheet.create({
    container: {
        ...pv,
        paddingHorizontal: 20,
        borderBottomWidth: 1,
        flexDirection: 'row',
    },
    notificationText: {
        fontSize: 14,
        lineHeight: 18,
    },
    error: {
        backgroundColor: '$lightPink',
        borderBottomColor: '$pink',
        borderTopColor: '$pink',
    },
    warn: {
        backgroundColor: '$yellowLight',
        borderColor: '$yellow',
    },
    info: {
        backgroundColor: '$blueGrayLighten',
        borderBottomColor: '$blueGrayLight',
        borderTopColor: '$blueGrayLight',
    },
    success: {
        backgroundColor: '$emerald25',
        borderBottomColor: '$emerald20',
        borderTopColor: '$emerald',
        color: '$emerald',
    },
    infoText: { color: '$blueGray' },
    successText: { color: '$emerald80' },
    warnText: { color: '$brown' },
    icon: { marginRight: 16 },
});
export const pageNoteStyle = EStyleSheet.create({
    text: {
        ...ph,
        fontSize: 14,
        color: '#B29F9F',
    },
    error: {
        backgroundColor: '$lightPink',
        borderTopWidth: 1,
        borderTopColor: '$pink',
    },
    warn: {
        backgroundColor: '$yellowLight',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderColor: '$yellow',
    },
});

export const detailedInfoColumns = EStyleSheet.create({
    detailedInfo: {
        backgroundColor: '$white',
        borderColor: '#e0e0e0',
        borderTopWidth: 1,
        // borderBottomWidth: 1,
        flexDirection: 'row',
        // flex: 1,
    },
    detailedInfoItem: {
        paddingVertical: 17,
        flex: 0.5,
        justifyContent: 'flex-start',
        paddingLeft: 28,
        alignItems: 'center',
    },
    detailedInfoItemFirst: {
        borderRightWidth: 1,
        borderColor: '#e0e0e0',
    },
    iconMarginR: {
        marginRight: 5,
        alignItems: 'center',
    },
    detailedInfoItemTitle: {
        fontSize: 10,
        fontWeight: '600',
        color: '#BCBCBC',
    },
});

export const timePicker = EStyleSheet.create({
    container: { flex: 1 },
    innerContainer: {
        paddingBottom: 16,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderColor: '#E9E9E9',
        backgroundColor: '#ffffff',
    },
    filters: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    times: {
        marginTop: 15,
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        paddingHorizontal: 9,
    },
    emptyTimeListText: {
        fontSize: 12,
        fontFamily: '$secondaryTextFontFamily',
        fontWeight: '600',
        color: '$lightGray',
    },
    filterName: {
        fontFamily: '$secondaryTextFontFamily',
        fontWeight: '600',
        color: '#555555',
        fontSize: 16,
    },
    title: {
        paddingBottom: 16,
        paddingLeft: 20,
        fontSize: 12,
        fontFamily: '$secondaryTextFontFamily',
        fontWeight: '600',
        color: '#555555',
    },
    timeItem: {
        width: 61,
        height: 32,
        margin: 4,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#EEF0F6',
        borderRadius: 3,
    },
    empty: { backgroundColor: '#5C85DD' },
    selected: { backgroundColor: '#5C85DD' },
    activeTitle: { color: '#ffffff' },
    timeTitle: {
        color: '#555555',
        textAlign: 'center',
    },
});

export const infoList = EStyleSheet.create({
    container: { marginTop: 20 },
    listHeaderText: {
        paddingBottom: 20,
        paddingTop: 8,
        paddingLeft: 8,
        fontSize: 12,
        fontWeight: '600',
        color: '#6E6E6E',
    },
    listFooterText: {
        marginTop: 16,
        lineHeight: 18,
        color: '#6D6D6D',
    },
    listItem: {
        flexDirection: 'row',
        flex: 1,
        borderBottomColor: '#E9E9E9',
        borderBottomWidth: 1,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 12,
    },
    first: {
        borderTopWidth: 1,
        borderTopColor: '#E9E9E9',
    },
    title: {
        flex: 0.38,
        marginLeft: 4,
        color: '#BBBBBB',
        fontSize: 16,
        fontFamily: '$sourceSansPropFontFamily',
    },
    rightTitleBlock: {
        flex: 0.7,
        paddingLeft: 10,
    },
    rightTitle: { color: '#6D6D6D' },
    phone: {
        color: '#6A90E0',
        marginTop: 12,
    },
    firstPhone: { color: '#6A90E0' },
    pb: { paddingBottom: 12 },
});

export const image = EStyleSheet.create({
    stubImage: {
        marginTop: '30%',
        width: 240,
        height: 140,
    },
    logo: {
        alignSelf: 'center',
        width: 100,
        height: 115,
    },
    indicator: {
        width: 6,
        height: 6,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 12,
        margin: 8,
    },
    indicator_yes: {
        backgroundColor: '#39C2D7',
        borderRadius: 12,
    },
});

const modalContainer = {
    backgroundColor: '$white',
    width: getWindowWidth() - 24 || '$deviceWidth-32',
    borderRadius: 3,
    margin: 12,
    overflow: 'hidden',
};

export const webModalContainer =
    Platform.OS === 'web'
        ? {
            width: 'calc(100vw - 24px)',
            left: undefined,
            right: undefined,
        }
        : {};

export const modal = EStyleSheet.create({
    overlay: {
        flexDirection: 'column',
        alignItems: Platform.OS === 'web' ? undefined : 'center',
        backgroundColor: '$modalOverlayBg',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    overlay_transparent: { backgroundColor: 'transparent' },
    stub: {
        width: '$deviceWidth',
        flexGrow: 1,
    },
    container: {
        ...modalContainer,
        paddingVertical: 20,
        paddingHorizontal: 12,
        marginBottom: 25,
    },
    container_no_padding: { ...modalContainer },
    container_for_filter: {
        position: 'absolute',
        maxHeight: height - 50,
        left: 8,
        right: 8,
        bottom: 8,
        borderRadius: 3,
        paddingVertical: 0,
        paddingHorizontal: 0,
        overflow: 'hidden',
        backgroundColor: '#F9F9F9',
        ...webModalContainer,
    },
    citySelectorContainer: {
        position: 'absolute',
        maxHeight: (Platform.OS === 'web' ? getWindowHeight() : height) - 50,
        top: Platform.OS === 'ios' ? 30 : 10,
        left: 8,
        right: 8,
        flex: 1,
        height: '100%',
        borderRadius: 3,
        paddingVertical: 0,
        paddingHorizontal: 0,
        overflow: 'hidden',
        backgroundColor: '#F9F9F9',
        ...webModalContainer,
    },
    citySelectorList: {
        height: (Platform.OS === 'web' ? getWindowHeight() : height) - 245,
        marginTop: 10,
    },
    itemList: {
        ...modalContainer,
        paddingTop: 20,
        paddingBottom: 0,
    },
    title: {
        textAlign: 'center',
        fontFamily: '$sourceSansPropFontFamily_semibold',
        marginBottom: 12,
        fontSize: 18,
        color: '$titleGray',
    },
    subtitle: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 15,
        paddingHorizontal: 14,
        color: '$headerScreenTitleColor',
        lineHeight: 15,
    },
    textInfo: {
        paddingHorizontal: 14,
        lineHeight: 16,
        paddingBottom: 15,
    },
    modalBtnTxt: {
        color: '#555555',
        fontSize: 16,
        lineHeight: 20,
    },
    modalButton: {
        paddingVertical: 14,
        paddingLeft: 20,
        borderTopWidth: 1,
        borderColor: '#E9E9E9',
    },
    selectModalTitle: {
        fontFamily: '$sourceSansPropFontFamily',
        color: '#555555',
        fontSize: 16,
    },
    button: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: 20,
    },
});

export const navigationList = EStyleSheet.create({
    navigationItem: {
        borderBottomColor: '#E9E9E9',
        backgroundColor: '$white',
    },
    rightIcon: { marginRight: 15 },
    navigationItemTitle: {
        fontSize: 18,
        color: '#606060',
    },
    container: {
        borderBottomWidth: 1,
        borderBottomColor: '#E0E0E0',
        paddingTop: 12,
        paddingBottom: 12,
    },
});

export const popupMessage = EStyleSheet.create({
    container: {
        position: 'absolute',
        left: 8,
        right: 8,
        bottom: 70,
        borderRadius: 3,
        backgroundColor: '$appHeaderBgColor',
        paddingVertical: 15,
        paddingHorizontal: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        ...webModalContainer,
    },
    title: {
        color: '$white',
        fontFamily: '$sourceSansPropFontFamily',
        fontSize: 16,
        flex: 1,
        marginRight: 4,
    },
    textInfo: {
        marginTop: 15,
        color: '$lightGray',
        lineHeight: 22,
    },
});

export const prompt = EStyleSheet.create({
    text: {
        fontSize: 16,
        color: '#555555',
    },
    modalHandlers: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 12,
        paddingTop: 20,
    },
    submit: { backgroundColor: '#B6BEDA' },
    cancel: { backgroundColor: '$emerald' },
    modalBtn: {
        flex: 0.47,
        borderRadius: 3,
        alignItems: 'center',
        paddingVertical: 11,
    },
    modalBtnTxt: {
        color: '$white',
        fontSize: 16,
        lineHeight: 20,
    },
});

export const button = EStyleSheet.create({
    button: {
        marginBottom: 10,
        borderRadius: 3,
        paddingTop: 12,
        paddingBottom: 10,
        alignItems: 'center',
        flex: 1,
        backgroundColor: '$grayForButton',
    },
    button_half: { flex: 0.49 },
    button_danger: { backgroundColor: '$red' },
    button__title: { color: '$white' },
    bigButtonContainer: { width: '$deviceWidth - 24' },
});

export const container = EStyleSheet.create({
    container: {
        paddingLeft: 13,
        paddingRight: 12,
        paddingTop: 12,
    },
    container_small: {
        paddingLeft: 20,
        paddingRight: 19,
    },
    container_forFlatList: { flex: 1 },
    container_mb_12: { marginBottom: 12 },
});

// noinspection JSUnusedGlobalSymbols
export const headLine = EStyleSheet.create({
    headLine: {
        color: '$headerScreenTitleColor',
        fontFamily: '$sourceSansPropFontFamily_bold',
        fontWeight: 'bold',
        fontSize: 16,
        marginBottom: 12,
    },
    headLine_wightNormal: {
        fontFamily: '$sourceSansPropFontFamily',
        fontWeight: 'normal',
    },
    headLine_smaller: {
        fontFamily: '$sourceSansPropFontFamily',
        fontSize: 14,
        fontWeight: 'normal',
    },
    headLine_subtitle: {
        fontFamily: '$sourceSansPropFontFamily',
        fontSize: 12,
        fontWeight: 'normal',
        marginBottom: 15,
    },
    headLine_alignLeft: { textAlign: 'left' },
    headLine_alignCenter: { textAlign: 'center' },
    headLine_marginReset: { marginBottom: 0 },
});

export const selector = EStyleSheet.create({
    // radio
    container: {
        height: '$deviceHeight',
        paddingTop: 8,
        backgroundColor: '$lightGrayBg',
        paddingBottom: 20,
    },
    listWrapper: {
        marginTop: 0,
        borderTopWidth: 1,
        borderColor: '$ultraLightGray',
    },
    listItemWrapper: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        paddingVertical: 14,
        paddingHorizontal: 12,
        // paddingRight: 25,
        borderBottomWidth: 1,
        borderColor: '$ultraLightGray',
        backgroundColor: '$lightGrayBg',
    },
    listItemDisabled: { color: COLORS.DARKER_GRAY },
    radioWrapper: {
        position: 'relative',
        width: 16,
        height: 16,
        borderWidth: 1,
        borderRadius: 20,
        borderColor: '$grayTextColor',
        marginRight: 12,
    },
    radioWrapperActive: { borderColor: '$appHeaderBgColor' },
    circleActive: {
        flex: 1,
        margin: 3,
        backgroundColor: '$appHeaderBgColor',
        borderRadius: 100,
    },
    listItemText: {
        color: '$headerScreenTitleColor',
        fontSize: 16,
    },
    listItemTextSmall: {
        color: '$headerScreenTitleColor',
        fontSize: 12,
        paddingTop: 10,
    },
    // normal
    item: {
        paddingVertical: 14,
        borderBottomWidth: 1,
        borderColor: '$ultraLightGray',
    },
    itemTxt: {
        color: '$darkGrayTextColor',
        fontSize: 16,
        lineHeight: 20,
    },
    itemSelectedTxt: {
        color: '$lightBlue',
        fontSize: 16,
        lineHeight: 20,
    },
    itemMultiselectSelectionTxt: {
        color: '#5C85DD',
        fontSize: 16,
        lineHeight: 20,
    },
    itemMultilineTxt: {
        flex: 1,
        flexWrap: 'wrap',
    },
});

export const textWithAuth = EStyleSheet.create({
    emptyListText: {
        paddingLeft: 10,
        paddingRight: 10,
        color: '$lightGray',
        lineHeight: 16,
    },
});

export const map = EStyleSheet.create({
    callout: {
        flex: 1,
        width: '$deviceWidth * 0.8',
    },
    markerUnselected: {
        width: 12,
        height: 12,
        borderRadius: 6,
        backgroundColor: 'white',
        borderWidth: 4,
        borderColor: 'red',
    },
});
export const datePickerCustom = EStyleSheet.create({
    mainContainer: {
        backgroundColor: 'white',
        flex: 1,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderColor: '#E9E9E9',
    },
    header: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingTop: 20,
        paddingBottom: 10,
        paddingHorizontal: 23,
    },
    headerTitle: {
        color: '#555555',
        fontFamily: '$sourceSansPropFontFamily_semibold',
        fontSize: 16,
    },
    tapBar: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        borderTopWidth: 1,
        borderColor: '#E9E9E9',
        marginTop: 8,
    },
    day: {
        flex: 1,
        maxWidth: 42,
        height: 36,
        backgroundColor: '#EEF0F6',
        justifyContent: 'center',
        borderRadius: 3,
        alignItems: 'center',
        margin: 4,
    },
    selected: { backgroundColor: '#5C85DD' },
    textSize: { fontSize: 14 },
    selectedText: { color: '$white' },
    selectedArea: { backgroundColor: '#e9eef9' },
    disabled: { backgroundColor: '#fff' },
    disabledText: { color: '#9C9C9C' },
    todayText: { color: '#5C85DD' },
    notAvailable: { backgroundColor: '#ffffff' },
    text: {
        textAlign: 'center',
        color: '$darkGrayTextColor',
    },
    textColorNextMonth: { color: '$grayTextColor' },
    daysHeaderText: {
        color: '#555555',
        fontFamily: '$sourceSansPropFontFamily_semibold',
        fontSize: 12,
    },
    activeText: { color: '#ffffff' },
    notActiveText: { color: '#999999' },
    weekendText: { color: '#E17575' },
    arrow: { color: '#666' },
});

export const checkBox = EStyleSheet.create({
    iconWrapper: {
        width: 16,
        height: 16,
        borderWidth: 1,
        borderRadius: 2,
        borderColor: '#999999',
        marginRight: 12,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconWrapperActive: { borderColor: '#5C85DD' },
    textWrapper: {
        flex: 1,
        flexDirection: 'row-reverse',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    text: { flex: 1 },
    container: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        flex: 1,
    },
});

export const yesNo = {
    btnsWrapper: {
        marginHorizontal: 20,
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    button: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff',
        borderColor: '#E9E9E9',
        paddingVertical: 12,
    },
    buttonTxt: {
        fontSize: 19,
        lineHeight: 23,
        color: '#555555',
    },
    buttonActive: {
        backgroundColor: '#5C85DD',
        borderColor: '#537BCE',
    },
    buttonActiveTxt: { color: '#fff' },
    buttonLt: {
        borderLeftWidth: 1,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderTopLeftRadius: 3,
        borderBottomLeftRadius: 3,
    },
    buttonRt: {
        borderRightWidth: 1,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderTopRightRadius: 3,
        borderBottomRightRadius: 3,
    },
};

export const appHeaderBgColor = EStyleSheet.value('$appNavBarColor');

export const loadingView = {
    loadingContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    loadingIndicator: { paddingVertical: 8 },
    loadingTitle: {
        textAlign: 'center',
        paddingHorizontal: 12,
    },
};

export const tabSwitch = {
    wrapper: {
        marginHorizontal: 12,
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    button: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff',
        borderColor: '#E9E9E9',
        paddingHorizontal: 4,
        paddingVertical: 12,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderRightWidth: 1,
        borderLeftWidth: 0,
    },
    buttonText: {
        fontSize: 14,
        color: '#555555',
    },
    buttonActive: {
        backgroundColor: '#5C85DD',
        borderColor: '#537BCE',
    },
    buttonActiveText: { color: '#fff' },
    buttonLeft: {
        borderRightWidth: 1,
        borderLeftWidth: 1,
        borderTopLeftRadius: 3,
        borderBottomLeftRadius: 3,
    },
    buttonRight: {
        borderRightWidth: 1,
        borderTopRightRadius: 3,
        borderBottomRightRadius: 3,
    },
};
