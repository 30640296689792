import React, { memo } from 'react';
import { Component, Text, View } from './react';
import { Button } from './Button';
import { TabSelector } from './Selector';
import { timePicker as styles } from './style';
import { CONVERT_TIMEZONE } from '../config';
import {
    CURRENT_SERVER_TIMEZONE_OFFSET, fmtTime, fmtTimeUtc, fmtTimeTz, getMexicoCityOffset,
} from '../utils/dates';
import { getEnums } from '../i18n';
import tracking from '../services/tracking';
import { memoizedFormatTimeslots } from '../selectors';

// needs to be refactored. Duplicated code, e.t.c
export class TimePickerClass extends Component {

    state = { filterIndex: -1 };

    getItems(timeslots, date) {

        const { onChoose, timetableId = '' } = this.props;
        const { filterIndex } = this.state;
        const timeslotForToday = memoizedFormatTimeslots(timeslots)[date];
        const timeSlotsCurrent = filterIndex < 0 ? [].concat(...timeslotForToday) : timeslotForToday[filterIndex];

        // const emptyTimeslotsCount = timeSlotsCurrent.length <= 5 ? 5 - timeSlotsCurrent.length : 5 - (timeSlotsCurrent.length % 5 === 0 ? 5 : timeSlotsCurrent.length % 5);
        // for (let i = 0; i < 3; i++) {
        //     timeSlotsCurrent.push({ startDate: false, id: false });
        // }

        const offset = getMexicoCityOffset(new Date()) + 1;

        return timeSlotsCurrent.map(({ startDate, id, adjustedStartDate }) => {

            // noinspection JSUnusedAssignment
            let itemStyles = {};
            let time;
            let action = null;
            if (startDate && id) {

                time = CONVERT_TIMEZONE ? fmtTimeTz(adjustedStartDate, offset ) : fmtTimeTz(adjustedStartDate, -CURRENT_SERVER_TIMEZONE_OFFSET);
                itemStyles =
                    timetableId === id
                        ? {
                            item: [styles.timeItem, styles.selected],
                            text: [styles.timeTitle, styles.activeTitle],
                        }
                        : {
                            item: styles.timeItem,
                            text: styles.timeTitle,
                        };
                action = () => {

                    onChoose(id, time);

                };

            } else {

                itemStyles = {
                    item: [styles.timeItem, styles.empty],
                    text: {},
                };

            }
            return (
                <Button key={id} styles={itemStyles.item} action={action} trackingAlias="timepick">
                    <Text style={itemStyles.text}>{time}</Text>
                </Button>
            );

        });

    }

    render() {

        const { timeslots = [], date } = this.props;
        const { filterIndex } = this.state;
        if (timeslots.length > 0) {

            const slots = memoizedFormatTimeslots(timeslots);
            const timeslotForToday = slots[date];
            if (timeslotForToday) {

                getEnums('timeSortItems').forEach((item) => {

                    const count =
                        item.filterIndex >= 0
                            ? timeslotForToday[item.filterIndex].length
                            : timeslotForToday.reduce((sum, cur) => sum + cur.length, 0);
                    Object.assign(item, {
                        count,
                        disabled: count === 0,
                    });

                });

            }

        }
        return (
            <View style={styles.container}>
                <View accessibilityLabel="time-picker" style={styles.innerContainer}>
                    <TabSelector
                        items={getEnums('timeSortItems')}
                        onItem={(e) => {

                            if (e.count > 0) {

                                this.setState({ filterIndex: e.filterIndex });

                            }
                            tracking.logEvent(`ai_datepick_${e.id}`);

                        }}
                        selected={getEnums('timeSortItems')[Number(filterIndex) + 1].id}
                    />
                    <View style={styles.times}>{timeslots.length > 0 && this.getItems(timeslots, date)}</View>
                </View>
            </View>
        );

    }

}

export const TimePicker = memo(props => <TimePickerClass {...props} />);
