import { USER_PHONE_BY_PROFILE, firebaseAnonymousToken } from './const.stub';
import { firebaseData } from './data/firebase.dump';

let firebaseToken = null;
let firebaseAuthCallback = () => 1;
let providerData = [];

const setFirebaseToken = (token = null) => {
    firebaseToken = token;
    providerData = !token ? [] : [{
        displayName: null,
        email: null,
        phoneNumber: token,
        photoURL: null,
        providerId: 'phone',
        uid: firebaseToken,
    }];
};

const authFn = () => ({
    get currentUser() {
        return {
            uid: firebaseToken || firebaseAnonymousToken,
            getIdToken: () => Promise.resolve(firebaseToken),
            providerData,
        };
    },
    signInWithPhoneNumber(phone, recapcher) {
        auth.PhoneAuthProvider.credential = (verificationId, code) => {
            [firebaseToken] = (Object.entries(USER_PHONE_BY_PROFILE)
                .find(([, v]) => {
                    const parts = v.split(':');
                    return `${parts[0]}${parts[1]}`.replace(/\D+/g, '') === phone.replace(/\D+/g, '') && parts[2] === code;
                }) || [null]);
            setFirebaseToken(firebaseToken);
            return firebaseToken;
        };
        return recapcher({
            confirm(code) {
                [firebaseToken] = (Object.entries(USER_PHONE_BY_PROFILE)
                    .find(([, v]) => {
                        const parts = v.split(':');
                        return `${parts[0]}${parts[1]}`.replace(/\D+/g, '') === phone.replace(/\D+/g, '') && parts[2] === code;
                    }) || [null]);
                setFirebaseToken(firebaseToken);
            },
        });
    },
    async signInWithCustomToken(token){
        [firebaseToken] = (Object.entries(USER_PHONE_BY_PROFILE)
                .find(([, v]) => {
                    const parts = v.split(':');
                    return `${parts[0]}${parts[1]}`.replace(/\D+/g, '') === token.replace(/\D+/g, '');
                }) || [null]);
        setFirebaseToken(firebaseToken);
        firebaseAuthCallback();
    },
    signInWithCredential(credential) {
        if (!credential) {
            throw ({ code: 'auth/invalid-verification-code', message: 'The SMS verification code used to create the phone auth credential is invalid.' });
        }
        setFirebaseToken(firebaseToken);
        firebaseAuthCallback();
    },
    signInAnonymously() {
        setFirebaseToken();
        return Promise.resolve(firebaseAuthCallback());
    },
    onAuthStateChanged(cb) {
        firebaseAuthCallback = () => cb({ providerData });
        firebaseAuthCallback();
    },
    signOut: () => {
        setFirebaseToken();
        return Promise.resolve(firebaseAuthCallback());
    },
    signInAndRetrieveDataWithCredential() {
        firebaseAuthCallback();
    },
});

const auth = Object.assign(authFn, {
    RecaptchaVerifier: () => function RecaptchaVerifier(r) {
        return Promise.resolve(r);
    },
    PhoneAuthProvider: {
        credential: () => { },
    },
});

const createDoc = (e={}, collId, id = e.id || e._id) => ({ 
    id,
    data: ()=>e,
    metadata:{},
    ref: {
        path: collId+'/'+id, 
        get() {
            return Promise.resolve({
                data() {
                    return { ...e };
                },
            });
        },
        onSnapshot(fn) {
            fn (createDoc(e, collId))
        }
    }
})
const subscriptions=  new Set();
const subscribe = (fn) =>{
    subscriptions.add(fn)
    fn()
    return ()=> subscriptions.delete(fn)
}
const notify = ()=>{
    subscriptions.forEach(fn=> fn())
}
const snapshot = collId => ({
    forEach(fn) {
        Object.values(firebaseData[collId]).forEach(e=>fn(createDoc(e, collId)))
    },
    docChanges:  Object.values(firebaseData[collId]).map(e=>({doc:createDoc(e, collId)})),

});
const collection = collId => ({
    doc: id => ({
        get: () => Promise.resolve(createDoc(firebaseData[collId][id])),
        set: (delta) => {
            const doc = firebaseData[collId][id];
            if (!doc) return;
            Object.assign(doc, delta);
            notify();
        },
        collection,
        onSnapshot(on) {
            const fn = () =>  on(createDoc(firebaseData[collId][id]));
            return subscribe(fn)
        },
    }),
    get() {
        return { 
            then(fn) {
                fn(snapshot(collId))
            }
        }
    },
    where() {
        return {
            onSnapshot(on) {
                const fn = () =>   on(snapshot(collId));
                return subscribe(fn)
            },
        };
    },
    onSnapshot(on) {
        const fn = () =>  on(snapshot(collId));
        return subscribe(fn)
    },
});

const firestore = () => ({
    settings() {
    },
    enablePersistence() {
    },
    collection
});

const links = () => ({
    onLink() { },
    getInitialLink: () => { },
});

const performance = () => ({
    trace: () => ({
        start: () => {},
        stop: () => {},
    }),
});

const perf = () => ({
    newTrace: () => performance().trace(),
});
const analytics = ()=> ({
        logEvent() {
        },
        setUserProperties(){

        }
    });
export const firebase = {
    __setAuthUserToken: setFirebaseToken,
    auth,
    performance,
    firestore: {
        Timestamp: {
            now: () => ({
                seconds: new Date().getTime() / 1000,
            }),
        },
        __notify(){
            notify();
        }
    },
    analytics,
    initializeApp() {
    },
    app() {
        return { auth, firestore, links, perf };
    },
    
};
