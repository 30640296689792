import { image as styles } from '../styles/common';
import actions from '../actions';
import { COVERERS, IMAGES } from '../assets';
import EmptyFavorites from '../assets/images/empty-favorites.svg';
import EmptyFavoriteMedcenters from '../assets/images/empy-favorite-medcenters.svg';
import Family from '../assets/images/family.svg';
import Time from '../assets/images/time.svg';
import Attachment from '../assets/images/attachment.svg';
import EmptyDocs from '../assets/images/empty-docs.svg';
import Layers from '../assets/images/layers.svg';
import LocationSearching from '../assets/images/location-searching.svg';
import LogoBeta from '../assets/images/aibolit_logo_beta.svg';
import { Subtitle, Title, VBox } from './elements';
import {
    Image, Platform, React, TouchableWithoutFeedback, View,
} from './react';

export const Img = React.memo(({ style, source, resizeMode = 'cover' }) => (
    <Image accessibilityLabel="image" resizeMode={resizeMode} style={style} source={source} />
));

Img.Marker = ({ color = '1' }) => <Img source={IMAGES[`Dot${color}`]} />;

Img.EmptyBasket = () => <Img style={styles.stubImage} source={IMAGES.EmptyFak} />;

Img.NotAuthorized = () => <Img style={styles.stubImage} source={IMAGES.NotAuthorized} />;

Img.Family = ({ width = 240, height = 140 }) => <Family style={styles.stubImage} width={width} height={height} />;

Img.Error = () => <Img style={styles.stubImage} source={IMAGES.ErrorImg} />;

Img.EmptyFavorites = ({ style }) => (Platform.OS === 'web' ? (
    <Img
        source={EmptyFavorites}
        style={[
            {
                width: 240,
                height: 140,
            },
            styles.stubImage,
            style,
        ]}
    />
) : (
    <EmptyFavorites style={[styles.stubImage, style]} width={240} height={140} />
));

Img.EmptyFavoriteMedcenters = ({ style }) => (Platform.OS === 'web' ? (
    <Img
        source={EmptyFavoriteMedcenters}
        style={[
            {
                width: 240,
                height: 140,
            },
            styles.stubImage,
            style,
        ]}
    />
) : (
    <EmptyFavoriteMedcenters style={[styles.stubImage, style]} width={240} height={140} />
));

Img.Time = () => (Platform.OS === 'web' ? (
    <Img
        source={Time}
        style={{
            width: 14,
            height: 14,
        }}
    />
) : (
    <Time width={14} height={14} />
));

Img.Attachment = ({ style }) => (Platform.OS === 'web' ? (
    <Img
        source={Attachment}
        style={[
            {
                width: 18,
                height: 18,
            },
            style,
        ]}
    />
) : (
    <Attachment style={style} width={18} height={18} />
));

Img.EmptyDocs = ({ style }) => (Platform.OS === 'web' ? (
    <Img
        source={EmptyDocs}
        style={[
            {
                width: 240,
                height: 140,
            },
            style,
        ]}
    />
) : (
    <EmptyDocs style={style} width={240} height={140} />
));

Img.ChildBadge = ({ style }) => <Img style={style} source={IMAGES.ChildBadge} />;

Img.UserAvatar = ({ style }) => <Img style={style} source={IMAGES.UserAvatar} />;

Img.LocationSearching = ({ style }) => (Platform.OS === 'web' ? (
    <Img
        source={LocationSearching}
        style={[
            {
                width: 24,
                height: 24,
            },
            style,
        ]}
    />
) : (
    <LocationSearching style={style} />
));

Img.Layers = ({ style }) => (Platform.OS === 'web' ? (
    <Img
        source={Layers}
        style={[
            {
                width: 24,
                height: 24,
            },
            style,
        ]}
    />
) : (
    <Layers style={style} />
));

Img.MapCover = () => (
    <Img
        style={{
            flex: 1,
            width: '100%',
            height: '100%',
        }}
        source={IMAGES.MapCover}
    />
);

Img.MapsUpdatedPopUp = () => (
    <Img
        style={{
            alignSelf: 'center',
            height: '89%',
        }}
        source={IMAGES.MapsUpdatedPopUp}
        resizeMode="contain"
    />
);

Img.EnterSignIn = ({ style }) => <Img style={style} source={IMAGES.IllustrationEnterSignIn} />;

Img.FillOutProfile = () => <Img source={IMAGES.IllustrationFillOutProfile} />;

Img.AddKids = ({ style }) => <Img style={style} source={IMAGES.IllustrationAddKids} />;

Img.Device = () => <Img style={[styles.stubImage, { height: 170 }]} source={IMAGES.Device} />;

Img.LogoBeta = ({ style }) => (Platform.OS === 'web' ? (
    <Img
        source={LogoBeta}
        style={[
            {
                width: 112,
                height: 20,
            },
            style,
        ]}
    />
) : (
    <LogoBeta style={style} width={112} height={20} />
));

Img.EpamMarker = () => (
    <Img
        style={{
            width: 60,
            height: 50,
        }}
        source={IMAGES.EpamMarker}
    />
);

Img.EpamMarkerLimited = () => (
    <Img
        style={{
            width: 60,
            height: 50,
        }}
        source={IMAGES.EpamMarkerLimited}
    />
);

Img.BnsMarker = () => (
    <Img
        style={{
            width: 60,
            height: 50,
        }}
        source={IMAGES.BnsMarker}
    />
);

Img.BnsMarkerLimited = () => (
    <Img
        style={{
            width: 60,
            height: 50,
        }}
        source={IMAGES.BnsMarkerLimited}
    />
);

Img.InfoIcon = ({ size = 25, style: stl }) => (
    <Img
        style={{
            width: size,
            height: size,
            ...stl,
        }}
        source={IMAGES.infoIcon}
    />
);

Img.WNRelatives = () => (
    <Img
        style={{
            alignSelf: 'center',
            width: 330,
            height: 330,
        }}
        source={IMAGES.WNRelatives}
    />
);

export const Logo = () => (
    <TouchableWithoutFeedback onPress={actions.tryOpenDevInfo} accessibilityLabel="app_logo">
        <View>
            <Img style={styles.logo} source={IMAGES.Logo} />
        </View>
    </TouchableWithoutFeedback>
);

Logo.Wrapper = ({
    children, title, subtitle, subtitleParams, wrapperStyle, titleStyle,
}) => (
    <View style={wrapperStyle}>
        <VBox centered>
            <Logo />
            {!title ? null : (
                <VBox centered gap={0.3} style={{ marginBottom: 20 }}>
                    <Title bold="true" centered id={title} style={titleStyle} />
                    {!subtitle ? null : (
                        <Subtitle.Bigger
                            style={{ color: '#9c9c9c' }}
                            centered
                            numberOfLines={null}
                            id={subtitle}
                            params={subtitleParams}
                        />
                    )}
                </VBox>
            )}
        </VBox>
        {children}
    </View>
);
export const CoverableBy = React.memo(({ coverer, containerStyles, isLimited }) => (!coverer ? null : (
    <View accessibilityLabel="epam-indicator" style={containerStyles}>
        <Image
            resizeMode="cover"
            style={{
                width: coverer === 'epam' ? 42 : 60,
                height: 16,
            }}
            source={
                isLimited
                    ? (COVERERS[`${coverer}`] || COVERERS.unknown).limLogo
                    : (COVERERS[`${coverer}`] || COVERERS.unknown).logo
            }
        />
    </View>
)));

export const CoverableByBig = React.memo(({ coverer, containerStyles, isLimited }) => (!coverer ? null : (
    <View accessibilityLabel="epam-indicator" style={containerStyles}>
        <Image
            resizeMode="cover"
            style={{
                width: coverer === 'epam' ? 50 : 70,
                height: 19,
            }}
            source={
                isLimited
                    ? (COVERERS[`${coverer}`] || COVERERS.unknown).limLogo
                    : (COVERERS[`${coverer}`] || COVERERS.unknown).logo
            }
        />
    </View>
)));

export const Dot = ({ on, style }) => <View style={[styles.indicator, !on ? null : styles.indicator_yes, style]} />;

CoverableBy.Dot = ({ coverer, isLimited, style }) => (
    <View
        accessibilityLabel="coverer-dot"
        style={[
            styles.indicator,
            coverer
                ? {
                    ...styles.indicator_yes,
                    backgroundColor: (COVERERS[`${coverer}`] || COVERERS.unknown).color,
                }
                : {},
            isLimited && coverer
                ? {
                    backgroundColor: 'white',
                    borderColor: (COVERERS[`${coverer}`] || COVERERS.unknown).color,
                    borderWidth: 1,
                }
                : {},
            style,
        ]}
    />
);

CoverableBy.OnboardingImg = ({
    coverer,
    style = {
        width: 240,
        height: 240,
    },
}) => <Img style={style} resizeMode="contain" source={COVERERS[coverer].onboardingSrc} />;
