import { useNavigation } from '@react-navigation/native';
import { useCallback } from 'react';

export const useAddFeedbackActionCallback = (forItemId, forItemKind) => {
    const navigation = useNavigation();
    return useCallback(
        () => navigation.navigate('AddFeedbacks', {
            feedback: {
                forItemKind,
                forItemId,
            },
        }),
        [navigation, forItemId, forItemKind],
    );
};
