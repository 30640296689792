import {
    BigButton, Box, Page, React, S, Stub,
} from '../../common';
import { openAppInStore } from '../../services/device';
import { useBackHandler } from '../../utils/routing';

/**
 * Outdated App Version page.
 */
export const OutdatedAppVersion = Page.register(() => {
    useBackHandler();
    return (
        <Page name="outdatedAppVersion">
            <Stub.OutdatedAppVersion>
                <Box centered style={S.padding}>
                    <BigButton primary action={openAppInStore} title="buttons.upgradeApp"/>
                </Box>
            </Stub.OutdatedAppVersion>
        </Page>
    );
});
