import { createReducerFor } from './utils';

const TYPE = 'FORMS';

const initialState = {};

export const setFormData = forms => dispatch => dispatch({
    type: TYPE,
    payload: forms,
});

export const setFormsData = (formName, data) => dispatch => dispatch({
    type: TYPE,
    payload: { [formName]: data },
});

export const setFormValue = (key, value) => (dispatch, getState) => {
    const state = getState()?.forms ?? {};
    if (state.values) {
        state.values = {
            ...state.values,
            [key]: value,
        };
    } else {
        state.values = { [key]: value };
    }
    dispatch({
        type: TYPE,
        payload: state,
    });
};

export const clearFormValues = () => (dispatch) => {
    dispatch({
        type: TYPE,
        payload: { values: {} },
    });
};

export const clearSuggestionsValues = () => (dispatch) => {
    dispatch({
        type: TYPE,
        payload: { suggestions: {} },
    });
};

export const addSuggestionItem = (field, item) => (dispatch, getState) => {
    let state = getState()?.forms?.suggestions ?? {};
    const index = Number(state?.[`index${field}`] ?? 0) + 1;
    if (item) {
        // eslint-disable-next-line no-param-reassign
        item.index = index;
    }
    const suggestions = state?.[field] ?? [];
    if (suggestions.find(s => s.id === item.id)) {
        return;
    }
    state = {
        ...state,
        [`index${field}`]: index,
        [field]: [
            ...suggestions,
            {
                ...item,
                index,
            },
        ],
    };
    dispatch({
        type: TYPE,
        payload: { suggestions: state },
    });
};

export const getFormValue = key => (dispatch, getState) => getState()?.forms?.values?.[key];

export const addHighlighter = id => (dispatch, getState) => {
    const highlighter = getState()?.forms?.highlighter ?? [];
    if (!highlighter.includes(id)) {
        highlighter.push(id);
    }
    dispatch({
        type: TYPE,
        payload: { highlighter: [...highlighter] },
    });
};

export const addHighlighters = items => (dispatch, getState) => {
    const highlighter = getState()?.forms?.highlighter ?? [];
    const set = new Set([...highlighter, ...items]);
    dispatch({
        type: TYPE,
        payload: { highlighter: [...Array.from(set)] },
    });
};

export const removeHighlighter = id => (dispatch, getState) => {
    const highlighter = (getState()?.forms?.highlighter ?? []).filter(item => item !== id);
    dispatch({
        type: TYPE,
        payload: { highlighter: [...highlighter] },
    });
};

export const removeHighlighters = items => (dispatch, getState) => {
    const highlighter = (getState()?.forms?.highlighter ?? []).filter(item => !items.includes(item));
    dispatch({
        type: TYPE,
        payload: { highlighter: [...highlighter] },
    });
};

export const clearHighlighters = () => (dispatch) => {
    const highlighter = [];
    dispatch({
        type: TYPE,
        payload: { highlighter },
    });
};

export const clearSection = section => dispatch => dispatch({
    type: TYPE,
    payload: { items: { [section]: [] } },
});

export const setFormError = error => dispatch => dispatch({
    type: TYPE,
    payload: { editUserPdError: error },
});

export default createReducerFor(TYPE, initialState);
