import EStyleSheet from 'react-native-extended-stylesheet';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
    Box, CoverableBy, React, Subtitle, TextHighliter, VBox, View,
} from '../../../common';
import { actualVisit } from '../../Visits/style';
import { InitialsAvatar } from '../../../combo';
import { capitalize } from '../../../utils';
import { navigate } from '../../../actions';
import { prepareVisit, VisitListItem } from '../../Visits/VisitListItem';
import tracking from '../../../services/tracking';
import { onResult } from '../../App/snippets/Tests&Visits/snippets';
import { getIsReportsEnable } from '../../../selectors';
import { PastVisitFooter } from '../../Visits/snippets/PastVisitFooter';

const textStyles = EStyleSheet.create({
    text: {
        fontSize: 12,
        fontWeight: '400',
        // fontFamily: '$sourceSansPropFontFamily',
        fontFamily: '$defaultFontFamily',
        lineHeight: 15,
    },
});
const subtitleStyle = textStyles.text;

export const FutureVisitListItem = ({
    visitInfo,
    hasDoctorName = false,
    keyword,
    onLayout,
    onGetRef,
    visitInfo: {
        fullName = '',
        visitor,
        specialization,
        medcenterName,
        time,
        dayText,
        covererCode,
        isFuture,
        // isFuture = isFutureVisit(targetDate) && (status === 'reserved' || status === CREATED_MANUALLY),
        isBns,
        physicianName,
        service = '',
        errorCode,
        updated,
        speciality,
        reports,
        drugs,
        attachments,
        isReportsManageable,
        serviceName,
    },
    isInPastVisitScreen,
    clickable = false,
    detailScreen = 'Visit',
    customDrawElement,
}) => {
    const onReadyClick = useCallback(() => {
        tracking.logEvent('ai_result_click');
        onResult(visitInfo, 'reports', true);
    }, [visitInfo, clickable]);
    const onAttachClick = useCallback(async () => {
        if (attachments?.length) {
            await prepareVisit(visitInfo, clickable);
            if (clickable) {
                navigate('VisitImagesSlider');
            } else {
                navigate(detailScreen, {
                    visitInfo,
                    clickable,
                });
            }
        }
    }, [attachments, clickable]);
    const onDrugsClick = React.useCallback(async () => {
        await prepareVisit(visitInfo, clickable);
        navigate(detailScreen, {
            visitInfo,
            clickable,
        });
    }, [visitInfo, clickable]);

    const isReportsEnable = useSelector(getIsReportsEnable);

    const mainPart = () => (
        <VBox style={[actualVisit.container]}>
            {serviceName ? (
                <TextHighliter style={subtitleStyle} numberOfLines={1} keyword={keyword} value={serviceName} />
            ) : null}
            {hasDoctorName && (
                <TextHighliter
                    style={subtitleStyle}
                    numberOfLines={0}
                    keyword={keyword}
                    value={isBns ? Object.R('titles.doctorIs', { doctor: physicianName }) : fullName}
                />
            )}
            {hasDoctorName && (
                <TextHighliter
                    style={subtitleStyle}
                    value={capitalize(speciality || specialization || service)}
                    keyword={keyword}
                />
            )}
            <TextHighliter style={subtitleStyle} value={medcenterName} keyword={keyword} numberOfLines={0} />
            {isFuture && errorCode && updated ? (
                <Subtitle.Warning>{Object.R(`error.${errorCode}`)}</Subtitle.Warning>
            ) : null}
            <Box style={actualVisit.box}>
                <View style={actualVisit.boxContainer}>
                    <View style={actualVisit.visitorInfoContainer}>
                        <View style={actualVisit.avatarContainer}>
                            <InitialsAvatar {...visitor} coverer={null} size={25} style={actualVisit.avatar} />
                        </View>
                        <View style={actualVisit.nameContainer}>
                            <TextHighliter
                                style={[subtitleStyle, { borderWidth: 0 }]}
                                numberOfLines={1}
                                keyword={keyword}
                                value={
                                    visitor?.isMe
                                        ? Object.R('titles.my_visit')
                                        : visitInfo?.customerFirstName ?? visitor?.firstLastName
                                }
                            />
                        </View>
                    </View>
                    <View style={actualVisit.dataAndCoveregeContainer}>
                        <CoverableBy coverer={covererCode} containerStyles={{ marginLeft: 2 }} />
                    </View>
                </View>
            </Box>
            <PastVisitFooter
                reportsCnt={isReportsEnable && isReportsManageable ? reports?.length : 0}
                drugsCnt={drugs?.length}
                attachmentsCnt={attachments?.length}
                onReadyClick={onReadyClick}
                onAttachClick={onAttachClick}
                onDrugsClick={onDrugsClick}
                keyword={keyword}
            />
        </VBox>
    );
    return (
        <VisitListItem
            visitInfo={visitInfo}
            title={`${capitalize(dayText)} ${time ? `- ${time}` : ''}`}
            keyword={keyword}
            mainPart={mainPart}
            onLayout={onLayout}
            onGetRef={(r) => {
                onGetRef?.(r);
            }}
            isInPastVisitScreen={isInPastVisitScreen}
            clickable={clickable}
            detailScreen={detailScreen}
            customDrawElement={customDrawElement}
            hideMenu
        />
    );
};
