import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
    BigButton,
    Page,
    Section,
    selectOptions,
    showNotification,
    SingleNavigation,
    TopNotification,
    VBox,
    View,
} from '../../common';
import { actualNotificationsByTarget, getIsVisitsLoadOrders, getUser } from '../../selectors';
import { isEmpty } from '../../utils';
import { loadVisits } from '../../actions/visits';
import { ClinicSelector } from '../../common/Selector';
import { addCard } from '../../actions/user';

export const LoadVisits = Page.register(
    ({
        // enableLoadPastsVisits,
        user,
    }) => {
        const [person, setPerson] = useState();
        const [visitors, setVisitors] = useState([]);
        const explainId = 'titles.loadVisitsHowToWork';
        const explainText = Object.R(explainId);
        const [selectedClinic, setSelectedClinic] = useState({});
        const [error, setError] = useState(undefined);

        const dispatch = useDispatch();
        const isLoading = useSelector(getIsVisitsLoadOrders);
        const notifications = useSelector(actualNotificationsByTarget);
        const navigation = useNavigation();

        const getVisitors = () => {
            if (!user?.info) {
                return [];
            }
            const relatives =
                user?.info?.relatives
                    ?.filter(item => item?.isVisitsManageable)
                    .map(({
                        profileId, name, relation, ...other
                    }) => ({
                        id: profileId,
                        name,
                        relation,
                        ...other,
                    })) ?? [];
            const me = user?.info?.me
                ? {
                    id: user?.info?.userId,
                    name: user?.info?.name,
                    birthday: user?.info?.birthday,
                    sex: user?.info?.sex,
                    phones: user?.info?.phones,
                    emails: user?.info?.emails,
                }
                : undefined;
            if (me && me?.name) {
                setPerson(me);
            } else {
                setPerson(relatives?.[0]);
            }
            return [me, ...relatives];
        };

        const selectVisitorHandler = () => selectOptions({
            title: 'titles.loadVisitsForWhoVisit',
            data: visitors?.filter(({ name }) => name),
            selected: person?.id,
            onSelect: params => setPerson(params),
        });

        useEffect(() => {
            setVisitors(getVisitors());
        }, [user]);

        return (
            <Page name="LoadVisits" isLoading={isLoading}>
                {!user?.isAuthenticated ? null : (
                    <>
                        {error ? (
                            <TopNotification
                                hint={{
                                    level: 'warn',
                                    message: `${error}`,
                                }}
                            />
                        ) : (
                            explainId !== explainText && <TopNotification hint={explainText} />
                        )}
                        <VBox>
                            <Section title="titles.loadVisitsForWhoVisit" capitalize>
                                <SingleNavigation
                                    title={`${person && person.name ? person.name : ' '}`}
                                    onPress={selectVisitorHandler}
                                />
                            </Section>
                            <Section title="titles.loadVisitsClinic" capitalize>
                                <SingleNavigation
                                    title={`${
                                        isEmpty(selectedClinic)
                                            ? Object.R('titles.loadVisitsClinicSelect')
                                            : selectedClinic?.name
                                    }`}
                                    onPress={() => Page.showModal(
                                        <ClinicSelector
                                            title={Object.R('titles.loadVisitsClinic')}
                                            onSelect={(clinic) => {
                                                const { id } = clinic;
                                                const notify = notifications(id);
                                                setError(notify?.message);
                                                setSelectedClinic(notify ? undefined : clinic);
                                            }}
                                            filterFunction={item => item.onlineConnectivity}
                                        />,
                                    )
                                    }
                                />
                            </Section>
                            <View style={{ paddingHorizontal: 10 }}>
                                <BigButton
                                    title="buttons.loadVisits"
                                    capitalize
                                    disabled={isEmpty(selectedClinic) || isEmpty(person)}
                                    action={() => {
                                        const profiles = visitors
                                            ?.filter?.(item => item?.id === person?.id)
                                            ?.map?.(({
                                                id, name, sex, birthday, phones = [], emails = [],
                                            }) => ({
                                                _id: id,
                                                fullNameRu: name,
                                                sex,
                                                birthday,
                                                phones: Array.isArray(phones) ? phones : [phones],
                                                emails,
                                            }));
                                        dispatch(
                                            loadVisits({
                                                profileId: person?.id ?? '',
                                                clinicId: selectedClinic?.id ?? '',
                                                onError: (e, fullError) => {
                                                    setError(
                                                        `${e}${fullError?.detail ? `. ${fullError?.detail}` : ''}`,
                                                    );
                                                },
                                                onSuccess: () => {
                                                    setError(undefined);
                                                    showNotification(`titles.loadVisitsSuccess`);
                                                    dispatch(addCard({ name: 'new data' }));
                                                    navigation.navigate('MyCard', { screen: 'Visit' });
                                                },
                                                profiles,
                                            }),
                                        );
                                    }}
                                />
                            </View>
                        </VBox>
                    </>
                )}
            </Page>
        );
    },
    { user: { from: getUser } },
);
