/* eslint-disable react/no-did-update-set-state */

// This component is HOK. It can accept any(???) component and makes the component animated, Exactly it will open and close smoothly or sharply
// as we want.
// The components core is PosedBlinkView, which accept config object with some properties that responsible for animating. All avaliable properties we can see in
// Pose library documentation.
// Currently it needs delay, and any another props, which will controlled. Trigger for start animation is updates props that we transfer to Blinker.


import posed from 'react-native-pose';
// noinspection ES6PreferShortImport
import { Component, React } from '../../common';

// !!!!!!!!! needs to tune up for the best !!!!!!!!!!!!!!!
const PosedBlinkView = posed.View({
    visible: {
        opacity: 1,
        scaleY: 1,
        scaleX: 1,
    },
    hidden: {
        opacity: 0.1,
        scaleY: 1.1,
        scaleX: 1.1,
    },
});

// When i learned Pose library better, maybe it will rewrite to functional component
export class Blinker extends Component {
    state = { poseController: true };

    // setTimeout already didn't work correctly, it will be reworks later, now variant for demo
    // update. It was connected with duration property in config PosedBlinkView
    // it means that with small values (time) delay works strange (less than 0,5 sec nothung happens, like  a minimum), otherwise ok
    componentDidUpdate(prevProps) {
        // don't forget to compare props!!!!!!!!!!!!!!!!!
        if (this.props.animateTrigger !== prevProps.animateTrigger) {
            this.setState({ poseController: false });
            setTimeout(() => this.setState({ poseController: true }), 100);
        }
    }

    render() {
        const { children } = this.props;
        const { poseController } = this.state;
        // console.log('---------------------- Blinker, poseController, ', poseController);
        return (
            <PosedBlinkView pose={poseController ? 'visible' : 'hidden'}>
                {children}
            </PosedBlinkView>
        );
    }
}
