import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import {
    Dimensions, Page, React, ScrollView, View, Platform,
} from '../../common';
import { Map } from '../../combo';
import { getMapType } from '../../selectors';
import { sortBy } from '../../utils';
import { useBackHandler } from '../../utils/routing';
import actions from '../../actions';
import { drugstoreDetails as style } from './style';
import { DrugstoreDetailsBanner } from './DrugstoreDetails.banner';
import { averageLocation } from './DrugstoreDetails.utils';

const { width } = Dimensions.get('window');

export const DrugstoreDetails = Page.register(
    ({
        from, data = [], item: initialItem, mapType, drugs = [], allData = undefined, navigation, filter,
    }) => {

        const [item, setItem] = useState(initialItem);

        useEffect(() => {

            setItem(initialItem);

        }, [initialItem]);

        const { id: itemId } = item || {};

        const initialRegion = useMemo(
            () => (initialItem && initialItem.latitude
                ? {
                    latitude: initialItem.latitude,
                    longitude: initialItem.longitude,
                }
                : averageLocation(data)),
            [initialItem, data],
        );

        useBackHandler(
            useCallback(() => {

                if (itemId) {

                    setItem(null);
                    return true;

                }
                return false;

            }, [itemId]),
        );

        const actualData = useMemo(
            () => sortBy(
                data
                    ?.filter?.(({ drugs: dr }) => dr?.length === drugs?.length || filter !== 1)
                    ?.map(e => (!e.isSelected === !(e.id === itemId)
                        ? e
                        : {
                            ...e,
                            isSelected: !e.isSelected,
                        })),
                '-latitude',
            ),
            [itemId, data],
        );

        actions.setFormData({
            DrugstoreDetails: {
                back: () => {

                    if (from === 'searchFirstAidKit') {

                        navigation.pop();
                        navigation.navigate('SearchDrugstores', {
                            drugs,
                            from,
                        });
                        // navigation.replace('FirstAidKit');

                    } else {

                        navigation.goBack();

                    }
                    return true;

                },
            },
        });

        return (
            <Page noscroll name="drugstore_details_screen">
                <View accessibilityLabel="drugstore_details_screen_map" style={{ flex: 1 }}>
                    <View style={{ flex: 1 }}>
                        {!initialRegion ? null : (
                            <Map
                                mapType={mapType}
                                data={actualData}
                                onItem={(i) => {

                                    navigation.setParams({ item: i });

                                }}
                                initialRegion={initialRegion}
                                drugs={drugs}
                                zoom={item ? 17 : undefined}
                                allDrugs={allData}
                                centerOnClick
                                item={item}
                                scaleKey="totalPrice"
                            />
                        )}
                    </View>
                    <ScrollView
                        style={[
                            style.infoContainer,
                            // eslint-disable-next-line no-nested-ternary
                            { maxHeight: !itemId ? 1 : Platform.OS === 'web' ? '40%' : width * 0.7 },
                        ]}>
                        {!itemId ? null : <DrugstoreDetailsBanner item={item} />}
                    </ScrollView>
                </View>
            </Page>
        );

    },
    { mapType: { from: getMapType } },
);
