import {
    BigButton,
    Box,
    Button,
    CoverableBy,
    Modal,
    Page,
    Platform,
    React,
    ScrollView,
    Subtitle,
    Title,
    VBox,
    View,
} from '../common';
import { parseToDdMmYyyy } from '../utils';
import { openURL } from '../services/device';
import { storage } from '../services/localstorage';
import { NotificationsPermissionsModal, ProfileInstructionsModal } from '../common/Modal';
import { relativeInfo } from '../screens/Account/snippets';
import { wasNotifPermAsked } from '../init';
import actions from '../actions';
import { showRateConfirmModal } from './RateAppDialog';
import { InitialsAvatar } from './InitialsAvatar';

export const SpouseAddedModal = (
    { spouse, onDone }, // for relative
) => (
    <Modal.Congrats onDone={onDone}>
        <Subtitle.Bigger id="titles.you_are_linked_to_family" numberOfLines={null} params={{ owner: spouse.name }} />
        <Box>
            <InitialsAvatar name={spouse.name} index={spouse.index} style={{ marginRight: 12 }} />
            {relativeInfo(spouse)}
        </Box>
    </Modal.Congrats>
);

const showSpouseAddedNotification = (spouse, onRequestClose) => {
    Page.showModal(<SpouseAddedModal spouse={spouse} onDone={onRequestClose} />);
};

const showRelativesJoinedNotification = (relatives, onRequestClose) => {
    // for child
    Page.showModal(
        <Modal.Congrats onDone={onRequestClose}>
            <Subtitle.Bigger id="titles.newRelativesFound" numberOfLines={null} />
            <VBox gap={1}>
                {relatives.map(({
                    index, name = '', birthday, relation,
                }) => (
                    <Box>
                        <InitialsAvatar name={name} index={index} style={{ marginRight: 12 }} />
                        <VBox flex justify="center">
                            <Title.Bigger numberOfLines={null}>{name}</Title.Bigger>
                            <Subtitle>{[relation, parseToDdMmYyyy(birthday)].filter(Boolean).join(', ')}</Subtitle>
                        </VBox>
                    </Box>
                ))}
            </VBox>
        </Modal.Congrats>,
    );
};
const showAdultRelativesJoinedNotification = (relatives, onRequestClose) => {
    Page.showModal(
        <Modal.Congrats onDone={onRequestClose}>
            <Subtitle.Bigger numberOfLines={null}>{`${Object.R('titles.newAdultRelatives', { fullName: relatives?.map(e => e.name)?.join(', ') })}`}</Subtitle.Bigger>
            <VBox gap={1}>
                {relatives.map(({
                    index, name = '', birthday, relation, isVisitsManageable,
                }) => (
                    <Box>
                        <InitialsAvatar
                            name={name}
                            index={index}
                            style={{ marginRight: 12 }}
                            withEditBadge
                            grey={!isVisitsManageable}
                        />
                        <VBox flex justify="center">
                            <Title.Bigger numberOfLines={null}>{name}</Title.Bigger>
                            <Subtitle>{[relation, parseToDdMmYyyy(birthday)].filter(Boolean).join(', ')}</Subtitle>
                        </VBox>
                    </Box>
                ))}
            </VBox>
        </Modal.Congrats>,
    );
};

const showProgramLinkedNotification = ({ covererCode, name, isRelatives }, onRequestClose) => {
    const relativeStr = isRelatives ? 'Relative' : '';
    Page.showModal(
        <Modal
            onRequestClose={() => {
                Page.closeModal();
                onRequestClose();
            }}
            hideClose>
            <ScrollView style={{ maxHeight: '90%' }}>
                <View
                    style={{
                        alignItems: 'center',
                        paddingHorizontal: 16,
                        paddingBottom: 16,
                    }}>
                    <Title bold centered numberOfLines={null} id="titles.congratulations" />
                    <Subtitle.Bigger
                        style={{
                            textAlign: 'center',
                            paddingTop: 4,
                            paddingBottom: 24,
                        }}
                        numberOfLines={null}>
                        {Object.R(`titles.now${relativeStr}LinkedToProgram_${covererCode}`, { programName: name })}
                    </Subtitle.Bigger>
                    <CoverableBy.OnboardingImg
                        coverer={covererCode}
                        style={
                            covererCode === 'bns-limited'
                                ? {
                                    width: 325,
                                    height: 325,
                                }
                                : {
                                    width: 240,
                                    height: 240,
                                }
                        }
                    />
                    <Subtitle.Bigger
                        style={{
                            textAlign: 'center',
                            marginTop: 16,
                        }}
                        id={`titles.now${relativeStr}VisitsAreCovered_${covererCode}`}
                        numberOfLines={null}
                    />
                    {covererCode === 'bns-limited' ? (
                        <CoverableBy.Dot coverer={covererCode} />
                    ) : (
                        <CoverableBy coverer={covererCode} containerStyles={{ marginVertical: 12 }} />
                    )}
                    <Subtitle.Bigger
                        style={{
                            textAlign: 'center',
                            paddingBottom: 8,
                        }}
                        id={`titles.now${relativeStr}ServicesAreCovered_${covererCode}`}
                        numberOfLines={null}
                    />
                    {covererCode !== 'bns-limited' && <CoverableBy.Dot coverer={covererCode} />}
                </View>
            </ScrollView>
            <Button
                primary
                title="button.understand"
                styles={{ marginHorizontal: 16 }}
                action={() => {
                    Page.closeModal();
                    onRequestClose();
                }}
            />
        </Modal>,
    );
};
// Use Object as bearer from performance purposes
Object.handleNewUserItemsFound = (
    newRelatives,
    newProgram,
    onRequestClose,
    onClose = () => {
        if (onRequestClose) {
            onRequestClose();
        }
    },
) => {
    const showChildren = (child) => {
        if (child && child.length) {
            showRelativesJoinedNotification(child, onClose);
        }
    };

    if (newProgram) {
        showProgramLinkedNotification(newProgram, () => {
            actions.setProgram(newProgram);
            Object.handleNewUserItemsFound(newRelatives, null, onRequestClose);
        });
    } else if (newRelatives && newRelatives.length) {
        if (
            newRelatives.length === 1 &&
            newRelatives[0].isSpouse &&
            newRelatives[0].accessLevels.some(level => level === 'VIEW_COVERAGE')
        ) {
            setTimeout(() => {
                showSpouseAddedNotification(newRelatives[0], onClose);
            }, 2000);
        } else {
            const addedRelatives = newRelatives.filter(
                e => !((e.isSpouse || e.isParent) && e.accessLevels.some(level => level === 'VIEW_VISITS')),
            );
            const addedChildren = newRelatives.filter(e => e.isChild);
            const addedOther = newRelatives.filter(e => !e.isChild);
            setTimeout(() => {
                if (addedOther.length) {
                    (addedRelatives.length ? showRelativesJoinedNotification : showAdultRelativesJoinedNotification)(
                        addedOther,
                        () => {
                            onClose();
                            showChildren(addedChildren);
                        },
                    );
                } else {
                    showChildren(addedChildren);
                }
            }, 2000);
        }
    } else {
        onClose();
    }
};

export const showNotificationInfoModal = (title, message, actionLink) => {
    let buttonTitle = 'button.understand';
    let localizeButtonTitle = true;
    let buttonAction = Page.closeModal;
    if (actionLink) {
        const [linkText, linkHref] = actionLink.replace(/[\\[\]]/g, '').split('|');
        buttonTitle = linkText;
        localizeButtonTitle = false;
        buttonAction = () => {
            openURL(linkHref);
            Page.closeModal();
        };
    }
    Page.showModal(
        <Modal>
            <Title bold centered numberOfLines={null}>
                {title.replace(/\\n/g, '\n')}
            </Title>
            <ScrollView style={{ maxHeight: 350 }}>
                <Title.Bigger numberOfLines={null}>{message.replace(/\\n/g, '\n')}</Title.Bigger>
            </ScrollView>
            <BigButton title={buttonTitle} localize={localizeButtonTitle} action={buttonAction} />
        </Modal>,
    );
};

export const checkRateRequestTime = async (callback) => {
    if (Platform.OS === 'web' || !wasNotifPermAsked.value) {
        return;
    }
    const lastRateRequest = await storage.get('lastRateRequest');
    if (!lastRateRequest) {
        showRateConfirmModal();
    } else {
        const rateRequestPeriod = await storage.get('rateRequestPeriod');
        if (rateRequestPeriod === 'never') {
            return;
        }
        const periods = await storage.get('ratePeriods');
        const nextRateRequest = new Date(
            new Date(lastRateRequest).getTime() + ((periods ? periods[rateRequestPeriod] : 0) || 0),
        );
        nextRateRequest <= new Date() ? setTimeout(showRateConfirmModal, 500) : callback();
    }
};

// noinspection JSUnusedGlobalSymbols
export const checkShowWNModal = async () => {
    // remove show modal what's new on 1st startup
    // if (Platform.OS === 'web') {
    //     return;
    // }
    // const didOpenWNModal = await storage.get('didOpenWNModal_relatives');
    // if (!didOpenWNModal) {
    //     Page.showModal((<WNModal />));
    // }
};

export const checkNotifModal = (onConfirm, onClose) => {
    Page.showSystemModal(<NotificationsPermissionsModal onConfirm={onConfirm} onClose={onClose} />);
};

// Use Object as bearer from performance purposes
Object.checkShowProfileInstructionsModal = async () => {
    const providerCodes = (await storage.get('providerCodes')) || [];
    const doNotShowProfileInstructionsModal = await storage.get('doNotShowProfileInstructionsModal');
    const user = (await storage.get('user')) || {};
    const isUserWithFullInformation = Boolean(user.name && user.sex && user.birthday);

    if (!doNotShowProfileInstructionsModal && providerCodes && providerCodes.length && !isUserWithFullInformation) {
        Page.showModal(<ProfileInstructionsModal />);
    }
};
