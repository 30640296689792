import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Platform, TouchableWithoutFeedback } from 'react-native';
import {
    BigButton, Button, Icon, React, Text, View,
} from '../../common';
import { styles } from '../../common/InAppUpdater';
import { Card } from '../../common/react';
import { getFormValues } from '../../selectors/forms';
import { hasUnfilledRelatives } from '../../selectors';
import { setFormValue } from '../../actions/forms';
import { COLORS } from '../../styles/colors';

export const FamilyStoreLocalPanel = () => {
    const formValues = useSelector(getFormValues);
    const hasUnfilled = useSelector(hasUnfilledRelatives);
    const dispatch = useDispatch();
    const [fullView, setFullView] = useState(false);
    const nav = useNavigation();

    const closeWindowHandler = () => {
        dispatch(setFormValue('hideFamilyWindow', true));
    };

    const goToFamilyHandler = () => {
        nav.navigate('Account', { screen: 'Relatives' });
    };

    const changeFullView = () => setFullView(prev => !prev);

    const outMessage = useMemo(
        () => (
            <Text
                style={[
                    // styles.textNormal,
                    {
                        color: COLORS.STANDARD_GRAY,
                        lineHeight: 20,
                        fontSize: 14,
                    },
                ]}
                numberOfLines={fullView ? 0 : 2}>
                {Object.R('titles.fillRelativeProfilesWindow')}
            </Text>
        ),
        [fullView],
    );

    return (
        !formValues?.hideFamilyWindow &&
        hasUnfilled && (
            <Card containerStyle={[styles.wrapper, { position: 'relative' }]}>
                <View style={styles.lineWrapper}>
                    <TouchableWithoutFeedback onPress={changeFullView}>
                        {Platform.OS === 'web' ? outMessage : <View>{outMessage}</View>}
                    </TouchableWithoutFeedback>
                    <Button
                        action={closeWindowHandler}
                        styles={[
                            styles.closeButton,
                            {
                                position: 'absolute',
                                right: -14,
                                top: -14,
                            },
                        ]}>
                        <Icon name="cross" color="#787878" style={styles.closeIcon} />
                    </Button>
                </View>
                <BigButton
                    styles={styles.button}
                    titleStyles={styles.buttonTitle}
                    title={Object.R('titles.fillRelativeProfilesButton')}
                    action={goToFamilyHandler}
                />
                <View></View>
            </Card>
        )
    );
};
