/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    CommonActions, useFocusEffect, useNavigation, useNavigationState,
} from '@react-navigation/native';
import {
    BigButton,
    Box,
    Button,
    ErrorStub,
    InfoList,
    Modal,
    Modal2,
    Page,
    React,
    S,
    Section,
    selectOptions,
    showNotification,
    Title,
    TopNotification,
    VBox,
    View,
} from '../../common';
import { MODE } from '../../config.js';
import actions, { back } from '../../actions';
import {
    fillDoctorsWithPartnershipList,
    getCurrentOrFirstProgram,
    getRelatedPrograms,
    getShowNewUserAgreementModal,
    getUserInfo,
    plannedVisits,
    umsSpecialties,
} from '../../selectors';
import {
    arrayToHash, findByIds, isAdult, parseToDdMmYyyy, sortBy,
} from '../../utils';
import api from '../../services/api';
import { isPeriodsIntersect, parseToDdMonYyyy } from '../../utils/dates';
import { checkRateRequestTime } from '../../combo';
import { ChildModalAgreements, ModalAgreements } from '../../common/Modal';
import { setInDeleting } from '../../actions/user';
import { notifications } from '../../common/Notifications';
import { getSelectedService, getServiceByAssignmentId } from '../../selectors/services';
import { useServiceListSelector } from '../../hooks/services';
import { trimObjectFields } from '../../utils/strings';
import { getFinalMessage } from '../../services/http';
import { CREATED_MANUALLY } from '../../actions/visits';
import { gettr } from '../../selectors/utils';
import { storage } from '../../services/localstorage';
import { checkNotifications } from '../../init';
import { getFormData } from '../../selectors/forms';
import { setFormData } from '../../actions/forms';
import { isPhoneNumberValid } from '../../utils/phone';
import { openURL } from '../../services/device';
import { getSupportBody } from '../../utils/supportInfo';
import { VisitInfoSelector } from './snippets/VisitInfoSelector';
import { NewVisitorInlineForm, selectOptionsWithForm } from './snippets/VisitorSelector';
import { RegistryInformation } from './RegistryInformation';

const BOOK_CAPABILITIES_WITH_PROFILES = true;

let commonErr = false;
let requestStarted = false;

const FillPopup = () => {

    const navigation = useNavigation();
    return (
        <Modal2
            title={'titles.fillProfilePopupTitle'}
            buttonTitle={'titles.fillProfilePopupButton'}
            hideClose={true}
            showCross={true}
            _onClose={() => {

                Page.closeModal();

            }}>
            <View style={{ margin: 16 }}>
                <Title
                    style={{
                        margin: 16,
                        marginTop: 0,
                    }}>
                    {Object.R('titles.fillProfilePopupText')}
                </Title>
                <Button
                    primary
                    title="titles.fillProfilePopupButton"
                    action={() => {

                        Page.closeModal();
                        navigation.navigate('Account', { screen: 'EditAccount' });

                    }}
                />
            </View>
        </Modal2>
    );

};

const TheErrorStub = ({ error = {} }) => (
    <ErrorStub error={{
        message: Object.R('hints.online_booking_try_again'),
        ...error,
    }} support>
        <Box centered style={S.padding}>
            <BigButton primary action={back} title="buttons.close" />
        </Box>
    </ErrorStub>
);

const getHint = (person, coverer, ownerHasActiveProgram, covererCode) => {

    if ((coverer || covererCode) && person) {

        if (person.declineReasons && person.declineReasons.some(({ code }) => code === 11)) {

            return {
                level: 'warn',
                message: 'hint.medcardNotActiveForClinic',
                allowBooking: true,
            };

        }
        if (!ownerHasActiveProgram && !person.programIsActive && person.programs && person.programs.length) {

            return {
                level: 'error',
                message: 'hint.not_active_program',
                allowBooking: true,
            };

        } else if (person.isChild && !person.programIsActive) {

            return {
                level: 'warn',
                message: 'hints.child_not_in_program',
                allowBooking: true,
            };

        }

    }
    return {
        level: 'warn',
        message: 'hints.add_online-visit',
        allowBooking: true,
    };

};

const getLocalizedErrorMessage = (reason) => {

    const messageId = `titles.DECLINE_REASON_${reason?.code}`;
    const localizedMessage = Object.R(messageId);
    return localizedMessage === messageId ? reason?.message : localizedMessage;

};

const getError = (
    person,
    bookError = {
        message: '',
        allowBooking: true,
        level: 'warn',
        hideButton: false,
    },
) => {

    if (person?.declineReasons?.length) {

        const reason = sortBy(person.declineReasons, 'weight')?.[0];
        const allowBooking = person?.onlineBookingAllowed !== false;
        return (
            {
                // message: allowBooking
                //     ? reason?.message
                //     : Object.R('error.wrongBookPerson', { reason: reason?.message }),
                message: getLocalizedErrorMessage(reason),
                allowBooking,
                level: allowBooking ? 'warn' : 'error',
            } ?? { allowBooking: true }
        );

    } else if (!person) {

        return {
            message: Object.R('titles.no_visitor_error'),
            allowBooking: false,
            level: 'error',
        };

    }
    return {
        level: bookError?.level || 'warn',
        message: bookError?.message ? Object.R('error.wrongBookPerson', { reason: bookError?.message }) : '',
        allowBooking: bookError?.allowBooking ?? true,
    };

};

const loadVisitors = ({
    assignmentId,
    me,
    relatives,
    setState,
    serviceId,
    resetInitVisitor,
    currentPerson,
    coverer,
    active,
    preselect = 'auto',
    userInfo,
    canReassign,
    manualVisitors,
    isManual,
    manualPerson,
    navigation,
}) => {

    let profiles = [];
    const bookingCapabilities = BOOK_CAPABILITIES_WITH_PROFILES
        ? api.getBookingCapabilitiesWithProfiles
        : api.getBookingCapabilities;
    const emptyProfiles = (relatives ?? [])
        ?.filter(({ name, fullNameRu }) => !(name ?? fullNameRu))
        .map(({ profileId }) => profileId);
    const bookingVisitsStatuses = ['BOOK_VISITS'];
    if (BOOK_CAPABILITIES_WITH_PROFILES) {

        profiles = (relatives ?? [])
            ?.filter(({ profileId }) => !emptyProfiles?.includes(profileId))
            ?.filter(({ accessLevels = [] }) => accessLevels?.some(status => bookingVisitsStatuses.includes(status)))
            ?.map?.(({
                profileId, fullNameRu, sex, birthday, name, emails = [], phones = [],
            }) => {

                return {
                    _id: profileId,
                    fullNameRu: name ?? fullNameRu,
                    birthday,
                    sex,
                    emails: Array.isArray(emails) ? emails : [emails],
                    phones: Array.isArray(phones) ? phones : [phones],
                };

            });
        if (userInfo?.name) {

            profiles.push({
                _id: userInfo?.userId,
                fullNameRu: userInfo?.name,
                birthday: userInfo?.birthday,
                sex: userInfo?.sex,
                phones: userInfo?.phones,
                emails: userInfo?.emails,
            });

        } else {
            // emptyProfiles.push(userInfo?.userId);
        }
        // profiles = [...profiles, ...(manualVisitors ?? [])];

    }
    requestStarted = true;
    bookingCapabilities(
        assignmentId,
        serviceId === '0' ? undefined : serviceId,
        profiles?.length ? profiles : undefined,
    )
        .then((list) => {

            const allowedIds = arrayToHash(
                BOOK_CAPABILITIES_WITH_PROFILES
                    ? list?.filter?.(({ profileId }) => !emptyProfiles?.includes(profileId))
                    : list.filter(e => e.onlineBookingAllowed),
                'profileId',
            );
            const visitors = [
                {
                    ...me,
                    subName: Object.R('titles.for_me'),
                    isMe: true,
                },
                ...relatives,
            ]
                .filter(e => allowedIds[e.profileId])
                .map(e => ({
                    ...e,
                    name: e.subName || e?.firstName || allowedIds[e.profileId]?.profileHint,
                    id: e.profileId,
                    programs: allowedIds[e.profileId].programs,
                    declineReasons: allowedIds[e.profileId].declineReasons,
                    profileHint: allowedIds[e.profileId]?.profileHint,
                    onlineBookingAllowed: allowedIds[e.profileId].onlineBookingAllowed,
                }));
            if (manualVisitors?.length) {

                manualVisitors.forEach((visitor) => {

                    if (!visitors.some(({ fullNameRu }) => fullNameRu === visitor.fullNameRu)) {

                        visitors.push(visitor);

                    }

                });
                // visitors = visitors.concat(manualVisitors);

            }
            const hp = visitors.some(e => e.programs && e.programs.length);
            resetInitVisitor?.(visitors);
            setState({
                visitors,
                isLoadingCapabilities: false,
                hasPrograms: hp,
            });
            let person;
            if (preselect === 'auto') {

                person = visitors?.find(({ onlineBookingAllowed }) => onlineBookingAllowed);

            } else if (preselect === 'forMe') {

                person = visitors?.find(({ isMe }) => isMe);

            } else if (preselect === 'forChild') {

                person = visitors?.find(
                    ({ relKind, onlineBookingAllowed }) => onlineBookingAllowed && relKind === 'CHILD',
                );

            } else if (preselect === 'forRelative') {

                person = visitors?.find(
                    ({ onlineBookingAllowed, relKind }) => onlineBookingAllowed && relKind && relKind !== 'CHILD',
                );

            } else if (preselect === 'person') {

                person = visitors?.find(({ id }) => id === currentPerson);
                if (!person && canReassign) {

                    person = visitors?.find(({ onlineBookingAllowed }) => onlineBookingAllowed);

                }
                if (!person && isManual) {

                    person = visitors?.find(({ fullNameRu }) => manualPerson === fullNameRu);

                }

            }
            const visitorError = getError(person);
            const hint = getHint(person, coverer, active);

            setState({
                person,
                visitorError,
                hint,
                isManual: false,
                capabilityDone: true,
            });
            navigation.setParams({ person });
            // setState({
            //     person,
            //     visitorsError: person
            //         ? null
            //         : {
            //             message: Object.R('titles.no_visitor_error'),
            //             allowBooking: false,
            //             level: 'error',
            //         },
            //     hint: getHint(person, coverer, active),
            // });

        })
        .catch((err) => {

            commonErr = true;
            setState({
                visitorsError: err,
                visitorError: {
                    allowBooking: false,
                    level: 'error',
                    message: err?.body?.title ?? err?.code,
                },
                isLoadingCapabilities: false,
                capabilityDone: true,
            });

        })
        .finally(() => {

            requestStarted = false;
            setState({
                firstLoading: false,
                loaded: true,
            });

        });

};

function userHavePrograms(userInfo) {

    if (!userInfo) {

        return false;

    }
    if (Object.keys(userInfo?.programs)?.length) {

        return true;

    }
    return !!userInfo?.relatives?.some(r => r.programId);

}

// const filterDropdown = (dropdownArray) => dropdownArray.filter(({ programBranches = [], isMe }) => isMe || programBranches.some(branch => branch === clinicId));
export const EnrollVisit = Page.register(
    // eslint-disable-next-line complexity,max-statements
    ({
        visitorsError,
        firstLoading = true,
        showMakeAppointmentButton = true,
        loaded = false,
        programsForSelection,
        isManual,
        capabilityDone = false,
        manualPersonId,
        setState,
        doctorCovererCode,
        navigation,
        fromScreen,
        formData,
        // api communication
        error,
        // visitorsError,
        isLoading,
        isLoadingCapabilities,
        showNewUserAgreementModal,
        // context
        currentProgram = {},
        relatedPrograms = {},
        visits,
        userInfo: {
            me = {}, relatives = [], programIsActive, programs = [], birthday,
        },
        userInfo,
        familyOwner = relatives.find(({ isOwner }) => isOwner) || { programIsActive },
        visitInfo,
        alreadyAgree = false,
        visitInfo: {
            orderId,
            profileId: existingPersonId,
            programId: existingProgramId,
            doctorInfo: { coverer: doctorCoverer },
            assignmentId,
            covererCode,
            mdInfo: { phones = [] },
            serviceName,
            serviceId,
        },
        // hasPrograms = false,
        startDate,
        endDate,
        visiterId,
        canChangeDate = true,
        isEditingVisit = false,
        // visitor
        canReassign = true,
        visitors = relatives,
        remoteConfig,
        selectedService,
        isVisitorEditable = canReassign,
        initialPerson = findByIds(visitors, existingPersonId, me.profileId) || visitors[0] || { name: '' },
        person,
        // person = initialPerson || { name: '' },
        noVisitorError = !visitors?.length && !person?.manual,
        getReschedulePrograms = () => {

            const profiles = { _id: visitInfo?.profileId };
            api.getBookingCapabilitiesWithProfiles(assignmentId, serviceId === '0' ? undefined : serviceId, [profiles])
                .then((list) => {

                    const found = list.find(({ profileId }) => profileId === visitInfo?.profileId);
                    if (found) {

                        setState({ programsForSelection: found.programs ?? [] });

                    }

                })
                .catch(() => {});

        },
        getPersonalPrograms = (selectedPerson) => {

            const id = visitInfo?.profileId;
            let currentPerson = selectedPerson;
            if (!currentPerson) {

                if (id) {

                    currentPerson = userInfo?.relatives?.find(({ profileId }) => profileId === id);
                    if (!currentPerson && userInfo?.me?.profileId === id && userInfo?.me) {

                        // consoleLog('CurrentPerson', userInfo.programs);
                        currentPerson = { ...userInfo?.me };
                        currentPerson.programs = userInfo?.me?.programs?.map?.(prg => prg?.id) ?? [];
                        // currentPerson.programs = Object.keys(userInfo?.me?.programs);

                    } else if (currentPerson?.programId && !currentPerson?.programs) {

                        currentPerson.programs = [currentPerson.programId];

                    }
                    // if (!Array.isArray(currentPerson?.programs) && currentPerson?.programId) {
                    //     currentPerson.programs = [currentPerson.programId];
                    // }
                    // consoleLog('CurrentPerson ---- ', currentPerson);

                } else {
                    // currentPerson = initialPerson;
                }

            }
            // const currentPerson = selectedPerson || userInfo;
            // consoleLog('selectedPerson', {
            //     selectedPerson,
            //     relatedPrograms,
            //     id,
            //     userInfo,
            //     initialPerson,
            //     currentPerson,
            //     t: typeof currentPerson?.programs,
            //     a: Array.isArray(currentPerson?.programs),
            //     visitors,
            // });
            let p = [];
            if (currentPerson && currentPerson.programs) {

                if (Array.isArray(currentPerson.programs)) {

                    p = currentPerson.programs;

                } else if (typeof currentPerson.programs === 'object') {

                    p = Object.keys(currentPerson.programs);

                }

            }
            p = Object.values(relatedPrograms).filter(relatedProgram => p.some(e => e === relatedProgram.id));
            if (Array.isArray(programsForSelection)) {

                // consoleLog('filter ', programsForSelection);
                p = p.filter(({ id: i }) => programsForSelection.includes(i));

            }
            const res = [
                {
                    id: 0,
                    name: Object.R('title.visit_own_funds'),
                },
            ]?.concat?.(p);
            return res;

        },
        getDefaultProgram = (selectedPerson) => {

            const prg = getPersonalPrograms(selectedPerson);
            let res =
                findByIds(
                    getPersonalPrograms(selectedPerson),
                    existingProgramId,
                    selectedPerson?.programId,
                    currentProgram.id,
                    visitInfo?.profileId,
                    0,
                ) || {};
            if (res?.id === 0 && covererCode && covererCode !== '') {

                const findProgram = prg?.find(({ coverer }) => coverer === covererCode);
                if (findProgram) {

                    res = findProgram;

                }

            }
            return res;

        },
        onSelectVisitor = (item, addFields, newVisitor) => {

            commonErr = false;
            if (addFields && newVisitor) {

                setState({
                    person: newVisitor,
                    program: undefined,
                    existingPersonId: newVisitor.id,
                    manualPersonId: newVisitor.id,
                    isManual: true,
                });
                navigation.setParams({ person: newVisitor });

            } else {

                const program = getDefaultProgram(item);
                if (item?.isMe && item?.declineReasons?.length && !item?.programs?.length) {
                    // setTimeout(() => {
                    //     Page.showModal(<FillPopup />);
                    // }, 0);
                }
                setState({
                    person: item,
                    // program: getDefaultProgram(item),
                    program,
                    bookError: undefined,
                    isManual: !!item?.manual,
                    manualPersonId: item?.manual ? item.id : undefined,
                    visitorsError: undefined,
                });
                navigation.setParams({ person: item });

            }
            setState({
                hint: getHint(item),
                visitorError: getError(item),
            });

        },
        chooseVisitor = isVisitorEditable
            ? () => {

                const getName = (item) => {

                    const bd = item?.birthday ? ` (${parseToDdMmYyyy(item.birthday)})` : '';
                    const name = item?.manual
                        ? `${item?.firstName} ${item?.lastName}`
                        : item?.firstLastName || item?.name || item?.fullNameRu;
                    return `${name}${bd}`;

                };
                const manualVisitors = (Array.isArray(formData?.ManualVisitor) ? formData.ManualVisitor : []).filter(
                    ({ fullNameRu, birthday: b }) => !visitors?.find(({ fullNameRu: name, birthday: bd }) => name === fullNameRu && bd === b),
                );
                const visitorList = [...visitors, ...manualVisitors];
                selectOptionsWithForm({
                    title: 'titles.select_visitor',
                    autoHeight: true,
                    // eslint-disable-next-line no-nested-ternary
                    data: remoteConfig?.enableAddPersonOnBooking
                        ? isEditingVisit || MODE === 'uat'
                            ? visitorList
                            : [
                                ...visitorList,
                                // ...(Array.isArray(formData?.ManualVisitor) ? formData.ManualVisitor : []),
                                {
                                    id: 'newVisitor',
                                    name: Object.R('titles.newVisitor'),
                                    FormComponent: NewVisitorInlineForm,
                                },
                            ]
                        : visitorList,
                    selected: person?.manual ? `${person?.fullNameRu ?? ''}` : person?.id,
                    onSelect: onSelectVisitor,
                    getName,
                });

            }
            : null,
        // programs
        personalPrograms = getPersonalPrograms(person),
        program = isEditingVisit && !covererCode ? getPersonalPrograms(person)[0] : getDefaultProgram(person),
        getVisitorList = () => {

            let result = typeof relatives === 'object' ? relatives : {};
            const myPerson = typeof userInfo === 'object' ? userInfo : {};
            result = [
                ...result,
                {
                    ...myPerson,
                    firstLastName: Object.R('titles.for_me'),
                    fullNameRu: myPerson?.name,
                    profileId: myPerson?.me?.profileId,
                },
            ];
            return result;

        },
        chooseProgram = () => selectOptions({
            title: 'titles.select_program',
            data: personalPrograms,
            selected: program.id,
            onSelect: (item) => {

                setState({ program: item });

            },
        }),
        onDidMount = () => {
            // loadVisitors(assignmentId, me, relatives, setState, selectedService?.id || serviceId);
            // actions.setFormValue('umsServices', []);
        },
        onSubmit = () => {

            // const routes = getRouteHistory(useNavigationState(s => s));

            const personV =
                person ||
                // initialPerson ||
                getVisitorList()?.find(({ profileId }) => profileId === visitInfo?.profileId);
            const makeVisitRecord = async () => {

                setState({
                    error: null,
                    isLoading: true,
                });
                if (orderId) {

                    notifications.cancelNotificationsByVisitId(orderId);

                }
                let visitApi = orderId ? 'changeVisitRecord' : 'makeVisitRecord';
                if (personV?.manual) {

                    visitApi = 'makeVisitIndependent';

                }
                const patientData = person?.isMe
                    ? {
                        birthday: userInfo?.birthday,
                        sex: userInfo?.sex,
                        fullNameRu: userInfo?.name,
                        phones: userInfo?.phones ?? [],
                        emails: userInfo?.emails ?? [],
                    }
                    : {
                        birthday: personV?.birthday,
                        sex: personV?.sex,
                        fullNameRu:
                              personV?.fullNameRu ??
                              `${personV?.surname ?? ''} ${personV?.firstName ?? ''} ${
                                  personV?.middleName ?? ''
                              }`.trim(),
                        phones: Array.isArray(personV?.phones) ? personV?.phones : [personV?.phones],
                        emails: Array?.isArray(personV?.emails) ? personV?.emails : [personV?.emails],
                    };
                patientData.emails = patientData?.emails?.filter?.(e => e) ?? [];
                patientData.phones = patientData?.phones?.filter?.(e => e) ?? [];

                if (patientData?.emails?.length === 0) {

                    delete patientData.emails;

                }

                if (patientData?.phones?.length === 0) {

                    delete patientData.phones;

                }

                if (!patientData?.fullNameRu) {

                    delete patientData?.fullNameRu;

                }

                if (!patientData?.birthday) {

                    delete patientData?.birthday;

                }

                if (patientData?.sex === undefined) {

                    delete patientData?.sex;

                }

                const serviceApi = orderId ? 'changeServiceRecord' : 'makeServiceRecord';
                let apiName = selectedService?.id || (serviceId && serviceId !== '0') ? serviceApi : visitApi;
                if (personV?.manual) {

                    apiName = visitApi;

                }
                const visitParams = {
                    ...visitInfo,
                    profileId: visitInfo?.profileId,
                    newPersonId: isVisitorEditable ? personV?.id ?? initialPerson?.id : visitInfo?.profileId,
                    programId: program?.id || undefined,
                    covererCode,
                    serviceId: null,
                };
                const serviceParams = {
                    ...visitParams,
                    serviceId: selectedService?.id || serviceId,
                    serviceName: selectedService?.name || serviceName,
                    slotDuration: selectedService?.duration,
                    assignmentId,
                };
                visitParams.patientData = patientData;
                serviceParams.patientData = patientData;
                if (personV?.manual) {

                    if (personV?.phone && isPhoneNumberValid(personV?.phone)) {

                        serviceParams.patientData.phones = [
                            {
                                usage: 'PRIMARY',
                                number: personV?.phone,
                            },
                        ];

                    }

                }
                const wasNPAsked = await storage.get('wasNPAsked');
                api[apiName](
                    selectedService?.id || (serviceId && serviceId !== '0') || personV?.manual
                        ? serviceParams
                        : visitParams,
                )
                    .then(() => {

                        setState({
                            error: null,
                            isLoading: false,
                        });
                        if (fromScreen === 'DoctorRecordDatePick') {

                            navigation.pop(1);

                        }
                        actions.setSelectedService();
                        navigation.dispatch(
                            CommonActions.reset({
                                index: 0,
                                routes: [{ name: 'Home' }],
                            }),
                        );
                        if (!wasNPAsked) {

                            checkNotifications('From visit', () => {

                                if (!isEditingVisit) {

                                    checkRateRequestTime(() => {

                                        showNotification(
                                            Object.R('titles.successfulBooking', {
                                                date: parseToDdMonYyyy(new Date(startDate)),
                                                time: visitInfo.time,
                                            }),
                                        );

                                    });

                                }

                            });

                        }
                        if (!isEditingVisit) {

                            checkRateRequestTime(() => {

                                showNotification(
                                    Object.R('titles.successfulBooking', {
                                        date: parseToDdMonYyyy(new Date(startDate)),
                                        time: visitInfo.time,
                                    }),
                                );

                            });

                        }

                    })
                    .catch((err) => {

                        if (err.status === 409) {

                            const v = {
                                ...err,
                                message:
                                    err?.code === 'TIMESLOT_IS_BUSY'
                                        ? Object.R('error.TIMESLOT_IS_BUSY')
                                        : (err.body || {}).detail,
                                allowBooking: false,
                                level: 'error',
                                code: undefined,
                                noSupport: true,
                                hideButton: true,
                            };
                            setState({
                                visitorError: v,
                                isLoading: false,
                                showMakeAppointmentButton: false,
                            });

                        } else if (err.status === 423) {

                            navigation.goBack();
                            showNotification(`${(err.body || {}).detail}\n${phones.join('\n')}`, false);

                        } else if (err.status === 403 && err.code === 'CODE_DOCTOR_AGE_LIMITS') {

                            const v = {
                                ...err,
                                message: Object.R('error.CODE_DOCTOR_AGE_LIMITS'),
                                allowBooking: false,
                                level: 'error',
                                code: undefined,
                                noSupport: true,
                                hideButton: true,
                            };
                            setState({
                                visitorError: v,
                                isLoading: false,
                            });

                        } else if (err?.code === 'CODE_VISITOR_DATA_MISMATCH') {

                            // const e = {
                            //     message: getFinalMessage(err.body),
                            //     allowBooking: false,
                            //     level: 'error',
                            // };
                            Page.showModal(
                                <RegistryInformation
                                    mdInfo={visitInfo?.mdInfo}
                                    errorHint={getFinalMessage(err.body)}
                                    errorTitle={'titles.changeVisit'}
                                    startDate={visitInfo?.startDate ?? visitInfo?.date}
                                    time={visitInfo?.time}
                                />,
                            );
                            setState({ isLoading: false });

                        } else if (err) {

                            const e = {
                                message: getFinalMessage(err.body),
                                allowBooking: false,
                                level: 'error',
                            };
                            setState({
                                visitorError: e,
                                isLoading: false,
                            });

                        }

                    });

            };

            const checkUserAgreementsAndMakeVisitRecords = async () => {

                if (me && (me.consentReceived || alreadyAgree || !showNewUserAgreementModal)) {

                    makeVisitRecord();

                } else {

                    Page.showModal(
                        <ModalAgreements
                            onConfirm={() => {

                                Page.closeModal();
                                if (personV?.relKind === 'CHILD') {

                                    setTimeout(() => {

                                        Page.showModal(
                                            <ChildModalAgreements
                                                title={[
                                                    personV?.fullNameRu ?? '',
                                                    parseToDdMmYyyy(personV?.birthday) ?? '',
                                                ].join(', ')}
                                                centerTitle={true}
                                                onCancel={() => Page.closeModal()}
                                                onConfirm={() => {

                                                    Page.closeModal();
                                                    setState({ alreadyAgree: true });
                                                    api.updateUserInfo({ consentReceived: true });

                                                }}
                                            />,
                                        );

                                    }, 500);

                                } else {

                                    setState({ alreadyAgree: true });
                                    api.updateUserInfo({ consentReceived: true });

                                }

                                // .then(() => makeVisitRecord());

                            }}
                            onCancel={() => Page.closeModal()}
                            title={[userInfo?.name ?? '', parseToDdMmYyyy(userInfo?.birthday) ?? ''].join(', ')}
                            centerTitle={true}
                        />,
                    );

                }

            };

            if (
                startDate &&
                endDate &&
                visits?.some(
                    ({
                        id: visitId,
                        startDate: visitStartDate,
                        endDate: visitEndDate,
                        profileId,
                        dateStart = Date.parse(visitStartDate),
                        dateEnd = Date.parse(visitEndDate),
                        status,
                        withoutTime,
                        customerFirstName,
                    }) => {

                        if (Number.isNaN(dateEnd) && status === CREATED_MANUALLY) {

                            dateEnd = dateStart + 59000;

                        }
                        if (profileId !== person?.id) {

                            return false;

                        }
                        if (
                            (status === CREATED_MANUALLY && withoutTime) ||
                            (customerFirstName && person?.manual && customerFirstName !== person?.firstName)
                        ) {

                            return false;

                        }

                        if (orderId && orderId === visitId) {

                            return false;

                        }
                        return isPeriodsIntersect(startDate, endDate, dateStart, dateEnd);
                        // const res =
                        //     (startDate >= dateStart && startDate < dateEnd) ||
                        //     (endDate > dateStart && endDate <= dateEnd);
                        // return res;

                    },
                )
            ) {

                Page.showModal(
                    <Modal hideClose>
                        <View>
                            <Title
                                id={
                                    canReassign
                                        ? 'titles.sameDateBookingError'
                                        : 'titles.sameDateBookingChangeDateError'
                                }
                                bold
                                style={{
                                    textAlign: 'center',
                                    marginBottom: 12,
                                }}
                                numberOfLines={null}
                            />
                            {canChangeDate ? (
                                <Button
                                    primary
                                    title="buttons.chooseAnotherDate"
                                    action={() => {

                                        Page.closeModal();
                                        navigation.replace('DoctorRecordDatePick', {
                                            mdInfo: visitInfo.mdInfo,
                                            doctorInfo: visitInfo.doctorInfo,
                                            canChangeDate,
                                            isEditingVisit,
                                            canReassign,
                                            profileId: person.id,
                                            covererCode,
                                        });

                                    }}
                                />
                            ) : null}
                            <Button
                                transparent
                                title="buttons.bookAnyway"
                                action={() => {

                                    Page.closeModal();
                                    checkUserAgreementsAndMakeVisitRecords();

                                }}
                            />
                            <Button transparent title="buttons.close" action={Page.closeModal} />
                        </View>
                    </Modal>,
                );

            } else {

                checkUserAgreementsAndMakeVisitRecords();

            }

        },
        bookError,
        // UI chunks
        hint = getHint(person, doctorCoverer, familyOwner.programIsActive, doctorCovererCode),
        visitorError = visitors?.length
            ? () => {

                if (canReassign === false && isEditingVisit) {

                    return null;

                }
                return getError(person, bookError);

            }
            : null,
        // notification = error ||
        //     visitorsError ||
        //     (noVisitorError &&
        //         !isLoadingCapabilities && {
        //         level: 'error',
        //         message: Object.R('titles.no_visitor_error'),
        //     }),
        footer = bd => (error?.status === 409 || !loaded ? null : (
            <View
                style={[
                    S.padding,
                    {
                        backgroundColor: 'white',
                        borderTopColor: '#F0F0F0',
                        borderTopWidth: 1,
                        paddingTop: 0,
                    },
                ]}>
                {!!visitorError?.message && !firstLoading && (
                    <>
                        <TopNotification
                            hint={visitorError}
                            containerStyle={{
                                borderBottomWidth: 0,
                                marginTop: 8,
                                marginBottom: 0,
                                paddingBottom: 0,
                            }}
                            submitText={visitorError?.noSupport ? null : Object.R('buttons.writeToSupport')}
                            submitContainerStyle={{
                                borderWidth: 0,
                                alignSelf: 'flex-end',
                                // marginTop: 0,
                                // paddingTop: 0,
                                marginRight: 16,
                                marginBottom: 12,
                                // paddingVertical: 0,
                            }}
                            onSubmit={() => {

                                openURL(`mailto:${Object.R('supportEmail')}${getSupportBody()}`);

                            }}
                        />
                    </>
                )}
                {/* {!visitorError?.hideButton && ( */}
                <BigButton
                    title="buttons.make_appointment_visit"
                    busy={isLoading}
                    action={onSubmit}
                    disabled={
                        (isVisitorEditable &&
                                (noVisitorError ||
                                    !loaded ||
                                    (!isAdult(bd ?? person?.birthday) && person?.relKind !== 'CHILD') ||
                                    visitorError?.allowBooking === false) &&
                                !(person?.manual === true)) ||
                            (visitorError?.allowBooking === false && visitorError?.status === 409)
                    }
                />
                {/* )} */}
            </View>
        )),
    }) => {

        const dispatch = useDispatch();
        const nav = useNavigationState(state => state);
        useEffect(() => {

            dispatch(
                setFormData({
                    EnrollVisit: {
                        back: () => {

                            if (nav?.routeNames?.[0] === 'DoctorRecordDatePick' && canChangeDate) {

                                navigation.navigate('DoctorRecordDatePick');

                            } else {

                                navigation.goBack();

                            }

                        },
                    },
                }),
            );

        }, []);
        useServiceListSelector(assignmentId, !(selectedService?.id || (serviceId && serviceId !== '0')));

        const user = useSelector(state => state?.user);
        const currentService = useSelector(state => getServiceByAssignmentId(state, assignmentId, serviceId));
        const info = {
            ...visitInfo,
            date: parseToDdMonYyyy(visitInfo.date),
            service: selectedService?.name || serviceName,
            serviceDescription: selectedService?.description || currentService?.description,
        };
        if (!info?.specialization && info?.speciality) {

            info.specialization = info.speciality;

        }
        if (info?.doctorInfo?.specialization === '') {

            info.doctorInfo.specialization = info?.specialization;

        }
        // const [firstLoading, setFirstLoading] = React.useState(true);
        const autoSelector = useRef(visiterId !== undefined ? visiterId : 'auto');

        const startLoadVisitors = useCallback(
            (reset) => {

                if (isVisitorEditable && ((assignmentId && selectedService?.id) || reset)) {

                    loadVisitors({
                        assignmentId,
                        me,
                        relatives,
                        setState,
                        serviceId: selectedService?.id || serviceId,
                        resetInitVisitor: reset,
                        currentPerson: isManual
                            ? manualPersonId ?? existingPersonId
                            : navigation?.getParam?.('person')?.id ?? existingPersonId,
                        // currentPerson: isManual ? manualPersonId ?? existingPersonId : person?.id ?? existingPersonId,
                        coverer: doctorCoverer,
                        active: familyOwner?.programIsActive,
                        preselect: isEditingVisit || isManual ? 'person' : autoSelector.current,
                        userInfo,
                        canReassign,
                        manualVisitors: Array.isArray(formData?.ManualVisitor) ? formData.ManualVisitor : [],
                        isManual,
                        manualPerson: person?.fullNameRu,
                        navigation,
                    });
                    setTimeout(() => {

                        autoSelector.current = 'person';
                        // const pp = getPersonalPrograms(person);
                        // consoleLog('pp 1', pp);
                        // setState({ personalPrograms: pp });

                    }, 0);

                } else if (!isVisitorEditable && assignmentId && userHavePrograms(userInfo)) {

                    // consoleLog('getReschedulePrograms', {
                    //     isVisitorEditable,
                    //     assignmentId,
                    //     covererCode,
                    // });
                    getReschedulePrograms();
                    setState({
                        firstLoading: false,
                        loaded: true,
                    });
                    // const pp = getPersonalPrograms(person);
                    // consoleLog('pp 2', pp);
                    // setState({ personalPrograms: pp });
                    // setState({ firstLoading: false });

                } else if (!isVisitorEditable && assignmentId) {

                    setState({
                        firstLoading: false,
                        loaded: true,
                    });

                }
                setState({ firstLoading: false });

            },
            [
                relatives,
                setState,
                assignmentId,
                me,
                selectedService?.id,
                serviceId,
                isVisitorEditable,
                person,
                visitorsError,
            ],
        );

        useEffect(() => {
            // if (!firstLoading) {
            // startLoadVisitors();
            // const pp = getPersonalPrograms(person);
            // consoleLog('pp 2', {
            //     pp,
            //     person,
            // });
            // // setState({ firstLoading: false });
            // setState({ personalPrograms: pp });
            // }
        }, [
            relatives,
            setState,
            assignmentId,
            me,
            selectedService?.id,
            serviceId,
            firstLoading,
            person,
            userInfo,
            relatedPrograms,
        ]);

        useEffect(() => {

            if (user?.inDeleting) {

                dispatch(setInDeleting(false));
                // navigation.goBack();

            }

        }, []);

        useEffect(() => {

            return () => {


                if (selectedService && window._currentRouteName === 'DoctorDetails') {

                    actions.setSelectedService();

                }

            };

        }, []);

        useFocusEffect(
            useCallback(() => {

                if (commonErr || requestStarted) {

                    return;

                }
                const resetInitVisitor = (actualVisitors) => {

                    const newInitPerson = findByIds(actualVisitors, existingPersonId, me.profileId) ||
                        actualVisitors[0] || { name: '' };
                    if (person?.name && person?.id !== newInitPerson?.id) {

                        onSelectVisitor(newInitPerson || { name: '' });

                    }

                };

                // console.log('user or me', { relatives, me, hasPrograms });
                startLoadVisitors(resetInitVisitor);
                // loadVisitors(assignmentId, me, relatives, setState, selectedService?.id || serviceId, resetInitVisitor);
                actions.setFormValue('umsServices', []);

            }, [selectedService?.id, serviceId, relatives, visitorsError]),
        );

        const hasPrograms = !!me?.programId || !!relatives?.some(({ programId }) => programId);
        const umsSpec = useSelector(umsSpecialties);
        const cp = useSelector(getCurrentOrFirstProgram);

        useEffect(() => {

            const doc = {
                specialization: visitInfo?.specialization,
                worksAt: [{ id: visitInfo?.branchId }],
            };
            const code = fillDoctorsWithPartnershipList([doc], cp, umsSpec);
            const codes = code?.[0]?.worksAt?.[0]?.coverer;
            setState({ doctorCovererCode: codes });

        }, [umsSpec, visitInfo, cp]);

        const getVisitorName = useCallback(() => {

            const myUser = typeof me === 'object' ? { ...me } : {};
            myUser.firstLastName = Object.R('titles.for_me');
            const fallbackPerson = [...(relatives ?? []), myUser]?.find(
                ({ profileId: pid }) => pid === visitInfo?.profileId,
            );
            let name = '';
            if (loaded !== true) {

                return '';

            }
            if (!person) {

                try {

                    if (visitorError?.message || firstLoading) {

                        return '';

                    }
                    return `${fallbackPerson?.firstLastName ?? fallbackPerson?.firstName ?? ''} ${
                        fallbackPerson?.lastName ?? ''
                    }${fallbackPerson?.birthday ? ` (${parseToDdMmYyyy(fallbackPerson?.birthday)})` : ''}`.trim();

                } catch (e) {

                    return name;

                }

            }
            if (person.manual) {

                name = `${person?.firstName} ${person?.lastName}`;

            } else {

                name = person?.firstLastName || person.name;

            }
            let bd = '';
            if (person.birthday) {

                bd = parseToDdMmYyyy(person.birthday);

            }

            return `${name}${bd ? ` (${bd})` : ''}`;

        }, [person, visitInfo, relatives, visitorError?.message, firstLoading, loaded]);

        return error && (error.status === -1 || error.status === 500) ? (
            <Page name="enroll-visit">
                <TheErrorStub error={error} />
            </Page>
        ) : (
            <Page
                name="enroll-visit"
                hint={!isLoadingCapabilities && hint}
                footer={!isLoadingCapabilities && footer(birthday)}
                // notification={notification}
                onDidMount={onDidMount}
                isLoading={isLoadingCapabilities || !loaded}>
                {isLoadingCapabilities || !loaded ? null : (
                    <VBox>
                        <VisitInfoSelector
                            title={getVisitorName()}
                            // title={person && person.name ? person.name : ''}
                            sectionTitle="titles.whose_visit"
                            sectionStyle={{ marginTop: 14 }}
                            selectorStyle={
                                !visitorError?.allowBooking && capabilityDone ? { borderColor: '#E2746C' } : {}
                            }
                            // selectorStyle={noVisitorError ? { borderColor: '#E2746C' } : {}}
                            isLoading={isLoadingCapabilities}
                            enabled={
                                isVisitorEditable && (visitors.length > 1 || remoteConfig?.enableAddPersonOnBooking)
                            }
                            onSelect={chooseVisitor}
                        />
                        {(programs.length === 0 && personalPrograms.length === 1) || !hasPrograms ? null : (
                            <VisitInfoSelector
                                title={program.name}
                                sectionTitle="titles.what_visit_program"
                                sectionStyle={{
                                    marginTop: 4,
                                    marginBottom: 8,
                                }}
                                onSelect={chooseProgram}
                                enabled={personalPrograms.length > 1}
                            />
                        )}
                        <Section
                            title="titles.details_of_visit_header"
                            flex
                            justify="space-between"
                            textStyles={{
                                color: '#555555',
                                marginBottom: 12,
                            }}>
                            <InfoList fields="enrollVisitInfoFields" data={trimObjectFields(info)} />
                        </Section>
                    </VBox>
                )}
            </Page>
        );

    },
    {
        userInfo: { from: getUserInfo },
        currentProgram: { from: getCurrentOrFirstProgram },
        relatedPrograms: { from: getRelatedPrograms },
        visits: { from: plannedVisits },
        showNewUserAgreementModal: { from: getShowNewUserAgreementModal },
        selectedService: { from: getSelectedService },
        remoteConfig: { from: gettr('db') },
        formData: { from: getFormData },
    },
);
