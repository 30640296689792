import { useSelector } from 'react-redux';
import {
    Box, Icon, React, selectOptions, Title, View,
} from '../common';
import actions from '../actions';
import { getGlobalLocations } from '../i18n';
import { getCurrentLocation, getUmsPrefilterMedcenters } from '../selectors';
import { dbGettr } from '../selectors/utils';
import { putIntoStore, SCHEME } from '../store';
import { resetDoctorsFilters } from '../screens/Doctors/utils';
import { ALLOWED_CITIES, DISALLOWED_CITIES } from '../config';

export const resetMedCentersFilter = () => {

    const { UMS_FILTER, UMS_PREFILTER_MEDCENTERS, UMS_PREFILTER_WITH_PROGRAMS } = SCHEME;
    putIntoStore(UMS_FILTER, {
        medcenters: [],
        withPrograms: false,
    });
    const { medcenters = [] } = getUmsPrefilterMedcenters();
    putIntoStore(
        UMS_PREFILTER_MEDCENTERS,
        medcenters.map(e => ({
            ...e,
            selected: false,
        })),
    );
    putIntoStore(UMS_PREFILTER_WITH_PROGRAMS, false);

};

/**
 * CitySelector combo.
 */
export const CitySelector = () => {

    const currentLocation = useSelector(getCurrentLocation);
    const locsAr = getGlobalLocations();
    const dc = useSelector(dbGettr('disallowedCities', DISALLOWED_CITIES));
    const select = () => {

        let locationData = [];
        if (Array.isArray(locsAr) && locsAr.length) {

            locationData = [...locsAr];
            if (ALLOWED_CITIES?.length) {

                locationData = locationData.filter(
                    e => ALLOWED_CITIES.includes(e.code) || ALLOWED_CITIES.includes(Number(e.code)),
                );

            }
            if (dc?.length) {

                locationData = locationData.filter(
                    e => !dc.includes(e.code) && !dc.includes(Number(e.code)),
                );

            }
            try {

                locationData[locationData.length - 1].isLast = true;

            } catch (e) {
                //
            }

        }
        selectOptions({
            title: 'titles.selectYourCity',
            selected: currentLocation.id,
            data: locationData,
            onSelect: (item) => {

                resetDoctorsFilters();
                actions.setLocation(item.code);
                resetMedCentersFilter();

            },
            isWithSearch: true,
            noLastLine: true,
        });

    };
    return (
        <Box flex onPress={select} ns="city-selector">
            <Icon.Geo style={{ paddingTop: 2 }} />
            <View style={{ flex: 1 }}>
                <Title
                    style={{
                        color: 'white',
                        marginLeft: 5,
                        borderBottomWidth: 1,
                        borderStyle: 'dashed',
                        borderColor: 'white',
                        alignSelf: 'baseline',
                        flexWrap: 'nowrap',
                    }}
                    numberOfLines={1}>
                    {currentLocation.name}
                </Title>
            </View>
        </Box>
    );

};
