/* eslint-disable no-use-before-define */
// noinspection JSUnusedGlobalSymbols

import service from '../services/api';
import { toDate } from '../utils';

const initialState = {
    id: null,
    attachmentsList: [],
    date: '',
    timetableId: null,
    isLoading: false,
    timeslots: [],
    onlineBookingAllowed: false,
    time: null,
};
const RESET_TIMETABLE = 'RESET_TIMETABLE';
const RESET_VISIT = 'RESET_VISIT';
const UPDATE_VISIT = 'UPDATE_VISIT';
const FETCH_TIMESLOTS_SUCCESS = 'FETCH_TIMESLOTS_SUCCESS';
const CHANGE_TIMESLOTS_PENDING_STATE = 'CHANGE_TIMESLOTS_PENDING_STATE';
const SET_TIMETABLE = 'SET_TIMETABLE';

export const setTimetable = (timetableId, time) => dispatch => dispatch({
    type: SET_TIMETABLE,
    payload: {
        timetableId,
        time,
    },
});

export const changeTimeslotsPendingState = (isLoading, error) => (dispatch) => {

    dispatch({
        type: CHANGE_TIMESLOTS_PENDING_STATE,
        payload: {
            isLoading,
            error,
        },
    });

};

export const fetchTimeslots =
    ({ assignmentId, physicianId }) => async (dispatch) => {

        let error;
        try {

            dispatch({
                type: CHANGE_TIMESLOTS_PENDING_STATE,
                payload: {
                    isLoading: true,
                    error: null,
                },
            });
            // changeTimeslotsPendingState(true);
            const result = await service.getDoctorsTimetable({
                assignmentId,
                physicianId,
            });
            const successData = fetchTimeslotsSuccess(Array.isArray(result) ? result[0] : result);
            dispatch(successData);

        } catch (err) {

            error = err;
            dispatch({
                type: CHANGE_TIMESLOTS_PENDING_STATE,
                payload: {
                    isLoading: false,
                    error,
                },
            });

        }

    };

export const fetchTimeslotsSuccess = ({
    assignmentId,
    onlineBookingAllowed,
    timetable = [],
    declineReasons = onlineBookingAllowed ? null : [{ code: 3 }],
} = {}) => ({
    type: FETCH_TIMESLOTS_SUCCESS,
    payload: {
        onlineBookingAllowed,
        declineReasons,
        declineReasonCode: declineReasons && declineReasons[0] ? declineReasons[0].code : null,
        declineReason:
            declineReasons && declineReasons[0] ? `error.onlineBooking/Forbidden${declineReasons[0].code}` : null,
        timeslots: timetable.map(slot => ({
            ...slot,
            startDate: toDate(slot.start),
            endDate: toDate(slot.end),
            assignmentId,
            onlineBookingAllowed,
        }))?.filter?.(({ startDate }) => {

            const current = new Date();
            try {

                return startDate?.getTime?.() >= current?.getTime?.();

            } catch {

                return true;

            }

        }),
        branchId: assignmentId,
        isLoading: false,
        error: null,
    },
});

export const resetTimetable = () => dispatch => dispatch({ type: RESET_TIMETABLE });

// ------------
export const resetVisit = payload => dispatch => dispatch({
    type: RESET_VISIT,
    payload,
});
export const updateVisit = payload => dispatch => dispatch({
    type: UPDATE_VISIT,
    payload,
});

export const visitInfoReducerCreator = () => {

    const actionHandlers = new Map([
        [
            CHANGE_TIMESLOTS_PENDING_STATE,
            (state, { payload: { isLoading, error } }) => ({
                ...state,
                isLoading,
                error,
            }),
        ],
        [
            RESET_TIMETABLE,
            state => ({
                ...state,
                timeslots: [],
                timetableId: null,
                time: null,
                date: null,
                id: null,
                declineReason: null,
                declineReasons: null,
                onlineBookingAllowed: false,
            }),
        ],
        [FETCH_TIMESLOTS_SUCCESS, (state, { payload }) => ({
            ...state,
            ...payload,
        })],
        [
            SET_TIMETABLE,
            (state, { payload: { timetableId, time } }) => ({
                ...state,
                timetableId,
                time,
            }),
        ],
        [UPDATE_VISIT, (state, { payload }) => ({
            ...state,
            ...payload,
        })],
        [RESET_VISIT, (state, { payload = initialState }) => ({ ...payload })],
    ]);

    return (state = initialState, action) => {

        if (actionHandlers.has(action.type)) {

            return actionHandlers.get(action.type)(state, action);

        }
        return state;

    };

};

export default visitInfoReducerCreator();
