/* eslint-disable no-underscore-dangle */
import { useSelector } from 'react-redux';
import { useNavigation, useNavigationState } from '@react-navigation/native';
import { useEffect } from 'react';
import {
    BigButton, NavigationList, Page, React, Section, TopNotification, View,
} from '../../common';
import { getFavoritesFull, selectMedCenterDetails } from '../../selectors';
import { putIntoStore, SCHEME } from '../../store';
import { useLiveFirebaseItem } from '../../services/firebase';
import { getRouteHistory } from '../../utils/getRouteHistory';
import { COLORS } from '../../styles/colors';
import { updateFavPhotoUrlAndName } from '../Doctors/DoctorDetails';
import { resetDoctorsFilters } from '../Doctors/utils';
import { McBannerMain, McDetailedInfoColumns } from './snippets';

const useNavTo = (clinic) => {
    const navigation = useNavigation();

    return React.useCallback(
        (route) => {
            if (route.id === 'DoctorList') {
                resetDoctorsFilters(
                    {
                        specialty: '',
                        worksAt: [clinic._id],
                        profiles: '0',
                    },
                    undefined,
                    route?.from ?? 'MedCenterDetails',
                );
            } else if (route.id === 'MedCenterServices') {
                putIntoStore(SCHEME.UMS_FILTER, {
                    medcenters: [clinic._id],
                    withPrograms: false,
                });
                navigation.navigate('MedCenterServices', {
                    mdInfo: clinic,
                    from: route?.from,
                });
                return;
            }
            const fromRouter = {
                MedCenterDetails: 'Clinic',
                default: '',
                Visit: 'Visit',
            };
            const fromArg = fromRouter[route?.from ?? 'default'] ?? '';
            const to = route.id === 'DoctorList' ? `DoctorsSpecialtiesSearch${fromArg}` : route.id || route;
            navigation.navigate(to, {
                mdInfo: clinic,
                from: route?.from ?? 'MedCenterDetails',
            });
        },
        [clinic],
    );
};

const useController = ({ _id: clinicId, branchDoctorsCounter }) => {
    const { clinic, hasServices, isLoading } = useSelector(state => selectMedCenterDetails(state, clinicId));
    const feedbackSum = useLiveFirebaseItem('clinics_feedback_sum', clinicId);
    const doctorsCounter = branchDoctorsCounter || clinic.branchDoctorsCounter;

    const hasDoctors = !!doctorsCounter || doctorsCounter === 0 ? doctorsCounter > 0 : true;
    const filtredFields = [hasServices ? null : 'MedCenterServices', hasDoctors ? null : 'DoctorList'];

    return [
        feedbackSum
            ? Object.assign(Object.create(clinic), {
                textFeedbackCount: feedbackSum.textFeedbackCount || 0,
                feedbackCount: feedbackSum.feedbackCount || 0,
            })
            : clinic,
        useNavTo(clinic),
        filtredFields,
        isLoading,
    ];
};

/**
 * MedCenter Details Page(☆☆☆☆☆).
 */
export const MedCenterDetails = Page.register(
    ({
        mdInfo = {}, navigation, byOnlineBooking = false, from = 'MedCenterDetails',
    }) => {
        const [clinic, navTo, filtredFields, isLoading] = useController(mdInfo);
        const favorites = useSelector(getFavoritesFull);
        const { connectivityStatus, notification } = clinic;
        const routes = getRouteHistory(useNavigationState(s => s));
        React.useEffect(() => {
            if (!mdInfo?.name && clinic?.name) {
                navigation.setParams({ mdInfo: clinic });
            }
        }, [clinic]);

        useEffect(() => {
            const favFound = favorites?.find?.(fav => fav?.id === clinic?.id);
            if (favFound) {
                const name = clinic?.name?.replace(' ', '\n');
                if (favFound?.photoUrl !== clinic?.photoUrl || favFound?.dispalyName !== name) {
                    updateFavPhotoUrlAndName(favorites, favFound, clinic?.photoUrl, name);
                }
            }
        }, [favorites, clinic?.id, clinic?.photoUrl, clinic?.name]);

        if (!clinic || !clinic?.id) {
            return <Page hint="titles.mcNotFound" isLoading={isLoading} />;
        }
        return (
            <Page name="medcenter-details" hint={notification} isLoading={isLoading}>
                {connectivityStatus !== 'online' ? null : (
                    <TopNotification
                        hint={{
                            level: 'success',
                            message: 'hint.online_booking_is_available',
                        }}
                    />
                )}
                {connectivityStatus !== 'unavailable' ? null : (
                    <TopNotification hint="hint.online_booking_is_not_available" />
                )}
                <Section
                    style={{
                        paddingTop: connectivityStatus === 'online' || connectivityStatus === 'unavailable' ? 20 : 0,
                        paddingBottom: 16,
                        backgroundColor: 'white',
                        marginTop: 0,
                        borderColor: COLORS.BORDER_GRAY,
                        borderBottomWidth: 1,
                    }}>
                    <McBannerMain clinic={clinic} />
                    {(connectivityStatus === 'unavailable' && mdInfo?.onlineConnectivity) ||
                    !mdInfo?.onlineConnectivity ||
                    byOnlineBooking ? (
                        <BigButton
                                title="buttons.make_appointment_medcenter"
                                trackingId={byOnlineBooking ? `ai_btn_mc_booking_from_ums` : 'ai_btn_mc_from_ums'}
                                trackingParams={{
                                    clinicId: clinic._id,
                                    journey: routes,
                                }}
                                action={
                                    byOnlineBooking &&
                                !(
                                    (connectivityStatus === 'unavailable' && mdInfo?.onlineConnectivity) ||
                                    !mdInfo?.onlineConnectivity
                                )
                                        ? () => {
                                            navigation.navigate('MedCenterRecordFull', {
                                                onPress: (f) => {
                                                    navTo({
                                                        id: 'DoctorList',
                                                        from: f ?? from ?? 'MedCenterDetails',
                                                    });
                                                },
                                                mdInfo: clinic,
                                                byOnlineBooking,
                                            });
                                        }
                                        : () => navigation.navigate('MedCenterRecord', {
                                            mdInfo: clinic,
                                            byOnlineBooking,
                                            from: from ?? 'MedCenterDetails',
                                        })
                                }
                            />
                        ) : (
                            <BigButton
                                title="buttons.choose_doctor"
                                action={() => navTo({
                                    id: 'DoctorList',
                                    from: from ?? 'MedCenterDetails',
                                })
                                }
                            />
                        )}
                </Section>
                <View>
                    <McDetailedInfoColumns clinic={clinic} navTo={navTo} />
                </View>
                <NavigationList
                    dataEnum="detailsNavigation"
                    onItem={(params) => {
                        // eslint-disable-next-line no-param-reassign
                        params.from = from ?? 'MedCenterDetails';
                        navTo(params);
                    }}
                    filtredFields={filtredFields}
                />
            </Page>
        );
    },
);
