import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fillDoctorsWithPartnershipList, getCurrentUserProgram, umsSpecialties } from '../selectors';

export const useDoctorsCoverage = (doctorForCoverage) => {
    const [doctorCoverage, setDoctorCoverage] = useState([]);
    const umsSpec = useSelector(umsSpecialties);
    const currentProgram = useSelector(getCurrentUserProgram);
    useEffect(() => {
        if (typeof doctorForCoverage !== 'object' || doctorForCoverage === null) {
            setDoctorCoverage([]);
            return;
        }
        const d = Array.isArray(doctorForCoverage) ? doctorForCoverage : [doctorForCoverage];
        const cov = fillDoctorsWithPartnershipList(d, currentProgram, umsSpec);
        setDoctorCoverage(cov);
    }, [doctorForCoverage, umsSpec, currentProgram]);
    return doctorCoverage;
};

export const useDoctorCoverage = (doctorForCoverage) => {
    const coverage = useDoctorsCoverage(doctorForCoverage);
    return coverage?.length ? coverage[0] : null;
};
