/* eslint-disable complexity,no-use-before-define */
import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Box,
    CoverableBy,
    Icon,
    Link,
    LoadingIndicator,
    React,
    SectionHeader,
    Subtitle,
    Text,
    TextHighliter as CommonTextHighlighter,
    Title,
    TouchableOpacity,
    VBox,
    View,
} from '../../common';
import { sortBy } from '../../utils';
import { COLORS } from '../../common/style';
import { getFormData } from '../../selectors/forms';
import actions from '../../actions';
import { storage } from '../../services/localstorage';
import { BumbaBanner } from '..';
import { AdaptiveListHeader } from './AdaptiveListHeader';
import { snippets as s } from './styles';
import { OptionsListItem } from './OptionsListItem';
import { isIncludesCaseLess as isSomeIncludesCaseLess } from './utils';


// TODO: will be refactored
// noinspection JSUnusedGlobalSymbols
export const SearchByKeywordListItem = ({
    item: {
        typeName,
        groupName,
        groupId,
        foundServices,
    },
    keyword,
    onItem,
    types,
    currentProgram,
}) => (
    <View>
        <AdaptiveListHeader titleFirst={typeName} titleSecond={groupName} keyword={keyword} types={types} />
        {
            sortBy(foundServices)
                .map((service) => {
                    const {
                        foundOptions,
                        id,
                    } = service;
                    return !foundOptions || foundOptions.length === 0
                        ? (
                            <ServiceListItem item={service} keyword={keyword} onItem={onItem} key={groupId + id}
                                currentProgram={currentProgram} />
                        )
                        : [
                            isIncludesCaseLess(service.name, keyword) &&
                            <ServiceListItem item={service} keyword={keyword} onItem={onItem} key={groupId + id}
                                currentProgram={currentProgram} />,
                            ...sortBy(foundOptions)
                                .map(preSelectedOption => (
                                    <ServiceListItem
                                        item={{
                                            ...service,
                                            preSelectedOption,
                                        }}
                                        keyword={keyword}
                                        option={preSelectedOption}
                                        onItem={onItem}
                                        key={id + preSelectedOption.id}
                                        currentProgram={currentProgram}
                                    />
                                )),
                        ];
                })
        }
    </View>
);

// TODO: will be refactored
// we need easy component that will to limit maxWith = window.Dimension
export const ServiceListItem = ({
    item,
    onItem,
    isRenderIndicator = true,
    option = null,
    currentProgram = { id: '*' },
    keyword,
    item: {
        name,
        programs = {},
    },
    program = programs[currentProgram.id],
    isUnderCurrentProgram = currentProgram.id === '*' ? false : program,
    coverer = isUnderCurrentProgram ? currentProgram.coverer : null,
    hasLimitations = program === 1 || (typeof program === 'object' && Object.values(((program).attributes || {}))
        .some((e = {}) => e.LIMIT)),
    style,
}) => (
    <TouchableOpacity onPress={() => onItem(item)} style={s.servicesListItem}>
        <View style={[{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        }, s.flw, style]}>
            {!isRenderIndicator ? null : (<CoverableBy.Dot coverer={coverer} isLimited={hasLimitations} />)}
            <View style={[s.flw, style]}>
                <CommonTextHighlighter value={name} keyword={keyword} wrap="wrap" numberOfLines={5} />
                {option
                    ? (
                        <CommonTextHighlighter value={option.name} keyword={keyword} style={s.serviceOptionText}
                            wrap="wrap"
                            numberOfLines={5} />
                    ) : null}
            </View>
            <Icon.Right />
        </View>
    </TouchableOpacity>
);

const isIncludesCaseLess = (value, keyword) => value && keyword && value.toLowerCase()
    .includes(keyword.toLowerCase());

const splitByKeyword = (str, keyword = '') => {
    const entries = str.match(new RegExp(keyword.replace(/[()]/g, '\\$&'), 'gi'));

    const resultArr = entries.reduce((accum, entryItem, entryItemIndex, entriesArr) => {
        const entryStartIndex = str.indexOf(entryItem);
        const beforeEntry = str.substring(0, entryStartIndex);
        // eslint-disable-next-line no-param-reassign
        str = str.slice(entryStartIndex + entryItem.length);

        if (beforeEntry.length > 0) {
            accum.push(beforeEntry, entryItem);
        } else {
            accum.push(entryItem);
        }
        if (entryItemIndex === (entriesArr.length - 1) && str.length > 0) {
            accum.push(str);
        }
        return accum;
    }, []);
    return keyword.length > 0 ? resultArr : [];
};
// TODO extract into separate file
// eslint-disable-next-line max-statements
const getElements = (value, keyword) => {
    const keywords = keyword?.split(' ');
    let resultArray = [value];
    for (let k = 0; k < keywords?.length; k++) {
        let splittedElementsfinal = [];
        for (let v = 0; v < resultArray?.length; v++) {
            const isIncluded = keywords[k] !== '' && isIncludesCaseLess(resultArray[v], keywords[k]);
            const splittedByKeyword = isIncluded ? splitByKeyword(resultArray[v], keywords[k]) : [];
            const splittedElements = splittedByKeyword.length ? [] : [resultArray[v]];
            for (let i = 0; i < splittedByKeyword.length; i += 1) {
                const words = splittedByKeyword[i];
                const splittedBySpace = isIncludesCaseLess(words, keywords[k]) ? [words] : words.split(' ');
                // eslint-disable-next-line max-depth
                for (let j = 0; j < splittedBySpace.length; j++) {
                    const element = splittedBySpace[j];
                    splittedElements.push(element);
                    // eslint-disable-next-line max-depth
                    if (j < splittedBySpace.length - 1) {
                        splittedElements[splittedElements.length - 1] += ' ';
                    }
                }
            }
            splittedElementsfinal = [...splittedElementsfinal, ...splittedElements];
        }
        resultArray = [...splittedElementsfinal];
    }
    return resultArray;
};

const getHighlitedElements = (elements, keyword, backgroundColor, style) => {
    const resultLines = [];
    let words = [];
    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        words.push(isSomeIncludesCaseLess(keyword, element)
            ? <MarkedText text={element} backgroundColor={backgroundColor} style={style} key={words.length} />
            : <Title style={style} key={words.length}>{element}</Title>);

        if (i === elements.length - 1 ||
            (isSomeIncludesCaseLess(keyword, elements[i]) === isSomeIncludesCaseLess(keyword, elements[i + 1]))) {
            resultLines.push(<View style={{ flexDirection: 'row' }} key={resultLines.length}>{words}</View>);
            words = [];
        }
    }
    return resultLines;
};

export const TextHighliter = ({
    value = '',
    keyword = '',
    backgroundColor = '#B1EFE2',
    style,
}) => {
    const isSomeIncluded = keyword !== '' && isSomeIncludesCaseLess(value, keyword);
    const elements = getElements(value, keyword);
    const resultLines = getHighlitedElements(elements, keyword, backgroundColor, style);

    return !isSomeIncluded
        ? (<Title style={style}>{value}</Title>)
        : (
            <View style={[{
                flexDirection: 'row',
                flexWrap: 'wrap',
                flex: 1,
            }]}>{resultLines}</View>
        );
};

export const MarkedText = ({
    text,
    backgroundColor,
    style,
}) => (
    <View style={[{ backgroundColor }]}>
        <Title style={style}>{text}</Title>
    </View>
);

export const ButtonLeft = ({ onPress }) => (
    <View style={{ height: '100%' }}>
        <Box centered onPress={onPress} style={s.buttonLeft}>
            <Icon.Left />
        </Box>
    </View>
);
export const UmsServiceTitle = ({ name }) => <Text numberOfLines={null} style={s.serviceTitle}>{name}</Text>;
export const UmsServiceCard = ({
    name,
    children,
    withBottomBorder,
}) => (
    <View style={[s.serviceCard, withBottomBorder ? { width: '100%', borderBottomWidth: 1, borderColor: '#E9E9E9' } : {}]}>
        <UmsServiceTitle name={name} />
        {children}
    </View>
);

export const BumbaSection = () => {
    const { bumbaBanner } = useSelector(getFormData);
    return (
        !!bumbaBanner &&
        <View style={{ marginHorizontal: 12 }}>
            <BumbaBanner onClose={() => {
                actions.setFormData({ bumbaBanner: false });
                storage.set('bumbaBanner', false)
                    .then();
            }} />
        </View>
    );
};

const getMessage = hasProgramm => (hasProgramm ? 'titles.withProgrammMessage' : 'titles.withoutProgrammMessage');

export const ServiceNotification = ({
    isValidOptions,
    hasProgramm,
}) => (
    <VBox gap={0.75} style={s.serviceNotification}>
        <VBox gap={0.5} justify="space-between">
            <Subtitle color={isValidOptions ? '#9C9C9C' : 'red'} style={[s.serviceCardText, s.optionText]}
                numberOfLines={null}
                id={isValidOptions ? getMessage(hasProgramm) : 'titles.optionsWarningMessage'} />
        </VBox>
    </VBox>
);

const OptionsList = ({
    optionsGrouped = [],
    onSelectOption,
    selectedOptions,
    onClearPress,
    isRefInfoLoaded,
}) => (
    !isRefInfoLoaded ? <LoadingIndicator />
        : <View>
            {optionsGrouped.map(({
                name,
                values,
            } = {}, index) => (
                <OptionsListItem
                    key={name}
                    name={name}
                    options={values}
                    onSelect={onSelectOption}
                    selected={selectedOptions}
                    isLastItem={index === optionsGrouped.length - 1}
                />))}
            {Object.values(selectedOptions)
                .filter(option => option !== null).length > 0 &&
                <View style={s.serviceOptions}>
                    <Link id="titles.clean_options" href={() => onClearPress()} trackingAlias="umsService" style={{
                        justifyContent: 'center',
                        textAlign: 'center',
                    }} />
                </View>}
        </View>
);

export const OptionsSection = ({
    isRefInfoLoaded,
    optionsGrouped,
    onSelectOption,
    selectedOptions,
    onClearPress,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <View>
            <TouchableOpacity style={[s.serviceOptionsHeader, { justifyContent: 'space-between' }]}
                onPress={() => setIsOpen(!isOpen)}>
                <View style={{
                    alignItems: 'center',
                    flexDirection: 'row',
                }}>
                    <Icon.Filter color={COLORS.MAIN} badge={Object.values(selectedOptions)
                        .filter(e => e !== null).length} />
                    <Title bold style={{ paddingLeft: 10 }}>{Object.R('titles.options')}</Title>
                </View>
                {isOpen ? <Icon.Down /> : <Icon.Right />}
            </TouchableOpacity>
            {isOpen
                ? (
                    <OptionsList
                        isRefInfoLoaded={isRefInfoLoaded}
                        optionsGrouped={optionsGrouped}
                        onSelectOption={onSelectOption}
                        onClearPress={onClearPress}
                        selectedOptions={selectedOptions}
                    />)
                : null
            }
        </View>
    );
};

// noinspection JSUnusedGlobalSymbols
export const UmsListsHeader = ({ title }) => (
    <SectionHeader title={title} containerStyles={s.UmsListsHeaderContainer} textStyles={s.UmsListsHeaderText}
        capitalize />);

// noinspection JSUnusedGlobalSymbols
export const ListVisibleController = ({
    visible,
    children,
}) => (<View style={visible ? { flex: 1 } : { flex: 0 }}>{children}</View>);
