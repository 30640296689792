import EStyleSheet from 'react-native-extended-stylesheet';
import { webModalContainer } from '../styles/common';
import { Platform } from '../common';

export const programInfo = EStyleSheet.create({
    servicesProgramInfoContent: { paddingHorizontal: 15 },
    badgeImgContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 8,
        marginRight: 25,
        marginBottom: 14,
    },
    text: {
        flex: 1,
        flexWrap: 'wrap',
    },
    singleText: {
        flex: 1,
        flexWrap: 'wrap',
        marginHorizontal: 8,
        marginBottom: 15,
    },
    programInfoButton: {
        paddingHorizontal: 10,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '$white',
        marginBottom: 0,
        borderWidth: 1,
        borderRadius: 3,
        borderColor: '#e0e0e0',
    },
});


export const medcenterFilter = EStyleSheet.create({
    container: {
        paddingTop: 8,
        backgroundColor: '#F9F9F9',
    },
    listItemWrapper: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        paddingVertical: 14,
        paddingHorizontal: 12,
        borderBottomWidth: 1,
        borderColor: '#E9E9E9',
    },
    checkboxWrapper: {
        width: 16,
        height: 16,
        borderWidth: 1,
        borderRadius: 2,
        borderColor: '#999999',
        marginRight: 12,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    checkboxWrapperActive: { borderColor: '#5C85DD' },
    listItemText: {
        color: '#555555',
        fontSize: 16,
    },
    list: { height: 256 },
    listFilter: {
        flex: 1,
        // height: height - 240,
        marginTop: 10,
    },
    listFilterWithSwitch: {
        flex: 1,
        // height: height - 300,
        marginTop: 10,
    },
    modalContainer: {
        ...(Platform.OS === 'web'
            ? {
                position: 'fixed',
                flex: 1,
                top: 10,
                bottom: 10,
                paddingVertical: 0,
                paddingHorizontal: 0,
            }
            : {
                marginHorizontal: 8,
                marginBottom: 8,
                marginTop: Platform.OS === 'ios' ? 40 : 8,

            }),
        borderRadius: 3,
        backgroundColor: '#F9F9F9',
        ...webModalContainer,
    },
    medcentersFilter: {
        flex: 1,
        // height: height - 170,
    },
    textWrapper: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    tabSwitch: { paddingBottom: 12 },
});

export const optionsButton = EStyleSheet.create({
    button: {
        borderWidth: 1.5,
        borderColor: '#5C85DD',
        paddingHorizontal: 10,
        marginTop: 14,
        marginRight: 12,
        marginBottom: 2,
        borderRadius: 3,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
    },
    text: {
        color: '#5C85DD',
        marginLeft: 6,
    },
});
