import { createSelector } from 'reselect';
import { SCHEME } from '../scheme';
import { arrayToHash, someEmpty } from '../utils';
import { fillDoctorsWithClinics, fillDoctorsWithPartnershipList, umsSpecialties } from './doctors';
import { getDoctorsForGlobalLocation } from './doctorsInfo';
import {
    getClinicsWithPricesInLocationClinic, getFeedbackSumsInLocationClinic, getnNarestAvailableDatesInLocationClinic, getSpecInfoForGlobalLocationAll, getSpecInfoForGlobalLocationForClinic, getTimetableSumByAssignmentInLocationClinic, isHasTimetableClinic, prepareActualSplitDoctors, prepareDoctorsWithFeedbackSumsInLocation, prepareDoctorsWithSplitPartnershipAndSumsForLocal,
} from './doctorsSums';
import { getGlobalLocation, getPhysiciansSearchFromClinics } from './getters';
import { getClinicsWithBumba, joinedClinics } from './medicians';
import { getCurrentOrFirstProgram } from './programs';
import { dbGettr, getAdaptedDoctorsWithWorksAt, prefGettr } from './utils';

const {
    PHYSICIANS_FILTER_SPECIALTY_CLINICS,
    PHYSICIANS_FILTER_SPECIALTY_ID_CLINICS,
    PHYSICIANS_FILTER_WORKS_AT_CLINIC,
    PHYSICIANS_FILTER_PERIOD_CLINIC,
    PHYSICIANS_FILTER_FAVORITES,
    PHYSICIANS_FILTER_PROFILES_CLINIC,
    PHYSICIANS_FILTER_AGES_CLINIC,
    PHYSICIANS_FILTER_FEEDBACKS_CLINIC,
    PHYSICIANS_SORT_BY_CLINIC,
    PHYSICIANS_CLINICS_FROM_SEARCH,
} = SCHEME;


export const getDoctorsBySpeciatityForClinic = createSelector(
    [getDoctorsForGlobalLocation, dbGettr('physicians.filterForClinic.specialty'), dbGettr('physicians.filterForClinic.specialtyId'), getSpecInfoForGlobalLocationAll],
    (data = {}, spec, specId, specList = []) => {
        const newArr = getAdaptedDoctorsWithWorksAt(data[specId]?.data, specList, specId);
        // const newArr = data[specId]?.data?.map(e => ({
        //     ...e,
        //     _id: e.id,
        //     worksAt: e.assignments?.map(a => ({
        //         ...a,
        //         id: a.branchId,
        //     })),
        //     specialization: specList?.find(e => e.id === specId)?.name,
        // })) || []; // data[specId]?.map((e) => ({ ...e, worksAt: e.assignments?.map((a) => ({ ...a, id: a.branchId })) })) || [];
        return arrayToHash(
            newArr
        );

    },
);

export const getDoctorsBySpeciatityForClinicWithClinics = createSelector(
    getDoctorsBySpeciatityForClinic,
    joinedClinics,
    getClinicsWithBumba,
    (physicians, clinics, bumbaClinics) => {

        return fillDoctorsWithClinics(physicians, clinics, bumbaClinics);

    },
);

export const getDoctorsBySpeciatityWithPartnershipListForClinic = createSelector(
    getDoctorsBySpeciatityForClinicWithClinics,
    getCurrentOrFirstProgram,
    umsSpecialties,
    (physicians, currentProgram, specialties) => fillDoctorsWithPartnershipList(physicians, currentProgram, specialties),
);

const doctorsBySpecialityWithFeedbackSumsInLocationClinic = createSelector(
    [getDoctorsBySpeciatityWithPartnershipListForClinic, getFeedbackSumsInLocationClinic],
    (physicians, feedbackSum) => prepareDoctorsWithFeedbackSumsInLocation(physicians, feedbackSum),
);

export const doctorsBySpecialityWithSplitPartnershipAndSumsForLocalClinic = createSelector(
    [
        doctorsBySpecialityWithFeedbackSumsInLocationClinic,
        getnNarestAvailableDatesInLocationClinic,
        getTimetableSumByAssignmentInLocationClinic,
        getClinicsWithPricesInLocationClinic,
        isHasTimetableClinic,
        getGlobalLocation,
    ],
    // eslint-disable-next-line max-params
    (physicians, timetableSum, timetableSumByAssignment, pricesSum, isUpdated, locationCode) => prepareDoctorsWithSplitPartnershipAndSumsForLocal(
        physicians,
        timetableSum,
        timetableSumByAssignment,
        pricesSum,
        isUpdated,
        locationCode,
    ),
);
export const actualSplitDoctorsBySpecialityForClinics = createSelector(
    [
        doctorsBySpecialityWithSplitPartnershipAndSumsForLocalClinic,
        getPhysiciansSearchFromClinics,
        dbGettr(PHYSICIANS_FILTER_SPECIALTY_CLINICS),
        dbGettr(PHYSICIANS_FILTER_WORKS_AT_CLINIC),
        dbGettr(PHYSICIANS_FILTER_PERIOD_CLINIC),
        dbGettr(PHYSICIANS_FILTER_FAVORITES),
        dbGettr(PHYSICIANS_FILTER_PROFILES_CLINIC),
        dbGettr(PHYSICIANS_FILTER_FEEDBACKS_CLINIC),
        dbGettr(PHYSICIANS_SORT_BY_CLINIC),
        getGlobalLocation,
        prefGettr('fullFavorites'),
        dbGettr(PHYSICIANS_CLINICS_FROM_SEARCH),
        dbGettr('sortByBumbaFeature'),
        dbGettr(PHYSICIANS_FILTER_AGES_CLINIC),
        dbGettr(PHYSICIANS_FILTER_SPECIALTY_ID_CLINICS),
    ],
    (
        physicians,
        search,
        specialty,
        worksAt,
        period,
        filterByFavorites,
        profiles,
        feedbacks,
        sort,
        locationCode,
        favorites = [],
        physiciansFromSearch,
        sortByBumba = true,
        ages = '0',
        specialityId,
        // eslint-disable-next-line max-params
    ) => prepareActualSplitDoctors(
        physicians,
        search,
        specialty,
        worksAt,
        period,
        filterByFavorites,
        profiles,
        feedbacks,
        sort,
        locationCode,
        favorites,
        physiciansFromSearch,
        sortByBumba,
        ages,
        specialityId
    ),
);
export const getHasPhysiciansDataForClinic = createSelector([getDoctorsBySpeciatityForClinic], (data) => {

    return !someEmpty(data);

});
