import { React, SearchBar, View } from '../../../common';
import { AllFeedbackSortControl } from './AllFeedbacksMyFilter';
import { COLORS } from '../../../styles/colors';
import { AllFeedbacksFilterIcon } from './AllFeedbacksFilterIcon';
import { useDispatch, useSelector } from 'react-redux';
import { setAllFeedbacksMyTextFilter } from '../../../actions/feedbacks';
import { getAllFeedbacksMyTextFilter } from '../../../selectors';

export const AllFeedbackSearch = () => {

    const dispatch = useDispatch();
    const searchText = useSelector(getAllFeedbacksMyTextFilter);

    const onSearchHandler = (text) => {

        dispatch(setAllFeedbacksMyTextFilter(text));

    };

    return (
        <View style={{
            flex: 1,
            flexDirection: 'row',
            padding: 12,
            paddingTop: 0,
            backgroundColor: COLORS.WHITE,
            borderBottomWidth: 1,
            borderBottomColor: COLORS.LIGHT_GRAY,
        }}>
            <View style={{
                flex: 1,
                borderWidth: 0,
            }}>
                <SearchBar
                    placeholder={'titles.allFeedbacksSearchPlaceholder'}
                    onSearch={onSearchHandler}
                    withDebounce
                    initialValue={searchText}
                />
            </View>
            <View style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                borderWidth: 0,
                marginTop: 12,
            }}>
                <AllFeedbacksFilterIcon style={{ marginRight: 2 }} />
                <AllFeedbackSortControl style={{ marginLeft: 2 }} />
            </View>
        </View>
    );

};
