import posed from 'react-native-pose';
// eslint-disable-next-line no-unused-vars
import { React } from '../../common';

// noinspection JSUnusedGlobalSymbols
export const ViewVisible = posed.View({
    // visible: {
    //     opacity: 1,
    //     scaleY: 1,
    //     // transition: {
    //     //     opacity: { ease: 'easeOut', duration: 300 },
    //     //     default: { ease: 'linear', duration: 500 },
    //     // },
    //     transition: { type: 'spring', stiffness: 100 },
    // },
    // hidden: {
    //     opacity: 0,
    //     // transition: { duration: 300 },
    // },
    // draggable: true,
    // draggable: 'x',
    // draggable: 'y',


    // dragging: { scale: 1.2 },
    // dragEnd: { scale: 1 },


    // draggable: 'x',
    // passive: {
    //     opacity: ['x', {
    //         inputRange: [-100, 0, 100],
    //         outputRange: [0, 1, 0],
    //     }],
    // },

    visible: { scale: 1 },
    hidden: { scale: 0 },
    passive: {
        backgroundColor: ['scale', {
            inputRange: [0.5, 1],
            outputRange: ['#f00', '#0f0'],
        }],
    },

});
