import { useCallback, useEffect, useState } from 'react';
import { React, selectOptions } from '../../../common';
import { ComboboxSelector } from './ComboboxSelector';
import { cloneObject } from './loadVisitors';

export const isProgramAllowedToProfile = (profile, programId, profileId) => {
    const p = Array.isArray(profile) ? profile.find(({ profileId: id }) => id === profileId && profileId) : profile;
    return !!p?.programId === programId;
};

export const getProfilePrograms = (profile, programs) => {
    const res = [
        {
            id: 0,
            name: Object.R('title.visit_own_funds'),
        },
    ];
    const p = cloneObject(profile?.programs ?? []);
    p?.forEach((program) => {
        res.push(programs[program]);
    });
    return res;
};

export const getProgramByCovererCode = (covererCode, programs, profile) => {
    const prog = Array.isArray(programs) ? programs : Object.values(programs);
    return prog?.find?.(({ covererCode: c, id }) => c === covererCode && (!profile || profile?.programId === id));
};

export const getDefaultProgram = (profile, programs) => {
    const res = getProfilePrograms(profile, programs);
    return res?.length === 1 ? res[0] : res[1];
};

export const ProgramSelector = ({
    profile: initialProfile, onChange = () => {}, programs = {}, selected,
}) => {
    const [programList, setProgramList] = useState([]);
    const [selectedProgram, setSelectedProgram] = useState(selected);
    const [profile, setProfile] = useState(initialProfile);

    useEffect(() => {
        setProfile(initialProfile);
    }, [initialProfile]);

    const onPressHandler = () => {
        selectOptions({
            title: 'titles.select_program',
            data: programList,
            selected: selectedProgram.id,
            onSelect: (item) => {
                onChange(item);
            },
        });
    };

    const getPrograms = useCallback(() => {
        return getProfilePrograms(profile, programs);
    }, [profile?.programs, programs]);

    useEffect(() => {
        if (selected) {
            if (selected.id !== selectedProgram.id) {
                setSelectedProgram(selected);
            }
        } else {
            setSelectedProgram(programList[0]);
        }
    }, [selected, programList]);

    useEffect(() => {
        setProgramList(getPrograms());
    }, [profile]);

    return !Object.keys(programs)?.length ? null : (
        <>
            <ComboboxSelector
                enabled={programList?.length > 1}
                onPressHandler={onPressHandler}
                title={'titles.what_visit_program'}
                value={selectedProgram?.name ?? '-'}
            />
        </>
    );
};
