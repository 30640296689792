import { useSelector } from 'react-redux';
import { useFocusEffect } from '@react-navigation/native';
import { useCallback, useEffect, useRef } from 'react';
import {
    Page, React, SearchBar, Section,
} from '../../common';
import { getFromStoreDb, putIntoStore } from '../../store';
import {
    actualSplitDoctorsBySpecialityForClinics,
    actualSplitDoctorsBySpecialityForVisit,
    actualSplitDoctorsBySpeciatity,
    getActualPhysiciansAdvancedSearch,
    getActualPhysiciansClinicsAdvancedSearch,
    getActualPhysiciansVisitsAdvancedSearch,
    getClinicsDoctorsMap,
    getDoctorsMap,
    getFiltersAdditionalInfo,
    getFiltersAdditionalInfoClinic,
    getFiltersAdditionalInfoVisit,
    getGlobalLocation,
    getHasFilterByClinic,
    getHasFilterByClinicClinic,
    getHasFilterByClinicVisit,
    getHasPhysiciansData,
    getHasPhysiciansDataForClinic,
    getHasPhysiciansDataForVisit,
    getHasSpecDataForLocation,
    getHasSpecialityFilter,
    getHasSpecialityFilterClinic,
    getHasSpecialityFilterVisit,
    getIsSpecLoading,
    getLocationAndSpec,
    getLocationAndSpecClinic,
    getLocationAndSpecVisit,
    getNumberOfActiveDoctorsClinicFilters,
    getNumberOfActiveDoctorsFilters,
    getNumberOfActiveDoctorsVisitFilters,
    getPhysiciansAdvancedSearch,
    getPhysiciansAdvancedSearchResults,
    getPhysiciansFilter,
    getPhysiciansFilterClinic,
    getPhysiciansFilterVisit,
    getPhysiciansForClinicAdvancedSearchResults,
    getPhysiciansForVisitAdvancedSearchResults,
    getPhysiciansSearch,
    getPhysiciansSearchFromClinics,
    getPhysiciansSearchFromVisits,
    getSpecInfoForGlobalLocationAll,
    getSpecInfoForGlobalLocationForClinic,
    getSpecInfoForGlobalLocationForVisit,
    getSpecialitiesForClinicInCurrentLocation,
    getSpecialitiesInCurrentLocationAllClinics,
    getSpecialitiesInCurrentLocationForVisit,
    getSpecialitysDictionary,
    getSpecialitysDictionaryForClinic,
    getSpecialitysDictionaryForVisit,
    getVisitDoctorsMap,
    isHasTimetable,
    isHasTimetableClinic,
    isHasTimetableVisit,
    physiciansPrefilterAges,
    physiciansPrefilterAgesClinic,
    physiciansPrefilterAgesVisit,
    physiciansPrefilterDates,
    physiciansPrefilterDatesClinic,
    physiciansPrefilterDatesVisit,
    physiciansPrefilterFeedbacks,
    physiciansPrefilterFeedbacksClinic,
    physiciansPrefilterFeedbacksVisit,
    physiciansPrefilterProfiles,
    physiciansPrefilterProfilesClinic,
    physiciansPrefilterProfilesVisit,
    physiciansPrefilterSpecialties,
    physiciansPrefilterSpecialtiesClinic,
    physiciansPrefilterSpecialtiesVisit,
    physiciansPrefilterSpecialty,
    physiciansPrefilterSpecialtyClinic,
    physiciansPrefilterSpecialtyId,
    physiciansPrefilterSpecialtyIdClinic,
    physiciansPrefilterSpecialtyIdVisit,
    physiciansPrefilterSpecialtyVisit,
    physiciansPrefilterTimes,
    physiciansPrefilterTimesClinic,
    physiciansPrefilterTimesVisit,
    physiciansPrefilterWorksAt,
    physiciansPrefilterWorksAtClinic,
    physiciansPrefilterWorksAtGroup,
    physiciansPrefilterWorksAtGroupClinic,
    physiciansPrefilterWorksAtGroupVisit,
    physiciansPrefilterWorksAtVisit,
    physiciansRecentSpecialtyRequests,
    physiciansRecentSpecialtyRequestsClinic,
    physiciansRecentSpecialtyRequestsVisit,
    getClinicFilterReset,
    getFiltredSpecialitiesInCurrentLocation,
    getHasFiltredSpecDataForLocation, getSpecialitiesError,
} from '../../selectors';
import { curry } from '../../utils';
import { SpecialtiesList } from '../../common/Specialties';
import { PageSpinner } from '../../common/PageSpinner';
import { SCHEME } from '../../scheme.js';
import firebaseService from '../../services/firebase';
import { DoctorsSearchResults } from './DoctorsSearch';
import actions from '../../actions';
import { useDoctorsDataExpirationHandler, getSpecialitiesPath } from '../../hooks/useDoctorsDataExpirationHandler';

const NS = 'doctors';

const onSearch = (scheme = SCHEME.PHYSICIANS_ADVANCED_SEARCH) => curry(putIntoStore, scheme);

const TheSearchBar = ({
    searchData, initialValue = '', onSearch: _onSearch, placeholder, style,
}) => (
    <SearchBar
        autoCorrect={false}
        initialValue={searchData || initialValue}
        searchAfter={2}
        onSearch={_onSearch}
        placeholder={placeholder}
        borderRadius={3}
        style={style}
    />
);

const DoctorsListHeader = ({
    source = getPhysiciansAdvancedSearch,
    searchScheme = SCHEME.PHYSICIANS_ADVANCED_SEARCH,
}) => {

    const searchData = useSelector(source);
    return (
        <TheSearchBar
            initialValue={''}
            searchData={searchData}
            onSearch={onSearch(searchScheme)}
            placeholder={Object.R('titles.doctorsSearchSpecialtyPlaceholder')}
        />
    );

};

export const doctorFilterParams = {
    MedCenterDetails: {
        name: 'MedCenterDetails',
        postfix: 'Clinic',
        hasFilter: getHasSpecialityFilterClinic,
        hasClinicFilter: getHasFilterByClinicClinic,
        scheme: SCHEME.PHYSICIANS_FILTER_FOR_CLINIC,
        sortScheme: SCHEME.PHYSICIANS_SORT_BY_CLINIC,
        searchTextScheme: SCHEME.PHYSICIANS_FROM_CLINICS_SEARCH,
        keyword: getActualPhysiciansClinicsAdvancedSearch,
        advancedSearch: getActualPhysiciansClinicsAdvancedSearch,
        advancedSearchResults: getPhysiciansForClinicAdvancedSearchResults,
        searchScheme: SCHEME.PHYSICIANS_CLINICS_ADVANCED_SEARCH,
        physiciansSearchScheme: SCHEME.PHYSICIANS_CLINICS_FROM_SEARCH,
        physicianSearch: getPhysiciansSearchFromClinics,
        doctorDataSource: actualSplitDoctorsBySpecialityForClinics, // actualSplitDoctorsForClinics,
        hasDataSource: getHasPhysiciansDataForClinic,
        filter: getPhysiciansFilterClinic,
        specializationForApi: 'specializationForApiForClinic',
        getFiltersAddInfo: getFiltersAdditionalInfoClinic,
        getLocationAndSpec: getLocationAndSpecClinic,
        hasTimetableSource: isHasTimetableClinic,
        numberOfActiveFilters: getNumberOfActiveDoctorsClinicFilters,
        doctorsWithActualCollections: actualSplitDoctorsBySpecialityForClinics, // getDoctorsWithActualCollectionsClinic,
        specialitysDictionary: getSpecialitysDictionaryForClinic,
        specialitysDictionaryAllClinics: getSpecialitiesForClinicInCurrentLocation,
        specialitysList: getSpecInfoForGlobalLocationForClinic,
        hasSpecialityDataForLocation: getHasSpecDataForLocation,
        // specialitysDictionaryAllClinics: getSpecialitysDictionaryForClinicAllClinics,
        doctorMap: getClinicsDoctorsMap,
        prefilter: {
            prefilter: SCHEME.PHYSICIANS_PREFILTER_CLINIC,
            dates: SCHEME.PHYSICIANS_PREFILTER_DATES_CLINIC,
            recentSpecRequest: SCHEME.PHYSICIANS_RECENT_SPECIALTY_REQUESTS_CLINIC,
            speciality: SCHEME.PHYSICIANS_PREFILTER_SPECIALTY_CLINIC,
            specialityId: SCHEME.PHYSICIANS_PREFILTER_SPECIALTY_ID_CLINIC,
            worksAt: SCHEME.PHYSICIANS_PREFILTER_WORKS_AT_CLINIC,
            worksAtGroup: SCHEME.PHYSICIANS_PREFILTER_WORKS_AT_GROUP_CLINIC,
            profiles: SCHEME.PHYSICIANS_PREFILTER_PROFILES_CLINIC,
            feedback: SCHEME.PHYSICIANS_PREFILTER_FEEDBACKS_CLINIC,
            times: SCHEME.PHYSICIANS_PREFILTER_TIMES_CLINIC,
            ages: SCHEME.PHYSICIANS_PREFILTER_AGES_CLINIC,
            getWorksAt: physiciansPrefilterWorksAtClinic,
            getTimes: physiciansPrefilterTimesClinic,
            getAges: physiciansPrefilterAgesClinic,
            getSpeciality: physiciansPrefilterSpecialtyClinic,
            getSpecialityId: physiciansPrefilterSpecialtyIdClinic,
            getSpecialities: physiciansPrefilterSpecialtiesClinic,
            getWorksAtGroup: physiciansPrefilterWorksAtGroupClinic,
            getProfile: physiciansPrefilterProfilesClinic,
            getRecentSpecRequest: physiciansRecentSpecialtyRequestsClinic,
            getFeedback: physiciansPrefilterFeedbacksClinic,
            getDates: physiciansPrefilterDatesClinic,
        },
    },
    Visit: {
        name: 'Visit',
        postfix: 'Visit',
        hasFilter: getHasSpecialityFilterVisit,
        hasClinicFilter: getHasFilterByClinicVisit,
        scheme: SCHEME.PHYSICIANS_FILTER_FOR_VISIT,
        sortScheme: SCHEME.PHYSICIANS_SORT_BY_VISIT,
        searchTextScheme: SCHEME.PHYSICIANS_FROM_VISITS_SEARCH,
        keyword: getActualPhysiciansVisitsAdvancedSearch,
        advancedSearch: getActualPhysiciansVisitsAdvancedSearch,
        advancedSearchResults: getPhysiciansForVisitAdvancedSearchResults,
        searchScheme: SCHEME.PHYSICIANS_VISITS_ADVANCED_SEARCH,
        physiciansSearchScheme: SCHEME.PHYSICIANS_VISITS_FROM_SEARCH,
        physicianSearch: getPhysiciansSearchFromVisits,
        doctorDataSource: actualSplitDoctorsBySpecialityForVisit,
        hasDataSource: getHasPhysiciansDataForVisit,
        filter: getPhysiciansFilterVisit,
        specializationForApi: 'specializationForApiForVisit',
        getFiltersAddInfo: getFiltersAdditionalInfoVisit,
        getLocationAndSpec: getLocationAndSpecVisit,
        hasTimetableSource: isHasTimetableVisit,
        numberOfActiveFilters: getNumberOfActiveDoctorsVisitFilters,
        doctorsWithActualCollections: actualSplitDoctorsBySpecialityForVisit, // getDoctorsWithActualCollectionsVisit,
        specialitysDictionary: getSpecialitysDictionaryForVisit,
        specialitysDictionaryAllClinics: getSpecialitiesInCurrentLocationAllClinics,
        specialitysList: getSpecInfoForGlobalLocationForVisit,
        hasSpecialityDataForLocation: getHasSpecDataForLocation,
        doctorMap: getVisitDoctorsMap,
        prefilter: {
            prefilter: SCHEME.PHYSICIANS_PREFILTER_VISIT,
            dates: SCHEME.PHYSICIANS_PREFILTER_DATES_VISIT,
            recentSpecRequest: SCHEME.PHYSICIANS_RECENT_SPECIALTY_REQUESTS_VISIT,
            speciality: SCHEME.PHYSICIANS_PREFILTER_SPECIALTY_VISIT,
            specialityId: SCHEME.PHYSICIANS_PREFILTER_SPECIALTY_ID_VISIT,
            worksAt: SCHEME.PHYSICIANS_PREFILTER_WORKS_AT_VISIT,
            worksAtGroup: SCHEME.PHYSICIANS_PREFILTER_WORKS_AT_GROUP_VISIT,
            profiles: SCHEME.PHYSICIANS_PREFILTER_PROFILES_VISIT,
            feedback: SCHEME.PHYSICIANS_PREFILTER_FEEDBACKS_VISIT,
            times: SCHEME.PHYSICIANS_PREFILTER_TIMES_VISIT,
            ages: SCHEME.PHYSICIANS_PREFILTER_AGES_VISIT,
            getWorksAt: physiciansPrefilterWorksAtVisit,
            getTimes: physiciansPrefilterTimesVisit,
            getAges: physiciansPrefilterAgesVisit,
            getSpeciality: physiciansPrefilterSpecialtyVisit,
            getSpecialityId: physiciansPrefilterSpecialtyIdVisit,
            getSpecialities: physiciansPrefilterSpecialtiesVisit,
            getWorksAtGroup: physiciansPrefilterWorksAtGroupVisit,
            getProfile: physiciansPrefilterProfilesVisit,
            getRecentSpecRequest: physiciansRecentSpecialtyRequestsVisit,
            getFeedback: physiciansPrefilterFeedbacksVisit,
            getDates: physiciansPrefilterDatesVisit,
        },
    },
    default: {
        name: 'default',
        postfix: '',
        hasFilter: getHasSpecialityFilter,
        hasClinicFilter: getHasFilterByClinic,
        scheme: SCHEME.PHYSICIANS_FILTER,
        sortScheme: SCHEME.PHYSICIANS_SORT_BY,
        searchTextScheme: SCHEME.PHYSICIANS_SEARCH,
        keyword: getActualPhysiciansAdvancedSearch,
        advancedSearch: getPhysiciansAdvancedSearch,
        advancedSearchResults: getPhysiciansAdvancedSearchResults,
        searchScheme: SCHEME.PHYSICIANS_ADVANCED_SEARCH,
        physiciansSearchScheme: SCHEME.PHYSICIANS_FROM_SERARCH,
        physicianSearch: getPhysiciansSearch,
        doctorDataSource: actualSplitDoctorsBySpeciatity,
        hasDataSource: getHasPhysiciansData,
        filter: getPhysiciansFilter,
        specializationForApi: 'specializationForApi',
        getFiltersAddInfo: getFiltersAdditionalInfo,
        getLocationAndSpec,
        hasTimetableSource: isHasTimetable,
        numberOfActiveFilters: getNumberOfActiveDoctorsFilters,
        doctorsWithActualCollections: actualSplitDoctorsBySpeciatity, // getDoctorsWithActualCollections,
        specialitysDictionary: getSpecialitysDictionary,
        specialitysDictionaryAllClinics: getSpecialitiesInCurrentLocationAllClinics,
        specialitysList: getSpecInfoForGlobalLocationAll,
        hasSpecialityDataForLocation: getHasSpecDataForLocation,
        doctorMap: getDoctorsMap,
        prefilter: {
            prefilter: SCHEME.PHYSICIANS_PREFILTER,
            dates: SCHEME.PHYSICIANS_PREFILTER_DATES,
            recentSpecRequest: SCHEME.PHYSICIANS_RECENT_SPECIALTY_REQUESTS,
            speciality: SCHEME.PHYSICIANS_PREFILTER_SPECIALTY,
            specialityId: SCHEME.PHYSICIANS_PREFILTER_SPECIALTY_ID,
            worksAt: SCHEME.PHYSICIANS_PREFILTER_WORKS_AT,
            profiles: SCHEME.PHYSICIANS_PREFILTER_PROFILES,
            feedback: SCHEME.PHYSICIANS_PREFILTER_FEEDBACKS,
            times: SCHEME.PHYSICIANS_PREFILTER_TIMES,
            ages: SCHEME.PHYSICIANS_PREFILTER_AGES,
            worksAtGroup: SCHEME.PHYSICIANS_PREFILTER_WORKS_AT_GROUP,
            getWorksAt: physiciansPrefilterWorksAt,
            getTimes: physiciansPrefilterTimes,
            getAges: physiciansPrefilterAges,
            getSpeciality: physiciansPrefilterSpecialty,
            getSpecialityId: physiciansPrefilterSpecialtyId,
            getSpecialities: physiciansPrefilterSpecialties,
            getWorksAtGroup: physiciansPrefilterWorksAtGroup,
            getProfile: physiciansPrefilterProfiles,
            getRecentSpecRequest: physiciansRecentSpecialtyRequests,
            getFeedback: physiciansPrefilterFeedbacks,
            getDates: physiciansPrefilterDates,
        },
    },
};

const resetSpecialtyFilter = (filters, from = 'default') => {

    const filterParams = doctorFilterParams[from] ?? doctorFilterParams.default;
    putIntoStore(filterParams.scheme, {
        ...filters,
        time: '0',
        dates: {},
        specialty: '',
    });
    putIntoStore(filterParams.physiciansSearchScheme, undefined);
    putIntoStore(filterParams.specializationForApi, undefined);
    // putIntoStore('specializationForApi', undefined);

};

const resetClinicFilter = (filters, from = 'default', resetWorksAt = false) => {

    const filterParams = doctorFilterParams[from] ?? doctorFilterParams.default;
    putIntoStore(filterParams.scheme, {
        ...filters,
        time: '0',
        dates: {},
        // specialty: '',
        worksAt: resetWorksAt ? [] : filters?.worksAt,
    });
    putIntoStore(filterParams.physiciansSearchScheme, undefined);
    putIntoStore(filterParams.specializationForApi, undefined);
    putIntoStore('clinicFilterReset', false);

    // putIntoStore('specializationForApi', undefined);

};

export const DoctorsListWithSpecialties = Page.register(({ params, navigation }) => {

    const isAuth = firebaseService.auth.currentUser;
    const locationCode = useSelector(getGlobalLocation);
    const isSpecLoading = useSelector(getIsSpecLoading);
    const filterParams = doctorFilterParams[params?.from] ?? doctorFilterParams.default;
    const hasSpecData = useSelector(filterParams?.hasSpecialityDataForLocation);
    const hasSpecialtyFilter = useSelector(filterParams?.hasFilter);
    const hasClinicFilter = useSelector(filterParams?.hasClinicFilter);
    const needToReset = useSelector(getClinicFilterReset);
    const isResetStarted = useRef(false);

    const isFromMedCenter = params?.mdInfo?.id;
    const filtredDictionary = useSelector(state => getFiltredSpecialitiesInCurrentLocation(state, params?.mdInfo?.id));
    const hasFiltredSpecData = useSelector(state => getHasFiltredSpecDataForLocation(state, params?.mdInfo?.id));
    const error = useSelector(getSpecialitiesError);

    const updateSpec = () => {

        if (hasClinicFilter && params?.mdInfo?.id) {

            actions.syncSpecialitiesInfo(locationCode, params?.mdInfo?.id);

        } else {

            actions.syncSpecialitiesInfo(locationCode);

        }

    };
    useEffect(() => {

        if (!hasSpecData || (isFromMedCenter && !hasFiltredSpecData)) {

            updateSpec();

        }

    }, [locationCode, hasSpecData, hasFiltredSpecData, params?.mdInfo?.id]);

    useDoctorsDataExpirationHandler(getSpecialitiesPath(locationCode, params?.mdInfo?.id), updateSpec);

    useFocusEffect(
        useCallback(() => {

            if (params?.keyword) {

                putIntoStore(SCHEME.PHYSICIANS_ADVANCED_SEARCH, params?.keyword);
                navigation.setParams({ keyword: '' });

            }

        }, []),
    );

    useFocusEffect(
        useCallback(() => {

            if (hasSpecialtyFilter) {

                setTimeout(() => {

                    const filters = getFromStoreDb(filterParams.scheme) || {};
                    resetSpecialtyFilter(filters, params?.from);

                }, 0);
                // firebaseService?.unsubscribeFromSplitCollectionsChanges();
                // firebaseService?.resetSplitCollections();

            }
            if (hasClinicFilter && !params?.mdInfo && isResetStarted.current === false) {

                isResetStarted.current = true;
                setTimeout(() => {

                    const filters = getFromStoreDb(filterParams.scheme) || {};
                    resetClinicFilter(filters, params?.from, needToReset);
                    setTimeout(() => {

                        isResetStarted.current = false;

                    }, 100);

                }, 0);

            }

        }, [hasSpecialtyFilter, hasClinicFilter, needToReset]),
    );

    const pureDictionary = useSelector(filterParams?.specialitysDictionaryAllClinics);
    const dictionary = isFromMedCenter ? filtredDictionary : pureDictionary;
    const keyword = useSelector(filterParams?.keyword);
    return (
        <Page name={NS} notification={error}>
            {isSpecLoading ? (
                <PageSpinner />
            ) : (
                <>
                    <DoctorsListHeader source={filterParams.advancedSearch} searchScheme={filterParams.searchScheme} />
                    {keyword?.length > 2 ? (
                        <DoctorsSearchResults
                            keyword={keyword}
                            searchResultSource={filterParams.advancedSearchResults}
                        />
                    ) : (
                        <>
                            <Section
                                title={Object.R('titles.specialtiesListTitle ')}
                                capitalize
                                style={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                }}
                            />
                            {dictionary?.length ? <SpecialtiesList data={dictionary} from={params?.from} mdInfo={params?.mdInfo} /> : null}
                        </>
                    )}
                </>
            )}
        </Page>
    );

});

export const DoctorsSpecialtiesSearch = ({ route: { params }, ...rest }) => {

    return (
        <DoctorsListWithSpecialties
            params={{
                ...params,
                ...rest,
            }}
        />
    );

};
