import { sectionStyle } from '../../Doctors/DoctorsSearch/style';
import {
    LoadingIndicator, React, Section, Text, Title, TouchableOpacity,
} from '../../../common';
import { visitInput as styles } from '../style';
import { DownArrowIcon } from './DownArrowIcon';
import { COLORS } from '../../../styles/colors';

export const ComboboxSelector = ({
    isLoading: loadingState,
    enabled,
    onPressHandler = () => {},
    title = 'title',
    value = '',
    isError = false,
}) => {

    return (
        <Section style={sectionStyle}>
            <Text style={styles.inputHeader}>{Object.R(title)}</Text>
            {loadingState ? (
                <LoadingIndicator indicatorSize={30} />
            ) : (
                <TouchableOpacity disabled={!enabled} onPress={onPressHandler} style={[styles.containerInput, isError ? {
                    borderWidth: 1,
                    borderColor: COLORS.ERROR,
                } : {}]}>
                    <Title.Bigger style={styles.inputText}>{value}</Title.Bigger>
                    {!enabled ? null : <DownArrowIcon onPress={onPressHandler} />}
                </TouchableOpacity>
            )}
        </Section>
    );

};
