import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import actions from '../actions';
import { getDoctorsOnlyWithPartnershipAndSums } from '../selectors';
import { getDoctorsDescriptions } from '../selectors/services';
import { useDoctorApi } from './useDoctorApi';

export const useDoctorDescriptions = (doctorId, doctorInfo) => {

    const doctorsDescriptions = useSelector(getDoctorsDescriptions);
    const doctorData = useSelector(getDoctorsOnlyWithPartnershipAndSums);
    const [list, setList] = useState([]);
    const { doctor: fbDoctor } = useDoctorApi({
        id: doctorId,
        nullWithoutAssignment: false,
        withAssignments: true,
    });

    const getAssignments = doctor => doctor?.worksAt?.map(({ assignmentId }) => assignmentId);

    useEffect(() => {

        let doctor = doctorInfo ?? doctorData?.find?.(({ _id }) => _id === doctorId);
        if (!doctor) {

            if (fbDoctor) {

                doctor = fbDoctor;

            }

        }
        const assignment = getAssignments(doctor);
        actions.fetchDoctorDescriptionsByAssignments(assignment, doctorId, false);

    }, [doctorId, doctorData, doctorInfo, fbDoctor]);

    useEffect(() => {

        setList(doctorsDescriptions[doctorId]);

    }, [doctorsDescriptions, doctorId]);

    const addFields = {};
    if (!isNaN(fbDoctor?.experience) && !fbDoctor?.experienceYears) {

        try {

            const year = Number(fbDoctor.experience) ?? 0;
            addFields.experienceYears = new Date().getFullYear() - year;

        } catch (e) {

            console.log('Error experience', e);

        }

    }
    if (!fbDoctor?.profile && doctorInfo?.profile) {

        try {

            addFields.profile = doctorInfo.profile;

        } catch (e) {

            console.log('Error experience', e);

        }

    }

    return {
        descriptions: list,
        doctorDetails: {
            ...(doctorInfo ?? {}),
            ...(fbDoctor ?? {}),
            ...addFields,
        },
    };

};
