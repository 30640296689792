// noinspection UnnecessaryLocalVariableJS

import { Platform } from 'react-native';
import { sortBy } from '../utils';
import api from './api';
import firebase from './firebase';
import { getFinalMessage } from './http';
import { user } from './user';

/**
 * Tests
 */
export default {
    async fetchTests() {
        const u = await user.getUserInfo();
        const tests = firebase.isAuthenticated() && u?.userId ? await api.getTests() : [];
        return tests ? sortBy(tests, '-date') : [];
    },
    // eslint-disable-next-line max-params
    async getTestResult(id, provider, providerCode, acode, recordLink, profileId, customerId, policyId) {
        const result = await api.getTestResult(
            id,
            provider,
            providerCode,
            acode,
            recordLink,
            profileId,
            customerId,
            policyId,
        );
        if (result.respInfo?.status === 200 || Platform.OS !== 'web') {
            try {
                const parsedData = JSON.parse(result.data);
                return {
                    error: {
                        ...parsedData,
                        defaultMessage: getFinalMessage(parsedData),
                        severity: parsedData?.severity,
                    },
                };
            } catch (e) {
                return {
                    data: result.data,
                    contentType: result?.respInfo?.headers['Content-Type'],
                };
            }
        } else {
            try {
                if (Platform.OS === 'web') {
                    const resp = await result.data?.text();
                    const parsedResp = resp ? JSON.parse(resp) : null;
                    return {
                        error: {
                            status: result.respInfo.status,
                            code: result.respInfo.code,
                            defaultMessage: getFinalMessage(parsedResp),
                            severity: parsedResp?.severity,
                        },
                    };
                }
                const parsedData = JSON.parse(result.data);
                return {
                    error: {
                        status: result.respInfo.status,
                        code: result.respInfo.code,
                        defaultMessage: getFinalMessage(parsedData),
                        severity: parsedData?.severity,
                    },
                };
            } catch (e) {
                return {
                    error: {
                        status: result.respInfo.status,
                        code: result.respInfo.code,
                    },
                };
            }
        }
    },
    async syncTests() {
        const result = await api.syncTests();
        return result;
    },
};
