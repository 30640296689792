/* eslint-disable no-underscore-dangle */
import EStyleSheet from 'react-native-extended-stylesheet';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import {
    BigButton, Button, ButtonsGroup, Card, ErrorStub, Field, LoadingIndicator, Page, Platform, React, showNotification, Switch, Text, TextArea, VBox, View,
} from '../../../common';
import { getStatusesOfHappenedVisits, getStatusesOfHappenedVisitsDoctor, isProfileEmpty as isProfileEmptySelector } from '../../../selectors';
import { DoctorBanner } from '../../Doctors/snippets';
import firebaseService from '../../../services/firebase';
import { fnId } from '../../../utils';
import { COLORS } from '../../../styles/colors';
import { listItem as styles } from '../style';
import actions from '../../../actions';
import { WithKeyboard } from '../../../utils/withKeyboard';
import { useController } from '../../Doctors/DoctorDetails';
import { useFeedbacks } from '../../../hooks/useFeedbacks';
import { VOTES } from './AddDcotorFeedback';
import { DoctorFeedbackChart, MyFeedback, SetFeedback } from './snippets';
import { DoctorsFeedbacksPage } from './DoctorFeedbaksPage';
import { dbGettr } from '../../../selectors/utils';

/**
 * Doctor Feedbacks Page (☆☆☆☆☆).
 */

export const useReduxStoreCollection = (profileId, visits) => {

    useFocusEffect(
        useCallback(
            () => (!(profileId && !visits)
                ? fnId
                : firebaseService.subscribeOnCollectionChanges(`profile/${profileId}/pastVisits`, (snapshot) => {

                    // putIntoStore(SCHEME.PAST_VISITS, firebaseService.snapshotToData(snapshot));
                    actions.setPastVisits(firebaseService.snapshotToData(snapshot));

                })),
            [profileId, visits],
        ),
    );

};

export const NoVisitedForFeedback = ({
    text,
    isAuthenticated = false,
    isAdult: isAdultUser = false,
    visible = true,
}) => {

    if (!visible) {

        return null;

    }

    const style = EStyleSheet.create({
        header: {
            color: COLORS.STANDARD_GRAY,
            fontSize: 16,
            fontWeight: '600',
            lineHeight: 17,
            fontFamily: '$sourceSansPropFontFamily_semibold',
        },
        normal: {
            fontSize: 14,
            lineHeight: 17,
            fontWeight: '400',
            color: '#979797',
            paddingTop: 16,
        },
    });
    return (
        <Card
            containerStyle={{
                elevation: 0,
                margin: 0,
                paddingHorizontal: 16,
                paddingVertical: 24,
                marginTop: 8,
                borderRadius: 0,
                borderColor: COLORS.BORDER_GRAY,
            }}>
            <Text style={style.header}>{Object.R('titles.YouRecommendation')}</Text>
            <Text style={style.normal}>
                {Object.R(
                    isAuthenticated && isAdultUser ? 'titles.AddRateAfterVisit' : 'titles.notAuthorizedForMyFeedbacks',
                )}
            </Text>
            {!!text && <Text>{text}</Text>}
        </Card>
    );

};

export const DoctorFeedbacks = Page.register(({ doctorInfo: { _id: forItemId }, doctorInfo }) => {

    const d = useFeedbacks(forItemId);
    const c = useController(forItemId, false);
    const clinics = useSelector(dbGettr('clinics.data'));
    const getDoctor = useCallback(() => {

        const d1 = typeof doctorInfo === 'object' ? doctorInfo : {};
        const r1 = { ...(c?.doctor ?? c?.doctorInfo ?? c?.doctor ?? {}) };
        const res = Object.assign(d1, r1);
        if (!res?.worksAt && res?.assignments) {

            res.worksAt = res.assignments.map(({ branchId }) => clinics?.[branchId]);

        }
        return res;

    }, [c, doctorInfo, clinics]);

    const getFeedback = useCallback(() => {

        const hasRecommend = (obj) => {

            try {

                const res = {
                    recommendCount: obj.recommendCount,
                    notRecommendCount: obj.notRecommendCount,
                    neutralCount: obj?.neutralCount,
                    textFeedbackCount: obj?.textFeedbackCount,
                };
                const sumRes = res.recommendCount + res.neutralCount + res.notRecommendCount + res.textFeedbackCount;
                if (Number.isInteger(sumRes) && sumRes !== 0) {

                    return res;

                }

            } catch (e) {
                //
            }
            // eslint-disable-next-line consistent-return
            return undefined;

        };
        return (
            hasRecommend(c, 'c') ??
            hasRecommend(doctorInfo, 'doctorInfo') ??
            hasRecommend(c?.doctorInfo, 'c.doctorInfo')
        );

    }, [c, doctorInfo]);
    return (
        <DoctorsFeedbacksPage forItemId={forItemId} doctorInfo={c?.doctorInfo}>
            <Card
                containerStyle={{
                    padding: 0,
                    margin: 0,
                    paddingBottom: 24,
                    paddingTop: 12,
                    paddingHorizontal: 0,
                    borderRadius: 0,
                    elevation: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                    borderTopWidth: 0,
                    borderColor: COLORS.BORDER_GRAY,
                }}>
                <VBox flex>
                    {!!getDoctor() && (
                        <DoctorBanner doctor={getDoctor()} hideFeedback={false} feedback={getFeedback()} />
                    )}
                </VBox>
            </Card>

            {d?.canGiveFeedback ? <DoctorFeedbackForm doctorId={forItemId} doctorData={getDoctor()} /> : null}
            {/* {d?.canGiveFeedback ? <DoctorFeedbackForm doctorId={forItemId} doctorData={c?.doctorInfo} /> : null} */}
            <Card
                containerStyle={{
                    marginTop: 8,
                    borderRadius: 0,
                    marginHorizontal: 0,
                    elevation: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                    borderColor: COLORS.BORDER_GRAY,
                }}>
                <VBox flex>
                    <DoctorFeedbackChart
                        neutral={getFeedback()?.neutralCount}
                        negative={getFeedback()?.notRecommendCount}
                        positive={getFeedback()?.recommendCount}
                    />
                </VBox>
            </Card>

            {d?.myFeedbacks?.length ? (
                <Card
                    containerStyle={{
                        marginTop: 8,
                        marginHorizontal: 0,
                        borderRadius: 0,
                        elevation: 0,
                        borderLeftWidth: 0,
                        borderRightWidth: 0,
                        paddingBottom: 0,
                        marginBottom: 0,
                        borderColor: COLORS.BORDER_GRAY,
                    }}>
                    <VBox flex>
                        <MyFeedback item={d?.myFeedbacks} interaction />
                    </VBox>
                </Card>
            ) : (
                <>
                    <NoVisitedForFeedback
                        visible={!d?.canGiveFeedback}
                        isAdult={d?.isAdult}
                        isAuthenticated={d?.isAuthenticated}
                    />
                </>
            )}
        </DoctorsFeedbacksPage>
    );

});

export const PostDoctorFeedback = ({
    initialRate,
    initialText,
    initialPublishName = true,
    onSubmit,
    disabled,
    cancelButton = false,
    isProgress,
    inline = true,
    fromNotification = false,
}) => {

    const [rate, setRate] = useState(initialRate);
    const [text, setText] = useState(initialText);
    const [publishName, setPublishName] = useState(initialPublishName);
    const [addToFavorites, setAddToFavorites] = useState(false);
    const [isFormChanged, setIsFormChanged] = useState(false);
    const [isDisabled, setIsDisabled] = useState(disabled);
    const [isPosting, setIsPosting] = useState(isProgress);
    // useFavoritesFetch();
    useEffect(() => {

        setRate(initialRate);
        setText(initialText);
        setPublishName(initialPublishName);

    }, [initialText, initialPublishName, initialRate]);

    useEffect(() => setIsPosting(isProgress), [isProgress]);

    const checkFormChange = () => setIsFormChanged(rate !== initialRate || text !== initialText || publishName !== initialPublishName);

    useEffect(() => {

        checkFormChange();

    }, [rate, text, publishName, initialPublishName, initialRate, initialText]);

    useEffect(() => {

        if (disabled !== isDisabled) {

            setIsDisabled(disabled);

        }

    }, [disabled]);

    const style = EStyleSheet.create({
        header: {
            color: COLORS.STANDARD_GRAY,
            lineHeight: 17,
            fontWeight: '400',
            fontFamily: '$sourceSansPropFontFamily',
            fontSize: 16,
        },
        text: {
            fontSize: 14,
            fontWeight: '400',
            color: '#9C9C9C',
            fontFamily: '$sourceSansPropFontFamily',
        },
    });

    const submitHandler = () => {


        onSubmit &&
            onSubmit({
                rate,
                text,
                anonymous: !publishName,
                addToFavorites: addToFavorites && rate === 1,
            });

    };

    const cancelHandler = () => {

        setRate(initialRate);
        setText(initialText);
        setPublishName(initialPublishName);

    };

    // noinspection PointlessBooleanExpressionJS
    return (
        <WithKeyboard enabled={!inline}>
            {isPosting ? (
                <LoadingIndicator />
            ) : (
                <>
                    <View
                        style={{
                            padding: 13,
                            paddingHorizontal: 16,
                            paddingBottom: 3,
                        }}>
                        <SetFeedback
                            onChange={(v) => {

                                setRate(v);

                            }}
                            selected={rate}
                            disabled={isDisabled}
                        />
                    </View>
                    {rate === 1 && fromNotification && (
                        <>
                            <View
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    paddingVertical: Platform.OS === 'ios' ? 8 : 10,
                                    paddingHorizontal: 16,
                                    borderWidth: 1,
                                    borderColor: COLORS.LIGHT_GRAY,
                                    backgroundColor: 'white',
                                    borderRadius: 3,
                                }}>
                                <Text
                                    style={{
                                        fontSize: 16,
                                        fontWeight: '400',
                                        color: COLORS.STANDARD_GRAY,
                                    }}>
                                    {Object.R('feedback.addToFavorites')}
                                </Text>

                                <Switch
                                    onValueChange={(v) => {

                                        setAddToFavorites(v);

                                    }}
                                    value={addToFavorites}
                                    accessibilityLabel="switch:doctor-feedbacks"
                                    style={{ transform: Platform.OS === 'ios' ? [{ scaleX: 0.8 }, { scaleY: 0.8 }] : [] }}
                                    activeThumbColor="#4770C0"
                                    activeTrackColor="#9FB9FF"
                                    onTintColor={Platform.OS !== 'web' ? '#6987D6' : '#8FB8FF'}
                                    thumbTintColor="#5C85DD"
                                    trackColor={{
                                        false: '#CFCFCF',
                                        true: Platform.OS === 'ios' ? '#5C85DD' : '#8FB8FF',
                                    }}
                                    disabled={isDisabled}
                                />
                            </View>
                        </>
                    )}

                    {rate !== undefined && Math.abs(Number(rate)) < 2 && (
                        <View
                            style={{
                                paddingHorizontal: 13,
                                paddingBottom: 25,
                                paddingTop: 10,
                            }}>
                            <Field ns="title-text">
                                <Text style={{ paddingBottom: 8 }}>
                                    <Text style={style.header}>{Object.R('titles.feedbackDescriptionBigText')}</Text>
                                    {/* <Title bold id="titles.feedbackDescriptionBigText" style={{ color: COLORS.STANDARD_GRAY }} /> */}
                                    <Text style={style.text}> {Object.R('titles.feedbackDescriptionSmallText')}</Text>
                                </Text>
                                <TextArea
                                    onChange={(t) => {

                                        let str = t;
                                        if (t.length > 2048) {

                                            str = str.slice(0, 2048);

                                        }
                                        setText(str);

                                    }}
                                    value={text}
                                    placeholder="feedback.physiciansInputPlaceholder"
                                    style={[
                                        {
                                            ...styles.feedbackInput,
                                            borderColor: COLORS.LIGHT_GRAY,
                                        },
                                        Platform.OS === 'web' ? {
                                            outline: 'none',
                                            outlineWidth: 0,
                                        } : {},
                                    ]}
                                    disabled={isDisabled}
                                />
                            </Field>
                            <View
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    paddingVertical: Platform.OS === 'ios' ? 8 : 10,
                                    paddingHorizontal: 16,
                                    borderWidth: 1,
                                    borderColor: COLORS.LIGHT_GRAY,
                                    backgroundColor: 'white',
                                    borderRadius: 3,
                                }}>
                                <Text
                                    style={{
                                        fontSize: 16,
                                        fontWeight: '400',
                                        color: COLORS.STANDARD_GRAY,
                                    }}>
                                    {Object.R('feedback.publicName')}
                                </Text>
                                <Switch
                                    onValueChange={(v) => {

                                        setPublishName(v);
                                        setIsFormChanged(v !== initialPublishName);

                                    }}
                                    value={publishName}
                                    accessibilityLabel="switch:doctor-feedbacks"
                                    style={{ transform: Platform.OS === 'ios' ? [{ scaleX: 0.8 }, { scaleY: 0.8 }] : [] }}
                                    activeThumbColor="#4770C0"
                                    activeTrackColor="#9FB9FF"
                                    onTintColor={Platform.OS !== 'web' ? '#6987D6' : '#8FB8FF'}
                                    thumbTintColor="#5C85DD"
                                    trackColor={{
                                        false: '#CFCFCF',
                                        true: Platform.OS === 'ios' ? '#5C85DD' : '#8FB8FF',
                                    }}
                                    disabled={isDisabled}
                                />
                            </View>
                            {cancelButton ? (
                                <ButtonsGroup style={styles.confirmationButtons}>
                                    <Button
                                        titleStyles={{ color: '#555' }}
                                        transparent
                                        action={cancelHandler}
                                        title="buttons.cancel"
                                    />
                                    <Button disabled={isDisabled} primary action={submitHandler} title="buttons.save" />
                                </ButtonsGroup>
                            ) : (
                                <BigButton
                                    disabled={Number(rate) > 1 || rate === undefined || !isFormChanged}
                                    busy={isDisabled}
                                    action={submitHandler}
                                    title="buttons.save"
                                />
                            )}
                        </View>
                    )}
                </>
            )}
        </WithKeyboard>
    );

};

const containerStyle = {
    padding: 0,
    margin: 0,
    elevation: 0,
    marginTop: 8,
    borderWidth: 0,
};

const DoctorFeedback = ({
    submitHandler, cancelButton, doctorId, data, posting,
}) => {

    return (
        <View container={containerStyle}>
            {data?.isLoading ? (
                <LoadingIndicator />
            ) : (
                <PostDoctorFeedback
                    onSubmit={submitHandler}
                    disabled={data?.isLoading}
                    cancelButton={cancelButton}
                    isProgress={posting || data?.isLoading}
                    initialText={data?.myFeedbacks?.[0]?.feedbackText ?? ''}
                    initialRate={data?.myFeedbacks?.[0]?.vote}
                    initialPublishName={!data?.myFeedbacks?.[0]?.anonymous}
                    id={doctorId}
                />
            )}
        </View>
    );

};

const WithCard = ({ withCart: wc, children }) => (wc ? (
    <Card
        containerStyle={{
            marginTop: 8,
            borderRadius: 0,
            marginHorizontal: 0,
            paddingHorizontal: 0,
            marginVertical: 0,
            paddingVertical: 0,
            elevation: 0,
            borderLeftWidth: 0,
            borderRightWidth: 0,
            borderColor: '#e0e0e0',
        }}>
        {children}
    </Card>
) : (
    children
));

export const DoctorFeedbackForm = ({
    doctorData,
    onlyVisited,
    withCard = false,
    cancelButton = false,
    // fetchFeedbacks = false,
    doctorPage = false,
    doctorId,
}) => {

    const isProfileEmpty = useSelector(isProfileEmptySelector);
    const [posting, setPosting] = useState(false);
    const statuses = useSelector(doctorPage ? getStatusesOfHappenedVisitsDoctor : getStatusesOfHappenedVisits);
    const data = useFeedbacks(doctorId, false);
    const [errorSamePatient, setErrorSamePatient] = useState(false);

    const submitHandler = async ({ rate, text, anonymous }) => {

        setPosting(true);
        const _id = data?.myFeedbacks?.[0]?._id;
        const params = {
            forItemKind: 'physicians',
            forItemId: doctorData.id || doctorId,
            feedbackText: text?.trim() ?? '',
            anonymous,
            vote: VOTES[rate ?? 0],
        };
        if (_id) {

            params._id = _id;

        }
        await actions.modifyFeedback(
            params,
            (error) => {

                setPosting(false);
                if (error?.code === 'FEEDBACK_FROM_SAME_PATIENT') {

                    setErrorSamePatient(true);

                }
                if (error) {

                    throw new Error(error?.message ?? 'Error');

                }
                showNotification('titles.comment_has_been_added');
                // actions.fetchCompleted();

            },
            true,
        );
        // }, fetchFeedbacks);
        // await actions.fetchVotes();

    };

    const {
        visits, myVotes, canGiveFeedback, myFeedbacks,
    } = data;

    const isShowingForm = useCallback(() => {

        if (errorSamePatient) {

            return false;

        }
        const visitsCount = visits?.filter?.(({ status }) => statuses?.includes(status))?.length;
        if (onlyVisited) {

            if (!visitsCount) {

                return false;

            }

        }
        if (myVotes?.[doctorId] || myFeedbacks?.length) {

            return !!(myFeedbacks?.length && myVotes?.[doctorId] === 'NONE');

        }
        if (isProfileEmpty || !canGiveFeedback) {

            return false;

        }
        return doctorData;

    }, [myFeedbacks, myVotes, isProfileEmpty, doctorData, canGiveFeedback, visits, statuses, errorSamePatient]);

    return isShowingForm() ? (

        <WithCard withCart={withCard}>
            <DoctorFeedback
                submitHandler={submitHandler}
                cancelButton={cancelButton}
                doctorId={doctorData?._id}
                data={data}
                posting={posting}
            />
        </WithCard>
    ) : (
        errorSamePatient && (
            <ErrorStub
                error={{ key: 'same_patient_feedback' }}
                support
                getSubtitle={() => Object.R('titles.feedbackFromSamePatient')}
                noSettings
            />
        )
    );

};
