import {
    Button, ButtonsGroup, Modal, Page, React, Title, View,
} from '../../common';
import api from '../../services/api';
import userService from '../../services/user';
import { getShowNewUserAgreementModal, getUserInfo, getUserInfoFormData } from '../../selectors';
import { AppHeader, InitialsAvatar } from '../../combo';
import { getSupportBody } from '../../utils/supportInfo';
import { getFinalMessage } from '../../services/http';
import { openURL } from '../../services/device';
import { LOCAL_STORE_RELATIVES, saveRelativeToStorage } from '../../services/user.utils';
import actions from '../../actions';
import { EditPage } from './EditPage';
import { relativeInfo } from './snippets'; // {
// {
//     "fullNameRu": "Еремин Сергей Владимирович",
//     "birthday": "1973-09-20",
//     "sex": "MALE",
//     "emails": [{
//         "address": "siarhei_yaromin@epam.com"
//     }]
// }

export const EditAccount = Page.register(
    EditPage(
        // eslint-disable-next-line max-params
        (fromScreen, navigation, test1, setError, showNewUserAgreementModal, nav) => async ({
            name, date: birthday, email, sex,
        }) => {

            const userMails = await userService.getUserEmailsUpdated(email, 'PRIMARY');
            const checkError = (e) => {

                if (e?.error) {

                    setError(e.error);

                } else {

                    switch (fromScreen) {

                        case 'Account':
                            navigation.goBack(null);
                            break;
                        case 'DoctorRecordDatePick':
                            navigation.replace('EnrollVisit', nav);
                            break;
                        default:
                            navigation.replace('EditResult');

                    }
                    // fromScreen === 'Account' ? navigation.goBack(null) : navigation.replace('EditResult');

                }

            };

            if (showNewUserAgreementModal) {

                api.updateUserInfo({
                    fullNameRu: name,
                    birthday,
                    sex,
                    consentReceived: true,
                    emails: userMails.map(({ address, usage }) => ({
                        address,
                        usage,
                    })),
                }).then(checkError);

            } else {

                api.updateUserInfo({
                    fullNameRu: name,
                    birthday,
                    sex,
                    emails: userMails.map(({ address, usage }) => ({
                        address,
                        usage,
                    })),
                }).then(checkError);

            }

        },
        'editUserAccount',
    ),
    {
        data: { from: getUserInfoFormData },
        userData: { from: getUserInfo },
        showNewUserAgreementModal: { from: getShowNewUserAgreementModal },
    },
    () => ({ header: () => <AppHeader /> }),
);
const EditRelativeOnSend =
    (_, navigation, isChildEdit, __, needSendConsent, ___, user) => async ({
        name, date: birthday, sex, profileId,
    }) => {

        const bd = user?.relatives?.find(r => r.profileId === profileId)?.birthday;
        navigation.goBack(null);
        try {

            if (needSendConsent) {

                await api.updateUserInfo({ consentReceived: true });

            }
            let person;
            if (isChildEdit) {

                person = await api.updateChild(
                    {
                        _id: profileId,
                        fullNameRu: name,
                        birthday,
                        sex,
                        consentReceived: true,
                    },
                    bd !== birthday,
                );
                // actions.reloadUserInfo(Object.handleNewUserItemsFound, actions.updatePrefs, actions.updateSelects);

            } else {

                person = await api.addChild({
                    fullNameRu: name,
                    birthday,
                    sex,
                    consentReceived: true,
                });

            }
            const savedProfile = person?.family?.find(
                ({ profile, relKind }) => profile?.fullNameRu === name && profile?.birthday === birthday && relKind === 'CHILD',
            );
            await saveRelativeToStorage({
                name,
                birthday,
                sex,
                profileId: savedProfile?.profileId ?? profileId,
                hashKey: user?.hashKey,
                person,
            });
            actions.reloadUserInfo(Object.handleNewUserItemsFound, actions.updatePrefs, actions.updateSelects);

        } catch (error) {

            if (error.code === 'PERSON_EXISTS') {

                Page.showModal(
                    <Modal hideClose>
                        <View
                            style={{
                                paddingHorizontal: 16,
                                paddingBottom: 16,
                            }}>
                            <Title bold centered numberOfLines={null} id="titles.childAlreadyExists" />
                            <Title style={{ marginTop: 16 }} numberOfLines={null} id="titles.addSimilarChildConfirm" />
                            <View
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginTop: 24,
                                }}>
                                <InitialsAvatar style={{ marginRight: 12 }} name={name} index={0} />
                                {relativeInfo({
                                    name,
                                    birthday,
                                    relation: Object.R('rel.CHILD'),
                                })}
                            </View>
                        </View>
                        <ButtonsGroup>
                            <Button
                                ns="cancel"
                                title="button.cancelButton"
                                titleStyles={{
                                    fontFamily: 'SourceSansPro-Semibold',
                                    color: '#555555',
                                }}
                                transparent
                                action={Page.closeModal}
                            />
                            <Button
                                ns="support"
                                title="button.support"
                                primary
                                action={() => {

                                    Page.closeModal();
                                    openURL(`mailto:${Object.R('supportEmail')}${getSupportBody()}`);

                                }}
                            />
                        </ButtonsGroup>
                    </Modal>,
                );

            }

        }

    };
const EditAdultRelativeOnSend =
    (_, navigation, isRelativeEdit, setError, needSendConsent, __, user1) => async ({
        name, date: birthday, sex, profileId, phones, relKind, user,
    }) => {

        const bd = user1?.relatives?.find(r => r.profileId === profileId)?.birthday;
        try {

            let person;
            if (needSendConsent) {

                await api.updateUserInfo({ consentReceived: true });

            }
            if (isRelativeEdit) {

                navigation.goBack(null);
                person = await api.updateRelative(
                    {
                        _id: profileId,
                        phones: [{ number: phones }],
                        fullNameRu: name,
                        birthday,
                        sex,
                    },
                    bd && bd !== birthday,
                );
                !!LOCAL_STORE_RELATIVES?.includes(relKind) &&
                    saveRelativeToStorage({
                        name,
                        birthday,
                        profileId,
                        phones: [{ number: phones }],
                        sex,
                        hashKey: user?.hashKey,
                        person: user,
                    });
                actions.reloadUserInfo(Object.handleNewUserItemsFound, actions.updatePrefs, actions.updateSelects);

            } else {

                actions.setFormValue('relativeDataToAdd', {
                    name,
                    birthday,
                    profileId: '',
                    sex,
                    phones: [{ number: phones }],
                    hashKey: user?.hashKey,
                    person: user,
                });
                person = await api.addRelative({
                    relKind,
                    phones: [{ number: phones }],
                    fullNameRu: name,
                    birthday,
                    sex,
                });

            }
            const savedProfile = person?.family?.find(
                ({ profile, relKind: rk }) => profile?.fullNameRu === name && profile?.birthday === birthday && relKind === rk,
            );
            if (LOCAL_STORE_RELATIVES.includes(relKind)) {

                await saveRelativeToStorage({
                    name,
                    birthday,
                    sex,
                    profileId: savedProfile?.profileId,
                    hashKey: user?.hashKey,
                    person,
                    relKind,
                });

            }

        } catch (error) {

            if (error.code === 'PERSON_EXISTS') {

                Page.showModal(
                    <Modal hideClose>
                        <View
                            style={{
                                paddingHorizontal: 16,
                                paddingBottom: 16,
                            }}>
                            <Title bold centered numberOfLines={null} id="titles.childAlreadyExists" />
                            <Title style={{ marginTop: 16 }} numberOfLines={null} id="titles.addSimilarChildConfirm" />
                            <View
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginTop: 24,
                                }}>
                                <InitialsAvatar style={{ marginRight: 12 }} name={name} index={0} />
                                {relativeInfo({
                                    name,
                                    birthday,
                                    relation: Object.R('rel.CHILD'),
                                })}
                            </View>
                        </View>
                        <ButtonsGroup>
                            <Button
                                ns="cancel"
                                title="button.cancelButton"
                                titleStyles={{
                                    fontFamily: 'SourceSansPro-Semibold',
                                    color: '#555555',
                                }}
                                transparent
                                action={Page.closeModal}
                            />
                            <Button
                                ns="support"
                                title="button.support"
                                primary
                                action={() => {

                                    Page.closeModal();
                                    openURL(`mailto:${Object.R('supportEmail')}${getSupportBody()}`);

                                }}
                            />
                        </ButtonsGroup>
                    </Modal>,
                );

            } else if (error.code === 'NEEDS_CONFIRMATION') {

                if (error?.body?.attributes?.objectId) {

                    const person = error?.body;
                    const savedProfile = person?.attributes?.objectId;
                    if (LOCAL_STORE_RELATIVES.includes(relKind)) {

                        await saveRelativeToStorage({
                            name,
                            birthday,
                            sex,
                            profileId: savedProfile,
                            hashKey: user?.hashKey,
                            person,
                            relKind,
                        });

                    }

                    navigation.navigate('RelativeSmsSend', {
                        relativePhoneNumber: phones,
                        objectId: error?.body?.attributes?.objectId,
                    });

                }

            } else if (error.code === 'SPOUSE_EXISTS') {

                Page.showModal(
                    <Modal>
                        <View
                            style={{
                                paddingHorizontal: 16,
                                paddingBottom: 16,
                            }}>
                            <Title bold centered numberOfLines={null} id="titles.SpouseAlreadyExists" />
                            <Title
                                style={{ marginTop: 16 }}
                                numberOfLines={null}
                                id="titles.SpouseAlreadyExistsDescription"
                            />
                        </View>
                        <ButtonsGroup>
                            <Button
                                ns="cancel"
                                title="button.cancelButton"
                                titleStyles={{
                                    fontFamily: 'SourceSansPro-Semibold',
                                    color: '#555555',
                                }}
                                transparent
                                action={Page.closeModal}
                            />
                            <Button
                                ns="support"
                                title="button.support"
                                primary
                                action={() => {

                                    Page.closeModal();
                                    openURL(`mailto:${Object.R('supportEmail')}${getSupportBody()}`);

                                }}
                            />
                        </ButtonsGroup>
                    </Modal>,
                );

            } else if (error.code === 'VALIDATION_ERROR') {


                setError &&
                    setError({
                        ...error,
                        message: error.body?.detail,
                    });

            } else {


                setError &&
                    setError({
                        ...error,
                        message: getFinalMessage(error.body),
                    });

            }

        }

    };
export const EditRelative = Page.register(
    EditPage(EditRelativeOnSend, 'addChild', 'hints.editUserPD', 'titles.child_account'),
    { showNewUserAgreementModal: { from: getShowNewUserAgreementModal } },
);
export const EditAdultRelative = Page.register(
    EditPage(
        EditAdultRelativeOnSend,
        'addRelativeWithOtherInt',
        'hints.edit_relative_profile',
        'titles.relative_account',
    ),
    { showNewUserAgreementModal: { from: getShowNewUserAgreementModal } },
);
