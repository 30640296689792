/* eslint-disable no-underscore-dangle */
import { createSelector } from 'reselect';
import { sortByNational } from '../utils';
import { allDoctors, getAllSpecialitiesExcludeHidden } from './doctors';
import { getClinics } from './medicians';

export const getDoctorsList = createSelector([allDoctors], (data) => {
    const doctors =
        Object.values(data ?? {})
            .filter?.(({ hidden }) => hidden !== true)
            .map?.(item => ({
                name: item?.fullName,
                id: item?._id,
                specialization: item?.specialization,
                worksAt: item?.worksAt,
            })) ?? [];
    return sortByNational(doctors, 'name');
});

export const getClinicsList = createSelector([getClinics], clinicsData => sortByNational(
    Object.values(clinicsData ?? {})
        .filter?.(({ hidden }) => hidden !== true)
        ?.map?.(item => ({
            name: item?.name,
            id: item?._id,
            address: item?.address,
        })) ?? [],
    'name',
));

export const getSpecialitiesList = createSelector([getAllSpecialitiesExcludeHidden], specializationsData => sortByNational(
    specializationsData?.map?.(item => ({
        name: item?.name,
        id: item?.id,
    })) ?? [],
    'name',
));

export const getEmptyArray = createSelector([], () => []);
