import EStyleSheet from 'react-native-extended-stylesheet';
// noinspection ES6PreferShortImport
import { Platform } from '../../common/react';

const attachmentsListItemWidth = 40;
const attachmentsListItemHeight = 40;
const attachmentPreviewWidth = 40;
const attachmentPreviewHeight = 40;
const attachmentPreviewSize = 40;

export const visitInfo = EStyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#F9F9F9',
    },
    scrollContainer: { flexGrow: 1 },
    innerContainer: {
        paddingHorizontal: 15,
        marginTop: 20,
    },
    heading: {
        paddingLeft: 7,
        marginTop: 12,
        fontFamily: '$secondaryTextFontFamily',
        fontSize: 10,
        fontWeight: '600',
        color: '#BBBBBB',
    },
    formInputs: {
        paddingLeft: 13,
        paddingRight: 12,
        marginTop: 5,
        justifyContent: 'space-between',
    },
    input: {
        marginTop: 4,
        paddingVertical: 10,
        paddingLeft: 12,
        textAlignVertical: 'center',
        backgroundColor: '$white',
        borderWidth: 1,
        borderColor: '#E9E9E9',
        fontSize: 16,
    },
    warnContainer: { flex: 0 },
});

export const visits = EStyleSheet.create({
    container: {
        marginTop: 10,
        paddingLeft: 12,
        paddingRight: 13,
    },
    emptyListText: {
        paddingLeft: 20,
        paddingRight: 10,
        color: '$lightGray',
        lineHeight: 20,
    },
    title: {
        textAlign: 'center',
        fontSize: 16,
        fontWeight: '600',
        color: '#555555',
        marginBottom: 12,
    },
    subtitle: {
        alignSelf: 'center',
        textAlign: 'center',
        fontSize: 12,
        fontWeight: '600',
        color: '#555555',
        marginBottom: 20,
        maxWidth: 170,
    },
    button: {
        paddingVertical: 14,
        paddingLeft: 20,
        borderTopWidth: 1,
        borderColor: '#E9E9E9',
    },
    modalBtnTxt: {
        color: '#555555',
        fontSize: 16,
        lineHeight: 20,
    },
});

export const attachments = EStyleSheet.create({
    container: {
        marginTop: 15,
        paddingTop: 15,
        paddingRight: 17,
        borderTopWidth: 1,
        borderColor: '#E9E9E9',
    },
    containerBottom: {
        marginTop: 12,
        paddingTop: 0,
        paddingRight: 17,
        borderBottomWidth: 1,
        borderColor: '#E9E9E9',
    },
    attachmentsListItem: {
        flexDirection: 'row',
        width: attachmentsListItemWidth,
        height: attachmentsListItemHeight,
        paddingHorizontal: 3,
        paddingVertical: 3,
        marginRight: 8,
        marginLeft: 5,
        marginVertical: 5,
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottomWidth: 0,
    },
    attachmentsItemTitle: {
        width: attachmentPreviewWidth,
        height: attachmentPreviewHeight,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    attachmentPreview: {
        alignSelf: 'center',
        width: attachmentPreviewSize,
        height: attachmentPreviewSize,
    },
    addAttachmentButton: { alignSelf: 'center' },
    emptyImage: { backgroundColor: '$gray' },
});


export const listItem = EStyleSheet.create({
    container: {
        paddingTop: 16,
        paddingLeft: 16,
        paddingBottom: 16,
        paddingRight: 16,
        backgroundColor: '#fff',
        borderRadius: 3,
        marginBottom: 5,
    },
    container_localVisit: {
        borderWidth: 1,
        borderColor: '#E9E9E9',
    },
    container_reservedVisit: {
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderRightWidth: 1,
        borderTopColor: '#E9E9E9',
        borderBottomColor: '#E9E9E9',
        borderRightColor: '#E9E9E9',
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    bottomRow: { marginTop: 16 },
    subtitle: {
        fontFamily: '$secondaryTextFontFamily',
        fontSize: 12,
        lineHeight: 16,
        fontWeight: '300',
        color: '#555555',
    },
    bordered: {
        borderLeftWidth: 4,
        borderLeftColor: 'transparent',
    },
    titleTxt: {
        fontSize: 14,
        lineHeight: 16,
        fontWeight: '500',
        color: '$blueTitle',
        fontFamily: '$secondaryTextFontFamily',
    },
    title: {
        fontSize: 16,
        marginBottom: 0,
        marginLeft: 0,
        color: '#555555',
        fontWeight: '600',
    },
    time: {
        color: '#555555',
        fontSize: 16,
        lineHeight: 16,
        fontFamily: '$secondaryTextFontFamily',
        fontWeight: '600',
        marginTop: 0,
        paddingTop: 0,
    },
    day: {
        fontSize: 12,
        lineHeight: 15,
        marginTop: 5,
        fontFamily: '$secondaryTextFontFamily',
        color: '$emerald',
    },
});


export const plannedVisitsList = EStyleSheet.create({
    wrapperStyle: {
        marginLeft: 0,
        position: 'relative',

    },
    pillsFormBox: {
        width: 68,
        alignSelf: 'flex-start',
    },
    green: { borderLeftColor: '#A3C644' },
    futureVisitTitle: { color: '#5C85DD' },
    title: {
        marginLeft: 8,
        color: '#555555',
        fontSize: 12,
        paddingBottom: 10,
        fontWeight: '600',
    },
    titleTxt: {
        color: '$headerScreenTitleColor',
        fontFamily: '$sourceSansPropFontFamily',
        marginLeft: 0,
        paddingLeft: 0,
        fontSize: 16,
        lineHeight: 17,
        fontWeight: '600',
    },
    subtitle: {
        color: '$headerScreenTitleColor',
        fontFamily: '$sourceSansPropFontFamily',
        fontSize: 12,
        lineHeight: 15,
    },
    emptyListText: {
        paddingLeft: 20,
        paddingRight: 10,
        color: '$lightGray',
        lineHeight: 20,
    },
    subtitleContainer: {
        marginTop: 5,
        width: '100%',
    },
    borderStyles: {
        borderRadius: 3,
        borderBottomWidth: 1,
        borderRightWidth: 1,
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderBottomColor: '#e0e0e0',
        borderRightColor: '#e0e0e0',
        borderTopColor: '#e0e0e0',
        borderLeftColor: '#e0e0e0',
        marginBottom: 5,
    },
    blueBorderStyles: {
        borderRadius: 3,
        borderBottomWidth: 1,
        borderRightWidth: 1,
        borderTopWidth: 1,
        borderLeftWidth: 4,
        borderBottomColor: '#e0e0e0',
        borderRightColor: '#e0e0e0',
        borderTopColor: '#e0e0e0',
        borderLeftColor: '#5C85DD',
        marginBottom: 5,
    },
    greyBorderStyles: {
        borderRadius: 3,
        borderBottomWidth: 1,
        borderRightWidth: 1,
        borderTopWidth: 1,
        borderLeftWidth: 4,
        borderBottomColor: '#e0e0e0',
        borderRightColor: '#e0e0e0',
        borderTopColor: '#e0e0e0',
        borderLeftColor: '$lightGray',
        marginBottom: 5,
    },
    orangeBorderStyles: {
        borderRadius: 3,
        borderBottomWidth: 1,
        borderRightWidth: 1,
        borderTopWidth: 1,
        borderLeftWidth: 4,
        borderBottomColor: '#e0e0e0',
        borderRightColor: '#e0e0e0',
        borderTopColor: '#e0e0e0',
        borderLeftColor: '#FAC665',
        marginBottom: 5,
    },
    futureLeftBorder: {
        borderLeftWidth: 0,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
    container: {
        paddingTop: 16,
        paddingLeft: 16,
        paddingBottom: 16,
        paddingRight: 16,
        backgroundColor: '#fff',
    },
    iconWrapper: {
        width: 25,
        height: 25,
        borderRadius: 100,
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: '$blueTxt',
        borderWidth: 1,
        backgroundColor: '$white',
    },
    leftIconBox: {
        alignSelf: 'flex-start',
        alignItems: 'center',
        marginRight: 16,
    },
    time: {
        color: '#555555',
        fontSize: 16,
        lineHeight: 16,
        fontFamily: '$primaryTextFontFamily',
        fontWeight: '600',
        marginTop: 0,
        paddingTop: 0,
    },
    day: {
        color: '$emerald',
        fontFamily: '$sourceSansPropFontFamily',
        fontSize: 12,
        lineHeight: 15,
        marginTop: 5,
    },
    rightIcon: { alignSelf: 'flex-start' },
    modalTitle: {
        textAlign: 'center',
        fontSize: 16,
        fontWeight: '600',
        color: '#555555',
        marginBottom: 12,
    },
    modalSubtitle: {
        alignSelf: 'center',
        textAlign: 'center',
        fontSize: 12,
        fontWeight: '600',
        color: '#555555',
        marginBottom: 20,
        maxWidth: 170,
    },
    modalBtn: {
        paddingVertical: 14,
        paddingLeft: 20,
        borderTopWidth: 1,
        borderColor: '#E9E9E9',
    },
    modalBtnTxt: {
        color: '#555555',
        fontSize: 16,
        lineHeight: 20,
    },
});

export const visitModalContent = EStyleSheet.create({
    modalBtnTxt: {
        color: '#555555',
        fontSize: 16,
        lineHeight: 20,
    },
    modalButton: {
        paddingVertical: 14,
        // paddingLeft: 20,
        borderTopWidth: 1,
        borderColor: '#E9E9E9',
    },
    selectModalTitle: {
        fontFamily: '$sourceSansPropFontFamily',
        color: '#555555',
        fontSize: 16,
    },
    registryNumbersSubtitle: {
        fontFamily: '$sourceSansPropFontFamily_semibold',
        fontWeight: '600',
        color: '#484848',
        fontSize: 14,
        marginTop: 12,
        paddingHorizontal: 16,
    },
});

// noinspection JSUnusedGlobalSymbols
export const warningModalContent = EStyleSheet.create({
    footer: {
        marginTop: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
});

export const visitInput = EStyleSheet.create({
    containerInput: {
        height: 48,
        position: 'relative',
        borderWidth: 1,
        backgroundColor: 'white',
        borderColor: '#e0e0e0',
        borderRadius: 3,
    },
    loadingCapabilitiesIndicator: {
        height: 48,
        position: 'relative',
        borderWidth: 1,
        backgroundColor: 'white',
        borderColor: '#e0e0e0',
        borderRadius: 3,
        marginTop: 10,
        paddingLeft: Platform.OS === 'ios' ? 24 : 12,
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    inputHeader: {
        marginBottom: 4,
        marginLeft: 12,
        fontSize: 12,
        color: '#A9A9A9',
        fontFamily: '$sourceSansPropFontFamily_semibold',
    },
    inputHeaderText: {
        fontFamily: '$sourceSansPropFontFamily',
        color: '#818181',
        fontSize: 12,
        fontWeight: 'normal',
        marginBottom: -8,
    },
    inputIcon: {
        position: 'absolute',
        right: 18,
        top: 14,
    },
    inputText: {
        paddingHorizontal: 12,
        paddingVertical: 12,
    },
});


export const visitsFilter = EStyleSheet.create({
    list: { maxHeight: 256 },
    listItemWrapper: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        paddingVertical: 14,
        paddingHorizontal: 12,
        borderBottomWidth: 1,
        borderColor: '#E9E9E9',
    },
});

export const visitReports = EStyleSheet.create({
    container: {
        marginTop: 15,
        paddingTop: 0,
        borderBottomWidth: 1,
        paddingBottom: 6,
        borderColor: '#E9E9E9',
        paddingHorizontal: 0,
        marginHorizontal: 8,
    },
    listItem: {
        flexDirection: 'row',
        // flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 12,
        borderWidth: 1,
        borderColor: '#E9E9E9',
        backgroundColor: 'white',
        marginTop: 4,
        borderRadius: 3,
    },
    loading: {
        paddingVertical: 0,
        marginRight: Platform.OS === 'ios' ? 10 : 0,
    },
});

export const actualVisit = EStyleSheet.create({
    container: { width: '100%' },
    box: {
        paddingTop: 4,
        width: '100%',
    },
    boxContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        flex: 1,
        width: '100%',
    },
    visitorInfoContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: -4,
        width: '60%',
    },
    avatarContainer: {
        width: 25,
        marginRight: 5,
    },
    avatar: { marginRight: 7 },
    nameContainer: { width: '100%' },
    dataAndCoveregeContainer: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
});

export const visitItem = EStyleSheet.create({
    container: {
        flexDirection: 'row',
        borderWidth: 0,
        paddingVertical: 0,
    },
    indicator: {
        width: 4,
        borderRadius: 3,
        borderTopEndRadius: 0,
        borderBottomEndRadius: 0,
        borderLeftWidth: 4,
        marginBottom: 5,
    },
    listItemContainer: {
        flex: 1,
        borderRightWidth: 1,
        borderTopWidth: 1,
        borderBottomWidth: 1.2,
    },
    contentContainer: {
        flexDirection: 'row',
        width: '94%',
    },
    attachContainer: {
        paddingRight: 2,
        paddingVertical: 2,
        marginLeft: -2,
        marginTop: -2,
    },
    titleHighlight: {
        paddingTop: 2,
        flex: 1,
    },
    menuContainer: {
        position: 'absolute',
        right: 0,
        top: 0,
        marginRight: -8,
    },
});
