/* eslint-disable camelcase */
// noinspection JSUnusedGlobalSymbols
import { useSelector } from 'react-redux';
// import { shareData } from '../../common/ShareIcon';
import { useEffect, useState } from 'react';
import {
    BigButton, Box, dataFrom, Icon, Modal, Modal2, Page, Panel, Platform, React, RText, Subtitle, Switch, Text, TextInput, Title, TouchableOpacity, VBox, View,
} from '../../common';
import style from '../../styles';
import { modal } from '../../styles/common';
import { capitalize } from '../../utils';
import {
    getAddedDrugsIds, getDrugInfo, getDrugstoresFilters, getEditedVisit, getIsPersonalized, getMinPrice,
} from '../../selectors';
import { getEnums } from '../../i18n';
import actions, { navigate } from '../../actions';
import { SimpleCheckbox } from '../../common/Selector';
import { putIntoStore } from '../../store';
import { SCHEME } from '../../scheme';
import { Modal3 } from '../../common/Modal';
import { COLORS } from '../../styles/colors';
import { Log } from '../../common/Log';
import { getFormValues } from '../../selectors/forms';
import { cloneObject } from '../../utils/objects';
import { drugstoresFilter as drugstoresFilterStyles, firstAidKitBannerSimpleStyles as simpleBunnerStyles, firstAidKitBannerStyles as bunnerStyles } from './style';
import { extendDrugData } from './util';

const MIN_QTY = 1;
const MAX_QTY = 99;

export const DrugsSummary = ({ price = '?', drugs }) => (drugs.length === 1 ? (
    <DrugInfo drug={drugs[0]} />
) : (
    <Panel
        style={{
            paddingHorizontal: 21,
            paddingVertical: 16,
            marginBottom: 0,
        }}>
        <VBox flex gap={1.3}>
            <Box justify="space-between">
                <Title.Bigger bold id="titles.first-aid-kit" />
                <Title.Bigger bold primary id="price" params={{ value: price }} />
            </Box>
            <Subtitle.Bigger numberOfLines={null}>
                {drugs.map(e => capitalize(e.name)).join(', ')}
            </Subtitle.Bigger>
        </VBox>
    </Panel>
));

export const firstAidKitMinPriceExplainModal = () => {
    Page.showModal(
        <Modal3 title="titles.priceRange" buttonCloseTitle="buttons.priceRangeConfirm">
            <View>
                <Subtitle
                    id="titles.priceRangeExplain"
                    style={{
                        padding: 20,
                        fontSize: 14,
                    }}
                    numberOfLines={0}
                />
                {/* <Text style={{ padding: 10, paddingHorizontal: 25, color: COLORS.STANDARD_GRAY, lineHeight: 20 }}>{Object.R('titles.howToFeedbacksText')}</Text> */}
            </View>
        </Modal3>,
    );
};

export const FirstAidKitBanner = ({
    data = [], onClickFind, loading, title, onPress, withDetails, totalCount,
}) => {
    const [showPrices, setShowPrices] = React.useState(true);
    // const userProfileIsEmpty = useSelector(isProfileEmpty);

    const prices = data
        ?.filter((item) => {
            const { selected } = item;
            if (typeof selected === 'undefined') {
                return true;
            }
            return !!selected;
        })
        ?.reduce(
            (prev, current) => {
                return {
                    from: prev.from + (current?.priceMin ?? 0) * Number(current?.qty ?? 1),
                    to: prev.to + (current?.priceMax ?? 0) * Number(current?.qty ?? 1),
                };
            },
            {
                from: 0,
                to: 0,
            },
        );

    useEffect(() => {
        setShowPrices(!loading);
    }, [loading]);

    return (
        <Panel style={bunnerStyles.pannel}>
            <TouchableOpacity style={bunnerStyles.wrapper} onPress={onPress}>
                <View style={[bunnerStyles.container]}>
                    <View style={bunnerStyles.main}>
                        <View style={[bunnerStyles.headerWrapper, { flexDirection: 'row' }]}>
                            <Text style={bunnerStyles.header}>
                                {data?.length > 1
                                    ? `${String.i18n(
                                        'drugs',
                                        data?.filter?.((item) => {
                                            const { selected } = item;
                                            if (typeof selected === 'undefined') {
                                                return true;
                                            }
                                            return !!selected;
                                        })?.length ?? 0,
                                    ).toLowerCase()}${
                                          totalCount &&
                                          totalCount !==
                                              data?.filter?.((item) => {
                                                  const { selected } = item;
                                                  if (typeof selected === 'undefined') {
                                                      return true;
                                                  }
                                                  return !!selected;
                                              })?.length
                                              ? ` (${Object.R('titles.of')} ${totalCount})`
                                              : ''
                                      }`
                                    : Object.R(title ?? 'titles.first-aid-kit')}
                            </Text>
                        </View>
                        <View style={bunnerStyles.priceWrapper}>
                            {showPrices && (
                                <TouchableOpacity onPress={firstAidKitMinPriceExplainModal}>
                                    <Text style={bunnerStyles.headerPrimary}>
                                        {Object.R('priceFromTo', {
                                            from: prices?.from?.toFixed?.(2) ?? '',
                                            to: prices?.to?.toFixed?.(2) ?? '',
                                        })}
                                    </Text>
                                </TouchableOpacity>
                            )}
                        </View>
                    </View>
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}>
                        <View />
                        {/* <Text */}
                        {/*     style={{ */}
                        {/*         ...bunnerStyles.text, */}
                        {/*         paddingBottom: 5, */}
                        {/*     }}> */}
                        {/*     {String.i18n('drugs', data?.length ?? 0).toLowerCase()} */}
                        {/* </Text> */}
                        {/* {!userProfileIsEmpty && (from === 'firstAidKit' || from === 'apt') ? ( */}
                        {/*     <Icon.Add size={16} onPress={onAddPress} /> */}
                        {/* ) : null} */}
                        <Text
                            style={{
                                ...bunnerStyles.text,
                                paddingBottom: 0,
                                // alignContent: 'flex-start',
                                alignItems: 'flex-start',
                                flex: 1,
                            }}>
                            {data
                                ?.filter?.((item) => {
                                    const { selected } = item;
                                    if (typeof selected === 'undefined') {
                                        return true;
                                    }
                                    return !!selected;
                                })
                                ?.map(item => item?.name ?? '')
                                .join(', ')}
                        </Text>
                        {withDetails && (
                            <View
                                style={{
                                    width: 20,
                                    justifyContent: 'center',
                                    // marginBottom: 15,
                                }}>
                                <Icon.Right />
                            </View>
                        )}
                    </View>
                </View>
                {!!data?.length && onClickFind && (
                    <BigButton title="titles.findInDrugStores" action={() => onClickFind && onClickFind()} />
                )}
            </TouchableOpacity>
        </Panel>
    );
};

export const FirstAidKitSimpleBanner = ({ data = [], withPrices = true }) => {
    const prices = data?.reduce(
        (prev, current) => ({
            from: prev.from + (current?.priceMin ?? 0) * Number(current?.qty ?? 1),
            to: prev.to + (current?.priceMax ?? 0) * Number(current?.qty ?? 1),
        }),
        {
            from: 0,
            to: 0,
        },
    );
    return (
        <Panel style={simpleBunnerStyles.container}>
            <Text style={simpleBunnerStyles.header}>
                {data.length
                    ? Object.R('titles.selectedDrugs', { count: data?.length })
                    : Object.R('titles.chooseDrugs')}
            </Text>
            {data.length ? (
                <TouchableOpacity onPress={firstAidKitMinPriceExplainModal}>
                    <Text style={simpleBunnerStyles.headerPrimary}>
                        {withPrices
                            ? Object.R('priceFromTo', {
                                from: prices.from.toFixed(2),
                                to: prices.to.toFixed(2),
                            })
                            : ''}
                    </Text>
                </TouchableOpacity>
            ) : null}
        </Panel>
    );
};

export const FirstAidKitDrugs = dataFrom(getMinPrice, ({ data, drugs = [] }) => (
    <DrugsSummary drugs={drugs} price={data} />
));

const DrugIconForUser = dataFrom(
    getIsPersonalized,
    ({
        data: isPersonalized,
        drugsInBasket,
        drug,
        canRemove = true,
        canAdd = true,
        manualVisit = false,
        fromVisit = false,
    }) => {
        const values = useSelector(getFormValues)?.drugsForVisit ?? [];
        const edited = useSelector(getEditedVisit);
        const drugs = edited?.drugs ?? [];
        const value = cloneObject(drug);
        value.selected = true;
        let drugIcon = <View />;
        if (Platform.OS === 'web' && !fromVisit) {
            return drugIcon;
        }
        if (!canAdd && !canRemove) {
            const drugsData = manualVisit ? values : drugs;
            return drugsData?.length && drugsData?.find?.(({ ls_num }) => drug?.ls_num === ls_num) ? (
                <RText primary id="notes.added" />
            ) : (
                <Icon.Add
                    onPress={() => {
                        if (manualVisit) {
                            actions.setFormValue('drugsForVisit', [...values, value]);
                        } else {
                            actions.updateEditedVisit({ drugs: [...drugs, value] });
                        }
                    }}
                />
            );
        }
        if (isPersonalized) {
            drugIcon = drugsInBasket.includes(drug?.id)
                ? !!canRemove && <Icon.Delete onPress={() => actions.toggleBasket(drug)} />
                : !!canAdd && <Icon.Add onPress={() => actions.toggleBasket(value)} />;
        }

        return drugIcon;
    },
);

export const DrugInfoRightIcon = dataFrom(
    getAddedDrugsIds,
    ({
        data: drugsInBasket, drug, canAdd, canRemove, manualVisit, fromVisit,
    }) => (
        <DrugIconForUser
            drugsInBasket={drugsInBasket}
            drug={drug}
            canRemove={canRemove}
            canAdd={canAdd}
            manualVisit={manualVisit}
            fromVisit={fromVisit}
        />
    ),
);

export const DrugDescription = ({
    drug: {
        producer, country, components, requirePrescript, tar,
    },
}) => (
    <VBox accessibilityLabel="description">
        <Subtitle.Bigger>{tar}</Subtitle.Bigger>
        {producer ? <Subtitle.Bigger>{`® ${producer} ${country ? `(${country})` : ''}`}</Subtitle.Bigger> : null}
        {components && components !== '~' ? <Subtitle.Bigger>{`${components}`}</Subtitle.Bigger> : null}
        <Subtitle.Bigger>{requirePrescript}</Subtitle.Bigger>
    </VBox>
);

export const DrugInfo = ({
    drug,
    withLoading = true,
    canRemove = true,
    canAdd = true,
    manualVisit = false,
    fromVisit,
    withQty = false,
}) => {
    const [drugData, setDrugData] = useState(drug);
    const drugsInfo = useSelector(getDrugInfo);
    useEffect(() => {
        setDrugData(drug);
        withLoading && actions.loadDrugInfo([drug]);
    }, [drug]);

    useEffect(() => {
        const extInfo = extendDrugData(drug, drugsInfo?.[drug?.id ?? drug?.ls_num] ?? {});
        setDrugData(extInfo);
    }, [drugsInfo]);
    const qty = withQty ? drugData?.qty ?? 1 : 1;
    return (
        <Panel
            style={{
                paddingHorizontal: 21,
                paddingVertical: 16,
                marginBottom: 0,
            }}
            vertical>
            <View style={[style.flexRow, style.spaceBetween, { marginBottom: 8 }]}>
                <View style={[style.flex1, style.mr]}>
                    <Title.Bigger numberOfLines={null} bold>{`${drug?.name}`}</Title.Bigger>
                </View>
                <DrugInfoRightIcon
                    drug={drugData}
                    canRemove={canRemove}
                    canAdd={canAdd}
                    manualVisit={manualVisit}
                    fromVisit={fromVisit}
                />
            </View>
            <View style={[style.flex1, style.flexRow, style.spaceBetween]}>
                <View style={[style.flex1, style.mr]}>
                    <DrugDescription drug={drugData} />
                </View>
                <Title.Bigger style={style.alignSelfEnd} bold primary>
                    {/* {drug?.pricingText} */}
                    {Object.R('priceFromTo', {
                        from: Number(drugData?.priceMin * qty ?? 0).toFixed(2),
                        to: Number(drugData?.priceMax * qty ?? 0).toFixed(2),
                    })}
                </Title.Bigger>
            </View>
        </Panel>
    );
};

export const renderDrugItem =
    (rightIcon, onPress, cap = false, prices = true) => ({ item }) => (
        <Panel
            style={{ flexDirection: 'row' }}
            pressHandler={
                onPress
                    ? () => onPress(item)
                    : () => {
                        if (item?.aptId) {
                            navigate('DrugsList', {
                                keyword: item.name,
                                drugList: null,
                            });
                        } else {
                            navigate('DrugDetails', {
                                drug: item,
                                from: 'drugDetails',
                            });
                        }
                    }
            }>
            <VBox flex style={style.mr}>
                <Title bold>{cap ? `${capitalize(item.name.toLowerCase())}` : `${item.name}`}</Title>
                <DrugDescription drug={item} />
            </VBox>
            <VBox justify="space-between" style={style.alignItemsEnd}>
                <View style={style.alignItemsEnd}>
                    {!!prices && <Subtitle primary>{item.pricingText}</Subtitle>}
                    {item.apt_cnt && !!prices ? (
                        <Subtitle primary>
                            {Object.R('titles.drugstoresCount', { count: item.apt_cnt || 0 })}
                        </Subtitle>
                    ) : null}
                </View>
                {rightIcon ? rightIcon(item) : <DrugInfoRightIcon drug={item} />}
            </VBox>
        </Panel>
    );

const MedicineQty = ({ qty: quantity = 1, onChange }) => {
    const [qty, setQty] = useState(1);
    const [fieldError, setFieldError] = useState(false);
    useEffect(() => {
        setQty(quantity);
    }, [quantity]);
    return (
        <View
            style={{
                marginHorizontal: 4,
                marginVertical: 0,
                borderWidth: 0,
                borderColor: 'red',
                flex: 1,
                flexDirection: 'row',
                marginTop: 0,
                paddingTop: 0,
                paddingBottom: 0,
                justifyContent: 'center',
                alignItems: 'center',
                padding: 0,
                paddingVertical: 0,
            }}>
            <TouchableOpacity
                style={{
                    borderWidth: 0,
                    borderColor: 'blue',
                    padding: 0,
                    margin: 0,
                    marginVertical: 0,
                    paddingVertical: 0,
                    marginTop: 0,
                    paddingTop: 0,
                    flex: 2,
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                }}
                onPress={() => {
                    setQty((prev) => {
                        if (prev > MIN_QTY) {
                            onChange && onChange(prev - 1);
                            return prev - 1;
                        }
                        return prev;
                    });
                }}>
                <Icon.Sub
                    size={16}
                    color={qty > 1 ? COLORS.NEGATIVE : COLORS.LIGHT_GRAY}
                    style={{
                        borderColor: qty > 1 ? COLORS.NEGATIVE : COLORS.LIGHT_GRAY,
                        width: 28,
                        height: 28,
                    }}
                />
            </TouchableOpacity>
            {Platform.OS === 'web' ? (
                <>
                    <TextInput.Number
                        keyboardType={Platform.OS === 'ios' ? 'number-pad' : 'decimal-pad'}
                        maxLength={2}
                        onChangeText={(text) => {
                            const num = Number(text.replace(/[^0-9]/g, ''));
                            setQty(num);
                            onChange && onChange(num);
                        }}
                        min={1}
                        onEndEditing={(result) => {
                            const int = parseInt(result.nativeEvent.text, 10);
                            setQty(int);
                            setFieldError(Number.isNaN(int) || !(int >= MIN_QTY || int <= MAX_QTY));
                        }}
                        value={qty}
                        style={{
                            color: COLORS.STANDARD_GRAY,
                            width: 40,
                            height: 28,
                            textAlign: 'center',
                            margin: 0,
                            padding: 0,
                            paddingHorizontal: 0,
                            paddingVertical: 0,
                            marginHorizontal: 4,
                            borderColor: fieldError ? COLORS.NEGATIVE : COLORS.LIGHT_GRAY,
                            flex: 1,
                        }}
                    />
                </>
            ) : (
                <TextInput.Number
                    keyboardType={Platform.OS === 'ios' ? 'number-pad' : 'decimal-pad'}
                    maxLength={2}
                    onChangeText={(text) => {
                        const num = Number(text.replace(/[^0-9]/g, ''));
                        setQty(num);
                        onChange && onChange(num);
                    }}
                    min={1}
                    onEndEditing={(result) => {
                        const int = parseInt(result.nativeEvent.text, 10);
                        setQty(int);
                        setFieldError(Number.isNaN(int) || !(int >= MIN_QTY || int <= MAX_QTY));
                    }}
                    // value={,qty}
                    style={{
                        color: COLORS.STANDARD_GRAY,
                        // width: 40,
                        textAlign: 'center',
                        margin: 0,
                        padding: 0,
                        paddingHorizontal: 0,
                        paddingVertical: 0,
                        marginHorizontal: 4,
                        borderColor: fieldError ? COLORS.NEGATIVE : COLORS.LIGHT_GRAY,
                        flex: 1,
                    }}>
                    {qty || ''}
                </TextInput.Number>
            )}
            <TouchableOpacity
                style={{
                    flex: 2,
                    borderWidth: 0,
                    borderColor: 'blue',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                }}
                onPress={() => {
                    setQty((prev) => {
                        if (prev < MAX_QTY) {
                            onChange && onChange(prev + 1);
                            return prev + 1;
                        }
                        return prev;
                    });
                }}>
                <Icon.Add
                    size={16}
                    color={qty < MAX_QTY ? COLORS.POSITIVE : COLORS.LIGHT_GRAY}
                    style={{
                        borderColor: qty < MAX_QTY ? COLORS.POSITIVE : COLORS.LIGHT_GRAY,
                        width: 28,
                        height: 28,
                    }}
                />
            </TouchableOpacity>
        </View>
    );
};

export const renderSelectableDrugItem =
    ({
        onSelect, onDeleteItem, onChangeQty, onPress, from, onFirstAidKit, basket, withPrices = true,
    }) => ({ item }) => {
        const debug = false;
        const onPressHandler = () => {
            if (item?.aptId) {
                navigate('DrugsList', {
                    keyword: item.name,
                    drugList: null,
                });
            } else {
                navigate('DrugDetails', {
                    drug: {
                        ...item,
                        apt_cnt: 5000,
                        from,
                    },
                    from,
                });
            }
            onPress && onPress({ item });
        };
        const onDelete = () => {
            if (from !== 'searchList') {
                actions.toggleBasket(item);
            }
            onDeleteItem && onDeleteItem(item);
        };
        const toggle = () => {
            onSelect(item);
        };

        const isInFirstAidKit = () => {
            if (from !== 'searchList' || !basket) {
                return false;
            }
            return basket.some(i => i.id === item.id);
        };
        return (
            <Panel style={{ flexDirection: 'row' }} pressHandler={undefined}>
                <VBox flex style={[style.mr]} onPress={onPressHandler}>
                    {!!debug && <Log value={item} display={false} />}
                    <Title bold>{item.name?.toUpperCase()}</Title>
                    <DrugDescription drug={item} />
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            borderWidth: 0,
                            borderColor: 'green',
                        }}>
                        <Subtitle
                            style={{
                                marginTop: 4,
                                borderWidth: 0,
                                borderColor: 'blue',
                                flex: 1,
                            }}
                            primary>
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {withPrices ? (item.price ? item.price : `${item.priceMin} – ${item.priceMax} руб`) : ''}
                        </Subtitle>
                        <MedicineQty
                            qty={item?.qty ?? 1}
                            onChange={(q) => {
                                onChangeQty && onChangeQty(item, q);
                            }}
                        />
                    </View>
                </VBox>
                <VBox
                    justify="space-between"
                    style={[
                        style.alignItemsEnd,
                        {
                            // alignSelf: 'flex-end',
                            justifyContent: 'space-between',
                            borderColor: 'red',
                            borderWidth: 0,
                        },
                    ]}>
                    <SimpleCheckbox
                        onPress={toggle}
                        selected={item.selected}
                        containerStyle={{
                            // flex: 1,
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            // borderWidth: 1,
                            // borderColor: 'red',
                            // position: 'absolute',
                            // right: 0,
                            paddingBottom: 10,
                            paddingLeft: 10,
                        }}
                    />
                    {from === 'searchList' ? (
                        <Icon.FirstAidKit
                            size={18}
                            color={isInFirstAidKit() ? COLORS.MAIN : COLORS.LIGHT_GRAY}
                            onPress={() => {
                                onFirstAidKit && onFirstAidKit(item);
                            }}
                            style={{
                                paddingVertical: 10,
                                paddingLeft: 10,
                            }}
                        />
                    ) : null}
                    <Icon.Delete
                        noStyles
                        onPress={onDelete}
                        style={{
                            paddingTop: 10,
                            paddingLeft: 10,
                        }}
                    />
                </VBox>
            </Panel>
        );
    };

const currency = 'p';
const distanceUnit = 'км';
export const renderDrugsStoreItem =
    (isSingle, onItem) => ({ item }) => (
        <TouchableOpacity
            style={{
                flexDirection: 'row',
                padding: 12,
                borderBottomWidth: 1,
                borderBottomColor: '#E9E9E9',
            }}
            onPress={() => onItem(item)}>
            <VBox flex>
                <Title>{item.name}</Title>
                <Subtitle>{item.address}</Subtitle>
                {!item.lackOf.length && !isSingle ? <Subtitle primary>(всё в наличии)</Subtitle> : null}
                {!item.lackOf.length ? null : (
                    <Subtitle strikethrough>{item.lackOf.map(e => e.name).join(', ')}</Subtitle>
                )}
            </VBox>
            <VBox
                justify="space-between"
                style={{
                    width: 64,
                    alignItems: 'flex-end',
                }}>
                {item.distanceError ? null : (
                    <Subtitle>
                        {item.distance} {distanceUnit}
                    </Subtitle>
                )}
                <Subtitle primary>
                    {item.totalPrice} {currency}
                </Subtitle>
            </VBox>
        </TouchableOpacity>
    );

const FilterItem = ({
    name, isWithoutTopBorder, selected, toggle, id,
}) => {
    const onValueChange = v => toggle({ [id]: v });
    return (
        <View
            style={[drugstoresFilterStyles.filterItem, !isWithoutTopBorder && drugstoresFilterStyles.firstFilterItem]}>
            <View style={drugstoresFilterStyles.titleWrap}>
                <Title numLines={2} style={drugstoresFilterStyles.title}>
                    {name}
                </Title>
            </View>
            <View style={drugstoresFilterStyles.switchWrap}>
                <Switch
                    value={selected}
                    style={{ transform: Platform.OS === 'ios' ? [{ scaleX: 0.8 }, { scaleY: 0.8 }] : [] }}
                    activeThumbColor="#4770C0"
                    activeTrackColor="#9FB9FF"
                    onTintColor={Platform.OS !== 'web' ? '#6987D6' : '#8FB8FF'}
                    thumbTintColor="#5C85DD"
                    trackColor={{
                        false: '#CFCFCF',
                        true: Platform.OS === 'ios' ? '#5C85DD' : '#8FB8FF',
                    }}
                    onValueChange={onValueChange}
                />
            </View>
        </View>
    );
};

export const DrugstoresFilterModal = () => {
    const drugstoresFilterData = getEnums('drugstoresFilter');
    const filters = useSelector(getDrugstoresFilters);
    const toggle = newValue => putIntoStore(SCHEME.DRUGSTORES_FILTER, { ...filters, ...newValue });
    return (
        <Modal2 title="titels.drugstoresFilter" containerStyle={drugstoresFilterStyles.modalContainer}>
            {drugstoresFilterData.map(({ name, id }, i) => (
                <FilterItem name={name} isWithoutTopBorder={!!i} selected={filters[id]} id={id} toggle={toggle} />
            ))}
        </Modal2>
    );
};
export const drugstoresFilter = num => (
    <Icon.Filter
        style={drugstoresFilterStyles.filter}
        badge={num}
        onPress={() => Page.showModal(<DrugstoresFilterModal />)}
    />
);

// TODO too general to be here
export const callPhones = (phones) => {
    if (phones.length > 1) {
        Page.showModal(
            <Modal title="titles.phone_numbers" containerStyles={modal.itemList}>
                {phones.map((phone, index) => (
                    <TouchableOpacity
                        accessibilityLabel={`phone_${index}`}
                        onPress={() => Page.closeModal(() => actions.dial(phone))}>
                        <Text style={modal.modalButton}>{phone}</Text>
                    </TouchableOpacity>
                ))}
            </Modal>,
        );
    } else {
        actions.dial(phones[0]);
    }
};
