// SCALE_COLORS - are the default colors. Their indixes + 1 match names of colored dots.
export const SCALE_COLORS = ['rgb(25, 183, 152)', 'rgb(55, 159, 171)', 'rgb(88, 134, 193)', 'rgb(128, 103, 216)', 'rgb(175, 108, 173)', 'rgb(207, 111, 145)', 'rgb(239, 114, 114)'];
export const FOUR_SCALE_COLORS = ['rgb(25, 183, 152)', 'rgb(88, 134, 193)', 'rgb(175, 108, 173)', 'rgb(239, 114, 114)'];

const scaleDaysValues = ['Сегодня', 'Завтра', 'Через 2 дня', '3 и более'];

const getColorByPrice = (value, minValue, maxValue) => {
    const count = SCALE_COLORS.length;
    const step = ((Number(maxValue) - Number(minValue)) / count);
    if (step < 0.1) {
        return 1;
    }
    for (let i = 1; i < count; i++) {
        if (value <= (Number(minValue) + Number(step * i))) {
            return i;
        }
    }
    return count;
};

// eslint-disable-next-line consistent-return
const getColorByFixedValue = (value) => {
    const count = FOUR_SCALE_COLORS.length;
    if (value >= count) {
        return SCALE_COLORS.length;
    }
    if (typeof value === 'number') {
        return (value + (value === 0 ? 1 : (value + 1)));
    }
};

const getGreenOrGrey = value => (value ? 1 : 9);

// noinspection JSUnusedGlobalSymbols
export const propsByScaleType = {
    daysCount: {
        colors: FOUR_SCALE_COLORS,
        fixedValues: scaleDaysValues,
        getColorId: value => +getColorByFixedValue(value),
        nullTitleId: 'titles.in_reception',
    },
    totalPrice: {
        colors: SCALE_COLORS,
        unit: 'p.',
        getColorId: (value, minValue, maxValue) => +getColorByPrice(value, minValue, maxValue),
    },
    onlineConnectivity: {
        colors: SCALE_COLORS,
        getColorId: value => getGreenOrGrey(value),
        nullTitleId: 'titles.ofline',
        toolTipTitleId: 'titles.online',
        hideScale: true,
    },
};
