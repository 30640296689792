import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
    Box,
    CoverableBy,
    FlatList,
    Icon,
    Modal,
    Page,
    Platform,
    React,
    Text,
    Title,
    TouchableOpacity,
    View,
} from '../../common';
import firebase from '../../services/firebase';
import firebaseService, {
    collections as collectiontToSubscribe,
    dinymicSplitCollectionsToSubscribe,
    pureCollections,
    splitCollectionsSums,
    splitCollectionsToSubscribe,
    splitCollectionsToUpdate,
} from '../../services/firebase';
import {
    arrayToHash, capitalize, getDefaultNearestDate, isEmpty, toDate,
} from '../../utils';
import actions, { navigate } from '../../actions';
import { COLORS } from '../../common/style';
import { preparePrefilters } from '../../services/user.utils';
import { getEmptyCollections, getRelatedPrograms, isAllPhysitionsCollectionsUpdated } from '../../selectors';
import { storage } from '../../services/localstorage';
import { putIntoStore } from '../../store';
import { getPriceRangeString } from '../../selectors/utils';
import { AgeBadge } from './snippets';
import { details as styles } from './style';
import { doctorFilterParams } from './DoctorsSpecialtiesSearch';

export const sortClinics = (clinicList = []) => {
    return clinicList.sort((a, b) => {
        if (a?.hasBumba !== b?.hasBumba) {
            return a?.hasBumba ? -1 : 1;
        }
        if (a?.connectivityStatus !== b?.connectivityStatus) {
            return a?.connectivityStatus === 'online' ? -1 : 1;
        }
        return a?.name > b?.name ? 1 : -1;
    });
};

export const useHasActualRelatedPrograms = () => {
    const programs = useSelector(getRelatedPrograms);
    const programsList = Object.values(programs);
    return programsList.length > 1 || programsList.some(({ id }) => id !== '*');
};

export const getHandledAgesFromWorkPlace = (worksAt) => {
    if (!worksAt) {
        return [];
    }
    const { patientLowerAge, patientUpperAge } = worksAt;

    let handledAges = [];
    if (patientLowerAge >= 18 || !patientUpperAge || patientUpperAge > 18) {
        handledAges = [
            {
                title: '18+',
                isForChild: false,
            },
        ];
    }
    if (patientLowerAge < 18) {
        handledAges.push({
            title:
                patientUpperAge === null || patientUpperAge === 18 || patientUpperAge === undefined
                    ? `${patientLowerAge || 0}+`
                    : Object.R('titles.doctorAgeHandlingInterval', {
                        from: `${patientLowerAge || 0}+`,
                        to: patientUpperAge,
                    }),
            isForChild: true,
        });
    }
    return handledAges;
};

export const toVisit = async (doctorInfo, mcId) => {
    const { worksAt } = doctorInfo;
    const navigateToDatePick = async (mdInfo) => {
        let phones = Array.isArray(mdInfo) ? mdInfo[0]?.phones : mdInfo?.phones;
        const { clinicId } = mdInfo;
        if (!phones) {
            try {
                const data = await firebase.getById('clinics', clinicId);
                phones = data?.phone?.split(';').map(e => e.trim()) ?? [];
            } catch (e) {
                phones = [];
            }
            mdInfo.phones = phones;
        }
        if (mdInfo.notification) {
            navigate('MedCenterRecord', { mdInfo });
        } else {
            if (Platform.OS === 'web') {
                actions.setSelectedService();
            }
            navigate('DoctorRecordDatePick', {
                mdInfo,
                doctorInfo,
            });
        }
    };
    if (worksAt.length === 1) {
        navigateToDatePick(worksAt[0]);
    } else if (mcId) {
        const mc = worksAt?.find(e => e.id === mcId);
        if (mc) {
            navigateToDatePick(mc);
        }
    } else if (worksAt.length > 1) {
        Page.showModal(
            <Modal title="titles.several_medical_centers" subtitle="titles.choose_a_medical_center">
                <FlatList
                    data={sortClinics(worksAt)}
                    keyExtractor={e => e.id}
                    renderItem={({
                        item,
                        item: {
                            id,
                            name,
                            nearestAvailableDate,
                            coverer,
                            connectivityStatus = '',
                            priceRange: { priceRangeString } = {},
                            hasBumba,
                        },
                        onItem = () => Page.closeModal(() => navigateToDatePick(item)),
                        handledAgesInterval = getHandledAgesFromWorkPlace(item),
                    }) => (
                        <TouchableOpacity
                            key={id}
                            onPress={onItem}
                            style={{
                                ...styles.modalButton,
                                borderRadius: 3,
                                borderRightWidth: 5,
                                borderTopColor: '#E9E9E9',
                                borderRightColor: COLORS[connectivityStatus.toUpperCase()] || '#0000',
                                borderColor: null,
                            }}>
                            <Box
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginBottom: 8,
                                    marginRight: 12,
                                }}>
                                <Text style={styles.selectModalTitle}>
                                    {name}
                                    {hasBumba && (
                                        <Icon.Attach
                                            size={11}
                                            style={{
                                                paddingTop: 3,
                                                paddingLeft: 4,
                                            }}
                                            color={COLORS.MAIN}
                                        />
                                    )}
                                </Text>
                                <CoverableBy coverer={coverer} />
                            </Box>
                            <View
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginBottom: 3,
                                    marginRight: 12,
                                }}>
                                <Box gap={0.5} style={{ alignItems: 'center' }}>
                                    <Icon name="calendar" size={24} color="#96B0E9" />
                                    <Text style={styles.selectModalIcon}>
                                        {capitalize(getDefaultNearestDate(nearestAvailableDate))}
                                    </Text>
                                </Box>
                                <Box
                                    gap={0.5}
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}>
                                    {handledAgesInterval.map(({ title, isForChild }) => (
                                        <AgeBadge title={title} isForChild={isForChild} />
                                    ))}
                                </Box>
                            </View>
                            {priceRangeString ? (
                                <Title
                                    bold
                                    style={{
                                        color: COLORS.STANDARD_GRAY,
                                        paddingTop: 6,
                                    }}>{`${Object.R('titles.consultation')} ${priceRangeString}`}</Title>
                            ) : null}
                        </TouchableOpacity>
                    )}
                />
            </Modal>,
        );
    }
};

export const gotoFilters = (from = 'default') => {
    preparePrefilters(from);
    navigate('DoctorFilters', { from });
};

export const resetDoctorsFilters = (newFilters = {}, filtersToSave = {}, from = 'default') => {
    const filterParams = doctorFilterParams[from] ?? doctorFilterParams.default;
    const defaultFilters = {
        specialty: '',
        worksAt: [],
        profiles: '0',
        feedbacks: false,
        time: '0',
        dates: {},
    };
    putIntoStore(filterParams.scheme, { ...defaultFilters, ...newFilters });
    putIntoStore(filterParams.physiciansSearchScheme, undefined);
    putIntoStore(filterParams.specializationForApi, undefined);
    actions.updateDocFilters(
        {
            worksAt: filtersToSave.worksAt || [],
            worksAtGroup: filtersToSave.worksAtGroup || 'any',
        },
        from === 'default' ? '' : from,
    );
};

const subscribeOnCollection = (collections) => {
    collectiontToSubscribe.push(...collections);
    collections.forEach(coll => firebase.setAndUpdateCollection(
        coll,
        (collName, data) => actions.syncCollection(`${collName}.data`, { ...data }),
        actions.stopCollectionFetching,
        actions.isAllCollectionFetching,
    )); // , actions.collectionFetchingTime));
};

const getCollection = (collections) => {
    // collectiontToSubscribe.push(...collections)
    collections.forEach(coll => firebase.updateCollection(
        coll,
        (collName, data) => actions.syncCollection(`${collName}.data`, { ...data }),
        actions.stopCollectionFetching,
        actions.isAllCollectionFetching,
        collections?.some(e => e === 'physicians') ? actions.physiciansCollectionsFetching : undefined,
    )); // , actions.collectionFetchingTime));
};

const getDinymicCollection = (collections) => {
    // pureCollections.push(...collections);
    collections.forEach(coll => firebase.pureSubscribeOnCollection(
        coll,
        (collName, data) => actions.syncCollection(`${collName}.data`, { ...data }),
        actions.stopCollectionFetching,
    )); // , actions.collectionFetchingTime));
};

const subscribeOnDinymicCollectuon = (collections) => {
    pureCollections.push(...collections);
    collections.forEach(coll => firebase.pureSubscribeOnCollection(
        coll,
        (collName, data) => actions.syncCollection(`${collName}.data`, { ...data }),
        actions.stopCollectionFetching,
    )); // , actions.collectionFetchingTime));
};

export const setPhysiciansCollection = async () => {
    actions.localPhysiciansFetching(true);
    const { lastMax = -1, data = {} } = await storage.getObject('physicians');
    firebase.collectionsData.physicians = {
        data,
        lastMax,
    };
    if (!isEmpty(data)) {
        actions.syncCollection(`physicians.data`, { ...data });
    }
    actions.localPhysiciansFetching(false);
};

export const fetchDoctorsAndAdditionalInfo = () => {
    const collections = useSelector(getEmptyCollections);
    useEffect(() => {
        if (collections.length) {
            if (collections.find(e => e === 'physicians')) {
                setPhysiciansCollection();
            } else if (
                ![...collectiontToSubscribe, ...pureCollections].find(
                    collName => collName === 'physicians_timetable_sum',
                )
            ) {
                subscribeOnDinymicCollectuon(['physicians_timetable_sum']);
                subscribeOnCollection(['physicians_prices_sum', 'physicians_feedback_sum']);
            }
        }
    }, [collections]);
};

// export const fetchPhysicians = () => {
//     const collections = useSelector(getEmptyCollections);
//     const isDoctorsFetched = useSelector(isAllPhysitionsCollectionsUpdated);
//     useEffect(() => {
//         if (collections.length) {
//             if (collections.find(e => e === 'physicians')) {
//                 setPhysiciansCollection();
//                 subscribeOnCollection(['physicians']);
//             }
//         }
//     }, [collections, isDoctorsFetched]);
// };

export const fetchOnlyPhysicians = (isAuth) => {
    const collections = useSelector(getEmptyCollections);
    const isDoctorsFetched = useSelector(isAllPhysitionsCollectionsUpdated);
    useEffect(() => {
        if (collections.length && isAuth) {
            if (collections.find(e => e === 'physicians')) {
                setPhysiciansCollection();
                getCollection(['physicians']);
            }
        }
    }, [collections, isDoctorsFetched, isAuth]);
};

const needToGetSplitCollection = name => !splitCollectionsToUpdate.some(collName => collName === name);
const needToSubscribeSplitCollection = name => !splitCollectionsToSubscribe.some(collName => collName === name);
const needToSubscribeDinymicSplitCollection = name => !dinymicSplitCollectionsToSubscribe.some(collName => collName === name);
const needToGetSplitCollectionSum = name => !splitCollectionsSums.some(collName => collName === name); // dinymicSplitCollectionsToSubscribe

export const fetchPhysicians = () => {
    const collections = useSelector(getEmptyCollections);
    const isDoctorsFetched = useSelector(isAllPhysitionsCollectionsUpdated);
    useEffect(() => {
        if (collections.length) {
            if (collections.find(e => e === 'physicians')) {
                setPhysiciansCollection();
                getCollection(['physicians']);
            } else if (
                ![...collectiontToSubscribe, ...pureCollections].find(
                    collName => collName === 'physicians_timetable_sum',
                )
            ) {
                subscribeOnDinymicCollectuon(['physicians_timetable_sum']);
                getCollection(['physicians_prices_sum', 'physicians_feedback_sum']);
            }
        }
    }, [collections, isDoctorsFetched]);
};

const specialtyRelatedSubscribe = (location, spec) => {
    if (spec) {
        if (needToGetSplitCollection(`${'physicians_prices'}.${location}.${spec}`)) {
            firebaseService.getSplitCollection('physicians_prices', location, spec, (collName, data) => actions.syncCollection(`${collName}.data`, { ...data }));
        }
        if (needToSubscribeSplitCollection(`${'physicians_feedback_counters'}.${location}.${spec}`)) {
            firebaseService.subscribeOnSplitCollection(
                'physicians_feedback_counters',
                location,
                spec,
                (collName, data) => actions.syncCollection(`${collName}.data`, { ...data }),
            );
        }
        if (needToGetSplitCollectionSum(`${'physicians_nta_sum'}.${location}.${spec}`)) {
            firebaseService.getSplitCollectionSum(
                'physicians_nta_sum',
                location,
                spec,
                'ntaByAssignment',
                (collName, data) => actions.syncCollection(`${collName}.data`, { ...data }),
                actions.isAllCollectionFetching,
            );
        }
        if (needToSubscribeDinymicSplitCollection(`${'physicians_nta'}.${location}.${spec}`)) {
            firebaseService.subscribeOnDinymicSplitCollection(
                'physicians_nta',
                location,
                spec,
                (collName, data) => {
                    actions.syncCollection(`${collName}.data`, { ...data });
                },
                firebaseService.splitCollectionsData[`${'physicians_nta_sum'}.${location}.${spec}`]?.lastMax,
            );
        }
    }
};

export const fetchPhysiciansData = (location, spec) => {
    const collections = useSelector(getEmptyCollections);
    useEffect(() => {
        if (collections.length) {
            if (collections.find(e => e === 'physicians')) {
                setPhysiciansCollection();
                getCollection(['physicians']);
            } else if (spec) {
                if (Array.isArray(spec)) {
                    spec?.forEach(s => specialtyRelatedSubscribe(location, s));
                } else {
                    specialtyRelatedSubscribe(location, spec);
                }
            }
        }
    }, [collections, splitCollectionsToUpdate?.length, spec]);
};

export const fetchDoctorsSums = () => {
    // InteractionManager.runAfterInteractions(() => getDinymicCollection(['physicians_timetable_sum']));
    // InteractionManager.runAfterInteractions(() => getCollection(['physicians_prices_sum', 'physicians_feedback_sum']));
    // InteractionManager.runAfterInteractions(() => storage.set('physicians', {
    //     data: firebaseService.collectionsData?.['physicians']?.data,
    //     lastMax: firebaseService.collectionsData?.['physicians']?.lastMax,
    // }));
    // subscribeOnDinymicCollectuon(['physicians_timetable_sum']);
    getCollection(['physicians_prices_sum', 'physicians_feedback_sum']);
};

export const fetchDoctors = async () => {
    const collections = useSelector(getEmptyCollections);
    const isDoctorsFetched = useSelector(isAllPhysitionsCollectionsUpdated);
    useEffect(() => {
        if (collections.length) {
            if (collections.find(e => e === 'physicians')) {
                setPhysiciansCollection();
            } else if (
                ![...collectiontToSubscribe, ...pureCollections].find(
                    collName => collName === 'physicians_timetable_sum',
                )
            ) {
                subscribeOnDinymicCollectuon(['physicians_timetable_sum']);
                subscribeOnCollection(['physicians_prices_sum', 'physicians_feedback_sum']);
            }
        }
        if (
            isDoctorsFetched &&
            ![...collectiontToSubscribe, ...pureCollections].find(collName => collName === 'physicians')
        ) {
            subscribeOnCollection(['physicians']);
        }
        // if (collections.length) {
        //     if (collections.find(collName => collName === 'physicians' && !collectiontToSubscribe.find(coll => coll === 'physicians'))) {
        //         subscribeOnCollection(['physicians']);
        //         // subscribeOnDinymicCollectuon(['physicians_timetable_sum']);
        //         // subscribeOnCollection(['physicians_prices_sum', 'physicians_feedback_sum']);
        //     } else if (!collectiontToSubscribe.find(collName => collName === 'physicians_timetable_sum')) {
        //         subscribeOnDinymicCollectuon(['physicians_timetable_sum']);
        //         subscribeOnCollection(['physicians_prices_sum', 'physicians_feedback_sum']);
        //     }
        // }
    }, [collections, isDoctorsFetched]);
};

export const fetchLocalDoctors = async (needFetch = true) => {
    const collections = useSelector(getEmptyCollections);
    useEffect(() => {
        if (collections.length && collections.find(e => e === 'physicians') && needFetch) {
            setPhysiciansCollection();
        }
    }, [collections]);
};

export const getDoctorWithNTA = (timetb = {}, doc) => {
    const nearestAvailableDates = arrayToHash(timetb.nearest_available_dates, 'clinic_id', 'nearest_available_date');
    const r = Object.create(doc);
    r.nearestDate = null;
    r.worksAt = doc.worksAt ? [...doc.worksAt] : [];
    r.worksAt.forEach((job) => {
        const nearestJobDate = toDate(nearestAvailableDates[job.id]);
        Object.assign(job, {
            nearestAvailableDate: nearestJobDate,
            connectivityStatus: job.onlineConnectivity ? 'online' : 'unavailable',
            priceRange: r.priceRange?.[job.id],
        });
        if (nearestJobDate && (!r.nearestDate || nearestJobDate < r.nearestDate)) {
            r.nearestDate = new Date(nearestJobDate.getTime());
        }
    });
    return r;
};

export const useUpdateDoctorFeedback = (doctorId, needUnsubscribe = true) => {
    useEffect(() => {
        let unsub;
        if (doctorId) {
            unsub = firebase?.subscribeOnDocChanges?.(`physicians_feedback_sum`, doctorId, (snapshot) => {
                try {
                    snapshot && putIntoStore('currentDoctorFeedbacks', snapshot.data());
                } catch (e) {
                    // console.log('Error unsubscribe', e);
                }
            });
        }
        return unsub && needUnsubscribe
            ? () => {
                unsub();
                putIntoStore('currentDoctorFeedbacks', null);
            }
            : () => {};
    }, [doctorId]);
};

export const getPriceRangeStringFromArr = (prices = []) => {
    const pr = prices.filter?.(e => e);
    const min = Math.min(...pr);
    const max = Math.max(...pr);
    return getPriceRangeString(min, max);
};
