import { dig } from '../utils';
import { createSelector, dbGettr } from './utils';
import { getUserInfo } from './getters';

/**
 * Invoices
 */

export const getInvoices = store => store.invoices;

// noinspection JSUnusedGlobalSymbols
export const getInfoForInvoicesFeature = createSelector(
    [getInvoices, dbGettr('clinics.data'), getUserInfo],
    (invoices, clinics, userInfo) => ({
        ...invoices,
        clinics,
        userInfo,
        relatives: userInfo.relatives || [],
        me: userInfo.me,
    }),
);

export const getTabBarOptions = createSelector(
    [getInvoices],
    invoices => ({ counts: { MyCard: dig(invoices, 'invoices.newServicesForReview.items.length') } }),
);
