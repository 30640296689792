/* eslint-disable no-use-before-define,no-underscore-dangle */
import { arrayToHash, sortBy } from '../../utils';
import {
    getFromStore, getFromStoreDb, putIntoStore, SCHEME,
} from '../../store';
import { navigate } from '../../actions';
import { MIN_WORD_LENGTH, wordsList } from '../../utils/strings';

const isSomeIncluded = (value, keywordList) => {
    const keywordSplit = [];
    for (let i = 0; i < keywordList.length; i++) {
        const d = Math.trunc(keywordList[i].length / 2);
        const strForSearch = [];
        if (d === 0 || d === 1) {
            strForSearch.push(keywordList[i]);
        } else {
            strForSearch.push(keywordList[i].substring(0, 2));
            strForSearch.push(keywordList[i].substring(1, keywordList[i].length - 1));
        }
        keywordSplit.push(strForSearch);
    }
    const lowerCaseValue = value?.toLowerCase();
    return (!keywordSplit.some(arr => !arr.some(e => lowerCaseValue?.includes(e))));
};

export const isIncludesKeyword = (value, keyword) => {
    if (!keyword || !value) {
        return false;
    }
    const keywordList = keyword.split(' ')
        .filter(word => word.length >= MIN_WORD_LENGTH);
    return isSomeIncluded(value, keywordList) ? value && wordsList(value, keywordList).length >= keywordList.length : false;
};

export const isIncludesCaseLess = (value, keyword) => isIncludesKeyword(value, keyword?.toLowerCase());

export const filterTypesByKeyword = (types = {}, keyword = '') => {
    const sortedTypesFl = [];
    // eslint-disable-next-line no-param-reassign
    keyword = keyword.toLowerCase();
    Object.values(types)
        .forEach(({
            sub: groups,
            name: typeName,
            id: typeId,
        }) => {
            Object.values(groups)
                .forEach(({
                    sub: services,
                    name: groupName,
                    id: groupId,
                }) => {
                    const groppedFoundServices = Object.values(services)
                        .filter(({
                            name: serviceName,
                            options = [],
                        }) => {
                            const includedInOpt = options.some(singleOpt => (singleOpt ? isIncludesKeyword(singleOpt.name, keyword) : false));
                            return isIncludesKeyword(serviceName, keyword) || includedInOpt;
                        })
                        .map(service => ({
                            ...service,
                            typeName,
                            typeId,
                            groupName,
                            groupId,
                            foundOptions: service.options.filter(opt => isIncludesKeyword(opt.name, keyword))
                                .map(opt => ({
                                    name: opt.name,
                                    id: opt.id,
                                })),
                        }));
                    if (groppedFoundServices.length > 0) {
                        sortedTypesFl.push({
                            typeName,
                            typeId,
                            groupName,
                            groupId,
                            foundServices: groppedFoundServices,
                        });
                    }
                });
        }, []);
    // In this point sortedTypesFl contains data for an old SearchByKeywordListItem && SearchByKeywordList implementation.
    // The data has stucture which needs  post processing, it means that we have to use loops inside components, so we going to get low perfomance as result.
    // Solution: transfer all calculating to this(or another) function and make array with objects where each element contains info just for one sinple component.
    // Let do it!
    // POINT TO THINCKING: data is filtered, but after TextHighliter does a lot of work after.

    const sortedTypesFlWithHeaders = addHeadersWithoutFirst(sortedTypesFl);

    return sortedTypesFlWithHeaders
        .reduce((acc, itemCurrent) => {
            const {
                headerComponent,
                foundServices,
            } = itemCurrent;
            if (headerComponent) {
                acc.push(itemCurrent);
            }
            if (foundServices) {
                foundServices.forEach((service) => {
                    const { foundOptions } = service;
                    if (!foundOptions || foundOptions.length === 0) {
                        acc.push(service);
                    }
                    if (foundOptions && foundOptions.length > 0) {
                        if (isIncludesCaseLess(service.name, keyword)) {
                            acc.push(service);
                        }
                        sortBy(foundOptions)
                            .forEach((preSelectedOption) => {
                                acc.push({
                                    ...service,
                                    preSelectedOption,
                                });
                            });
                    }
                });
            }
            return acc;
        }, []);
};

// This function add for each element in array adding object with data for AdaptiveHeader.
// It needs for perfect control elements when scrolling and when we want to make any animation.
// First element we dont need, for the avoidance duplicating info in main header and first element.
// And leave just one header title for all type (it will save usefull place on the screen)

export const addHeadersWithoutFirst = (arrWithData) => {
    const typeNames = [];
    return arrWithData
        .reduce((acc, currValue) => {
            const {
                groupName,
                groupId,
                typeId,
                typeName,
            } = currValue;
            if (typeNames.some(tName => tName === currValue.typeName)) {
                acc.push({
                    headerComponent: true,
                    typeName: null,
                    groupName,
                    groupId,
                    typeId,
                    typeNameCopy: typeName,
                });
            } else {
                typeNames.push(currValue.typeName);
                acc.push({
                    headerComponent: true,
                    typeName,
                    groupName,
                    groupId,
                    typeId,
                    typeNameCopy: typeName,
                });
            }
            acc.push(currValue);
            return acc;
        }, [])
        .slice(1);
};

export const listVisibleProcessor = (listsControlParams, navigation, item) => {
    const {
        keyword,
        typeId,
        groupVisible,
        itemVisible,
        searchVisible,
        level,
        fromSearch,
        listsControlParamsBeforeTransitionToGroup,
    } = listsControlParams || {};
    if (!item) {
        if (navigation && !typeId && !searchVisible) {
            navigation.navigate('Home');
        }
        if (searchVisible) {
            navigation.setParams({
                keyword: '',
                searchVisible: false,
            });
        }
        if (groupVisible && !keyword) {
            navigation.setParams({
                level: 'type',
                typeId: null,
                typeName: null,
                typeVisible: true,
                groupVisible: false,
            });
        }
        if (itemVisible && !keyword && !fromSearch) {
            navigation.setParams({
                level: 'group',
                groupId: null,
                groupName: null,
                itemVisible: false,
                groupVisible: true,
            });
        }
        if (itemVisible && !keyword && fromSearch) {
            navigation.setParams({ ...listsControlParamsBeforeTransitionToGroup });
        }
    } else {
        if (level === 'type') {
            navigation.setParams({
                level: 'group',
                typeName: item.name,
                typeId: item.id,
                typeVisible: false,
                groupVisible: true,
            });
        }
        if (level === 'group') {
            navigation.setParams({
                level: 'item',
                groupId: item.id,
                groupName: item.name,
                groupVisible: false,
                itemVisible: true,
            });
        }
    }
};

// eslint-disable-next-line consistent-return
export const getHeaderSummary = (isProgrammSelectorVisible, fromSearch, type) => {
    if (fromSearch) {
        return 111;
    }

    if (isProgrammSelectorVisible && type === 'type') {
        return 181;
    }
    if (!isProgrammSelectorVisible && type === 'type') {
        return 115;
    } //


    if (isProgrammSelectorVisible && type === 'group') {
        return 119;
    }
    if (!isProgrammSelectorVisible && type === 'group') {
        return 52;
    }

    if (isProgrammSelectorVisible && type === 'item') {
        return 162;
    }
    if (!isProgrammSelectorVisible && type === 'item') {
        return 95;
    }
};

export const goToUmsFilter = async () => {
    const {
        medcenters = [],
        withPrograms = false,
        group,
    } = getFromStoreDb(SCHEME.UMS_FILTER) || {};
    const clinics = Object.values(getFromStoreDb(SCHEME.CLINICS)?.data || []) || {};
    const location = getFromStore(SCHEME.LOCATION) || 17;
    let clinicsArray = Object.values(clinics);

    if (clinicsArray.length) {
        const mapping = getFromStore('ums.info.catalog.mappings');
        const providersHash = arrayToHash(mapping, 'providerId');
        clinicsArray = clinicsArray.filter(e => providersHash[e.id]?.itemIds.length && e.areaCode === location);
    }

    putIntoStore(SCHEME.UMS_PREFILTER, {
        medcenters: sortBy(clinicsArray.map(e => ({
            ...e,
            id: e._id,
            selected: medcenters.some(id => id === e._id),
        })), 'name'),
        withPrograms,
        withOnline: undefined,
        group: group || 'any',
    });
    navigate('UmsFilter');
};

export const getServicesIds = (items, clinicId) => items.filter(({ branchIds, ppiServiceId }) => branchIds?.some(e => e === clinicId) && ppiServiceId).map(({ ppiServiceId }) => ppiServiceId);
