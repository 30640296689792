import React from 'react';
import {
    Modal as ModalRN,
    Platform,
    Switch as SwitchRN,
    Text as TextRN,
    TouchableOpacity as TouchableOpacityRN,
    TouchableWithoutFeedback as TouchableWithoutFeedbackRN,
} from 'react-native';
import ModalWeb from 'modal-react-native-web';

export {
    default as React, Component, PureComponent, Fragment,
} from 'react';
export {
    View,
    FlatList,
    Image,
    ActivityIndicator,
    ScrollView,
    SafeAreaView,
    Platform,
    Keyboard,
    Linking,
    Dimensions,
    StyleSheet,
    StatusBar,
    // Picker,
    Alert,
    RefreshControl,
    Share,
    AppState,
    BackHandler,
} from 'react-native';

export {
    ListItem, Badge, Card, SearchBar as SearchBarNative,
} from 'react-native-elements';

export { Picker } from '@react-native-picker/picker';

export { ScrollView as KeyboardAwareScrollView } from 'react-native'; // -keyboard-aware-scroll-view
export { default as S } from '../styles';

const withPrefix = (ns, prefix = 'button') => (ns ? `${prefix}:${ns}` : prefix);
export const Text = React.memo(props => (
    <TextRN {...props} accessibilityLabel={withPrefix(props.accessibilityLabel, 'text')} />
));
export const TouchableOpacity = React.memo(props => (
    <TouchableOpacityRN {...props} accessibilityLabel={withPrefix(props.accessibilityLabel)} />
));
export const TouchableWithoutFeedback = React.memo(props => (
    <TouchableWithoutFeedbackRN {...props} accessibilityLabel={withPrefix(props.accessibilityLabel)} />
));
export const Switch = ({ thumbTintColor, trackColor = { true: '#6987D6' }, ...props }) => {
    const extraProps = Platform.select({
        ios: {
            trackColor,
            ios_backgroundColor: '#E0E0E0',
        },
        android: {
            thumbColor: props.value ? thumbTintColor : 'white',
            trackColor,
        },
        default: {},
    });
    return <SwitchRN activeTrackColor="#9FB9FF" {...props} {...extraProps} />;
};

Switch.Default = ({
    accessibilityLabel,
    style,
    activeThumbColor = '#4770C0',
    activeTrackColor = '#9FB9FF',
    onTintColor = Platform.OS !== 'web' ? '#6987D6' : '#8FB8FF',
    thumbTintColor = '#5C85DD',
    trackColor = {
        false: '#CFCFCF',
        true: Platform.OS === 'ios' ? '#5C85DD' : '#8FB8FF',
    },
    value,
    onValueChange,
}) => {
    return (
        <Switch
            accessibilityLabel={accessibilityLabel}
            style={[{ ...style }, { transform: Platform.OS === 'ios' ? [{ scaleX: 0.8 }, { scaleY: 0.8 }] : [] }]}
            activeThumbColor={activeThumbColor}
            activeTrackColor={activeTrackColor}
            onTintColor={onTintColor}
            thumbTintColor={thumbTintColor}
            trackColor={trackColor}
            value={value}
            onValueChange={onValueChange}
        />
    );
};

let ModalImpl2 = ModalRN;
// Workaround for web:
if (Platform.OS === 'web') {
    /* global window */
    ModalWeb.setAppElement(window.document.getElementById('root') || window.document.body);
    ModalImpl2 = ModalWeb;
    if (window.Cypress) {
        ModalImpl2 = props => <div id="modal">{props.children}</div>;
    }

    Platform.select = ({ web, android, default: deff }) => web || deff || android;
}

export const ModalImpl = ModalImpl2;
