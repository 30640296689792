import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import {
    Avatar, CustomSectionList, React, Stub, TextHighliter, Title, TouchableOpacity,
} from '../../../common';
import { elementsStyles } from '../../../styles/elements';
import { getIsSpecLoading, getPureSpecialitiesFullList } from '../../../selectors';
import { capitalize } from '../../../utils';
import tracking from '../../../services/tracking';
import actions, { navigate } from '../../../actions';
import { onSpecialty } from '../../../common/Specialties';
import {
    getLenghtObj,
    getMaxCountOfSections,
    getPhysiciansAdvSearchData,
    getSpecializationsAdvSearchData,
    priorityObj,
} from './utils';
import { sectionStyle as style } from './style';
import api from '../../../services/api';
import {
    getSpecialitiesFullListPath,
    useDoctorsDataExpirationHandler,
} from '../../../hooks/useDoctorsDataExpirationHandler';
import { setSpecialitiesError } from '../../../actions/doctors';
import { DOCTOR_SEARCH_LIMIT } from '../../../config';

const isItemsEqual = (prop, propNext) => prop.keyword === propNext.keyword && prop.isLast === propNext.isLast;

export const DoctorSectionItem = React.memo(({ item, isLast, keyword }) => {

    const onPress = useCallback(() => {

        const eventParams = ['open_doc_from_asearch', { docId: item.id }];
        tracking.logEvent(...eventParams);
        navigate('DoctorDetails', {
            doctorInfo: {
                _id: item.id,
                id: item.id,
            },
        });

    }, [item.id]);

    return (
        <TouchableOpacity style={style.item} onPress={onPress} key={`${item?.id}-doctor`}>
            <View style={[style.itemDocContainer, { borderBottomWidth: isLast ? 0 : 1 }]}>
                <View style={{ flex: 1 }}>
                    {keyword ? (
                        <TextHighliter bold value={item?.fullName} keyword={keyword} style={elementsStyles.text_bold} />
                    ) : (
                        <Title bold>{item?.fullName}</Title>
                    )}
                    <Title style={{ fontSize: 12 }}>{item?.specialty}</Title>
                </View>
                <Avatar of="physicians" imageUrl={item.photoUrl} size={45} />
            </View>
        </TouchableOpacity>
    );

}, isItemsEqual);

export const SectionItem = React.memo(({ item, isLast, keyword }) => {

    const onPress = useCallback(() => {

        const eventParams = ['open_spec_from_asearch', { spec: item.title }];
        tracking.logEvent(...eventParams);
        onSpecialty(item?.title, undefined, item?.id);

    }, [item.title]);

    return (
        <TouchableOpacity style={style.item} onPress={onPress} key={`${item?.id}-spec`}>
            <View style={[style.itemContainer, { borderBottomWidth: isLast ? 0 : 1 }]}>
                {keyword ? (
                    <TextHighliter numberOfLines={1} value={capitalize(item?.title)} keyword={keyword} />
                ) : (
                    <Title numberOfLines={1}>{capitalize(item?.title)}</Title>
                )}
            </View>
        </TouchableOpacity>
    );

}, isItemsEqual);

const getRenderItem = (key) => {

    switch (key) {

        case 'doctors':
            return DoctorSectionItem;
        default:
            return SectionItem;

    }

};

export const DoctorsSearchResults = ({ keyword }) => {

    const specialitiesList = useSelector(getPureSpecialitiesFullList);
    const [isPLoading, setIsPLoading] = useState(false);
    const [isSLoading, setIsSLoading] = useState(false);
    const [dataP, setDataP] = useState();
    const [dataS, setDataS] = useState();
    const [previousKeyword, setPreviousKeyword] = useState('');
    const [searchTimeout, setSearchTimeout] = useState();
    const dispatch = useDispatch();
    const isSpecLoading = useSelector(getIsSpecLoading);

    const dataSearch = {
        doctors: dataP,
        specializations: dataS,
    };

    const isNotDefaultState = Object.values(dataSearch)
        .some(e => e !== undefined);

    const loadingState = {
        doctors: isPLoading || !isNotDefaultState,
        specializations: isSLoading || !isNotDefaultState,
    };

    const lenghtObj = getLenghtObj(dataSearch);
    const countsObj = getMaxCountOfSections(lenghtObj);
    const keys = Object.keys(dataSearch).sort((key1, key2) => priorityObj[key1] - priorityObj[key2]);
    const nothingFound = isNotDefaultState && !Object.values(dataSearch)?.filter(e => e?.length)?.length && !isSLoading && !isPLoading;
    const limit = 50;

    useEffect(() => {

        if (!specialitiesList) {

            setIsSLoading(true);
            actions.syncSpecialitiesFullList();

        } else if (specialitiesList && setIsSLoading) {

            setIsSLoading(false);

        }

    }, [specialitiesList]);

    useEffect(() => {

        if (!isSpecLoading) {

            setIsSLoading(false);

        }

    }, [isSpecLoading]);

    useDoctorsDataExpirationHandler(getSpecialitiesFullListPath(), () => actions.syncSpecialitiesFullList({ forceReload: true }),);

    useEffect(() => {

        if (keyword && keyword !== previousKeyword && specialitiesList) {

            setIsPLoading(true);
            const updatePhSearch = async () => {

                try {

                    const result = await api.searchDoctorsByName(keyword, DOCTOR_SEARCH_LIMIT);
                    const phs = getPhysiciansAdvSearchData(result?.physicians || [], specialitiesList, keyword);
                    setDataP(phs);
                    dispatch(setSpecialitiesError({ error: null }));

                } catch (e) {

                    dispatch(setSpecialitiesError({ error: e }));

                } finally {

                    setIsPLoading(false);

                }

            };
            setPreviousKeyword(keyword);
            try {

                const specs = getSpecializationsAdvSearchData(specialitiesList, keyword);
                setDataS(specs);

            } catch (e) {

                // console.error('ERROR specializations search', e);

            }

            if (searchTimeout) {

                clearTimeout(searchTimeout);

            }
            setSearchTimeout(setTimeout(updatePhSearch, 800));

        }

    }, [keyword, specialitiesList, isPLoading, setIsPLoading]);

    // if (isSLoading) {
    //     return <LoadingView title={'titles.searchInProgress'} />;
    // }
    return keys?.length && !nothingFound ? (
        <>
            {keys?.map(key => (
                <CustomSectionList
                    maxNumToRender={countsObj?.[key]}
                    sectionTitle={
                        loadingState[key]
                            ? Object.R(`titles.search${capitalize(key)}SectionNull`)
                            : Object.R(`titles.search${capitalize(key)}Section`, { count: dataSearch[key]?.length > limit ? `${limit}+` : dataSearch[key]?.length || 0 })
                    }
                    data={dataSearch[key]}
                    renderItem={getRenderItem(key)}
                    keyword={keyword}
                    limit={limit}
                    isLoading={loadingState[key]}
                    key={key}
                />
            ))}
        </>
    ) : nothingFound ? (
        <Stub.DoctorsNotFound noTitle />
    ) : null;

};
