import { useEffect } from 'react';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import {
    AutocompleteSearchBar,
    BackHandler,
    dataFrom,
    Icon,
    Page,
    Platform,
    React,
    RText,
    SectionList,
    Stub,
    View,
} from '../../../common';
import actions, { navigate } from '../../../actions';
import { getDrugsSuggestions } from '../../../services/tabletka';
import {
    fetchDrugsByKeyword,
    setRemoteKeyword,
    setSearchKeyword,
    setSearchSuggestionsKeyword,
} from '../../../actions/drugs';
import { getTextWithAuth } from '../../../utils/aCodeSecurity';
import {
    getGlobalLocation,
    getIsPersonalized,
    getSearchDrugsKeyword,
    getSearchDrugsShouldReload,
    getSearchHistory,
    getSearchKeywordDrugs,
    getSearchSuggestionsKeyword,
} from '../../../selectors';
import { CitySelector, MainHeader } from '../../../combo';
import { drugsAnaloguesFilter, FirstAidKitIcon } from '..';
import { renderDrugItem } from '../snippets';

export const getNormalizedKeyword = (kw) => {
    if ((kw ?? '').includes('-')) {
        return kw.split('-')?.[0] ?? '';
    }
    return kw;
};

const EmptyDrugsHistoryStub = dataFrom(getIsPersonalized, ({ data: isPersonalized }) => (
    <Stub.Simple type={isPersonalized ? 'empty-user-drugs-history' : 'empty-drugs-history'} />
));

/**
 * Drugs search page.
 */
export const SearchDrugsHistory = dataFrom(getSearchHistory, ({ data }) => (
    <SectionList
        white
        title="titles.history_search"
        type="drugs-history"
        data={
            data
                ? data.map(e => ({
                    ...e,
                    right: (
                        <View style={{ marginRight: 10 }}>
                            <Icon.Exc onPress={() => actions.deleteSearchHistory(e)} />
                        </View>
                    ),
                }))
                : []
        }
        onItem={({ name }) => navigate('DrugsList', {
            keyword: name,
            drugsList: null,
        })
        }
        ListEmptyComponent={<EmptyDrugsHistoryStub />}
    />
));

export const SearchDrugsResults = dataFrom(getSearchKeywordDrugs, ({ data, isFocus, suggestionsKeyword }) => {
    let hardwareBackPressListener;
    hardwareBackPressListener && hardwareBackPressListener.remove();

    const backHandler = () => {
        if (isFocus && suggestionsKeyword.length && suggestionsKeyword !== data.keyword) {
            actions.setSearchKeyword(suggestionsKeyword);
            actions.setSearchShouldReload(true);
            return true;
        }
        return false;
    };

    if (isFocus) {
        hardwareBackPressListener = BackHandler.addEventListener('hardwareBackPress', backHandler);
    }

    const location = useSelector(getGlobalLocation);

    useEffect(() => {
        actions.fetchDrugsByKeyword(data?.keyword ?? suggestionsKeyword);
    }, [location]);

    return (
        <SectionList
            sectionStyle={{
                borderTopWidth: 0,
                paddingHorizontal: 12,
            }}
            listContainerStyle={{ borderTopWidth: 0 }}
            title="drugs"
            type="drugs"
            count={data && data.length}
            right={drugsAnaloguesFilter}
            isLoading={data.isLoading}
            data={data.data}
            error={data.error}
            renderItem={renderDrugItem(undefined, (item) => {
                actions.addDrugInHistory(Object.assign(item));
                navigate('DrugDetails', {
                    drug: item,
                    from: 'drugDetails',
                });
            })}
        />
    );
});

export const SearchDrugs = Page.register(
    () => {
        const isFocused = useIsFocused();
        const dispatch = useDispatch();

        const keyword = useSelector(getSearchDrugsKeyword);
        const shouldReload = useSelector(getSearchDrugsShouldReload);
        const suggestionsKeyword = useSelector(getSearchSuggestionsKeyword);
        const navigation = useNavigation();

        useEffect(() => {
            return navigation.addListener('focus', () => {
                setTimeout(() => {
                    dispatch(setSearchSuggestionsKeyword(''));
                    dispatch(setSearchKeyword(''));
                }, 500);
            });
        }, [navigation]);

        return (
            <Page name="search-drugs" hideOnBackground noscroll>
                <AutocompleteSearchBar
                    autoCorrect={false}
                    searchAfter={2}
                    suggestionsRequest={(enteredKeyword) => {
                        actions.setSearchSuggestionsKeyword(enteredKeyword);
                        return getDrugsSuggestions(getNormalizedKeyword(enteredKeyword));
                    }}
                    onSuggestionSelected={(suggestion) => {
                        dispatch(fetchDrugsByKeyword(suggestion));
                    }}
                    value={keyword}
                    keyword={keyword}
                    shouldReload={shouldReload}
                    placeholder="placeholders.search_drugs"
                    onClearClick={() => {
                        dispatch(setRemoteKeyword(''));
                    }}
                />
                {Platform.OS !== 'web' ? (
                    <RText
                        id={getTextWithAuth('titles.useFirstAidKit', 'titles.useFirstAidKitNonAuth')}
                        style={{
                            marginHorizontal: 12,
                            marginTop: 12,
                            marginBottom: 6,
                        }}
                        numberOfLines={3}
                    />
                ) : null}
                {!keyword || keyword.length < 3 ? (
                    <SearchDrugsHistory />
                ) : (
                    <SearchDrugsResults
                        isFocus={isFocused}
                        suggestionsKeyword={getNormalizedKeyword(suggestionsKeyword)}
                        shouldReload={shouldReload}
                    />
                )}
            </Page>
        );
    },
    // properties descriptors
    // {
    //     // keyword: { from: getSearchDrugsKeyword },
    //     // shouldReload: { from: getSearchDrugsShouldReload },
    //     // suggestionsKeyword: { from: getSearchSuggestionsKeyword },
    // },
    undefined,
    () => ({
        header: () => (
            <MainHeader right={<FirstAidKitIcon />}>
                <CitySelector />
            </MainHeader>
        ),
    }),
);
