import { useEffect, useState } from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';
import {
    Icon, Page, Platform, React, Subtitle, Text, Title, TouchableOpacity, View,
} from '../../../common';
import { CoverableByBig } from '../../../common/Img';
import { elementsStyles } from '../../../styles/elements';
import { COLORS } from '../../../styles/colors';
import { formatDate } from '../../../utils';
import { ResponseToFeedbackList, showMenu } from '../MedCenters/FeedbacksListItem';
import { getShortUserName } from '../../../utils/users';
import { Modal3 } from '../../../common/Modal';
import actions from '../../../actions';
import { LikeButton } from '../../../common/LikeButton';

export const MAX_FEEDBACK_LENGTH_WEB = 200;

export const FeedbackBadge = ({ vote, style, onPress }) => {
    const votes = {
        RECOMMEND: {
            color: '#118A72',
            backgroundColor: '#E0F4F0',
            title: 'titles.positiveFeedbackShort',
            explainText: 'positive',
        },
        NEUTRAL: {
            color: '#646464',
            backgroundColor: '#EFEFEF',
            title: 'titles.neutralFeedbackShort',
            explainText: 'neutral',
        },
        NOT_RECOMMEND: {
            color: '#DF655C',
            backgroundColor: '#FFF4F3',
            title: 'titles.negativeFeedbackShort',
            explainText: 'negative',
        },
        NOT_CONFIRMED: {
            color: '#B67A17',
            backgroundColor: '#FEECCE',
            title: 'titles.notConfirmedFeedbackShort',
            explainText: 'notConfirmed',
        },
    };
    const voteItem = votes[vote];
    if (!voteItem) {
        return null;
    }
    return (
        // eslint-disable-next-line no-use-before-define
        <TouchableOpacity onPress={() => (onPress ? onPress() : explainFeedbackModal(votes[vote]?.explainText))}>
            <View
                style={{
                    backgroundColor: voteItem.backgroundColor,
                    borderRadius: 12,
                    paddingVertical: 2,
                    paddingHorizontal: 8,
                    alignSelf: 'flex-start',
                    borderWidth: 1,
                    borderColor: voteItem.backgroundColor,
                    ...style,
                }}>
                <Text
                    style={{
                        fontSize: 12,
                        lineHeight: 15,
                        fontWeight: '400',
                        color: voteItem.color,
                    }}>
                    {Object.R(voteItem.title)}
                </Text>
            </View>
        </TouchableOpacity>
    );
};

export const FeedbackCoverers = ({ userCoverers }) => {
    if (!userCoverers) {
        return null;
    }
    let cover;
    if (Array.isArray(userCoverers)) {
        if (!userCoverers.length) {
            return null;
        }
        cover = userCoverers.map((item, index) => (
            <View
                style={{
                    paddingLeft: index ? 5 : 0,
                    paddingTop: 0,
                    marginTop: 0,
                }}>
                <CoverableByBig coverer={item} />
            </View>
        ));
    } else {
        cover = <CoverableByBig coverer={userCoverers} />;
    }
    if (cover) {
        return (
            <View
                style={{
                    paddingLeft: 16,
                    flexDirection: 'row',
                }}>
                {cover}
            </View>
        );
    }
    return null;
};

export const FeedbackListItem = ({
    bold = false,
    userName = '',
    date = new Date(),
    vote = '',
    feedbackText = '',
    showBorder = false,
    confirmed = false,
    userCoverers = '',
    interaction = false,
    responses,
    my = false,
    usefulCount,
    usefulForMe,
    onSetUseful = () => {},
    onComplain,
    created,
    doctorInfo,
    editedMode = 'all', // "responses"
}) => {
    const [expand, setExpand] = useState(false);
    const boldStyle = bold ? elementsStyles.text_bold : {};

    const getText = (text, exp) => {
        if (Platform.OS !== 'web' || exp || text?.length < MAX_FEEDBACK_LENGTH_WEB) {
            return text;
        }
        return `${text.trim().substring(0, MAX_FEEDBACK_LENGTH_WEB)}...`;
    };

    const isEdited = () => {
        if (editedMode === 'all') {
            try {
                const diff = new Date(date).getTime() - new Date(created).getTime();
                return diff > 1000;
            } catch (e) {
                return false;
            }
        }
        if (!Array.isArray(responses) || !responses.length) {
            return false;
        }

        const lastResponse = responses.sort((a, b) => {
            try {
                const da = new Date(a.updatedAt);
                const db = new Date(b.updatedAt);
                if (da > db) {
                    return 1;
                } else if (db > da) {
                    return -1;
                }
            } catch (e) {
                // console.log('Error sort date', { a, b });
            }
            return 0;
        })[0];
        if (!lastResponse) {
            return false;
        }
        return new Date(lastResponse.updatedAt).getTime() < new Date(date).getTime();
    };

    return (
        <View
            style={{
                paddingBottom: 16,
                borderColor: '#E9E9E9',
                borderBottomWidth: showBorder ? 1 : 0,
            }}>
            <View
                style={{
                    flexDirection: 'row',
                    paddingTop: 18,
                }}>
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        flex: 3,
                    }}>
                    {doctorInfo ? (
                        <Title bold>{doctorInfo.name ?? ''}</Title>
                    ) : (
                        <Text
                            style={{
                                fontSize: 16,
                                lineHeight: 17,
                                fontWeight: '400',
                                ...boldStyle,
                                color: COLORS.STANDARD_GRAY,
                                paddingTop: 2,
                            }}>
                            {my ? getShortUserName(userName) : userName}
                        </Text>
                    )}
                    <FeedbackCoverers userCoverers={userCoverers} />
                </View>
                <View
                    style={{
                        alignItems: 'flex-end',
                        flex: 1,
                        justifyContent: 'flex-end',
                    }}>
                    <Text
                        style={{
                            color: '#9C9C9C',
                            fontSize: 12,
                            lineHeight: 20,
                            fontWeight: '400',
                        }}>
                        {formatDate(date)}
                    </Text>
                    {
                        // (!confirmed && !!vote) && isEdited() && <View style={{ alignItems: 'flex-end' }}>
                        //     <Text
                        //         style={{
                        //             fontSize: 12,
                        //             color: COLORS.DARK_GRAY,
                        //         }}>{Object.R('title.feedbackIsEdited')}</Text>
                        // </View>
                    }
                </View>
            </View>
            {!!doctorInfo && (
                <View>
                    <Text
                        style={[
                            {
                                marginTop: 4,
                                fontSize: 12,
                                lineHeight: 15,
                                fontWeight: '400',
                                color: COLORS.STANDARD_GRAY,
                            },
                        ]}>
                        {doctorInfo?.specialization}
                    </Text>
                </View>
            )}

            <View
                style={{
                    paddingTop: 14,
                    flexDirection: !confirmed && isEdited() && vote ? 'row' : 'row',
                    // justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    // borderWidth: 1,
                }}>
                <View
                    style={{
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        alignItems: 'flex-start',
                        flex: 1,
                        padding: 0,
                        margin: 0,
                    }}>
                    <FeedbackBadge
                        vote={vote}
                        style={{
                            marginBottom: confirmed ? 0 : 5,
                            marginRight: vote === '' ? 0 : 5,
                        }}
                    />
                    {!confirmed && (
                        <>
                            <FeedbackBadge vote="NOT_CONFIRMED" />
                        </>
                    )}
                </View>
                {
                    // (!confirmed && !!vote) &&
                    isEdited() && (
                        <View style={{ alignItems: 'flex-end' }}>
                            <Text
                                style={{
                                    fontSize: 12,
                                    color: COLORS.DARK_GRAY,
                                }}>
                                {Object.R('title.feedbackIsEdited')}
                            </Text>
                        </View>
                    )
                }
            </View>
            {feedbackText ? (
                <View style={{ paddingTop: 12 }}>
                    {interaction ? (
                        <TouchableOpacity onPress={() => setExpand(prevState => !prevState)}>
                            <Text
                                numberOfLines={expand ? 0 : 5}
                                style={{
                                    fontSize: 14,
                                    lineHeight: 18,
                                    color: COLORS.STANDARD_GRAY,
                                }}>
                                {getText(feedbackText, expand)}
                            </Text>
                        </TouchableOpacity>
                    ) : (
                        <Text
                            numberOfLines={5}
                            style={{
                                fontSize: 14,
                                lineHeight: 18,
                            }}>
                            {feedbackText}
                        </Text>
                    )}
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}>
                        <>
                            <LikeButton
                                title={
                                    usefulCount
                                        ? `${Object.R('button.useful')} (${usefulCount})`
                                        : Object.R('button.useful')
                                }
                                onPress={() => !my && onSetUseful()}
                                selected={usefulForMe}
                                disabled={!!my}
                            />
                            {!my && typeof onComplain === 'function' && (
                                <TouchableOpacity
                                    style={{
                                        width: 24,
                                        height: 24,
                                        marginTop: 12,
                                    }}
                                    onPress={onComplain}>
                                    <Icon.Danger />
                                </TouchableOpacity>
                            )}
                        </>
                    </View>
                </View>
            ) : (
                my && (
                    <Text
                        style={{
                            fontSize: 14,
                            lineHeight: 17,
                            fontWeight: '400',
                            color: '#979797',
                            paddingTop: 16,
                        }}>
                        {Object.R('titles.emptyMyFeedback')}
                    </Text>
                )
            )}
            {responses?.length ? <ResponseToFeedbackList data={responses} /> : null}
        </View>
    );
};

export const MyFeedbackItem = ({
    item, needEdit = true, bottomBorderWidth = 0, interraction, doctorInfo,
}) => {
    try {
        const {
            userName = '',
            feedbackText = '',
            vote = '',
            // forItemId: id,
            anonymous = true,
            updatedAt = new Date().toISOString(),
            // ...rest
            status = '',
            userCoverers = [],
            responses,
            usefulCount,
            _id,
            usefulForMe,
        } = item;
        return (
            <View
                style={{
                    flexDirection: 'column',
                    paddingHorizontal: 1,
                    paddingVertical: 20,
                }}>
                <View
                    style={{
                        flexDirection: 'row',
                        height: 25,
                    }}>
                    <View
                        style={{
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            flex: 1,
                        }}>
                        <Text
                            style={{
                                fontSize: 16,
                                lineHeight: 17,
                                fontWeight: '600',
                                ...elementsStyles.text_bold,
                                color: COLORS.STANDARD_GRAY,
                            }}>
                            {doctorInfo?.name ? doctorInfo?.name : Object.R('titles.YouRecommendation')}
                        </Text>
                    </View>
                    <View
                        style={{
                            alignItems: 'flex-end',
                            flex: 1,
                            padding: 0,
                            margin: 0,
                        }}>
                        <View
                            style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                            }}>
                            <Icon.Menu onPress={() => showMenu(item, needEdit, true)} />
                        </View>
                    </View>
                </View>
                <View
                    style={{
                        borderBottomWidth: bottomBorderWidth,
                        borderColor: '#E9E9E9',
                    }}>
                    <FeedbackListItem
                        feedbackText={feedbackText}
                        vote={vote}
                        date={updatedAt}
                        userName={anonymous ? Object.R('feedback.anonumous') : userName}
                        confirmed={status === 'CONFIRMED'}
                        userCoverers={anonymous ? null : userCoverers}
                        interaction={interraction}
                        responses={responses}
                        my
                        usefulCount={usefulCount}
                        usefulForMe={usefulForMe}
                        onSetUseful={() => {
                            actions.modifyUseful({
                                id: _id,
                                value: !usefulForMe,
                            });
                        }}
                    />
                </View>
            </View>
        );
    } catch {
        return null;
    }
};

export const MyFeedback = ({ item, interaction, doctorInfo }) => {
    if (Array.isArray(item)) {
        return (
            <>
                {item.map((feedback, idx) => (
                    <MyFeedbackItem
                        item={feedback}
                        needEdit={!idx}
                        bottomBorderWidth={idx === item.length - 1 ? 0 : 1}
                        interraction={interaction}
                        key={item?.id}
                    />
                ))}
            </>
        );
    }
    return <MyFeedbackItem item={item} bottomBorderWidth={0} interraction={interaction} doctorInfo={doctorInfo} />;
};

export const Feedback = ({
    recommendCount = 0,
    notRecommendCount = 0,
    neutralCount = 0,
    hide = false,
    style = {},
    iconSize = 18,
    textStyle: txt = {},
}) => {
    if (hide) {
        return null;
    }
    const countVotes = neutralCount + recommendCount + notRecommendCount;
    const textStyle = {
        fontSize: 12,
        lineHeight: 15,
        fontWeight: '400',
    };
    return (
        <View
            style={{
                ...style,
                flexDirection: 'row',
                alignItems: 'center',
            }}>
            <Icon.RateDoctor color={COLORS.MAIN} size={iconSize} />
            <Text
                style={{
                    flexDirection: 'row',
                    marginLeft: 4,
                    ...txt,
                }}
                numberOfLines={0}>
                {countVotes ? (
                    <>
                        <Text style={{ color: COLORS.POSITIVE, ...textStyle }}>+{recommendCount}</Text>
                        <Text style={{ color: '#979797', ...textStyle }}>/</Text>
                        <Text style={{ color: COLORS.NEGATIVE, ...textStyle }}>-{notRecommendCount}</Text>
                        <Text style={{ color: COLORS.STANDARD_GRAY, ...textStyle }}>
                            {' '}
                            {Object.R('titles.countVotes', { countVotes })}
                        </Text>
                    </>
                ) : (
                    <Text style={{ color: '#555555', ...textStyle }}>{Object.R('titles.noVotes')}</Text>
                )}
            </Text>
        </View>
    );
};

export const FeedbackItem = ({
    title = '',
    value = 0,
    color = '#C4C4C4',
    onChange,
    selected = false,
    textId = '',
    up = false,
    down = false,
}) => {
    const [isSelected, setIsSelected] = useState(selected);
    const pressHandler = () => {
        if (onChange) {
            onChange(value);
        }
    };

    useEffect(() => {
        setIsSelected(selected);
    }, [selected]);

    let text = textId ? Object.R(textId) : '';

    if (text === textId) {
        text = '';
    }

    const BORDER_RADIUS = 3;

    // eslint-disable-next-line no-nested-ternary
    const leftRadius = up
        ? { borderTopStartRadius: BORDER_RADIUS }
        : down
            ? { borderBottomStartRadius: BORDER_RADIUS }
            : {};
    // eslint-disable-next-line no-nested-ternary
    const rightRadius = up
        ? { borderTopEndRadius: BORDER_RADIUS }
        : down
            ? { borderBottomEndRadius: BORDER_RADIUS }
            : {};

    return (
        <TouchableOpacity onPress={pressHandler}>
            <View
                style={{
                    flexDirection: 'row',
                    margin: 0,
                    alignItems: 'flex-start',
                    ...leftRadius,
                    ...rightRadius,
                }}>
                <View
                    style={{
                        backgroundColor: color,
                        width: 5,
                        height: '100%',
                        borderWidth: 0,
                        borderColor: color,
                        ...leftRadius,
                    }}
                />
                <View
                    style={{
                        flexDirection: 'row',
                        margin: 0,
                        padding: 0,
                        borderWidth: Math.abs(value),
                        borderRightWidth: 1,
                        borderLeftWidth: 0,
                        borderColor: '#E9E9E9',
                        flex: 1,
                        ...rightRadius,
                    }}>
                    <Icon.Radio
                        style={{
                            paddingLeft: 15,
                            paddingTop: 16,
                        }}
                        color={selected ? COLORS.MAIN : '#999999'}
                        checked={isSelected}
                    />
                    <View
                        style={{
                            flexDirection: 'column',
                            paddingLeft: 10,
                            paddingVertical: 10,
                            flex: 1,
                            paddingTop: 12,
                        }}>
                        <Text
                            style={{
                                fontSize: 16,
                                color: COLORS.STANDARD_GRAY,
                            }}>
                            {title}
                        </Text>
                        {text && selected ? (
                            <Text
                                style={{
                                    fontSize: 14,
                                    paddingTop: 10,
                                    color: COLORS.STANDARD_GRAY,
                                    marginRight: 14,
                                }}
                                numberOfLines={0}>
                                {text}
                            </Text>
                        ) : null}
                    </View>
                </View>
            </View>
        </TouchableOpacity>
    );
};

const DoctorFeedbackChartItem = ({
    count = 0, color = '#9C9C9C', width = 0, titleId = '',
}) => {
    const backgroundColor = '#E0E1E1';
    return (
        <View style={{ paddingTop: 16 }}>
            {/* <TouchableOpacity onPress={onPress}> */}
            <View
                style={{
                    flexDirection: 'row',
                    paddingBottom: 6,
                }}>
                <View style={{ flex: 4 }}>
                    <Text
                        style={{
                            textAlign: 'left',
                            color: COLORS.STANDARD_GRAY,
                            fontSize: 14,
                            lineHeight: 20,
                            fontWeight: '400',
                        }}>
                        {Object.R(titleId)}
                    </Text>
                </View>
                <View style={{ flex: 1 }}>
                    <Text
                        style={{
                            color: count ? color : COLORS.STANDARD_GRAY,
                            textAlign: 'right',
                            fontSize: 14,
                            lineHeight: 20,
                            fontWeight: '400',
                        }}>
                        {count}
                    </Text>
                </View>
            </View>
            <View
                style={{
                    borderRadius: 8,
                    height: 8,
                    flex: 1,
                    backgroundColor,
                }}>
                {count ? (
                    <View
                        style={{
                            backgroundColor: color,
                            width: width ? `${width}%` : 8,
                            padding: 0,
                            margin: 0,
                            borderRadius: 8,
                            height: 8,
                        }}>
                        <Text> </Text>
                    </View>
                ) : (
                    <Text style={{ height: 8 }}> </Text>
                )}
            </View>
            {/* </TouchableOpacity> */}
        </View>
    );
};

const howToFeedbacks = () => {
    Page.showModal(
        <Modal3 title="titles.howToFeedbacksTitle" buttonCloseTitle="button.understand">
            <View>
                <Subtitle
                    id="titles.howToFeedbacksText"
                    style={{
                        padding: 20,
                        fontSize: 14,
                    }}
                    numberOfLines={0}
                />
                {/* <Text style={{ padding: 10, paddingHorizontal: 25, color: COLORS.STANDARD_GRAY, lineHeight: 20 }}>{Object.R('titles.howToFeedbacksText')}</Text> */}
            </View>
        </Modal3>,
    );
};

const explainFeedbackModal = (rate = 'neutral') => {
    const title = `titles.${rate}FeedbackModal`;
    const text = `titles.${rate}FeedbackExplainModal`;
    Page.showModal(
        <Modal3
            title={title}
            noScroll
            onRequestClose={() => Page.closeModal()}
            withOverlay
            buttonCloseTitle="button.understand">
            <Subtitle
                id={text}
                style={{
                    margin: 0,
                    paddingHorizontal: 12,
                    paddingTop: 0,
                    paddingBottom: 20,
                    fontSize: 14,
                }}
                numberOfLines={0}
            />
        </Modal3>,
    );
};

export const DoctorFeedbackChart = ({ positive = 0, neutral = 0, negative = 0 }) => {
    const total = positive + neutral + negative;
    const positiveWidth = total ? Math.round((positive / total) * 100) : 0;
    const neutralWidth = total ? Math.round((neutral / total) * 100) : 0;
    const negativeWidth = total ? Math.round((negative / total) * 100) : 0;
    const styles = EStyleSheet.create({
        header: {
            color: COLORS.STANDARD_GRAY,
            fontSize: 16,
            fontWeight: '600',
            lineHeight: 17,
            // paddingTop: 4,
            fontFamily: '$sourceSansPropFontFamily_semibold',
        },
    });
    return (
        <View
            style={{
                flexDirection: 'column',
                padding: 1,
                paddingVertical: 9,
            }}>
            <View
                style={{
                    flexDirection: 'column',
                    flex: 1,
                }}>
                <Text style={styles.header}>
                    {total ? Object.R('titles.feedbackChartHeader') : Object.R('titles.firstFeedbacks')}
                </Text>
                <TouchableOpacity
                    onPress={() => {
                        howToFeedbacks();
                    }}>
                    <View
                        style={{
                            flexDirection: 'row',
                            padding: 0,
                            margin: 0,
                            alignItems: 'center',
                            alignContent: 'center',
                            paddingTop: 8,
                        }}>
                        <Text
                            style={{
                                color: '#979797',
                                fontSize: 12,
                                fontWeight: '400',
                                lineHeight: 15,
                            }}>
                            {Object.R('titles.feedbacksHowTo')}
                        </Text>
                        <Icon.Question size={13} style={{ marginLeft: 8 }} />
                    </View>
                </TouchableOpacity>
            </View>
            <DoctorFeedbackChartItem
                width={positiveWidth}
                count={positive}
                color="#18B797"
                titleId="titles.positiveFeedback"
            />
            <DoctorFeedbackChartItem width={neutralWidth} count={neutral} titleId="titles.neutralFeedback" />
            <DoctorFeedbackChartItem
                width={negativeWidth}
                count={negative}
                color="#E2655C"
                titleId="titles.negativeFeedback"
            />
        </View>
    );
};

export const SetFeedback = ({ selected, onChange, disabled }) => {
    const [isSelected, setIsSelected] = useState(selected);
    const [isDisabled, setIsDisabled] = useState(disabled);

    const clickHandler = (value) => {
        if (onChange && !isDisabled) {
            setIsSelected(value);
            onChange(value);
        }
    };

    useEffect(() => {
        setIsSelected(selected);
    }, [selected]);
    useEffect(() => {
        setIsDisabled(disabled);
    }, [disabled]);

    const style = EStyleSheet.create({
        header: {
            color: COLORS.STANDARD_GRAY,
            lineHeight: 17,
            fontWeight: '600',
            fontFamily: '$sourceSansPropFontFamily_semibold',
            paddingVertical: 10,
            fontSize: 16,
        },
    });

    return (
        <View style={{ paddingVertical: 5 }}>
            <Text style={style.header}>{Object.R('titles.recommendationHeader')}</Text>
            <Text
                numberOfLines={0}
                style={{
                    fontSize: 14,
                    color: '#9C9C9C',
                    paddingBottom: 10,
                }}>
                {Object.R('titles.recommendationText')}
            </Text>
            <View style={{ marginVertical: 10 }}>
                <View
                    style={{
                        flexDirection: 'column',
                        padding: 0,
                        backgroundColor: '#FFF',
                        borderRadius: 3,
                    }}>
                    <FeedbackItem
                        title={Object.R('titles.positiveFeedback')}
                        textId="titles.positiveFeedbackExplain"
                        value={1}
                        color={COLORS.POSITIVE}
                        onChange={clickHandler}
                        selected={isSelected === 1}
                        up
                    />
                    <FeedbackItem
                        title={Object.R('titles.neutralFeedback')}
                        textId="titles.neutralFeedbackExplain"
                        value={0}
                        onChange={clickHandler}
                        selected={isSelected === 0}
                    />
                    <FeedbackItem
                        title={Object.R('titles.negativeFeedback')}
                        textId="titles.negativeFeedbackExplain"
                        value={-1}
                        color={COLORS.NEGATIVE}
                        onChange={clickHandler}
                        selected={isSelected === -1}
                        down
                    />
                </View>
            </View>
        </View>
    );
};
