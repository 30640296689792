

import { useSelector } from 'react-redux';
import {
    Icon, React, Title, TouchableWithoutFeedback, View, Platform,
} from '../../common';
import { TabSwitch } from '../../common/TabSwitch';
import { modal as styles } from '../../common/style';
import { getMapType } from '../../selectors';
import { COLORS } from '../../styles/colors';
import { colorScaleStyles, switchMapModalStyles } from './styles';

export const ColorScale = React.memo(
    ({
        maxValue,
        minValue,
        colors,
        onPress,
        unit,
        fixedValues,
        hasCorrectValues = fixedValues && fixedValues.length === colors.length,
    }) => (
        <TouchableWithoutFeedback onPress={onPress}>
            <View style={[colorScaleStyles.scale, { backgroundColor: colors[colors.length - 1] }]}>
                {colors.map((color, i) => (
                    <View
                        key={color}
                        style={[
                            colorScaleStyles.color,
                            { backgroundColor: color },
                            hasCorrectValues && colorScaleStyles.fixedValueWrapper,
                        ]}>
                        {hasCorrectValues ? <Title style={colorScaleStyles.valueText}>{fixedValues[i]}</Title> : null}
                    </View>
                ))}
                {hasCorrectValues ? null : (
                    <View style={colorScaleStyles.values}>
                        <Title style={colorScaleStyles.valueText}>{`${minValue} ${unit}`}</Title>
                        <Title style={colorScaleStyles.valueText}>{`${maxValue} ${unit}`}</Title>
                    </View>
                )}
            </View>
        </TouchableWithoutFeedback>
    ),
);

export const SwitchMapModal = ({ onChange, onClose, isWeb = Platform.OS === 'web' }) => {

    const mp = useSelector(getMapType);
    return (
        <TouchableWithoutFeedback onPress={onClose}>
            <View style={switchMapModalStyles.switchWrapper}>
                <View
                    style={[styles.container, switchMapModalStyles.switch, isWeb ? { marginTop: 12 } : {}]}
                    accessibilityLabel="modal">
                    <View style={switchMapModalStyles.header}>
                        <Title id="titles.layers" style={switchMapModalStyles.headerTitle} />
                        <Icon name="cross" onPress={onClose} style={switchMapModalStyles.headerCrossIcon} />
                    </View>
                    <TabSwitch
                        containerStyle={[switchMapModalStyles.tabSwitch, isWeb ? { width: '95%' } : {}]}
                        data={[
                            {
                                id: 'standard',
                                isActive: mp === 'standard',
                                name: Object.R('titles.layersSwitchStandard'),
                            },
                            {
                                id: 'hybrid',
                                isActive: mp === 'hybrid',
                                name: Object.R('titles.layersSwitchHybrid'),
                            },
                            {
                                id: 'satellite',
                                isActive: mp === 'satellite',
                                name: Object.R('titles.layersSwitchSatellite'),
                            },
                        ]}
                        onChange={onChange}
                    />
                </View>
            </View>
        </TouchableWithoutFeedback>
    );

};

export const UserLocationMarkerWeb = () => {

    return (
        <View
            style={{
                width: 12,
                height: 12,
                background: COLORS.MAIN,
                borderRadius: 50,
                borderColor: 'white',
                borderWidth: 1,
                shadowColor: COLORS.DARK_GRAY,
                shadowOpacity: 0.7,
                shadowRadius: 5,
                elevation: 5,
            }}
        />
    );

};
