import EStyleSheet from 'react-native-extended-stylesheet';
import { AUTO_SWITCH_TO_FUZZY_LENGTH, DEFAULT_SEARCH_FUNCTION, searchTextSplitter } from '../utils/strings';
import { elementsStyles as styles } from '../styles/elements';
import { React, Text, View } from './react';
import { textStyleResolver, Title } from './elements';

const titleStyle = EStyleSheet.create({
    title: {
        color: '$darkGrayTextColor',
        fontFamily: '$sourceSansPropFontFamily',
        fontSize: 16,
    },
});
const MarkedText = ({
    text, backgroundColor, style, ...flags
}) => (
    <Text style={[titleStyle.title, style, textStyleResolver(flags), { backgroundColor }]}>{text}</Text>
);
// <Title style={[style, { backgroundColor }]}>{text}</Title>

const NoMarkedText = ({ style, text, ...flags }) => (
    <Text
        style={[styles.headLine, titleStyle.title, style, textStyleResolver(flags)]}
        numberOfLines={0}
        ellipsizeMode="tail">
        {text}
    </Text>
);

// eslint-disable-next-line max-statements
export const TextHighliter = ({
    value = '',
    keyword = '',
    backgroundColor = '#B1EFE2',
    containerStyle,
    style,
    wrap = 'nowrap',
    numberOfLines = 1,
    switchToFuzzy = AUTO_SWITCH_TO_FUZZY_LENGTH,
    searchFunction = DEFAULT_SEARCH_FUNCTION,
    textStyle,
    ...props
}) => {
    const keyWords = keyword?.trim?.()?.split?.(' ');
    let result;
    if (keyword?.trim?.()?.length && keyWords?.length >= 1) {
        const texts = searchTextSplitter(value, keyword?.split?.(' '), searchFunction, switchToFuzzy);
        result = texts?.map?.((item, index) => {
            const addStyle = [style, { flexWrap: 'wrap' }, textStyleResolver(props)];
            return item.selected ? (
                // eslint-disable-next-line react/no-array-index-key
                <MarkedText
                    text={item.text}
                    backgroundColor={backgroundColor}
                    style={addStyle}
                    key={`key-selected-${index}`}
                />
            ) : (
                // eslint-disable-next-line react/no-array-index-key
                <NoMarkedText style={addStyle} key={`key-${index}`} text={item.text} />
            );
        });
    } else {
        result = <Text style={[titleStyle.title, style, textStyleResolver(props)]}>{value}</Text>;
    }

    return (
        <View
            style={[
                containerStyle,
                {
                    flexDirection: 'row',
                    flexWrap: wrap,
                },
            ]}>
            <Title style={[{ flex: 1 }, textStyle]} numberOfLines={numberOfLines}>
                {result}
            </Title>
        </View>
    );
};
