/**
 * Constants.
 */

export const DOCTOR_STUB = Object.freeze({
    fullName: 'Неизвестен',
    worksAt: [],
    specialization: '',
});

export const EMPTY_ARRAY = Object.freeze([]);
export const EMPTY_OBJECT = Object.freeze({});
// noinspection JSUnusedGlobalSymbols
export const EMPTY_MAP = new Map();
