import {
    COUNTRY_CODE,
    COUNTRY_OPERATORS,
    COUNTRY_PHONE_LENGTH,
    COUNTRY_TEST_OPERATORS,
    MODE,
    SIGNIN_MASK,
} from '../config';

export function extractPhoneNumber(phoneData) {
    const phoneDataWithoutTime = phoneData.replace(/(\(([a-zA-Z:\s\u0430-\u044f\u0410-\u042f]+-*\.*\d*)+\))/g, '');
    return phoneDataWithoutTime
        .replace(/([a-zA-Z:\s\u0430-\u044f\u0410-\u042f]+-*\(*\)*\/*\.*\/*)/g, '')
        .replace(/[()-]/g, '')
        .trim();
}

export const formatPhoneNumber = ({ number }) =>
    (number || '').replace(/\D/g, '').replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5');

export const getMaskByPhonePrefix = (prefix) => {
    // Use extra digits to prevent digits loss when changing to smaller mask
    // console.log('MASK', SIGNIN_MASK.replace(/0/g, '9').replace(/[\[\]]/g, ''));
    return SIGNIN_MASK.replace(/0/g, '9').replace(/[\[\]]/g, '');
    switch (prefix.length) {
        case 1:
            return '(999) 999 99 99';
        case 2:
            return '(999) 99 99 999';
        case 4:
            return '999 99 99999';
        default:
            return '(99) 999 99 99';
    }
};

export const maskNumbers = (mask, numbers) => {
    let finalNumber = '';
    for (let i = 0; i < mask.length; i++) {
        const item = mask[i];
        if (item === '9') {
            if (!numbers.length) {
                break;
            }
            finalNumber += numbers[0];
            // eslint-disable-next-line no-param-reassign
            numbers = numbers.slice(1);
        } else {
            finalNumber += item;
        }
    }
    // console.log('Mask numbers', { mask, numbers, finalNumber });
    return finalNumber;
};

export const isPhoneNumberValidOld = (number = '') => {
    try {
        if (
            MODE !== 'prod' &&
            (number.match(/(\+375\(55\) [0-9]{3} [0-9]{2} [0-9]{2})/) ||
                number.replace(/[ ()]/g, '').match(/^\+375(22|25|29|33|44|55|65)\d{7}$/))
        ) {
            return true;
        }
        return number.replace(/[ ()]/g, '').match(/^\+375(22|25|29|33|44|65)\d{7}$/);
    } catch (error) {
        return false;
    }
};

export const defaultValidationRules = [
    {
        countryCode: COUNTRY_CODE,
        numberLengthMin: MODE === 'uat' ? 11 : COUNTRY_PHONE_LENGTH,
        numberLengthMax: MODE === 'uat' ? 12 : COUNTRY_PHONE_LENGTH,
        prefixes: COUNTRY_OPERATORS,
        testPrefixes: COUNTRY_TEST_OPERATORS,
    },
];

export const isPhoneNumberValid = (number = '', validationRules = defaultValidationRules, mode = MODE ?? 'prod') => {
    try {
        // Remove spaces, parentheses, and dashes for uniformity
        const sanitizedNumber = number.replace(/[ ()-]/g, '');
        // Check if the number starts with a '+' and remove it
        const strippedNumber = sanitizedNumber.startsWith('+') ? sanitizedNumber.slice(1) : sanitizedNumber;

        if (MODE === 'uat') {
            const totalLength = strippedNumber.length;

            if (
                totalLength >= defaultValidationRules?.[0]?.numberLengthMin &&
                totalLength <= defaultValidationRules?.[0]?.numberLengthMax
            ) {
                return true;
            }
            return false;
        }

        // Find the first matching rule based on the country code
        const rule = validationRules.find(({ countryCode }) => {
            const countryCodeDigits = countryCode.replace(/[\s+]/g, '');
            return strippedNumber.startsWith(countryCodeDigits);
        });

        if (!rule) {
            return false; // No matching rule found
        }

        const { countryCode, numberLengthMin, numberLengthMax, prefixes, testPrefixes } = rule;
        const countryCodeDigits = countryCode.replace(/[\s+]/g, '');
        const numberWithoutCountryCode = strippedNumber.slice(countryCodeDigits.length);

        // Check if the length of the entire number fits within the specified range
        const totalLength = strippedNumber.length;
        if (totalLength < numberLengthMin || totalLength > numberLengthMax) {
            return false;
        }

        // Check if the number starts with a valid prefix (if prefixes are provided)
        const isValidPrefix =
            prefixes.length === 0 || prefixes.some((prefix) => numberWithoutCountryCode.startsWith(prefix));

        // Check test prefixes only if not in production mode (if testPrefixes are provided)
        const isValidTestPrefix =
            testPrefixes.length === 0 || testPrefixes.some((prefix) => numberWithoutCountryCode.startsWith(prefix));

        if (mode !== 'prod') {
            return isValidPrefix || isValidTestPrefix;
        }
        return isValidPrefix; // In production mode, only valid prefixes are allowed
    } catch (error) {
        return false;
    }
};

export const isTestPhoneNumber = (number, rules = defaultValidationRules) => {
    const sanitizedNumber = number.replace(/[ ()-+]/g, '');
    return rules.some((rule) => {
        return rule.testPrefixes.some((prefix) => {
            return sanitizedNumber.startsWith(rule.countryCode.replace(/[\s+]/g, '') + prefix);
        });
    });
};
