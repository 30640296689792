import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Page } from '../../../common';
import { DrugsStoresList } from '../DrugstoresList';
import { applyFAKIcon } from '..';
import { setFormData } from '../../../actions/forms';
import { getFormData } from '../../../selectors/forms';

export const DrugDetails = applyFAKIcon(
    ({
        navigation,
        route: { params: { drug, sortBy = 'totalPrice', from = 'drugDetails' } },
    }) => {
        const dispatch = useDispatch();
        const forms = useSelector(getFormData);
        const back = () => {
            if (from === 'basket') {
                navigation.pop();
                navigation.navigate('FirstAidKit');
                return false;
            }
            navigation.goBack();
            return false;
        };
        // useEffect(() => {
        if (!forms?.DrugDetails?.back) {
            dispatch(
                setFormData({
                    DrugDetails: {
                        back,
                        logName: 'DrugDetails',
                    },
                }),
            );
        }
        // }, [dispatch, setFormData, back]);

        return (
            <Page name="drug-details">
                {/* <DrugInfo drug={drug} /> */}
                <DrugsStoresList
                    drugs={[drug]}
                    navigation={navigation}
                    sortBy={sortBy}
                    from={from}
                    firstAidKit={from === 'basket'}
                />
            </Page>
        );
    },
);
