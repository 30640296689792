import { autocompleteSearchBar as autocompleteStyle, searchBar as style } from '../styles/input';
import actions, { navigate } from '../actions';
import {
    Platform, React, SearchBarNative, TouchableWithoutFeedback, View,
} from './react';
import { Icon } from './Icon';
import { TextHighliter } from './TextHighliter';
import { withState } from './_hoc';
import { SectionList } from './List';
import { ErrorStub } from './Stub';
import { InputWrapper, TextInput } from './input';
import { Link } from './Button';
import { RText } from './elements';

const SuggestionsListItem = ({ item, onPress, keyword }) => (
    <TouchableWithoutFeedback onPress={onPress}>
        <View style={autocompleteStyle.suggestionsItem}>
            <TextHighliter
                value={item.name}
                keyword={keyword}
                containerStyle={autocompleteStyle.suggestionsItemTextContainer}
                style={autocompleteStyle.suggestionsItemText}
            />
        </View>
    </TouchableWithoutFeedback>
);

export const getNormalizedKeyword = (kw) => {
    if ((kw ?? '').includes('-')) {
        return `${kw.split('-')?.[0]}-`;
    }
    return kw;
};

export const AutocompleteSearchBar = withState(
    ({
        isLoading,
        keyword,
        shouldReload,
        placeholder,
        value = keyword,
        onClear,
        onChangeText,
        suggestions = [],
        setState,
        onSuggestionSelected,
        isShowingSuggestions,
        errorOccurred,
        autoCorrect = true,
        routeToNav = 'DrugsList',
        manualVisit,
    }) => {
        let containerStyle = {};
        if (isShowingSuggestions) {
            containerStyle =
                Platform.OS === 'ios'
                    ? {
                        zIndex: 1,
                        height: '100%',
                    }
                    : { height: '100%' };
        }
        if (shouldReload) {
            onChangeText(keyword);
            actions.setSearchShouldReload(false);
        }
        return (
            <View style={containerStyle}>
                <View style={[style.searchBar, { zIndex: 2 }]}>
                    <InputWrapper>
                        <SearchBarNative
                            autoCorrect={autoCorrect}
                            accessibilityLabel="search"
                            searchIcon={Icon.SearchBarFind}
                            clearIcon={value ? <Icon.SearchBarClose onPress={onClear} /> : null}
                            platform="default"
                            placeholder={placeholder}
                            containerStyle={{
                                ...style.container,
                                borderRadius: 3,
                                backgroundColor: 'transparent',
                                borderTopColor: 'transparent',
                                borderBottomColor: 'transparent',
                                width: '100%',
                                padding: 0,
                                margin: 0,
                                paddingRight: 0,
                            }}
                            inputStyle={style.input}
                            inputContainerStyle={[style.input, { paddingVertical: 0 }]}
                            rightIconContainerStyle={value ? { width: 30 } : {}}
                            leftIconContainerStyle={{ width: 30 }}
                            value={value || keyword}
                            onCancel={onClear}
                            {...(Platform.OS === 'web' ? {} : { onClear })}
                            onChangeText={onChangeText}
                            underlineColorAndroid="transparent"
                            onSubmitEditing={() => {
                                if (value.trim().length > 2) {
                                    navigate(routeToNav, {
                                        keyword: value.trim(),
                                        drugsList: null,
                                        manualVisit,
                                    });
                                }
                            }}
                            InputComponent={TextInput}
                        />
                    </InputWrapper>
                </View>
                {errorOccurred ? (
                    <ErrorStub
                        error={Object.R('titles.errorSearchDrugs')}
                        style={autocompleteStyle.errorPanel}
                        support
                    />
                ) : null}
                {isShowingSuggestions && !errorOccurred ? (
                    <SectionList
                        white
                        title="drugs"
                        type="drugs-suggestions"
                        listContainerStyle={{ flexGrow: 0 }}
                        isLoading={isLoading}
                        loadingIndicatorStyle={autocompleteStyle.loadingIndicatorStyle}
                        count={suggestions && suggestions.length}
                        data={suggestions}
                        renderItem={({ item }) => (
                            <SuggestionsListItem
                                item={item}
                                keyword={getNormalizedKeyword(value)}
                                onPress={() => {
                                    onSuggestionSelected(item.name);
                                    setState({
                                        value: item.name,
                                        suggestions: [],
                                        isShowingSuggestions: false,
                                    });
                                }}
                            />
                        )}
                        noscroll
                        footer={
                            <View
                                style={{
                                    paddingVertical: 8,
                                    borderTopWidth: 1,
                                    borderTopColor: '#E0E0E0',
                                }}>
                                <Link
                                    noflex
                                    href={() => {
                                        if (value.trim().length > 2) {
                                            navigate(routeToNav, {
                                                keyword: value.trim(),
                                                drugsList: null,
                                            });
                                        }
                                    }}
                                    underline
                                    id="titles.advanced_search"
                                />
                                <RText id="hints.advanced_search" style={{ marginTop: 2 }} />
                            </View>
                        }
                    />
                ) : null}
            </View>
        );
    },
    ({
        placeholder = 'placeholders.search',
        setState,
        suggestionsRequest,
        onSuggestionSelected,
        searchAfter = 0,
        timeout,
        onChangeText = v => setState(
            {
                value: v,
                isLoading: true,
                isShowingSuggestions: v.length > searchAfter,
                errorOccurred: false,
            },
            () => {
                if (v.length > searchAfter) {
                    if (timeout) {
                        clearTimeout(timeout);
                    }
                    // eslint-disable-next-line no-param-reassign
                    timeout = setTimeout(async () => {
                        suggestionsRequest(v)
                            .then((data) => {
                                setState({
                                    suggestions: data,
                                    isLoading: false,
                                });
                            })
                            .catch(() => setState({
                                suggestions: [],
                                isLoading: false,
                                errorOccurred: true,
                            }));
                    }, 800);
                } else if (v === '') {
                    onSuggestionSelected('');
                    setState({
                        value: '',
                        suggestions: [],
                        isLoading: false,
                        isShowingSuggestions: false,
                    });
                }
            },
        ),
    }) => ({
        placeholder: Object.R(placeholder),
        onClear: () => {
            onChangeText('');
            actions.setSearchSuggestionsKeyword('');
        },
        onChangeText,
        setState,
    }),
);
