import EStyleSheet from 'react-native-extended-stylesheet';

export const mapStyles = EStyleSheet.create({
    mapWrapper: {
        width: '100%',
        flex: 1,
    },
    map: { flex: 1 },
    menuButton: {
        marginTop: 20,
        backgroundColor: 'white',
        height: 45,
        width: 45,
        borderRadius: 99,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'flex-end',
    },
    menuGroupButtons: {
        position: 'absolute',
        top: 52,
        right: 25,
    },
});

export const switchMapModalStyles = EStyleSheet.create({
    switchWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    switch: {
        position: 'absolute',
        top: 0,
        alignItems: 'center',
    },
    header: {
        marginBottom: 20,
        width: '100%',
    },
    headerTitle: {
        fontWeight: '600',
        alignSelf: 'center',
    },
    headerCrossIcon: {
        position: 'absolute',
        right: 6,
    },
    tabSwitch: { marginHorizontal: 10 },

});

export const colorScaleStyles = EStyleSheet.create({
    scale: {
        flex: 1,
        borderWidth: 4,
        borderColor: 'white',
        borderRadius: 3,
        height: 32,
        flexDirection: 'row',
        position: 'absolute',
        top: 20,
        left: 15,
        right: 15,
    },
    color: { flex: 1 },
    values: {
        flexDirection: 'row',
        paddingHorizontal: 8,
        justifyContent: 'space-between',
        position: 'absolute',
        top: 2,
        right: 0,
        left: 0,
    },
    valueText: {
        color: 'white',
        fontSize: 14,
        alignSelf: 'center',
    },
    fixedValueWrapper: {
        paddingHorizontal: 4,
        justifyContent: 'center',
    },
});

export const mapMarkerStyles = EStyleSheet.create({
    selectedPriceTooltipWrapper: {
        zIndex: 100,
        marginLeft: -30,
        marginTop: -30,
        width: 80,
        height: 46,
    },
});

export const priceTooltipStyles = EStyleSheet.create({
    priceTooltip: { alignItems: 'center' },
    body: {
        height: 24,
        borderRadius: 12,
    },
    text: {
        color: 'white',
        fontSize: 14,
        padding: 4,
        marginHorizontal: 4,
        paddingTop: 2,
    },
    callout: {
        width: 0,
        height: 0,
        borderLeftWidth: 5,
        borderRightWidth: 5,
        borderTopWidth: 13,
        borderStyle: 'solid',
        backgroundColor: 'transparent',
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
    },
});
