import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    BigButton, List, Page, React, Section, showNotification, Stub, View,
} from '../../common';
import { SimpleCheckbox } from '../../common/Selector';
import {
    getBasket, getDrugInfo, getUser, isDrugsInfoLoading,
} from '../../selectors';
import tracking from '../../services/tracking';
import actions from '../../actions';
import { optionsSearchMultipleDrugs, useBackHandler } from '../../utils/routing';
import { AppHeader } from '../../combo';
import { FirstAidKitSimpleBanner, renderSelectableDrugItem } from './snippets';
import { firstAidKitstyles } from './style';
import { extendDrugData } from './util';

export const SearchListDrugs = Page.register(({ drugs = [], navigation }) => {
    const drugsInfo = useSelector(getDrugInfo);
    const isLoading = useSelector(isDrugsInfoLoading);
    const [data, setData] = useState([]);
    const basket = useSelector(getBasket);

    const userInfo = useSelector(getUser);

    useEffect(() => {
        if (
            !drugs?.every?.((i) => {
                return !!drugsInfo[i?.ls_num];
            })
        ) {
            actions.loadDrugInfo(drugs);
        }
    }, [drugs]);

    useEffect(() => {
        setData(
            drugs.map((i) => {
                const id = Number(i?.ls_num ?? i?.id);
                const info = { ...drugsInfo };
                const d = info[id];
                return { ...extendDrugData(d), ...i };
            }),
        );
    }, [drugsInfo]);

    useEffect(() => {
        actions.setSearchList(data ?? []);
    }, [data]);

    const searchDrugstoresHandler = buttonName => () => {
        tracking.logEvent(`buttonFindListDrugs_${buttonName}`);
        navigation.replace('SearchDrugstores', {
            from: 'searchListDrugs',
            drugs: data
                // .filter(f => f.selected)
                .map(item => ({
                    ...item,
                    apt_cnt: '5000',
                })),
            totalCount: data?.length,
        });
    };

    useBackHandler(() => {
        searchDrugstoresHandler('back')();
        return true;
    });
    navigation.setOptions({
        ...optionsSearchMultipleDrugs,
        header: () => {
            return (
                <AppHeader
                    right
                    backPress={() => {
                        searchDrugstoresHandler('back')();
                    }}
                />
            );
        },
    });

    const onSelect = (item) => {
        const newData = data.map(e => (item.id === e.id
            ? {
                ...e,
                selected: !e.selected,
            }
            : e));
        setData(newData);
    };
    const onSelectAll = () => {
        const isAllSelected = !data.some(e => !e.selected);
        const newData = data.map(e => ({
            ...e,
            selected: !isAllSelected,
        }));
        setData(newData);
    };
    const onDeleteItem = (item) => {
        const newData = data?.filter(e => e.id !== item.id);
        setData(newData);
    };

    const onChangeQty = (item, qty) => {
        const newData = data.map(e => (item.id === e.id
            ? {
                ...e,
                qty,
            }
            : e));
        setData(newData);
    };

    const isInBasket = (item) => {
        return basket.some(e => e.id === item.id);
    };

    return (
        <Page noscroll name="SearchDrugs" isLoading={isLoading}>
            {!isLoading && (
                <>
                    {!!data?.length && (
                        <FirstAidKitSimpleBanner
                            data={data.filter(f => f.selected)}
                            onClickFind={searchDrugstoresHandler('green')}
                            count={data?.length ?? 0}
                        />
                    )}
                    <Section
                        flex
                        style={firstAidKitstyles.section}
                        count={data?.length ?? 0}
                        title={'drugs'}
                        textStyles={firstAidKitstyles.sectionTextStyles}
                        right={
                            <View style={firstAidKitstyles.checkboxWrapper}>
                                {data?.length ? (
                                    <SimpleCheckbox
                                        onPress={onSelectAll}
                                        selected={!data.some(e => !e.selected)}
                                        light
                                    />
                                ) : null}
                            </View>
                        }>
                        <List
                            type="basket"
                            data={data}
                            renderItem={renderSelectableDrugItem({
                                onSelect,
                                onDeleteItem,
                                onChangeQty,
                                onPress: () => {},
                                from: 'searchList',
                                onFirstAidKit: (param) => {
                                    if (!userInfo?.isAuthenticated) {
                                        showNotification('titles.useFirstAidKitNonAuth');
                                        return;
                                    }
                                    showNotification(
                                        Object.R(
                                            isInBasket(param) ? 'titles.removeFromAidKit' : 'titles.addToFirstAidKit',
                                            { drug: param?.name ?? param?.ls_name ?? '' },
                                        ),
                                        false,
                                    );
                                    actions.toggleBasket(param);
                                },
                                basket,
                            })}
                            ListEmptyComponent={<Stub.DrugList />}
                            style={firstAidKitstyles.list}
                        />
                    </Section>
                    <View style={firstAidKitstyles.footer}>
                        <BigButton
                            title={`${Object.R('titles.findInDrugStores')} (${
                                data?.filter?.(f => f.selected).length ?? 0
                            })`}
                            action={searchDrugstoresHandler('green')}
                            disabled={!data.filter(f => f.selected).length}
                        />
                    </View>
                </>
            )}
        </Page>
    );
});
