import { sortBy } from '../utils';
import api from './api';
import firebase from './firebase';
import { user } from './user';

/**
 * Feedbacks
 */
export default {

    async fetchFeedbacks(id) {
        const u = await user.getUserInfo();
        const feedbacks = firebase.isAuthenticated() && u?.userId ? await api.getFeedbacksFor(id) : await api.getFeedbacksAnonymouslyFor(id);
        return feedbacks ? sortBy(feedbacks.items, '-createdAt') : [];
    },

    async deleteFeedback({
        _id: feedbackId,
        forItemId,
        forItemKind,
    }, fetch = true) {
        await api.deleteFeedback(feedbackId);
        if (!fetch && forItemKind !== 'clinics') {
            return [];
        }
        return this.fetchFeedbacks(forItemId, forItemKind);
    },

    async modifyFeedback(feedback, forItemKind, fetch = true) {
        await api.modifyFeedback(feedback);
        if (!fetch) {
            return [];
        }
        return this.fetchFeedbacks(feedback.forItemId, forItemKind);
    },

    async modifyUseful(id, value) {
        const response = await api.postUseful(id, value);
        return response;
    },

    async fetchCompleted(itemKind = 'physicians') {
        // noinspection UnnecessaryLocalVariableJS
        const completed = await api.getCompletedFeedbacksFor(itemKind);
        return completed;
    },

    async getVotes(itemKind = 'physicians') {
        // noinspection UnnecessaryLocalVariableJS
        const votes = await api.getCompletedVotes(itemKind);
        return votes;
    },
};
