// noinspection JSUnusedGlobalSymbols

import { SCHEME } from '../scheme';
import { DEFAULT_LOCATION } from '../config';
import {
    dbGettr, gettr, prefGettr, selectGettr,
} from './utils';
import { EMPTY_ARRAY } from './const';
import { DOCTOR_BY_ID_FIRST_CALL } from '../actions/doctors';

const {
    MEDCENTERS_SEARCH,
    PHYSICIANS_SEARCH,
    PHYSICIANS_FROM_CLINICS_SEARCH,
    PHYSICIANS_FROM_VISITS_SEARCH,
    PHYSICIANS_FAVORITES_SEARCH,
    PHYSICIANS_IS_LOADING,
    UMS_FILTER,
    UMS_PREFILTER,
    UMS_PREFILTER_WITH_PROGRAMS,
    UMS_PREFILTER_MEDCENTERS_GROUP,
    DRUGSTORES_FILTER,
    PHYSICIANS_ADVANCED_SEARCH,
    PHYSICIANS_CLINICS_ADVANCED_SEARCH,
    PHYSICIANS_VISITS_ADVANCED_SEARCH,
} = SCHEME;
/**
 * Getters.
 */

export const getUserInfo = gettr('user.info', {});
export const getUserPerson = gettr('user.info.me');
export const getUserConsentReceived = gettr('user.info.me.consentReceived');
export const getUserPersonCards = gettr('user.info.me.cards');
export const getUserRelatives = gettr('user.info.relatives');
export const getUserPrograms = gettr('user.info.programs');
export const getUserMyPrograms = gettr('user.info.me.programs');
export const getUserPersonId = gettr('user.info.me.profileId');
export const getUserLoadingState = gettr('user.isLoading');
export const getUserFetchingState = gettr('user.isFetching');
export const getUserError = gettr('user.error');
export const getUserInfoError = gettr('user.info.error');
export const getSignInProcess = gettr('user.signIn');
export const getStoreKey = gettr('user.info.storeKey');

export const getIsAuthenticated = gettr('user.isAuthenticated');
export const getUser = gettr('user', {});

export const getIsFetching = gettr('fetching.isFetching');
export const getFetching = gettr('fetching');

export const getMedcentersSearch = dbGettr(MEDCENTERS_SEARCH);

export const getPhysiciansSearch = dbGettr(PHYSICIANS_SEARCH, '');
export const getPhysiciansSearchFromClinics = dbGettr(PHYSICIANS_FROM_CLINICS_SEARCH, '');
export const getPhysiciansSearchFromVisits = dbGettr(PHYSICIANS_FROM_VISITS_SEARCH, '');
export const getPhysiciansFavoritesSearch = dbGettr(PHYSICIANS_FAVORITES_SEARCH, '');
export const getPhysiciansAdvancedSearch = dbGettr(PHYSICIANS_ADVANCED_SEARCH, '');
export const getPhysiciansClinicsAdvancedSearch = dbGettr(PHYSICIANS_CLINICS_ADVANCED_SEARCH, '');
export const getPhysiciansVisitsAdvancedSearch = dbGettr(PHYSICIANS_VISITS_ADVANCED_SEARCH, '');

export const getPhysicians = dbGettr('physicians.data');
export const getPhysiciansFilter = dbGettr('physicians.filter');
export const getPhysiciansFilterClinic = dbGettr('physicians.filterForClinic');
export const getPhysiciansFilterVisit = dbGettr('physicians.filterForVisit');
export const getShowNewUserAgreementModal = dbGettr('showNewUserAgreementModal');
export const getEndOfLife = dbGettr('endOfLife');
export const getInAppUpdateAndroid = dbGettr('inAppUpdateAndroid', false);
export const getInAppUpdateIOS = dbGettr('inAppUpdateIOS', false);
export const getInAppUpdatePeriod = dbGettr('inAppUpdateNotifyPeriod', false);
export const getPhysiciansPrefilter = dbGettr('physicians.prefilter');
export const physiciansPrefilterSpecialty = dbGettr('physicians.prefilter.specialty', '');
export const physiciansPrefilterSpecialtyId = dbGettr('physicians.prefilter.specialtyId', '');
export const physiciansPrefilterSpecialtyClinic = dbGettr('physicians.prefilterForClinic.specialty', '');
export const physiciansPrefilterSpecialtyIdClinic = dbGettr('physicians.prefilterForClinic.specialtyId', '');
export const physiciansPrefilterSpecialtyVisit = dbGettr('physicians.prefilterForVisit.specialty', '');
export const physiciansPrefilterSpecialtyIdVisit = dbGettr('physicians.prefilterForVisit.specialtyId', '');
export const physiciansPrefilterSpecialties = dbGettr('physicians.prefilter.specialties', EMPTY_ARRAY);
export const physiciansPrefilterSpecialtiesClinic = dbGettr('physicians.prefilterForClinic.specialties', EMPTY_ARRAY);
export const physiciansPrefilterSpecialtiesVisit = dbGettr('physicians.prefilterForVisit.specialties', EMPTY_ARRAY);
export const physiciansPrefilterPeriods = dbGettr('physicians.prefilter.periods', EMPTY_ARRAY);
export const physiciansPrefilterProfiles = dbGettr('physicians.prefilter.profiles', EMPTY_ARRAY);
export const physiciansPrefilterProfilesClinic = dbGettr('physicians.prefilterForClinic.profiles', EMPTY_ARRAY);
export const physiciansPrefilterProfilesVisit = dbGettr('physicians.prefilterForVisit.profiles', EMPTY_ARRAY);
export const physiciansPrefilterFeedbacks = dbGettr('physicians.prefilter.feedbacks', false);
export const physiciansPrefilterFeedbacksClinic = dbGettr('physicians.prefilterForClinic.feedbacks', false);
export const physiciansPrefilterFeedbacksVisit = dbGettr('physicians.prefilterForVisit.feedbacks', false);
export const physiciansRecentSpecialtyRequests = dbGettr('physicians.recentSpecialtyRequests', EMPTY_ARRAY);
export const physiciansRecentSpecialtyRequestsClinic = dbGettr(
    'physicians.recentSpecialtyRequestsForClinic',
    EMPTY_ARRAY,
);
export const physiciansRecentSpecialtyRequestsVisit = dbGettr(
    'physicians.recentSpecialtyRequestsForVisit',
    EMPTY_ARRAY,
);
export const physiciansPrefilterTimes = dbGettr('physicians.prefilter.times', EMPTY_ARRAY);
export const physiciansPrefilterAges = dbGettr('physicians.prefilter.ages', EMPTY_ARRAY);
export const physiciansPrefilterTimesClinic = dbGettr('physicians.prefilterForClinic.times', EMPTY_ARRAY);
export const physiciansPrefilterTimesVisit = dbGettr('physicians.prefilterForVisit.times', EMPTY_ARRAY);
export const physiciansPrefilterAgesClinic = dbGettr('physicians.prefilterForClinic.ages', EMPTY_ARRAY);
export const physiciansPrefilterAgesVisit = dbGettr('physicians.prefilterForVisit.ages', EMPTY_ARRAY);
export const physiciansPrefilterDates = dbGettr('physicians.prefilter.dates', {});
export const physiciansPrefilterDatesClinic = dbGettr('physicians.prefilterForClinic.dates', {});
export const physiciansPrefilterDatesVisit = dbGettr('physicians.prefilterForVisit.dates', {});
export const physiciansIsLoading = dbGettr(PHYSICIANS_IS_LOADING, false);
export const getOnBackground = dbGettr('onBackground', false);

export const visitsPrefilterVisitors = dbGettr('visits.prefilter.visitors', EMPTY_ARRAY);
export const visitsPrefilterClinics = dbGettr('visits.prefilter.clinics', EMPTY_ARRAY);
export const visitsPrefilterDoctors = dbGettr('visits.prefilter.doctors', EMPTY_ARRAY);
export const visitsPrefilterSpeciality = dbGettr('visits.prefilter.speciality', EMPTY_ARRAY);
export const visitsPrefilterAttachments = dbGettr('visits.prefilter.byAttachments', false);
export const visitsPrefilterReports = dbGettr('visits.prefilter.byReports', false);

export const testsPrefilterVisitors = dbGettr('tests.prefilter.visitors', EMPTY_ARRAY);
export const testsPrefilterClinics = dbGettr('tests.prefilter.clinics', EMPTY_ARRAY);
export const testsPrefilterStatuses = dbGettr('tests.prefilter.statuses', EMPTY_ARRAY);
export const getIsSyncLoading = gettr('tests.isSyncLoading', false);

// fetching
export const getFetchingSyncAllPhysicians = gettr('fetching.sync_all_physicians', false);
export const getIsFavoritesLoadFromCollections = gettr('fetching.isFavoritesLoadFromCollections', false);

// all prefs
export const getPreferences = store => store.prefs.data || {};

export const getPrefsLoadingState = gettr('prefs.isLoading');

// includeAnalogs
export const getIncludeAnalogs = selectGettr('includeAnalogs', false);
// locations
export const getGlobalLocation = selectGettr('location', DEFAULT_LOCATION);
export const getAcodeConfirmation = selectGettr('acodeConfirmation');

export const getNotificationsSettings = selectGettr('notificationsSettings');
export const getMainScreenSettings = selectGettr('mainScreenSettings');
export const getAllowScreenshots = selectGettr('allowScreenshots', true);

export const getSearchHistory = prefGettr('drugsSearch', EMPTY_ARRAY);
// language
export const getCurrentLanguage = selectGettr('locale');

export const getSupportedlanguages = dbGettr('supportedLanguages');

export const getDocuments = prefGettr('documents');
// export const getFavorites = prefGettr('favorites');
export const getFavoritesFull = prefGettr('fullFavorites');
export const getisPrefsLoading = gettr('prefs.isLoading');

// map
export const getMapType = selectGettr('mapType');
// popularQueris
export const getPopularQueries = gettr('db.popularQueries');
// bunnerSettings
export const getBunnerSettings = gettr('db.bunnerSettings');

export const getClinicFilterReset = gettr('db.clinicFilterReset');

export const getHideTimePeriodSearch = gettr('db.hideTimePeriodSearch');
export const getDisabledCoverers = gettr('db.disabledCoverers');
export const getStatusesOfHappenedVisits = gettr('db.statusesOfHappenedVisits');
export const getStatusesOfHappenedVisitsDoctor = gettr('db.statusesOfHappenedVisitsDoctor');
export const getDisableUnconfirmedFeedback = gettr('db.disableUnconfirmedFeedback');
export const getDisableUnconfirmedFeedbackOnlineOnly = gettr('db.disableUnconfirmedFeedbackForOnlineClinicsOnly');

export const getUsePreviousDoctorsList = gettr('db.usePreviousDoctorsList');
export const getUseLocationNta = gettr('db.useLocationNta');

// ums filter
export const getUmsFilter = dbGettr(UMS_FILTER);
export const getUmsPrefilter = dbGettr(UMS_PREFILTER);
export const getUmsPrefilterWithPrograms = dbGettr(UMS_PREFILTER_WITH_PROGRAMS);
export const getUmsPrefilterMedcentersGroup = dbGettr(UMS_PREFILTER_MEDCENTERS_GROUP, 'any');

// past visits
export const getPastVisits = gettr('pastVisits.data');
export const getIsPastVisitsLoading = gettr('pastVisits.isLoading', false);

// reports/results
export const getIsResultOrReportLoading = gettr('visits.isResultOrReportLoading', false);
export const getIsReportsEnable = dbGettr('enableReports', false);
export const getIsTestsEnable = dbGettr('enableTests', true);

export const getCurrentDoctorFeedbacks = dbGettr('currentDoctorFeedbacks', null);

export const getIsOnline = gettr('ums.isOnline', false);
export const getIsServicesDoctorsLoading = gettr('ums.isDoctorsLoading', true);

export const getDrugstoresFilters = dbGettr(DRUGSTORES_FILTER, {});

export const getServiceInfo = gettr('ums.serviceInfo');
export const getServiceInfoMc = gettr('ums.serviceMc');

export const getDefaultSmsProvider = dbGettr('defaultSmsProvider', 'assist');
export const getSecondSmsProvider = dbGettr('secondSmsProvider', 'fb');

export const getSpecInfo = gettr('doctors.specialitiesInfo');
export const getSpecialitiesFullList = gettr('doctors.specialitiesFullList.data', []);
export const getIsSpecialitiesFetched = gettr('doctors.isSpecialitiesFetched', false);
export const getPureSpecialitiesFullList = gettr('doctors.specialitiesFullList.data');
export const getIsSpecLoading = gettr('doctors.isSpecLoading');
export const getDoctorsData = gettr('doctors.data');
export const getDoctors = gettr('doctors');
export const getIsDoctorsLoading = gettr('doctors.isDoctorsLoading');
export const getDoctorBiIdFirstCallDate= gettr(`doctors.${DOCTOR_BY_ID_FIRST_CALL}`);
