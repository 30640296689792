/* eslint-disable no-underscore-dangle */
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { StackActions, useNavigation } from '@react-navigation/native';
import {
    Card, LoadingIndicator, Page, React, showNotification, Stub, View,
} from '../../../common';
import actions, { back } from '../../../actions';
import { useDoctorApi } from '../../../hooks/useDoctorApi';
import { DoctorBanner } from '../../Doctors/snippets';
import { setFormData } from '../../../actions/forms';
import { adaptDoctorToFav } from '../../App/snippets/utils';
import { useController } from '../../Doctors/DoctorDetails';
import { useFeedbacks } from '../../../hooks/useFeedbacks';
import { PostDoctorFeedback } from './DoctorFeedbacks';
import { dbGettr } from '../../../selectors/utils';

/**
 * This page is to submit a user feedback for a medician or a medcenter.
 */

export const VOTES = {
    '-1': 'NOT_RECOMMEND',
    0: 'NEUTRAL',
    1: 'RECOMMEND',
};

export const AddDoctorFeedbacks = Page.register(({ route }) => {


    const [isSending, setIsSending] = useState(false);
    const navigation = useNavigation();
    const feedback = navigation.getParam('feedback');
    const clinics = useSelector(dbGettr('clinics.data'));
    // const doctorInfo = navigation.getParam('doctorInfo');
    const fromNotification = route?.params?.fromNotification ?? navigation.getParam('fromNotification');
    const [error, setError] = useState();
    const dispatch = useDispatch();

    const { doctor } = useDoctorApi({
        id: feedback?.forItemId,
        withAssignments: true,
        nullWithoutAssignment: false,
    });

    const formData = useFeedbacks(feedback?.forItemId);
    const d = useController(feedback?.doctorId ?? feedback?.forItemId, !fromNotification);

    const docInfo = route?.params?.doctorInfo;
    const addDocInfo = {};
    try {

        if (!Array.isArray(doctor?.worksAt) && !Array.isArray(docInfo?.worksAt) && Array.isArray(doctor.assignments)) {

            addDocInfo.worksAt = doctor.assignments.map(({ branchId }) => clinics?.[branchId]);

        }

    } catch (e) {
        //
    }


    const isEditFeedback = () => (Array.isArray(formData?.myFeedbacks) ? formData?.myFeedbacks[0]?._id : formData?.myFeedbacks?._id);

    const getVote = (vote) => {

        const [result] = Object.entries(VOTES)
            .filter(([, value]) => value === vote)
            .map(([key]) => key);
        return Number(result) ?? 2;

    };

    const setTitle = () => {

        const title = Object.R(
            isEditFeedback() ? 'screenTitles.EditDoctorFeedback' : 'screenTitles.AddDoctorFeedbacks',
        );
        dispatch(
            setFormData({
                EditDoctorFeedback: { title },
                AddDoctorFeedbacks: { title },
            }),
        );

    };

    const getData = useCallback(() => {

        let fb;

        const propName1 = 'recommendCount';
        const propName2 = 'notRecommendCount';
        const propName3 = 'neutralCount';

        if (formData?.feedbacksInfo?.[propName1] || formData?.feedbacksInfo?.[propName2] || formData?.feedbacksInfo?.[propName3]) {

            fb = formData.feedbacksInfo;

        } else if (formData?.[propName1] || formData?.[propName2] || formData?.[propName3]) {

            fb = formData;

        } else if (d?.[propName1] || d?.[propName2] || d?.[propName3]) {

            fb = d;

        } else if (d?.votes?.[propName1] || d?.votes?.[propName2] || d?.votes?.[propName3]) {

            fb = d.votes;

        } else {

            fb = formData?.votes;

        }
        return {
            doctor,
            feedback: fb,
        };

    }, [formData, doctor, d]);

    const _post = ({
        feedbackText, anonymous, vote, _id,
    }) => actions.modifyFeedback(
        {
            _id,
            forItemId: formData?.doctorId,
            // forItemId: formData?.doctorId || (doctorInfo || doctor)?.id,
            forItemKind: 'physicians',
            feedbackText,
            anonymous,
            vote,
        },
        (e) => {

            if (e) {

                throw new Error(e?.message ?? 'Error');

            }
            // actions.fetchCompleted();
            if (fromNotification) {

                navigation.dispatch(
                    StackActions.replace('DoctorFeedbacks', { doctorInfo: doctor ?? {} }),
                );

            } else {

                back();

            }

        },
        true,
    );
    //    }, needFetch);

    useEffect(() => {

        if (!formData?.isLoading && typeof formData?.myVotes !== 'object' && !Array.isArray(formData?.myFeedbacks)) {

            setTitle();

        }

    }, [formData]);

    return (
        <Page isLoading={isSending && formData?.isLoading} error={error} notification={error} enableAutoHideKeyboard={false}>
            <View
                style={{
                    padding: 0,
                    margin: 0,
                }}>
                <Card
                    containerStyle={{
                        padding: 0,
                        margin: 0,
                        paddingBottom: 20,
                        paddingTop: 12,
                        borderBottomWidth: 1,
                        borderRadius: 0,
                        elevation: 0,
                        borderLeftWidth: 0,
                        borderRightWidth: 0,
                        borderTopWidth: 0,
                    }}>
                    {formData?.isLoading || isSending ? (
                        <LoadingIndicator />
                    ) : (
                        !!doctor && (
                            <DoctorBanner
                                doctor={{
                                    ...(docInfo ?? {}),
                                    ...doctor,
                                    ...addDocInfo,
                                }}
                                feedback={getData().feedback}
                                hideFeedback={false}
                                iconSize={15}
                            />
                        )
                    )}
                </Card>

                {formData?.isAuthenticated && formData?.isAdult ? (
                    <>
                        <PostDoctorFeedback
                            inline={false}
                            disabled={isSending || formData?.isLoading}
                            initialRate={getVote(formData?.myFeedbacks?.[0]?.vote)}
                            // initialRate={getVote(formData?.myVotes?.[feedback?.forItemId])}
                            initialText={formData?.myFeedbacks?.[0]?.feedbackText}
                            initialPublishName={!formData?.myFeedbacks?.[0]?.anonymous}
                            fromNotification={fromNotification}
                            id={feedback?.forItemId}
                            onSubmit={({
                                rate: r, text: t, anonymous: a, addToFavorites,
                            }) => {

                                setIsSending(true);
                                const postParams = {
                                    feedbackText: t?.trim() ?? '',
                                    anonymous: a,
                                    vote: VOTES[r ?? 0],
                                };
                                if (formData?.myFeedbacks[0]?._id) {

                                    postParams._id = formData?.myFeedbacks[0]?._id;

                                }
                                _post(postParams)
                                    .then(() => {

                                        showNotification(
                                            isEditFeedback()
                                                ? 'titles.comment_has_been_edited'
                                                : 'titles.comment_has_been_added',
                                        );

                                    })
                                    .catch((e) => {

                                        setIsSending(false);
                                        setError(e);

                                    });
                                if (addToFavorites) {

                                    const full = adaptDoctorToFav(doctor);
                                    actions.addFullFavorites(full);

                                }

                            }}
                        />
                    </>
                ) : (
                    <Stub.NotAuthorized />
                )}
            </View>
        </Page>
    );

});
