/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import { AppHeader, TabBar } from './combo';
import { Button, dataFrom } from './common';
import { getUser } from './selectors';
import {
    About as Other,
    AccessCheck,
    AccountPending,
    AccountReset,
    AccountUserInfo,
    AddDoctorFeedbacks,
    AddDoctorFeedbacks as EditDoctorFeedback,
    AddFeedbacks,
    AddFeedbacks as EditFeedback,
    BnsProgram,
    BnsSignIn,
    ChooseProgram,
    CodeConfirmation,
    DatePick as DoctorRecordDatePick,
    DoctorDetails,
    DoctorFeedbacks,
    DoctorFilters,
    DoctorInfo,
    DoctorsListGeneral,
    DoctorsMap,
    Documents,
    DocumentsSlider,
    DrugDetailsForVisit,
    DrugsListForVisit,
    DrugstoreDetails,
    EditAccount,
    EditAdultRelative,
    EditRelative,
    EnrollVisit,
    EpamAddRelativeHint,
    EpamProgram,
    EpamSignIn,
    FamilyInviteConfirm,
    FamilyJoin,
    FirstAidKit,
    Home,
    InvoiceConfirmation,
    InvoiceMultiService,
    InvoiceReviewedInfo,
    MedCenterDetails,
    MedCenterFeedbacks,
    MedCenterInfo,
    MedCenterRecord,
    MedCentersList,
    MedCentersMap,
    MyCard,
    NotificationsSettings,
    OutdatedAppVersion,
    PDFViewer,
    promptLogOut,
    RelativeCodeConfirmation,
    RelativeGrants,
    Relatives,
    RelativeSmsSend,
    SearchDrugsForVisit,
    SearchDrugstores,
    SearchDrugstoresForVisit,
    ServiceDetails,
    SignIn,
    UMSDoctorsList,
    UmsFilter,
    UmsMain,
    Visit as VisitRelative,
    Visit,
    VisitImagesSlider,
    VisitsFilter,
    WebPage,
    ConsentConfirmation,
} from './screens';
import {
    optionsAllFeedbacks,
    optionsDoctorDetails,
    optionsDoctorFeedbacks,
    optionsDoctorsFavorites,
    optionsDoctorsFilter,
    optionsDoctorsList,
    optionsDoctorsSpecialtiesSearch,
    optionsDrugs,
    optionsEditDoctorFeedbacks,
    optionsMedCenterDetails,
    optionsMyCards,
    optionsSearchDrugstores,
    optionsSearchMultipleDrugs,
    optionsShareDrugsWithApt,
    optionsVisit,
    optionsWebPage,
    screenOptions,
    screenOptionsBare,
    screenOptionsLoadVisits,
    screenOptionsNoHeader,
    screenOptionsRoot,
    screenOptionsTab,
} from './utils/routing';
import { AccountRemove } from './screens/Account/AccountRemove';
import { UMSMedCentersMap } from './screens/UMS/UMSMedCentersMap';
import { ACodeConfirmation } from './screens/Visits/ACodeConfirmation';
import { TestsFilter } from './screens/Visits/TestsFilter';
import { LoadVisits } from './screens/Visits/LoadVisits';
import { AccessSettings } from './screens/Account/AccessSettings';
import { MainScreenSettings } from './screens/Account/MainScreenSettings';
import { MedCenterRecordFull } from './screens/MedCenters/MedCenterRecordFull';
import { SearchListDrugs } from './screens/Tabletka/SearchListDrugs';
import { AddFutureVisitorsManuallyPage, AddPastVisitorsManuallyPage } from './screens/Visits/AddVisitManually';
import { DoctorsSpecialtiesSearch } from './screens/Doctors/DoctorsSpecialtiesSearch';
import { DoctorsFavorites } from './screens/Doctors/DoctorsFavorites';
import { SearchDrugs } from './screens/Tabletka/pages/SearchDrugs';
import { DrugsList } from './screens/Tabletka/pages/DrugList';
import { DrugDetails } from './screens/Tabletka/pages/DrugDetails';
import { AllFeedbacks } from './screens/App/AllFeedbacks/AllFeedbacks';
import { EditUserPD } from './screens/Family/EditUserPD';
import { EnrollVisitProfiles } from './screens/Visits/EnrollVisit/NewEnrollVisit';
import { USE_MEDICINE_SEARCH } from './config';
import { AllFeedbackFilterScreen } from './screens/App/AllFeedbacks/AllFeedbackFilterScreen';

const useAllFeedbacks = true;
const tab = fn => fn(createBottomTabNavigator());
const stack = fn => fn(createStackNavigator());
const USE_NEW_ENROLL_VISIT = true;
const homeTabRoutes = stack(Stack => () => (
    <Stack.Navigator screenOptions={screenOptions} initialRouteName="HomeRoute">
        <Stack.Screen name="HomeRoute" component={Home} options={screenOptionsTab} />
        <Stack.Screen name="AddFutureVisitManually" component={AddFutureVisitorsManuallyPage} />
        <Stack.Screen name="AddPastVisitManually" component={AddPastVisitorsManuallyPage} />
        <Stack.Screen name="DoctorsMapClinic" component={DoctorsMap} />
        {/* <Stack.Screen name="LoadVisits" component={LoadVisits} options={screenOptionsLoadVisits} /> */}
        <Stack.Screen name="EditAccount" component={EditAccount} />
        <Stack.Screen name="EnrollVisit" component={USE_NEW_ENROLL_VISIT ? EnrollVisitProfiles : EnrollVisit} />
        <Stack.Screen name="EnrollVisitProfiles" component={EnrollVisitProfiles} />
        <Stack.Screen name="EditResult" component={AccountUserInfo} />
        <Stack.Screen name="DoctorList" component={DoctorsListGeneral} options={optionsDoctorsList} />
        <Stack.Screen
            name="DoctorsSpecialtiesSearch"
            component={DoctorsSpecialtiesSearch}
            options={optionsDoctorsSpecialtiesSearch}
        />
        <Stack.Screen
            name="DoctorsSpecialtiesSearchClinic"
            component={DoctorsSpecialtiesSearch}
            options={optionsDoctorsSpecialtiesSearch}
        />
        <Stack.Screen name="DoctorsFavorites" component={DoctorsFavorites} options={optionsDoctorsFavorites} />
        <Stack.Screen name="DoctorFilters" component={DoctorFilters} options={optionsDoctorsFilter} />
        <Stack.Screen name="DoctorsMap" component={DoctorsMap} />

        <Stack.Screen name="MedCenterRecord" component={MedCenterRecord} options={screenOptionsBare} />
        <Stack.Screen name="MedCenterRecordFull" component={MedCenterRecordFull} options={screenOptionsBare} />

        <Stack.Screen name="DoctorDetails" component={DoctorDetails} options={optionsDoctorDetails} />
        <Stack.Screen name="DoctorRecordDatePick" component={makeVisitRoutes} />
        <Stack.Screen name="DoctorInfo" component={DoctorInfo} screenOptions={screenOptions} />
        <Stack.Screen name="DoctorFeedbacks" component={DoctorFeedbacks} options={optionsDoctorFeedbacks} />
        <Stack.Screen name="AddFeedbacks" component={AddFeedbacks} />
        <Stack.Screen name="EditFeedback" component={EditFeedback} />
        <Stack.Screen name="AddDoctorFeedbacks" component={AddDoctorFeedbacks} options={optionsEditDoctorFeedbacks} />
        <Stack.Screen name="EditDoctorFeedback" component={EditDoctorFeedback} options={optionsEditDoctorFeedbacks} />

        <Stack.Screen name="UmsMain" component={UmsMain} screenOptions={screenOptions} />
        <Stack.Screen name="UmsFilter" component={UmsFilter} screenOptions={screenOptions} />

        <Stack.Screen name="WebPage" component={WebPage} options={optionsWebPage} />
        <Stack.Screen name="EpamProgram" component={EpamProgram} />
        <Stack.Screen name="BnsProgram" component={BnsProgram} />
        <Stack.Screen name="ServiceDetails" component={ServiceDetails} options={screenOptionsBare} />
        <Stack.Screen name="UMSDoctorsList" component={UMSDoctorsList} options={screenOptionsBare} />
        <Stack.Screen name="UMSMedCentersMap" component={UMSMedCentersMap} options={screenOptionsBare} />
        <Stack.Screen name="DrugsList" component={DrugsList} options={optionsDrugs} />

        <Stack.Screen name="MedCentersMap" component={MedCentersMap} />
        <Stack.Screen name="MedCenterDetails" component={MedCenterDetails} options={optionsMedCenterDetails} />
        <Stack.Screen name="MedCenterInfo" component={MedCenterInfo} />
        <Stack.Screen name="MedCenterServices" component={UmsMain} options={{ gestureEnabled: false }} />
        <Stack.Screen name="MedCenterFeedbacks" component={feedbackRoutes} />
        <Stack.Screen name="DoctorListClinic" component={DoctorsListGeneral} options={optionsDoctorsList} />
        {/* <Stack.Screen name="LoadVisits" component={LoadVisits} options={screenOptionsLoadVisits} /> */}

        <Stack.Screen name="VisitImagesSlider" component={VisitImagesSlider} options={screenOptionsBare} />
        <Stack.Screen name="FirstAidKit" component={FirstAidKit} />
        <Stack.Screen
            name="SearchDrugsRoute"
            component={SearchDrugs}
            options={SearchDrugs.screenOptions}
        />
        <Stack.Screen name="SearchDrugstores" component={SearchDrugstores} options={optionsSearchDrugstores} />
        <Stack.Screen name="DrugstoreDetails" component={DrugstoreDetails} options={optionsShareDrugsWithApt} />
        <Stack.Screen name="DrugDetails" component={DrugDetails} options={optionsSearchDrugstores} />
        <Stack.Screen name="SearchDrugs" component={SearchDrugs} />
        <Stack.Screen name="SearchListDrugs" component={SearchListDrugs} options={optionsSearchMultipleDrugs} />
        <Stack.Screen name="Visit" component={Visit()} options={optionsVisit} />

        <Stack.Screen name="SearchDrugsForVisit" component={SearchDrugsForVisit} />
        <Stack.Screen name="DrugsListForVisit" component={DrugsListForVisit} />
        <Stack.Screen name="SearchDrugstoresForVisit" component={SearchDrugstoresForVisit} />
        <Stack.Screen name="DrugstoreDetailsForVisit" component={DrugstoreDetails} />
        <Stack.Screen name="DrugDetailsForVisit" component={DrugDetailsForVisit} />
    </Stack.Navigator>
));

const drugDetailsRouts = stack(Stack => ({ route: { params } }) => (
    <Stack.Navigator screenOptions={screenOptions} initialRouteName="DrugDetails">
        <Stack.Screen name="DrugDetails" component={DrugDetails} options={optionsDrugs} initialParams={params} />
        <Stack.Screen name="SearchDrugstores" component={SearchDrugstores} options={optionsSearchDrugstores} />
        <Stack.Screen name="DrugstoreDetails" component={DrugstoreDetails} options={optionsDrugs} />
        <Stack.Screen name="FirstAidKit" component={FirstAidKit} />
        <Stack.Screen name="SearchListDrugs" component={SearchListDrugs} options={optionsSearchMultipleDrugs} />
    </Stack.Navigator>
));

const searchDrugsRoutes = stack(Stack => ({ route: { params } }) => (
    <Stack.Navigator screenOptions={screenOptions} initialRouteName="SearchDrugsRoute">
        <Stack.Screen
            name="SearchDrugsRoute"
            component={SearchDrugs}
            initialParams={params}
            options={SearchDrugs.screenOptions}
        />
        <Stack.Screen
            name="DrugsList"
            component={DrugsList}
            options={{
                ...optionsDrugs,
                params,
            }}
        />
        <Stack.Screen name="DrugDetails" component={DrugDetails} options={optionsDrugs} />
        <Stack.Screen name="SearchDrugstores" component={SearchDrugstores} options={optionsSearchDrugstores} />
        <Stack.Screen name="DrugstoreDetails" component={DrugstoreDetails} options={optionsDrugs} />
        <Stack.Screen name="SearchListDrugs" component={SearchListDrugs} options={optionsSearchMultipleDrugs} />
        <Stack.Screen name="FirstAidKit" component={FirstAidKit} />
    </Stack.Navigator>
));

const medCentersRoutes = stack(Stack => () => (
    <Stack.Navigator
        screenOptions={screenOptions}
        initialRouteName="MedCentersListRoute"
        cardStyle={{
            flex: 1,
            overflow: 'hidden',
        }}>
        <Stack.Screen name="MedCentersListRoute" component={MedCentersList} options={screenOptionsTab} />
        <Stack.Screen name="MedCentersMap" component={MedCentersMap} />
        <Stack.Screen name="MedCenterDetails" component={MedCenterDetails} options={optionsMedCenterDetails} />
        <Stack.Screen name="MedCenterInfo" component={MedCenterInfo} />
        <Stack.Screen name="MedCenterRecord" component={MedCenterRecord} options={screenOptionsBare} />
        <Stack.Screen name="MedCenterServices" component={UmsMain} options={{ gestureEnabled: false }} />
        <Stack.Screen name="UmsFilter" component={UmsFilter} screenOptions={screenOptions} />
        <Stack.Screen name="ServiceDetails" component={ServiceDetails} options={screenOptionsBare} />
        <Stack.Screen name="UMSDoctorsList" component={UMSDoctorsList} options={screenOptionsBare} />
        <Stack.Screen name="MedCenterFeedbacks" component={feedbackRoutes} />
        <Stack.Screen name="DoctorListClinic" component={DoctorsListGeneral} options={optionsDoctorsList} />
        {/* <Stack.Screen */}
        {/*     name="DoctorsSpecialtiesSearch" */}
        {/*     component={DoctorsSpecialtiesSearch} */}
        {/*     options={optionsDoctorsSpecialtiesSearch} */}
        {/* /> */}
        <Stack.Screen
            name="DoctorsSpecialtiesSearchClinic"
            component={DoctorsSpecialtiesSearch}
            options={optionsDoctorsSpecialtiesSearch}
        />
        <Stack.Screen name="DoctorsFavorites" component={DoctorsFavorites} options={optionsDoctorsFavorites} />
        <Stack.Screen name="DoctorsMapClinic" component={DoctorsMap} />
        <Stack.Screen name="DoctorFilters" component={DoctorFilters} options={optionsDoctorsFilter} />
        <Stack.Screen name="DoctorDetails" component={DoctorDetails} options={optionsDoctorDetails} />
        <Stack.Screen name="DoctorRecordDatePick" component={makeVisitRoutes} />
        <Stack.Screen name="DoctorInfo" component={DoctorInfo} screenOptions={screenOptions} />
        <Stack.Screen name="DoctorFeedbacks" component={DoctorFeedbacks} options={optionsDoctorFeedbacks} />
        <Stack.Screen name="AddFeedbacks" component={AddFeedbacks} />
        <Stack.Screen name="EditFeedback" component={EditFeedback} />
        <Stack.Screen name="AddDoctorFeedbacks" component={AddDoctorFeedbacks} options={optionsEditDoctorFeedbacks} />
        <Stack.Screen name="EditDoctorFeedback" component={EditDoctorFeedback} options={optionsEditDoctorFeedbacks} />
    </Stack.Navigator>
));

const myCardRoutes = stack(Stack => () => (
    <Stack.Navigator screenOptions={screenOptions}>
        <Stack.Screen name="MyCard" component={MyCard} options={optionsMyCards} />
        <Stack.Screen name="Visit" component={Visit()} options={optionsVisit} />
        <Stack.Screen name="AddFutureVisitManually" component={AddFutureVisitorsManuallyPage} />
        <Stack.Screen name="AddPastVisitManually" component={AddPastVisitorsManuallyPage} />
        <Stack.Screen name="DoctorDetails" component={DoctorDetails} options={optionsDoctorDetails} />
        <Stack.Screen name="MedCenterDetails" component={MedCenterDetails} options={optionsMedCenterDetails} />
        <Stack.Screen name="DoctorInfo" component={DoctorInfo} screenOptions={screenOptions} />
        <Stack.Screen name="MedCenterInfo" component={MedCenterInfo} />
        <Stack.Screen name="DoctorRecordDatePick" component={makeVisitRoutes} />
        <Stack.Screen name="DoctorList" component={DoctorsListGeneral} options={optionsDoctorsList} />
        <Stack.Screen name="DoctorListClinic" component={DoctorsListGeneral} options={optionsDoctorsList} />
        <Stack.Screen
            name="DoctorsSpecialtiesSearch"
            component={DoctorsSpecialtiesSearch}
            options={optionsDoctorsSpecialtiesSearch}
        />
        <Stack.Screen
            name="DoctorsSpecialtiesSearchClinic"
            component={DoctorsSpecialtiesSearch}
            options={optionsDoctorsSpecialtiesSearch}
        />
        <Stack.Screen
            name="DoctorsSpecialtiesSearchVisit"
            component={DoctorsSpecialtiesSearch}
            options={optionsDoctorsSpecialtiesSearch}
        />
        <Stack.Screen name="DoctorsFavorites" component={DoctorsFavorites} options={optionsDoctorsFavorites} />
        <Stack.Screen name="DoctorsMap" component={DoctorsMap} />
        <Stack.Screen name="DoctorFilters" component={DoctorFilters} options={optionsDoctorsFilter} />
        <Stack.Screen name="MedCentersMap" component={MedCentersMap} />
        <Stack.Screen name="MedCenterServices" component={UmsMain} options={{ gestureEnabled: false }} />
        <Stack.Screen name="UmsFilter" component={UmsFilter} screenOptions={screenOptions} />
        <Stack.Screen name="ServiceDetails" component={ServiceDetails} options={screenOptionsBare} />
        <Stack.Screen name="UMSDoctorsList" component={UMSDoctorsList} options={screenOptionsBare} />

        <Stack.Screen name="SearchDrugsForVisit" component={SearchDrugsForVisit} />
        <Stack.Screen name="DrugsListForVisit" component={DrugsListForVisit} />
        <Stack.Screen name="SearchDrugstoresForVisit" component={SearchDrugstoresForVisit} />
        <Stack.Screen name="DrugstoreDetailsForVisit" component={DrugstoreDetails} />
        <Stack.Screen name="DrugDetailsForVisit" component={DrugDetailsForVisit} />
        <Stack.Screen name="SearchListDrugs" component={SearchListDrugs} options={optionsSearchMultipleDrugs} />
        <Stack.Screen name="SearchDrugstores" component={SearchDrugstores} options={optionsSearchDrugstores} />
        <Stack.Screen name="DrugstoreDetails" component={DrugstoreDetails} options={optionsDrugs} />

        <Stack.Screen name="InvoiceMultiService" component={invoicesRoutes} />
        <Stack.Screen name="InvoiceReviewedInfo" component={InvoiceReviewedInfo} options={screenOptionsBare} />
        <Stack.Screen name="VisitsFilter" component={VisitsFilter} />
        <Stack.Screen name="TestsFilter" component={TestsFilter} />
        <Stack.Screen name="ACodeConfirmation" component={ACodeConfirmation} />
        <Stack.Screen name="PDFViewer" component={PDFViewer} />
        <Stack.Screen name="VisitImagesSlider" component={VisitImagesSlider} />
        <Stack.Screen name="LoadVisits" component={LoadVisits} options={screenOptionsLoadVisits} />

        <Stack.Screen name="DoctorFeedbacks" component={DoctorFeedbacks} options={optionsDoctorFeedbacks} />
        <Stack.Screen name="AddFeedbacks" component={AddFeedbacks} />
        <Stack.Screen name="EditFeedback" component={EditFeedback} />
        <Stack.Screen name="AddDoctorFeedbacks" component={AddDoctorFeedbacks} options={optionsEditDoctorFeedbacks} />
        <Stack.Screen name="EditDoctorFeedback" component={EditDoctorFeedback} options={optionsEditDoctorFeedbacks} />
        <Stack.Screen name="MedCenterFeedbacks" component={feedbackRoutes} />
        <Stack.Screen name="DoctorsMapVisit" component={DoctorsMap} />

        <Stack.Screen name="EditAccount" component={EditAccount} />
    </Stack.Navigator>
));

const invoicesRoutes = stack(Stack => () => (
    <Stack.Navigator screenOptions={screenOptions}>
        <Stack.Screen name="InvoiceMultiService" component={InvoiceMultiService} />
        <Stack.Screen name="InvoiceConfirmation" component={InvoiceConfirmation} />
    </Stack.Navigator>
));

const otherRoutes = stack(Stack => () => (
    <Stack.Navigator initialRouteName="OtherRoute">
        <Stack.Screen name="OtherRoute" component={Other} options={screenOptionsTab} />
    </Stack.Navigator>
));

const drugListRoutes = stack(Stack => ({ route: { params } }) => (
    <Stack.Navigator initialRouteName="DrugsListRoute">
        <Stack.Screen name="DrugsListRoute" component={DrugsList} initialParams={params} options={optionsDrugs} />
    </Stack.Navigator>
));

const adultRelativesRoutes = stack(
    Stack => (
        { route: { params } }, //
    ) => (
        <Stack.Navigator screenOptions={screenOptionsNoHeader} initialRouteName="AddAdultRelativeRoute">
            <Stack.Screen name="AddAdultRelativeRoute" component={EditAdultRelative} initialParams={params} />
            <Stack.Screen name="RelativeSmsSend" component={RelativeSmsSend} initialParams={params} />
            <Stack.Screen
                name="RelativeCodeConfirmation"
                component={RelativeCodeConfirmation}
                initialParams={params}
            />
        </Stack.Navigator>
    ),
);

const relativesRoutes = stack(Stack => ({ route: { params } }) => (
    <Stack.Navigator screenOptions={screenOptionsNoHeader} initialRouteName="RelativesRoute">
        <Stack.Screen name="RelativesRoute" component={Relatives} initialParams={params} />
        <Stack.Screen name="FamilyInviteConfirm" component={FamilyInviteConfirm} />
        <Stack.Screen name="EditRelative" component={EditRelative} />
        <Stack.Screen name="RelativeGrants" component={RelativeGrants} />
        <Stack.Screen name="AddAdultRelative" component={adultRelativesRoutes} />
        <Stack.Screen name="EditAdultRelative" component={EditAdultRelative} />
        <Stack.Screen name="EditChild" component={EditRelative} />
        <Stack.Screen name="FamilyJoin" component={FamilyJoin} />
        <Stack.Screen name="EditUserPD" component={EditUserPD} />
        <Stack.Screen name="VisitRelative" component={VisitRelative(false)} />
        <Stack.Screen name="ACodeConfirmation" component={ACodeConfirmation} />
        <Stack.Screen name="PDFViewer" component={PDFViewer} />
        <Stack.Screen name="EnrollVisit" component={USE_NEW_ENROLL_VISIT ? EnrollVisitProfiles : EnrollVisit} />
        <Stack.Screen name="DoctorRecordDatePick" component={makeVisitRoutes} />
        <Stack.Screen name="Visit" component={Visit()} />
    </Stack.Navigator>
));

const visitRoutes = stack(Stack => () => (
    <Stack.Navigator screenOptions={screenOptions}>
        <Stack.Screen name="Visit" component={Visit()} options={optionsVisit} />
        <Stack.Screen name="VisitsFilter" component={VisitsFilter} />
        <Stack.Screen name="TestsFilter" component={TestsFilter} />
        <Stack.Screen name="DoctorRecordDatePick" component={makeVisitRoutes} />
        <Stack.Screen name="VisitImagesSlider" component={VisitImagesSlider} />
        <Stack.Screen name="SearchDrugsForVisit" component={SearchDrugsForVisit} />
        <Stack.Screen name="DrugsListForVisit" component={DrugsListForVisit} />
        <Stack.Screen name="SearchDrugstoresForVisit" component={SearchDrugstoresForVisit} />
        <Stack.Screen name="DrugstoreDetailsForVisit" component={DrugstoreDetails} />
        <Stack.Screen name="DrugDetailsForVisit" component={DrugDetailsForVisit} />
        <Stack.Screen name="DoctorDetails" component={DoctorDetails} options={optionsDoctorDetails} />
        <Stack.Screen name="DoctorInfo" component={DoctorInfo} screenOptions={screenOptions} />
        <Stack.Screen name="DoctorFeedbacks" component={DoctorFeedbacks} options={optionsDoctorFeedbacks} />
        <Stack.Screen name="MedCenterDetails" component={MedCenterDetails} options={optionsMedCenterDetails} />
        <Stack.Screen name="MedCenterInfo" component={MedCenterInfo} />
        <Stack.Screen name="MedCentersMap" component={MedCentersMap} />
        <Stack.Screen name="MedCenterRecord" component={MedCenterRecord} options={screenOptionsBare} />
        <Stack.Screen name="MedCenterServices" component={UmsMain} options={{ gestureEnabled: false }} />
        <Stack.Screen name="UmsFilter" component={UmsFilter} screenOptions={screenOptions} />
        <Stack.Screen name="ServiceDetails" component={ServiceDetails} options={screenOptionsBare} />
        <Stack.Screen name="UMSDoctorsList" component={UMSDoctorsList} options={screenOptionsBare} />
        <Stack.Screen name="MedCenterFeedbacks" component={feedbackRoutes} />
        <Stack.Screen name="DoctorList" component={DoctorsListGeneral} options={optionsDoctorsList} />
        <Stack.Screen
            name="DoctorsSpecialtiesSearch"
            component={DoctorsSpecialtiesSearch}
            options={optionsDoctorsSpecialtiesSearch}
        />
        <Stack.Screen
            name="DoctorsSpecialtiesSearchVisit"
            component={DoctorsSpecialtiesSearch}
            options={optionsDoctorsSpecialtiesSearch}
        />
        <Stack.Screen name="DoctorsFavorites" component={DoctorsFavorites} options={optionsDoctorsFavorites} />
        <Stack.Screen name="DoctorFilters" component={DoctorFilters} options={optionsDoctorsFilter} />
        <Stack.Screen name="ACodeConfirmation" component={ACodeConfirmation} />
        <Stack.Screen name="PDFViewer" component={PDFViewer} />
        <Stack.Screen name="SearchDrugstores" component={SearchDrugstores} options={optionsSearchDrugstores} />
        <Stack.Screen name="SearchListDrugs" component={SearchListDrugs} options={optionsSearchMultipleDrugs} />
    </Stack.Navigator>
));

const makeVisitRoutes = stack(Stack => ({ route: { params } }) => (
    <Stack.Navigator screenOptions={screenOptionsNoHeader} initialRouteName="DoctorRecordDatePickRout">
        <Stack.Screen name="DoctorRecordDatePickRout" component={DoctorRecordDatePick} initialParams={params} />
        <Stack.Screen name="EnrollVisit" component={USE_NEW_ENROLL_VISIT ? EnrollVisitProfiles : EnrollVisit} />
        <Stack.Screen name="EnrollVisitProfiles" component={EnrollVisitProfiles} />
        <Stack.Screen name="MedCenterRecord" component={MedCenterRecord} options={screenOptionsNoHeader} />
        <Stack.Screen name="DoctorDetails" component={DoctorDetails} options={optionsDoctorDetails} />
        <Stack.Screen name="EditAccount" component={EditAccount} />
    </Stack.Navigator>
));

const accountRoutes = stack(Stack => dataFrom(
    getUser,
    ({
        data: { isAuthenticated, info: { accessNotAuthorized, waitingConfirmEmail, deviceIdOfExistedUser } = {} },
        route: { params },
    }) => (
        <Stack.Navigator
            screenOptions={screenOptionsBare}
            animationEnabled="true"
            initialRouteName={'AccountRoute'}>
            {isAuthenticated ? (
                <>
                    <Stack.Screen
                        name="AccountRoute"

                        component={
                            // eslint-disable-next-line no-nested-ternary
                            (accessNotAuthorized && !waitingConfirmEmail) || deviceIdOfExistedUser
                                ? AccessCheck
                                : waitingConfirmEmail
                                    ? AccountPending
                                    : AccountUserInfo
                        }
                        initialParams={params}
                        options={{
                            header: () => ((accessNotAuthorized && !waitingConfirmEmail) || deviceIdOfExistedUser ? (
                                <AppHeader>
                                    <Button
                                        title="titles.signOut"
                                        ns="signout-link"
                                        action={promptLogOut}
                                        titleStyles={{
                                            paddingTop: 2,
                                            paddingRight: 6,
                                            color: 'white',
                                            fontSize: 18,
                                            fontFamily: 'SourceSansPro-Semibold',
                                        }}
                                    />
                                </AppHeader>
                            ) : (
                                <AppHeader
                                    title={Object.R('screenTitles.Account')}
                                    // backPress={() => {
                                    //     console.log('navigation acc: ', navigation);
                                    //     navigation.goBack();
                                    // }} // causes bag EPMAIMD-52077
                                />
                            )),
                        }}
                    />
                    <Stack.Screen name="Relatives" component={relativesRoutes} />

                    <Stack.Screen name="ChooseProgram" component={ChooseProgram} />
                    <Stack.Screen name="EpamProgram" component={EpamProgram} />
                    <Stack.Screen name="EpamSignIn" component={EpamSignIn} />
                    <Stack.Screen name="EpamAddRelativeHint" component={EpamAddRelativeHint} />
                    <Stack.Screen name="BnsSignIn" component={BnsSignIn} />
                    <Stack.Screen name="BnsProgram" component={BnsProgram} />
                    <Stack.Screen name="WebPage" component={WebPage} options={optionsWebPage} />

                    <Stack.Screen name="AccessSettings" component={AccessSettings} />
                    <Stack.Screen name="MainScreenSettings" component={MainScreenSettings} />
                    <Stack.Screen name="NotificationsSettings" component={NotificationsSettings} />
                    <Stack.Screen name="Documents" component={Documents} />
                    <Stack.Screen name="DocumentsSlider" component={DocumentsSlider} />
                    <Stack.Screen name="EditAccount" component={EditAccount} />
                    <Stack.Screen name="EditResult" component={AccountUserInfo} />
                    <Stack.Screen name="EditRelative" component={EditRelative} />
                    <Stack.Screen name="RelativeGrants" component={RelativeGrants} />
                    <Stack.Screen name="AddAdultRelative" component={adultRelativesRoutes} />
                    <Stack.Screen name="AccountReset" component={AccountReset} options={screenOptionsBare} />
                    <Stack.Screen
                        name="AccountRemove"
                        component={AccountRemove}
                        options={screenOptionsBare}
                        initialParams={params}
                    />
                </>
            ) : (
                <>
                    <Stack.Screen name="SignIn" initialParams={params} component={SignIn} />
                    <Stack.Screen name="CodeConfirmation" component={CodeConfirmation} />
                </>
            )}
        </Stack.Navigator>
    ),
));

const feedbackRoutes = stack(Stack => ({ route: { params } }) => (
    <Stack.Navigator screenOptions={screenOptionsNoHeader} initialRouteName="Feedbacks">
        {params.mdInfo ? <Stack.Screen name="Feedbacks" component={MedCenterFeedbacks} initialParams={params} /> : null}
        {params.doctorInfo ? (
            <Stack.Screen name="Feedbacks" component={DoctorFeedbacks} initialParams={params} />
        ) : null}
        <Stack.Screen name="AddFeedbacks" component={AddFeedbacks} />
        <Stack.Screen name="EditFeedback" component={EditFeedback} />
        <Stack.Screen name="AddDoctorFeedbacks" component={AddDoctorFeedbacks} options={optionsEditDoctorFeedbacks} />
        <Stack.Screen name="EditDoctorFeedback" component={EditDoctorFeedback} options={optionsEditDoctorFeedbacks} />
    </Stack.Navigator>
));

const allFeedbacksRoutes = stack(Stack => () => (
    <Stack.Navigator screenOptions={screenOptions}>
        <Stack.Screen name="AllFeedbacks" component={AllFeedbacks} options={optionsAllFeedbacks} />
        <Stack.Screen name="EditDoctorFeedback" component={EditDoctorFeedback} options={optionsEditDoctorFeedbacks} />
        <Stack.Screen name="DoctorFeedbacks" component={DoctorFeedbacks} />
        <Stack.Screen name="AllFeedbackFilterScreen" component={AllFeedbackFilterScreen} options={screenOptions} />
    </Stack.Navigator>
));

const Tabs = tab(Tab => () => (
    <Tab.Navigator
        screenOptions={{
            headerShown: false,
            gestureEnabled: true,
        }}
        initialRouteName="Home"
        backBehavior="initialRoute"
        tabBar={props => <TabBar {...props} />}>
        <Tab.Screen name="Home" component={homeTabRoutes} />
        <Tab.Screen name="MedCentersList" component={medCentersRoutes} />
        {USE_MEDICINE_SEARCH !== '0' && <Tab.Screen name="SearchDrugs" component={searchDrugsRoutes} />}
        <Tab.Screen name="MyCard" component={myCardRoutes} />
        {useAllFeedbacks && <Tab.Screen name="MainFeedbacks" component={allFeedbacksRoutes} />}
        <Tab.Screen name="Other" component={otherRoutes} />
    </Tab.Navigator>
));

const Top = stack(Stack => () => (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
        <Stack.Screen name="Tabs" component={Tabs} />
        <Stack.Screen name="Account" component={accountRoutes} options={screenOptions} />
        <Stack.Screen name="OutdatedAppVersion" component={OutdatedAppVersion} options={screenOptionsRoot} />
        <Stack.Screen name="ConsentConfirmation" component={ConsentConfirmation} options={screenOptionsRoot} />

        <Stack.Screen name="Visit" component={visitRoutes} options={screenOptions} />
        <Stack.Screen name="DrugsList" component={drugListRoutes} options={optionsDrugs} />
        <Stack.Screen name="DrugDetails" component={drugDetailsRouts} options={optionsDrugs} />
        {/* <Stack.Screen name="DrugDetails" component={searchDrugsRoutes} /> */}
        {/* <Stack.Screen name="DoctorDetails" component={doctorRoutes} />
        <Stack.Screen name="MedCenterDetails" component={medCenterRoutes} /> */}
    </Stack.Navigator>
));

export const navigationRef = React.createRef();

export const navigate = (name, params) => navigationRef.current?.navigate(name, params);

const documentTitle = {
    formatter(options, route) {

        const formTitle = options?.title;
        const routeName = route?.name;
        let calculatedTitle = Object.R(formTitle || `screenTitles.${routeName}`);
        if (calculatedTitle?.startsWith?.('screenTitles.') && calculatedTitle?.endsWith?.('Route')) {

            calculatedTitle = Object.R(`screenTitles.${routeName?.substring(0, routeName.length - 5)}`);

        }
        return calculatedTitle;

    },
};
export default ({ store }) => {

    useEffect(() => {

        store.setNavigator(navigationRef.current);

    }, [navigationRef.current, store]);

    return (
        <NavigationContainer ref={navigationRef} documentTitle={documentTitle}>
            <Top />
        </NavigationContainer>
    );

};
