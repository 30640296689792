/* eslint-disable prefer-object-spread */
export const mergeObjects = (o1, o2) => Object.create(
    Object.getPrototypeOf(o1),
    Object.assign({}, Object.getOwnPropertyDescriptors(o1), Object.getOwnPropertyDescriptors(o2)),
);

export const cloneObject = originalObject => (typeof originalObject === 'object'
    ? Object.create(Object.getPrototypeOf(originalObject), Object.getOwnPropertyDescriptors(originalObject))
    : originalObject);

export const deepCloneObject = (obj) => {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }
    if (obj instanceof Date) {
        return new Date(obj.getTime());
    }

    const clonedObj = Object.create(Object.getPrototypeOf(obj));

    // eslint-disable-next-line no-restricted-syntax
    for (const key of Reflect.ownKeys(obj)) {
        const descriptor = Object.getOwnPropertyDescriptor(obj, key);

        // eslint-disable-next-line no-prototype-builtins
        if (descriptor.hasOwnProperty('value')) {
            // If the property is a value, clone it
            descriptor.value = deepCloneObject(descriptor.value);
        }

        Object.defineProperty(clonedObj, key, descriptor);
    }

    return clonedObj;
};
