import { useNavigation } from '@react-navigation/native';
import EStyleSheet from 'react-native-extended-stylesheet';
import { useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import { findNodeHandle } from 'react-native';
import RNExitApp from 'react-native-exit-app';
import {
    dataFrom, Page, Platform, React, Section, Text, TouchableWithoutFeedback, View,
} from '../../common';
import { SwipeBack } from '../../common/SwipeBack';
import {
    getIsEmailUnconfirmed,
    getMainScreenOptions,
    getNeedInAppUpdates,
    getUser,
    getUserInteractionError,
    isProfileEmpty,
    isProfileHaveRelatives,
    plannedVisits,
} from '../../selectors';
import { VisitsList } from '../Visits/VisitsList';
import { syncUserState } from '../../init';
import { PlannedVisitListItem } from '../Visits/VisitListItem';
import { useIsOffine } from '../../services/http';
import { InAppUpdater } from '../../common/InAppUpdater';
import { EndOfLifeApp } from '../../common/EndOfLifeApp';
import { getVisitForScroll } from '../../selectors/forms';
import actions from '../../actions';
import { FamilyStoreLocalPanel } from '../Family/FamilyStoreLocalPanel';
import {
    AddVisitsIcon, Banner, PopularQueries, SectionWithVisitsLoading,
} from './snippets';
import { FavoritesListSection } from './snippets/Favorites';
import { ACodeReminder } from './snippets/ACodeReminder';
import { InstallMobilePanel } from './snippets/InstallMobilePanel';
import { EmailReminder } from './snippets/EmailReminder';
import { OfflineClinicWarning } from './snippets/OfflineClinicWarning';

const PlannedVisitsList = dataFrom(plannedVisits, props => (
    <VisitsList
        paddingLeft={12}
        {...props}
        ListItem={PlannedVisitListItem}
        enableLoadFromClinic
        isInPastVisitScreen={false}
    />
));

const useErrorNotification = () => {

    const error = useSelector(getUserInteractionError);
    const isOffline = useIsOffine();
    if (error) {

        return error;

    }
    if (isOffline) {

        return Object.R('error.network_connection');

    }
    return null;

};

const PageContainer = ({ children, onGetRef }) => {

    const error = useErrorNotification();
    return (
        <Page name="home" notification={error} onPullRefresh={syncUserState} hideOnBackground onGetRef={onGetRef}>
            {children}
        </Page>
    );

};

export const FillProfile = ({ unconfirmedEmails, paddingLeft }) => {

    const navigation = useNavigation();
    const onClickFillProfileHandler = () => {

        navigation.navigate(unconfirmedEmails === true ? 'Account' : 'EditAccount');

    };

    const style = EStyleSheet.create({
        container: {
            paddingHorizontal: 12,
            paddingLeft: paddingLeft ?? 12,
        },
        link: {
            color: '$appTitleColor',
            fontSize: 14,
        },

        touch: {},

        text: {
            color: '$lightGray',
            fontSize: 14,
        },
    });

    return (
        <Text style={style.container} numberOfLines={0}>
            <Text style={style.text}>{Object.R('titles.fillProfileHint')}</Text>
            <TouchableWithoutFeedback style={style.touch} onPress={onClickFillProfileHandler}>
                <Text style={style.link}>{Object.R('titles.fillProfileLink')}</Text>
            </TouchableWithoutFeedback>
        </Text>
    );

};

/**
 * Home page.
 */
export const Home = Page.register(
    ({
        profileEmpty, unconfirmedEmails, plannedVisits: visits, user, haveRelatives,
    }) => {

        const componentRef = useRef();
        const scrollRef = useRef();
        const scroll = useSelector(getVisitForScroll);
        const updates = useSelector(getNeedInAppUpdates);
        const { shouldDisplayFavorites, shouldDisplayPopularQueries } = useSelector(getMainScreenOptions);

        const scrollToVisit = () => {

            if (!scrollRef.current || !componentRef.current) {

                return;

            }
            componentRef?.current?.measureLayout?.(findNodeHandle(scrollRef.current), (x, y) => {

                scrollRef?.current?.scrollTo?.({
                    x: 0,
                    y,
                });
                actions.setFormValue('visitForScroll', null);

            });

        };

        useEffect(() => {

            if (!scrollRef.current || !componentRef.current || !scroll?.id) {

                return;

            }
            scrollToVisit();

        }, [scroll?.id, componentRef.current, scrollRef.current]);

        return (
            <SwipeBack onSwipeBack={() => {

                RNExitApp.exitApp();

            }} enabled forOS={['ios']}>
                <View style={{ flex: 1 }}>
                    <PageContainer
                        onGetRef={(r) => {

                            scrollRef.current = r;

                        }}>
                        <OfflineClinicWarning />
                        <ACodeReminder />
                        <InstallMobilePanel />
                        {Platform.OS !== 'web' && (updates?.ios || updates?.android) && (
                            <InAppUpdater
                                useVersionCheck
                                permanentCancelUpdate
                                notifyPeriod={updates?.period}
                                elseComponent={<FamilyStoreLocalPanel />}
                            />
                        )}
                        {/* <TestPermission /> */}
                        {Platform.OS === 'web' && <FamilyStoreLocalPanel />}
                        <EmailReminder />
                        <Section ns="banner" style={{ marginTop: 12 }}>
                            <Banner />
                        </Section>
                        {/* <SetReminder /> */}
                        {shouldDisplayPopularQueries ? (
                            <Section ns="doctor_speciality" title="titles.popularQueries">
                                <PopularQueries />
                            </Section>
                        ) : null}
                        {shouldDisplayFavorites ? <FavoritesListSection /> : null}
                        <SectionWithVisitsLoading
                            title="titles.nearest_visits"
                            right={
                                (!profileEmpty || haveRelatives) &&
                        !user?.isLoading && <AddVisitsIcon isFuture size={18} from={'Home'} />
                            }>
                            {profileEmpty === true && (!visits || !visits?.length) ? (
                                user?.isLoading === false &&
                        (user?.isFetching === false || Platform.OS === 'web') && (
                        <FillProfile unconfirmedEmails={unconfirmedEmails} />
                                )
                            ) : (
                                <PlannedVisitsList
                                    onGetRef={(r) => {

                                        componentRef.current = r;

                                    }}
                                    refId={scroll?.id}
                                />
                            )}
                        </SectionWithVisitsLoading>
                        <EndOfLifeApp />
                    </PageContainer>
                </View>
            </SwipeBack>
        );

    },
    {
        profileEmpty: { from: isProfileEmpty },
        unconfirmedEmails: { from: getIsEmailUnconfirmed },
        plannedVisits: { from: plannedVisits },
        user: { from: getUser },
        haveRelatives: { from: isProfileHaveRelatives },
    },
);
