// noinspection JSUnusedGlobalSymbols

const initialState = {
    id: null,
    attachmentsList: [],
    date: '',
    timetableId: null,
    isLoading: false,
    timeslots: [],
    onlineBookingAllowed: false,
    time: null,
};

const RESET_VISIT = 'RESET_EDITED_VISIT';
const UPDATE_VISIT = 'UPDATE_EDITED_VISIT';

export const resetEditedVisit = payload => dispatch => dispatch({
    type: RESET_VISIT,
    payload,
});
export const updateEditedVisit = payload => dispatch => dispatch({
    type: UPDATE_VISIT,
    payload: { visitEdited: true, ...payload },
});

export const visitEditReducerCreator = () => {
    const actionHandlers = new Map([
        [
            UPDATE_VISIT,
            (state, { payload }) => ({ ...state, ...payload }),
        ],
        [
            RESET_VISIT,
            (state, { payload = initialState }) => ({ ...payload }),
        ],
    ]);

    return (state = initialState, action) => {
        if (actionHandlers.has(action.type)) {
            return actionHandlers.get(action.type)(state, action);
        }
        return state;
    };
};

export default visitEditReducerCreator();
