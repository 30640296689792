import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import {
    Box, Icon, Logo, Page, Platform, React, Section, shareData, Subtitle, TextInput, Title,
} from '../../common';
import userService from '../../services/user';
import { setFormData } from '../../actions/forms';

const Footer = () => (
    <Section
        title=" "
        right={
            Platform.OS !== 'web' ? (
                <Box centered gap={2}>
                    <Title bold centered id="buttons.share_app" />
                    <Icon.ShareRound onPress={() => shareData('App Link')} />
                </Box>
            ) : null
        }
        style={{
            marginBottom: 20,
            marginRight: 20,
        }}
    />
);

export const FamilyInviteConfirm = Page.register(
    ({
        code = '',
        setState,
        error,
        requestFamilyCode = async () => {
            try {
                const familyCode = await userService.getFamilyCode();
                setState({ code: familyCode });
            } catch (err) {
                setState({ error: err });
            }
        },
    }) => {
        const dispatch = useDispatch();
        const navigation = useNavigation();
        useEffect(() => {
            dispatch(
                setFormData({
                    FamilyInviteConfirm: {
                        back: () => {
                            navigation.goBack();
                            return true;
                        },
                    },
                }),
            );
        }, []);
        return (
            <Page
                name="family-invite-confirm"
                notification={
                    error && {
                        message: error.message,
                        code: error.code || -1,
                        onSubmit: requestFamilyCode,
                    }
                }
                footer={<Footer />}
                onDidMount={requestFamilyCode}>
                <Section>
                    <Logo.Wrapper
                        title="titles.code_to_relative"
                        subtitle="titles.confirm_affinity"
                        titleStyle={{ paddingBottom: 12 }}
                    />
                </Section>
                <Section centered>
                    <TextInput
                        disabled
                        value={`${code}`}
                        style={{
                            textAlign: 'center',
                            minWidth: 188,
                            paddingVertical: 16,
                            paddingLeft: 12,
                            borderColor: '#E0E0E0',
                            backgroundColor: 'white',
                        }}
                        textStyle={{ textAlign: 'center' }}
                    />
                    <Subtitle.Bigger
                        numberOfLines={null}
                        id="titles.permanentCode"
                        centered
                        style={{
                            marginTop: 10,
                            color: '#9c9c9c',
                        }}
                    />
                </Section>
            </Page>
        );
    },
);
