import { SCHEME } from '../scheme';
import { createSelector, dbGettr, gettr } from './utils';
import { getIsAuthenticated, getUserInfo } from './getters';
import { doctorsWithFeedbackSums } from './doctors';
import { getCurrentDoctorFeedbacks } from '.';

/**
 * Feedbacks
 */

export const getFeedbacks = store => store.feedbacks;

export const getActualFeedbacks = createSelector(
    [getIsAuthenticated, getFeedbacks, getUserInfo, doctorsWithFeedbackSums, getCurrentDoctorFeedbacks],
    (isAuthenticated, feedbacks, userInfo, sums, feedbackInfo) => {
        const feedbacksSums = {
            feedbacksCount: 0,
            recommendCount: 0,
            notRecommendCount: 0,
            neutralCount: 0,
            recommendationIndex: 0,
            textFeedbackCount: 0,
        };
        if (Array.isArray(sums)) {
            const found = feedbackInfo || sums.find(({ _id }) => _id === feedbacks?.forItemId);
            if (found) {
                feedbacksSums.feedbacksCount = found.feedbacksCount;
                feedbacksSums.textFeedbackCount = found.textFeedbackCount;
                feedbacksSums.neutralCount = found.neutralCount;
                feedbacksSums.recommendCount = found.recommendCount;
                feedbacksSums.notRecommendCount = found.notRecommendCount;
                feedbacksSums.recommendationIndex = found.recommendationIndex;
            }
        } else if (!sums && feedbackInfo) {
            feedbacksSums.feedbacksCount = feedbackInfo.feedbacksCount;
            feedbacksSums.textFeedbackCount = feedbackInfo.textFeedbackCount;
            feedbacksSums.neutralCount = feedbackInfo.neutralCount;
            feedbacksSums.recommendCount = feedbackInfo.recommendCount;
            feedbacksSums.notRecommendCount = feedbackInfo.notRecommendCount;
            feedbacksSums.recommendationIndex = feedbackInfo.recommendationIndex;
        }
        return {
            isAuthenticated,
            feedbacks,
            userInfo,
            feedbacksSums,
        };
    },
);

export const getFeedbackSort = createSelector([dbGettr(SCHEME.PHYSICIANS_FEEDBACK_SORT_BY)], state => state);

export const getFeedbackFormData = createSelector([getFeedbacks], feedbacks => feedbacks?.feedbackFormData);

// noinspection JSUnresolvedVariable
export const getMyVotes = createSelector([gettr('feedbacks')], feedbacks => ({
    physicians: feedbacks?.votes_physicians,
    clinics: feedbacks?.votes_clinics,
}));
