/* eslint-disable no-use-before-define */
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { StyleSheet } from 'react-native';
import {
    Button, ButtonsGroup, Page, React, Text, View,
} from '../../common';
import {
    getIsPersonalized, getIsUserFetching, getUserInfo, getUserInteraction, getUserLoadingState,
} from '../../selectors';
import { back } from '../../actions';
import api from '../../services/api';
import { setInDeleting } from '../../actions/user';

export const AccountRemove = Page.register(
    // eslint-disable-next-line complexity
    ({ interaction: { error }, lastUserAction, navigation }) => {
        const timerRef = useRef(0);

        const [timer, setTimer] = useState(9);
        const timerHandler = () => {
            setTimer((prevState) => {
                if (prevState <= 1 && timerRef.current) {
                    clearTimeout(timerRef.current);
                }
                return prevState - 1;
            });
        };

        useEffect(() => {
            timerRef.current = setInterval(timerHandler, 1000);
            return () => {
                if (timerRef.current) {
                    clearInterval(timerRef.current);
                }
            };
        }, []);

        const dispatch = useDispatch();

        return (
            <Page
                safeArea
                name="accountRemove"
                notification={
                    error && {
                        ...error,
                        onSubmit: lastUserAction,
                    }
                }>
                <View style={styles.container}>
                    <Text style={styles.text}>{Object.R('titles.if_you_remove_account')}</Text>
                    <View style={styles.bottom}>
                        <ButtonsGroup>
                            <Button
                                ns="removeAccountButton"
                                transparent
                                title={`${timer ? `(${timer}) ` : ''}${Object.R('buttons.remove')}`}
                                disabled={timer > 0}
                                action={async () => {
                                    dispatch(setInDeleting(true));
                                    await api.removeAccount();
                                    await api.logOut();
                                    navigation.replace('Home');
                                    // back();
                                }}
                                numberOfLines={2}
                                danger
                                titleStyles={{ fontFamily: 'SourceSansPro-Semibold' }}
                                capitalize
                            />
                            <Button
                                ns="cancelButton"
                                normal
                                title={Object.R('buttons.cancel')}
                                action={() => {
                                    back();
                                }}
                                numberOfLines={2}
                                capitalize
                                primary={false}
                            />
                        </ButtonsGroup>
                    </View>
                </View>
            </Page>
        );
    },
    {
        userInfo: { from: getUserInfo },
        interaction: { from: getUserInteraction },
        isFetchingUser: { from: getIsUserFetching },
        isLoading: { from: getUserLoadingState },
        isPersonalized: { from: getIsPersonalized },
    },
);

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
    },
    text: {
        marginHorizontal: 10,
        marginVertical: 10,
    },
    bottom: {
        flex: 1,
        justifyContent: 'flex-end',
        marginBottom: 15,
    },
});
