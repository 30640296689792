// noinspection JSUnusedGlobalSymbols,ExceptionCaughtLocallyJS

import service from '../services/tests';
import userService from '../services/user';
import { decryptCached } from '../utils/crypto';
import { putIntoStore, SCHEME } from '../store';
import { createReducerFor } from './utils';

const TYPE = 'TESTS';

export const fetchTests = () => async (dispatch) => {
    let data = [];
    const error = null;
    const payload = {
        data,
        error,
    };
    try {
        payload.isLoading = true;
        dispatch({
            type: TYPE,
            payload: { isLoading: true },
        });
        data = await service.fetchTests();
    } catch (ex) {
        payload.error = ex;
    } finally {
        payload.data = data;
        payload.isLoading = false;
        dispatch({
            type: TYPE,
            payload,
        });
    }
};

export const setTests = tests => async (dispatch) => {
    if (!tests) {
        dispatch(({
            type: TYPE,
            payload: {
                error: null,
                isLoading: false,
                data: null,
            },
        }));
    }
    dispatch(({
        type: TYPE,
        payload: {
            error: null,
            isLoading: true,
        },
    }));
    try {
        const userInfo = await userService.getUserInfo();
        const { hashKey } = userInfo;
        const decrypted = tests.map(e => decryptCached(e.data, hashKey));
        dispatch(({
            type: TYPE,
            payload: {
                error: null,
                data: decrypted,
            },
        }));
    } catch (e) {
        dispatch(({
            type: TYPE,
            payload: { error: e },
        }));
    } finally {
        dispatch(({
            type: TYPE,
            payload: { isLoading: false },
        }));
    }
};

export const syncTests = () => async (dispatch) => {
    dispatch(({
        type: TYPE,
        payload: {
            error: null,
            isSyncLoading: true,
        },
    }));
    try {
        const result = await service.syncTests();
        if (result?.respInfo?.status !== 200) {
            // noinspection UnnecessaryLocalVariableJS
            const error = { code: result?.respInfo.code };
            throw error;
        }
    } catch (e) {
        dispatch(({
            type: TYPE,
            payload: { error: e },
        }));
    } finally {
        dispatch(({
            type: TYPE,
            payload: { isSyncLoading: false },
        }));
    }
};

export const startTestLoading = () => dispatch => dispatch({
    type: TYPE,
    payload: { isLoading: true },
});

export const stopTestLoading = () => dispatch => dispatch({
    type: TYPE,
    payload: { isLoading: false },
});

export const emptyTests = () => async (dispatch) => {
    dispatch({
        type: TYPE,
        payload: {
            error: null,
            data: null,
            isLoading: false,
        },
    });
};


export const resetTestFilter = () => {
    try {
        putIntoStore(SCHEME.TESTS_FILTER, {
            visitors: [],
            statuses: [],
            clinics: [],
        });
        putIntoStore(SCHEME.TESTS_PREFILTER_VISITORS, []);
        putIntoStore(SCHEME.TESTS_PREFILTER_CLINICS, []);
        putIntoStore(SCHEME.TESTS_PREFILTER_STATUSES, []);
    } catch {
        //
    }
};
export default createReducerFor(TYPE);
