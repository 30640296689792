import EStyleSheet from 'react-native-extended-stylesheet';
import { COLORS } from '../../styles/colors';

const styles = EStyleSheet.create({
    wrapper: {
        height: '$deviceHeight',
        backgroundColor: '#FFFFFF',
        paddingBottom: 80,
        position: 'relative',
    },
    drugstoresWrapper: {
        flexWrap: 'nowrap',
        flexDirection: 'column',
        position: 'relative',
        backgroundColor: '$white',
        flexGrow: 1,
    },
    accordionHeader: {
        paddingTop: 21,
        paddingBottom: 17,
        paddingLeft: 20,
        backgroundColor: '#F9F9F9',
        borderColor: '#E9E9E9',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        alignItems: 'center',
        flexDirection: 'row',
        paddingRight: 24,
    },
    chevron: { marginHorizontal: 10 },
    accordionTxt: {
        fontSize: 12,
        color: '#555555',
    },
    accordionContent: {
        paddingLeft: 25,
        paddingRight: 15,
        paddingTop: 5,
        paddingBottom: 10,
    },
    showAllBtnWrapper: { marginLeft: 'auto' },
});

export default styles;

// noinspection JSUnusedGlobalSymbols
export const drugList = EStyleSheet.create({
    list: {
        paddingLeft: 0,
        marginLeft: 0,
        paddingBottom: 16,
        borderBottomWidth: 1,
        borderColor: '#E9E9E9',
    },
    emptyListText: {
        borderTopWidth: 1,
        borderColor: '#E9E9E9',
        paddingTop: 16,
        color: '#9E9E9E',
        lineHeight: 20,
    },
    listHeaderDate: {
        fontFamily: 'Museo Sans Cyrl',
        color: '$titleGray',
    },
    listHeaderTitle: {
        fontFamily: '$secondaryTextFontFamily',
        fontSize: 12,
        fontWeight: '600',
        color: '#5F5F5F',
    },
    drugsListFindAllButton: { marginRight: 30 },
    drugInfoRightContiner: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    listItem: {
        paddingTop: 12,
        paddingLeft: 12,
        paddingBottom: 16,
        paddingRight: 16,
        backgroundColor: '#fff',
        borderRadius: 3,
        borderBottomColor: '#E9E9E9',
        borderWidth: 1,
        borderColor: '#E9E9E9',
        marginBottom: 4,
    },
    listItemTitle: {
        fontFamily: '$secondaryTextFontFamily',
        paddingRight: 2,
        color: '#555555',
        fontSize: 16,
    },
    listItemSubtitle: { marginTop: 5 },
    preparatInfo: { marginRight: 40 },
    preparatInfoText: {
        fontSize: 12,
        color: '#6C6C6C',
    },
    text: {
        fontSize: 12,
        color: '#777777',
    },
    wrapperStyle: {
        marginLeft: 0,
        paddingRight: 0,
    },
    titleTxt: {
        fontSize: 14,
        lineHeight: 16,
        fontWeight: '500',
        color: '#555555',
        fontFamily: '$sourceSansPropFontFamily',
    },
    title: {
        marginBottom: 6,
        marginLeft: 0,
    },
    subtitle: {
        fontFamily: '$sourceSansPropFontFamily',
        fontSize: 12,
        lineHeight: 16,
        fontWeight: '300',
        color: '#555555',
    },
    subtitleContainer: { width: '$deviceWidth * 0.48' },
    rightTitle: {
        color: '$emerald',
        fontSize: 12,
        lineHeight: 16,
        fontFamily: '$sourceSansPropFontFamily',
        fontWeight: '300',
        textAlign: 'right',
        marginRight: 20,
        width: '100%',
    },
    container: {
        borderWidth: 1,
        borderColor: '#E9E9E9',
        borderBottomColor: '#E9E9E9',
        borderRadius: 3,
        paddingTop: 12,
        paddingRight: 18,
        paddingBottom: 20,
        paddingLeft: 12,
        marginVertical: 2,
        backgroundColor: '$white',
    },
});

// noinspection JSUnusedGlobalSymbols
export const drugstoresSorting = EStyleSheet.create({
    sortBar: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    sortBtn: {
        flex: 1,
        justifyContent: 'center',
    },
    sortBtnTxt: { textAlign: 'center' },
    sortActive: {
        borderBottomWidth: 2,
        borderColor: '#5C85DD',
        opacity: 1,
    },
    btnWrapper: {
        paddingTop: 16,
        paddingBottom: 11,
    },
});

// noinspection JSUnusedGlobalSymbols
export const searchDrugstores = EStyleSheet.create({
    wrapper: {
        height: '$deviceHeight',
        backgroundColor: '#FFFFFF',
        paddingBottom: 80,
        position: 'relative',
    },
    drugstoresWrapper: {
        flexWrap: 'nowrap',
        flexDirection: 'column',
        position: 'relative',
        backgroundColor: '$white',
        flexGrow: 1,
    },
    accordionHeader: {
        paddingTop: 21,
        paddingBottom: 17,
        paddingLeft: 20,
        backgroundColor: '#F9F9F9',
        borderColor: '#E9E9E9',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        alignItems: 'center',
        flexDirection: 'row',
        paddingRight: 24,
    },
    chevron: { marginHorizontal: 10 },
    accordionTxt: {
        fontSize: 12,
        color: '#555555',
    },
    accordionContent: {
        paddingLeft: 25,
        paddingRight: 15,
        paddingTop: 5,
        paddingBottom: 10,
    },
    showAllBtnWrapper: { marginLeft: 'auto' },
});

export const drugstoreDetails = EStyleSheet.create({
    $addressBoxBorderColor: '#E0E0E0',
    $userAddressColor: '#212121',
    $userNameColor: '#828282',
    wrapper: {
        height: '$deviceHeight',
        backgroundColor: '$white',
        flexDirection: 'column',
    },
    mapContainer: {
        width: '$deviceWidth',
        height: '$deviceHeight * 0.40',
        position: 'relative',
        zIndex: 0,
    },
    map: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    addressBoxInnerWrap: { paddingRight: 50 },
    drugsListBox: { paddingVertical: 16 },
    drugName: {
        fontSize: 14,
        color: '#212121',
        paddingVertical: 4,
    },
    bottomBox: {
        flex: 1,
        position: 'relative',
        zIndex: 1,
        paddingBottom: 16,
        paddingTop: 16,
    },
    userAddress: {
        fontSize: 16,
        fontWeight: '600',
        color: '#555555',
    },
    userName: {
        fontSize: 12,
        color: '#555555',
    },
    subtitle: {
        marginTop: 20,
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
    },
    price: {
        color: '#555555',
        fontSize: 16,
        fontWeight: '600',
    },
    roundedBtn: {
        width: 45,
        height: 45,
        borderRadius: 99,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    btnCall: { backgroundColor: '#5C85DD' },
    btnRoute: {
        backgroundColor: '$emerald',
        marginTop: 20,
    },
    btns: {
        position: 'absolute',
        bottom: 20,
        right: 25,
        flexDirection: 'column',
        zIndex: 10,
    },
    modalButton: {
        paddingVertical: 14,
        paddingLeft: 20,
        borderTopWidth: 1,
        borderColor: 'blue',
        color: 'red',
    },
    modalTitle: {
        textAlign: 'center',
        fontSize: 16,
        fontFamily: '$sourceSansPropFontFamily_semibold',
        color: '#555555',
        marginBottom: 12,
        marginTop: 12,
    },
    borderBottom: {
        borderBottomWidth: 1,
        borderColor: '#E9E9E9',
    },
    infoContainer: {
        borderTopWidth: 1,
        borderColor: '#E9E9E9',
        paddingHorizontal: 0,
        backgroundColor: '#ffffff',
    },
});

export const firstAidKitBannerStyles = EStyleSheet.create({
    header: {
        color: COLORS.STANDARD_GRAY,
        fontFamily: '$sourceSansPropFontFamily_semibold',
        fontSize: 16,
        fontWeight: '600',
    },
    headerPrimary: {
        color: '$emerald',
        fontFamily: '$sourceSansPropFontFamily_semibold',
        fontSize: 16,
        fontWeight: '600',
    },
    text: {
        color: COLORS.STANDARD_GRAY,
        fontSize: 12,
    },
    pannel: {
        paddingBottom: 0,
        marginBottom: 0,
    },
    container: {
        paddingHorizontal: 10,
        paddingVertical: 5,
    },
    wrapper: {
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 15,
    },
    main: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    headerWrapper: {
        // flex: 1,
        // alignContent: 'flex-start',
        // alignItems: 'flex-start',
        // justifyContent: 'flex-start',
        paddingBottom: 5,
    },
    priceWrapper: {
        // flex: 2,
        // alignContent: 'flex-end',
        // alignItems: 'flex-end',
        // justifyContent: 'flex-end',
    },
});

export const firstAidKitBannerSimpleStyles = EStyleSheet.create({
    header: {
        color: COLORS.STANDARD_GRAY,
        fontFamily: '$sourceSansPropFontFamily_semibold',
        fontSize: 18,
        fontWeight: '600',
    },
    headerPrimary: {
        color: '$emerald',
        fontFamily: '$sourceSansPropFontFamily_semibold',
        fontSize: 18,
        fontWeight: '600',
    },
    container: {
        marginBottom: 0,
        paddingHorizontal: 21,
        paddingVertical: 23,
        justifyContent: 'space-between',
    },
});

export const firstAidKitstyles = EStyleSheet.create({
    section: {
        marginTop: 5,
        marginBottom: 0,
    },
    sectionTextStyles: {
        paddingLeft: 0,
        weight: 'bold',
    },
    addIcon: {
        width: 20,
        height: 20,
        marginLeft: 8,
    },
    checkboxWrapper: { marginRight: 12 },
    list: { paddingTop: 5 },
    footer: {
        borderTopWidth: 1,
        borderTopRadius: 2,
        borderTopColor: '#e0e0e0',
        paddingHorizontal: 12,
        paddingBottom: 12,
    },
});

export const drugstoresFilter = EStyleSheet.create({
    filter: { marginRight: 12 },
    modalContainer: { marginHorizontal: '10%' },
    filterItem: {
        flexDirection: 'row',
        paddingVertical: 14,
        borderBottomWidth: 1,
        borderColor: '$ultraLightGray',
        alignItems: 'center',
    },
    firstFilterItem: { borderTopWidth: 1 },
    titleWrap: { flex: 9 },
    title: {
        marginHorizontal: 24,
        fontSize: 16,
        color: COLORS.STANDARD_GRAY,
    },
    switchWrap: {
        flex: 1,
        marginHorizontal: 24,
        alignItems: 'center',
        justifyContent: 'center',
    },
});
