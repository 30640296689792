import firebase from './firebase';
import { storage } from './localstorage';

const USER_KEY = 'user';

export const addVisitStats = async ({
    id,
    attachments: { length: picturesAttached = 0 } = {},
    comment,
    drugs: { length: drugsAttached = 0 } = {},
    titleChanged = false,
    visitEdited = false,
    profileId,
    descriptionWords = comment ? comment.split(' ').length : 0,
}) => {
    const { me: { profileId: pId } = {} } = await storage.get(USER_KEY) || {};
    await firebase.setVisitsStats({
        id,
        descriptionWords,
        drugsAttached,
        picturesAttached,
        titleChanged,
        visitEdited,
        visitor: profileId === pId ? 'self' : 'child',
    });
};

// noinspection JSUnusedGlobalSymbols
export const setVisitStatusIfExists = async (id, status) => firebase.setVisitStatusIfExists(id, status);

export const saveUserStats = async ({
    favorites = [],
    drugsSearch: { length: drugsSearchHistory = 0 } = {},
    basket: { length: drugsInFirstAidKit = 0 } = {},
    documents = [],
} = {}) => {
    const { data: pData = {} } = await storage.get('physicians') || {};
    const { data: cData = {} } = await storage.get('clinics') || {};

    firebase.setUserStats({
        drugsSearchHistory,
        drugsInFirstAidKit,
        doctorsBookmarks: favorites.filter(f => pData[f]).length,
        clinicsBookmarks: favorites.filter(f => cData[f]).length,
        personalDocuments: documents.length,
    });
};
