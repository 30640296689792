import ImageViewer from 'react-native-image-zoom-viewer';
import {
    Button, ButtonsGroup, Dimensions, Icon, Modal, Page, React, Title, View, withState,
} from '../common';

/**
 * Image slider component to display images as slides
 *
 * WARNING: passing 'onDelete', make sure to implement delete logic there
 */
export const ImageSlider = withState(({
    initialId,
    dataUrls,
    onDelete,
    setState,
    currentItemId = initialId,
}) => (
    <View style={{
        flex: 1,
        backgroundColor: 'black',
    }}>
        <ImageViewer
            style={{ marginBottom: 48 }}
            index={initialId ? dataUrls.findIndex(({ id }) => id === initialId) : 0}
            enablePreload
            saveToLocalByLongPress={false}
            imageUrls={dataUrls}
            flipThreshold={Dimensions.get('window').width / 2}
            renderIndicator={() => null}
            onChange={index => setState({ currentItemId: dataUrls[index].id })}
        />
        {
            dataUrls.length > 1
                ? <View style={{
                    position: 'absolute',
                    bottom: 16,
                    left: 16,
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'transparent',
                    zIndex: 10,
                }}>
                    <Title style={{
                        color: 'white',
                        fontSize: 16,
                        backgroundColor: 'transparent',
                    }}>{Object.R('tiles.positionFromTotal', {
                            position: dataUrls.findIndex(({ id }) => id === currentItemId) + 1,
                            total: dataUrls.length,
                        })}</Title>
                </View> : null
        }
        {
            onDelete
                ? <View style={{
                    position: 'absolute',
                    bottom: 14,
                    right: 16,
                }}>
                    <Icon.Trash.White
                        style={{
                            backgroundColor: 'transparent',
                            width: 24,
                            height: 24,
                            borderRadius: 0,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        color="#FFFFFF"
                        onPress={() => Page.showModal((
                            <Modal hideClose>
                                <Title style={{
                                    alignSelf: 'center',
                                    textAlign: 'center',
                                }} bold id="titles.docDeleteConfirm"/>
                                <ButtonsGroup>
                                    <Button
                                        transparent
                                        title="buttons.no"
                                        action={Page.closeModal}
                                    />
                                    <Button
                                        primary
                                        title="buttons.yes"
                                        action={() => {
                                            onDelete(currentItemId);
                                            Page.closeModal();
                                        }}
                                    />
                                </ButtonsGroup>
                            </Modal>
                        ))}
                    />
                </View>
                : null
        }
    </View>
));
