/* eslint-disable no-use-before-define */
import { searchBar as style } from '../styles/input';
import { debouncer } from '../utils';
import {
    Platform, React, SearchBarNative, View,
} from './react';
import { Icon } from './Icon';
import { InputWrapper, TextInput } from './input';

/**
 * SearchBar component.
 *
 *  Uncontrolled mode. use initialValue for value.
 *
 * @see https://react-native-training.github.io/react-native-elements/docs/searchbar.html
 */
export const SearchBar = ({
    isLoading,
    initialValue = '',
    autoCorrect = true,
    additionalStyle,
    onFocusChange,
    placeholder = 'placeholders.search',
    onSearch,
    searchAfter = 0,
    withDebounce,
    borderRadius = 3,
    fixLength = 0,
    style: styleProp,
}) => {
    const [value, setValue] = React.useState(initialValue);

    React.useEffect(() => {
        if (initialValue) {
            setValue(initialValue);
            onSearch(initialValue);
        }
    }, [initialValue]);

    const onClear = React.useCallback(() => {
        onChangeText('');
    }, []);
    const debounce = debouncer(500);
    const onChangeText = React.useCallback(
        (v) => {
            setValue(v);
            if (withDebounce) {
                debounce(() => {
                    onSearch(v.length > searchAfter ? v : '');
                });
            } else {
                onSearch(v.length > searchAfter ? v : '');
            }
        },
        [searchAfter, initialValue],
    );

    if (searchAfter && value?.length > (searchAfter || 1) && !initialValue) {
        setValue(initialValue);
    }
    return (
        <InputWrapper>
            <View
                style={[
                    style.searchBar,
                    styleProp,
                    {
                        borderRadius,
                        marginRight: Platform.OS === 'web' && fixLength ? 10 - fixLength : 10,
                    },
                    additionalStyle,
                ]}>
                <SearchBarNative
                    searchIcon={Icon.SearchBarFind}
                    clearIcon={value ? <Icon.SearchBarClose onPress={onClear} /> : null}
                    platform="default"
                    autoCorrect={autoCorrect}
                    showloading={isLoading}
                    loadingprops={{ style: style.loadingIcon }}
                    placeholder={Object.R(placeholder)}
                    containerStyle={[style.container, { borderRadius }]}
                    rightIconContainerStyle={value ? { width: 30 } : {}}
                    leftIconContainerStyle={{ width: 30 }}
                    inputStyle={[style.input, { width: '100%' }]}
                    inputContainerStyle={[style.input, { paddingVertical: 0 }]}
                    value={value}
                    accessibilityLabel="search"
                    onCancel={onClear}
                    {...(Platform.OS === 'web' ? {} : { onClear })}
                    onChangeText={onChangeText}
                    underlineColorAndroid="transparent"
                    onFocus={onFocusChange}
                    onBlur={onFocusChange}
                    InputComponent={TextInput}
                />
            </View>
        </InputWrapper>
    );
};
