import { CREATED_MANUALLY } from '../../../actions/visits';
import { isPeriodsIntersect } from '../../../utils/dates';
import {
    Button, Modal, Page, React, Title, View,
} from '../../../common';

export const checkNearestVisits = ({
    startDate,
    endDate,
    visits,
    profile,
    visitInfo = {},
    onBook = () => {},
    onError = () => {},
    navigation,
    canChangeDate,
    canReassign,
    isEditingVisit,
}) => {
    const { orderId, covererCode } = visitInfo;
    if (
        startDate &&
        endDate &&
        visits?.some(
            ({
                id: visitId,
                startDate: visitStartDate,
                endDate: visitEndDate,
                profileId,
                dateStart = Date.parse(visitStartDate),
                dateEnd = Date.parse(visitEndDate),
                status,
                withoutTime,
                customerFirstName,
            }) => {
                if (Number.isNaN(dateEnd) && status === CREATED_MANUALLY) {
                    dateEnd = dateStart + 59000;
                }
                if (profileId !== profile?.id) {
                    return false;
                }
                if (
                    (status === CREATED_MANUALLY && withoutTime) ||
                    (customerFirstName && profile?.manual && customerFirstName !== profile?.firstName)
                ) {
                    return false;
                }

                if (orderId && orderId === visitId) {
                    return false;
                }
                return isPeriodsIntersect(startDate, endDate, dateStart, dateEnd);
            },
        )
    ) {
        Page.showModal(
            <Modal hideClose>
                <View>
                    <Title
                        id={canReassign ? 'titles.sameDateBookingError' : 'titles.sameDateBookingChangeDateError'}
                        bold
                        style={{
                            textAlign: 'center',
                            marginBottom: 12,
                        }}
                        numberOfLines={null}
                    />
                    {canChangeDate ? (
                        <Button
                            primary
                            title="buttons.chooseAnotherDate"
                            action={() => {
                                Page.closeModal();
                                navigation?.replace?.('DoctorRecordDatePick', {
                                    mdInfo: visitInfo.mdInfo,
                                    doctorInfo: visitInfo.doctorInfo,
                                    canChangeDate,
                                    isEditingVisit,
                                    canReassign,
                                    profileId: profile.id,
                                    covererCode,
                                });
                            }}
                        />
                    ) : null}
                    <Button
                        transparent
                        title="buttons.bookAnyway"
                        action={() => {
                            Page.closeModal();
                            onBook();
                        }}
                    />
                    <Button transparent title="buttons.close" action={Page.closeModal} />
                </View>
            </Modal>,
        );
    } else {
        onBook();
    }
};
