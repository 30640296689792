import {
    useCallback, useEffect, useRef, useState,
} from 'react';
import DatePicker from 'react-native-date-picker';
import TextInputMask from 'react-native-text-input-mask';
import {
    Icon,
    Modal2,
    Page,
    Platform,
    React,
    showNotification,
    SingleNavigation,
    TextInput,
    TouchableOpacity,
    View,
} from '../common';
import {
    adjustTimeZone,
    combineDates,
    formatDate,
    formatTime,
    isDateFuture,
    isValidDate,
    isValidTime,
    parseTimeString,
    currentTimezone, toDate,
} from '../utils/dates';
import { input } from '../styles/input';
import { COLORS } from '../styles/colors';
import { DateInput } from '../common/DateInput';
import { placeholderTextColor } from '../common/input';

export const DateSelector = ({
    date = new Date(),
    time = new Date(),
    mode = 'date',
    locale = 'ru',
    title,
    subtitle,
    buttonTitle,
    buttonCloseTitle,
    onSubmit,
    useSystemModal = false,
    timezoneOffsetInMinutes = currentTimezone,
    maxDate,
    minDate,
    onCloseModal,
}) => {

    const zeroTime = (d) => {

        try {

            timezoneOffsetInMinutes === 0 ? d.setUTCHours(0) : d.setHours(0);
            timezoneOffsetInMinutes === 0 ? d.setUTCMinutes(0) : d.setMinutes(0);
            timezoneOffsetInMinutes === 0 ? d.setSeconds(0) : d.setSeconds(0);

        } catch (e) {
            //
        }
        return d;

    };

    const selectedDate = useRef(date);
    const selectedTime = useRef(mode === 'date' ? zeroTime(time) : time);

    useEffect(() => {

        selectedDate.current = date;

    }, [date]);

    useEffect(() => {

        selectedTime.current = time;

    }, [time]);

    const dateChangeHandler = (d) => {

        if (mode === 'date') {

            selectedTime.current = d;

        }
        selectedDate.current = d;

    };

    const timeChangeHandler = (t) => {

        if (mode === 'time') {

            selectedDate.current = t;

        }
        selectedTime.current = t;

    };

    return (
        <Modal2
            title={title}
            _onClose={() => {

                if (onCloseModal) {

                    onCloseModal?.();

                } else {

                    useSystemModal ? Page.closeSystemModal() : Page.closeModal();

                }

            }}
            subtitle={subtitle}
            buttonTitle={(buttonTitle ?? mode === 'date'
                ? Object.R('titles.choose_the_date')
                : Object.R('titles.choose_the_time') ?? ''
            ).toUpperCase()}
            buttonCloseTitle={buttonCloseTitle}
            onSubmit={() => {

                onSubmit?.(combineDates(selectedDate.current, selectedTime.current));
                if (onCloseModal) {

                    onCloseModal?.();

                } else {

                    useSystemModal ? Page.closeSystemModal() : Page.closeModal();

                }

            }}
            useSystemModal={useSystemModal}>
            <View
                style={{
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                {mode === 'date' || mode === 'datetime' ? (
                    <DatePicker
                        androidMode={'spinner'}
                        date={selectedDate.current}
                        onDateChange={dateChangeHandler}
                        androidVariant="nativeAndroid"
                        mode="date"
                        is24hourSource="locale"
                        locale={locale}
                        timeZoneOffsetInMinutes={timezoneOffsetInMinutes}
                        minimumDate={minDate}
                        maximumDate={maxDate}
                        theme={'light'}
                    />
                ) : null}

                {mode === 'time' || mode === 'datetime' ? (
                    <DatePicker
                        androidMode={'spinner'}
                        date={selectedTime.current}
                        onDateChange={timeChangeHandler}
                        androidVariant="nativeAndroid"
                        mode="time"
                        is24hourSource="locale"
                        locale={locale}
                        timeZoneOffsetInMinutes={timezoneOffsetInMinutes}
                        theme={'light'}
                    />
                ) : null}
            </View>
        </Modal2>
    );

};

export const getDate = ({
    mode = 'data',
    date = new Date(),
    onSelectDate = () => {},
    useSystemModal = false,
    timezoneOffsetInMinutes,
    maxDate,
    minDate,
}) => {

    const modal = useSystemModal ? Page.showSystemModal : Page.showModal;
    modal(
        <DateSelector
            mode={mode}
            onSubmit={(d) => {

                onSelectDate(d);

            }}
            date={date ?? new Date()}
            time={date ?? new Date()}
            useSystemModal={useSystemModal}
            timezoneOffsetInMinutes={timezoneOffsetInMinutes}
            maxDate={maxDate}
            minDate={minDate}
        />,
    );

};

export const getFormat = (m, dt) => {

    const modes = {
        date: formatDate(dt),
        // eslint-disable-next-line no-nested-ternary
        time: dt ? (formatTime(dt, false,true)?.length < 5 ? `0${formatTime(dt, false, true)}` : formatTime(dt, false, true)) : '',
        datetime: `${formatDate(dt)} ${formatTime(dt, false, true)}`,
    };
    return modes[m];

};

const masks = {
    date: {
        format: '[00]{.}[00]{.}[9900]',
        web: '99.99.9999',
        maxLength: 10,
        placeholder: 'titles.datePlaceholder',
    },
    time: {
        format: '[00]{:}[00]',
        web: '99:99',
        maxLength: 5,
        placeholder: 'titles.timePlaceholder',
    },
    datetime: {
        format: '[00]{.}[00]{.}[9900] [00]{:}[00]',
        web: '99.99.9999 99:99',
        maxLength: 16,
        placeholder: 'titles.datetimePlaceholder',
    },
};

export const getNumber = (param, defaultValue) => {

    try {

        if (param === '') {

            return defaultValue ?? 0;

        }
        const n = Number(param);
        return Number.isNaN(n) ? defaultValue ?? 0 : n;

    } catch {

        return 0;

    }

};

export const splitDate = (date) => {

    let segments = date?.split?.('.');
    let year = -1;
    let month = -1;
    let day = -1;
    if (segments?.length !== 3) {

        segments = date?.split('-');
        if (segments?.length !== 3) {

            return null;

        }
        if (segments[0].length === 4) {

            year = getNumber(segments[0]);
            month = getNumber(segments[1]);
            day = getNumber(segments[2]);

        } else {

            day = getNumber(segments[0]);
            month = getNumber(segments[1]);
            year = getNumber(segments[2]);

        }

    } else {

        day = getNumber(date?.substring(0, 2), -1);
        month = getNumber(date?.substring(3, 5), -1);
        year = getNumber(date?.substring(6, 10), -1);

    }
    return {
        year,
        month,
        day,
    };

};

// eslint-disable-next-line max-statements
export const getDateError = (date, mode, secondPart, future, past) => {

    if (!date) {

        return 'emptyDate';

    }
    const getFuturePastError = (y, m, d, h, min) => {

        if (future && past) {

            return null;

        }
        const dt = new Date(y, m - 1, d, h, min);
        if (future === true && !isDateFuture(dt)) {

            return 'futureError';

        }
        if (past === true && isDateFuture(dt, false)) {

            return 'pastError';

        }
        return null;

    };
    let day = -1;
    let month = -1;
    let year = -1;
    let hour = -1;
    let minute = -1;
    let segments = [];
    switch (mode) {

        case 'date':
            segments = splitDate(date);
            if (!segments) {

                return 'dateError';

            }
            day = segments?.day;
            month = segments?.month;
            year = segments?.year;
            if (!isValidDate(day, month, year)) {

                return 'dateError';

            }
            if (secondPart) {

                hour = getNumber(new Date(secondPart).getHours());
                minute = getNumber(new Date(secondPart).getMinutes());

            } else {

                hour = 23;
                minute = 59;

            }
            return getFuturePastError(year, month, day, hour, minute);
        case 'time':
            hour = getNumber(date?.substring(0, 2));
            minute = getNumber(date?.substring(3, 5));
            if (secondPart) {

                day = new Date(secondPart).getDate();
                month = new Date(secondPart).getMonth() + 1;
                year = new Date(secondPart).getFullYear();

            }
            if (!isValidTime(hour, minute)) {

                return 'timeError';

            }
            if (!secondPart) {

                return null;

            }
            return getFuturePastError(year, month, day, hour, minute);
        case 'datetime':
            day = getNumber(date?.substring(0, 2), -1);
            month = getNumber(date?.substring(3, 5), -1);
            year = getNumber(date?.substring(6, 10), -1);
            hour = getNumber(date?.substring(11, 13), -1);
            minute = getNumber(date?.substring(14, 16), -1);
            if (!isValidDate(day, month, year) || !isValidTime(hour, minute)) {

                return 'dateError';

            }
            return getFuturePastError(year, month, day, hour, minute);
        default:
            return null;

    }

};

export const DateTimeSelector = ({
    title,
    date,
    onSelectDate,
    mode = 'datetime',
    error,
    edit,
    onError,
    future = true,
    past = true,
    secondPart,
    required,
    onFocus,
    onBlur,
    onTextChange,
    popupError = false,
    onClick,
    useSystemModal = false,
    inputStyle = {},
    inputStyleWeb = {},
    withTimezone = false,
}) => {

    const [isError, setIsError] = useState(error);
    const [visibleIOSSelector, setVisibleIOSSelector] = useState(false);
    const startDate = useRef(date || new Date());
    useEffect(() => {

        setIsError(error);

    }, [error]);

    const d = useCallback(
        (newDate) => {

            if (newDate) {

                return getFormat(mode, newDate);

            }
            return getFormat(mode, date);

        },
        [date, mode],
    );

    const [editText, setEditText] = useState(d());

    const isFormatError = useCallback(
        (text) => {

            if (!required && !text) {

                return null;

            }
            return getDateError(text ?? editText, mode, secondPart, !!future, !!past);

        },
        [editText, mode, secondPart, future, past, required],
    );

    useEffect(() => {

        const err = getDateError(editText, mode, secondPart, !!future, !!past);
        if (err && date) {

            popupError && showNotification(Object.R(`titles.${err}`));

        }

    }, [secondPart]);

    const onPressHandler = () => {

        onClick?.();
        if (date) {

            startDate.current = date;

        } else {

            if (editText && mode === 'time') {

                let hours = new Date().getHours();
                let minutes = new Date().getMinutes();
                startDate.current = new Date();
                try {

                    const parts = editText?.split?.(':') ?? [hours, minutes];
                    if (parts.length === 2) {

                        hours = getNumber(parts[0], 0);
                        minutes = getNumber(parts[1], 0);

                    }

                } catch (e) {

                    //

                }
                startDate.current.setHours(Number(hours));
                startDate.current.setMinutes(Number(minutes));

            } else if (editText) {

                startDate.current = withTimezone ? toDate(new Date()) : new Date();

            } else {

                startDate.current = withTimezone ? toDate(new Date()) : new Date();

            }

        }
        if (Platform.OS === 'ios') {

            setVisibleIOSSelector(true);

        } else {

            getDate({
                mode,
                date: startDate.current,
                onSelectDate: (dt) => {

                    setEditText(getFormat(mode, dt));
                    isFormatError(getFormat(mode, dt));
                    const err = isFormatError(getFormat(mode, dt));
                    if (err) {

                        onError?.(err);
                        popupError && showNotification(Object.R(`titles.${err}`));

                    } else {

                        onError?.(null);

                    }
                    onSelectDate?.(dt);

                },
                useSystemModal,
            });

        }

    };

    const toHtmlDateValue = (v) => {

        const segments = v?.split('.');
        if (segments?.length !== 3) {

            return v;

        }
        const day = Number(segments[0]);
        const month = Number(segments[1]);
        const year = Number(segments[2]);
        return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;

    };

    // eslint-disable-next-line max-statements
    const textToDate = (t) => {

        if (mode === 'time') {

            const segments = t?.split(':');
            if (segments?.length !== 2) {

                return null;

            }
            const hour = getNumber(segments[0]);
            const minute = getNumber(segments[1]);
            if (!isValidTime(hour, minute)) {

                return null;

            }
            const dt = new Date(secondPart);
            dt.setHours(hour);
            dt.setMinutes(minute);
            dt.setSeconds(59);
            return dt;

        }
        let day = -1;
        let month = -1;
        let year = -1;
        const segments = splitDate(t);
        if (!segments) {

            return null;

        }
        day = segments?.day;
        month = segments?.month;
        year = segments?.year;

        const hour = getNumber(new Date(secondPart).getHours());
        const minute = getNumber(new Date(secondPart).getMinutes());

        if (!isValidDate(day, month, year)) {

            return null;

        }
        return new Date(year, month - 1, day, hour, minute, 59);

    };

    const blurHandler = () => {

        const err = isFormatError(editText);
        const dt = textToDate(editText);
        dt && onSelectDate?.(dt);
        if (err) {

            if ((required && err === 'emptyDate') || err) {

                onError?.(err);

            }

        } else {

            onError?.(null);

        }
        onBlur?.(editText);

    };

    // eslint-disable-next-line max-statements
    const changeHandler = (t) => {

        setEditText(t);
        onTextChange?.(t);
        let len = 10;
        if (mode === 'time') {

            len = 5;

        } else if (mode === 'datetime') {

            len = 16;

        }
        if (t?.length < len) {

            onSelectDate?.(null);
            return;

        }
        try {

            if (mode === 'time') {

                const time = parseTimeString(t);
                if (time) {

                    onSelectDate?.(time);
                    onError?.(null);

                } else {

                    onSelectDate?.(null);
                    onError?.('timeError');

                }

            }
            let dt = new Date(t)?.getTime?.();
            if (Number.isNaN(dt)) {

                dt = new Date(toHtmlDateValue(t))?.getTime?.();

            }
            if (!Number.isNaN(dt)) {

                onSelectDate?.(adjustTimeZone(new Date(dt), 2));
                onError?.(null);

            } else {

                onSelectDate?.(null);
                onError?.(mode === 'time' ? 'timeError' : 'dateError');

            }

        } catch {
            //
        }

    };

    const onIosConfirm = (dt) => {

        setEditText(getFormat(mode, dt));
        isFormatError(getFormat(mode, dt));
        const err = isFormatError(getFormat(mode, dt));
        if (err) {

            onError?.(err);
            popupError && showNotification(Object.R(`titles.${err}`));

        } else {

            onError?.(null);

        }
        onSelectDate?.(dt);
        setVisibleIOSSelector(false);

    };

    return (
        <>
            {edit ? (
                <View style={{ position: 'relative' }}>
                    {Platform.OS === 'web' ? (
                        <>
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {mode === 'date' ? (
                                <View
                                    style={{
                                        borderWidth: 0,
                                        borderColor: 'red',
                                    }}>
                                    <DateInput
                                        ns="date"
                                        value={toHtmlDateValue(editText)}
                                        onChange={changeHandler}
                                        onBlur={blurHandler}
                                        error={isError}
                                        addMarginTop={-12}
                                        marginTop={2}
                                        padding={14}
                                        style={[
                                            {
                                                height: 50,
                                                minHeight: 50,
                                            },
                                            inputStyleWeb,
                                        ]}
                                    />
                                </View>
                            ) : mode === 'time' ? (
                                <View
                                    style={{
                                        borderWidth: 0,
                                        borderColor: 'red',
                                    }}>
                                    <DateInput
                                        ns="time"
                                        value={editText}
                                        onChange={changeHandler}
                                        type="time"
                                        onBlur={blurHandler}
                                        error={isError}
                                        addMarginTop={-12}
                                        padding={14}
                                        marginTop={2}
                                        style={[
                                            {
                                                height: 50,
                                                minHeight: 50,
                                            },
                                            inputStyleWeb,
                                        ]}
                                    />
                                </View>
                            ) : (
                                <TextInput.MaskedNumber
                                    style={[
                                        {
                                            flex: 1,
                                            marginLeft: 8,
                                        },
                                        inputStyle,
                                    ]}
                                    onChangeText={changeHandler}
                                    mask={masks[mode]?.web}
                                    placeholder={Object.R(masks[mode]?.placeholder)}
                                    placeholderTextColor={placeholderTextColor}
                                    value={editText}
                                    onBlur={blurHandler}
                                    error={isError}
                                    onFocus={onFocus}
                                />
                            )}
                        </>
                    ) : (
                        <TextInputMask
                            mask={masks[mode]?.format}
                            maxLength={masks[mode]?.maxLength}
                            onChangeText={changeHandler}
                            value={editText}
                            style={[
                                input.inputTextArea,
                                {
                                    marginTop: 0,
                                    marginBottom: 0,
                                    fontSize: 16,
                                    marginVertical: 0,
                                    paddingVertical: 12,
                                    paddingHorizontal: 12,
                                    // paddingTop: 14,
                                    borderColor: isError ? 'red' : COLORS.BORDER_GRAY,
                                    height: Platform.OS === 'ios' ? 50 : 48,
                                },
                                inputStyle,
                            ]}
                            onBlur={blurHandler}
                            keyboardType="numeric"
                            placeholder={Object.R(masks[mode]?.placeholder)}
                            placeholderTextColor={placeholderTextColor}
                            error={isError}
                            onFocus={onFocus}
                        />
                    )}
                    {Platform.OS !== 'web' && (
                        <TouchableOpacity
                            onPress={onPressHandler}
                            style={{
                                borderWidth: 0,
                                width: 35,
                                height: '100%',
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                marginRight: 12,
                                marginTop: 0,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            {mode === 'date' ? (
                                <Icon.Calendar size={25} color={COLORS.DARK_GRAY} />
                            ) : (
                                <Icon.Clock size={20} color={COLORS.DARK_GRAY} onPress={onPressHandler} />
                            )}
                        </TouchableOpacity>
                    )}
                </View>
            ) : (
                <SingleNavigation
                    title={title ? `${title} ${d()}` : d()}
                    showIndicator
                    onPress={onPressHandler}
                    mode={mode}
                />
            )}
            {Platform.OS === 'ios' && (
                <DatePicker
                    date={startDate.current}
                    mode={mode}
                    modal={true}
                    open={!!visibleIOSSelector}
                    confirmText={(mode === 'date' ? Object.R('titles.choose_the_date') : Object.R('titles.choose_the_time') ?? '').toUpperCase()}
                    cancelText={Object.R('buttons.close')}
                    title={' '}
                    theme={'light'}
                    is24hourSource={'locale'}
                    onConfirm={onIosConfirm}
                    onCancel={() => setVisibleIOSSelector(false)}
                />
            )}
        </>
    );

};
