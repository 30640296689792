import { Linking, Platform } from 'react-native';
// try to mock as soon as possible
import actions, { getNavigator, navigate } from '../actions';
import { urlParse } from '../utils';
import { MY_API_HOST } from '../config';
import { showHtmlModal } from '../common/Modal';
import tracking from './tracking';
import { storage } from './localstorage';
import firebase from './firebase';
import { get } from './http';

let withoutModal;

export const isInStandaloneMode = () => {

    return Platform.OS ==='web' ?  window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone : null;

};

export const changeDisplayOfWebInstallPopup = (value = 'none', timeoutSeconds) => {

    const changeDispaly = () => {

        if (Platform.OS === 'web') {

            if (!withoutModal) {

                withoutModal = true;
                const popup = document.getElementById('popup');
                if (popup) {

                    popup.style.cssText = `display: ${value};`;

                }

            }

        }

    };
    timeoutSeconds && typeof timeoutSeconds === 'number' ? setTimeout(changeDispaly, timeoutSeconds) : changeDispaly();

};

const getFullNumber = (pnFromStorage) => {

    if (!pnFromStorage) {

        return;

    }
    const cleanedNumber = pnFromStorage.replace(/\D/g, '');
    return `375${cleanedNumber}`;

};
const tryToLogin = async (phoneNumber) => {

    let userInfo = await storage.getObject('user');

    if ((!userInfo || !Object.keys(userInfo)?.length) && Platform.OS === 'web') {

        userInfo = window.sessionStorage.getItem('user');

    }
    let hasSamePhoneNumber;
    if (userInfo?.phones) {

        hasSamePhoneNumber = userInfo?.phones?.some((phone) => {

            return phone.number?.slice(1) === `${phoneNumber}`;

        });
        if (phoneNumber) {

            if (!hasSamePhoneNumber) {

                Object.afterRelogin = () => {

                    navigate('Account', { deeplinkPhoneNumber: phoneNumber });

                };

            }
            // } else {
            //     changeDisplayOfWebInstallPopup('block');
            // }

        }

    } else {

        Object.checkUserInfo = async (userInfoArr) => {

            if (userInfoArr?.length) {

                const hasSamePN = userInfoArr.some((el) => {

                    const res = el.phoneNumber?.slice(1) === `${phoneNumber}`;
                    return res;

                });

                if (!hasSamePN) {

                    await actions.logOut();
                    navigate('Account', { deeplinkPhoneNumber: phoneNumber });
                    // Object.checkUserInfo = null;

                }

            } else {

                navigate('Account', { deeplinkPhoneNumber: phoneNumber });

            }

        };

    }

    // if (phoneNumber) {
    //     if (!hasSamePhoneNumber) {
    //         if (userInfo?.phones) {
    //             Object.afterRelogin = () => {
    //                 navigate('Account', { deeplinkPhoneNumber: phoneNumber });
    //             };
    //         } else {
    //             navigate('Account', { deeplinkPhoneNumber: phoneNumber });
    //         }
    //     }
    //     // } else {
    //     //     changeDisplayOfWebInstallPopup('block');
    //     // }
    // }

};

const addProviderCodeFromDynamicLink = async (pCode, isStartLink) => {

    if (isStartLink) {

        tracking.logEvent('ai_open_by_link', { pCode });

    }
    const providerCodes = (await storage.get('providerCodes')) || [];
    const isFirstTime = !providerCodes || !providerCodes.length;
    const user = (await storage.get('user')) || {};
    const isUserWithFullInformation = Boolean(user.name && user.sex && user.birthday);

    if (!providerCodes.some(el => el === pCode)) {

        providerCodes.push(pCode);

    }
    await storage.set('providerCodes', providerCodes);

    if (isFirstTime && !isUserWithFullInformation) {

        Object.checkShowProfileInstructionsModal();

    }
    if (!isStartLink && isUserWithFullInformation) {

        actions.reloadUserInfo(() => {}, actions.updatePrefs, actions.updateSelects);

    }

};

const openDrugstoresSearch = (params) => {

    const {
        drugs, title, from, aptId, totalCount = 1,
    } = params;
    const nav = getNavigator();
    const current = nav?.getCurrentRoute?.();
    actions.addLog('deeplink', 'openDrug    storesSearch');
    const p = {
        drugs,
        title,
        from,
        aptId,
        totalCount,
    };
    if (current?.name === 'SearchDrugstores') {

        nav?.setParams?.(p);

    } else {

        navigate('SearchDrugstores', p);

    }

};

const openDrugDetails = (params) => {

    const {
        drug, title, from, aptId, totalCount = 1,
    } = params;
    actions.addLog('deeplink', 'openDrugDetails');
    navigate('DrugDetails', {
        drug,
        title,
        from,
        aptId,
        totalCount,
    });

};

const drugsDeepLinkHandler = (params) => {

    if (!params) {

        return;

    }
    const {
        drugs = {}, drug = {}, type, addParams,
    } = params;
    const drugsFromShare = (Object.keys(drug)?.length > 0 ? [drug] : drugs)?.map?.(item => ({
        ...item,
        apt_cnt: 5000,
        ls_name: item?.name ?? item?.ls_name,
        id: item.id ?? item.ls_num,
        name: item?.ls_name ?? item?.name,
        producer: item?.firm_name ?? item?.producer,
        tar_name: item?.tar,
        country_name: item?.country,
        selected: true,
        // pricingText: item?.price_max && item?.price_min ? `от ${item?.price_min} до ${item?.price_max} руб` : undefined,
    }));
    switch (type) {

        case 'firstAidKit':
            openDrugstoresSearch({
                drugs: drugsFromShare,
                title: Object.R('titles.shareFirstAidKit'),
                from: 'searchFirstAidKit',
                aptId: addParams?.aptId,
                totalCount: drugsFromShare?.length ?? 0,
            });
            break;
        case 'apt':
            openDrugstoresSearch({
                drugs: drugsFromShare,
                title: Object.R('titles.shareDrugstore'),
                from: 'apt',
                aptId: addParams?.aptId,
                totalCount: drugsFromShare?.length ?? 0,
            });
            break;
        case 'drug':
            openDrugDetails({
                drug: drugsFromShare[0],
                title: Object.R('titles.shareDrug'),
                from: 'drugDetails',
                aptId: addParams?.aptId,
                totalCount: 1,
            });
            break;
        default:
            break;

    }
    // actions.addLog('deeplink', drugsFromShare);
    // navigate('SearchDrugstores', {
    //     drugs: drugsFromShare,
    //     title: Object.R('titles.shareDrugsBanner'),
    //     from: 'share',
    // });

};

// const openDrugWithApt = (drugs, aptId) => {
//     actions.addLog('deeplink', 'openDrugWithApt');
//     // navigate('SearchDrugstores', {
//     //     drugs,
//     //     title: Object.R('titles.shareDrugstore'),
//     //     from: 'share',
//     //     aptId,
//     // });
// };
// eslint-disable-next-line max-statements,complexity
const checkForDynamicLink = (url, isStartLink = false) => {

    actions.addLog('deeplink', `Start with url: ${url}`);
    if (url) {

        const {
            params: {
                docId,
                docName,
                clinicId,
                pCode,
                link,
                drugs,
                type: drugsType,
                addParams,
                ac,
                pn,
                o,
                pc,
                bm,
                ed,
            } = {},
            target,
            type,
            params,
            path,
        } = urlParse(typeof url === 'string' ? url : url.url, {}, 'o');
        actions.addLog('deeplink', {
            docId,
            docName,
            clinicId,
            pCode,
            link,
            target,
            type,
            params,
            path,
            ac,
            pn,
        });
        if (type === 'https' && target === MY_API_HOST && !docId && !docName && !clinicId && !pCode && !ac && !pn) {

            actions.addLog('deeplink', 'CONFIRMATION EMAIL');
            get.Anonymously(url).then((r) => {

                try {

                    const html = r && r.data; // .match(/<body[^>]*>([^<]*(?:(?!<\/?body)<[^<]*)*)<\/body\s*>/i)[1];
                    if (html) {

                        showHtmlModal({
                            html: r.data,
                            title: Object.R('titles.confirmationEmail'),
                        });

                    }

                } catch (e) {

                    actions.addLog('deeplink', 'CONFIRMATION EMAIL');
                    // eslint-disable-next-line no-console
                    console.log('Error parse html', {
                        e,
                        r,
                    });

                }

            });

        }
        if (path === 'drugs' || (Array.isArray(path) && path[0] === 'drugs')) {

            drugsDeepLinkHandler(params);

        }
        if (docId) {

            if (isStartLink) {

                actions.addLog('deeplink', 'DoctorID - startLink');
                tracking.logEvent('ai_open_by_link', { docId });

            } else {

                actions.addLog('deeplink', 'DoctorID');

            }
            navigate('DoctorDetails', { doctorInfo: { _id: docId } });

        } else if (docName) {

            if (isStartLink) {

                actions.addLog('deeplink', 'DoctorName - startLink');
                tracking.logEvent('ai_open_by_link', { docName });

            } else {

                actions.addLog('deeplink', 'DoctorID');

            }
            navigate('DoctorsSpecialtiesSearch', { keyword: docName.split('+').join(' ') });
            changeDisplayOfWebInstallPopup('block', 30000);

        } else if (clinicId) {

            navigate('MedCenterDetails', { mdInfo: { _id: clinicId } });
            if (pCode) {

                actions.addLog('deeplink', 'ClinicID - pCode');
                setTimeout(() => {

                    addProviderCodeFromDynamicLink(pCode, isStartLink);

                }, 500);

            } else {

                actions.addLog('deeplink', 'ClinicID');

            }

        } else if (pCode) {

            actions.addLog('deeplink', 'pCode');
            addProviderCodeFromDynamicLink(pCode, isStartLink);

        } else if (link) {

            actions.addLog('deeplink', `Check link ${link}`);
            checkForDynamicLink(link);

        } else if ((ac && ac === 'login' && pn) || o) {

            if (ac && ac === 'login' && pn) {

                actions.addLog('deeplink', `Action - login`);
                tryToLogin(pn);

            }
            if (o) {

                Object.orderId = String(o);
                if (pc) {

                    Object.orderProviderCode = pc;

                }

            }
            if (ed !== null || ed !== undefined) {

                Object.ed = ed;

            }
            if (bm) {

                Object.bookMark = true;

            }

        }

    }

};

export const initDeepLinks = async (cb) => {

    const dynamicLinksUnsubscription = firebase.addDeepLinkReceiveListener(checkForDynamicLink);

    Linking.addEventListener('url', async (event) => {

        cb(event);
        checkForDynamicLink(event.url);

    });

    const startLink = (await firebase.getStartLink()) || (await Linking.getInitialURL());
    setTimeout(() => checkForDynamicLink(startLink, true), 1);

    return dynamicLinksUnsubscription;

};
