import { enableScreens } from 'react-native-screens';
import { MODE } from './config';

import './styles/variables';

const initLogging = () => {
    // logging
    // console.disableYellowBox = true;
    const log = console.log.bind(console);
    console.error = MODE === 'dev' ? (message, ...args) => log(`E/: ${message}`, ...args) : () => true;
    console.log = MODE !== '1dev' ? (message, ...args) => log(`D/: ${message}`, ...args) : () => true;
};

initLogging();

// Before rendering any navigation stack
enableScreens(false);

// polyfill for RegExp.escape
if (!RegExp.escape) {
    RegExp.escape = s => String(s).replace(/[\\^$*+?.()|[\]{}]/g, '\\$&');
}
