import RNRestart from 'react-native-restart';
import {
    dataFrom, NavigationItem, Platform, React, selectOptions,
} from '../common';
import actions from '../actions';
import { getActualLanguagesData } from '../selectors';

/**
 * LanguageSelector combo.
 */
export const LanguageSelector = dataFrom(
    getActualLanguagesData,
    ({
        data: languagesData,
        select = () => selectOptions({
            title: 'titles.select_language',
            subtitle: 'titles.reload_app_warning',
            subtitleStyle: { textAlign: 'center' },
            // eslint-disable-next-line no-undef
            onSelect: async ({ id }) => {
                await actions.setLocale(id);
                // eslint-disable-next-line no-undef
                Platform.OS === 'web' ? window.location.reload() : RNRestart.Restart();
            },
            selected: languagesData?.selected,
            data: languagesData?.languages,
        }),
    }) => (
        <NavigationItem
            firstItem
            onItem={select}
            title="titles.selectLanguage"
            containerStyle={{ borderBottomWidth: 0 }}
        />
    ),
);
