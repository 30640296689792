import { Linking, Platform, TouchableWithoutFeedback } from 'react-native';
// import DeviceSettings from 'react-native-device-settings';
import { getErrorMessage } from '../i18n';
import { getSupportBody } from '../utils/supportInfo';
import FamilyImage from '../assets/images/family.svg';
import { openURL } from '../services/device';
import { COLORS } from '../styles/colors';
import {
    KeyboardAwareScrollView, React, Text, View,
} from './react';
import {
    RText, Section, Subtitle, Title, VBox,
} from './elements';
import { Button, ButtonsGroup } from './Button';
import { Img } from './Img';
import { Icon } from './Icon';

export const Stub = ({
    type, title, subTitle, img, children, style: st,
}) => (
    <View style={st}>
        {type === 'empty-default' || type === 'empty-drugstores' || type === 'empty-visit-drugs' ? (
            <Section>
                <RText id={`stubs.${type}`} />
            </Section>
        ) : (
            <VBox centered gap={2} ns={type}>
                {img}
                <VBox centered gap={2}>
                    <VBox centered gap={1}>
                        <Title bold="true" centered style={{ height: 30 }}>
                            {title || Object.R(`stubs.${type}.title`)}
                        </Title>
                        <Subtitle centered smaller numberOfLines={4}>
                            {subTitle || Object.R(`stubs.${type}.subTitle`)}
                        </Subtitle>
                    </VBox>
                    {children}
                </VBox>
            </VBox>
        )}
    </View>
);

export const ErrorStub = ({
    error,
    children,
    support,
    style,
    getSubtitle = () => {
        const codeMessage = getErrorMessage(error.code);
        if (codeMessage && codeMessage !== 'undefined') {
            return codeMessage;
        }
        return error.message ? error.message : typeof error === 'string' && error;
    },
    noSettings = false,
}) => (
    <KeyboardAwareScrollView onStartShouldSetResponder={() => true}>
        <VBox centered gap={2} ns="error" style={[{ paddingBottom: 24 }, style]}>
            <Img.Error />
            <VBox centered gap={1}>
                <Title bold centered>
                    {Object.R(`${error.key ? `error.${error.key}` : 'titles.something_went_wrong'}`)}
                </Title>
                <Subtitle centered numberOfLines={null}>
                    {getSubtitle()}
                </Subtitle>
                {!support ? null : (
                    <ButtonsGroup>
                        {noSettings ? null : (
                            <Button normal capitalize title="buttons.settings" action={() => Linking.openSettings()} />
                        )}
                        <Button
                            primary
                            capitalize
                            title="titles.support"
                            action={() => openURL(`mailto:${Object.R('supportEmail')}${getSupportBody()}`)}
                        />
                    </ButtonsGroup>
                )}
                {children}
            </VBox>
        </VBox>
    </KeyboardAwareScrollView>
);

Stub.Simple = ({ type }) => (
    <Section style={{ paddingHorizontal: 0 }}>
        <RText numberOfLines={null} id={`stubs.${type}`} />
    </Section>
);
Stub.Basket = () => <Stub type="empty-basket" img={<Img.EmptyBasket />} />;
Stub.DrugList = () => <Stub type="empty-druglist" img={<Img.EmptyBasket />} />;

export const RelativeHelpText = () => {
    return (
        <View
            style={{
                flex: 1,
                margin: 16,
                marginLeft: 12,
                // marginHorizontal: 16,
                // marginHorizontal: 50,
                // marginRight: 32,
            }}>
            <Text style={{ textAlign: 'left' }}>
                <Subtitle style={{ color: '#9C9C9C' }} numberOfLines={null}>
                    {Object.R('titles.fillFamilyHelpText')}
                </Subtitle>
                <TouchableWithoutFeedback
                    onPress={() => {
                        openURL(`mailto:${Object.R('supportEmail')}${getSupportBody()}`);
                    }}>
                    <View>
                        <Subtitle
                            style={{
                                color: COLORS.MAIN,
                                cursor: Platform.OS === 'web' ? 'pointer' : 'auto',
                                padding: 0,
                                margin: 0,
                                top: 3,
                                lineHeight: 13,
                            }}>
                            {' '}
                            {Object.R('titles.fillFamilyHelpLinkText')}
                        </Subtitle>
                    </View>
                </TouchableWithoutFeedback>
            </Text>
        </View>
    );
};

Stub.Family = () => (
    <View
        style={{
            flex: 1,
            alignItems: 'center',
        }}>
        {Platform.OS === 'web' ? (
            <img alt="family" src={FamilyImage} width={240} height={140} style={{ marginTop: 32 }} />
        ) : (
            <Img.Family style={{ marginTop: 32 }} />
        )}
        <Title
            id="titles.noFamily"
            style={{
                textAlign: 'center',
                marginTop: 32,
            }}
            bold
        />
        <Text
            numberOfLines={0}
            style={[
                {
                    paddingHorizontal: 12,
                    textAlign: 'center',
                    lineHeight: 16,
                },
                Platform.OS === 'web' ? { wrap: 'nowrap' } : {},
            ]}>
            <Subtitle
                style={{
                    textAlign: 'center',
                    marginTop: 8,
                    lineHeight: 16,
                }}
                numberOfLines={0}>
                {Object.R('titles.fillFamilyHelpText')}{' '}
            </Subtitle>
            <TouchableWithoutFeedback
                onPress={() => {
                    openURL(`mailto:${Object.R('supportEmail')}${getSupportBody()}`);
                }}>
                <View>
                    <Subtitle
                        style={[
                            { color: COLORS.MAIN },
                            Platform.OS === 'web'
                                ? {
                                    cursor: 'pointer',
                                    display: 'inline',
                                    marginHorizontal: 0,
                                    paddingHorizontal: 0,
                                }
                                : {
                                    lineHeight: 16,
                                    alignItems: 'flex-end',
                                    bottom: -3,
                                    left: Platform.OS === 'ios' ? 10 : 3,
                                    // top: 4,
                                },
                        ]}>
                        {Object.R('titles.fillFamilyHelpLinkText')}
                    </Subtitle>
                </View>
            </TouchableWithoutFeedback>
        </Text>
    </View>
);

Stub.NotAuthorized = () => (
    <Section>
        <Stub type="not-authorized" img={<Img.NotAuthorized />}>
            {/* <View style={styles.bigButtonContainer}>
                    <BigButton action={() => navigate('SignIn')} title="buttons.sign_in" />
                </View> */}
        </Stub>
    </Section>
);

Stub.Feedbacks = () => <Stub type="empty-feedbacks" img={<Icon.EmptyFeedback />} />;
Stub.FeedbacksTab = () => <Stub type="empty-feedbacksTab" img={<Icon.EmptyFeedback />} />;

Stub.FavoriteDoctors = () => (
    <View
        style={{
            flex: 1,
            alignItems: 'center',
        }}>
        <Img.EmptyFavorites style={{ marginTop: 32 }} />
        <Title
            id="titles.noFavoriteDoctors"
            style={{
                textAlign: 'center',
                marginTop: 32,
            }}
            bold
        />
        <Subtitle
            id="titles.bookmarkDoctorsPrompt"
            style={{
                textAlign: 'center',
                marginTop: 8,
                paddingHorizontal: 16,
                color: '#9C9C9C',
            }}
        />
    </View>
);

Stub.DoctorsNotFound = () => (
    <View
        style={{
            flex: 1,
            alignItems: 'center',
        }}>
        <Img.EmptyFavorites style={{ marginTop: 32 }} />
        <Title
            id="titles.doctorsNotFoundStubTitle"
            style={{
                textAlign: 'center',
                marginTop: 32,
            }}
            bold
        />
        <Subtitle
            id="titles.doctorsNotFoundStub"
            style={{
                textAlign: 'center',
                marginTop: 8,
                paddingHorizontal: 16,
                color: '#9C9C9C',
            }}
        />
    </View>
);

Stub.FavoriteMedcenters = () => (
    <View
        style={{
            flex: 1,
            alignItems: 'center',
        }}>
        <Img.EmptyFavoriteMedcenters style={{ marginTop: 32 }} />
        <Title
            id="titles.noFavoriteMedcenters"
            style={{
                textAlign: 'center',
                marginTop: 32,
            }}
            bold
        />
        <Subtitle
            id="titles.bookmarkMedcentersPrompt"
            style={{
                textAlign: 'center',
                marginTop: 8,
                paddingHorizontal: 16,
                color: '#9C9C9C',
            }}
        />
    </View>
);

Stub.OutdatedAppVersion = ({ children }) => (
    <Stub
        title={Object.R('error.outdatedAppVersion')}
        img={<Img.Device />}
        subTitle={Object.R(`hint.outdatedAppVersion`)}>
        {children}
    </Stub>
);
