import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { React } from '../../../common';
import { parseToDdMmYyyy } from '../../../utils';
import { getManualVisitors } from '../../../selectors/forms';
import { NewVisitorInlineForm, selectOptionsWithForm } from '../snippets/VisitorSelector';
import { getProfileHint } from './visitorsListOperations';
import { ComboboxSelector } from './ComboboxSelector';

const getNameFunction = (item) => {

    if (item?.isMe) {

        return Object.R('titles.for_me');

    }
    const bd = item?.birthday ? ` (${parseToDdMmYyyy(item.birthday)})` : '';
    const name = item?.manual
        ? `${item?.firstName} ${item?.lastName}`
        : item?.firstLastName || item?.name || item?.fullNameRu;
    return `${name}${bd}`;

};

export const ProfileSelector = React.memo(
    ({
        selected,
        onChange,
        enabled = true,
        data = [],
        hasManual = true,
        sectionStyle = { marginTop: 14 },
        isError,
        orderId,
    }) => {

        const [selectedProfile, setSelectedProfile] = useState(selected);
        const manualVisitors = useSelector(getManualVisitors)?.filter(
            ({ fullNameRu: name, birthday: bd }) => !data?.find(({ fullNameRu, birthday }) => fullNameRu === name && birthday === bd),
        );
        !orderId &&
            manualVisitors.push({
                id: 'newVisitor',
                name: Object.R('titles.newVisitor'),
                FormComponent: NewVisitorInlineForm,
            });
        const visitors = [...data, ...(hasManual ? manualVisitors : [])];
        if (!visitors?.find?.(({ isMe }) => isMe) && !orderId) {

            // console.log('visitors', visitors);
            visitors.splice(0, 0, {
                id: 'empty',
                isMe: true,
                name: Object.R('titles.for_me'),
            });

        }

        useEffect(() => {

            setSelectedProfile(selected);

        }, [selected]);

        const onSelectVisitor = (item, addFields, newVisitor) => {

            if (item?.id === 'newVisitor') {

                onChange(newVisitor);

            } else {

                onChange(item);

            }

        };

        const onPressHandler = useCallback(() => {

            selectOptionsWithForm({
                title: 'titles.select_visitor',
                autoHeight: true,
                data: visitors,
                selected: selectedProfile?.manual ? `${selectedProfile?.fullNameRu ?? ''}` : selectedProfile?.id,
                onSelect: onSelectVisitor,
                getName: getNameFunction,
            });

        }, [selectedProfile, visitors, getNameFunction, onSelectVisitor]);

        return (
            <ComboboxSelector
                title={'titles.whose_visit'}
                value={getProfileHint(selectedProfile)}
                enabled={enabled}
                onPressHandler={onPressHandler}
                sectionStyle={sectionStyle}
                isError={isError}
            />
        );

    },
);
