import { formatTime, isFutureVisit, parseISO8601String } from '../utils';
import { notifications } from '../common/Notifications';
import { isDateFuture } from '../utils/dates';
import { CREATED_MANUALLY } from '../actions/visits';
import { storage } from './localstorage';

export const rescheduleVisitNotifications = async (visits) => {
    // notifications.cancelAll();
    notifications.cancelAllNotificationsForVisits();
    const notificationsSettings = (await storage.get('selections')).notificationsSettings || {};
    // eslint-disable-next-line no-use-before-define
    visits.forEach(visit => scheduleVisitNotification(visit, notificationsSettings));
};

const scheduleVisitNotification = async (visit, notificationsSettings) => {
    if (!visit.startDate) {
        return;
    }
    const withoutTime = visit?.withoutTime;
    const visitDate = parseISO8601String(visit.startDate);
    if (visitDate?.getHours() === 0 && visitDate?.getMinutes() === 0) {
        visitDate.setSeconds(1);
    }
    // Midnight visits are duplicates from Lode - we don`t need to notify for them
    // TODO: possible remove before release
    notifications.cancelNotification(parseInt(visit?.id ?? '0'));
    await notifications.cancelNotificationsByVisitId(visit?.id);
    if (
        (visitDate.getHours() !== 0 || visitDate.getMinutes() !== 0 || visit?.withoutTime) &&
        ((isFutureVisit(visitDate) && visit.status === 'reserved') ||
            (visit.status === CREATED_MANUALLY && isDateFuture(visitDate, false))) &&
        !notificationsSettings.doNotNotify &&
        notificationsSettings.selectedRemainderTimes
    ) {
        notificationsSettings.selectedRemainderTimes.forEach((time) => {
            let idIntPostFix = 0;
            if (withoutTime && !(time === '1dayMorning' || time === '1dayEvening')) {
                return;
            }
            const notificationDate = new Date(visitDate.getTime());
            let dateTitle = Object.R('titles.today');
            // eslint-disable-next-line default-case
            switch (time) {
                case '30minutes':
                    idIntPostFix = 1;
                    notificationDate.setMinutes(notificationDate.getMinutes() - 30);
                    break;
                case '1hour':
                    idIntPostFix = 2;
                    notificationDate.setHours(notificationDate.getHours() - 1);
                    break;
                case '2hours':
                    idIntPostFix = 3;
                    notificationDate.setHours(notificationDate.getHours() - 2);
                    break;
                case '4hours':
                    idIntPostFix = 4;
                    notificationDate.setHours(notificationDate.getHours() - 4);
                    break;
                case '1dayMorning':
                    idIntPostFix = 5;
                    notificationDate.setDate(notificationDate.getDate() - 1);
                    notificationDate.setHours(10, 0, 0);
                    dateTitle = Object.R('titles.tomorrow');
                    break;
                case '1dayEvening':
                    idIntPostFix = 6;
                    notificationDate.setDate(notificationDate.getDate() - 1);
                    notificationDate.setHours(20, 0, 0);
                    dateTitle = Object.R('titles.tomorrow');
                    break;
            }
            if (isFutureVisit(notificationDate) && (visit.status === 'reserved' || visit.status === CREATED_MANUALLY)) {
                const n = {
                    // id,
                    title: Object.R('titles.visit_to_doctor'),
                    message: withoutTime
                        ? Object.R('titles.haveUpcomingVisitWithoutTime', { date: dateTitle })
                        : Object.R('titles.haveUpcomingVisit', {
                            date: dateTitle,
                            time: formatTime(visitDate),
                        }),
                    date: notificationDate,
                    userInfo: {
                        id: visit.id,
                        shift: idIntPostFix,
                    },
                };
                // console.log('Schedule visit', n);
                notifications.schedule(n);
            }
        });
    }
};
