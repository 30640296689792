/* eslint-disable complexity,no-underscore-dangle */
/* eslint-disable max-statements */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import React from '../../../../common/react';
import {
    Button, ButtonsGroup, Modal2, Page, TopNotification, TouchableWithoutFeedback, View,
} from '../../../../common';
import {
    capitalize, formatDate, formatTime, getTimeZoneDiffMinutes, isAdult, isEmpty, MinskTimeZoneOffsetMinutes,
} from '../../../../utils';
import user from '../../../../services/user';
import api from '../../../../services/api';
import { getClinicCityIfDifferentFromCurrent, getUserConsentReceived, getUserMainInfo } from '../../../../selectors';
import { changeDisplayOfWebInstallPopup } from '../../../../services/deeplinks';
import { formatDateTz, parseDateFromDotsToISO } from '../../../../utils/dates';
import { resetDoctorsFilters } from '../../../Doctors/utils';
import actions from '../../../../actions';
import { resetMedCentersFilter } from '../../../../combo';
import firebase from '../../../../services/firebase';
import { adaptDoctorToFav } from '../../../App/snippets/utils';
import { IS_FOR_CHILD, IS_FOR_ME, IS_OTHER } from './consts';
import {
    AgreemantCheckbox, EmailInput, PatientSelector, SwitchWithLabel, SwitchWithNotification, VisitInfo,
} from './snippets';

const adaptVisitData = (
    {
        startDate, physicianName, branchName, timezone = MinskTimeZoneOffsetMinutes,
    },
    { fullNameRu, birthday },
) => {
    const date = formatDateTz(startDate, timezone);
    const time = formatTime(startDate, timezone, !!getTimeZoneDiffMinutes(timezone));
    const bd = formatDate(birthday);
    return {
        date: `${date} в ${time}`,
        doctor: physicianName,
        name: fullNameRu,
        birthday: bd,
        mc: branchName,
    };
};

const getEmail = (emails = []) => {
    const e = emails.find(el => el.usage === 'PRIMARY');
    return e?.address;
};

const getChildProfileId = (family, patient) => {
    return family?.find(
        el => el.profile.birthday === patient.birthday && el.profile.fullNameRu === patient.fullNameRu,
    )?.profileId;
};

const adaptToPatch = (profileData, emails, isSave) => {
    return isSave
        ? {
            fullNameRu: profileData.fullNameRu,
            sex: profileData.sex,
            birthday: profileData.birthday,
            emails, // profileData.emails?.find(el=>el?.address ===email) ? profileData.emails: [...profileData.emails, {address:email }]
            consentReceived: true,
        }
        : { emails, consentReceived: true };
};

const getDataToUpdateUser = (userEmail, email, emails, patientData, userConsent, saveData, isForMe) => {
    const dataToUpdate = {};
    if (userEmail !== email) {
        dataToUpdate.emails = emails;
    }
    if (!userConsent) {
        dataToUpdate.consentReceived = true;
    }
    if (saveData && isForMe) {
        dataToUpdate.fullNameRu = patientData.fullNameRu;
        dataToUpdate.birthday = patientData.birthday;
        dataToUpdate.sex = patientData.sex;
    }
    return dataToUpdate;
};

const getPatientId = (forMe, forOther, forChild, targetProfileId, updatedUserProfileId, childId, isSaveProfileData) => {
    if (forMe && isSaveProfileData) {
        return updatedUserProfileId;
    }
    if (targetProfileId) {
        return targetProfileId;
    }
    if (forChild && isSaveProfileData) {
        return childId;
    }
    return null;
};
// OBW visit
// eslint-disable-next-line max-statements
// eslint-disable-next-line complexity
export const NewVisitModal = ({ data }) => {
    Object.alreadyImported = true;
    const {
        order, patientData, omId, targetProfile,
    } = data;
    const adaptedVisitData = adaptVisitData(order, patientData);
    const {
        date, doctor, name, birthday, mc,
    } = adaptedVisitData;
    const originalEmail = getEmail(patientData.emails);
    const {
        userEmail, userBirthday, userFullNameRu, userProfileId, userHasProgram,
    } = useSelector(getUserMainInfo);

    const isUserConsentReceived = useSelector(getUserConsentReceived);
    const locationCode = useSelector(state => getClinicCityIfDifferentFromCurrent(state, order?.branchId));

    const [email, setEmail] = useState(userEmail || originalEmail || '');
    const [emailError, setEmailError] = useState(null);
    const [selectedPatient, setSelectedPatient] = useState({});
    const [isSavePatientData, setIsSavePatientData] = useState(true);
    const [isUserAgreementChecked, setIsUserAgreementChecked] = useState(false);
    const [isAdditionalAgreementChecked, setIsAdditionalAgreementChecked] = useState(false);
    const [isChildAgreementChecked, setIsChildAgreementChecked] = useState(false);
    const [doctorData, setDoctorData] = useState({});

    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isChangeCity, setIsChangeCity] = useState(true);

    const isAdultPatient = isAdult(parseDateFromDotsToISO(birthday));
    const [isToBookmark, setIsToBookmark] = useState(true);

    const isForChild = selectedPatient.id === IS_FOR_CHILD;
    const isForMe = selectedPatient.id === IS_FOR_ME;
    const isForOther = selectedPatient.id === IS_OTHER;
    const hasProfile = !!targetProfile;
    const isTargetProfileConsentReceived = targetProfile && targetProfile?.consentReceived;
    // const isForMeAndHasConsent = !targetProfile && isUserConsentReceived && isForMe;
    // const hidePoliceCheckbox =
    //     isForOther || isTargetProfileConsentReceived || isForMeAndHasConsent || (!targetProfile && !selectedPatient.id);
    // const hideAdditionalAgreementCheckbox =
    //      isUserConsentReceived || (!targetProfile && !selectedPatient.id);
    const hideChildAgreementCheckbox =
        isForOther ||
        isForMe ||
        isAdultPatient ||
        (isTargetProfileConsentReceived && !isAdultPatient) ||
        (!targetProfile && !selectedPatient.id);

    const isPatientSelected = isForChild || selectedPatient.id === IS_FOR_ME;
    const isDisable =
        (!selectedPatient.id && !hasProfile) ||
        emailError ||
        !email?.trim()?.length ||
        (!isUserAgreementChecked && !isUserConsentReceived) ||
        (!isAdditionalAgreementChecked && !isUserConsentReceived) ||
        (!isChildAgreementChecked && !hideChildAgreementCheckbox) ||
        isLoading;

    useEffect(() => {
        if ((isForMe && userBirthday && userFullNameRu && isSavePatientData) || (isForMe && userHasProgram)) {
            setIsSavePatientData(false);
        }
    }, [isForMe]);

    useEffect(() => {
        if (!email && userEmail) {
            setEmail(userEmail);
        }
    }, [userEmail]);

    useEffect(() => {
        Object.orderId = null;
        Object.orderProviderCode = null;
        Object.onNewVisitFound = null;
        Object.onAlreadyImported = null;
    }, []);

    const onClose = () => {
        Page.closeModal();
    };
    const onSubmit = () => {
        setIsLoading(true);
        (async () => {
            let childProfileId;
            if (isForChild && isSavePatientData) {
                try {
                    const childInfo = await api.addChild({
                        fullNameRu: patientData.fullNameRu,
                        sex: patientData.sex,
                        birthday: patientData.birthday,
                        consentReceived: true,
                    });
                    childProfileId = getChildProfileId(childInfo?.family, patientData);
                } catch (e) {
                    setError(e?.body?.detail || e.code);
                    setIsLoading(false);
                    return;
                }
            }
            // let userProfileId; // = userProfile.birthday && userProfile.fullNameRu ? userProfile._id : null;
            const userMails = await user.getUserEmailsUpdated(email, 'PRIMARY');
            // const dataToSave = adaptToPatch(
            //     patientData,
            //     userMails.map(({ address, usage }) => ({
            //         address,
            //         usage,
            //     })),
            //     isSavePatientData && isForMe,
            // );
            const emails = userMails.map(({ address, usage }) => ({
                address,
                usage,
            }));
            const dataToSave = getDataToUpdateUser(
                userEmail,
                email,
                emails,
                patientData,
                isUserConsentReceived,
                isSavePatientData,
                isForMe,
            );
            const actualUserId = (userBirthday && userFullNameRu) || isSavePatientData ? userProfileId : null;

            const profileId = getPatientId(
                isForMe,
                isForOther,
                isForChild,
                targetProfile?._id,
                actualUserId,
                childProfileId,
                isSavePatientData,
            );
            await user.importPubVisit(profileId, omId, isSavePatientData && isForMe);

            if (!isEmpty(dataToSave)) {
                const userProfile = await api.updateUserInfo(dataToSave);
                // userProfileId = userProfile.birthday && userProfile.fullNameRu ? userProfile._id : null;
            }

            if (!isTargetProfileConsentReceived && !isAdultPatient && targetProfile?._id) {
                await api.updateChild({ consentReceived: true, _id: targetProfile?._id });
            }

            // const profileId = getPatientId(isForMe, isForOther, isForChild, targetProfile?._id, userProfileId, childProfileId, isSavePatientData);
            // if (hasProfile && !isAdultPatient && targetProfile?._id) {
            //     profileId = targetProfile?._id;
            // } else if (!hasProfile && isForChild && childProfileId) {
            //     profileId = childProfileId;
            // } else if (isForMe) {
            //     profileId = isSavePatientData ? userProfileId : null;
            // } else {
            //     profileId = userProfileId;
            // }
            // const profileId = isForChild ? childProfileId : userProfileId;
            // await user.importPubVisit(profileId, omId);
            if (isChangeCity && locationCode) {
                resetDoctorsFilters();
                actions.setLocation(locationCode);
                resetMedCentersFilter();
            }
            try {
                if (Object.bookMark && isToBookmark && doctorData && Object.keys(doctorData)?.length) {
                    const full = adaptDoctorToFav(doctorData);
                    actions.addFullFavorites(full);
                }
            } catch (e) {
                //
            }
            onClose();
            setIsLoading(false);
            changeDisplayOfWebInstallPopup('block', 5000);
        })();
    };

    useEffect(() => {
        if (!data?.order?.physicianId) {
            return;
        }
        firebase
            .getById('physicians', data?.order?.physicianId)
            .then(d => setDoctorData(d))
            .catch(() => setDoctorData(null));
    }, [data?.order?.physicianId]);

    return (
        <Modal2 title={'titles.newVisitFound'} hideClose>
            <TouchableWithoutFeedback>
                <View style={{ paddingBottom: 12 }}>
                    <VisitInfo
                        title={Object.R(`titles.visitInfo`, {
                            date,
                            doctor,
                            name,
                            birthday,
                            mc,
                        })}
                    />
                    <View style={{ paddingHorizontal: 12 }}>
                        <EmailInput onChange={setEmail} value={email} onError={setEmailError} error={emailError} />
                        {!hasProfile ? (
                            <>
                                <PatientSelector
                                    onSelect={setSelectedPatient}
                                    selectedItem={selectedPatient}
                                    filtredFields={isAdultPatient ? [IS_FOR_CHILD] : [IS_FOR_ME]}
                                />
                                {isPatientSelected && !(isForMe && userHasProgram) ? (
                                    <SwitchWithNotification
                                        hint={'hint.savePatientDataOfVisit'}
                                        caption={Object.R('titles.savePatientDataOfVisit')}
                                        value={isSavePatientData}
                                        onValueChange={setIsSavePatientData}
                                    />
                                ) : null}
                            </>
                        ) : null}
                        {locationCode ? (
                            <SwitchWithLabel
                                caption={Object.R('titles.myCityLabel', { city: capitalize(Object.R(`locations.${locationCode}`)) })}
                                value={isChangeCity}
                                onValueChange={setIsChangeCity}
                                containerStyle={{ marginBottom: 12 }}
                                style={isPatientSelected ? {} : { marginTop: 0 }}
                            />
                        ) : null}
                        {!!Object.bookMark && !!doctorData && (
                            <SwitchWithLabel
                                caption={Object.R('titles.addDoctorToBookmarks')}
                                value={isToBookmark}
                                onValueChange={setIsToBookmark}
                                containerStyle={{ marginBottom: 12 }}
                                style={{ marginTop: 8 }}
                            />
                        )}
                        {isUserConsentReceived ? null : (
                            <AgreemantCheckbox
                                title={Object.R('titles.userAgreement')}
                                selected={isUserAgreementChecked}
                                onPress={setIsUserAgreementChecked}
                            />
                        )}
                        {isUserConsentReceived ? null : (
                            <AgreemantCheckbox
                                title={Object.R('titles.personalDataAgreement')}
                                selected={isAdditionalAgreementChecked}
                                height={50}
                                checkboxStyle={{ marginTop: 20 }}
                                onPress={setIsAdditionalAgreementChecked}
                            />
                        )}
                        {hideChildAgreementCheckbox ? null : (
                            <AgreemantCheckbox
                                title={Object.R('titles.childDataAgreement')}
                                selected={isChildAgreementChecked}
                                height={50}
                                checkboxStyle={{ marginTop: 20 }}
                                onPress={setIsChildAgreementChecked}
                            />
                        )}
                    </View>
                    {!!error && (
                        <TopNotification
                            hint={{
                                level: 'error',
                                message: error,
                            }}
                            containerStyle={{ borderTopWidth: 1, marginTop: 6 }}
                        />
                    )}
                    <ButtonsGroup>
                        <Button normal title="buttons.close" action={onClose} capitalize />
                        <Button
                            primary
                            title="buttons.continue"
                            disabled={isDisable}
                            action={onSubmit}
                            capitalize
                            busy={isLoading}
                        />
                    </ButtonsGroup>
                </View>
            </TouchableWithoutFeedback>
        </Modal2>
    );
};
