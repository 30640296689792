export default {
    '5b3f5fd716a7c57fe': {
        _id: '5b3f5fd716a7c57fe',
        _lastModifiedAt: 1537884025063,
        _lastModifiedBy: null,
        nearest_available_dates: [{
            clinic_id: '5b3f5fa4529813245',
            nearest_available_date: null,
        }],
        _coll: 'physicians_timetable_sum',
    },
    '5b3f5fde9849da7bf': {
        _id: '5b3f5fde9849da7bf',
        _lastModifiedAt: 1537884025279,
        _lastModifiedBy: null,
        nearest_available_dates: [{
            clinic_id: '5b3f5fa4529813245',
            nearest_available_date: null,
        }],
        _coll: 'physicians_timetable_sum',
    },
    '5b3f5fe0426103ddd': {
        _id: '5b3f5fe0426103ddd',
        _lastModifiedAt: 1537884025523,
        _lastModifiedBy: null,
        nearest_available_dates: [{
            clinic_id: '5b3f5faa72b310212',
            nearest_available_date: null,
        }],
        _coll: 'physicians_timetable_sum',
    },
    '5b3f5ff389bb91501': {
        _id: '5b3f5ff389bb91501',
        _lastModifiedAt: 1537884026203,
        _lastModifiedBy: null,
        nearest_available_dates: [{
            clinic_id: '5b3f5fa7eaf998e58',
            nearest_available_date: null,
        }, {
            clinic_id: '5b3f5fa4529813245',
            nearest_available_date: null,
        }],
        _coll: 'physicians_timetable_sum',
    },
    '5b3f5ffd80995e17b': {
        _id: '5b3f5ffd80995e17b',
        _lastModifiedAt: 1537884026847,
        _lastModifiedBy: null,
        nearest_available_dates: [{
            clinic_id: '5b3f5fa4529813245',
            nearest_available_date: null,
        }],
        _coll: 'physicians_timetable_sum',
    },
    '5b3f600e1c88c6d2f': {
        _id: '5b3f600e1c88c6d2f',
        _lastModifiedAt: 1537884027290,
        _lastModifiedBy: null,
        nearest_available_dates: [{
            clinic_id: '5b3f5faa72b310212',
            nearest_available_date: null,
        }],
        _coll: 'physicians_timetable_sum',
    },
    '5b3f6010d6ca2b335': {
        _id: '5b3f6010d6ca2b335',
        _lastModifiedAt: 1537884027518,
        _lastModifiedBy: null,
        nearest_available_dates: [{
            clinic_id: '5b3f5fa4529813245',
            nearest_available_date: null,
        }],
        _coll: 'physicians_timetable_sum',
    },
    '5b3f601db72a7d317': {
        _id: '5b3f601db72a7d317',
        _lastModifiedAt: 1537884028362,
        _lastModifiedBy: null,
        nearest_available_dates: [{
            clinic_id: '5b3f5faa72b310212',
            nearest_available_date: null,
        }, {
            clinic_id: '5b3f5fa7eaf998e58',
            nearest_available_date: null,
        }],
        _coll: 'physicians_timetable_sum',
    },
    '5b3f60257ef411621': {
        _id: '5b3f60257ef411621',
        _lastModifiedAt: 1537884029219,
        _lastModifiedBy: null,
        nearest_available_dates: [{
            clinic_id: '5b3f5fa4529813245',
            nearest_available_date: null,
        }],
        _coll: 'physicians_timetable_sum',
    },
    '5b3f604b141ffd7b6': {
        _id: '5b3f604b141ffd7b6',
        _lastModifiedAt: 1537884030740,
        _lastModifiedBy: null,
        nearest_available_dates: [{
            clinic_id: '5b3f5fa7eaf998e58',
            nearest_available_date: null,
        }],
        _coll: 'physicians_timetable_sum',
    },
    '5b3f606537ffe18fc': {
        _id: '5b3f606537ffe18fc',
        _lastModifiedAt: 1537884032047,
        _lastModifiedBy: null,
        nearest_available_dates: [{
            clinic_id: '5b3f5fa7eaf998e58',
            nearest_available_date: null,
        }],
        _coll: 'physicians_timetable_sum',
    },
    '5b3f6078917c09059': {
        _id: '5b3f6078917c09059',
        _lastModifiedAt: 1537884032468,
        _lastModifiedBy: null,
        nearest_available_dates: [{
            clinic_id: '5b3f5fa4529813245',
            nearest_available_date: null,
        }],
        _coll: 'physicians_timetable_sum',
    },

};
