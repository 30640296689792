import { View } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import { navigate } from '../actions';
import { getFromStoreDb, putIntoStore } from '../store';
import { capitalize } from '../utils';
import { doctorFilterParams } from '../screens/Doctors/DoctorsSpecialtiesSearch';
import { Title } from './elements';
import { TouchableOpacity } from './react';
import { COLORS } from './style';
import { React } from '.';

const letterContainerSize = 40;

export const specialty = EStyleSheet.create({
    list: {
        borderColor: '#E9E9E9',
        borderWidth: 1,
        borderRadius: 3,
        backgroundColor: '#fff',
        paddingRight: 12,
        marginHorizontal: 12,
        marginBottom: 12,
    },
    item: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 44,
    },
    textItem: {
        borderBottomWidth: 1,
        borderColor: '#E9E9E9',
        paddingVertical: 12,
        flex: 1,
    },
    firstItem: { paddingLeft: 0 },
    firstItemText: { paddingVertical: 19 },
    letterContainer: {
        width: letterContainerSize,
        height: letterContainerSize,
        margin: 2,
        alignItems: 'center',
        justifyContent: 'center',
    },
    letter: {
        color: COLORS.MAIN,
        fontWeight: 'bold',
        fontSize: 18,
    },
});

const Letter = ({ title }) => {
    const letter = title[0].toUpperCase();
    return (
        <View style={specialty.letterContainer}>
            <Title style={specialty.letter}>{letter}</Title>
        </View>
    );
};

export const onSpecialty = (spec, from = 'default') => {
    const filterParam = doctorFilterParams[from] ?? doctorFilterParams.default;
    const doctorsFilters = getFromStoreDb(filterParam.scheme) || {};
    putIntoStore(filterParam.scheme, {
        ...doctorsFilters,
        specialty: spec,
    });
    navigate(`DoctorList${from === 'default' ? '' : 'Clinic'}`, {
        title: spec,
        from,
    });
};

export const SpecialtyItem = ({ item: { title, isFirst }, isLast, from }) => {
    const onSpecialtyPress = () => {
        onSpecialty(title, from);
    };
    return (
        <TouchableOpacity style={[specialty.item, !isFirst ? {} : specialty.firstItem]} onPress={onSpecialtyPress}>
            {!isFirst ? null : <Letter title={title} />}
            <View
                style={[
                    specialty.textItem,
                    !isFirst ? {} : specialty.firstItemText,
                    isLast ? { borderBottomWidth: 0 } : {},
                ]}>
                <Title>{capitalize(title)}</Title>
            </View>
        </TouchableOpacity>
    );
};

export const SpecialtiesList = ({ data, from }) => {
    return (
        <View style={specialty.list}>
            {data.map((e, i) => (
                <SpecialtyItem item={e} isLast={data?.length - 1 === i} from={from} />
            ))}
            {/* <SectionList
                contentContainerStyle={specialty.list}
                sections={data}
                renderItem={SpecialtyItem}
                initialNumToRender={15}
            /> */}
        </View>
    );
};
