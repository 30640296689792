import { tomorrowDate } from "../const.stub";

export default {
    'EKTzauHSKHSfnjCIjUFN': {
        _lastModifiedAt: 0,
        endDate: tomorrowDate.getTime(),
        level: 'warn',
        message: 'Онлайн-запись временно недоступна',
        startDate: 0,
        target: '5b4c5eceaf981f5f0',
    },
}
