/* eslint-disable global-require,no-underscore-dangle,no-alert */
import {
    Alert, Linking, PermissionsAndroid as PermissionsAndroidNative, Platform, Share,
} from 'react-native';
// eslint-disable-next-line import/no-unresolved
// import ImagePicker from 'react-native-image-picker';
// eslint-disable-next-line import/no-unresolved
// import { copyFileToCache, launchCamera, launchImageLibrary } from 'react-native-image-picker-android';
import RNFetchBlob from 'react-native-blob-util';
import DeviceInfo from 'react-native-device-info';
import NotificationManager from 'react-native-check-notification-enable';
import Geolocation from '@react-native-community/geolocation';
import FileShare from 'react-native-share';
import {
    API_HOST, FIRESTORE, GOOGLE_WEB_CLIENT_ID, MODE, VERSION,
} from '../config';
import {
    extractPhoneNumber, formatDateShort, generateRandomId, urlStringify,
} from '../utils';
import { resolveTabletkaLoc } from '../i18n';
import { getFromStore, SCHEME } from '../store';
import { COLORS } from '../styles/colors';
import firebase from './firebase';
import { storage } from './localstorage';
import { showImagePickerOrg } from './imagePicker';

/* global window */
navigator.geolocation = Geolocation;

export const checkExternalStorage = () => (Platform.OS === 'android'
    ? PermissionsAndroidNative.request(PermissionsAndroidNative.PERMISSIONS.READ_EXTERNAL_STORAGE)
    : Promise.resolve('granted'));

export const checkPhotosLibrary = () => {
    if (Platform.OS === 'ios') {
        const {
            check, request, PERMISSIONS, RESULTS,
        } = require('react-native-permissions');
        return check(PERMISSIONS.IOS.PHOTO_LIBRARY).then((result) => {
            if (result === RESULTS.GRANTED) {
                return true;
            } else if (result === RESULTS.DENIED) {
                return request(PERMISSIONS.IOS.PHOTO_LIBRARY).then(res => res === RESULTS.GRANTED);
            }
            return false;
        });
    }
    return Promise.resolve(true);
};

export const fileExists = Platform.OS === 'web' ? () => false : f => require('react-native-fs').exists(f);

// eslint-disable-next-line global-require
const documentDirectoryPath = () => (Platform.OS === 'web' ? '' : require('react-native-fs').DocumentDirectoryPath);

const PermissionsAndroid = PermissionsAndroidNative || {
    check: () => true,
    PERMISSIONS: {},
};

const { ACCESS_FINE_LOCATION } = Platform.OS === 'web' ? '' : PermissionsAndroid.PERMISSIONS;

const failedLocation = () => ({
    coords: resolveTabletkaLoc(getFromStore(SCHEME.LOCATION) || 17),
    isFailed: true,
});

const errorHandler = (error) => {
    // eslint-disable-next-line no-console
    console.log('geolocation err', error);
    return failedLocation();
};

const withPermissionsCheckAndroid = async (toCallWhenPermissionGranted) => {
    const hasLocationPermission = await PermissionsAndroid.check(ACCESS_FINE_LOCATION);

    if (hasLocationPermission === true) {
        return toCallWhenPermissionGranted();
    }
    const result = await PermissionsAndroid.request(ACCESS_FINE_LOCATION);

    if (result === PermissionsAndroid.RESULTS.GRANTED) {
        return toCallWhenPermissionGranted();
    }

    return failedLocation();
};

const withPermissionsCheckIOS = toCallWhenPermissionGranted => Promise.resolve(toCallWhenPermissionGranted());

const withPermissionsCheck = (toCallWhenPermissionGranted) => {
    if (window.Cypress) {
        return {
            coords: {
                latitude: 0,
                longitude: 0,
            },
        };
    }
    if (Platform.OS === 'android') {
        return withPermissionsCheckAndroid(toCallWhenPermissionGranted);
    }
    return withPermissionsCheckIOS(toCallWhenPermissionGranted);
};

/**
 * getCurrentGeoPosition
 */

/* global navigator */
export function getCurrentPosition() {
    const position = () => new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject)).catch(errorHandler);
    return withPermissionsCheck(position);
}

export const tryOpenDevInfo = (function tryOpenDevInfoFactory() {
    const devcount = 5;
    const devtime = 200 * devcount;

    let counter = 0;

    const _showDevInfoAlert = () => {
        Alert.alert(
            `Dev mode:  ${MODE}`,
            `Version:  ${VERSION}\n
    Host:  ${API_HOST}\n
    Google Webclient Id:  ${GOOGLE_WEB_CLIENT_ID}\n
    Firebase Project Id:  ${FIRESTORE}\n
    `.trim(),
            [
                {
                    text: 'OK',
                    // eslint-disable-next-line no-console
                    onPress: () => console.log('OK Pressed'),
                },
            ],
            { cancelable: true },
        );
    };

    return () => {
        if (counter === 0) {
            setTimeout(() => {
                counter = 0;
            }, devtime);
        }
        counter++;
        if (counter === devcount) {
            _showDevInfoAlert();
        }
    };
})();
export const requestCameraPermission = async () => {
    if (Platform.OS === 'android') {
        try {
            const granted = await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.CAMERA, {
                title: 'Camera Permission',
                message: 'App needs camera permission',
            });
            // If CAMERA Permission is granted
            return granted === PermissionsAndroid.RESULTS.GRANTED;
        } catch (err) {
            // eslint-disable-next-line no-console
            console.warn(err);
            return false;
        }
    } else {
        return true;
    }
};

export const requestExternalReadPermission = async () => {
    if (Platform.OS === 'android') {
        try {
            const granted = await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE, {
                title: 'External Storage Write Permission',
                message: 'App needs write permission',
            });
            // If WRITE_EXTERNAL_STORAGE Permission is granted
            return granted === PermissionsAndroid.RESULTS.GRANTED;
        } catch (err) {
            // eslint-disable-next-line no-console
            console.warn(err);
            // eslint-disable-next-line no-undef,no-alert
            alert('Write permission err', err);
        }
        return false;
    }
    return true;
};

const captureImage = async (cb, onDidCancel) => {
    const options = {
        mediaType: 'photo',
        saveToPhotos: true,
    };
    const isCameraPermitted = await requestCameraPermission();
    const isStoragePermitted = await requestExternalReadPermission();
    if (isCameraPermitted && isStoragePermitted) {
        // launchCamera(options, (response) => {
        //     // eslint-disable-next-line no-console
        //     console.log('Response = ', response);
        //     if (response.didCancel) {
        //         onDidCancel();
        //         return;
        //     } else if (response.errorCode === 'camera_unavailable') {
        //         // eslint-disable-next-line no-undef
        //         alert('Camera not available on device');
        //         return;
        //     } else if (response.errorCode === 'permission') {
        //         // eslint-disable-next-line no-undef
        //         alert('Permission not satisfied');
        //         return;
        //     } else if (response.errorCode === 'others') {
        //         // eslint-disable-next-line no-undef
        //         alert(response.errorMessage);
        //         return;
        //     }
        //     cb({
        //         path: response.uri,
        //         type: response.type,
        //         uri: response.fileName,
        //         id: guid(),
        //         error: response.error,
        //         origURL: response.origURL,
        //     });
        // });
    }
};

const chooseFile = (cb, onDidCancel) => {
    const options = {
        mediaType: 'photo',
        maxWidth: 300,
        maxHeight: 550,
        quality: 1,
    };
    // launchImageLibrary(options, (response) => {
    //     // eslint-disable-next-line no-console
    //     console.log('Response = ', response);

    //     if (response.didCancel) {
    //         onDidCancel();
    //         return;
    //     } else if (response.errorCode === 'permission' || response.errorCode === 'camera_unavailable') {
    //         // eslint-disable-next-line no-undef
    //         alert(Object.R('titles.errorAddDocuments'));
    //         return;
    //     } else if (response.errorCode === 'others') {
    //         // eslint-disable-next-line no-undef
    //         alert(response.errorMessage);
    //         return;
    //     }
    //     cb({
    //         path: response.uri,
    //         type: response.type,
    //         uri: response.fileName,
    //         id: guid(),
    //         error: response.error,
    //         origURL: response.origURL,
    //     });
    // });
};

export const copyToCache = (path) => {}; // copyFileToCache(path);

export const tryShowImageFromCache = async (path, item, uri, onRetryRender, onErrorOccurred) => {
    if (Platform.OS === 'android' && path) {
        const isExist = await fileExists(path);
        if (isExist) {
            const cacheImgPath = `${require('react-native-fs').CachesDirectoryPath}/${uri}`;
            // fileExists(cacheImgPath).then((cachePathIsExist) => {
            //     if (cachePathIsExist) {
            //         console.log('log__2');
            //         item.cachePath = cacheImgPath;
            //         onRetryRender();
            //     } else {
            //         copyToCache(path);
            //         fileExists(cacheImgPath).then((isCreated) => {
            //             if (isCreated) {
            //                 console.log('log__1');
            //                 item.cachePath = cacheImgPath;
            //                 onRetryRender();
            //             } else {
            //                 console.log('log_3');

            //                 onErrorOccurred();
            //             }
            //         });
            //     }
            // });
            const isExistCacheImgPath = await fileExists(cacheImgPath);
            if (isExistCacheImgPath) {
                // eslint-disable-next-line no-param-reassign
                item.cachePath = cacheImgPath;
                onRetryRender();
            } else {
                copyToCache(path);
                const isCreated = await fileExists(cacheImgPath);
                // eslint-disable-next-line max-depth
                if (isCreated) {
                    // eslint-disable-next-line no-param-reassign
                    item.cachePath = cacheImgPath;
                    onRetryRender();
                } else {
                    onErrorOccurred();
                }
            }
        } else {
            onErrorOccurred();
        }
    } else {
        onErrorOccurred();
    }
};

/**
 * showImagePicker.
 * @param {*} cb
 * @param {*} onDidCancel
 * @param fromGallery
 */
export const showImagePicker = (cb, onDidCancel, fromGallery = true) => {
    showImagePickerOrg(cb, onDidCancel, (fromGallery = true));
    // if (Platform.OS === 'ios') {
    //     ImagePicker.showImagePicker(
    //         {
    //             takePhoto: true,
    //             useLastPhoto: true,
    //             chooseFromLibrary: true,
    //             storageOptions: { cameraRoll: Platform.OS === 'android' },
    //             permissionDenied: {
    //                 title: Object.R('titles.error'),
    //                 text: Object.R('titles.errorAddDocuments'),
    //                 reTryTitle: Object.R('buttons.settings'),
    //                 okTitle: 'OK',
    //             },

    //             ...Object.R('imagePickerOptions'),
    //         },
    //         ({
    //             path,
    //             type,
    //             uri = '',
    //             didCancel,
    //             error,
    //             origURL,
    //         }) => {
    //             if (!didCancel) {
    //                 const uriParts = uri.split('/');
    //                 cb({
    //                     path,
    //                     type,
    //                     uri: uriParts[uriParts.length - 1],
    //                     id: guid(),
    //                     error,
    //                     origURL,
    //                 });
    //             } else if (didCancel && onDidCancel) {
    //                 onDidCancel();
    //             }
    //         },
    //     );
    // } else if (fromGallery) {
    //     chooseFile(cb, onDidCancel);
    // } else {
    //     captureImage(cb, onDidCancel);
    // }
};

export const openURL = (url) => {
    const urlFormatted = url?.split?.(' ')?.length > 1 ? encodeURI(url) : url;
    Linking.openURL(urlFormatted ?? url);
};

export function openIfSupported(url, onFailed) {
    Linking.canOpenURL(url).then((supported) => {
        if (supported) {
            openURL(url);
        } else {
            // eslint-disable-next-line no-console
            console.log('Linking.openURL', 'not supported', url);
            if (onFailed) {
                onFailed();
            }
        }
    }, onFailed);
}

export function openAddress(address, onFailed) {
    const url = Platform.select({
        ios: `maps:0,0?q=${address || ''}`,
        android: `geo:0,0?q=${address || ''}`,
    });

    openIfSupported(url, onFailed);
}

// eslint-disable-next-line no-unused-vars
export function openLocation(latitude, longitude, locationName = '', onFailed) {
    const url = Platform.select({
        ios: `maps:0,0?q=${latitude},${longitude}`,
        android: `geo:0,0?q=${latitude},${longitude}`, // (${locationName})
    });

    openIfSupported(url, onFailed);
}

export function dial(phone) {
    openURL(`tel:${extractPhoneNumber(phone)}`);
}

export function openAppInStore() {
    const url = Platform.select({
        ios: `https://apps.apple.com/app/apple-store/id1441433446?pt=289177&ct=app_update&mt=8`,
        android: `market://details?id=md.aibolit.member.prod`,
        web: `market://details?id=md.aibolit.member.prod`,
    });

    openURL(url);
}

export const existsByPath = async path => fileExists(path);

const isNewArchitecture = !!global?.nativeFabricUIManager;
export const getIosFileAbsolutePath = (uri, path) => (isNewArchitecture ? path : path);

export const resolveImagePath = (uri, path) => (Platform.OS === 'ios' ? getIosFileAbsolutePath(uri, path) : `file://${path}`);

// iOS-specific method to copy image from assets to application documents
// eslint-disable-next-line global-require
export const copyImageToDocuments = (source, imageName) => require('react-native-fs').copyAssetsFileIOS(source, getIosFileAbsolutePath(imageName), 0, 0);

export const getDynamicLink = async (params, path) => {
    let domain = 'link.aibolit.md/l/';
    // TODO: remove from 'iosBundlePostfix' a '1' in the end in case issue with provisioning
    // profile bundle id resolves. In that case also change corresponding values in XCode
    let iosBundlePostfix = 'dev1';
    let androidBundlePostfix;
    const target = path && path.length ? `https://link.aibolit.md/${path.join('/')}` : `https://link.aibolit.md/d`;

    const testMode = '';

    switch (testMode || MODE) {
        case 'dev':
            domain = 'aibolitdev.page.link';
            androidBundlePostfix = 'dev';
            break;
        case 'qa':
            domain = 'aibolitqa.page.link';
            androidBundlePostfix = 'qa';
            break;
        case 'stag':
            domain = 'aibolitstag.page.link';
            androidBundlePostfix = 'stag';
            break;
        case 'prod':
            iosBundlePostfix = 'prod';
            androidBundlePostfix = 'prod';
            break;
        default:
            iosBundlePostfix = 'prod';
            androidBundlePostfix = 'prod';
    }

    return urlStringify({
        type: 'https',
        target: domain,
        params: {
            link: urlStringify({
                target,
                params,
            }),
            apn: `md.aibolit.member.${androidBundlePostfix}`,
            isi: 1441433446,
            ibi: `md.aibolit.member.${iosBundlePostfix}`,
            efr: 1,
        },
    });
};

export const shareItem = async (title, message, onShare) => {
    const t = Object.R('titles.shareAppText');
    const result = await Share.share({
        title,
        message: Platform.OS === 'ios' ? title ?? t : message,
        url: message,
    });
    if (result.action === Share.sharedAction) {
        onShare();
    }
};

export const shareItemNew = async (title, message, onShare) => {
    if (Platform.OS === 'web' && 'share' in navigator) {
        navigator?.share?.({
            text: message,
            title,
        });
        return;
    }
    const result = await Share.share(
        {
            title,
            message,
            // url: message,
        },
        {
            subject: title,
            dialogTitle: title,
        },
    );
    if (result.action === Share.sharedAction) {
        onShare();
    }
};

export const getUniqueDeviceId = async () => {
    const realDeviceId = await DeviceInfo.getUniqueId();
    // in case of web or real device id missing let's generate random one
    return realDeviceId === 'unknown' ? generateRandomId() : realDeviceId;
};

export const getDeviceName = () => (Platform.OS === 'web' ? window.navigator.userAgent : DeviceInfo.getDeviceName());

export const checkPushNotificationsPermission = async () => {
    if (Platform.OS === 'web') {
        return true;
    }
    return Platform.OS === 'ios'
        ? firebase.checkPushNotificationsPermission()
        : NotificationManager.areNotificationsEnabled();
};

export const getStoreDetailsLink = () => (Platform.OS === 'ios'
    ? 'itms-apps://itunes.apple.com/app/apple-store/id1441433446?mt=8&action=write-review'
    : 'market://details?id=md.aibolit.member.prod');

export const getDeviceModel = () => DeviceInfo.getDeviceId();

export const checkDeviceId = async (onFirst) => {
    // await storage.set('deviceId', '33d41e0de248c537');
    const savedDeviceId = await storage.get('deviceId');
    // 33d41e0de248c537
    if (!savedDeviceId) {
        const deviceId = await getUniqueDeviceId();
        storage.set('deviceId', deviceId);
        onFirst(deviceId);
    }
};
const EDPath = Platform.OS === 'android' ? require('react-native-fs').ExternalDirectoryPath : '';

const publicDirObj =
    Platform.OS === 'web'
        ? {}
        : {
            path: EDPath,
            pathName: EDPath.toLowerCase().includes('android')
                ? `android${EDPath.toLowerCase().split('android')[1]}`
                : EDPath,
        };

const getSuitablePath = () => Platform.select({
    ios: {
        path: require('react-native-fs').DocumentDirectoryPath,
        pathName: 'Files',
    },
    android:
            Platform.Version > 28
                ? {
                    path: require('react-native-fs').DownloadDirectoryPath,
                    pathName: 'Downloads',
                }
                : publicDirObj,
});

const getExtentionBycontentType = (contentType) => {
    switch (contentType) {
        case 'application/pdf':
            return '.pdf';
        case 'application/vnd.ms-excel':
            return '.xls';
        case 'application/msword':
            return '.doc';
        case 'application/vnd.rar':
            return '.rar';
        default:
            return '';
    }
};

export const downloadResultFile = async (data, usePublicDir, contentType = 'application/pdf') => {
    const pathObj = usePublicDir ? publicDirObj : getSuitablePath();
    let error;
    try {
        const decoded = decodeURIComponent(data);
        const fileName = `${Date.now()}${getExtentionBycontentType(contentType)}`;
        const fpath = `${pathObj.path}/${fileName}`;
        await RNFetchBlob.fs.writeFile(fpath, decoded, 'base64');
        if (Platform.OS === 'android') {
            const { android } = RNFetchBlob;
            android.addCompleteDownload({
                showNotification: true,
                mime: contentType,
                description: 'Test result',
                title: fileName,
                path: fpath,
            });
        }
    } catch (e) {
        error = e;
    }
    return error || Object.R('titles.fileSavedIn', { path: pathObj.pathName });
};

export const downloadFile = async (sourceData, showMessage, contentType = 'application/pdf') => {
    const answ = await downloadResultFile(sourceData, false, contentType);
    if (typeof answ === 'string') {
        showMessage(answ);
        return;
    }
    if (answ?.message === 'Permission denied' && Platform.OS === 'android') {
        const answ2 = await downloadResultFile(sourceData, true, contentType);
        showMessage(typeof answ2 === 'string' ? answ2 : String(answ2));
        return;
    }
    showMessage(typeof answ === 'string' ? answ : String(answ));
};

const downloadBlob = (blob, item, isVisit) => {
    // eslint-disable-next-line no-undef
    const doc = document;
    const doc1 = doc.getElementById('content');

    let el = doc.getElementById('floatWindow');
    if (!el) {
        el = doc.createElement('div');
        doc1.appendChild(el);
    }
    const outStyle = `
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
        height: 100%;
        text-align: center;
        display: block;
        `;
    const inDiv = `
        position: fixed;
        bottom: 12px;
        left: 12px;
        right: 12px;
        height: 170px;
        background-color: white;
        border-radius: 3px;
    `;
    const titleStyle = `font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 17px;
        text-align: center;
        padding-top: 20px;
        color: #555555;`;
    const textStyle = `font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        padding: 16px 16px 0;
        color: #555555;
        text-align: left;
    `;
    const aStyle = `
        position: fixed;
        bottom: 10px;
        left: 10px;
        right: 10px;
        height: 40px;
        border-radius: 3px;
        margin-top: 16px;
        text-align: center;
        color: ${COLORS.MAIN};
    `; //                <a href="${blob}" download="${name}" id="pdf_id">1</a>
    el.outerHTML = `
        <!--suppress ALL -->
<div id="floatWindow" style="${outStyle}" onclick="(function(){
            let i = document.getElementById('floatWindow');
            if (i) {
                i.style='display: none;';
            }
            return true; })();return true;">
            <div style="${inDiv}" onclick="(function(e){ let i = document.getElementById('floatWindow');
                    e.stopPropagation();
                    return false; })(arguments[0]);return false;">
                    <div style="${titleStyle}">
                        ${Object.R(`titles.safari${isVisit ? 'Report' : 'Test'}`)}
                    </div>
                    <div style="${textStyle}">${item?.report?.name ?? item?.name ?? ''}</div>
                    <div style="${textStyle}">${formatDateShort(item?.report?.date ?? item?.date) ?? ''}</div>
                <a href="${blob}" target="_blank" id="pdf_id1" onclick="(
                    function(e) {
                        let i = document.getElementById('floatWindow');
                        if (i) {
                            i.style='display: none;';
                        }
                        e.stopPropagation();
                        return true;
                    }
                )(arguments[0]);return true;" style="${aStyle}">${Object.R('titles.safariOpen')}</a>
            </div>
        </div>
    `;
};

export const openFileOnNewTab = (data, name, isVisit) => {
    const url = URL.createObjectURL(data);
    const win = window.open(url, '_blank');
    if (win) {
        return;
    }
    downloadBlob(url, name, isVisit);
};

// noinspection JSUnusedGlobalSymbols
export const handleResultFile = data => (Platform.OS === 'web' ? openFileOnNewTab(data) : downloadResultFile(data));

export const shareFile = async (data, onFinally, contentType = 'application/pdf') => {
    const base64Data = `data:${contentType};base64,${data}`;
    try {
        await FileShare.open({ url: base64Data });
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log('error of sharing: ', e);
    } finally {
        onFinally();
    }
};
