import { Page, React } from '../../../common';
import { ChildModalAgreements, ModalAgreements } from '../../../common/Modal';
import { parseToDdMmYyyy } from '../../../utils';
import api from '../../../services/api';
import actions from '../../../actions';

export const checkConsentReceived = (info, profile) => {

    return new Promise((resolve) => {

        if (info?.me?.consentReceived && (!profile?.isChild || profile?.consentReceived)) {

            resolve(true);
            return;

        }

        const childModal = () => Page.showModal(
            <ChildModalAgreements
                title={[profile?.fullNameRu ?? profile?.firstLastName ?? '', parseToDdMmYyyy(profile?.birthday) ?? ''].join(', ')}
                centerTitle={true}
                onCancel={() => {

                    Page.closeModal();
                    resolve(false);

                }}
                onConfirm={() => {

                    Page.closeModal();
                    api.updateChild({
                        _id: profile?.profileId,
                        consentReceived: true,
                    })
                        .then(() => {

                            resolve(true);
                            setTimeout(
                                () => actions.reloadUserInfo(
                                    Object.handleNewUserItemsFound,
                                    actions.updatePrefs,
                                    actions.updateSelects,
                                ),
                                100,
                            );

                        })
                        .catch(() => {

                            resolve(false);

                        });

                }}
            />,
        );
        !info?.me?.consentReceived
            ? Page.showModal(
                <ModalAgreements
                    onConfirm={() => {

                        Page.closeModal();
                        api.updateUserInfo({ consentReceived: true });
                        if (profile?.relKind === 'CHILD') {

                            setTimeout(() => {

                                childModal();

                            }, 500);

                        } else {

                            setTimeout(
                                () => actions.reloadUserInfo(
                                    Object.handleNewUserItemsFound,
                                    actions.updatePrefs,
                                    actions.updateSelects,
                                ),
                                100,
                            );
                            resolve(true);

                        }

                    }}
                    onCancel={() => {

                        Page.closeModal();
                        resolve(false);

                    }}
                    title={[info?.name ?? '', parseToDdMmYyyy(info?.birthday) ?? '']
                        ?.filter?.(i => i)
                        ?.join?.(', ')}
                    centerTitle={true}
                />,
            )
            : childModal();

    });

};
