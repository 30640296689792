import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsEmailUnconfirmed, getUserInfo } from '../../../selectors';
import { inAppUpdaterStyles as styles } from '../../../common/InAppUpdater';
import {
    Button, Card, Icon, Text, TextHighliter, View,
} from '../../../common';
import { getFormValues } from '../../../selectors/forms';
import { setFormValue } from '../../../actions/forms';
import { useNavigation } from '@react-navigation/native';

const DISABLE_REMINDER_KEY = 'disableEmailReminder';

export const EmailReminder = () => {

    const formValues = useSelector(getFormValues);
    const dispatch = useDispatch();
    const navigation = useNavigation();

    const info = useSelector(getUserInfo);
    const isEmailUnconfirmed = useSelector(getIsEmailUnconfirmed);
    const [isWindowDisable, setIsWindowDisable] = useState(true);

    const closeWindowHandler = () => {

        dispatch(setFormValue(DISABLE_REMINDER_KEY, true));

    };


    useEffect(() => {

        setIsWindowDisable(formValues?.[DISABLE_REMINDER_KEY]);

    }, [formValues]);

    const email = useMemo(() => {

        const e = info?.emails?.find(({ usage }) => usage === 'PRIMARY');
        return e?.address;

    }, [info?.emails]);

    const emailMessage = useMemo(() => {

        return <TextHighliter highlightStyle={{ fontFamily: 'SourceSansPro-Semibold' }} keyword={email} numberOfLines={0} value={Object.R('reminder.confirmEmail', { email })} backgroundColor={'white'} />;

    }, [email]);

    return isEmailUnconfirmed && !isWindowDisable ? <>
        <Card containerStyle={[styles.wrapper, { position: 'relative' }]}>
            <View style={styles.lineWrapper}>
                <Button
                    action={closeWindowHandler}
                    styles={[
                        styles.closeButton,
                        {
                            position: 'absolute',
                            right: -14,
                            top: -14,
                        },
                    ]}>
                    <Icon name="cross" color="#787878" style={styles.closeIcon} />
                </Button>
            </View>
            <Text>{emailMessage}</Text>
            <Button
                transparent
                title={Object.R('reminder.confirmEmailButton')}
                titleStyles={{ color: '#5C85DD' }}
                action={() => {

                    navigation.navigate('Account');

                }}
            />
            <View />
        </Card>
    </> : null;

};
