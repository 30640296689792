/* eslint-disable no-underscore-dangle */
import { useNavigation } from '@react-navigation/native';
import {
    useCallback, useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions, { back } from '../../actions';
import { MedcentersFilterModal } from '../../combo';
import {
    BackHandler, Button, ButtonsGroup, DatePicker, Page, Platform, React, Section, selectOptions, SingleNavigation, Switch, View,
} from '../../common';
import { COLORS } from '../../common/style';
import { synonymize } from '../../i18n';
import { getGlobalLocation, getHideTimePeriodSearch, physiciansIsLoading } from '../../selectors';
import api from '../../services/api';
import { putIntoStore, SCHEME } from '../../store';
import { normalizeString } from '../../utils';
import { doctorFilterParams } from './DoctorsSpecialtiesSearch';
import { SpecializationSearchBar } from './snippets';
import { getRequestDates, resetDoctorsFilters } from './utils';
import { setIsDoctorsSearching } from '../../actions/doctors';

const { PHYSICIANS_IS_LOADING } = SCHEME;

const NS = 'doctor-filter';

const resetSelections = (list, period = '0') => list.map(e => ({
    ...e,
    selected: period === e.id || false,
}));

// eslint-disable-next-line max-statements
export const DoctorsFilter = ({ route: { params } }) => {

    const { from = 'default', mdInfo } = params;
    const filter = doctorFilterParams[from] ?? doctorFilterParams.default;
    const prefilter = filter.prefilter ?? doctorFilterParams.default.prefilter;

    const worksAt = useSelector(prefilter.getWorksAt);
    const times = useSelector(prefilter.getTimes);
    const ages = useSelector(prefilter.getAges);
    const specialty = useSelector(prefilter.getSpeciality);
    const specialtyId = useSelector(prefilter.getSpecialityId);
    const specialties = useSelector(prefilter.getSpecialities);
    const worksAtGroup = useSelector(prefilter.getWorksAtGroup);
    const profiles = useSelector(prefilter.getProfile);
    const recentSpecialtyRequests = useSelector(prefilter.getRecentSpecRequest);
    const feedbacks = useSelector(prefilter.getFeedback);
    const dates = useSelector(prefilter.getDates);

    const areaCode = useSelector(getGlobalLocation);
    const isLoading = useSelector(physiciansIsLoading);
    const hideTimePeriodSearch = useSelector(getHideTimePeriodSearch);

    const [dateStart, setDateStart] = useState();
    const [dateEnd, setDateEnd] = useState();

    const navigation = useNavigation();

    const specialtySearch = useRef();
    const [hasEmptyRFields, setHasEmptyRFields] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {

        setDateStart(dates?.dateStart);
        setDateEnd(dates?.dateEnd);

    }, [dates]);

    const getSelectedMedcenterName = useCallback(
        () => worksAt
            ?.filter?.(e => e?.selected)
            ?.map?.(({ name }) => name)
            ?.join?.(',\n') || Object.R('titles.anyMedcenter'),
        [worksAt],
    );

    const getSelectedTimeName = useCallback(
        () => (times?.find?.(e => e?.selected) || times?.[0] || { name: '???' })?.name,
        [times],
    );

    const getSelectedAgeName = useCallback(
        () => {

            const selected = (ages?.find?.(e => e?.selected) || ages?.[0])?.name ?? 0;
            return Object.R(selected);

        },
        [ages],
    );

    const getSpecialtiesSuggestions = useCallback(() => {

        const specialtiesSuggestions = specialties.filter(spec => spec.id !== '');
        recentSpecialtyRequests.forEach((item) => {

            const index = specialtiesSuggestions.findIndex(({ id }) => id === item);
            const suggestion = specialtiesSuggestions[index];
            specialtiesSuggestions.splice(index, 1);
            specialtiesSuggestions.splice(0, 0, {
                ...suggestion,
                isPreviousSelection: true,
            });

        });
        return specialtiesSuggestions;

    }, [specialties, recentSpecialtyRequests]);

    // eslint-disable-next-line max-statements
    const _applyFilter = useCallback(async () => {

        const time = (times.find(e => e.selected) || {}).id;
        const age = ages.find(e => e.selected)?.id;
        const finalSpecialty = specialty
            .replace(/\([А-Яа-яA-Za-zЁё]*\)/gi, '')
            .trim()
            .toLowerCase();
        if ((time && time !== '0' && !(finalSpecialty && dates.dateStart)) || (dates.dateStart && !finalSpecialty)) {

            setHasEmptyRFields(true);
            return;

        }
        const finaSpecialtyId = specialtyId || specialties?.find(e => e.name === finalSpecialty)?.apiId;
        const nWorksAt = worksAt.filter(e => e.selected).map(e => e.id);
        const filters = {
            specialty: finalSpecialty,
            specialtyId: finaSpecialtyId,
            worksAt: nWorksAt?.length === worksAt?.length ? [] : nWorksAt,
            worksAtGroup,
            profiles: (profiles.find(e => e.selected) || {}).id,
            feedbacks,
            time,
            dates,
            age,
        };
        putIntoStore(filter.scheme, filters);
        actions.updateDocFilters(
            {
                worksAt: filters.worksAt,
                worksAtGroup,
            },
            from === 'default' ? '' : from,
        );
        if (finalSpecialty && dates.dateStart) {

            const specializationForApi = getSpecialtiesSuggestions()
                ?.find?.(e => e?.name === finalSpecialty)?.apiName;
            putIntoStore(PHYSICIANS_IS_LOADING, true);
            dispatch(setIsDoctorsSearching(true));
            try {

                const params = getRequestDates({
                    dateStart: dates?.dateStart,
                    dateEnd: dates?.dateEnd,
                    timeRange: time,
                });
                const searchResult = await api.getDoctorSearchResultInt({
                    areaCode,
                    specialityName: specializationForApi || finalSpecialty,
                    ...params,
                });
                const searchResultArray = JSON.parse((searchResult || {}).data);
                putIntoStore(
                    filter.physiciansSearchScheme,
                    Array.isArray(searchResultArray) ? searchResultArray : undefined,
                );
                Array.isArray(searchResultArray) && putIntoStore('specializationForApi', specializationForApi);

            } catch (e) {
                //
            } finally {

                dispatch(setIsDoctorsSearching(false));

            }

            putIntoStore(PHYSICIANS_IS_LOADING, false);

        } else {

            putIntoStore(filter.physiciansSearchScheme, undefined);
            putIntoStore('specializationForApi', undefined);

        }
        Object.trackAction('filter_doc_all', {
            specialty: finalSpecialty,
            specialtyId,
            worksAt: Boolean(worksAt?.length),
            worksAtGroup,
            profile: profiles.find(e => e.selected),
            feedbacks,
            time,
            dateStart: dates.dateStart,
            dateEnd: dates.dateEnd,
            age,
        });
        if (filters?.specialty) {

            back();

        } else {

            navigation.popToTop();
            navigation.navigate(`DoctorsSpecialtiesSearch${from === 'MedCenterDetails' ? 'Clinic' : ''}`, {
                params: {
                    from,
                    mdInfo,
                },
            });

        }

    }, [times, worksAt, worksAtGroup, profiles, feedbacks, dates, specialty, areaCode, ages]);

    useEffect(() => {

        const backHandler = Platform.OS !== 'web' ? BackHandler.addEventListener('hardwareBackPress', () => {

            if (hasEmptyRFields) {

                _applyFilter();

            } else {

                back();

            }
            return true;

        }) : null;
        return () => backHandler?.remove?.();

    }, []);

    const onDate = useCallback(
        (date) => {

            if ((dateStart && dateEnd) || (!dateStart && !dateEnd)) {

                putIntoStore(prefilter.dates, {
                    dateStart: date,
                    dateEnd: undefined,
                });
                return;

            }
            if (dateStart || dateEnd) {

                putIntoStore(
                    prefilter.dates,
                    new Date(date).valueOf() > new Date(dateStart || dateEnd).valueOf()
                        ? {
                            dateStart: dateStart || dateEnd,
                            dateEnd: date,
                        }
                        : {
                            dateStart: date,
                            dateEnd: dateStart || dateEnd,
                        },
                );

            }

        },
        [dateStart, dateEnd],
    );
    const handleSuggestionSelected = (apiId, item, recentSpecialtyReq) => {

        if (item) {

            const recentItemIndex = recentSpecialtyRequests.findIndex(recentId => recentId === item);
            const newRecentRequests = [...recentSpecialtyReq];
            if (recentItemIndex >= 0) {

                newRecentRequests.splice(recentItemIndex, 1);

            } else if (newRecentRequests.length === 3) {

                newRecentRequests.splice(0, 1);

            }
            newRecentRequests.push(item);
            putIntoStore(prefilter.recentSpecRequest, newRecentRequests);

        }
        putIntoStore(prefilter.speciality, item);
        putIntoStore(prefilter.specialityId, apiId);


    };

    const filterSpecialtySuggestions = useCallback(
        (specialtiesSuggestions, query) => {

            const normalizedQuery = normalizeString(query);
            const searchResult = [];
            specialtiesSuggestions.forEach((item) => {

                let { name: n } = item;
                const nameParts = normalizeString(n)?.split?.(/[\s-]/);
                const synonyms = nameParts.map(part => synonymize(part))?.join?.(' ');
                const didFindName = nameParts.some(part => part?.startsWith?.(normalizedQuery));
                const foundSynonym = synonyms
                    .split?.(' ')
                    .find?.(synonym => normalizeString(synonym)?.startsWith?.(normalizedQuery));
                if (didFindName || foundSynonym) {

                    if (!didFindName) {

                        n += ` (${foundSynonym})`;

                    }
                    searchResult.push({
                        ...item,
                        name: n,
                    });

                }

            });
            return searchResult;

        },
        [specialties],
    );

    const _resetFilter = () => {

        resetDoctorsFilters({}, {}, from);
        putIntoStore(prefilter.speciality, '');
        putIntoStore(prefilter.specialityId, '');

        setHasEmptyRFields(false);
        if (specialtySearch) {

            // Update search component state to match store
            specialtySearch.current?.state?.clear?.();

        }
        putIntoStore(prefilter.worksAt, resetSelections(worksAt));
        putIntoStore(prefilter.worksAtGroup, 'any');
        putIntoStore(prefilter.profiles, resetSelections(profiles));
        putIntoStore(prefilter.feedback, false);
        putIntoStore(prefilter.times, resetSelections(times));
        putIntoStore(prefilter.dates, {});
        putIntoStore(prefilter.ages, resetSelections(ages));

    };

    useEffect(() => {

        const result =
            (times?.some?.(e => e?.selected && e?.id !== '0') && !(specialty && dateStart)) ||
            (dateStart && !specialty);
        setHasEmptyRFields(result);

    }, [dateStart, dateEnd, times, specialty]);

    const backAction = () => {

        if (hasEmptyRFields) {

            // back();
            _applyFilter();

        } else {

            _applyFilter();
            // back();

        }
        return true;

    };
    actions.setFormData({ DoctorFilters: { back: backAction } });

    return (
        <Page
            name={NS}
            hint={
                hasEmptyRFields &&
                (!specialty || !dateStart) && {
                    level: 'error',
                    message: Object.R('error.fieldsAreRequired'),
                }
            }
            isLoading={isLoading}
            footer={
                <ButtonsGroup
                    style={{
                        backgroundColor: 'white',
                        paddingBottom: 12,
                        borderTopWidth: 1,
                        borderTopColor: '#F0F0F0',
                    }}>
                    <Button
                        transparent
                        action={_resetFilter}
                        title="buttons.reset_filter"
                        titleStyles={{
                            color: '#555555',
                            fontSize: 14,
                            fontWeight: '900',
                            fontFamily: 'SourceSansPro-Semibold',
                        }}
                    />
                    <Button
                        primary
                        action={_applyFilter}
                        title="buttons.find_doctor"
                        disabled={hasEmptyRFields && (!specialty || !dateStart)}
                        style={{
                            fontSize: 14,
                            fontWeight: '900',
                            fontFamily: 'SourceSansPro-Semibold',
                        }}
                    />
                </ButtonsGroup>
            }>
            <Section title="titles.doctorSpeciality">
                <SpecializationSearchBar
                    ref={(ref) => {

                        specialtySearch.current = ref;

                    }}
                    suggestionsListStyle={{
                        paddingHorizontal: 0,
                        borderWidth: 1,
                        borderColor: '#E9E9E9',
                    }}
                    suggestionTextStyle={{ paddingHorizontal: 16 }}
                    initialSuggestions={getSpecialtiesSuggestions()}
                    onQueryChange={(query) => {

                        putIntoStore(prefilter.speciality, query);
                        putIntoStore(prefilter.specialityId, null);

                    }}
                    value={specialty}
                    suggestionsRequest={query => filterSpecialtySuggestions(getSpecialtiesSuggestions(), query)}
                    onSuggestionSelected={(id, item) => handleSuggestionSelected(id, item, recentSpecialtyRequests)}
                    style={
                        hasEmptyRFields &&
                        !specialty && {
                            borderColor: COLORS.ACTION,
                            borderWidth: 1.5,
                            outline: 'none',
                        }
                    }
                />
            </Section>
            {hideTimePeriodSearch ? null : (
                <View>
                    <Section title={Object.R('titles.date')}>
                        <DatePicker
                            handleChooseDate={onDate}
                            withoutTimeslots
                            dateStart={dateStart}
                            open={dateStart}
                            dateEnd={dateEnd}
                            style={{
                                ...(hasEmptyRFields &&
                                    !dateStart && {
                                    borderColor: COLORS.ACTION,
                                    borderWidth: 1.5,
                                    borderRadius: 3,
                                }),
                                borderWidth: 1.2,
                                borderRadius: 3,
                            }}
                        />
                    </Section>
                    <Section title={Object.R('titles.time')}>
                        <SingleNavigation
                            title={getSelectedTimeName()}
                            onPress={() => {

                                Object.trackAction('filter_doc_time');
                                selectOptions({
                                    title: Object.R('titles.convenientTime'),
                                    data: times,
                                    onSelect: ({ id }) => {

                                        Object.trackAction('filter_doc_time_select', { timeId: id });
                                        putIntoStore(
                                            prefilter.times,
                                            times.map(e => ({
                                                ...e,
                                                selected: e.id === id,
                                            })),
                                        );

                                    },
                                });

                            }}
                        />
                    </Section>
                </View>
            )}
            <Section title="title.whereDoctorWorks">
                <SingleNavigation
                    title={getSelectedMedcenterName()}
                    onPress={() => Page.showModal(
                        <MedcentersFilterModal
                            groupFrom={prefilter.getWorksAtGroup}
                            prefilterDataFrom={prefilter.getWorksAt}
                            prefilterPath={prefilter.worksAt}
                            groupPath={prefilter.worksAtGroup}
                        />,
                    )
                    }
                />
            </Section>
            <Section title={Object.R('titles.filterAgeTitle')}>
                <SingleNavigation
                    title={getSelectedAgeName()}
                    onPress={() => {

                        Object.trackAction('filter_doc_age');
                        selectOptions({
                            title: Object.R('titles.filterAgeTitlePopup'),
                            data: ages,
                            onSelect: ({ id }) => {

                                Object.trackAction('filter_doc_age_select', { ageId: id });
                                putIntoStore(
                                    prefilter.ages,
                                    ages.map(e => ({
                                        ...e,
                                        selected: e.id === id,
                                    })),
                                );

                            },
                        });

                    }}
                />
            </Section>

            {/* <Section> */}
            {/* <SingleNavigation */}
            {/*     title={Object.R('titles.doctorForChildren')} */}
            {/*     rightItem={ */}
            {/*         <Switch.Default */}
            {/*             value={!!profiles.find(v => v?.id === '2' && v?.selected)} */}
            {/*             onValueChange={(v) => { */}

            {/*                 Object.trackAction('filter_doc_profile_switch', { profileId: v ? 'children' : 'all' }); */}
            {/*                 putIntoStore( */}
            {/*                     prefilter.profiles, */}
            {/*                     profiles.map((e) => { */}

            {/*                         if (e?.id === '2') { */}

            {/*                             e.selected = v; */}

            {/*                         } */}
            {/*                         if (e?.id === '0') { */}

            {/*                             e.selected = !v; */}

            {/*                         } */}
            {/*                         return { ...e }; */}

            {/*                     }), */}
            {/*                 ); */}

            {/*             }} */}
            {/*         /> */}
            {/*     } */}
            {/* /> */}
            {/* </Section> */}
            <Section>
                <SingleNavigation
                    title={Object.R('titles.onlyWithFeedbacks')}
                    style={{ paddingVertical: Platform.OS === 'ios' ? 8 : 10 }}
                    rightItem={
                        <Switch
                            accessibilityLabel="switch:doctor-feedbacks"
                            style={{ transform: Platform.OS === 'ios' ? [{ scaleX: 0.8 }, { scaleY: 0.8 }] : [] }}
                            activeThumbColor="#4770C0"
                            activeTrackColor="#9FB9FF"
                            onTintColor={Platform.OS !== 'web' ? '#6987D6' : '#8FB8FF'}
                            thumbTintColor="#5C85DD"
                            trackColor={{
                                false: '#CFCFCF',
                                true: Platform.OS === 'ios' ? '#5C85DD' : '#8FB8FF',
                            }}
                            value={feedbacks}
                            onValueChange={(value) => {

                                putIntoStore(prefilter.feedback, value);

                            }}
                        />
                    }
                />
            </Section>
        </Page>
    );

};

export const DoctorFilters = Page.register(DoctorsFilter);
