/* eslint-disable no-use-before-define */
import { Platform } from 'react-native';
import {
    InfoList, Link, List, Page, Panel, React, RText, S, Section, selectOptions, SingleNavigation, Subtitle, Title, VBox, View,
} from '../../common';
import { getUserInfo } from '../../selectors';
import bns from '../../services/bns';
import { navigate } from '../../actions';
import { AppHeaderExt } from '../../combo';
import { MY_API_HOST } from '../../config';

const programIdsWithMemo = [
    '5f35c4a',
    '5f35c52',
    '5f35c53',
    '5f35c55',
    '5f35c56',
    '5f35c58',
    '5f35c59',
    '5f35c5a',
    '5f35c5f',
    '5f35c5f',
    '5f35c68',
    '5f35c69',
    '5f35c6a',
    '5f35c6b',
    '5f35c6c',
];

const ContractSelector = ({
    // input:
    data = [],
    onSelect,
    selected = {},
    // calculated:
    hasMany = data.length > 1,
    navProps = {
        title: selected.name,
        showIndicator: selected.id !== '*',
        coverer: selected.coverer,
        hideIconRight: !hasMany,
        onPress: !hasMany
            ? null
            : () => selectOptions({
                title: 'titles.choose_contract',
                selected: selected.id,
                data,
                onSelect,
                hideClose: true,
            }),
    },
}) => (data.length ? (
    <AppHeaderExt>
        <SingleNavigation {...navProps} />
    </AppHeaderExt>
) : null);

const ListItem = ({ item: { name, currency, balance } }) => (
    <Panel style={{ alignItems: 'center' }}>
        <VBox flex gap={1} style={{ marginRight: 18 }}>
            <VBox gap={0.1}>
                <Title
                    numberOfLines={1}
                    style={{
                        fontFamily: 'SourceSansPro-Regular',
                        fontSize: 16,
                    }}>{`${name[0].toUpperCase()}${name.slice(1).toLowerCase()}`}</Title>
            </VBox>
        </VBox>
        <Title
            numberOfLines={1}
            style={{
                fontFamily: 'SourceSansPro-Regular',
                fontSize: 14,
            }}>
            {balance.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} {currency}
        </Title>
    </Panel>
);

const loadAllContracts = (setState) => {
    setState({
        error: null,
        contracts: [],
        isLoading: true,
    });
    bns.info()
        .then((result) => {
            const contracts = result.map(e => ({
                ...e,
                id: e.profileId,
                name: `${e.profile.fullNameRu}`,
            }));
            setState({
                contracts,
                isLoading: false,
            });
        })
        .catch(error => setState({
            error,
            isLoading: false,
        }));
};

export const BnsProgram = Page.register(
    // eslint-disable-next-line complexity
    ({
        setState,
        error,
        isLoading = !!programId,
        isContractLoading,
        program,
        contracts = [],
        contractProfileId,
        contract = contractProfileId ? contracts.find(e => e.profileId === contractProfileId) : contracts[0],
        userInfo,
        relatives = [userInfo.me, ...userInfo.relatives],
        selectedProgram = (contract &&
            contract.profileId &&
            relatives.find(({ profileId }) => profileId === contract.profileId)) ||
            program,
        programId = selectedProgram.programId || selectedProgram.id,
        programCaption = selectedProgram.programCaption,
        coverer = selectedProgram.coverer,
        declineReasonCode = selectedProgram.declineReasonCode,
        limits = (contract && contract.programLimits) || [],
        mainLimit = limits.slice(0, 1),
        partialLimits = limits.slice(1),
        onContractSelect = item => setState({ contract: item }),
        onDidMount = () => {
            if (programId) {
                loadAllContracts(setState);
            }
        },
        showProgramLink = Boolean(programCaption),
        filteredFields = programCaption ? [] : ['programCaption'],
    }) => (
        <Page
            safeArea
            name="bns-program"
            persistKeyboard
            notification={error}
            isLoading={isLoading}
            onDidMount={onDidMount}>
            {contracts.length < 1 || (contracts.length === 1 && !contractProfileId) ? null : (
                <ContractSelector
                    data={contractProfileId ? [contract] : contracts}
                    selected={contract}
                    onSelect={onContractSelect}
                />
            )}
            <Section title="titles.general_info">
                <InfoList
                    fields="programFields"
                    filteredFields={filteredFields}
                    data={programId ? selectedProgram : { coverer }}
                    titleStyle={{ flex: 0.45 }}
                    rightTitleBlockStyle={{ flex: 0.55 }}
                />
            </Section>
            {!programId && declineReasonCode ? (
                <Subtitle
                    style={{
                        marginTop: 12,
                        marginHorizontal: 24,
                        fontSize: 16,
                    }}
                    numberOfLines={1}
                    color="#DF655C"
                    id={`titles.declineReasonCode_${declineReasonCode}`}
                />
            ) : null}
            {programId ? (
                <View>
                    {showProgramLink && (
                        <Section style={S.mh}>
                            <RText id="hint.follow_program_details_link" />
                            <Link
                                id="link.program_details_link"
                                underline
                                href={
                                    Platform.OS === 'web'
                                        ? `https://${MY_API_HOST}/files/bns-program/index.html#${programId}`
                                        : () => {
                                            navigate('WebPage', {
                                                title: Object.R(`coverer.${coverer}`),
                                                source: { uri: `https://${MY_API_HOST}/files/bns-program/index.html#${programId}` },
                                            });
                                        }
                                }
                                style={{ marginVertical: 12 }}>
                                link.program_details_link
                            </Link>
                        </Section>
                    )}
                    {programIdsWithMemo.find(e => programId.startsWith(e)) || !showProgramLink ? (
                        <Section style={S.mh}>
                            <Link
                                id="link.program_memo_link"
                                underline
                                href={
                                    Platform.OS === 'web'
                                        ? `https://${MY_API_HOST}/files/bns-program/memo/${
                                              showProgramLink ? programId : '5e3bfa20d7c8a40113b74b7a'
                                          }.pdf`
                                        : () => {
                                            navigate('WebPage', {
                                                title: Object.R(`coverer.${coverer}`),
                                                source: {
                                                    uri: `https://${MY_API_HOST}/files/bns-program/memo/${
                                                          showProgramLink ? programId : '5e3bfa20d7c8a40113b74b7a'
                                                      }.pdf`,
                                                },
                                            });
                                        }
                                }
                                style={{ marginBottom: 12 }}>
                                link.program_memo_link
                            </Link>
                        </Section>
                    ) : null}
                    <Section title="titles.insuranceBalance" capitalize>
                        <List isLoading={isContractLoading} data={mainLimit} renderItem={ListItem} />
                    </Section>
                    {!partialLimits.length ? null : (
                        <Section title="titles.insuranceBalanceParts" capitalize>
                            <List data={partialLimits} renderItem={ListItem} />
                        </Section>
                    )}
                </View>
            ) : null}
        </Page>
    ),
    { userInfo: { from: getUserInfo } },
);
