import { Platform, TextInput as TextInputRN, TouchableWithoutFeedback } from 'react-native';
import { TextInputMask } from 'react-native-masked-text';
import { useRef } from 'react';
import { input as st } from '../styles/input';
import { React, Text, View } from './react';

export const placeholderTextColor = '#B8B8B8';

export const Field = ({
    children,
    title = '',
    ns = title || '',
    style,
    titleStyle,
    required,
    capitalize,
    obj,
    headerHint = required ? '' : Object.R('titles.not_mandatory'),
}) => (
    <View accessibilityLabel={`${ns}-text`} style={[st.field, style]}>
        {title ? (
            <Text style={[st.fieldCaption, titleStyle]}>
                {capitalize
                    ? `${Object.R(title, obj)} ${headerHint}`?.toUpperCase?.()
                    : `${Object.R(title, obj)} ${headerHint}`}
            </Text>
        ) : null}
        {children}
    </View>
);

export const InputWrapper = ({ children }) => (Platform.OS === 'web' ? <TouchableWithoutFeedback>{children}</TouchableWithoutFeedback> : children);

export const TextInput = React.memo(
    ({
        ns = '',
        onChange,
        onChangeNative,
        value,
        invalid,
        placeholder = '',
        hideText = false,
        disabled,
        multiline = false,
        style,
        textStyle,
        ...props
    }) => {
        if (disabled) {
            return (
                <View style={[st.input, st.disabled, style]}>
                    <Text style={textStyle}>{value}</Text>
                </View>
            );
        }
        return (
            <InputWrapper>
                <TextInputRN
                    onChangeText={onChange}
                    onChange={onChangeNative}
                    value={value}
                    style={[
                        st.input,
                        style,
                        textStyle,
                        invalid && value ? st.invalid : null,
                        Platform.OS === 'web' && {
                            outline: 'none',
                            cursor: 'text',
                        },
                    ]}
                    accessibilityLabel={`input:${ns}`}
                    underlineColorAndroid="transparent"
                    placeholder={Object.R(placeholder)}
                    secureTextEntry={hideText}
                    placeholderTextColor={placeholderTextColor}
                    numberOfLines={1}
                    multiline={multiline}
                    {...props}
                />
            </InputWrapper>
        );
    },
);

TextInput.Number = props => <TextInput ns="number" keyboardType="number-pad" {...props} />;

TextInput.MaskedNumber = ({
    ns = 'phone',
    style,
    mask = '(99) 999 99 99',
    placeholder = '(__) ___ __ __',
    onChangeText,
    value,
    disabled,
    onKeyPress,
    onSelectionChange,
    selection,
}) => {
    const ref = useRef();
    return disabled ? (
        <View style={[st.input, st.disabledPhonePrefix, style]}>
            <Text>{value}</Text>
        </View>
    ) : (
        <InputWrapper>
            <TextInputMask
                accessibilityLabel={`input:${ns}`}
                type="custom"
                style={[st.input, style, Platform.OS === 'web' && { outline: 'none' }]}
                options={{ mask }}
                placeholder={placeholder}
                keyboardType="phone-pad"
                textContentType="telephoneNumber"
                onChangeText={onChangeText}
                value={value}
                refInput={(r) => {
                    ref.current = r;
                }}
                onKeyPress={(e) => {
                    !!onKeyPress && onKeyPress(e, ref);
                }}
                onSelectionChange={onSelectionChange}
                selection={selection}
            />
        </InputWrapper>
    );
};

export const TextArea = ({
    ns = '',
    onChange,
    value,
    invalid,
    placeholder = '',
    disabled,
    hideText = false,
    style,
    onFocus,
}) => {
    if (disabled) {
        return (
            <View style={[st.inputTextAreaDisabled]}>
                <Text>{value}</Text>
            </View>
        );
    }
    return (
        <InputWrapper>
            <TextInputRN
                numberOfLines={3}
                onChangeText={onChange}
                value={value}
                style={[st.inputTextArea, invalid ? st.invalid : null, style]}
                accessibilityLabel={`textarea${ns ? `:${ns}` : ''}`}
                underlineColorAndroid="transparent"
                placeholder={Object.R(placeholder)}
                secureTextEntry={hideText}
                placeholderTextColor={placeholderTextColor}
                multiline
                onFocus={onFocus}
            />
        </InputWrapper>
    );
};
