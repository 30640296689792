import {
    Button, ButtonsGroup, Component, Field, Modal, React, TextArea,
} from '../../common';
import { invoiceItem as s } from './style';

export class InvoiceRejectModal extends Component {
    state = { rejectedMessageText: '' };

    render() {
        const { rejectedMessageText } = this.state;
        const {
            closeModal,
            id,
            updateRejectedInfo,
        } = this.props;
        const rejectInfo = {
            rejectedRecordId: id,
            rejectionComment: rejectedMessageText,
        };

        return (
            <Modal title="titles.your_comment_required">
                <Field ns="title-text">
                    <TextArea
                        placeholder="placeholder.rejected_this_service"
                        onChange={t => this.setState({ rejectedMessageText: t })}
                        value={rejectedMessageText}
                    />
                </Field>
                <ButtonsGroup gap={0} style={{ paddingHorizontal: 0 }}>
                    <Button
                        title="titles.cancel"
                        styles={[s.invoiceButton, { marginTop: 12 }]}
                        titleStyles={{ color: '$darkGrayTextColor' }}
                        action={closeModal}
                    />
                    <Button
                        title="buttons.send"
                        styles={[s.invoiceButton, { paddingVertical: 0 }]}
                        primary={rejectedMessageText.length > 0}
                        disabled={rejectedMessageText.length === 0}
                        action={() => closeModal(() => updateRejectedInfo([rejectInfo]))}
                    />
                </ButtonsGroup>
            </Modal>
        );
    }
}
