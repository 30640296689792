import { useDispatch } from 'react-redux';
import { useRoute } from '@react-navigation/native';
import { useEffect } from 'react';
import { setFormData } from '../actions/forms';
import { back } from '../actions';

export const useStdBackNavigation = (
    {
        routeName = undefined, logName, callback = () => {}, dependency,
    } = {
        routeName: undefined,
        callback: () => {},
        dependency,
    },
) => {

    const dispatch = useDispatch();
    const route = useRoute();
    useEffect(() => {

        dispatch(
            setFormData({
                [routeName ?? route?.name]: {
                    logName,
                    back: () => {

                        const res = callback();
                        if (res === false) {

                            return;

                        }
                        back();

                    },
                },
            }),
        );

    }, [dispatch, route?.name, routeName, logName, dependency]);

};
