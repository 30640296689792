import { React, RText } from '../common';
import { useStoredValue } from '../services/localstorage';
import { formatDate } from '../utils';

/**
 * Shows date of Last Update of Firebase data.
 */
export const DbLastUpdateDate = React.memo(() => {
    const { lastMax } = useStoredValue('clinics', {});
    const date = formatDate(lastMax);
    return <RText centered style={{ fontSize: 10 }} id="hints.home" params={{ date }}/>;
});
