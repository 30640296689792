/* eslint-disable no-underscore-dangle */
import {
    Button, ButtonsGroup, Component, Page, React, Section, Subtitle, View,
} from '../../common';
import { back } from '../../actions';
import { InvoiceCard } from './InvoiceCard';
import { InvoiceSingleService } from './InvoiceSingleService';
import { InvoiceAdaptiveButton } from './InvoiceAdaptiveButton';
import { invoiceItem as s } from './style';

// const rejectInfo = {
//     rejectedRecordId: id,
//     rejectionComment: rejectedMessageText,
// };

export class InvoiceMultiService extends Component {
    state = {
        approvedIds: this.props.route.params?.approvedIds ? this.props.route.params.approvedIds : [],
        rejectedIds: this.props.route.params?.rejectedIds ? this.props.route.params.rejectedIds : [],
    };

    getRejectedRecords() {
        const { rejectedIds } = this.state;
        const { route: { params: { records } } } = this.props;
        return rejectedIds.reduce((acc, id) => {
            acc.push(records.find(item => item._id === id));
            return acc;
        }, []);
    }

    getStateButton = () => {
        const {
            approvedIds,
            rejectedIds,
        } = this.state;
        const { route: { params: { records } } } = this.props;
        const buttonState = {
            '': records.length !== (approvedIds.length + rejectedIds.length),
            approved: records.length === approvedIds.length,
            rejected: records.length === rejectedIds.length,
            partly: records.length === (approvedIds.length + rejectedIds.length) && approvedIds.length > 0 && rejectedIds.length > 0,
        };
        return Object.entries(buttonState)
            .find(item => item[1] === true)[0];
    };


    render() {
        const {
            navigation: {
                replace,
                state: {
                    params,
                    params: {
                        records,
                        processingInvoice,
                    },
                },
            },
        } = this.props;
        // console.log('---------------- InvoiceMultiService, params', params);
        const {
            approvedIds,
            rejectedIds,
        } = this.state;
        const rejectedRecords = this.getRejectedRecords();
        const rejectedMessageText = rejectedRecords.length > 0 ? rejectedRecords[0].rejectionComment : '';
        const stateButton = this.getStateButton();
        const invoiceConfirmationNavParams = {
            approvedIds,
            rejectedIds,
            rejectedRecords,
            rejectedMessageText,
            processingInvoice,
            back,
            stateButton,
            pressedType: stateButton,
        };
        return (
            <Page
                backgroundColor="#f2f2f2"
                footer={
                    <InvoiceAdaptiveButton
                        stateButton={stateButton}
                        action={() => {
                            if (stateButton === 'approved') {
                                back();
                                processingInvoice(approvedIds, [], 'approved');
                            } else {
                                replace('InvoiceConfirmation', invoiceConfirmationNavParams);
                            }
                        }}
                    />
                }
            >
                <Section>
                    <InvoiceCard item={params} forMultiService/>
                    <Subtitle id="titles.payment_details" bold style={{
                        marginTop: 24,
                        marginBottom: 20,
                    }}/>
                    {
                        records
                            .map(item => (
                                <View key={item._id}>
                                    <InvoiceSingleService {...item} key={item._id} forMultiService/>
                                    <InvoiceButtonGroupMulti
                                        _id={item._id}
                                        updateApprovedId={this.updateApprovedId}
                                        approvedIds={approvedIds}
                                        recordStatus={item.status || false}
                                    />
                                </View>
                            ))
                    }
                </Section>
            </Page>
        );
    }
}

/* eslint-disable react/no-multi-comp */
export class InvoiceButtonGroupMulti extends Component {
    state = { pressed: this.props.recordStatus };

    render() {
        const {
            _id,
            updateApprovedId,
        } = this.props;
        const { pressed } = this.state;
        return (
            <ButtonsGroup gap={0} style={[s.border, {
                marginBottom: 24,
                paddingHorizontal: 0,
            }]}>
                <Button
                    title="ОТКЛОНИТЬ"
                    styles={[s.invoiceButton, s.borderRight, (pressed === 'rejected' || pressed === 'REJECTED') && s.redBackground]}
                    titleStyles={pressed === 'rejected' || pressed === 'REJECTED' ? s.whiteColor : s.redColor}
                    action={() => {
                        updateApprovedId(_id, 'rejected');
                        this.setState({ pressed: 'rejected' });
                    }}
                />
                <Button
                    title="ПОДТВЕРДИТЬ"
                    styles={[s.invoiceButton, (pressed === 'approved' || pressed === 'APPROVED') && s.greenBackground]}
                    titleStyles={pressed === 'approved' || pressed === 'APPROVED' ? s.whiteColor : s.greenColor}
                    action={() => {
                        updateApprovedId(_id, 'approved');
                        this.setState({ pressed: 'approved' });
                    }}
                />
            </ButtonsGroup>
        );
    }
}
