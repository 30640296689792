import { getIncludeAnalogs } from '../selectors';
import { React, TouchableOpacity, View } from './react';
import { RText } from './elements';
import { yesNo as style } from './style';
import { dataFrom } from './_hoc';


function applyActiveStyle(isActive, styl) {
    return isActive ? styl : {};
}

export const YesNo = dataFrom(getIncludeAnalogs, ({
    data,
    onChange,
}) => (
    <View style={style.btnsWrapper}>
        <TouchableOpacity
            onPress={() => onChange(true)}
            style={[style.button, style.buttonLt, applyActiveStyle(data, style.buttonActive)]}
        >
            <RText style={[style.buttonTxt, applyActiveStyle(data, style.buttonActiveTxt)]} id="buttons.yes"/>
        </TouchableOpacity>
        <TouchableOpacity
            onPress={() => onChange(false)}
            style={[style.button, style.buttonRt, applyActiveStyle(!data, style.buttonActive)]}
        >
            <RText style={[style.buttonTxt, applyActiveStyle(!data, style.buttonActiveTxt)]} id="buttons.no"/>
        </TouchableOpacity>
    </View>
));
