/* eslint-disable no-use-before-define */
import {
    BigButton,
    Keyboard,
    LoadingIndicator,
    Logo,
    Page,
    Platform,
    React,
    showNotification,
    Text,
    TextInput,
    Title,
    View,
} from '../../common';
import { getShowNewUserAgreementModal, getUserInfo } from '../../selectors';
import epam from '../../services/epam';
import { Agreements } from '../../common/Agreemants';
import api from '../../services/api';

const ProgramLoadingIndicator = () => (
    <View style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    }}>
        <LoadingIndicator style={{ paddingVertical: 8 }} indicatorSize={Platform.OS === 'ios' ? 'large' : 50}/>
        <Title.Bigger style={{
            textAlign: 'center',
            paddingHorizontal: 12,
        }} id="titles.profileUpdatingHint"/>
    </View>
);

/**
 * EPAM SignIn page.
 */
export const EpamSignIn = Page.register(
    ({
        navigation,
        setState,
        userInfo: {
            emails = [],
            me = {},
        },
        epamEmail = emails.find(userEmail => userEmail && userEmail.confirmed && userEmail.address.endsWith('@epam.com')),
        previousMail = emails.find(({
            usage,
            address,
        }) => usage !== 'PRIMARY' && address.endsWith('@epam.com')) || epamEmail || {},
        email = previousMail.address || '',
        error,
        inputError,
        isLoading,
        isAgree = false,
        newAgreement,
        onSubmitEmail = async (mail) => {
            const checkResult = checkEmail(mail);
            if (checkResult) {
                setState({ inputError: checkResult });
            } else {
                try {
                    await epam.register(mail, previousMail, result => handleRegisterResult(result, Object.R('titles.programConfirmationEmailWasSent', { address: mail })));
                } finally {
                    Keyboard.dismiss();
                }
            }
        },
        handleRegisterResult = (result, successMessage) => {
            if (result.success || (result.error && result.error.code === 'CONFIRMATION_REQUIRED')) {
                if (!me?.consentReceived || !newAgreement) {
                    api.updateUserInfo({ consentReceived: true });
                }
                onConfirmationSent(successMessage);
            } else if (result.error?.code === 'CONTRACT_MISSMATCH') {
                setState({
                    ...result,
                    error: { message: Object.R('error.CONTRACT_MISSMATCH', { name: result.error.body.attributes.contract }) },
                });
            } else if (result.error?.code === 'CONTRACT_CLOSED') {
                setState({
                    ...result,
                    error: {
                        message: Object.R('error.CONTRACT_CLOSED', { name: result.error.body.attributes.contract }),
                        code: result.error?.code,
                    },
                });
            } else {
                setState(result);
            }
        },
        checkEmail = (value) => {
            if (!value || !value.length) {
                return null;
            }
            const emailRegexp = /^([A-Z0-9_%+-]*(\.(?!\.))?[A-Z0-9_%+-]*)+@epam.com$/i;
            return emailRegexp.test(value) ? null : Object.R('error.epam_email_format_error');
        },
        onConfirmationSent = (message) => {
            navigation.navigate('Home');
            showNotification(message, false);
        },
    }) => (
        <Page
            name="epam-signin"
            persistKeyboard
            notification={error && {
                ...error,
                onSubmit: () => onSubmitEmail(email),
            }}
        >
            {isLoading ? <ProgramLoadingIndicator/>
                : <View style={{
                    paddingBottom: 20,
                    paddingHorizontal: 12,
                }}>
                    <Logo.Wrapper
                        title="titles.enterEpamEmail"
                        subtitle="titles.enterEpamEmailHint"
                        wrapperStyle={{ marginTop: 24 }}
                        titleStyle={{
                            marginTop: 32,
                            marginBottom: 8,
                            fontFamily: 'SourceSansPro-Semibold',
                        }}
                    >
                        <TextInput
                            placeholder="placeholders.epamEmail"
                            style={{
                                fontSize: 14,
                                color: '#555555',
                            }}
                            onChange={(value) => {
                                setState({ email: value });
                            }}
                            invalid={inputError}
                            onBlur={() => setState({ inputError: checkEmail(email) })}
                            value={email}
                            keyboardType="email-address"
                            caretHidden={false}
                        />
                        {inputError && <Text style={{ color: '#DF655C' }}>{inputError}</Text>}
                        <Agreements userAgreement={!me?.consentReceived}
                            personalDataAgreement={!me?.consentReceived && newAgreement}
                            onChange={agree => setState({ isAgree: agree })}/>
                        <BigButton disabled={!email.length || inputError || (!isAgree && !me?.consentReceived)}
                            action={() => onSubmitEmail(email)} title="buttons.send"/>
                    </Logo.Wrapper>
                </View>
            }
        </Page>
    ),
    {
        userInfo: { from: getUserInfo },
        newAgreement: { from: getShowNewUserAgreementModal },
    },
);
