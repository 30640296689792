import EStyleSheet from 'react-native-extended-stylesheet';

const itemPadding = 14;

export const sectionStyle = EStyleSheet.create({
    item: {
        paddingTop: itemPadding,
        paddingHorizontal: 16,
    },
    itemDocContainer: {
        borderColor: '#E9E9E9',
        paddingBottom: itemPadding,
        flexDirection: 'row',
        alignItems: 'center',
    },
    itemContainer: {
        borderColor: '#E9E9E9',
        paddingBottom: itemPadding,
    },
});
