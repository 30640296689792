import { useNavigationState } from '@react-navigation/native';
import { useEffect, useState } from 'react';

export const getRouteHistory = (navigator, qty = 3) => {
    const result = [];
    if (!(navigator.routes || Array.isArray(navigator.routes))) {
        return result.join('->');
    }

    const { routes } = navigator;
    const { length } = routes;
    let num = 0;
    for (let i = length - 1; i >= 0; i--) {
        result.push(routes[i].name);
        num += 1;
        if (num >= qty) {
            break;
        }
    }
    return result.join('->');
};

export const getNavHistory = (navigator, qty = 3) => {
    const result = [];
    if (!(navigator.routes || Array.isArray(navigator.routes))) {
        return result;
    }

    const { routes } = navigator;
    const { length } = routes;
    let num = 0;
    for (let i = length - 1; i >= 0; i--) {
        result.push(routes[i].name);
        num += 1;
        if (num >= qty) {
            break;
        }
    }
    return result;
};

export const useNavHistory = (filter, deep) => {
    const nav = useNavigationState(state => state);
    const [history, setHistory] = useState([]);
    useEffect(() => {
        let h = getNavHistory(nav);
        if (filter) {
            if (typeof filter === 'string') {
                h = h.filter(item => item !== filter);
            } else if (typeof filter === 'function') {
                h = h.filter(filter);
            } else if (Array.isArray(filter)) {
                h = h.filter(item => !filter?.includes?.(item));
            }
        }
        if (typeof deep === 'number') {
            h = h.slice(0, deep);
        }
        setHistory(h);
    }, [nav]);
    return history;
};

export const getCurrentRoute = (navigator, lower = false) => {
    if (!(navigator.routes || Array.isArray(navigator.routes))) {
        return '';
    }
    const { routes } = navigator;
    const { length } = routes;
    const route = routes[length - 1].name ?? '';
    return lower ? route.toLowerCase() : route;
};
