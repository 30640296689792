import React, { useEffect, useRef, useState } from 'react';
import { ScrollView, Platform, Button, Title, ButtonsGroup } from '../../common';
import { CommonPageContainer } from '../../common/Page.js';
import actions, { back } from '../../actions';
import api from '../../services/api';
import { View } from '../../common';
import { AgreemantCheckbox } from '../Visits/snippets/NewVisitModal/snippets.js';
import { renderRelativePanel } from '../Account/snippets.js';
import { useBackHandler } from '../../utils/routing';
import { useNavigation } from '@react-navigation/native';
import { syncUserState } from '../../init';

const ProfileItem = renderRelativePanel(false);

export const ProfilesListItem = ({ item, onSelect }) => {
    const [isSelected, setIsSelected] = useState(false);
    const onPress = () => {
        setIsSelected(!isSelected);
        onSelect({ item, isSelected: !isSelected });
    };
    return (
        <View style={{ marginBottom: 16 }}>
            {item?.name && item?.birthday ? <ProfileItem item={item} withEditBadge={false} readOnly /> : null}
            <AgreemantCheckbox
                title={
                    item?.isMainProfile
                        ? Object.R('titles.personalDataAgreement')
                        : Object.R('titles.childDataAgreement')
                }
                selected={isSelected}
                checkboxStyle={{
                    marginTop: 2,
                    marginRight: 6,
                }}
                onPress={onPress}
                style={{ marginBottom: Platform.OS === 'web' ? 12 : 0, marginTop: 12 }}
            />
        </View>
    );
};

const getProfilesObj = (dataArr = []) => {
    return dataArr.reduce((acc, item) => {
        acc[item.id] = false;
        return acc;
    }, {});
};

const sortByMainProfile = (arr = []) => {
    return [...arr].sort((a, b) => (b.isMainProfile === true) - (a.isMainProfile === true));
};

const updateConsentOnProfiles = async (data) => {
    for (const { id, isMainProfile } of data) {
        if (isMainProfile) {
            await api.updateUserInfo({ consentReceived: true });
        } else {
            await api.updateChild({
                consentReceived: true,
                _id: id,
            });
        }
    }
};

export const ConsentConfirmation = () => {
    const data = sortByMainProfile(Object.profilesWithoutConsent);
    const hasMainProfile = data?.some((e) => e.isMainProfile);

    const [selectedProfiles, setSelectedProfiles] = useState(getProfilesObj(data));
    const [isMainProfileHasConsent, setIsMainProfileHasConsent] = useState(!hasMainProfile);

    const navigation = useNavigation();
    const canLeave = useRef(false);

    const isAllChildConsentsChecked = Object.values(selectedProfiles).every((value) => value === true);
    const isAllChecked = isAllChildConsentsChecked && isMainProfileHasConsent;

    useBackHandler(() => true);

    // Prevent "back" navigation on iOS via swipe gesture to enforce navigation only through buttons
    useEffect(() => {
        navigation.setOptions({
            gestureEnabled: false,
        });
    }, [navigation]);
    useEffect(() => {
        const unsubscribe = navigation.addListener('beforeRemove', (e) => {
            if (!canLeave.current) {
                e.preventDefault();
            }
        });

        return unsubscribe;
    }, [navigation]);

    const onSelect = ({ item, isSelected }) => {
        const updatedObject = { ...selectedProfiles, [item?.id]: isSelected };
        setSelectedProfiles(updatedObject);
    };
    const onLogout = async () => {
        canLeave.current = true;
        Object.profilesWithoutConsent = null;
        back();
        await actions.logOut();
        if (Object.callbackOnSignIn) {
            Object.callbackOnSignIn = null;
            back();
        }
    };
    const onConfirm = async () => {
        canLeave.current = true;
        Object.profilesWithoutConsent = null;
        back();
        await updateConsentOnProfiles(data);
        // await actions.reloadUserInfo(() => Object.handleNewUserItemsFound, actions.updatePrefs, actions.updateSelects);
        await syncUserState();
        // if (Object.callbackOnSignIn && data?.find((e) => e?.isMainProfile && e?.name)?.id) {
        //     console.log('onConfirm back');
        //     back();
        // }
    };

    return (
        <CommonPageContainer>
            <ScrollView
                enableOnAndroid
                enableAutomaticScroll
                accessibilityLabel="scroll"
                extraScrollHeight={Platform.OS === 'ios' ? 60 : 10}
                style={{
                    height: Platform.OS === 'web' ? 0 : undefined,
                    flex: 1,
                }}
                contentContainerStyle={{ flexGrow: 1, marginHorizontal: 12 }}>
                <View style={{ alignItems: 'center', marginTop: 40, marginBottom: 20, width: '100%' }}>
                    <Title bold>{Object.R('titles.usingTheApplication')}</Title>
                    <Title numberOfLines={null} style={{ marginTop: 12 }}>
                        {Object.R('titles.usingTheApplicationHint')}
                    </Title>
                    {hasMainProfile ? (
                        <AgreemantCheckbox
                            title={Object.R('titles.userAgreement')}
                            selected={isMainProfileHasConsent}
                            checkboxStyle={{
                                marginTop: 2,
                                marginRight: 6,
                            }}
                            onPress={() => setIsMainProfileHasConsent(!isMainProfileHasConsent)}
                            style={{ marginBottom: Platform.OS === 'web' ? 12 : 0, marginTop: 20, width: '100%' }}
                        />
                    ) : null}
                </View>
                {data?.map((item) => (
                    <ProfilesListItem item={item} onSelect={onSelect} />
                ))}
            </ScrollView>
            <ButtonsGroup
                style={{
                    paddingBottom: 12,
                    borderTopWidth: 1,
                    borderTopColor: '#F0F0F0',
                }}>
                <Button
                    transparent
                    action={onLogout}
                    title="buttons.exit"
                    titleStyles={{
                        fontSize: 14,
                        fontWeight: '900',
                        fontFamily: 'SourceSansPro-Semibold',
                    }}
                />
                <Button
                    primary
                    action={onConfirm}
                    title="buttons.continue"
                    disabled={!isAllChecked}
                    style={{
                        fontSize: 14,
                        fontWeight: '900',
                        fontFamily: 'SourceSansPro-Semibold',
                    }}
                />
            </ButtonsGroup>
        </CommonPageContainer>
    );
};
