import { React, Title, View } from '../../common';
import { invoiceItem as s } from './style';

export const InvoiceNotification = (props) => {
    const { pressedState } = props;
    let options;
    if (pressedState && pressedState !== '') {
        const optionsList = {
            approved: {
                title: 'titles.confirmed',
                style: s.approvedNotification,
            },
            rejected: {
                title: 'titles.rejected',
                style: s.rejectedNotification,
            },
            partly: {
                title: 'titles.confirmed_partially',
                style: s.partlyNotification,
            },
        };
        options = optionsList[pressedState];
    }
    return pressedState === '' || !options ? null : (
        <View style={[s.notificationCommon, options.style]}>
            <Title id={options.title} style={s.notificationCommonTitle}/>
        </View>
    );
};
