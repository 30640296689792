/* eslint-disable class-methods-use-this */
import EStyleSheet from 'react-native-extended-stylesheet';
import {
    dateLocales, formatTimeslots, getIsoFormattedDate, getNewAdjustedDate, isDayNotPast,
} from '../utils';
import {
    Component, React, Text, TouchableOpacity, View,
} from './react';
import { COLORS, datePickerCustom as styles } from './style';
import { RText, Title, VBox } from './elements';
import { Button } from './Button';
import { Icon } from './Icon';

const daysShortNames = [{
    name: 'days.short.mon',
    we: false,
}, {
    name: 'days.short.tue',
    we: false,
}, {
    name: 'days.short.wed',
    we: false,
}, {
    name: 'days.short.thu',
    we: false,
}, {
    name: 'days.short.fri',
    we: false,
}, {
    name: 'days.short.sat',
    we: false,
}, {
    name: 'days.short.sun',
    we: true,
}];

const Header = ({ title }) => (
    <View style={styles.header}>
        {/* <Icon awesome name="arrow-circle-o-left" /> */}
        <Title id={title} bold style={styles.headerTitle}/>
        {/* <Icon awesome name="arrow-circle-o-right" /> */}
    </View>
);

const DaysHeader = () => (
    <View style={{
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
    }}>
        {daysShortNames.map(({
            we,
            name,
        }) => {
            const textStyle = we ? [styles.daysHeaderText, styles.weekendText] : styles.daysHeaderText;
            return (<DayItem value={name} style={styles.disabled} textStyle={[textStyle]} key={name}/>);
        })}
    </View>
);

const DayItem = ({
    value,
    style,
    textStyle,
    onPress,
    date,
    first,
}) => (
    <Button styles={[styles.day, style]} action={onPress ? () => onPress(date) : null} trackingAlias="datepick">
        <VBox centered>
            {first && <RText id={first} style={[textStyle, styles.textSize]}/>}
            <RText style={[textStyle, styles.textSize]} id={value}/>
        </VBox>
    </Button>
);

export const TapBar = ({
    onPress,
    close,
}) => (
    <Button styles={styles.tapBar} action={onPress}>
        {close ? <Icon awesome name="angle-down" onPress={onPress}/>
            : <Icon awesome name="angle-up" onPress={onPress}/>}
    </Button>
);

const tapStyles = EStyleSheet.create({
    sectionHeaderText: {
        paddingRight: 4,
        fontSize: 12,
        fontFamily: '$sourceSansPropFontFamily_semibold',
        color: COLORS.MAIN,
    },
});

export const TapBarText = ({
    onPress,
    close,
    openText,
    closeText,
}) => (
    <View styles={{ borderWidth: 1 }}>
        <TouchableOpacity
            style={{ margin: 4 }}
            onPress={onPress}>
            <View style={{ flexDirection: 'row' }}>
                <Text
                    style={[{ color: COLORS.MAIN }, tapStyles.sectionHeaderText]}>
                    {!close ? closeText : openText}
                </Text>
                <View style={{
                    marginTop: 2,
                    marginLeft: 0,
                    marginRight: 10,
                }}>
                    {!close
                        ? (
                            <Icon.Up size={12} color={COLORS.MAIN}/>
                        )
                        : (
                            <Icon.Down size={12} color={COLORS.MAIN}/>
                        )
                    }
                </View>
            </View>
        </TouchableOpacity>
    </View>
);

const SevenElements = ({
    arrayForRender,
    onPress,
    withoutTimeslots,
}) => (
    <View style={{
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
    }}>
        {arrayForRender.map((item) => {
            // TODO too complex
            const date = getNewAdjustedDate(item[0]);
            const {
                avaliable,
                selected,
                enabled,
                inSelectedArea,
            } = item[1];

            const style = [!avaliable && ((inSelectedArea && styles.selectedArea) || styles.disabled)];
            const textStyle = [date.getDay() === 0 ? styles.weekendText : styles.text];
            if (selected) {
                style.push(styles.selected);
                textStyle.push(styles.selectedText);
            } else {
                if (!enabled) {
                    textStyle.push(date.getDay() === 0 ? styles.weekendText : styles.disabledText);
                }
                if (item[0] === getIsoFormattedDate(getNewAdjustedDate())) {
                    textStyle.push(styles.todayText);
                }
            }

            const value = date.getDate();
            const shortMonthName = value === 1 && dateLocales.ru.bookMonthNamesShort[date.getMonth()].toLowerCase();

            return (<DayItem value={value} style={style} textStyle={textStyle}
                onPress={avaliable || (withoutTimeslots && enabled) ? onPress : null} date={item[0]}
                first={shortMonthName} key={value}/>);
        })}
    </View>
);

const Days = ({
    list,
    close,
    onPress,
    withoutTimeslots,
}) => {
    const arrayFromObj = Object.entries(list);
    const arrOfWeeks = [];
    for (let i = 0; i < arrayFromObj.length; i += 7) {
        arrOfWeeks.push(arrayFromObj.slice(i, i + 7));
    }

    if (close && arrOfWeeks.length > 0) {
        return <SevenElements arrayForRender={arrOfWeeks[0]} onPress={onPress} withoutTimeslots={withoutTimeslots}/>;
    } else if (!close && arrOfWeeks.length > 0) {
        return arrOfWeeks.map(item => (
            <SevenElements arrayForRender={item} onPress={onPress} withoutTimeslots={withoutTimeslots}/>));
    }
    return null;
};

export class DatePicker extends Component {
    state = {
        close: !this.props.open,
        userTouch: false,
    };

    getMonthName = date => (!date ? '' : dateLocales.ru.monthNames[getNewAdjustedDate(date)
        .getMonth()]);

    getFullYear = date => (!date ? '' : getNewAdjustedDate(date)
        .getFullYear());

    getMondayDateOfCurrWeek() {
        const today = getNewAdjustedDate();
        const weeksDayNumber = today.getDay();
        const monday = today.getDate() - (weeksDayNumber === 0 ? 6 : weeksDayNumber - 1);
        return this.createDateByNumber(monday);
    }

    getSundayDate(date) {
        const sunday = date.getDate() + (7 - (date.getDay()));
        return date.getDay() === 0 ? date : this.createDateByNumber(sunday, date);
    }

    getEndArrNumber(firstDate, lastDate) {
        const countOfDays = (((lastDate.getTime() - firstDate.getTime()) / (86400 * 1000)) + 1);
        return countOfDays < 28 ? firstDate.getDate() + 27 : firstDate.getDate() + (countOfDays - 1);
    }

    get28Dates(lastDate) {
        const allDates = {};
        if (lastDate) {
            const firstDay = this.getMondayDateOfCurrWeek()
                .getDate();
            const endArrNumber = this.getEndArrNumber(this.getMondayDateOfCurrWeek(), this.getSundayDate(getNewAdjustedDate(lastDate)));

            for (let i = firstDay; i <= endArrNumber; i++) {
                const date = this.createDateByNumber(i, this.getMondayDateOfCurrWeek());
                allDates[getIsoFormattedDate(date)] = {
                    avaliable: false,
                    enabled: isDayNotPast(date),
                };
            }
        }
        return allDates;
    }

    get28DatesForTimeslots() {
        const {
            timeslots,
            timezone,
        } = this.props;
        const timeslotsDates = Object.keys(formatTimeslots(timeslots, timezone));
        const lastTimeSlotDate = timeslotsDates[timeslotsDates.length - 1];

        return this.get28Dates(lastTimeSlotDate);
    }

    get28DatesForCalendar() {
        const today = new Date();
        const lastDate = today.setMonth(today.getMonth() + 1);
        return this.get28Dates(lastDate);
    }

    createDateByNumber(number, date) {
        const dateIn = date || getNewAdjustedDate();
        return getNewAdjustedDate(dateIn.getFullYear(), dateIn.getMonth(), number);
    }

    datesWithState() {
        const {
            timeslots,
            value,
            initState,
            timezone,
        } = this.props;
        const {
            close,
            userTouch,
        } = this.state;
        const dates = this.get28DatesForTimeslots();
        const timeslotsDates = Object.keys(formatTimeslots(timeslots, timezone));

        timeslotsDates.forEach(item => (!dates[item] ? null : Object.assign(dates[item], { avaliable: true })));

        Object.keys(dates)
            .forEach(item => (item !== value ? null : Object.assign(dates[item], { selected: true })));

        if (initState !== undefined && !userTouch && close !== initState) {
            this.setState({ close: initState });
        }
        return dates;
    }

    DatesWithStateForCalendar() {
        const {
            dateStart,
            dateEnd,
        } = this.props;

        const dates = this.get28DatesForCalendar();
        Object.keys(dates)
            .forEach(item => (Object.assign(dates[item], {
                selected: (item === dateStart) || (item === dateEnd),
                inSelectedArea: (new Date(item).valueOf() > new Date(dateStart).valueOf() && new Date(item).valueOf() < new Date(dateEnd).valueOf()),
            })));
        return dates;
    }

    changeState(close) {
        this.setState({
            close: !close,
            userTouch: true,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.userTouchEvent !== this.props.userTouchEvent) {
            this.setState({ userTouch: false });
        }
    }

    render() {
        const { close } = this.state;
        const {
            value,
            handleChooseDate,
            withoutTimeslots = false,
            dateStart,
            style,
        } = this.props;

        const list = withoutTimeslots ? this.DatesWithStateForCalendar() : this.datesWithState();
        const element = Object.entries(list).findIndex(([, val]) => val.selected);
        const needOpen = element > 6;

        const isClose = needOpen && !this.state.userTouch ? false : close;

        return (
            <View style={[styles.mainContainer, style]}>
                <Header
                    title={`${this.getMonthName(value || dateStart || new Date())} ${this.getFullYear(value || dateStart || new Date())}`}/>
                <View style={{ marginHorizontal: 8 }}>
                    <DaysHeader/>
                    <Days list={list}
                        close={isClose} onPress={handleChooseDate}
                        withoutTimeslots={withoutTimeslots}/>
                </View>
                <TapBar onPress={() => this.changeState(isClose)}
                    close={isClose}/>
            </View>
        );
    }
}
