import {
    BigButton, Logo, Page, React, Title,
} from '../../common';
import { getUserLoadingState } from '../../selectors';
import { AppHeader } from '../../combo';
import { storage } from '../../services/localstorage';
import { back, replace } from '../../actions';
import { syncUserState } from '../../init';
import { useBackHandler } from '../../utils/routing';
import { initUserProfile } from '../../services/user.utils';

export const AccountReset = Page.register(
    ({
        route: {
            params: {
                navigatedFromScreen,
                deviceIdOfExistedUser,
            },
        },
        isLoading,
        setState,
        navigation,
        phonePrefix = '+375',
        phoneNumber = '',
        error,
        onDidMount = () => storage.getObject('phoneNumber', '')
            .then(phone => setState({ phoneNumber: phone })),
        onSubmit = async () => {
            const result = await initUserProfile(deviceIdOfExistedUser || true);
            if (result instanceof Error) {
                setState({ error: result });
            } else {
                await syncUserState();
                if (navigatedFromScreen === 'Account') {
                    navigation.replace('ChooseProgram');
                } else {
                    navigation.replace('Account');
                    navigation.navigate('ChooseProgram');
                }
            }
        },
    }) => {
        const backHandler = React.useCallback(() => (navigatedFromScreen === 'Account' ? back() : replace(navigatedFromScreen)), [navigatedFromScreen]);
        useBackHandler(backHandler);
        return (
            <Page
                name="reset-account"
                isLoading={isLoading}
                onDidMount={onDidMount}
                notification={error}
            >
                <Logo.Wrapper
                    wrapperStyle={{
                        marginTop: 16,
                        padding: 8,
                        alignItems: 'center',
                    }}
                >
                    <Title bold centered id="titles.creatingNewProfileForNumber" style={{
                        marginTop: 32,
                        fontSize: 18,
                    }}/>
                    <Title style={{ marginTop: 18 }}>{`${phonePrefix} ${phoneNumber}`}</Title>
                    <Title id="titles.userWithNumberWasRegistered" style={{
                        color: '#BBBBBB',
                        textAlign: 'center',
                        marginTop: 8,
                    }}/>
                    <Title id="titles.canCreateProfileIfNumberOwner" style={{
                        color: '#BBBBBB',
                        textAlign: 'center',
                        marginTop: 24,
                    }}/>
                    <BigButton title="buttons.createNewProfile" action={onSubmit} styles={{ marginTop: 36 }}/>
                </Logo.Wrapper>
            </Page>
        );
    },
    { isLoading: { from: getUserLoadingState } },
    ({
        navigation,
        route: { params = {} },
    }) => ({
        header: () => (<AppHeader
            navigation={navigation}
            backPress={() => (params.navigatedFromScreen === 'Account' ? back() : replace(params.navigatedFromScreen))}
        />),
    }),
);

