export default [
    {
        myPersonId: '1',
        personNameRu: 'Иван Иванович Иванов',
        programLimits: [
            {
                type: 'А',
                name: 'АМБУЛАТОРНАЯ',
                balance: '1500.50',
                currency: 'USD',
            },
        ],
    },
];