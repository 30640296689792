/* eslint-disable no-unused-vars */
// noinspection JSUnusedLocalSymbols

import { useEffect, useState } from 'react';
import 'react-native-gesture-handler';
import {
    Linking, NativeModules, PermissionsAndroid, Platform,
} from 'react-native';
// eslint-disable-next-line import/no-unresolved
import RNFetchBlob from 'react-native-blob-util';
import NetInfo from '@react-native-community/netinfo';
import './prepare';
// try to mock as soon as possible
// eslint-disable-next-line import/first
import 'aibolit-mock/setupIfParam';
import { getGlobalLocations, setLocale } from './i18n';
import tracking from './services/tracking';
import { storage } from './services/localstorage';
import { checkForMigration } from './services/migration';
import firebase from './services/firebase';
import Store, { putIntoStore } from './store';
import actions, { back, navigate, replace } from './actions';
import { checkDeviceId } from './services/device';
import { initDeepLinks, isInStandaloneMode } from './services/deeplinks';
import { SCHEME } from './scheme';
import { subscribeOnAppStateChanged } from './services/appstate';
import { initRemoteConfig } from './services/remoteconfig';
import { preparePrefilters } from './services/user.utils';
import { debouncer } from './utils';
import { notifications } from './common/Notifications';
import userServises from './services/user';
import { checkNotifModal } from './combo';
import { removeAllACodes } from './services/encryptedStorage';
import { getBasket } from './selectors';
import { getExactAlarmPermissions } from './utils/permissions';
import { Page } from './common';
import { NewVisitModal } from './screens/Visits/snippets/NewVisitModal/NewVisitModal';
import { AlreadyImportedModal } from './screens/Visits/snippets/NewVisitModal/AlreadyImportedModal';
// import { checkShowWNModal } from './combo';

/* global window */

let goToPage = null;
let goToParams = null;
let remoteConfigWaiting = null;

// eslint-disable-next-line import/no-mutable-exports
export const wasNotifPermAsked = {
    value: false,
    requestOK: null,
};

const initClinicsCollection = async () => {
    const clinicsKey = 'clinics';
    const { data = {} } = await storage.getObject(clinicsKey);
    actions.syncCollection(`${clinicsKey}.data`, { ...data });
};

const setLocation = async (location) => {
    if (!location) {
        return;
    }
    const locations = await getGlobalLocations();
    await actions.setLocation((locations.find(item => item.id === location) || { code: 17 }).code);
};

const appyLocationForPhoneLogin = async (user) => {
    const { location = '' } = user;
    if (location && location.length) {
        await setLocation(location);
    } else {
        const { location: selectedLocation } = await storage.getObject('selections');
        if (selectedLocation) {
            await actions.setLocation(selectedLocation);
        }
    }
};

let foundNewUserInfo = false;
let profileUnsubscribe = () => false;
let profileDataUnsubscribe = () => false;

const putCollectionIntoStoreCommon = (coll, data) => setTimeout(() => actions.syncCollection(`${coll}.data`, { ...data }), 7000);

const putCollectionIntoStore = (coll, data) => setTimeout(() => actions.syncCollection(`${coll}.data`, { ...data }), 3000);

// const forbidScreenshotsOnRoutes = () => {
//     const forbidPreview = ['Home', 'MyCard', 'SearchDrugs'];
//     const navigation = store.navigator;
//     let route;
//     try {
//         const state = navigation.dangerouslyGetState();
//         const currentScreen = navigation.getCurrentRoute();// state.routes[state.index].name;
//         route = currentScreen.name;
//     } catch (e) {
//         //
//         // console.log('Error in navigation', e);
//     }
//
//     if (route) {
//         if (forbidPreview.includes(route)) {
//             return true;
//         }
//     }
//     // forbidScreenShot();
//     allowScreenShot();
//     return false;
// };

const subscribeOnAppState = () => {
    const debounce = debouncer(5000);
    subscribeOnAppStateChanged({
        onActive: () => {
            firebase.updateSplitCollectionsChanges(putCollectionIntoStore);
            debounce(() => firebase.subscribeOnCollectionsChanges(
                putCollectionIntoStore,
                actions.stopCollectionFetching,
                actions.isAllCollectionFetching,
            ));
            putIntoStore('onBackground', false);
        },
        onInactive: () => {
            // forbidScreenshotsOnRoutes();
            userServises.resetAcodeTimeout();
            debounce(() => firebase.unsubscribeFromSplitCollectionsChanges());
            debounce(() => firebase.unsubscribeFromCollectionsChanges());
            putIntoStore('onBackground', true);
        },
        onBlur: () => {
            // forbidScreenshotsOnRoutes();
        },
        onFocus: () => {
            // allowScreenShot();
        },
    });
};

export const openDoctorFeedbackFromNotify = (notify, doctor, onOpen, onCancel) => {
    if (notify?.userInteraction) {
        const {
            doctorId,
            type,
            // eslint-disable-next-line no-unsafe-optional-chaining
        } = notify?.data;
        if (doctorId && type === 'feedback_reminder') {
            const feedback = {
                forItemId: doctorId,
                anonymous: false,
            };
            goToPage = 'AddDoctorFeedbacks';
            goToParams = {
                feedback,
                fromNotification: true,
                background: !notify?.foreground,
                needFetch: true,
            };
            // if (notify?.foreground) {
            try {
                actions.setFormValue('umsServices', []);
            } catch (e) {
                //
            }
            navigate(goToPage, goToParams);
            // goToParams = null;
            // }
            // notifications.cancelNotification(notify.id);
        } else if (onCancel) {
            onCancel();
        }
    } else if (onCancel) {
        onCancel();
    }
};

export const checkNotifications = (from = 'Init', onClose) => {
    notifications?.checkPermissions?.(() => {
        if (wasNotifPermAsked.value) {
            return;
        }
        wasNotifPermAsked.value = true;
        checkNotifModal(
            () => {
                storage.set('wasNPAsked', true);
                if (Platform.OS === 'android') {
                    const exactAlarm = getExactAlarmPermissions(true, false);
                    PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.POST_NOTIFICATIONS)
                        .then((granted) => {
                            notifications.createChannel();
                            notifications.createChannel('fcm_fallback_notification_channel', 'Общие оповещения');
                            if (Platform.Version < 33) {
                                NativeModules.SettingsModule.openAppNotificationsSettings();
                            }
                        })
                        .catch((err) => {
                            //
                        });
                } else if (Platform.OS === 'ios') {
                    Linking.openURL(`app-settings:`);
                }
            },
            () => {
                onClose && onClose?.();
            },
        );
    });
};

/* eslint-disable max-statements */
export const syncUserState = async (cb, isAfterSign = false, checkForNewUserItems = true, goBack = true) => {
    let user;
    try {
        actions.setFormValue('pastVisitsSubscribe', undefined);
        await actions.startFetching();

        await actions.checkAuthenticated();
        foundNewUserInfo = false;
        try {
            const cbForNews = checkForNewUserItems
                ? (newRelatives, newProgram) => {
                    foundNewUserInfo = true;
                    Object.handleNewUserItemsFound(newRelatives, newProgram, cb ? () => cb(user) : null);
                }
                : () => {};
            Object.onNewVisitFound = (res) => {
                if (Object.orderId) {
                    setTimeout(() => Page.showModal(<NewVisitModal data={res} />), 100);
                }
            };
            Object.onAlreadyImported = (res) => {
                setTimeout(() => {
                    Page.showModal(<AlreadyImportedModal />);
                }, 100);
            };
            user =
                (await actions.reloadUserInfo(
                    (nr, np) => {
                        actions.setProgram(np);
                        cbForNews(nr, np);
                    },
                    actions.updatePrefs,
                    actions.updateSelects,
                )) || {};
            actions.fetchVotes('physicians', !user.userId);
            actions.fetchVotes('clinics', !user.userId);
            actions.fetchCompleted(undefined, !user.userId);
            if (Object.callbackOnSignIn && (user.isPerson || user.relatives[0]?.profileId)) {
                Object.callbackOnSignIn(
                    // eslint-disable-next-line no-nested-ternary
                    Object.preEnrollVisitRoute === 'EditAccount' || Object.preEnrollVisitRoute === undefined
                        ? user.userId
                        : Object.preEnrollVisitRoute === 'EditRelative'
                            ? user.relatives?.find?.(({ relKind }) => relKind === 'CHILD')?.profileId ??
                          user.relatives[0]?.profileId ??
                          user.userId
                            : user.relatives[0]?.profileId,
                );
                goBack && back();
                Object.callbackOnSignIn = null;
                Object.preEnrollVisitRoute = undefined;
            }

            if (user.outdatedAppVersion) {
                await actions.stopUserFetch();
                navigate('OutdatedAppVersion');
                return;
            }
        } catch (error) {
            await actions.stopUserFetch();
            throw error;
        }
    } finally {
        actions.stopFetching();
    }

    const { storeKey } = user;
    profileDataUnsubscribe();
    if (storeKey) {
        actions.pingVisits();
        const debounce = debouncer(2000);

        profileDataUnsubscribe = firebase.subscribeOnDocChanges(
            'profile',
            storeKey,
            async (doc) => {
                if (!doc?.metadata?.hasPendingWrites || Platform.OS === 'ios') {
                    const wasNPAsked = await storage.get('wasNPAsked');
                    const data = doc?.data();
                    if (data) {
                        if (
                            !wasNPAsked &&
                            !wasNotifPermAsked.value &&
                            Platform.OS !== 'web' &&
                            data.visitList?.length
                        ) {
                            setTimeout(checkNotifications, 3000);
                        }
                        debounce(() => actions.syncVisits(data.visitList));
                    }
                }
            },
            true,
        );
    } else {
        actions.resetVisits();
    }

    profileUnsubscribe();
    if (storeKey) {
        let onProfileEvents = () => false;
        profileUnsubscribe = firebase.subscribeOnDocChanges('profileEvents', storeKey, () => {
            // eslint-disable-next-line no-console
            console.log('profileEvents was updated');
            onProfileEvents();
            onProfileEvents = syncUserState;
        });
    }

    setTimeout(actions.syncUmsInfo, 200);

    await tracking.setUserProperties(user);
    if (remoteConfigWaiting) {
        clearTimeout(remoteConfigWaiting);
        remoteConfigWaiting = null;
    }
    remoteConfigWaiting = setTimeout(
        async () => {
            await initRemoteConfig();
            remoteConfigWaiting = null;
        },
        Platform.OS === 'web' ? 10000 : 7500,
    );

    if (isAfterSign) {
        isAfterSign(user);
    }
    // If new user info found, callback will be called on notification close
    if (cb && !foundNewUserInfo) {
        cb(user);
    }
};

// eslint-disable-next-line no-underscore-dangle
const __init = async (setUnsubscribe) => {
    Object.fetchBlob = Object.fetchBlob || RNFetchBlob;
    tracking.checkForTestLabRunning();
    // notifications.createChannel('fcm_fallback_notification_channel', 'Общие оповещения');
    checkDeviceId(deviceId => tracking.logEvent('ai_first_open', { deviceId }));
    if (Platform.OS === 'web') {
        const isPWA = isInStandaloneMode();
        console.log('isPWA: ', isPWA);
        if (isPWA) {
            setTimeout(() => tracking.logEvent('open_from_pwa'), 1000);
        }
    }
    if (Platform.OS === 'ios') {
        await firebase.checkForRemoteM();
        firebase.onMessage((rm) => {
            if (rm) {
                const { title, body: message } = rm.notification || {};
                notifications.notification({
                    title,
                    subtitle: '',
                    message,
                    playSound: true,
                    // number: Platform.OS === 'ios' ? 10 : '10', // (optional) Valid 32 bit integer specified as string. default: none (Cannot be zero),
                    userInfo: { ...rm.data },
                });
            }
        });
    }
    // locale and location
    const { locale, location, acodeConfirmation } = await storage.getObject('selections');
    let showBumbaBanner = await storage.get('bumbaBanner');
    if (showBumbaBanner === undefined || showBumbaBanner === null) {
        showBumbaBanner = true;
        await storage.set('bumbaBanner', true);
    }
    actions.setFormData({ bumbaBanner: !!showBumbaBanner });
    const supportedLanguages = await storage.get('supportedLanguages');
    if (supportedLanguages?.some(e => e.id === locale) || locale === 'ru') {
        setLocale(locale);
    } else {
        await actions.setLocale('ru');
    }
    await actions.setLocation(location || 17);
    await actions.setAcodeConfirmation(acodeConfirmation);
    initRemoteConfig({ online_booking_enabled: false });
    await checkForMigration();
    // first setup of common collections
    initClinicsCollection();

    // listen Firestore and Auth in realtime:
    setTimeout(subscribeOnAppState, 1000);

    let firstTime = true;

    firebase.addAuthStateListener(async (state) => {
        // eslint-disable-next-line no-console
        console.log('Auth state changed: ', state);

        if (firstTime) {
            firstTime = false;
            const acodeExpirationTime = await storage.get('acodeExpirationTime');
            if (acodeExpirationTime >= 0) {
                setTimeout(removeAllACodes, 1000);
                Object.acodeRecheck = true;
            }
            if (Platform.OS === 'ios') {
                const isPreviousUserNotSaved = await storage.get('isPreviousUserNotSaved');
                const phoneNumber = await storage.get('phoneNumber');
                if (!isPreviousUserNotSaved && state !== null && !state?.isAnonymous && !phoneNumber) {
                    storage.set('isPreviousUserNotSaved', true);
                    await actions.logOut();
                }
            }
            if (Object.afterRelogin) {
                if (state !== null && !state?.isAnonymous) {
                    await actions.logOut();
                }
                Object.afterRelogin();
                Object.afterRelogin = null;
            } else if (Object.checkUserInfo) {
                await Object.checkUserInfo(state?.providerData, state?.phoneNumber);
                Object.checkUserInfo = null;
            }
            // checkShowWNModal();
            if (Object.checkShowProfileInstructionsModal) {
                Object.checkShowProfileInstructionsModal();
            }
            if (Platform.OS === 'web' && window.location.hash === '#signed') {
                window.location = '#';
                syncUserState(() => navigate('Account'));
            } else if (Platform.OS === 'web') {
                syncUserState();
            }
            return;
        }
        putIntoStore(SCHEME.PHYSICIANS_FROM_SERARCH, undefined);
        putIntoStore('specializationForApi', undefined);
        preparePrefilters();
        if (state?.providerData.length) {
            syncUserState(
                async (user) => {
                    try {
                        const basket = getBasket(Store.getState());
                        if (basket && Array.isArray(basket)) {
                            actions.setDrugsInfo(basket);
                        }
                    } catch (e) {
                        // console.log('Error get prefs', e);
                    }

                    actions.stopUserFetch();
                    if (user.accessNotAuthorized && !user.waitingConfirmEmail) {
                        replace('AccessCheck');
                        return;
                    }
                    replace('Account');
                    if (
                        !user.isPerson &&
                        !user.waitingConfirmEmail &&
                        !user.deviceIdOfExistedUser &&
                        !(Object.orderId && Object.orderProviderCode)
                    ) {
                        Object.preEnrollVisitRoute === 'EditAccount' || !Object.preEnrollVisitRoute
                            ? navigate('ChooseProgram')
                            : navigate(Object.preEnrollVisitRoute);
                    }
                    if (Object.orderId && Object.orderProviderCode) {
                        navigate('Home');
                    }
                },
                async (user) => {
                    appyLocationForPhoneLogin(user);
                    tracking.logEvent('login', { method: 'phone' });
                },
            );
        } else {
            if (state === null) {
                // firebase.cancelAllNotifications();
            }
            syncUserState();
            actions.clearPreferences();
            actions.resetSearchKeyword();
            actions.emptyTests();
            actions.setPastVisits(null);
        }
    });

    // const networkUnsubscription =
    NetInfo.addEventListener(async () => {
        const netInfo = await NetInfo.fetch();
        if (!netInfo.isInternetReachable) {
            return;
        }

        await actions.checkAuthenticated();
        const userInteractionError = await actions.getUserInteractionError();
        if (userInteractionError && userInteractionError.status === -1) {
            syncUserState();
            await actions.setUserInteractionError(null);
        }
        initRemoteConfig();
    });

    // TODO use https://reactnavigation.org/docs/navigation-container/#linking
    // speed up: const deepLinksUnsubscription = await
    initDeepLinks((event) => {
        actions.addLog('deeplink init', event);
        if (event.url.startsWith('aimd:')) {
            syncUserState(async ({ location: loc }) => {
                await setLocation(loc);
                navigate('Account');
            });
        }
    });

    notifications.customNotificationHandler = (notify) => {
        openDoctorFeedbackFromNotify(
            notify,
            undefined,
            () => notifications.finish(notify),
            () => notifications.finish(notify),
        );
    };

    setTimeout(() => {
        Object.trackNavigation('Home');
    }, 1000);

    setTimeout(() => {
        if (goToPage && goToParams) {
            navigate(goToPage, goToParams);
        }
        goToPage = null;
    }, 1000);

    setUnsubscribe(() => () => {});
    // to prevent app crash:
    // setUnsubscribe(() => () => {

    //     firebaseUnsubscription();
    //     deepLinksUnsubscription();
    //     profileUnsubscribe();
    //     profileDataUnsubscribe();
    //     networkUnsubscription();
    //     appStateChangeUnsubscribe();
    // });
};

export const useInit = () => {
    const [unsubcribe, setUnsubscribe] = useState();

    useEffect(() => {
        __init(setUnsubscribe);
    }, []);

    useEffect(() => unsubcribe, [unsubcribe]);

    return !!unsubcribe;
};
