// eslint-disable-next-line import/extensions
import dump from 'aibolit-dump';
import { VERSION } from '../config';
import { storage } from './localstorage';

const VERSION_KEY = 'app_version';
const getDbVersion = async () => parseInt(await storage.get(VERSION_KEY) || 0);

const initial = async () => {
    const data = dump();
    return Promise.all(Object.keys(data)
        .map(key => storage.set(key, data[key])));
};

// stub from zero to 1
const migrations = { '000000000': initial };

/* eslint-disable no-await-in-loop */
export const checkForMigration = async () => {
    const dbVersion = await getDbVersion();
    const formAppVersion = VERSION.split('-')[0].split('.')
        .reduce((acc, v) => acc + v.padStart(3, '0'), '');
    if (!dbVersion) {
        await initial();
        await storage.set(VERSION_KEY, formAppVersion);
        return;
    }

    const formDbVersion = dbVersion < 10 ? dbVersion.toString()
        .padStart(3, '0')
        .padEnd(9, '0') : dbVersion;
    if (formDbVersion < formAppVersion) {
        Object.keys(migrations)
            .sort()
            .filter(a => a > formDbVersion)
            .forEach(t => migrations[t]());
        await storage.set(VERSION_KEY, formAppVersion);
    }
};
