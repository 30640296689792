import { getEnums } from '../../../i18n';
import { View } from '../../../common';
import { HyperLink } from '../../../common/Hyperlink';
import { buttonsStyles } from '../../../styles/button';
import { Checkbox } from '../../../common/Agreemants';
import React, { useMemo, useState } from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';
import { useAssignmentInfo } from '../../../hooks/useAssignmentInfo';
import { useSelector } from 'react-redux';
import { getUserCards } from '../../../selectors';


const styles = EStyleSheet.create({
    container: {
        height: 75,
        padding: 16,
    },

});

export const ClinicConsent = ({ clinicId, onConsent = (_, __) => {}, assignmentId }) => {

    const [received, setReceived] = useState(false);

    const consentBranches = getEnums('consentForProvider');
    const branch = consentBranches?.find?.(({ id }) => id === clinicId);
    const info = useAssignmentInfo({
        assignmentId,
        request: !!branch,
    });

    const cards = useSelector(getUserCards);

    const hasConsent = useMemo(() => {

        if (!cards?.length || !info?.providerCode) {

            return false;

        }

        const card = cards.find(({ id }) => id === info?.providerCode);

        if (card) {

            return !!card.consentReceived;

        }

        return false;

    }, [cards, info]);

    if (!clinicId) {

        onConsent(true, false);
        return null;

    }


    if (!branch) {

        onConsent(true, false);
        return null;

    }

    if (hasConsent) {

        onConsent(true, false);
        return null;

    }
    return <>
        <View
            style={[styles.container]}>
            <Checkbox
                title={
                    <HyperLink
                        title={Object.R(branch?.routeTo ?? '', {})}
                        style={{ linkColor: buttonsStyles.link.color }}
                        linkStyle={{ textDecorationLine: 'underline' }}
                        onClickText={() => {

                            onConsent(!received, true);
                            setReceived(prevState => !prevState);

                        }}
                    />
                }
                style={{
                    marginHorizontal: 2,
                    marginVertical: 40,
                    marginTop: 3,
                }}
                selected={received}
                numberOfLines={0}
                onPress={() => {

                    onConsent(!received, true);
                    setReceived(prevState => !prevState);

                }}
            />
        </View>
    </>;

};
