/* eslint-disable complexity */
/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import RNFirebase from '@react-native-firebase/app';
import RNFirebaseAuth from '@react-native-firebase/auth';
import RNFirestore from '@react-native-firebase/firestore';
import RNFirebaseMessaging from '@react-native-firebase/messaging';
import RNFirebaseRemoteconfig from '@react-native-firebase/remote-config';
import RNFirebaseDynamicLinks from '@react-native-firebase/dynamic-links';
import { Platform } from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import MD5 from 'crypto-js/md5';
import { debouncer, isEmpty, someEmpty } from '../utils';
import { storage } from './localstorage';
import tracking from './tracking';

export const collections = ['clinics'];
export const staticCollections = ['settings'];
export const pureCollections = ['notifications']; // 'settings' // 'physicians_feedback_sum', 'physicians_timetable_sum', 'physicians_prices_sum' // 'clinics', 'notifications', 'settings'
const collectionsWithDumps = ['physicians', 'clinics'];
export const splitCollectionsToUpdate = [];
export const splitCollectionsSums = [];
export const splitCollectionsToSubscribe = [];
export const dinymicSplitCollectionsToSubscribe = [];


const collectionsUnsubscriptions = {};
const splitCollectionsUnsubscriptions = {};
const profileCollectionsUnsubscriptions = {};

if (Platform.OS !== 'web') {
    RNFirestore().settings({ persistence: false });
}

const _appVerifier = null;
const getRecaptchaVerifier = () => _appVerifier ||
    (Platform.OS === 'web'
        ? RNFirebase.app().RecaptchaVerifier()
        : new RNFirebaseAuth.RecaptchaVerifier('recaptcha-container', { size: 'invisible' }));
let recaptchaVerifier;

const snapshotDataObject = (snap) => {
    const data = {};
    snap?.forEach((doc) => {
        const { deleted, _id, ...fields } = doc.data();
        if (!deleted) {
            const id = doc.id || _id;
            data[id] = {
                ...fields,
                _id: id,
                id,
                path: doc.ref.path,
            };
        }
    });
    return data;
};
const snapshotToData = (snapshot) => {
    const result = [];
    snapshot?.forEach((doc) => {
        const e = doc.data();
        const id = e._id || doc.id;
        result.push({
            ...e,
            id,
        });
    });
    return result;
};
const querySnapshotToData = (querySnapshot) => {
    const data = {};
    if (!querySnapshot.size) {
        return;
    }
    const { docs } = querySnapshot;
    for (let i = 0; i < docs.length; i++) {
        const docData = docs[i].data();
        const id = docs[i].id || docData._id;
        data[id] = {
            ...docData,
            _id: id,
            id,
            path: docs[i].ref.path,
        };
    }
    // eslint-disable-next-line consistent-return
    return data;
};

// noinspection JSUnusedGlobalSymbols,JSUnusedLocalSymbols
const firebaseService = {
    get app() {
        return RNFirebase.app();
    },
    get auth() {
        return Platform.OS === 'web' ? this.app.auth() : RNFirebaseAuth();
    },
    get cfg() {
        return Platform.OS === 'web' ? this.app.remoteConfig() : RNFirebaseRemoteconfig();
    },

    get firestore() {
        if (this._firestore) {
            return this._firestore;
        }
        this._firestore = Platform.OS === 'web' ? this.app.firestore() : RNFirestore();

        // this._firestore.settings({
        //     cacheSizeBytes: this._firestore.CACHE_SIZE_UNLIMITED,
        // });

        // this._firestore.enablePersistence(true).catch((err) => {
        //     console.error('firestore.enablePersistence', err.code);
        // });

        return this._firestore;
    },
    /** ****************************
     * FireStore
     ***************************** */
    snapshotToData,
    snapshotDataObject,

    userStatsRef() {
        return this.firestore.collection('userStats').doc(this.auth.currentUser.uid);
    },

    subscribeOnDocChanges(coll, id, cb, hot) {
        const doc = this.firestore.collection(coll).doc(id);
        if (hot) {
            doc.get().then(cb);
        }
        return doc.onSnapshot(cb);
    },

    collection(coll) {
        return this.firestore.collection(coll);
    },

    subscribeOnCollection(coll, cb) {
        this.firestore.collection(coll).get().then(cb);
        return this.firestore.collection(coll).onSnapshot(cb);
    },

    subscribeOnCollectionChanges(coll, cb) {
        profileCollectionsUnsubscriptions[coll] = this.firestore.collection(coll).onSnapshot(cb);
    },

    getCollectionsKeys() {
        return this.firestore.collection('refData').doc('collectionsKeys');
    },

    unsubscribeFromProfileCollectionsChanges() {
        Object.keys(profileCollectionsUnsubscriptions).forEach((coll) => {
            const unsubscribe = profileCollectionsUnsubscriptions[coll];
            if (unsubscribe) {
                unsubscribe();
                delete profileCollectionsUnsubscriptions[coll];
            }
        });
    },
    unsubscribeFromSplitCollectionsChanges() {
        Object.keys(splitCollectionsUnsubscriptions).forEach((coll) => {
            const unsubscribe = splitCollectionsUnsubscriptions[coll];
            if (unsubscribe) {
                unsubscribe();
                delete splitCollectionsUnsubscriptions[coll];
            }
        });
    },

    resetSplitCollections() {
        splitCollectionsToSubscribe.splice(0, splitCollectionsToSubscribe.length);
        splitCollectionsToUpdate.splice(0, splitCollectionsToUpdate.length);
        dinymicSplitCollectionsToSubscribe.splice(0, dinymicSplitCollectionsToSubscribe.length);
    },

    unsubscribeFromCollectionsChanges() {
        collections.forEach((coll) => {
            const unsubscribe = collectionsUnsubscriptions[coll];
            if (unsubscribe) {
                unsubscribe();
                delete collectionsUnsubscriptions[coll];
            }
        });
        pureCollections.forEach((coll) => {
            const unsubscribe = collectionsUnsubscriptions[coll];
            if (unsubscribe) {
                unsubscribe();
                delete collectionsUnsubscriptions[coll];
            }
        });
        staticCollections.forEach((coll) => {
            const unsubscribe = collectionsUnsubscriptions[coll];
            if (unsubscribe) {
                unsubscribe();
                delete collectionsUnsubscriptions[coll];
            }
        });
    },

    collectionsData: {},
    async onlySubscribeOnCollection(coll) {
        const debounce = debouncer(20000);
        const { lastMax = -1, data = {} } = await storage.getObject(`${coll}`);
        const subscribeCollection = (ts) => {
            const unsubscribe = collectionsUnsubscriptions[coll];
            if (unsubscribe) {
                unsubscribe();
            }

            // eslint-disable-next-line max-statements
            collectionsUnsubscriptions[coll] = this.firestore
                .collection(coll)
                .where('_lastModifiedAt', '>', ts)
                .onSnapshot((snapshot) => {
                    if (!snapshot || !snapshot.size) {
                        return;
                    }
                    const docsChanges = snapshot.docChanges();
                    if (!docsChanges.length) {
                        return;
                    }
                    let max = 0;
                    for (let i = 0; i < docsChanges.length; i++) {
                        const docChange = docsChanges[i];
                        const { doc } = docChange;
                        const docData = doc.data();
                        const id = doc.id || docData._id;
                        if (!docData.deleted && docChange.type !== 'removed') {
                            Object.assign(docData, {
                                _id: id,
                                id,
                                path: doc.ref.path,
                            });
                            if (data[id]) {
                                Object.assign(data[id], docData); // { ...fields, _id: id, id, path: doc.ref.path, _lastModifiedAt };
                            } else {
                                data[id] = docData;
                            }
                        } else {
                            delete data[id];
                        }
                        max = Math.max(docData._lastModifiedAt || 0, max);
                    }
                    if (ts < max) {
                        debounce(() => storage.set(`${coll}`, {
                            data,
                            lastMax: max,
                        })); // .then(() => onLog(coll, Date.now() - ddd2, 'write_storage')));
                    }
                });
        };
        subscribeCollection(lastMax);
    },
    getItemsFromCollection(coll, arr = []) {
        return this.firestore
            .collection(coll)
            .where('_id', 'in', arr)
            .get()
            .then((querySnapshot) => {
                return querySnapshotToData(querySnapshot);
            });
    },
    getActualCollection(coll, cb, newSubscribe, onAllSync) {
        tracking.logEvent(`sync_all_${coll}`);
        onAllSync && onAllSync(coll, true);
        this.firestore
            .collection(coll)
            .where('deleted', '!=', true)
            .get()
            .then(async (querySnapshot) => {
                const debounce = debouncer(20000);
                const data = {};
                if (!querySnapshot.size) {
                    return;
                }
                const actualLastModifiedAtSnapsh = await this.firestore
                    .collection(coll)
                    .orderBy('_lastModifiedAt', 'desc')
                    .limit(1)
                    .get();

                const actualLastModifiedAt = actualLastModifiedAtSnapsh?.docs
                    ? actualLastModifiedAtSnapsh.docs.length &&
                    actualLastModifiedAtSnapsh?.docs[0]?.data()?._lastModifiedAt
                    : -1;

                const { docs } = querySnapshot;
                for (let i = 0; i < docs.length; i++) {
                    const docData = docs[i].data();
                    const id = docs[i].id || docData._id;
                    data[id] = {
                        ...docData,
                        _id: id,
                        id,
                        path: docs[i].ref.path,
                    };
                }
                cb(coll, data);
                this.collectionsData[coll] = {
                    data,
                    lastMax: actualLastModifiedAt,
                    isSaved: false,
                };
                debounce(() => storage.set(`${coll}`, {
                    data,
                    lastMax: actualLastModifiedAt,
                }));
                onAllSync && onAllSync(coll, false);
                newSubscribe(actualLastModifiedAt);
            });
    },

    syncIsChecked: {},

    async updateCollection(coll, cb, onStopFetching, onAllSync, onFetching) {
        const debounce = debouncer(20000);
        onFetching && onFetching(true);
        if (!this.collectionsData[coll]?.data) {
            const result = await storage.getObject(`${coll}`);
            this.collectionsData[coll] = {
                data: result.data || {},
                lastMax: result.lastMax || -1,
            };
            if (!isEmpty(this.collectionsData[coll]?.data)) {
                cb(coll, this.collectionsData[coll]?.data);
            }
        }
        const isActual = !collectionsWithDumps.some(collName => collName === coll);
        const subscribeCollection = (ts) => {
            // eslint-disable-next-line max-statements,complexity
            this.firestore
                .collection(coll)
                .where('_lastModifiedAt', '>', ts)
                .get()
                .then(async (snapshot) => {
                    onStopFetching && onStopFetching(coll);
                    const data = this.collectionsData[coll]?.data;
                    const getHash = () => Object.keys(data)
                        .filter(k => k !== 'undefined')
                        .sort()
                        .join('');
                    if (!snapshot || !snapshot.size) {
                        if (!isActual && !this.syncIsChecked[coll]) {
                            try {
                                this.syncIsChecked[coll] = true;
                                const collectionsKeysSnapshot = await this.getCollectionsKeys().get();
                                const collectionsKeysSnapshotData = collectionsKeysSnapshot.data() || {};
                                // eslint-disable-next-line max-depth
                                if (
                                    collectionsKeysSnapshotData[coll] &&
                                    collectionsKeysSnapshotData[coll] !== getHash()
                                ) {
                                    this.getActualCollection(coll, cb, subscribeCollection, onAllSync);
                                    onFetching && onFetching(false);
                                    return;
                                }
                            } catch (e) {
                                // eslint-disable-next-line no-console
                                console.log('error: ', e);
                            }
                        }
                        onFetching && onFetching(false);
                        return;
                    }
                    const docsChanges = snapshot.docChanges();
                    if (!docsChanges.length) {
                        onFetching && onFetching(false);
                        return;
                    }
                    let max = 0;
                    for (let i = 0; i < docsChanges.length; i++) {
                        const docChange = docsChanges[i];
                        const { doc } = docChange;
                        const docData = doc.data();
                        const id = doc.id || docData._id;
                        if (!docData.deleted && docChange.type !== 'removed') {
                            Object.assign(docData, {
                                _id: id,
                                id,
                                path: doc.ref.path,
                            });
                            if (data[id]) {
                                Object.assign(data[id], docData);
                            } else {
                                data[id] = docData;
                            }
                        } else {
                            delete data[id];
                        }
                        max = Math.max(docData._lastModifiedAt || 0, max);
                    }
                    if (!isActual && !this.syncIsChecked[coll]) {
                        try {
                            this.syncIsChecked[coll] = true;
                            const collectionsKeysSnapshot = await this.getCollectionsKeys().get();
                            const collectionsKeysSnapshotData = collectionsKeysSnapshot.data() || {};
                            if (collectionsKeysSnapshotData[coll] && collectionsKeysSnapshotData[coll] !== getHash()) {
                                this.getActualCollection(coll, cb, subscribeCollection, onAllSync);
                                onFetching && onFetching(false); // !
                                return;
                            }
                        } catch (e) {
                            // eslint-disable-next-line no-console
                            console.log('error: ', e);
                        }
                    }
                    onFetching && onFetching(false);
                    if (ts < max) {
                        this.collectionsData[coll] = {
                            data,
                            lastMax: max,
                            isSaved: false,
                        };
                        cb(coll, data);
                        debounce(() => {
                            (async () => {
                                await storage.set(`${coll}`, {
                                    data,
                                    lastMax: max,
                                });
                                // onFetching && onFetching(false);
                            })();
                        }); // .then(() => console.log(`${coll}: `, Date.now() - ddd2, 'write_storage'))); // .then(() => onLog(coll, Date.now() - ddd2, 'write_storage')));
                    } else {
                        // onFetching && onFetching(false);
                    }
                });
        };
        subscribeCollection(this.collectionsData[coll]?.lastMax);
    },

    // eslint-disable-next-line no-unused-vars
    async setAndUpdateCollection(coll, cb, onStopFetching, onAllSync, onLog) {
        const debounce = debouncer(20000);
        if (!this.collectionsData[coll]?.data) {
            const result = await storage.getObject(`${coll}`);
            this.collectionsData[coll] = {
                data: result.data || {},
                lastMax: result.lastMax || -1,
            };
            if (!isEmpty(this.collectionsData[coll]?.data)) {
                cb(coll, this.collectionsData[coll]?.data);
            }
        }

        const isActual = !collectionsWithDumps.some(collName => collName === coll);
        const subscribeCollection = (ts) => {
            const unsubscribe = collectionsUnsubscriptions[coll];
            if (unsubscribe) {
                unsubscribe();
            }
            // eslint-disable-next-line max-statements,complexity
            collectionsUnsubscriptions[coll] = this.firestore
                .collection(coll)
                .where('_lastModifiedAt', '>', ts)
                // eslint-disable-next-line max-statements
                // eslint-disable-next-line complexity
                // eslint-disable-next-line max-statements
                .onSnapshot(async (snapshot) => {
                    onStopFetching && onStopFetching(coll);
                    const data = this.collectionsData[coll]?.data;
                    const getHash = () => Object.keys(data)
                        .filter(k => k !== 'undefined')
                        .sort()
                        .join('');
                    if (!snapshot || !snapshot.size) {
                        if (!isActual && !this.syncIsChecked[coll]) {
                            try {
                                this.syncIsChecked[coll] = true;
                                const collectionsKeysSnapshot = await this.getCollectionsKeys().get();
                                const collectionsKeysSnapshotData = collectionsKeysSnapshot.data() || {};
                                // eslint-disable-next-line max-depth
                                if (
                                    collectionsKeysSnapshotData[coll] &&
                                    collectionsKeysSnapshotData[coll] !== getHash()
                                ) {
                                    this.getActualCollection(coll, cb, subscribeCollection, onAllSync);
                                    return;
                                }
                            } catch (e) {
                                // eslint-disable-next-line no-console
                                console.log('error: ', e);
                            }
                        }
                        return;
                    }
                    const docsChanges = snapshot.docChanges();
                    if (!docsChanges.length) {
                        return;
                    }
                    let max = 0;
                    for (let i = 0; i < docsChanges.length; i++) {
                        const docChange = docsChanges[i];
                        const { doc } = docChange;
                        const docData = doc.data();
                        const id = doc.id || docData._id;
                        if (!docData.deleted && docChange.type !== 'removed') {
                            Object.assign(docData, {
                                _id: id,
                                id,
                                path: doc.ref.path,
                            });
                            if (data[id]) {
                                Object.assign(data[id], docData);
                            } else {
                                data[id] = docData;
                            }
                        } else {
                            delete data[id];
                        }
                        max = Math.max(docData._lastModifiedAt || 0, max);
                    }
                    if (!isActual && !this.syncIsChecked[coll]) {
                        try {
                            this.syncIsChecked[coll] = true;
                            const collectionsKeysSnapshot = await this.getCollectionsKeys().get();
                            const collectionsKeysSnapshotData = collectionsKeysSnapshot.data() || {};
                            if (collectionsKeysSnapshotData[coll] && collectionsKeysSnapshotData[coll] !== getHash()) {
                                this.getActualCollection(coll, cb, subscribeCollection, onAllSync);
                                return;
                            }
                        } catch (e) {
                            // eslint-disable-next-line no-console
                            console.log('error: ', e);
                        }
                    }
                    if (ts < max) {
                        this.collectionsData[coll] = {
                            data,
                            lastMax: max,
                            isSaved: false,
                        };
                        cb(coll, data);
                        // if (coll !== 'physicians') {
                        debounce(() => storage.set(`${coll}`, {
                            data,
                            lastMax: max,
                        }));
                        // } // .then(() => console.log(`${coll}: `, Date.now() - ddd2, 'write_storage'))); // .then(() => onLog(coll, Date.now() - ddd2, 'write_storage')));
                    }
                });
        };
        subscribeCollection(this.collectionsData[coll]?.lastMax);
    },
    updatedDinymicCollection(coll, cb, onStopFetching, onLog) {
        const debounce = debouncer(500);
        const lastMax = this.collectionsData[coll]?.lastMax || -1;
        const data = this.collectionsData[coll]?.data || {};
        const subscribeCollection = (ts) => {
            const unsubscribe = collectionsUnsubscriptions[coll];
            if (unsubscribe) {
                unsubscribe();
            }
            // eslint-disable-next-line max-statements
            collectionsUnsubscriptions[coll] = this.firestore
                .collection(coll)
                .where('_lastModifiedAt', '>', ts)
                .get()
                .then((snapshot) => {
                    onStopFetching && onStopFetching(coll);
                    if (!snapshot || !snapshot.size) {
                        return;
                    }
                    const docsChanges = snapshot.docChanges();
                    if (!docsChanges.length) {
                        return;
                    }
                    let max = 0;
                    for (let i = 0; i < docsChanges.length; i++) {
                        const docChange = docsChanges[i];
                        const { doc } = docChange;
                        const docData = doc.data();
                        const id = doc.id || docData._id;
                        if (docChange.type !== 'removed') {
                            if (data[id]) {
                                Object.assign(data[id], {
                                    ...docData,
                                    _id: id,
                                    id,
                                    path: doc.ref.path,
                                });
                            } else {
                                data[id] = docData;
                            }
                        } else {
                            delete data[id];
                        }
                        max = Math.max(docData._lastModifiedAt || 0, max);
                    }
                    debounce(() => cb(coll, data));
                    this.collectionsData[coll] = {
                        data,
                        lastMax: max,
                    };
                });
        };
        subscribeCollection(lastMax);
    },
    // eslint-disable-next-line no-unused-vars
    pureSubscribeOnCollection(coll, cb, onStopFetching, onLog) {
        const debounce = debouncer(500);
        const lastMax = this.collectionsData[coll]?.lastMax || -1;
        const data = this.collectionsData[coll]?.data || {};
        const subscribeCollection = (ts) => {
            const unsubscribe = collectionsUnsubscriptions[coll];
            if (unsubscribe) {
                unsubscribe();
            }
            // eslint-disable-next-line max-statements
            collectionsUnsubscriptions[coll] = this.firestore
                .collection(coll)
                .where('_lastModifiedAt', '>', ts)
                .onSnapshot((snapshot) => {
                    onStopFetching && onStopFetching(coll);
                    if (!snapshot || !snapshot.size) {
                        return;
                    }
                    const docsChanges = snapshot.docChanges();
                    if (!docsChanges.length) {
                        return;
                    }
                    let max = 0;
                    for (let i = 0; i < docsChanges.length; i++) {
                        const docChange = docsChanges[i];
                        const { doc } = docChange;
                        const docData = doc.data();
                        const id = doc.id || docData._id;
                        if (docChange.type !== 'removed') {
                            if (data[id]) {
                                Object.assign(data[id], {
                                    ...docData,
                                    _id: id,
                                    id,
                                    path: doc.ref.path,
                                });
                            } else {
                                data[id] = docData;
                            }
                        } else {
                            delete data[id];
                        }
                        max = Math.max(docData._lastModifiedAt || 0, max);
                    }
                    debounce(() => cb(coll, data));
                    this.collectionsData[coll] = {
                        data,
                        lastMax: max,
                    };
                });
        };
        subscribeCollection(lastMax);
    },
    subscribeOnCollectionsChanges(cb, onStop, onAllSync, onLog) {
        collections.forEach((coll) => {
            this.setAndUpdateCollection(coll, cb, onStop, onAllSync, onLog);
        });
        pureCollections.forEach((coll) => {
            this.pureSubscribeOnCollection(coll, cb, onStop, onLog);
        });
        staticCollections.forEach((coll) => {
            this.onlySubscribeOnCollection(coll, cb, onStop, onLog);
        });
    },
    updateSplitCollectionsChanges(cb) {
        splitCollectionsToUpdate.forEach((coll) => {
            const [collShortName, location, spec] = coll.split('.');
            this.getSplitCollection(collShortName, location, spec, cb); // coll, location, spec, cb
        });
        splitCollectionsToSubscribe.forEach((coll) => {
            const [collShortName, location, spec] = coll.split('.');
            this.subscribeOnSplitCollection(collShortName, location, spec, cb); // coll, location, spec, cb
        });
        dinymicSplitCollectionsToSubscribe.forEach((coll) => {
            const [collShortName, location, spec] = coll.split('.');
            this.subscribeOnDinymicSplitCollection(collShortName, location, spec, cb); // coll, location, spec, cb
        });
    },

    splitCollectionsData: {},

    async firstSetOfSplitCollection(collectionName, cb) {
        if (!this.splitCollectionsData[collectionName]?.data) {
            const result = await storage.getObject(`${collectionName}`);
            this.splitCollectionsData[collectionName] = {
                data: result.data || {},
                lastMax: result.lastMax || -1,
            };
            if (!isEmpty(this.splitCollectionsData[collectionName]?.data)) {
                cb(collectionName, this.splitCollectionsData[collectionName]?.data);
            }
        }
    },

    getMaxAndSetDataFromSnapshot(docsChanges, data) {
        let max = 0;
        for (let i = 0; i < docsChanges.length; i++) {
            const docChange = docsChanges[i];
            const { doc } = docChange;
            const docData = doc.data();
            const id = doc.id || docData._id;
            if (docChange.type !== 'removed') {
                if (data[id]) {
                    Object.assign(data[id], {
                        ...docData,
                        _id: id,
                        id,
                        path: doc.ref.path,
                    });
                } else {
                    // eslint-disable-next-line no-param-reassign
                    data[id] = docData;
                }
            } else {
                // eslint-disable-next-line no-param-reassign
                delete data[id];
            }
            // eslint-disable-next-line no-param-reassign
            max = Math.max(docData._lastModifiedAt || 0, max);
        }
        return max;
    },
    getMaxAndSetSumDataFromSnapshot(docsChanges, data, keyName) {
        let max = 0;
        for (let i = 0; i < docsChanges.length; i++) {
            const docChange = docsChanges[i];
            const { doc } = docChange;
            const docData = doc.data();
            if (docChange.type !== 'removed') {
                if (data) {
                    Object.assign(data, {
                        ...docData?.[keyName],
                    });
                } else {
                    // eslint-disable-next-line no-param-reassign
                    data = docData?.[keyName];
                }
            }
            // eslint-disable-next-line no-param-reassign
            max = Math.max(docData._lastModifiedAt || 0, max);
        }
        return max;
    },
    async getSplitCollection(coll, location, spec, cb) {
        const debounce = debouncer(500);
        const collectionName = `${coll}.${location}.${spec}`;
        await this.firstSetOfSplitCollection(collectionName, cb);
        const lastMax = this.splitCollectionsData[collectionName]?.lastMax || -1;
        const data = this.splitCollectionsData[collectionName]?.data || {};
        if (!splitCollectionsToUpdate.some(c => c === collectionName)) {
            splitCollectionsToUpdate.push(collectionName);
        }
        this.firestore
            .collection(`${coll}/${location}/${spec}`)
            .where('_lastModifiedAt', '>', lastMax)
            .get()
            .then((snapshot) => {
                if (!snapshot || !snapshot.size) {
                    return;
                }
                const docsChanges = snapshot.docChanges();
                if (!docsChanges.length) {
                    return;
                }
                const max = this.getMaxAndSetDataFromSnapshot(docsChanges, data);
                debounce(() => {
                    storage.set(`${collectionName}`, {
                        data,
                        lastMax: max,
                    });
                    cb(collectionName, data);
                });
                this.splitCollectionsData[collectionName] = {
                    data,
                    lastMax: max,
                };
            });
    },

    // eslint-disable-next-line max-params
    async getSplitCollectionSum(coll, location, spec, keyName, cb, onUpdate) {
        const debounce = debouncer(500);
        const collectionName = `${coll}.${location}.${spec}`;
        if (splitCollectionsSums.some(sum => sum === collectionName)) {
            return;
        }
        const data = {};
        splitCollectionsSums.push(collectionName);
        this.firestore
            .collection(`${coll}/${location}/${spec}`)
            .get()
            .then((snapshot) => {
                if (!snapshot || !snapshot.size) {
                    onUpdate && onUpdate(collectionName, true);
                    return;
                }
                const docsChanges = snapshot.docChanges();
                if (!docsChanges.length) {
                    onUpdate && onUpdate(collectionName, true);
                    return;
                }
                const max = this.getMaxAndSetSumDataFromSnapshot(docsChanges, data, keyName);
                debounce(() => {
                    cb(collectionName, data);
                });
                this.splitCollectionsData[collectionName] = {
                    lastMax: max,
                };
                onUpdate && onUpdate(collectionName, true);
            }, () => {
                onUpdate && onUpdate(collectionName, true);
            });
    },

    async subscribeOnSplitCollection(coll, location, spec, cb) {
        const debounce = debouncer(1000);
        const collectionName = `${coll}.${location}.${spec}`;
        await this.firstSetOfSplitCollection(collectionName, cb);
        const subscribeCollection = (ts) => {
            if (!splitCollectionsToSubscribe.some(c => c === collectionName)) {
                splitCollectionsToSubscribe.push(collectionName);
            }
            const unsubscribe = splitCollectionsUnsubscriptions[collectionName];
            if (unsubscribe) {
                unsubscribe();
            }
            splitCollectionsUnsubscriptions[collectionName] = this.firestore
                .collection(`${coll}/${location}/${spec}`)
                .where('_lastModifiedAt', '>', ts)
                .onSnapshot(async (snapshot) => {
                    const data = this.splitCollectionsData[collectionName]?.data || {};
                    if (!snapshot || !snapshot.size) {
                        return;
                    }
                    const docsChanges = snapshot.docChanges();
                    if (!docsChanges.length) {
                        return;
                    }
                    const max = this.getMaxAndSetDataFromSnapshot(docsChanges, data);
                    if (ts < max) {
                        this.splitCollectionsData[collectionName] = {
                            data,
                            lastMax: max,
                        };
                        cb(collectionName, data);
                        debounce(() => storage.set(`${collectionName}`, {
                            data,
                            lastMax: max,
                        }));
                    }
                });
        };
        subscribeCollection(this.splitCollectionsData[collectionName]?.lastMax);
    },
    async subscribeOnDinymicSplitCollection(coll, location, spec, cb, _lastMax) {
        const collectionName = `${coll}.${location}.${spec}`;
        const subscribeCollection = (ts) => {
            if (!dinymicSplitCollectionsToSubscribe.some(c => c === collectionName)) {
                dinymicSplitCollectionsToSubscribe.push(collectionName);
            }
            const unsubscribe = splitCollectionsUnsubscriptions[collectionName];
            if (unsubscribe) {
                unsubscribe();
            }
            splitCollectionsUnsubscriptions[collectionName] = this.firestore
                .collection(`${coll}/${location}/${spec}`)
                .where('_lastModifiedAt', '>', ts)
                .onSnapshot(async (snapshot) => {
                    const data = this.splitCollectionsData[collectionName]?.data || {};
                    if (!snapshot || !snapshot.size) {
                        return;
                    }
                    const docsChanges = snapshot.docChanges();
                    if (!docsChanges.length) {
                        return;
                    }
                    const max = this.getMaxAndSetDataFromSnapshot(docsChanges, data);
                    if (ts < max) {
                        this.splitCollectionsData[collectionName] = {
                            data,
                            lastMax: max,
                        };
                        cb(collectionName, data);
                    }
                });
        };
        subscribeCollection(this.splitCollectionsData[collectionName]?.lastMax || _lastMax || -1);
    },

    getById(coll, id) {
        return !id
            ? null
            : this.firestore
                .collection(coll)
                .doc(id)
                .get()
                .then(r => r?.data())
                .catch(() => null);
    },

    onUserProfile(storeId, onUserProfileUpdate) {
        const unsubscribe = !storeId
            ? null
            : this.firestore
                .collection('profile')
                .doc(storeId)
                .onSnapshot((snap) => {
                    if (!snap?.metadata?.hasPendingWrites) {
                        unsubscribe && unsubscribe();
                        onUserProfileUpdate(snap?.data());
                    }
                });
    },

    getUserProfile(storeId) {
        return this.getById('profile', storeId);
    },

    setUserData(colName, data, storeId) {
        this.firestore
            .collection('profile')
            .doc(storeId)
            .set({ [colName]: data }, { merge: true });
    },

    setUserStats(props) {
        this.userStatsRef().set(props, { merge: true });
    },

    setVisitsStats({ id, ...props }) {
        this.userStatsRef().collection('visits').doc(MD5(id).toString())
            .set(props, { merge: true });
    },

    setVisitStatusIfExists(id, status) {
        const doc = this.userStatsRef().collection('visits').doc(MD5(id).toString());
        doc.get().then((r) => {
            if (r?.data()) {
                doc.set({ status }, { merge: true });
            }
        });
    },

    setAuthStats({ id, event, value }) {
        if (!this.isAuthenticated() || id) {
            const data = {};
            data[Platform.OS === 'web' ? this.firestore.Timestamp() : RNFirestore.Timestamp.now().seconds.toString()] =
                !value
                    ? { event }
                    : {
                        event,
                        value,
                    };
            this.firestore
                .collection('authStats')
                .doc((id || this.auth.currentUser.uid).toString())
                .set(data, { merge: true });
        }
    },

    /** ****************************
     * Configuration
     ***************************** */
    initRemoteConfig(defaults = {}) {
        const { cfg } = this;
        // if (MODE === 'dev') {
        //     cfg.enableDeveloperMode();
        // }
        cfg.setDefaults(defaults);
        // Platform.OS !== 'web' && cfg.setConfigSettings({ fetchTimeMillis: 3500, minimumFetchIntervalMillis: 1000 });

        return cfg
            .fetch(1)
            .then(() => cfg.fetchAndActivate()) // cfg.activate())
            .then(() => {
                // let parameters;
                const parameters = cfg.getAll();
                const data = {};
                Object.entries(parameters).forEach((el) => {
                    const [key, entry] = el;
                    const value = entry.asString();
                    data[key] = value === 'true' || value === 'false' ? JSON.parse(value) : value;
                });
                return data;
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.log(`Error processing firebase remote config`, error);
                throw error;
            });
    },
    /** ****************************
     * Authentication
     ***************************** */

    async addAuthStateListener(listenerFunc) {
        const { auth } = this;
        return auth.onAuthStateChanged(async (result) => {
            const id = await storage.get('anonymousId');
            if (id && auth.currentUser) {
                if (auth.currentUser.uid === id) {
                    this.setAuthStats({ event: 'app reopened' });
                } else {
                    this.setAuthStats({
                        id,
                        event: 'auth state changed',
                        value: auth.currentUser.uid,
                    });
                }
            }

            listenerFunc(result);
        });
    },

    getToken() {
        return this.auth.currentUser?.getIdToken() || null;
    },

    getUserId() {
        return this.auth.currentUser?.uid || null;
    },

    isAuthenticated() {
        return Boolean(this.auth.currentUser?.providerData?.length);
    },

    async checkAuth() {
        if (await this.isAuthenticated()) {
            return true;
        } else if (!this.auth.currentUser && (await NetInfo.fetch()).isInternetReachable) {
            if (Platform.OS === 'web') {
                await RNFirebase.app().auth().signInAnonymously();
            } else {
                await RNFirebaseAuth().signInAnonymously();
            }

            this.setAuthStats({ event: 'anonymous created' });
        }
        return false;
    },

    signOut() {
        return this.auth.signOut();
    },

    removeAccount() {
        //
    },

    async signInWithPhoneNumber(phoneNumber, cb = r => r) {
        const isOnline = (await NetInfo.fetch()).isInternetReachable;
        if (!isOnline) {
            throw new Error(Object.R('error.network_connection'));
        }
        if (Platform.OS === 'web') {
            if (!recaptchaVerifier) {
                recaptchaVerifier = getRecaptchaVerifier();
            }
            // noinspection UnnecessaryLocalVariableJS
            const authResult = this.auth.signInWithPhoneNumber(phoneNumber, recaptchaVerifier).then(cb);
            return authResult;
        }
        this.setAuthStats({
            event: 'sending to phone',
            value: phoneNumber,
        });
        const t = this;
        return this.auth
            .signInWithPhoneNumber(phoneNumber, true)
            .then(async (confirmationResult) => {
                this.setAuthStats({
                    event: 'sms sent',
                    value: phoneNumber,
                });
                cb(confirmationResult);
            })
            .catch((error) => {
                t.setAuthStats({
                    event: `sms not sent`,
                    // value: `${phoneNumber}: ${error?.message()}`,
                    value: phoneNumber,
                });
                // t.setAuthStats('sms not sent', error);
                console.log('Sms not send', error);
                throw error;
            });
    },

    async signConfirmation(confirmationResult, code, onError) {
        this.setAuthStats({
            event: 'code entered',
            value: code,
        });

        const credential =
            Platform.OS === 'web'
                ? this.app.getCredential(confirmationResult.verificationId, code || ' ')
                : RNFirebaseAuth.PhoneAuthProvider.credential(confirmationResult.verificationId, code || ' ');
        try {
            // noinspection UnnecessaryLocalVariableJS
            const result = await this.auth.signInWithCredential(credential).catch((e) => {
                this.setAuthStats({ event: 'wrong code' });
                throw e;
            });
            return result;
        } catch (error) {
            if (onError) {
                onError(error);
            }
            throw error;
        }
    },
    async signInWithToken(token, onError) {
        try {
            return await this.auth.signInWithCustomToken(token).catch((e) => {
                throw e;
            });
        } catch (e) {
            if (onError) {
                onError(e);
            }
            throw e;
        }
    },

    /** ****************************
     * Notifications
     ***************************** */

    onMessage(cb) {
        try {
            RNFirebaseMessaging().onMessage(cb);
        } catch (e) {
            //
        }
    },
    async checkForRemoteM() {
        try {
            if (RNFirebaseMessaging().isDeviceRegisteredForRemoteMessages) {
                return;
            }
            await RNFirebaseMessaging().registerDeviceForRemoteMessages();
        } catch (e) {
            //
        }
    },
    // eslint-disable-next-line consistent-return
    getFCMToken() {
        try {
            const result =
                Platform.OS === 'web' ||
                    (Platform.OS === 'ios' && !RNFirebaseMessaging().isDeviceRegisteredForRemoteMessages)
                    ? null
                    : RNFirebaseMessaging().getToken();
            return result;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log('Error: ', e);
            return null;
        }
    },
    getAPNSToken() {
        return RNFirebaseMessaging().getAPNSToken();
    },

    addDeepLinkReceiveListener(listenerFunc) {
        return Platform.OS !== 'web' && RNFirebaseDynamicLinks().onLink(listenerFunc);
    },

    async getStartLink() {
        return Platform.OS !== 'web' && RNFirebaseDynamicLinks().getInitialLink();
    },

    async checkPushNotificationsPermission() {
        if (Platform.OS === 'ios') {
            return RNFirebaseMessaging()?.hasPermission();
        }
        return true;
    },

    async subscribeToPushNotifications() {
        if (Platform.OS === 'ios') {
            // const enabled = await RNFirebaseMessaging().hasPermission();
            // if (!enabled) {
            //     await RNFirebaseMessaging().requestPermission();
            // }
        } else if (Platform.OS === 'android') {
            // const channel = new RNFirebaseMessaging.Android.Channel(
            //     'reminder',
            //     'Reminder',
            //     RNFirebaseMessaging.Android.Importance.Max,
            // );
            // RNFirebaseMessaging().android.createChannel(channel);
        }
        this.subscribeToNotifications();
    },

    subscribeToNotifications() {
        // if (!RNFirebaseMessaging) { return; } // web mode
        // notifications.notification({ title: 'Test', message: 'Subscribed' });
        // RNFirebaseMessaging().onMessage(async (message) => {
        //     const headless = await RNFirebaseMessaging().getIsHeadless();
        //     console.log(`%c Message from cloud HEADLESS=${headless}`, 'color: #EE00FF', message);
        // });
        // notifications.customNotificationHandler = (notify) => {
        //     if (notify?.foreground) {
        //         notifications.cancelNotification(notify?.id);
        //         console.log('Clear');
        //     }
        //     console.log(`%c Message opened - push notify`, `color: #EE00FF`, notify);
        // };
        // RNFirebaseMessaging().onNotificationOpenedApp(async (message) => {
        //     const headless = await RNFirebaseMessaging().getIsHeadless();
        //     console.log(`%c Message opened  HEADLESS=${headless}`, `color: #EE00FF`, message);
        // });
        // RNFirebaseMessaging().setBackgroundMessageHandler(async (message) => {
        //     const headless = await RNFirebaseMessaging().getIsHeadless();
        //     console.log(`%c setBackgroundMessageHandler HEADLESS=${headless}`, `color: #EE00FF`, message);
        // });
        // RNFirebaseMessaging().getInitialNotification().then(this.handleNotificationOpen);
        // RNFirebaseMessaging().onNotificationOpened(this.handleNotificationOpen);
        //
        // return RNFirebaseMessaging().onNotification(async (notification) => {
        //     notification
        //         .setSound('default')
        //         .android.setPriority(RNFirebaseMessaging.Android.Priority.High)
        //         .android.setChannelId('reminder')
        //         .android.setVibrate(1000)
        //         .android.setSmallIcon('@drawable/ic_notification')
        //         .android.setColor('#5C85DD');
        //     await RNFirebaseMessaging().displayNotification(notification);
        // });
    },

    handleNotificationOpen(notification) {
        if (!notification) {
            return;
        }
        const { popupTitle, popupMessage, popupAction } = notification.notification._data;
        if (popupMessage) {
            // eslint-disable-next-line global-require
            const { showNotificationInfoModal } = require('../combo');
            showNotificationInfoModal(popupTitle, popupMessage, popupAction);
        }
    },

    scheduleNotification(notificationId, date, title = 'Aibolit', text = '') {
        if (Platform.OS === 'web') {
            return;
        }
        const notification = new RNFirebaseMessaging.Notification()
            .setNotificationId(notificationId)
            .setTitle(title)
            .setBody(text)
            .android.setPriority(RNFirebaseMessaging.Android.Priority.High)
            .android.setChannelId('reminder')
            .android.setAutoCancel(false)
            .android.setSmallIcon('@drawable/ic_notification')
            .android.setColor('#5C85DD');

        RNFirebaseMessaging().scheduleNotification(notification, { fireDate: date.getTime() });
    },

    cancelAllNotifications() {
        if (Platform.OS === 'web') {
            return;
        }
        RNFirebaseMessaging().cancelAllNotifications();
    },
};

export const useFirebaseItem = (coll, id) => {
    const [item, setItem] = useState(null);

    useEffect(() => {
        (async () => {
            setItem(null);
            const info = await firebaseService.getById(coll, id);
            if (info) {
                setItem(info);
            }
        })();
    }, [coll, id]);

    return item;
};

export const getItemById = (coll, id) => firebaseService.getById(coll, id);

export const useLiveFirebaseItem = (coll, id) => {
    const [item, setItem] = useState(null);
    useEffect(
        () => firebaseService.subscribeOnDocChanges(coll, id, doc => setItem({
            ...doc?.data(),
            id: doc?.id,
        })),
        [coll, id],
    );
    return item;
};

export const useLiveFirebaseCollection = (coll, params = []) => {
    const [data, setData] = useState(null);
    useEffect(
        () => firebaseService.subscribeOnCollection(coll, ss => setData(snapshotDataObject(ss))),
        [coll, ...params],
    );
    return data;
};

export default firebaseService;
