import {
    Checkbox,
    List, Modal2, React, SearchBar, Text, TouchableWithoutFeedback, View,
} from '../../../common';
import { useKeyboardHeight } from '../../../common/Modal';
import { medcenterFilter as modalStyle } from '../../../combo/style';
import { getMarginHorizontalObject } from '../../../styles/common';
import { COLORS } from '../../../styles/colors';
import { useDispatch, useSelector } from 'react-redux';
import { getAllFeedbacksMySpecialityPreFilter } from '../../../selectors';
import { useMemo } from 'react';
import { getStringForSearch } from '../../../utils/strings';
import { setAllFeedbacksMySpecialityFilterToggle } from '../../../actions/feedbacks';
import { visitsFilter as style } from '../../Visits/style';

const SpecialityItem = ({

    selected, name, toggle, id,

}) => (
    <TouchableWithoutFeedback>
        <View
            key={id}
            style={{
                ...style.listItemWrapper,
                flexDirection: 'column',
                flex: 1,
                paddingHorizontal: 20,
                paddingVertical: 10,
                alignItems: 'flex-start',
            }}
        >
            <Checkbox onPress={toggle} selected={selected} title={<></>} style={{ borderWidth: 1 }}>
                <View
                    style={{
                        alignSelf: 'flex-start',
                        flex: 1,
                    }}>
                    <Text
                        style={{
                            fontSize: 14,
                            color: COLORS.STANDARD_GRAY,
                        }}>
                        {name}
                    </Text>
                </View>
            </Checkbox>
        </View>
    </TouchableWithoutFeedback>
);

const SpecialitiesSelector = ({ data }) => {

    const selected = useSelector(getAllFeedbacksMySpecialityPreFilter);
    const [filter, setFilter] = React.useState('');
    const filteredData = useMemo(() => data?.filter?.(({ name }) => !filter || getStringForSearch(name).includes(getStringForSearch(filter))).map(e => ({
        ...e,
        selected: selected?.includes(e?.id),
    })), [data, filter, selected]);
    const dispatch = useDispatch();
    return (
        <>
            <SearchBar initialValue={filter} onSearch={setFilter} />
            <List data={filteredData} renderItem={({ item: i }) => {

                return <SpecialityItem id={i?.id} name={i?.name} selected={i?.selected} toggle={() => dispatch(setAllFeedbacksMySpecialityFilterToggle(i?.id))} />;

            }} />
        </>
    );

};

export const SelectSpecialityModal = ({ list }) => {

    const keyboardHeight = useKeyboardHeight();
    return (
        <Modal2
            noScroll
            title="titles.forDoctorSpeciality"
            containerStyles={[
                modalStyle.modalContainer,
                {
                    ...getMarginHorizontalObject(),
                    marginBottom: 8 + keyboardHeight,
                },
            ]}>
            <View
                style={[
                    {
                        flex: 1,
                        borderBottomWidth: 1,
                        borderBottomColor: COLORS.BORDER_GRAY,
                    },
                ]}
                accessibilityLabel="list-wrapper">
                <SpecialitiesSelector data={list} />
            </View>
        </Modal2>
    );

};
