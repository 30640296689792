/* eslint-disable no-underscore-dangle */
import { filterAdultChild, filterWithoutManual } from './visitorsListOperations';

export const bookVisitorsCache = {
    cachedProfiles: [],
    cachedAssignmentId: '',
    cachedServiceId: '',
    cachedRequest: '',
};

export const isCacheValid = (profiles = []) => {
    const prof = filterWithoutManual(filterAdultChild(profiles));
    const isSameLength = prof?.length && prof?.length === bookVisitorsCache.cachedProfiles?.length;
    if (!isSameLength) {
        // consoleLog('not same length', { prof, cache: bookVisitorsCache.cachedProfiles, profiles });
        return false;
    }
    return prof?.every?.((p) => {
        const c = bookVisitorsCache.cachedProfiles?.find(
            ({ profileId, _id }) => profileId === p?.profileId || (_id && _id === p?._id),
        );
        if (!c) {
            // consoleLog('not found', p, bookVisitorsCache.cachedProfiles);
            return false;
        }
        return p?.profileId === c?.profileId && p?.birthday === c?.birthday && p?.name === c?.name;
    });
};
