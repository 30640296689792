import { useFocusEffect, useNavigation } from '@react-navigation/native';
import EStyleSheet from 'react-native-extended-stylesheet';
import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';
import firebase from '../../../../services/firebase';
import actions from '../../../../actions';
import {
    getClinicsFilterFromVisitHistory,
    getDoctorsFilterFromVisitHistory,
    getEnableLoadPastsVisits,
    getIsAuthenticated,
    getIsEmailUnconfirmed,
    getIsPastVisitsLoading,
    getNumberOfActiveVisitsFilters,
    getSpecialityFilterFromVisitHistory,
    getUser,
    getUserInfo,
    getVisitorsFilterFromVisitHistory,
    historyVisits,
    historyVisitsList,
    isProfileEmpty,
    isProfileHaveRelatives,
} from '../../../../selectors';
import {
    FlatList,
    Icon,
    Keyboard,
    Modal2,
    Page,
    Platform,
    SearchBar,
    Section,
    Selector,
    Text,
    TouchableWithoutFeedback,
    View,
} from '../../../../common';
import { goToVisitsFilter } from '../../../Visits/utils';
import { ActualVisitListItem, FillProfile } from '../../..';
import { fnDotId } from '../../../../utils/fn';
import { TextWithAuth } from '../../../../combo';
import { isAdult, sortBy } from '../../../../utils';
import { getFormValues, getVisitForScroll } from '../../../../selectors/forms';
import tracking from '../../../../services/tracking';
import { DISABLE_ADD_MANUAL_VISIT } from '../../../../config';
import { wordsFound } from './Tests';
import { MyCardTabSelector } from './snippets';

const MIN_LENGTH_SEARCH = 3;

const useReduxStoreCollection = (profileId, visits) => {

    useFocusEffect(
        React.useCallback(() => {

            const subscribed = actions.getFormValue('pastVisitsSubscribe');
            if (profileId && !subscribed) {

                actions.setFormValue('pastVisitsSubscribe', true);
                actions.startLoadingPastVisits();
                firebase.subscribeOnCollectionChanges(`profile/${profileId}/pastVisits`, (snapshot) => {

                    // putIntoStore(SCHEME.PAST_VISITS, firebase.snapshotToData(snapshot));
                    actions.setPastVisits(firebase.snapshotToData(snapshot));

                });

            }

        }, [profileId, visits]),
    );

};

const FilterIcon = () => {

    const data = useSelector(getNumberOfActiveVisitsFilters);
    const clinicList = useSelector(getClinicsFilterFromVisitHistory);
    const doctorList = useSelector(getDoctorsFilterFromVisitHistory);
    const visitors = useSelector(getVisitorsFilterFromVisitHistory);
    const speciality = useSelector(getSpecialityFilterFromVisitHistory);
    return (
        <Icon.Filter
            style={{ marginRight: 0 }}
            badge={data}
            onPress={() => goToVisitsFilter(clinicList, doctorList, visitors, speciality)}
        />
    );

};

const renderItem =
    ({
        keyword, onLayout, fromPast, clickable = true, detailScreen, FutureVisitItem, customDrawElement,
    }) => ({ item }) => (
        <>
            {item?.isFuture && FutureVisitItem ? (
                <>
                    <FutureVisitItem
                        visitInfo={item}
                        customDrawElement={customDrawElement}
                        detailScreen={detailScreen}
                        clicable={clickable}
                        isInPastVisitScreen={fromPast}
                        keyword={keyword}
                        hasDoctorName
                    />
                </>
            ) : (
                <>
                    <ActualVisitListItem
                        visitInfo={item}
                        hasDoctorName
                        keyword={keyword}
                        onLayout={(e) => {

                            onLayout?.(e, item);

                        }}
                        isInPastVisitScreen={fromPast}
                        clickable={clickable}
                        detailScreen={detailScreen}
                        customDrawElement={customDrawElement}
                    />
                </>
            )}
        </>
    );

const VisitsList = React.memo(
    ({
        visits,
        onSyncVisit,
        keyword,
        ListHeaderComponent,
        listStyles,
        emptyComponent,
        isInPastVisitScreen,
        clickable = true,
        detailScreen = 'Visit',
        FutureVisitItem,
        customDrawElement,
    }) => {

        const scrollRef = useRef();
        // const scrollStarted = useRef(false);

        const scroll = useSelector(getVisitForScroll);

        const [itemHeights, setItemHeights] = useState({});

        useEffect(() => {

            if (scroll) {

                const foundIndex = visits?.findIndex?.(i => i?.id === scroll?.id);
                if (foundIndex > -1) {

                    if (visits?.slice(0, foundIndex)?.some?.(i => !itemHeights[i?.id])) {

                        return;

                    }
                    actions.setFormValue('visitForScroll', null);
                    scrollRef?.current?.scrollToItem?.({
                        item: visits[foundIndex],
                        animated: true,
                        viewOffset: 0,
                        viewPosition: 0,
                    });

                }

            }

        }, [scroll, visits, itemHeights]);

        const getOffset = useCallback(
            (index) => {

                let offset = 0;
                for (let i = 0; i < index; i++) {

                    offset += itemHeights?.[visits?.[i]?.id] ?? 0;

                }
                return offset ?? 0;

            },
            [itemHeights],
        );

        if (visits === null) {

            return null;

        }


        return (
            <FlatList
                style={[
                    {
                        height: Platform.OS === 'web' ? 0 : undefined,
                        flex: 1,
                    },
                    listStyles,
                ]}
                accessibilityLabel="list"
                data={visits}
                // data={FutureVisitItem ? sortBy(visits, '-endDate') : visits}
                scrollEventThrottle={16}
                keyExtractor={fnDotId}
                renderItem={renderItem({
                    keyword,
                    onLayout: (e, item) => {

                        const height = e?.nativeEvent?.layout?.height ?? 0;
                        setItemHeights(prev => ({
                            ...prev,
                            [item?.id]: height,
                        }));

                    },
                    fromPast: isInPastVisitScreen,
                    clickable,
                    detailScreen,
                    FutureVisitItem,
                    customDrawElement,
                })}
                getItemLayout={(data, index) => {

                    return {
                        length: itemHeights?.[data[index]?.id] ?? 0,
                        offset: getOffset(index),
                        index,
                    };

                }}
                ListEmptyComponent={
                    emptyComponent || (
                        <EmptyHistoryText onSyncVisit={onSyncVisit} keyword={keyword} clickable={clickable} />
                    )
                }
                ListHeaderComponent={ListHeaderComponent}
                initialNumToRender={5}
                windowSize={10}
                // maxToRenderPerBatch={20}
                // updateCellsBatchingPeriod={40}
                persistentScrollbar
                onScrollBeginDrag={() => Keyboard.dismiss()}
                ref={(r) => {

                    scrollRef.current = r;

                }}
                // disableVirtualization
            />
        );

    },
    (data1, data2) => {

        return JSON.stringify(data1?.visits) === JSON.stringify(data2?.visits); // && data1?.keyword === data2?.keyword;

    },
);

export const AddVisitsIcon = ({ size = 20, isFuture, from }) => {

    const auth = useSelector(getIsAuthenticated);
    const navigation = useNavigation();
    const page = useMemo(() => (isFuture ? 'home' : 'myCard'), [isFuture]);
    const updateVisitsHandler = () => {

        tracking.logEvent(`plus_load_visits_${page}`);
        if (from === 'Home') {

            navigation.navigate('MyCard', { screen: 'LoadVisits' });

        } else {

            navigation.navigate('LoadVisits');

        }

    };
    const addVisitManuallyHandler = () => {

        tracking.logEvent(`plus_manual_add_${page}`);
        navigation.navigate(isFuture ? 'AddFutureVisitManually' : 'AddPastVisitManually', { isFuture });

    };
    const makeVisitHandler = () => {

        tracking.logEvent(`plus_visit_add_${page}`);
        const postfix = {
            Home: '',
            Visit: 'Visit',
        };
        const postfixName = postfix[from] ?? '';
        navigation.navigate(`DoctorsSpecialtiesSearch${postfixName}`, { from });

    };
    const menuItems = [
        {
            id: 'makeVisit',
            name: Object.R('titles.makeVisit'),
            handler: makeVisitHandler,
        },
        {
            id: 'loadVisits',
            name: Object.R('titles.loadVisits'),
            handler: updateVisitsHandler,
        },
        {
            id: 'addVisitManually',
            name: Object.R(`titles.add${isFuture ? 'Future' : ''}VisitManually`),
            handler: addVisitManuallyHandler,
        },
    ];

    const showSelectorHandler = () => {

        Page.showModal(
            <Modal2
                title={'titles.addFutureVisitManuallyTitle'}
                showCross
                hideClose
                // subtitle={' '}
                containerStyle={{
                    marginBottom: 10,
                    marginHorizontal: 10,
                }}>
                <Selector
                    data={
                        DISABLE_ADD_MANUAL_VISIT === '1'
                            ? menuItems?.filter(({ id }) => id !== 'addVisitManually')
                            : menuItems
                    }
                    itemStyle={{ marginHorizontal: 16 }}
                    itemTextStyle={{
                        color: 'black',
                        fontSize: 14,
                    }}
                    onSelect={(item) => {

                        item?.handler?.();
                        Page.closeModal();

                    }}
                    style={{
                        alignItems: 'center',
                        marginHorizontal: 12,
                        marginBottom: 0,
                    }}
                    firstLine
                />
            </Modal2>,
        );

    };
    return auth ? (
        <Icon.Add
            onPress={showSelectorHandler}
            size={size}
            style={{
                marginRight: 14,
                marginTop: 2,
                height: size + 3,
                width: size + 3,
            }}
        />
    ) : null;

};

const ListHeader = ({ onSearch, enableLoadPastsVisits, needTabs = true }) => {

    return (
        <View>
            {!!needTabs && <MyCardTabSelector />}
            <View
                style={{
                    flexDirection: 'row',
                    paddingBottom: 5,
                    flex: 1,
                }}>
                <View
                    style={{
                        flex: 1,
                        paddingLeft: 0,
                        paddingRight: 0,
                    }}>
                    <SearchBar
                        onSearch={onSearch}
                        additionalStyle={{
                            flex: 1,
                            marginHorizontal: 0,
                            marginRight: needTabs ? 12 : 0,
                            paddingLeft: 0,
                            marginLeft: 0,
                            // fixLength: 5,
                        }}
                        placeholder="placeholders.search"
                        withDebounce={false}
                    />
                </View>
                {!!needTabs && (
                    <View
                        style={{
                            flexDirection: 'row',
                            marginRight: Platform.OS !== 'web' ? 14 : 0,
                            marginTop: 22,
                            paddingRight: 0,
                            width: 65,
                        }}>
                        {!!enableLoadPastsVisits && <AddVisitsIcon from={'Visit'} />}
                        <FilterIcon />
                    </View>
                )}
            </View>
        </View>
    );

};
const filteredVisits = (visitList = [], forSearch = '', userInfo, add = []) => {

    try {

        const v = [...sortBy(add ?? [], '-endDate'), ...sortBy(visitList ?? [], '-endDate')];
        if (!forSearch) {

            return v;

        }
        const preparedText = forSearch.trim().toLowerCase();
        if (preparedText.length < MIN_LENGTH_SEARCH) {

            return v;

        }

        const keywords = Array.from(new Set(preparedText.split(' '))).filter(str => !!str);

        return Array.isArray(v)
            ? v.filter((i) => {

                const user =
                      i?.visitor?.profileId === userInfo?.userId
                          ? Object.R('titles.my_visit')
                          : i?.visitor?.firstLastName ?? '';
                let allNames;
                try {

                    allNames = `${i?.fullName?.trim?.()} ${i?.fullname?.trim?.()}`;

                } catch (e) {

                    allNames = '';

                }
                const text =
                      ` ${i?.name?.trim?.()} ${i?.dayText?.trim?.()} BN=${i?.branchName?.trim?.()} ${user?.trim?.()} ${i?.specialization?.trim?.()} ${i?.physicianName?.trim?.()} ${i?.serviceName?.trim?.()} ${allNames}`.toLowerCase();
                return wordsFound(text, keywords);

            })
            : v;

    } catch (e) {

        // eslint-disable-next-line no-console
        console.log('Error in search visits', e);

    }
    return visitList;

};

export const HistoryVisits = React.memo(
    // eslint-disable-next-line max-statements
    ({
        needHeader = true,
        profileId: pid,
        clickable = true,
        detailScreen = 'Visit',
        addData = [],
        FutureVisitItem,
        customDrawElement,
    }) => {

        const history = useSelector(historyVisits);
        const visitsData = useSelector(historyVisitsList);
        const [data, setData] = useState([]);
        const [visits, setVisits] = useState([]);
        const [textFilter, setTextFilter] = useState('');
        const isLoading = useSelector(getIsPastVisitsLoading);
        useFocusEffect(() => {

            actions.setFormValue('isVisitsGenerated',false);
            setTimeout(() => {

                actions.setFormValue('isVisitsGenerated', true);

            }, 1000);

        });

        useEffect(() => {

            actions.setFormValue('isVisitsGenerated', false );

            if (!visitsData) {

                setData([...addData]);
                setTimeout(() => {

                    actions.setFormValue('isVisitsGenerated', true);

                }, 100);
                return;

            }
            if (pid) {

                setData([
                    ...sortBy(addData?.filter?.(i => i?.profileId === pid) ?? [], '-endDate'),
                    ...sortBy(
                        Array.isArray(visitsData)
                            ? visitsData?.filter?.(i => i?.profileId === pid) ?? []
                            : visitsData?.visits?.filter?.(i => i?.profileId === pid) ?? [],
                        '-endDate',
                    ),
                ]);

            } else {

                const result = Array.isArray(visitsData)
                    ? [...sortBy(addData ?? [], '-endDate'), ...sortBy(visitsData, '-endDate')]
                    : [...sortBy(addData ?? [], '-endDate'), ...sortBy(visitsData?.visits ?? [], '-endDate')];
                setData(result);

            }
            setTimeout(() => {

                actions.setFormValue('isVisitsGenerated', true);

            }, 100);

        }, [visitsData, pid]);
        const navigation = useNavigation();
        const enableLoadPastsVisits = useSelector(getEnableLoadPastsVisits);
        const userInfo = useSelector(getUserInfo);
        const {
            profileId, isAuthenticated, visits: allVisits, emailUnconfirmed,
        } = history;

        useEffect(() => {

            if (!allVisits) {

                setVisits([]);

            }
            if (pid) {

                setVisits(allVisits?.filter?.(i => i?.profileId === pid));

            } else {

                setVisits(allVisits);

            }

        }, [allVisits, pid]);

        useReduxStoreCollection(profileId, visits);
        useEffect(() => {

            if (!isAuthenticated) {

                setTextFilter('');

            }

        }, [isAuthenticated]);

        const allVisitsData = useCallback(
            () => (isAuthenticated ? filteredVisits(visits, textFilter, userInfo, addData)?.map((v) => {

                if (v?.endDate) {

                    return v;

                }
                return {
                    ...v,
                    endDate: v?.startDate,
                };

            }) : []),
            [visits, textFilter, userInfo, addData, isAuthenticated],
        );

        if (!isAuthenticated) {

            return isAuthenticated === undefined ? null : (
                <Section isLoading={isLoading} title="my-card.visitsHeader" style={{ marginBottom: 0 }}>
                    <TextWithAuth id="notes.you_have_no_visitsLoading" paddingLeft={10} />
                </Section>
            );

        }

        const updateVisitsHandler = () => {

            navigation.navigate('LoadVisits');

        };

        const HeaderComponent = () => {

            const { profileEmpty: empty } = useSelector(historyVisits);
            const haveRelatives = useSelector(isProfileHaveRelatives);
            return (
                <>
                    {empty && !haveRelatives ? (
                        <>
                            <MyCardTabSelector />
                            <FillProfile unconfirmedEmails={emailUnconfirmed} paddingLeft={0} />
                        </>
                    ) : (
                        <>
                            <ListHeader
                                onSearch={setTextFilter}
                                enableLoadPastsVisits={enableLoadPastsVisits}
                                needTabs={needHeader}
                            />
                            <EmptyHistoryText
                                onSyncVisit={enableLoadPastsVisits ? updateVisitsHandler : null}
                                clickable={clickable}
                            />
                        </>
                    )}
                </>
            );

        };

        if (data && (!data?.length || (!Array.isArray(data) && !data?.visits?.length)) && !isLoading) {

            return (
                <VisitsList
                    listStyles={{ paddingHorizontal: 12 }}
                    ListHeaderComponent={<HeaderComponent />}
                    visits={!Array.isArray(data) ? data?.visits : data}
                    onSyncVisit={enableLoadPastsVisits ? updateVisitsHandler : null}
                    keyword={textFilter.trim().length > 2 ? textFilter.trim() : ''}
                    emptyComponent={<></>}
                    isInPastVisitScreen
                    clickable={clickable}
                    detailScreen={detailScreen}
                    customDrawElement={customDrawElement}
                />
            );

        }

        return (
            <VisitsList
                listStyles={{ paddingHorizontal: 12 }}
                ListHeaderComponent={
                    <ListHeader
                        onSearch={(t) => {

                            setTextFilter(t);

                        }}
                        enableLoadPastsVisits={enableLoadPastsVisits}
                        needTabs={needHeader}
                    />
                }
                visits={allVisitsData()}
                onSyncVisit={enableLoadPastsVisits ? updateVisitsHandler : null}
                keyword={textFilter.trim().length > 2 ? textFilter.trim() : ''}
                isInPastVisitScreen
                clickable={clickable}
                detailScreen={detailScreen}
                FutureVisitItem={FutureVisitItem}
                customDrawElement={customDrawElement}
            />
        );

    },
);

const EmptyHistoryText = ({ onSyncVisit, keyword, clickable = true }) => {

    const data = useSelector(getNumberOfActiveVisitsFilters);
    const { isAuthenticated, info } = useSelector(getUser);
    const isLoading = useSelector(getIsPastVisitsLoading);
    const isEmptyProfile = useSelector(isProfileEmpty);
    const haveRelatives = useSelector(isProfileHaveRelatives);
    const isUnconfirmedEmails = useSelector(getIsEmailUnconfirmed);
    const navigation = useNavigation();
    const formVal = useSelector(getFormValues);
    const { isVisitsGenerated } = formVal ?? {};

    const [loading, setLoading] = useState(true);

    const [isEmpty, setIsEmpty] = useState(isEmptyProfile && !haveRelatives);

    useEffect(() => {

        setIsEmpty(isEmptyProfile && !haveRelatives);

    }, [isEmptyProfile, haveRelatives]);

    useEffect(() => {

        if (!isLoading && isVisitsGenerated) {

            setTimeout(() => {

                setLoading(false);

            }, 100);

        } else {

            setLoading(true);

        }

    }, [isLoading, isVisitsGenerated]);

    const style = EStyleSheet.create({
        link: {
            color: '$appTitleColor',
            fontSize: 14,
        },

        text: {
            color: '$lightGray',
            fontSize: 14,
        },
    });

    const fillProfile = () => {

        navigation.navigate(isUnconfirmedEmails ? 'Account' : 'EditAccount');

    };

    if (keyword) {

        return <TextWithAuth id="titles.visitsNotFoundSearch" paddingLeft={20} />;

    }

    if (isAuthenticated && !isEmpty && info?.birthday && !isAdult(info?.birthday)) {

        return <TextWithAuth paddingLeft={0} id="titles.visitsForAdultsOnly" />;

    }
    return (
        <>
            <TextWithAuth
                id={
                    data > 0
                        ? `titles.visitsNotFound${loading ? 'Loading' : ''}`
                        : `notes.you_have_no_visits${loading ? 'Loading' : ''}`
                }
                paddingLeft={0}
            />
            {!!onSyncVisit && isAuthenticated && !loading && !!clickable && (
                <Text style={{ paddingHorizontal: 0 }} numberOfLines={0}>
                    <Text style={style.text}>
                        {Object.R(isEmpty ? 'titles.fillProfileSyncHint' : 'titles.loadVisitsHint')}
                    </Text>
                    { }
                    {isEmpty ? (
                        <TouchableWithoutFeedback
                            onPress={fillProfile}
                            style={{
                                padding: 0,
                                margin: 0,
                            }}>
                            <Text style={style.link}>{Object.R('titles.fillProfileSyncLink')}</Text>
                        </TouchableWithoutFeedback>
                    ) : (
                        <TouchableWithoutFeedback
                            onPress={onSyncVisit}
                            style={{
                                padding: 0,
                                margin: 0,
                            }}>
                            <Text style={style.link}>{Object.R('titles.loadVisitsLink')}</Text>
                        </TouchableWithoutFeedback>
                    )}
                </Text>
            )}
        </>
    );

};
