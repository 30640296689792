import { personId1, personId2 } from '../const.stub';

export default {
    '5c4eba6640a01b31c4babf43': {
        _id: '5c4eba6640a01b31c4babf43',
        userProfileId: personId1,
        userName: 'Преображе́нский personId1',
        forItemKind: 'physicians',
        forItemId: '5c4702bd16875a39f',
        createdAt: '2019-01-28T08:16:38.517Z',
        feedbackText: '+',
        anonymous: false,
    },
    '5c4eba7a40a01b31c4babf44': {
        _id: '5c4eba7a40a01b31c4babf44',
        userName: 'Пользователь 688',
        forItemKind: 'physicians',
        forItemId: '5c4702bd16875a39f',
        createdAt: '2019-01-28T08:16:58.746Z',
        feedbackText: '+',
        anonymous: true,
    },
    '5c518b1152faff0011494ffa': {
        _id: '5c518b1152faff0011494ffa',
        userProfileId: personId2,
        userName: 'Борменталь personId2',
        forItemKind: 'physicians',
        forItemId: '5c4702bd16875a39f',
        createdAt: '2019-01-30T11:31:29.939Z',
        feedbackText: 'Tut',
        anonymous: false,
    },
    '5c59503952faff0012a1863d': {
        _id: '5c59503952faff0012a1863d',
        userProfileId: '5c59955e52faff0012a18654',
        userName: 'Янучек Алёна Александровна',
        forItemKind: 'physicians',
        forItemId: '5c4702bd16875a39f',
        createdAt: '2019-02-05T08:58:33.55Z',
        feedbackText: 'Хороший врач',
        anonymous: false,
    },
    '5c59d60252faff0012a18677': {
        _id: '5c59d60252faff0012a18677',
        userProfileId: '5c542aaa52faff001294083d',
        userName: 'Лицкевич Александр Михайлович',
        forItemKind: 'physicians',
        forItemId: '5c4702bd16875a39f',
        createdAt: '2019-02-05T18:29:22.741Z',
        feedbackText: 'csc',
        anonymous: false,
    },
    '5c5ad22352faff0010d6ffe3': {
        _id: '5c5ad22352faff0010d6ffe3',
        userName: 'Свердлова Свердла Свердловна',
        forItemKind: 'physicians',
        forItemId: '5c4702bd16875a39f',
        createdAt: '2019-02-06T12:25:07.698Z',
        feedbackText: 'Арлп',
        anonymous: true,
    },
    '5c5ad7ae52faff0010d6ffec': {
        _id: '5c5ad7ae52faff0010d6ffec',
        userName: 'Янучек Алёна Александровна',
        forItemKind: 'physicians',
        forItemId: '5c4702bd16875a39f',
        createdAt: '2019-02-06T12:48:46.459Z',
        feedbackText: 'Хороший',
        anonymous: true,
    },
    '5c5ad85552faff0010d6ffed': {
        _id: '5c5ad85552faff0010d6ffed',
        userName: 'Янучек Алёна Александровна',
        forItemKind: 'physicians',
        forItemId: '5c4702bd16875a39f',
        createdAt: '2019-02-06T12:51:33.01Z',
        feedbackText: 'Й',
        anonymous: true,
    },
    '5c5adb3052faff0010d6fff5': {
        _id: '5c5adb3052faff0010d6fff5',
        userName: 'Янучек Алёна Александровна',
        forItemKind: 'physicians',
        forItemId: '5c4702bd16875a39f',
        createdAt: '2019-02-06T13:03:44.33Z',
        feedbackText: 'Й',
        anonymous: true,
    },
    '5c5adb3c52faff0010d6fff7': {
        _id: '5c5adb3c52faff0010d6fff7',
        userName: 'Янучек Алёна Александровна',
        forItemKind: 'physicians',
        forItemId: '5c4702bd16875a39f',
        createdAt: '2019-02-06T13:03:56.544Z',
        feedbackText: 'Й',
        anonymous: true,
    },
    '5c5adb4a52faff0010d6fff8': {
        _id: '5c5adb4a52faff0010d6fff8',
        userName: 'Янучек Алёна Александровна',
        forItemKind: 'physicians',
        forItemId: '5c4702bd16875a39f',
        createdAt: '2019-02-06T13:04:10.106Z',
        feedbackText: 'Й',
        anonymous: true,
    },
    '5c5adb4b52faff0010d6fff9': {
        _id: '5c5adb4b52faff0010d6fff9',
        userName: 'Янучек Алёна Александровна',
        forItemKind: 'physicians',
        forItemId: '5c4702bd16875a39f',
        createdAt: '2019-02-06T13:04:11.823Z',
        feedbackText: 'Й',
        anonymous: true,
    },
};
