// noinspection JSUnusedGlobalSymbols

import userService from '../services/user';
import { isFutureVisit } from '../utils';
import { decryptCached } from '../utils/crypto';
import { createReducerFor } from './utils';

const type = 'PATS_VISITS';

const getHashOfPastVisits = (pastVisits) => {
    return pastVisits ? pastVisits.map(e => e.id + e.data)
        .join('') : '';
};
let pastVisitsFromFirebase;

export const setPastVisits = pastVisits => async (dispatch, getState) => {
    let loading = false;
    try {
        loading = getState()?.pastVisits?.isLoading;
    } catch (e) {
        //
    }
    if (!pastVisits || !pastVisits?.length) {
        dispatch(({
            type,
            payload: {
                error: null,
                isLoading: false,
                data: null,
            },
        }));
    }
    const visitsFromState = getHashOfPastVisits(pastVisitsFromFirebase);
    const visits = getHashOfPastVisits(pastVisits);
    if (visitsFromState !== visits) {
        pastVisitsFromFirebase = pastVisits;
        dispatch(({
            type,
            payload: {
                error: null,
                isLoading: true,
            },
        }));
        try {
            const userInfo = await userService.getUserInfo();
            const { hashKey } = userInfo;
            const decrypted = pastVisits.map(e => decryptCached(e.data, hashKey));
            const otherVisits = decrypted.filter(({
                targetDate,
                status,
            }) => !(isFutureVisit(targetDate) && status === 'reserved'));
            dispatch(({
                type,
                payload: {
                    error: null,
                    isLoading: false,
                    data: otherVisits,
                },
            }));
        } catch (e) {
            dispatch(({
                type,
                payload: {
                    error: e,
                    isLoading: false,
                },
            }));
        }
    } else if (loading) {
        dispatch(({
            type,
            payload: { isLoading: false },
        }));
    }
};

export const startLoadingPastVisits = () => dispatch => dispatch(({
    type,
    payload: { isLoading: true },
}));
export const stopLoadingPastVisits = () => dispatch => dispatch(({
    type,
    payload: { isLoading: false },
}));

export default createReducerFor(type);
