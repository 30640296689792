import { BigButton, Logo, Page, React, Title, View } from '../../common';
import { getUserLoadingState } from '../../selectors';
import { AppHeader } from '../../combo';
import { storage } from '../../services/localstorage';
import { back, replace } from '../../actions';
import { syncUserState } from '../../init';
import { useBackHandler } from '../../utils/routing';
import { initUserProfile } from '../../services/user.utils';
import { COUNTRY_CODE } from '../../config';
import { useState } from 'react';
import { Agreements } from '../../common/Agreemants';
import api from '../../services/api';

export const AccountReset = Page.register(
    ({
        route: {
            params: { navigatedFromScreen, deviceIdOfExistedUser },
        },
        isLoading,
        setState,
        navigation,
        phonePrefix = COUNTRY_CODE,
        phoneNumber = '',
        error,
        isAgree,
        onDidMount = () => storage.getObject('phoneNumber', '').then((phone) => setState({ phoneNumber: phone })),
        onSubmit = async () => {
            const result = await initUserProfile(deviceIdOfExistedUser || true);
            await api.updateUserInfo({ consentReceived: true });
            if (result instanceof Error) {
                setState({ error: result });
            } else {
                await syncUserState();
                if (navigatedFromScreen === 'Account') {
                    navigation.replace('ChooseProgram');
                } else {
                    navigation.replace('Account');
                    navigation.navigate('ChooseProgram');
                }
            }
        },
    }) => {
        const backHandler = React.useCallback(
            () => (navigatedFromScreen === 'Account' ? back() : replace(navigatedFromScreen)),
            [navigatedFromScreen],
        );
        useBackHandler(backHandler);
        return (
            <Page name="reset-account" isLoading={isLoading} onDidMount={onDidMount} notification={error} safeArea>
                <Logo.Wrapper
                    wrapperStyle={{
                        marginTop: 16,
                        padding: 8,
                        alignItems: 'center',
                    }}>
                    <Title
                        bold
                        centered
                        id="titles.creatingNewProfileForNumber"
                        style={{
                            marginTop: 32,
                            fontSize: 18,
                        }}
                    />
                    <Title style={{ marginTop: 18 }}>{`${phonePrefix} ${phoneNumber}`}</Title>
                    <Title
                        id="titles.userWithNumberWasRegistered"
                        style={{
                            color: '#BBBBBB',
                            textAlign: 'center',
                            marginTop: 8,
                        }}
                    />
                    <Title
                        id="titles.canCreateProfileIfNumberOwner"
                        style={{
                            color: '#BBBBBB',
                            textAlign: 'center',
                            marginTop: 24,
                        }}
                    />
                    <View style={{ width: '100%' }}>
                        <Agreements
                            userAgreement
                            personalDataAgreement
                            onChange={(agree) => setState({ isAgree: agree })}
                        />
                    </View>
                    <BigButton
                        title="buttons.createNewProfile"
                        action={onSubmit}
                        styles={{ marginTop: 36 }}
                        disabled={!isAgree}
                        disabledStyle={{ marginTop: 36 }}
                    />
                </Logo.Wrapper>
            </Page>
        );
    },
    { isLoading: { from: getUserLoadingState } },
    ({ navigation, route: { params = {} } }) => ({
        header: () => (
            <AppHeader
                navigation={navigation}
                backPress={() =>
                    params.navigatedFromScreen === 'Account' ? back() : replace(params.navigatedFromScreen)
                }
            />
        ),
    }),
);
