/* eslint-disable no-underscore-dangle */
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
    dataFrom,
    Icon,
    Page,
    React,
    Section,
    selectOptions,
    View,
    Platform,
    Keyboard,
    FlatList,
    Subtitle,
} from '../../common';
import actions from '../../actions';
import {
    getCurrentLocation,
    getDoctorsForServiceWithSort,
    getFetchingSyncAllPhysicians,
    getIsServicesDoctorsLoading,
    isHasUMSListTimetable,
} from '../../selectors';
import { ProgramSelector } from '../../combo';
import { UmsServiceCard } from '../UMS/snippets';
import { getFromStoreDb, putIntoStore } from '../../store';
import { doctorSortOptions } from '../../selectors/utils';
import { SCHEME } from '../../scheme';
import { arrayToHash, curry } from '../../utils';
import tracking from '../../services/tracking';
import { PageSpinner } from '../../common/PageSpinner';
import { DoctorsListItem } from '..';
import { pricesIncludeNaN } from '../MedCenters/snippets';
import { doctorsSnippets } from './style';
import { fetchPhysiciansData, getPriceRangeStringFromArr } from './utils';

const NS = 'doctors';

const onSort = curry(putIntoStore, SCHEME.UMS_PHYSICIANS_SORT_BY);

const showSortModal = () => {
    const selectedSort = getFromStoreDb(SCHEME.UMS_PHYSICIANS_SORT_BY);
    const doctorSort = doctorSortOptions();
    const selectedSortId = selectedSort ? selectedSort.id : doctorSort[0].id;
    selectOptions({
        title: 'titles.sort_list_of_doctors',
        selected: selectedSortId,
        data: doctorSort,
        onSelect: e => Page.closeModal(() => {
            onSort(e);
            tracking.logEvent(`ai_ums_doctor_ls_${e?.id}`);
        }),
    });
};

const SortIcon = () => <Icon.Sort onPress={showSortModal} />;

const TheMenu = () => (
    <View style={doctorsSnippets.menu}>
        <SortIcon />
    </View>
);

const ListSectionHeaderImpl = ({ countOf, title = !countOf ? 'titles.loadingData' : 'doctors' }) => {
    const isAllSyncRunning = useSelector(getFetchingSyncAllPhysicians);
    return (
        <Section
            title={isAllSyncRunning ? 'titles.all_doctors_sync' : title}
            countOf={!countOf || isAllSyncRunning ? undefined : countOf}
            right={<TheMenu />}
            isLoading={!countOf || isAllSyncRunning}
        />
    );
};

const ListSectionHeader = dataFrom(getDoctorsForServiceWithSort)(({ data }) => (
    <ListSectionHeaderImpl countOf={data} />
));
const ListHeader = ({
    serviceName, clinicName, clinicAddres, prices = [],
}) => {
    const priseStr = `${Object.R('titles.price')} ${getPriceRangeStringFromArr(prices)}`;
    // const { serviceName, clinicName, clinicAddres } = useSelector(getServiceInfoMc) || {};
    return (
        <View>
            <ProgramSelector withInfo />
            <UmsServiceCard name={serviceName} withBottomBorder>
                {prices?.length && !pricesIncludeNaN(prices) ? (
                    <Subtitle style={{ marginTop: 6 }}>{priseStr}</Subtitle>
                ) : null}
            </UmsServiceCard>
            <UmsServiceCard name={clinicName} withBottomBorder>
                <Subtitle style={{ marginTop: 6 }}>{clinicAddres}</Subtitle>
            </UmsServiceCard>
            <ListSectionHeader />
        </View>
    );
};

export const getSpecialities = (data) => {
    const spec = arrayToHash(data, 'specialization');
    return Object.keys(spec);
};

const DataList = ({ clinicId, header }) => {
    const data = useSelector(getDoctorsForServiceWithSort);
    const specialities = getSpecialities(data);
    const location = useSelector(getCurrentLocation);
    fetchPhysiciansData(location?.code, specialities);

    const onMomentumScrollBegin = () => {
        Keyboard.dismiss();
    };
    const renderItem = ({ item }) => (
        <DoctorsListItem
            doctorInfo={item}
            key={item.id}
            noHighlight
            mcId={clinicId}
            hasTimetableSource={state => isHasUMSListTimetable(state, { location: location?.code, specialty: item?.specialization })
            }
        />
    );
    return (
        <FlatList
            style={{
                height: Platform.OS === 'web' ? 0 : undefined,
                flex: 1,
            }}
            ListHeaderComponent={header}
            accessibilityLabel="list"
            data={data}
            scrollEventThrottle={8}
            onMomentumScrollBegin={onMomentumScrollBegin}
            renderItem={renderItem}
            keyExtractor={item => item.id}
            ListEmptyComponent={null}
            initialNumToRender={5}
            windowSize={5}
            maxToRenderPerBatch={20}
            bounces={false}
        />
    );
};

export const UMSDoctorsList = Page.register(
    ({
        serviceIds, clinicId, clinicName, clinicAddres, serviceName, prices,
    }) => {
        useEffect(() => {
            actions.getDoctorsForServices(serviceIds, [clinicId]);
            return actions.resetUMSDoctors;
        }, [serviceIds, clinicId]);
        const isDoctorsLoading = useSelector(getIsServicesDoctorsLoading);
        const DoctorsListHeader = () => (
            <ListHeader serviceName={serviceName} clinicName={clinicName} clinicAddres={clinicAddres} prices={prices} />
        );
        return isDoctorsLoading ? (
            <PageSpinner withLogo={false} />
        ) : (
            <Page name={NS} noscroll>
                <DataList clinicId={clinicId} header={DoctorsListHeader} />
            </Page>
        );
    },
);
