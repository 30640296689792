import { Platform } from 'react-native';
import { useState } from 'react';
import { AVATAR_TYPES_IMAGES, RANDOM_COLORS } from '../assets';
import {
    Image, React, Text, View,
} from './react';
import { circleStyle } from './style';
import { CoverableBy, Img } from './Img';
import { Icon } from './Icon';

export const Avatar = React.memo(({
    imageUrl,
    of = '',
    bigger,
    coverer,
    size = bigger ? 100 : 60,
    style: st,
    isLimited,
    webK = 1.08,
}) => {
    const [errorOccurred, setError] = useState(false);
    return (
        <View style={{
            alignItems: 'center',
            width: size,
            height: Platform.OS === 'web' ? size * webK : size,
        }} accessibilityLabel="avatar">
            {imageUrl || of ? <Image
                style={[circleStyle(size), st]}
                source={(!errorOccurred && imageUrl) ? { uri: imageUrl } : AVATAR_TYPES_IMAGES[of]}
                defaultSource={AVATAR_TYPES_IMAGES[of]}
                onError={() => setError(true)}
            /> : <Img.UserAvatar style={{
                width: size,
                height: size,
            }}/>}
            {
                !!coverer && <CoverableBy coverer={coverer} containerStyles={{
                    bottom: 6,
                    width: '100%',
                    alignItems: 'center',
                }} isLimited={isLimited}/>
            }
        </View>
    );
});

export const LetterAvatar = ({
    letters = '',
    index = 0,
    size = 50,
    style: st,
    colors = RANDOM_COLORS[index % RANDOM_COLORS.length],
    withEditBadge,
    grey,
}) => (
    <View>
        <View
            accessibilityLabel="avatar-section"
            style={[st, circleStyle(size), colors,
                {
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderWidth: 3,
                },
            ]}
        >
            <Text
                style={{
                    fontSize: ((2 * size) / 5),
                    flexDirection: 'row',
                    alignItems: 'center',
                    color: '#fff',
                }}
            >
                {letters}
            </Text>
        </View>
        {withEditBadge
            ? <Icon.EditCircle grey={grey}/>
            : null
        }
    </View>
);
