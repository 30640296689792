// noinspection JSUnusedGlobalSymbols

import { createReducerFor } from './utils';
import userService from '../services/user';
import { decryptCached } from '../utils/crypto';
import firebase from '../services/firebase';
import api from '../services/api';
import { rescheduleVisitNotifications } from '../services/notifications';
import { debouncer } from '../utils';
import { putIntoStore, SCHEME } from '../store';

const type = 'VISITS';
export const CREATED_MANUALLY = 'created_manually';

const updateOldVisits = (visits, userInfo) => {

    // EPMAIMD-5300. this code section needs while users have old personIds value in local storage
    if (visits && visits.length) {

        const people = [userInfo.me, ...(userInfo.relatives || [])];
        if (people.length) {

            people.forEach(({ profileId }) => {

                visits.forEach(visit => (visit.personId === profileId && visit.profileId !== profileId
                    ? Object.assign(visit, { profileId })
                    : null));

            });

        }

    }
    return visits;

};

const debounce = debouncer(10000);
/**
 * Forces visits data re-query on backend.
 * Called at user sync.
 */
export const pingVisits = () => async (dispatch, getState) => {

    const { visits: { data: prevVisits } } = getState();

    setTimeout(api.pingVisits, 100);

    dispatch({
        type,
        payload: {
            error: null,
            data:
                prevVisits &&
                prevVisits.map(e => ({
                    ...e,
                    updated: false,
                })),
            lastPingStarted: Date.now(),
        },
    });

};

export const resetVisits = () => async (dispatch) => {

    dispatch({
        type,
        payload: {
            error: null,
            data: null,
            isLoading: false,
        },
    });

};

export const addImportedVisit = visit => (dispatch, getState) => {

    const { importedVisits = [] } = getState()?.visits ?? {};
    const newState = [...importedVisits, visit];
    dispatch({
        type,
        payload: {
            importedVisits: newState,
            clearImported: false,
        },
    });

};

export const clearImportedVisits = () => (dispatch) => {

    dispatch({
        type,
        payload: { importedVisits: [] },
    });

};

/**
 * Synchronizes visits data from firebase profile document.
 * Called every time profile document is changed.
 */
export const syncVisits = visitList => async (dispatch, getState) => {

    try {

        const userInfo = await userService.getUserInfo();
        const { hashKey, storeKey } = userInfo;
        const { visits: { lastPingStarted, clearImported = false } } = getState();

        if (!hashKey || !storeKey) {

            dispatch({
                type,
                payload: {
                    error: null,
                    data: null,
                    isLoading: false,
                },
            });
            return;

        }
        // console.warn(`syncVisits : time=${Date.now()} `);

        dispatch({
            type,
            payload: {
                error: null,
                isLoading: true,
            },
        });

        const encrypted = visitList || (await firebase.getUserProfile(storeKey)).visitList || [];

        const decrypted = encrypted.map(e => decryptCached(e, hashKey));

        const data = updateOldVisits(decrypted, userInfo).map(e => ({
            ...e,
            updated: e.lastUpdated >= lastPingStarted,
            isManageable:
                userInfo?.relatives?.find(r => r.profileId === e.profileId)?.isVisitsManageable ||
                e.profileId === userInfo?.userId,
        }));

        // debounce to avoid massive serie of re-scheduling in case of many providers.
        debounce(() => rescheduleVisitNotifications(data));

        // const actualData = data.filter(e => (isFutureVisit(e.endDate) && e.status === 'reserved'));
        const someUpdated = data.some(e => e.updated || e.status === CREATED_MANUALLY);

        const allVisitsAreUnmanageable = !data.some(e => e.isManageable);
        const clear = clearImported ? { importedVisit: [] } : { clearImported: true };
        dispatch({
            type,
            payload: {
                data,
                isLoading: !!(data.length && !someUpdated && !allVisitsAreUnmanageable),
                ...clear,
            },
        });

    } catch (ex) {

        dispatch({
            type,
            payload: {
                error: ex,
                isLoading: false,
            },
        });

    }

};

const getError = (result) => {

    try {

        return JSON.parse(result?.data)?.title ?? 'Error';

    } catch {

        return 'Error';

    }

};

const getFullError = (result) => {

    try {

        return JSON.parse(result?.data);

    } catch {

        return result?.data;

    }

};

export const loadVisits =
    ({
        profileId, clinicId, onError, onSuccess, profiles = [],
    }) => async (dispatch) => {

        try {

            dispatch({
                type,
                payload: { isLoadingOrders: true },
            });
            const res = await api.loadVisits(profileId, clinicId, profiles);
            const data = await res.info();

            if (data.status === 200) {


                onSuccess && onSuccess();

            }
            if (data?.status > 399) {


                onError && onError(data?.message || getError(res), getFullError(res));

            }

        } catch (e) {

            // eslint-disable-next-line no-console
            console.log('Error load visits', e);

            onError && onError(e);

        }
        dispatch({
            type,
            payload: { isLoadingOrders: false },
        });

    };

export const startResultOrReportLoading = () => dispatch => dispatch({
    type,
    payload: { isResultOrReportLoading: true },
});

export const stopResultOrReportLoading = () => dispatch => dispatch({
    type,
    payload: { isResultOrReportLoading: false },
});

export const setResultOrReportError = error => dispatch => dispatch({
    type,
    payload: { error },
});

export const resetVisitsFilter = () => {

    try {

        putIntoStore(SCHEME.VISITS_FILTER, {
            visitors: [],
            byAttachments: false,
            byReports: false,
            clinics: [],
            doctors: [],
        });
        putIntoStore(SCHEME.VISITS_PREFILTER_VISITORS, []);
        putIntoStore(SCHEME.VISITS_PREFILTER_CLINICS, []);
        putIntoStore(SCHEME.VISITS_PREFILTER_DOCTORS, []);
        putIntoStore(SCHEME.VISITS_PREFILTER_ATTACHMENTS, false);
        putIntoStore(SCHEME.VISITS_PREFILTER_REPORTS, false);

    } catch {
        //
    }

};
export default createReducerFor(type);
