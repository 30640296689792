import { useSelector } from 'react-redux';
import {
    dataFrom, OnMapButton, Page, React, SearchBar, Section, Stub, View,
} from '../../common';
import { putIntoStore, SCHEME } from '../../store';
// import { SCHEME } from '../../scheme';
import { actualMedCenters, getIsFavoriteMedcentersFilterActive, getMedcentersSearch } from '../../selectors';
import { ProgramSelector } from '../../combo';
import { curry, isEmpty, sortBy } from '../../utils';
import { AnimatedHeaderList } from '../../common/AnimatedHeaderList';
import { useHasActualRelatedPrograms } from '../Doctors/utils';
import { useLiveFirebaseCollection } from '../../services/firebase';
import { dbGettr, medcenterSortOptions } from '../../selectors/utils';
import { MedCentersListItem, MedCentersListMenu as Menu } from './snippets';

/**
 * MedCenters list page.
 */

// `onSearch` handler for MedCentersSearchBar.
const onSearch = curry(putIntoStore, SCHEME.MEDCENTERS_SEARCH);

// `MedCentersSearchBar` component.
const MedCentersSearchBar = dataFrom(getMedcentersSearch, ({ data }) => (
    <SearchBar initialValue={data} onSearch={onSearch} placeholder="placeholders.search_medcenters" searchAfter={2} />
));

// `FilterSection` component.
const FilterSection = dataFrom(actualMedCenters, ({ data }) => (
    <Section
        title="titles.medcenters"
        countOf={data || []}
        right={
            <>
                {data?.length ? (
                    <OnMapButton
                        logEventName="ai_btn_on_medcenters_map"
                        route="MedCentersMap"
                        containerStyle={{ flex: 1 }}
                    />
                ) : null}
                <Menu />
            </>
        }
        isLoading={!data}
    />
));

const MedcentersListEmpty = () => dataFrom(getIsFavoriteMedcentersFilterActive, ({ data: isFilterActive }) => (!isEmpty(isFilterActive) ? <Stub.FavoriteMedcenters /> : null));

// List header section
const ListHeader = () => (
    <View>
        <ProgramSelector withInfo />
        <MedCentersSearchBar />
        <FilterSection />
    </View>
);

const renderItem = ({ item }) => <MedCentersListItem mdInfo={item} />;

const useData = () => {

    const data = useSelector(actualMedCenters);
    const sort = useSelector(dbGettr(SCHEME.MEDCENTERS_SORT_BY)) || medcenterSortOptions()[0];
    const feedbackSum = useLiveFirebaseCollection('clinics_feedback_sum', [data]) || {};
    return React.useMemo(() => {

        if (!data) {

            return data;

        }
        const mappedData = data.map(e => Object.assign(Object.create(e?.item ?? e), {
            textFeedbackCount: feedbackSum[e.id]?.textFeedbackCount || 0,
            feedbackCount: feedbackSum[e.id]?.feedbackCount || 0,
        }));
        if (sort?.expr === '-doctorsCounter') {

            return mappedData.sort((a, b) => {

                if (a.doctorsCounter === b.doctorsCounter) {

                    // if (a.branchDoctorsCounter === b.branchDoctorsCounter) {
                    if (a.name === b.name) {

                        return 0;

                    }
                    if (a.name > b.name) {

                        return 1;

                    }
                    return -1;

                }
                return b.doctorsCounter - a.doctorsCounter;

            });

        }
        return sortBy(mappedData, sort?.expr);

    }, [data, feedbackSum, sort?.expr]);

};

export const MedCentersList = Page.register(() => {

    const data = useData();
    const hasPrograms = useHasActualRelatedPrograms();
    return (
        <Page noscroll name="medcenters">

            <AnimatedHeaderList
                data={data}
                ListHeader={ListHeader}
                headerHeight={hasPrograms ? 180 : 110}
                renderItem={renderItem}
                emptyComponentRenderer={MedcentersListEmpty}
                separateHeader={false}
            />
        </Page>
    );

});
