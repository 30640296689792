/* eslint-disable no-underscore-dangle */
// noinspection JSUnusedGlobalSymbols

import api from '../services/api';
import { storage } from '../services/localstorage';
import { user } from '../services/user';
import { asyncActionCreator, createReducerFor } from './utils';

const TYPE = 'ums';

export const initialState = {
    info: {},
    isLoading: true,
};

const UMS = 'ums';

const isUMSNeedsUpdate = (prevVersion, curVersion) => {
    if (!prevVersion) {
        return true;
    }
    const {
        _id,
        modifiedAt,
    } = prevVersion;
    return _id !== curVersion._id || !modifiedAt || !curVersion.modifiedAt || new Date(curVersion.modifiedAt) > new Date(modifiedAt);
};

const equalsKeys = (a, b) => Object.keys(a || {})
    .sort()
    .join(',') === Object.keys(b || {})
    .sort()
    .join(',');


const getUserProgramsFromUMS = async (userPrograms, fromUMS) => {
    const programs = { ...userPrograms };
    let programsResults;
    if (!fromUMS) {
        const programIds = Object.keys(programs);
        programsResults = await Promise.all(programIds.map(programId => api.getUmsProgram(programId)));
    } else {
        programsResults = fromUMS;
    }
    programsResults.forEach(program => program.programs?.forEach((p) => {
        programs[p._id] = {
            ...programs[p._id],
            children: p.children,
            adults: p.adults,
        };
    }));
    return programs;
};

async function sync() {
    let info = await storage.getObject(UMS);
    try {
        const { version: currentVersion } = await api.getUmsVersion() || {};
        const needsUpdate = isUMSNeedsUpdate(info.version, currentVersion);
        if (needsUpdate) {
            const [{
                items,
                options,
                version,
            }, mappings] = await Promise.all([api.getUmsServices(), api.getUmsServicesByProviders()]);
            // program updates are depends on ums version
            // empty coverage will force programs updates too
            info = {
                ...info,
                version,
                catalog: {
                    items,
                    options,
                    mappings,
                },
                coverage: {},
            };
        }

        // add related programs info
        const programs = (await user.getUserInfo()).programs || {};
        const programsIds = Object.keys(programs);
        if (!programsIds.length) {
            Object.assign(info, { coverage: {} });
        } else if (!equalsKeys(programs, info?.coverage)) {
            Object.assign(info, { coverage: {} });
            const programsResults = await Promise.all(programsIds.map(programId => api.getUmsProgram(programId)));
            info.programs = await getUserProgramsFromUMS(programs, programsResults);
            programsResults.forEach(({ coverage }) => {
                Object.assign(info.coverage, coverage);
            });
        } else {
            info.programs = await getUserProgramsFromUMS(programs);
        }
        await storage.set(UMS, info);
    } catch (ex) {
        info = {
            ...info,
            error: ex,
            isLoading: false,
        };
    }
    return info;
}

export const syncUmsInfo = asyncActionCreator(TYPE, 'info', { isLoading: true }, () => sync());
export const setOnlineFilter = isOnline => dispatch => dispatch(({
    type: TYPE,
    payload: { isOnline },
}));

export const getDoctorsForServices = (srvicesIds, clinicIds) => async (dispatch) => {
    if (!srvicesIds || !srvicesIds?.length) {
        dispatch(({
            type: TYPE,
            payload: {
                error: null,
                isDoctorsLoading: false,
                doctors: null,
            },
        }));
    } else {
        dispatch(({
            type: TYPE,
            payload: {
                error: null,
                isDoctorsLoading: true,
            },
        }));
        try {
            const doctors = await api.getServicesDoctors(srvicesIds, clinicIds);
            dispatch(({
                type: TYPE,
                payload: {
                    error: null,
                    isDoctorsLoading: false,
                    doctors,
                },
            }));
        } catch (e) {
            dispatch(({
                type: TYPE,
                payload: {
                    error: e,
                    isDoctorsLoading: false,
                },
            }));
        }
    }
};

export const setCurrentService = info => (dispatch) => {
    dispatch(({
        type: TYPE,
        payload: { serviceInfo: info },
    }));
};

export const setCurrentServiceMcInfo = info => (dispatch) => {
    dispatch(({
        type: TYPE,
        payload: { serviceMc: info },
    }));
};

export const resetUMSDoctors = () => (dispatch) => {
    dispatch(({
        type: TYPE,
        payload: { doctors: null, isDoctorsLoading: undefined },
        // payload: { doctors: null, serviceMc: null },

    }));
};

export default createReducerFor(TYPE, initialState);
