import store from '../store';
import { dial, openURL, tryOpenDevInfo } from '../services/device';
import * as visitActions from './visits';
import * as prefsActions from './prefs';
import * as selectsActions from './selections';
import * as usersActions from './user';
import * as drugsActions from './drugs';
import * as visitInfoActions from './visitInfo';
import * as visitEditActions from './visitEdit';
import * as visitViewActions from './visitView';
import * as feedbackActions from './feedbacks';
import * as umsActions from './ums';
import * as invoicesActions from './invoices';
import * as fetchingActions from './fetching';
import * as testsActions from './tests';
import * as collectionsActions from './collections';
import * as pastVisitsActions from './pastVisits';
import * as forms from './forms';
import * as log from './logs';
import * as services from './services';
import * as doctors from './doctors';

/**
 * Binds redux actions to the store instance.
 * @param {*} actions
 */
const bindActions = (actions) => {
    const r = {};
    Object.keys(actions).forEach((id) => {
        const fn = actions[id];
        r[id] = (...args) => fn?.(...args)?.(store.dispatch, store.getState);
    });
    return r;
};

/**
 * Navigates back.
 */
export function back({ toTop } = {}) {
    if (toTop) {
        store.navigator.popToTop();
    } else {
        store.navigator.goBack(null);
    }
}

/**
 * Navigates into specified route with given params.
 *
 * @param {*} routeName
 * @param {*} params
 */
export function navigate(routeName, params) {
    Object.trackNavigation(routeName);
    // eslint-disable-next-line no-unused-expressions
    store.navigator?.navigate(routeName, params);
}

export const push = (routeName, params) => {
    Object.trackNavigation(routeName);
    store.navigator.push(routeName, params);
};

/**
 *  Replace the current route with a new one
 *
 * @param {*} routeName
 * @param {*} params
 */
export function replace(routeName, params) {
    Object.trackNavigation(routeName);
    // const { routes, routes: { length } } = store.navigator.dangerouslyGetState();
    // const { key } = routes[length - 1];
    store.navigator.replace(routeName, params);
}

export const getNavigator = () => store?.navigator;

export default {
    navigate,
    back,
    replace,
    dial,
    openURL,
    tryOpenDevInfo,
    ...bindActions({
        ...visitActions,
        ...visitInfoActions,
        ...visitEditActions,
        ...visitViewActions,
        ...prefsActions,
        ...selectsActions,
        ...usersActions,
        ...drugsActions,
        ...feedbackActions,
        ...umsActions,
        ...invoicesActions,
        ...fetchingActions,
        ...collectionsActions,
        ...testsActions,
        ...pastVisitsActions,
        ...forms,
        ...log,
        ...services,
        ...doctors,
    }),
};
