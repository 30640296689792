// noinspection JSUnusedGlobalSymbols

import { createReducerFor } from './utils';

const TYPE = 'FETCHING';

export default createReducerFor(TYPE);

export const startFetching = () => dispatch => dispatch({
    type: TYPE,
    payload: { isFetching: true },
});

export const stopFetching = () => dispatch => dispatch({
    type: TYPE,
    payload: { isFetching: false },
});

export const stopCollectionFetching = collName => dispatch => dispatch({ type: TYPE, payload: { [collName]: true } });
export const isAllCollectionFetching = (collName, isFetching) => dispatch => dispatch({ type: TYPE, payload: { [`sync_all_${collName}`]: isFetching } });
export const collectionFetchingTime = (collName, time, funcName) => dispatch => dispatch({ type: TYPE, payload: { [`${collName}_${funcName}`]: time } });
export const onFavoritesLoadFromCollections = value => dispatch => dispatch({ type: TYPE, payload: { isFavoritesLoadFromCollections: value } });

export const localPhysiciansFetching = isLocalPhysiciansFetching => dispatch => dispatch({
    type: TYPE,
    payload: { isLocalPhysiciansFetching },
});

export const physiciansCollectionsFetching = isPhysiciansCollectionsFetching => dispatch => dispatch({
    type: TYPE,
    payload: { isPhysiciansCollectionsFetching },
});

