/* eslint-disable no-underscore-dangle */
import { useSelector } from 'react-redux';
import EStyleSheet from 'react-native-extended-stylesheet';
import { useCallback, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import {
    BigButton,
    Box,
    Button,
    CoverableBy,
    FlatList,
    Icon,
    Img,
    Link,
    List,
    ListItem,
    LoadingIndicator,
    Modal,
    Page,
    Platform,
    Prompt,
    React,
    ScrollView,
    showNotification,
    Subtitle,
    TextHighliter,
    Title,
    TouchableOpacity,
    VBox,
    View,
} from '../../common';
import { InitialsAvatar } from '../../combo';
import { createEvent } from '../../services/calendars';
import {
    checkExternalStorage,
    checkPhotosLibrary,
    copyImageToDocuments,
    existsByPath,
    getIosFileAbsolutePath,
} from '../../services/device';
import actions, { navigate } from '../../actions';
import { capitalize, formatDateLong, isDayNotPast } from '../../utils';
import api from '../../services/api';
import { onShowOnMap } from '../../common/InfoList';
import { notifications } from '../../common/Notifications';
import { onResult } from '../App/snippets/Tests&Visits/snippets';
import { COLORS } from '../../styles/colors';
import { SubtitleHighlight, TitleHighlight } from '../../common/elements';
import { getIsReportsEnable } from '../../selectors';
import tracking from '../../services/tracking';
import { isDateFuture } from '../../utils/dates';
import { CREATED_MANUALLY } from '../../actions/visits';
import { PastVisitFooter } from './snippets/PastVisitFooter';
import {
    actualVisit, plannedVisitsList as style, visitItem, visitModalContent as styles,
} from './style';
import { shareVisit } from './utils';
import { RegistryInformation } from './RegistryInformation';

export const prepareVisit = async (visitInfo, clickable = true) => {

    if (clickable) {

        await actions.resetEditedVisit(visitInfo);

    } else {

        await actions.resetViewedVisit(visitInfo);

    }
    const { attachments = [] } = visitInfo;
    if (attachments && attachments.length) {

        await checkExternalStorage();
        if (Platform.OS === 'ios') {

            const granted = await checkPhotosLibrary();
            if (granted) {

                await Promise.all(
                    attachments.map(async ({ uri, origURL }) => {

                        const exists = await existsByPath(getIosFileAbsolutePath(uri));
                        if (!exists && origURL) {

                            try {

                                await copyImageToDocuments(origURL, uri);

                            } catch (err) {

                                // eslint-disable-next-line no-console
                                console.log('image copy error occurred', err);

                            }

                        }

                    }),
                );

            }

        }

    }

};

const checkVisit = async (visit, errorTitle, errorHint, cb) => {

    const v = { ...visit };
    let assignmentId;
    if (!v.assignmentId && !v?.canManualDelete) {

        const doctor = v?.physicianId;
        let doctorInfo;
        try {

            doctorInfo = await api.getDoctorById(doctor); // firebase.getById('physicians', doctor);
            if (doctorInfo) {

                assignmentId = doctorInfo?.assignments
                    ?.find(({ branchId }) => branchId === v?.branchId)?.assignmentId;
                v.assignmentId = assignmentId;

            }

        } catch (e) {

            //

        }

    }
    if (v.assignmentId || !isDayNotPast(v.targetDate) || v.status === CREATED_MANUALLY) {

        cb(v);

    } else if (
        visit.isFuture
        // (isFutureVisit(v.targetDate) && v.status === 'reserved') ||
        // (isFutureVisit(v.targetDate) && v.status === CREATED_MANUALLY)
    ) {

        // TODO: change to use RegistryInformation component in next release
        Page.showModal(
            <Modal>
                <Title bold centered style={{ fontSize: 16 }} numberOfLines={null} id={errorTitle} />
                <Subtitle
                    centered
                    style={{
                        fontSize: 12,
                        marginTop: 12,
                        paddingHorizontal: 8,
                    }}
                    numberOfLines={null}
                    id={errorHint}
                />
                <Subtitle
                    centered
                    style={{
                        fontSize: 12,
                        marginBottom: 12,
                        paddingHorizontal: 8,
                    }}
                    numberOfLines={null}>{`${formatDateLong(v.startDate, false, false)}${v.time ? `, ${v.time}` : ''}`}</Subtitle>
                {v.mdInfo && v.mdInfo.phones ? (
                    <View>
                        <Subtitle
                            style={styles.registryNumbersSubtitle}
                            id="titles.registry"
                            params={{ name: v.mdInfo.name }}
                        />
                        <ScrollView style={{ maxHeight: '85%' }}>
                            <View
                                style={{
                                    paddingHorizontal: 16,
                                    marginVertical: 12,
                                }}>
                                <List
                                    type="contacts"
                                    data={v.mdInfo.phones}
                                    renderItem={({ item, index }) => (
                                        <View
                                            style={{
                                                borderTopWidth: 1,
                                                borderColor: '#E9E9E9',
                                                borderBottomWidth: index === v.mdInfo.phones.length - 1 ? 1 : 0,
                                            }}>
                                            <Link
                                                style={{ paddingVertical: 14 }}
                                                href={() => actions.dial(item)}
                                                trackingAlias="tel_registry">
                                                {item}
                                            </Link>
                                        </View>
                                    )}
                                />
                            </View>
                        </ScrollView>
                    </View>
                ) : null}
                <View style={{ paddingHorizontal: 14 }}>
                    <BigButton title="button.understand" action={Page.closeModal} />
                </View>
            </Modal>,
        );

    } else {

        Page.showModal(
            <Modal>
                <Title bold centered style={{ fontSize: 16 }} numberOfLines={null} id={errorTitle} />
                <Subtitle
                    centered
                    style={{
                        fontSize: 12,
                        marginVertical: 12,
                        paddingHorizontal: 8,
                    }}
                    numberOfLines={null}
                    id="titles.canDeleteVisitTomorrow"
                />
                <View style={{ paddingHorizontal: 14 }}>
                    <BigButton title="button.understand" action={Page.closeModal} />
                </View>
            </Modal>,
        );

    }

};

const rescheduleVisit = visit => () => {

    checkVisit(visit, 'titles.changeVisit', 'titles.contactRegistryToEditVisit', (v) => {

        navigate('DoctorRecordDatePick', {
            orderId: v.id,
            doctorInfo: v.doctorInfo,
            mdInfo: v.mdInfo,
            profileId: v.profileId,
            canRegister: true,
            canReassign: false,
            covererCode: v.covererCode,
            isEditingVisit: true,
            assignmentId: v.assignmentId,
            serviceId: v.serviceId,
        });

    });

};
const reassignVisitor = visit => () => {

    checkVisit(visit, 'titles.changeVisit', 'titles.contactRegistryToEditVisit', v => navigate('EnrollVisit', {
        // screen: 'EnrollVisit',
        visitInfo: {
            ...v,
            date: v.targetDate,
        },
        canChangeDate: false,
        isEditingVisit: true,
        startDate: v.startDate ? Date.parse(v.startDate) : null,
        endDate: v.endDate ? Date.parse(v.endDate) : null,
        assignmentId: v.assignmentId,
        // serviceId: v.serviceId,
        // serviceName: v.serviceName,
        // serviceDuration: getTimeslotDurationInMinutes(v.startDate, v.endDate),
    }));

};
export const getDeleteVisitAction = v => () => {

    checkVisit(
        v,
        v.isFuture ? 'titles.cancelVisit' : 'titles.delete_visit',
        // isFutureVisit(v.targetDate) && v.status === 'reserved' ? 'titles.cancelVisit' : 'titles.delete_visit',
        'titles.contactRegistryToDeclineVisit',
        // eslint-disable-next-line no-use-before-define
        visit => deleteVisit(visit),
    );

};

export const getDeleteManualVisitAction = v => () => {

    checkVisit(
        v,
        v.isFuture ? 'titles.cancelVisit' : 'titles.delete_visit',
        // isFutureVisit(v.targetDate) && v.status === 'reserved' ? 'titles.cancelVisit' : 'titles.delete_visit',
        'titles.contactRegistryToDeclineVisit',
        // eslint-disable-next-line no-use-before-define
        visit => deleteVisit(visit),
    );

};

const menuItems = v => [
    v.canShowOnMap && {
        id: 'show_on_map',
        handler: () => {

            onShowOnMap(v.mdInfo?.addressLink, v.mdInfo?.address, v.mdInfo?._id, !!v.mdInfo?.latitude);

        },
    },
    Platform.OS !== 'web' && {
        id: 'share',
        handler: () => {

            setTimeout(() => shareVisit(v), 500);

        },
    },
    v.canAddToCalendar && {
        id: 'add_to_calendar',
        handler: () => {

            let ed = v?.endDate;
            try {

                const t = new Date(v.startDate);
                ed = v.endDate || t.setMinutes(t.getMinutes() + 30);

            } catch {
                //
            }
            createEvent(
                v.name,
                v.startDate,
                ed,
                v.address || '',
                v.mdInfo.id
                    ? `${v.medcenterName}\n${v.address}\n${v.dayText}${v.time ? `, ${v.time}` : ''}`
                    : `${v.dayText}${v.time ? `, ${v.time}` : ''}`,
            );

        },
    },
    v.canUpdate && {
        id: 'reschedule_visit',
        handler: rescheduleVisit(v),
    },
    v.canUpdate &&
            v.canReassignVisitor &&
            v.relatives?.some(e => e.isVisitsManageable) && {
        id: 'switch_patient',
        handler: reassignVisitor(v),
    },
    v.canDelete && {
        id: 'cancel_visit',
        handler: getDeleteVisitAction(v),
    },
    v.canManualDelete && {
        id: 'cancel_manual_visit',
        handler: getDeleteManualVisitAction(v),
    },
    // v.canUpdate && v.updated && { id: 'reschedule_visit', handler: rescheduleVisit(v) },
    // v.canUpdate && v.canReassignVisitor && v.updated && v.relatives?.some(e => e.isVisitsManageable) && { id: 'switch_patient', handler: reassignVisitor(v) },
    // v.canDelete && v.updated && { id: 'cancel_visit', handler: getDeleteVisitAction(v) },
].filter(Boolean);

const showMenu = (v, noBottomPadding) => Page.showModal(
    <Modal title={v.name} containerStyle={noBottomPadding && { paddingBottom: 1 }}>
        <ScrollView>
            <Subtitle
                centered
                numberOfLines={4}
                style={{
                    // marginTop: -15,
                    paddingBottom: 12,
                }}>
                {v.mdInfo.id
                    ? `${v.medcenterName}\n${v.address}\n${v.dayText}${v.time ? `, ${v.time}` : ''}`
                    : `${Object.R('titles.unknown')}\n${v.dayText}${v.time ? `, ${v.time}` : ''}`}
            </Subtitle>
            <FlatList
                data={menuItems(v)}
                renderItem={({ item: { id, handler } }) => (
                    <Button
                        title={`buttons.${id}`}
                        styles={styles.modalButton}
                        textStyles={styles.selectModalTitle}
                        action={() => Page.closeModal(handler)}
                    />
                )}
            />
        </ScrollView>
    </Modal>,
);

const deleteVisit = ({
    specialization,
    medcenterName,
    visitItemDate,
    id: orderId,
    // targetDate,
    startDate,
    time,
    status,
    mdInfo,
    mdInfo: { assignmentId, phones },
    isFuture,
    // isFuture = (isFutureVisit(targetDate) && status === 'reserved') ||
    //     (isFutureVisit(targetDate) && status === CREATED_MANUALLY),
    isManual = status === CREATED_MANUALLY,
    profileId,
    covererCode,
    setIsDeleteDisabled = () => {},
    onDeleteComplete = () => {},
    onDeleteFailed = () => {},
    onDeleteStarted = () => {},
    assignmentId: visitAssignmentId,
}) => {

    const text = isFuture
        ? [medcenterName, visitItemDate].join('\n')
        : [specialization, medcenterName, visitItemDate].join('\n');
    const subtitle = isFuture && (isManual ? 'titles.deleteManualVisitConfirmation' : 'notes.visitAdditionalPhrase');
    return Page.showModal(
        <Prompt
            title={Object.R(
                isManual
                    ? 'titles.deleteManualVisitConfirmationTitle'
                    : `titles.${!isFuture ? 'delete' : 'cancel'}_visit`,
            )}
            subtitle={subtitle}
            textInfo={text}
            submitTitle={isFuture ? 'buttons.yes' : 'buttons.delete'}
            cancelTitle={isFuture ? 'buttons.no' : 'buttons.cancel'}
            cancelOptions={{ primary: false }}
            onSubmit={() => {

                setIsDeleteDisabled(true);
                onDeleteStarted();
                api.declineVisit({
                    orderId,
                    assignmentId: assignmentId ?? visitAssignmentId,
                    profileId,
                    startDate,
                    covererCode,
                    errorExtraText: phones?.join?.('\n'),
                })
                    .then(() => {

                        actions.clearImportedVisits();
                        onDeleteComplete();
                        if (isFuture) {

                            notifications.cancelNotificationsByVisitId(orderId);

                        }
                        showNotification(
                            Object.R(isFuture ? 'titles.visitWasCancelled' : 'titles.visitWasDeleted', { visitDate: visitItemDate }),
                            false,
                        );

                    })
                    .catch((error = {}) => {

                        const { code, detail } = error;
                        onDeleteFailed();
                        if (code === 'CODE_VISITOR_DATA_MISMATCH') {

                            Page.showModal(
                                <RegistryInformation
                                    errorTitle={isFuture ? 'titles.visitCancelError' : 'titles.visitDeleteError'}
                                    errorHint={detail}
                                    mdInfo={mdInfo}
                                    startDate={startDate}
                                    time={time}
                                />,
                            );
                            return;

                        }
                        showNotification(
                            `${Object.R(isFuture ? 'titles.visitCancelError' : 'titles.visitDeleteError')}\n${
                                error?.message ?? 'n-a'
                            }`,
                            false,
                        );

                    })
                    .then(() => {

                        setIsDeleteDisabled(false);

                    });

            }}
        />,
    );

};

const textStyles = EStyleSheet.create({
    text: {
        fontSize: 12,
        fontWeight: '400',
        // fontFamily: '$sourceSansPropFontFamily',
        fontFamily: '$defaultFontFamily',
        lineHeight: 15,
    },
});
const subtitleStyle = textStyles.text;

const fontStyles = EStyleSheet.create({
    header: {
        fontFamily: '$sourceSansPropFontFamily_semibold',
        fontWeight: '600',
    },
});

// eslint-disable-next-line complexity
export const VisitListItem = ({
    title,
    mainPart,
    leftPart = () => {},
    visitInfo,
    visitInfo: {
        id,
        targetDate,
        status,
        attachments = [],
        // startDate,
        // endDate,
        isFuture,
        // isFuture = (isFutureVisit(targetDate) && status === 'reserved') ||
        //     (isFutureVisit(targetDate) && status === CREATED_MANUALLY),
        isPast = status === CREATED_MANUALLY && !isDateFuture(new Date(targetDate), false, 0),
        isManual = status === CREATED_MANUALLY,
        customerFirstName,
        updated,
        errorCode,
    },
    borderColorStyle = updated && status !== CREATED_MANUALLY ? 'blueBorderStyles' : 'greyBorderStyles',
    keyword,
    onLayout,
    onGetRef,
    isInPastVisitScreen,
    clickable = true,
    detailScreen = 'Visit',
    customDrawElement,
    hideMenu,
}) => {

    const [isDeleteDisabled, setIsDeleteDisabled] = useState(false);
    const navigation = useNavigation();
    const onPress = React.useCallback(async () => {

        await prepareVisit(visitInfo, clickable);
        actions.setFormData({
            Visit: {
                back: () => {

                    actions.setResultOrReportError();
                    navigation.goBack();
                    return false;

                },
            },
        });
        navigate(detailScreen, {
            visitInfo,
            clickable,
        });

    }, [visitInfo, clickable]);

    const onAttach = React.useCallback(async () => {

        await prepareVisit(visitInfo, clickable);
        if (clickable) {

            navigate('VisitImagesSlider');

        } else {

            navigate(detailScreen, {
                visitInfo,
                clickable,
            });

        }

    }, [visitInfo, clickable]);

    const onMenu = React.useCallback(
        () => showMenu(
            {
                ...visitInfo,
                setIsDeleteDisabled,
                // eslint-disable-next-line no-nested-ternary
                ...(isManual
                    ? {
                        canUpdate: false,
                        canDelete: false,
                        canManualDelete: true,
                    }
                    : customerFirstName || !visitInfo?.visitor?.profileId
                        ? { canUpdate: false }
                        : {}),
            },
            true,
        ),
        [visitInfo, isManual],
    );

    const onDelete = React.useCallback(
        () => checkVisit(
            visitInfo,
            visitInfo.isFuture ? 'titles.cancelVisit' : 'titles.delete_visit',
            'titles.contactRegistryToDeclineVisit',
            () => deleteVisit({
                ...visitInfo,
                setIsDeleteDisabled,
            }),
        ),
        [visitInfo, status],
    );
    return (
        <View
            style={[visitItem.container]}
            onLayout={onLayout}
            ref={(r) => {

                onGetRef?.(r);

            }}>
            {isFuture && (
                <View
                    style={[
                        visitItem.indicator,
                        { borderColor: updated && status !== CREATED_MANUALLY ? COLORS.MAIN : '#e0e0e0' },
                    ]}
                />
            )}
            <View
                style={[
                    isFuture
                        ? style[
                            errorCode && updated && status !== CREATED_MANUALLY
                                ? 'orangeBorderStyles'
                                : borderColorStyle
                        ]
                        : style.borderStyles,
                    visitItem.listItemContainer,
                    { borderLeftWidth: isFuture ? 0 : 1 },
                    isFuture && style.futureLeftBorder,
                ]}>
                <ListItem
                    Component={TouchableOpacity}
                    key={id}
                    accessibilityLabel="item"
                    titleNumberOfLines={1}
                    onPress={onPress}
                    containerStyle={[
                        style.container,
                        {
                            borderRadius: 3,
                            alignItems: 'flex-start',
                        },
                    ]}>
                    {leftPart()}
                    <ListItem.Content style={{ ...style.wrapperStyle }}>
                        <View style={visitItem.contentContainer}>
                            {attachments.length && isFuture ? (
                                <TouchableOpacity style={visitItem.attachContainer} onPress={onAttach}>
                                    <Img.Attachment />
                                </TouchableOpacity>
                            ) : null}
                            <TitleHighlight
                                keyword={keyword}
                                numberOfLines={2}
                                containerStyle={[visitItem.titleHighlight, { paddingLeft: attachments.length ? 2 : 0 }]}
                                style={[style.titleTxt, fontStyles.header]}>
                                {title}
                            </TitleHighlight>
                        </View>
                        <View style={style.subtitleContainer}>{mainPart()}</View>
                        {clickable && !hideMenu ? (
                            <View style={visitItem.menuContainer}>
                                {
                                    // eslint-disable-next-line no-nested-ternary
                                    isManual ? (
                                        isPast && (isInPastVisitScreen === true || isInPastVisitScreen !== false) ? (
                                            <Icon.Delete onPress={onDelete} />
                                        ) : (
                                            <Icon.Menu onPress={onMenu} />
                                        )
                                    ) // eslint-disable-next-line no-nested-ternary
                                        : !targetDate ? null : isDeleteDisabled ? (
                                            <LoadingIndicator indicatorSize={20} style={{ paddingVertical: 0 }} />
                                        ) : isFuture ? (
                                            <Icon.Menu onPress={onMenu} />
                                        ) : (
                                            <Icon.Delete onPress={onDelete} />
                                        )
                                }
                            </View>
                        ) : (
                            <View style={visitItem.menuContainer} />
                        )}
                    </ListItem.Content>
                </ListItem>
            </View>
        </View>
    );

};

export const PlannedVisitListItem = ({
    visitInfo,
    visitInfo: {
        fullName = '',
        visitor,
        visitor: { isMe, subName } = {},
        customerFirstName,
        covererCode,
        specialization,
        medcenterName,
        time,
        dayText,
        isBns,
        physicianName = '',
        service = '',
        errorCode,
        updated,
        speciality,
        serviceName,
        status,
    },
    onGetRef,
    isInPastVisitScreen,
}) => {

    let doctor = null;
    if (isBns) {

        doctor = physicianName ? `${Object.R('titles.doctorIs', { doctor: physicianName })}\n` : '';

    } else {

        doctor = `${fullName}\n`;

    }
    const leftPart = () => (
        <View style={style.leftIconBox}>
            <InitialsAvatar {...visitor} coverer={null} size={48} />
            <Subtitle style={[subtitleStyle, { marginTop: 3 }]}>
                {isMe ? Object.R('titles.my') : subName || (visitor?.fullNameRu ?? '')?.split?.(' ')?.[1] || customerFirstName || ''}
            </Subtitle>
        </View>
    );
    const mainPart = () => {

        return (
            <View style={{ width: '100%' }}>
                {serviceName ? (
                    <Subtitle numberOfLines={1} style={[subtitleStyle]}>
                        {serviceName}
                    </Subtitle>
                ) : null}
                <Subtitle numberOfLines={6} style={[subtitleStyle]}>
                    {`${doctor}${capitalize(
                        status === CREATED_MANUALLY
                            ? speciality ?? specialization ?? service ?? ''
                            : speciality ?? specialization ?? service ?? '',
                    )}`}
                </Subtitle>
                <View
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                    }}>
                    <Subtitle style={[subtitleStyle, { width: '70%' }]} numberOfLines={2}>
                        {medcenterName ?? ''}
                    </Subtitle>
                    <CoverableBy
                        coverer={covererCode}
                        containerStyles={{
                            position: 'absolute',
                            right: 0,
                            bottom: 0,
                        }}
                    />
                </View>
                {errorCode && updated ? <Subtitle.Warning>{Object.R(`error.${errorCode}`)}</Subtitle.Warning> : null}
            </View>
        );

    };

    return (
        <VisitListItem
            visitInfo={visitInfo}
            title={`${capitalize(dayText)} ${time ? `- ${time}` : ''}`}
            leftPart={leftPart}
            mainPart={mainPart}
            onGetRef={onGetRef}
            isInPastVisitScreen={isInPastVisitScreen}
        />
    );

};

export const ActualVisitListItem = React.memo(
    ({
        visitInfo,
        hasDoctorName = false,
        keyword,
        onLayout,
        onGetRef,
        visitInfo: {
            fullName = '',
            visitor,
            specialization,
            medcenterName,
            name,
            // targetDate,
            // status,
            time,
            dayText,
            covererCode,
            isFuture,
            // isFuture = isFutureVisit(targetDate) && (status === 'reserved' || status === CREATED_MANUALLY),
            isBns,
            physicianName,
            service = '',
            errorCode,
            updated,
            speciality,
            reports,
            drugs,
            attachments,
            isReportsManageable,
            serviceName,
        },
        isInPastVisitScreen,
        clickable = true,
        detailScreen = 'Visit',
        customDrawElement,
    }) => {

        const onReadyClick = useCallback(() => {

            tracking.logEvent('ai_result_click');
            onResult(visitInfo, 'reports', true);

        }, [visitInfo, clickable]);
        const onAttachClick = useCallback(async () => {

            if (attachments?.length) {

                await prepareVisit(visitInfo, clickable);
                if (clickable) {

                    navigate('VisitImagesSlider');

                } else {

                    navigate(detailScreen, {
                        visitInfo,
                        clickable,
                    });

                }

            }

        }, [attachments, clickable]);
        const onDrugsClick = React.useCallback(async () => {

            await prepareVisit(visitInfo, clickable);
            navigate(detailScreen, {
                visitInfo,
                clickable,
            });

        }, [visitInfo, clickable]);

        const isReportsEnable = useSelector(getIsReportsEnable);

        const mainPart = () => (
            <VBox style={[actualVisit.container]}>
                {serviceName ? (
                    <TextHighliter style={subtitleStyle} numberOfLines={1} keyword={keyword} value={serviceName} />
                ) : null}
                {hasDoctorName && (
                    <TextHighliter
                        style={subtitleStyle}
                        numberOfLines={0}
                        keyword={keyword}
                        value={isBns ? Object.R('titles.doctorIs', { doctor: physicianName }) : fullName}
                    />
                )}
                {hasDoctorName && (
                    <TextHighliter
                        style={subtitleStyle}
                        value={capitalize(speciality || specialization || service)}
                        keyword={keyword}
                    />
                )}
                <TextHighliter style={subtitleStyle} value={medcenterName} keyword={keyword} numberOfLines={0} />
                {isFuture && errorCode && updated ? (
                    <Subtitle.Warning>{Object.R(`error.${errorCode}`)}</Subtitle.Warning>
                ) : null}
                <Box style={actualVisit.box}>
                    <View style={actualVisit.boxContainer}>
                        <View style={actualVisit.visitorInfoContainer}>
                            <View style={actualVisit.avatarContainer}>
                                <InitialsAvatar {...visitor} coverer={null} size={25} style={actualVisit.avatar} />
                            </View>
                            <View style={actualVisit.nameContainer}>
                                <TextHighliter
                                    style={[subtitleStyle, { borderWidth: 0 }]}
                                    numberOfLines={1}
                                    keyword={keyword}
                                    value={
                                        visitor?.isMe
                                            ? Object.R('titles.my_visit')
                                            : visitInfo?.customerFirstName ?? visitor?.firstLastName
                                    }
                                />
                            </View>
                        </View>
                        <View style={actualVisit.dataAndCoveregeContainer}>
                            <CoverableBy coverer={covererCode} containerStyles={{ marginLeft: 2 }} />
                            {(!isFuture || customDrawElement) && (
                                <SubtitleHighlight textStyle={{ textAlign: 'right' }} keyword={keyword}>{`${dayText} ${
                                    time ? `${Object.R('dates.at')} ${time}` : ''
                                }`}</SubtitleHighlight>
                            )}
                        </View>
                    </View>
                </Box>
                <PastVisitFooter
                    reportsCnt={isReportsEnable && isReportsManageable ? reports?.length : 0}
                    drugsCnt={drugs?.length}
                    attachmentsCnt={attachments?.length}
                    onReadyClick={onReadyClick}
                    onAttachClick={onAttachClick}
                    onDrugsClick={onDrugsClick}
                    keyword={keyword}
                />
            </VBox>
        );
        return (
            <VisitListItem
                visitInfo={visitInfo}
                title={isFuture && !customDrawElement ? `${capitalize(dayText)} ${time ? `- ${time}` : ''}` : name}
                keyword={keyword}
                mainPart={mainPart}
                onLayout={onLayout}
                onGetRef={(r) => {

                    onGetRef?.(r);

                }}
                isInPastVisitScreen={isInPastVisitScreen}
                clickable={clickable}
                detailScreen={detailScreen}
                customDrawElement={customDrawElement}
            />
        );

    },
    (prev, next) => prev.keyword === next.keyword &&
        prev?.visitInfo?.name === next?.visitInfo?.name &&
        prev?.visitInfo?.attachments === next?.visitInfo?.attachments &&
        prev?.visitInfo?.drugs === next?.visitInfo?.drugs &&
        prev?.visitInfo?.reports === next?.visitInfo?.reports,
);
