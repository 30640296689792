import { useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { elementsStyles } from '../../../styles/elements';
import {
    Avatar,
    Icon, Platform, React, Text, TextHighliter, TouchableOpacity, View,
} from '../../../common';
import { COLORS } from '../../../styles/colors';
import { formatDate } from '../../../utils';
import { LikeButton } from '../../../common/LikeButton';
import { ResponseToFeedbackList, showMenu } from '../../Feedbacks/MedCenters/FeedbacksListItem';
import { FeedbackBadge, MAX_FEEDBACK_LENGTH_WEB } from '../../Feedbacks/Doctors/snippets';
import { useSelector } from 'react-redux';
import { getAllFeedbacksMyTextFilter } from '../../../selectors';
import { SubtitleHighlight, TitleHighlight } from '../../../common/elements';

export const AllFeedbackListItem = React.memo(({
    bold = false,
    date = new Date(),
    vote = '',
    feedbackText = '',
    showBorder = false,
    confirmed = false,
    interaction = false,
    responses,
    usefulForMe,
    created,
    doctorInfo,
    editedMode = 'all', // "responses",
    item,
    usefulCount,
    usefulWithoutText = true,
    photoUrl,
    limited,
    coverer,
    forChild,
    forAdult,
    currentForChildren,
    currentForAdults,
    editable = true,
}) => {

    const [expand, setExpand] = useState(false);
    const boldStyle = bold ? elementsStyles.text_bold : {};
    const navigation = useNavigation();
    const textFilter = useSelector(getAllFeedbacksMyTextFilter);

    const getText = (text, exp) => {

        if (Platform.OS !== 'web' || exp || text?.length < MAX_FEEDBACK_LENGTH_WEB) {

            return text;

        }
        return `${text.trim().substring(0, MAX_FEEDBACK_LENGTH_WEB)}...`;

    };

    const isEdited = () => {

        if (editedMode === 'all') {

            try {

                const diff = new Date(date).getTime() - new Date(created).getTime();
                return diff > 1000;

            } catch (e) {

                return false;

            }

        }
        if (!Array.isArray(responses) || !responses.length) {

            return false;

        }

        const lastResponse = responses.sort((a, b) => {

            try {

                const da = new Date(a.updatedAt);
                const db = new Date(b.updatedAt);
                if (da > db) {

                    return 1;

                } else if (db > da) {

                    return -1;

                }

            } catch (e) {
                // console.log('Error sort date', { a, b });
            }
            return 0;

        })[0];
        if (!lastResponse) {

            return false;

        }
        return new Date(lastResponse.updatedAt).getTime() < new Date(date).getTime();

    };

    const DocInfoTouch = ({ children }) => <TouchableOpacity
        onPress={() => {

            navigation.navigate('MainFeedbacks', {
                screen: 'DoctorFeedbacks',
                params: {
                    doctorInfo: {
                        ...doctorInfo,
                        _id: doctorInfo?.id,
                        fullName: doctorInfo?.name ?? doctorInfo?.fullName,
                    },
                },
            });

        }}>{children}</TouchableOpacity>;

    return (
        <View
            style={{
                paddingBottom: 16,
                borderColor: '#E9E9E9',
                borderBottomWidth: showBorder ? 1 : 0,
            }}>
            <View
                style={{
                    flexDirection: 'row',
                    paddingTop: 12,
                }}>
                <View style={{
                    paddingRight: 12,
                    marginTop: -6,
                }}>
                    <DocInfoTouch>
                        <Avatar
                            of="physicians"
                            imageUrl={photoUrl}
                            coverer={
                                coverer && ((forChild && currentForChildren) || (forAdult && currentForAdults))
                                    ? coverer
                                    : ''
                            }
                            isLimited={limited && limited === coverer}
                        />
                    </DocInfoTouch>
                </View>
                <View
                    style={{
                        flexDirection: 'col',
                        justifyContent: 'flex-start',
                        flex: 1,
                    }}>
                    {doctorInfo ? (
                        <DocInfoTouch>
                            <TitleHighlight bold keyword={textFilter}>{doctorInfo.name ?? doctorInfo?.fullName ?? ''}</TitleHighlight>
                        </DocInfoTouch>
                    ) : (
                        <Text
                            style={{
                                fontSize: 16,
                                lineHeight: 17,
                                fontWeight: '400',
                                ...boldStyle,
                                color: COLORS.STANDARD_GRAY,
                                paddingTop: 2,
                            }} />
                    )}
                    {!!doctorInfo && (
                        <SubtitleHighlight
                            keyword={textFilter}
                            style={[
                                {
                                    marginTop: 4,
                                    fontSize: 12,
                                    lineHeight: 15,
                                    fontWeight: '400',
                                    color: COLORS.STANDARD_GRAY,
                                },
                            ]}>
                            {doctorInfo?.specialization}
                        </SubtitleHighlight>
                    )}
                </View>
                <View
                    style={{
                        alignItems: 'flex-end',
                        justifyContent: 'flex-start',
                    }}>
                    <Icon.Menu onPress={() => showMenu(item, editable, true, 'AllFeedbacks')} />
                    {
                        // (!confirmed && !!vote) && isEdited() && <View style={{ alignItems: 'flex-end' }}>
                        //     <Text
                        //         style={{
                        //             fontSize: 12,
                        //             color: COLORS.DARK_GRAY,
                        //         }}>{Object.R('title.feedbackIsEdited')}</Text>
                        // </View>
                    }
                </View>
            </View>
            {!!feedbackText && <View
                style={{
                    paddingTop: 14,
                    flexDirection: !confirmed && isEdited() && vote ? 'row' : 'row',
                    // justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    // borderWidth: 1,
                }}>
                <View
                    style={{
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        alignItems: 'flex-start',
                        flex: 1,
                        padding: 0,
                        margin: 0,
                    }}>
                    <FeedbackBadge
                        vote={vote}
                        style={{
                            marginBottom: confirmed ? 0 : 5,
                            marginRight: vote === '' ? 0 : 5,
                        }}
                    />
                    {!confirmed && (
                        <>
                            <FeedbackBadge vote="NOT_CONFIRMED" />
                        </>
                    )}
                </View>
                {
                    // (!confirmed && !!vote) &&
                    isEdited() && (
                        <View style={{ alignItems: 'flex-end' }}>
                            <Text
                                style={{
                                    fontSize: 12,
                                    color: COLORS.DARK_GRAY,
                                }}>
                                {Object.R('title.feedbackIsEdited')}
                            </Text>
                        </View>
                    )
                }
            </View>}
            {feedbackText ? (
                <View style={{ paddingTop: 12 }}>
                    {interaction ? (
                        <TouchableOpacity onPress={() => setExpand(prevState => !prevState)}>
                            <TextHighliter
                                keyword={textFilter}
                                numberOfLines={expand ? 0 : 5}
                                style={{
                                    fontSize: 14,
                                    lineHeight: 18,
                                    color: COLORS.STANDARD_GRAY,
                                }} value={getText(feedbackText, expand)} />
                        </TouchableOpacity>
                    ) : (
                        <TextHighliter
                            numberOfLines={5}
                            keyword={textFilter}
                            style={{
                                fontSize: 14,
                                lineHeight: 18,
                            }} value={feedbackText} />
                    )}
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}>
                        <>
                            <LikeButton
                                title={
                                    usefulCount
                                        ? `${Object.R('button.useful')} (${usefulCount})`
                                        : Object.R('button.useful')
                                }
                                selected={usefulForMe}
                                disabled={true}
                            />
                        </>
                        <Text
                            style={{
                                color: '#9C9C9C',
                                fontSize: 12,
                                lineHeight: 20,
                                fontWeight: '400',
                                alignSelf: 'flex-end',
                            }}>
                            {formatDate(date)}
                        </Text>
                    </View>
                </View>
            ) : (
                <>
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}>
                        <>
                            {usefulWithoutText ? <LikeButton
                                title={
                                    usefulCount
                                        ? `${Object.R('button.useful')} (${usefulCount})`
                                        : Object.R('button.useful')
                                }
                                selected={usefulForMe}
                                disabled={true}
                            /> : <View />}
                        </>
                        <View
                            style={{
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                alignItems: 'flex-start',
                                flex: 1,
                                padding: 0,
                                margin: 0,
                                paddingTop: 12,
                            }}>
                            <FeedbackBadge
                                vote={vote}
                                style={{
                                    marginBottom: confirmed ? 0 : 5,
                                    marginRight: vote === '' ? 0 : 5,
                                }}
                            />
                            {!confirmed && (
                                <>
                                    <FeedbackBadge vote="NOT_CONFIRMED" />
                                </>
                            )}
                        </View>
                        <Text
                            style={{
                                color: '#9C9C9C',
                                fontSize: 12,
                                lineHeight: 20,
                                fontWeight: '400',
                                alignSelf: 'flex-end',
                            }}>
                            {formatDate(date)}
                        </Text>
                    </View>
                </>
            )}
            {responses?.length ? <ResponseToFeedbackList data={responses} /> : null}
        </View>
    );

});
