import { Img, React, Stub } from '../../../common';

export const StubMyFeedbacksNotAuthorized = () => {

    return (
        <Stub
            type="notAuthorized"
            img={<Img.EnterSignIn style={{
                width: 120,
                height: 120,
            }} />}
            style={{
                marginVertical: 64,
                marginHorizontal: 64,
            }}
            title={Object.R('stub.myFeedbacksNotAuthorizedTitle')}
            subTitle={Object.R('stub.myFeedbacksNotAuthorizedSubtitle')}
            titleStyle={{ height: 64 }}
        />
    );

};
