import { useDispatch, useSelector } from 'react-redux';
import { CommonActions } from '@react-navigation/native';
import { useEffect } from 'react';
import {
    Avatar,
    Icon,
    InfoList,
    LoadingIndicator,
    NavigationItem,
    Page,
    Platform,
    Prompt,
    React,
    Section,
    showNotification,
    Subtitle,
    SupportModal,
    Text,
    Title,
    View,
} from '../../common';
import { formatPhoneNumber, isAdult, toDate } from '../../utils';
import {
    getAllowScreenshots,
    getIsPersonalized,
    getIsReportsOrTestsEnable,
    getIsUserFetching,
    getUserInfo,
    getUserInteraction,
    getUserLoadingState,
    hasUnfilledRelatives,
} from '../../selectors';
import actions, { navigate } from '../../actions';
import { getEnums } from '../../i18n';
import { COLORS } from '../../styles/colors';
import { parseToDdMonYyyy } from '../../utils/dates';
import userService from '../../services/user';
import { checkExternalStorage, checkPhotosLibrary } from '../../services/device';
import api from '../../services/api';
import { setInDeleting } from '../../actions/user';
import { resetTestFilter } from '../../actions/tests';
import { resetVisitsFilter } from '../../actions/visits';
import { removeAllACodes } from '../../services/encryptedStorage';
import store from '../../store';
import { setFormData } from '../../actions/forms';
import { DISABLE_PROGRAM_CHOOSE } from '../../config';
import { PersonalEmailInputModal, ProgramsBlock } from './snippets';
import { autoGenUserName } from './utils';
import { accountInfo as styles } from './styles';

const EmptyProfileHint = ({
    pendingProgramConfirm, withoutEmail, message, style = {},
}) => {
    if (pendingProgramConfirm) {
        return (
            <Title
                numberOfLines={null}
                id="hint.corporateDataWillBeFetched"
                style={{
                    marginTop: 8,
                    fontSize: 14,
                }}
            />
        );
    }
    return (
        <View
            style={[
                {
                    flexDirection: 'row',
                    marginTop: 8,
                },
            ]}>
            <Text>
                <Text>
                    <Icon.Warning
                        style={{
                            // position: 'absolute',
                            // alignSelf: 'flex-end',
                            left: 0,
                            marginTop: 0,
                            height: 19,
                        }}
                    />
                </Text>
                {/* Use extra spaces to keep space for 'Warning' icon and have new line start at appropriate position */}
                <Text>
                    <Title
                        numberOfLines={null}
                        style={[
                            {
                                fontSize: 16,
                                color: COLORS.ACTION,
                                marginLeft: 0,
                            },
                            style,
                        ]}>
                        {'  '}
                        {Object.R(message || (withoutEmail ? 'titles.enterEmail' : 'titles.enterProfile'))}
                    </Title>
                </Text>
            </Text>
        </View>
    );
};

const showPersonalEmailInputModal = ({ address: email = '', confirmed = false }) => {
    Page.showModal(
        <PersonalEmailInputModal
            initialEmail={email}
            checkForIdentity={confirmed}
            identityErrorText={Object.R('titles.emailAlreadyLinked')}
            onSubmit={async (sentEmail) => {
                const userMails = await userService.getUserEmailsUpdated(sentEmail, 'PRIMARY');
                await api.updateUserInfo({
                    emails: userMails.map(({ address, usage }) => ({
                        address,
                        usage,
                    })),
                });
                showNotification(Object.R('titles.emailLinkConfirmSent', { email: sentEmail }), false);
            }}
            onResend={async (sentEmail) => {
                try {
                    await api.resendActivationEmail();
                    showNotification(Object.R('titles.emailLinkConfirmSent', { email: sentEmail }), false);
                    Page.closeModal();
                } catch (e) {
                    showNotification(Object.R('titles.emailLinkConfirmSentError', { email: sentEmail }), false);
                }
            }}
        />,
    );
};

const promptLogOut = ({ onSubmit }) => setTimeout(
    () => Page.showModal(
        <Prompt
            title="titles.are_you_sure"
            subtitle="titles.if_you_logout"
            submitTitle="buttons.exit"
            cancelTitle="button.stay"
            onSubmit={
                () => {
                    api.resetFCMToken().finally(() => {
                        if (onSubmit) {
                            onSubmit();
                        }
                        actions.logOut();
                        const nav = store.navigator;

                        const reset = CommonActions.reset({
                            index: 0,
                            routes: [{ name: 'Tabs' }],
                        });
                        nav.dispatch(reset);
                        setTimeout(() => nav.navigate('Account', { screen: 'SignIn' }), 1000);
                        actions.setShowEmailReconfirm(true);
                        actions.setOnlineFilter(false);
                        actions.setSearchSuggestionsKeyword('');
                    });
                }

                // actions.setFormData({ bumbaBanner: true });
            }
            submitOptions={{
                danger: false,
                titleStyles: { fontFamily: 'SourceSansPro-Semibold' },
            }}
            cancelOptions={{ transparent: false }}
        />,
    ),
    0,
);

const EmailPanel = ({
    pendingEmailConfirm,
    emails = [],
    confirmedEmail = emails ? emails.find(({ confirmed, usage }) => confirmed && usage === 'PRIMARY') : null,
}) => {
    if (!pendingEmailConfirm) {
        Page.closeModal();
    }
    if (pendingEmailConfirm) {
        return (
            <NavigationItem
                title={<Title>{pendingEmailConfirm.address}</Title>}
                titleStyle={{
                    marginLeft: 12,
                    fontSize: 14,
                }}
                subtitle={
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginLeft: 0,
                            paddingLeft: 0,
                        }}>
                        <Icon.Warning />
                        <Subtitle
                            id="titles.awaitingYourConfirm"
                            style={{
                                marginLeft: 11,
                                color: COLORS.ACTION,
                                fontWeight: '400',
                                fontSize: 14,
                            }}
                        />
                    </View>
                }
                onItem={() => {
                    showPersonalEmailInputModal(pendingEmailConfirm);
                }}
                firstItem={false}
            />
        );
    }
    return confirmedEmail ? null : (
        <NavigationItem
            wrapperStyle={{
                paddingVertical: 0,
                marginVertical: -2,
            }}
            leftIcon={<Icon.Warning />}
            title="titles.addMail"
            titleStyle={{
                fontSize: 14,
                color: COLORS.ACTION,
            }}
            titleWrapperStyle={{ marginLeft: -8 }}
            onItem={() => showPersonalEmailInputModal({
                address: '',
                confirmed: false,
                confirmedEmail,
            })
            }
            firstItem={false}
            containerStyle={{ paddingLeft: 26 }}
            noPadding
        />
    );
};

const LoadingView = () => (
    <View style={styles.loadingContainer}>
        <LoadingIndicator style={styles.loadingIndicator} indicatorSize={Platform.OS === 'ios' ? 'large' : 50} />
        <Title.Bigger style={styles.loadingTitle} id="titles.profileUpdatingHint" />
    </View>
);

export const AccountUserInfo = Page.register(
    // eslint-disable-next-line complexity
    ({
        userInfo: {
            name,
            isPerson,
            me,
            photoUrl,
            emails = [],
            phones,
            birthday,
            userId,
            relatives = [],
            canEditProfile,
        },
        isReportsOrTestsEnable,
        interaction: { error },
        isFetchingUser,
        isLoading,
        isPersonalized,
        lastUserAction,
        setState,
        personalEmail = emails.find(({ usage }) => usage === 'PRIMARY'),
        pendingProgramConfirm = emails.find(({ confirmed, usage }) => !confirmed && usage !== 'PRIMARY'),
        currrentProgramDeclineCode = !me || !me.programs
            ? undefined
            : (me.programs.find(program => program.id === me.programId) || {}).declineReasonCode,
        programs = !!me &&
        (!currrentProgramDeclineCode || currrentProgramDeclineCode !== 8) &&
        me.coverer &&
        me.coverer !== 'undefined'
            ? [me]
            : null,
        navigation,
        // allowScreenshots,
    }) => {
        const dispatch = useDispatch();
        const isUnfilledRelatives = useSelector(hasUnfilledRelatives);
        useEffect(() => {
            dispatch(
                setFormData({
                    Account: {
                        back: () => {
                            navigation.goBack();
                        },
                    },
                }),
            );
        }, []);
        return isLoading || isFetchingUser ? (
            <Page name="account">
                <LoadingView />
            </Page>
        ) : (
            <Page
                name="account"
                notification={
                    error && {
                        ...error,
                        onSubmit: lastUserAction,
                    }
                }
                safeArea>
                <Section>
                    <View
                        style={{
                            flexDirection: 'row',
                            paddingVertical: 14,
                        }}>
                        <Avatar size={80} imageUrl={photoUrl} />
                        <View
                            style={{
                                flex: 1,
                                paddingLeft: 14,
                            }}>
                            <View
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}>
                                <View style={{ flex: 1 }}>
                                    <Title.Bigger
                                        numberOfLines={null}
                                        style={{
                                            fontSize: 18,
                                            fontFamily: 'SourceSansPro-Semibold',
                                        }}>
                                        {name || autoGenUserName(userId)}
                                    </Title.Bigger>
                                    {name && personalEmail && personalEmail.confirmed ? null : (
                                        <EmptyProfileHint
                                            pendingProgramConfirm={pendingProgramConfirm}
                                            withoutEmail={!personalEmail?.confirmed && name}
                                        />
                                    )}
                                </View>
                                {!canEditProfile || (pendingProgramConfirm && !name) ? null : (
                                    <Icon.Edit
                                        style={{
                                            padding: 4,
                                            marginRight: 12,
                                            marginLeft: 6,
                                        }}
                                        size={20}
                                        color="black"
                                        onPress={() => navigate('EditAccount')}
                                    />
                                )}
                            </View>
                            {birthday ? (
                                <Title style={{ marginTop: 10 }}>
                                    {parseToDdMonYyyy(toDate(birthday), true, true)}
                                </Title>
                            ) : null}
                        </View>
                    </View>
                </Section>
                {relatives.length || isPerson ? (
                    <NavigationItem
                        title="titles.my_family"
                        subtitle={
                            isUnfilledRelatives ? (
                                <EmptyProfileHint
                                    message={Object.R('titles.navigationFillProfilePD')}
                                    style={{
                                        paddingBottom: 0,
                                        marginBottom: 0,
                                        alignSelf: 'flex-end',
                                    }}
                                />
                            ) : null
                        }
                        onItem={() => (birthday && !isAdult(birthday)
                            ? SupportModal('titles.notAdultCantHaveFamily')
                            : navigate('Relatives'))
                        }
                    />
                ) : null}
                {Platform.OS === 'web' ? null : (
                    <NavigationItem
                        title="titles.documents"
                        firstItem={false}
                        onItem={async () => {
                            await checkExternalStorage();
                            if (Platform.OS === 'ios') {
                                await checkPhotosLibrary();
                            }
                            navigate('Documents');
                        }}
                    />
                )}
                <Section.White title="titles.my_account" headerStyle={styles.userInfoField}>
                    <InfoList
                        fields="userInfoFields"
                        data={{
                            phone: (phones || []).map(formatPhoneNumber).join(', '),
                            email: personalEmail &&
                                personalEmail.confirmed && {
                                ...personalEmail,
                                onPress: () => {
                                    showPersonalEmailInputModal(personalEmail);
                                },
                            },
                        }}
                        itemStyle={styles.userInfoField}
                    />
                    <EmailPanel
                        pendingEmailConfirm={!personalEmail || personalEmail.confirmed ? null : personalEmail}
                        emails={emails}
                    />
                </Section.White>
                <View>
                    {!DISABLE_PROGRAM_CHOOSE && (
                        <>
                            <Section title="titles.programs" />
                            <ProgramsBlock
                                programs={programs}
                                pendingConfirm={pendingProgramConfirm}
                                onAddProgramRequest={() => navigate('ChooseProgram', { programs: getEnums('corporatePrograms', e => e.id !== 'no') })
                                }
                                onProgramsListItem={program => navigate(program.coverer.includes('bns') ? 'BnsProgram' : 'EpamProgram', { program })
                                }
                            />
                        </>
                    )}
                    <Section title="titles.security" capitalize />
                    {/* {Platform.OS === 'android' && <NavigationItem */}
                    {/*    title="titles.allowScreenshots" */}
                    {/*    onItem={() => { */}
                    {/*        actions.setAllowScreenshots(!allowScreenshots); */}
                    {/*    }} */}
                    {/*    firstItem={!(Platform.OS !== 'web' && isReportsOrTestsEnable)} */}
                    {/*    rightIcon={<Switch value={!!allowScreenshots} */}
                    {/*                       style={{ transform: Platform.OS === 'ios' ? [{ scaleX: 0.8 }, { scaleY: 0.8 }] : [] }} */}
                    {/*                       activeThumbColor="#4770C0" */}
                    {/*                       activeTrackColor="#9FB9FF" */}
                    {/*                       onTintColor={Platform.OS !== 'web' ? '#6987D6' : '#8FB8FF'} */}
                    {/*                       thumbTintColor="#5C85DD" */}
                    {/*                       trackColor={{ */}
                    {/*                           false: '#CFCFCF', */}
                    {/*                           true: Platform.OS === 'ios' ? '#5C85DD' : '#8FB8FF', */}
                    {/*                       }} */}
                    {/*                       onValueChange={(v) => { */}
                    {/*                           actions.setAllowScreenshots(v); */}
                    {/*                       }}/>} */}
                    {/* />} */}
                    {Platform.OS !== 'web' && isReportsOrTestsEnable && (
                        <NavigationItem
                            title="titles.accessSettings"
                            firstItem
                            onItem={() => {
                                navigate('AccessSettings');
                            }}
                        />
                    )}
                    {/* <Section title="titles.account_other"/> */}
                    {isPersonalized && Platform.OS !== 'web' ? (
                        <NavigationItem
                            onItem={() => navigate('NotificationsSettings')}
                            title="titles.notifications"
                            firstItem={!(Platform.OS !== 'web' && isReportsOrTestsEnable)}
                        />
                    ) : null}
                    <NavigationItem
                        title="titles.mainScreenSettings"
                        onItem={() => {
                            navigate('MainScreenSettings');
                        }}
                        firstItem={
                            !(isPersonalized && Platform.OS !== 'web') &&
                            !(Platform.OS !== 'web' && isReportsOrTestsEnable)
                        }
                    />
                    <NavigationItem
                        onItem={() => navigate('AccountRemove')}
                        title="titles.removeAccount"
                        firstItem={false}
                    />
                    <NavigationItem
                        onItem={() => {
                            setState({ lastUserAction: promptLogOut });
                            promptLogOut({
                                onSubmit: () => {
                                    removeAllACodes(undefined, false);
                                    resetTestFilter();
                                    resetVisitsFilter();
                                    navigation.replace('MyCard', { screen: 'Visit' });
                                    dispatch(setInDeleting(true));
                                    navigation.replace('Home');
                                },
                            });
                        }}
                        titleStyle={{ color: COLORS.ACTION }}
                        title="titles.exit"
                        firstItem={false}
                    />
                </View>
            </Page>
        );
    },
    {
        userInfo: { from: getUserInfo },
        interaction: { from: getUserInteraction },
        isFetchingUser: { from: getIsUserFetching },
        isLoading: { from: getUserLoadingState },
        isPersonalized: { from: getIsPersonalized },
        isReportsOrTestsEnable: { from: getIsReportsOrTestsEnable },
        allowScreenshots: { from: getAllowScreenshots },
    },
);
