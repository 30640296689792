import { NativeModules, Platform } from 'react-native';

const { CacheManager } = NativeModules;

export const removeAllCachedResponses = () => {

    if (Platform.OS === 'ios') {

        CacheManager.clearCache();
        CacheManager.removePDFFromCache();

        try {

            CacheManager?.clearAppCache?.(() => {


            });

        } catch (e) {
            //
        }

    }

};
export const removePDFFromCache = () => {

    CacheManager.removePDFFromCache();

};
