/* eslint-disable no-underscore-dangle */
import {
    BigButton, List, Page, React, Section, Title,
} from '../../common';
import api from '../../services/api';
import actions, { back, navigate } from '../../actions';
import { InvoiceCard } from './InvoiceCard';
import { InvoiceSingleService } from './InvoiceSingleService';

const reProcessingInvoice = invoices => (id, rejectInfo, type, delay = 100) => {
    setTimeout(async () => {
        await api.processingInvoices(id, rejectInfo);
        await actions.fetchInvoices(invoices);
        back();
    }, delay);
};
const getIdsByStatus = (records, status) => records.filter(item => item.status === status)
    .map(el => el._id);

export const InvoiceReviewedInfo = (props) => {
    const {
        route: {
            params,
            params: {
                records,
                invoices,
            },
        },
    } = props;
    const isApproved = params.status === 'APPROVED';
    const titleStyles = {
        marginTop: 24,
        marginBottom: 12,
        marginLeft: 8,
    };
    // console.log('------------------ InvoiceReviewedInfo, records', records);

    return (
        <Page
            backgroundColor="#f2f2f2"
            footer={
                <BigButton
                    title="buttons.correct_answer"
                    styles={{
                        height: 60,
                        borderRadius: 0,
                    }}
                    action={() => {
                        navigate('InvoiceMultiService', {
                            ...params,
                            processingInvoice: reProcessingInvoice(invoices),
                            approvedIds: getIdsByStatus(records, 'APPROVED'),
                            rejectedIds: getIdsByStatus(records, 'REJECTED'),
                        });
                    }}
                />
            }
        >
            <Section>
                <InvoiceCard item={params} forNewList={!isApproved} forMultiService={isApproved}
                    withComment={!isApproved}/>
                <Title id="titles.payment_details" style={titleStyles}/>
                <List
                    data={records}
                    renderItem={({ item }) => (<InvoiceSingleService {...item} forReviewedInfo/>)}
                />
            </Section>
        </Page>
    );
};

