/* eslint-disable no-underscore-dangle */
import { useSelector } from 'react-redux';
import { ScrollView } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import {
    Avatar,
    BadgeRed,
    Box,
    dataFrom,
    dataTrigger,
    Icon,
    Modal,
    OnMapButton,
    Page,
    Panel,
    React,
    SearchBar,
    Section,
    SelectableButton,
    selectOptions,
    Stub,
    Subtitle,
    Text,
    TextHighliter,
    Title,
    TopNotification,
    TouchableOpacity,
    View,
    TouchableWithoutFeedback,
} from '../../common';
import { getFromStoreDb, putIntoStore } from '../../store';
import actions, { navigate } from '../../actions';
import {
    actualDoctors,
    getClinicsHasBumba,
    getClinicsWithBumba,
    getCurrentUserProgram,
    getDoctorsSearchSuggestions,
    getFetchingSyncAllPhysicians,
    getIsFavoriteDoctorsFilterActive,
    getIsPersonalized,
    getNumberOfActiveDoctorsFilters,
    getPhysiciansSearch,
    physiciansIsLoading,
} from '../../selectors';
import { capitalize, curry, isEmpty } from '../../utils';
import { doctorSortOptions } from '../../selectors/utils';
import { ProgramSelector } from '../../combo';
import { AnimatedHeaderList } from '../../common/AnimatedHeaderList';
import { COLORS } from '../../styles/colors';
import { elementsStyles } from '../../common/style';
import tracking from '../../services/tracking';
import { AUTO_SWITCH_TO_FUZZY_LENGTH_DOCTOR } from '../../utils/strings';
// noinspection ES6PreferShortImport
import { Dimensions } from '../../common/react';
// import firebase, { collections as collectiontToSubscribe, pureCollections } from '../../services/firebase';
import { Feedback } from '../Feedbacks/Doctors/snippets';
import { storage } from '../../services/localstorage';
import { getFormData } from '../../selectors/forms';
import { ViewHighlight } from '../../common/ViewHighlight';
import { ListHeaderWithSearch } from '../../combo/ListHeaderWithSearch';
import { SCHEME } from '../../scheme';
import { DoctorCalendar, DoctorComment, FiltersTagsGroup } from './snippets';
import { doctorsSnippets, optionsButton } from './style';
import {
    fetchDoctors,
    gotoFilters,
    resetDoctorsFilters,
    sortClinics,
    toVisit,
    useHasActualRelatedPrograms,
} from './utils';
import { doctorFilterParams } from './DoctorsSpecialtiesSearch';

/**
 * Doctors list page.
 *
 * Code rank: ⭐⭐⭐.
 */

const NS = 'doctors';
// eslint-disable-next-line camelcase
const _use_two_lines_in_doctor_list_item = false;
const smallScreenWidth = 349;

const equalsItem = ({ doctorInfo: i1 }, { doctorInfo: i2 }) => i1.id === i2.id &&
    i1.feedbacksCount === i2.feedbacksCount &&
    i1.nearestDate === i2.nearestDate &&
    i1.coverer === i2.coverer &&
    i1.priceRange?.priceRangeString === i2.priceRange?.priceRangeString;

// eslint-disable-next-line complexity
export const DoctorsListItem = React.memo(
    ({
        doctorInfo, fromMap, noHighlight = false, mcId, hasTimetableSource, keywordSelector = getPhysiciansSearch,
    }) => {
        const {
            fullName,
            photoUrl,
            worksAt,
            specialization,
            feedbacksCount,
            textFeedbackCount,
            nearestDate,
            handledAges,
            coverer,
            notification,
            limited,
            priceRange: { priceRangeString } = {},
            recommendCount = 0,
            notRecommendCount = 0,
            neutralCount = 0,
        } = doctorInfo;
        const onPress = React.useCallback(() => {
            Object.trackAction('doctor_feedbacks_DoctorList', { doctorId: doctorInfo.id }, 'ai_icon');
            navigate('DoctorFeedbacks', { doctorInfo });
        }, [doctorInfo]);
        const keyword = noHighlight ? '' : useSelector(keywordSelector);

        const bumba = useSelector(getClinicsWithBumba);

        const { children: currentForChildren = false, adults: currentForAdults = false } =
            useSelector(getCurrentUserProgram) || {};

        const forChild =
            doctorInfo?.profile?.some(({ isForChildren }) => isForChildren) ||
            doctorInfo?.handledAges?.ages.some?.(item => item?.isForChild === true);
        const forAdult =
            doctorInfo?.profile?.some(({ isForChildren }) => !isForChildren) ||
            doctorInfo?.handledAges?.ages.some?.(item => item?.isForChild === false);
        const textPadding = 3;
        const iconPaddingRight = 22;
        // eslint-disable-next-line camelcase
        const smallScreen = Dimensions.get('window').width < smallScreenWidth && _use_two_lines_in_doctor_list_item;
        const onItemPress = React.useCallback(() => {
            if (fromMap) {
                tracking.logEvent('navigate_to_DoctorDetails_from_map');
            }
            navigate('DoctorDetails', { doctorInfo });
        });
        return (
            <Panel
                onPress={onItemPress}
                style={{
                    marginHorizontal: 12,
                    flexDirection: 'column',
                    paddingHorizontal: 0,
                }}>
                <View
                    style={{
                        flexDirection: 'row',
                        marginHorizontal: 16,
                    }}>
                    <View
                        style={{
                            flex: 1,
                            marginRight: 18,
                        }}>
                        {keyword ? (
                            <TextHighliter
                                bold
                                value={fullName}
                                keyword={keyword}
                                style={elementsStyles.text_bold}
                                switchToFuzzy={AUTO_SWITCH_TO_FUZZY_LENGTH_DOCTOR}
                            />
                        ) : (
                            <Title bold numberOfLines={1}>
                                {fullName}
                            </Title>
                        )}
                        {!notification ? null : (
                            <Subtitle.Warning style={{ marginTop: 1.2 }}>{notification.message}</Subtitle.Warning>
                        )}
                        <Text
                            style={[
                                {
                                    marginTop: 4,
                                    fontSize: 12,
                                    lineHeight: 15,
                                    fontWeight: '400',
                                    color: COLORS.STANDARD_GRAY,
                                },
                            ]}>
                            {capitalize(specialization)}
                        </Text>
                        {
                            // <TextHighliter value={specialization} style={[elementsStyles.subtitle, { marginTop: 1.2 }]} keyword={keyword} />
                        }
                        {
                            // eslint-disable-next-line react/no-array-index-key
                            fromMap || mcId ? null : (
                                <View
                                    style={{
                                        flexDirection: 'column',
                                        marginTop: 0,
                                    }}>
                                    {sortClinics(worksAt ?? []).map((e, idx) => (
                                        <View style={{ flexDirection: 'row' }}>
                                            {bumba.includes(e.clinicId) ? (
                                                <ViewHighlight
                                                    style={{
                                                        flexDirection: 'row',
                                                        borderRadius: 3,
                                                        borderColor: 'transparent',
                                                    }}
                                                    highlightStyle={{
                                                        flexDirection: 'row',
                                                        backgroundColor: COLORS.HIGHLIGHT_BACKGROUND,
                                                        borderRadius: 3,
                                                    }}
                                                    highlightId="panel">
                                                    <Icon.Attach
                                                        size={11}
                                                        style={{
                                                            marginTop: 2,
                                                            marginRight: 4,
                                                        }}
                                                    />
                                                    <Subtitle
                                                        key={`${e.name}${idx}`}
                                                        style={{
                                                            fontSize: 12,
                                                            lineHeight: 15,
                                                            fontWeight: '400',
                                                            color: COLORS.BUMBA_LINK,
                                                        }}
                                                        numberOfLines={2}>
                                                        {e.name}
                                                    </Subtitle>
                                                </ViewHighlight>
                                            ) : (
                                                <Subtitle
                                                    key={`${e.name}${idx}`}
                                                    style={{
                                                        fontSize: 12,
                                                        lineHeight: 15,
                                                        fontWeight: '400',
                                                        color: COLORS.STANDARD_GRAY,
                                                    }}
                                                    numberOfLines={2}>
                                                    {e.name}
                                                </Subtitle>
                                            )}
                                        </View>
                                    ))}
                                </View>
                            )
                        }
                        {handledAges?.title ? (
                            <Text
                                numberOfLines={0}
                                style={{
                                    ...elementsStyles.text_bold,
                                    marginTop: 4,
                                    color: COLORS.STANDARD_GRAY,
                                    lineHeight: 15,
                                    fontSize: 12,
                                    fontWeight: '600',
                                }}>
                                {handledAges?.title ?? ''}
                            </Text>
                        ) : null}
                        {priceRangeString && !mcId ? (
                            <Text
                                numberOfLines={0}
                                style={{
                                    ...elementsStyles.text_bold,
                                    marginTop: 4,
                                    color: COLORS.STANDARD_GRAY,
                                    lineHeight: 15,
                                    fontSize: 12,
                                    fontWeight: '600',
                                }}>{`${Object.R('titles.consultation')} ${priceRangeString}`}</Text>
                        ) : null}
                        {/*
                            {priceRangeString ? <Subtitle numberOfLines={0} style={{ color: '#18B797' }}>{`${Object.R('titles.consultation')} ${priceRangeString}`}</Subtitle> : null}
                            */}
                    </View>
                    <Avatar
                        of="physicians"
                        imageUrl={photoUrl}
                        coverer={
                            coverer && ((forChild && currentForChildren) || (forAdult && currentForAdults))
                                ? coverer
                                : ''
                        }
                        isLimited={limited && limited === coverer}
                    />
                </View>
                <View
                    style={{
                        marginTop: 8,
                        marginHorizontal: 16,
                    }}>
                    {smallScreen ? (
                        <View style={{ flexDirection: 'column' }}>
                            <View
                                style={{
                                    flexDirection: 'row',
                                    flex: 1,
                                }}>
                                <TouchableOpacity
                                    onPress={onPress}
                                    style={{
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        // justifyContent: 'flex-start',
                                        padding: 0,
                                        margin: 0,
                                        paddingRight: iconPaddingRight - 3,
                                        flex: 1,
                                    }}>
                                    <Feedback
                                        recommendCount={recommendCount}
                                        notRecommendCount={notRecommendCount}
                                        neutralCount={neutralCount}
                                        textStyle={{ paddingTop: textPadding }}
                                    />
                                </TouchableOpacity>
                                <TouchableOpacity
                                    onPress={onPress}
                                    style={{
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        // justifyContent: 'flex-end',
                                        padding: 0,
                                        margin: 0,
                                        paddingRight: iconPaddingRight - 15,
                                        flex: 1,
                                    }}>
                                    <DoctorComment
                                        feedbacksCount={feedbacksCount}
                                        textFeedbackCount={textFeedbackCount}
                                        textStyle={{ paddingTop: textPadding }}
                                    />
                                </TouchableOpacity>
                            </View>
                            <View
                                style={{
                                    flex: 1,
                                    justifyContent: 'center',
                                }}>
                                <TouchableOpacity
                                    onPress={() => {
                                        Object.trackAction(
                                            'doctor_visit_DoctorList',
                                            { doctorId: doctorInfo.id },
                                            'ai_icon',
                                        );
                                        toVisit(doctorInfo, mcId);
                                    }}
                                    style={{
                                        flexDirection: 'row',
                                        // justifyItems: 'flex-end',
                                        justifyContent: 'center',
                                        padding: 0,
                                        margin: 0,
                                        marginRight: iconPaddingRight,
                                        flex: 1,
                                        paddingTop: 10,
                                        // alignItems: 'center',
                                        // alignSelf: 'center',
                                        // borderWidth: 1,
                                    }}>
                                    {/* <Icon.CalendarBig color={COLORS.MAIN} style={{ alignItems: 'center' }} />
                                        <Text
                                            numberOfLines={2}
                                            ellipsizeMode="tail"
                                            style={{
                                                paddingTop: textPadding,
                                                marginLeft: 4,
                                                fontSize: 12,
                                                lineHeight: 15,
                                                fontWeight: '400',
                                                color: COLORS.STANDARD_GRAY,
                                            }}
                                        >
                                            {capitalize(getDefaultNearestDate(nearestDate))}
                                        </Text> */}
                                    <DoctorCalendar textPadding={textPadding} nearestDate={nearestDate} />
                                </TouchableOpacity>
                            </View>
                        </View>
                    ) : (
                        <View
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}>
                            <TouchableOpacity
                                onPress={() => {
                                    Object.trackAction(
                                        'doctor_visit_DoctorList',
                                        { doctorId: doctorInfo.id },
                                        'ai_icon',
                                    );
                                    toVisit(doctorInfo, mcId);
                                }}
                                style={{
                                    flexDirection: 'row',
                                    justifyItems: 'flex-end',
                                    padding: 0,
                                    margin: 0,
                                    marginRight: iconPaddingRight,
                                    flex: 1,
                                    alignItems: 'center',
                                    // borderWidth: 1,
                                }}>
                                <DoctorCalendar
                                    textPadding={textPadding}
                                    nearestDate={nearestDate}
                                    hasTimetableSource={hasTimetableSource}
                                />
                            </TouchableOpacity>
                            <TouchableOpacity
                                onPress={onPress}
                                style={{
                                    flexDirection: 'row',
                                    justifyItems: 'center',
                                    padding: 0,
                                    margin: 0,
                                    paddingRight: iconPaddingRight - 3,
                                    flex: 1.1,
                                    alignItems: 'center',
                                }}>
                                <Feedback
                                    recommendCount={recommendCount}
                                    notRecommendCount={notRecommendCount}
                                    neutralCount={neutralCount}
                                    textStyle={{ paddingTop: textPadding }}
                                />
                            </TouchableOpacity>
                            <TouchableOpacity
                                onPress={onPress}
                                style={{
                                    flexDirection: 'row',
                                    justifyItems: 'flex-end',
                                    padding: 0,
                                    margin: 0,
                                    paddingRight: iconPaddingRight - 15,
                                    flex: 0.9,
                                    alignItems: 'center',
                                }}>
                                <DoctorComment
                                    feedbacksCount={feedbacksCount}
                                    textFeedbackCount={textFeedbackCount}
                                    textStyle={{ paddingTop: textPadding }}
                                />
                            </TouchableOpacity>
                        </View>
                    )}
                </View>
            </Panel>
        );
    },
    equalsItem,
);

const onSpecialty = (name = '') => {
    if (name) {
        const filters = getFromStoreDb(SCHEME.PHYSICIANS_FILTER) || {};
        const newFilters = {
            ...filters,
            specialty: name.toLowerCase(),
        };
        putIntoStore(SCHEME.PHYSICIANS_FILTER, newFilters);
        putIntoStore(SCHEME.PHYSICIANS_FROM_SERARCH, undefined);
        putIntoStore('specializationForApi', undefined);
        putIntoStore(SCHEME.PHYSICIANS_SEARCH, '');
        actions.updateDocFilters(newFilters);
    }
};

const onMedcenter = (medcenterId = '') => {
    const filters = getFromStoreDb(SCHEME.PHYSICIANS_FILTER) || {};
    const newFilters = {
        ...filters,
        worksAt: [medcenterId],
    };
    resetDoctorsFilters(newFilters);
    putIntoStore(SCHEME.PHYSICIANS_SEARCH, '');
};

const DoctorsSearchSuggestions = () => {
    const { specialties = [], medcenters = [] } = useSelector(getDoctorsSearchSuggestions) || {};
    return (
        <View>
            <TopNotification hint={Object.R('hints.no_matches_by_name')} containerStyle={{ textAlign: 'center' }} />
            {specialties.length || medcenters.length ? (
                <Title
                    bold
                    style={{
                        paddingHorizontal: 24,
                        paddingTop: 10,
                    }}>
                    {Object.R('titles.perhaps_you_were_looking_for')}
                </Title>
            ) : null}
            {specialties.length ? (
                <Section title={Object.R('titles.specializations_section')} capitalize>
                    <Box
                        style={{
                            flexWrap: 'wrap',
                            justifyContent: 'flex-start',
                            paddingHorizontal: 8,
                        }}>
                        {specialties.map(e => (
                            <SelectableButton
                                title={e.key}
                                style={{ backgroundColor: '#9AACDA' }}
                                action={() => onSpecialty(e.key)}
                            />
                        ))}
                    </Box>
                    <View
                        style={{
                            borderBottomWidth: 1,
                            borderBottomColor: '#E0E0E0',
                            paddingBottom: 12,
                        }}
                    />
                </Section>
            ) : null}
            {medcenters.length ? (
                <Section title={Object.R('titles.medcenters_section')} capitalize>
                    <Box
                        style={{
                            flexWrap: 'wrap',
                            justifyContent: 'flex-start',
                            paddingHorizontal: 8,
                        }}>
                        {medcenters.map(e => (
                            <SelectableButton
                                title={e.name}
                                style={{
                                    backgroundColor: '#bdafec',
                                    maxWidth: '100%',
                                }}
                                action={() => onMedcenter(e.id)}
                            />
                        ))}
                    </Box>
                </Section>
            ) : null}
        </View>
    );
};

// const DoctorsListEmpty = () => dataFrom(
//     getIsFavoriteDoctorsFilterActive,
//     ({ data: isFilterActive }) => (!isEmpty(isFilterActive) ? <Stub.FavoriteDoctors/> : null),
// );
const DoctorsListEmpty = () => {
    const isFilterActive = useSelector(getIsFavoriteDoctorsFilterActive);
    return !isEmpty(isFilterActive) ? <Stub.FavoriteDoctors /> : null;
};

const onSearch =
    selector => (...r) => {
        putIntoStore(selector, ...r);
    };
const onSort = scheme => curry(putIntoStore, scheme); //

const TheSearchBar = dataFrom(getPhysiciansSearch)(({ data, initialValue = '', onFocusChange }) => (
    <SearchBar
        autoCorrect={false}
        initialValue={data || initialValue}
        searchAfter={2}
        onSearch={onSearch}
        placeholder="placeholders.search_doctors"
        additionalStyle={{ flex: 1 }}
        onFocusChange={onFocusChange}
        borderRadius={3}
    />
));

const showSortModal =
    (from = 'default') => () => {
        const filter = doctorFilterParams?.[from] ?? doctorFilterParams.default;
        const selectedSort = getFromStoreDb(filter.sortScheme);
        const doctorSort = doctorSortOptions();
        const selectedSortId = selectedSort ? selectedSort.id : doctorSort[0].id;
        selectOptions({
            title: 'titles.sort_list_of_doctors',
            selected: selectedSortId,
            data: doctorSort,
            onSelect: e => Page.closeModal(() => {
                onSort(filter.sortScheme)(e);
                tracking.logEvent(`ai_doctor_ls_${e?.id}`);
            }),
        });
    };

const FavoritesIcon = dataTrigger(
    getIsPersonalized,
    dataFrom(getIsFavoriteDoctorsFilterActive)(({ data: isFavoritesFilterActive }) => (
        <Icon
            style={{ marginRight: 19.2 }}
            name={`bookmark-${!isEmpty(isFavoritesFilterActive) ? 'active' : 'normal'}`}
            color={!isEmpty(isFavoritesFilterActive) ? '#5C85DD' : '#666666'}
            onPress={() => {
                putIntoStore(SCHEME.PHYSICIANS_FILTER_FAVORITES, isEmpty(isFavoritesFilterActive));
                actions.updateDocFilters({ filterFavoriteDoctors: isEmpty(isFavoritesFilterActive) });
            }}
        />
    )),
);

const FilterButton = dataFrom(getNumberOfActiveDoctorsFilters)(({ data, isFocused, from }) => (
    <TouchableOpacity style={optionsButton.button} onPress={() => gotoFilters(from)}>
        <Icon.Filter color={COLORS.MAIN} />
        {isFocused ? null : (
            <Title bold style={optionsButton.text}>
                {Object.R('titles.params')}
            </Title>
        )}
        {data ? <BadgeRed badge={data} /> : null}
    </TouchableOpacity>
));

// eslint-disable-next-line no-unused-vars
export const showLogs = (data) => {
    // const data = useSelector(getFetching);
    Page.showModal(
        <Modal title="titles.phone_numbers">
            <ScrollView style={{ height: '80%' }}>
                <Title numberOfLines={60}>
                    {Object.entries(data || {})
                        .map(([id, val]) => `${id}: ${val}`)
                        .join('\n')}
                </Title>
            </ScrollView>
        </Modal>,
    );
};

export const SortIcon = ({ from = 'default' }) => {
    // const data = useSelector(getFetching);
    return <Icon.Sort onPress={showSortModal(from)} />;
};

const TheMenu = ({ from = 'default' }) => (
    <View style={doctorsSnippets.menu}>
        <FavoritesIcon />
        <SortIcon from={from} />
    </View>
);

const ListSectionHeaderImpl = dataFrom(getIsFavoriteDoctorsFilterActive, ({
    data, countOf, title, from, clinicId,
}) => {
    const isAllSyncRunning = useSelector(getFetchingSyncAllPhysicians);
    const isPhysiciansSearchRunning = useSelector(physiciansIsLoading);
    const defaultTitle =
        // eslint-disable-next-line no-nested-ternary
        !countOf || isPhysiciansSearchRunning ? 'titles.loadingData' : !isEmpty(data) ? 'favoriteDoctors' : 'doctors';
    const routePostFix = {
        default: '',
        '': '',
        MedCenterDetails: 'Clinic',
        Visit: 'Visit',
    };
    return (
        <Section
            title={isAllSyncRunning ? 'titles.all_doctors_sync' : title || defaultTitle}
            countOf={!countOf || isAllSyncRunning || isPhysiciansSearchRunning ? undefined : countOf}
            right={
                <>
                    {countOf?.length ? (
                        <OnMapButton
                            logEventName="ai_btn_on_doctors_map"
                            route={`DoctorsMap${routePostFix[from] ?? ''}`}
                            routeParams={{
                                from,
                                item: { id: clinicId },
                            }}
                            containerStyle={{ flex: 1 }}
                        />
                    ) : null}
                    <TheMenu from={from} />
                </>
            }
            isLoading={!countOf || isAllSyncRunning || isPhysiciansSearchRunning}
        />
    );
});

// const ListSectionHeader = dataFrom(getDoctorsWithActualCollections)(({ data, from }) => (
//     <ListSectionHeaderImpl countOf={data} from={from} />
// ));
const ListSectionHeader = ({ from = 'default' }) => {
    const filters = doctorFilterParams?.[from] ?? doctorFilterParams.default;
    const selector = filters.doctorsWithActualCollections;
    const clinic = useSelector(filters.filter)?.worksAt;
    const clinicId = clinic?.length === 1 ? clinic?.[0] : null;
    const data = useSelector(selector);
    return <ListSectionHeaderImpl countOf={data} from={from} clinicId={clinicId} />;
};

export const DoctorsListHeader = ({ from }) => {
    const filters = doctorFilterParams?.[from] ?? doctorFilterParams.default;
    const selector = filters.searchTextScheme;
    const numberOfFilters = filters?.numberOfActiveFilters;
    const filtersData = useSelector(numberOfFilters);
    const searchData = useSelector(filters.physicianSearch);
    return (
        <ListHeaderWithSearch
            onSearch={onSearch(selector)}
            searchData={searchData}
            filtersData={filtersData}
            gotoFilters={() => gotoFilters(from)}
            placeholder="placeholders.search_doctors"
        />
    );
};

export const ListHeader = ({ from = 'default' }) => {
    const { bumbaBanner } = useSelector(getFormData);
    const hasBumba = useSelector(getClinicsHasBumba);
    return (
        <TouchableOpacity activeOpacity={1}>
            <>
                <ProgramSelector withInfo />
                <DoctorsListHeader from={from} />
                <FiltersTagsGroup from={from} />
                {!!bumbaBanner && !!hasBumba && (
                    <BumbaBanner
                        onClose={() => {
                            actions.setFormData({ bumbaBanner: false });
                            storage.set('bumbaBanner', false).then();
                        }}
                    />
                )}
                <ListSectionHeader from={from} />
            </>
        </TouchableOpacity>
    );
};

const renderItem = ({ item }) => <DoctorsListItem doctorInfo={item} key={item.id} />;

export const DataList = ({ headerHeight }) => {
    const data = useSelector(actualDoctors);
    const isFavoritesActive = useSelector(getIsFavoriteDoctorsFilterActive);
    const keyword = useSelector(getPhysiciansSearch);
    const { bumbaBanner } = useSelector(getFormData);
    const hasBumba = useSelector(getClinicsHasBumba);
    // const favorites = useSelector(getFavoritesFull);

    // useEffect(() => {
    //     if (isEmpty(data) || isEmpty(favorites) || !Array.isArray(favorites) || !Array.isArray(data)) {
    //         return;
    //     }
    //     let isFavoritesChanged = false;
    //     const fav = [...favorites];
    //     fav.forEach((f) => {
    //         const found = data.find(doc => doc?.id === f?.id);
    //         if (!found) {
    //             return;
    //         }
    //         if (f?.photoUrl !== found?.photoUrl) {
    //             f.photoUrl = found.photoUrl;
    //             isFavoritesChanged = true;
    //         }
    //     });
    //     if (isFavoritesChanged) {
    //         console.log('Favorite changed', {
    //             fav,
    //             favorites,
    //         });
    //         actions.updateFullFavoritesList(fav);
    //     }
    // }, [favorites, data]);

    return (
        <AnimatedHeaderList
            data={data}
            ListHeader={ListHeader}
            headerHeight={headerHeight + (bumbaBanner && hasBumba ? 72 : 0)}
            renderItem={renderItem}
            emptyComponentRenderer={keyword && isEmpty(isFavoritesActive) ? DoctorsSearchSuggestions : DoctorsListEmpty}
            separateHeader={false}
            // listHeaderProps={{from: 'default'}}
        />
    );
};

export const BumbaBanner = ({ onClose = () => {} }) => {
    return (
        <View
            style={{
                backgroundColor: COLORS.MAIN,
                height: 72,
                flexDirection: 'row',
                marginHorizontal: 12,
                borderRadius: 2,
                // flex: 1,
                overflow: 'hidden',
                marginTop: 8,
                position: 'relative',
            }}>
            <View
                style={{
                    height: 87,
                    width: 87,
                    backgroundColor: '#FFFFFF',
                    opacity: 0.2,
                    borderRadius: 140,
                    marginTop: 4,
                    marginLeft: 0,
                    padding: 0,
                    left: -8,
                    position: 'absolute',
                }}></View>
            <TouchableOpacity
                onPress={() => {
                    actions.addHighlighters(['panel', 'MyCard']);
                }}
                style={{
                    flexDirection: 'row',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                }}>
                <View>
                    <Icon.Attach
                        color={COLORS.WHITE}
                        size={34}
                        style={{
                            opacity: 1,
                            marginTop: 23,
                            marginHorizontal: 25,
                        }}
                    />
                </View>
                <View
                    style={{
                        paddingRight: 20,
                        paddingLeft: 10,
                        paddingTop: 0,
                        paddingBottom: 0,
                        marginBottom: 0,
                        right: 0,
                        top: 0,
                        left: 0,
                        flex: 1,
                        bottom: 0,
                        height: 72,
                        flexDirection: 'column',
                        justifyContent: 'center',
                        // borderWidth: 1,
                    }}>
                    <View
                        style={{
                            height: 72,
                            flex: 1,
                            justifyContent: 'center',
                        }}>
                        <Text
                            style={{
                                color: COLORS.WHITE,
                                fontWeight: '700',
                                fontSize: 12,
                                lineHeight: 15,
                            }}>
                            {Object.R('title.bumbaBannerTitle')}
                        </Text>

                        {/* </View> */}
                        {/* <View */}
                        {/*    style={{ */}
                        {/*        height: 48, */}
                        {/*        justifyContent: 'center', */}
                        {/*        // borderWidth: 1, */}
                        {/*        marginVertical: 2, */}
                        {/*    }} */}
                        {/* > */}
                        <Text
                            style={{
                                color: COLORS.WHITE,
                                fontWeight: '400',
                                fontSize: 12,
                                lineHeight: 15,
                            }}
                            numberOfLines={3}>
                            {Object.R('title.bumbaBannerText')}
                        </Text>
                    </View>
                </View>
            </TouchableOpacity>
            <Icon.Cross
                color={COLORS.WHITE}
                style={{
                    position: 'absolute',
                    right: 5,
                    top: 5,
                }}
                onPress={() => {
                    onClose();
                }}
            />
        </View>
    );
};

export const OldDoctorsList = Page.register(() => {
    const hasPrograms = useHasActualRelatedPrograms();
    fetchDoctors();
    useFocusEffect(() => {
        actions.setFormValue('umsServices', []);
    });
    return (
        <Page name={NS} noscroll>
            <DataList headerHeight={hasPrograms ? 180 : 110} />
        </Page>
    );
});
