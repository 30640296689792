/**
 * Application Configuration.
 * Auto-generated file. Do not edit.
 * @vendor https://epam.com
 */
export const ID = 'aimd';
export const MODE = 'prod';
export const VERSION = '2.2.738-prod.RC2-bm.e8e958459';
export const DEFAULT_LOCALE = 'ru-RU';
export const GOOGLE_WEB_CLIENT_ID = '137792746040-uqajlhfupn54c36o9qif29perc268ah7.apps.googleusercontent.com';
export const API_HOST = 'aibolit.md';
export const MY_API_HOST = 'my2.aibolit.md';
export const UMS_API_HOST = 'ums2.aibolit.md';
export const FIRESTORE = 'aibolitmd-member-prod';
export const SUPPORT_EMAIL = 'support@aibolit.md';
