/* eslint-disable no-underscore-dangle */
import {
    arrayToHash, formatDateShort, isEmpty, sortBy,
} from '../utils';
import { SCHEME } from '../scheme';
import {
    assignClinic, createSelector, dbGettr, doctorSortOptions, gettr, mergeFeedbacksToDoctor,
} from './utils';
import { actualSplitDoctors, actualSplitDoctorsForClinics, actualSplitDoctorsForVisits } from './doctorsSums';
import { actualSplitDoctorsBySpeciatity, getDoctorsWithPartnershipList } from './doctorsInfo';
import { actualSplitDoctorsBySpecialityForClinics } from './doctorsInfo.clinic';
import { actualSplitDoctorsBySpecialityForVisit } from './doctorsInfo.visit';
import {
    actualDoctors,
    actualDoctorsClinic,
    actualDoctorsVisit,
    doctorsOnlyWithPartnershipAndSums,
    doctorsWithPartnershipAndSumsForLocal,
    doctorsWithPartnershipList,
    doctorsWithSplitPartnershipAndSumsForLocal,
    getGlobalLocation,
    getUsePreviousDoctorsList,
} from '.';

export const getSpecialities = (data) => {

    const spec = arrayToHash(data, 'specialization');
    return Object.keys(spec);

};

export const getDoctorsWithActualCollections = createSelector(
    [actualDoctors, actualSplitDoctors, getUsePreviousDoctorsList],
    (doctors, liteDoctors, usePreviosList) => (usePreviosList ? doctors : liteDoctors),
);

export const getDoctorsWithActualCollectionsClinic = createSelector(
    [actualDoctorsClinic, actualSplitDoctorsForClinics, getUsePreviousDoctorsList],
    (doctors, liteDoctors, usePreviosList) => (usePreviosList ? doctors : liteDoctors),
);

export const getDoctorsWithActualCollectionsVisit = createSelector(
    [actualDoctorsVisit, actualSplitDoctorsForVisits, getUsePreviousDoctorsList],
    (doctors, liteDoctors, usePreviosList) => (usePreviosList ? doctors : liteDoctors),
);

export const getCountOfActualDoctors = createSelector([getDoctorsWithActualCollections], doctors => doctors?.length);
export const getDoctorsWithPartnershipAndSums = createSelector(
    [doctorsWithPartnershipAndSumsForLocal, doctorsWithSplitPartnershipAndSumsForLocal, getUsePreviousDoctorsList],
    (doctors, liteDoctors, usePrevious) => (usePrevious ? doctors : liteDoctors),
);
export const getDoctorsOnlyWithPartnershipAndSums = createSelector(
    [doctorsOnlyWithPartnershipAndSums, doctorsWithSplitPartnershipAndSumsForLocal, getUsePreviousDoctorsList],
    (doctors, liteDoctors, usePrevious) => (usePrevious ? doctors : liteDoctors),
);

const getSplitCollectionInfo = (data, location, specialty) => {

    return data?.[location]?.[specialty]?.data || {};

};

export const getUMSDoctorsIds = createSelector([gettr('ums.doctors')], (doctors) => {

    return doctors?.map(({ physicianId }) => physicianId);

});

export const getDoctorsForService = createSelector(
    [getDoctorsWithPartnershipList, gettr('ums.doctors'), gettr('ums.isDoctorsLoading')],
    (physicians, umsDoctors, isDoctorsLoading) => {

        if (isEmpty(physicians) || isDoctorsLoading) {

            return null;

        }
        let rr = physicians.filter(({ hidden } = {}) => !hidden);
        rr = rr
            ?.filter(({ worksAt: pWorksAt = [] } = {}) => pWorksAt?.some(({ assignmentId: pAssignmentId } = {}) => umsDoctors?.some(({ assignmentId }) => assignmentId === pAssignmentId),),)
            ?.map((e = {}) => {

                const times = umsDoctors
                    ?.filter(({ assignmentId } = {}) => (e.worksAt || [])?.some(
                        ({ assignmentId: pAssignmentId } = {}) => pAssignmentId === assignmentId,
                    ),)
                    ?.map(({ firstTimeSlotAvail, assignmentId } = {}) => ({
                        nta: new Date(firstTimeSlotAvail).valueOf(),
                        assignmentId,
                    }));
                const minNta = times?.reduce((min, cur) => {

                    if (cur.nta < min.nta) {

                        return cur;

                    }
                    return min;

                });
                return Object.assign(Object.create(e), {
                    nearestDate: minNta.nta,
                    assignmentIdForNTA: minNta.assignmentId,
                });

            });
        return rr;

    },
);

const getCollectionInfoForAllSpecForUMS = (state, collName) => {

    const physicians = getDoctorsForService(state);
    if (isEmpty(physicians)) {

        return {};

    }
    const specialities = getSpecialities(physicians);
    const locationCode = getGlobalLocation(state);
    const mergedInfo = {};
    specialities?.forEach((spec) => {

        Object.assign(mergedInfo, getSplitCollectionInfo(state.db?.[collName], locationCode, spec));

    });
    return mergedInfo;

};

export const getDoctorsForServiceWithSums = createSelector(
    [
        getDoctorsForService,
        getGlobalLocation,
        (state) => {

            return getCollectionInfoForAllSpecForUMS(state, 'physicians_feedback_counters');

        },

        (state) => {

            return getCollectionInfoForAllSpecForUMS(state, 'physicians_prices');

        },
    ],
    (physicians, locationCode, feedbacksCounter = {}, prices = {}) => {

        if (isEmpty(physicians) || (isEmpty(feedbacksCounter) && isEmpty(prices))) {

            return physicians;

        }

        const hasPrices = Object.keys(prices)?.length;
        const hasFeedback = Object.keys(feedbacksCounter)?.length;

        return physicians.map((e) => {

            const r = Object.create(e);
            if (hasPrices) {

                r.priceRange = prices[e.id] || {};
                const allWorksAt = [...e.worksAt];
                r.worksAt = allWorksAt.filter(({ areaCode }) => areaCode === locationCode);
                r.worksAt.forEach((job) => {

                    Object.assign(job, { priceRange: r.priceRange[job._id] });

                });

            }
            r._id = e.id;
            return hasFeedback ? mergeFeedbacksToDoctor(r, feedbacksCounter?.[e.id]) : r;

        });

    },
);

export const getDoctorsForServiceWithSort = createSelector(
    [getDoctorsForServiceWithSums, dbGettr(SCHEME.UMS_PHYSICIANS_SORT_BY)],
    (physicians, sortValue) => {

        const sortExpr = sortValue ? sortValue.expr : doctorSortOptions()[0].expr;
        return sortBy(physicians, sortExpr);

    },
);

export const getClinicsDoctorsMap = createSelector(
    [
        actualSplitDoctorsBySpecialityForClinics,
        // getDoctorsWithActualCollections,
        dbGettr('clinics.data'),
        getGlobalLocation,
        dbGettr('physicians.filterForClinic.worksAt', []),
    ],
    // eslint-disable-next-line consistent-return
    (doctors, clinics, location, filter) => {

        if (doctors) {

            const clinicsDoctorsObj = {};
            for (let i = 0; i < doctors.length; i++) {

                const doc = doctors[i];
                if (doc.assignmentIdForNTA) {

                    const clinic = doc.worksAt?.find(mc => mc.assignmentId === doc.assignmentIdForNTA);
                    assignClinic(clinicsDoctorsObj, doc.nearestDate, clinic.id, doc);

                } else {

                    doc.worksAt?.forEach((e) => {

                        assignClinic(clinicsDoctorsObj, e.nearestAvailableDate, e.id, doc);

                    });

                }

            }
            return Object.entries(clinicsDoctorsObj)
                .map(([key, value]) => {

                    const daysCountArr = Object.values(value)
                        .filter(d => typeof d.daysCount === 'number')
                        .map(doc => ({
                            daysCount: doc.daysCount,
                            nearestDate: doc.nearestDate,
                        }));
                    const minNta = daysCountArr?.reduce(
                        (min, cur) => {

                            if (cur.daysCount < min.daysCount || min.daysCount === null) {

                                return cur;

                            }
                            return min;

                        },
                        { daysCount: null },
                    );
                    const clinic = { ...clinics[key] } || {};
                    return {
                        id: key,
                        get doctors() {

                            return sortBy(Object.values(value), e => (e.nearestDate ? e.nearestDate.valueOf() : Number.MAX_VALUE),);

                        },
                        latitude: Number(clinic.latitude),
                        longitude: Number(clinic.longitude),
                        daysCount: minNta.daysCount,
                        nearestDate: (minNta.nearestDate && formatDateShort(minNta.nearestDate))?.toLowerCase?.(),
                        name: clinic.name,
                        address: clinic.address,
                        // addressLink: clinics[key]?.addressLink,
                        schedule: clinic.schedule,
                        phones: clinic.phone?.replace(/[А-Яа-я- ()]/g, ''),
                        areaCode: clinic.areaCode,
                    };

                })
                .filter(
                    e => e.latitude &&
                        e.longitude &&
                        e.areaCode === location &&
                        (!filter.length || filter.some(id => id === e.id)),
                ); // if without nta -> .filter(e => typeof e.daysCount === 'number');

        }

    },
);

export const getVisitDoctorsMap = createSelector(
    [
        actualSplitDoctorsBySpecialityForVisit,
        // getDoctorsWithActualCollections,
        dbGettr('clinics.data'),
        getGlobalLocation,
        dbGettr('physicians.filterForVisit.worksAt', []),
    ],
    // eslint-disable-next-line consistent-return
    (doctors, clinics, location, filter) => {

        if (doctors) {

            const clinicsDoctorsObj = {};
            for (let i = 0; i < doctors.length; i++) {

                const doc = doctors[i];
                if (doc.assignmentIdForNTA) {

                    const clinic = doc.worksAt?.find(mc => mc.assignmentId === doc.assignmentIdForNTA);
                    assignClinic(clinicsDoctorsObj, doc.nearestDate, clinic.id, doc);

                } else {

                    doc.worksAt?.forEach((e) => {

                        assignClinic(clinicsDoctorsObj, e.nearestAvailableDate, e.id, doc);

                    });

                }

            }
            return Object.entries(clinicsDoctorsObj)
                .map(([key, value]) => {

                    const daysCountArr = Object.values(value)
                        .filter(d => typeof d.daysCount === 'number')
                        .map(doc => ({
                            daysCount: doc.daysCount,
                            nearestDate: doc.nearestDate,
                        }));
                    const minNta = daysCountArr?.reduce(
                        (min, cur) => {

                            if (cur.daysCount < min.daysCount || min.daysCount === null) {

                                return cur;

                            }
                            return min;

                        },
                        { daysCount: null },
                    );
                    const clinic = { ...clinics[key] } || {};
                    return {
                        id: key,
                        get doctors() {

                            return sortBy(Object.values(value), e => (e.nearestDate ? e.nearestDate.valueOf() : Number.MAX_VALUE),);

                        },
                        latitude: Number(clinic.latitude),
                        longitude: Number(clinic.longitude),
                        daysCount: minNta.daysCount,
                        nearestDate: (minNta.nearestDate && formatDateShort(minNta.nearestDate))?.toLowerCase?.(),
                        name: clinic.name,
                        address: clinic.address,
                        // addressLink: clinics[key]?.addressLink,
                        schedule: clinic.schedule,
                        phones: clinic.phone?.replace(/[А-Яа-я- ()]/g, ''),
                        areaCode: clinic.areaCode,
                    };

                })
                .filter(
                    e => e.latitude &&
                        e.longitude &&
                        e.areaCode === location &&
                        (!filter.length || filter.some(id => id === e.id)),
                ); // if without nta -> .filter(e => typeof e.daysCount === 'number');

        }

    },
);

export const getDoctorsMap = createSelector(
    [
        actualSplitDoctorsBySpeciatity,
        // getDoctorsWithActualCollections,
        dbGettr('clinics.data'),
        getGlobalLocation,
        dbGettr('physicians.filter.worksAt', []),
    ],
    // eslint-disable-next-line consistent-return
    (doctors, clinics, location, filter) => {

        if (doctors) {

            const clinicsDoctorsObj = {};
            for (let i = 0; i < doctors.length; i++) {

                const doc = doctors[i];
                if (doc.assignmentIdForNTA) {

                    const clinic = doc.worksAt?.find(mc => mc.assignmentId === doc.assignmentIdForNTA);
                    assignClinic(clinicsDoctorsObj, doc.nearestDate, clinic.id, doc);

                } else {

                    doc.worksAt?.forEach((e) => {

                        assignClinic(clinicsDoctorsObj, e.nearestAvailableDate, e.id, doc);

                    });

                }

            }

            return Object.entries(clinicsDoctorsObj)
                .map(([key, value]) => {

                    const daysCountArr = Object.values(value)
                        .filter(d => typeof d.daysCount === 'number')
                        .map(doc => ({
                            daysCount: doc.daysCount,
                            nearestDate: doc.nearestDate,
                        }));
                    const minNta = daysCountArr?.reduce(
                        (min, cur) => {

                            if (cur.daysCount < min.daysCount || min.daysCount === null) {

                                return cur;

                            }
                            return min;

                        },
                        { daysCount: null },
                    );
                    const clinic = { ...clinics[key] } || {};

                    return {
                        id: key,
                        get doctors() {

                            return sortBy(Object.values(value), e => (e.nearestDate ? e.nearestDate.valueOf() : Number.MAX_VALUE),);

                        },
                        latitude: Number(clinic.latitude),
                        longitude: Number(clinic.longitude),
                        daysCount: minNta.daysCount,
                        nearestDate: (minNta.nearestDate && formatDateShort(minNta.nearestDate))?.toLowerCase?.(),
                        name: clinic.name,
                        address: clinic.address,
                        // addressLink: clinics[key]?.addressLink,
                        schedule: clinic.schedule,
                        phones: clinic.phone?.replace(/[А-Яа-я- ()]/g, ''),
                        areaCode: clinic.areaCode,
                    };

                })
                .filter(
                    e => e.latitude &&
                        e.longitude &&
                        e.areaCode === location &&
                        (!filter.length || filter.some(id => id === e.id)),
                ); // if without nta -> .filter(e => typeof e.daysCount === 'number');

        }

    },
);

export const getIsDoctorsSearching = createSelector([gettr('doctors')], ({ isDoctorsSearching }) => isDoctorsSearching);
