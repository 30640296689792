import { useSelector } from 'react-redux';
import { navigate } from '../../actions';
import {
    Avatar, Icon, Panel, React, Subtitle, Text, TextHighliter, Title, View,
} from '../../common';
import { COLORS } from '../../styles/colors';
import { elementsStyles } from '../../styles/elements';
import { AUTO_SWITCH_TO_FUZZY_LENGTH_DOCTOR } from '../../utils/strings';
import { capitalize } from '../../utils';
import { getClinicsWithBumba, getCurrentUserProgram, getPhysiciansFavoritesSearch } from '../../selectors';
import { ViewHighlight } from '../../common/ViewHighlight';
import { sortClinics } from './utils';

const equalsItem = ({ doctorInfo: i1 }, { doctorInfo: i2 }) => i1.id === i2.id && i1.coverer === i2.coverer;

export const DoctorsFavoritesItem = React.memo(({
    doctorInfo, fromMap, noHighlight = false, mcId,
}) => {
    const {
        fullName, photoUrl, worksAt, specialization, handledAges, coverer, notification, limited,
    } = doctorInfo;
    const keyword = noHighlight ? '' : useSelector(getPhysiciansFavoritesSearch);
    const bumba = useSelector(getClinicsWithBumba);

    const { children: currentForChildren = false, adults: currentForAdults = false } =
        useSelector(getCurrentUserProgram) || {};

    const forChild =
        doctorInfo?.profile?.some(({ isForChildren }) => isForChildren) ||
        doctorInfo?.handledAges?.ages.some?.(item => item?.isForChild === true);
    const forAdult =
        doctorInfo?.profile?.some(({ isForChildren }) => !isForChildren) ||
        doctorInfo?.handledAges?.ages.some?.(item => item?.isForChild === false);

    const onItemPress = React.useCallback(() => {
        navigate('DoctorDetails', {
            doctorInfo,
            from: 'DoctorsFavorites',
        });
    });
    return (
        <Panel
            onPress={onItemPress}
            style={{
                marginHorizontal: 12,
                flexDirection: 'column',
                paddingHorizontal: 0,
            }}>
            <View
                style={{
                    flexDirection: 'row',
                    marginHorizontal: 16,
                }}>
                <View
                    style={{
                        flex: 1,
                        marginRight: 18,
                    }}>
                    {keyword ? (
                        <TextHighliter
                            bold
                            value={fullName}
                            keyword={keyword}
                            style={elementsStyles.text_bold}
                            switchToFuzzy={AUTO_SWITCH_TO_FUZZY_LENGTH_DOCTOR}
                        />
                    ) : (
                        <Title bold numberOfLines={1}>
                            {fullName}
                        </Title>
                    )}
                    {!notification ? null : (
                        <Subtitle.Warning style={{ marginTop: 1.2 }}>{notification.message}</Subtitle.Warning>
                    )}
                    <Text
                        style={[
                            {
                                marginTop: 4,
                                fontSize: 12,
                                lineHeight: 15,
                                fontWeight: '400',
                                color: COLORS.STANDARD_GRAY,
                            },
                        ]}>
                        {capitalize(specialization)}
                    </Text>
                    {
                        // eslint-disable-next-line react/no-array-index-key
                        fromMap || mcId ? null : (
                            <View
                                style={{
                                    flexDirection: 'column',
                                    marginTop: 0,
                                }}>
                                {sortClinics(worksAt ?? []).map((e, idx) => (
                                    <View style={{ flexDirection: 'row' }}>
                                        {bumba.includes(e.clinicId) ? (
                                            <ViewHighlight
                                                style={{
                                                    flexDirection: 'row',
                                                    borderRadius: 3,
                                                    borderColor: 'transparent',
                                                }}
                                                highlightStyle={{
                                                    flexDirection: 'row',
                                                    backgroundColor: COLORS.HIGHLIGHT_BACKGROUND,
                                                    borderRadius: 3,
                                                }}
                                                highlightId="panel">
                                                <Icon.Attach
                                                    size={11}
                                                    style={{
                                                        marginTop: 2,
                                                        marginRight: 4,
                                                    }}
                                                />
                                                <Subtitle
                                                    key={`${e.name}${idx}`}
                                                    style={{
                                                        fontSize: 12,
                                                        lineHeight: 15,
                                                        fontWeight: '400',
                                                        color: COLORS.BUMBA_LINK,
                                                    }}
                                                    numberOfLines={2}>
                                                    {e.name}
                                                </Subtitle>
                                            </ViewHighlight>
                                        ) : (
                                            <Subtitle
                                                key={`${e.name}${idx}`}
                                                style={{
                                                    fontSize: 12,
                                                    lineHeight: 15,
                                                    fontWeight: '400',
                                                    color: COLORS.STANDARD_GRAY,
                                                }}
                                                numberOfLines={2}>
                                                {e.name}
                                            </Subtitle>
                                        )}
                                    </View>
                                ))}
                            </View>
                        )
                    }
                    {handledAges?.title ? (
                        <Text
                            numberOfLines={0}
                            style={{
                                ...elementsStyles.text_bold,
                                marginTop: 4,
                                color: COLORS.STANDARD_GRAY,
                                lineHeight: 15,
                                fontSize: 12,
                                fontWeight: '600',
                            }}>
                            {handledAges?.title ?? ''}
                        </Text>
                    ) : null}
                </View>
                <Avatar
                    of="physicians"
                    imageUrl={photoUrl}
                    coverer={
                        coverer && ((forChild && currentForChildren) || (forAdult && currentForAdults)) ? coverer : ''
                    }
                    isLimited={limited && limited === coverer}
                />
            </View>
        </Panel>
    );
}, equalsItem);
