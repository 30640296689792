// noinspection JSUnusedGlobalSymbols

import service from '../services/user';
import { setLocale as setLocaleSrv } from '../i18n';
import { createReducerFor } from './utils';

const TYPE = 'selections';

export const selectsAction = fn => (...params) => async (dispatch) => {
    let data = null;
    let error = null;
    try {
        data = await fn(...params);
    } catch (ex) {
        error = ex;
    } finally {
        dispatch(({
            type: TYPE,
            payload: {
                error,
                data,
            },
        }));
    }
};

export const updateSelections = selectsAction(service.updateSelections);

export const setLocation = v => updateSelections('location', () => Number(v));
export const setLocale = v => updateSelections('locale', () => setLocaleSrv(v));
export const setNotificationsSettings = v => updateSelections('notificationsSettings', () => v);
export const setMainScreenSettings = v => updateSelections('mainScreenSettings', () => v);
export const toggleIncludeAnalogs = v => updateSelections('includeAnalogs', () => v);
export const setMapType = v => updateSelections('mapType', () => v);
export const setAcodeConfirmation = v => updateSelections('acodeConfirmation', () => v);
export const setAllowScreenshots = v => updateSelections('allowScreenshots', () => !!v);
export const updateSelects = selectsAction(obj => obj);

export default createReducerFor(TYPE);
