import { useFocusEffect } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import actions from '../../../../actions';
import { TextWithAuth } from '../../../../combo';
import {
    FlatList,
    Icon,
    Keyboard,
    ListItem,
    Platform,
    SearchBar,
    Section,
    TouchableWithoutFeedback,
    View,
} from '../../../../common';
import { getIsAuthenticated, getNumberOfActiveVisitsFilters, getUserInfo } from '../../../../selectors';
import {
    getActualTests,
    getActualTestsWithFilter,
    getClinicsFilterFromTestsHistory,
    getNumberOfActiveTestsFilters,
    getStatusesFilterFromTestsHistory,
    getVisitorsFilterFromTestsHistory,
} from '../../../../selectors/tests';
import { isCurrentYear } from '../../../../utils/dates';
import { strongSearch, wordsList } from '../../../../utils/strings';
import { goToTestsFilter } from '../../../Visits/utils';
import { fnId, formatDateShort } from '../../../../utils';
import firebase from '../../../../services/firebase';
import { listItem } from '../../../Visits/style';
import tracking from '../../../../services/tracking';
import {
    BranchTest,
    DateTest,
    MyCardTabSelector,
    NameTest,
    onResult,
    Results,
    StatusTest,
    VisitorTest,
} from './snippets';

const MIN_LENGTH_SEARCH = 3;

const EmptyTestText = () => {
    const { data } = useSelector(getNumberOfActiveVisitsFilters);
    const { isLoading } = useSelector(getActualTests);
    return (
        <TextWithAuth
            paddingLeft={0}
            id={
                data > 0
                    ? `titles.testsNotFound${isLoading ? 'Loading' : ''}`
                    : `notes.you_have_no_tests${isLoading ? 'Loading' : ''}`
            }
        />
    );
};

const FilterTestsIcon = ({ clinics: clinicsTests, statuses: statusesTests, visitors: visitorsTests }) => {
    const data = useSelector(getNumberOfActiveTestsFilters);
    return (
        <Icon.Filter
            style={{ marginRight: 14 }}
            badge={data}
            onPress={() => goToTestsFilter(clinicsTests, statusesTests, visitorsTests)}
        />
    );
};

const getVisitor = (user, id) => {
    let myVisit = false;
    if (user?.userId === id) {
        myVisit = true;
        return {
            myVisit,
            user,
        };
    }
    return {
        myVisit,
        user: user?.relatives?.find(relative => relative.profileId === id) ?? {
            firstLastName: '',
            name: '',
        },
    };
};

const TestsListHeader = ({ setTextFilter, withPanel = true, needHeader = true }) => {
    const statusesList = useSelector(getStatusesFilterFromTestsHistory);
    const clinicTestsList = useSelector(getClinicsFilterFromTestsHistory);
    const visitorsList = useSelector(getVisitorsFilterFromTestsHistory);
    return (
        <View
            style={{
                marginHorizontal: 0,
                paddingHorizontal: 0,
            }}>
            {!!needHeader && <MyCardTabSelector />}
            {withPanel && (
                <View
                    style={{
                        flexDirection: 'row',
                        paddingBottom: 5,
                        paddingLeft: 0,
                    }}>
                    <SearchBar
                        additionalStyle={{
                            flex: 1,
                            marginHorizontal: 0,
                            paddingLeft: 0,
                            paddingRight: 0,
                            marginLeft: 0,
                            marginRight: needHeader ? 12 : 0,
                        }}
                        fixLength={-10}
                        onSearch={setTextFilter}
                        placeholder="placeholders.search"
                        withDebounce={false}
                    />
                    {!!needHeader && (
                        <View
                            style={{
                                flexDirection: 'row',
                                marginTop: 22,
                            }}>
                            <FilterTestsIcon
                                clinics={clinicTestsList}
                                statuses={statusesList}
                                visitors={visitorsList}
                            />
                        </View>
                    )}
                </View>
            )}
        </View>
    );
};

export const TestsItem = React.memo(({ item, userInfo, keywords = [] }) => {
    const {
        name, date, branchName, status, testResults,
    } = item;
    const visitor = getVisitor(userInfo, item?.profileId);
    const onReadyClick = () => {
        tracking.logEvent('ai_test_click');
        onResult(item);
    };

    return (
        <ListItem
            Component={TouchableWithoutFeedback}
            key={item?.id}
            containerStyle={[
                listItem.container,
                {
                    borderBottomWidth: 1,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    borderLeftWidth: 1,
                    borderColor: '#e0e0e0',
                },
            ]}>
            <ListItem.Content style={[listItem.wrapperStyle]}>
                <View
                    style={{
                        flexDirection: 'row',
                        paddingTop: 5,
                        width: '100%',
                    }}>
                    <NameTest testName={name} keywords={keywords} />
                </View>
                <View
                    style={{
                        flexDirection: 'row',
                        paddingTop: 5,
                        width: '100%',
                    }}>
                    <BranchTest clinic={branchName} keywords={keywords} />
                    <StatusTest bold statusTest={status} keywords={keywords} />
                </View>
                <View
                    style={{
                        flexDirection: 'row',
                        paddingTop: 5,
                        width: '100%',
                    }}>
                    <VisitorTest visit={visitor.user} myVisit={visitor.myVisit} keywords={keywords} />
                    <DateTest dateVisit={date} keywords={keywords} />
                </View>
                {status === 'ready' && (visitor?.user?.isReportsManageable || visitor?.myVisit) && (
                    <View
                        style={{
                            flexDirection: 'row',
                            paddingTop: 5,
                            width: '100%',
                        }}>
                        <Results results={testResults} onReadyClick={onReadyClick} />
                    </View>
                )}
            </ListItem.Content>
        </ListItem>
    );
});

const statuses = {
    'in progress': Object.R('titles.testsStatusInProgress'),
    ready: Object.R('titles.testsStatusReady'),
};

export const wordsFound = (text, words) => {
    const list = wordsList(text, words, strongSearch, 0);
    return list.length && words.every(word => list.includes(word));
};

const useReduxStoreCollection = (profileId, tests) => {
    useFocusEffect(
        React.useCallback(
            () => (!(profileId && !tests)
                ? fnId
                : firebase.subscribeOnCollectionChanges(`profile/${profileId}/tests`, (snapshot) => {
                    actions.setTests(firebase.snapshotToData(snapshot));
                })),
            [profileId, tests],
        ),
    );
};

let syncCompleted;

const TestList = ({
    data,
    emptyComponent,
    withPanel,
    isLoading,
    userInfo,
    keywords,
    setTextFilter,
    listStyles,
    needHeader,
}) => (
    <FlatList
        style={[
            {
                height: Platform.OS === 'web' ? 0 : undefined,
                flex: 1,
            },
            listStyles,
        ]}
        accessibilityLabel="list"
        data={data}
        scrollEventThrottle={16}
        keyExtractor={e => e.id}
        // onRefresh={Platform.OS !== 'web' && actions.syncTests}
        renderItem={({ item }) => <TestsItem item={item} userInfo={userInfo} keywords={keywords} />}
        ListEmptyComponent={emptyComponent}
        ListHeaderComponent={
            <TestsListHeader needHeader={needHeader} setTextFilter={setTextFilter} withPanel={withPanel} />
        }
        initialNumToRender={5}
        windowSize={15}
        refreshing={!!isLoading}
        maxToRenderPerBatch={50}
        updateCellsBatchingPeriod={25}
        persistentScrollbar
        onScrollBeginDrag={() => Keyboard.dismiss()}
    />
);

export const Tests = ({ needHeader = true, profileId: pid }) => {
    const [textFilter, setTextFilter] = useState('');
    let keywords = [];
    let { data: tests } = useSelector(getActualTestsWithFilter);
    let { data: haveData, isLoading } = useSelector(getActualTests);
    if (pid) {
        tests = tests?.filter(t => t?.profileId === pid);
        haveData = haveData?.filter(t => t?.profileId === pid);
    }
    const userInfo = useSelector(getUserInfo);
    const isAuthenticated = useSelector(getIsAuthenticated);
    // const onClose = () => showSettingsModal();
    useReduxStoreCollection(userInfo?.storeKey, haveData);
    useEffect(() => {
        if (syncCompleted === undefined) {
            syncCompleted = true;
            actions.syncTests();
        }
    }, []);
    useEffect(() => {
        if (!isAuthenticated) {
            setTextFilter('');
        }
    }, [isAuthenticated]);

    if (!isAuthenticated) {
        // return isAuthenticated === undefined ? null :
        return (
            <Section isLoading={false} title="my-card.testsHeader" style={{ marginBottom: 0 }}>
                <TextWithAuth id="notes.you_have_no_tests" />
            </Section>
        );
    }

    if (!haveData?.length) {
        return (
            <TestList
                emptyComponent={<EmptyTestText isLoading={isLoading} />}
                data={[]}
                listStyles={{ paddingHorizontal: 12 }}
                withPanel={false}
                keywords={keywords}
                isLoading={isLoading}
                setTextFilter={isAuthenticated}
                userInfo={userInfo}
                needHeader={needHeader}
            />
        );
    }

    const getFilteredTests = (t) => {
        if (!textFilter) {
            return t;
        }
        const preparedText = textFilter.trim().toLowerCase();
        if (preparedText.length < MIN_LENGTH_SEARCH) {
            return t;
        }

        keywords = Array.from(new Set(preparedText.split(' '))).filter(str => !!str);
        const userId = userInfo?.userId;
        return t.filter((i) => {
            const id = i?.profileId;
            const user =
                id === userId
                    ? Object.R('titles.my_test')
                    : userInfo?.relatives?.find(relative => relative?.profileId === id)?.firstLastName ?? '';
            const text = ` ${i?.name} ${formatDateShort(i?.date, !isCurrentYear(i?.date))} ${statuses[i?.status]} ${
                i?.branchName
            } ${user} `.toLowerCase();
            return wordsFound(text, keywords);
            // wordsFound(i.name.toLowerCase(), keywords) || wordsFound(formatDateShort(i.date.toLowerCase(), !isCurrentYear(i.date)), keywords) || wordsFound(statuses[i.status].toLowerCase(), keywords)
        });
    };

    const sortTests = data => data?.sort((a, b) => (new Date(b.date ?? 0).getTime() - new Date(a?.date ?? 0).getTime() > 0 ? 1 : -1));

    return (
        <TestList
            emptyComponent={
                textFilter || haveData?.length ? (
                    <TextWithAuth id="stubs.empty-default" />
                ) : (
                    <TextWithAuth id="notes.you_have_no_tests" />
                )
            }
            data={sortTests(getFilteredTests(tests))}
            withPanel
            isLoading={isLoading}
            userInfo={userInfo}
            keywords={keywords}
            setTextFilter={setTextFilter}
            listStyles={{ paddingHorizontal: 12 }}
            needHeader={needHeader}
        />
    );
};
