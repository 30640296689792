import ImageViewer from 'react-native-image-zoom-viewer';
import {
    Button,
    ButtonsGroup,
    Dimensions,
    Icon,
    LoadingIndicator,
    Modal,
    Page,
    Platform,
    React,
    Title,
    View,
} from '../../common';
import { AppHeader } from '../../combo';
import actions, { back } from '../../actions';
import { getEditedVisit } from '../../selectors';
import { resolveImagePath } from '../../services/device';
import { getAttachmentsForVisit } from '../../selectors/forms';
import { useState } from 'react';

export const VisitImagesSlider = Page.register(
    ({
        visitInfo: { attachments: dataVisit = [] } = {},
        initialId,
        readOnly,
        manualVisit,
        manualData,
        enableDelete = false,
        setState,
        isUpdating,
        bottom = Platform.OS === 'ios' ? 35 : 16,
    }) => {

        const data = (manualVisit ? manualData : dataVisit) ?? [];
        const dataUrls = data.map(({ uri, path, cachePath }) => ({ url: resolveImagePath(uri, cachePath || path) }));
        const [currentItemId, setCurrentItemId] = useState(data ? (data.find(({ id }) => id === initialId) || data[0] || {}).id : null);
        return (
            <Page backgroundColor="black" noscroll>
                <View
                    style={{
                        flex: 1,
                        backgroundColor: 'black',
                    }}>
                    {!isUpdating ? (
                        <ImageViewer
                            style={{ marginBottom: 48 }}
                            index={initialId ? data.findIndex(({ id }) => id === initialId) : 0}
                            enablePreload
                            saveToLocalByLongPress={false}
                            imageUrls={dataUrls}
                            flipThreshold={Dimensions.get('window').width / 2}
                            renderIndicator={() => null}
                            onChange={(index) => {

                                setCurrentItemId(data?.[index]?.id);

                            }}
                        />
                    ) : (
                        <View
                            style={{
                                flex: 1,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            <LoadingIndicator />
                        </View>
                    )}
                    {data.length > 1 ? (
                        <View
                            style={{
                                position: 'absolute',
                                bottom,
                                left: 16,
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'transparent',
                                zIndex: 10,
                            }}>
                            <Title
                                style={{
                                    color: 'white',
                                    fontSize: 16,
                                    backgroundColor: 'transparent',
                                }}>
                                {Object.R('tiles.positionFromTotal', {
                                    position: data.findIndex(({ id }) => id === currentItemId) + 1,
                                    total: data.length,
                                })}
                            </Title>
                        </View>
                    ) : null}
                    {enableDelete ? (
                        <View
                            style={{
                                position: 'absolute',
                                bottom,
                                right: 16,
                            }}>
                            {readOnly ? null : (
                                <Icon.Trash.White
                                    style={{
                                        backgroundColor: 'transparent',
                                        width: 24,
                                        height: 24,
                                        borderRadius: 0,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    color="#FFFFFF"
                                    onPress={() => {

                                        Page.showModal(
                                            <Modal hideClose>
                                                <Title
                                                    style={{
                                                        alignSelf: 'center',
                                                        textAlign: 'center',
                                                    }}
                                                    bold
                                                    id="titles.docDeleteConfirm"
                                                />
                                                <ButtonsGroup>
                                                    <Button transparent title="buttons.no" action={Page.closeModal} />
                                                    <Button
                                                        primary
                                                        title="buttons.yes"
                                                        action={async () => {

                                                            Page.closeModal();
                                                            setState({ isUpdating: true });
                                                            const newData = data.filter(
                                                                ({ id }) => id !== currentItemId,
                                                            );
                                                            if (manualVisit) {

                                                                await actions.setFormValue(
                                                                    'attachmentsForVisit',
                                                                    newData,
                                                                );

                                                            } else {

                                                                await actions.updateEditedVisit({ attachments: newData });

                                                            }

                                                            if (!newData.length) {

                                                                back();

                                                            } else {

                                                                const currentItemIndex = data.findIndex(
                                                                    ({ id }) => id === currentItemId,
                                                                );
                                                                const newInitialIndex =
                                                                    currentItemIndex === data.length - 1
                                                                        ? currentItemIndex - 1
                                                                        : currentItemIndex;
                                                                setCurrentItemId(newData?.[newInitialIndex]?.id);
                                                                setState({
                                                                    initialId: newData[newInitialIndex].id,
                                                                    isUpdating: false,
                                                                });

                                                            }

                                                        }}
                                                    />
                                                </ButtonsGroup>
                                            </Modal>,
                                        );

                                    }}
                                />
                            )}
                        </View>
                    ) : null}
                </View>
            </Page>
        );

    },
    {
        visitInfo: { from: getEditedVisit },
        manualData: { from: getAttachmentsForVisit },
    },
    () => ({ header: () => <AppHeader /> }),
);
