/* eslint-disable no-underscore-dangle */
// noinspection JSUnusedGlobalSymbols

import { arrayToHash, createMemoizedProps, isEmpty } from '../utils';
import { SCHEME } from '../scheme';
import { synonymize } from '../i18n';
import {
    createSelector, dbGettr, gettr, prefGettr,
} from './utils';
import { EMPTY_OBJECT } from './const';


/* eslint-disable no-param-reassign */
import { getCurrentOrFirstProgram, getRelatedPrograms } from './programs';
import { getGlobalLocation } from './getters';
import { getKeywordsFromSearchString, isStringIncludeAllKeywords } from '../utils/strings';

const {
    MEDCENTERS_SEARCH,
    PHYSICIANS_FILTER,
    PHYSICIANS_FILTER_FOR_CLINIC,
    PHYSICIANS_FILTER_FOR_VISIT,
    MEDCENTERS_FILTER,
    PHYSICIANS_FILTER_FAVORITES,
    MEDCENTERS_FILTER_FAVORITES,
    PHYSICIANS_PREFILTER_WORKS_AT_GROUP,
    PHYSICIANS_PREFILTER_WORKS_AT_GROUP_CLINIC,
    PHYSICIANS_PREFILTER_WORKS_AT_GROUP_VISIT,
} = SCHEME;

const clinicProto = createMemoizedProps({
    services: () => [],
    emails: e => (e.email ? e.email.split(';') : []),
    phones: e => (e.contacts ? e.contacts.split(';') : []),
    // timezone: () => currentTimezone,
    workTime() {

        return (this.schedule?.match(/(\b\d+:\d+\s-\s\d+:\d+)/) || [])[0] || Object.R('titles.no_schedule');

    },
    notification() {

        return this.getNotif?.(this.id);

    },
    connectivityStatus() {

        if (this.notification) {

            return 'offline';

        }
        return this.onlineConnectivity ? 'online' : 'unavailable';

    },
    _search(e) {

        const nameParts = e.name.replace(/[^А-Яа-яA-Za-z0-9Ёё ]/g, '').split(' ');
        return `${e.name} ${e.address} ${nameParts.map(name => synonymize(name)).join(' ')}`.replace(/ё/gi, 'е');

    },
    limited() {

        return this.servicesCoverage
            ? Object.values(this.servicesCoverage).some(({ attributes = {} }) => attributes.LIMIT)
            : false;

    },
});
/**
 * Clinics, Doctors and UMS services.
 */
export const getClinicsIdsForSelectedProgram = createSelector(
    getRelatedPrograms,
    getCurrentOrFirstProgram,
    (programs, currentProgram, resolved = programs[currentProgram?.id || '*']) => (resolved?.branches ? arrayToHash(resolved.branches, e => e) : null),
);

const getPhysiciansPrefilterWorksAt = (clinics, programsBranches, currentProgram, servicesCoverage) => clinics
    .filter(e => !e.hidden)
    .map(e => ({
        ...e,
        coverer: programsBranches?.[e.id] ? currentProgram.coverer : null,
        get limited() {

            return Object.values(servicesCoverage?.[currentProgram?.id]?.[e.id] || {}).some(el => el.attributes);

        },
    }));

export const physiciansPrefilterWorksAt = createSelector(
    dbGettr('physicians.prefilter.worksAt', []),
    getClinicsIdsForSelectedProgram,
    getCurrentOrFirstProgram,
    gettr(`ums.info.coverage`),
    (clinics, programsBranches, currentProgram, servicesCoverage) => getPhysiciansPrefilterWorksAt(clinics, programsBranches, currentProgram, servicesCoverage),
);

export const physiciansPrefilterWorksAtClinic = createSelector(
    dbGettr('physicians.prefilterForClinic.worksAt', []),
    getClinicsIdsForSelectedProgram,
    getCurrentOrFirstProgram,
    gettr(`ums.info.coverage`),
    (clinics, programsBranches, currentProgram, servicesCoverage) => getPhysiciansPrefilterWorksAt(clinics, programsBranches, currentProgram, servicesCoverage),
);

export const physiciansPrefilterWorksAtVisit = createSelector(
    dbGettr('physicians.prefilterForVisit.worksAt', []),
    getClinicsIdsForSelectedProgram,
    getCurrentOrFirstProgram,
    gettr(`ums.info.coverage`),
    (clinics, programsBranches, currentProgram, servicesCoverage) => getPhysiciansPrefilterWorksAt(clinics, programsBranches, currentProgram, servicesCoverage),
);

export const physiciansPrefilterWorksAtGroup = createSelector(
    dbGettr(PHYSICIANS_PREFILTER_WORKS_AT_GROUP),
    (groupId = 'any') => groupId,
);

export const physiciansPrefilterWorksAtGroupClinic = createSelector(
    dbGettr(PHYSICIANS_PREFILTER_WORKS_AT_GROUP_CLINIC),
    (groupId = 'any') => groupId,
);

export const physiciansPrefilterWorksAtGroupVisit = createSelector(
    dbGettr(PHYSICIANS_PREFILTER_WORKS_AT_GROUP_VISIT),
    (groupId = 'any') => groupId,
);

export const actualNotificationsByTarget = createSelector(dbGettr('notifications.data'), (notifications) => {

    if (!notifications) {

        return () => null;

    }
    const hash = arrayToHash(Object.values(notifications), 'target');
    return (id) => {

        const now = Date.now();
        const note = hash[id];
        if (!note) {

            return null;

        }
        if (now > note.startDate && now < note.endDate) {

            return note;

        }
        return null;

    };

});
export const actualNotificationsByTargetList = createSelector(dbGettr('notifications.data'), (notifications) => {

    if (!notifications) {

        return () => {};

    }
    const hash = arrayToHash(Object.values(notifications), 'target');
    return (ids = []) => {

        const now = Date.now();
        const notes = {};
        ids.forEach((id) => {

            const note = hash[id];
            if (!note) {

                notes[id] = null;

            }
            if (now > note?.startDate && now < note?.endDate) {

                notes[id] = note;

            }

        });
        return notes;

    };

});

export const getClinics = createSelector([dbGettr('clinics.data')], clinics => clinics);

export const joinedClinics = createSelector(
    [
        dbGettr('clinics.data'),
        getClinicsIdsForSelectedProgram,
        actualNotificationsByTarget,
        getCurrentOrFirstProgram,
        gettr(`ums.info.coverage`),
    ],
    (clinics, programsBranches, getNotif, currentProgram, servicesCoverage) => (isEmpty(clinics)
        ? EMPTY_OBJECT
        : arrayToHash(Object.values(clinics), '_id', e => Object.assign(Object.create(clinicProto), {
            ...e,
            getNotif,
            // in terms of UMS programs for this user, provides at least one service paid by consumer
            coverer: programsBranches?.[e._id] ? currentProgram?.coverer : null,
            servicesCoverage: servicesCoverage?.[currentProgram?.id]?.[e._id],
        }))),
);

export const getNumberOfActiveDoctorsFilters = createSelector(
    dbGettr(PHYSICIANS_FILTER),
    ({
        specialty = '', worksAt = [], profiles, feedbacks = false, time, dates = {}, age,
    } = EMPTY_OBJECT) => [specialty, worksAt.length ? 1 : 0, Number(profiles), feedbacks, Number(time), dates.dateStart, age === '1' || age === '2' ? 1 : 0].reduce(
        (acc, next) => (next ? 1 + acc : acc),
        0,
    ),
);

export const getNumberOfActiveDoctorsClinicFilters = createSelector(
    dbGettr(PHYSICIANS_FILTER_FOR_CLINIC),
    ({
        specialty = '', worksAt = [], profiles, feedbacks = false, time, dates = {}, age,
    } = EMPTY_OBJECT) => [specialty, worksAt.length ? 1 : 0, Number(profiles), feedbacks, Number(time), dates.dateStart, age === '1' || age === '2' ? 1 : 0].reduce(
        (acc, next) => (next ? 1 + acc : acc),
        0,
    ),
);

export const getNumberOfActiveDoctorsVisitFilters = createSelector(
    dbGettr(PHYSICIANS_FILTER_FOR_VISIT),
    ({
        specialty = '', worksAt = [], profiles, feedbacks = false, time, dates = {}, age,
    } = EMPTY_OBJECT) => [specialty, worksAt.length ? 1 : 0, Number(profiles), feedbacks, Number(time), dates.dateStart, age === '1' || age === '2' ? 1 : 0].reduce(
        (acc, next) => (next ? 1 + acc : acc),
        0,
    ),
);

export const getNumberOfActiveMedCentersFilters = createSelector(dbGettr(MEDCENTERS_FILTER), (filterType = {}) => (!filterType.id || filterType.id === 'any' ? 0 : 1));

export const getIsFavoriteDoctorsFilterActive = createSelector(
    dbGettr(PHYSICIANS_FILTER_FAVORITES),
    (isFilterActive = false) => isFilterActive,
);

export const getIsFavoriteMedcentersFilterActive = createSelector(
    dbGettr(MEDCENTERS_FILTER_FAVORITES),
    (isFilterActive = false) => isFilterActive,
);

export const clinicsForLocation = createSelector(joinedClinics, getGlobalLocation, (clinics = {}, locationCode) => {

    clinics = Object.values(clinics);
    if (!clinics.length) {

        return null;

    }
    if (locationCode) {

        clinics = clinics.filter(e => e.areaCode === locationCode);

    }
    return clinics;

});
export const actualMedCenters = createSelector(
    [
        clinicsForLocation,
        dbGettr(MEDCENTERS_SEARCH),
        dbGettr(MEDCENTERS_FILTER),
        dbGettr(MEDCENTERS_FILTER_FAVORITES),
        prefGettr('fullFavorites'),
    ],
    (clinics, search, filter, filterByFavorites, favorites = []) => {

        if (!clinics) {

            return null;

        }
        clinics = clinics.filter(e => !e.hidden);
        if (search) {

            const keywords = getKeywordsFromSearchString(search);

            clinics = clinics.filter(({ _search }) => isStringIncludeAllKeywords(_search, keywords));

        }

        if (filter) {

            // eslint-disable-next-line default-case
            switch (filter.id) {

                case 'byProgram': {

                    clinics = clinics.filter(({ coverer }) => coverer);
                    break;

                }
                case 'byOnlineService': {

                    clinics = clinics.filter(({ onlineConnectivity }) => onlineConnectivity);
                    break;

                }

            }

        }
        if (!isEmpty(filterByFavorites)) {

            clinics = clinics.filter(item => favorites.some((e) => {

                const id = item?.item ? item?.item?.id : item?.id;
                return (e?.item ? e?.item?.id === id : e.id === id);

            } ));

        }
        return clinics;

    },
);
export const getIsShowMedcenterFilter = createSelector(
    [getCurrentOrFirstProgram, gettr('user.info.programs')],
    (currentProgram, programs = {}) => currentProgram?.id && currentProgram.id !== '*' && programs[currentProgram.id]?.covererCode !== 'bns-limited',
);
export const getClinicsMedcentersMap = createSelector([actualMedCenters], clinics => clinics
    .filter(e => e.latitude && e.longitude)
    .map(e => ({
        ...e,
        latitude: +e.latitude,
        longitude: +e.longitude,
        onlineConnectivity: e.onlineConnectivity ? 1 : null,
        phones: e.phones,
        message: e.onlineConnectivity ? Object.R('titles.onlineConnectivityMessage') : '',
        hasServices: e.services.length,
    })));

export const getClinicsHasBumba = createSelector([clinicsForLocation], (clinics) => {

    const c = clinics?.some(({ bumbaReportsSupport, bumbaTestsSupport }) => bumbaReportsSupport || bumbaTestsSupport);
    return !!c;

});

export const getClinicListWithBumba = (clinics) => {

    const result = [];
    (clinics ?? []).forEach(({ bumbaReportsSupport, bumbaTestsSupport, _id }) => {

        if (bumbaReportsSupport || bumbaTestsSupport) {

            result.push(_id);

        }

    });
    return result;

};

export const getClinicsWithBumba = createSelector([clinicsForLocation], clinics => getClinicListWithBumba(clinics));

export const getAllClinicsWithBumba = createSelector([joinedClinics], (clinics) => {

    try {

        return getClinicListWithBumba(Object.values(clinics));

    } catch {

        return [];

    }

});

export const getClinicsWithBumbaByType = createSelector([dbGettr('clinics.data')], (clinicsObj = {}) => {

    const clinics = Object.values(clinicsObj);
    return (clinics ?? [])
        .filter(({ bumbaReportsSupport, bumbaTestsSupport }) => bumbaReportsSupport || bumbaTestsSupport)
        .reduce(
            (prev, { bumbaReportsSupport, bumbaTestsSupport, _id }) => ({
                ...prev,
                [_id]: {
                    bumbaReportsSupport,
                    bumbaTestsSupport,
                },
            }),
            {},
        );

});

export const getAdaptedClinics = createSelector([dbGettr('clinics.data')], clinics => (isEmpty(clinics)
    ? EMPTY_OBJECT
    : arrayToHash(Object.values(clinics), '_id', e => Object.assign(Object.create(clinicProto), e))));

export const getClinicsNames = createSelector(
    [clinicsForLocation, (_, clinicIds) => clinicIds],
    (clinics, clinicIds) => {

        if (!clinicIds || !clinicIds?.length) {

            return [];

        }
        const result = clinics.filter(clinic => clinicIds?.some(el => el === clinic._id));
        return arrayToHash(
            result?.map(cl => ({
                name: cl?.name,
                id: cl?._id,
            })),
        );

    },
);
