import { FeedbacksPage } from '../Feedbacks/MedCenters/FeedbacksPage';
import {
    BigButton, Page, React, Section,
} from '../../common';
import { useAddFeedbackActionCallback } from '../Feedbacks/hooks';
import { McBanner } from './snippets';

/**
 * MedCenter Feedbacks page(☆☆☆☆☆).
 */
export const MedCenterFeedbacks = Page.register(({
    mdInfo,
    mdInfo: { _id: forItemId },
}) => {
    const onAdd = useAddFeedbackActionCallback(forItemId, 'clinics');
    return (
        <FeedbacksPage forItemKind="clinics" forItemId={forItemId}>
            <Section>
                <McBanner clinic={mdInfo}/>
                <BigButton action={onAdd} title="titles.leave_feedback" trackingAlias="Clinics"/>
            </Section>
        </FeedbacksPage>
    );
});
