import { personId4 } from '../const.stub';

export const profile = {
    _id: personId4,
    fullNameRu: 'Тест Тестов Тестович',
    primaryPhone: { number: '375291234567'},
    phones: [{ number: '375291234567'}],
    primaryEmail: { address: 'Auto_EPM-AIMD_Test4@epam.com', usage: 'PRIMARY', confirmed: true },
    emails: [{ address: 'Auto_EPM-AIMD_Test4@epam.com', usage: 'PRIMARY', confirmed: true }],
    birthday: '1992-05-08',
    sex: 'MALE',
    programs: [{ id: '5c2e74ad91aca54130b0c80d', covererCode: 'bns' }],
    keys: [{ tag: 'FULL_NAME_RU', value: 'Тест Тестов Тестович' }],
    external: {
        'horizont-minsk': {
            id: '81901',
        },
        'lode-minsk': {
            id: '122801',
            validFrom: '2018-09-06T19:39:53.217Z',
        },
    },
    hashKey: personId4,
    storeKey: personId4,
    _updated: { time: '2000-01-01T00:00:00.000Z' }
};

export const coverage = {
    people: [
        {
            profile: {
                _id: personId4,
                primaryPhone: { number: '375291234567' },
                phones: [{ number: '375291234567' }],
                birthday: '1992-05-08',
                sex: 'MALE',
                emails: [{ address: 'Auto_EPM-AIMD_Test4@epam.com', usage: 'PRIMARY', confirmed: true }],
                hashKey: personId4,
                storeKey: personId4,
                _updated: { time: '2000-01-01T00:00:00.000Z' }
            },
            profileId: personId4,
            relKind: 'OWNER'
        },
        {
            profile: {
                _id: '5bd1bd53cff47e000d5d7430',
                fullNameRu: 'Тестовый Ребёнок Тестович',
                birthday: '2018-10-04',
                relKind: 'CHILD',
                eligible: true,
                programs: [],
            },
            profileId: '5bd1bd53cff47e000d5d7430',
            relKind: 'CHILD',
        }
    ],
    programs: {
        '5c2e74ad91aca54130b0c80d': {
            _id: '5c2e74ad91aca54130b0c80d',
            name: 'Белнефтестрах',
            shortName: 'АЛ_АППкд',
            description: 'Амбулаторно-поликлиническая помощь консультативно-диагностическая',
            status: 'OK',
            branchIds: ['5b46096c302c5a36f', '5c0f888dc868d0c02'],
            eligible: true,
            covererCode: 'bns',
            insurerCode: 'bns',
        },
    },
};