import { urlParse, tail } from '../../app/utils';

/* eslint-disable no-param-reassign */
const fileNameToKey = file => tail(file, '/');
const minimatch = (key, pattern) => key.match((new RegExp(pattern.replace('*', '.+'), 'g')));

export default (storageStub, routes) => ({
    fs: {
        dirs: { DocumentDir: '' },
        readFile: file => JSON.stringify(storageStub[fileNameToKey(file)]),
        writeFile: (file, s) => {
            let data = null;
            try {
                data = JSON.parse(s);
            } catch (e) {
                data = s;
            }
            storageStub[fileNameToKey(file)] = data;
        },
        unlink: (file) => { delete storageStub[fileNameToKey(file)]; },
        exists: file => Promise.resolve(fileNameToKey(file) in storageStub),
        ls: () => Promise.resolve(Object.keys(storageStub)),
    },
    config: () => ({
        fetch(method, u, requestHeaders = {}, requestBody) {
            const profile = requestHeaders.Authorization ? tail(requestHeaders.Authorization, ':') : requestHeaders['aimd-auth'];
            const url = urlParse(u, { method, headers: requestHeaders, body: requestBody, profile });
            const propKey = `${method}!/${url.path.join('/')}`;
            const routeKey = routes[propKey] ? propKey : Object.keys(routes).find(pattern => minimatch(propKey, pattern));
            const route = routes[routeKey] || (() => ({ body: '-', status: 404 }));
            const result = route(url);
            const { body = `{"error":"empty response body","r":"${routeKey}", "f":"${JSON.stringify(result)}"}`, status = 200, headers = {} } = result;
            const info = { data: body, status, headers };
            return Promise.resolve({ respInfo: info, data: body, info: () => info, json: () => JSON.parse(body) });
        },
    }),
});