import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { inAppUpdaterStyles as styles } from '../../../common/InAppUpdater';
import {
    Button, Card, Icon, Text, View,
} from '../../../common';
import { getEnums } from '../../../i18n';
import { useSelector } from 'react-redux';
import { getUserCards } from '../../../selectors';

const ENUM_KEY = 'сlinicWarning';
const STORAGE_KEY = 'disableOfflineClinicWarning';


const getDisabledReminder = async providerCode => (await localStorage.getItem(`${STORAGE_KEY}_${providerCode}`) ?? false);
const setDisabledReminder = async (providerCode, value) => localStorage.setItem(`${STORAGE_KEY}_${providerCode}`, value);

export const OfflineClinicWarningWindow = ({ providerCode }) => {

    const [isWindowDisable, setIsWindowDisable] = useState(true);

    const offlineMessage = useMemo(() => {

        const messages = getEnums(ENUM_KEY);
        return messages?.find?.(({ id }) => id === providerCode)?.routeTo;

    }, [providerCode]);

    useEffect(() => {

        getDisabledReminder(providerCode)
            .then(setIsWindowDisable);

    }, []);

    const closeWindowHandler = useCallback(() => {

        setIsWindowDisable(true);
        setDisabledReminder(providerCode, true);

    }, [providerCode, setIsWindowDisable]);

    return !!offlineMessage && !isWindowDisable && <Card containerStyle={[styles.wrapper, { position: 'relative' }]}>
        <View style={styles.lineWrapper}>
            <Button
                action={closeWindowHandler}
                styles={[
                    styles.closeButton,
                    {
                        position: 'absolute',
                        right: -14,
                        top: -14,
                    },
                ]}>
                <Icon name="cross" color="#787878" style={styles.closeIcon} />
            </Button>
        </View>
        <Text>{Object.R(offlineMessage)}</Text>
        <View />
    </Card>;

};

export const OfflineClinicWarning = () => {

    const cards = useSelector(getUserCards);

    const offlineMessages = useMemo(() => {

        return getEnums(ENUM_KEY);

    }, []);

    const listClinic = useMemo(() => {

        return offlineMessages?.filter(({ id }) => cards?.find?.(({ providerCode }) => id === providerCode));

    }, [cards, offlineMessages]);

    return listClinic?.map(({ id }) => (<OfflineClinicWarningWindow key={id} providerCode={id} />));

};
