import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { Page, React } from '../../common';
import {
    getCurrentProgram, getHasOnlyChildProgram, getRelatedPrograms, umsLocalServicesTree,
} from '../../selectors';
import { navigate } from '../../actions';
import { sortBy } from '../../utils';
import { ProgramSelector } from '../../combo';
import { useBackHandler } from '../../utils/routing';
import { ButtonLeft } from './snippets';
import { AdaptiveListHeader } from './AdaptiveListHeader';
import { GroupsList, ItemsList } from './UmsLists';
import { SearchByKeywordList } from './SearchByKeywordList';
import { listVisibleProcessor } from './utils';

/* eslint-disable complexity */

export const UmsMain = Page.register(
    ({
        level = 'type',
        keyword = '',
        onSearchValue = '',
        typeId = null,
        typeName = null,
        groupId = null,
        groupName = null,
        tree = [],
        programs = {},
        programsList = Object.values(programs),
        hasOnlyChildProgram,
        currentProgram = programsList.length ? ((hasOnlyChildProgram && programsList.find(e => e.id !== '*')) || programsList[0]) : {},
        actions,
        typeVisible = true,
        groupVisible = false,
        itemVisible = false,
        searchVisible = false,
        navigation,
        route,
        fromSearch = false,
        listsControlParamsBeforeTransitionToGroup,
    }) => {
        const { setParams } = useNavigation();
        const onSearch = useCallback(v => setParams({
            keyword: v,
            onSearchValue: v,
            searchVisible: v.length > 2,
        }), []);
        const onBack = useCallback(() => {
            // eslint-disable-next-line no-undef
            if ((window._currentRouteName === 'UmsMain' || window._currentRouteName === 'MedCenterServices') && (route.params?.level === 'item' || route.params?.level === 'group')) {
                listVisibleProcessor(route.params, navigation);
                return true;
            }
            return false;
        }, [route.params, navigation]);
        useBackHandler(onBack);
        const listsControlParams = {
            keyword,
            onSearchValue,
            typeId,
            typeName,
            groupId,
            groupName,
            typeVisible,
            groupVisible,
            itemVisible,
            searchVisible,
            setParams,
            level,
            fromSearch,
            listsControlParamsBeforeTransitionToGroup,
        };
        const isProgrammSelectorVisible = programsList.length > 1 || programsList.some(({ id }) => id !== '*');

        const isTypeList = Object.values(tree).length && onSearchValue.length <= 2;
        useEffect(() => {
            actions.setProgram(currentProgram);
        }, []);
        useFocusEffect(() => {
            actions.setFormValue('umsService', null);
        });
        const ums = useSelector(state => state?.ums);
        return (
            <Page name="ums" noscroll>
                {typeVisible || searchVisible
                    ? (
                        <SearchByKeywordList
                            // data={tree}
                            tree={tree}
                            keyword={keyword}
                            onItem={item => navigate('ServiceDetails', { details: { ...item } })}
                            onTitleSecondPress={setParams}
                            currentProgram={currentProgram}
                            setParamsUmsMain={setParams}
                            listsControlParams={listsControlParams}
                            isProgrammSelectorVisible={isProgrammSelectorVisible}
                            headerComponents={[
                                (isProgrammSelectorVisible && (typeVisible || searchVisible))
                                    ? <ProgramSelector withInfo/> : null,
                            ]}
                            onSearchValue={onSearchValue}
                            onSearch={onSearch}
                            onTypeListItem={item => listVisibleProcessor(listsControlParams, navigation, item)}
                            listVisibleProcessor={listVisibleProcessor}
                            isTypeList={isTypeList}
                            isComponentVisible={typeVisible || searchVisible}
                            isLoading={ums.isLoading}
                        />
                    )
                    : null

                }
                {groupVisible && !(typeVisible || searchVisible)
                    ? (
                        <GroupsList
                            data={(tree && tree[typeId] && sortBy(Object.values(tree[typeId].sub))) || []}
                            currentProgram={currentProgram}
                            isProgrammSelectorVisible={isProgrammSelectorVisible}
                            onItem={item => listVisibleProcessor(listsControlParams, navigation, item)}
                            headerComponents={[
                                isProgrammSelectorVisible && groupVisible ? <ProgramSelector withInfo/> : null,
                                groupVisible
                                    ? <AdaptiveListHeader titleFirst={typeName} titleSecond={groupName}/> : null,
                            ]}
                            buttonLeft={groupVisible && !keyword ? <ButtonLeft
                                key="back"
                                onPress={onBack}
                            /> : null}
                            fromSearch={fromSearch}
                        />
                    )
                    : null

                }
                {itemVisible && !(typeVisible || searchVisible)
                    ? (
                        <ItemsList
                            data={(tree && tree[typeId] && tree[typeId].sub[groupId] && sortBy(Object.values(tree[typeId].sub[groupId].sub))) || []}
                            currentProgram={currentProgram}
                            isProgrammSelectorVisible={isProgrammSelectorVisible}
                            onItem={(item) => {
                                actions.setFormValue('umsService', item);
                                navigate('ServiceDetails', { details: { ...item } });
                            }}
                            headerComponents={[
                                isProgrammSelectorVisible && itemVisible ? <ProgramSelector withInfo/> : null,
                                itemVisible
                                    ? <AdaptiveListHeader titleFirst={typeName} titleSecond={groupName}/> : null,
                            ]}
                            buttonLeft={itemVisible && !keyword ? <ButtonLeft
                                key="back"
                                onPress={() => listVisibleProcessor(route.params, navigation)}
                            /> : null}
                            fromSearch={fromSearch}
                        />
                    )
                    : null
                }
            </Page>
        );
    },
    {
        tree: { from: umsLocalServicesTree },
        programs: { from: getRelatedPrograms },
        currentProgram: { from: getCurrentProgram },
        hasOnlyChildProgram: { from: getHasOnlyChildProgram },
    },
);
