import { Platform } from 'react-native';
import { MY_API_HOST, UMS_API_HOST, VERSION } from '../config';
import { removeEmpty, urlStringify } from '../utils';
import { ErrorManager } from '../utils/ErrorManager';
import firebase from './firebase';
import {
    del, get, patch, post, put,
} from './http';

// my API
export const myApiUrlBase = `https://${MY_API_HOST}/api/v2/my`;
const userProfileUrl = `${myApiUrlBase}/profile`;
const addRelativeUrl = `${myApiUrlBase}/profile/add-relative-start`;
const sendRelativeSmsUrl = `${myApiUrlBase}/profile/add-relative-sms-send`;
const confirmRelativeSmsCodeUrl = `${myApiUrlBase}/profile/add-relative-sms-confirm`;
const addChildUrl = `${myApiUrlBase}/profile/add-child`;
const updateChildUrl = `${myApiUrlBase}/profile/child`;
const userPhotoUrl = `${myApiUrlBase}/profile/photo`;
const userFamilyCodeUrl = `${myApiUrlBase}/family/share`;
const userFamilyShareableUrl = `${myApiUrlBase}/family/shareable`;
const userLinkToFamilyUrl = `${myApiUrlBase}/family/join`;
const updateRelativeURL = `${myApiUrlBase}/profile/relative`;
const getGruntsUrl = `${myApiUrlBase}/grants`;
const providersUrl = `${myApiUrlBase}/providers`;
const visitLoadUrl = `${providersUrl}/sync/`;
const timetableUrl = `${providersUrl}/timetables`;
const pingVisitsUrl = `${providersUrl}/sync/orders`;
const manualVisitsUrl = `${providersUrl}/orders/manual`;
const visitsUrl = `${providersUrl}/orders`;
const visitsIndependentUrl = `${providersUrl}/orders/independent`;
const feedbackUrl = `${myApiUrlBase}/feedback/list`;
const relativesUrl = `${myApiUrlBase}/coverage`;
const feedbacksUrl = `${myApiUrlBase}/feedback`;
const invoicesUrl = `${myApiUrlBase}/services`;
const programsUrl = `${myApiUrlBase}/ums/program`;
const requestAccessByEmail = `${myApiUrlBase}/device-set-profile`;
const resendActivationEmail = `${userProfileUrl}/re-confirm`;
const discoverCovererUpdatesUrl = `${myApiUrlBase}/profile/discover`;
const doctorsSerch = `${myApiUrlBase}/providers/assignment/summary`;
const testsUrl = `${myApiUrlBase}/providers/tests`;
const pubOrderDataUrl = `${myApiUrlBase}/providers/orders/pub`;

const servicesUrl = `${providersUrl}`;
// https://my.aibolit-qa2.epm-aimd.projects.epam.com/api/v2/my/providers/tests
const visitRecordUrl = `${providersUrl}/reports`;

// public
export const publicApiUrlBase = `https://${UMS_API_HOST}/api/v2/public`;
const umsItemUrl = `${publicApiUrlBase}/ums/ref-opts-items`;
const umsServiceUrl = `${publicApiUrlBase}/ums/tree-spec/latest`;
const umsVersionUrl = `${publicApiUrlBase}/ums/tree-spec/version`;
const umsServiceProvidersUrl = `${publicApiUrlBase}/ums/item-providers`;

// bns
const bnsRegisterUrl = `${myApiUrlBase}/profile/link-to-coverer/bns`;
const bnsInfoUrl = `${myApiUrlBase}/bns/program-details`;
const bnsRelativeRegisterUrl = `${userProfileUrl}/link-relative-to-coverer/bns`;

// epam
const epamRegisterUrl = `${myApiUrlBase}/profile/link-to-coverer/epam`;
const epamUnregisterUrl = `${myApiUrlBase}/profile/unlink-coverer/epam`;

// authorization
const loginUrl = `${myApiUrlBase}/phone-login/init`;
const confirmSmsCodeUrl = `${myApiUrlBase}/phone-login/confirm-sms`;
const getCaptchaCodeUrl = `${myApiUrlBase}/phone-login/human-code`;
const confirmCaptchaCodeUrl = `${myApiUrlBase}/phone-login/confirm-human`;

const logError = (prefix, ex) => {
    // eslint-disable-next-line no-console
    console.log(`ERROR: ${prefix} ${ex}`);
    return ex;
};

const url = (target, params) => ({
    target,
    params,
});

const errHandler = title => (ex) => {
    logError(title, ex);
    ErrorManager.setError(ex);
    return { error: ex.message };
};

// noinspection JSUnusedGlobalSymbols
/**
 * API client service.
 *
 * Here we just fetch/submit the data 'as is', do no adapt.
 */
export default {
    /**
     * User.
     */

    userPhoto: () => get(userPhotoUrl, undefined, undefined, 'blob')
        .then(({ data }) => {
            if (Platform.OS === 'web') {
                return URL.createObjectURL(data);
            }
            const decoded = decodeURIComponent(data);
            const protocol = decoded.slice(0, 4);
            return protocol === 'http' || protocol === 'data' ? decoded : `data:image/jpeg;base64,${decoded}`;
        })
        .catch((ex) => {
            logError(`userPhoto`, ex);
            return null;
        }),

    userInfo: (anonymousId, deviceId, fcmToken) => get
        .Json(
            url(
                userProfileUrl,
                removeEmpty({
                    version: VERSION,
                    anonymousId,
                    deviceId,
                    apiClient: Platform.OS === 'web' ? 'mwa' : 'mma',
                }),
            ),
            undefined,
            { 'AIMD-fcm-token': fcmToken },
        )
        .catch(error => ({
            error: logError('userInfo', error),
            outdatedAppVersion: `${error.status}` === '301', // Moved permanently
        })),

    initUser: deviceIdOfExistedUser => post.Json(
        deviceIdOfExistedUser
            ? urlStringify({
                target: userProfileUrl,
                params: { forceNew: true },
            })
            : userProfileUrl,
        {},
    ),
    updateUserInfo: payload => patch.Json(userProfileUrl, payload).catch(errHandler(`sendUserInfo`)),
    addChild: payload => post.Json(url(addChildUrl), payload),
    updateChild: (payload, reInitPersonalCards = false) => patch.Json(url(updateChildUrl, { reInitPersonalCards }), payload),
    deleteChild: id => del.Json(`${updateChildUrl}/${id}`),
    userFamilyCode: shareableMembers => post.Json(userFamilyCodeUrl, shareableMembers),
    userFamilyShareable: () => get.Json(userFamilyShareableUrl),
    addRelative: body => post.Json(addRelativeUrl, body),
    updateRelative: (payload, reInitPersonalCards = false) => patch.Json(url(updateRelativeURL, { reInitPersonalCards }), payload),
    deleteRelative: id => del.Json(`${updateRelativeURL}/${id}`),
    sendRelativeSms: relId => post.Json(
        urlStringify({
            target: sendRelativeSmsUrl,
            params: { relId },
        }),
        {},
    ),
    confirmRelativeSmsCode: (relId, code) => post.Json(
        urlStringify({
            target: confirmRelativeSmsCodeUrl,
            params: {
                relId,
                code,
            },
        }),
        {},
    ),
    getGrunts: () => get.Json(getGruntsUrl),
    updateGrunts: payload => patch.Json(getGruntsUrl, payload),
    userRelatives: () => get.Json(relativesUrl).catch(errHandler(`userRelatives`)),
    requestAccessByEmail: (profileId, deviceName, email) => post.Json(
        url(`${requestAccessByEmail}/${profileId}`, {
            method: 'EMAIL',
            deviceName,
        }),
        email,
    ),
    // .catch(errHandler(`userRelatives`)),
    resendActivationEmail: () => post.Json(`${resendActivationEmail}`).catch(errHandler(`userRelatives`)),
    discoverCovererUpdates: (body = {}) => post.Json(discoverCovererUpdatesUrl, body),

    linkToFamily: code => post.Json(url(`${userLinkToFamilyUrl}/${code}`)).catch((err) => {
        const errObject = { message: Object.R('error.invalidCodeSent') };
        ErrorManager.setError(errObject);
        throw Object.assign(err, errObject);
    }),

    isAuthenticated: firebase.isAuthenticated,
    checkAuth: () => firebase.checkAuth(),
    logOut: () => firebase.signOut().then(() => firebase.isAuthenticated()),
    removeAccount: () => del.Json(userProfileUrl).catch(errHandler(`removeAccount`)),

    /**
     * Visits.
     */

    pingVisits: () => get(
        url(pingVisitsUrl, {
            status: 'all',
            splitpastvisits: 'true',
        }),
    ),

    getDoctorsTimetable: ({
        assignmentId,
        physicianId,
        now = new Date(),
        dateStart = now.toISOString().slice(0, 10),
        dateEnd = new Date(now.setMonth(now.getMonth() + 2)).toISOString().slice(0, 10),
    }) => (firebase.isAuthenticated()
        ? get.Json(
            url(timetableUrl, {
                assignmentId,
                dateStart,
                dateEnd,
                physicianId,
            }),
        )
        : get.Json.Anonymously(
            url(timetableUrl, {
                assignmentId,
                dateStart,
                dateEnd,
                physicianId,
            }),
        )),
    // makeVisitRecordWithProfiles: ({ timetableId}) => post.Json(url(visitsUrl), {}),
    makeVisitRecord: ({
        timetableId, assignmentId, newPersonId, programId, patientData,
    }) => post
        .Json(url(visitsUrl), {
            timetableId,
            assignmentId,
            profileId: newPersonId,
            programId,
            patientData,
        })
        .catch((err) => {
            const errObject =
                    err.status === 403
                        ? Object.assign(err, { message: Object.R('error.onlineBooking/Forbidden0') })
                        : err;
            ErrorManager.setError(errObject);
            throw errObject;
        }),
    makeVisitIndependent: ({
        timetableId,
        serviceId,
        serviceName,
        slotDuration,
        assignmentId,
        patientData: {
            fullNameRu, birthday, sex, phones,
        },
    }) => post.Json(url(visitsIndependentUrl), {
        timetableId,
        serviceId,
        serviceName,
        slotDuration,
        assignmentId,
        patientData: {
            fullNameRu,
            birthday,
            sex,
            phones,
        },
    }),
    makeServiceRecord: ({
        timetableId,
        serviceId,
        serviceName,
        slotDuration,
        assignmentId,
        profileId,
        programId,
        covererCode,
        newPersonId,
        patientData,
    }) => post
        .Json(url(visitsUrl), {
            timetableId,
            serviceId,
            serviceName,
            slotDuration,
            assignmentId,
            profileId: newPersonId || profileId,
            programId,
            covererCode,
            patientData,
        })
        .catch((err) => {
            const errObject =
                    err.status === 403
                        ? Object.assign(err, { message: Object.R('error.onlineBooking/Forbidden0') })
                        : err;
            ErrorManager.setError(errObject);
            throw errObject;
        }),

    changeServiceRecord: ({
        orderId,
        timetableId,
        serviceId,
        serviceName,
        slotDuration,
        assignmentId,
        profileId,
        newPersonId,
        programId,
        covererCode,
        patientData,
    }) => put.Json(
        url(`${visitsUrl}/${orderId}`, {
            profileId,
            covererCode,
        }),
        {
            timetableId,
            assignmentId,
            profileId: newPersonId,
            programId,
            serviceId,
            serviceName,
            slotDuration,
            patientData,
        },
    ),
    changeVisitRecord: ({
        timetableId,
        orderId,
        assignmentId,
        profileId,
        newPersonId,
        programId,
        covererCode,
        patientData,
    }) => put.Json(
        url(`${visitsUrl}/${orderId}`, {
            profileId,
            covererCode,
        }),
        {
            timetableId,
            assignmentId,
            profileId: newPersonId,
            programId,
            patientData,
        },
    ),
    updateOrder: ({
        orderId, name, comment, drugs = [], attachments = [], providerCode, profileId: orderProfileId,
    }) => put.Json(url(`${visitsUrl}/${orderId}/auxiliarydata`), {
        name: (name || '').trim(),
        comment: (comment || '').trim(),
        drugs,
        attachments,
        providerCode,
        orderProfileId,
    }),

    declineVisit: ({
        orderId, assignmentId, profileId, covererCode, errorExtraText,
    }) => del(
        url(`${visitsUrl}/${orderId}`, {
            assignmentId,
            profileId,
            covererCode,
        }),
    ).then((response) => {
        const info = response.info() ?? {};
        const {
            status, code, body, title, type,
        } = info;
        if (status !== 204) {
            if (status === 423) {
                throw new Error(`${(JSON.parse(response.data) || {}).detail}\n${errorExtraText}`);
            } else if (status === 403) {
                let errorInfo = {};
                try {
                    errorInfo = JSON.parse(response.data);
                } catch (e) {
                    errorInfo = {
                        status,
                        code,
                        title,
                        type,
                        ...JSON.parse(body),
                    };
                }
                throw errorInfo;
            }
            throw new Error(Object.R('titles.service_unavailable'));
        }
    }),
    loadVisits: (profileId, clinicId, profiles = []) => post(`${visitLoadUrl}${profileId}/orders`, {
        status: 'all',
        clinicId,
        async: false,
        forced: true,
        profiles,
    }),

    getBookingCapabilities: (assignmentId, serviceId) => get.Json(
        url(`${providersUrl}/book-capabilities`, {
            assignmentId,
            serviceId,
        }),
    ),
    getBookingCapabilitiesWithProfiles: (assignmentId, serviceId, profiles) => post.Json(`${providersUrl}/book-capabilities`, {
        assignmentId,
        serviceId,
        profiles,
    }),
    // getVisitReport: (reportId, providerCode, code, resultId) => get(`${visitRecordUrl}/${reportId}/${resultId}?providerCode=${providerCode}`, undefined, { 'AIMD-A-Code': code }, 'blob'),
    // eslint-disable-next-line max-params
    getVisitReport: (reportId, providerCode, code, resultId, profileId, customerId, policyId, orderId) => get(
            `${visitRecordUrl}/${profileId}/${reportId}/${resultId}?providerCode=${providerCode}${
                customerId ? `&customerId=${customerId}` : ''
            }${policyId ? `&policyId=${policyId}` : ''}${orderId ? `&orderId=${orderId}` : ''}`,
            undefined,
            { 'AIMD-A-Code': code },
            'blob',
    ),
    manualVisit: params => put(manualVisitsUrl, params),

    /**
     * Feedbacks
     */
    getFeedbacksFor: itemId => get.Json(url(feedbackUrl, { itemId })),
    getCompletedFeedbacksFor: itemKind => get.Json(url(`${feedbacksUrl}/completed/items`, { itemKind })),
    getCompletedVotes: itemKind => get.Json(url(`${feedbacksUrl}/completed/votes`, { itemKind })),
    getFeedbacksAnonymouslyFor: itemId => get.Json.Anonymously(url(feedbackUrl, { itemId })),
    modifyFeedback: feedback => put.Json(feedbacksUrl, feedback),
    deleteFeedback: itemId => del.Json(`${feedbacksUrl}/${itemId}`),
    postUseful: (feedbackId, useful) => post.Json(`${feedbacksUrl}/${feedbackId}/useful`, { useful }),
    postFeedbackAbuse: (forItemId, feedbackText = 'Reporting abuse for feedback') => put.Json(`${feedbacksUrl}/abuse`, {
        forItemId,
        feedbackText,
    }),
    postFeedbackAbuseAnonymous: (forItemId, feedbackText = 'Reporting abuse for feedback') => put.Json.Anonymously(
            `${feedbacksUrl}/abuse`,
            { 'content-type': 'application/json' },
            {
                forItemId,
                feedbackText,
            },
    ),

    /**
     * UMS Services.
     */
    getUmsServices: () => get.Json.Anonymously(umsServiceUrl),
    getUmsVersion: () => get.Json.Anonymously(umsVersionUrl),
    getUmsServicesByProviders: () => get.Json.Anonymously(umsServiceProvidersUrl),
    getUmsItemInfo: (itemOrOption, program) => get.Json.Anonymously(
        url(umsItemUrl, {
            itemOrOption,
            program,
        }),
    ),
    getUmsProgram: programId => get.Json(url(`${programsUrl}/${programId}`)),
    getServicesDoctors: (serviceIds, branchIds) => get.Json.Anonymously(
        url(doctorsSerch, {
            serviceIds: serviceIds.join(','),
            branchIds: branchIds.join(','),
        }),
    ),

    /**
     * Invoices
     */
    getAllInvoices: () => get.Json(invoicesUrl),
    processingInvoices: (approvedServicesIds = [], rejectionInfo = []) => post(invoicesUrl, {
        approvedServicesIds,
        rejectionInfo,
    })
        .then(() => 1)
        .catch((ex) => {
            // eslint-disable-next-line no-console
            const errString = `ERROR: processingInvoices ${ex.message}`;
            ErrorManager.setError(errString);
            // eslint-disable-next-line no-console
            console.log(errString);
        }),

    /**
     * BNS Insurance
     */
    bnsRegister: code => post(url(`${bnsRegisterUrl}/${encodeURI(code).replace(/\//g, '%2F')}`)).then(res => res.json()),
    bnsInfo: () => get.Json(url(bnsInfoUrl)),
    bnsRelativeRegister: (code, relativeId) => post.Json(`${bnsRelativeRegisterUrl}/${encodeURI(code).replace(/\//g, '%2F')}/${relativeId}`),

    /**
     * EPAM Health care
     */
    epamActivateEmailAndRegister: email => post.Json(url(`${epamRegisterUrl}/${email}`)),
    epamCancelRegister: () => post.Json(url(`${epamUnregisterUrl}`)),
    epamRegister: () => post.Json(url(epamRegisterUrl)),

    /**
     * Authorization
     */
    phoneLogin: (phone, deviceId, deviceName, appHash) => post.Json.Anonymously(
        url(loginUrl),
        { 'Content-Type': 'application/json' },
        {
            phone,
            deviceId,
            deviceName,
            appHash,
        },
    ),
    confirmSms: (code, id) => post.Anonymously(url(`${confirmSmsCodeUrl}/${id}`), { 'Content-Type': 'application/json' }, code),
    getCaptchaCode: id => get
        .Anonymously(url(`${getCaptchaCodeUrl}/${id}`))
        .then(({ data }) => {
            const decoded = decodeURIComponent(data);
            const protocol = decoded.slice(0, 4);
            return protocol === 'http' || protocol === 'data' ? decoded : `data:image/png;base64,${decoded}`;
        })
        .catch((ex) => {
            logError(`HumanCodeImage`, ex);
            return null;
        }),
    getCaptchaCodeWeb: id => get
        .AnonymouslyBin(url(`${getCaptchaCodeUrl}/${id}`))
        .then(({ data }) => {
            return URL.createObjectURL(data);
        })
    // eslint-disable-next-line no-console
        .catch(e => console.log('Error in web captcha', e)),
    confirmCaptchaCode: (code, id) => post.Json.Anonymously(url(`${confirmCaptchaCodeUrl}/${id}`), { 'Content-Type': 'text/plain' }, code),
    /**
     * Doctors search
     */
    // eslint-disable-next-line max-params
    getDoctorSearchResult: (areaCode, dateStart, dateEnd, timeStart, timeEnd, specialityName) => get.Anonymously(
        url(doctorsSerch, {
            specialityName,
            areaCode,
            dateStart,
            dateEnd,
            timeStart,
            timeEnd,
        }),
    ),

    /**
     * Tests
     */
    getTests: () => get.Json(testsUrl),
    // eslint-disable-next-line max-params
    getTestResult: (testId, resultsProvider, providerCode, code, resultId, profileId, customerId, policyId) => get(
            `${testsUrl}/${profileId}/${testId}/results/${resultsProvider}/${resultId}?providerCode=${providerCode}${
                customerId ? `&customerId=${customerId}` : ''
            }${policyId ? `&policyId=${policyId}` : ''}`,
            undefined,
            {
                'AIMD-A-Code': code,
                'Content-Type': 'application/pdf',
            },
            'blob',
    ),
    syncTests: () => post(`${providersUrl}/sync/tests`),
    tryGetServices: (assignmentId, programId) => (firebase.isAuthenticated()
        ? get.Json(
            programId
                ? `${servicesUrl}/assignments/${assignmentId}/services?programIds=${programId}`
                : `${servicesUrl}/assignments/${assignmentId}/services`,
        )
        : get.Json.Anonymously(`${servicesUrl}/assignments/${assignmentId}/services`)),
    getDoctorDescriptions: (assignments = []) => (firebase.isAuthenticated()
        ? get.Json(`${servicesUrl}/assignments?ids=${assignments.join(',')}`)
        : get.Json.Anonymously(`${servicesUrl}/assignments?ids=${assignments.join(',')}`)),
    resetFCMToken: () => {
        return post.Json(`${myApiUrlBase}/device/reset-fcm-token`);
    },
    /**
     * OBW visits
     */

    getPubVisit: (orderId, pCode) => get(`${pubOrderDataUrl}/discover?${pCode ? `providerCode=${pCode}&` : ''}orderId=${orderId}`),
    importPubVisit: (profileId, omId, useOrderPatientData) => post(`${pubOrderDataUrl}/import`, { omId, profileId, useOrderPatientData }),
};
