import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import {
    Button, ButtonsGroup, Modal2, Page, Title, TouchableWithoutFeedback, View,
} from '../../../../common';

export const AlreadyImportedModal = () => {

    const navigation = useNavigation();
    const chooseDoctorHandler = () => {

        Page.closeModal(() => {

            navigation.navigate('DoctorsSpecialtiesSearch');

        });

    };

    useEffect(() => {

        Object.orderId = null;
        Object.orderProviderCode = null;
        Object.onNewVisitFound = null;
        Object.onAlreadyImported = null;

    }, []);

    return (
        <Modal2 title={'titles.alreadyImportedTitle'} hideClose>
            <TouchableWithoutFeedback>
                <View style={{ paddingBottom: 12 }}>
                    <View style={{ padding: 12 }}>
                        <Title id="titles.alreadyImportedText" numberOfLines={0} style={{ paddingBottom: 24 }} />
                    </View>
                    <ButtonsGroup>
                        <Button title="buttons.close" action={Page.closeModal} normal />
                        <Button title="buttons.chooseDoctor" action={chooseDoctorHandler} primary />
                    </ButtonsGroup>
                </View>
            </TouchableWithoutFeedback>
        </Modal2>
    );

};
