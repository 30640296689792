import api from './api';
import { getFinalMessage } from './http';
import { removePendingPrograms } from './programs';

/**
 * Service around EPAM Healthcare program.
 */
export default {

    // register EPAM-user with his EPAM Healthcare program
    register: async (email, previousMail, cb) => {
        try {
            cb({
                error: null,
                isLoading: true,
            });
            if (previousMail && previousMail.address) {
                await removePendingPrograms();
            }
            const result = await api.epamActivateEmailAndRegister(email);
            cb({
                success: result,
                isLoading: false,
            });
        } catch (err) {
            cb({
                error: {
                    ...err,
                    message: err.message || getFinalMessage(err),
                },
                isLoading: false,
            });
        }
    },
};
