
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { tabBar as tabStyles } from '../styles/components';
import { getEnums } from '../i18n';
import { getCurrentProgram, getMedcentersListWithPrograms } from '../selectors';
import { medcenterFilter as style } from '../combo/style';
import { Icon } from './Icon';
import { Subtitle, Title, WithBadge } from './elements';
import {
    React, Text, TouchableOpacity, View,
} from './react';
import {
    checkBox as checkBoxStyles, COLORS, getMarginHorizontalObject, selector as styles,
} from './style';
import { TextHighliter } from './TextHighliter';
import { Modal2, useKeyboardHeight } from './Modal';
import { SearchBar } from './SearchBar';
import { TabSwitch } from './TabSwitch';
import { CoverableBy, FlatList, Page } from '.';

const activeTitleColor = '#555555';
const inactiveTitleColor = '#9C9C9C';
export const narrowData = data => (Array.isArray(data)
    ? data
    : Object.entries(data).map(([id, val]) => (typeof val === 'string'
        ? {
            id,
            name: val,
        }
        : val)));

export const Selector = ({
    data = {},
    onSelect,
    selected,
    radio,
    keyword,
    style: stl,
    noLastLine,
    program,
    noTextLim,
    itemStyle,
    itemTextStyle,
    firstLine,
}) => {

    // eslint-disable-next-line no-unused-vars
    const [_, setEntry] = React.useState();
    const actualData = narrowData(data);
    return (
        <View
            style={{
                ...styles.listWrapper,
                flex: 1,
                marginHorizontal: 0,
                paddingHorizontal: 0,
                borderTopWidth: 0,
            }}
            accessibilityLabel="list">
            {actualData.map(
                // eslint-disable-next-line complexity
                (entry, idx, { isSelected = entry.selected || selected === entry.id }) => {

                    const onPress = () => {

                        if (entry.disabled) {

                            return;

                        }
                        setEntry(entry);
                        onSelect(entry, isSelected);

                    };
                    const isLast = idx + 1 === actualData?.length;
                    const coverage = entry?.programCoverage && program && entry?.programCoverage[program?.id];
                    const coverer = coverage?.coverage && Number(coverage?.coverage) > 0 ? program.coverer : null;
                    const additionalProps = noTextLim ? { numberOfLines: 0 } : {};
                    return radio ? (
                        <TouchableOpacity
                            key={entry.id}
                            onPress={onPress}
                            style={[styles.listItemWrapper, stl, isLast && noLastLine && { borderBottomWidth: 0 }]}>
                            <View style={[styles.radioWrapper, isSelected ? styles.radioWrapperActive : null]}>
                                {isSelected ? <View style={styles.circleActive} /> : null}
                            </View>
                            {keyword ? (
                                <View
                                    style={{
                                        flexDirection: 'column',
                                        flex: 1,
                                    }}>
                                    <TextHighliter value={`${entry.name}`} keyword={`${keyword}`} numberOfLines={0} />
                                </View>
                            ) : (
                                <View
                                    style={{
                                        flexDirection: 'column',
                                        flex: 1,
                                    }}>
                                    <Title
                                        style={[styles.listItemText, entry.disabled ? styles.listItemDisabled : null]}
                                        {...additionalProps}>{`${entry.name}`}</Title>
                                    {entry?.subtitle ? (
                                        <Subtitle
                                            style={[
                                                styles.listItemTextSmall,
                                                entry.disabled ? styles.listItemDisabled : null,
                                            ]}>
                                            {entry?.subtitle}
                                        </Subtitle>
                                    ) : (
                                        <></>
                                    )}
                                </View>
                            )}
                            {coverer ? (
                                <CoverableBy.Dot
                                    coverer={coverer}
                                    style={{ marginLeft: 12 }}
                                    isLimited={coverage?.attributes}
                                />
                            ) : null}
                        </TouchableOpacity>
                    ) : (
                        <TouchableOpacity
                            key={entry.id}
                            style={[
                                styles.item,
                                isLast && noLastLine && { borderBottomWidth: 0 },
                                stl,
                                idx === 0 && firstLine && { borderTopWidth: 1 },
                            ]}
                            onPress={onPress}>
                            <View
                                style={[
                                    {
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    },
                                    itemStyle,
                                ]}>
                                <Text style={[isSelected ? styles.itemSelectedTxt : styles.itemTxt, itemTextStyle]}>
                                    {entry.name}
                                </Text>
                                {isSelected && <Icon name="check" color="#5EB3E3" />}
                            </View>
                        </TouchableOpacity>
                    );

                },
            )}
        </View>
    );

};

export const MultiSelector = ({
    data = {}, onSelect, selected, itemMarker = null, checkboxPosition = 'right',
}) => {

    const [_, setEntry] = React.useState();
    return (
        <View style={styles.listWrapper} accessibilityLabel="list">
            {narrowData(data).map((entry) => {

                const isSelected =
                    entry.selected !== undefined ? entry.selected : selected.some(element => element.id === entry.id);
                if (entry.selected === undefined) {

                    entry.selected = isSelected;

                }
                const checkbox = (
                    <View style={[checkBoxStyles.iconWrapper, selected && checkBoxStyles.iconWrapperActive]}>
                        {isSelected && <Icon name="check" size={14} color="#5C85DD" />}
                    </View>
                );
                const onPress = () => {

                    entry.selected = !entry.selected;
                    setEntry(!_);
                    onSelect(entry, isSelected);

                };
                return (
                    <TouchableOpacity key={entry.id} style={styles.item} onPress={onPress}>
                        <View
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingHorizontal: 10,
                            }}>
                            {checkboxPosition === 'left' && checkbox}
                            {itemMarker && itemMarker(entry)}
                            <Text
                                style={[
                                    styles.itemMultilineTxt,
                                    isSelected ? styles.itemMultiselectSelectionTxt : styles.itemTxt,
                                ]}>
                                {entry.name}
                            </Text>
                            {checkboxPosition === 'right' && checkbox}
                        </View>
                    </TouchableOpacity>
                );

            })}
        </View>
    );

};

export const Checkbox = ({
    onPress,
    selected,
    titleId,
    title,
    badge,
    ns = 'checkbox',
    numberOfLines = 2,
    style: stl,
    textStyle,
    children,
}) => (
    <TouchableOpacity
        onPress={onPress}
        style={checkBoxStyles.container}
        accessibilityLabel={ns}
        onStartShouldSetResponderCapture={() => true}>
        <View style={[checkBoxStyles.iconWrapper, selected && checkBoxStyles.iconWrapperActive, stl]}>
            {selected && <Icon name="check" size={14} color="#5C85DD" />}
        </View>
        <View style={[checkBoxStyles.textWrapper, textStyle]}>
            {children}
            {children ? null : (
                <>
                    {badge}
                    <Title id={titleId} numberOfLines={numberOfLines} style={checkBoxStyles.text}>
                        {titleId ? '' : title}
                    </Title>
                </>
            )}
        </View>
    </TouchableOpacity>
);

export const SimpleCheckbox = ({
    onPress, selected, ns = 'checkbox', containerStyle, light,
}) => (
    <TouchableOpacity
        onPress={onPress}
        style={[
            {
                flexDirection: 'row',
                alignItems: 'center',
            },
            containerStyle,
        ]}
        accessibilityLabel={ns}
        onStartShouldSetResponderCapture={() => true}>
        <View
            style={[
                {
                    width: 16,
                    height: 16,
                    borderWidth: 1,
                    borderRadius: 2,
                    borderColor: !light && !selected ? '#999999' : '#5C85DD',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                },
                selected && !light && { backgroundColor: '#5C85DD' },
            ]}>
            {!selected ? null : <Icon name="check" size={14} color={selected && !light ? 'white' : '#5C85DD'} />}
        </View>
    </TouchableOpacity>
);

export const TabSelector = ({
    items = [],
    onItem,
    selected,
    outerStyles,
    item = typeof items === 'string' ? getEnums(items) : items,
    itemStyle,
    withCount = false,
    getItemCount = () => 0,
}) => {

    return (
        <View style={[tabStyles.tab_container, outerStyles]}>
            {item.map(e => (
                <TouchableOpacity
                    accessibilityLabel={`tab:${e.id}`}
                    key={e.id}
                    style={[
                        tabStyles.tab,
                        {
                            paddingTop: 16,
                            paddingBottom: 12,
                        },
                        e.id === selected ? tabStyles.tab_active : {},
                        e?.flex ? { flex: e.flex } : {},
                    ]}
                    onPress={() => (e.id !== selected ? onItem(e) : null)}>
                    <WithBadge notification={e.showIndicator} badge={e.badge}>
                        <View
                            style={[
                                {
                                    flexDirection: 'row',
                                    paddingHorizontal: 0,
                                    paddingVertical: 0,
                                },
                                itemStyle,
                            ]}>
                            <Subtitle
                                bold
                                style={{
                                    color:
                                        e.id?.trim?.() === selected?.trim?.() && !e.disabled
                                            ? activeTitleColor
                                            : inactiveTitleColor,
                                }}

                            >
                                {Object.R(e.name)}{withCount ? ` (${getItemCount?.(e) ?? 0})` : ''}
                            </Subtitle>
                            {e.child}
                        </View>
                    </WithBadge>
                </TouchableOpacity>
            ))}
        </View>
    );

};

export const ClinicTypeFilter = ({
    favorites = false, program = false, selected = '', onSelect,
}) => {

    const items = [
        {
            id: '',
            name: Object.R('titles.any'),
            isActive: selected === '',
        },
    ];
    if (favorites) {

        items.push({
            id: 'byFavorite',
            name: Object.R('titles.fromFavorites'),
            isActive: selected === 'byFavorite',
        });

    }

    if (program) {

        items.push({
            id: 'byProgram',
            name: Object.R('title.by_program'),
            isActive: selected === 'byProgram',
        });

    }
    return items.length > 1 ? <TabSwitch data={items} onChange={onSelect} /> : null;

};

export const ClinicSelector = ({ onSelect, title, filterFunction }) => {

    const clinics = useSelector(getMedcentersListWithPrograms);
    const [withCoverage, setWithCoverage] = useState(false);
    const [favorites, setFavorites] = useState(false);
    const [filterType, setFilterType] = useState('');
    const [filter, setFilter] = useState('');
    const [items, setItems] = useState([]);
    const currentProgram = useSelector(getCurrentProgram);

    useEffect(() => {

        const hasCoverage = !!(clinics ?? []).filter(
            item => item?.coverage.length && currentProgram && item?.coverage.find(c => c?.id === currentProgram?.id),
        ).length;
        const hasFavorites = !!(clinics ?? []).filter(item => item.isFavorite).length;
        setWithCoverage(hasCoverage);
        setFavorites(hasFavorites);
        const tempItems = clinics
            .filter((item) => {

                let userFilterResult;
                if (filterFunction) {

                    userFilterResult = filterFunction(item);
                    if (userFilterResult === false) {

                        return false;

                    }

                }
                if (filterType === 'byFavorite') {

                    return item.isFavorite;

                }
                if (filterType === 'byProgram') {

                    return !!item.coverage.find(cover => cover.id === currentProgram?.id);

                }
                return true;

            })
            .sort((a, b) => a?.name.localeCompare(b?.name));
        setItems(tempItems);

    }, [clinics, currentProgram, filterType]);

    const keyboardHeight = useKeyboardHeight(['ios']);

    return (

        <Modal2
            noScroll
            title={title}
            containerStyles={[
                style.modalContainer,
                window.isDesktop ? { ...getMarginHorizontalObject() } : { ...getMarginHorizontalObject() },
                { marginBottom: keyboardHeight + 8 },
            ]}>
            <View style={style.medcentersFilter}>
                <ClinicTypeFilter
                    onSelect={id => setFilterType(id)}
                    selected={filterType}
                    favorites={favorites}
                    program={withCoverage && currentProgram}
                />
                <SearchBar autoCorrect={false} onSearch={setFilter} initialValue={filter} />
                <FlatList
                    data={
                        filter
                            ? items.filter(item => (item?.name ?? '').toLowerCase().includes(filter.toLowerCase()))
                            : items
                    }
                    renderItem={item => (
                        <TouchableOpacity
                            onPress={() => {

                                onSelect && onSelect(item?.item);
                                Page.closeModal();

                            }}>
                            <View
                                style={{
                                    flexDirection: 'row',
                                    flexWrap: 'nowrap',
                                    borderBottomWidth: 1,
                                    borderColor: COLORS.BORDER_GRAY,
                                    paddingHorizontal: 6,
                                    paddingVertical: 12,
                                }}>
                                <View style={{ flex: 1 }}>
                                    <TextHighliter
                                        style={{ color: COLORS.STANDARD_GRAY }}
                                        keyword={filter}
                                        value={item?.item?.name}
                                        numberOfLines={2}
                                    />
                                </View>
                                <View
                                    style={{
                                        width: 50,
                                        justifyContent: 'center',
                                    }}>
                                    {currentProgram &&
                                    item?.item?.coverage.find(i => i.id === currentProgram?.id) &&
                                    item?.item?.coverage?.length ? (
                                        <CoverableBy coverer={item?.item?.coverage[0].coverer} />
                                        ) : null}
                                </View>
                            </View>
                        </TouchableOpacity>
                    )}
                    style={{
                        flex: 1,
                        padding: 10,
                        borderBottomWidth: 1,
                        borderBottomColor: COLORS.BORDER_GRAY,
                    }}
                />
            </View>
        </Modal2>
    );

};
