import {
    Avatar, dataFrom, LetterAvatar, React,
} from '../common';
import { getUserInfo } from '../selectors';

const lettersOf = name => (!name ? '?' : name.split(' ')
    .slice(1)
    .map(s => ((s && s[0]) || ''))
    .join(''));

/**
 * InitialsAvatar.
 */
export const InitialsAvatar = dataFrom(
    getUserInfo,
    ({
        data: { photoUrl },
        name,
        isMe = false,
        ...props
    }) => (isMe
        ? <Avatar of="users" imageUrl={photoUrl} {...props} />
        : <LetterAvatar letters={lettersOf(name)} {...props} />),
);

