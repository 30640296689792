import { Icon, React } from '../../../common';
import { useSelector } from 'react-redux';
import {
    getAllFeedbacksMyHasTextFilter, getAllFeedbacksMyHideNeutralFilter, getAllFeedbacksMyHideNotRecommendFilter, getAllFeedbacksMyHideRecommendFilter, getAllFeedbacksMySpecialityPreFilter,
} from '../../../selectors';
import { navigate } from '../../../actions';

export const AllFeedbacksFilterIcon = ({ style }) => {

    const hasText = useSelector(getAllFeedbacksMyHasTextFilter);
    const hideNotRecommend = useSelector(getAllFeedbacksMyHideNotRecommendFilter);
    const hideRecommend = useSelector(getAllFeedbacksMyHideRecommendFilter);
    const hideNeutral = useSelector(getAllFeedbacksMyHideNeutralFilter);
    const selected = useSelector(getAllFeedbacksMySpecialityPreFilter);

    let data = 0;
    if (hasText) {

        data++;

    }
    if (hideNotRecommend) {

        data++;

    }
    if (hideRecommend) {

        data++;

    }
    if (hideNeutral) {

        data++;

    }
    if (selected?.length) {

        data++;

    }

    const clickHandler = () => {

        navigate({ name: 'AllFeedbackFilterScreen' });

    };

    return (
        <Icon.Filter
            style={[style]}
            badge={data}
            onPress={clickHandler}
        />
    );

};
