/* eslint-disable no-underscore-dangle */
import { KeyboardAvoidingView, Platform, TextInput } from 'react-native';
import {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeviceInfo from 'react-native-device-info';
import { getMarginHorizontalObject, getWindowHeight, modal as stylesModal } from '../../../styles/common';
import {
    Dimensions, React, ScrollView, Switch, Text, TouchableOpacity, TouchableWithoutFeedback, View,
} from '../../../common/react';
import {
    Button,
    ButtonsGroup,
    CoverableBy,
    Field,
    Icon,
    Link,
    Modal2,
    Page,
    SearchBar,
    selectOptions,
    Selector,
    SingleNavigation,
    Stub,
    Subtitle,
    TextHighliter,
    Title,
    TopNotification,
} from '../../../common';
import { COLORS } from '../../../styles/colors';
import { useKeyboardHeight } from '../../../common/Modal';
import { narrowData } from '../../../common/Selector';
import { selector as styles } from '../../../common/style';
import { getFormData } from '../../../selectors/forms';
import { PhoneInput, validateByFieldRequired, validateByFieldType } from '../../../common/fields';
import { isAdult, toDate } from '../../../utils';
import { DateTimeSelector } from '../../../combo/DateSelector';
import { input } from '../../../styles/input';
import { getUserInfo } from '../../../selectors';
import { isMobile, prepareNameForSave } from '../../../utils/strings';
import api from '../../../services/api';
import { setFormsData } from '../../../actions/forms';
import { gender, toDateTZ, toMySQLString } from '../../../utils/dates';
import { getSupportBodyAsync } from '../../../utils/supportInfo';
import { openURL } from '../../../services/device';
import { isPhoneNumberValid } from '../../../utils/phone';
import { saveRelativeToStorage } from '../../../services/user.utils';
import { COUNTRY_CODE, MODE } from '../../../config';
import { ChildAgreement } from '../../../common/Agreemants';

const CODE_TIMER = 60000;

export const SelectorWithForm = ({
    data = {},
    onSelect,
    selected,
    radio,
    keyword,
    style: stl,
    noLastLine,
    program,
    noTextLim,
    itemStyle,
    itemTextStyle,
    firstLine,
    formForId = '',
    onClose,
    getName,
}) => {

    // eslint-disable-next-line no-unused-vars
    const [_, setEntry] = React.useState();
    const actualData = narrowData(data);
    const formSubmitHandler = (entry, formValues) => {

        // console.log('Form values', formValues);
        onSelect(entry, true, formValues);

    };
    return (
        <View
            style={{
                ...styles.listWrapper,
                flex: 1,
                marginHorizontal: 0,
                paddingHorizontal: 0,
                borderTopWidth: 0,
            }}
            accessibilityLabel="list">
            {actualData.map(
                // eslint-disable-next-line complexity
                (
                    entry,
                    idx,
                    {
                        isSelected = entry.selected ||
                            (entry?.id ? selected === entry.id : selected === entry?.fullNameRu),
                    },
                ) => {

                    const { FormComponent } = entry;
                    const onPress = () => {

                        if (entry.disabled) {

                            return;

                        }
                        setEntry(entry);
                        onSelect(entry, isSelected);

                    };
                    const isLast = idx + 1 === actualData?.length;
                    const coverage = entry?.programCoverage && program && entry?.programCoverage[program?.id];
                    const coverer = coverage?.coverage && Number(coverage?.coverage) > 0 ? program.coverer : null;
                    const additionalProps = noTextLim ? { numberOfLines: 0 } : {};
                    return radio ? (
                        <React.Fragment key={idx}>
                            <TouchableOpacity
                                key={entry.id}
                                onPress={onPress}
                                style={[
                                    styles.listItemWrapper,
                                    stl,
                                    !!(isLast && noLastLine) && { borderBottomWidth: 0 },
                                ]}>
                                <View style={[styles.radioWrapper, isSelected ? styles.radioWrapperActive : null]}>
                                    {isSelected ? <View style={styles.circleActive} /> : null}
                                </View>
                                {keyword ? (
                                    <View
                                        style={{
                                            flexDirection: 'column',
                                            flex: 1,
                                        }}>
                                        <TextHighliter
                                            value={`${entry.name}`}
                                            keyword={`${keyword}`}
                                            numberOfLines={0}
                                        />
                                    </View>
                                ) : (
                                    <View
                                        style={{
                                            flexDirection: 'column',
                                            flex: 1,
                                        }}>
                                        <Title
                                            style={[
                                                styles.listItemText,
                                                entry.disabled ? styles.listItemDisabled : null,
                                            ]}
                                            {...additionalProps}>{`${
                                            typeof getName === 'function' ? getName(entry) : entry.name
                                        }`}</Title>
                                        {entry?.subtitle ? (
                                            <Subtitle
                                                style={[
                                                    styles.listItemTextSmall,
                                                    entry.disabled ? styles.listItemDisabled : null,
                                                ]}>
                                                {entry?.subtitle}
                                            </Subtitle>
                                        ) : (
                                            <></>
                                        )}
                                    </View>
                                )}
                                {coverer ? (
                                    <CoverableBy.Dot
                                        coverer={coverer}
                                        style={{ marginLeft: 12 }}
                                        isLimited={coverage?.attributes}
                                    />
                                ) : null}
                            </TouchableOpacity>
                            {formForId && formForId === entry?.id && FormComponent ? (
                                <FormComponent
                                    onSubmit={formValues => formSubmitHandler(entry, formValues)}
                                    onCancel={() => {

                                        onClose?.();

                                    }}
                                />
                            ) : null}
                        </React.Fragment>
                    ) : (
                        <TouchableOpacity
                            key={entry.id}
                            style={[
                                styles.item,
                                isLast && noLastLine && { borderBottomWidth: 0 },
                                stl,
                                idx === 0 && firstLine && { borderTopWidth: 1 },
                            ]}
                            onPress={onPress}>
                            <View
                                style={[
                                    {
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    },
                                    itemStyle,
                                ]}>
                                <Text style={[isSelected ? styles.itemSelectedTxt : styles.itemTxt, itemTextStyle]}>
                                    {entry.name}
                                </Text>
                                {isSelected && <Icon name="check" color="#5EB3E3" />}
                            </View>
                        </TouchableOpacity>
                    );

                },
            )}
        </View>
    );

};

export const useWindowHeight = () => {

    const [height, setHeight] = useState(0);

    const sizeHandler = () => {

        const size = Platform.OS === 'web' ? getWindowHeight() : Dimensions.get('window').height;
        setHeight(size);

    };

    useEffect(() => {

        if (Platform.OS === 'web') {


            window.addEventListener('resize', sizeHandler);
            sizeHandler();

        }
        return () => {

            if (Platform.OS === 'web') {


                window.removeEventListener('resize', sizeHandler);

            }

        };

    }, []);

    useEffect(() => {

        sizeHandler();

    }, [Dimensions]);
    return height;

};

// eslint-disable-next-line complexity
export const ModalSelectorWithForm = (props) => {

    const [value, setValue] = useState('');
    const [showFormForId, setShowFormForId] = useState('');
    const [selected, setSelected] = useState(props.selected);
    const windowHeight = useWindowHeight();
    const platforms = ['ios', 'android'];
    if (Platform.OS === 'web' && isMobile()) {

        platforms.push('web');

    }
    const keyboardHeight = useKeyboardHeight(platforms);
    const [hideClose, setHideClose] = useState(!!props?.hideClose);

    const filterByValue = useCallback(
        (d, val) => {

            try {

                const words = (val ?? '').toLowerCase().trim().split(' ');
                if (words.length) {

                    return d.filter(({ name }) => words.every(w => name.toLowerCase().includes(w)));

                }
                return d;

            } catch (e) {

                return d;

            }

        },
        [props.data, value],
    );

    const data = filterByValue(props.data, value);
    const doSelectItem = (item, isSelected, values) => {

        props.onSelect(item, isSelected, values);
        Page.closeModal();

    };

    const diff = 30;

    const keyDiffSize = Platform.OS === 'web' && isMobile() ? diff : diff;
    // const MAX_FORM_HEIGHT = 2000;

    const notchSize = DeviceInfo?.hasNotch?.() ? 50 : 20;

    const getHeight = useCallback(() => {

        return (
            // eslint-disable-next-line no-nested-ternary
            (Platform.OS === 'web'
                ? isMobile()
                    ? windowHeight - keyDiffSize
                    : windowHeight
                : windowHeight - keyboardHeight - notchSize) - keyDiffSize
        );

    }, [keyboardHeight, windowHeight, notchSize]);

    const getContainerStyles = useCallback(() => {

        const containerStyles = [];
        if (DeviceInfo?.hasNotch?.()) {

            containerStyles.push({ top: notchSize });

        }
        if (props.isWithSearch) {

            containerStyles.push(stylesModal.citySelectorContainer);
            containerStyles.push({
                ...getMarginHorizontalObject(),
                maxHeight: getHeight(),
            });

        }

        if (props.autoHeight) {

            containerStyles.push({
                maxHeight: getHeight(),
                height: getHeight(),
            });
            if (Platform.OS === 'ios' && keyboardHeight) {

                containerStyles.push({ bottom: keyboardHeight });

            }

        }
        return containerStyles.reduce((prev, curr) => ({
            ...prev,
            ...curr,
        }), {});

    }, [props, stylesModal, Dimensions, keyboardHeight, getWindowHeight, selected, showFormForId, getHeight]);

    return (
        <Modal2
            title={props.title}
            onSubmit={props.onSubmit}
            subtitle={props.subtitle}
            subtitleStyle={props.subtitleStyle}
            hint={props.hint}
            hideClose={hideClose}
            noScroll={props.isWithSearch}
            _onClose={props.onClose}
            containerStyle={selected?.id === showFormForId || selected === showFormForId ? getContainerStyles() : {}}>
            <KeyboardAvoidingView
                behavior={Platform.OS === 'android' ? 'height' : 'height'}
                // behavior={Platform.OS === 'android' ? 'padding' : 'height'}
                contentContainerStyle={{
                    minHeight: 'auto',
                    height: 'auto',
                }}
                keyboardVerticalOffset={Platform.OS === 'android' ? 70 : 0}>
                {props.isWithSearch ? <SearchBar autoCorrect={false} onSearch={setValue} initialValue={value} /> : null}
                <ScrollView
                    enableOnAndroid
                    enableAutomaticScroll
                    keyboardDismissMode="on-drag"
                    keyboardShouldPersistTaps="handled"
                    style={[
                        { ...(props.isWithSearch ? stylesModal.citySelectorList : {}) },
                        { marginTop: 0 },
                        selected?.id === showFormForId || selected === showFormForId
                            ? {
                                height: 'auto',
                                minHeight: 'auto',
                            }
                            : { height: 'auto' },
                        // selected?.id === showFormForId || selected === showFormForId ? { height: MAX_FORM_HEIGHT } : {},
                    ]}>
                    <View
                        style={{
                            borderBottomWidth: props?.noLastLine ? 1 : 0,
                            flex: 1,
                            borderBottomColor: COLORS.LIGHT_GRAY,
                        }}>
                        <SelectorWithForm
                            radio
                            {...props}
                            keyword={value}
                            data={data}
                            onSelect={(item, isSelected, values) => {

                                setSelected(item);
                                if (!item?.FormComponent || values) {

                                    if (values) {


                                        item.name = values.firstName;

                                    }
                                    doSelectItem(item, isSelected, values);

                                } else if (item.id !== showFormForId) {

                                    setShowFormForId(item.id);
                                    setHideClose(true);

                                }

                            }}
                            noLastLine={props?.noLastLine}
                            formForId={showFormForId}
                            selected={showFormForId || selected || selected?.id}
                            // selected={showFormForId || selected || selected?.id || data?.[0]?.id}
                            onClose={props?.onClose}
                            getName={props?.getName}
                        />
                        {Platform.OS === 'ios' && (
                            <View
                                style={{
                                    height: keyboardHeight / 6,
                                    borderWidth: 0,
                                    borderColor: 'blue',
                                }} />
                        )}
                        {props.isWithSearch && value && !(data && data.length) ? <Stub type="empty-default" /> : null}
                    </View>
                </ScrollView>
            </KeyboardAvoidingView>
        </Modal2>
    );

};

/**
 * Select options modal.
 *
 * @param props
 */
export function selectOptionsWithForm(props) {

    Page.showModal(<ModalSelectorWithForm {...props} />);

}

export const getPhoneIfValid = (phone) => {

    const phoneValid = isPhoneNumberValid(phone);
    if (phoneValid) {

        return phone;

    }
    return '';

};

// eslint-disable-next-line max-statements,complexity
export const NewVisitorInlineForm = ({
    formName = 'newVisitor',
    onSubmit = () => {},
    onCancel = () => {},
    onClose = () => {},
    saveState = false,
}) => {

    const form = useSelector(getFormData);
    const user = useSelector(getUserInfo);
    const [firstName, setFirstName] = useState(saveState ? form?.[formName]?.firstName ?? '' : '');
    const [lastName, setLastName] = useState(saveState ? form?.[formName]?.lastName ?? '' : '');
    const [middleName, setMiddleName] = useState(saveState ? form?.[formName]?.middleName ?? '' : '');
    const [birthDay, setBirthday] = useState(toDate(saveState ? form?.[formName]?.birthDay ?? '' : ''));
    const [phone, setPhone] = useState((saveState && form?.[formName]?.phone) ?? user?.phones?.[0]?.number ?? '');
    const [formError, setFormError] = useState({});
    const [saveData, setSaveData] = useState(false);
    const [myPhone, setMyPhone] = useState(false);
    const [selectedRelativeType, setSelectedRelativeType] = useState('');
    const [codeRequested, setCodeRequested] = useState(false);
    const [allowRequestCode, setAllowRequestCode] = useState(true);
    const [sex, setSex] = useState('');
    const [objectId, setObjectId] = useState('');
    const [code, setCode] = useState('');
    const codeTimer = useRef(0);
    const [countDown, setCountDown] = useState(0);
    const [isAddStarted, setIsAddStarted] = useState(false);
    const dispatch = useDispatch();
    const manualVisitor = useSelector(getFormData);
    const [relVisible, setRelVisible] = useState(false);
    const [warningModalVisible, setWarningModalVisible] = useState(false);
    const [warningModalTitle, setWarningModalTitle] = useState('titles.error');
    const [warningModalText, setWarningModalText] = useState('');
    const [alreadySend, setAlreadySend] = useState(false);
    const [childAgree, setChildAgree] = useState(false);

    const [errorText, setErrorText] = useState('');
    const relativeType = [
        {
            id: 'CHILD',
            name: Object.R('titles.childRelative'),
            max: 5,
            error: 'titles.notAllowedAnotherChild',
        },
        {
            id: 'SPOUSE',
            name: Object.R('relKind.SPOUSE'),
            max: 1,
            error: 'titles.notAllowedAnotherFamily',
        },
        {
            id: 'PARENT',
            name: Object.R('relKind.PARENT'),
            max: 4,
            error: 'titles.notAllowedAnotherParent',
        },
        {
            id: 'OTHER',
            name: Object.R('titles.otherRelative'),
            max: 1000,
            error: 'titles.notAllowedAnotherRelative',
        },
    ];
    const inputStyle = [
        {
            borderWidth: 1,
            borderRadius: 3,
            borderColor: COLORS.BORDER_GRAY,
            height: 40,
            padding: 4,
            paddingHorizontal: 8,
            backgroundColor: COLORS.WHITE,
            color: COLORS.STANDARD_GRAY,
            fontSize: 14,
        },
        Platform.OS === 'web' ? { 'outline-style': 'none' } : {},
    ];

    const getFamilyCount = useCallback(
        (member) => {

            const userFamily = user?.relatives ?? [];
            return userFamily?.filter(({ relKind }) => relKind === member ?? selectedRelativeType)?.length ?? 0;

        },
        [user, selectedRelativeType],
    );

    const isMaxFamilyMembers = useCallback(() => {

        const count = getFamilyCount(selectedRelativeType);
        const max = relativeType?.find?.(({ id }) => id === selectedRelativeType);
        return count >= max?.max ?? 1000 ? max?.error ?? '' : '';

    }, [selectedRelativeType, relativeType, getFamilyCount]);

    const getErrorText = (e) => {

        const title = e?.body?.title ?? '';
        const details = e?.body?.detail ?? '';
        return details || title;

    };

    const isAdultLocal = (bd) => {

        if (!bd) {

            return false;

        }
        const dateString = new Date(bd)?.toISOString?.();
        return isAdult(dateString);

    };

    const isPersonAdult = useCallback(() => {

        return isAdultLocal(birthDay);

    }, [birthDay]);

    const submitHandler = () => {

        const max = isMaxFamilyMembers();
        if (max && saveData) {

            setWarningModalText(Object.R(max));
            setWarningModalVisible(true);
            return;

        }
        const bd = toDateTZ(birthDay);
        const birthday = bd?.toISOString?.()?.substring?.(0, 10);
        let data = {
            firstName: prepareNameForSave(firstName),
            middleName: prepareNameForSave(middleName),
            lastName: prepareNameForSave(lastName),
            birthDay: bd,
            phone: getPhoneIfValid(phone),
        };
        const fullNameRu = `${prepareNameForSave(lastName)} ${prepareNameForSave(firstName)} ${prepareNameForSave(
            middleName,
        )}`;
        if (saveData) {

            if (selectedRelativeType === 'CHILD') {

                setIsAddStarted(true);
                api.addChild({
                    fullNameRu,
                    sex,
                    birthday,
                    consentReceived: true,
                })
                    .then((child) => {

                        const newChild = child?.family?.find(
                            ({ relKind, profile }) => relKind === selectedRelativeType &&
                                profile?.fullNameRu === fullNameRu &&
                                profile?.birthday === birthday,
                        );
                        if (newChild) {

                            data._id = newChild?.profileId;
                            data.id = newChild?.profileId;
                            data.fullName = fullNameRu;
                            data.fullNameRu = fullNameRu;
                            data.name = fullNameRu;
                            data.subName = fullNameRu;
                            data.sex = sex;
                            data.birthday = toMySQLString(birthDay);
                            saveRelativeToStorage({
                                name: fullNameRu,
                                birthday,
                                profileId: newChild?.profileId,
                                sex,
                                hashKey: user?.hashKey,
                                person: user,
                            });
                            onSubmit?.(data);

                        }

                    })
                    .catch((err) => {

                        setErrorText(getErrorText(err));

                    })
                    .finally(() => {

                        setIsAddStarted(false);

                    });

            } else if (objectId && code) {

                setIsAddStarted(true);
                api.confirmRelativeSmsCode(objectId, code)
                    .then(async (d) => {

                        data = {
                            ...data,
                            ...d,
                            subName: d?.fullNameRu ?? fullNameRu,
                            name: d?.fullNameRu ?? fullNameRu,
                            id: d?._id,
                        };
                        saveRelativeToStorage({
                            name: fullNameRu,
                            birthday,
                            profileId: d?._id,
                            sex,
                            hashKey: user?.hashKey,
                            person: user,
                        });
                        onSubmit?.(data);

                    })
                    .catch((e) => {

                        setErrorText(getErrorText(e));

                    })
                    .finally(() => {

                        setIsAddStarted(false);

                    });

            }

        } else {

            const visitor = {
                firstName: prepareNameForSave(firstName),
                middleName: prepareNameForSave(middleName),
                lastName: prepareNameForSave(lastName),
                birthDay,
                phone: selectedRelativeType === 'CHILD' ? undefined : getPhoneIfValid(phone),
                sex,
                fullNameRu,
                birthday,
                name: fullNameRu,
                subName: fullNameRu,
                manual: true,
            };
            const manualVisitorArray = Array.isArray(manualVisitor?.ManualVisitor) ? manualVisitor?.ManualVisitor : [];
            const jsonArray = [...manualVisitorArray, visitor].map(i => JSON.stringify(i));
            const visitors = Array.from(new Set(jsonArray)).map(i => JSON.parse(i));
            dispatch(setFormsData('ManualVisitor', visitors));
            onSubmit?.({ ...visitor });

        }

    };

    const errorHandler = (title, error) => {

        setFormError((prev) => {

            const e = { ...prev };
            if (!error) {

                delete e[title];

            } else {

                e[title] = error;

            }
            return e;

        });

    };

    const hasErrors = useCallback(() => {

        const errors = Object.keys(formError)?.filter(item => (item === 'titles.phoneForConnection' ? isPersonAdult() : true),);
        if (!errors?.length) {

            // console.log('No errors');
            return false;

        }
        if (sex) {

            return true;

        }
        // console.log('Errors', errors);
        return !(errors?.length === 1 && !isPersonAdult() && errors[0] === 'phone');

    }, [formError, isPersonAdult]);

    const cancelHandler = () => {

        onCancel?.();
        onClose?.();
        Page?.closeModal();

    };

    const sentCodeHandler = () => {

        setErrorText('');
        const fullNameRu = `${prepareNameForSave(lastName)} ${prepareNameForSave(firstName)} ${prepareNameForSave(
            middleName,
        )}`;
        api.addRelative({
            relKind: selectedRelativeType,
            birthday: toDateTZ(birthDay)?.toISOString?.()?.substring(0, 10) ?? toMySQLString(birthDay),
            sex,
            fullNameRu,
            phones: [phone],
        })
            .then(() => {})
            .catch((e) => {

                if (e.code === 'NEEDS_CONFIRMATION') {

                    const objId = e?.body?.attributes?.objectId;
                    if (objId) {

                        setObjectId(objId);
                        api.sendRelativeSms(objId)
                            .then(() => {})
                            .catch((err) => {

                                setErrorText(getErrorText(err));

                            });

                    } else {

                        setErrorText(getErrorText(e));

                    }

                } else {

                    setErrorText(getErrorText(e));

                }

            });

    };

    const drawCountdown = () => {

        if (codeTimer.current && Date.now() < codeTimer.current) {

            const cd = Math.floor((codeTimer.current - Date.now()) / 1000);
            setCountDown(cd);
            if (cd <= 0) {

                setAllowRequestCode(true);
                return;

            }


            requestAnimationFrame(drawCountdown);
            return;

        }
        setAllowRequestCode(true);

    };

    const canAdd = useMemo(() => {

        if (saveData) {

            if (selectedRelativeType === 'CHILD' && !isPersonAdult()) {

                return !(!sex || hasErrors());

            }
            return !(!sex || hasErrors() || !objectId || !code);

        }
        return !(!sex || hasErrors());

    }, [sex, hasErrors, saveData, selectedRelativeType, objectId, code]);

    // const changeMyPhoneHandler = (v) => {
    //     setMyPhone((prev) => {
    //         if (!prev && v) {
    //             setPhone(user?.phones?.[0]?.number ?? '');
    //         } else {
    //             setPhone('');
    //         }
    //         return v;
    //     });
    // };

    const birthdayChangeHandler = (d) => {

        if (!isAdultLocal(d) && !!d) {

            setSelectedRelativeType(relativeType[0].id);

        } else if (isAdultLocal(d) && selectedRelativeType === 'CHILD') {

            setSelectedRelativeType('');

        }
        setBirthday(d);

    };

    const sendCodeHandler = () => {

        setAlreadySend(true);
        const max = isMaxFamilyMembers();
        if (max) {

            setWarningModalText(Object.R(max));
            setWarningModalVisible(true);
            return;

        }
        sentCodeHandler();
        codeTimer.current = new Date().getTime() + CODE_TIMER;
        drawCountdown();
        setAllowRequestCode(false);
        setCodeRequested(true);

    };

    return (
        <TouchableWithoutFeedback>
            <View style={{ paddingHorizontal: 16 }}>
                <InputField
                    title={'titles.lastName'}
                    required
                    value={lastName}
                    onChange={setLastName}
                    inputStyle={inputStyle}
                    type={'text'}
                    onError={errorHandler}
                    autofocus={false}
                    placeholder={Object.R('addRelativeWithOther_lastName.placeholder')}
                    placeholderTextColor={COLORS.PLACEHOLDER_GRAY}
                    validateOnBlur
                />
                <InputField
                    title={'titles.firstName'}
                    required
                    value={firstName}
                    onChange={setFirstName}
                    inputStyle={inputStyle}
                    type={'text'}
                    onError={errorHandler}
                    autofocus={false}
                    placeholderTextColor={COLORS.PLACEHOLDER_GRAY}
                    placeholder={Object.R('addRelativeWithOther_firstName.placeholder')}
                    validateOnBlur
                />
                <InputField
                    title={'titles.middleName'}
                    required // ={MODE !== 'uat'}
                    value={middleName}
                    onChange={setMiddleName}
                    inputStyle={inputStyle}
                    type={'text'}
                    onError={errorHandler}
                    autofocus={false}
                    placeholder={Object.R('addRelativeWithOther_middleName.placeholder')}
                    placeholderTextColor={COLORS.PLACEHOLDER_GRAY}
                    validateOnBlur
                    titleId={'middleName'}
                />
                <InputField
                    title={'addRelative.sex'}
                    required
                    value={sex}
                    onChange={setSex}
                    inputStyle={inputStyle}
                    type={'sex'}
                />
                <InputField
                    title={'titles.birthday'}
                    required
                    value={birthDay}
                    onChange={birthdayChangeHandler}
                    type={'pastDateReverse'}
                    inputStyle={inputStyle}
                    inputStyleWeb={{ fontSize: 14 }}
                    onError={errorHandler}
                    mode={'date'}
                    edit
                    useSystemModal
                    autofocus={false}
                    validateOnBlur
                />
                {isPersonAdult() && (
                    <View>
                        <InputField
                            title={'titles.phoneForConnection'}
                            required={isPersonAdult()}
                            value={phone}
                            onChange={setPhone}
                            type={'phone'}
                            inputStyle={[inputStyle, myPhone ? { color: COLORS.DARK_GRAY } : {}]}
                            onError={errorHandler}
                            autofocus={false}
                            validateOnBlur
                            editable={!myPhone}
                        />
                    </View>
                )}
                <TopNotification level={'info'} hint={'titles.rememberPatientDataHint'} />
                <View
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginVertical: 12,
                    }}>
                    <Text style={[input.fieldCaption]}>{Object.R('titles.savePatientData')}</Text>
                    <Switch.Default
                        value={saveData}
                        onValueChange={(val) => {

                            setSaveData(val);
                            setErrorText('');

                        }}
                        style={{ alignSelf: 'flex-end' }}
                    />
                </View>
                {!!saveData && (
                    <>
                        <Field title={'titles.relativeType'} required capitalize>
                            <SingleNavigation
                                title={relativeType?.find?.(({ id }) => id === selectedRelativeType)?.name ?? ''}
                                placeholder={Object.R('addRelativeWithOther_relKind.placeholder')}
                                placeholderStyle={{
                                    color: COLORS.PLACEHOLDER_GRAY,
                                    fontSize: 14,
                                }}
                                textStyle={{
                                    color: COLORS.STANDARD_GRAY,
                                    fontSize: 14,
                                }}
                                onPress={() => {

                                    setErrorText('');
                                    if (Platform.OS === 'ios') {

                                        setRelVisible(true);

                                    } else {

                                        selectOptions({
                                            title: Object.R('titles.relativeType'),
                                            data:
                                                isPersonAdult() || !birthDay
                                                    ? relativeType.filter(({ id }) => id !== 'CHILD')
                                                    : [relativeType[0]],
                                            onSelect: ({ id }) => {

                                                setSelectedRelativeType(id);

                                            },
                                            selected: selectedRelativeType,
                                            useSystemModal: true,
                                        });

                                    }

                                }}
                            />
                            {!!relVisible && Platform.OS === 'ios' && (
                                <View>
                                    <Modal2 _onClose={() => setRelVisible(false)} title={'titles.relativeType'}>
                                        <Selector
                                            radio
                                            data={
                                                isPersonAdult() || !birthDay
                                                    ? relativeType.filter(({ id }) => id !== 'CHILD')
                                                    : [relativeType[0]]
                                            }
                                            onSelect={(item) => {

                                                setSelectedRelativeType(item.id);
                                                setRelVisible(false);
                                                setErrorText('');

                                            }}
                                            selected={selectedRelativeType}
                                        />
                                    </Modal2>
                                </View>
                            )}
                        </Field>
                        {(selectedRelativeType !== 'CHILD' || isPersonAdult()) && selectedRelativeType !== '' && (
                            <View>
                                <TopNotification
                                    hint={{
                                        level: 'info',
                                        message: 'titles.relativeRequestCodeHint',
                                    }}
                                />
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        flex: 1,
                                        margin: 0,
                                        padding: 0,
                                        borderWidth: 0,
                                    }}>
                                    <View
                                        style={{
                                            flex: 1,
                                            justifyContent: 'flex-end',
                                            paddingRight: 0,
                                            borderWidth: 0,
                                        }}>
                                        <Field title={'titles.inputCode'} required capitalize>
                                            <TextInput
                                                style={[inputStyle, { marginRight: 6 }]}
                                                editable={codeRequested}
                                                autofocus={false}
                                                value={code}
                                                onChangeText={setCode}
                                            />
                                        </Field>
                                    </View>
                                    <View
                                        style={{
                                            flex: 1,
                                            justifyContent: 'flex-end',
                                            marginBottom: 8,
                                            marginLeft: 6,
                                            borderWidth: 0,
                                        }}>
                                        {alreadySend ? (
                                            <View style={{ alignItems: 'center' }}>
                                                {countDown ? (
                                                    <View
                                                        style={{
                                                            borderWidth: 3,
                                                            borderColor: '#B6BEDA',
                                                            width: 40,
                                                            height: 40,
                                                            borderRadius: 40 / 2,
                                                        }}>
                                                        <View
                                                            style={{
                                                                flex: 1,
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}>
                                                            <Text
                                                                style={{
                                                                    color: '#B6BEDA',
                                                                    fontFamily: 'SourceSansPro-Semibold',
                                                                }}>
                                                                {countDown}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                ) : (
                                                    <Link
                                                        disabled={!allowRequestCode || hasErrors()}
                                                        href={() => {

                                                            sendCodeHandler();

                                                        }}
                                                        style={{
                                                            alignSelf: 'center',
                                                            marginBottom: 8,
                                                            fontSize: 14,
                                                        }}>
                                                        {countDown
                                                            ? Object.R('titles.sendAgainTime', { countDown })
                                                            : Object.R('titles.sendAgainAdHoc')}
                                                    </Link>
                                                )}
                                            </View>
                                        ) : (
                                            <Button
                                                primary
                                                action={() => {

                                                    sendCodeHandler();

                                                }}
                                                title={`${Object.R('titles.requestCode')}${
                                                    countDown ? ` (${countDown}) с.` : ''
                                                }`}
                                                style={{ marginVertical: 12 }}
                                                disabled={!allowRequestCode || hasErrors()}
                                            />
                                        )}
                                    </View>
                                </View>
                            </View>
                        )}
                    </>
                )}
                {!!errorText && (
                    <TopNotification
                        hint={{
                            level: 'error',
                            message: errorText,
                        }}
                    />
                )}
                {!!warningModalVisible && (
                    <Modal2 _onClose={() => setWarningModalVisible(false)} hideClose>
                        <View style={{ padding: 12 }}>
                            <Title
                                style={{
                                    fontSize: 16,
                                    fontWeight: '700',
                                    padding: 12,
                                    textAlign: 'center',
                                }}>
                                {Object.R(warningModalTitle)}
                            </Title>
                            <Subtitle
                                style={{
                                    fontSize: 12,
                                    fontWeight: '400',
                                    padding: 12,
                                }}
                                numberOfLines={0}>
                                {Object.R(warningModalText)}
                            </Subtitle>
                            <ButtonsGroup>
                                <Button
                                    transparent
                                    title={'buttons.later'}
                                    action={() => setWarningModalVisible(false)}
                                />
                                <Button
                                    primary
                                    title={'buttons.write'}
                                    action={async () => {

                                        const body = await getSupportBodyAsync();
                                        openURL(`mailto:${Object.R('supportEmail')}${body}`);
                                        setWarningModalVisible(false);

                                    }}
                                />
                            </ButtonsGroup>
                        </View>
                    </Modal2>
                )}
                <ChildAgreement
                    onChange={(v) => {

                        setChildAgree(v);

                    }}
                    // birthday={birthDay}
                    // name={firstName}
                    visible={selectedRelativeType === 'CHILD' && !isPersonAdult() && !!saveData}
                />
                <ButtonsGroup
                    style={{
                        marginHorizontal: 0,
                        paddingHorizontal: 0,
                        marginBottom: 16,
                    }}>
                    <Button normal action={cancelHandler} title={'titles.cancelAdHoc'} styles={{ flex: 1 }} />
                    <Button
                        primary
                        action={submitHandler}
                        title={'titles.addAdHoc'}
                        disabled={isAddStarted || !canAdd || !childAgree}
                        styles={{ flex: 1 }}
                    />
                </ButtonsGroup>
            </View>
        </TouchableWithoutFeedback>
    );

};

const maleText = Object.R('sex.MALE');
const femaleText = Object.R('sex.FEMALE');
const OtherText = Object.R('sex.OTHER');

export const Gender = ({ onChange, value }) => {

    const [v, setV] = useState(value);
    const [vis, setVis] = useState(false);
    useEffect(() => {

        setV(value);

    }, [value]);
    const data =
        MODE === 'uat'
            ? [
                {
                    id: 'FEMALE',
                    name: gender.female,
                },
                {
                    id: 'MALE',
                    name: gender.male,
                },
                {
                    id: 'OTHER',
                    name: gender.other,
                },
            ]
            : [
                {
                    id: 'FEMALE',
                    name: gender.female,
                },
                {
                    id: 'MALE',
                    name: gender.male,
                },
            ];

    const getById = useCallback(id => data?.find?.(({ id: _id }) => _id === id), [data]);

    return (
        <>
            <SingleNavigation
                title={getById(v)?.name ?? ''}
                placeholder={Object.R('addRelative_sex.placeholder')}
                placeholderStyle={{
                    color: COLORS.PLACEHOLDER_GRAY,
                    fontSize: 14,
                }}
                textStyle={{
                    color: COLORS.STANDARD_GRAY,
                    fontSize: 14,
                }}
                onPress={() => {

                    if (Platform.OS === 'ios') {

                        setVis(true);

                    } else {

                        selectOptions({
                            title: Object.R('addRelative_sex.placeholder'),
                            data,
                            onSelect: ({ id }) => {

                                onChange?.(id);

                            },
                            selected: v,
                            useSystemModal: true,
                        });

                    }

                }}
            />
            {!!vis && Platform.OS === 'ios' && (
                <View>
                    <Modal2 _onClose={() => setVis(false)} title={'addRelative_sex.placeholder'}>
                        <Selector
                            radio
                            keyword={value}
                            data={data}
                            onSelect={(item) => {

                                onChange?.(item.id);
                                setVis(false);

                            }}
                            selected={v}
                        />
                    </Modal2>
                </View>
            )}
        </>
    );

};

const DateTimeSelectorWithModal = (props) => {

    return <DateTimeSelector {...props} />;

};

export const InputField = ({
    title,
    type,
    value: initialValue,
    onChange,
    onError,
    required,
    inputStyle,
    getRef,
    validateOnBlur = false,
    validateOnChange = false,
    titleId,
    ...props
}) => {

    const [error, setError] = useState('');
    const [value, setValue] = useState(initialValue);
    const currentValue = useRef(initialValue);
    const fieldByType = {
        text: { Component: TextInput },
        date: { Component: DateTimeSelectorWithModal },
        pastDate: { Component: DateTimeSelectorWithModal },
        pastDateReverse: { Component: DateTimeSelectorWithModal },
        phone: { Component: PhoneInput },
        sex: { Component: Gender },
    };
    useEffect(() => {

        let e = required && validateByFieldRequired({
            required,
            id: titleId,
        }, initialValue);
        if (e) {

            onError?.(title, e);

        } else {

            e = validateByFieldType({ type }, initialValue);
            onError?.(title, e);

        }

    }, []);

    const validate = (text) => {

        const e = validateByFieldRequired({
            required,
            id: titleId,
        }, text ?? value);
        setError(e);
        if (e) {

            return;

        }

        let v = text ?? value;
        if (type === 'phone') {

            v = `+${(text ?? value)?.replace?.(/[^0-9]/g, '')}`;

        } else if (type === 'pastDateReverse') {

            v = toDateTZ(v, true);

        }
        const err = validateByFieldType({ type }, v);
        setError(err);

    };

    const onChangeHandler =
        (name, validateNow = false) => (data) => {

            const text = data?.nativeEvent?.text ?? data;
            setValue(text);
            currentValue.current = text;
            typeof onChange === 'function' && onChange?.(text);
            if (validateOnChange || (validateNow && data)) {

                validate(text);

            }

        };

    const onBlur = useCallback(() => {

        if (validateOnBlur) {

            validate(currentValue.current);

        }

    }, [value, validateOnBlur, currentValue]);

    useEffect(() => {

        // onError?.(title, error);
        error !== '' && onError?.(title, error);

    }, [error]);

    useEffect(() => {

        if (type === 'phone' || type === 'text') {

            if (type === 'phone') {

                let e = validateByFieldRequired({
                    required,
                    id: titleId,
                }, initialValue);
                if (!e) {

                    e = validateByFieldType({ type }, initialValue);

                }
                if (!e) {

                    setError(e);

                }

            }
            setValue(initialValue);

        }

    }, [initialValue]);

    const Component = fieldByType[type]?.Component ?? React.Fragment;
    return (
        <Field required={required} title={title}>
            <Component
                value={value}
                date={value}
                time={value}
                onChangeText={onChangeHandler('onChangeText')}
                onBlur={onBlur}
                required={required}
                style={inputStyle}
                inputStyle={inputStyle}
                onSelectDate={onChangeHandler('selectDate', true)}
                countryCode={COUNTRY_CODE}
                onChange={onChangeHandler('onChange')}
                ref={(r) => {

                    getRef?.(r);

                }}
                {...props}
            />
            {!!error && <Text style={{ color: COLORS.NEGATIVE }}>{error}</Text>}
        </Field>
    );

};
