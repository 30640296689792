/* eslint-disable no-underscore-dangle,no-param-reassign */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Svg, {
    Defs, LinearGradient, Rect, Stop,
} from 'react-native-svg';
import { useNavigationState } from '@react-navigation/native';
import {
    Avatar,
    Box,
    Button,
    CoverableBy,
    DetailedInfoColumns,
    Icon,
    Img,
    InputWrapper,
    List,
    Platform,
    React,
    RText,
    SearchBarNative,
    Subtitle,
    Text,
    Title,
    TouchableOpacity,
    TouchableWithoutFeedback,
    VBox,
    View,
    withState,
} from '../../common';
import { capitalize, getDefaultNearestDate } from '../../utils';
import actions, { back, navigate } from '../../actions';
import { getDoctorProfile, getHandledAgesFromProfile } from '../../selectors/utils';
import {
    doctorsWithFeedbackSums,
    getClinicsNames,
    getClinicsWithBumbaByType,
    getCurrentUserProgram,
    isHasTimetable,
} from '../../selectors';
import { COLORS } from '../../styles/colors';
import { notifications } from '../../common/Notifications';
import { Feedback } from '../Feedbacks/Doctors/snippets';
import { infoModal } from '../../common/Modal';
import { getRouteHistory } from '../../utils/getRouteHistory';
import { FilterTag } from '../../common/FilterTag';
import { getEnums } from '../../i18n';
import { TapBarText } from '../../common/DatePicker';
import { putIntoStore } from '../../store';
import { SCHEME } from '../../scheme';
import api from '../../services/api';
import { getRequestDates, sortClinics, toVisit } from './utils';
import { specializationSearchBar } from './style';
import { doctorFilterParams } from './DoctorsSpecialtiesSearch';

const _notifyDebug_ = false;
const forChildren = profile => profile?.some(({ isForChildren }) => isForChildren);
const forAdults = profile => profile?.some(({ isForChildren }) => !isForChildren);

export const Gradient = () => (Platform.OS === 'web' ? (
    <div
        style={{
            height: 12,
            width: '95%',
            background: '#E0E0E0',
            marginLeft: 10,
        }}
    />
) : (
    <Svg height="14" width="95%">
        <Defs>
            <LinearGradient id="grad" x1="0" y1="0" x2="1" y2="0">
                <Stop offset="0" stopColor="#E0E0E0" stopOpacity="1" />
                <Stop offset="1" stopColor="#fff" stopOpacity="1" />
            </LinearGradient>
        </Defs>
        <Rect x="6" y="3" width="95" height="14" fill="url(#grad)" />
    </Svg>
));
export const DoctorComment = ({
    textFeedbackCount, style = {}, hide = false, iconSize = 18, textStyle = {},
}) => (hide ? null : (
    <View
        style={{
            ...style,
            flexDirection: 'row',
            alignItems: 'center',
        }}>
        <Icon.CommentDoctor color={COLORS.MAIN} size={iconSize} />
        <Text
            style={{
                fontSize: 12,
                lineHeight: 15,
                fontWeight: '400',
                marginLeft: 4,
                color: COLORS.STANDARD_GRAY,
                ...textStyle,
            }}>
            {String.i18n('feedbacks', textFeedbackCount ?? 0)}
        </Text>
    </View>
));

export const DoctorCalendar = ({ textPadding, nearestDate, hasTimetableSource = isHasTimetable }) => {

    const isUpdated = useSelector(hasTimetableSource);
    return (
        <>
            <Icon.CalendarBig color={COLORS.MAIN} style={{ alignItems: 'center' }} />
            {isUpdated ? (
                <Text
                    numberOfLines={2}
                    ellipsizeMode="tail"
                    style={{
                        paddingTop: textPadding,
                        marginLeft: 4,
                        fontSize: 12,
                        lineHeight: 15,
                        fontWeight: '400',
                        color: COLORS.STANDARD_GRAY,
                    }}>
                    {capitalize(getDefaultNearestDate(nearestDate))}
                </Text>
            ) : (
                <Gradient />
            )}
        </>
    );

};

export const DoctorBanner = ({
    doctor: {
        photoUrl,
        fullName,
        specialization,
        worksAt,
        coverer,
        profile,
        limited,
        priceRange: { priceRangeString } = {},
        id,
    },
    doctor,
    feedback: {
        recommendCount = 0, notRecommendCount = 0, neutralCount = 0, textFeedbackCount = 0,
    } = {},
    hideFeedback = true,
    style = {},
    iconSize = 18,
}) => {

    const { children: currentForChildren = false, adults: currentForAdults = false } =
        useSelector(getCurrentUserProgram) || {};
    const bumbaClinics = useSelector(getClinicsWithBumbaByType);

    const hasBumbaTest = clinicId => !!(bumbaClinics ?? {})[clinicId]?.bumbaTestsSupport;
    const hasBumbaReport = clinicId => !!(bumbaClinics ?? {})[clinicId]?.bumbaReportsSupport;
    const hasBumba = clinicId => hasBumbaTest(clinicId) || hasBumbaReport(clinicId);

    const clinicsBumbaOptions = {
        tests: worksAt?.some(({ clinicId, id: id_ }) => hasBumbaTest(clinicId) || hasBumbaTest(id_)),
        reports: worksAt?.some(({ clinicId, id: id_ }) => hasBumbaReport(clinicId) || hasBumbaReport(id_)),
    };

    const clinicOptionsText = `${clinicsBumbaOptions?.tests ? 'Tests' : ''}${
        clinicsBumbaOptions?.reports ? 'Reports' : ''
    }`;

    const forChild = forChildren(profile || getDoctorProfile(worksAt || []));
    const forAdult = forAdults(profile || getDoctorProfile(worksAt || []));

    const marginText = Platform.OS === 'web' ? 0 : 2;
    const getDoctorBadge = () => (coverer && ((forChild && currentForChildren) || (forAdult && currentForAdults)) ? coverer : '');

    const fontSize = 13;
    return (
        <Box
            gap={1.5}
            style={{
                ...style,
                paddingLeft: 12,
                paddingTop: 12,
                paddingRight: 0,
                marginTop: 0,
            }}>
            <Avatar
                of="physicians"
                imageUrl={photoUrl}
                coverer={getDoctorBadge()}
                size={64}
                isLimited={limited && coverer === limited}
            />
            <VBox gap={0.25} style={{ flex: 1 }}>
                <Title.Bigger bold numberOfLines={2}>
                    {fullName?.replace(' ', '\n')}
                </Title.Bigger>
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}>
                    <Subtitle.Bigger
                        style={{
                            flex: 1,
                            fontSize,
                        }}
                        numberOfLines={null}>
                        {capitalize(specialization)}
                    </Subtitle.Bigger>
                    <View
                        style={{
                            flexDirection: 'row',
                            paddingRight: 12,
                        }}>
                        <AgeBadges ages={getHandledAgesFromProfile(profile).ages} />
                    </View>
                </View>
                {sortClinics(
                    worksAt?.map?.((item) => {

                        item.hasBumba = hasBumba(item?.clinicId) || hasBumba(item?.id);
                        return item;

                    }),
                )?.map(e => (
                    <Box key={e.id} centered>
                        {coverer && getDoctorBadge() ? (
                            <CoverableBy.Dot coverer={e.coverer} style={{ marginLeft: 0 }} />
                        ) : null}
                        <Subtitle.Bigger
                            style={{
                                flex: 1,
                                alignItems: 'center',
                                lineHeight: 18,
                                fontSize,
                            }}
                            numberOfLines={2}>
                            <Text>
                                {e.name}
                                {(hasBumba(e.clinicId) || hasBumba(e.id)) && (
                                    <>
                                        <Icon.Attach
                                            size={11}
                                            style={{
                                                paddingTop: 3,
                                                paddingLeft: 4,
                                            }}
                                            color={COLORS.MAIN}
                                        />
                                    </>
                                )}
                            </Text>
                        </Subtitle.Bigger>
                    </Box>
                ))}
                {worksAt?.some(({ clinicId, id: id_ }) => hasBumba(clinicId) || hasBumba(id_)) && (
                    <TouchableOpacity
                        onPress={() => {

                            const text = Object.R(`titles.bumbaDoctorExplanation${clinicOptionsText}`);
                            const title = Object.R(`titles.bumbaDoctorExplanationTitle`);
                            infoModal(title, text);

                        }}>
                        <Box style={{ marginTop: 10 }}>
                            <Icon.Attach
                                size={11}
                                style={{
                                    marginTop: 2,
                                    marginRight: 4,
                                }}
                            />
                            <Subtitle.Bigger
                                style={{
                                    flex: 1,
                                    alignItems: 'center',
                                    fontSize,
                                    color: COLORS.MAIN,
                                }}
                                numberOfLines={2}>
                                {Object.R(`titles.doctorDetailsHasBumba${clinicOptionsText}`)}
                            </Subtitle.Bigger>
                        </Box>
                    </TouchableOpacity>
                )}
                {priceRangeString && hideFeedback ? (
                    <Title
                        bold
                        style={{
                            color: COLORS.STANDARD_GRAY,
                            paddingTop: 6,
                        }}>{`${Object.R('titles.consultation')} ${priceRangeString}`}</Title>
                ) : (
                    <View style={{ flexDirection: 'row' }}>
                        <Feedback
                            hide={hideFeedback}
                            neutralCount={neutralCount}
                            notRecommendCount={notRecommendCount}
                            recommendCount={recommendCount}
                            style={{ marginTop: 5 }}
                            textStyle={{ paddingTop: marginText }}
                            iconSize={iconSize}
                        />
                        <DoctorComment
                            hide={hideFeedback}
                            textFeedbackCount={textFeedbackCount}
                            style={{
                                marginTop: 5,
                                paddingLeft: 22,
                            }}
                            iconSize={iconSize}
                            textStyle={{ paddingTop: marginText }}
                        />
                    </View>
                )}
                {_notifyDebug_ ? (
                    <View
                        style={{
                            flexDirection: 'row',
                            padding: 0,
                            margin: 0,
                        }}>
                        <Button
                            orange
                            title="Add notify 5s"
                            action={() => {

                                notifications.schedule({
                                    date: new Date(Date.now() + 5000),
                                    text: fullName,
                                    title: fullName,
                                    message: 'Send feedback',
                                    data: {
                                        type: 'feedback_reminder',
                                        doctorId: id,
                                    },
                                });

                            }}
                        />
                        <Button
                            orange
                            title="To add feedback"
                            action={() => {

                                const feedback = {
                                    forItemId: id,
                                    anonymous: false,
                                };
                                navigate('AddDoctorFeedbacks', {
                                    feedback,
                                    fromNotification: true,
                                    background: true,
                                    doctorInfo: doctor,
                                });

                            }}
                        />
                    </View>
                ) : null}
            </VBox>
        </Box>
    );

};

export const DoctorDetailedInfoColumns = ({
    doctor,
    doctor: { id: doctorId },
    action = () => toVisit(doctor),
    toFeedbacks = () => navigate('DoctorFeedbacks', { doctorInfo: doctor }),
    feedbacks,
}) => {

    const navState = useNavigationState(s => s);
    const [doctorInfo, setDoctorInfo] = useState(doctor);
    const doctorFeedbacksSum = useSelector(doctorsWithFeedbackSums);
    useEffect(() => {

        if (feedbacks?.textFeedbackCount) {

            setDoctorInfo(feedbacks);

        } else {

            const found = doctorFeedbacksSum?.find(({ id }) => doctorId === id);
            if (found) {

                setDoctorInfo(found);

            }

        }

    }, [doctorFeedbacksSum, feedbacks]);
    return (
        <DetailedInfoColumns
            leftIcon={<Icon.CalendarBig size={20} color={COLORS.MAIN} />}
            leftItemTitle={<RText id="titles.nearest_date" />}
            leftItemText={String(getDefaultNearestDate(doctor?.nearestDate))}
            rightIcon={<Icon.CommentDoctor size={20} color={COLORS.MAIN} />}
            rightItemTitle={<RText id="titles.feedbacks" />}
            rightItemText={
                <RText style={{ color: '#555' }} id="feedbacks" count={doctorInfo?.textFeedbackCount || 0} />
            }
            rightItemHandlePress={() => {

                Object.trackAction('doctorDetailDetails_toFBIcon', { journey: getRouteHistory(navState) }, 'ai_nav');
                toFeedbacks();

            }}
            leftItemHandlePress={() => {

                Object.trackAction('doctorDetailDetails_toVisitIcon', { journey: getRouteHistory(navState) }, 'ai_nav');
                action();

            }}
        />
    );

};

// noinspection JSUnusedGlobalSymbols
export const AgesText = ({ ages = [] }) => (
    <Subtitle numberOfLines={0}>{ages.map(({ title }) => title).join(',')}</Subtitle>
);

export const AgeBadges = ({ ages = [] }) => (
    <Box gap={0.5} centered style={{ alignSelf: 'baseline' }}>
        {ages.map(({ id, badge, isForChild }) => (
            <AgeBadge key={id} title={badge} isForChild={isForChild} />
        ))}
    </Box>
);

export const AgeBadge = ({ title, isForChild = false }) => (
    <View
        style={{
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 2,
            paddingHorizontal: 8,
            backgroundColor: '#ACB4CD',
            borderRadius: 12,
        }}>
        {isForChild ? (
            <Img.ChildBadge
                style={{
                    marginRight: 6,
                    width: 13,
                    height: 13,
                }}
            />
        ) : null}
        <Title
            style={{
                color: 'white',
                fontSize: 12,
            }}>
            {title}
        </Title>
    </View>
);

export const SpecializationSearchBar = withState(
    ({
        value,
        clear,
        onChangeText,
        setState,
        onSuggestionSelected,
        isShowingSuggestions,
        suggestionsRequest,
        suggestions = suggestionsRequest(value),
        searchBar,
        style,
    }) => (
        <View>
            <View
                style={[
                    specializationSearchBar.searchBar,
                    style,
                    isShowingSuggestions
                        ? {
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                        }
                        : {
                            borderBottomLeftRadius: 3,
                            borderBottomRightRadius: 3,
                        },
                ]}>
                <InputWrapper>
                    <SearchBarNative
                        ref={(ref) => {

                            searchBar = ref;

                        }}
                        accessibilityLabel="search"
                        searchIcon={Icon.SearchBarFind}
                        clearIcon={null}
                        platform="default"
                        placeholder={Object.R('placeholders.doctorSpecialization')}
                        containerStyle={[
                            specializationSearchBar.container,
                            {
                                backgroundColor: 'transparent',
                                borderTopColor: 'transparent',
                                borderBottomColor: 'transparent',
                                width: '90%',
                                padding: 0,
                                margin: 0,
                                outline: 'none',
                                outlineWidth: 0,
                            },
                        ]}
                        inputStyle={[
                            specializationSearchBar.input,
                            {
                                outline: 'none',
                                outlineWidth: 0,
                            },
                        ]}
                        inputContainerStyle={[
                            specializationSearchBar.input,
                            {
                                paddingVertical: 0,
                                outline: 'none',
                                outlineWidth: 0,
                            },
                        ]}
                        leftIconContainerStyle={{ width: 30 }}
                        value={value}
                        onCancel={clear}
                        {...(Platform.OS === 'web' ? {} : { onClear: clear })}
                        onChangeText={onChangeText}
                        underlineColorAndroid="transparent"
                        onFocus={() => {

                            onChangeText(value);
                            setState({ isShowingSuggestions: true });

                        }}
                    />
                </InputWrapper>
                {(value && value.length) || isShowingSuggestions ? (
                    <Icon.SearchBarClose
                        iconStyle={
                            Platform.OS === 'web' && !window.isDesktop
                                ? {
                                    marginHorizontal: 0,
                                    width: 20,
                                    marginRight: 16,
                                }
                                : {}
                        }
                        onPress={() => {

                            if (searchBar) {

                                searchBar.blur();

                            }
                            clear();

                        }}
                    />
                ) : null}
            </View>
            {isShowingSuggestions ? (
                <List
                    style={specializationSearchBar.suggestionsList}
                    count={suggestions && suggestions.length}
                    data={suggestions}
                    nestedScrollEnabled
                    renderItem={({
                        item: {
                            apiId, id, name, isPreviousSelection,
                        },
                    }) => (
                        <TouchableWithoutFeedback
                            onPress={() => {

                                onSuggestionSelected(apiId, name);
                                setState({
                                    value: name,
                                    isShowingSuggestions: false,
                                });

                            }}>
                            <View
                                style={[
                                    specializationSearchBar.suggestionsItem,
                                    id === suggestions[suggestions.length - 1]?.id ? { borderBottomWidth: 0 } : {},
                                ]}>
                                {isPreviousSelection ? <Img.Time /> : null}
                                <Title
                                    style={[
                                        isPreviousSelection
                                            ? specializationSearchBar.previousSuggestionItemText
                                            : specializationSearchBar.suggestionItemText,
                                        { paddingBottom: isPreviousSelection && Platform.OS === 'android' ? 4 : 0 },
                                    ]}>
                                    {name}
                                </Title>
                            </View>
                        </TouchableWithoutFeedback>
                    )}
                    ListEmptyComponent={<View />}
                />
            ) : null}
        </View>
    ),
    ({
        setState,
        onSuggestionSelected,
        onQueryChange,
        onChangeText = (v) => {

            if (onQueryChange) {

                onQueryChange(v);

            }
            setState({ value: v });

        },
    }) => ({
        setState,
        onChangeText,
        clear: () => {

            onSuggestionSelected(null, '');
            setState({
                value: '',
                isShowingSuggestions: false,
            });

        },
    }),
);

const getDates = ({ dateStart, dateEnd }) => {

    if (dateStart) {

        const [, m, d] = dateStart.split('-');
        if (!dateEnd) {

            return `${d}.${m}`;

        }
        const [, m2, d2] = dateEnd.split('-');
        return `${d}.${m} - ${d2}.${m2}`;

    }
    return '';

};

const getTime = timeId => (timeId === '0' ? '' : getEnums('preferredTimes')?.find(el => el.id === timeId)?.name);
const getAge = (ageId = '0') => {

    const agesList = {
        0: 'title.filterAgeAny',
        1: 'titles.filterAgeChild',
        2: 'title.filterAgeAdult',
    };
    // const enums = getEnums('preferredAges');
    // console.log('enums',{
    //     enums,
    //     timeId,
    //     res: (timeId === '0' ? '' : enums?.find(el => el.id === timeId)?.name),
    // });
    return ageId === '0' ? '' : Object.R(agesList[ageId] ?? '');
    // return (ageId === '0' ? '' : enums?.find(el => el.id === ageId)?.name);

};

// const getProfile = (profileId) => getEnums('preferredProfile')?.find(el => el.id === profileId)?.name;

const getKeyAndTitlesObj = (key, value) => {

    if (!value) {

        return '';

    }
    switch (key) {

        case 'dates':
            return getDates(value);
        case 'time':
            return getTime(value);
        case 'age':
            return getAge(value);
        case 'worksAt':
            return '';
        case 'profiles':
            return value === '0' ? '' : Object.R('titles.filterTagChild');
        case 'worksAtGroup':
            return '';
        case 'feedbacks':
            return value ? Object.R('titles.filterTagFeedbacks') : '';
        case 'specialtyId':
            return '';
        default:
            return capitalize(value);

    }

};

const getAdaptedTitle = (value = '', n) => {

    if (value.length <= n) {

        return value;

    }
    return `${value.substring(0, n - 2)}...`;

};

const getKeyAndTitles = (obj, names) => {

    if (obj) {

        const keys = Object.keys(obj);
        const result = keys.map((el) => {

            const title = getKeyAndTitlesObj(el, obj[el], names);
            return {
                key: el,
                title: getAdaptedTitle(title, 18),
            };

        });
        if (obj.worksAt) {

            const worksAtArr = obj.worksAt?.map(id => ({
                key: `worksAt-${id}`,
                title: getAdaptedTitle(names[id]?.name, 18),
            }));
            return [...result, ...worksAtArr];

        }
        return result;

    }
    return obj;

};

const defaultValues = {
    specialty: '',
    worksAt: [],
    profiles: '0',
    feedbacks: false,
    time: '0',
    dates: {},
};

const priorityOfTags = {
    specialty: 0,
    profiles: 1,
    dates: 2,
    time: 3,
    feedbacks: 4,
    worksAt: 5,
};

const updatedPhysiciansByApi = async (specializationForApi, areaCode, dateStart, dateEnd) => {

    putIntoStore(SCHEME?.PHYSICIANS_IS_LOADING, true);
    actions?.setIsDoctorsSearching?.(true);
    try {

        const params =
            getRequestDates({
                dateStart,
                dateEnd,
            }) ?? {};

        const searchResult = await api.getDoctorSearchResultInt({
            specialityName: specializationForApi,
            areaCode,
            ...params,
            // dateStart,
            // dateEnd || dateStart,
            // '00:00',
            // '23:59',
        });
        const searchResultArray = JSON.parse((searchResult || {}).data);
        putIntoStore(SCHEME?.PHYSICIANS_FROM_SERARCH, Array.isArray(searchResultArray) ? searchResultArray : undefined);

    } catch (e) {
        //
    } finally {

        actions?.setIsDoctorsSearching?.(false);

    }
    putIntoStore(SCHEME?.PHYSICIANS_IS_LOADING, false);

};

const onTagClose = (key, filters, info, from = 'default') => {

    const filterParams = doctorFilterParams[from] ?? doctorFilterParams.default;
    if (key === 'specialty') {

        putIntoStore(filterParams.scheme, {
            ...filters,
            time: '0',
            dates: {},
            specialty: '',
        });
        putIntoStore(filterParams.physiciansSearchScheme, undefined);
        putIntoStore(filterParams.specializationForApi, undefined);
        back();

    } else if (key === 'dates') {

        putIntoStore(filterParams.scheme, {
            ...filters,
            time: '0',
            dates: {},
        });
        putIntoStore(filterParams.physiciansSearchScheme, undefined);
        putIntoStore(filterParams.specializationForApi, undefined);

    } else if (key === 'time') {

        putIntoStore(filterParams.scheme, {
            ...filters,
            time: '0',
        });
        updatedPhysiciansByApi(
            info?.specialtyForApi,
            info?.areaCode,
            filters?.dates?.dateStart,
            filters?.dates?.dateEnd,
        );

    } else if (key.startsWith('worksAt')) {

        const [, id] = key.split('-');
        const worksAt = filters?.worksAt.filter(e => e !== id);
        putIntoStore(filterParams.scheme, {
            ...filters,
            worksAt,
        });
        actions.updateDocFilters({
            worksAt,
            worksAtGroup: 'any',
        });

    } else {

        putIntoStore(filterParams.scheme, {
            ...filters,
            [key]: defaultValues[key],
        });

    }

};

export const FiltersTagsGroup = ({ from }) => {

    const filterParams = doctorFilterParams[from] ?? doctorFilterParams.default;
    const [tagHeight, setTagHeight] = useState();
    const [tagsHeight, setTagsHeight] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const doctorsFilters = useSelector(filterParams.filter);
    const medcenterNames = useSelector(state => getClinicsNames(state, doctorsFilters?.worksAt));
    const info = useSelector(filterParams.getFiltersAddInfo);
    let filtersTags = [];
    if (doctorsFilters) {

        filtersTags = getKeyAndTitles(doctorsFilters, medcenterNames)
            ?.filter(e => e?.title)
            ?.sort(({ key, title }, { key: key2, title: title2 }) => {

                const [aKey] = key.split('-');
                const [aKey2] = key2.split('-');
                return aKey === aKey2 ? title2?.length - title?.length : priorityOfTags[aKey] - priorityOfTags[aKey2];

            });

    }
    const onLayout = (event) => {

        const { height } = event.nativeEvent.layout;
        if (!tagHeight) {

            setTagHeight(height);

        }

    };
    const onViewLayout = (event) => {

        const { height } = event.nativeEvent.layout;
        if (tagsHeight !== height) {

            setTagsHeight(height);

        }

    };
    const fullHeight = tagHeight ? tagHeight * 2 + 18 : 0;
    return (
        <>
            <View
                style={
                    !isOpen && tagHeight
                        ? {
                            maxHeight: fullHeight,
                            overflow: 'hidden',
                        }
                        : {}
                }>
                <View
                    style={[
                        {
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            marginHorizontal: 12,
                        },
                    ]}
                    onLayout={onViewLayout}>
                    {filtersTags.map(({ key, title }) => (
                        <FilterTag
                            title={title}
                            getHeigth={onLayout}
                            key={key}
                            onClose={() => onTagClose(key, doctorsFilters, info, from)}
                        />
                    ))}
                </View>
            </View>
            {tagsHeight && fullHeight && tagsHeight > fullHeight ? (
                <View style={{ alignSelf: 'flex-end' }}>
                    <TapBarText
                        close={!isOpen}
                        onPress={() => {

                            setIsOpen(!isOpen);

                        }}
                        openText={Object.R('titles.serviceDescriptionMore')}
                        closeText={Object.R('titles.serviceDescriptionLess')}
                    />
                </View>
            ) : null}
        </>
    );

};
