import ModalDropdown from 'react-native-modal-dropdown';
import { React, View } from './react';
import { Icon } from './Icon';
import { Title } from './elements';
import { withState } from './_hoc';

/**
 * Dropdown component.
 */
export const Dropdown = withState(
    ({
        data = [],
        value = data[0],
        containerStyle,
        dropdownTextStyle,
        onSelectOption,
        dropdownStyle,
    }) => (
        <ModalDropdown
            options={data}
            style={containerStyle}
            dropdownStyle={[{ height: 45 * data.length }, dropdownStyle]}
            dropdownTextStyle={[{ height: 44 }, dropdownTextStyle]}
            dropdownTextHighlightStyle={dropdownTextStyle}
            onSelect={onSelectOption}
        >
            <View style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: 12,
            }}>
                <Title style={dropdownTextStyle}>{value}</Title>
                <Icon
                    name="down-arrow"
                    size={20}
                    color="#555555"
                    style={{ marginTop: 2 }}
                />
            </View>
        </ModalDropdown>
    ),
    ({
        onSelect,
        setState,
        onSelectOption = (index, option) => {
            if (onSelect) {
                onSelect(option);
            }
            setState({ value: option });
        },
    }) => ({ onSelectOption }),
);
