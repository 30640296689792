import { useNavigationState } from '@react-navigation/native';
import {
    Avatar,
    Box,
    CoverableBy,
    DetailedInfoColumns,
    Icon,
    Link,
    Panel,
    React,
    RText,
    Subtitle,
    Title,
    TouchableOpacity,
    VBox,
    View,
} from '../../../common';
import { COLORS } from '../../../styles/colors';
import { onShowOnMap } from '../../../common/InfoList';
import { infoModal } from '../../../common/Modal';
import { hasClinicsCoords } from '../../Tabletka/DrugstoreDetails.utils';
import { getRouteHistory } from '../../../utils/getRouteHistory';

export * from './MedCentersListItem';
export * from './MedCentersListMenu';

export const McBanner = React.memo(({
    clinic: {
        coverer, photoUrl, name, address,
    },
}) => (
    <VBox centered>
        <Avatar of="clinics" imageUrl={photoUrl} coverer={coverer} bigger />
        <VBox style={{ paddingTop: 16 }} centered gap={0.65}>
            <Title.Bigger bold centered>
                {name}
            </Title.Bigger>
            <Subtitle.Bigger>{address}</Subtitle.Bigger>
        </VBox>
    </VBox>
));

export const McBannerMain = React.memo(
    ({
        clinic: {
            coverer,
            photoUrl,
            name,
            address,
            addressLink,
            limited,
            _id,
            latitude,
            longitude,
            bumbaTestsSupport,
            bumbaReportsSupport,
        },
    }) => {

        const onShowOnMapHandler = () => {

            onShowOnMap(
                addressLink,
                address,
                _id,
                hasClinicsCoords([
                    {
                        latitude,
                        longitude,
                    },
                ]),
            );

        };

        const onExplainClickHandler = () => {

            const resource = `titles.bumbaClinic${bumbaTestsSupport ? 'Tests' : ''}${
                bumbaReportsSupport ? 'Results' : ''
            }`;
            const headerResource = `${resource}Header`;
            const textResource = `${resource}Text`;
            const header = Object.R(headerResource);
            const text = Object.R(textResource);
            infoModal(header, text);

        };

        return (
            <Box>
                <Avatar of="clinics" imageUrl={photoUrl} coverer={coverer} size={86} isLimited={limited} />
                <VBox
                    gap={0.65}
                    style={{
                        flex: 1,
                        paddingLeft: 24,
                    }}>
                    <Title.Bigger style={{ fontSize: 18 }} numberOfLines={null} bold>
                        {name}
                    </Title.Bigger>
                    {bumbaReportsSupport || bumbaTestsSupport ? (
                        <View
                            style={{ flexDirection: 'row' }}
                            onPress={() => {

                                onExplainClickHandler();

                            }}>
                            <Subtitle style={{ fontSize: 14 }}>{address}</Subtitle>
                            <Icon.Attach
                                style={{
                                    paddingTop: 3,
                                    paddingLeft: 4,
                                }}
                                size={11}
                            />
                        </View>
                    ) : (
                        <Subtitle style={{ fontSize: 14 }}>{address}</Subtitle>
                    )}

                    {!address ? null : (
                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginVertical: -4,
                            }}>
                            <Icon.Geo color="#6684D7" size={16} />
                            <Link style={{ padding: 6 }} href={onShowOnMapHandler} trackingAlias="map" underline>
                                titles.showOnMap
                            </Link>
                        </View>
                    )}

                    {(bumbaReportsSupport || bumbaTestsSupport) && (
                        <TouchableOpacity onPress={() => onExplainClickHandler()}>
                            <Box style={{ marginTop: 10 }}>
                                <Icon.Attach
                                    size={11}
                                    style={{
                                        marginTop: 2,
                                        marginRight: 4,
                                    }}
                                />
                                <Subtitle.Bigger
                                    style={{
                                        flex: 1,
                                        alignItems: 'center',
                                        fontSize: 14,
                                        color: COLORS.MAIN,
                                    }}
                                    numberOfLines={2}>
                                    {Object.R(
                                        `titles.bumbaClinic${bumbaTestsSupport ? 'Tests' : ''}${
                                            bumbaReportsSupport ? 'Results' : ''
                                        }`,
                                    )}
                                </Subtitle.Bigger>
                            </Box>
                        </TouchableOpacity>
                    )}
                </VBox>
            </Box>
        );

    },
);

export const McDetailedInfoColumns = React.memo(({ clinic: { workTime, feedbackCount }, navTo }) => {

    const navState = useNavigationState(s => s);
    return (
        <DetailedInfoColumns
            leftIcon={<Icon.Clock size={25} />}
            leftItemTitle={<RText id="titles.working_time" />}
            leftItemText={workTime}
            leftItemHandlePress={() => {

                Object.trackAction('clinicDetails_clinicInfo', { journey: getRouteHistory(navState) }, 'ai_nav');

                navTo('MedCenterInfo');

            }}
            rightIcon={<Icon.CommentDoctor size={20} color={COLORS.MAIN} />}
            rightItemTitle={<RText id="titles.feedbacks" />}
            rightItemText={
                feedbackCount == null ? null : (
                    <RText id="feedbacks" style={{ color: '#555555' }} count={feedbackCount} />
                )
            }
            rightItemHandlePress={() => {

                Object.trackAction('clinicDetails_feedbacks', { journey: getRouteHistory(navState) }, 'ai_nav');

                navTo('MedCenterFeedbacks');

            }}
        />
    );

});

export const ServicesListItem = React.memo(
    ({
        data, data: {
            name, type = {}, group = {}, coverer, limited,
        }, onPress,
    }) => (
        <View onStartShouldSetResponder={() => true}>
            <Panel pressHandler={onPress ? () => onPress(data) : null}>
                <VBox gap={1} flex>
                    <VBox gap={0.6}>
                        <Title bold>{name}</Title>
                        <Subtitle numberOfLines={null}>{group.name}</Subtitle>
                    </VBox>
                    <Box justify="space-between">
                        <Subtitle>{type.name}</Subtitle>
                        <CoverableBy coverer={coverer} isLimited={limited} />
                    </Box>
                </VBox>
            </Panel>
        </View>
    ),
);
