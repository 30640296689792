import { useSelector } from 'react-redux';
import {
    React,
    Section,
    View,
    Platform,
    Title,
    Switch,
} from '../../common';
import { getIsOnline, getUmsActiveFilters } from '../../selectors';
import { ListHeaderWithSearch } from '../../combo/ListHeaderWithSearch';
import actions from '../../actions';
import tracking from '../../services/tracking';
import { goToUmsFilter } from './utils';

export const UMSSwitch = ({ value, onValueChange }) => <Switch
    activeThumbColor="#4770C0"
    activeTrackColor="#9FB9FF"
    accessibilityLabel="switch:drugs-search"
    // eslint-disable-next-line no-nested-ternary
    style={Platform.OS === 'ios' ? { transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }] } : Platform.OS === 'web' ? { marginLeft: 12 } : {}}
    onTintColor={Platform.OS !== 'web' ? '#6987D6' : '#8FB8FF'}
    thumbTintColor="#5880D0"
    value={value}
    trackColor={{
        false: '#CFCFCF',
        true: Platform.OS === 'ios' ? '#5C85DD' : '#8FB8FF',
    }}
    onValueChange={onValueChange}
/>;

const OnlineSwitch = () => {
    const isOnline = useSelector(getIsOnline);
    const onValueChange = (value) => {
        tracking.logEvent(`ums_online_switch`);
        actions.setOnlineFilter(value);
    };
    return (
        <View style={{
            flexDirection: 'row', flex: 1, justifyContent: 'flex-end', alignItems: 'center',
        }}>
            <Title style={{
                fontFamily: 'SourceSansPro-Semibold',
                fontSize: 12,
                color: isOnline ? '#6987D6' : '#787878',
            }}>{Object.R('titles.umsOnline')}</Title>
            <UMSSwitch value={isOnline} onValueChange={onValueChange} />
        </View>
    );
};

const UMSListSectionHeader = ({ isLoading }) => (
    <Section
        isLoading={isLoading}
        title={Object.R('titles.umsListsHeader')}
        style={{
            marginHorizontal: 20,
            paddingTop: 10,
            marginTop: 0,
            marginBottom: 0,
            paddingBottom: 8,
            borderBottomWidth: 1,
            borderBottomColor: '#E9E9E9',
            paddingLeft: 0,
        }}
        capitalize
        right={<OnlineSwitch />}
    />
);

export const UMSListHeader = ({ onSearch, searchData, isLoading }) => {
    const filtersData = useSelector(getUmsActiveFilters);
    return (
        <>
            <ListHeaderWithSearch onSearch={onSearch} searchData={searchData} filtersData={filtersData} gotoFilters={goToUmsFilter} placeholder="titles.search_services" />
            <UMSListSectionHeader isLoading={isLoading} />
        </>
    );
};
