import { notifications } from '../../../common/Notifications';
import { isPhoneNumberValid } from '../../../utils/phone';
import api from '../../../services/api';

const getApi = ({ serviceId, orderId, manual }) => {

    let visitApi;
    if (orderId) {

        notifications.cancelAllNotificationsForVisits(orderId);

    }
    if (serviceId && serviceId !== '0') {

        if (orderId) {

            visitApi = 'changeServiceRecord';

        } else {

            visitApi = 'makeServiceRecord';

        }

    } else if (orderId) {

        visitApi = 'changeVisitRecord';

    } else {

        visitApi = 'makeVisitRecord';

    }
    if (manual) {

        if (orderId) {

            if (serviceId && serviceId !== '0') {


                visitApi = 'changeServiceRecord';

            } else {

                visitApi = 'changeVisitRecord';

            }

        } else {

            visitApi = 'makeVisitIndependent';

        }

    }
    return visitApi;

};

const getPatientData = (profile, removeEmpty = false) => {

    const res = Object.entries({
        birthday: profile?.birthday,
        sex: profile?.sex,
        fullNameRu: profile?.isMe
            ? profile?.name
            : profile?.fullNameRu ??
            `${profile?.surname ?? ''} ${profile?.firstName ?? ''} ${profile?.middleName ?? ''}`.trim(),
        phones: (Array.isArray(profile?.phones) ? profile?.phones : [profile?.phones])?.filter?.(p => p),
        emails: (Array?.isArray(profile?.emails) ? profile?.emails : [profile?.emails])?.filter(e => e),
    }).reduce((acc, [key, value]) => {

        if (value) {

            acc[key] = value;

        }
        return acc;

    }, {});
    if (removeEmpty) {

        Object.entries(res).forEach(([key, value]) => {

            if (!value || Array.isArray(value) && value.length === 0) {

                delete res[key];

            }

        });

    }
    return res;

};

export const makeBooking = ({
    onError = () => {},
    onLoadingState = () => {},
    orderId,
    profile,
    selectedService,
    serviceId,
    visitInfo,
    program,
    assignmentId,
    onSuccess = () => {},
    consentReceived = false,
    manualConsent = false,
}) => {

    if (!profile && !orderId) {

        onError({
            message: 'profile not found',
            level: 'error',
            allowBooking: false,
        });
        return;

    }
    onLoadingState(true);

    const srvId = selectedService?.id ?? serviceId;

    const visitApi = getApi({
        serviceId: srvId,
        orderId,
        manual: profile?.manual,
    });

    const patientData = getPatientData(profile, true);
    const visitParams = {
        ...visitInfo,
        assignmentId,
        timeslotId: visitInfo?.timetableId,
        profileId: orderId ? visitInfo?.profileId : profile?.id,
        newPersonId: profile?.profileId ?? profile?.id,
        programId: program?.id || undefined,
        covererCode: visitInfo?.covererCode,
        serviceId: null,
        patientData,
    };

    const serviceParams = {
        ...visitParams,
        serviceId: selectedService?.id || serviceId,
        serviceName: selectedService?.name || visitInfo?.serviceName,
        slotDuration: selectedService?.duration,
        assignmentId,
    };


    if (profile?.manual && profile?.phone && isPhoneNumberValid(profile?.phone)) {

        const numbers = [{
            usage: 'PRIMARY',
            number: profile?.phone,
        }];
        if (serviceParams?.patientData?.emails?.length === 0) {

            delete serviceParams.patientData.emails;

        }
        if (visitParams?.patientData?.emails?.length === 0) {

            delete visitParams.patientData.emails;

        }
        serviceParams.patientData.phones = numbers;
        visitParams.patientData.phones = numbers;

    }

    if (profile?.manual && orderId && visitInfo?.profileId) {

        visitParams.profileId = visitInfo.profileId;
        serviceParams.profileId = visitInfo.profileId;

    }

    const apiParams =
        selectedService?.id || (serviceId && serviceId !== '0') || profile?.manual ? serviceParams : visitParams;
    if (consentReceived && manualConsent) {

        // apiParams.consentReceived = consentReceived;

    }
    api[visitApi](apiParams)
        .then(a => onSuccess(a))
        .catch(e => onError(e))
        .finally(() => onLoadingState(false));

};
