import { Footer, renderListItem } from '..';
import actions from '../../actions';
import {
    List,
    Page,
    React,
} from '../../common';
import { getEnums } from '../../i18n';
import { getMainScreenSettings } from '../../selectors';

export const MainScreenSettings = Page.register(
    ({
        navigation,
        setState,
        options = getEnums('mainScreenSettings'),
        mainScreenSettings,
        selectedOptions = mainScreenSettings || options.map(({ id }) => id),
        listItems = options.map(({
            id,
            name,
        }) => ({
            id,
            name,
            isSelected: selectedOptions.some(optionId => optionId === id),
        })),
        onOptionSelect = (id) => {
            const index = selectedOptions.indexOf(id);
            const result = [...selectedOptions];
            if (index >= 0) {
                result.splice(index, 1);
            } else {
                result.push(id);
            }
            setState({ selectedOptions: result });
        },
        cancel = () => navigation.goBack(),
        apply = () => {
            (async() => {
                await actions.setMainScreenSettings(selectedOptions);
                navigation.goBack();
            })();
        },
        footer = <Footer onCancel={cancel} onApply={apply}/>,
    }) => (
        <Page
            noscroll
            footer={footer}
            hint={'titles.mainScreenSettingsHint'}
        >
            <List
                data={listItems}
                renderItem={({
                    item: {
                        id,
                        name,
                        isSelected,
                    },
                }) => renderListItem(name, isSelected, () => onOptionSelect(id))}
            />
        </Page>
    ),
    { mainScreenSettings: { from: getMainScreenSettings } },
);
