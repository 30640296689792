import {
    BigButton, Logo, Page, React, Section,
} from '../../common';
import api from '../../services/api';
import { adaptErrorMessage } from './utils';

export const RelativeSmsSend = Page.register(({
    error,
    navigation,
    objectId,
    setState,
    isWaitingResponse = false,
    requestFamilyCode = async () => {
        try {
            if (objectId) {
                await api.sendRelativeSms(objectId);
                navigation.navigate('RelativeCodeConfirmation', { objectId });
                setState({ isWaitingResponse: false });
            }
        } catch (err) {
            setState({
                error: adaptErrorMessage(err),
                isWaitingResponse: false,
            });
        }
    },
    onSubmit = () => {
        if (!isWaitingResponse) {
            setState({ isWaitingResponse: true });
            requestFamilyCode();
        }
    },
}) => (
    <Page
        name="relative-sms-send"
        notification={error && {
            message: error.message,
            code: error.code || -1,
            onSubmit,
        }}
    >
        <Section centered>
            <Logo.Wrapper title="titles.relative_send_sms" subtitle="titles.relative_send_sms_description"
                titleStyle={{ paddingBottom: 12 }}/>
        </Section>
        <Section>
            <BigButton title="buttons.send" action={onSubmit} busy={isWaitingResponse} disabled={isWaitingResponse}/>
        </Section>
    </Page>
));

