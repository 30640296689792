/* eslint-disable no-underscore-dangle */
import actions, { navigate } from '../actions';
import { formatDateLong } from '../utils';
import { getFields } from '../i18n';
import { openAddress, openURL } from '../services/device';
import {
    FlatList, Platform, React, Text, TouchableWithoutFeedback, View,
} from './react';
import { infoList as styles } from './style';
import { Link } from './Button';
import { Subtitle, Title } from './elements';
import { Icon } from './Icon';
import { CoverableBy } from './Img';
import { NavigationItem } from './List';

const InfoDataTitle = ({ style, children, numberOfLines = 2 }) => (
    <Title style={[Platform.OS === 'ios' ? { lineHeight: 18 } : {}, style]} numberOfLines={numberOfLines}>
        {children}
    </Title>
);

export const onShowOnMap = (addressLink, address, _id, hasCoords) => {

    // if (Platform.OS === 'web') {
    //
    //     if (addressLink) {
    //
    //         openURL(addressLink);
    //
    //     } else {
    //
    //         openAddress(address);
    //
    //     }
    //
    // } else {
    //
    hasCoords ? navigate('MedCentersMap', { id: _id }) : Platform.OS === 'web' && addressLink ? openURL(addressLink) : openAddress(address);

    // }

};

const renderers = {
    address: (val, {
        data: {
            addressLink, address, _id, onlineConnectivity, latitude,
        } = {},
    }) => {

        const isFromMc = !!String(onlineConnectivity);
        const showOnMapHandler = () => {

            if (isFromMc) {

                onShowOnMap(addressLink, address, _id, !!latitude);

            } else {

                addressLink ? openURL(addressLink) : openAddress(addressLink);

            }

        };
        return (
            <View
                style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>
                <Icon.Geo color="#6684D7" size={16} />
                <Link
                    style={{ paddingHorizontal: 6 }}
                    href={showOnMapHandler}
                    localize={false}
                    trackingAlias="map"
                    underline>{`${val}`}</Link>
            </View>
        );

    },
    email: email => (
        <Link href={`mailto:${email}`} localize={false} trackingAlias="mail">
            {email}
        </Link>
    ),
    personalEmail: ({ address }, _, onItem) => (
        <NavigationItem
            noPadding
            wrapperStyle={{
                paddingVertical: 0,
                marginVertical: -13,
                marginLeft: 0,
            }}
            containerStyle={{
                paddingLeft: 0,
                paddingRight: 0,
                marginRight: 0,
                borderBottomWidth: 0,
                marginVertical: 0,
                paddingVertical: 0,
            }}
            title={<InfoDataTitle style={{ lineHeight: 15 }}>{address}</InfoDataTitle>}
            firstItem={false}
            onItem={onItem}
        />
    ),
    phone: val => (
        <Link href={() => actions.dial(val)} trackingAlias="tel_info">
            {val}
        </Link>
    ),
    text: val => <InfoDataTitle style={[Platform.OS === 'web' ? {} : { alignSelf: 'baseline' }]} numberOfLines={null}>{`${val}`}</InfoDataTitle>,
    enum: (val, item) => (
        <InfoDataTitle style={{ alignSelf: 'baseline' }} numberOfLines={null}>
            {Object.R(`${item.typeSpec}.${val}`)}
        </InfoDataTitle>
    ),
    date: val => (
        <InfoDataTitle style={{ alignSelf: 'baseline' }} numberOfLines={null}>
            {formatDateLong(val) || Object.R('titles.date_in_not_defined')}
        </InfoDataTitle>
    ),
    physicianLinked: (doctorInfo, param, cb = () => {}) => (
        <View>
            {!doctorInfo.id ? (
                <Title>{doctorInfo.fullName}</Title>
            ) : (
                <Link
                    href={() => {

                        cb(doctorInfo);
                        navigate('DoctorDetails', {
                            doctorInfo: doctorInfo?.doctor ? {
                                ...doctorInfo,
                                ...doctorInfo.doctor,
                            } : doctorInfo,
                        });

                    }}
                    trackingAlias="VisitPhysician">
                    {doctorInfo.fullName}
                </Link>
            )}
            {doctorInfo.specialization ? <Subtitle>{doctorInfo.specialization}</Subtitle> : null}
        </View>
    ),
    physician: doctorInfo => (
        <View>
            <InfoDataTitle>{doctorInfo.fullName}</InfoDataTitle>
            <Subtitle>{doctorInfo.specialization}</Subtitle>
        </View>
    ),
    profile: (profile, item) => {

        // noinspection JSUnusedAssignment
        let text = '';
        if (profile.isForChildren) {

            text = profile.maxAge
                ? Object.R('titles.childrenFromTo', {
                    from: profile.minAge,
                    to: profile.maxAge,
                })
                : Object.R('titles.childrenFrom', { from: profile.minAge });

        } else {

            text = Object.R('titles.adults');

        }
        let style = {};
        if (item.value.findIndex(e => e === profile) < item.value.length - 1) {

            text += ',';
            style = { marginBottom: -12 };

        }
        return <InfoDataTitle style={style}>{text}</InfoDataTitle>;

    },
    medcenterLinked: (mdInfo, params) => {

        return (
            <View>
                {mdInfo.hidden ||
                (!mdInfo.name && mdInfo.branchName) ||
                (!mdInfo?.id && !mdInfo?._id) ||
                mdInfo?.noLink ? (
                    <Title>{mdInfo.name || mdInfo.branchName}</Title>
                    ) : (
                        <Link
                            href={() => navigate('MedCenterDetails', {
                                mdInfo,
                                from: params?.data?.from,
                            })
                            }
                            trackingAlias="VisitMedcenter">
                            {mdInfo.name}
                        </Link>
                    )}
                <Subtitle>{mdInfo.address}</Subtitle>
            </View>
        );

    },
    medcenter: mdInfo => (
        <View>
            <InfoDataTitle>{mdInfo.name}</InfoDataTitle>
            <Subtitle>{mdInfo.address}</Subtitle>
        </View>
    ),
    medcenterWithDescription: mdInfo => (
        <View>
            <InfoDataTitle>{mdInfo.name}</InfoDataTitle>
            <Subtitle>{mdInfo.address}</Subtitle>
        </View>
    ),
    paymentMethod: ({ programName, coverer }) => (
        <View
            style={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
            <InfoDataTitle style={{ flex: 1 }} numberOfLines={null}>
                {programName}
            </InfoDataTitle>
            {coverer ? <CoverableBy coverer={coverer} /> : null}
        </View>
    ),
    programName: val => (
        <View
            style={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
            <InfoDataTitle style={{ flex: 1 }} numberOfLines={null}>
                {Object.R(`coverer.${val}`)}
            </InfoDataTitle>
            <CoverableBy coverer={val} />
        </View>
    ),
    service: val => (
        <InfoDataTitle style={{ alignSelf: 'baseline' }} numberOfLines={null}>
            {val}
        </InfoDataTitle>
    ),
    experience: (val) => {

        if (!val || (typeof val !== 'number' && typeof val !== 'string')) {

            return null;

        }
        if (typeof val === 'number') {

            if (val < 1) {

                return null;

            }

        }
        const v = String(val);
        let years = Object.R('years.other');
        if (v.endsWith('1') && !v.endsWith('11')) {

            years = Object.R('years.1');

        } else if ((v.endsWith('2') || v.endsWith('3') || v.endsWith('4')) && !v.endsWith('12')) {

            years = Object.R('years.234');

        }
        return (
            <InfoDataTitle style={{ alignSelf: 'baseline' }} numberOfLines={null}>
                {`${val} ${years}`}
            </InfoDataTitle>
        );

    },
};

const innerJoin = (fields = [], data = {}) => fields
    .filter(({ id }) => data[id] && (!Array.isArray(data[id]) || data[id].length))
    .map(field => ({
        ...field,
        data,
        value:
                typeof data[field.id] === 'object' && Object.keys(data[field.id])?.includes?.('0')
                    ? Object.values(data[field.id])
                    : data[field.id],
        onItemPress: data[field.id].onPress,
    }));

export const InfoList = ({
    fields,
    data,
    listHeaderText,
    listFooterText,
    itemStyle,
    titleStyle,
    rightTitleBlockStyle,
    filteredFields = [],
    style: stl,
    customRenderer,
    onItemClick = () => {},
}) => {

    const d = innerJoin(
        (typeof fields === 'string' ? getFields(fields) : fields).filter(
            el => !filteredFields.some(e => e === el.id),
        ),
        data,
    );

    return (
        <FlatList
            ListHeaderComponent={listHeaderText && <Text style={styles.listHeaderText}>{listHeaderText}</Text>}
            ListFooterComponent={listFooterText && <Text style={styles.listFooterText}>{listFooterText}</Text>}
            accessibilityLabel="info_list"
            keyExtractor={e => e.id}
            data={d}
            renderItem={({ item, index }) => {

                const {
                    title, value, onItemPress, type = 'text', required, typeSpec, onPress,
                } = item;

                const containerStyles = index < 1 ? [styles.listItem, styles.first] : styles.listItem;
                const renderer = renderers[type] ?? renderers.text;

                let dataLine;
                const isCustom = typeSpec === 'customRender' && customRenderer;
                if (isCustom) {

                    dataLine = customRenderer(item, required, title);

                } else {

                    const right = (Array.isArray(value) ? value : [value]).map((val, i) => {

                        return (
                            <View style={[styles.pb]} key={typeof val === 'object' ? i : val}>
                                {renderer(val, item, () => {

                                    onItemClick(i, val, item);
                                    onItemPress?.(item);
                                    onPress?.(item);

                                })}
                            </View>
                        );

                    });
                    dataLine = (
                        <View
                            style={[
                                itemStyle,
                                {
                                    flex: 1,
                                    flexDirection: 'row',
                                },
                            ]}>
                            <Text style={[styles.title, titleStyle]}>{`${title}:`}</Text>
                            <View style={[styles.rightTitleBlock, rightTitleBlockStyle]}>{right}</View>
                        </View>
                    );

                }

                return (
                    <View
                        style={[
                            containerStyles,
                            stl,
                            isCustom
                                ? {
                                    marginLeft: 0,
                                    paddingLeft: 0,
                                    marginRight: 0,
                                    paddingRight: 0,
                                    borderBottomWidth: 0,
                                    paddingTop: 0,
                                    marginTop: 0,
                                }
                                : {},
                        ]}
                        accessibilityLabel={`field_${index}`}>
                        {onItemPress || onPress ? (
                            <TouchableWithoutFeedback
                                onPress={() => {

                                    onItemPress?.();
                                    onPress?.();

                                }}>
                                {dataLine}
                            </TouchableWithoutFeedback>
                        ) : (
                            dataLine
                        )}
                    </View>
                );

            }}
        />
    );

};
