/* eslint-disable no-underscore-dangle */
import { StackActions } from '@react-navigation/native';
import thunk from 'redux-thunk';
import { applyMiddleware, combineReducers, createStore } from 'redux';
// reducers:
import drugs from './actions/drugs';
import user from './actions/user';
import visits from './actions/visits';
import prefs from './actions/prefs';
import selections from './actions/selections';
import visitInfo from './actions/visitInfo';
import visitEdit from './actions/visitEdit';
import visitView from './actions/visitView';
import feedbacks from './actions/feedbacks';
import ums from './actions/ums';
import invoices from './actions/invoices';
import fetching from './actions/fetching';
import tests from './actions/tests';
import pastVisits from './actions/pastVisits';
import forms from './actions/forms';
import log from './actions/logs';
import services from './actions/services';
import { SCHEME } from './scheme';

export { SCHEME };

// Redux middleware for action logging
const logging = store => next => (action) => {
    const result = next(action);
    // eslint-disable-next-line no-console
    console.log(`%cACTION:${action.type}:${action.path || ''}`, 'color: #9999FF', {
        get payload() {
            return action.payload || action.delta;
        },
        get state() {
            return store.getState();
        },
    });
    return result;
};

// db reducer
// eslint-disable-next-line max-statements
const dbReducer = (state = {}, {
    type, delta = {}, path, data = {},
}) => {
    if (type === 'SET') {
        let target = state;
        const steps = path.split('.');
        steps.slice(0, -1).forEach((e) => {
            target = target[e] || (target[e] = {});
        });
        target[steps[steps.length - 1]] = delta;
        return { ...state };
    }
    if (type === 'SET_ALL') {
        const target = state;
        Object.keys(delta).forEach((key) => {
            target[key] = { ...target[key], ...delta[key] };
        });
        return { ...target };
    }
    if (type === 'SET_RC_ITEM') {
        const target = state;
        Object.keys(delta).forEach((key) => {
            target[key] = delta[key];
        });
        return { ...target };
    }
    if (type === 'SET_COLLECTION') {
        let target = state;
        const steps = path.split('.');
        steps.slice(0, -1).forEach((e) => {
            target = target[e] || (target[e] = {});
        });
        target[steps[steps.length - 1]] = data;
        return { ...state };
    }
    return state;
};

const mainReducer = combineReducers({
    user,
    visitInfo,
    drugs,
    visits,
    prefs,
    selections,
    feedbacks,
    invoices,
    ums,
    db: dbReducer,
    fetching,
    visitEdit,
    visitView,
    tests,
    pastVisits,
    forms,
    log,
    services,
});

// store instance
const store = createStore(mainReducer, {}, applyMiddleware(thunk, logging));

// patch navigator to enable storing routing params into redux store
store.setNavigator = (_navigator) => {
    if (_navigator) {
        const _dispatch = _navigator.dispatch.bind(_navigator);
        store.navigator = Object.assign(_navigator, {
            ...StackActions,
            dispatch(ev) {
                return _dispatch(ev);
            },
        });
    }
};

/**
 * stores into db by path.
 * @param {*} path
 * @param {*} delta
 */

export const putIntoStore = (path, delta) => {
    store.dispatch({
        type: 'SET',
        delta,
        path,
    });
};
export const putAllIntoStore = (delta) => {
    store.dispatch({
        type: 'SET_ALL',
        delta,
    });
};
export const putRCItemIntoStore = (delta) => {
    store.dispatch({
        type: 'SET_RC_ITEM',
        delta,
    });
};

/**
 * Universal selector from db.
 * @param {*} path
 */
export const getFromStoreDb = path => path.split('.').reduce((r, e) => (r ? r[e] : r), store.getState().db);

export const getFromStore = path => path.split('.').reduce((r, e) => (r ? r[e] : r), store.getState());

export default store;
