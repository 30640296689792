import { someEmpty } from '../utils';
import { normSpecialtyString } from './utils';

const getDictionary = (list, getTitle) => {
    console.log('list: ', list);
    return list.reduce((dic, { name: word, id }) => {
        const title = getTitle(word)?.[0];
        const firstLetter = title[0]?.toUpperCase();
        if (!dic[firstLetter]) {
            dic[firstLetter] = [
                {
                    title,
                    id,
                    value: word,
                },
            ];
        } else {
            dic[firstLetter].push({
                title,
                id,
                value: word,
            });
        }
        return dic;
    }, {});
};

export const getDocSpecDictionary = (list = []) => {
    const dic = getDictionary(list, normSpecialtyString);
    const values = Object.values(dic)?.sort((a, b) => a[0]?.title?.charCodeAt(0) - b[0]?.title?.charCodeAt(0));
    const sorted = values.map((d) => ({
        data: d?.sort((a, b) => {
            const titleA = a.title?.toLowerCase();
            const titleB = b.title?.toLowerCase();

            if (titleA < titleB) {
                return -1;
            } else if (titleA > titleB) {
                return 1;
            }
            return 0;
        }),
    }));
    const indexOfRussianSpec = sorted?.findIndex((e) => /^[А-Яа-я]/.test(e.data[0]?.title[0]));
    const dictionary =
        indexOfRussianSpec > 0 ? [...sorted.slice(indexOfRussianSpec), ...sorted.slice(0, indexOfRussianSpec)] : sorted;
    const dictionaryArr = [];
    dictionary.forEach((e) =>
        dictionaryArr.push(
            ...e.data.map((item, i) =>
                i > 0
                    ? item
                    : {
                          ...item,
                          isFirst: true,
                      },
            ),
        ),
    );
    return dictionaryArr;
};

