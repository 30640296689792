
import {
    useCallback, useEffect, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import {
    dataFrom, dataTrigger, Icon, Page, React, Stub,
} from '../../common';
import actions, { back } from '../../actions';
import {
    actualSplitDoctors,
    getClinicsHasBumba,
    getGlobalLocation,
    getIsFavoriteDoctorsFilterActive,
    getIsPersonalized,
} from '../../selectors';
import { isEmpty } from '../../utils';
import { AnimatedHeaderList } from '../../common/AnimatedHeaderList';
// import firebase, { collections as collectiontToSubscribe, pureCollections } from '../../services/firebase';
// noinspection ES6PreferShortImport
import { getFormData } from '../../selectors/forms';
import { DoctorsListItem } from '..';
import { putIntoStore } from '../../store';
import { SCHEME } from '../../scheme';
import firebaseService from '../../services/firebase';
import { useNavHistory } from '../../utils/getRouteHistory';
import { ListHeader } from './DoctorsList';
import { fetchPhysiciansSplitData, useHasActualRelatedPrograms } from './utils';
import { doctorFilterParams } from './DoctorsSpecialtiesSearch';
import { getDoctorsBySpecPath, getSpecialitiesPath, useDoctorsDataExpirationHandler } from '../../hooks/useDoctorsDataExpirationHandler';

const NS = 'doctors';

const DoctorsListEmpty = () => {

    const isFilterActive = useSelector(getIsFavoriteDoctorsFilterActive);
    return !isEmpty(isFilterActive) ? <Stub.FavoriteDoctors /> : null;

};

export const DoctorsFavoritesIcon = dataTrigger(
    getIsPersonalized,
    dataFrom(getIsFavoriteDoctorsFilterActive)(({ data: isFavoritesFilterActive }) => (
        <Icon
            name={`bookmark-${!isEmpty(isFavoritesFilterActive) ? 'active' : 'normal'}`}
            color={'#fff'}
            onPress={() => {

                putIntoStore(SCHEME.PHYSICIANS_FILTER_FAVORITES, isEmpty(isFavoritesFilterActive));
                actions.updateDocFilters({ filterFavoriteDoctors: isEmpty(isFavoritesFilterActive) });

            }}
        />
    )),
);

const renderItem =
    (source, keywordSource) => ({ item }) => <DoctorsListItem doctorInfo={item} key={item.id} hasTimetableSource={source} keywordSelector={keywordSource} />;

const DataList = ({ headerHeight, dataSource = actualSplitDoctors, from, mdInfo }) => {

    const filter = doctorFilterParams[from] ?? doctorFilterParams.default;
    const hasTimetableSource = filter?.hasTimetableSource ?? doctorFilterParams.default.hasTimetableSource;
    const keywordSource = filter.physicianSearch ?? doctorFilterParams.default.physicianSearch;
    const doctorFilter = useSelector(filter.filter);
    const data = useSelector(dataSource);
    const { bumbaBanner } = useSelector(getFormData);
    const hasBumba = useSelector(getClinicsHasBumba);
    const firstLoading = useRef(0);
    const location = useSelector(getGlobalLocation);
    const redirect = useRef('');

    const nav = useNavigation();
    const history = useNavHistory();

    useFocusEffect(() => {

        if (redirect.current) {

            if (redirect.current === 'Home') {

                nav.navigate('Home');
                return;

            }
            if (history?.length > 1 && history.slice(0, 1).find(i => i.startsWith('DoctorsSpecialtiesSearch'))) {

                nav.goBack();

            } else {

                nav.navigate(redirect.current, { from, mdInfo });
                redirect.current = '';

            }

        }

    });

    useEffect(() => {

        if (firstLoading.current === 0) {

            firstLoading.current = location;

        } else {

            firstLoading.current = location;
            putIntoStore(filter.scheme, {
                ...doctorFilter,
                time: '0',
                dates: {},
                specialty: '',
            });
            putIntoStore(filter.physiciansSearchScheme, undefined);
            putIntoStore(filter.specializationForApi, undefined);
            redirect.current = `DoctorsSpecialtiesSearch${filter.postfix}`;
            if (from === 'popular') {

                redirect.current = `Home`;

            }

        }

    }, [location]);

    return (
        <AnimatedHeaderList
            data={data}
            ListHeader={ListHeader}
            headerHeight={headerHeight + (bumbaBanner && hasBumba ? 72 : 0)}
            renderItem={renderItem(hasTimetableSource, keywordSource)}
            emptyComponentRenderer={DoctorsListEmpty}
            separateHeader={false}
            listHeaderProps={{ from, mdInfo }}
            keyboardShouldPersistTaps={'handled'}
            // onRefresh={fetchDoctorsSums}
        />
    );

};

export const DoctorsListGeneral = Page.register(({ navigation, ...params }) => {

    const [filterParams, setFilterParams] = useState(doctorFilterParams[params?.from] ?? doctorFilterParams.default);
    const hasPrograms = useHasActualRelatedPrograms();
    // const filterParams = ;
    const info = useSelector(filterParams.getLocationAndSpec);
    const hasData = useSelector(filterParams.hasDataSource);
    const hasSpec = useSelector(filterParams.hasSpecialityDataForLocation);
    const filterData = useSelector(filterParams.filter);

    const updateSpec = () => {

        actions.syncSpecialitiesInfo(info.location);

    };

    useEffect(() => {

        if (!hasSpec && info.location) {

            updateSpec();
            return;

        }
        if (info?.location && info.specialtyId && !hasData && hasSpec) {

            actions.syncDoctors(info.location, info.specialtyId);

        }

    }, [info?.location, info.specialtyId, hasData, hasSpec, filterData]);

    useDoctorsDataExpirationHandler(
        getSpecialitiesPath(info?.location),
        updateSpec,
    );

    useDoctorsDataExpirationHandler(getDoctorsBySpecPath(info?.location, info.specialtyId), () => actions.syncDoctors(info.location, info.specialtyId));

    fetchPhysiciansSplitData(info.location, info.specialty, hasData);
    useFocusEffect(
        useCallback(() => {

            actions.setFormValue('umsServices', []);
            setFilterParams(doctorFilterParams[params?.from] ?? doctorFilterParams.default);

        }, []),
    );
    useEffect(() => {

        return () => {

            firebaseService?.unsubscribeFromSplitCollectionsChanges();
            firebaseService?.resetSplitCollections();

        };

    }, []);
    useEffect(() => {

        if (info.specialtyTitle) {

            navigation.setParams({ title: info.specialtyTitle });

        }

    }, [info.specialtyTitle]);

    useFocusEffect(() => {

        if (!info?.specialty && !params?.route?.params?.title) {

            back();

        }

    });

    return (
        <Page name={NS} noscroll>
            <DataList
                headerHeight={hasPrograms ? 180 : 110}
                dataSource={filterParams?.doctorDataSource}
                from={params?.from}
                mdInfo={params?.mdInfo}
            />
        </Page>
    );

});
