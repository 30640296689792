import { useEffect, useState } from 'react';
import { Text } from 'react-native';
import { getEnums } from '../i18n';
import { input } from '../styles/input';
import { isAdult, isFuture, testEmailRegex } from '../utils';
import { isPhoneNumberValid } from '../utils/phone';
import { checkBox as checkBoxStyles } from '../styles/common';
import { DateInput } from './DateInput';
import { React, TouchableOpacity, View } from './react';
import { HyperLink } from './Hyperlink';
import {
    Icon, Keyboard, Platform, selectOptions, SingleNavigation, TextInput,
} from '.';

const SIMPLE_PHONE_EDIT = true;

const Input = ({
    placeholder, value, onChange, error, checkValidation, id, ...props
}) => (
    <TextInput
        {...props}
        ns={id}
        placeholder={placeholder}
        style={input.infoText}
        onChange={onChange}
        invalid={error}
        value={value}
        onBlur={() => checkValidation(value)}
    />
);

Input.Email = ({
    placeholder, value, onChange, error, checkValidation, id,
}) => (
    <TextInput
        ns={id}
        placeholder={placeholder}
        style={input.infoText}
        onChange={onChange}
        invalid={error}
        onBlur={() => checkValidation(value)}
        value={value}
        keyboardType="email-address"
    />
);

Input.Date = DateInput;

Input.ChildDate = props => <DateInput {...props} child />;

const SelectorInput = (props) => {
    const {
        placeholder, value, typeSpec, checkValidation, onChange, editable = true,
    } = props;
    return (
        <SingleNavigation
            placeholder={placeholder}
            title={
                getEnums(`${typeSpec}`).find(e => e.id === value)
                    ? getEnums(`${typeSpec}`).find(e => e.id === value).name
                    : ''
            }
            style={{ marginBottom: 0 }}
            placeholderStyle={input.hint}
            textStyle={input.infoText}
            editable={editable}
            onPress={() => {
                Keyboard.dismiss();
                selectOptions({
                    title: Object.R(`titles.selection.${typeSpec}`),
                    selected: value,
                    data: getEnums(`${typeSpec}`),
                    // data: getEnums(`${id}`),
                    onSelect: ({ id: v }) => {
                        onChange(v);
                        checkValidation(v);
                    },
                });
            }}
        />
    );
};

export const CheckboxWithoutText = ({
    onPress, selected, ns = 'checkbox', style,
}) => (
    <TouchableOpacity
        onPress={onPress}
        style={checkBoxStyles.container}
        accessibilityLabel={ns}
        onStartShouldSetResponderCapture={() => true}>
        <View style={[checkBoxStyles.iconWrapper, selected && checkBoxStyles.iconWrapperActive, style]}>
            {selected && <Icon name="check" size={14} color="#5C85DD" />}
        </View>
    </TouchableOpacity>
);

const AgreementInput = ({
    typeSpec, value, checkValidation, onChange,
}) => {
    const [v, setV] = useState(value);
    const extractLinks = Object.R(typeSpec);
    const checkBoxHandler = () => {
        onChange(!v);
        checkValidation(!v);
        setV(prevState => !prevState);
    };

    return (
        <>
            <Text style={{ alignItems: 'center' }}>
                <CheckboxWithoutText selected={v} onPress={checkBoxHandler} style={{ marginRight: 10 }} />
                <HyperLink title={extractLinks} />
            </Text>
        </>
    );
};

const formatInput = (text) => {
    const cleanedText = `${text}`.replace(/[^0-9]/g, ''); // remove all non-numeric characters
    const inputLength = cleanedText.length;

    if (inputLength <= 2) {
        return cleanedText;
    }

    if (inputLength <= 5) {
        return `${cleanedText.slice(0, 2)} ${cleanedText.slice(2)}`;
    }

    if (inputLength <= 7) {
        return `${cleanedText.slice(0, 2)} ${cleanedText.slice(2, 5)}-${cleanedText.slice(5)}`;
    }

    return `${cleanedText.slice(0, 2)} ${cleanedText.slice(2, 5)}-${cleanedText.slice(5, 7)}-${cleanedText.slice(7)}`;
};

export const SimplePhoneInput = ({
    countryCode = '+375',
    maxLength = 13,
    onChangeText,
    onChange,
    value = '',
    ...props
}) => {
    // const [inputValue, setInputValue] = useState(countryCode + value);

    const handleChange = (val) => {
        const prefixedValue = `${countryCode}${val.substring(countryCode.length)}`;
        const formattedText = `+${prefixedValue.replace(/[^0-9]/g, '').substring(0, maxLength - 1)}`;

        // setInputValue(formattedText);
        onChangeText?.(formattedText);
        onChange?.(formattedText);
    };
    useEffect(() => {
        const val =
            value?.startsWith?.(countryCode) || value?.startsWith?.(countryCode?.trim?.())
                ? value
                : countryCode + value ?? '';
        handleChange(val);
    }, [value]);

    return (
        <TextInput
            value={value}
            onChangeText={handleChange}
            keyboardType="number-pad"
            maxLength={maxLength}
            {...props}
        />
    );
};

export const PhoneInput = ({
    countryCode = '+375', maxLength = 13, onChangeText, onChange, value = '', ...props
}) => {
    if (SIMPLE_PHONE_EDIT) {
        return (
            <SimplePhoneInput
                countryCode={countryCode}
                maxLength={maxLength}
                onChangeText={onChangeText}
                onChange={onChange}
                value={value}
                {...props}
            />
        );
    }
    const [inputValue, setInputValue] = useState(countryCode + value);

    const handleChange = (val) => {
        const prefixedValue = `${countryCode}${val.substring(countryCode.length)}`;
        const formattedText = `${countryCode} ${formatInput(prefixedValue.substring(countryCode.length))}`;

        setInputValue(formattedText);
        onChangeText?.(formattedText);
        onChange?.(`+${formattedText.replace(/[^0-9]/g, '')}`.substring(0, maxLength + 1));
    };

    useEffect(() => {
        const val =
            value?.startsWith(countryCode) || value?.startsWith(countryCode?.trim())
                ? value
                : countryCode + value ?? '';
        handleChange(val);
    }, [value]);

    return (
        <TextInput
            value={inputValue}
            onChangeText={handleChange}
            keyboardType="number-pad"
            maxLength={maxLength + countryCode.length}
            {...props}
        />
    );
};

const FIELD_TYPES = {
    email: Input.Email,
    date: Input.Date,
    childDate: Input.ChildDate,
    enum: SelectorInput,
    agreement: AgreementInput,
    phone: PhoneInput,
};

export const resolveFieldType = type => FIELD_TYPES[type] || Input;

const textRegexp = /^[ ]*[А-Яа-яA-Za-zЁёІіЎў'`‘’]{1,15}([-]{0,1}[А-Яа-яA-Za-zЁёІіЎў'`‘’]{1,15})?[ ]*$/;
// const dateRegexp = /^[0-9_-]{4}\/[0-9_-]{1,2}\/[0-9_-]{1,2}$/;
const dateRegexp = /^[0-9]{4}-[01]{1}[0-9]{1}-[0-3]{1}[0-9]{1}$/;
const dateReverseRegexp = /^[0-3]{1}[0-9]{1}.[01]{1}[0-9]{1}.[0-9]{4}$/;
const acodeRegexp = /^[0-9]{6}$/;

const VALIDATORS = {
    date: (v) => {
        if (Date.parse(v) && (!(Platform.OS === 'web') || dateRegexp.test(v))) {
            return !isAdult(v) || isFuture(v) ? Object.R('error.birthday_error') : null;
        }
        return Platform.OS === 'web' ? Object.R('error.date_format_error') : Object.R('error.format_error');
    },
    childDate: (v) => {
        if (Date.parse(v) && (!(Platform.OS === 'web') || dateRegexp.test(v))) {
            return isAdult(v) || isFuture(v) ? Object.R('error.child_birthday_error') : null;
        }
        return Platform.OS === 'web' ? Object.R('error.date_format_error') : Object.R('error.format_error');
    },
    pastDate: (v) => {
        if (Date.parse(v) && (!(Platform.OS === 'web') || dateRegexp.test(v))) {
            return isFuture(v) ? Object.R('error.birthday_error') : null;
        }
        return Platform.OS === 'web' ? Object.R('error.date_format_error') : Object.R('error.format_error');
    },
    pastDateReverse: (v) => {
        const val = v?.toISOString?.()?.substring?.(0, 10) ?? v;
        if (Date.parse(v) && (!(Platform.OS === 'web') || dateRegexp.test(val))) {
            return isFuture(val) ? Object.R('error.birthday_error') : null;
        }
        return Platform.OS === 'web' ? Object.R('error.date_format_error') : Object.R('error.format_error');
    },
    enum: v => (textRegexp.test(v) ? null : Object.R('error.format_error')),
    text: v => (textRegexp.test(v) ? null : Object.R('error.text_format_error')),
    email: v => (testEmailRegex(v) ? null : Object.R('error.email_format_error')),
    phone: v => (isPhoneNumberValid(v) ? null : Object.R('error.phone_format_error')),
    acode: v => (acodeRegexp.test(v) ? null : Object.R('error.wrongFormat')),
    agreement: v => (v ? null : Object.R('error.agreement')),
};
export const validateByFieldType = ({ type }, value) => {
    if (!value) {
        return null;
    }
    const validator = VALIDATORS[type];
    if (!validator) {
        return null;
    }
    return validator(value);
};

export const validateByFieldRequired = ({ required }, value) => {
    if (required === null || required === undefined) {
        return null;
    }
    return value ? null : Object.R('error.required_field');
};
