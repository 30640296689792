import EStyleSheet from 'react-native-extended-stylesheet';

export const pdfViewerStyles = EStyleSheet.create({
    header: {
        backgroundColor: 'white',
        borderBottomColor: '#E8E8E8',
        borderBottomWidth: 1,
        paddingVertical: 10,
        paddingHorizontal: 14,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    viewer: {
        flex: 1,
        padding: 12,
        backgroundColor: '$lightGrayBg',
    },
    container: {
        backgroundColor: '$lightGrayBg',
        flex: 1,
    },
    title: {
        flex: 1,
        marginRight: 14,
    },
    menuIcon: {
        padding: 0,
        marginRight: 0,
        marginTop: 0,
    },
});
