import React from 'react';
import { Provider } from 'react-redux';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import RootNavigation from '../routes';
import store from '../store';

/**
 * The Application component.
 *
 * It composes the store Provider and the navigation Root.
 */
export const Application = () => {
    return (
        <GestureHandlerRootView style={{ flex: 1 }}>
            <Provider store={store}>
                <RootNavigation store={store} />
            </Provider>
        </GestureHandlerRootView>
    );
};
