import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../../selectors';
import {
    BigButton,
    Button,
    ButtonsGroup,
    Keyboard,
    Modal,
    Page,
    TextInput,
    Title,
    TouchableWithoutFeedback,
    View,
} from '../../../common';
import { importChilds, importMyFamily } from '../../../services/user.utils';
import { COLORS } from '../../../styles/colors';

export const ImportMyFamilyModal = ({ onImport = () => {}, onCancel = () => {}, isChild = false }) => {
    const [text, setText] = useState('');
    const [data, setData] = useState('');

    return (
        <Modal hideClose>
            <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
                <View>
                    <Title.Bigger
                        id={isChild ? 'titles.importChildTitle' : 'titles.importMyFamilyTitle'}
                        style={{
                            marginTop: 8,
                            marginHorizontal: 12,
                            textAlign: 'center',
                            fontSize: 18,
                            fontFamily: 'SourceSansPro-Semibold',
                        }}
                    />
                    <View style={{ marginHorizontal: 10 }}>
                        <Title
                            id={'titles.importMyFamilyKey'}
                            numberOfLines={null}
                            style={{
                                marginTop: 20,
                                marginHorizontal: 2,
                            }}
                        />
                        <TextInput
                            placeholder="placeholders.importFamilyKey"
                            style={{
                                marginTop: 12,
                                fontSize: 16,
                                color: '#555555',
                            }}
                            onChange={(value) => {
                                setText(value);
                            }}
                            value={text}
                        />
                        <Title
                            id={'titles.importMyFamilyData'}
                            numberOfLines={null}
                            style={{
                                marginTop: 20,
                                marginHorizontal: 2,
                            }}
                        />
                        <TextInput
                            placeholder={'placeholders.importFamilyData'}
                            style={{
                                marginTop: 12,
                                fontSize: 16,
                                color: '#555555',
                            }}
                            onChange={(value) => {
                                setData(value);
                            }}
                            value={data}
                        />
                        <ButtonsGroup style={{ paddingHorizontal: 0 }}>
                            <BigButton
                                title="buttons.cancel"
                                action={() => {
                                    onCancel();
                                    Page.closeModal();
                                }}
                                styles={{ backgroundColor: 'transparent' }}
                                titleStyles={{ color: '#565656' }}
                            />
                            <BigButton
                                title={isChild ? 'buttons.shareChildImport' : 'buttons.shareMyFamilyImport'}
                                disabledStyle={{ backgroundColor: '#B8BED8' }}
                                disabled={!data || !text}
                                action={() => {
                                    onImport(text, data);
                                }}
                            />
                        </ButtonsGroup>
                    </View>
                </View>
            </TouchableWithoutFeedback>
        </Modal>
    );
};

export const ImportMyFamilyButton = () => {
    const user = useSelector(getUserInfo);
    const importFamilyButtonHandler = () => {
        Page.showModal(
            <>
                <ImportMyFamilyModal
                    onImport={(key, data) => {
                        importMyFamily(user?.hashKey, key, data);
                        Page.closeModal();
                    }}
                    isChild={false}
                />
            </>,
            () => {},
        );
    };

    return (
        <>
            <Button
                styles={[
                    {
                        borderWidth: 1,
                        borderColor: COLORS.BORDER_GRAY,
                        backgroundColor: COLORS.POSITIVE,
                        color: COLORS.LIGHT_GRAY,
                        padding: 4,
                    },
                ]}
                action={importFamilyButtonHandler}
                title={Object.R('titles.importMyFamily')}
            />
        </>
    );
};

export const ImportChildrenButton = () => {
    const user = useSelector(getUserInfo);
    const importChildrenButtonHandler = () => {
        Page.showModal(
            <>
                <ImportMyFamilyModal
                    onImport={(key, data) => {
                        importChilds(user?.hashKey, key, data);
                        Page.closeModal();
                    }}
                    isChild
                />
            </>,
            () => {},
        );
    };

    return (
        <>
            <Button
                styles={[
                    {
                        borderWidth: 1,
                        borderColor: COLORS.BORDER_GRAY,
                        backgroundColor: COLORS.POSITIVE,
                        color: COLORS.LIGHT_GRAY,
                        padding: 4,
                    },
                ]}
                action={importChildrenButtonHandler}
                title={Object.R('titles.importChildren')}
            />
        </>
    );
};
