import { Platform } from 'react-native';
import { getGlobalLocations, getLanguage } from '../i18n';
import {
    getCurrentLanguage, getGlobalLocation, getPopularQueries, getSupportedlanguages,
} from './getters';
import { createSelector, dbGettr } from './utils';

export const getCurrentLocation = createSelector(
    [
        getGlobalLocation,
        getGlobalLocations,
    ],
    (currentLocationCode = 17, locations = []) => locations.find(item => item.code === String(currentLocationCode) || item.code === currentLocationCode) || {},
);

export const getActualPopularQueries = createSelector(
    [
        getPopularQueries,
        getCurrentLocation,
    ],
    (queries = [], { banners } = {}) => queries.filter(item => item.type === 'drugs' || banners?.includes('doctors')) || {},
);

export const getActualLanguagesData = createSelector(
    [
        getCurrentLanguage,
        getSupportedlanguages,
    ],
    (lang = getLanguage(), languages = []) => {
        return {
            languages,
            selected: lang,
        };
    },
);

export const getMapsAppsConfig = createSelector(
    [dbGettr('mapsAppsConfig')],
    config => (config && Object.keys(config)
        .some(key => key === 'ios' || key === 'android')
        ? Platform.select({
            ios: config.ios,
            android: config.android,
        }) : config),
);
