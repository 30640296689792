import { useSelector } from 'react-redux';
import {
    dataFrom,
    Img,
    Link,
    Modal2,
    Page,
    React,
    selectOptions,
    SingleNavigation,
    Title,
    TouchableOpacity,
    View,
} from '../common';
import actions, { navigate } from '../actions';
import { getCurrentProgram, getHasOnlyChildProgram, getRelatedPrograms } from '../selectors';
import tracking from '../services/tracking';
import { programInfo } from './style';
import { AppHeaderExt } from './AppHeader';

const BadgeImgSources = {
    bns: <Img.BnsMarker />,
    'bns-lim': <Img.BnsMarkerLimited />,
    epam: <Img.EpamMarker />,
    'epam-lim': <Img.EpamMarkerLimited />,
};

const BadgeImg = ({
    coverer = '',
    isLimited,
}) => (
    <View style={programInfo.badgeImgContainer}>
        {BadgeImgSources[isLimited ? `${coverer}-lim` : `${coverer}`]}
    </View>
);

const ProgramInfo = ({
    coverer,
    program,
}) => (
    <View style={programInfo.servicesProgramInfoContent}>
        {coverer
            ? <View>
                <View style={{ flexDirection: 'row' }}>
                    <BadgeImg coverer={coverer} />
                    <Title style={programInfo.text} numberOfLines={3}>{Object.R('titles.programInfo')}</Title>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <BadgeImg coverer={coverer} isLimited />
                    <Title style={programInfo.text} numberOfLines={3}>{Object.R('titles.programInfoLimited')}</Title>
                </View>
                <Link
                    id="link.see_terms_of_program"
                    underline
                    href={() => {
                        navigate(coverer.includes('bns') ? 'BnsProgram' : 'EpamProgram', { program });
                    }}
                    style={{
                        margin: 8,
                        alignSelf: 'center',
                    }}
                />
            </View>
            : (
                <Title style={programInfo.singleText} numberOfLines={null}>
                    {Object.R('titles.withoutProgramInfoText')}
                </Title>
            )}
    </View>
);


const ProgramSelectorImpl = dataFrom(getCurrentProgram)(({
    // input:
    programs,
    onSelect = actions.setProgram,
    hasOnlyChildProgram,
    data: selected = programs.length ? ((hasOnlyChildProgram && programs.find(e => e.id !== '*')) || programs[0]) : {},
    // calculated:
    programsCount = programs.length,
    hasMany = programsCount > 1,
    onSelectWithReset = (program) => {
        onSelect(program);
        actions.resetServiceList(); // to avoid problems with services cache when changing program
    },
    navProps = {
        title: selected.name,
        showIndicator: selected.id !== '*',
        coverer: selected.coverer,
        hideIconRight: !hasMany,
        onPress: !hasMany ? null : () => selectOptions({
            title: 'titles.displayProgram',
            selected: selected.id,
            data: programs,
            onSelect: onSelectWithReset,
            hideClose: true,
        }),
    },
    withInfo,
}) => {
    if (!selected || !selected.id || (!hasMany && selected && selected.id === '*')) {
        if (!selected || !selected.id) {
            actions.setProgram(programs[0]);
        }
        return null;
    }
    return (
        <AppHeaderExt>
            <View style={{
                flexDirection: 'row',
                paddingVertical: 0,
                marginVertical: 0,
            }}>
                {withInfo
                    ? (
                        <TouchableOpacity
                            style={programInfo.programInfoButton}
                            onPress={() => {
                                Page.showModal((
                                    <Modal2
                                        title={selected.coverer ? Object.R('titles.yourProgram') : Object.R('corporatePrograms.no')}>
                                        <ProgramInfo coverer={selected.coverer} program={selected} />
                                    </Modal2>
                                ));
                                tracking.logEvent(`ai_btn_program_info`);
                            }}
                        >
                            <Img.InfoIcon style={{
                                margin: 0,
                                padding: 0,
                            }} size={25} />
                        </TouchableOpacity>
                    ) : null}
                <SingleNavigation style={{ flex: 1 }} {...navProps} />
            </View>
        </AppHeaderExt>);
});

export const ProgramSelector = dataFrom(getRelatedPrograms)(({
    data = {},
    onSelect,
    programs = Object.values(data),
    withInfo,
}) => {
    const hasOnlyChildProgram = useSelector(getHasOnlyChildProgram);
    return !programs.length ? null : (<ProgramSelectorImpl programs={programs} onSelect={onSelect} withInfo={withInfo}
        hasOnlyChildProgram={hasOnlyChildProgram} />);
});
