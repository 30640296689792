import React, { useEffect } from 'react';
// import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { Provider } from 'react-redux';
import RootNavigation from '../routes';
import store from '../store';
import { MODE } from '../config';
import { Platform } from '../common';

/**
 * The Application component.
 *
 * It composes the store Provider and the navigation Root.
 */
export const Application = () => {
    useEffect(() => {
        try {
            if (MODE === 'qa' && Platform.OS === 'ios') {
                // connectToRemoteDebugger();
            }
        } catch {
            //
        }
    }, []);
    return (
        <Provider store={store}>
            <RootNavigation store={store} />
        </Provider>
    );
};
