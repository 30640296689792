/* eslint-disable no-use-before-define */
const today = 'сегодня';
const tomorrow = 'завтра';
const pad = (x, size = 2) => {
    let s = String(x);
    while (s.length < size) {
        s = `0${s}`;
    }

    return s;
};
let currentYear = new Date().getFullYear();

export const isCurrentYear = date => new Date(date).getFullYear() === new Date().getFullYear();

let childYear = new Date().getFullYear();

export const years = Array.from({ length: 100 }, () => `${currentYear--}`);

export const childYears = Array.from({ length: 19 }, () => `${childYear--}`);

export const dateLocales = {
    ru: {
        monthNames: [
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Май',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
            'Октябрь',
            'Ноябрь',
            'Декабрь',
        ],
        monthNamesShort: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июня', 'Июля', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
        monthNamesDeclination: [
            'Января',
            'Февраля',
            'Марта',
            'Апреля',
            'Мая',
            'Июня',
            'Июля',
            'Августа',
            'Сентября',
            'Октября',
            'Ноября',
            'Декабря',
        ],
        dayNames: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
        dayNamesShort: ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'],
        bookMonthNamesShort: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
    },
};
export const months = dateLocales.ru.monthNamesShort;

export const getMonthName = (d, mode = '') => {
    if (!d) {
        return '';
    }
    const isValidMode = dateLocales.ru[`monthNames${mode}`];

    return isValidMode ? isValidMode[d.getMonth()] : dateLocales.ru.monthNames[d.getMonth()];
};

/* eslint-disable complexity, no-param-reassign */

export function parseISO8601String(x) {
    if (typeof x !== 'string') {
        throw new Error(`parseISO8601String: not a string: ${x}`);
    }
    if (x.length < 11) {
        x += 'T12:00';
    }
    const timebits =
        /^([0-9]{4})-([0-9]{2})-([0-9]{2})[ T]([0-9]{2}):([0-9]{2})(?::([0-9]*)(\.[0-9]*)?)?Z?(?:([+-])([0-9]{2})([0-9]{2}))?/;
    const m = timebits.exec(`${x}`);
    if (!m) {
        return null;
    }
    // utcdate is milliseconds since the epoch
    const utcdate = Date.UTC(
        parseInt(m[1]),
        parseInt(m[2]) - 1, // months are zero-offset (!)
        parseInt(m[3]),
        parseInt(m[4]),
        parseInt(m[5]), // hh:mm
        (m[6] && parseInt(m[6])) || 0, // optional seconds
        (m[7] && parseFloat(m[7])) || 0,
    );
    // optional timezone offset
    if (m[9] && m[10]) {
        const offsetMinutes = parseInt(m[9]) * 60 + parseInt(m[10]);
        return new Date(utcdate + (m[8] === '+' ? -60000 : +60000) * offsetMinutes);
    }
    return new Date(utcdate);
}

/**
 * Universal all-weather converter to Date.
 *
 * @param {*} x any value to be converted to date
 * @returns Date instance or null
 */
export const toDate = (x) => {
    const type = typeof x;
    if (x == null) {
        return null;
    }
    if (type === 'number') {
        return new Date(x);
    }
    if (type === 'object') {
        // Date-like
        if (x.getTime) {
            return x;
        }
        // firestore.Timestamp
        if (x.toDate) {
            return x.toDate();
        }
        // firestore timestamp for web
        if (x.seconds && x.nanoseconds != null) {
            return new Date(x.seconds * 1000 + x.nanoseconds);
        }
    }
    return parseISO8601String(x);
};

export const toDateTZ = (d, resetTime = false) => {
    const dt = toDate(d);
    try {
        const result = `${dt.getFullYear().toString().padStart(4, '0')}-${(dt.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${dt.getDate().toString().padStart(2, '0')}`;
        const resultDate = new Date(result);
        resultDate.setUTCHours(resetTime ? 0 : dt.getHours());
        resultDate.setUTCMinutes(resetTime ? 0 : dt.getMinutes());
        resultDate.setUTCSeconds(resetTime ? 0 : dt.getSeconds());
        return resultDate;
    } catch (e) {
        return dt;
    }
};

/**
 * Checks if given to-date-convertable lies in the future.
 * @param {*} date to-date value
 * @param withoutTime
 */
export const isFutureVisit = (date, withoutTime = false) => {
    if (withoutTime) {
        const current = new Date();
        const d = toDate(date);
        current.setHours(0, 0, 0, 0);
        d.setHours(0, 0, 0, 0);
        return d >= current;
    }
    return date ? toDate(date).getTime() >= Date.now() - 1000 : false;
};

// return date in format dd.mm.yyyy
export const formatDate = (x) => {
    if (!x) {
        return '';
    }
    try {
        const date = toDate(x);
        const day = date.getDate();
        const dayWithZero = day < 10 ? `0${day}` : day;
        const month = date.getMonth() + 1;
        const monthWithZero = month < 10 ? `0${month}` : month;
        const year = date.getFullYear();
        return `${dayWithZero}.${monthWithZero}.${year}`;
    } catch {
        return '';
    }
};

export const formatDateTz = (x, tz) => {
    if (!x) {
        return '';
    }
    try {
        const date = tz ? adjustTimeZone(toDate(x), tz) : toDate(x);
        // const date = adjustTimeZone(toDate(x), tz);
        const day = date.getDate();
        const dayWithZero = day < 10 ? `0${day}` : day;
        const month = date.getMonth() + 1;
        const monthWithZero = month < 10 ? `0${month}` : month;
        const year = date.getFullYear();
        return `${dayWithZero}.${monthWithZero}.${year}`;
    } catch {
        return '';
    }
};

export const toMySQLString = (dateTime) => {
    const year = dateTime?.getFullYear?.() ?? 0;
    const month = String((dateTime?.getMonth?.() ?? 0) + 1).padStart(2, '0');
    const day = String(dateTime?.getDate?.() ?? 1).padStart(2, '0');

    return `${year}-${month}-${day}`;
};

// return date in format yyyy-mm-dd
export const formatFullDate = (x, withTimezone = false) => {
    if (!x) {
        return '';
    }
    if (x instanceof Date && withTimezone) {
        if (withTimezone) {
            const time = x.getTime() + x.getTimezoneOffset() * 60 * 1000;
            const date = new Date(time);
            return date.toISOString().slice(0, 10);
        }
    }

    const date = toDate(x);
    return date.toISOString().slice(0, 10);
};

export const formatDateLong = (x, withTime, tz, withTimezone) => {
    if (!x) {
        return '';
    }
    const date = toDate(x);
    const visitYear = date.getFullYear();
    const day = date.getDate();
    const monthName = getMonthName(date, 'Short');
    const str = `${day} ${monthName} ${visitYear}`;
    return withTime ? [`${str}`, formatTime(date, tz, withTimezone)].join(' в ') : str;
};

export const formatDateShort = (x, withYear = false) => {
    if (!x) {
        return '';
    }
    const date = toDate(x);
    const year = withYear ? ` ${date.getFullYear()}` : '';
    return todayOrTomorrow(date) || `${date.getDate()} ${getMonthName(date, 'Declination')}${year}`;
};

export const sinceDateAndMonth = (x) => {
    if (!x) {
        return null;
    }
    const date = toDate(x);
    const monthName = dateLocales.ru.monthNamesDeclination[date.getMonth()].toLowerCase();
    return todayOrTomorrow(date) || `C ${date.getDate()} ${monthName}`;
};

export const todayOrTomorrow = (date) => {
    if (!date) {
        return null;
    }
    const now = new Date();
    if (
        date.getFullYear() === now.getFullYear() &&
        date.getMonth() === now.getMonth() &&
        date.getDate() === now.getDate()
    ) {
        return today;
    }
    const tomorrowDate = new Date(new Date().setDate(now.getDate() + 1));
    if (
        date.getFullYear() === tomorrowDate.getFullYear() &&
        date.getMonth() === tomorrowDate.getMonth() &&
        date.getDate() === tomorrowDate.getDate()
    ) {
        return tomorrow;
    }
    return null;
};

export const isToday = (date) => {
    if (!date) {
        return false;
    }
    const now = new Date();
    return (
        date.getFullYear() === now.getFullYear() &&
        date.getMonth() === now.getMonth() &&
        date.getDate() === now.getDate()
    );
};

export const isDayNotPast = (date) => {
    if (!date) {
        return '';
    }
    const now = adjustTimeZone(new Date(), 180);
    return (
        date.getFullYear() > now.getFullYear() ||
        (date.getFullYear() === now.getFullYear() &&
            (date.getMonth() > now.getMonth() ||
                (date.getMonth() === now.getMonth() && date.getDate() >= now.getDate())))
    );
};

export const formatTime = (x, tz, withTimezone) => {
    if (!x) {
        return '';
    }
    try {
        const date = adjustTimeZone(toDate(x), tz);
        const minutes = date.getMinutes();
        return `${date.getHours()}:${pad(minutes)} ${withTimezone ? formatTimezone(tz) : ''}`.trim();
    } catch {
        return '';
    }
};

export const MinskTimeZoneOffsetMinutes = 3 * 60;

export const getTimeZoneDiffMinutes = tz => (tz ? Number(tz) + new Date().getTimezoneOffset() : null);

export const formatTimezone = (tzOffset) => {
    const toNumber = Number(tzOffset);
    return toNumber
        ? `(GMT ${
              toNumber >= 0
                  ? `+${pad(toNumber / 60)}:${pad(toNumber % 60)}`
                  : `-${pad(-toNumber / 60)}:${pad(-toNumber % 60)}`
          })`
        : null;
};

export const adjustTimeZone = (d, tz) => {
    try {
        const diff = tz + d.getTimezoneOffset();
        if (diff) {
            return new Date(d.getTime() + diff * 60 * 1000);
        }
        return d;
    } catch {
        return d;
    }
};

// yyyy-mm-dd day with zero, toISOString works wrong in some cases
export const getIsoFormattedDate = (adjastedDate) => {
    const day = adjastedDate.getDate() > 9 ? adjastedDate.getDate() : `0${adjastedDate.getDate()}`;
    const month = adjastedDate.getMonth() + 1 > 9 ? adjastedDate.getMonth() + 1 : `0${adjastedDate.getMonth() + 1}`;
    return `${adjastedDate.getFullYear()}-${month}-${day}`;
};

export const getNewAdjustedDate = (...date) => {
    if (date && date.length === 1) {
        return adjustTimeZone(new Date(...date), 0);
    }
    if (date && date.length > 1) {
        return new Date(...date);
    }
    return adjustTimeZone(new Date(), 180);
};

export const formatTimeslots = (timeslots = [], timezone) => {
    const format = {};
    timeslots
        .sort((a, b) => a.startDate - b.startDate)
        .map(item => ({
            ...item,
            adjustedStartDate: adjustTimeZone(item.startDate, timezone),
        }))
        .forEach(({ id, startDate, adjustedStartDate }) => {
            const dateStr = getIsoFormattedDate(new Date(adjustedStartDate));

            if (format[dateStr]) {
                if (isGreaterThanToday(adjustedStartDate)) {
                    format[dateStr][timeOfDay(adjustedStartDate)].push({
                        startDate,
                        id,
                        adjustedStartDate,
                    });
                }
            } else {
                format[dateStr] = [[], [], []];
                if (isGreaterThanToday(adjustedStartDate)) {
                    format[dateStr][timeOfDay(adjustedStartDate)].push({
                        startDate,
                        id,
                        adjustedStartDate,
                    });
                }
            }
        });
    Object.keys(format).map(item => (format[item].reduce((acc, elem) => acc + elem.length, 0) === 0 ? delete format[item] : null));

    return format;
};

const isGreaterThanToday = startDate => startDate > getNewAdjustedDate();

export const timeOfDay = (startDate) => {
    const time = startDate.getHours();
    if (time <= 12) {
        return 0;
    }
    if (time > 12 && time <= 16) {
        return 1;
    }
    if (time > 16) {
        return 2;
    }
    return 0;
};

export const isAvaliableOnWeek = (firsrTimeslot) => {
    const todayDate = getNewAdjustedDate();
    const createIsoFormattedDate = number => getIsoFormattedDate(getNewAdjustedDate(todayDate.getFullYear(), todayDate.getMonth(), number));
    const dayOfWeek = todayDate.getDay() !== 0 ? todayDate.getDay() : 7;
    const firstDay = todayDate.getDate() - (dayOfWeek - 1);
    const week = [];
    for (let i = firstDay; i < firstDay + 7; i++) {
        week.push(createIsoFormattedDate(i));
    }
    return week.includes(firsrTimeslot);
};

export const daysInMonth = (month, year) => (months.includes(month) && year
    ? Array.from({ length: new Date(Number(year), months.indexOf(month) + 1, 0).getDate() }, (v, k) => `${k + 1}`)
    : null);

export const getDefaultNearestDate = nearestDate => (!nearestDate ? Object.R('titles.at_registry') : sinceDateAndMonth(nearestDate));

export const parseToDdMmYyyy = (date) => {
    if (!date || typeof date === 'number') {
        return '';
    }
    return date.split('-').reverse().join('-');
};

export const parseToDdMonYyyy = (x, inDeclination = false, withTimezone = false) => {
    if (x instanceof Date) {
        if (withTimezone) {
            const time = x.getTime() + x.getTimezoneOffset() * 60 * 1000;
            x = new Date(time);
        }
        const day = x.getDate();
        const month = x.getMonth();
        const monthNames = inDeclination ? dateLocales.ru.monthNamesDeclination : dateLocales.ru.monthNamesShort;
        const monthName = monthNames[parseInt(month)];
        return `${day} ${monthName} ${x.getFullYear()}`;
    }

    const [year, month, day] = x.split('-');
    const monthName = dateLocales.ru.monthNamesShort[parseInt(month) - 1];
    return `${day} ${monthName} ${year}`;
};

const getAge = (dateString) => {
    const day = new Date();
    const birthDate = new Date(dateString);
    let age = day.getFullYear() - birthDate.getFullYear();
    const m = day.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && day.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};

export const isAdult = birthday => getAge(birthday) >= 18;
export const isFuture = birthday => getAge(birthday) < 0;

export const getNumberOfDaysBetween = (date1, date2) => {
    if (!date2) {
        return null;
    }
    const convDate1 = new Date(date1);
    const convDate2 = new Date(date2);
    const dayMS = 1000 * 60 * 60 * 24;
    const delta = convDate2.getTime() - convDate1.getTime();
    return Math.round(delta / dayMS);
};

export const logTimeFormat = (d, short = true) => {
    if (d === 0) {
        return '';
    }
    if (d) {
        return short ? new Date(d).toLocaleTimeString() : new Date(d).toLocaleString();
    }
    return d;
};

export const combineDates = (dateDate, dateTime, defaultStartOfDay = true) => {
    if (dateDate === null || typeof dateDate === 'undefined') {
        return null;
    }
    const year = dateDate.getFullYear();
    const month = dateDate.getMonth();
    const day = dateDate.getDate();

    let minutes = defaultStartOfDay ? 0 : 23;
    let hours = defaultStartOfDay ? 0 : 59;
    const seconds = defaultStartOfDay ? 0 : 59;
    if (dateTime) {
        hours = dateTime?.getHours();
        minutes = dateTime?.getMinutes();
        // seconds = dateTime?.getSeconds();
    }

    return new Date(year, month, day, hours, minutes, seconds);
};

export const isValidDate = (dd, mm, yyyy) => {
    if (mm > 12 || mm < 1) {
        return false;
    }
    if (yyyy < 1900 || yyyy > 2100) {
        return false;
    }
    const isLeapYear = (yyyy % 4 === 0 && yyyy % 100 !== 0) || yyyy % 400 === 0;
    if (mm === 2) {
        if (isLeapYear && dd > 29) {
            return false;
        }
        if (!isLeapYear && dd > 28) {
            return false;
        }
    } else if (mm < 0 || mm > 12) {
        return false;
    }
    if (dd < 0) {
        return false;
    }
    if ([1, 3, 5, 7, 8, 10, 12].includes(mm) && dd > 31) {
        return false;
    }
    return !([4, 6, 9, 11].includes(mm) && dd > 30);
};

export const isValidTime = (hh, mm) => {
    if (hh < 0 || hh > 23) {
        return false;
    }
    return !(mm < 0 || mm > 59);
};

export const isDateFuture = (d, zeroTimeIsEmpty = true, defaultLengthVisit = 60) => {
    try {
        const tz = 0; // new Date().getTimezoneOffset() * 60 * 1000;
        const now = Date.now() + tz;
        const dt = new Date(d);
        const hour = d.getHours();
        const minute = d.getMinutes();
        if (zeroTimeIsEmpty && hour === 0 && minute === 0) {
            dt.setHours(23);
            dt.setMinutes(59);
        }
        const checkedDate = dt.getTime() + defaultLengthVisit * 1000;
        return checkedDate >= now;
    } catch (e) {
        return false;
    }
};
export const isDatePast = (d) => {
    try {
        return new Date(d).getTime() <= Date.now() + 60 * 1000000;
    } catch {
        return false;
    }
};

export const parseTimeString = (timeString) => {
    try {
        timeString = timeString.trim();

        const timeFormats = [
            /^\d{1,2}:\d{1,2}:\d{2}$/, // '14:06:32' or '2:4:32'
            /^\d{1,2}:\d{1,2}$/, // '14:6' or '2:4'
            /^\d{1,2}:\d{1,2}[ ]?[APap][Mm]$/i, // '2:4pm' or '2:4 AM'
        ];

        for (let i = 0; i < timeFormats.length; i++) {
            const format = timeFormats[i];
            if (format.test(timeString)) {
                const currentDate = new Date();
                const timeParts = timeString.match(/\d{1,2}|\w{2}/gi);
                const hours = parseInt(timeParts[0]);
                const minutes = parseInt(timeParts[1]);

                if (
                    Number.isNaN(hours) ||
                    Number.isNaN(minutes) ||
                    hours < 0 ||
                    hours > 23 ||
                    minutes < 0 ||
                    minutes > 59
                ) {
                    return null;
                }

                if (timeParts[2]) {
                    const amPm = timeParts[2].toLowerCase();
                    if ((amPm === 'pm' || amPm === 'am') && (hours < 1 || hours > 12)) {
                        return null;
                    }
                    if (amPm === 'pm' && hours !== 12) {
                        currentDate.setHours(hours + 12);
                    } else if (amPm === 'am' && hours === 12) {
                        currentDate.setHours(0);
                    } else {
                        currentDate.setHours(hours);
                    }
                } else {
                    if (hours < 0 || hours > 23) {
                        return null;
                    }
                    currentDate.setHours(hours);
                }

                currentDate.setMinutes(minutes);
                currentDate.setSeconds(0);

                if (!Number.isNaN(currentDate)) {
                    return currentDate;
                }
            }
        }

        return null;
    } catch (error) {
        return null;
    }
};

export const isPeriodsIntersect = (period1Start, period1End, period2Start, period2End) => {
    if (typeof period1Start !== 'number') {
        period1Start = toDate(period1Start).getTime();
    }
    if (typeof period2Start !== 'number') {
        period2Start = toDate(period2Start).getTime();
    }
    if (typeof period1End !== 'number') {
        period1End = toDate(period1End).getTime();
    }
    if (typeof period2End !== 'number') {
        period2End = toDate(period2End).getTime();
    }
    return !(period1End < period2Start || period2End < period1Start);
};

// 22.12.1999
export const formatDateToDotsFormat = (inputDate) => {
    const date = new Date(inputDate);
    let day = date.getDate();
    let month = date.getMonth() + 1; // Month is zero-based, so add 1
    const year = date.getFullYear();
    if (day < 10) {
        day = `0${day}`;
    }
    if (month < 10) {
        month = `0${month}`;
    }
    return `${day}.${month}.${year}`;
};

// from 22.12.1999 to 22-12-1999
export const parseDateFromDotsToISO = (dateString) => {
    const parts = dateString.split('.');

    let day = parseInt(parts[0], 10);
    if (day < 10) {
        day = `0${day}`;
    }

    let month = parseInt(parts[1], 10);
    if (month < 10) {
        month = `0${month}`;
    }
    const year = parseInt(parts[2], 10);

    return `${year}-${month}-${day}`;
};
