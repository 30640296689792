/* eslint-disable react/no-unused-state */
import { PureComponent } from 'react';
import MapView from 'react-native-maps';
// import { Platform } from 'react-native';
import {
    Button, Icon, Img, Platform, React, Title, TouchableWithoutFeedback, View,
} from '../../common';
import { getCurrentPosition } from '../../services/device';
import actions from '../../actions';
// import { inRegion } from '../utils';
import { TabSwitch } from '../../common/TabSwitch';
import { modal as styles } from '../../common/style';
import { MapMarker } from './MapMarker';
import { colorScaleStyles, mapStyles, switchMapModalStyles } from './styles';
import { propsByScaleType } from './utils';

const ZOOM = 15;
const DELTA = Math.exp(Math.log(360) - ZOOM * Math.LN2);

const ColorScale = React.memo(
    ({
        maxValue,
        minValue,
        colors,
        onPress,
        unit,
        fixedValues,
        hasCorrectValues = fixedValues && fixedValues.length === colors.length,
    }) => (
        <TouchableWithoutFeedback onPress={onPress}>
            <View style={[colorScaleStyles.scale, { backgroundColor: colors[colors.length - 1] }]}>
                {colors.map((color, i) => (
                    <View
                        key={color}
                        style={[
                            colorScaleStyles.color,
                            { backgroundColor: color },
                            hasCorrectValues && colorScaleStyles.fixedValueWrapper,
                        ]}>
                        {hasCorrectValues ? <Title style={colorScaleStyles.valueText}>{fixedValues[i]}</Title> : null}
                    </View>
                ))}
                {hasCorrectValues ? null : (
                    <View style={colorScaleStyles.values}>
                        <Title style={colorScaleStyles.valueText}>{`${minValue} ${unit}`}</Title>
                        <Title style={colorScaleStyles.valueText}>{`${maxValue} ${unit}`}</Title>
                    </View>
                )}
            </View>
        </TouchableWithoutFeedback>
    ),
);

const SwitchMapModal = ({ mapType = 'standard', onChange, onClose }) => (
    <TouchableWithoutFeedback onPress={onClose}>
        <View style={switchMapModalStyles.switchWrapper}>
            <View style={[styles.container, switchMapModalStyles.switch]} accessibilityLabel="modal">
                <View style={switchMapModalStyles.header}>
                    <Title id="titles.layers" style={switchMapModalStyles.headerTitle} />
                    <Icon name="cross" onPress={onClose} style={switchMapModalStyles.headerCrossIcon} />
                </View>
                <TabSwitch
                    containerStyle={switchMapModalStyles.tabSwitch}
                    data={[
                        {
                            id: 'standard',
                            isActive: mapType === 'standard',
                            name: Object.R('titles.layersSwitchStandard'),
                        },
                        {
                            id: 'hybrid',
                            isActive: mapType === 'hybrid',
                            name: Object.R('titles.layersSwitchHybrid'),
                        },
                        {
                            id: 'satellite',
                            isActive: mapType === 'satellite',
                            name: Object.R('titles.layersSwitchSatellite'),
                        },
                    ]}
                    onChange={onChange}
                />
            </View>
        </View>
    </TouchableWithoutFeedback>
);

export class Map extends PureComponent {
    constructor(props) {
        super(props);
        const {
            data, scaleKey = 'totalPrice', allDrugs, drugs,
        } = this.props;
        const drugsLength = drugs?.length ?? 0;
        const values = allDrugs
            ? data.filter(item => item.drugs.length === drugsLength).map(item => +item[scaleKey])
            : data.map(item => +item[scaleKey]); // totalPrice
        this.maxValue = Math.max(...values);
        this.minValue = Math.min(...values);
        this.valueDelta = this.maxValue - this.minValue;
        this.state = {
            currentLongitudeDelta: DELTA / 2,
            currentLatitudeDelta: DELTA,
        };
    }

    navigateToUserLocation = async () => {
        const { coords: { latitude, longitude } } = await getCurrentPosition();
        this.toRegion({
            latitude,
            longitude,
        });
    };

    toRegion = ({
        latitude, longitude, latitudeDelta = DELTA, longitudeDelta = DELTA / 2,
    }) => {
        if (this.mapRef && latitude && longitude) {
            this.mapRef.animateToRegion({
                latitude,
                longitude,
                latitudeDelta,
                longitudeDelta,
            });
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps?.initialRegion?.longitude !== this?.props?.initialRegion?.longitude ||
            prevProps?.initialRegion?.latitude !== this?.props?.initialRegion?.latitude
        ) {
            this.toRegion(this.props.initialRegion);
        }
    }

    render() {
        const {
            data,
            mapType,
            onItem,
            initialRegion,
            scaleKey = 'totalPrice',
            toolTipKey,
            drugs,
            allDrugs,
            centerOnClick = false,
            item,
        } = this.props;
        const { isShowingLayersMenu } = this.state || {};
        const extraProps =
            Platform.OS === 'web'
                ? {
                    region: {
                        latitudeDelta: DELTA,
                        longitudeDelta: DELTA / 2,
                        ...initialRegion,
                    },
                }
                : {};

        const { getColorId, hideScale, toolTipTitleId } = propsByScaleType[scaleKey];
        return (
            <View style={mapStyles.mapWrapper}>
                <MapView
                    {...extraProps}
                    ref={(ref) => {
                        this.mapRef = ref;
                    }}
                    style={mapStyles.map}
                    initialRegion={{
                        latitudeDelta: DELTA,
                        longitudeDelta: DELTA / 2,
                        ...initialRegion,
                    }}
                    showsUserLocation
                    showsMyLocationButton={false}
                    showsScale={false}
                    showsCompass={false}
                    toolbarEnabled={false}
                    mapType={mapType}
                    onPress={(e) => {
                        if (!e.nativeEvent.action || e.nativeEvent.action !== 'marker-press') {
                            onItem(null);
                        }
                    }}
                    onRegionChangeComplete={region => this.setState({
                        currentLongitudeDelta: region.longitudeDelta,
                        currentLatitudeDelta: region.latitudeDelta,
                    })
                    }>
                    {data.map((e) => {
                        let color = getColorId(e[scaleKey], this.minValue, this.maxValue);
                        if (allDrugs === true && e.drugs.length < drugs.length) {
                            color = -1;
                        }
                        return (
                            <MapMarker
                                key={e.id}
                                pressHandler={(i) => {
                                    if (centerOnClick) {
                                        const toRegion = {
                                            ...i,
                                            latitudeDelta: this.state.currentLatitudeDelta,
                                            longitudeDelta: this.state.currentLongitudeDelta,
                                        };
                                        this.toRegion(toRegion);
                                    }
                                    // eslint-disable-next-line no-unused-expressions
                                    onItem && onItem(i);
                                }}
                                data={{
                                    ...e,
                                    isSelected: item?.id === e?.id,
                                }}
                                colorId={color}
                                scaleKey={scaleKey}
                                unit={propsByScaleType[scaleKey].unit}
                                nullTitle={Object.R(propsByScaleType[scaleKey].nullTitleId)}
                                toolTipKey={toolTipKey}
                                toolTipTitle={toolTipTitleId ? Object.R(toolTipTitleId) : ''}
                            />
                        );
                    })}
                </MapView>
                {isShowingLayersMenu ? (
                    <SwitchMapModal
                        onChange={actions.setMapType}
                        mapType={mapType}
                        onClose={() => this.setState({ isShowingLayersMenu: false })}
                    />
                ) : (
                    <View style={mapStyles.menuGroupButtons}>
                        <Button
                            ns="layers_button"
                            action={() => this.setState({ isShowingLayersMenu: true })}
                            styles={mapStyles.menuButton}>
                            <Img.Layers />
                        </Button>
                        <Button action={this.navigateToUserLocation} styles={mapStyles.menuButton}>
                            <Img.LocationSearching />
                        </Button>
                    </View>
                )}
                {isShowingLayersMenu || hideScale || this.valueDelta < 0.7 ? null : (
                    <ColorScale maxValue={this.maxValue} minValue={this.minValue} {...propsByScaleType[scaleKey]} />
                )}
            </View>
        );
    }
}
