import { Dimensions } from 'react-native';
import { WheelPicker as WheelPickerAndroid } from 'react-native-wheel-picker-android';
import {
    childYears, dateLocales, daysInMonth, months, toDate, years,
} from '../utils';
import {
    Box, Button, Component, Modal, Picker, Platform, React,
} from '.';

const grey = '#E9E9E9';
const darkGrey = '#B7B7B7';
const black = 'black';

const screenWidth = Dimensions.get('window').width;
// const screenWidthScale = PixelRatio.getPixelSizeForLayoutSize(screenWidth) / 1080;
const WheelPicker = ({
    data = [],
    onChange,
    selectedItem,
    isSmall,
    width,
    fontSize = 16,
}) => (Platform.OS === 'android' ? (
    <WheelPickerAndroid
        style={{
            width: screenWidth / 4,
            height: 200,
        }}
        onItemSelected={index => onChange(index)}
        isCurved={false}
        data={data}
        visibleItemCount={3}
        renderIndicator
        indicatorColor={grey}
        itemTextColor={darkGrey}
        itemTextSize={28}
        selectedItemTextSize={28}
        selectedItemTextColor={black}
        initPosition={selectedItem}
    />
) : (
    <Picker
        style={{
            // eslint-disable-next-line no-nested-ternary
            flex: width || (isSmall ? 0.7 : 1),
            height: 200,
        }}
        selectedValue={data[selectedItem]}
        onValueChange={(itemValue, itemIndex) => onChange(itemIndex)}
        itemStyle={{ fontSize }}>
        {data.map(e => (
            <Picker.Item label={`${e}`} value={e}/>
        ))}
    </Picker>
));

export class BirthdayPicker extends Component {
    constructor(props) {
        super(props);
        this.monthsData = months;
        this.yearsData = this.props.child ? childYears : years;

        const value = this.props.value ? toDate(this.props.value) : new Date();
        const [y, m, d] = [value.getFullYear(), value.getMonth(), value.getDate()];
        const month = this.monthsData.indexOf(dateLocales.ru.monthNamesShort[m]);
        const year = this.yearsData.indexOf(String(y));

        const daysData = this.getPickerArray(daysInMonth(dateLocales.ru.monthNamesShort[m], y));
        this.state = {
            day: daysData?.indexOf(d.toString()),
            month,
            year,
        };
    }

    onConfirm = (daysData) => {
        const {
            year,
            month,
            day,
        } = this.state;
        const date = new Date();
        date.setUTCFullYear(Number(this.yearsData[year]), month, daysData[day]);
        return this.props.onChange(date);
    };

    getPickerArray = array => (this.props.value ? array : ['', ...array]);

    render() {
        const {
            day,
            year,
            month,
        } = this.state;
        const daysData = this.getPickerArray(daysInMonth(this.monthsData[month], this.yearsData[year]));
        const onConfirm = () => this.onConfirm(daysData);
        const deviceWidth = Dimensions?.get('screen')?.width;
        return (
            <Modal title="titles.choose_date_of_birthday">
                <Box style={{
                    overflow: 'hidden',
                    padding: 0,
                    margin: 0,
                }} justify="space-between">
                    {deviceWidth <= 320 ? (
                        <>
                            <WheelPicker
                                data={daysData}
                                onChange={d => this.setState({ day: d })}
                                selectedItem={day}
                                isSmall
                                width={0.8}
                                fontSize={13}
                            />
                            <WheelPicker
                                data={this.monthsData}
                                onChange={m => this.setState({ month: m })}
                                selectedItem={month}
                                width={1}
                                fontSize={13}
                            />
                            <WheelPicker
                                data={this.yearsData}
                                onChange={y => this.setState({ year: y })}
                                selectedItem={year}
                                width={1}
                                fontSize={14}
                            />
                        </>
                    ) : (
                        <>
                            <WheelPicker
                                data={daysData}
                                onChange={d => this.setState({ day: d })}
                                selectedItem={day}
                                isSmall
                            />
                            <WheelPicker
                                data={this.monthsData}
                                onChange={m => this.setState({ month: m })}
                                selectedItem={month}
                            />
                            <WheelPicker
                                data={this.yearsData}
                                onChange={y => this.setState({ year: y })}
                                selectedItem={year}
                            />
                        </>
                    )}
                </Box>
                <Button
                    primary
                    title="buttons.confirm"
                    action={onConfirm}
                    disabled={year === '' || month === '' || day === ''}
                />
            </Modal>
        );
    }
}
