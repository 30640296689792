/* eslint-disable no-unused-vars */
import EStyleSheet from 'react-native-extended-stylesheet';
import { Dimensions } from 'react-native';
import { Platform } from '../../common';

const { width } = Dimensions.get('window');
const flexMaxWidthD = {
    maxWidth: width,
    flex: 1,
};
const borderColor = '#E9E9E9';
// noinspection JSUnusedLocalSymbols
const topBorder = {
    borderTopColor: borderColor,
    borderTopWidth: 1,
};
// noinspection JSUnusedLocalSymbols
const bottomBorder = {
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
};

// noinspection JSUnusedGlobalSymbols
export const umsMain = EStyleSheet.create({
    serviceListWrapper: { flex: 1 },
    serviceList: {
        flexDirection: 'row',
        backgroundColor: 'white',
    },
});

export const snippets = EStyleSheet.create({
    flw: { ...flexMaxWidthD },
    buttonLeft: {
        height: '100%',
        paddingHorizontal: 12,
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    servicesListItem: {
        ...flexMaxWidthD,
        paddingVertical: 14,
        paddingRight: 10,
        marginRight: 20,
        borderBottomColor: borderColor,
        borderBottomWidth: 1,
    },
    optionsListItemContainer: {
        ...flexMaxWidthD,
        backgroundColor: 'white',
        paddingTop: 14,
    },
    optionsListItem: {
        paddingRight: 30,
        paddingLeft: 20,
    },
    optionsListItemBorder: {
        marginTop: 14,
        borderBottomColor: borderColor,
        borderBottomWidth: 1,
    },
    serviceCard: {
        padding: 20,
        backgroundColor: '#FFFFFF',
    },
    serviceTitle: {
        color: '$darkGrayTextColor',
        fontFamily: '$sourceSansPropFontFamily',
        fontWeight: 'bold',
        fontSize: 16,
    },
    serviceCardText: {
        flex: 1,
        flexWrap: 'wrap',
        fontSize: 14,
        lineHeight: 14,
        textAlignVertical: 'center',
    },
    serviceNotification: { paddingTop: 16 },
    serviceOptions: {
        backgroundColor: '#FFFFFF',
        borderColor,
        borderBottomWidth: 1,
        paddingVertical: 8,
        justifyContent: 'center',
        textAlign: 'center',
    },
    serviceOptionsHeader: {
        backgroundColor: '#FFFFFF',
        paddingVertical: 12,
        paddingRight: 30,
        paddingLeft: 20,
        borderColor,
        borderBottomWidth: 1,
        borderTopWidth: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    serviceNotificationHeader: {
        fontSize: 18,
        color: '$darkGrayTextColor',
        fontFamily: '$sourceSansPropFontFamily',
        paddingLeft: 0,
    },
    optionText: { fontSize: 12 },
    serviceOptionText: {
        fontSize: 14,
        color: '#9C9C9C',
    },
    UmsListsHeaderContainer: { height: 62 },
    UmsListsHeaderText: {
        paddingLeft: 20,
        color: '#555555',
    },
});

export const adaptiveListHeader = EStyleSheet.create({
    titleFirst: {
        backgroundColor: '#E9E9E9',
        paddingVertical: 15,
        paddingLeft: 20,
    },
    titleSecond: {
        backgroundColor: '#DADADA',
        paddingVertical: 12,
        paddingLeft: 20,
    },
    titleArrow: { paddingRight: 30 },
    titleFirstText: {
        color: '#555555',
        fontFamily: '$sourceSansPropFontFamily_semibold',
        fontSize: 16,
    },
    titleSecondText: {
        color: '#555555',
        fontFamily: '$sourceSansPropFontFamily_semibold',
        fontSize: 14,
    },
});

// const IOS_MARGIN = 5;
// const ANDROID_MARGIN = 8;
const IOS_MARGIN = 0;
const ANDROID_MARGIN = 0;

export const searchByKeywordList = EStyleSheet.create({
    container: { flex: 1 },
    headerWrapper: {
        position: 'absolute',
        left: 0,
        right: 0,
        // eslint-disable-next-line no-nested-ternary
        marginRight: Platform.OS === 'web' ? 16 : Platform.OS === 'ios' ? IOS_MARGIN : ANDROID_MARGIN,
        // borderWidth: 1,
        // borderColor: 'red',
    },
});
