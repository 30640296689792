import RNFirebase from '@react-native-firebase/app';
import RNFirebaseAnalytics from '@react-native-firebase/analytics';
import RNFirebaseCrashlytics from '@react-native-firebase/crashlytics';
import RNFirebasePerf from '@react-native-firebase/perf';
import { Platform } from 'react-native';
import { isTestDevice } from 'react-native-firebase-test-lab';
import { cyrlat } from '../utils';
import { MODE } from '../config';

const prepareEventName = name => cyrlat(name).replace(/\W+/g, '_').slice(0, 31);

let isRunningFromTestLab = false;

function logEvent(eventName, params) {
    if (isRunningFromTestLab) {
        return;
    }
    (Platform.OS === 'web' ? RNFirebase.analytics : RNFirebaseAnalytics)().logEvent(eventName, params);
    // eslint-disable-next-line no-console
    console.log(`%cGA> ${eventName}`, 'color: #0000E0;background: #00AA00', params);
}

Object.trackNavigation = (routeName, params) => {
    logEvent(prepareEventName(`ai_page_${routeName}`), params);
};

Object.trackAction = (id, params = {}, action = 'ai_btn') => {
    if (isRunningFromTestLab) {
        return;
    }
    // to correctly handle 1) PopularQueries buttons from Home screen and
    // 2) sometimes button titles keys are in section 'buttons' and sometimes in
    // section 'titles' in i18n, but anyway we are skiping both for event name
    const dotIdx = (id || '')?.lastIndexOf?.('.') ?? 0;
    const eventId = dotIdx !== -1 ? id?.slice?.(dotIdx + 1) : id;
    const rawName = `${action}_${[params && params.trackingAlias, eventId].filter(Boolean).join('_')}`;
    const eventName = prepareEventName(rawName);
    // eslint-disable-next-line no-console
    // console.log(`Tracking ${eventName}`, {
    //     params,
    //     action,
    //     id,
    // });
    if (MODE === 'dev' && (eventName.includes('undefined') || eventName[eventName.length - 1] === '_')) {
        // eslint-disable-next-line no-console
        console.warn(`Check tracking name: ${eventName}. Probably event name or tracking alias is missing.`);
    }
    logEvent(eventName, params);
};

Object.trackError = (message, data, code = 0) => {
    const crashlytics = RNFirebaseCrashlytics();

    crashlytics.recordError(code, `${message} : ${data}`);
};

export default {
    async checkForTestLabRunning() {
        isRunningFromTestLab = await isTestDevice();
    },

    // eslint-disable-next-line consistent-return
    setUserProperties(user) {
        if (isRunningFromTestLab) {
            return Promise.resolve();
        }
        const { location = '', userType, me: { coverer = '', programName = '' } = {} } = user;

        const userProperties = {
            user_type: userType || 'REGULAR',
            user_city: location,
            user_programs: programName,
            user_programs_company: coverer,
            user_platform: Platform.OS,
        };
        try {
            const set = (Platform.OS === 'web' ? RNFirebase.analytics : RNFirebaseAnalytics)();
            // console.log('USER PROPERTIES', userProperties);
            return set.setUserProperties(userProperties);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log('Set user properties error', e);
        }
    },
    logEvent,
    startTrace(key) {
        if (isRunningFromTestLab) {
            return { stop() {} };
        }
        const perf = Platform.OS === 'web' ? RNFirebase.app().perf() : RNFirebasePerf();

        const trace = perf.newTrace(key);
        trace.start();
        return {
            stop() {
                trace.stop();
            },
        };
    },
    trace(key, fn) {
        // const start = Date.now();
        const trace = this.startTrace(key);
        try {
            fn();
        } finally {
            // console.warn(key, `in ${Date.now() - start}ms`);
            trace.stop();
        }
    },
};
