import clinics from './clinics.json';
import physicians from './physicians.json';
import ums from './ums.json';
import physicians_prices_sum from './physicians_prices_sum.json';

export default () =>
    ({
        clinics,
        physicians,
        physicians_prices_sum,
        ums,
        prefs: { location: 17 },
    });

