import { useDispatch, useSelector } from 'react-redux';
import { sortBy } from '../../../utils';
import {
    getAllFeedbacksMyFilter, getAllFeedbacksMySortOrder, getMyFeedbacksExistingPhysiciansApi, getMyFeedbacksPhysiciansModerationStatuses,
} from '../../../selectors';
import { setAllFeedbacksMyFilter, setAllFeedbacksMySortOrder } from '../../../actions/feedbacks';
import {
    Icon, Page, React, selectOptions, TabSelector, Text, TouchableOpacity, View,
} from '../../../common';
import { COLORS } from '../../../styles/colors';
import { allFeedbackSortOptions } from '../../../selectors/utils';
import tracking from '../../../services/tracking';
import { AllFeedbackSearch } from './AllFeedbackSearch';
import { useCallback, useEffect, useRef } from 'react';

const COUNTING_UNCONFIRMED = true;

export const MY_FILTER_ITEMS = [
    {
        id: 'published',
        name: 'titles.myFeedbacksFilterPublished',
        visible: true,
        order: 0,
        alwaysVisible: true,
    },
    {
        id: 'moderation',
        name: 'titles.myFeedbacksFilterModeration',
        visible: true,
        order: 10,
        filter: 'DOUBTFUL',
        moderationState: 'PENDING',
    },
    {
        id: 'declined',
        name: 'titles.myFeedbacksFilterDeclined',
        visible: true,
        order: 20,
        filter: 'REJECTED',
        moderationState: 'REJECTED',
        alwaysVisible: true,
    },
];

export const useMyFeedbacksFilter = () => {

    const activeFilter = useSelector(getAllFeedbacksMyFilter);
    const moderationStates = useSelector(getMyFeedbacksPhysiciansModerationStatuses);
    return {
        items: sortBy(
            MY_FILTER_ITEMS?.filter(({
                visible, id, alwaysVisible, filter, moderationState,
            }) => visible && (alwaysVisible || !id || moderationStates.includes(moderationState))),
            'order',
        ),
        activeFilter,
    };

};

export const AllFeedbackSortControl = ({ style }) => {

    const selectedSort = useSelector(getAllFeedbacksMySortOrder);
    const dispatch = useDispatch();
    const showSortModal = () => {

        const feedbackSort = allFeedbackSortOptions();
        const selectedSortFound = feedbackSort.find(({ id }) => selectedSort?.id && id === selectedSort?.id);
        const selectedSortId = selectedSort && selectedSortFound ? selectedSort.id : feedbackSort[0].id;
        selectOptions({
            title: 'titles.sort_list_of_doctor_feedbacks',
            selected: selectedSortId,
            data: feedbackSort,
            onSelect: (e) => {

                dispatch(setAllFeedbacksMySortOrder(e));
                tracking.logEvent(`ai_all_fb_doc_ls_${e?.id}`);
                Page.closeModal();

            },
        });

    };
    return <TouchableOpacity>
        <Icon.Sort
            onPress={() => {

                showSortModal();

            }}
            style={[{
                paddingTop: 0,
                marginTop: 0,
            }, style]}
        />
    </TouchableOpacity>;

};

export const MyFeedbacksSectionHeader = () => {

    return (
        <>
            <View
                style={{
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    padding: 18,
                    backgroundColor: COLORS.BLUE_LIGHT_GRAY,
                }}>
                <Text
                    style={{
                        fontSize: 14,
                        lineHeight: 14,
                        color: COLORS.BLUE_GRAY,
                    }}>
                    {Object.R('titles.allFeedbacksMyFeedbacks')}
                </Text>
            </View>
        </>
    );

};

export const AllFeedbacksMyFilterHeader = () => {

    const { activeFilter, items } = useMyFeedbacksFilter();
    const feedbacks = useSelector(getMyFeedbacksExistingPhysiciansApi);
    const dispatch = useDispatch();
    const i = useRef(items);

    const checkActiveTab = useCallback(() => {

        if (i.current?.find(({ id }) => id === activeFilter) === undefined) {

            dispatch(setAllFeedbacksMyFilter(items[0]?.id));

        }

    }, [activeFilter, dispatch]);

    useEffect(() => {

        setTimeout(checkActiveTab, 50);

    }, [items, activeFilter, checkActiveTab]);

    const getCount = useCallback(
        (active) => {

            const res = feedbacks?.filter?.(({ status, moderationState }) => (active?.moderationState ? (moderationState === active?.moderationState) : moderationState === undefined || moderationState === 'ACCEPTED' && (status === 'CONFIRMED' || (status === 'UNCONFIRMED' && COUNTING_UNCONFIRMED))));
            return res?.length ?? 0;

        },
        [feedbacks]
    );

    if (items?.length === 0) {

        return null;

    } else if (items?.length === 1) {

        if (activeFilter !== items[0]?.id) {

            dispatch(setAllFeedbacksMyFilter(items[0]?.id));
            return null;

        }

    }

    const itemSelectHandler = item => dispatch(setAllFeedbacksMyFilter(item?.id));


    return (
        <>
            <MyFeedbacksSectionHeader />
            <TabSelector items={items} onItem={itemSelectHandler} selected={activeFilter} withCount getItemCount={getCount} />
            <AllFeedbackSearch />
        </>
    );

};
