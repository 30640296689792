/**
 * Index of all application screens.
 * Auto-generated file. Do not edit.
 * @vendor https://epam.com
 */
export * from './Account/AccessCheck';
export * from './Account/AccountPending';
export * from './Account/AccountReset';
export * from './Account/AccountUserInfo';
export * from './Account/ChooseProgram';
export * from './Account/CodeConfirmation';
export * from './Account/Documents';
export * from './Account/DocumentsSlider';
export * from './Account/EditPage';
export * from './Account/Edits';
export * from './Account/NotificationsSettings';
export * from './Account/SignIn';
export * from './Account/Timer';
export * from './AnimatedComponents/Blinker';
export * from './AnimatedComponents/Examples';
export * from './AnimatedComponents/Opacitier';
export * from './AnimatedComponents/TestAdComponent';
export * from './App/About';
export * from './App/Home';
export * from './App/MyCard';
export * from './App/OutdatedAppVersion';
export * from './App/ViewImage';
export * from './App/WebPage';
export * from './Coverers/BnsProgram';
export * from './Coverers/BnsSignIn';
export * from './Coverers/EpamProgram';
export * from './Coverers/EpamSignIn';
export * from './Coverers/EpamAddRelativeHint';
export * from './Doctors/DoctorDetails';
export * from './Feedbacks/Doctors/DoctorFeedbacks';
export * from './Doctors/DoctorInfo';
export * from './Doctors/DoctorsList';
export * from './Doctors/DoctorsListGeneral';
export * from './Doctors/Filter';
export * from './Doctors/UMSDoctorsList';
export * from './Family/FamilyInviteConfirm';
export * from './Family/FamilyInvitePermission';
export * from './Family/FamilyJoin';
export * from './Family/Relatives';
export * from './Family/RelativeSmsSend';
export * from './Family/RelativeCodeConfirmation';
export * from './Family/RelativeGrants';
export * from './Feedbacks/MedCenters/AddFeedback';
export * from './Feedbacks/MedCenters/FeedbacksListItem';
export * from './Feedbacks/MedCenters/FeedbacksPage';
export * from './Invoices/InvoiceAdaptiveButton';
export * from './Invoices/InvoiceCard';
export * from './Invoices/InvoiceConfirmation';
export * from './Invoices/InvoiceMultiService';
export * from './Invoices/InvoiceNotification';
export * from './Invoices/InvoiceRejectModal';
export * from './Invoices/InvoiceReminderModal';
export * from './Invoices/InvoiceReviewedInfo';
export * from './Invoices/InvoiceSingleService';
export * from './Invoices/InvoicesList';
export * from './MedCenters/MedCenterDetails';
export * from './MedCenters/MedCenterFeedbacks';
export * from './MedCenters/MedCenterInfo';
export * from './MedCenters/MedCenterRecord';
export * from './MedCenters/MedCenterServices';
export * from './MedCenters/MedCentersList';
export * from './MedCentersMap/MedCentersMap';
export * from './Tabletka/DrugstoreDetails.banner';
export * from './Tabletka/DrugstoreDetails';
export * from './Tabletka/FirstAidKit';
export * from './Tabletka/SearchDrugstores';
export * from './Tabletka';
export * from './UMS/AdaptiveListHeader';
export * from './UMS/AnimatedFlatlist';
export * from './UMS/OptionsListItem';
export * from './UMS/SearchByKeywordList';
export * from './UMS/ServiceDetails';
export * from './UMS/UmsLists';
export * from './UMS/UmsMain';
export * from './UMS/UmsFilter';
export * from './Visits/Attachments';
export * from './Visits/DatePick';
export * from './Visits/EnrollVisit';
export * from './Visits/Visit';
export * from './Visits/VisitImagesSlider';
export * from './Visits/VisitListItem';
export * from './Visits/VisitsFilter';
export * from './Visits/VisitsList';
export * from './Visits/drugs';
export * from './PDFViewer/PDFViewer';
export * from './DoctorsMap/DoctorsMap';
export * from './Feedbacks/Doctors/AddDcotorFeedback';
export * from './Feedbacks/Doctors/DoctorFeedbaksPage';
