/* eslint-disable no-use-before-define */
import {
    NativeModules, PermissionsAndroid, Platform, processColor,
} from 'react-native';
import { toDate } from '../utils';

/* eslint-disable max-params */
export function createEvent(eventName = '', startDate, endDate, location, description, allDay = false) {
    presentEventCreatingDialog({
        title: eventName,
        startDate: toDate(startDate).toISOString(),
        endDate: toDate(endDate)?.toISOString(),
        location,
        notes: description,
        allDay,
        navigationBarIOS: {
            barTintColor: '#5C85DD',
            tintColor: 'white',
        },
    });
}

// noinspection CommaExpressionJS
const { WRITE_CALENDAR, READ_CALENDAR } = Platform.OS === 'web' ? ('', '') : PermissionsAndroid.PERMISSIONS;
const { AddCalendarEvent } = NativeModules;

const presentEventCreatingDialog = (options) => {
    const toCall = () => setTimeout(() => {
        // noinspection JSIgnoredPromiseFromCall
        AddCalendarEvent.presentEventCreatingDialog(processColorsIOS(options));
    }, 10);
    return withPermissionsCheck(toCall);
};

const processColorsIOS = (config) => {
    if (Platform.OS === 'android' || !config) {
        return config;
    }
    const { navigationBarIOS } = config;
    if (navigationBarIOS) {
        const { tintColor, backgroundColor, barTintColor } = navigationBarIOS;
        // noinspection JSValidateTypes
        navigationBarIOS.tintColor = tintColor && processColor(tintColor);
        // noinspection JSValidateTypes
        navigationBarIOS.backgroundColor = backgroundColor && processColor(backgroundColor);
        // noinspection JSValidateTypes
        navigationBarIOS.barTintColor = barTintColor && processColor(barTintColor);
    }
    return config;
};

const withPermissionsCheck = (toCallWhenPermissionGranted) => {
    if (Platform.OS === 'android') {
        return withPermissionsCheckAndroid(toCallWhenPermissionGranted);
    }
    return withPermissionsCheckIOS(toCallWhenPermissionGranted);
};

const permissionNotGranted = 'permissionNotGranted';

const withPermissionsCheckAndroid = async (toCallWhenPermissionGranted) => {
    // eslint-disable-next-line no-console
    console.log('check android perm');
    // it seems unnecessary to check first, but if permission is manually disabled
    // the PermissionsAndroid.request will return granted (a RN bug?)
    const [hasWritePermission, hasReadPermission] = await Promise.all([
        PermissionsAndroid.check(WRITE_CALENDAR),
        PermissionsAndroid.check(READ_CALENDAR),
    ]);

    if (hasWritePermission === true && hasReadPermission === true) {
        return toCallWhenPermissionGranted();
    }

    const results = await PermissionsAndroid.requestMultiple([WRITE_CALENDAR, READ_CALENDAR]);

    if (
        results[READ_CALENDAR] === PermissionsAndroid.RESULTS.GRANTED &&
        results[WRITE_CALENDAR] === PermissionsAndroid.RESULTS.GRANTED
    ) {
        return toCallWhenPermissionGranted();
    }
    return Promise.reject(permissionNotGranted);
};

const withPermissionsCheckIOS = async (toCallWhenPermissionGranted) => {
    // noinspection JSUnresolvedFunction
    const hasPermission = await AddCalendarEvent.requestCalendarPermission();

    if (hasPermission) {
        return toCallWhenPermissionGranted();
    }
    return Promise.reject(permissionNotGranted);
};
