import { useSelector } from 'react-redux';
import {
    Platform, React, ScrollView, Text, TouchableOpacity, TouchableWithoutFeedback, View,
} from '../../../common';
import { navigate } from '../../../actions';
import { getActualPopularQueries, getCurrentLocation } from '../../../selectors';
import { gotoDoctors } from './utils';

const PopularQueryItem = (props) => {
    const buttonStyle = {
        marginRight: 6,
        marginVertical: 6,
        borderRadius: 20,
        paddingVertical: 7,
        paddingHorizontal: 20,
        backgroundColor: '#B6BEDA',
    };
    const textStyle = { color: 'white' };
    return (
        <View style={[buttonStyle, props.style]}>
            <TouchableOpacity onPress={props.action}>
                <Text style={[textStyle, props?.textStyle]}>{Object.R(props?.title ?? '')}</Text>
            </TouchableOpacity>
        </View>
    );
};

const PopularQueryColumn = ({ item }) => {
    const onClickHandler = i => () => {
        Object.trackAction(i?.title, { trackingAlias: 'PopularQuery' });
        if (i?.type === 'drugs') {
            navigate('DrugsList', {
                keyword: i.name,
                drugsList: null,
            });
        } else {
            gotoDoctors(i.id, !!i?.useSearch, true, undefined, i.specId);
        }
    };
    return (
        <>
            <TouchableWithoutFeedback>
                <View style={{ flexDirection: 'row' }}>
                    <Text>
                        {
                            // JSON.stringify(item)
                        }
                        {item?.data?.map(subItem => (
                            <View style={{ padding: 0, margin: 0 }} key={subItem.name}>
                                <PopularQueryItem
                                    key={subItem.name}
                                    action={onClickHandler(subItem)}
                                    title={subItem.name}
                                    style={{
                                        backgroundColor: subItem.type === 'drugs' ? '#8EC7D4' : '#9AACDA',
                                        marginVertical: 5,
                                        paddingVertical: 5,
                                    }}
                                />
                            </View>
                        ))}
                    </Text>
                </View>
            </TouchableWithoutFeedback>
        </>
    );
};

export const PopularQueries = () => {
    const { banners } = useSelector(getCurrentLocation);
    const lines = banners && banners?.split(';')?.includes('doctors') ? 2 : 1;
    const data = useSelector(getActualPopularQueries);
    return (
        <ScrollView
            style={{
                position: 'relative',
                height: (Platform.OS === 'ios' ? 38 : 39) * lines,
                overflowX: 'hidden',
                overflowY: 'hidden',
            }}
            nestedScrollEnabled={true}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            scrollEnabled={false}
            removeClippedSubviews>
            <PopularQueryColumn item={{ data }} />
        </ScrollView>
    );
};
