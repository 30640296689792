import { sortBy } from '../../../utils';

export const getHintObject = ({
    profile, coverer, covererCode, ownerHasActiveProgram,
}) => {

    if ((coverer || covererCode) && profile) {

        if (profile.declineReasons && profile.declineReasons.some(({ code }) => code === 11)) {

            return {
                level: 'warn',
                message: 'hint.medcardNotActiveForClinic',
                allowBooking: true,
            };

        }
        if (!ownerHasActiveProgram && !profile.programIsActive && profile.programs && profile.programs.length) {

            return {
                level: 'error',
                message: 'hint.not_active_program',
                allowBooking: true,
            };

        } else if (profile.isChild && !profile.programIsActive) {

            return {
                level: 'warn',
                message: 'hints.child_not_in_program',
                allowBooking: true,
            };

        }

    }
    return {
        level: 'warn',
        message: 'hints.add_online-visit',
        allowBooking: true,
    };

};

export const getError = (
    profile,
    defaultError = {
        message: '',
        allowBooking: true,
        level: 'warn',
        hideButton: false,
    },
    orderId
) => {

    if (profile?.declineReasons?.length) {

        const reason = sortBy(profile.declineReasons, 'weight')[0];
        const allowBooking = profile?.onlineBookingAllowed !== false;
        return (
            {
                message: allowBooking
                    ? reason?.message
                    : Object.R('error.wrongBookPerson', { reason: reason?.message }),
                allowBooking,
                level: allowBooking ? 'warn' : 'error',
            } ?? { allowBooking: true }
        );

    } else if (!profile && !orderId) {

        return {
            message: Object.R('titles.no_visitor_error'),
            allowBooking: false,
            level: 'error',
        };

    } else if (profile?.id === 'empty') {

        return {
            message: Object.R('error.wrongBookPerson', { reason: Object.R('titles.DECLINE_REASON_10') }),
            allowBooking: false,
            level: 'error',
        };

    }
    return {
        level: defaultError?.level || 'warn',
        message: defaultError?.message ? Object.R('error.wrongBookPerson', { reason: defaultError?.message }) : '',
        allowBooking: defaultError?.allowBooking ?? true,
    };

};
