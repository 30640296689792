import { useCallback } from 'react';
import { buttonsStyles as style, transparentButtonsStyles } from '../styles/button';
import { openURL } from '../services/device';
import tracking from '../services/tracking';
import { navigate } from '../actions';
import {
    ActivityIndicator, Platform, React, S, Text, TouchableOpacity, TouchableWithoutFeedback, View,
} from './react';
import { COLORS } from './style';
import { Icon, Title } from '.';

/* eslint-disable complexity */
export const Button = ({
    action,
    styles,
    disabledStyle,
    title,
    titleStyles,
    ns = '',
    children,
    disabled,
    normal,
    transparent,
    primary,
    busy,
    trackingAlias,
    trackingParams = {},
    trackingId,
    danger,
    orange,
    localize = true,
    capitalize = false,
    numberOfLines = 1,
}) => {
    const loggedAction = () => {
        Object.trackAction(trackingId || title, { trackingAlias, ...trackingParams });
        action();
    };

    const titleText = localize ? Object.R(title) : title;
    const titleElement = !title ? null : (
        <Text
            numberOfLines={numberOfLines}
            style={[normal || primary || danger ? style.button__title : null, titleStyles, { textAlign: 'center' }]}>
            {capitalize && titleText ? String(titleText).toUpperCase() : titleText}
        </Text>
    );
    const st = [
        normal ? style.button_normal : null,
        styles,
        disabled ? style.disabled : null,
        primary ? style.button_primary : null,
        transparent ? style.button_transparent : null,
        danger ? style.button_danger : null,
        busy || disabled ? [style.disabled, disabledStyle] : null,
        orange ? style.button_orange : null,
    ];
    const active = !(disabled || busy || !action);
    return (
        <TouchableOpacity
            style={st}
            disabled={!!disabled}
            onPress={active ? loggedAction : null}
            accessibilityLabel={ns + (active ? '' : '__deactivated')}>
            {busy ? (
                <ActivityIndicator
                    animating
                    size={Platform.OS === 'ios' ? 1 : 16}
                    color="#56CCF2"
                    style={{ paddingRight: 12 }}
                />
            ) : null}
            {children ? (
                <View numberOfLines={numberOfLines} style={S.flexRow__center}>
                    {titleElement}
                    {children}
                </View>
            ) : (
                titleElement
            )}
        </TouchableOpacity>
    );
};
export const TransparentButton = ({
    action,
    styles,
    disabledStyle,
    title,
    titleStyles,
    ns = '',
    children,
    disabled,
    normal,
    transparent,
    primary,
    busy,
    blue,
    border,
    trackingAlias,
    trackingParams = {},
    danger,
    orange,
    localize = true,
    capitalize = false,
    numberOfLines = 1,
}) => {
    const loggedAction = () => {
        Object.trackAction(title, { trackingAlias, ...trackingParams });
        action();
    };

    const titleText = localize ? Object.R(title) : title;
    const st = [
        normal ? transparentButtonsStyles.button_normal : null,
        styles,
        disabled ? transparentButtonsStyles.disabled : null,
        primary ? transparentButtonsStyles.button_primary : null,
        transparent ? transparentButtonsStyles.button_transparent : null,
        danger ? transparentButtonsStyles.button_danger : null,
        blue ? transparentButtonsStyles.button_blue : null,
        busy || disabled ? [transparentButtonsStyles.disabled, disabledStyle] : null,
        orange ? transparentButtonsStyles.button_orange : null,
        border ? transparentButtonsStyles.button_border : null,
    ];

    const titleElement = !title ? null : (
        <Text
            numberOfLines={numberOfLines}
            style={[
                normal || primary || danger || blue ? transparentButtonsStyles.button__title : null,
                titleStyles,
                { textAlign: 'center' },
                st,
            ]}>
            {capitalize && titleText ? String(titleText).toUpperCase() : titleText}
        </Text>
    );

    const active = !(disabled || busy || !action);
    return (
        <TouchableOpacity
            style={st}
            disabled={disabled}
            onPress={active ? loggedAction : null}
            accessibilityLabel={ns + (active ? '' : '__deactivated')}>
            {busy ? (
                <ActivityIndicator
                    animating
                    size={Platform.OS === 'ios' ? 1 : 16}
                    color="#56CCF2"
                    style={{ paddingRight: 12 }}
                />
            ) : null}
            {children ? (
                <View numberOfLines={numberOfLines} style={S.flexRow__center}>
                    {titleElement}
                    {children}
                </View>
            ) : (
                titleElement
            )}
        </TouchableOpacity>
    );
};
export const SelectableButton = props => (
    <Button
        {...props}
        styles={[style.btnRounded, props.selected ? style.btnRoundedSelected : {}, props.style]}
        titleStyles={style.btnTxtRounded}>
        {props.children}
    </Button>
);

export const BigButton = props => (
    <Button
        ns="bigButton"
        {...props}
        styles={[style.bigButton, props.styles]}
        titleStyles={[style.bigButtonTitle, props.titleStyles]}
    />
);

export const Link = ({
    href,
    id,
    children = id,
    ns = 'link',
    style: st,
    underline,
    disabled,
    bigger,
    localize = true,
    trackingAlias,
    trackingParams = {},
    noflex,
}) => {
    const loggedAction = useCallback(() => {
        Object.trackAction(id, { trackingAlias, ...trackingParams }, 'ai_lnk');
        return typeof href === 'string' ? openURL(href) : href();
    }, [href]);

    return Platform.OS === 'web' && typeof href === 'string' ? (
        <TouchableWithoutFeedback accessibilityLabel={ns}>
            <View>
                <Text
                    numberOfLines={4}
                    style={[
                        style.link,
                        st,
                        underline ? style.link_underline : null,
                        bigger ? { fontSize: 16 } : null,
                        disabled ? { color: 'grey' } : null,
                    ]}
                    href={href}
                    accessibilityRole="link"
                    target="_blank">
                    {localize ? Object.R(children) : children}
                </Text>
            </View>
        </TouchableWithoutFeedback>
    ) : (
        <TouchableOpacity onPress={disabled ? () => {} : loggedAction} style={noflex ? {} : { flex: 1 }}>
            {typeof children === 'string' ? (
                <Text
                    numberOfLines={4}
                    style={[
                        style.link,
                        st,
                        underline ? style.link_underline : null,
                        bigger ? { fontSize: 16 } : null,
                        disabled ? { color: 'grey' } : null,
                    ]}>
                    {localize ? Object.R(children) : children}
                </Text>
            ) : (
                children
            )}
        </TouchableOpacity>
    );
};
export const ButtonsGroup = ({
    children,
    ns = 'buttons',
    style: st,
    gap = '2%',
    _children = React.Children.toArray(children),
}) => (
    <View style={[style.buttons_group, st]} accessibilityLabel={ns}>
        <View style={{ flex: 1 }}>{_children[0]}</View>
        {_children[1] ? <View style={{ width: gap }} /> : null}
        <View style={{ flex: _children[1] ? 1 : 0 }}>{_children[1]}</View>
    </View>
);

export const OnMapButton = ({
    logEventName, route, containerStyle, routeParams,
}) => (Platform.OS !== 'web' ? (
    <TouchableOpacity
        style={[{ flexDirection: 'row' }, containerStyle]}
        onPress={() => {
            tracking.logEvent(logEventName);
            navigate(route, routeParams);
        }}>
        <Icon.Geo color={COLORS.MAIN} />
        <Title
            style={{
                marginLeft: 6,
                color: COLORS.MAIN,
            }}
            id="buttons.on_map"
        />
    </TouchableOpacity>
) : null);
