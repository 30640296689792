import { useEffect, useState } from 'react';
import {
    Icon, LoadingIndicator, React, Section, Text, Title, TouchableOpacity,
} from '../../../common';
import { visitInput as styles } from '../style';

const DownArrowIcon = ({ onPress, style }) => (
    <Icon name="down-arrow" size={20} color="#555555" style={[styles.inputIcon, style]} onPress={onPress} />
);

export const VisitInfoSelector = ({
    title,
    sectionTitle,
    sectionStyle,
    isLoading,
    onSelect,
    enabled,
    selectorStyle,
}) => {
    const [h, setH] = useState(44);
    useEffect(() => {
        setH(44);
    }, [title]);
    return (
        <Section style={[sectionStyle, { minHeight: h }]}>
            {sectionTitle ? <Text style={styles.inputHeader}>{Object.R(sectionTitle)}</Text> : null}
            {isLoading ? (
                <LoadingIndicator style={styles.loadingCapabilitiesIndicator} indicatorSize={30} />
            ) : (
                <TouchableOpacity
                    disabled={!enabled}
                    onPress={onSelect}
                    style={[styles.containerInput, selectorStyle, { minHeight: h }]}>
                    <Title.Bigger
                        style={[styles.inputText, { marginRight: 24, minHeight: h }]}
                        numberOfLines={1}
                        onLayout={(c) => {
                            const height = c?.nativeEvent?.layout?.height ?? 44;
                            // if (height > 44) {
                            //     setH(66);
                            // }
                        }}>
                        {title}
                    </Title.Bigger>
                    {!enabled ? null : <DownArrowIcon onPress={onSelect} style={h === 44 ? null : { marginTop: 8 }} />}
                </TouchableOpacity>
            )}
        </Section>
    );
};
