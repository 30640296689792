import {
    removeListener, getOtp, getHash, startOtpListener,
} from 'react-native-otp-verify';
import {
    BigButton, Field, Image, Keyboard, Link, Logo, Page, Platform, React, Section, TextInput,
} from '../../common';
import {
    getDefaultSmsProvider, getIsUserFetching, getSecondSmsProvider, getUserInteraction,
} from '../../selectors';
import { MODE } from '../../config';
import { user } from '../../services/user';
import { getMessageWithTime } from '../../services/user.utils';
import { Timer } from './Timer';

export const CodeConfirmation = Page.register(
    ({
        id,
        interaction: { error, isLoading },
        isAuthenticating,
        phoneNumber = '',
        code = MODE === 'dev' ? '111111' : '',
        disabled = true,
        confirmation,
        setState,
        actions,
        isSignInWithApi = false,
        waitingResponse,
        navigation,
        // defaultSmsProvider,
        secondSmsProvider,
        captcha = null,
        captchaTextCode = '',
        startListenSms = async () => {

            if (Platform.OS === 'android') {


                try {

                    await getOtp();

                    startOtpListener((message) => {

                        const index = message.indexOf(':');
                        let finalSmsCode = message.slice(index + 1).split('.')[0];
                        finalSmsCode = Number(finalSmsCode) ? finalSmsCode : '';
                        setState({ code: finalSmsCode });
                        removeListener();

                    });

                } catch (e) {

                    //

                }

            }

        },
        onCaptchaSubmit = async () => {

            const currentCode = captchaTextCode;
            setState({
                isCaptchaSubmitted: true,
                captchaTextCode: '',
            });
            try {

                const resp = await user.captchaCodeConfirmation(Number(currentCode), id, actions.setUserError);
                Keyboard.dismiss();
                if (resp?.id) {

                    setState({ captcha: null });
                    actions.setUserError('');
                    if (resp?.needsHumanCheck && resp?.captchaCode) {

                        setState({ captcha: resp?.captchaCode });

                    }

                } else if (resp?.code === 'INVALID_INPUT') {

                    if (resp?.attributes?.retryAfter) {

                        actions.setUserError(getMessageWithTime(resp.attributes.retryAfter, 'error.code_was_entered_incorrectly/seconds', 'error.code_was_entered_incorrectly/minutes'));

                    } else {

                        actions.setUserError(resp?.body?.title ?? Object.R(resp?.body?.code ?? 'error.ERROR_INVALID_VERIFICATION_CODE'));

                    }

                }
                // {
                //   "status": 500,
                //   "message": "Вероятно, пропало интернет-соединение или сервис недоступен. Попробуйте еще раз или повторите попытку позже.",
                //   "code": "INVALID_INPUT",
                //   "body": {
                //     "type": "aimd:my-api-problem",
                //     "code": "INVALID_INPUT",
                //     "title": "Недопустимые входные данные",
                //     "attributes": {
                //       "retryAfter": "2024-10-08T05:21:35.522Z"
                //     },
                //     "status": 500
                //   }
                // }
                setState({ waitingResponse: false });

            } catch {

                setState({
                    waitingResponse: false,
                    captchaTextCode: '',
                });

            } finally {

                setState({ isCaptchaSubmitted: false });

            }

        },
        onSubmit = async () => {

            Keyboard.dismiss();
            if (isSignInWithApi) {

                actions.startUserFetch();
                Object.SendConfirmationCode = true;
                const token = await user.smsConfirmation(Number(code), id, (message, errorCode) => {

                    Object.SendConfirmationCode = false;
                    actions.stopUserFetch();
                    actions.setUserError(message, errorCode);

                });
                if (token) {

                    actions.signInWithToken(token, (message, errorCode) => {

                        Object.SendConfirmationCode = false;
                        actions.stopUserFetch();
                        actions.setUserError(message, errorCode);

                    });

                }

            } else {

                Object.SendConfirmationCode = true;
                actions.startUserFetch();
                actions.sendSmsCode(code, confirmation, (e) => {

                    Object.SendConfirmationCode = false;
                    let message = '';
                    const errorCode = e?.code;
                    if (e?.code === 'auth/invalid-verification-code' || e?.code === 'auth/code-expired') {

                        message = Object.R('error.ERROR_INVALID_VERIFICATION_CODE');

                    } else {

                        message = Object.R('titles.somethingWentWrong');

                    }
                    actions.stopUserFetch();
                    actions.setUserInteractionError({
                        message,
                        errorCode,
                    });

                });

            }
            // actions.setUserInteractionError({ });
            setState({ waitingResponse: false });

        },
        sendAgain = async () => {

            // // isSignInWithApi ?
            let appHash = [];
            if (Platform.OS === 'android') {

                appHash = await getHash();
                startListenSms();

            }
            if (secondSmsProvider === 'fb') {

                const fullPhoneNumber = `+${phoneNumber.replace(/\D+/g, '')}`;
                try {

                    actions.signInWithPhoneNumber(fullPhoneNumber, (newId) => {

                        setState({
                            code: '',
                            id: newId,
                            confirmation: newId,
                            isSignInWithApi: false,
                        });

                    });

                } catch {

                    // console.log('Error login', e);

                }

            } else {

                const { id: newId, captchaCode } = await user.signIn(
                    `+${phoneNumber.replace(/\D+/g, '')}`,
                    appHash[0],
                    actions.setUserError,
                );
                if (captchaCode) {

                    setState({ captcha: captchaCode });

                }
                if (newId) {

                    actions.setUserError();

                }
                setState({
                    code: '',
                    id: newId,
                    isSignInWithApi: true,
                });

            }

        },
    }) => (
        <Page
            name="sign-in"
            persistKeyboard
            notification={
                error && {
                    ...error,
                    onSubmit: isSignInWithApi ? undefined : onSubmit,
                }
            }
            isLoading={isLoading || isAuthenticating}
            onDidMount={() => {

                startListenSms();
                actions.setFormData({
                    CodeConfirmation: {
                        back: () => {

                            actions.setUserInteractionError();
                            navigation.goBack();
                            return true;

                        },
                    },
                });

            }}
            onWillUnmount={() => {

                actions.setUserError();
                if (Platform.OS === 'android') {

                    removeListener?.();

                }

            }}>
            <Section flex>
                {
                    captcha ? (
                        <Logo.Wrapper title="titles.enter_captcha_code"><Image
                            source={{ uri: captcha }}
                            style={{
                                height: 60,
                                width: 140,
                                alignSelf: 'center',
                                marginBottom: 8,
                            }}
                        /><TextInput.Number style={{ flex: 1 }} onChange={c => setState({ captchaTextCode: c }) } value={captchaTextCode} />
                        </Logo.Wrapper>
                    ) : (
                        <Logo.Wrapper
                            title="titles.enter_code"
                            subtitle="titles.codeHasBeenSent"
                            subtitleParams={{ phone: phoneNumber }}>
                            <Field>
                                <TextInput.Number
                                    ns="code"
                                    placeholder="placeholders.code"
                                    value={code}
                                    onChange={c => setState({ code: c })}
                                />
                            </Field>
                        </Logo.Wrapper>
                    )
                }
                <BigButton
                    action={() => {

                        setState({ waitingResponse: true });
                        if (captcha) {

                            onCaptchaSubmit();

                        } else {

                            onSubmit();

                        }

                    }}
                    title={captcha ? 'buttons.continue' : 'buttons.enter_to_aibolit'}
                    disabled={!(code || (captcha && captchaTextCode )) || waitingResponse }
                />
            </Section>
            <Section centered>{disabled ? <Timer onComplete={() => setState({ disabled: false })} /> : null}</Section>
            <Section centered style={{ marginBottom: 40 }}>
                {!disabled && (
                    <Link
                        underline
                        disabled={disabled}
                        bigger
                        id="titles.sendAgain"
                        href={() => {

                            setState({ disabled: true });
                            sendAgain();

                        }}
                        trackingAlias="CodeConfirm"
                    />
                )}
            </Section>
        </Page>
    ),
    {
        interaction: { from: getUserInteraction },
        isAuthenticating: { from: getIsUserFetching },
        defaultSmsProvider: { from: getDefaultSmsProvider },
        secondSmsProvider: { from: getSecondSmsProvider },
    },
);
