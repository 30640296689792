/* eslint-disable no-underscore-dangle */
import {
    Component, Field, List, Page, React, Section, SubtitleDark, TextArea, Title,
} from '../../common';
import { back } from '../../actions';
import { InvoiceSingleService } from './InvoiceSingleService';
import { InvoiceAdaptiveButton } from './InvoiceAdaptiveButton';

// import { invoiceItem as s } from './style';

export class InvoiceConfirmation extends Component {
    state = { rejectedMessageText: this.props.route.params.rejectedMessageText || '' };

    getRejectInfo() {
        const { route: { params: { rejectedIds } } } = this.props;
        const { rejectedMessageText } = this.state;
        return rejectedIds.map(id => ({
            rejectedRecordId: id,
            rejectionComment: rejectedMessageText,
        }));
    }

    render() {
        const {
            route: {
                params: {
                    processingInvoice,
                    approvedIds,
                    rejectedRecords,
                    pressedType,
                },
            },
        } = this.props;
        const { rejectedMessageText } = this.state;
        const stateButton = rejectedMessageText.length > 0 ? 'approved' : '';
        const buttonParams = {
            '': { title: Object.R('buttons.send') },
            approved: { title: Object.R('buttons.send') },
        };
        // console.log('------------- InvoiceConfirmation, rejectedMessageText', rejectedMessageText);
        return (
            <Page
                footer={
                    <InvoiceAdaptiveButton
                        stateButton={stateButton}
                        params={buttonParams}
                        action={() => {
                            back();
                            processingInvoice(approvedIds, this.getRejectInfo(), pressedType);
                        }}
                    />
                }
            >
                <Section style={{ marginBottom: 12 }}>
                    <Title id="titles.your_comment_required" bold style={{
                        marginLeft: 8,
                        marginBottom: 12,
                    }}/>
                    <Field ns="title-text">
                        <TextArea
                            placeholder="placeholder.why_you_not_confirm_some_services"
                            onChange={t => this.setState({ rejectedMessageText: t })}
                            value={rejectedMessageText}
                        />
                    </Field>
                </Section>
                <Section>
                    <SubtitleDark id="titles.not_confirmed_services" bold style={{
                        marginLeft: 8,
                        marginBottom: 24,
                    }}/>
                    <List
                        accessibilityLabel="invoices_rejected"
                        data={rejectedRecords}
                        renderItem={({ item }) => (
                            <InvoiceSingleService {...item} key={item._id} forConfirm/>
                        )}
                    />
                </Section>
            </Page>
        );
    }
}
