import {
    BigButton, React, S, TopNotification, View,
} from '../../../common';
import { openURL } from '../../../services/device';
import { getSupportBody } from '../../../utils/supportInfo';

export const Footer = ({
    error, isButtonVisible, isLoading, isButtonDisabled, onSubmit = () => {},
}) => {
    return (
        <View
            style={[
                S.padding,
                {
                    backgroundColor: 'white',
                    borderTopColor: '#F0F0F0',
                    borderTopWidth: 1,
                    paddingTop: 0,
                },
            ]}>
            {!!error?.message && (
                <TopNotification
                    hint={error}
                    containerStyle={{
                        borderBottomWidth: 0,
                        marginTop: 8,
                        marginBottom: 0,
                        paddingBottom: 0,
                    }}
                    submitText={error?.noSupport ? null : Object.R('buttons.writeToSupport')}
                    submitContainerStyle={{
                        borderWidth: 0,
                        alignSelf: 'flex-end',
                        marginRight: 16,
                        marginBottom: 12,
                    }}
                    onSubmit={() => {
                        openURL(`mailto:${Object.R('supportEmail')}${getSupportBody()}`);
                    }}
                />
            )}
            {!!isButtonVisible && !isLoading && (
                <BigButton
                    title="buttons.make_appointment_visit"
                    busy={isLoading}
                    action={onSubmit}
                    disabled={isButtonDisabled}
                />
            )}
        </View>
    );
};
