import { Platform } from 'react-native';
import EncryptedStorage from 'react-native-encrypted-storage';
import store from '../store';

let arrOfKeysToRemove = [];

const sessionStorage = {
    async setItem(key, value) {
        arrOfKeysToRemove.push(key);
        // eslint-disable-next-line no-undef
        window.sessionStorage.setItem(key, value);
    },
    async getItem(key) {
        // eslint-disable-next-line no-undef
        return window.sessionStorage.getItem(key);
    },
    async removeItem(key) {
        // eslint-disable-next-line no-undef
        window.sessionStorage.removeItem(key);
        arrOfKeysToRemove = arrOfKeysToRemove.filter(e => e !== key);
    },
    async removeAll() {
        arrOfKeysToRemove.forEach((key) => {
            sessionStorage.removeItem(key);
        });
    },

};
// noinspection JSUnusedGlobalSymbols
const eStorage = {

    async setItem(key, value) {
        try {
            await EncryptedStorage.setItem(key, value);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('There was an error on the native side: ', error);
        }
    },
    async getItem(key) {
        try {
            // noinspection UnnecessaryLocalVariableJS
            const session = await EncryptedStorage.getItem(key);
            return session;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('There was an error on the native side: ', error);
        }
        return null;
    },
    async removeItem(key) {
        try {
            await EncryptedStorage.removeItem(key);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('There was an error on the native side: ', error);
        }
    },
    async removeAll() {
        try {
            await EncryptedStorage.clear();
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('There was an error on the native side: ', error);
        }
    },

};

export const removeACodeForProvider = async (provider, webOnly = true) => {
    const web = Platform.OS === 'web';
    if (!web && webOnly) {
        return;
    }
    await (web ? sessionStorage : eStorage).removeItem(`acode-${provider}`);
    await (web ? sessionStorage : eStorage).removeItem(`lastAccess-${provider}`);
};

export const removeAllACodes = (providers, onlyForWeb = true) => {
    if (onlyForWeb && Platform.OS !== 'web') {
        return;
    }
    const cards = store.getState()?.user?.info?.me?.cards ?? providers ?? [];
    const providerList = cards.map(item => item?.providerCode);
    providerList.forEach(provider => removeACodeForProvider(provider));
};

export default Platform.OS === 'web' ? sessionStorage : eStorage;
