export {
    getDefaultNearestDate,
    isFutureVisit,
    formatDate,
    formatDateShort,
    sinceDateAndMonth,
    formatDateLong,
    formatTime,
    todayOrTomorrow,
    isDayNotPast,
    toDate,
    formatTimeslots,
    getIsoFormattedDate,
    isAvaliableOnWeek,
    getNewAdjustedDate,
    daysInMonth,
    formatFullDate,
    timeOfDay,
    formatTimezone,
    getTimeZoneDiffMinutes,
    getMonthName,
    parseToDdMmYyyy,
    dateLocales,
    adjustTimeZone,
    MinskTimeZoneOffsetMinutes,
    years,
    parseISO8601String,
    months,
    childYears,
    isAdult,
    isFuture,
    isToday,
    getNumberOfDaysBetween,
} from './dates';
export {
    capitalize,
    head,
    tail,
    formatString,
    normalizeString,
    getFirstLastName,
    getLastSubName,
    trimExtraSpaces,
    testEmailRegex,
    getSplittedName,
} from './strings';
export { extractPhoneNumber, formatPhoneNumber, getMaskByPhonePrefix } from './phone';
export { urlParse, urlStringify } from './url';
export { calculateDistance, inRegion } from './geospatial';
export { cyrlat } from './cyrlat';
export { decrypt, encrypt } from './crypto';
export {
    compose,
    and,
    assert,
    curry,
    fnFalse,
    fnId,
    fnNull,
    fnThis,
    fnThisProp,
    fnThrow,
    fnTrue,
    fnVoid,
    isFunction,
    isSomething,
    log,
    or,
    someOrNull,
    sum,
    swap,
    removeEmpty,
} from './fn';

const fnId = x => x;

export const debouncer = (lag = 1500) => {
    let timerId;
    return (fn) => {
        if (timerId) {
            clearTimeout(timerId);
            timerId = setTimeout(() => {
                timerId = null;
                fn();
            }, lag);
        } else {
            fn();
            timerId = setTimeout(() => {
                timerId = null;
            }, lag);
        }
    };
};

/** ***********************
 * Objects.
 */

/**
 * Checks if argument is empty .
 */
export const isEmpty = (x) => {
    if (!x) {
        return true;
    }
    if (x instanceof Object) {
        // (zero-length array)
        if (Array.isArray(x)) {
            return x.length === 0;
        }
        // (zero-size map)
        if (x instanceof Map) {
            return x.size === 0;
        }
        // (has no props)
        return Object.keys(x).length === 0;
    }
    return false;
};

// noinspection JSUnusedGlobalSymbols
/**
 * Checks if ALL arguments are empty .
 */
export const allEmpty = (...xx) => xx.filter(isEmpty).length === xx.length;

/**
 * Checks if SOME arguments are empty .
 */
export const someEmpty = (...xx) => xx.filter(isEmpty).length > 0;

/**
 * Digs value in a given object structure by a given path.
 *
 * @param {*} o source object
 * @param {*} steps path
 * @param {*} def default value
 */
export const dig = (o, steps, def) => {
    const x = steps.split('.').reduce((r, e) => (r ? r[e === '0' ? 0 : e] : r), o);
    return typeof x === 'undefined' ? def : x;
};

/** ***********************
 * Arrays.
 */

/**
 * Builds histogram on given field for given list.
 *
 * @param {*} list source
 * @param {*} field to be used as group key
 */
export function histogram(list, field = 'id') {
    const result = {};
    const fieldFn = typeof field === 'string' ? e => e[field] : field;
    const iter = (v, entry) => {
        const slot =
            result[v] ||
            (result[v] = {
                key: v,
                count: 0,
                subs: [],
            });
        slot.count++;
        slot.subs.push(entry);
    };
    (list || []).forEach((e) => {
        const value = fieldFn(e);
        if (Array.isArray(value)) {
            value.forEach(v => iter(v, e));
        } else {
            iter(value, e);
        }
    });
    return result;
}

export function sortByComparator(property = 'name', order = 1) {
    let fn = property;
    if (typeof property === 'string') {
        if (property[0] === '-') {
            /* eslint-disable */
            order = -1;
            property = property.substr(1);
        }
        fn = (e) => e[property];
    }

    function compare(a, b) {
        const aa = fn(a);
        const bb = fn(b);
        /* eslint-disable */
        return aa < bb ? -order : aa > bb ? order : 0;
    }

    return compare;
}

/**
 * Sorts array by element property.
 *
 * @param {*} arr source
 * @param {*} property element property to sort by
 * @param {*} order
 */
export function sortBy(arr, property, order) {
    return (arr || []).slice(0).sort(sortByComparator(property, order));
}

/**
 * Sorts array by check if property value exists.
 *
 * @param {*} arr source
 * @param {*} property element property to sort by
 * @param {*} order
 */
export function sortByNonNull(arr, property = 'name', order = 1) {
    const comparator = (a, b) => {
        if (a[property] !== null) {
            return b[property] !== null ? 0 : -order;
        }
        return b[property] !== null ? order : 0;
    };
    return (arr || []).sort(comparator);
}

const getMaxDate = () => new Date(2100, 11, 31, 23, 59, 59);

const getDateFromNTS = (a) => {
    if (a === null || !a) {
        return 99999999;
    }
    try {
        const d = typeof a === 'object' ? a : new Date(a);

        const year = d?.getFullYear() ?? 0;
        const month = `0${d?.getMonth() ?? 0}`.slice(-2);
        const day = `0${d?.getDate() ?? 0}`.slice(-2);
        return Number(`${year}${month}${day}`);
    } catch (e) {
        // console.log('Error get date', { e, a, d });
        return 99999999;
    }
};

const getTimeFromNTS = (a) => {
    try {
        let d = typeof a === 'object' ? a : new Date(a);
        return d?.getTime?.() ?? getMaxDate().getTime();
    } catch (e) {
        // console.log('Error get time from NTS', { e, a });
        return new getMaxDate().getTime();
    }
};

export const sortDoctorByBumbaComparator = (a, b) => {
    const da = getDateFromNTS(a?.nearestDate);
    const db = getDateFromNTS(b?.nearestDate);
    const ba = a?.hasBumba ?? false;
    const bb = b?.hasBumba ?? false;
    const ta = getTimeFromNTS(a?.nearestDate);
    const tb = getTimeFromNTS(b?.nearestDate);
    // console.log(`a ${a?.fullName}, b ${b?.fullName}`, {a: {da, ta, ba, a}, b: {db, tb, bb, b}});
    if (da > db) {
        return 1;
    } else if (da < db) {
        return -1;
    }

    if (ba !== bb) {
        if (ba) {
            return -1;
        }
        return 1;
    }
    if (ta > tb) {
        return 1;
    } else if (ta < tb) {
        return -1;
    }

    return 0;
};
/**
 * Transforms array into hash object.
 * @param {*} list source array
 * @param {*} idKey id key
 * @param {*} valKey value key
 */
export const arrayToHash = (list, idKey = 'id', valKey = fnId) => {
    const r = {};
    if (list) {
        const keyFn = typeof idKey === 'string' ? (e) => e[idKey] : idKey;
        const valFn = typeof valKey === 'string' ? (e) => e[valKey] : valKey;
        list.forEach((e) => {
            r[keyFn(e)] = valFn(e);
        });
    }
    return r;
};

/** ***********************
 * Math.
 */

/* Simple GUID generator. */
export function guid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

/* eslint-disable */
/* Simple hash function. */
export const hash = function (s) {
    let a = 1,
        c = 0,
        h,
        o;
    if (s) {
        a = 0;
        /* jshint plusplus:false bitwise:false */
        for (h = s.length - 1; h >= 0; h--) {
            o = s.charCodeAt(h);
            a = ((a << 6) & 268435455) + o + (o << 14);
            c = a & 266338304;
            a = c !== 0 ? a ^ (c >> 21) : a;
        }
    }
    return String(a);
};

// noinspection JSUnusedGlobalSymbols
export const arrayToObject = (arr, keyField = 'id', keyValue = 'name') =>
    Object.assign({}, ...arr.map((item) => ({ [item[keyField]]: item[keyValue] })));

export const removeArrayDuplicates = (arr) => {
    if (arr <= 1) {
        return arr;
    }
    return arr.reduce(
        (acc, cur) => {
            if (acc.some((item) => Object.keys(cur).some((field) => item[field] !== cur[field]))) {
                acc.push(cur);
            }
            return acc;
        },
        [arr[0]],
    );
};

export const findByIds = (arr, ...ids) => {
    if (!arr || !arr.length) {
        return;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (let id of ids) {
        const item = id == null ? null : arr.find((e) => e.id === id);
        if (item) {
            return item;
        }
    }
};

// Math.random should be unique because of its seeding algorithm.
// Convert it to base 36 (numbers + letters), and grab the first 12 characters
// after the decimal. To easy distinguish between real device id and generated random
// we are adding '_' in front
export const generateRandomId = () => `_${Math.random().toString(36).substr(2, 12)}`;

const memoizedPropGetter = (key, fn) => {
    const memoKey = `$$${key}`;
    return function getter() {
        if (typeof this[memoKey] === 'undefined') {
            this[memoKey] = fn.call(this, this);
        }
        return this[memoKey];
    };
};
export const createMemoizedProps = (meta) =>
    Object.entries(meta).reduce(
        (r, [key, fn]) => Object.defineProperty(r, key, { get: memoizedPropGetter(key, fn) }),
        {},
    );

export const isRussian = (s) => {
    const russian = /[^\x00-\x7F\s]/;
    return russian.test(s);
};

export const sortByNational = (a = [], prop = 'name') => {
    return a.sort((x, y) => {
        const valX = x[prop];
        const valY = y[prop];
        const isXRussian = isRussian(valX);
        const isYRussian = isRussian(valY);
        if (isXRussian && !isYRussian) {
            return -1;
        } else if (!isXRussian && isYRussian) {
            return 1;
        }
        const order = 1;
        return valX > valY ? order : valX < valY ? -order : 0;
    });
};
