import { SCHEME } from '../scheme';
import { isEmpty } from '../utils';
import { createSelector, dbGettr } from './utils';
import { EMPTY_OBJECT } from './const';

/**
 * Feedbacks
 */

const {
    TESTS_FILTER,
    TESTS_FILTER_VISITORS,
    TESTS_FILTER_CLINICS,
    TESTS_FILTER_STATUSES,
} = SCHEME;

export const getTests = store => store.tests;

export const getActualTests = createSelector(
    [getTests],
    tests => (tests),
);

export const getActualTestsWithFilter = createSelector([
    getActualTests,
    dbGettr(TESTS_FILTER_CLINICS),
    dbGettr(TESTS_FILTER_STATUSES),
    dbGettr(TESTS_FILTER_VISITORS),
], (
    tests,
    clinics = [],
    statuses = [],
    visitors = [],
) => {
    if (isEmpty(tests) || isEmpty(tests.data)) {
        return [];
    }

    let result = [...tests.data];

    if (clinics.length) {
        result = result.filter(item => clinics.some(clinic => clinic === item.branchId));
    }

    if (statuses.length) {
        result = result.filter(item => statuses.some(status => status === item.status));
    }

    if (visitors.length) {
        result = result.filter(item => visitors.some(visitor => visitor === item.profileId));
    }

    return {
        ...tests,
        data: result,
    };
});

export const getClinicsFromTestsHistory = createSelector([
    getActualTests,
], (tests = []) => {
    if (isEmpty(tests)) {
        return [];
    }
    try {
        const result = new Map();
        tests.data.forEach((test) => {
            result.set(test.branchId, {
                id: test.branchId,
                name: test.branchName,
            });
        });
        return Array.from(result, ([id, value]) => ({
            id,
            name: value.name,
        }))
            .sort();
    } catch {
        return [];
    }
});

export const getClinicsFilterFromTestsHistory = createSelector([getClinicsFromTestsHistory], clinics => clinics.map(item => ({
    ...item,
    selected: false,
})));

export const getStatusesFromTestsHistory = createSelector([
    getActualTests,
], (tests) => {
    if (isEmpty(tests)) {
        return [];
    }
    const changeTable = {
        'in progress': Object.R('statuses.inProgress'),
        ready: Object.R('statuses.ready'),
    };
    try {
        const result = new Map();
        tests.data.forEach((test) => {
            result.set(test.status, {
                id: test.status,
                name: changeTable[test.status],
            });
        });
        return Array.from(result, ([id, value]) => ({
            id,
            name: value.name,
        }))
            .sort();
    } catch {
        return [];
    }
});

export const getStatusesFilterFromTestsHistory = createSelector([getStatusesFromTestsHistory], clinics => clinics.map(item => ({
    ...item,
    selected: false,
})));

export const getVisitorsFromTestsHistory = createSelector([
    getActualTests,
], (tests) => {
    if (isEmpty(tests)) {
        return [];
    }
    try {
        const result = new Set();
        tests.data.forEach((test) => {
            result.add(test.profileId);
        });
        return Array.from(result)
            .map(item => ({
                id: item,
                name: item,
            }));
    } catch {
        return [];
    }
});

export const getVisitorsFilterFromTestsHistory = createSelector([getVisitorsFromTestsHistory], clinics => clinics.map(item => ({
    ...item,
    selected: false,
})));

export const getNumberOfActiveTestsFilters = createSelector(
    [dbGettr(TESTS_FILTER)],
    ({
        visitors = [],
        clinics = [],
        statuses = [],
    } = EMPTY_OBJECT) => [visitors.length ? 1 : 0, clinics.length ? 1 : 0, statuses.length ? 1 : 0].reduce((acc, next) => (next ? 1 + acc : acc), 0),
);
