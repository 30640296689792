const VERBATIM_KEYS = {
    deviceId: 1,
    ums: 1,
    clinics: 1,
    physicians: 1,
    db_version: 1,
    selections: 1,
    firebase_ts: 1,
    bumbaBanner: 1,
    profileList: 1,
};
const resolveStorage = (key) => (VERBATIM_KEYS[key] || !window.isDesktop ? window.localStorage : window.sessionStorage);

function normHeaders(s = '') {
    return s
        .split('\n')
        .filter(Boolean)
        .reduce((r, h) => {
            const [k, v = ''] = h.split(':');
            const nkey = k
                .trim()
                .split('-')
                .map((s) => s[0].toUpperCase() + s.slice(1))
                .join('-');
            r[nkey] = v.trim();
            return r;
        }, {});
}

const fileKey = (file) => file.split('/').slice(-1)[0];
export default {
    fs: {
        dirs: {
            DocumentDir: '',
        },
        readFile: (file, encoding) => {
            const key = fileKey(file);
            const storage = resolveStorage(key);
            return storage.getItem(key);
        },
        writeFile: (file, s, encoding) => {
            const key = fileKey(file);
            const storage = resolveStorage(key);
            return storage.setItem(key, s);
        },
        unlink: (file) => {
            const key = fileKey(file);
            const storage = resolveStorage(key);
            return storage.removeItem(key);
        },
        exists: (file) => {
            const key = fileKey(file);
            const storage = resolveStorage(key);
            for (let i = 0; i < storage.length; i++) {
                if (key === storage.key(i)) {
                    return Promise.resolve(true);
                }
            }
            return Promise.resolve(false);
        },
        ls: () => {
            const r = [];
            const storage = resolveStorage('');
            for (let i = 0; i < storage.length; i++) {
                r.push(storage.key(i));
            }
            return Promise.resolve(r);
        },
    },
    config: () => ({
        fetch: (method, url, headers, body, responseType) =>
            new Promise((resolve, reject) => {
                let http = new XMLHttpRequest();
                http.open(method, url, true);
                Object.entries(headers || {}).forEach(([key, val]) => http.setRequestHeader(key, val));
                http.withCredentials = true;
                if (responseType) {
                    http.responseType = responseType;
                }
                http.onreadystatechange = () => {
                    if (http.readyState === 4) {
                        const data = responseType === 'blob' ? http.response : http.responseText;
                        const headers = normHeaders(http.getAllResponseHeaders());
                        const respInfo = {
                            status: http.status,
                            headers,
                            body: data,
                            code: http.statusText,
                        };
                        resolve({
                            respInfo,
                            info: () => respInfo,
                            data,
                            json: () => JSON.parse(data),
                        });
                    }
                };
                http.send(body);
            }),
    }),
};
