import { Link, Panel, React } from '../common';
import actions from '../actions';

export const PhoneItem = ({
    item,
    details,
    trackingAlias,
}) => (
    <Panel>
        <Link href={() => actions.dial(item)} trackingAlias={trackingAlias || 'tel_registry'} trackingParams={{ ...details }}>{item}</Link>
    </Panel>
);
