import encryptedStorage, { removeACodeForProvider } from '../services/encryptedStorage';
import store from '../store';
import { isBioProvided } from '../services/fingerprintScanner';
import { storage } from '../services/localstorage';

const LAST_ACCESS_PREFIX = 'lastAccess-';
const CODE_PREFIX = 'acode-';
const PROFILE_PREFIX = '';
const BIO_DEVICES = ['face', 'touch', 'bio', 'fingerprint'];
const ZERO_DELAY = 500;

export const getCurrentTimestamp = () => Date.now();

// const getExpireTime = async () => await storage.get('acodeExpirationTime');
const getExpireTime = () => store?.getState?.()?.db?.acodeExpirationTime ?? 120000;
const getRevalidateUnexpiredACodes = () => store?.getState?.()?.db?.revalidateUnexpiredACodes ?? false;
const getMyCards = () => store?.getState?.()?.user?.info?.me?.cards ?? [];
export const getTextWithAuth = (authText, text) => (store?.getState?.()?.user?.isAuthenticated ? authText : text);


export const aCodeSecurity = {
    debug: false,
    message(text, value) {

        if (aCodeSecurity.debug) {

            // eslint-disable-next-line no-console
            console.log(text, value);

        }

    },
    async setLastAccess(provider, defaultTime = -1) {

        try {

            const expiredTime = getExpireTime();
            if (typeof provider !== 'string') {

                this.message('Unknown provider setLastAccess', {
                    provider,
                    defaultTime,
                });
                return 0;

            }
            let time = (defaultTime === -1 ? getCurrentTimestamp() : defaultTime);

            if (expiredTime === 0) {

                time += ZERO_DELAY;
                this.message(`add to time ${ZERO_DELAY} ms setLastAccess`, {
                    provider,
                    defaultTime,
                    time,
                });

            }

            const key = `${LAST_ACCESS_PREFIX}${provider}`;
            const value = String(time);
            await encryptedStorage.setItem(key, value);
            this.message('setLastAccess', {
                key,
                value,
                time,
            });
            return time;

        } catch (e) {

            return 0;

        }

    },
    async getLastAccess(provider) {

        try {

            if (typeof provider !== 'string') {

                this.message('Unknown provider getLastAccess', provider);
                return 0;

            }
            const timeString = await encryptedStorage.getItem(`${LAST_ACCESS_PREFIX}${provider}`);
            if (timeString === null) {

                this.message('Unknown timeString getLastAccess', {
                    provider,
                    timeString,
                });
                return 0;

            }
            if (Number.isNaN(Number(timeString))) {

                this.message('wrong timeString getLastAccess', {
                    provider,
                    timeString,
                });
                return 0;

            }
            this.message('getLastAccess', {
                provider,
                timeString,
            });
            return Number(timeString);

        } catch (e) {

            return 0;

        }

    },

    async getCode(provider, profile = '') {

        try {

            if (typeof provider !== 'string') {

                this.message('wrong provider getCode', {
                    provider,
                    profile,
                });
                return 0;

            }
            const code = await encryptedStorage.getItem(`${CODE_PREFIX}${PROFILE_PREFIX}${profile}${provider}`);
            if (code === null) {

                this.message('code null getCode', {
                    provider,
                    profile,
                    code,
                });
                return 0;

            }
            if (Number.isNaN(Number(code))) {

                this.message('wrong code getCode', {
                    provider,
                    profile,
                    code,
                });
                return 0;

            }
            this.message('getCode', {
                provider,
                profile,
                code: Number(code),
            });
            return Number(code);

        } catch (e) {

            return 0;

        }

    },

    async hasCode(provider, profile = '') {

        return await aCodeSecurity.getCode(provider, profile) !== 0;

    },

    async setCode(provider, code, profile = '') {

        try {

            if (typeof provider !== 'string' || !(typeof code === 'string' || typeof code === 'number')) {

                this.message('wrong parameters setCode', {
                    provider,
                    profile,
                    code,
                });
                return 0;

            }
            const time = aCodeSecurity.setLastAccess(provider);
            if (time === null) {

                this.message('wrong set time setCode', {
                    provider,
                    profile,
                    code,
                    time,
                });
                return 0;

            }
            const key = `${CODE_PREFIX}${PROFILE_PREFIX}${profile}${provider}`;
            const value = String(code);
            await encryptedStorage.setItem(key, value);
            this.message('setCode', {
                key,
                value,
                time,
            });
            return time;

        } catch (e) {

            return 0;

        }

    },

    async isValidAccess(provider) {

        const access = await aCodeSecurity.getLastAccess(provider);
        if (!access) {

            this.message('not valid access isValidAccess', {
                provider,
                access,
            });
            return 0;

        }
        const now = getCurrentTimestamp();
        const expireTime = getExpireTime();
        let expired = access + expireTime;
        if (expireTime === -1) {

            expired = now + (12 * 31 * 24 * 3600 * 1000);

        }
        if (expired > now) {

            this.message('isValidAccess', {
                provider,
                expired: new Date(expired).toLocaleTimeString(),
                now: new Date(now).toLocaleTimeString(),
            });
            return expired;

        }
        this.message('expired isValidAccess', {
            provider,
            expired: new Date(expired).toLocaleTimeString(),
            now: new Date(now).toLocaleTimeString(),
        });
        await removeACodeForProvider(provider);
        return 0;

    },

    async getValidCode(provider, profile = '') {

        try {

            const code = await aCodeSecurity.getCode(provider, profile);
            if (await aCodeSecurity.isValidAccess(provider)) {

                this.message('getValidCode', {
                    provider,
                    profile,
                    code,
                });
                return code;

            }
            this.message('no valid code getValidCode', {
                provider,
                profile,
                code,
            });
            return 0;

        } catch (e) {

            return 0;

        }

    },

    async resetUnexpiredAccess() {

        try {

            const revalidate = getRevalidateUnexpiredACodes();
            // if (!revalidate) {
            //     return;
            // }
            const cards = getMyCards()?.map(item => item?.providerCode).filter(item => item);
            this.message('resetUnexpiredAccess', cards);

            for (const card of cards) {


                const access = await aCodeSecurity.isValidAccess(card);
                if (access && revalidate) {

                    this.message('revalidate access', card);

                    await aCodeSecurity.setLastAccess(card);

                }

            }

        } catch (e) {

            // eslint-disable-next-line no-console
            console.log('Error reset timers', e);

        }

    },
    /**
     * Check provider data on encrypted storage
     * @param provider provider code
     * @param profile profileId, when need to store a-codes separately
     * @returns {Promise<{code: number, isValid: boolean, validUntil: number, useBio: boolean, bio, selectedConfirmation: ?string}>}
     */
    async checkProvider(provider, profile = '') {

        const res = {
            code: 0,
            isValid: false,
            validUntil: 0,
            useBio: false,
            bio: undefined,
            selectedConfirmation: undefined,
        };
        this.message('checkProvider', {
            provider,
            profile,
        });
        res.code = await this.getCode(provider, profile);
        const expired = await this.isValidAccess(provider);
        res.isValid = expired !== 0;
        res.validUntil = expired;
        const { acodeConfirmation } = await storage.getObject('selections');
        const bio = await isBioProvided();
        res.useBio = BIO_DEVICES.includes(acodeConfirmation) && bio.available;
        res.bio = bio;
        res.selectedConfirmation = acodeConfirmation;
        this.message('checkProvider result', res);
        return res;

    },
};
