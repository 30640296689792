/* eslint-disable no-use-before-define */
import { tail } from '../utils';
import { unit } from '../styles/atomic';
import { COLORS } from '../styles/colors';
import { strongSearch } from '../utils/strings';
import { TextHighliter } from './TextHighliter';
import { elementsStyles as styles } from './style';
import {
    ActivityIndicator, Platform, React, Text, TouchableOpacity, View,
} from './react';

/**
 * Typography.
 */
export const textStyleResolver = ({
    subtitle, centered, bold, primary, strikethrough, color,
}) => [
    subtitle && styles.headLine_subtitle,
    centered && { textAlign: 'center' },
    bold && styles.text_bold,
    primary && styles.text_color,
    strikethrough && styles.text_strikethrough,
    color && { color: `${color}` },
];

// Localized text element.
export const RText = React.memo((props) => {

    const {
        id, ns = id, style, params, count, numberOfLines = 2, capitalize = false, notRequired, directText,
    } = props;
    const styl = [styles.text, style, textStyleResolver(props), boxStyleResolver(props)];
    const text = directText ? directText : notRequired
        ? `${String.i18n(id, count, params)} ${Object.R('titles.notRequired')}`
        : String.i18n(id, count, params);
    return (
        <Text accessibilityLabel={ns} style={styl} numberOfLines={numberOfLines}>
            {capitalize ? text.toUpperCase() : text}
        </Text>
    );

});

export const Title = React.memo(
    ({
        children,
        numberOfLines = 2,
        id,
        ns = id || 'title',
        count,
        params,
        style,
        onLayout,
        onTextLayout,
        ellipsizeMode = 'tail',
        ...flags
    }) => (
        <Text
            onTextLayout={onTextLayout}
            onLayout={onLayout}
            numberOfLines={numberOfLines}
            accessibilityLabel={ns}
            style={[styles.headLine, style, textStyleResolver(flags)]}
            ellipsizeMode={ellipsizeMode}>
            {id ? String.i18n(id, count, params) : children}
        </Text>
    ),
);

export const ClickableTitle = React.memo(
    ({
        children, numberOfLines = 2, characterLimit = 30, id, ns = id || 'title', count, params, style, ...flags
    }) => {

        const [showAll, setShowAll] = React.useState(false);
        return (
            <TouchableOpacity
                style={[
                    styles.headLine,
                    {
                        paddingLeft: 0,
                        marginLeft: 0,
                    },
                ]}
                onPress={() => setShowAll(!showAll)}>
                <Text
                    numberOfLines={showAll ? 0 : numberOfLines}
                    accessibilityLabel={ns}
                    style={[styles.headLine, style, textStyleResolver(flags)]}>
                    {(id ? String.i18n(id, count, params) : children)?.substring(
                        0,
                        // eslint-disable-next-line no-nested-ternary
                        Platform.OS === 'web' ? (showAll ? undefined : characterLimit * numberOfLines) : undefined,
                    )}
                </Text>
            </TouchableOpacity>
        );

    },
);

export const TitleHighlight = ({
    children,
    keyword = '',
    numberOfLines = 2,
    id,
    ns = id || 'title',
    count,
    params,
    style,
    searchFunction = strongSearch,
    containerStyle,
    ...flags
}) => (
    <TextHighliter
        style={[styles.headLine, style, textStyleResolver(flags)]}
        numberOfLines={numberOfLines}
        accessibilityLabel={ns}
        wrap="wrap"
        value={id ? String.i18n(id, count, params) : children}
        keyword={keyword}
        searchFunction={searchFunction}
        containerStyle={containerStyle}
    />
);

Title.Bigger = props => <Title style={styles.headLine_bigger} {...props} />;

export const Subtitle = React.memo(
    ({
        children, numberOfLines = 2, id, ns = id || 'subtitle', count, params, style, ...flags
    }) => (
        <Text
            numberOfLines={numberOfLines}
            accessibilityLabel={ns}
            style={[styles.subtitle, style, textStyleResolver(flags)]}>
            {id ? String.i18n(id, count, params) : children}
        </Text>
    ),
);

export const SubtitleHighlight = React.memo(
    ({
        children,
        keyword,
        numberOfLines = 2,
        id,
        ns = id || 'subtitle',
        count,
        params,
        style,
        searchFunction = strongSearch,
        textStyle,
        containerStyle,
        ...flags
    }) => (
        <TextHighliter
            style={[styles.subtitle, style, textStyleResolver(flags)]}
            keyword={keyword}
            numberOfLines={numberOfLines}
            wrap="wrap"
            searchFunction={searchFunction}
            value={id ? String.i18n(id, count, params) : children}
            ns={ns}
            textStyle={textStyle}
            containerStyle={containerStyle}
        />
        // <Text
        //     numberOfLines={numberOfLines}
        //     accessibilityLabel={ns}
        //     style={[styles.subtitle, style, textStyleResolver(flags)]}
        // >{id ? String.i18n(id, count, params) : children}
        // </Text>
    ),
);

export const SubtitleDark = props => <Subtitle style={{ paddingLeft: 2 }} color="$darkGrayTextColor" {...props} />;

Subtitle.Bigger = props => <Subtitle style={styles.subtitle_bigger} {...props} />;
Subtitle.Warning = props => <Subtitle style={{ color: COLORS.WARNING }} {...props} />;

/**
 * Accessories.
 */

export const LoadingIndicator = ({ style, indicatorSize = 50, color = '#56CCF2' }) => (
    <View accessibilityLabel="loadingIndicator" style={[{ paddingVertical: 20 }, style]}>
        <ActivityIndicator animating size={Platform.OS === 'ios' ? 1 : indicatorSize} color={color} />
    </View>
);

export const BadgeRed = ({
    badge, top = 0, right = 0, style, textStyle,
}) => (
    <View
        style={[
            styles.badge,
            {
                top,
                right,
            },
            style,
        ]}>
        <Text style={[styles.badgeText, textStyle]} accessibilityLabel={`text:badge:${badge}`}>
            {badge}
        </Text>
    </View>
);

BadgeRed.Notification = props => <BadgeRed style={styles.badge_notification} {...props} />;

export const WithBadge = ({
    children, style, badge, onPress, notification, badgeStyle, top, right,
}) => {

    const content = (
        <View style={style}>
            {children}
            {!badge ? null : (
                <BadgeRed
                    badge={badge}
                    textStyle={{
                        padding: 0,
                        margin: 0,
                    }}
                    style={badgeStyle}
                    right={right}
                    top={top}
                />
            )}
            {!notification || notification === 0 ? null : <BadgeRed.Notification />}
        </View>
    );

    return onPress ? <TouchableOpacity onPress={onPress}>{content}</TouchableOpacity> : content;

};

export const BadgePendings = ({
    badge, top = 0, right = 0, style, textStyle,
}) => (
    <View
        style={[
            styles.badgePendings,
            {
                top,
                right,
            },
            style,
        ]}>
        <Text style={[styles.badgePendingsText, textStyle]} accessibilityLabel={`text:badge:${badge}`}>
            {badge}
        </Text>
    </View>
);

/**
 * Layout.
 */

export const boxStyleResolver = ({
    centered, vertical, justify, flex,
}) => [
    {
        flexWrap: 'nowrap',
        flexDirection: vertical ? 'column' : 'row',
        justifyContent: justify || 'flex-start',
    },
    centered && { alignItems: 'center' },
    flex && { flex: 1 },
];

export const SectionHeader = (props) => {

    const {
        title,
        titleIcon,
        count,
        countOf,
        params,
        right,
        containerStyles,
        textStyles,
        capitalize = false,
        isLoading = false,
        numberOfLines = 0,
        notRequired,
        mandatory = false,
        directTitle,
    } = props;
    if (!title) {

        return null;

    }
    return (
        <View style={[styles.sectionHeader, containerStyles]} accessibilityLabel="sectionHeader">
            <View
                style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    flex: 1,
                }}>
                <RText
                    style={[styles.sectionHeaderText, textStyles]}
                    id={title}
                    count={countOf ? countOf.length : count}
                    capitalize={capitalize}
                    params={params}
                    numberOfLines={numberOfLines}
                    notRequired={notRequired}
                    directText={directTitle}
                />
                {titleIcon}
                {isLoading ? (
                    <LoadingIndicator style={{ marginLeft: Platform.OS === 'ios' ? 16 : 6 }} indicatorSize={22} />
                ) : null}
                {!!mandatory && (
                    <RText
                        style={[
                            styles.sectionHeaderText,
                            textStyles,
                            {
                                color: COLORS.ERROR,
                                marginLeft: 0,
                                paddingLeft: 0,
                                fontSize: 22,
                            },
                        ]}
                        numberOfLines={numberOfLines}
                        id={'*'}
                    />
                )}
            </View>
            {right}
        </View>
    );

};

export const Section = (props) => {

    const {
        title,
        header,
        white,
        children,
        ns = `${tail(title) || (white ? 'white' : 'normal')}`,
        notRequired,
        mandatory = false,
        style: stl,
    } = props;
    return (
        <View
            accessibilityLabel={`section:${ns}`}
            style={[
                styles.section,
                boxStyleResolver(
                    {
                        ...props,
                        notRequired,
                        vertical: true,
                    },
                    white ? { paddingHorizontal: 0 } : null,
                ),
                stl,
            ]}>
            {header || <SectionHeader {...props} notRequired={notRequired} mandatory={mandatory} />}
            <View
                style={[
                    white ? styles.children : null,
                    {
                        flexGrow: 1,
                        flexShrink: 1,
                    },
                ]}>
                {children}
            </View>
        </View>
    );

};

Section.ForList = props => (
    <Section flex justify="flex-start" style={{ marginBottom: 0 }} {...props}>
        {props.children}
    </Section>
);

Section.White = (props) => {

    const {
        children, ns = 'section', style: stl, headerStyle,
    } = props;
    return (
        <View
            accessibilityLabel={ns}
            style={[
                boxStyleResolver({
                    ...props,
                    vertical: true,
                }),
                {
                    marginTop: 12,
                    marginBottom: 8,
                    paddingHorizontal: 0,
                },
                stl,
            ]}>
            <SectionHeader {...props} containerStyles={headerStyle} />
            <View
                style={{
                    paddingHorizontal: 0,
                    backgroundColor: 'white',
                    flex: 1,
                }}>
                {children}
            </View>
        </View>
    );

};

export const Box = React.memo((props) => {

    const {
        children, pressHandler, onPress = pressHandler, style: st, ns = 'box', vertical, gap, onLayout,
    } = props;
    const style = [boxStyleResolver(props), st];
    const [ch0, ...rest] = React.Children.toArray(children);
    if (ch0) {

        ch0.key = 0;

    }
    const content = [ch0];
    rest.forEach((ch) => {

        if (gap) {

            content.push(
                <View key={content.length} style={{ [vertical ? 'height' : 'width']: gap <= 2 ? gap * unit : gap }} />,
            );

        }
        content.push({
            ...ch,
            key: content.length,
        });

    });
    return onPress ? (
        <TouchableOpacity style={style} onPress={onPress} accessibilityLabel={ns} onLayout={onLayout}>
            {content}
        </TouchableOpacity>
    ) : (
        <View style={style} accessibilityLabel={ns} onLayout={onLayout}>
            {content}
        </View>
    );

});

export const VBox = props => <Box vertical {...props} />;

export const Panel = React.memo(
    ({
        children, ns = 'panel', pressHandler, onPress = pressHandler, style = {}, ...flags
    }) => (onPress ? (
        <TouchableOpacity
            accessibilityLabel={ns}
            style={[styles.panel, ...boxStyleResolver(flags)]?.concat?.(style)}
            onPress={onPress}>
            {children}
        </TouchableOpacity>
    ) : (
        <View accessibilityLabel={ns} style={[styles.panel, ...boxStyleResolver(flags)]?.concat?.(style)}>
            {children}
        </View>
    )),
);

export const Brilliant = ({ hidden }) => (hidden ? null : (
    <View
        style={{
            borderBottomWidth: 1,
            borderBottomColor: '#E9E9E9',
        }}
    />
));
