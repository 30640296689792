/**
 * Application Configuration.
 * Auto-generated file. Do not edit.
 * @vendor https://epam.com
 */
export const ID = 'aimd';
export const MODE = 'prod';
export const VERSION = '2.2.868-prod.dev-074.9e8fbc879';
export const DEFAULT_LOCALE = 'ru-RU';
export const GOOGLE_WEB_CLIENT_ID = '137792746040-uqajlhfupn54c36o9qif29perc268ah7.apps.googleusercontent.com';
export const API_HOST = 'aibolit.md';
export const MY_API_HOST = 'my2.aibolit.md';
export const UMS_API_HOST = 'ums2.aibolit.md';
export const FIRESTORE = 'aibolitmd-member-prod';
export const SUPPORT_EMAIL = 'support@aibolit.md';
export const USE_MEDICINE_SEARCH = '1';
export const HIDE_CITY_SELECTOR = '0';
export const HIDE_TEST_TAB = '0';
export const DISABLE_ADD_MANUAL_VISIT = '0';
export const DEFAULT_LOCATION = 17;
export const DISABLE_SERVICES = 0;
export const DEFAULT_PHONE_VALUE = '+375';
export const COUNTRY_CODE = '+375';
export const COUNTRY_OPERATORS = ['44', '33', '29', '22', '25', '65'];
export const COUNTRY_TEST_OPERATORS = ['55'];
export const COUNTRY_PHONE_LENGTH = 12;
export const SIGNIN_PLACEHOLDER = '(__) ___ __ __';
export const SIGNIN_MASK = '(99) 999 99 99';
export const USE_SMS_ASSISTANT = true;
export const DEFAULT_LANGUAGE = 'ru';
export const ALLOWED_LANGUAGES = [];
export const ALLOWED_CITIES = [];
export const DISABLE_PROGRAM_CHOOSE = false;
export const DISABLE_INSTALL_MOBILE_APP_MESSAGE = false;
export const USE_SPACE_IN_NAME = false;
export const TIMEZONE = 'Europe/Minsk';
export const QTY_MONTHS_FOR_TIMETABLE = 2;
export const GOOGLE_API_KEY = 'AIzaSyBhQKT1-V62SwgT7RMdA34h6FUBaB_7zF0';
export const CONVERT_TIMEZONE = false;
export const SIGNIN_MASK_MOBILE = '([00]) [000] [00] [00]';
export const DISABLE_ADD_ADULT_RELATIVE = false;
export const DISALLOWED_CITIES = [33];
export const DOCTOR_SEARCH_LIMIT = 50;
