import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { getAllFeedbacksActivePage } from '../../../selectors';
import { React, TabSelector } from '../../../common';
import { setAllFeedbacksActivePage } from '../../../actions/feedbacks';
import { sortBy } from '../../../utils';
import { AllFeedbacksMyFilterHeader } from './AllFeedbacksMyFilter';

export const ALL_FEEDBACKS_TABS = [
    {
        id: 'my',
        name: 'titles.allFeedbacksMyPage',
        visible: true,
        order: 1,
    },
    {
        id: 'search',
        name: 'titles.allFeedbacksSearchPage',
        visible: false,
        order: 0,
    },
];

export const useAllFeedbacksPage = () => {

    const activePage = useSelector(getAllFeedbacksActivePage);
    const [pages] = useState(
        sortBy(
            ALL_FEEDBACKS_TABS?.filter(({ visible }) => visible),
            'order',
        ),
    );
    return {
        activePage,
        pages,
    };

};

export const AllFeedbacksHeader = () => {

    const { activePage, pages } = useAllFeedbacksPage();
    const dispatch = useDispatch();

    const setActivePage = (page) => {

        dispatch(setAllFeedbacksActivePage(page?.id));

    };

    if (pages?.length === 1) {

        if (activePage !== pages[0].id) {

            dispatch(setAllFeedbacksActivePage(pages?.[0]?.id));

        }
        if (activePage === 'my') {

            return <AllFeedbacksMyFilterHeader />;

        }
        return null;

    } else if (!pages?.length) {

        return null;

    }

    return (
        <>
            <TabSelector items={pages} selected={activePage} onItem={setActivePage} />
            {activePage === 'my' && <AllFeedbacksMyFilterHeader />}
        </>
    );

};
