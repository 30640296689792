import { Img, React, Stub } from '../../../common';

export const StubMyFeedbacksEmpty = () => {

    return (
        <Stub
            type="empty"
            img={<Img.Device style={{ marginTop: 16 }} />}
            style={{
                marginVertical: 24,
                marginHorizontal: 64,
            }}
            title={Object.R('stub.myFeedbacksEmptyTitle')}
            subTitle={Object.R('stub.myFeedbacksEmptySubtitle')}
            titleStyle={{ height: 24 }}
        />
    );

};
