import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedService, getServicesIsLoading } from '../selectors/services';
import {
    ActivityIndicator,
    Platform,
    React,
    Section,
    selectOptions,
    SingleNavigation,
    Title,
    TouchableOpacity,
} from '../common';
import { setSelectedService, setSelectedServiceByAssignment } from '../actions/services';
import { useServiceListSelector } from '../hooks/services';
import { getFormValues } from '../selectors/forms';
import actions from '../actions';
import { COLORS } from '../styles/colors';
import { TapBarText } from '../common/DatePicker';
import { getCurrentProgram } from '../selectors';
import tracking from '../services/tracking';

const removeTrailingDot = (inputString) => {
    return inputString.replace(/\.$/, '');
};

const isSameService = (id, serviceId) => removeTrailingDot(id ?? '')?.trim?.() === removeTrailingDot(serviceId ?? '')?.trim?.();

export const ServiceSelector = ({
    assignmentId, readOnly, isWarning = false, hideDescription = false, onSelect,
}) => {
    const list = useServiceListSelector(assignmentId);
    const selectedService = useSelector(getSelectedService);
    const isLoading = useSelector(getServicesIsLoading);
    const currentProgram = useSelector(getCurrentProgram);
    const dispatch = useDispatch();
    const { umsServices } = useSelector(getFormValues);
    const [warning, setWarning] = useState(isWarning);

    useEffect(() => {
        setWarning(selectedService?.id ? false : isWarning);
    }, [isWarning, selectedService]);

    useEffect(() => {
        let doSelect = false;
        if (selectedService && list?.length) {
            if (!list.find(({ id }) => isSameService(id, selectedService?.id))) {
                dispatch(setSelectedService());
                doSelect = true;
            }
        } else if (!selectedService && list?.length && umsServices?.length) {
            const isNeedToPreselect =
                list.filter(({ code }) => umsServices?.find(({ codeAsIs }) => isSameService(codeAsIs, code))).length ===
                1;
            if (isNeedToPreselect) {
                const found = list?.findIndex?.(({ code }) => isSameService(umsServices[0]?.codeAsIs, code));
                if (found > -1) {
                    dispatch(setSelectedService(list[found]));
                    doSelect = true;
                }
            }
        }
        if (!doSelect && !selectedService && list?.length) {
            const found = list?.findIndex?.(({ defaultService }) => defaultService);
            if (found > -1) {
                dispatch(setSelectedService(list[found]));
            }
        }
    }, [list, selectedService, umsServices]);
    // const onClear = () => {
    //     dispatch(setSelectedService(null));
    // };
    const coverege = currentProgram && selectedService?.programCoverage?.[currentProgram?.id];
    return isLoading ? (
        <ActivityIndicator size="big" />
    ) : (
        <>
            <SingleNavigation
                title={selectedService?.name ? selectedService.name : Object.R('titles.selectServicePlaceholder')}
                style={[
                    { marginHorizontal: 0 },
                    warning
                        ? {
                            borderColor: COLORS.ERROR,
                            borderTopColor: COLORS.ERROR,
                            borderLeftColor: COLORS.ERROR,
                            borderRightColor: COLORS.ERROR,
                            borderBottomColor: COLORS.ERROR,
                        }
                        : {},
                ]}
                textStyle={{ color: selectedService?.name ? COLORS.STANDARD_GRAY : COLORS.DARK_GRAY }}
                showIndicator
                smallCoverer
                coverer={coverege?.coverage ? currentProgram.coverer : null}
                isLimited={coverege?.attributes}
                onPress={() => {
                    if (readOnly) {
                        return;
                    }
                    selectOptions({
                        title: Object.R('titles.selectServicePlaceholder'),
                        data: list,
                        selected: selectedService?.id,
                        isWithSearch: true,
                        noLastLine: true,
                        noTextLim: true,
                        program: currentProgram,
                        onSelect: (v) => {
                            dispatch(setSelectedService(v));
                            dispatch(setSelectedServiceByAssignment(assignmentId, v));
                            actions.setFormValue('umsServices', []);
                            tracking.logEvent('select_service', { assignmentId });
                            onSelect && onSelect(v);
                        },
                    });
                }}
            />
            {selectedService?.description && !hideDescription ? (
                <Title numberOfLines={null} style={{ marginTop: 10 }}>
                    {selectedService?.description}
                </Title>
            ) : null}
        </>
    );
};

export const ServiceDescription = ({ numberOfLines = 3 }) => {
    const selectedService = useSelector(getSelectedService);
    const [fullSize, setFullSize] = useState(false);
    const [showArrow, setShowArrow] = useState(Platform.OS === 'web');

    const layoutHandler = (e) => {
        // console.log('layoutTexthandler', e.nativeEvent);
        // const fontSize = 16;
        // const lineHeight = 1;
        // const lines = (lineHeight + fontSize) * (numberOfLines + 1);
        // const height = e?.nativeEvent?.lines?.reduce?.((acc, current) => acc + current?.capHeight, 0) ?? 0;
        if (Platform.OS !== 'android') {
            const text = e?.nativeEvent?.lines?.reduce?.((acc, current) => acc + current?.text, '') ?? '';
            const same = text === selectedService?.description;
            setShowArrow(!same || fullSize);
        } else {
            setShowArrow((e?.nativeEvent?.lines?.length ?? numberOfLines + 1) > numberOfLines);
            // setShowArrow(height >= lines);
        }
    };
    return selectedService?.description ? (
        <Section.White
            style={{
                borderBottomWidth: 1,
                borderColor: COLORS.LIGHT_GRAY,
            }}
            headerStyle={{
                borderBottomWidth: 1,
                borderColor: COLORS.LIGHT_GRAY,
            }}
            title="titles.serviceDescription"
            header="Test header"
            right={
                showArrow ? (
                    <TapBarText
                        close={!fullSize}
                        onPress={() => setFullSize(!fullSize)}
                        openText={Object.R('titles.serviceDescriptionMore')}
                        closeText={Object.R('titles.serviceDescriptionLess')}
                    />
                ) : null
            }>
            <TouchableOpacity onPress={() => setFullSize(!fullSize)}>
                <Title
                    onTextLayout={layoutHandler}
                    numberOfLines={fullSize ? null : numberOfLines}
                    style={{
                        marginTop: 16,
                        marginBottom: 16,
                        paddingHorizontal: 16,
                    }}>
                    {selectedService?.description}
                </Title>
            </TouchableOpacity>
        </Section.White>
    ) : null;
};

export const ServiceSection = ({
    assignmentId, readOnly, isWarning, onSelect,
}) => {
    return (
        <ServiceSelector
            assignmentId={assignmentId}
            readOnly={readOnly}
            isWarning={isWarning}
            hideDescription
            onSelect={onSelect}
        />
    );
};
