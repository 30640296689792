import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import {
    BigButton, Logo, Page, React, Section,
} from '../../common';
import { getIsFetching, getUserRelatives } from '../../selectors';
import api from '../../services/api';
import actions from '../../actions';
import { setFormData } from '../../actions/forms';

export const RelativeGrants = Page.register(
    ({ relativeId, isFetching, relatives = [] }) => {
        const relative = relatives.find(e => e.profileId === relativeId);
        const { profileId, grantLevel, name } = relative || {};
        const noAccessToVisits = !(grantLevel === 'All' || grantLevel === 'BOOK_VISITS');
        const onPress = async () => {
            await actions.startFetching();
            try {
                await api.updateGrunts([
                    {
                        profileId,
                        level: noAccessToVisits ? 'BOOK_VISITS' : 'VIEW_COVERAGE',
                    },
                ]);
            } catch (e) {
                await actions.stopFetching();
            }
        };
        const dispatch = useDispatch();
        const navigator = useNavigation();
        useEffect(() => {
            dispatch(
                setFormData({
                    RelativeGrants: {
                        back: () => {
                            navigator.goBack();
                            return true;
                        },
                    },
                }),
            );
        }, []);
        return (
            <Page name="relative-grants" isLoading={isFetching}>
                <Section centered>
                    <Logo.Wrapper
                        title={noAccessToVisits ? 'titles.allow_visits_management' : 'titles.forbid_visits_management'}
                        subtitle={Object.R(noAccessToVisits ? 'titles.pressAllow' : 'titles.pressForbidden', { fullName: name })}
                        titleStyle={{ paddingBottom: 12 }}
                    />
                </Section>
                <Section>
                    <BigButton
                        title={noAccessToVisits ? 'buttons.allow' : 'buttons.forbid'}
                        action={onPress}
                        danger={!noAccessToVisits}
                        capitalize
                    />
                </Section>
            </Page>
        );
    },
    {
        isFetching: { from: getIsFetching },
        relatives: { from: getUserRelatives },
    },
);
