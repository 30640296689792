import { API_HOST, MODE } from '../config';
import {
    calculateDistance, capitalize, sortBy, urlStringify,
} from '../utils';
import { getCurrentPosition } from './device';

const assert = (flag, msg) => {
    if (!flag) {
        throw new Error(msg);
    }
    return flag;
};

const apiUrl = `https://${MODE === 'prod' ? 'pub2.' : ''}${API_HOST}/api/v2/public/tabletka/v3`;
const searchTail = '/search';

const client = () => Object.fetchBlob.config({
    followRedirect: false,
    trusty: true,
    timeout: 10000, // 10 seconds timeout
});

const getWith = (params, urlTail, rootUrl = searchTail) => client()
    .fetch(
        'GET',
        urlStringify({
            target: `${apiUrl}${rootUrl}${urlTail}`,
            params,
        }),
    )
    .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        throw new Error(Object.R('titles.errorSearchDrugs'));
    })
    .then((response) => {
        const st = response.respInfo.status;
        if (st === 471 || st === 476 || st === 470 || st === 472) {
            return [];
        }
        assert(response.respInfo.status === 200, Object.R('titles.errorSearchDrugs'));
        return urlTail === '/apt'
            ? response.json().map((e) => {
                e.lsNum = params.ls_num;
                return e;
            })
            : response.json();
    });

export const getDrugsInfoByNameAndId = async (drugs, location) => {
    return Promise.all(
        drugs.map(async (drug) => {
            const d = await getWith(
                {
                    ls: drug.ls_name,
                    region: location,
                    limit: 5,
                },
                '/drugs',
            );
            let found = null;
            d?.some((item) => {
                found = item?.class?.find(e => e.ls_num === drug.ls_num);
                return !!found;
            });
            const res = {
                ...found,
                name: found?.ls_name,
                id: found?.ls_num,
                priceMin: Number(found?.price_min),
                priceMax: Number(found?.price_max),
            };
            if (Number.isNaN(res.priceMin)) {
                res.priceMin = 0;
            }
            if (Number.isNaN(res.priceMax)) {
                res.priceMax = 0;
            }
            return res;
        }),
    );
};

export const searchDrugs = async ({ keyword: ls, includeAnalogs = false, location }) => {
    const drugs = await getWith(
        {
            ls,
            region: location,
        },
        '/drugs',
    );
    let noClassDrugs;
    if (drugs[0]?.noclass.length > 4) {
        noClassDrugs = await getWith(
            {
                ls,
                region: location,
                limit: drugs[0]?.noclass_count,
            },
            '/noclass',
        );
    }
    const result = includeAnalogs
        ? drugs[0]?.class.concat(
            (noClassDrugs ? noClassDrugs.data : drugs[0]?.noclass) || [],
            drugs[0]?.mnn_analog || [],
        )
        : drugs[0]?.class.concat((noClassDrugs ? noClassDrugs.data : drugs[0]?.noclass) || []);
    return (result || []).map(e => ({
        ...e,
        id: e.ls_num || `${e.usr_num}${e.ls_name.toLowerCase()}`,
        key: e.ls_name.toLowerCase(),
        name: capitalize(e.ls_name),
        requirePrescript: Object.R(
            e.otc_rx === 'OTC' || !e.otc_rx ? 'titles.withoutPrescript' : 'titles.requirePrescript',
        ),
        producer: e.firm_name || e.sup_name,
        country: e.country_name,
        components: e.nlec_name,
        priceMin: Number(e.price_min),
        priceMax: Number(e.price_max),
        price: Number(e.ls_price),
        pricingText: e.ls_price ? `от ${e.ls_price}` : `от ${e.price_min} до ${e.price_max} руб`,
        tar: e.tar_name,
        aptId: e.usr_num,
    }));
};

export const getDrugsSuggestions = async (keyword) => {
    const result = await getWith({ q: keyword }, '/autocomplete');
    return result.map(e => ({ name: capitalize(e.ls_name) }));
};

const getIsOpenToday = (worksToday) => {
    if (worksToday === '1000-1000' || !worksToday) {
        return 0;
    }
    const currentDate = new Date();
    const workingTimeAsString = worksToday.split(/[-.]+/);
    const workingTime = workingTimeAsString.map(e => Number(e));
    const startDate = new Date();
    startDate.setHours(workingTime[0]);
    startDate.setMinutes(workingTime[1]);
    const endDate = new Date();
    endDate.setHours(workingTime[2]);
    endDate.setMinutes(workingTime[3]);
    return currentDate.valueOf() >= startDate.valueOf() && currentDate.valueOf() <= endDate.valueOf() ? 1 : 0;
};

export const getDrugsStores = async (drugsAll, sortOrder = 'totalPrice', location = 1001) => {
    const drugs = drugsAll.filter(e => e.selected);
    const {
        coords: { latitude: lat, longitude: lng },
        isFailed,
    } = await getCurrentPosition();
    // const drugsHash = drugs.reduce((r, e) => ({ ...r, [e.id]: e }), {});
    const raw = await Promise.all(
        drugs.map(({ id, aptId, apt_cnt: aptCount }) => (aptId
            ? getWith({ apt_id: aptId }, '/list', '/pharmacies')
            : getWith(
                {
                    ls_num: id,
                    region: location,
                    limit: aptCount,
                    bmp: 1,
                },
                '/apt',
            ))),
    );
    const prices = {};
    const hash = []
        .concat(...raw.filter(e => e))
        .map(
            ({
                apt_id: aptId,
                id,
                name,
                phones,
                address,
                geo_x: longitude,
                geo_y: latitude,
                lsNum,
                price_list: priseList,
                work_today: workToday,
                work_msg: workMsg,
                is_delivery: isDelivery,
                is_order: isOrder, // Preferential dispensing of medicines
                is_reservation: isReservation,
                is_open: isOpen,
                work1,
                work2,
                work3,
                work4,
                work5,
                work6,
                work7,
                mon,
                tue,
                wed,
                thu,
                fri,
                sat,
                san,
            }) => ({
                id: id || aptId,
                mon: mon || work1 || null,
                tue: tue || work2 || null,
                wed: wed || work3 || null,
                thu: thu || work4 || null,
                fri: fri || work5 || null,
                sat: sat || work6 || null,
                san: san || work7 || null,
                phones,
                address,
                name,
                latitude: Number(latitude),
                longitude: Number(longitude),
                lsNum,
                price: priseList && Number(priseList[0]?.price),
                count: priseList && Number(priseList[0]?.amount),
                drugs: [],
                drugsHash: {},
                workToday,
                workMsg,
                isDelivery,
                isOrder,
                isReservation,
                isOpen: isOpen || getIsOpenToday(workToday),
            }),
        )
        .reduce((acc, e) => {
            const item = acc[e.id] || (acc[e.id] = e);
            const drug = drugs.find(d => (e.lsNum ? d.id === e.lsNum : d.aptId === e.id));
            const price = Number(e.price || drug?.price);
            const drugId = e.lsNum;
            if (prices[drugId]) {
                prices[drugId] = {
                    min: Math.min(prices[drugId].min ?? 0, price),
                    max: Math.max(prices[drugId].max ?? 0, price),
                };
            } else {
                prices[drugId] = {
                    min: price,
                    max: price,
                };
            }
            const ls = {
                ...drug,
                price,
                count: e.count,
            };
            item.drugsHash[ls.id] = ls;
            item.drugs.push(ls);
            return acc;
        }, {});
    const listWithLackOf = Object.values(hash).map(e => ({
        ...e,
        capacity: e.drugs.length === drugs.length ? 1 : e.drugs.length / drugs.length,
        phones: (e.phones || '')
            .split(';')
            .filter(x => x)
            .map(s => ({ label: s.trim() })),
        distance: calculateDistance(lat, lng, e.latitude, e.longitude),
        distanceError: isFailed,
        totalPrice:
            Math.round(e.drugs.reduce((acc, { price, qty = 1 }) => Number(price) * Number(qty) + acc, 0) * 100) / 100,
        lackOf: drugs
            .filter(ee => !e.drugsHash[ee.id])
            .map(i => ({
                ...i,
                priceMax: prices[i.ls_num] ? prices[i.ls_num]?.max : i.priceMax ?? 0,
                priceMin: prices[i.ls_num] ? prices[i.ls_num]?.min : i.priceMin ?? 0,
            })),
    }));
    const list = listWithLackOf
        .map(e => ({
            ...e,
            rank:
                1 + // non-zero
                (1 - e.capacity) * 100 + // lack of capacity
                e.distance * 5 + // far away
                e.totalPrice + // total price of presenting stuff
                e.distance * e.lackOf.reduce((r, ee) => r + ee.priceMax, 0), // price of absence
        }))
        .map(e => ({
            ...e,
            rankOrder: e.rank, //
            distanceOrder: e.distance + (1 - e.capacity) * 100000, //
            totalPriceOrder: e.totalPrice + (1 - e.capacity) * 100000, //
        }));

    return {
        data: sortBy(
            list.filter(e => e.rank < 100000 && e.latitude && e.longitude),
            `${sortOrder}Order`,
        ),
        isFailed,
    };
};
