/* eslint-disable no-underscore-dangle */
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo, useState } from 'react';
import { CommonActions, useFocusEffect, useNavigation } from '@react-navigation/native';
import {
    InfoList, Page, React, Section, showNotification, VBox,
} from '../../../common';
import { getUser, plannedVisits } from '../../../selectors';
import { gettr } from '../../../selectors/utils';
import { useServiceListSelector } from '../../../hooks/services';
import { getSelectedService, getServiceByAssignmentId } from '../../../selectors/services';
import { trimObjectFields } from '../../../utils/strings';
import { parseToDdMonYyyy } from '../../../utils/dates';
import { RegistryInformation } from '../RegistryInformation';
import { getFinalMessage } from '../../../services/http';
import { setSelectedService } from '../../../actions/services';
import actions from '../../../actions';
import { checkNotifications } from '../../../init';
import { checkRateRequestTime } from '../../../combo';
import { storage } from '../../../services/localstorage';
import { useStdBackNavigation } from '../../../hooks/useStdBackNavigation';
import { getManualVisitors } from '../../../selectors/forms';
import { ProfileSelector } from './ProfileSelector';
import { loadVisitors } from './loadVisitors';
import { getError, getHintObject } from './errors';
import {
    getDefaultProgram, getProgramByCovererCode, isProgramAllowedToProfile, ProgramSelector,
} from './ProgramSelector';
import { Footer } from './Footer';
import { checkConsentReceived } from './checkConsentReceived';
import { makeBooking } from './makeBooking';
import { checkNearestVisits } from './checkNearestVisits';
import { ClinicConsent } from './ClinicConsent';

export const getIsProgramActive = user => user?.relatives?.find(p => p.isOwner) || user?.info?.isProgramActive;

export const EnrollVisitProfiles = Page.register(({ route: { params = {} } }) => {

    const info = useSelector(getUser);
    const {
        visitInfo = {},
        fromScreen,
        savedVisitor,
        savedProgram,
        visiterId,
        canReassign,
        startDate,
        endDate,
        canChangeDate,
        isEditingVisit,
    } = params;
    const {
        assignmentId, serviceId, orderId, covererCode, serviceName, doctorInfo, visitor, mdInfo = {},
    } = visitInfo;
    const clinicId = mdInfo?.id;
    const forWho = visiterId || visitor;
    const doctorCoverer = doctorInfo?.coverer;
    const canChangeVisitor = !(canReassign === false);
    const [profiles, setProfiles] = useState([]);
    const [selectedProfile, setSelectedProfile] = useState();
    const hasManual = useSelector(gettr('db'))?.enableAddPersonOnBooking;
    const selectedService = useSelector(getSelectedService);
    const [isLoading, setIsLoading] = useState(false);
    const currentService = useSelector(state => getServiceByAssignmentId(state, assignmentId, serviceId));
    const [error, setError] = useState();
    const [isButtonVisible, setButtonVisible] = useState(true);
    const [selectedProgram, setSelectedProgram] = useState();
    const [timeslotBusyText, setTimeslotBusyText] = useState('hint.timeslotIsBusy');
    const visits = useSelector(plannedVisits);
    const navigation = useNavigation();
    const userHavePrograms = !!Object.keys(info?.info?.programs ?? {})?.length;
    const dispatch = useDispatch();
    const manualVisitors = useSelector(getManualVisitors);
    const [consentReceived, setConsentReceived] = useState(false);
    const [consentReceivedManual, setConsentReceivedManual] = useState(false);

    useStdBackNavigation();
    const selectProfile = useCallback(
        (p = []) => {

            let result;
            const hasAllowedProfiles = p?.some?.(e => e?.onlineBookingAllowed);
            if (!canChangeVisitor && !selectedProfile) {

                result = p.find(e => e.profileId === visitInfo?.profileId);

            } else if (savedVisitor) {

                result = p.find(e => (savedVisitor?.manual
                    ? e?.fullNameRu === savedVisitor?.fullNameRu
                    : e.profileId === savedVisitor.profileId || e?.profileId === savedVisitor?._id));

            } else if (!selectedProfile) {

                if (typeof forWho === 'object') {

                    result = p?.find?.(e => e?.profileId === forWho.profileId);

                } else {

                    switch (forWho) {

                        case 'forMe':
                            result = p?.find?.(({ onlineBookingAllowed, isMe }) => onlineBookingAllowed && isMe);
                            break;
                        case 'forChild':
                            result = p?.find?.(e => e?.onlineBookingAllowed && e?.relKind === 'CHILD');
                            break;
                        case 'forRelative':
                            result = p?.find?.(
                                e => e?.onlineBookingAllowed && ['PARENT', 'SPOUSE'].includes(e?.relKind),
                            );
                            break;
                        default:
                            result = p?.find(e => e?.onlineBookingAllowed);
                            break;

                    }

                }

            }
            if (!result && hasAllowedProfiles) {

                result = p.find(e => e.onlineBookingAllowed);

            }
            !selectedProfile && setSelectedProfile(result);
            const e = selectedProfile?.id === 'empty' ? getError(selectedProfile) : getError(result, undefined, orderId);
            setError(e);

            if (savedProgram) {

                setSelectedProgram(savedProgram);
                return true;

            }
            canChangeVisitor && setSelectedProgram(getDefaultProgram(result, info?.info?.programs));
            !canChangeVisitor && setSelectedProgram(getProgramByCovererCode(covererCode, info?.info?.programs, result));

            return false;

        },
        [
            profiles,
            visitInfo,
            setSelectedProfile,
            canChangeVisitor,
            forWho,
            selectedProfile,
            savedVisitor,
            savedProgram,
        ],
    );

    useFocusEffect(
        useCallback(() => {

            const fromCache = !!(savedProgram || savedVisitor);
            // const listLength = filterAdultChild(profiles?.filter(p => !p?.manual))?.length ?? 0;
            setIsLoading(!fromCache);
            loadVisitors({
                info,
                assignmentId,
                serviceId: selectedService?.id ?? serviceId,
                requestBookCapabilities: canChangeVisitor || userHavePrograms,
                cacheRequest: fromCache,
                // listLength,
                manualVisitors,
            })
                .then((p) => {

                    setProfiles(p);
                    const setTheProgram = selectProfile(p);
                    if (visitInfo?.covererCode && !canChangeVisitor && !setTheProgram) {

                        const found =
                            savedProgram ||
                            Object.entries(info?.info?.programs ?? {})?.find?.(
                                ([, value]) => value.covererCode === visitInfo.covererCode &&
                                    isProgramAllowedToProfile(p, value?.programId, visitInfo?.profileId),
                            );
                        if (found) {

                            setSelectedProgram({
                                id: found[0],
                                ...found[1],
                            });

                        }

                    }

                })
                .catch(() => {})
                .finally(() => {

                    setIsLoading(false);

                });

        }, [info, assignmentId, serviceId, savedProgram, savedVisitor]),
    );

    useServiceListSelector(assignmentId, !(selectedService?.id || (serviceId && serviceId !== '0')));

    const infoFields = useMemo(
        () => ({
            ...visitInfo,
            date: parseToDdMonYyyy(visitInfo?.date ?? ''),
            service: selectedService?.name || serviceName,
            serviceDescription: selectedService?.description || currentService?.description,
        }),
        [selectedService, currentService, visitInfo],
    );

    const isButtonDisabled = useCallback(() => {

        return !error?.allowBooking || (!selectedProfile && !orderId) || !consentReceived;

    }, [error?.allowBooking, selectedProfile, orderId, consentReceived]);

    const onSubmit = useCallback(async () => {

        const consent = await checkConsentReceived(info?.info, selectedProfile);
        if (!consent) {

            return;

        }
        checkNearestVisits({
            startDate,
            endDate,
            visits,
            profile: selectedProfile,
            visitInfo,
            navigation,
            canReassign,
            canChangeDate,
            isEditingVisit,
            onBook: async () => {

                const wasNPAsked = await storage.get('wasNPAsked');
                makeBooking({
                    consentReceived,
                    manualConsent: consentReceivedManual,
                    info: info?.info,
                    profile: selectedProfile,
                    orderId,
                    onError: (e) => {

                        if (e?.status === 409) {

                            setButtonVisible(false);
                            setError({
                                message: (e.body || {}).detail,
                                allowBooking: false,
                                level: 'error',
                                code: undefined,
                                noSupport: true,
                            });
                            setTimeslotBusyText((e.body || {}).detail);

                        } else if (e.code === 'CODE_VISITOR_DATA_MISMATCH') {

                            Page.showModal(
                                <RegistryInformation
                                    mdInfo={visitInfo?.mdInfo}
                                    errorHint={getFinalMessage(e.body)}
                                    errorTitle={'titles.changeVisit'}
                                    startDate={visitInfo?.startDate ?? visitInfo?.date}
                                    time={visitInfo?.time}
                                />,
                            );

                        } else if (e?.status === 423) {

                            navigation.goBack();
                            showNotification(
                                `${(e.body || {}).detail}\n${visitInfo?.mdInfo?.phones?.join?.('\n')}`,
                                false,
                            );

                        } else {

                            setError({
                                message: getFinalMessage(e.body),
                                allowBooking: false,
                                level: 'error',
                            });

                        }

                    },
                    onLoadingState: setIsLoading,
                    selectedService,
                    serviceId,
                    program: selectedProgram,
                    assignmentId,
                    visitInfo,
                    onSuccess: () => {

                        setError();
                        if (fromScreen === 'DoctorRecordDatePick') {

                            navigation?.pop(1);

                        }
                        dispatch(setSelectedService());
                        actions.setSelectedService();
                        navigation.dispatch(
                            CommonActions.reset({
                                index: 0,
                                routes: [{ name: 'Home' }],
                            }),
                        );
                        if (!wasNPAsked) {

                            checkNotifications('From visit', () => {

                                if (!isEditingVisit) {

                                    checkRateRequestTime(() => {

                                        setTimeout(() => showNotification(
                                            Object.R('titles.successfulBooking', {
                                                date: parseToDdMonYyyy(visitInfo?.date ?? ''),
                                                time: visitInfo?.time,
                                            }),
                                        ), 100);

                                    });

                                }

                            });

                        } else {

                            setTimeout(() => showNotification(
                                Object.R('titles.successfulBooking', {
                                    date: parseToDdMonYyyy(visitInfo?.date ?? ''),
                                    time: visitInfo?.time,
                                }),
                            ), 100);

                        }
                        if (!isEditingVisit) {

                            checkRateRequestTime(() => {

                                showNotification(
                                    Object.R('titles.successfulBooking', {
                                        date: parseToDdMonYyyy(visitInfo?.date ?? ''),
                                        time: visitInfo?.time,
                                    }),
                                );

                            });

                        }
                        // consoleLog('success booking', param);

                    },
                });

            },
        });

    }, [info?.info, selectedProfile, startDate, endDate, visits, visitInfo, navigation, canReassign, canChangeDate, isEditingVisit, orderId, selectedService, serviceId, selectedProgram, assignmentId, fromScreen, dispatch, consentReceived, consentReceivedManual]);

    const onProfileSelect = useCallback(
        (v) => {

            setSelectedProfile(v);
            const defaultProgram = getDefaultProgram(v, info?.info?.programs);
            setSelectedProgram(defaultProgram);
            setError(getError(v,undefined, orderId));
            navigation.setParams({ savedVisitor: v });
            navigation.setParams({ savedProgram: defaultProgram });

        },
        [selectedProfile, error, setError],
    );

    const onChangeProgramHandler = (program) => {

        setSelectedProgram(program);
        navigation.setParams({ savedProgram: program });

    };

    const getHint = useCallback(() => {

        return getHintObject({
            profile: selectedProfile,
            coverer: doctorCoverer,
            covererCode,
            ownerHasActiveProgram: getIsProgramActive(info?.info),
        });

    }, [selectedProfile, doctorCoverer, covererCode, info?.info, timeslotBusyText]);

    const onConsentHandler = (received, manual) => {

        setConsentReceived(received);
        setConsentReceivedManual(manual);

    };

    return (
        <>
            <Page
                isLoading={isLoading}
                name={'enroll-visit'}
                footer={
                    <Footer
                        isButtonDisabled={isButtonDisabled()}
                        onSubmit={onSubmit}
                        isButtonVisible={isButtonVisible}
                        error={
                            !isButtonVisible
                                ? {
                                    message: timeslotBusyText,
                                    level: 'error',
                                }
                                : error
                        }
                        isLoading={isLoading}
                    />
                }
                hint={getHint()}>
                {!isLoading && (
                    <VBox>
                        <ProfileSelector
                            data={profiles}
                            selected={selectedProfile}
                            onChange={onProfileSelect}
                            hasManual={hasManual && canChangeVisitor}
                            isLoading={isLoading}
                            isError={error?.allowBooking === false}
                            enabled={canChangeVisitor}
                            orderId={orderId}
                        />
                        <ProgramSelector
                            profile={selectedProfile}
                            onChange={onChangeProgramHandler}
                            programs={info?.info?.programs}
                            isLoading={isLoading}
                            selected={selectedProgram}
                        />
                        <Section
                            title="titles.details_of_visit_header"
                            flex
                            justify="space-between"
                            textStyles={{
                                color: '#555555',
                                marginBottom: 12,
                            }}>
                            <InfoList fields="enrollVisitInfoFields" data={trimObjectFields(infoFields)} />
                        </Section>
                        <ClinicConsent clinicId={clinicId} onConsent={onConsentHandler} assignmentId={assignmentId} />
                    </VBox>
                )}
            </Page>
        </>
    );

});
