import { Dimensions } from 'react-native';

const {
    width,
    height,
} = Dimensions.get('window');

/**
 * layout.
 */

// units and measures:
export const unit = 12;

// paddings:
export const pb = { paddingBottom: unit };
export const ph = { paddingHorizontal: unit };
export const pv = { paddingVertical: unit };
export const pr = { paddingRight: unit };
export const pbHalf = { paddingBottom: unit / 2 };
export const padding = { ...ph, ...pv };
// margins:
export const mb = { marginBottom: unit };
export const mh = { marginHorizontal: unit };
export const mv = { marginVertical: unit };
export const ml = { marginLeft: unit };
export const mr = { marginRight: unit };
export const margin = { ...mh, ...mv };
// sizes:
export const fullScreen = {
    width,
    height,
};

/**
 * Typography
 */
export const lineHeight = 16;
export const fontSize = 12;

export const decor = {
    strikethrough: {
        textDecorationLine: 'line-through',
        textDecorationStyle: 'solid',
    },
};
