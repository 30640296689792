import { KeyboardAvoidingView } from 'react-native';
import {
    BigButton,
    Field,
    Keyboard,
    Logo,
    Page,
    Platform,
    React,
    Section,
    Text,
    TextInput,
    Title,
    View,
} from '../../common';
import { getUserInfo } from '../../selectors';
import { navigate } from '../../actions';
import { MODE } from '../../config';
import bns from '../../services/bns';
import { DateInput } from '../../common/DateInput';
import { validateByFieldType } from '../../common/fields';
import api from '../../services/api';
import { formatFullDate } from '../../utils';

export const BnsSignIn = Page.register(
    ({
        setState,
        error,
        isLoading,
        code = MODE === 'dev' ? '9745009238658' : '',
        isInvalid = false,
        showDateInput = error?.code === 'InputRequiredException' && error?.attributes?.covererCode === 'bns-limited',
        birthday,
        waitingResponse,
        relativeId,
        actions,
        onSubmit = async () => {
            try {
                if (showDateInput && birthday) {
                    const updateProfile = await api.updateUserInfo({ birthday: formatFullDate(birthday) });
                    if (!updateProfile || updateProfile.error) {
                        setState({ waitingResponse: false });
                        return;
                    }
                }
                bns.register(code.toUpperCase(), relativeId, async (result) => {
                    setState({ waitingResponse: false });
                    if (result.success) {
                        if (relativeId) {
                            await actions.reloadUserInfo(Object.handleNewUserItemsFound, actions.updatePrefs, actions.updateSelects);
                            await actions.syncUmsInfo();
                        }
                        navigate('Account');
                        return;
                    }
                    if (result?.error?.status === 404) {
                        Object.assign(result.error, { message: Object.R('titles.notFoundBnsError') });
                    } else if (result?.error?.code === 'CODE_NO_CHILD_LINKAGE') {
                        Object.assign(result.error, { message: Object.R('titles.codeNoChildLinkageBnsError') });
                    }
                    setState(result);
                });
            } finally {
                Keyboard.dismiss();
            }
        },
        setCode = (text) => {
            setState({
                code: Platform.OS === 'android' ? text : text.toUpperCase(),
                isInvalid: /[^А-Я\d-/]+/.test(text.toUpperCase()),
            });
        },
    }) => (
        <Page
            name="bns-signin"
            persistKeyboard
            notification={error && {
                ...error,
                onSubmit: error.type && error.type.includes('bns') ? null : onSubmit,
            }}
            isLoading={isLoading}
        >
            <KeyboardAvoidingView
                behavior="position"
                style={Platform.OS === 'ios' ? { paddingBottom: 100 } : {}}
            >
                <Section flex>
                    <Logo.Wrapper
                        title={showDateInput ? 'titles.bns_code' : 'titles.enterBnsCode'}
                        subtitle={showDateInput ? '' : 'titles.enterBnsCodeHint'}
                        wrapperStyle={{ marginTop: 16 }}
                        titleStyle={{ marginTop: 10 }}
                    >
                        <Field>
                            <TextInput
                                ns="code"
                                placeholder="placeholders.number"
                                value={code}
                                invalid={isInvalid}
                                onChange={setCode}
                                onEndEditing={Platform.OS === 'android' ? () => {
                                    setState({ code: code.toUpperCase() });
                                } : null}
                                autoCapitalize="characters"
                                autoCorrect={false}
                            />
                            {isInvalid
                                ? <Text style={{ color: '#DF655C' }}>{Object.R('error.text_format_error')}</Text> : null}
                        </Field>
                        {showDateInput
                            ? <View>
                                <Title bold style={{
                                    marginBottom: 10,
                                    marginTop: 4,
                                    textAlign: 'center',
                                }}>{Object.R('titles.enter_date_of_birth')}</Title>
                                <DateInput value={birthday} placeholder={Object.R('placeholders.date_of_birth')}
                                    onChange={date => setState({ birthday: date })}
                                    checkValidation={date => validateByFieldType({ type: 'date' }, date)}/>
                            </View> : null}
                        <BigButton action={waitingResponse ? () => {
                        } : () => {
                            setState({ waitingResponse: true });
                            onSubmit();
                        }} title="buttons.sendNumber" disabled={!code || isInvalid || (showDateInput && !birthday)}/>
                    </Logo.Wrapper>
                </Section>
            </KeyboardAvoidingView>
        </Page>
    ),
    { userInfo: { from: getUserInfo } },
);
