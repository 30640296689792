export const COLORS = {
    STANDARD_GRAY: '#555555',
    STANDARD_GRAY_DISABLED: '#888888',
    DARKER_GRAY: '#999999',
    DARK_GRAY: '#9C9C9C',
    DARK_GRAY1: '#919191',
    PLACEHOLDER_GRAY: '#ACACAC',
    LIGHT_GRAY: '#E9E9E9',
    POSITIVE: '#18B797',
    NEGATIVE: '#DF655C',
    MAIN: '#5C85DD',
    BUMBA_LINK: '#555555',
    ACTION: '#DF655C',

    WARNING: '#FAC665',
    ERROR: '#E2746C',

    OFFLINE: '#FABF5596',
    ONLINE: '#18B79780',
    UNAVAILABLE: '#BABECC80',
    BACKGROUND_GRAY: '#F2F2F2',
    BORDER_GRAY: '#E2E2E2',
    WHITE: '#FFFFFF',
    HIGHLIGHT_BACKGROUND: '#DDDDDD',
    EDIT_BORDER: '#E0E0E0',
    RED: '#FF0000',
};
