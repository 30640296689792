/* eslint-disable no-use-before-define */
import { useFocusEffect } from '@react-navigation/native';
import RNSmsRetriever from 'react-native-sms-retriever-api';
import TextInputMask from 'react-native-text-input-mask';
import { KeyboardAvoidingView } from 'react-native';
import {
    BigButton,
    Field,
    Image,
    InputWrapper,
    Keyboard,
    Logo,
    Page,
    Platform,
    React,
    Section,
    TextInput,
} from '../../common';
import {
    getDefaultSmsProvider, getIsUserFetching, getUserError, getUserLoadingState,
} from '../../selectors';
import { getMaskByPhonePrefix } from '../../utils';
import { storage } from '../../services/localstorage';
import { insertIntoString } from '../../utils/strings';
import { isPhoneNumberValid, maskNumbers } from '../../utils/phone';
import { user } from '../../services/user';
import { input as st } from '../../styles/input';

// const PhoneEdit = ({
//     onChangeText,
//     placeholder,
//     value,
//     style,
//     ns,
//     disabled,
//     textStyle,
//     multiline,
//     hideText,
//     selection,
//     placeholderTextColor,
//     invalid,
//     ...props
// }) => {
//     const [currentSelection, setCurrentSelection] = useState(selection);
//     if (disabled) {
//         return <View style={[st.input, st.disabled, style]}><Text style={textStyle}>{value}</Text></View>;
//     }
//     return (
//         <InputWrapper>
//             <TextInputRN
//                 onChangeText={onChangeText}
//                 value={value}
//                 style={[
//                     st.input,
//                     style,
//                     textStyle,
//                     invalid && value ? st.invalid : null,
//                     Platform.OS === 'web' && {
//                         outline: 'none',
//                         cursor: 'text',
//                     },
//                 ]}
//                 accessibilityLabel={`input:${ns}`}
//                 underlineColorAndroid="transparent"
//                 placeholder={Object.R(placeholder)}
//                 secureTextEntry={hideText}
//                 placeholderTextColor={placeholderTextColor}
//                 numberOfLines={1}
//                 multiline={multiline}
//                 onKeyPress={({ nativeEvent }) => {
//                 }
//                 }
//                 onSelectionChange={({ nativeEvent }) => {
//                     setCurrentSelection(nativeEvent);
//                 }
//                 }
//                 {...props}
//             />
//         </InputWrapper>
//     );
// };

/**
 * The SignIn by phone number page.
 */
export const SignIn = Page.register(
    // ui composition
    ({
        loginId,
        isLoading,
        error,
        isFetching,
        deeplinkPhoneNumber,
        phonePrefix = '+375',
        phoneNumber = deeplinkPhoneNumber
            ? maskNumbers(
                getMaskByPhonePrefix(phonePrefix.replace('+', '')),
                  `${deeplinkPhoneNumber}`.substring(3).replace(/\D+/g, ''),
            ) || ''
            : '',

        isValid = isPhoneNumberValid(phonePrefix + phoneNumber),
        setState,
        actions,
        navigation,
        captchaCodeImg,
        captchaCodeNum,
        waitingResponse,
        defaultSmsProvider,
        isCaptchaSubmitted = false,
        onPhoneNumber = (s) => {
            let finalNumber = s;
            if (s.length > phoneNumber.length) {
                finalNumber = maskNumbers(phoneMask, s.replace(/\D+/g, ''));
            }
            setState({
                phoneNumber: finalNumber,
                isValid: isPhoneNumberValid(phonePrefix + s),
            });
        },
        onCaptchaCode = (code) => {
            setState({ captchaCodeNum: code });
        },
        // onDidMount = () => storage.getObject('phoneNumber', '')
        //     .then((phone) => {
        //         // If phone number is longer than 10 - this phone number is from old versions and needs clear
        //         if (!phonePrefix.replace(/\D+/g, '').length && phone.replace(/\D+/g, '').length > 10) {
        //             // eslint-disable-next-line no-param-reassign
        //             phone = '';
        //         }
        //         setState({
        //             phoneNumber: phone,
        //             isValid: isPhoneNumberValid(phonePrefix + phone),
        //         });
        //     }),
        onSubmitCaptchaCode = async () => {
            setState({ isCaptchaSubmitted: true });
            try {
                const resp = await user.captchaCodeConfirmation(Number(captchaCodeNum), loginId, actions.setUserError);
                Keyboard.dismiss();
                if (resp?.id) {
                    setState({ captchaCodeImg: null });
                    navigation.navigate('CodeConfirmation', {
                        phoneNumber: `${
                            phonePrefix.replace('+', '').length === 4
                                ? insertIntoString(phonePrefix, '-', 2)
                                : phonePrefix
                        } ${phoneNumber}`,
                        id: resp.id,
                        isSignInWithApi: true,
                    });
                }
                setState({ waitingResponse: false });
            } finally {
                setState({ isCaptchaSubmitted: false });
            }
        },
        onSubmitPhone = async () => {
            // await storage.set('bumbaBanner', true);
            // actions.setFormData({ bumbaBanner: true });
            const fullPhoneNumber = `+${(phonePrefix + phoneNumber).replace(/\D+/g, '')}`;
            const phoneNumberForCodeConfirmation = `${
                phonePrefix.replace('+', '').length === 4 ? insertIntoString(phonePrefix, '-', 2) : phonePrefix
            } ${phoneNumber}`;
            if (fullPhoneNumber.startsWith('+37555')) {
                let appHash = [];
                if (Platform.OS === 'android') {
                    appHash = await RNSmsRetriever.getHash();
                }
                actions.startUserFetch();
                const { id, captchaCode } =
                    (await user.signIn(fullPhoneNumber, appHash[0], (message, errorCode) => {
                        actions.stopUserFetch();
                        actions.setUserError(message, errorCode);
                    })) || {};
                actions.stopUserFetch();
                if (id) {
                    storage.set('phoneNumber', phoneNumber);
                    if (captchaCode) {
                        setState({
                            captchaCodeImg: captchaCode,
                            loginId: id,
                        });
                    } else {
                        navigation.navigate('CodeConfirmation', {
                            phoneNumber: phoneNumberForCodeConfirmation,
                            id,
                            isSignInWithApi: true,
                        });
                    }
                }
            } else if (defaultSmsProvider === 'fb') {
                try {
                    actions.signInWithPhoneNumber(fullPhoneNumber, (confirmation) => {
                        storage.set('phoneNumber', phoneNumber);
                        navigation.navigate('CodeConfirmation', {
                            phoneNumber: phoneNumberForCodeConfirmation,
                            confirmation,
                            isSignInWithApi: false,
                        });
                    });
                } catch (e) {
                    // console.log('Error login', e);
                }
            } else {
                let appHash = [];
                if (Platform.OS === 'android') {
                    appHash = await RNSmsRetriever.getHash();
                }
                const { id: newId, captchaCode } = await user.signIn(
                    `+${fullPhoneNumber.replace(/\D+/g, '')}`,
                    appHash[0],
                    (e) => {
                        actions.setUserError(e);
                        setState({ waitingResponse: false });
                    },
                );

                if (captchaCode) {
                    actions.setUserError();
                    setState({
                        captchaCodeImg: captchaCode,
                        loginId: newId,
                    });
                } else {
                    storage.set('phoneNumber', phoneNumber);
                    navigation.navigate('CodeConfirmation', {
                        phoneNumber: phoneNumberForCodeConfirmation,
                        confirmation: newId,
                        isSignInWithApi: true,
                        id: newId,
                    });
                }
            }
            setState({ waitingResponse: false });
        },
        onPhonePrefix = (text) => {
            let prefix = text.replace(/\D+/g, '');
            if (text.length > 0) {
                prefix = `+${prefix}`;
            }
            setState({
                phonePrefix: prefix,
                isValid: isPhoneNumberValid(prefix + phoneNumber),
            });
        },
        prefixMask = phonePrefix.replace('+', '').length === 4 ? '+ 9-999' : '+ 9999',
        phoneMask = getMaskByPhonePrefix(phonePrefix.replace('+', '')),
    }) => {
        const setUserError = React.useCallback(() => actions.setUserError, []);
        useFocusEffect(setUserError);
        // useEffect(setUserError);
        return (
            <Page
                name="sign-in"
                persistKeyboard
                // onDidMount={onDidMount}
                notification={
                    error &&
                    (error instanceof Error
                        ? {
                            message: error.message,
                            code: error.code,
                            onSubmitPhone,
                        }
                        : {
                            ...error,
                            onSubmitPhone,
                        })
                }
                isLoading={isLoading || isFetching}>
                <KeyboardAvoidingView
                    behavior={Platform.OS !== 'ios' ? 'padding' : 'position'}
                    keyboardVerticalOffset={20}>
                    <Section>
                        {captchaCodeImg ? (
                            <Logo.Wrapper title="titles.enter_captcha_code">
                                <Image
                                    source={{ uri: captchaCodeImg }}
                                    style={{
                                        height: 60,
                                        width: 140,
                                        alignSelf: 'center',
                                        marginBottom: 8,
                                    }}
                                />
                                <TextInput.Number style={{ flex: 1 }} onChangeText={onCaptchaCode} />
                            </Logo.Wrapper>
                        ) : (
                            <Logo.Wrapper
                                title="titles.enter_your_phone_number"
                                subtitle="titles.sms_activation_message">
                                <Field
                                    style={{
                                        flex: 1,
                                        flexDirection: 'row',
                                    }}>
                                    <TextInput.MaskedNumber
                                        ns="phonePrefix"
                                        style={{ width: 86 }}
                                        onChangeText={onPhonePrefix}
                                        value={phonePrefix}
                                        mask={prefixMask}
                                        placeholder="+"
                                        disabled
                                    />
                                    {Platform.OS === 'ios' || Platform.OS === 'android' ? (
                                        <InputWrapper>
                                            <TextInputMask
                                                style={[
                                                    st.input,
                                                    {
                                                        flex: 1,
                                                        marginLeft: 8,
                                                    },
                                                ]}
                                                mask={'([00]) [000] [00] [00]'}
                                                placeholder="(__) ___ __ __"
                                                autoskip={true}
                                                onChangeText={onPhoneNumber}
                                                type={'phone'}
                                                keyboardType={'phone-pad'}
                                            />
                                        </InputWrapper>
                                    ) : (
                                        <>
                                            {/* <MaskedInput style={{ flex: 1, marginLeft: 8 }} */}
                                            {/*    mask={phoneMask} */}
                                            {/*    placeholder={phoneMask.replace(/9/gi, '_')} */}
                                            {/*    onChangeText={onPhoneNumber} */}
                                            {/*    value={phoneNumber} */}
                                            {/* /> */}
                                            <TextInput.MaskedNumber
                                                style={{
                                                    flex: 1,
                                                    marginLeft: 8,
                                                }}
                                                onChangeText={onPhoneNumber}
                                                mask={phoneMask}
                                                placeholder={phoneMask.replace(/9/gi, '_')}
                                                value={phoneNumber}
                                            />
                                        </>
                                    )}
                                    {/* <TextInput.MaskedNumber */}
                                    {/*     style={{ */}
                                    {/*         flex: 1, */}
                                    {/*         marginLeft: 8, */}
                                    {/*     }} */}
                                    {/*     onChangeText={onPhoneNumber} */}
                                    {/*     mask={phoneMask} */}
                                    {/*     placeholder={phoneMask.replace(/9/gi, '_')} */}
                                    {/*     value={phoneNumber} */}
                                    {/* /> */}
                                </Field>
                            </Logo.Wrapper>
                        )}
                        <BigButton
                            title="buttons.continue"
                            disabled={
                                captchaCodeImg
                                    ? !captchaCodeNum || !isValid || isCaptchaSubmitted
                                    : !isValid || waitingResponse
                            }
                            action={
                                waitingResponse
                                    ? () => {}
                                    : () => {
                                        setState({ waitingResponse: true });
                                        (captchaCodeImg ? onSubmitCaptchaCode : onSubmitPhone)();
                                    }
                            }
                        />
                    </Section>
                </KeyboardAvoidingView>
            </Page>
        );
    },
    // props
    {
        isFetching: { from: getIsUserFetching },
        isLoading: { from: getUserLoadingState },
        error: { from: getUserError },
        defaultSmsProvider: { from: getDefaultSmsProvider },
    },
);
