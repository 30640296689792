import EStyleSheet from 'react-native-extended-stylesheet';
import { COLORS } from './colors';

const photoSize = 30;

export const navbar = EStyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    userPhoto: {
        width: photoSize,
        height: photoSize,
        borderRadius: photoSize / 2,
    },
    userPhotoEmpty: { backgroundColor: '$gray' },
    icon: {
        width: photoSize,
        height: photoSize,
    },
    link: {
        paddingLeft: 6,
        color: '$white',
        fontSize: 16,
        fontFamily: '$sourceSansPropFontFamily_semibold',
    },
    navbar: {
        backgroundColor: '$appNavBarColor',
        elevation: 0,
        shadowOpacity: 0,
        shadowOffset: { height: 0 },
        shadowRadius: 0,
    },
    navbarModal: {
        backgroundColor: '$appNavBarColor',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    navbarTitle: {
        fontSize: 20,
        color: '$white',
        fontFamily: '$sourceSansPropFontFamily_semibold',
    },
    navBarBackWrapper: {
        flex: 2,
        height: 30,
        flexWrap: 'wrap',
    },
    navBarBackButton: {
        flex: 1,
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
    },
    safeArea: {
        backgroundColor: '$appNavBarColor',
        display: 'flex',
        justifyContent: 'center',
        minHeight: 55,
    },
});

export const tabBar = EStyleSheet.create({
    tabBar: {
        height: 60,
        paddingTop: 10,
        paddingBottom: 6,
        flexDirection: 'row',
        borderTopWidth: 1,
        borderTopColor: '#E8E8E8',
        backgroundColor: 'white',
    },
    tab: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    tabHighlight: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 5,
        backgroundColor: COLORS.HIGHLIGHT_BACKGROUND,
    },
    tab_container: {
        flexDirection: 'row',
        backgroundColor: '$white',
        borderBottomWidth: 1,
        borderBottomColor: '$ultraLightGray',
        paddingHorizontal: 12,
    },
    tab_active: {
        borderBottomWidth: 3,
        borderBottomColor: '$appHeaderBgColor',
    },
    safeArea: { backgroundColor: 'white' },
});

