import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAssignments } from '../selectors';
import api from '../services/api';
import { setAssignmentInfo } from '../actions/doctors';

export const useAssignmentInfo = ({ assignmentId, request = true }) => {

    const [loading, setLoading] = React.useState('');
    const [info, setInfo] = React.useState({ loading: true });
    const assignmentsInfo = useSelector(getAssignments);
    const dispatch = useDispatch();
    const loadAssignment = async () => {

        try {

            const i = await api.getAssignmentInfo(assignmentId);
            dispatch(setAssignmentInfo(i));

        } catch (e) {

            setLoading(false);

        }

    };
    useEffect(() => {

        if (!assignmentId) {

            setLoading(false);
            setInfo({ loading: false });
            return;

        }
        const i = assignmentsInfo?.[assignmentId];
        if (i) {

            setLoading(false);
            setInfo({
                ...i,
                loading: false,
            });

        } else {

            if (request) {

                setLoading(true);
                setInfo({ loading: true });
                loadAssignment();

            }

        }

    }, [assignmentId, assignmentsInfo, request]);
    return info;

};
