import api from '../services/api';
import { trimObjectFields } from '../utils/strings';
import { createReducerFor } from './utils';

const TYPE = 'SERVICES';

const initialState = {
    servicesList: {},
    selectedServices: {},
    doctorsDescriptions: {},
    isLoading: false,
    selectedService: null,
};
export default createReducerFor(TYPE, initialState);

export const resetServiceList = () => (dispatch) => {

    dispatch({
        type: TYPE,
        payload: { servicesList: {} },
    });

};

export const fetchServicesListByAssignment = (assignmentId, reFetch = false, programId) => async (dispatch, getState) => {

    try {

        const list = getState()?.services ?? {};
        const servCache = list?.servicesList?.[assignmentId];
        if ((servCache && !reFetch) || list?.isLoading) {

            return;

        }
        if (list?.isFetching === assignmentId) {

            return;

        }
        dispatch({
            type: TYPE,
            payload: { isFetching: assignmentId },
        });
        const data = await api.tryGetServices(assignmentId, programId) ?? [];
        dispatch({
            type: TYPE,
            payload:
            {
                servicesList: {
                    ...list,
                    [assignmentId]: data,
                },
                isLoading: false,
                isFetching: false,
            },
        });

    } catch (e) {

        dispatch({
            type: TYPE,
            payload: {
                isLoading: false,
                isFetching: false,
            },
        });

        // eslint-disable-next-line no-console
        console.log(`Error get services list for assignmentId ${assignmentId}`, e);

    }

};

export const fetchDoctorDescriptionsByAssignments = (assingnments = '', doctorId = '', reFetch = false) => async (dispatch, getState) => {

    const list = getState()?.services?.doctorsDescriptions ?? {};
    try {

        if ((list?.[doctorId] && !reFetch) || list?.isLoading) {

            return;

        }

        dispatch({
            type: TYPE,
            payload: {
                doctorsDescriptions: {
                    ...list,
                    isLoading: true,
                },
            },
        });

        const data = await api.getDoctorDescriptions(assingnments) ?? [];
        dispatch({
            type: TYPE,
            payload:
            {
                doctorsDescriptions: {
                    ...list,
                    [doctorId]: data,
                    isLoading: false,
                },
            },
        });

    } catch (e) {

        dispatch({
            type: TYPE,
            payload: {
                doctorsDescriptions: {
                    ...list,
                    isLoading: false,
                },
            },
        });

        // eslint-disable-next-line no-console
        console.log(`Error get doctor descriptions for assignments`, e);

    }

};

export const clearSelectedServices = () => (dispatch) => {

    dispatch({
        type: TYPE,
        payload: { selectedServices: {} },
    });

};

export const setSelectedServiceByAssignment = (assignmentId, service) => (dispatch, getState) => {

    try {

        const list = getState()?.services?.selectedServices ?? {};
        dispatch({
            type: TYPE,
            payload:
            {
                selectedServices: {
                    ...list,
                    [assignmentId]: trimObjectFields(service),
                },
            },
        });

    } catch (e) {

        // eslint-disable-next-line no-console
        console.log(`Error select service for assignmentId ${assignmentId}`, e);

    }

};

export const setSelectedService = selectedService => (dispatch) => {

    try {

        dispatch({
            type: TYPE,
            payload:
                { selectedService: trimObjectFields(selectedService) },
        });

    } catch (e) {

        // eslint-disable-next-line no-console
        console.log(`Error select service`, e);

    }

};
