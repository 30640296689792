import {
    Button, ButtonsGroup, Logo, Page, React, Section, Subtitle, Title, VBox,
} from '../../common';
import { AppHeader } from '../../combo';
// TODO rename to ...Prompt?? not used.
export const FamilyInvitePermission = Page.register(
    ({
        navigation,
        actions: { back },
        doAgree = () => navigation.replace('FamilyInviteConfirm'),
    }) => (
        <Page name="family-invite-permission">
            <Section centered>
                <Logo/>
            </Section>
            <Section centered>
                <VBox centered gap={0.5}>
                    <Title.Bigger bold id="titles.pay_attention" centered/>
                    <Subtitle.Bigger numberOfLines={null} id="titles.permission_to_relatives" centered/>
                </VBox>
            </Section>
            <ButtonsGroup>
                <Button normal title="button.not_agree" action={back}/>
                <Button primary title="button.agree" action={doAgree}/>
            </ButtonsGroup>
        </Page>
    ),
    {},
    ({ navigation }) => ({ header: () => <AppHeader back navigation={navigation}/> }),
);
