import { Platform, React, View } from './react';
import { LoadingIndicator, Title } from './elements';
import { loadingView as styles } from './style';

export const LoadingView = ({
    title,
    localize = true,
}) => (
    <View style={styles.loadingContainer}>
        <LoadingIndicator style={styles.loadingIndicator} indicatorSize={Platform.OS === 'ios' ? 'large' : 50}/>
        <Title.Bigger style={styles.loadingTitle}>{localize ? Object.R(title) : title}</Title.Bigger>
    </View>
);
