import { input } from '../styles/input';
import { formatDateLong, toDate } from '../utils';
import { COLORS } from '../styles/colors';
import { isMobile, isSafari } from '../utils/strings';
import { getDate } from '../combo/DateSelector';
import { CURRENT_SERVER_TIMEZONE_OFFSET, formatUTCDateToMysqlDate, toDateTZ } from '../utils/dates';
import { React } from './react';
import {
    Icon, Keyboard, Platform, S, Text, TouchableOpacity,
} from '.';
import { useRef, useState } from 'react';
import DatePicker from 'react-native-date-picker';

const toInlineStyle = (inputStyles) => {

    try {

        if (inputStyles === null || typeof inputStyles === 'undefined') {

            return {};

        }

        if (Array.isArray(inputStyles)) {

            return inputStyles.reduce((mergedStyles, style) => {

                if (Array.isArray(style)) {

                    return {
                        ...mergedStyles,
                        ...toInlineStyle(style),
                    };

                }
                if (style === null || typeof style === 'undefined') {

                    return mergedStyles;

                }
                if (typeof style === 'object') {

                    return {
                        ...mergedStyles,
                        ...style,
                    };

                }
                return mergedStyles;

            }, {});

        }
        if (typeof inputStyles === 'object') {

            return { ...inputStyles };

        }
        return {};

    } catch (e) {

        return inputStyles;

    }

};

export const DateInput = ({
    id,
    value,
    child,
    placeholder,
    onChange,
    checkValidation,
    error,
    type,
    background,
    addMarginTop = 13,
    style,
    padding,
    marginTop = 0,
    ...props
}) => {

    const iconStyle = {
        borderWidth: 0,
        width: 35,
        // height: '100%',
        position: 'absolute',
        top: isMobile() || isSafari() ? 24 + ((type === 'time' && 2) ?? 0) : 0,
        right: 0,
        marginRight: 12,
        marginTop: addMarginTop,
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9,
        // margin: 'auto',
        // bottom: 0,
    };

    const [iosPopup, setIosPopup] = useState(false);
    const dateRef = useRef(null);

    if (type === 'childDate') {

        type = 'date';

    }

    let val = value;
    if (type === 'date') {

        if (typeof value === 'string') {

            val = toDate(value) ?? new Date(value);

        } else {

            let offset = value?.getTimezoneOffset?.() ?? 0;
            if (offset === CURRENT_SERVER_TIMEZONE_OFFSET) {

                offset = 0;

            }
            val = new Date(value?.getTime?.() + offset * 60000);

        }

    }

    return Platform.OS === 'web' ? (
        <div
            style={toInlineStyle([
                {
                    background: background ?? '#fff',
                    flex: 1,
                    width: '100%',
                },
                style,
            ])}>
            <input
                type={type || 'date'}
                min="1900-01-01"
                style={toInlineStyle([
                    {
                        // backgroundColor: '#fff',
                        padding: padding ?? 12,
                        borderRadius: 3,
                        borderWidth: 1,
                        borderColor: error ? '#DF655C' : '#E0E0E0',
                        borderStyle: 'solid',
                        fontFamily: 'FontAwesome',
                        fontSize: 14,
                        outline: 'none',
                        cursor: 'text',
                        // minWidth: '93%',
                        width: '100%',
                        boxSizing: 'border-box',
                        '-webkit-appearance': 'none',
                        // minHeight: '1.4em',
                        minHeight: 45,
                        textAlign: 'start',
                        display: 'block',
                        position: 'relative',
                        background: 'transparent',
                        flex: 1,
                        zIndex: 10,
                        paddingTop: (padding ?? 12) + (isMobile() ? marginTop : 0),
                    },
                    style,
                ])}
                onChange={(e) => {

                    const v = e?.target?.value;
                    onChange(v);
                    checkValidation?.(v);

                }}
                value={value}
                placeholder={placeholder}
                {...props}
            />
            {(isMobile() || isSafari()) && type !== 'time' && (
                <Icon.Calendar size={25} color={COLORS.DARK_GRAY} style={iconStyle} />
            )}
            {(isMobile() || isSafari()) && type === 'time' && (
                <Icon.Clock size={20} color={COLORS.DARK_GRAY} style={iconStyle} />
            )}
        </div>
    ) : (
        <TouchableOpacity
            accesibilityLabel={id}
            style={[
                input.input,
                S.flex1,
                S.flexRow__center,
                S.spaceBetween,
                input.input__panel,
                { paddingVertical: 10 },
            ]}
            onPress={() => {

                Keyboard.dismiss();
                const minDate = Platform.OS === 'android' && toDateTZ(new Date(1900, 0, 1), true);
                const dateFormatted = Number.isNaN(val?.getTime?.())
                    ? new Date()
                    : new Date((val?.getTime?.() ?? 0) + CURRENT_SERVER_TIMEZONE_OFFSET * 60000);
                const offset = dateFormatted?.getTimezoneOffset?.() ?? 0;
                dateRef.current = new Date(
                    (dateFormatted?.getTime?.() ?? 0) - offset * 60000 - CURRENT_SERVER_TIMEZONE_OFFSET * 60000,
                );
                if (Platform.OS === 'ios') {

                    setIosPopup(true);

                } else {

                    getDate({
                        mode: 'date',
                        date: dateRef.current,
                        onSelectDate: (date, displayDate) => {

                            const d = formatUTCDateToMysqlDate(date);
                            onChange(d, displayDate);
                            checkValidation(d, displayDate);

                        },
                        // maxDate,
                        minDate,
                        timezoneOffsetInMinutes: 0,
                    });

                }

            }}>
            <DatePicker
                date={dateRef.current ?? new Date()}
                mode={'date'}
                modal={true}
                open={iosPopup}
                confirmText={(Object.R('titles.choose_the_date') ?? '').toUpperCase()}
                cancelText={Object.R('buttons.close')}
                title={' '}
                theme={'light'}
                is24hourSource={'locale'}
                onConfirm={(dt) => {

                    const d = formatUTCDateToMysqlDate(dt);
                    onChange(d);
                    checkValidation(d);
                    setIosPopup(false);

                }}
                onCancel={() => setIosPopup(false)}
            />
            <Text style={[input.infoText, !value && input.hint]}>
                {`${value ? formatDateLong(value) : placeholder}`}
            </Text>
            <Icon name="calendar" size={26} color="#666666" />
        </TouchableOpacity>
    );

};
