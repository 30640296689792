/* eslint-disable no-underscore-dangle */
import { useNavigation } from '@react-navigation/native';
// import { ScrollView } from 'react-native-gesture-handler';
import { useSelector } from 'react-redux';
import {
    Dimensions, NavigationList, React, View, Platform,
} from '../../common';
import { MapBunner } from '../DoctorsMap/MedcenterDetailsBunner';
import { putIntoStore } from '../../store';
import { SCHEME } from '../../scheme';
import { getHasServices, getServiceInfo } from '../../selectors';
import tracking from '../../services/tracking';
import { getServicesIds } from '../UMS/utils';
import { resetDoctorsFilters } from '../Doctors/utils';
import { ScrollView } from 'react-native';

const { width } = Dimensions.get('window');

const useNavTo = (clinic, servicesItems, serviceName) => {

    const navigation = useNavigation();

    return React.useCallback(
        (route) => {

            tracking.logEvent(`ai_btn_mc_map_${route.id}`);
            if (route.id === 'ChooseDoctor') {

                resetDoctorsFilters(
                    {
                        specialty: '',
                        worksAt: [clinic._id],
                        profiles: '0',
                    },
                    undefined,
                    'MedCenterDetails',
                );
                if (
                    servicesItems &&
                    serviceName &&
                    servicesItems?.some(
                        ({ onlineBooking, branchIds }) => onlineBooking && branchIds?.some(e => e === clinic?.id),
                    )
                ) {

                    const serviceIds = getServicesIds(servicesItems, clinic?.id);
                    // actions.setCurrentServiceMcInfo({
                    //     serviceName, clinicId: clinic?.id, clinicName: clinic?.name, clinicAddres: clinic?.address,
                    // });
                    navigation.navigate('UMSDoctorsList', {
                        serviceIds,
                        clinicId: clinic?.id,
                        serviceName,
                        clinicName: clinic?.name,
                        clinicAddres: clinic?.address,
                        prices: clinic?.showPrices ? clinic?.prices : undefined,
                        from: 'MedCenterDetails',
                    });
                    return;

                }
                navigation.navigate('DoctorsSpecialtiesSearch', {
                    mdInfo: clinic,
                    from: 'MedCenterDetails',
                });
                return;

            } else if (route.id === 'MedCenterServices') {

                putIntoStore(SCHEME.UMS_FILTER, {
                    medcenters: [clinic._id],
                    withPrograms: false,
                    from: 'MedCenterDetails',
                });
                navigation.navigate('MedCenterServices', { mdInfo: clinic });
                return;

            }
            navigation.navigate(route.id || route, { mdInfo: clinic });

        },
        [clinic],
    );

};

export const MedCenterDetailsBunner = ({ item, noServices }) => {

    const servInfo = useSelector(getServiceInfo);
    const navTo = useNavTo(item, servInfo?.items ?? [], servInfo?.serviceName);
    const hasServices = useSelector(state => getHasServices(state, item?._id));
    const hasOnlineServices =
        servInfo?.items &&
        servInfo?.serviceName &&
        servInfo?.items?.some?.(
            ({ onlineBooking, branchIds }) => onlineBooking && branchIds?.some?.(e => e === item?.id),
        );
    const filtredFields = [
        hasServices && !noServices ? null : 'MedCenterServices',
        item?.branchDoctorsCounter && hasOnlineServices ? null : 'ChooseDoctor',
    ];

    let info;
    try {

        info = {
            ...(item ?? {}),
            phones: item?.phone,
        };

    } catch (e) {

        info = {};

    }
    return (
        <View style={{ height: Platform.OS === 'web' ? '40%' : width * 0.7 }}>
            <ScrollView>
                <MapBunner
                    info={info}
                />
                <NavigationList dataEnum="mcMapDetailsNavigation" onItem={navTo} filtredFields={filtredFields} />
            </ScrollView>
        </View>
    );

};
