import api from '../services/api';
import { storage } from '../services/localstorage';
import { createReducerFor } from './utils';
import { CACHE_VALID_TIME, isTimePassed } from '../hooks/useDoctorsDataExpirationHandler';
import { isEmpty } from '../utils';

const TYPE = 'doctors';
export const DOCTOR_BY_ID_FIRST_CALL = 'doctorByIdFirstCallDate';

const getSpecialitiesFromApi = async (location, branchId) => {

    return await (branchId
        ? api.getSpecialitiesListFromApiForBranch(branchId)
        : api.getSpecialitiesListFromApi(location));

};

const getSpecialitiesFullFromApi = async () => {

    return await api.getSpecialitiesFullListFromApi();

};

const getDoctorsFromApi = async (location = 17, specId = '5d480f9616c6177f8', worksAtId = 'l1y23pk1bf4c494f7') => {

    return await api.getDoctorsBySpec(location, worksAtId, specId);

};

export const findDoctorInState = (id, state, withAssignments) => {

    if (!id) {

        return null;

    }
    for (const area in state) {

        const specialities = state[area];
        for (const spec in specialities) {

            const doctors = specialities[spec];
            const doctorsData = specialities[spec]?.data;

            if (Array.isArray(doctorsData)) {

                const found = doctorsData.find(e => e?.id === id && (!withAssignments || e?.assignments?.length));

                if (found) {

                    return found;

                }

            }
            const f = doctors?.[id];
            if (f && (!withAssignments || f?.assignments?.length)) {

                return f;

            }

        }

    }
    return null;

};

export const loadDoctorInfo =
    (id, withAssignments = false) => async (dispatch, getState) => {

        const prevData = getState()?.[TYPE]?.data || {};
        if (!id) {

            return;

        }

        if (findDoctorInState(id, prevData, withAssignments)) {

            return;

        }
        dispatch({
            type: TYPE,
            payload: {
                error: null,
                isDoctorsLoading: true,
            },
        });
        let data = {};
        let error = null;
        let firstModificationDate;
        try {

            data = await api.getDoctorById(id);
            if (isEmpty(prevData?.[0])) {

                firstModificationDate = Date.now();

            }
            if (data) {

                data.name = data.fullName;

            }

        } catch (e) {

            if (e?.status === 404) {

                error = null;

            } else {

                error = e;

            }
            dispatch({
                type: TYPE,
                payload: {
                    error,
                    isDoctorsLoading: false,
                },
            });

        } finally {

            const newData = { ...prevData };
            if (!newData?.[0]) {

                newData[0] = {};

            }
            newData[0][data?.specialityId] = newData[0][data?.specialityId] || {};
            newData[0][data?.specialityId][data?.id] = data;
            const payload = firstModificationDate ? {
                [DOCTOR_BY_ID_FIRST_CALL]: firstModificationDate,
                error,
                isDoctorsLoading: false,
                data: newData,
            } : {
                error,
                isDoctorsLoading: false,
                data: newData,
            };
            dispatch({
                type: TYPE,
                payload,
            });

        }

    };

const completeDoctorObjects = (ids, objects) => {

    const existingIds = objects.map(obj => obj.id);
    const missingObjects = ids
        .filter(id => !existingIds.includes(id))
        .map(id => ({ id }));
    return [...objects, ...missingObjects];

};

export const loadDoctorListInfo =
    (ids = []) => async (dispatch, getState) => {

        const doctorsState = getState()?.[TYPE];
        const isLoading = doctorsState?.isDoctorsLoading;
        const prevData = doctorsState?.data || {};
        if (!ids?.length || isLoading) {

            return;

        }

        const filteredIds = ids.filter(id => !findDoctorInState(id, prevData));

        if (filteredIds?.length === 0) {

            return;

        }

        dispatch({
            type: TYPE,
            payload: {
                error: null,
                isDoctorsLoading: true,
            },
        });

        let data = {};
        let firstModificationDate;
        try {

            data = await api.getDoctorsByListIds(filteredIds);
            if (data && data.length) {

                data = completeDoctorObjects(filteredIds, data).map(e => ({
                    ...e,
                    name: e.fullName,
                }));

            }

        } catch (e) {

            dispatch({
                type: TYPE,
                payload: {
                    error: e,
                    isDoctorsLoading: false,
                },
            });

        } finally {

            if (isEmpty(prevData?.[0])) {

                firstModificationDate = Date.now();

            }
            const newData = { ...prevData };
            if (!newData?.[0]) {

                newData[0] = {};

            }
            const newDataArea = newData?.[0];
            data?.forEach((e) => {

                if (!newDataArea[e?.specialityId]) {

                    newDataArea[e?.specialityId] = {};

                }
                newDataArea[e?.specialityId][e?.id] = e;

            });

            const payload = firstModificationDate ? {
                [DOCTOR_BY_ID_FIRST_CALL]: firstModificationDate,
                error: null,
                isDoctorsLoading: false,
                data: newData,
            } : {
                error: null,
                isDoctorsLoading: false,
                data: newData,
            };
            dispatch({
                type: TYPE,
                payload,
            });


        }

    };

export const checkDoctorsCache = () => async (dispatch, getState) => {

    const prevData = getState()?.[TYPE]?.data || {};
    const date = getState()?.[TYPE]?.[DOCTOR_BY_ID_FIRST_CALL];

    if (isEmpty(prevData?.[0])) {

        return;

    }
    if (isTimePassed(date, CACHE_VALID_TIME)) {

        const { 0: _, ...newData } = prevData;
        dispatch({
            type: TYPE,
            payload: {
                error: null,
                isDoctorsLoading: false,
                data: newData,
                [DOCTOR_BY_ID_FIRST_CALL]: undefined,
            },
        });

    }

};


const createDoctorsInfoObject = data => ({
    data,
    modificationDate: new Date()?.valueOf(),
});

const getSpecialitiesInfoFromApiWithDate = async (location, branchId) => {

    const data = await getSpecialitiesFromApi(location, branchId);
    return createDoctorsInfoObject(data);

};

export const syncSpecialitiesFullList =
    ({ forceReload } = { forceReload: false }) => async (dispatch, getState) => {

        try {

            const isLoading = getState?.()?.[TYPE]?.isLoadingSpecialities;
            const isFetched = getState?.()?.[TYPE]?.isSpecialitiesFetched;
            if (isLoading) {

                return;

            }

            if (!forceReload && isFetched) {

                return;

            }

            dispatch({
                type: TYPE,
                payload: { isLoadingSpecialities: true },
            });
            const data = await getSpecialitiesFullListFromApiWithDate();
            dispatch({
                type: TYPE,
                payload: {
                    specialitiesFullList: data,
                    isLoadingSpecialities: false,
                    isSpecialitiesFetched: true,
                },
            });

        } catch (e) {

            dispatch({
                type: TYPE,
                payload: {
                    error: e,
                    isLoadingSpecialities: false,
                },
            });

        }

    };

const getSpecialitiesInfoKey = (location, branchId) => (branchId ? `specialitiesInfo_${location}` : `specialitiesInfo_${location}_${branchId}`);

const getSpecialitiesFullListFromApiWithDate = async () => {

    const data = await getSpecialitiesFullFromApi();
    return createDoctorsInfoObject(data);

};

const getSpecialitiesInfoFromLocalStorage = async (location, branchId) => {

    const key = getSpecialitiesInfoKey(location, branchId);
    const localData = await storage.get(key);
    const parsedLocalData = JSON.parse(localData);
    if (!localData) {

        const data = await getSpecialitiesInfoFromApiWithDate(location, branchId);
        storage.set(key, data);
        return data;

    }
    // set Data to store

    if (parsedLocalData?.modificationDate > 12) {

        const data = await getSpecialitiesInfoFromApiWithDate(location, branchId);
        storage.set(key, data);
        return data;

    }
    if (parsedLocalData?.modificationDate <= 12) {

        return parsedLocalData;

    }

};

// export const syncSpecialitiesInfo = asyncActionCreator(TYPE, 'specialitiesInfo', {}, () => getSpecialitiesFromApi());
export const syncSpecialitiesInfo = (location, branchId) => async (dispatch, getState) => {

    const branchCode = branchId || 'all';

    const state = getState()?.[TYPE];
    const prevData = state?.specialitiesInfo || {};
    const isCacheValid = (state?.specialitiesInfo?.[location]?.[branchCode]?.modificationDate ?? 0) > Date.now() - CACHE_VALID_TIME;
    if (isCacheValid) {

        return;

    }
    const syncSpecialitiesInfoParams = `is_${location}_${branchId}_loading`;
    if (state?.[syncSpecialitiesInfoParams]) {

        return;

    }
    if (!location) {

        dispatch({
            type: TYPE,
            payload: {
                error: null,
                isSpecLoading: false,
                specialitiesInfo: null,
            },
        });
        return;

    }
    dispatch({
        type: TYPE,
        payload: {
            error: null,
            isSpecLoading: true,
            [syncSpecialitiesInfoParams]: true,
        },
    });
    try {

        const data = await getSpecialitiesInfoFromApiWithDate(location, branchId);
        const newData = {
            ...prevData,
            [location]: {
                ...(prevData?.[location] || {}),
                [branchId || 'all']: data,
            },
        };
        dispatch({
            type: TYPE,
            payload: {
                error: null,
                isSpecLoading: false,
                specialitiesInfo: newData,
                [syncSpecialitiesInfoParams]: null,
            },
        });

    } catch (e) {

        dispatch({
            type: TYPE,
            payload: {
                error: e,
                isSpecLoading: false,
                syncSpecialitiesInfoParams: null,
                [syncSpecialitiesInfoParams]: null,
            },
        });

    }

};

export const syncDoctors = (location, specId) => async (dispatch, getState) => {

    const prevData = getState()?.[TYPE]?.data || {};
    if (!location) {

        dispatch({
            type: TYPE,
            payload: {
                error: null,
                isDoctorsLoading: false,
                data: null,
            },
        });
        return;

    }
    dispatch({
        type: TYPE,
        payload: {
            error: null,
            isDoctorsLoading: true,
        },
    });
    try {

        const data = await getDoctorsFromApi(location, specId);
        const result = createDoctorsInfoObject(data);
        const newData = {
            ...prevData,
            [location]: {
                ...(prevData?.[location] || {}),
                [specId || 'all']: result,
            },
        };
        dispatch({
            type: TYPE,
            payload: {
                error: null,
                isDoctorsLoading: false,
                data: newData,
            },
        });

    } catch (e) {

        dispatch({
            type: TYPE,
            payload: {
                error: e,
                isDoctorsLoading: false,
            },
        });

    }

};

export const setSpecialitiesError = ({ error }) => (dispatch) => {

    dispatch({
        type: TYPE,
        payload: { error },
    });

};

export const setIsDoctorsSearching = isDoctorsSearching => (dispatch) => {

    dispatch({
        type: TYPE,
        payload: { isDoctorsSearching },
    });

};

export const setAssignmentInfo = assignmentInfo => (dispatch, getState) => {

    const prevData = getState()?.[TYPE]?.assignments || {};
    if (typeof assignmentInfo !== 'object' && !assignmentInfo?.id && !assignmentInfo?.providerCode) {

        return;

    }
    dispatch({
        type: TYPE,
        payload: {
            assignments: {
                ...prevData,
                [assignmentInfo?.id]: assignmentInfo,
            },
        },
    });

};

export default createReducerFor(TYPE, {});
