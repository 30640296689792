import { MODE } from '../config';

export function extractPhoneNumber(phoneData) {
    const phoneDataWithoutTime = phoneData.replace(/(\(([a-zA-Z:\s\u0430-\u044f\u0410-\u042f]+-*\.*\d*)+\))/g, '');
    return phoneDataWithoutTime
        .replace(/([a-zA-Z:\s\u0430-\u044f\u0410-\u042f]+-*\(*\)*\/*\.*\/*)/g, '')
        .replace(/[()-]/g, '')
        .trim();
}

export const formatPhoneNumber = ({ number }) => (number || '').replace(/\D/g, '')
    .replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5');


export const getMaskByPhonePrefix = (prefix) => {
    // Use extra digits to prevent digits loss when changing to smaller mask
    switch (prefix.length) {
        case 1:
            return '(999) 999 99 99';
        case 2:
            return '(999) 99 99 999';
        case 4:
            return '999 99 99999';
        default:
            return '(99) 999 99 99';
    }
};

export const maskNumbers = (mask, numbers) => {
    let finalNumber = '';
    for (let i = 0; i < mask.length; i++) {
        const item = mask[i];
        if (item === '9') {
            if (!numbers.length) {
                break;
            }
            finalNumber += numbers[0];
            // eslint-disable-next-line no-param-reassign
            numbers = numbers.slice(1);
        } else {
            finalNumber += item;
        }
    }
    return finalNumber;
};

export const isPhoneNumberValid = (number = '') => {
    try {
        if (MODE !== 'prod' && (number.match(/(\+375\(55\) [0-9]{3} [0-9]{2} [0-9]{2})/) || number.replace(/[ ()]/g, '')
            .match(/^\+375(22|25|29|33|44|55|65)\d{7}$/))) {
            return true;
        }
        return number.replace(/[ ()]/g, '')
            .match(/^\+375(22|25|29|33|44|65)\d{7}$/);
    } catch (error) {
        return false;
    }
};
