/* eslint-disable no-underscore-dangle */
// noinspection ExceptionCaughtLocallyJS
import { Platform } from 'react-native';
import NetInfo from '@react-native-community/netinfo';
/* eslint-disable max-statements */
import { decrypt, encrypt, isAdult, isFutureVisit } from '../utils';
import { memoryStorage } from '../utils/memoryStorage';
import { aCodeSecurity } from '../utils/aCodeSecurity';
import api from './api';
import { storage } from './localstorage';
import firebase from './firebase';
import { saveUserStats } from './stats';
import { getDeviceName } from './device';
import {
    applyUserProfile,
    clearForUnAuthenticated,
    errouneousUser,
    extractNewRelatives,
    getMessageWithTime,
    getUserInfoDefaults,
    mergeUserInfo,
    personalizeMergedProfileFromStorage,
    PREFS,
    removeRelativeFromStorage,
    saveFamilyToStorage,
    USER_KEY,
} from './user.utils';
import { removeAllCachedResponses } from './iosCacheManager';
import { FingerprintScannerRelease, getLocalizedError, isFingerprintMatch } from './fingerprintScanner';
import testServices from './tests';
import { removeAllACodes } from './encryptedStorage';
import { getErrorCodeIfNeeded, getFinalMessage } from './http';
import { changeDisplayOfWebInstallPopup } from './deeplinks';
import ReactNativeBiometrics from 'react-native-biometrics';

const wait = (delay) => {

    return new Promise(resolve => (delay === 0 ? resolve() : setTimeout(resolve, delay)));

};

const getProfilesWithoutConsentReceived = ({me, name, birthday, relatives }) => {

let mainProfile;
let relProfiles = [];
let result;

if(!me?.consentReceived){
    mainProfile = {
        name,
        birthday, 
        isMainProfile: true,
        id: me?.profileId         
    };
}

if(relatives?.length){
    relProfiles = relatives.filter(e => !e.consentReceived && e.relKind === 'CHILD' && e.birthday && e.fullNameRu && !isAdult(e.birthday)).map(({fullNameRu,birthday, relation, profileId}) => ({
        name: fullNameRu,
        birthday,
        relation,
        id: profileId
    }));

}
result = mainProfile ? [mainProfile, ...relProfiles] : relProfiles;
return result;
};

/**
 * Service around managing user-related information
 * like creds, favorites etc.
 */
export const user = {
    getUserInfo: async () => getUserInfoDefaults(this.userInfo || (await storage.getObject(USER_KEY))),

    // eslint-disable-next-line complexity
    reloadUserInfo: async (onNewItemsFound, updatePrefs, updateSelects) => {

        // userData
        if (!firebase.isAuthenticated()) {

            const userId = firebase.getUserId();
            if (userId) {

                await storage.set('anonymousId', firebase.getUserId());

            }

            // Clear storage only if user was authenticated
            const localStorageUserInfo = getUserInfoDefaults(await storage.getObject(USER_KEY, {}));
            if (localStorageUserInfo.userId || localStorageUserInfo.waitingConfirmEmail) {

                clearForUnAuthenticated();

            }
            this.userInfo = {};
            return this.userInfo;

        }

        const anonymousId = await storage.get('anonymousId');
        const deviceId = await storage.get('deviceId');
        let fcmToken = null;
        try {

            fcmToken = await firebase.getFCMToken();

        } catch (e) {

            // eslint-disable-next-line no-console
            console.log('getFCMToken error: ', e);

        }
        let backEndUserInfo = await api.userInfo(anonymousId, deviceId, fcmToken);
        if (backEndUserInfo.error) {

            const localStorageUserInfo = getUserInfoDefaults(await storage.getObject(USER_KEY, {}));
            if (backEndUserInfo.error.code === 'USER_NOT_REGISTERED') {

                const delay = Platform.OS === 'web' && !Object.SendConfirmationCode ? 10000 : 0;
                Object.SendConfirmationCode = false;
                await wait(delay);
                const deviceIdOfExistedUser = await errouneousUser(localStorageUserInfo, backEndUserInfo);
                if (deviceIdOfExistedUser?.deviceIdOfExistedUser) {

                    return deviceIdOfExistedUser;

                }
                backEndUserInfo = await api.userInfo(anonymousId, deviceId, fcmToken);

            } else {

                return errouneousUser(localStorageUserInfo, backEndUserInfo);

            }

        }

        const localStorageProviderCodes = (await storage.get('providerCodes')) || [];
        let providerCodes = {};
        if (
            localStorageProviderCodes &&
            localStorageProviderCodes.length &&
            backEndUserInfo.sex &&
            backEndUserInfo.fullNameRu &&
            backEndUserInfo.birthday
        ) {

            providerCodes = { providerCodes: localStorageProviderCodes };
            storage.set('doNotShowProfileInstructionsModal', true);

        }
        await storage.set('anonymousId', null);

        // relatives
        const [relatives, photoUrl, userInfoWithUpdates = {}] = await Promise.all([
            (await api.userRelatives()) || {},
            await api.userPhoto(),
            Promise.resolve(backEndUserInfo),
            // (await api.discoverCovererUpdates(providerCodes)) || {},
        ]);

        saveFamilyToStorage(
            userInfoWithUpdates?._id,
            userInfoWithUpdates?.hashKey,
            userInfoWithUpdates?.family,
            relatives,
        );

        const mergedUnpersonalized = mergeUserInfo({
            ...backEndUserInfo,
            ...userInfoWithUpdates,
        }, relatives, photoUrl);

        const merged = await personalizeMergedProfileFromStorage(mergedUnpersonalized, userInfoWithUpdates?.hashKey);

        const profilesWithoutConsent = getProfilesWithoutConsentReceived(merged);
        if(profilesWithoutConsent?.length){
            Object.profilesWithoutConsent = profilesWithoutConsent;
            return merged;
        }else {
            Object.profilesWithoutConsent  = null;
        }

        const localStorageUserInfo = getUserInfoDefaults(await storage.getObject(USER_KEY, {}));
        const [newRelatives, newProgram, deletedRelatives] = extractNewRelatives(
            merged,
            localStorageUserInfo,
            backEndUserInfo,
            userInfoWithUpdates,
        );
        if (onNewItemsFound && (newRelatives.length || newProgram)) {

            await onNewItemsFound(newRelatives, newProgram);

        }
        if (deletedRelatives?.length) {

            deletedRelatives.forEach((profile) => {

                removeRelativeFromStorage({
                    profileId: profile?.profileId,
                    hashKey: merged?.hashKey,
                });

            });

        }

        // do not store hashKey
        await storage.set(USER_KEY, {
            ...merged,
            hashKey: null,
            storeKey: null,
        });
        this.userInfo = merged;
        // user profile:
        const userData = await firebase.getUserProfile(merged?.storeKey);
        applyUserProfile(merged, userData, updatePrefs, updateSelects);
        // firebase.onUserProfile(merged?.storeKey, userData => applyUserProfile(merged, userData, updatePrefs, updateSelects));
        // applyUserProfile(merged, updatePrefs, updateSelects);
        // if (Object.orderId && Object.orderProviderCode) {

        user.getPubVisit({
            orderId: Object.orderId,
            pCode: Object.orderProviderCode,
            ed: Object.ed,
        }).then((res) => {

            if (res && !res.alreadyImported) {

                Object.onNewVisitFound(res);

            } else if (res?.empty && res?.alreadyImported) {

                if (Object.orderProviderCode) {

                    Object.onAlreadyImported?.(res);

                }

            } else {

                changeDisplayOfWebInstallPopup('block');

            }

        });
        // }
        return merged;

    },

    requestAccessByEmail: async (profileId, deviceName, email) => {

        await api.requestAccessByEmail(profileId, deviceName, email);
        await storage.set(USER_KEY, { waitingConfirmEmail: email });

    },

    getUserEmailsUpdated: async (address, usage) => {

        const { emails: userMails = [] } = await user.getUserInfo();
        if (!address || !address.length) {

            return userMails;

        }
        let personMail = userMails.find(({ usage: emailUsage }) => usage === emailUsage);
        if (personMail) {

            personMail.address = address;

        } else {

            personMail = {
                address,
                usage,
            };
            userMails.push(personMail);

        }
        return userMails;

    },

    getFamilyCode: async () => {

        const shareableMembers = await api.userFamilyShareable();
        const shareableData = await api.userFamilyCode(
            shareableMembers
                .filter(m => m.relKind === 'CHILD' || m.relKind === 'SPOUSE')
                .map(member => ({
                    ...member,
                    level: member.relKind === 'CHILD' ? 'ALL' : member.level,
                })),
        );
        return shareableData._id;

    },

    hasFutureVisitsCheck: async (relId) => {

        const userInfo = await user.getUserInfo();
        const { hashKey, storeKey } = userInfo;

        if (!hashKey || !storeKey) {

            return;

        }
        const chiper = (await firebase.getUserProfile(storeKey)).visitList || [];

        const visits = chiper.map(e => decrypt(e, hashKey));
        // eslint-disable-next-line consistent-return
        return Boolean(visits.find(e => e.profileId === relId && isFutureVisit(e.startDate)));

    },

    deleteChild: async (childId, onError) => {

        // TODO move this check to back-end!!!
        const hasFutureVisits = await user.hasFutureVisitsCheck(childId);

        if (hasFutureVisits) {

            onError('titles.child_cannot_be_deleted');

        } else {

            user.getUserInfo().then(({ hashKey }) => {

                if (hashKey) {

                    removeRelativeFromStorage({
                        profileId: childId,
                        hashKey,
                    });

                }

            });
            api.deleteChild(childId);

        }

    },

    deleteRelative: async (relId, onError) => {

        // TODO move this check to back-end!!!
        const hasFutureVisits = await user.hasFutureVisitsCheck(relId);
        if (hasFutureVisits) {

            onError('titles.relative_cannot_be_deleted');

        } else {

            user.getUserInfo().then(({ hashKey }) => {

                if (hashKey) {

                    removeRelativeFromStorage({
                        profileId: relId,
                        hashKey,
                    });

                }

            });
            api.deleteRelative(relId);

        }

    },

    getPubVisit: async ({ orderId, pCode, ed }) => {

        const visitAndProfileData = await api.getPubVisit({
            orderId,
            providerCode: pCode,
            ed,
        });
        if (visitAndProfileData?.respInfo?.status === 204) {

            return {
                empty: true,
                alreadyImported: true,
            };

        }
        return visitAndProfileData?.data ? JSON.parse(visitAndProfileData?.data) : null;

    },

    importPubVisit: async (profileId, omId, useOrderPatientData = null) => {

        const visitAndProfileData = await api.importPubVisit(profileId, omId, useOrderPatientData);
        return visitAndProfileData?.data ? JSON.parse(visitAndProfileData?.data) : null;

    },
    /**
     * Preferences and Selections
     */

    updatePreference: async (key, valFn) => {

        const { hashKey, storeKey } = await user.getUserInfo();

        if (!hashKey || !storeKey) {

            return null;

        }

        try {

            const prefs = decrypt(await storage.get(PREFS), hashKey) || {};
            const val = await valFn(prefs[key]);
            const newPrefs = {
                ...prefs,
                [key]: val,
            };
            const encrypted = encrypt(newPrefs, hashKey);
            await saveUserStats(newPrefs);
            await storage.set(PREFS, encrypted);
            await firebase.setUserData('prefs', encrypted, storeKey);
            return newPrefs;

        } catch (ex) {

            // eslint-disable-next-line no-console
            console.error(ex);
            return null;

        }

    },

    updateSelections: async (key, valFn) => {

        const selects = (await storage.get('selections')) || {};
        const val = await valFn(selects[key]);
        const newSelects = {
            ...selects,
            [key]: val ?? '',
        };
        Object.keys(newSelects).forEach((k) => {

            if (typeof newSelects[k] === 'undefined') {

                newSelects[k] = '';

            }

        });
        await storage.set('selections', newSelects);
        const { storeKey } = await user.getUserInfo();
        if (storeKey) {

            await firebase.setUserData('selections', newSelects, storeKey);

        }
        return newSelects;

    },

    /**
     * Authorization
     */
    // eslint-disable-next-line consistent-return
    signIn: async (phoneNumber, appHash, onError) => {

        try {

            const isOnline = (await NetInfo.fetch()).isInternetReachable;
            if (!isOnline) {

                throw new Error(Object.R('error.network_connection'));

            }
            const deviceName = await getDeviceName();
            const deviceId = await storage.get('deviceId');
            const response = await api.phoneLogin(phoneNumber, deviceId, deviceName, appHash);
            let captchaCode;
            const { id, needsHumanCheck } = response || {};
            if (needsHumanCheck) {

                if (Platform.OS === 'web') {

                    captchaCode = await api.getCaptchaCodeWeb(id);

                } else {

                    captchaCode = await api.getCaptchaCode(id);

                }

            }
            return {
                id,
                captchaCode,
            };

        } catch (e) {

            if (e.body && e.body.attributes && e.body.attributes.reInitAfter) {

                onError(
                    getMessageWithTime(
                        e.body.attributes.reInitAfter,
                        'error.activation_canceled/seconds',
                        'error.activation_canceled/minutes',
                    ),
                    getErrorCodeIfNeeded(e.body),
                );

            } else if (e.code === 'INVALID_PARAM') {

                onError(Object.R('error.auth/invalid-phone-number'), getErrorCodeIfNeeded(e.body));

            } else {

                onError(getFinalMessage(e.body) || e.message, getErrorCodeIfNeeded(e.body));

            }

        } finally {

            removeAllCachedResponses();

        }

    },

    // eslint-disable-next-line consistent-return
    smsConfirmation: async (code, id, onError) => {

        try {

            const smsCode = await api.confirmSms(Number(code), id);
            if (smsCode.respInfo.status === 200) {

                return smsCode.data;

            }
            const resp = JSON.parse(smsCode.data);
            if (resp.code === 'INVALID_INPUT') {

                throw Object.assign(new Error(Object.R('error.ERROR_INVALID_VERIFICATION_CODE'), { code: resp.code }));

            } else if (resp.code === 'INVALID_STATE' && resp.attributes && resp.attributes.retryAfter) {

                throw new Error(
                    getMessageWithTime(
                        resp.attributes.retryAfter,
                        'error.code_was_entered_incorrectly/seconds',
                        'error.code_was_entered_incorrectly/minutes',
                    ),
                );

            } else {

                onError(getFinalMessage(resp), getErrorCodeIfNeeded(resp));

            }

        } catch (e) {

            onError(e.message, e.code);

        }

    },


    captchaCodeConfirmation: async (code, id, onError) => {

        try {

            // noinspection UnnecessaryLocalVariableJS
            const response = await api.confirmCaptchaCode(Number(code), id);
            return response;

        } catch (e) {

            if (e?.body?.code === 'INVALID_INPUT') {

                const message = Object.R('error.input_code_was_entered_incorrectly');
                onError(message, e?.body.code);
                // onError(e.body.detail);

            } else if (e?.body?.code === 'INVALID_STATE') {

                const message = getMessageWithTime(
                    e?.body?.attributes?.retryAfter,
                    'error.input_code_was_entered_incorrectly/seconds',
                    'error.input_code_was_entered_incorrectly/minutes',
                );
                onError(message, e?.body.code);
                // onError(e.body.detail);

            } else {

                onError(getFinalMessage(e.body) || e.message);

            }
            return e;

        }

    },
    /**
     * A-code
     */
    // eslint-disable-next-line max-params
    tryToOpenResult: async (item, acode, onError, onSuccess, isVisit, onCancel) => {

        const provider = item?.providerCode;
        const checkACode = await aCodeSecurity.checkProvider(provider);

        if (!checkACode.isValid) {

            const checkResult = await user.checkResult(
                checkACode?.useBio,
                checkACode?.bio?.available,
                checkACode?.code,
            );
            aCodeSecurity.message('checkResult', checkResult);
            if (typeof checkResult === 'object') {

                if (checkResult?.name) {

                    onError(getLocalizedError(checkResult?.name));

                }
                onError(checkResult);
                return;

            }
            if (checkResult === false) {

                onCancel();
                return;

            }
            aCodeSecurity.message('bio checked OK', acode);
            await aCodeSecurity.setLastAccess(provider);
            // eslint-disable-next-line no-param-reassign
            acode = await aCodeSecurity.getValidCode(provider);
            aCodeSecurity.message('new acode', acode);

        }
        const result = isVisit ? await user.openVisitReportMem(item, acode) : await user.openTestResultMem(item, acode);
        if (result?.error) {

            if (result.error.code === 'INVALID_ACODE' || result.error.code === 'INVALID_ACODE_BLOCKED') {

                onError(result.error);

            } else {

                onError(result.error.defaultMessage || result.error.code || result.error.status);

            }

        } else if (result.data) {

            await aCodeSecurity.setLastAccess(provider);
            await aCodeSecurity.resetUnexpiredAccess();
            await user.resetAcodeTimeout();
            onSuccess(result.data, result.contentType);

        }

    },
    checkResult: async (useBio, available, code = 0) => {

        if (useBio && available && code) {

            let answ = {
                success: false,
                code: 'bio_error',
                message: 'bio.ERROR_BIO',
            };
            try {

                answ = await isFingerprintMatch(new ReactNativeBiometrics({ allowDeviceCredentials: true }));
                if (typeof answ === 'object') {

                    answ = answ?.success;

                }
                Object.acodeRecheck = answ?.success !== true;

            } catch (error) {

                answ = {
                    success: false,
                    code: 'bio_error',
                    message: 'bio.ERROR_BIO',
                    error,
                };
                Object.acodeRecheck = true;

            } finally {


                Platform.OS === 'android' && FingerprintScannerRelease();

            }
            return answ;

        }
        return { code: 'REENTER_ACODE' };

    },
    openTestResult: async (test, acode) => {

        const {
            id, testProvider: provider, providerCode, testResult, profileId, customerId, policyId,
        } = test;
        // noinspection UnnecessaryLocalVariableJS
        const result = await testServices.getTestResult(
            id,
            provider,
            providerCode,
            String(acode),
            testResult,
            profileId,
            customerId,
            policyId,
        );
        return result;

    },
    openVisitReport: async (visit, acode) => {

        const {
            providerCode,
            report: { id, reportResults = [] } = {},
            profileId,
            customerId,
            policyId,
            orderId,
        } = visit;
        const result = await api.getVisitReport(
            id,
            providerCode,
            String(acode),
            reportResults[0],
            profileId,
            customerId,
            policyId,
            orderId,
        );
        if (result.respInfo?.status === 200 || Platform.OS !== 'web') {

            try {

                const parsedData = JSON.parse(result.data);
                return {
                    error: {
                        ...parsedData,
                        defaultMessage: getFinalMessage(result.data),
                        severity: result.data?.severity ?? parsedData?.severity,
                    },
                };

            } catch (e) {

                return {
                    data: result.data,
                    contentType: result?.respInfo?.headers['Content-Type'],
                };

            }

        } else {

            if (Platform.OS === 'web') {

                const resp = await result.data?.text();
                const parsedResp = resp ? JSON.parse(resp) : null;
                return {
                    error: {
                        status: result.respInfo.status,
                        code: result.respInfo.code,
                        defaultMessage: getFinalMessage(parsedResp),
                        severity: parsedResp?.severity,
                    },
                };

            }

            return {
                error: {
                    status: result.respInfo.status,
                    code: result.respInfo.code,
                    defaultMessage: getFinalMessage(result.respInfo.data),
                    severity: result.respInfo.data?.severity,
                },
            };

        }

    },
    openTestResultMem: async (test, acode) => {

        let report = memoryStorage.get(test);
        if (report) {

            return report.info;

        }
        report = await user.openTestResult(test, acode);
        if (!report?.error) {

            memoryStorage.add(test, report, report?.data?.length ?? report?.data?.size);

        }
        return report;

    },
    openVisitReportMem: async (visit, acode) => {

        let report = memoryStorage.get(visit);
        if (report) {

            return report.info;

        }
        report = await user.openVisitReport(visit, acode);
        if (!report.error) {

            memoryStorage.add(visit, report, report?.data?.length ?? report?.data?.size);

        }
        return report;

    },
    resetAcodeTimeout: async () => {

        Object.acodeRecheck = false;
        const acodeExpirationTime = await storage.get('acodeExpirationTime');
        if (Object.acodeTimeout) {

            clearTimeout(Object.acodeTimeout);

        }
        if (acodeExpirationTime > 0) {

            Object.acodeTimeout = setTimeout(() => {

                removeAllACodes();
                Object.acodeRecheck = true;

            }, acodeExpirationTime);

        } else if (acodeExpirationTime === 0) {

            removeAllACodes();
            Object.acodeRecheck = true;

        }

    },
};

export default user;
