
// noinspection ES6PreferShortImport

import { useNavigation } from '@react-navigation/native';
import ReactNativeBiometrics from 'react-native-biometrics';
import { Linking, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { DbLastUpdateDate } from '../../combo/DbLastUpdateDate';
import {
    Button,
    Logo,
    Modal,
    NavigationItem,
    NavigationList,
    Page,
    React,
    RText, ScrollView,
    Section,
    showSettingsModal,
    Text,
    View,
} from '../../common';
import { SwipeBack } from '../../common/SwipeBack';
import {
    ALLOWED_LANGUAGES, DISABLE_INSTALL_MOBILE_APP_MESSAGE, MODE, VERSION as version,
} from '../../config';
import { LanguageSelector } from '../../combo/LanguageSelector';
import { getSupportedlanguages } from '../../selectors';
import { Dimensions } from '../../common/react';
import { COLORS } from '../../styles/colors';
import { notifications } from '../../common/Notifications';
import { Modal3 } from '../../common/Modal';
import { getSupportBody } from '../../utils/supportInfo';
import { memoryStorage } from '../../utils/memoryStorage';
import { dbGettr } from '../../selectors/utils';
import { getLogs } from '../../selectors/logs';
import { clearLog, LOG_TO_RELEASE } from '../../actions/logs';
import actions from '../../actions';
import store from '../../store';
import { aCodeSecurity } from '../../utils/aCodeSecurity';
import { logTimeFormat } from '../../utils/dates';
import firebase from '../../services/firebase';
import { getFormValues } from '../../selectors/forms';
import { isMobile } from '../../utils/strings';
import { ShareChildrenButton, ShareFamilyButton } from './snippets/ShareFamilyButton';
import { ImportChildrenButton, ImportMyFamilyButton } from './snippets/ImportMyFamilyButton';

const SHOW_NOTIFY_LIST = true;
const SHOW_RC = true;
const SHOW_LOG = true;

export const openURL = ({ routeTo, blank = true }) => {


    const addParams = String(routeTo).indexOf('mailto') === -1 ? '' : getSupportBody();
    if (Platform.OS === 'web') {


        return window.open(`${routeTo}${addParams}`, blank ? '_blank' : '_self');

    }
    return Linking.openURL(`${routeTo}${addParams}`);

};

export const showLastMax = () => {

    Page.showModal(
        <Modal title="last max physicians">
            <Text numberOfLines={5} selectable>
                {firebase.collectionsData?.physicians?.lastMax}
            </Text>
        </Modal>,
    );

};

/**
 * The 'About' page. (☆☆☆☆☆)
 * Contains an application attribution information
 * such as logo, legals, support link, version etc.
 */

export const About = Page.register(() => {

    const checkBio = async () => {

        try {

            const res = await ReactNativeBiometrics.isSensorAvailable();
            actions.addLog('bio', res);

        } catch (e) {

            // eslint-disable-next-line no-console
            console.log('BIO Error', e);

        }

    };

    const languages = ALLOWED_LANGUAGES?.length
        ? useSelector(getSupportedlanguages)?.filter?.(({ id }) => ALLOWED_LANGUAGES?.find(({ id: f }) => f === id))
        : useSelector(getSupportedlanguages);
    const GetWidth = () => {

        try {

            const { width, height } = Dimensions.get('window');
            return (
                <Text
                    style={{
                        color: COLORS.STANDARD_GRAY,
                        fontSize: 8,
                        textAlign: 'center',
                        padding: 3,
                    }}
                    centered>
                    {' '}
                    Resolution: {width?.toFixed(0)}x{height.toFixed(0)}
                </Text>
            );

        } catch {

            return null;

        }

    };

    const NotifyButton = () => {

        if (!SHOW_NOTIFY_LIST) {

            return null;

        }
        return (
            <Button
                title="Notifications"
                styles={[
                    {
                        borderWidth: 1,
                        borderColor: COLORS.BORDER_GRAY,
                        backgroundColor: COLORS.POSITIVE,
                        color: COLORS.LIGHT_GRAY,
                        padding: 4,
                    },
                ]}
                action={() => {

                    notifications.getScheduleList(l => Page.showModal(
                        <Modal3 title="Notifications list">
                            <Text>
                                {JSON.stringify(
                                    l
                                        .sort((a, b) => (new Date(a.date).getTime() > new Date(b.date).getTime() ? 1 : -1),)
                                        .map(item => ({
                                            id: item.id,
                                            message: item.message,
                                            data: item.data,
                                            date: new Date(item.date).toLocaleString(),
                                        })),
                                    null,
                                    2,
                                )}
                            </Text>
                        </Modal3>,
                    ),);
                    // Alert.alert('Notifications', JSON.stringify(list));

                }}
            />
        );

    };

    const RemoteConfigButton = () => {

        if (!SHOW_RC) {

            return null;

        }

        const GetParam = ({ param }) => {

            const db = useSelector(dbGettr(param, 'UNKNOWN'));
            return (
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}>
                    <Text>{param}:</Text>
                    <Text>{JSON.stringify(db)}</Text>
                </View>
            );

        };

        return (
            <Button
                title="RC"
                styles={[
                    {
                        borderWidth: 1,
                        borderColor: COLORS.BORDER_GRAY,
                        backgroundColor: COLORS.POSITIVE,
                        color: COLORS.LIGHT_GRAY,
                        padding: 4,
                    },
                ]}
                action={async () => {

                    Page.showModal(
                        <Modal3 title="Remote config">
                            <View style={{ padding: 10 }}>
                                <Text>
                                    PDF storage: {memoryStorage?.size}, now: {memoryStorage.currentSize()}
                                </Text>
                                <GetParam param="defaultSmsProvider" />
                                <GetParam param="acodeExpirationTime" />
                                <GetParam param="disableUnconfirmedFeedback" />
                                <GetParam param="disableUnconfirmedFeedbackForOnlineClinicsOnly" />
                                <GetParam param="enableLoadPastsVisits" />
                                <GetParam param="enableReports" />
                                <GetParam param="enableTests" />
                                <GetParam param="endOfLife" />
                                <GetParam param="hideTimePeriodSearch" />
                                <GetParam param="inAppUpdateAndroid" />
                                <GetParam param="inAppUpdateIOS" />
                                <GetParam param="inAppUpdateNotifyPeriod" />
                                <GetParam param="mapsAppsConfig" />
                                <GetParam param="showNewUserAgreementModal" />
                                <GetParam param="statusesOfHappenedVisits" />
                                <GetParam param="statusesOfHappenedVisitsDoctor" />
                                <GetParam param="supportedLanguages" />
                                <GetParam param="popularQueries" />
                            </View>
                        </Modal3>,
                    );

                }}
            />
        );

    };

    const showLog = (log, onClear = () => {}) => {

        actions.addLog('NEW_ARCHITECTURE', global?.nativeFabricUIManager ? 'Fabric' : 'Paper');
        Page.showModal(
            <Modal3
                title="Logs"
                onSubmit={() => {

                    onClear();
                    Page.closeModal();

                }}
                buttonTitle="Clear">
                <Text>{JSON.stringify(log, null, 2)}</Text>
            </Modal3>,
        );

    };

    const LogButton = () => {

        const dispatch = useDispatch();

        const log = useSelector(getLogs);

        if (!SHOW_LOG) {

            return null;

        }
        return (
            <Button
                title="LOGS"
                styles={[
                    {
                        borderWidth: 1,
                        borderColor: COLORS.BORDER_GRAY,
                        backgroundColor: COLORS.POSITIVE,
                        color: COLORS.LIGHT_GRAY,
                        padding: 4,
                    },
                ]}
                action={() => {

                    showLog(log, () => dispatch(clearLog()));

                }}
            />
        );

    };

    const BioButton = () => SHOW_LOG && (
        <Button
            title="BIO"
            styles={[
                {
                    borderWidth: 1,
                    borderColor: COLORS.BORDER_GRAY,
                    backgroundColor: COLORS.POSITIVE,
                    color: COLORS.LIGHT_GRAY,
                    padding: 4,
                },
            ]}
            action={() => {

                checkBio();
                showSettingsModal(() => {});

            }}
        />
    );

    const showEncryptedModal = async () => {

        const cards = store.getState()?.user?.info?.me?.cards ?? [];

        for (const card of cards) {

            const provider = card?.providerCode;
            if (!provider) {

                return;

            }

            const check = await aCodeSecurity.checkProvider(provider);
            if (check.code) {

                actions.addLog('encrypted', `${provider}: ${check.code} ${logTimeFormat(check.validUntil, false)}`);

            }

        }

    };

    const EncryptedStorage = () => {

        return (
            SHOW_LOG && (
                <Button
                    title="Enc"
                    styles={[
                        {
                            borderWidth: 1,
                            borderColor: COLORS.BORDER_GRAY,
                            backgroundColor: COLORS.POSITIVE,
                            color: COLORS.LIGHT_GRAY,
                            padding: 4,
                        },
                    ]}
                    action={async () => {

                        await showEncryptedModal();

                    }}
                />
            )
        );

    };

    const LastMax = () => {

        return (
            SHOW_LOG && (
                <Button
                    title="LM"
                    styles={[
                        {
                            borderWidth: 1,
                            borderColor: COLORS.BORDER_GRAY,
                            backgroundColor: COLORS.POSITIVE,
                            color: COLORS.LIGHT_GRAY,
                            padding: 4,
                        },
                    ]}
                    action={showLastMax}
                />
            )
        );

    };

    const ScheduleNotification = () => {

        return (
            SHOW_LOG && (
                <Button
                    title="Notify 15s"
                    styles={[
                        {
                            borderWidth: 1,
                            borderColor: COLORS.BORDER_GRAY,
                            backgroundColor: COLORS.POSITIVE,
                            color: COLORS.LIGHT_GRAY,
                            padding: 4,
                        },
                    ]}
                    action={() => {

                        notifications.schedule({
                            date: new Date(Date.now() + 15000),
                            text: 'Test scheduled notify',
                            title: 'Title scheduled notify',
                            message: 'Send feedback',
                            data: {
                                type: 'feedback_reminder1',
                                doctorId: 'no_id',
                            },
                        });

                    }}
                />
            )
        );

    };

    const d = useSelector(getFormValues);

    const navigation = useNavigation();

    const onSwipeBackHandler = () => {

        navigation.navigate('Home');

    };
    return (
        <Page name="others" noscroll>
            <SwipeBack forOS={['ios']} onSwipeBack={onSwipeBackHandler} enabled={true}>
                <ScrollView style={{ flex: 1 }}>
                    <Section>
                        <Logo.Wrapper title="others.mainGoal" subtitle="others.serviceSource" />
                    </Section>
                    {Platform.OS === 'web' && isMobile() && !DISABLE_INSTALL_MOBILE_APP_MESSAGE && (
                        <NavigationItem
                            onItem={() => {

                                const urlKey = 'titles.otherNavigationMwaLinkFallback';
                                const defaultUrl = Object.R(urlKey);
                                try {

                                    if (!!defaultUrl && defaultUrl !== urlKey) {

                                        openURL({ routeTo: defaultUrl });
                                        return;

                                    }
                                    const docId = d?.deeplink?.docId;
                                    const utm_campaign = d?.deeplink?.utm_campaign ?? 'mma_link_from_other_section';
                                    const dId = docId ? `?docId=${docId}&` : '?';
                                    const domain = Object.R('titles.otherNavigationMwaLinkDomain') ?? 'https://link.aibolit.md';
                                    const url = `${domain}/l/?link=${domain}/d${dId}apn=md.aibolit.member.prod&isi=1441433446&ibi=md.aibolit.member.prod&efr=1&utm_source=mwa&utm_medium=mma_link_from_other_section&utm_campaign=${utm_campaign}`;
                                    openURL({ routeTo: url });
                                    Object.trackAction('click_mobile_link_from_other', {
                                        utm_source: 'mwa',
                                        utm_medium: 'mma_link_from_other_section',
                                        utm_campaign,
                                    });

                                } catch (e) {

                                    defaultUrl !== urlKey && !!defaultUrl && openURL({ routeTo: defaultUrl });

                                }

                            }}
                            firstItem={true}
                            title="titles.otherNavigationMwaTitle"
                            containerStyle={{ borderBottomWidth: 0 }}
                        />
                    )}
                    {languages?.length > 1 ? <LanguageSelector firstItem={true} /> : null}
                    <NavigationList
                        dataEnum={['othersNavigation']}
                        onItem={(item) => {

                            openURL(item);

                        }}
                        useSwipeToHome
                        filtredFields={MODE === 'uat' ? ['about', 'privacy', 'user_agreement', 'personalData'] : []}
                    />
                    <View>
                        <Section>
                            <DbLastUpdateDate />
                            <RText centered style={{ fontSize: 10 }} id="others.version" params={{ version }} />
                            {MODE === 'qa' || LOG_TO_RELEASE ? (
                                <View
                                    style={{
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                    <View style={{ flexDirection: 'row' }}>
                                        <GetWidth />
                                    </View>
                                    <View style={{ flexDirection: 'row' }}>
                                        <NotifyButton />
                                        <RemoteConfigButton />
                                        <LogButton />
                                        <BioButton />
                                        <EncryptedStorage />
                                        <LastMax />
                                        <ScheduleNotification />
                                    </View>
                                    <View style={{ flexDirection: 'row' }}>
                                        <ShareFamilyButton />
                                        <ShareChildrenButton />
                                    </View>
                                    <View style={{ flexDirection: 'row' }}>
                                        <ImportMyFamilyButton />
                                        <ImportChildrenButton />
                                    </View>
                                </View>
                            ) : null}
                        </Section>
                    </View>
                </ScrollView>
            </SwipeBack>
        </Page>
    );

});
