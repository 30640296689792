/* global window */

import iconFontFeather from 'react-native-vector-icons/Fonts/Feather.ttf';
import iconFontFontAwesome from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import iconFontEntypo from 'react-native-vector-icons/Fonts/Entypo.ttf';
import iconFontIonicons from 'react-native-vector-icons/Fonts/Ionicons.ttf';
import IconMaterialCommunityIcons from 'react-native-vector-icons/Fonts//MaterialCommunityIcons.ttf';

const isMobile =
    window.navigator &&
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        window.navigator.userAgent,
    );

window.isDesktop = !isMobile;
window.isLocalHost = window.location.host.includes('localhost');

const iconFontStyles = `@font-face {
  src: url(${iconFontFeather});
  font-family: Feather;
  }@font-face {
    src: url(${iconFontFontAwesome});
    font-family: FontAwesome;
  }@font-face {
    src: url(${iconFontEntypo});
    font-family: Entypo;
  }@font-face {
    src: url(${IconMaterialCommunityIcons});
    font-family: MaterialCommunityIcons;
  }@font-face {
    src: url(${iconFontIonicons});
    font-family: Ionicons;
  }`;

const style = document.createElement('style');
if (style.styleSheet) {
    style.styleSheet.cssText = iconFontStyles;
} else {
    style.appendChild(document.createTextNode(iconFontStyles));
}
document.head.appendChild(style);

const shouldDisplayPopup = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const acParamExists = urlParams.has('ac') && urlParams.get('ac') === 'login';
    const pnParamExists = urlParams.has('pn');
    const docNameParamExists = urlParams.has('docName');

    return !((acParamExists && pnParamExists) || docNameParamExists);
};

if (!window.sessionStorage.getItem('withoutModal') && shouldDisplayPopup()) {
    const popup = document.getElementById('popup');
    if (popup) {
        popup.style.cssText = `display: block;`;
    }
}

const hideInstallPrompt = () => {
    window.sessionStorage.setItem('withoutModal', true);
    const popup = document.getElementById('popup');
    if (popup) {
        popup.style.cssText = `display: none;`;
    }
};

const openInMobileBtn = document.getElementById('in-mobile-btn');
if (openInMobileBtn) {
    openInMobileBtn.onclick = hideInstallPrompt;
}

const continueBtn = document.getElementById('continue-btn');
if (continueBtn) {
    continueBtn.onclick = hideInstallPrompt;
}

const rootElement = document.getElementById('root');

setTimeout(() => {
    const { renderWebApp } = require('../app');
    renderWebApp(rootElement);
}, 5);
