/* eslint-disable complexity */
import { Animated, Keyboard } from 'react-native';
import {
    dataFrom,
    FlatList,
    Icon,
    Platform,
    PureComponent,
    React,
    ScrollView,
    SearchBar,
    Section,
    Stub,
    View,
} from '../../common';
import { sortBy } from '../../utils';
import { getUmsActiveFilters } from '../../selectors';
import { ServiceListItem } from './snippets';
import { AdaptiveListHeader } from './AdaptiveListHeader';
import { searchByKeywordList as styles } from './styles';
import { filterTypesByKeyword, getHeaderSummary, goToUmsFilter } from './utils';
import { UMSListHeader } from './UMSListHeader';

const AnimatedFlatList = Animated.createAnimatedComponent(FlatList);

const FilterIcon =
    dataFrom(
        getUmsActiveFilters,
        ({ data }) => (
            <Icon.Filter badge={data} onPress={goToUmsFilter}/>
        ),
    );

export class SearchByKeywordList extends PureComponent {
    // using constructor in this case is requirement when uses onViewableItemsChanged and viewabilityConfig, otherwise we will get error
    // see into documentaion FlatList RN

    constructor(props) {
        super(props);
        this.state = {
            typeName: null,
            groupName: null,
            groupId: '',
            typeId: '',
            animatedValue: new Animated.Value(0),
        };

        this.onViewableItemsChanged = this.onViewableItemsChanged.bind(this);
        this.viewabilityConfig = { itemVisiblePercentThreshold: 1 };
    }

    onViewableItemsChanged = ({ viewableItems }) => {
        const { isTypeList } = this.props;
        if (!isTypeList && viewableItems[0] && viewableItems[0].item && !viewableItems[0].item.headerComponent) {
            const {
                typeId,
                typeName,
                groupId,
                groupName,
            } = viewableItems[0].item;
            this.setState({
                typeName,
                groupName,
                groupId,
                typeId,
            });
        }
    };

    // eslint-disable-next-line class-methods-use-this
    getUmsConfig = (listsControlParams, typeName, typeId, groupName, groupId) => ({
        groupId,
        groupName,
        groupVisible: false,
        itemVisible: true,
        keyword: '',
        level: 'item',
        searchVisible: false,
        typeId,
        typeName,
        typeVisible: false,
        fromSearch: true,
        listsControlParamsBeforeTransitionToGroup: listsControlParams,
    });

    render() {
        const {
            onItem,
            onTypeListItem,
            keyword,
            currentProgram,
            setParamsUmsMain,
            isProgrammSelectorVisible,
            onSearch,
            onSearchValue,
            tree,
            headerComponents,
            isTypeList,
            isComponentVisible,
            type,
            listsControlParams,
            isLoading,
        } = this.props;

        const {
            typeName,
            groupName,
            typeId,
            groupId,
        } = this.state;

        const headerOffsetValue = getHeaderSummary(isProgrammSelectorVisible, false, 'type');

        const translateY = this.state.animatedValue.interpolate({
            inputRange: [0, headerOffsetValue],
            outputRange: [0, -headerOffsetValue],
            extrapolate: 'clamp',
        });

        const commonData = isTypeList ? sortBy(Object.values(tree)) : filterTypesByKeyword(tree, keyword);

        const marginOffset = !isTypeList && commonData.length ? (headerOffsetValue + 100) : headerOffsetValue;

        const havePartner = !!commonData.find(item => !!item.coverer);

        const optionHasCoverage = (item) => {
            const options = item.options?.find(i => i.id === item?.preSelectedOption?.id);
            if (options) {
                return options.coverage?.includes(currentProgram.id);
            }
            return true;
        };

        const shouldRenderIndicator = (!currentProgram || currentProgram.id !== '*') && havePartner;

        const haveHeader = headerComponents.some(header => !!header);
        const contentContainerVerticalOffset = commonData.length ? 100 : 0;

        let firstItemOffset = 0;
        if (Platform.OS === 'ios') {
            firstItemOffset = haveHeader ? 179 : 105;
        } else {
            firstItemOffset = haveHeader ? 186 : 121;
        }
        return (
            <View style={styles.container}>
                <AnimatedFlatList
                    accessibilityLabel="list"
                    data={commonData}
                    keyExtractor={({
                        id,
                        preSelectedOption,
                    }) => (preSelectedOption ? `${id}-${preSelectedOption.id}` : id)}
                    onViewableItemsChanged={this.onViewableItemsChanged}
                    viewabilityConfig={this.viewabilityConfig}
                    onMomentumScrollBegin={() => {
                        Keyboard.dismiss();
                    }}
                    contentContainerStyle={{
                        marginTop: isTypeList ? marginOffset : contentContainerVerticalOffset,
                        marginLeft: isTypeList ? 20 : 0,
                    }}
                    style={{
                        marginBottom: -2,
                        height: Platform.OS === 'web' ? 0 : undefined,
                        flex: 1,
                    }}
                    scrollEventThrottle={16}
                    onScroll={Animated.event(
                        [{ nativeEvent: { contentOffset: { y: this.state.animatedValue } } }],
                        Platform.OS === 'web' ? {} : { useNativeDriver: true },
                    )}
                    ListEmptyComponent={(<Stub type={`empty-${type || 'default'}`} style={{
                        marginTop: headerOffsetValue,
                        marginLeft: 16,
                    }}/>)}
                    renderItem={({
                        item,
                        index,
                        item: {
                            headerComponent,
                            typeName: headerItemTypeName,
                            groupName: headerItemGroupName,
                            groupId: headerItemGroupID,
                            typeId: headerItemTypeID,
                            typeNameCopy: headerItemTypeNameCopy,
                        },
                    }) => (
                        <View>
                            {
                                // Recompensed margin offset. Temporary solution.
                                (index === 0 && !isTypeList) ? <View style={{ height: firstItemOffset }}/> : null
                            }
                            {
                                isTypeList
                                    ? (
                                        <ServiceListItem
                                            onItem={onTypeListItem}
                                            item={item}
                                            isRenderIndicator={shouldRenderIndicator}
                                            currentProgram={currentProgram}
                                        />
                                    ) : null
                            }
                            {
                                !isTypeList && headerComponent
                                    ? (
                                        <AdaptiveListHeader
                                            titleFirst={headerItemTypeName}
                                            titleSecond={headerItemGroupName}
                                            onTitleSecondPress={() => {
                                                setParamsUmsMain(this.getUmsConfig(listsControlParams, headerItemTypeNameCopy, headerItemTypeID, headerItemGroupName, headerItemGroupID));
                                            }}
                                        />
                                    ) : null
                            }
                            {
                                !isTypeList && !headerComponent
                                    ? (
                                        <ServiceListItem
                                            item={item}
                                            keyword={keyword}
                                            option={item.preSelectedOption}
                                            onItem={onItem}
                                            currentProgram={optionHasCoverage(item) ? currentProgram : ''}
                                        />) : null
                            }
                            {
                                // Recompensed header offset. Temporary solution.
                                (commonData.length - 1 === index)
                                    ? <View style={{ height: isTypeList ? marginOffset : 100 }}/> : null
                            }
                        </View>
                    )
                    }
                />
                <Animated.View style={[styles.headerWrapper, { transform: [{ translateY }] }]}>
                    {headerComponents}
                    {isComponentVisible ? <UMSListHeader onSearch={onSearch} searchData={onSearchValue} isLoading={isLoading} /> : null}
                    {!isTypeList && isComponentVisible
                        // Wrap header in ScrollView to prevent header press with open keyboard
                        ? <ScrollView>
                            <AdaptiveListHeader
                                titleFirst={commonData.length && (typeName || commonData[0].typeName || 'Something went wrong')}
                                titleSecond={commonData.length && (groupName || commonData[0].groupName || 'Something went wrong')}
                                onTitleSecondPress={() => {
                                    setParamsUmsMain(this.getUmsConfig(listsControlParams, typeName, typeId, groupName, groupId));
                                }}
                                // animated
                            />
                        </ScrollView> : null
                    }
                </Animated.View>
            </View>
        );
    }
}

