import { useCallback, useState } from 'react';
import {
    FlatList, Icon, LoadingIndicator, React, Title, TouchableOpacity, View,
} from '../../common';
import { onActualResult } from '../App/snippets/Tests&Visits/snippets';
import tracking from '../../services/tracking';
import { COLORS } from '../../styles/colors';
import { visitReports as styles } from './style';

const ListItem = ({ item, onPress, index }) => {
    const [isLoading, setIsLoading] = useState();
    const onReportPress = useCallback(async () => {
        setIsLoading(true);
        await onPress(item);
        setIsLoading(false);
    }, [item, onPress]);
    return (
        <TouchableOpacity onPress={onReportPress} style={styles.listItem}>
            <View style={{ flexDirection: 'row' }}>
                <Title>{Object.R(`titles.report${index ? 'Number' : ''}`, { index })}</Title>
                <Icon.Attach
                    size={12}
                    style={{
                        marginLeft: 6,
                        marginTop: 5,
                    }}
                />
            </View>
            {isLoading ? (
                <LoadingIndicator style={styles.loading} indicatorSize={20} />
            ) : (
                <Icon.Right color={COLORS.DARK_GRAY} />
            )}
        </TouchableOpacity>
    );
};

export const VisitReports = ({ data, visitInfo }) => {
    const onPress = useCallback(
        async (item) => {
            tracking.logEvent('ai_result_click_details');
            await onActualResult(
                {
                    ...visitInfo,
                    report: item,
                },
                true,
            );
        },
        [visitInfo],
    );
    return (
        <View accessibilityLabel="visits_reports-section" style={styles.container}>
            <FlatList
                accessibilityLabel="visits_reports-list"
                data={data}
                renderItem={({ item, index }) => (
                    <ListItem item={item} onPress={onPress} index={data.length > 1 ? index + 1 : null} />
                )}
            />
        </View>
    );
};
