/* eslint-disable no-console */
import ReactNativeBlobUtil from 'react-native-blob-util';
import { useEffect, useState } from 'react';
import { useIsFocused } from '@react-navigation/native';
import Pdf from 'react-native-pdf';
import { useDispatch } from 'react-redux';
import {
    Button, FlatList, Icon, LoadingIndicator, Modal, Page, Platform, PopupMessage, React, Title, View,
} from '../../common';
import { getIsAuthenticated, getOnBackground } from '../../selectors';
import { downloadFile, shareFile } from '../../services/device';
import { visitModalContent as styles } from '../Visits/style';
import { removePDFFromCache } from '../../services/iosCacheManager';
import { setFormData } from '../../actions/forms';
import { back } from '../../actions';
import { pdfViewerStyles } from './styles';

const menuItems = (sourceData, showMessage) => (Platform.OS === 'ios'
    ? [
        {
            id: 'share',
            handler: (onClose) => {
                if (Platform.OS === 'android') {
                    // eslint-disable-next-line no-unused-expressions
                    onClose && onClose();
                }
                shareFile(sourceData, onClose);
            },
        },
    ]
    : [
        {
            id: 'download',
            handler: (onClose) => {
                onClose();
                downloadFile(sourceData, showMessage);
            },
        },
        {
            id: 'share',
            handler: (onClose) => {
                if (Platform.OS === 'android') {
                    // eslint-disable-next-line no-unused-expressions
                    onClose && onClose();
                }
                shareFile(sourceData, onClose);
            },
        },
    ]);

const Menu = ({
    sourceData, showMessage, name, onClose,
}) => (
    <Modal title={name} onRequestClose={onClose}>
        <FlatList
            data={menuItems(sourceData, showMessage)}
            renderItem={({ item: { id, handler } }) => (
                <Button
                    title={`buttons.${id}`}
                    styles={styles.modalButton}
                    textStyles={styles.selectModalTitle}
                    action={() => {
                        handler(onClose);
                    }} //
                />
            )}
        />
    </Modal>
);

export const PDFViewer = Page.register(
    ({
        sourceData, name, isAuthenticated, isRunningOnBackground, navigation,
    }) => {
        useEffect(() => {
            if (!isAuthenticated) {
                navigation.goBack();
            }
        }, [isAuthenticated]);

        const isFocused = useIsFocused();
        if (Platform.OS === 'ios' && !isFocused) {
            removePDFFromCache();
        }

        const [message, setMessage] = useState('');
        const [isMenuOpen, setIsMenuOpen] = useState(false);

        const showMessage = (mes) => {
            setMessage(mes);
            setTimeout(() => setMessage(''), 5000);
        };
        const onClose = () => {
            setMessage('');
        };
        const onModalClose = () => {
            setIsMenuOpen(false);
        };

        const onPress = () => {
            setIsMenuOpen(true);
        };

        const dispatch = useDispatch();

        useEffect(() => {
            dispatch(
                setFormData({
                    PDFViewer: {
                        back: () => {
                            back();
                        },
                    },
                }),
            );
        }, []);

        return (
            <View style={pdfViewerStyles.container}>
                <View style={pdfViewerStyles.header}>
                    <Title style={pdfViewerStyles.title}>{name}</Title>
                    <Icon.Menu onPress={onPress} style={pdfViewerStyles.menuIcon} />
                </View>
                <Pdf
                    source={{
                        uri: `data:application/pdf;base64,${sourceData}`,
                        cache: true,
                        expiration: 1,
                    }}
                    spacing={20}
                    onLoadComplete={(pages, path) => ReactNativeBlobUtil.fs
                        .unlink(path)
                        .then(() => {})
                        .catch(err => console.log(`Error unlink file ${path}`, err))
                    }
                    onError={(...rest) => console.log('Error open pdf', rest)}
                    renderActivityIndicator={LoadingIndicator}
                    style={pdfViewerStyles.viewer}
                />
                {message ? <PopupMessage message={message} onClose={onClose} /> : null}
                {isMenuOpen ? (
                    <Menu sourceData={sourceData} showMessage={showMessage} name={name} onClose={onModalClose} />
                ) : null}
                {(isRunningOnBackground && Platform.OS === 'ios' && (
                    <View
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                            backgroundColor: 'white',
                        }}
                    />
                )) ||
                    null}
            </View>
        );
    },
    {
        isAuthenticated: { from: getIsAuthenticated },
        isRunningOnBackground: { from: getOnBackground },
    },
);
